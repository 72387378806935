import React, { useContext, useEffect } from "react";
import LogAcquisitionForm from "./LogAcquisitionForm";
import { SidebarComponentsContext } from "components_new/SidebarComponents/SidebarComponentsWrapper";
import useInvestmentLogAcquisition from "store/investments/v2/logAcquisition/hooks";
import { useInvestment } from "components_new/SidebarComponents/views/InvestmentView/hooks/useInvestment";
import useOrganizations from "store/organizations/hooks";
import Loader from "components/shared/Loader";
import { isEmpty } from "lodash";

function LogAcquisitionFormWrapper({ ownName }) {
  const {
    getComponentState,
    registerForm,
    mountSidebarComponent,
    hideSidebarComponent,
    closeSidebar,
  } = useContext(SidebarComponentsContext);

  const {
    currentOrganization: { username: currentOrganizationUserName },
  } = useOrganizations();

  const { investment, componentData } = getComponentState(ownName);

  useEffect(() => {
    if (!isEmpty(ownName)) {
      registerForm(ownName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    createLogAcquisition,
    filterTags,
    tagSuggestions,
  } = useInvestmentLogAcquisition();
  const { data, isLoading } = useInvestment(investment?.id);

  if (isLoading || isEmpty(investment)) {
    return <Loader />;
  }

  return (
    <LogAcquisitionForm
      ownName={ownName}
      company={data?.company}
      investment={data?.details}
      closeSidebar={closeSidebar}
      createLogAcquisition={createLogAcquisition}
      filterTags={filterTags}
      tagSuggestions={tagSuggestions}
      componentData={componentData}
      mountSidebarComponent={mountSidebarComponent}
      hideSidebarComponent={hideSidebarComponent}
      currentOrganizationUserName={currentOrganizationUserName}
    />
  );
}

export default LogAcquisitionFormWrapper;
