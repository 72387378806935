import Api from "lib/Api";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

export function usePassFromDeal() {
  const queryClient = useQueryClient();
  const { organizationUsername } = useParams();

  return useMutation(
    ({ formData, pipelineId, dealId }) => {
      return Api.patch(`/pipelines/${pipelineId}/deals/${dealId}/pass`, {
        deal: formData,
      });
    },
    {
      onSuccess: ({ data: updatedDeal }, { pipelineId, dealId }) => {
        updateActiveDealsView(queryClient, pipelineId, updatedDeal);
        updatePassedDeals(queryClient, pipelineId, updatedDeal);
        updateCompanyView(queryClient, organizationUsername, updatedDeal);
        updateDealView(queryClient, updatedDeal);

        queryClient.refetchQueries(["Deal", dealId]);
        queryClient.refetchQueries(["Pipeline", pipelineId]);
        queryClient.invalidateQueries(["PassedDeals", pipelineId, {}]);
        queryClient.invalidateQueries(["SearchDeals", pipelineId]);
      },
    },
  );
}

function updateActiveDealsView(queryClient, pipelineId, updatedDeal) {
  const key = ["ActiveDeals", pipelineId, {}];
  const activeDealsView = queryClient.getQueryData(key);

  if (activeDealsView?.deals) {
    queryClient.setQueryData(key, {
      ...activeDealsView,
      deals: activeDealsView.deals.filter((item) => item.id !== updatedDeal.id),
    });
  }
}

function updateDealView(queryClient, updatedDeal) {
  const key = ["Deal", updatedDeal.id];
  const dealView = queryClient.getQueryData(key);

  if (dealView?.deal) {
    queryClient.setQueryData(key, {
      ...dealView,
      deal: updatedDeal,
    });
  }
}

function updateCompanyView(queryClient, organizationUsername, updatedDeal) {
  const key = ["Company", organizationUsername, updatedDeal.company_id];
  const companyView = queryClient.getQueryData(key);

  if (companyView?.companyDeals) {
    queryClient.setQueryData(key, {
      ...companyView,
      companyDeals: companyView.companyDeals.map((deal) =>
        deal.id === updatedDeal.id ? updatedDeal : deal,
      ),
    });
  }
}

function updatePassedDeals(queryClient, pipelineId, updatedDeal) {
  const key = ["PassedDeals", pipelineId, {}];
  const passedDeals = queryClient.getQueryData(key);

  if (passedDeals?.deals) {
    queryClient.setQueryData(key, {
      ...passedDeals,
      deals: [updatedDeal, ...passedDeals.deals],
    });
  } else {
    queryClient.setQueryData(key, {
      owners: [],
      headquarters: [],
      deals: [updatedDeal],
    });
  }
}
