import Api from "lib/Api";
import { mutationFail, mutationSuccess } from "store/domain/domainActions";
import { receiveCollections } from "store/entities";
import { receiveView } from "store/views";
import { receivePortfolioProfile } from "store/portfolioProfile/actions";
import { find } from "lodash";

export function logWriteOff(formData, investmentId) {
  return (dispatch) => {
    return Api.postMutation(
      "createLogWriteOff",
      `v2/investments/${investmentId}/actions/write_offs`,
      {
        investment_write_off: formData,
      },
      (writeOff) => ({
        upserts: [{ entity: writeOff, into: "writeOffs" }],
      }),
    )
      .then(
        ({
          entities,
          views: { portfolio: views_portfolio, investment: views_investment },
        }) => {
          dispatch(mutationSuccess("createLogWriteOff"));
          dispatch(receiveCollections(entities));
          dispatch(receiveView("investment", views_investment));
          dispatch(receivePortfolioProfile(views_portfolio));
          dispatch(receiveView("portfolio", views_portfolio));

          return find(entities.investment_data, { id: investmentId });
        },
      )
      .catch((error) => {
        dispatch(mutationFail("createLogWriteOff"));
        throw error;
      });
  };
}
