import React, { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { fadeIn, animationFromLeft } from "lib/animations";
import LoginLayout from "components/auth/LoginLayout";
import TextInput from "components/shared/form/TextInput";
import PrimaryButton from "components/shared/PrimaryButton";
import ForgotConfirmation from "components/auth/forgotPassword/ForgotConfirmation";
import MightyLogo from "components_new/atoms/MightyLogo";
import verifyEmail from "lib/verifyEmail";
import classNames from "classnames";

function ForgotPasswordView({
  onSubmit,
  forgotPasswordSucceeded = false,
  forgotValidationErrors = {},
}) {
  const [email, setEmail] = useState("");
  const isEmailValid = useMemo(() => verifyEmail(email), [email]);
  const groupClassnames = classNames({
    LoginFormContainer: true,
  });

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      onSubmit(email);
    },
    [onSubmit, email],
  );

  return forgotPasswordSucceeded ? (
    <ForgotConfirmation />
  ) : (
    <LoginLayout extraClassnames="Login--forgotPassword">
      <div className="Login-holder">
        <div className="u-bg-primary LoginWelcome">
          <motion.div className="LoginLogoHolder">
            <MightyLogo size="large" theme="inverse" />
          </motion.div>
          <motion.h1
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            transition={{ duration: 0.05 }}
            className="u-heading"
          >
            Can’t remember your password? No Problem!
          </motion.h1>
          <motion.p
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            transition={{ duration: 0.15 }}
            className="LoginWelcome-message"
          >
            Please enter your email address and we’ll help you create a new one.
          </motion.p>
        </div>
        <div className="u-bg-tint-0 LoginForm">
          <div className="LoginForm-holder">
            <form className={groupClassnames} onSubmit={handleSubmit}>
              <motion.h2
                initial="hidden"
                animate="visible"
                variants={animationFromLeft()}
                transition={{ duration: 0.15 }}
                className="u-sectionHeading LoginTitle"
              >
                Forgot password?
              </motion.h2>
              <motion.p
                initial="hidden"
                animate="visible"
                variants={animationFromLeft({ delay: 0.1 })}
                transition={{ duration: 0.15 }}
                className="LoginForm-message"
              >
                That’s ok. We’ll send you instructions to set a new one
              </motion.p>
              <motion.div
                initial="hidden"
                animate="visible"
                variants={animationFromLeft({ delay: 0.3 })}
                transition={{ duration: 0.15 }}
              >
                <TextInput
                  typeOfInput="text"
                  placeholder="Email address"
                  inputProps={{ "aria-label": "Email address" }}
                  onChange={setEmail}
                  showErrors={!!forgotValidationErrors.email}
                  errorMessage={forgotValidationErrors.email}
                  autoFocus
                />
              </motion.div>
              <Link className="BackToLogin" to="/login">
                <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={animationFromLeft({ delay: 0.4 })}
                  transition={{ duration: 0.15 }}
                >
                  Back to login
                </motion.div>
              </Link>
              <PrimaryButton
                customClass="LoginAnimatedElement"
                disabled={!isEmailValid}
                type="submit"
              >
                <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={animationFromLeft({ delay: 0.4 })}
                  transition={{ duration: 0.15 }}
                  className="Button-text"
                >
                  Send Password Reset Instructions
                </motion.div>
              </PrimaryButton>
            </form>
          </div>
        </div>
      </div>
    </LoginLayout>
  );
}

export default ForgotPasswordView;
