import React, { Fragment } from "react";
import { connect } from "react-redux";
import { closeModal, updateModalOptions } from "store/modalV2/actions";
import { revokeUserOrganizationMembership } from "store/organizations/organizationMembers/actions";
import ConfirmationModal from "components/shared/ConfirmationModal";
import { flash } from "actions/synchronous/flash";

const createMessage = (organizationName) => {
  return (
    <Fragment>
      Reject the invitation to join the organization{" "}
      <strong> {organizationName} </strong>
    </Fragment>
  );
};

function mapStateToProps(state, ownProps) {
  const {
    organization_name: organizationName,
  } = ownProps.userOrganizationMembership;
  return {
    title: "Reject Invitation",
    message: createMessage(organizationName),
    confirmButtonLabel: "Reject Invitation",
    cancelButtonLabel: "Cancel",
    primaryButtonClassnames: "Button--warning",
    submitting: ownProps.isSubmitting || false,
  };
}

function updateModalProps(dispatch, props) {
  dispatch(updateModalOptions("leaveOrganization", props));
}

function dispatchToProps(dispatch, ownProps) {
  const { userOrganizationMembership } = ownProps;
  return {
    closeModal: () => dispatch(closeModal()),
    action: () => {
      updateModalProps(dispatch, {
        isSubmitting: true,
      });
      dispatch(
        revokeUserOrganizationMembership(userOrganizationMembership.id),
      ).then(() => {
        updateModalProps(dispatch, {
          isSubmitting: false,
        });
      });
      dispatch(closeModal());
      dispatch(
        flash("rejectInvitation", {
          organizationName: userOrganizationMembership.organization_name,
        }),
      );
    },
  };
}

export default connect(mapStateToProps, dispatchToProps)(ConfirmationModal);
