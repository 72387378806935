import Api from "lib/Api";
import {
  upsertResource,
  removeResource,
  receiveCollection,
} from "store/resourcesV2";

export function fetchCustomFields() {
  return (dispatch) => {
    return Api.getCollection("customFields", `/custom_fields`).then(
      (customFields) => {
        dispatch(receiveCollection("customFields", customFields));
      },
    );
  };
}

export function deleteCustomField(customFieldId) {
  return (dispatch) => {
    return Api.deleteMutation(
      "removeCustomField",
      `/custom_fields/${customFieldId}`,
      () => ({
        destructions: [{ from: "customFields", id: customFieldId }],
      }),
    ).then(() => {
      dispatch(removeResource("customFields", customFieldId));
    });
  };
}

export function createCustomField(formData) {
  return (dispatch) => {
    const custom_field = {
      ...formData,
    };

    return Api.postMutation(
      "createCustomField",
      "/custom_fields",
      {
        custom_field,
      },
      (customField) => ({
        upserts: [{ entity: customField, into: "customFields" }],
      }),
    ).then((customField) => {
      dispatch(upsertResource("customFields", customField));
    });
  };
}

export function editCustomField(formData, customFieldId) {
  return (dispatch) => {
    const custom_field = {
      ...formData,
    };

    return Api.patchMutation(
      "updateCustomField",
      `/custom_fields/${customFieldId}`,
      {
        custom_field,
      },
      (customField) => ({
        upserts: [{ entity: customField, into: "customFields" }],
      }),
    ).then((customField) => {
      dispatch(upsertResource("customFields", customField));
    });
  };
}
