import React, { useState, useEffect } from "react";
import FormFooter from "components_new/SidebarComponents/SidebarForm/FormFooter";
import HoldingMiniCard from "components/shared/HoldingMiniCard";
import useForm from "lib/hooks/useForm";
import "./StockSplitForm.scss";
import StockSplitDetails from "components_new/SidebarComponents/forms/groups/StockSplitDetails";
import RequiredNote from "components_new/SidebarComponents/shared/RequiredNote";
import { isEmpty } from "lodash";
import Documents from "components_new/SidebarComponents/forms/groups/Documents";
import Notes from "components_new/SidebarComponents/forms/groups/Notes";
import PropTypes from "prop-types";
import { investmentDataPropType, companyAvatarPropType } from "PropTypes";
import { isUploading } from "components_new/elements/DropZone/config";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import getMessage from "components_new/lib/Messages";

const INITIAL_VALUES = {
  formData: {
    date: null,
    split_ratio_from: null,
    split_ratio_to: null,
    notes: "",
    documents_data: [],
  },
};

function StockSplitForm({
  ownName,
  investment,
  company,
  createStockSplit,
  closeSidebar,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [uploadQueue, setUploadQueue] = useState([]);
  const { addToast } = useSetToast();

  const onSubmit = (clientData) => {
    setIsLoading(true);
    createStockSplit({ ...clientData, investmentId: investment.id })
      .then(() => {
        addToast(getMessage("addedStockSplit"));
        closeSidebar();
      })
      .catch((error) => {
        handleErrors(error);
        handleScrollView(error);
      })
      .finally(() => setIsLoading(false));
  };

  const {
    formData,
    handleChange,
    handleSubmit,
    handleErrors,
    handleScrollView,
    errors,
    touched,
  } = useForm(INITIAL_VALUES, onSubmit);

  const {
    date,
    split_ratio_from,
    split_ratio_to,
    documents_data,
    notes,
  } = formData;

  useEffect(() => {
    setDisableSubmit(
      isEmpty(date) ||
        split_ratio_from === "" ||
        split_ratio_from === null ||
        split_ratio_to === "" ||
        split_ratio_to === null ||
        isUploading(uploadQueue),
    );
  }, [date, split_ratio_from, split_ratio_to, uploadQueue]);

  return (
    <div className="StockSplitForm">
      <div className="HoldingGroup">
        <HoldingMiniCard
          investment={investment}
          company={company}
          className="FormElement"
        />
      </div>

      <StockSplitDetails
        formData={formData}
        onChange={handleChange}
        errors={errors}
        investment={investment}
      />

      <Documents
        onChange={(documents) => handleChange("documents_data", documents)}
        documents={documents_data}
        onQueueChange={setUploadQueue}
      />
      <Notes
        name="notes"
        notes={notes}
        onChange={(notes) => handleChange("notes", notes)}
      />
      <RequiredNote />
      <FormFooter
        componentName={ownName}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        disableSubmit={disableSubmit}
        touched={touched}
      />
    </div>
  );
}

StockSplitForm.propTypes = {
  ownName: PropTypes.string.isRequired,
  investment: investmentDataPropType.isRequired,
  company: companyAvatarPropType.isRequired,
  createStockSplit: PropTypes.func.isRequired,
  closeSidebar: PropTypes.func.isRequired,
};

export default StockSplitForm;
