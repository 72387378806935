import React, { createContext, useState, useContext, useCallback } from "react";

const ToastContext = createContext();
const SetToastContext = createContext();

function ToastProvider({ children }) {
  const [state, setState] = useState([]);
  return (
    <ToastContext.Provider value={state}>
      <SetToastContext.Provider value={setState}>
        {children}
      </SetToastContext.Provider>
    </ToastContext.Provider>
  );
}

function useToastList() {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error("useToastList must be used within a ToastProvider");
  }
  return context;
}

function useSetToast() {
  const setState = useContext(SetToastContext);
  if (setState === undefined) {
    throw new Error("useSetToast must be used within a ToastProvider");
  }
  const addToast = useCallback(
    (notifications) => setState((prevState) => [...prevState, notifications]),
    [setState],
  );
  const removeToast = useCallback(
    (id) =>
      setState((prevState) => prevState.filter((state) => state.id !== id)),
    [setState],
  );
  return { addToast, removeToast };
}

export { ToastProvider, useToastList, useSetToast };
