import React from "react";
import formatPrice from "lib/formatters/formatPrice";
import formatMultiple from "components/shared/MultipleLabel/formatMultiple";
import IndicatePositiveOrNegativeValue from "components/shared/IndicatePositiveOrNegativeValue";
import formatDescription from "lib/formatDescription";
import formatPercentage from "lib/formatters/formatPercentage";
import {
  DefinitionList,
  DefinitionItem,
} from "components/shared/DefinitionList";

function FundSummary({ performance }) {
  return (
    <div className="FundSummary OverviewSection-block">
      <h5 className="OverviewSection-blockTitle">Performance</h5>
      <div className="OverviewSection-blockGroup">
        <DefinitionList>
          <DefinitionItem
            name="Commitment"
            extraClassnames="InvestmentOverviewSection--item"
            description={formatDescription(performance.totalAcquisitionAmount)}
            title={formatPrice(performance.costBasis)}
          />
          <DefinitionItem
            name="Paid-In Amount"
            extraClassnames="InvestmentOverviewSection--item"
            description={formatDescription(performance.capital_called)}
            title={formatPrice(performance.capital_called)}
          />
          <DefinitionItem
            name="Unfunded Amount"
            extraClassnames="InvestmentOverviewSection--item"
            description={formatDescription(performance.unfunded_amount)}
            title={formatPrice(performance.unfunded_amount)}
          />
          <DefinitionItem
            name="Paid-In Percent"
            extraClassnames="InvestmentOverviewSection--item"
            description={formatPercentage(performance.percent_called)}
            title={formatPercentage(performance.percent_called)}
          />
        </DefinitionList>
      </div>
      <div className="OverviewSection-blockGroup">
        <DefinitionList>
          <DefinitionItem
            name="Cost Basis"
            extraClassnames="InvestmentOverviewSection--item"
            description={formatDescription(performance.costBasis)}
            title={formatPrice(performance.costBasis)}
          />
          <DefinitionItem
            name="Fair Market Value"
            extraClassnames="InvestmentOverviewSection--item"
            description={formatDescription(performance.fairMarketValue)}
            title={formatPrice(performance.fairMarketValue)}
          />
          <DefinitionItem
            name="Unrealized Gain"
            extraClassnames="InvestmentOverviewSection--item"
            title={formatPrice(performance.unrealizedGain)}
            description={
              <IndicatePositiveOrNegativeValue
                value={performance.unrealizedGain}
              />
            }
          />
          <DefinitionItem
            name="Realized Gain"
            extraClassnames="InvestmentOverviewSection--item"
            description={formatDescription(performance.realizedGain)}
            title={formatPrice(performance.realizedGain)}
          />
          <DefinitionItem
            name="Total Gain"
            extraClassnames="InvestmentOverviewSection--item"
            title={formatPrice(performance.totalReturn)}
            description={
              <IndicatePositiveOrNegativeValue
                value={performance.totalReturn}
              />
            }
          />
        </DefinitionList>
      </div>
      <div className="OverviewSection-blockGroup">
        <DefinitionList>
          <DefinitionItem
            name="Return Multiple"
            extraClassnames="InvestmentOverviewSection--item OverviewSection-multiple"
            description={formatMultiple(performance.multiple, "alternate")}
          />
          <DefinitionItem
            name="TVPI Multiple"
            extraClassnames="InvestmentOverviewSection--item OverviewSection-multiple"
            description={formatMultiple(performance.tvpiMultiple, "alternate")}
          />
        </DefinitionList>
      </div>
    </div>
  );
}

export default FundSummary;
