import React, { useContext, useState, useEffect, useLayoutEffect } from "react";
import { SidebarComponentsContext } from "./SidebarComponentsWrapper";
import SidebarForm from "./SidebarForm";
import ViewHeader from "./SidebarView/ViewHeader";
import { makeSidebarComponent } from "./makeSidebarComponent";
import { isKind } from "./config";
import classnames from "classnames";
import { isEmpty } from "lodash";
import "./SidebarView/SidebarView.scss";

function SidebarComponent({ componentName }) {
  const { getComponentState } = useContext(SidebarComponentsContext);
  const { visible, sidebarPolicy, complexSidebar } = getComponentState(
    componentName,
  );

  const [sidebarComponent, setSidebarComponent] = useState(null);
  const [componentVisible, setComponentVisible] = useState(false);
  const [split, setSplit] = useState(false);

  useEffect(() => {
    if (!isEmpty(componentName)) {
      setSidebarComponent(makeSidebarComponent(componentName));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (componentVisible !== visible) {
      setComponentVisible(visible);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useLayoutEffect(() => {
    setSplit(
      sidebarPolicy && sidebarPolicy.pattern === "split" && complexSidebar,
    );
  }, [complexSidebar, sidebarPolicy]);

  const sidebarComponentClassnames = classnames("SidebarComponent", {
    SidebarForm: isKind(componentName, "form"),
    SidebarView: isKind(componentName, "view"),
    split: !!split,
    visible: !!componentVisible,
  });

  if (isKind(componentName, "form")) {
    return (
      <div className={sidebarComponentClassnames}>
        <SidebarForm componentName={componentName}>
          {sidebarComponent}
        </SidebarForm>
      </div>
    );
  }

  if (isKind(componentName, "view")) {
    return (
      <div className={sidebarComponentClassnames}>
        <ViewHeader componentName={componentName} />
        {sidebarComponent}
      </div>
    );
  }
}

export default SidebarComponent;
