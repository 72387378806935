import React from "react";
import EmptyPage from "components_new/elements/EmptyPage";

function EmptyView() {
  return (
    <EmptyPage
      headline={`You don't have any contacts`}
      subHeadline="You can add contacts while creating an investment or deal"
    />
  );
}

export default EmptyView;
