import React from "react";
import Avatar from "components_new/elements/Avatar";
import Icon from "components_new/atoms/Icon";
import CompanySummaryCard from "components_new/views/Company/shared/CompanyLeftSidebar/CompanySummaryCard";
import CompanyInvestmentPerformanceSidepane from "components_new/views/Company/shared/CompanyLeftSidebar/CompanyInvestmentPerformanceSidepane";
import "./CompanyLeftSidebar.scss";

const CompanyLeftSideBar = ({
  companyDetails,
  companyAvatars,
  companyHoldingTotals,
  investments,
  displayFixedContent,
  onEditClick,
}) => {
  return (
    <div className="CompanyLeftSidebar">
      <div
        className="StickySummary"
        style={{
          transform: displayFixedContent
            ? "translateY(0)"
            : "translateY(-60px)",
        }}
      >
        <div className="ShadowMask">
          <div />
        </div>
        <div className="AvatarWrapper">
          <Avatar
            title={companyDetails.name}
            imageUrl={
              (companyDetails && companyDetails.logo_url) ||
              (companyAvatars && Object.values(companyAvatars)[0].logo_url)
            }
            label={companyDetails && companyDetails.status}
            attributes={
              companyDetails.tier
                ? [
                    {
                      label: `tier ${companyDetails.tier}`,
                      labelType: "generic",
                    },
                  ]
                : null
            }
            labelColor="generic"
            width={30}
            height={30}
          />
          <div className="CircularButton">
            <Icon
              extraClassnames="ToggleEdit"
              icon="ri-pencil-line"
              alt="Edit"
            />
          </div>
        </div>
      </div>

      <CompanySummaryCard
        companyAvatars={companyAvatars}
        companyDetails={companyDetails}
        companyHoldingTotals={companyHoldingTotals}
        investments={investments}
        companyEditFunc={onEditClick}
      />
      <hr className="full-width" />
      <CompanyInvestmentPerformanceSidepane />
    </div>
  );
};

export default CompanyLeftSideBar;
