import Api from "lib/Api";

import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { upsertResource } from "store/resourcesV2/actions";
import { useParams } from "react-router-dom";
import { uniqBy } from "lodash";

export function useCreateDeal() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { organizationUsername } = useParams();

  return useMutation(
    ({ formData, pipelineId }) => {
      const payload = {
        ...formData,
        documents_data: Object.values(formData.documents_data || {}),
      };

      return Api.post(`/pipelines/${pipelineId}/deals`, {
        deal: payload,
      });
    },
    {
      onSuccess: ({ data }, { pipelineId }) => {
        updateActiveDealsView(queryClient, pipelineId, data);
        updateCompanyView(queryClient, organizationUsername, data);

        queryClient.invalidateQueries(["Contacts"]);
        queryClient.invalidateQueries(["Pipeline"]);
        queryClient.invalidateQueries(["SearchDeals", pipelineId]);
        // TODO: Remove this integration with redux when we migrated organization
        // into React Query.
        dispatch(upsertResource("organizations", data.organization));
      },
      onError: (error) => {
        throw error;
      },
    },
  );
}

function updateActiveDealsView(queryClient, pipelineId, data) {
  const key = ["ActiveDeals", pipelineId, {}];
  const activeDealsView = queryClient.getQueryData(key);

  if (activeDealsView?.deals) {
    const newDeal = {
      ...data.deal,
      company: data.company,
    };

    queryClient.setQueryData(key, {
      ...activeDealsView,
      deals: [...activeDealsView.deals, newDeal],
    });
  }
}

function updateCompanyView(queryClient, organizationUsername, data) {
  const { deal: createdDeal, contacts: createdContacts } = data;
  const key = ["Company", organizationUsername, createdDeal.company_id];
  const companyView = queryClient.getQueryData(key);

  if (companyView?.companyDeals) {
    const referrers = [...companyView.referrers, ...createdContacts];
    const uniqueReferrer = uniqBy(referrers, "id");
    queryClient.setQueryData(key, {
      ...companyView,
      companyDeals: [...companyView.companyDeals, createdDeal],
      referrers: uniqueReferrer,
    });
  }
}
