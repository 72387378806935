import Api from "lib/Api";
import {
  findOrCreateCompany,
  isInCurrentView,
} from "store/investments/investment/actions";
import { buildPostResourceRoute } from "lib/globalActionsHelper";
import {
  mutationStart,
  mutationFail,
  mutationSuccess,
  itemFetchStart,
  itemFetchSuccess,
  itemFetchFail,
  collectionFetchStart,
  collectionFetchSuccess,
  collectionFetchFail,
} from "store/domain/domainActions";
import { OVERRIDE_COLLECTION } from "store/domain/domainActionTypes";
import { receiveCollections, receiveCollection } from "store/entities";
import { setResourceAvailability } from "store/resources/actions";
import { receiveView, updateView, removeFromView } from "store/views";
import { fetchPipeline } from "store/pipelines/actions";
import { fetchCompaniesV2 } from "store/companies/actions";
import { isEmpty } from "lodash";

export function fetchDeal(pipelineId, dealId) {
  return (dispatch) => {
    dispatch(itemFetchStart("deals", dealId));
    return Api.get(`/pipelines/${pipelineId}/deals/${dealId}`)
      .then(
        ({
          data: {
            entities,
            views: { deal: view },
          },
        }) => {
          const [deal] = entities.deals;

          dispatch(receiveCollections(entities));
          dispatch(receiveView("deal", view));

          dispatch(itemFetchSuccess("deals", dealId, deal));
          return deal;
        },
      )
      .catch((error) => {
        dispatch(setResourceAvailability(false));
        dispatch(itemFetchFail("deals", dealId));
        throw error;
      });
  };
}

export function createDeal(formData, pipelineId) {
  return (dispatch, getState) => {
    const payload = {
      ...formData,
      documents_data: Object.values(formData.documents_data),
    };
    dispatch(mutationStart("createDeal"));
    return Api.post(`/pipelines/${pipelineId}/deals`, {
      deal: payload,
    })
      .then(({ data: { entities } }) => {
        const [createdDeal] = entities.deals;
        const [organization] = entities.organization;
        const { contacts } = entities;
        const {
          views: { company: companyView },
        } = getState();

        if (isInCurrentView(companyView, createdDeal.company_id)) {
          contacts.forEach((contact) => {
            dispatch(updateView("company", "contacts_ids", contact.id));
            dispatch(updateView("company", "deals_contact_ids", contact.id));
          });
          dispatch(receiveCollections({ contacts }));
          dispatch(updateView("company", "deal_ids", createdDeal.id));
        }

        dispatch(mutationSuccess("createDeal"));
        dispatch(receiveCollections(entities));
        dispatch(
          itemFetchSuccess("organizations", organization.id, organization),
        );
        dispatch(fetchCompaniesV2());
        // TODO: Remove this fetching when we have a way to make updates, destructions and upserts inside a view (v2)
        dispatch(fetchPipeline(pipelineId));
        return createdDeal;
      })
      .catch((error) => {
        dispatch(mutationFail("createDeal"));
        throw error;
      });
  };
}

export function updateStage(dealId, stageId, pipelineId) {
  return (dispatch) => {
    dispatch(mutationStart("updateStage"));
    const payload = { stage_id: stageId };

    return Api.patchMutation(
      "updateDeal",
      `/pipelines/${pipelineId}/deals/${dealId}`,
      { deal: payload },
      ({ entities: { deals: updatedDeals } }) => {
        dispatch(receiveCollection("deals", updatedDeals));
        return { upserts: [{ entity: updatedDeals, into: "deals" }] };
      },
    ).then(() => {
      dispatch(mutationSuccess("updateStage"));
    });
  };
}

export function updateDeal(dealId, formData, pipelineId) {
  return (dispatch, getState) => {
    const { isNewCompany, companyName, company_id } = formData;
    const companyStatusPromise = isNewCompany
      ? findOrCreateCompany(dispatch, getState, companyName)
      : Promise.resolve(company_id);

    dispatch(mutationStart("updateDeal"));
    return companyStatusPromise.then((companyId) => {
      const payload = {
        ...formData,
        company_id: companyId,
        documents_data: Object.values(formData.documents_data),
      };

      return Api.patch(`/pipelines/${pipelineId}/deals/${dealId}`, {
        deal: payload,
      }).then(
        ({
          data: {
            entities,
            views: { deal: view },
          },
        }) => {
          const {
            views: { deal: dealView },
          } = getState();

          const { contacts } = entities;
          contacts.forEach((contact) =>
            dispatch(updateView("company", "deals_contact_ids", contact.id)),
          );
          dispatch(receiveCollections({ contacts }));

          dispatch(receiveCollections(entities));
          if (isInCurrentView(dealView, dealId)) {
            dispatch(receiveView("deal", view));
          }

          dispatch(fetchDealDocuments(pipelineId, dealId));
          dispatch(mutationSuccess("updateDeal"));

          return view;
        },
      );
    });
  };
}

export function fetchDeals(pipelineId) {
  return () => {
    return Api.getCollection("deals", `/pipelines/${pipelineId}/deals`);
  };
}

export function removeDeal(dealId, pipelineId) {
  return (dispatch) => {
    dispatch(mutationStart("removeDeal"));

    return Api.delete(`/pipelines/${pipelineId}/deals/${dealId}`).then(() => {
      dispatch(removeFromView("company", "deal_ids", dealId));
      dispatch(mutationSuccess("removeDeal"));
    });
  };
}

export function updateDealSimply(dealId, formData, pipelineId) {
  return (dispatch) => {
    return Api.patchMutation(
      "updateDeal",
      `/pipelines/${pipelineId}/deals/${dealId}`,
      { deal: formData },
      ({ entities: { deals: updatedDeals } }) => {
        dispatch(receiveCollection("deals", updatedDeals));
        return { upserts: [{ entity: updatedDeals, into: "deals" }] };
      },
    );
  };
}

export function fetchDealDocuments(pipelineId, dealId) {
  return (dispatch) => {
    dispatch(collectionFetchStart("documents"));
    return Api.get(`/pipelines/${pipelineId}/deals/${dealId}/documents`).then(
      ({ data: documents }) => {
        dispatch(collectionFetchSuccess("documents", documents));
        return documents;
      },
    );
  };
}

export function saveDealDocuments(pipelineId, dealId, filepickerData) {
  return (dispatch, getState) => {
    const payload = {
      documents: {
        documentsData: filepickerData,
      },
    };
    const {
      views: { deal: dealView },
    } = getState();

    return Api.postMutation(
      "createDocuments",
      `/pipelines/${pipelineId}/deals/${dealId}/documents`,
      payload,
      (documents) => {
        const upserts = isInCurrentView(dealView, dealId)
          ? documents.map((document) =>
              addResourceToDealView(
                "dealDocuments",
                "deal_documents_ids",
                document,
                document.id,
              ),
            )
          : [];

        upserts.forEach((upsertAction) => dispatch(upsertAction));
      },
    );
  };
}

export function addResourceToDealView(
  resourceName,
  resourceIdsName,
  resource,
  resourceId,
) {
  return (dispatch) => {
    dispatch(receiveCollection(resourceName, [resource]));
    dispatch(updateView("deal", resourceIdsName, resourceId));
  };
}

export function bulkDocumentsUpdate(pipelineId, dealId, payload) {
  return (dispatch) => {
    return Api.patch(`/pipelines/${pipelineId}/deals/${dealId}/documents`, {
      operations: payload,
    }).then(({ data }) => {
      if (data.success) {
        const { results } = data;
        const { destructions, upserts } = buildChangeSetsFromResults(results);

        destructions.forEach((destructionAction) =>
          dispatch(destructionAction),
        );
        upserts.forEach((upsertAction) => dispatch(upsertAction));
      }

      return data;
    });
  };
}

function buildChangeSetsFromResults(results) {
  const destructions = [];
  const upserts = [];

  results.forEach((result) => {
    if (result.type === "destroy" && result.success) {
      destructions.push(
        removeFromView("deal", "deal_documents_ids", result.resource.id),
      );
    }

    if (result.type === "update" && result.success) {
      upserts.push(
        addResourceToDealView(
          "dealDocuments",
          "deal_documents_ids",
          result.resource,
          result.resource.id,
        ),
      );
    }
  });

  return { destructions, upserts };
}

export function commitToDeal(dealId, formData, pipelineId) {
  return (dispatch) => {
    return Api.patchMutation(
      "updateDeal",
      `/pipelines/${pipelineId}/deals/${dealId}/commit`,
      { deal: formData },
      (updatedDeal) => {
        dispatch(receiveCollection("deals", [updatedDeal]));
        return { upserts: [{ entity: updatedDeal, into: "deals" }] };
      },
    );
  };
}

export function passFromDeal(dealId, formData, pipelineId) {
  return (dispatch) => {
    return Api.patchMutation(
      "updateDeal",
      `/pipelines/${pipelineId}/deals/${dealId}/pass`,
      { deal: formData },
      (updatedDeal) => {
        dispatch(receiveCollection("deals", [updatedDeal]));
        return { upserts: [{ entity: updatedDeal, into: "deals" }] };
      },
    );
  };
}

export function reactivateDeal(dealId, pipelineId) {
  return (dispatch) => {
    return Api.patchMutation(
      "reactivateDeal",
      `/pipelines/${pipelineId}/deals/${dealId}/reactivate`,
      {},
      (updatedDeal) => {
        dispatch(receiveCollection("deals", [updatedDeal]));
        return { upserts: [{ entity: updatedDeal, into: "deals" }] };
      },
    );
  };
}

export function fetchDealPosts(pipelineId, dealId) {
  return () => {
    return Api.getCollection(
      "dealPosts",
      `/pipelines/${pipelineId}/deals/${dealId}/posts`,
    );
  };
}

export function createDealPost(resource, formData) {
  return (dispatch) => {
    const postResourceRoute = buildPostResourceRoute(resource);

    return Api.postMutation(
      "createDealPost",
      `${postResourceRoute}/posts`,
      { post: formData },
      (dealPost) =>
        dispatch(
          addResourceToDealView(
            "dealPosts",
            "deal_posts_ids",
            dealPost,
            dealPost.id,
          ),
        ),
    );
  };
}

export function updateDealPost(resource, postId, formData) {
  return (dispatch) => {
    const postResourceRoute = buildPostResourceRoute(resource);

    return Api.patchMutation(
      "updateDealPost",
      `${postResourceRoute}/posts/${postId}`,
      { post: formData },
      (dealPost) =>
        dispatch(
          addResourceToDealView(
            "dealPosts",
            "deal_posts_ids",
            dealPost,
            dealPost.id,
          ),
        ),
    );
  };
}

export function removeDealPost(resource, postId) {
  return (dispatch) => {
    const postResourceRoute = buildPostResourceRoute(resource);

    return Api.deleteMutation(
      "removeDealPost",
      `${postResourceRoute}/posts/${postId}`,
      () => {
        dispatch(removeFromView("deal", "deal_posts_ids", postId));
      },
    );
  };
}

export function updateDealsOnDrop(pipelineId, deal) {
  return (dispatch) => {
    return Api.patch(
      `/pipelines/${pipelineId}/deals/${deal.id}/bulk_update_on_drop`,
      {
        deal,
      },
    ).then(({ data }) => {
      dispatch(receiveCollection("deals", data));
      dispatch({
        type: OVERRIDE_COLLECTION,
        collectionName: "deals",
        entities: data,
      });
    });
  };
}

export function fetchDealsV2(pipelineId, filterParams, scope) {
  const params = isEmpty(filterParams) ? {} : filterParams.filters;

  return (dispatch) => {
    dispatch(collectionFetchStart("deals"));
    return Api.get(`/v2/pipelines/${pipelineId}/deals/${scope}`, {
      params: { ...params },
    })
      .then(
        ({
          data: {
            entities,
            views: { deals: view },
          },
        }) => {
          dispatch(receiveCollections(entities));
          dispatch(receiveView("deals", view));

          dispatch(collectionFetchSuccess("deals", entities.deals));
        },
      )
      .catch((_) => dispatch(collectionFetchFail("deals")));
  };
}
