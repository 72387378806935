import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getCollectionAsObject } from "store/entities";
import { getCollection } from "store/entities/selectors";
import { getCollection as getDomainCollection } from "store/domain/selectors";
import {
  fetchContacts as fetchContactsHelper,
  deleteContact as deleteContactHelper,
  updateContact as updateContactHelper,
  validateContact as validateContactHelper,
} from "./actions";
import { filterTag } from "store/tags/actions";
import { filterIndustry } from "store/industries/actions";
import { filterCompanyTitle } from "store/companyTitles/actions";
import { debounce, isEqual } from "lodash";
import { flash } from "actions/synchronous/flash";

export default () => {
  const dispatch = useDispatch();

  // TODO: should all of these be defined elsewhere & imported for re-use?
  const view = useSelector((state) => state.views.contacts, shallowEqual);
  const contacts = useSelector(
    (state) => getCollection(state, "contacts", view.ids),
    isEqual,
  );

  const companyAvatars = useSelector(
    (state) =>
      getCollectionAsObject(state, "companyAvatars", view.company_avatar_ids),
    isEqual,
  );

  const fetchContacts = ({ filters } = {}) =>
    dispatch(fetchContactsHelper({ filters }));

  const filterTags = debounce((tagName) => {
    return dispatch(filterTag(tagName));
  }, 300);

  const tagSuggestions = useSelector((state) =>
    getDomainCollection(state, "tagSuggestions"),
  );

  const filterIndustries = debounce((industryName) => {
    return dispatch(filterIndustry(industryName));
  });

  const industrySuggestions = useSelector((state) => {
    const industries = getDomainCollection(state, "industries");
    return industries;
  });

  const filterTitles = debounce((companyTitle) => {
    return dispatch(filterCompanyTitle(companyTitle));
  }, 300);

  const titleSuggestions = useSelector((state) =>
    getDomainCollection(state, "companyTitleSuggestions"),
  );

  const validateContact = (contact) => dispatch(validateContactHelper(contact));

  const updateContact = (contactId, formData) =>
    dispatch(updateContactHelper(contactId, formData)).then(() => {
      dispatch(flash("updateReferrer"));
    });

  const deleteReferrerFlashMessage = (fullname) =>
    dispatch(flash("deleteReferrer", { fullname }));

  const deleteContact = (contactId) => dispatch(deleteContactHelper(contactId));

  return {
    contactsView: view,
    contacts,
    companyAvatars,
    fetchContacts,
    filterTags,
    tagSuggestions,
    filterIndustries,
    industrySuggestions,
    filterTitles,
    titleSuggestions,
    updateContact,
    deleteContact,
    deleteReferrerFlashMessage,
    validateContact,
  };
};
