import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  investmentPropType,
  distributionPropType,
  errorsPropType,
} from "PropTypes";
import TableColumns from "components/shared/Table/TableColumns";

function DistributionRowTemplate({ index, data, columns, updateForm, errors }) {
  return (
    <TableColumns
      columns={columns}
      renderColumn={({ renderValue, renderForm }) => (
        <Fragment>
          <div className="PreviewRow">{renderValue(data)}</div>
          <div className="EditRow">
            {renderForm(data, updateForm, index, errors)}
          </div>
        </Fragment>
      )}
    />
  );
}

DistributionRowTemplate.propTypes = {
  index: PropTypes.number,
  updateForm: PropTypes.func,
  data: distributionPropType,
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  investment: investmentPropType,
  errors: errorsPropType,
};

export default DistributionRowTemplate;
