import React from "react";
import CompanySm from "-!svg-react-loader?!assets/icons/MightyIcons/company-sm.svg";
import CompanyMed from "-!svg-react-loader?!assets/icons/MightyIcons/company-med.svg";
import CompanyLrg from "-!svg-react-loader?!assets/icons/MightyIcons/company-lrg.svg";
import CompanyXlrg from "-!svg-react-loader?!assets/icons/MightyIcons/company-xlrg.svg";
import ContactSm from "-!svg-react-loader?!assets/icons/MightyIcons/contact-sm.svg";
import ContactMed from "-!svg-react-loader?!assets/icons/MightyIcons/contact-med.svg";
import ContactLrg from "-!svg-react-loader?!assets/icons/MightyIcons/contact-lrg.svg";
import ContactXlrg from "-!svg-react-loader?!assets/icons/MightyIcons/contact-xlrg.svg";
import DashboardSm from "-!svg-react-loader?!assets/icons/MightyIcons/dashboard-sm.svg";
import DashboardMed from "-!svg-react-loader?!assets/icons/MightyIcons/dashboard-med.svg";
import DashboardLrg from "-!svg-react-loader?!assets/icons/MightyIcons/dashboard-lrg.svg";
import DashboardXlrg from "-!svg-react-loader?!assets/icons/MightyIcons/dashboard-xlrg.svg";
import DealSm from "-!svg-react-loader?!assets/icons/MightyIcons/deal-sm.svg";
import DealMed from "-!svg-react-loader?!assets/icons/MightyIcons/deal-med.svg";
import DealLrg from "-!svg-react-loader?!assets/icons/MightyIcons/deal-lrg.svg";
import DealXlrg from "-!svg-react-loader?!assets/icons/MightyIcons/deal-xlrg.svg";
import InvestmentSm from "-!svg-react-loader?!assets/icons/MightyIcons/investment-sm.svg";
import InvestmentMed from "-!svg-react-loader?!assets/icons/MightyIcons/investment-med.svg";
import InvestmentLrg from "-!svg-react-loader?!assets/icons/MightyIcons/investment-lrg.svg";
import InvestmentXlrg from "-!svg-react-loader?!assets/icons/MightyIcons/investment-xlrg.svg";
import NoteSm from "-!svg-react-loader?!assets/icons/MightyIcons/note-sm.svg";
import NoteMed from "-!svg-react-loader?!assets/icons/MightyIcons/note-med.svg";
import NoteLrg from "-!svg-react-loader?!assets/icons/MightyIcons/note-lrg.svg";
import NoteXlrg from "-!svg-react-loader?!assets/icons/MightyIcons/note-xlrg.svg";
import PortfolioSm from "-!svg-react-loader?!assets/icons/MightyIcons/portfolio-sm.svg";
import PortfolioMed from "-!svg-react-loader?!assets/icons/MightyIcons/portfolio-med.svg";
import PortfolioLrg from "-!svg-react-loader?!assets/icons/MightyIcons/portfolio-lrg.svg";
import PortfolioXlrg from "-!svg-react-loader?!assets/icons/MightyIcons/portfolio-xlrg.svg";
import QueueSm from "-!svg-react-loader?!assets/icons/MightyIcons/queue-sm.svg";
import QueueMed from "-!svg-react-loader?!assets/icons/MightyIcons/queue-med.svg";
import QueueLrg from "-!svg-react-loader?!assets/icons/MightyIcons/queue-lrg.svg";
import QueueXlrg from "-!svg-react-loader?!assets/icons/MightyIcons/queue-xlrg.svg";
import ReportSm from "-!svg-react-loader?!assets/icons/MightyIcons/report-sm.svg";
import ReportMed from "-!svg-react-loader?!assets/icons/MightyIcons/report-med.svg";
import ReportLrg from "-!svg-react-loader?!assets/icons/MightyIcons/report-lrg.svg";
import ReportXlrg from "-!svg-react-loader?!assets/icons/MightyIcons/report-xlrg.svg";
import Icon from "components_new/atoms/Icon";
import PropTypes from "prop-types";

function MightyIcon({ icon, className }) {
  const icons = {
    "company-sm": CompanySm,
    "company-med": CompanyMed,
    "company-lrg": CompanyLrg,
    "company-xlrg": CompanyXlrg,
    "contact-sm": ContactSm,
    "contact-med": ContactMed,
    "contact-lrg": ContactLrg,
    "contact-xlrg": ContactXlrg,
    "dashboard-sm": DashboardSm,
    "dashboard-med": DashboardMed,
    "dashboard-lrg": DashboardLrg,
    "dashboard-xlrg": DashboardXlrg,
    "deal-sm": DealSm,
    "deal-med": DealMed,
    "deal-lrg": DealLrg,
    "deal-xlrg": DealXlrg,
    "investment-sm": InvestmentSm,
    "investment-med": InvestmentMed,
    "investment-lrg": InvestmentLrg,
    "investment-xlrg": InvestmentXlrg,
    "note-sm": NoteSm,
    "note-med": NoteMed,
    "note-lrg": NoteLrg,
    "note-xlrg": NoteXlrg,
    "portfolio-sm": PortfolioSm,
    "portfolio-med": PortfolioMed,
    "portfolio-lrg": PortfolioLrg,
    "portfolio-xlrg": PortfolioXlrg,
    "queue-sm": QueueSm,
    "queue-med": QueueMed,
    "queue-lrg": QueueLrg,
    "queue-xlrg": QueueXlrg,
    "report-sm": ReportSm,
    "report-med": ReportMed,
    "report-lrg": ReportLrg,
    "report-xlrg": ReportXlrg,
  };
  return <Icon as={icons[icon]} className={className} />;
}

MightyIcon.propType = {
  icon: PropTypes.oneOf([
    "company-sm",
    "company-med",
    "company-lrg",
    "company-xlrg",
    "contact-sm",
    "contact-med",
    "contact-lrg",
    "contact-xlrg",
    "dashboard-sm",
    "dashboard-med",
    "dashboard-lrg",
    "dashboard-xlrg",
    "deal-sm",
    "deal-med",
    "deal-lrg",
    "deal-xlrg",
    "investment-sm",
    "investment-med",
    "investment-lrg",
    "investment-xlrg",
    "note-sm",
    "note-med",
    "note-lrg",
    "note-xlrg",
    "portfolio-sm",
    "portfolio-med",
    "portfolio-lrg",
    "portfolio-xlrg",
    "queue-sm",
    "queue-med",
    "queue-lrg",
    "queue-xlrg",
    "report-sm",
    "report-med",
    "report-lrg",
    "report-xlrg",
  ]).isRequired,
};

export default MightyIcon;
