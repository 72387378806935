import { useQuery } from "react-query";
import useOrganizations from "store/organizations/hooks";
import Api from "lib/Api";

export function useSnapshots() {
  const { currentOrganizationName } = useOrganizations();
  return useQuery(["SnapshotsView", currentOrganizationName], async () => {
    const { data: snapshots } = await Api.get(`/snapshots`);

    return {
      snapshots,
    };
  });
}
