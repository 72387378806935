import Api from "lib/Api";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import updatePost from "lib/posts/updatePost";

export function useEditNote() {
  const { organizationUsername } = useParams();
  const queryClient = useQueryClient();

  return useMutation(
    (formData) => {
      return Api.patch(`/v2/notes/${formData.id}`, {
        post: formData,
      });
    },
    {
      onSuccess: ({ data: newNote }) => {
        const params = {
          queryClient,
          organizationUsername,
          newNote,
        };

        updatePostInDealView(params);
        updatePostInCompanyView(params);
      },
      onError: (error) => {
        throw error;
      },
    },
  );
}

function updatePostInDealView({ queryClient, newNote }) {
  const { resource } = newNote;
  if (resource.type.toLowerCase() === "deal") {
    const key = ["Deal", resource.deal_id];
    const dealView = queryClient.getQueryData(key);
    if (dealView) {
      queryClient.setQueryData(key, {
        ...dealView,
        posts: updatePost(dealView.posts, newNote),
      });
    }
  }
}

function updatePostInCompanyView({
  queryClient,
  organizationUsername,
  newNote,
}) {
  const { resource } = newNote;
  if (resource.type.toLowerCase() === "company") {
    const companyId = resource.company_id;
    const key = ["Company", organizationUsername, companyId];
    const companyView = queryClient.getQueryData(key);
    if (companyView) {
      queryClient.setQueryData(key, {
        ...companyView,
        companyPosts: updatePost(companyView.companyPosts, newNote),
      });
    }
  }
}
