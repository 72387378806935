export const initialValues = {
  formData: {
    id: "",
    name: "",
    headquarters: "",
    description: "",
    acquisitions: [],
    aliases: [],
    current_pps: null,
    employees_range: "",
    founders: "",
    fully_diluted_shares: "",
    linkedin_handle: "",
    notes: "",
    status: "",
    status_change_company_id: "",
    status_change_date: "",
    twitter_handle: "",
    valuation: "",
    website: "",
    year_founded: "",
    companyDocumentIds: [],
    interesting: false,
    double_down: false,
    stealth: false,
    is_yc: false,
    interesting_reason: "",
    yc_class: "",
    activity_status: "",
    tier: "",
    firm_type: [],
    investment_domain: [],
    service_domain: [],
    type: "company",
  },
};
