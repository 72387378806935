import React from "react";
import Toaster from "components_new/elements/Toaster";
import Alert from "components_new/elements/Alert";

function Notifications() {
  return (
    <Toaster>
      {({ notification, removeToast }) => (
        <Alert
          id={notification.id}
          type={notification.type}
          title={notification.title}
          subTitle={notification.subTitle}
          url={notification.url}
          urlTitle={notification.urlTitle}
          onClose={() => removeToast(notification.id)}
          preserve={notification.preserve}
        />
      )}
    </Toaster>
  );
}

export default Notifications;
