import React from "react";
import TaskForm from "./TaskForm";
import { useDispatch } from "react-redux";
import { openModal } from "store/modalV2";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import getMessage from "components_new/lib/Messages";
import useOrganizations from "store/organizations/hooks";
import useCurrentUser from "hooks/useCurrentUser";

import { useCreateTask } from "components_new/views/Queues/hooks/mutations/useCreateTask";
import { useUpdateTask } from "components_new/views/Queues/hooks/mutations/useUpdateTask";
import { isEmpty } from "lodash";

function TaskFormWrapper({
  task,
  prefilledSelection,
  prefilledOwner,
  closeSidebar,
  toogleSize,
}) {
  const dispatch = useDispatch();
  const { addToast } = useSetToast();
  const { organizationMemberships } = useOrganizations();
  const { id: currentUserID } = useCurrentUser();

  const { mutateAsync: createTask } = useCreateTask();
  const { mutateAsync: updateTask } = useUpdateTask();

  const isEditing = !isEmpty(task);

  const openRemoveTaskModal = ({ taskId }) =>
    dispatch(
      openModal("confirmTaskDeletion", {
        taskId,
        callback: closeSidebar,
      }),
    );

  const showConfirmationMessage = isEditing
    ? () => addToast(getMessage("editedTask"))
    : () => addToast(getMessage("addedTask"));

  const handleCreateTask = (formData) => {
    return createTask({ formData });
  };

  const handleUpdateTask = (formData) => {
    return updateTask({ taskId: formData.id, formData });
  };

  const submitAction = isEditing ? handleUpdateTask : handleCreateTask;

  return (
    <TaskForm
      task={task}
      isEditing={isEditing}
      prefilledSelection={prefilledSelection}
      prefilledOwner={prefilledOwner}
      organizationMemberships={organizationMemberships}
      currentUserID={currentUserID}
      showConfirmationMessage={showConfirmationMessage}
      submitAction={submitAction}
      closeSidebar={closeSidebar}
      openRemoveTaskModal={openRemoveTaskModal}
      toogleSize={toogleSize}
    />
  );
}

export default TaskFormWrapper;
