import React, { useContext } from "react";
import { SidebarComponentsContext } from "../SidebarComponentsWrapper";
import ScrollView from "components_new/elements/ScrollView";
import Icon from "components_new/atoms/Icon";
import Header from "components_new/atoms/Header";
import PropTypes from "prop-types";
import classNames from "classnames";
import { isEmpty } from "lodash";

function FormHeader({ componentName }) {
  const {
    getComponentState,
    getFormState,
    setWarningMessage,
    onUnmount,
  } = useContext(SidebarComponentsContext);

  const { title, submitTo, index } = getComponentState(componentName);
  const { isEditing } = getFormState(componentName);

  const headerTitle = !isEmpty(submitTo)
    ? `Back to ${getComponentState(submitTo).title}`
    : title;

  const onIconClick = () => {
    if (isEditing) {
      setWarningMessage(componentName, true);
    } else {
      onUnmount(componentName, submitTo, index);
    }
  };

  const hasMultipleSidebar = index > 0;
  const iconName = hasMultipleSidebar ? "ri-arrow-left-line" : "ri-close-line";
  const sidebarHeaderClassnames = classNames("SidebarForm-header", {
    "has-multipleSidebar": hasMultipleSidebar,
  });

  return (
    <ScrollView.StickyHeader>
      <div className={sidebarHeaderClassnames}>
        <Header as="h3">{headerTitle}</Header>
        <Icon
          icon={iconName}
          size={hasMultipleSidebar ? "small" : "tiny"}
          onClick={onIconClick}
        />
      </div>
    </ScrollView.StickyHeader>
  );
}

FormHeader.propTypes = {
  componentName: PropTypes.string.isRequired,
};

export default FormHeader;
