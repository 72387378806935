import React from "react";
import {
  investmentsObjectPropType,
  investmentEventsPropType,
  companiesPropType,
  portfoliosObjectOfArrayPropType,
} from "PropTypes";
import PropTypes from "prop-types";
import ActivityFeedEvent from "./ActivityFeedEvent";

function renderActivityFeedEvent(
  index,
  event,
  events,
  companies,
  investments,
  portfoliosByCompanyId,
  organizationUsername,
) {
  // TODO: Allow to search company in objects.
  const { company_id: companyId } = event;
  const company = companies.find(({ id }) => id === companyId) || {};
  const investment = investments[event.params.investment_id];
  const companyPortfolios = portfoliosByCompanyId[company.id];
  return (
    <ActivityFeedEvent
      company={company}
      event={event}
      events={events}
      index={index}
      key={index}
      investment={investment}
      portfolios={companyPortfolios}
      organizationUsername={organizationUsername}
    />
  );
}

function ActivityFeedEvents({
  events,
  companies,
  investments,
  portfoliosByCompanyId,
  organizationUsername,
}) {
  return (
    <div className="ActivityFeedEvents">
      {events.map((event, index) =>
        renderActivityFeedEvent(
          index,
          event,
          events,
          companies,
          investments,
          portfoliosByCompanyId,
          organizationUsername,
        ),
      )}
    </div>
  );
}

ActivityFeedEvents.propTypes = {
  companies: companiesPropType.isRequired,
  events: investmentEventsPropType.isRequired,
  investments: investmentsObjectPropType.isRequired,
  portfoliosByCompanyId: portfoliosObjectOfArrayPropType,
  organizationUsername: PropTypes.string.isRequired,
};

export default ActivityFeedEvents;
