import { useState, useEffect } from "react";
import sanitizeHtml from "sanitize-html";
import marked from "marked";

export const useMarked = (markdown, options = {}) => {
  const [html, setHtml] = useState(markdown);
  useEffect(() => {
    const tokens = marked.lexer(markdown);
    const html = marked.parser(tokens);

    setHtml(
      sanitizeHtml(html, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat(["blockquote"]),
        enforceHtmlBoundary: true,
      }),
    );
  }, [markdown, options]);

  return html;
};
