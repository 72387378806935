import { useMutation, useQueryClient } from "react-query";
import Api from "lib/Api";

export function useUpdateDealStage() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ pipelineId, dealId, stageId }) =>
      Api.patch(`/pipelines/${pipelineId}/deals/${dealId}`, {
        stage_id: stageId,
      }),
    {
      onMutate: ({ pipelineId, dealId, stageId }) => {
        const key = ["Deal", dealId];

        const previousData = queryClient.getQueryData(key);
        const updatedDeal = {
          ...previousData.deal,
          stage_id: stageId,
          staged_at: new Date(),
        };

        const updatedStage = previousData.stages.find(
          (stage) => stage.id === stageId,
        );

        queryClient.cancelQueries(key);
        queryClient.setQueryData(key, (oldData) => ({
          ...oldData,
          deal: updatedDeal,
          stage: updatedStage,
        }));

        updateActiveDealsView(queryClient, pipelineId, updatedDeal);

        // rollback function
        return () => queryClient.setQueryData(key, previousData);
      },
      onError: (_err, _, rollback) => rollback(),
      onSuccess: (_, { pipelineId, dealId }) => {
        queryClient.invalidateQueries(["ActiveDeals", pipelineId, {}]);
        queryClient.invalidateQueries(["Deal", dealId]);
        queryClient.invalidateQueries(["SearchDeals", pipelineId]);
      },
    },
  );
}

function updateActiveDealsView(queryClient, pipelineId, updatedDeal) {
  const key = ["ActiveDeals", pipelineId, {}];
  const activeDealsView = queryClient.getQueryData(key);

  if (activeDealsView?.deals) {
    queryClient.setQueryData(["ActiveDeals", pipelineId, {}], (oldData) => ({
      ...oldData,
      deals: oldData.deals.map((deal) =>
        deal.id === updatedDeal.id ? updatedDeal : deal,
      ),
    }));
  }
}
