import {
  UPDATE_COMPANY_LOGO_SUCCESS,
  INVALID_IMAGE_UPLOADED,
  RECEIVE_COMPANY,
  INVALID_IMAGE_UPLOAD_DISMISSED,
} from "actions/synchronous/companyLogoPicker";

const initialState = {
  error: null,
  company: null,
  notImageUploaded: false,
};

function companyLogoPicker(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_COMPANY:
      return Object.assign({}, state, { company: action.company });
    case UPDATE_COMPANY_LOGO_SUCCESS:
      return Object.assign({}, initialState);
    case INVALID_IMAGE_UPLOADED:
      return Object.assign({}, state, {
        notImageUploaded: true,
        progress: null,
      });
    case INVALID_IMAGE_UPLOAD_DISMISSED:
      return Object.assign({}, state, { notImageUploaded: false });
    default:
      return state;
  }
}

export default companyLogoPicker;
