import React, { Fragment } from "react";
import { getText } from "TermsMapper";
import { placeholderDateFormat } from "config/dateInput";
import PriceInput from "components/shared/form/PriceInput";
import DatePicker from "components_new/atoms/DatePicker";
import HelperContent from "components/shared/HelperContent";
import { customPurchasedErrors } from "../../config";

function PurchasedDebtForm({ formData, updateForm, errors, historyAction }) {
  const { date_helper_text: dateHelperContent } = historyAction;
  return (
    <Fragment>
      <PriceInput
        label={getText("debt", "total_acquisition_amount")}
        name="total_acquisition_amount"
        placeholder="0.00"
        onChange={(value) => updateForm("total_acquisition_amount", value)}
        errorMessage={customPurchasedErrors(
          errors,
          "total_acquisition_amount",
          formData.type,
        )}
        value={formData.total_acquisition_amount}
        size="small"
      />
      <DatePicker
        label={getText("debt", "date")}
        name="date"
        placeholder={placeholderDateFormat}
        onChange={(value, validated) => updateForm("date", value, validated)}
        extraClassnames="FormElement FormElement--Date"
        error={!!errors.for("date")}
        errorMessage={errors.for("date")}
        value={formData.date}
        helperContent={() => <HelperContent content={dateHelperContent} />}
        showHelper={!!dateHelperContent}
        size="small"
        oldVersion
      />
    </Fragment>
  );
}

export default PurchasedDebtForm;
