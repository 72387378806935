import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";
import Calendar from "./Calendar";
import moment from "moment";
import classNames from "classnames";
import Icon from "components_new/atoms/Icon";
import { Form, Input, Button } from "@ableco/semantic-ui-react";
import { isEmpty } from "lodash";
import {
  defaultDateMask,
  displayDateFormat,
  valueDateFormat,
  formatTextoToDate,
  dateStringToMoment,
  dateInvalidMessage,
} from "./config";
import HelperMessage from "components/shared/form/HelperMessage";
import useInputId from "lib/hooks/useInputId";
import "semantic-ui-css/components/label.css";
import Text from "components_new/atoms/Text";
import Loader from "components_new/atoms/Loader";
import "./DatePicker.scss";

function DatePicker(props) {
  const {
    autoFocus,
    name,
    value,
    size,
    placeholder,
    prefillToday,
    onChange,
    disabled,
    readOnly,
    error,
    errorMessage,
    extraClassnames,
    textHelper,
    label,
    isOptional,
    oldVersion,
    required = false,
    inlineLabel,
    isLoading,
    isSuccess,
    isError,
  } = props;
  const id = useInputId();
  const dateInput = useRef();

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [displayDate, setDisplayDate] = useState(null);
  const [calendarDate, setCalendarDate] = useState(null);
  const [preSelectDate, setPreSelectDate] = useState(null);
  const [errorContent, setError] = useState(errorMessage || null);
  const wrappedLabel = required ? label + "*" : label;

  useEffect(() => {
    if (prefillToday) {
      propagateDateChange(moment());
    }

    if (autoFocus) {
      dateInput.current.inputElement.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (value && formatTextoToDate(value) !== displayDate) {
      propagateDateChange(moment(value).utc());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (errorMessage !== errorContent) {
      setError(errorMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  const propagateDateChange = useCallback(
    (date) => {
      const properDate = date.format(valueDateFormat);
      const nextDisplayDate = date.format(displayDateFormat);

      setDisplayDate(nextDisplayDate);
      setCalendarDate(nextDisplayDate);
      setPreSelectDate(properDate);
      setError("");

      if (onChange) {
        onChange(properDate, true);
      }
    },
    [onChange],
  );

  const handleDateChange = (event) => {
    const nextDisplayDate = event.target.value;
    setDisplayDate(nextDisplayDate);
    setError("");

    const preSelectDate = dateStringToMoment(nextDisplayDate);
    if (preSelectDate.isValid()) {
      setPreSelectDate(preSelectDate.format(valueDateFormat));
    }
  };

  const handlePastedDate = (event) => {
    const pastedDate = dateStringToMoment(event.clipboardData.getData("text"));
    const isValidDate = pastedDate.isValid();

    if (isValidDate) {
      propagateDateChange(
        moment(pastedDate).format(displayDateFormat),
        isValidDate,
      );
    } else {
      event.preventDefault();
      showErrorMessage();
    }
  };

  const handleBlur = (event) => {
    const { value } = event.currentTarget;
    if (isEmpty(value) && isOptional) {
      cleanDatePicker();
    } else {
      updateDatePickerState(formatTextoToDate(value));
    }
  };

  const handleCalendarDateClick = (date) => {
    updateDatePickerState(formatTextoToDate(date));
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      updateDatePickerState(formatTextoToDate(displayDate));
    }
  };

  const toggleCalendar = (event) => {
    event.preventDefault();
    if (!isCalendarOpen) dateInput.current.inputElement.focus();
    setIsCalendarOpen(!isCalendarOpen);
  };

  const cleanDatePicker = () => {
    setDisplayDate(null);
    setError("");

    if (onChange) {
      onChange(null, false);
    }
  };

  const showErrorMessage = () => {
    cleanDatePicker();
    setError(dateInvalidMessage);
  };

  const updateDatePickerState = (date) => {
    const currentValue = dateStringToMoment(date);
    dateInput.current.inputElement.blur();
    setIsCalendarOpen(false);

    if (currentValue.isValid()) {
      propagateDateChange(currentValue);
    } else if (!isEmpty(displayDate)) {
      showErrorMessage();
    } else {
      cleanDatePicker();
    }
  };

  const datePickerClassnames = classNames("DatePicker", {
    "show-calendar": isCalendarOpen,
    "read-only": readOnly,
    "old-version": oldVersion,
    [size]: !!size,
    [extraClassnames]: !!extraClassnames,
  });

  const inputClassnames = classNames({ error: !!error });
  return (
    <Form.Field className={datePickerClassnames}>
      {label && (
        <label
          data-testid="FieldMessage"
          className="DatePicker-label"
          htmlFor={id}
        >
          {wrappedLabel}
          {isOptional && <span> (optional) </span>}
        </label>
      )}
      <Input className={inputClassnames}>
        <MaskedInput
          id={id}
          ref={dateInput}
          name={name}
          mask={defaultDateMask}
          placeholder={placeholder}
          value={displayDate}
          onChange={handleDateChange}
          onPaste={handlePastedDate}
          onKeyPress={handleKeyPress}
          onBlur={handleBlur}
          disabled={disabled}
          readOnly={readOnly}
        />
        <Button
          icon
          type="button"
          onClick={toggleCalendar}
          disabled={disabled || isLoading}
          aria-label="Select date"
          className="DatePicker-ToggleCalendarButton"
        >
          {inlineLabel && (
            <Text
              type="label-bold"
              color="brand-secondary"
              extraClassnames="DatePicker-InlineLabel"
            >
              {inlineLabel}
            </Text>
          )}
          {isLoading && <Loader active={true} inline size="tiny" />}
          {!isLoading && isSuccess && <Icon icon="ri-check-line" size="tiny" />}
          {!isLoading && isError && <Icon icon="ri-close-line" size="tiny" />}
          {!isLoading && !isSuccess && !isError && (
            <Icon icon="ri-calendar-line" />
          )}
        </Button>
      </Input>
      <div onMouseDown={(event) => event.preventDefault()}>
        <Calendar
          selectedDate={calendarDate}
          preselectedDate={preSelectDate}
          onDateClick={handleCalendarDateClick}
        />
      </div>
      {textHelper && <HelperMessage text={textHelper} />}
      {errorContent && (
        <label className="DatePicker-errorMessage">{errorContent}</label>
      )}
    </Form.Field>
  );
}

DatePicker.propTypes = {
  dateMask: PropTypes.array, //eslint-disable-line react/forbid-prop-types
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  extraClassnames: PropTypes.string,
  initialValue: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  autoFocus: PropTypes.bool,
  prefillToday: PropTypes.bool,
  readOnly: PropTypes.bool,
  isOptional: PropTypes.bool,
  textHelper: PropTypes.string,
  size: PropTypes.oneOf(["tiny", "small", "regular"]),
  oldVersion: PropTypes.bool,
  required: PropTypes.bool,
  inlineLabel: PropTypes.string,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isError: PropTypes.bool,
};

DatePicker.defaultProps = {
  dateMask: defaultDateMask,
  disabled: false,
  isOptional: false,
  errorMessage: null,
  extraClassnames: "",
  initialValue: null,
  name: null,
  onChange: () => {},
  placeholder: "MM/DD/YYYY",
  value: "",
  autoFocus: false,
  prefillToday: false,
  readOnly: false,
  size: "regular",
  oldVersion: false,
  inlineLabel: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export default DatePicker;
