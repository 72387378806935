import { REQUEST_STATUS } from "./requestsReducer";
import { some, compact } from "lodash";

export function isSubmitting(state, mutationName) {
  return state.domain.requests[mutationName] === REQUEST_STATUS.FETCHING;
}

export function isCollectionFetching(state, collectionName) {
  const collectionRequestsState = state.domain.requests[collectionName];
  return (
    collectionRequestsState &&
    collectionRequestsState.asCollection === REQUEST_STATUS.FETCHING
  );
}

function requestToLoadingState(requestState) {
  return {
    none: !requestState || requestState === REQUEST_STATUS.NONE,
    loading: requestState === REQUEST_STATUS.FETCHING,
    loaded: requestState === REQUEST_STATUS.RECEIVED,
  };
}

export function getCollectionLoadingState(state, collectionName) {
  const requestState =
    state.domain.requests[collectionName] &&
    state.domain.requests[collectionName].asCollection;
  return requestToLoadingState(requestState);
}

export function getSingletonLoadingState(state, singletonName) {
  return requestToLoadingState(state.domain.requests[singletonName]);
}

export function getEntityLoadingState(state, entity) {
  const { id, collectionName } = entity;
  const requests = state.domain.requests;
  const requestState =
    requests[collectionName] && requests[collectionName].byId[id];
  return requestToLoadingState(requestState);
}

export function getCombinedLoadingStates(
  state,
  { collectionNames = [], singletonNames = [], entities = [] } = {
    collectionNames: [],
    singletonNames: [],
    entities: [],
  },
) {
  const collectionLoadingStates = collectionNames.map((collectionName) =>
    getCollectionLoadingState(state, collectionName),
  );
  const entityLoadingStates = entities.map((entity) =>
    getEntityLoadingState(state, entity),
  );
  const singletonLoadingStates = singletonNames.map((singletonName) =>
    getSingletonLoadingState(state, singletonName),
  );

  const loadingStates = [
    ...collectionLoadingStates,
    ...entityLoadingStates,
    ...singletonLoadingStates,
  ];

  const none = loadingStates.every((_) => _.none);
  const loaded = loadingStates.every((_) => _.loaded);

  return {
    none,
    loaded,
    loading: !none && !loaded,
  };
}

export function isCollectionUnrequested(state, collectionName) {
  const collectionRequestsState = state.domain.requests[collectionName];
  return (
    collectionRequestsState &&
    collectionRequestsState.asCollection === REQUEST_STATUS.NONE
  );
}

export function isEntityFetching(state, collectionName, id) {
  const collectionRequestsState = state.domain.requests[collectionName];
  return (
    collectionRequestsState &&
    collectionRequestsState.byId[id] === REQUEST_STATUS.FETCHING
  );
}

export function isCollectionAvailable(state, collectionName) {
  const collectionRequestsState = state.domain.requests[collectionName];
  return (
    collectionRequestsState && collectionRequestsState.isCollectionAvailable
  );
}

export function isEntityUnloadedOrFetching(state, collectionName, id) {
  const collectionRequestsState = state.domain.requests[collectionName];
  return (
    collectionRequestsState &&
    (collectionRequestsState.byId[id] === REQUEST_STATUS.FETCHING ||
      collectionRequestsState.byId[id] === REQUEST_STATUS.NONE ||
      !collectionRequestsState.byId[id])
  );
}

export function findEntity(state, collectionName, id) {
  return state.domain.entities[collectionName].byId[id];
}

export function getSingleton(state, singletonName) {
  return state.domain.entities[singletonName];
}

export function getCollection(state, collectionName) {
  return Object.values(getCollectionAsObject(state, collectionName));
}

export function getCollectionAsObject(state, collectionName) {
  return state.domain.entities[collectionName].byId;
}

export function isAnyoneCollectionFetching(state, { collectionNames = [] }) {
  const collectionsRequestsState = compact(
    collectionNames.map(
      (collectionName) => state.domain.requests[collectionName],
    ),
  );

  return some(
    collectionsRequestsState,
    (collection) => collection.asCollection === REQUEST_STATUS.FETCHING,
  );
}
