import { useQuery } from "react-query";
import Api from "lib/Api";

export function useDeal(pipelineId, dealId) {
  return useQuery(
    ["Deal", dealId],
    async () => {
      const { data } = await Api.get(
        `/pipelines/${pipelineId}/deals/${dealId}`,
      );

      const companyInvestments = data.company_investments.reduce(
        (memo, item) => {
          memo[item.id] = item;
          return memo;
        },
        {},
      );

      const deal = {
        ...data.deal,
        company: { ...data.deal.company, ...data.company },
        referrers: data.contacts,
      };

      return {
        deal,
        company: deal.company,
        stage: data.stage,
        companyHoldingTotals: data.company_holding_totals,
        stages: data.stages,
        companyAvatars: data.company_avatars,
        documents: data.documents,
        contacts: data.contacts,
        posts: data.posts,
        companyInvestments,
      };
    },
    {
      enabled: !!dealId,
    },
  );
}
