import { connect } from "react-redux";
import { flash } from "actions/synchronous/flash";
import OrganizationMembers from "./OrganizationMembers";
import { inviteOrganizationMember } from "store/organizations/organizationMembers/actions";
import { getResource } from "store/resourcesV2/selectors";

function mapStateToProps(state) {
  const { organizations } = state;
  //HERE WE ARE NOT USING THE HOOKS TO GET CURRENT ORGANIZATION BECAUSE IS CHANGING ITEMS IN
  //ORGANIZATION MEMBERSHIP, THE SELECTOR IN HOOK NOT NOTIFY TO THE COMPONENT ABOUT THE CHANGE.
  const currentOrganization = getResource(
    state,
    "organizations",
    organizations.currentOrganizationId,
  );

  return {
    currentOrganization,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    inviteOrganizationMember: (payload) => {
      dispatch(inviteOrganizationMember(payload)).then((payload) => {
        const { email } = payload;
        if (payload.previous_invitation) {
          dispatch(flash("resendInvite", { email }));
        } else {
          dispatch(flash("sendInvite", { email }));
        }
      });
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrganizationMembers);
