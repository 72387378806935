import React from "react";
import classNames from "classnames";
import { Popup as SemanticPopup } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/popup.css";
import "./Tooltip.scss";

function Tooltip(props) {
  const {
    warning,
    size,
    content,
    basic,
    extraClassnames,
    customStyles,
    breakLine,
    position,
    ...inputProps
  } = props;
  const tooltipClassnames = classNames("Tooltip", {
    warning: !!warning,
    [size]: !!size,
    [extraClassnames]: !!extraClassnames,
    "with-breakLine": breakLine,
  });

  return (
    <SemanticPopup
      position={position}
      className={tooltipClassnames}
      basic={basic}
      style={customStyles && customStyles}
      {...inputProps}
    >
      <SemanticPopup.Content>
        {Array.isArray(content) && content.length > 0 ? (
          <ul className="Tooltip-list">
            {content.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        ) : (
          content
        )}
      </SemanticPopup.Content>
    </SemanticPopup>
  );
}

Tooltip.defaultProps = {
  basic: true,
  breakLine: false,
};

export default Tooltip;
