import React, { Fragment } from "react";
import { Outlet, useParams, useLocation } from "react-router-dom";
import TabMenu from "components_new/elements/TabMenu";
import EmptyPipelines from "./EmptyPipelines";
import PropTypes from "prop-types";
import DownloadCsvButton from "components_new/elements/DownloadCsvButton";
import CreateSnapshotButton from "components_new/elements/CreateSnapshotButton";
import "./DealsView.scss";

function DealsView({
  hasPipelines,
  pipelineId,
  activeDealsCount = 0,
  committedDealsCount = 0,
  passedDealsCount = 0,
}) {
  const { organizationUsername } = useParams();
  const { pathname: currentLocation } = useLocation();

  const route = (path) => `/${organizationUsername}/deals/${path}`;
  const isActive = (path) => currentLocation.includes(route(path));

  return (
    <div className="DealsView">
      {hasPipelines ? (
        <Fragment>
          <TabMenu
            tabs={[
              {
                name: "Active Deals",
                active: isActive("active"),
                to: route("active"),
                count: activeDealsCount || 0,
                textOnly: true,
              },
              {
                name: "Committed",
                active: isActive("committed"),
                to: route("committed"),
                count: committedDealsCount || 0,
                textOnly: true,
              },
              {
                name: "Passed",
                active: isActive("passed"),
                to: route("passed"),
                count: passedDealsCount || 0,
                textOnly: true,
              },
            ]}
            rightContent={
              <div className="item">
                <DownloadCsvButton
                  collection="pipelines"
                  params={{ pipeline_id: pipelineId }}
                />
                <CreateSnapshotButton
                  disabled={!isActive("active")}
                  kind="secondary"
                  snapshotType="deal"
                />
              </div>
            }
          />
          <Outlet />
        </Fragment>
      ) : (
        <EmptyPipelines />
      )}
    </div>
  );
}

DealsView.propTypes = {
  hasPipelines: PropTypes.bool.isRequired,
  pipelineId: PropTypes.string,
  activeDealsCount: PropTypes.number.isRequired,
  committedDealsCount: PropTypes.number.isRequired,
  passedDealsCount: PropTypes.number.isRequired,
  routeParams: PropTypes.shape({
    organizationUsername: PropTypes.string,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  children: PropTypes.node,
};

export default DealsView;
