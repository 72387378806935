export const WEBSITE_PREFILL = "http://";
export const EMPLOYEES_COUNT_OPTIONS = [
  { key: 0, text: "1 - 10", value: "1-to-10" },
  { key: 1, text: "11 - 50", value: "11-to-50" },
  { key: 2, text: "51 - 100", value: "51-to-100" },
  { key: 3, text: "101 - 250", value: "101-to-250" },
  { key: 4, text: "251 - 500", value: "251-to-500" },
  { key: 5, text: "501 - 1,000", value: "501-to-1000" },
  { key: 6, text: "1,001 - 2,500", value: "1001-to-2500" },
  { key: 7, value: "2501-to-5000", text: "2,501 - 5,000" },
  { key: 8, text: "5,001 - 10,000", value: "5001-to-10000" },
  { key: 9, text: "10,001+", value: "more-than-10000" },
];
export const STATUS_OPTIONS = [
  { key: 0, text: "Active", value: "Active" },
  { key: 1, text: "Acquired", value: "Acquired" },
  { key: 2, text: "Failed", value: "Failed" },
  { key: 3, text: "IPO", value: "IPO" },
];
export const ACTIVITY_STATUS_OPTIONS = [
  { key: "", text: "Select status", value: null },
  { key: 0, text: "Active Fundraising", value: "Active Fundraising" },
  { key: 1, text: "Active M&A", value: "Active M&A" },
  { key: 2, text: "Raising Soon", value: "Raising Soon" },
];
