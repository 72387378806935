import { isEmpty } from "lodash";

export default function redirectFromInitialValue({
  formData,
  prefilledSelection,
  currentOrganizationUsername,
}) {
  const selectedValue = formData.selection || prefilledSelection;
  if (selectedValue.type === "company") {
    const { formOrigin } = formData;
    const companyId = isEmpty(prefilledSelection)
      ? selectedValue.id
      : prefilledSelection.company_id;

    const redirectUrl = `/${currentOrganizationUsername}/companies/${companyId}`;

    switch (formOrigin) {
      case "filesForm":
        return `${redirectUrl}/files`;
      case "noteForm":
        return `${redirectUrl}/notes`;
      default:
        return `${redirectUrl}/investments`;
    }
  } else if (selectedValue.type === "deal") {
    const dealId = isEmpty(prefilledSelection)
      ? selectedValue.id
      : prefilledSelection.id;
    return `/${currentOrganizationUsername}/deals/active/${dealId}`;
  } else {
    return `/${currentOrganizationUsername}/investments/${selectedValue.id}`;
  }
}
