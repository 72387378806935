import React, { useEffect } from "react";
import FormGroup from "components_new/elements/FormGroup";
import DatePicker from "components_new/atoms/DatePicker";
import PercentageInput from "components_new/atoms/PercentageInput";
import DropdownForm from "components_new/atoms/DropdownForm";
import { isEmpty } from "lodash";

import {
  termsItems,
  interestScheduleItems,
  accrualScheduleItems,
} from "./config";

function InterestDetails({ formData, handleChange }) {
  const {
    note_maturity_date,
    note_maturity_date_term,
    interest_rate,
    interest_schedule,
    interest_accrual_schedule,
  } = formData;
  const otherSelected = note_maturity_date_term === "other";

  useEffect(() => {
    if (!isEmpty(note_maturity_date_term) && !otherSelected) {
      handleChange("note_maturity_date", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note_maturity_date_term]);

  return (
    <FormGroup
      title="Interest Details"
      extraClassNames="InterestDetails"
      isExpanded={false}
      required={false}
      noBottomBorder
      subGroup
    >
      <DropdownForm
        name="note_maturity_date_term"
        label="Terms"
        placeholder="Please select"
        items={termsItems}
        onChange={(_, { value }) =>
          handleChange("note_maturity_date_term", value)
        }
        value={note_maturity_date_term}
        size="tiny"
      />
      {otherSelected && (
        <DatePicker
          name="note_maturity_date"
          label="Maturity Date"
          onChange={(value, validated) =>
            handleChange("note_maturity_date", value, validated)
          }
          value={note_maturity_date}
          prefillToday={true}
          size="tiny"
        />
      )}
      <PercentageInput
        name="interest_rate"
        label="Interest rate"
        onChange={(value) => handleChange("interest_rate", value)}
        defaultValue={interest_rate}
        value={interest_rate}
        allowDecimal
        size="tiny"
      />
      <DropdownForm
        name="interest_schedule"
        label="Interest schedule"
        placeholder="Please select"
        items={interestScheduleItems}
        onChange={(_, { value }) => handleChange("interest_schedule", value)}
        value={interest_schedule}
        size="tiny"
      />
      <DropdownForm
        name="interest_accrual_schedule"
        label="Interest accrual schedule"
        placeholder="Please select"
        items={accrualScheduleItems}
        onChange={(_, { value }) =>
          handleChange("interest_accrual_schedule", value)
        }
        value={interest_accrual_schedule}
        size="tiny"
      />
    </FormGroup>
  );
}

export default InterestDetails;
