import React from "react";
import FileTypeIcon from "components/shared/FileTypeIcon";

function AttachmentSingleValue({ attachment, currentOrganizationUsername }) {
  return (
    <div className="AttachmentSingleValue" data-testid="AttachmentSingleValue">
      <span className="AttachmentSingleValue-documentIcon">
        <FileTypeIcon file={attachment} />
      </span>
      <span className="AttachmentSingleValue-name">
        <a
          href={`/${currentOrganizationUsername}/documents/${attachment.id}/download`}
          rel="nofollow noopener"
          target="_blank"
        >
          {attachment.file_name}
        </a>
      </span>
    </div>
  );
}

export default AttachmentSingleValue;
