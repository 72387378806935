import React, { Component, Fragment } from "react";
import classNames from "classnames";
import preventerHandler from "lib/preventerHandler";

const MAX_HEIGHT = 40;
const MAX_CHARACTER = 50;
const MAX_PER_LINE = 30;
const LONG_CONTENT_DEFAULT_STATE = {
  collapsibleContent: true,
  contentExpanded: false,
};

class InvestmentItemContentLong extends Component {
  state = {
    collapsibleContent: false,
    contentExpanded: true,
  };

  setValueRef = (element) => {
    this.value = element;
  };

  componentDidMount() {
    const valueHeight = this.value.clientHeight;

    if (valueHeight > MAX_HEIGHT) {
      this.setState(LONG_CONTENT_DEFAULT_STATE);
    }
  }

  remainingTextLength = (text) => {
    const words = text.split(" ");
    const remainingLength = words.reduce((accumulator, currentValue) => {
      const currentRemaining = accumulator + currentValue.length;
      return currentRemaining < 30
        ? accumulator + currentValue.length
        : accumulator;
    }, 0);
    return MAX_PER_LINE - remainingLength;
  };

  truncateText = (text = "") => {
    if (text.length >= MAX_CHARACTER) {
      return (
        text.slice(0, MAX_CHARACTER - 3 - this.remainingTextLength(text)) +
        "..."
      );
    }
    return text;
  };

  handleToggleClick = preventerHandler(() => {
    this.setState({
      contentExpanded: !this.state.contentExpanded,
    });
  });

  render() {
    const { value } = this.props;
    const { collapsibleContent, contentExpanded } = this.state;
    const componentClassnames = classNames("InvestmentItemContentLong", {
      "is-collapsed": !contentExpanded,
    });

    return (
      <div className={componentClassnames}>
        <div className="InvestmentItemContentLong-inner">
          <div
            className="InvestmentItemContentLong-valueCol"
            ref={this.setValueRef}
          >
            <div className="InvestmentItemContentLong-value">
              {collapsibleContent && !contentExpanded ? (
                <Fragment>
                  <span>{this.truncateText(value)}</span>
                  <a
                    href="#"
                    className="InvestmentItemContentLong-toggle"
                    onClick={this.handleToggleClick}
                  >
                    {" more"}
                  </a>
                </Fragment>
              ) : (
                value
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InvestmentItemContentLong;
