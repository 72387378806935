import { useMutation, useQueryClient } from "react-query";
import Api from "lib/Api";

export function useDeleteInvestmentDocument() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ document }) => Api.delete(`/documents/${document.id}`),
    {
      onSuccess: (_, { document: deletedDocument, investmentId }) => {
        return queryClient.setQueryData(
          ["Investment", investmentId],
          (oldData) => ({
            ...oldData,
            documents: oldData.documents.filter(
              (document) => document.id !== deletedDocument.id,
            ),
          }),
        );
      },
    },
  );
}
