import React from "react";
import PropTypes from "prop-types";
import { dealProptype } from "PropTypes";
import differenceInDate from "lib/differenceInDate";
import { formatPriceInputAbbreviated } from "lib/abbreviatedFormat";
import formatDate from "lib/formatDate";
import classnames from "classnames";
import ReferrerList from "components_new/atoms/ReferrerList";
import DataCard from "components_new/elements/DataCard";
import Due from "components_new/elements/Due";
import OwnerDealForm from "../OwnerDealForm";
import Text from "components_new/atoms/Text";
import BasicLabel from "components_new/atoms/BasicLabel";
import Icon from "components_new/atoms/Icon";
import { calculateStagedAtText } from "./config";
import "./ActiveDealCard.scss";

function ActiveDealCard({ deal, onClick, selectedDealId }) {
  const timeInPipeline = differenceInDate(deal.created_at);
  const dealCardClassnames = classnames("ActiveDeals-Stages-DealCard", {
    active: selectedDealId === deal.id,
    new: timeInPipeline === 0,
  });

  const createdAt = calculateStagedAtText(deal.created_at);

  return (
    <DataCard
      extraClassnames={dealCardClassnames}
      onClick={() => onClick(deal.id)}
    >
      <DataCard.Header extraClassnames="DealCard-Header">
        <DataCard.Avatar
          imageUrl={deal.company.logo_url}
          icon="company-sm"
          size="small"
        />
        <div className="DealCard-Header-CompanyInfo">
          <div className="DealCard-Header-CompanyInfo-NameAndFlags">
            <Text
              type="subtitle-2-bold"
              extraClassnames="DealCard-Header-CompanyInfo-NameAndFlags-Name u-textWithEllipsis"
            >
              {deal.company.name}
            </Text>
            {deal.company.stealth && (
              <Icon icon="ri-eye-off-fill" size="tiny" color="purple" />
            )}
          </div>
          <div className="DealCard-Header-CompanyInfo-NameAndAllocation">
            {deal.round_name && (
              <Text
                type="body-2-regular"
                color="brand-light"
                extraClassnames="u-textWithEllipsis"
              >
                {deal.round_name}
              </Text>
            )}
            <Text
              type="body-2-regular"
              color="brand-light"
              extraClassnames="DealCard-Header-CompanyInfo-NameAndAllocation-Allocation"
            >
              {deal.allocation &&
                `(${formatPriceInputAbbreviated(deal.allocation, {
                  precision: 2,
                  noTrailingZeros: true,
                })})`}
            </Text>
          </div>
        </div>
        <div className="DealCard-Header-AvatarInfo">
          <div className="DealCard-Header-DealDates">
            {deal.decision_due_date ? (
              <Due
                date={deal.decision_due_date}
                unread={timeInPipeline === 0}
              />
            ) : (
              <Text type="label-semibold">
                Updated {formatDate(deal.updated_at, "monthAndDay")}
              </Text>
            )}
            <Text type="label-regular" color="brand-light">
              {createdAt}
            </Text>
          </div>
          <div className="DealCard-Header-Owner">
            <OwnerDealForm
              dealId={deal.id}
              pipelineId={deal.pipeline_id}
              owner={{
                avatar: deal.owner_avatar_color,
                url: deal.owner_avatar_url,
                initials: deal.owner_initials,
                name: deal.owner_name,
              }}
            />
          </div>
        </div>
      </DataCard.Header>
      {deal.company.description && (
        <DataCard.Content extraClassnames="DealCard-Description">
          {deal.company.description}
        </DataCard.Content>
      )}
      <DataCard.Content extraClassnames="DealCard-Footer">
        <ReferrerList referrers={deal.referrers} />
        <div className="DealCard-Labels">
          {deal.company.is_yc && <BasicLabel type="primary">YC</BasicLabel>}{" "}
          {deal.is_follow_on && (
            <BasicLabel type="primary">Follow-on</BasicLabel>
          )}
        </div>
      </DataCard.Content>
    </DataCard>
  );
}

ActiveDealCard.propTypes = {
  deal: dealProptype,
  onClick: PropTypes.func,
  selectedDealId: PropTypes.string,
};

export default ActiveDealCard;
