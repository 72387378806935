import Api from "lib/Api";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import useViewIds from "lib/hooks/useViewIds";

// TODO: Remove this integration with redux when we migrated Company View
// into React Query.
import { useRemoveContactFromInvestment } from "../useRemoveContactFromInvestment";

export function useDeleteContact() {
  const queryClient = useQueryClient();
  const { organizationUsername } = useParams();
  const { initialCompanyId: companyId } = useViewIds();
  const removeContactFromInvestmentView = useRemoveContactFromInvestment();

  return useMutation(
    async (contactId) => await Api.delete(`/v2/contacts/${contactId}`),
    {
      onSuccess: (_, contactId) => {
        const key = ["ContactsDirectory", organizationUsername, {}];
        const contactsView = queryClient.getQueryData(key);

        if (contactsView?.contacts) {
          queryClient.setQueryData(key, {
            ...contactsView,
            contacts: contactsView.contacts.filter(
              (item) => item.id !== contactId,
            ),
          });
        }

        removeContactFromCompanyView({
          queryClient,
          organizationUsername,
          companyId,
          contactId,
        });

        // TODO: Remove this integration with redux when we migrated Company View
        // into React Query.
        removeContactFromInvestmentView(contactId);
      },
    },
  );
}

function removeContactFromCompanyView({
  queryClient,
  organizationUsername,
  companyId,
  contactId,
}) {
  const key = ["Company", organizationUsername, companyId];
  const companyView = queryClient.getQueryData(key);

  const deleteContact = (collection) =>
    collection.filter((item) => item.id !== contactId);

  if (companyView?.employees && companyView?.referrers) {
    queryClient.setQueryData(key, {
      ...companyView,
      employees: deleteContact(companyView.employees),
      referrers: deleteContact(companyView.referrers),
    });
  }
}
