import React from "react";
import PrimaryButton from "components/shared/PrimaryButton";
import { Link } from "react-router-dom";
import TextInput from "components/shared/form/TextInput";
import preventerHandler from "lib/preventerHandler";
import verifyEmail from "lib/verifyEmail";
import PropTypes from "prop-types";

class ForgotPassword extends React.Component {
  state = {
    formData: {},
    isEmailSent: false,
    isEmailValid: false,
  };

  handleSubmit = () => {
    const { email } = this.state.formData;
    this.props.forgotPassword(email).then(() => {
      this.setState({ isEmailSent: true });
    });
  };

  updateEmail = (email) => {
    this.setState({
      formData: { email },
      isEmailValid: verifyEmail(email),
    });
  };

  renderForgotMessageSent = () => {
    const { goToAuthenticationView } = this.props;
    return (
      <React.Fragment>
        <h1 className="u-heading LoginAnimatedElement">Instructions Sent</h1>
        <p className="FormElement invitationtMessage LoginAnimatedElement SignUpRequestMessage">
          You'll receive an email from us soon. Please follow the instructions
          to create your new password.
        </p>
        <Link
          to="#"
          className="LoginAnimatedElement ForgotLink FormElement"
          onClick={goToAuthenticationView}
        >
          Back to login
        </Link>
      </React.Fragment>
    );
  };

  render() {
    const { goToAuthenticationView } = this.props;
    const { isEmailSent, isEmailValid } = this.state;
    if (isEmailSent) return this.renderForgotMessageSent();
    return (
      <form
        onSubmit={preventerHandler(() => this.handleSubmit())}
        className="InvitationOrgFormContainer"
        role="form"
      >
        <h1 className="u-heading LoginAnimatedElement">Forgot Password?</h1>
        <p className="FormElement invitationtMessage LoginAnimatedElement SignUpRequestMessage">
          Can't remember you password? We'll send you instructions to setup a
          new one.
        </p>
        <TextInput
          autoFocus
          extraClassnames="LoginAnimatedElement"
          label="Email address"
          name="email"
          onChange={this.updateEmail}
        />
        <Link
          to="#"
          className="LoginAnimatedElement ForgotLink FormElement"
          onClick={goToAuthenticationView}
        >
          Back to login
        </Link>
        <PrimaryButton
          customClass="LoginAnimatedElement FormElement"
          disabled={!isEmailValid}
          type="submit"
        >
          <span className="Button-text">Send Password Reset Instructions</span>
        </PrimaryButton>
      </form>
    );
  }
}

ForgotPassword.propTypes = {
  goToAuthenticationView: PropTypes.func.isRequired,
  forgotPassword: PropTypes.func.isRequired,
};

export default ForgotPassword;
