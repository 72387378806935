import moment from "moment";

const FORMATS = {
  default: "MM/DD/YYYY",
  dashes: "MM-DD-YYYY",
  detailed: "MMMM D, YYYY",
  semiDetailed: "MMM D, YYYY",
  ll: "ll",
  monthAndDay: "MMM DD",
  monthAndYear: "MMM YYYY",
  monthAndDayNumeric: "MM/DD",
  fullYear: "YYYY",
  fullMonthAndYear: "MMMM YYYY",
  fullMonthAndDay: "MMMM DD",
};

export default function formatDate(date, format = "default") {
  let formatted = null;
  if (date) {
    formatted = moment(date).format(FORMATS[format]);
  }
  return formatted;
}
