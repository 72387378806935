import PropTypes from "prop-types";
import React from "react";
import preventerHandler from "lib/preventerHandler";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";
import TooltipHolder from "components/shared/TooltipHolder";

function Separator() {
  return <li className="DropdownMenuItem-separator" />;
}

function MenuItem(props) {
  const {
    onClickAction,
    linkTo,
    text,
    toggleDropdown,
    isDisabled,
    tooltip,
    tooltipPosition,
    extraClassnames,
  } = props.item;

  const navigate = useNavigate();

  const onClickHandler = linkTo
    ? () => {
        navigate(linkTo);
        toggleDropdown();
      }
    : () => {
        onClickAction();
        if (toggleDropdown) {
          toggleDropdown();
        }
      };

  const link = (
    <a
      href="#"
      className="u-dropdownLink DropdownMenuItem-anchor"
      onClick={preventerHandler(!isDisabled ? onClickHandler : () => {})}
    >
      {text}
    </a>
  );

  const wrappedLink = tooltip ? (
    <TooltipHolder position={tooltipPosition} tooltip={tooltip}>
      {link}
    </TooltipHolder>
  ) : (
    link
  );

  const DropdownMenuItemClassnames = classnames(
    "u-bg-tint-0 DropdownMenuItem",
    {
      "DropdownMenuItem-withTooltip": !!tooltip,
      "is-disabled": isDisabled,
      [extraClassnames]: !!extraClassnames,
    },
  );

  return <li className={DropdownMenuItemClassnames}>{wrappedLink}</li>;
}

const menuItemTypes = {
  "menu-item": MenuItem,
  separator: Separator,
};

function DropdownMenuItem(props) {
  const { type = "menu-item" } = props.item;
  const MenuItemComponent = menuItemTypes[type];

  if (!MenuItemComponent) {
    return null;
  }

  return <MenuItemComponent {...props} />;
}

DropdownMenuItem.propTypes = {
  item: PropTypes.shape({
    type: PropTypes.oneOf(["separator", "menu-item"]), // default menu-item
    text: PropTypes.string,
    linkTo: PropTypes.string,
    onClickAction: PropTypes.func,
    // TODO standardize this to be the callable function name
    toggleDropdown: PropTypes.func,
    extraClassnames: PropTypes.string,
    isDisabled: PropTypes.bool,
    tooltipPosition: PropTypes.oneOf(["bottom", "top"]),
  }).isRequired,
};

export default DropdownMenuItem;
