import React, { useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { motion, AnimatePresence } from "framer-motion";
import "./Sidebar.scss";

function Sidebar({
  expanded = false,
  size = "regular",
  position = "right",
  children,
  ...otherProps
}) {
  const [currentSize, setCurrentSize] = useState(size);
  const sidebarClassnames = classnames("Sidebar", {
    [currentSize]: !!currentSize,
    [position]: !!position,
  });

  const sizes = {
    regular: "340",
    wide: "600",
  };

  const toogleSize = () => {
    return currentSize == "regular"
      ? setCurrentSize("wide")
      : setCurrentSize("regular");
  };

  return (
    <AnimatePresence exitBeforeEnter>
      {expanded && (
        <motion.section
          className={sidebarClassnames}
          initial={{ [position]: `-${sizes[currentSize]}px` }}
          animate={{ [position]: 0 }}
          exit={{ [position]: `-${sizes[currentSize] + 5}px` }}
          transition={{ ease: "linear", duration: 0.4 }}
        >
          {children({
            ...otherProps,
            toogleSize,
            currentSize,
          })}
        </motion.section>
      )}
    </AnimatePresence>
  );
}

Sidebar.propTypes = {
  size: PropTypes.oneOf(["regular", "wide"]),
  position: PropTypes.oneOf(["right", "left"]),
  expanded: PropTypes.bool.isRequired,
  children: PropTypes.func.isRequired,
};

export default Sidebar;
