export const BACKSPACE_KEY = 8;
export const TAB_KEY = 9;
export const ENTER_KEY = 13;
export const DOWN_ARROW_KEY = 40;
export const ESC_KEY = 27;
export const UP_ARROW_KEY = 38;
export const DELETE_KEY = 46;
export const SPACE_KEY = 32;

export default {
  BACKSPACE_KEY,
  TAB_KEY,
  ENTER_KEY,
  DOWN_ARROW_KEY,
  ESC_KEY,
  UP_ARROW_KEY,
  DELETE_KEY,
  SPACE_KEY,
};
