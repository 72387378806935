import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Message as SemanticMessage } from "@ableco/semantic-ui-react";
import "./Message.scss";

function Message({ extraClassNames, children, ...messageProps }) {
  const messageClassname = classNames("Message", {
    [extraClassNames]: !!extraClassNames,
  });

  return (
    <SemanticMessage className={messageClassname} {...messageProps}>
      {children}
    </SemanticMessage>
  );
}

Message.propTypes = {
  extraClassNames: PropTypes.string,
  as: PropTypes.oneOf([PropTypes.string, PropTypes.func]),
  attached: PropTypes.bool,
  children: PropTypes.node,
  color: PropTypes.string,
  compact: PropTypes.bool,
  error: PropTypes.bool,
  floating: PropTypes.bool,
  hidden: PropTypes.bool,
  info: PropTypes.bool,
  negative: PropTypes.bool,
  onDismiss: PropTypes.func,
  positive: PropTypes.bool,
  size: PropTypes.oneOf([
    "mini",
    "tiny",
    "small",
    "large",
    "big",
    "huge",
    "massive",
  ]),
  success: PropTypes.bool,
  visible: PropTypes.bool,
  warning: PropTypes.bool,
};

export default Message;
