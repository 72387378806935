export const UPDATE_COMPANY_LOGO_START =
  "companyLogoPicker/UPDATE_COMPANY_LOGO_START";
export const UPDATE_COMPANY_LOGO_SUCCESS =
  "companyLogoPicker/UPDATE_COMPANY_LOGO_SUCCESS";
export const INVALID_IMAGE_UPLOADED =
  "companyLogoPicker/INVALID_IMAGE_UPLOADED";
export const RECEIVE_COMPANY = "companyLogoPicker/RECEIVE_COMPANY";
export const INVALID_IMAGE_UPLOAD_DISMISSED =
  "companyLogoPicker/INVALID_IMAGE_UPLOAD_DISMISSED";

export function updateCompanyLogoStart() {
  return { type: UPDATE_COMPANY_LOGO_START };
}

export function updateCompanyLogoSuccess() {
  return { type: UPDATE_COMPANY_LOGO_SUCCESS };
}

export function invalidCompanyLogoUploaded() {
  return { type: INVALID_IMAGE_UPLOADED };
}

export function invalidCompanyLogoUploadDismissed() {
  return { type: INVALID_IMAGE_UPLOAD_DISMISSED };
}

export function receiveCompanyLogoCompany(company) {
  return { type: RECEIVE_COMPANY, company };
}
