import React, { Component } from "react";
import ReactHighcharts from "react-highcharts";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import numeral from "numeral";
import { cashFlowPerformancePropType } from "PropTypes";
import { isEmpty, camelCase, snakeCase, toUpper, isEqual } from "lodash";
import formatTooltip from "./formatTooltip";
import parseDateToUTC from "lib/parseDateToUTC";
import {
  initialPointDifferenceAmount,
  initialPointDifferenceTypePeriod,
  cashOutSeries,
  cashInSeries,
  cashBalanceSeries,
  globalChartConfig,
  labelsYAxis,
  plotOptionsChart,
  tooltipChart,
  yAxisChart,
  xAxisChart,
} from "./portfolioCashFlowPerformanceConfig";
import parseDataToChartPoints from "lib/charts/parseDataToChartPoints";
import addFakeTodaysPoint from "lib/charts/addFakeTodaysPoint";
import addFakeInitialPoint from "lib/charts/addFakeInitialPoint";
import getTodayUTC from "lib/charts/getTodayUTC";
import tickPositioner from "lib/charts/tickPositioner";

const today_utc = getTodayUTC();

function cashBalanceDataMapper({
  period_start: periodStart,
  cash_balance: cashBalance,
}) {
  return {
    x: parseDateToUTC(periodStart),
    y: parseFloat(cashBalance),
  };
}

function cashOutDataMapper({ period_start: periodStart, cash_out: cashOut }) {
  return {
    x: parseDateToUTC(periodStart),
    y: parseFloat(cashOut) * -1,
  };
}

function cashInDataMapper({ period_start: periodStart, cash_in: cashIn }) {
  return {
    x: parseDateToUTC(periodStart),
    y: parseFloat(cashIn),
  };
}

class CashFlowPerformanceChart extends Component {
  static propTypes = {
    cashFlowPerformance: cashFlowPerformancePropType.isRequired,
  };

  componentDidMount() {
    NoDataToDisplay(ReactHighcharts.Highcharts);
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(
      this.props.cashFlowPerformance,
      nextProps.cashFlowPerformance,
    );
  }

  getCashInData() {
    const { cashFlowPerformance } = this.props;

    if (cashFlowPerformance.length === 0) {
      return [];
    }

    const chartPoints = parseDataToChartPoints(
      cashFlowPerformance,
      cashInDataMapper,
    );
    return chartPoints;
  }

  getCashOutData() {
    const { cashFlowPerformance } = this.props;

    if (cashFlowPerformance.length === 0) {
      return [];
    }

    const chartPoints = parseDataToChartPoints(
      cashFlowPerformance,
      cashOutDataMapper,
    );

    return chartPoints;
  }

  getCashBalanceData() {
    const { cashFlowPerformance } = this.props;

    if (cashFlowPerformance.length === 0) {
      return [];
    }

    const chartPoints = parseDataToChartPoints(
      cashFlowPerformance,
      cashBalanceDataMapper,
    );
    const chartPointsWithFakeInitialPoint = addFakeInitialPoint(
      chartPoints,
      initialPointDifferenceAmount,
      initialPointDifferenceTypePeriod,
    );
    const hasTodayPoint = chartPoints.some(({ x }) => x === today_utc);

    if (chartPoints.length >= 1 && !hasTodayPoint) {
      return addFakeTodaysPoint(chartPointsWithFakeInitialPoint);
    }

    return chartPointsWithFakeInitialPoint;
  }

  isEmptyData() {
    const { cashFlowPerformance } = this.props;
    return isEmpty(cashFlowPerformance);
  }

  formatCharYAxisLabels() {
    return toUpper(numeral(this.value).format("$0[.]00a"));
  }

  renderHTMLChartTooltip() {
    const {
      x: date,
      y: amount,
      series: { name: cashType },
      data: { fake_point = false } = {},
    } = this.point;
    const formattedTooltip = formatTooltip(snakeCase(cashType));

    if (fake_point) {
      return null;
    }

    return `
      <p class="PortfolioCashFlowPerformanceTooltip CashFlowPerformanceChartTooltip PortfolioCashFlowPerformanceTooltip--${camelCase(
        cashType,
      )} CashFlowPerformanceChartTooltip--${camelCase(cashType)}">
        <span class="PortfolioCashFlowPerformanceTooltip-amount CashFlowPerformanceChartTooltip-amount">
          ${formattedTooltip.amount(amount)}
        </span>
        <span class="PortfolioCashFlowPerformanceTooltip-date CashFlowPerformanceChartTooltip-date">
          ${formattedTooltip.date(date)}
        <span>
      </p>
    `;
  }

  render() {
    const cashInData = this.getCashInData();
    const cashOutData = this.getCashOutData();
    const cashBalanceData = this.getCashBalanceData();
    const config = {
      ...globalChartConfig,
      tooltip: {
        ...tooltipChart,
        formatter: this.renderHTMLChartTooltip,
      },
      plotOptions: plotOptionsChart,
      series: [
        {
          ...cashOutSeries,
          data: cashOutData,
        },
        {
          ...cashInSeries,
          data: cashInData,
        },
        {
          ...cashBalanceSeries,
          data: cashBalanceData,
        },
      ],
      xAxis: {
        tickPositioner,
        ...xAxisChart,
      },
      yAxis: {
        ...yAxisChart,
        labels: {
          ...labelsYAxis,
          formatter: this.formatCharYAxisLabels,
        },
      },
    };
    return <ReactHighcharts config={config} />;
  }
}

export default CashFlowPerformanceChart;
