import React from "react";
import { Grid } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/grid.css";
import PropTypes from "prop-types";
import MightyLogo from "components_new/atoms/MightyLogo";
import OrganizationMenu from "./OrganizationMenu";
import Link from "components_new/atoms/Link";
import SearchBox from "components/header/SearchBox";
import UserMenu from "components/header/UserMenu";
import MainActions from "components/header/MainActions";
import "./Header.scss";

function Header({ children, organizationUsername }) {
  return (
    <Grid columns="equal" className="MenuHeader" as="header">
      {children}
      <Grid.Column className="MenuHeader-information">
        <Link
          extraClassnames="MenuHeader-logo"
          to={`/${organizationUsername}/dashboard`}
        >
          <MightyLogo theme="inverse" size="small" />
          <h1 className="u-screenReaderOnly">Mighty</h1>
        </Link>
        <OrganizationMenu />
      </Grid.Column>
      <Grid.Column className="MenuHeader-search" width={6}>
        <SearchBox name="search" />
      </Grid.Column>
      <Grid.Column floated="right" className="MenuHeader-actions">
        <MainActions />
        <UserMenu />
      </Grid.Column>
    </Grid>
  );
}

Header.propTypes = {
  expandSidebar: PropTypes.func.isRequired,
  children: PropTypes.node,
  organizationUsername: PropTypes.string,
};

Header.defaultProps = {
  expandSidebar: () => {},
  children: null,
};

export default Header;
