export const vestingScheduleItems = [
  { key: 0, text: "Monthly", value: "monthly" },
  { key: 1, text: "Quarterly", value: "quarterly" },
  { key: 2, text: "Annually", value: "annually" },
];

export const vestingCliffItems = [
  { key: 0, text: "12 months", value: "twelve" },
  { key: 1, text: "24 months", value: "twenty_four" },
  { key: 2, text: "36 months", value: "thirty_six" },
];
