import React, { useState, Fragment } from "react";
import classNames from "classnames";

import ListItem from "components_new/atoms/InlineLabelValue/ListItem";

function NestedList({ index, data }) {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };
  const utilityIcon = expanded ? "ri-arrow-up-s-line" : "ri-arrow-down-s-line";
  const subListInlineValueListClassNames = classNames("InlineValueNestedList", {
    hidden: !expanded,
  });
  return (
    <Fragment>
      <div onClick={toggleExpanded} className="ToggleContainer">
        <ListItem
          key={index}
          icon={data.icon}
          toggleIcon={utilityIcon}
          label={data.label}
          url={data.url}
        />
      </div>
      <div className={subListInlineValueListClassNames}>
        {data.list &&
          data.list.map((item, nestedIndex) => {
            return (
              <ListItem
                key={`${index}-${nestedIndex}`}
                icon={item.icon}
                label={item.label}
                url={item.url}
              >
                {item.value}
              </ListItem>
            );
          })}
      </div>
    </Fragment>
  );
}

export default NestedList;
