import React from "react";
import { detailFieldsByInvestment } from "../config";
import InvestmentDetailItem from "components/investments/InvestmentView/InvestmentDetailItem";
import InvestmentCustomFieldItem from "components/investments/InvestmentView/InvestmentCustomFieldItem";
import InvestmentDetailItemLong from "components/investments/InvestmentView/InvestmentDetailItemLong";
import isEmptyValue from "components_new/lib/isEmptyValue";
import { some } from "lodash";

const MULTIPLE_CHOICE = "multiple_choice";

function hasDetails(list, investment) {
  const { simple, long } = list;

  return (
    some(simple, (field) => investment[field]) ||
    some(long, (field) => investment[field])
  );
}

export function hasCustomFieldsValues(customFields) {
  return (
    some(customFields, (customField) => !!customField.field_value) ||
    some(
      customFields,
      (customField) =>
        customField.field_type === MULTIPLE_CHOICE &&
        customField.field_data.some((choice) => Boolean(choice.value)),
    )
  );
}

function InvestmentDetailsList({
  investment,
  list,
  openReferrerModal,
  openContactDetailsView,
}) {
  const detailsList = list || detailFieldsByInvestment[investment.type];
  const customFields = Object.values(investment.custom_fields);
  const hasCustomFields = hasCustomFieldsValues(customFields);

  if (!hasDetails(detailsList, investment) && !hasCustomFields) {
    return null;
  }

  const { simple = [], long = [] } = detailsList;

  return (
    <div className="InvestmentDetailsList">
      <h3 className="InvestmentDetailsList-title">Investment Details</h3>
      <div className="InvestmentDetailsList-list">
        <div className="InvestmentDetailsList-shortList">
          {simple.map((detailName) => {
            const value = investment[detailName];

            if (isEmptyValue(value)) {
              return null;
            }

            return (
              <InvestmentDetailItem
                key={detailName}
                investment={investment}
                detailName={detailName}
                openReferrerModal={openReferrerModal}
                openContactDetailsView={openContactDetailsView}
              />
            );
          })}
          {customFields.map((customField) => {
            const {
              field_value: value,
              field_label: label,
              field_type: type,
              field_data: multipleChoiceValues,
              field_visible: visibility,
            } = customField;

            const multipleChoicesWithValues = multipleChoiceValues.filter(
              (choice) => Boolean(choice.value),
            );

            const customValue =
              type === MULTIPLE_CHOICE ? multipleChoicesWithValues : value;

            if (
              visibility === false ||
              customValue === null ||
              customValue.length === 0
            ) {
              return null;
            }

            return (
              <InvestmentCustomFieldItem
                key={type}
                label={label}
                value={customValue}
                type={type}
              />
            );
          })}
        </div>
        <div className="InvestmentDetailsList-longList">
          {long.map(
            (detailName) =>
              investment[detailName] && (
                <InvestmentDetailItemLong
                  key={detailName}
                  investment={investment}
                  detailName={detailName}
                />
              ),
          )}
        </div>
      </div>
    </div>
  );
}

export default InvestmentDetailsList;
