import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Form from "components_new/forms/Form";
import Button from "components_new/atoms/Button";
import Header from "components_new/atoms/Header";
import Loader from "components_new/atoms/Loader";
import Icon from "components_new/atoms/Icon";
import RequiredNote from "components_new/SidebarComponents/shared/RequiredNote";
import HtmlEditor from "components_new/elements/HtmlEditor";
import redirectFromInitialValue from "components_new/lib/redirectFromInitialValue";
import { SearchAndSelectField as SearchAndSelect } from "components_new/elements/SearchAndSelect";
import {
  addTaskIdToForm,
  addTaskAttachmentsToFormDocuments,
} from "components_new/SidebarComponents/forms/config";
import DropZone from "components_new/elements/DropZone";
import useForm from "lib/hooks/useForm";
import useDebounce from "lib/hooks/useDebounce";
import RemoteSearch from "components_new/elements/RemoteSearch";
import { merge, isEmpty } from "lodash";
import "./NoteForm.scss";

const INITIAL_VALUES = {
  formData: {
    id: null,
    selection: {},
    content: "",
    task_id: undefined,
    documents_data: [],
    note_type: null,
    created_at: null,
    updated_at: null,
  },
};

const TIME_TO_AUTOSAVE = 1500;

function showMessageStatus(submitAction, formData, isLoading) {
  const defaultText = (
    <a onClick={() => submitAction(formData)}>
      Unsaved changes, click to save <Icon icon="ri-pencil-line" size="tiny" />
    </a>
  );

  const showLoader = (
    <span>
      Autosaving <Loader active={true} inline size="tiny" />
    </span>
  );

  const showSavedAgo = (
    <span>Last autosave was {moment(formData.updated_at).fromNow()}</span>
  );
  return (
    <div className="showMessageStatus">
      {!formData.created_at &&
        !isLoading &&
        !isEmpty(formData.content) &&
        defaultText}
      {isLoading && showLoader}
      {formData.updated_at && !isLoading && showSavedAgo}
    </div>
  );
}

function NoteForm({
  closeSidebar,
  toogleSize,
  prefilledSelection,
  taskId,
  taskAttachments = [],
  showConfirmationMessage,
  updateUserAccountInformation,
  currentOrganizationUsername,
  currentUser,
  createNote,
  editNote,
  deleteNote,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isModeAutoSave, setIsModeAutoSave] = useState(
    currentUser.auto_save_notes || false,
  );
  const [showDropzone, setShowDropzone] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const wasSent = useRef(false);
  const initialValues = useMemo(() => {
    return merge(
      {},
      {
        formData: {
          ...INITIAL_VALUES.formData,
          selection: prefilledSelection || {},
        },
      },
      addTaskIdToForm(taskId),
      addTaskAttachmentsToFormDocuments(taskId, taskAttachments),
    );
  }, [taskId, taskAttachments, prefilledSelection]);

  const { formData, handleChange, handleErrors } = useForm(initialValues);
  const { selection, content } = formData;

  const handleCreationNote = useCallback(
    async (formData) => {
      setIsLoading(true);
      try {
        const { data: newNote } = await createNote(formData);
        setIsEditing(true);

        formData["id"] = newNote.id;
        formData["created_at"] = newNote.created_at;
        formData["updated_at"] = newNote.updated_at;
      } catch (error) {
        handleErrors(error);
      } finally {
        setIsLoading(false);
      }
    },
    [createNote, handleErrors],
  );

  const handleEditionnNote = useCallback(
    async (formData) => {
      setIsLoading(true);
      try {
        const filteredNewDocuments = formData.documents_data.filter(
          (document) => !document.id,
        );

        const newFormData = {
          ...formData,
          documents_data: filteredNewDocuments,
        };

        const { data: newNote } = await editNote(newFormData);
        formData["updated_at"] = newNote.updated_at;
        formData["documents_data"] = newNote.documents_data;
      } catch (error) {
        handleErrors(error);
      } finally {
        setIsLoading(false);
        wasSent.current = false;
      }
    },
    [editNote, handleErrors],
  );

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      isEditing
        ? await handleEditionnNote(formData)
        : await handleCreationNote(formData);
      closeSidebar();
      navigate(
        redirectFromInitialValue({
          formData,
          prefilledSelection,
          currentOrganizationUsername,
        }),
      );
      showConfirmationMessage();
    } catch (error) {
      handleErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  const currentSubmitAutoSave = useCallback(
    (formData) => {
      if (
        isModeAutoSave &&
        formData.selection.id &&
        !isEmpty(formData.content)
      ) {
        if (isEditing) {
          handleEditionnNote(formData);
        } else if (!wasSent.current) {
          wasSent.current = true;
          handleCreationNote(formData);
        }
      }
    },
    [handleCreationNote, handleEditionnNote, isEditing, isModeAutoSave],
  );

  const currentCancelAction = useCallback(() => {
    if (isModeAutoSave && formData.selection.id && !isEmpty(formData.content)) {
      deleteNote(formData).then(() => {
        closeSidebar();
      });
    } else {
      closeSidebar();
    }
  }, [isModeAutoSave, closeSidebar, deleteNote, formData]);

  useDebounce(formData, TIME_TO_AUTOSAVE, currentSubmitAutoSave);

  useEffect(() => {
    if (!showDropzone && taskAttachments.length > 0) {
      setShowDropzone(true);
    }
  }, [taskAttachments, showDropzone]);

  useEffect(() => {
    setDisableSubmit(isEmpty(selection.id) || isEmpty(content));
  }, [selection, content]);

  useEffect(() => {
    updateUserAccountInformation(currentUser.id, {
      ...currentUser,
      auto_save_notes: isModeAutoSave,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModeAutoSave]);

  useEffect(() => {
    if (formData.note_type !== "diligence") {
      handleChange("diligence_contact", null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.note_type]);

  const submitButtonText = isModeAutoSave ? "Save and Close" : "Save Note";
  const cancelButtonText = isModeAutoSave ? "Discard" : "Cancel";
  const modeText = isModeAutoSave ? "ON" : "OFF";
  const isNoteTypeDiligence = formData.note_type === "diligence";

  return (
    <Form onSubmit={handleSubmit} extraClassName="NoteForm">
      <Form.Header onClose={closeSidebar} onToogle={toogleSize}>
        <Header as="h3">Add a new note</Header>
      </Form.Header>
      <Form.Content>
        <div className="NoteForm-content">
          <SearchAndSelect
            name="selection"
            label="Company, Investment, or Deal*"
            placeHolder="Select a company, investment, or deal"
            onChange={(value) =>
              handleChange("selection", {
                ...value,
                company_id: isEmpty(value) ? null : value.id,
              })
            }
            scope="all"
            size="tiny"
            initialSelection={formData.selection}
          />
          {isModeAutoSave &&
            showMessageStatus(currentSubmitAutoSave, formData, isLoading)}
          <HtmlEditor
            name="content"
            label="Note*"
            placeholder="Add a note"
            value={formData.content}
            onChange={(_, { value }) => {
              handleChange("content", value);
            }}
            onChangeOptions={(value) => handleChange("note_type", value)}
            optionValue={formData.note_type}
            onAttachFilesClick={() => setShowDropzone(true)}
            showAddAttachmentLink={true}
            showOptions={true}
            rows="22"
            size="tiny"
          />
          {isNoteTypeDiligence && (
            <RemoteSearch
              value={formData.diligence_contact}
              onChange={(value) => handleChange("diligence_contact", value)}
              label="Diligence Contact"
              placeholder="Add diligence contact"
              scope="contacts"
              size="tiny"
              // allowAddItems
            />
          )}
          {showDropzone && (
            <DropZone
              onChange={(values) => handleChange("documents_data", values)}
              canMarkAsClosing={true}
              canChooseFileType={true}
              prefilledFiles={taskAttachments}
              size="tiny"
            />
          )}
        </div>
        <div className="NoteForm-extras">
          <RequiredNote />

          <a
            className="NoteForm-autoSave"
            rel="nofollow noopener"
            onClick={() => setIsModeAutoSave((prevState) => !prevState)}
          >
            Autosave is {modeText}
          </a>
        </div>
      </Form.Content>
      <Form.Footer>
        <Button
          disabled={disableSubmit}
          primary
          loading={isLoading}
          type="submit"
          size="small"
        >
          {submitButtonText}
        </Button>
        <Button
          onClick={currentCancelAction}
          disabled={isLoading}
          secondary
          type="button"
          size="small"
        >
          {cancelButtonText}
        </Button>
      </Form.Footer>
    </Form>
  );
}

export default NoteForm;
