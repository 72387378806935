import Api from "lib/Api";

import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";

export function useUpdatePortfolio() {
  const { organizationUsername } = useParams();
  const queryClient = useQueryClient();

  return useMutation(
    (formData) =>
      Api.patch(`/portfolios/${formData.id}`, {
        portfolio: formData,
      }),
    {
      onSuccess: ({ data: updatedPortfolio }) => {
        const key = ["Portfolios", organizationUsername];
        const portfoliosView = queryClient.getQueryData(key);

        if (portfoliosView?.portfolios) {
          queryClient.setQueryData(key, {
            ...portfoliosView,
            portfolios: portfoliosView.portfolios.map((portfolio) => {
              return portfolio.id === updatedPortfolio.id
                ? updatedPortfolio
                : portfolio;
            }),
            portfoliosById: {
              ...portfoliosView.portfoliosById,
              [`${updatedPortfolio.id}`]: updatedPortfolio,
            },
          });
        }
      },
      onError: (error) => {
        throw error;
      },
    },
  );
}
