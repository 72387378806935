import React from "react";
import TextInput from "components/shared/form/TextInput";
import verifyEmail from "lib/verifyEmail";

function EmailInput({ errorMessage, ...props }) {
  return (
    <TextInput
      {...props}
      validator={verifyEmail}
      errorMessage={
        errorMessage ||
        (props.componentValidationEnabled &&
        props.value &&
        !verifyEmail(props.value)
          ? "Email is not valid"
          : null)
      }
    />
  );
}

EmailInput.defaultProps = {
  componentValidationEnabled: true,
};

export default EmailInput;
