import React, { useState } from "react";
import EmptyPipelines from "./EmptyPipelines";
import useOrganizations from "store/organizations/hooks";

import { useCreatePipeline } from "components_new/views/Deals/hooks/mutations/useCreatePipeline";
import { useNavigate } from "react-router-dom";

function EmptyPipelinesWrapper() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const {
    currentOrganization,
    isCurrentUserOrganizationOwner,
  } = useOrganizations();

  const {
    has_pipelines: hasPipelines,
    private: isPrivateOrg,
  } = currentOrganization;

  const { mutate: createNewPipeline } = useCreatePipeline();

  const redirectToPipelineSettings = () => {
    const settingsPath = isPrivateOrg ? "personal-settings" : "settings";
    navigate(`/${currentOrganization.username}/${settingsPath}/pipeline`);
  };

  const handleCreatePipeline = async () => {
    setIsSubmitting(true);

    await createNewPipeline();
    redirectToPipelineSettings();

    setIsSubmitting(false);
  };

  return (
    <EmptyPipelines
      hasPipelines={hasPipelines}
      createPipeline={handleCreatePipeline}
      isSubmitting={isSubmitting}
      isOrganizationOwner={isCurrentUserOrganizationOwner}
    />
  );
}

export default EmptyPipelinesWrapper;
