import React from "react";
import PropTypes from "prop-types";

import cx from "classnames";
import Avatar from "components_new/elements/Avatar";
import ColumnedValueList from "components_new/atoms/ColumnedValueList";
import EllipsisDropdown from "components_new/elements/EllipsisDropdown";
import { useNavigate } from "react-router-dom";

import { Grid, List, Dimmer, Loader } from "@ableco/semantic-ui-react";
import Segment from "components_new/atoms/Segment";
import "semantic-ui-css/components/grid.css";
import "semantic-ui-css/components/loader.css";
import "semantic-ui-css/components/dimmer.css";

import "./RowCard.scss";

const avatarSize = (isHeader) => (isHeader ? "small" : "mini");

function RowCard({
  avatarData,
  columnedData,
  header,
  dimmer = false,
  rightLabel,
  rightLabelEmphasized,
  dropdown,
  dropdownItems,
  dropdownInformation,
  quickAction,
  link,
  onClick,
  hoverable,
}) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (link) {
      navigate(link);
    }
  };

  return (
    <Segment
      className={cx("RowCard", {
        "is-hoverable": hoverable,
        basic: header,
      })}
      onClick={onClick || handleClick}
    >
      <Dimmer active={dimmer} inverted>
        <Loader size="small" indeterminate>
          Preparing Report
        </Loader>
      </Dimmer>
      <Grid>
        <Grid.Row className="AvatarRow">
          <Grid.Column width={12}>
            <Avatar
              title={avatarData.title}
              subtitle={avatarData.subtitle}
              imageUrl={avatarData.imageUrl}
              avatarIcon={avatarData.avatarIcon}
              titleUrl={avatarData.titleUrl}
              size={avatarSize(!!header)}
              imagePosition={avatarData.imagePosition}
              utilityIcon={avatarData.utilityIcon}
            />
          </Grid.Column>
          {(rightLabel || dropdown || dropdownItems || quickAction) && (
            <Grid.Column
              floated="right"
              textAlign="right"
              verticalAlign="middle"
              width={4}
            >
              <List className="DropdownContainer" size="small" horizontal>
                {rightLabel && (
                  <List.Item className="ListItem">
                    {rightLabelEmphasized ? (
                      <span className="Label-Highlighted">{rightLabel}</span>
                    ) : (
                      rightLabel
                    )}
                  </List.Item>
                )}
                <List.Item>
                  {dropdownItems && (
                    <EllipsisDropdown
                      disabled={dropdownInformation.dropdownDisabled}
                      iconOrientation={
                        dropdownInformation.dropdownIconOrientation
                      }
                      iconSize={dropdownInformation.dropdownIconSize}
                      options={dropdownItems}
                    />
                  )}
                </List.Item>
                <List.Item>{dropdown}</List.Item>
                <List.Item>{quickAction}</List.Item>
              </List>
            </Grid.Column>
          )}
        </Grid.Row>
        {columnedData && (
          <ColumnedValueList
            extraClassname="ColumnData"
            data={columnedData}
            ellipsize={!header}
          />
        )}
      </Grid>
    </Segment>
  );
}

RowCard.defaultProps = {
  dropdownInformation: {
    dropdownIconSize: "small",
    dropdownIconOrientation: "vertical",
    dropdownDisabled: false,
  },
  rightLabel: "",
  hoverable: false,
};
RowCard.propTypes = {
  size: PropTypes.bool,
  hoverable: PropTypes.bool,
  avatarData: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    imageUrl: PropTypes.string,
    avatarIcon: PropTypes.string,
    titleUrl: PropTypes.func,
    imagePosition: PropTypes.string,
    utilityIcon: PropTypes.string,
  }),
  columnedData: PropTypes.arrayOf(PropTypes.shape({})),
  header: PropTypes.bool,
  rightLabel: PropTypes.string,
  dropdown: PropTypes.node,
  dropdownInformation: PropTypes.shape({
    dropdownIconOrientation: PropTypes.string,
    dropdownIconSize: PropTypes.string,
    dropdownDisabled: PropTypes.bool,
  }),
  dropdownItems: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      text: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  quickAction: PropTypes.node,
  dimmer: PropTypes.bool,
  rightLabelEmphasized: PropTypes.bool,
  link: PropTypes.string,
  onClick: PropTypes.func,
};

export default RowCard;
