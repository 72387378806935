import React from "react";
import PropTypes from "prop-types";
import { createCellClassNames } from "../config";

function HeaderCell({ cell, perCellClassNames }) {
  return (
    <div
      key={cell.text}
      className={createCellClassNames(cell, perCellClassNames)}
    >
      {cell.text}
    </div>
  );
}

function TableHeader({ columns, wrapperClassNames, perCellClassNames }) {
  return (
    <div className={wrapperClassNames}>
      {columns.map((cell, i) => (
        <HeaderCell key={i} cell={cell} perCellClassNames={perCellClassNames} />
      ))}
    </div>
  );
}

TableHeader.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  perCellClassNames: PropTypes.string,
  wrapperClassNames: PropTypes.string,
};

export default TableHeader;
