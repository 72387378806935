import TextInput from "components/shared/form/TextInput";
import NumberInput from "components/shared/form/NumberInput";
import TextArea from "components/shared/form/TextArea";
import CheckBoxGroup from "components/shared/form/CheckBoxGroup";
import RadioButtonGroup from "components/shared/form/RadioButtonGroup";
import CurrencyInput from "components_new/atoms/CurrencyInput";
import DatePicker from "components_new/atoms/DatePicker";

export const componentType = {
  text: TextInput,
  currency: CurrencyInput,
  number: NumberInput,
  date: DatePicker,
  textarea: TextArea,
  multiple_choice: CheckBoxGroup,
  single_choice: RadioButtonGroup,
};

export const componentSelectInputProps = [
  { value: "text", label: "Text Input" },
  { value: "currency", label: "Currency Input" },
  { value: "number", label: "Number Input" },
  { value: "date", label: "Date Input" },
  { value: "textarea", label: "Text Area" },
  { value: "multiple_choice", label: "Multiple Choice" },
  { value: "single_choice", label: "Single Choice" },
];

export const resourceTypes = [
  "Deal",
  "Equity",
  "Debt",
  "Fund",
  "LLC",
  "Option",
  "Warrant",
  "Safe",
  "Contact",
  "Company",
];

const multipleChoiceDefaultData = [
  { label: "Option One", value: false },
  { label: "Option Two", value: false },
  { label: "Option Three", value: false },
];

const singleChoiceDefaultData = [
  { label: "Option One", value: "Option One" },
  { label: "Option Two", value: "Option Two" },
  { label: "Option Three", value: "Option Three" },
];

export const fieldDataForChoiceFields = (fieldType) => {
  if (fieldType === "single_choice") {
    return singleChoiceDefaultData;
  } else if (fieldType === "multiple_choice") {
    return multipleChoiceDefaultData;
  } else {
    return [];
  }
};

export const defaultCustomFieldData = {
  field_label: null,
  helper_text: null,
  field_type: null,
  resource_types: [
    "equity",
    "debt",
    "fund",
    "llc",
    "option",
    "warrant",
    "deal",
    "safe",
    "contact",
    "company",
  ],
  resource_type_labels: [],
  field_data: [],
};

//We need to do this for now because exist two edge cases when user add MultipleChoice or TextArea
export function manageCustomFieldProps(type, customProps) {
  switch (type) {
    case "single_choice":
    case "multiple_choice":
      return {
        ...customProps,
        listStyle: "wide",
      };
    case "textarea":
      return {
        ...customProps,
        rows: "6",
      };
    case "number":
      return {
        ...customProps,
        placeholder: "0",
      };
    case "currency":
      return {
        ...customProps,
        placeholder: "0.0000",
        iconPosition: "left",
      };
    case "date":
      return {
        ...customProps,
        placeholder: "MM/DD/YYYY",
      };
    default:
      return { ...customProps };
  }
}

export default {
  componentSelectInputProps,
  componentType,
  resourceTypes,
  manageCustomFieldProps,
  defaultCustomFieldData,
  fieldDataForChoiceFields,
};
