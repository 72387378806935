import React from "react";
import classNames from "classnames";

import Value from "components_new/atoms/Value";
import Icon from "components_new/atoms/Icon";
import CompanyLinkValue from "components_new/views/Company/shared/CompanyLinkValue";
import { WEBSITE_PREFILL } from "components_new/views/Company/shared/companyProfileConfig";

import { List } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/list.css";
import "./InlineLabelValue.scss";

function ListItem({
  icon,
  label,
  url,
  toggleIcon,
  children,
  type,
  ...options
}) {
  const labelValueClassnames = classNames("InlineLabelValue", {
    PercentValue: type === "percentage",
    TextValue: type === "text",
    QuantityValue: type === "quantity",
    CurrencyValue: type === "currency",
    ListValue: type === "list",
  });
  return (
    <List.Item className={labelValueClassnames}>
      <List.Content floated="right" className="value" as="dd">
        <Value type={type} {...options}>
          {children}
        </Value>
      </List.Content>
      <List.Content className="label" as="dt">
        {icon && (
          <Icon
            extraClassnames="ListIcon"
            icon={icon}
            size="mini"
            alt={label}
          />
        )}
        <span className="ListLabel u-textWithEllipsis">
          {url ? (
            <CompanyLinkValue
              url={url}
              label={label}
              preFilled={WEBSITE_PREFILL}
              extraClassnames="ListLink u-textWithEllipsis"
            />
          ) : (
            label
          )}
        </span>
        {toggleIcon && (
          <Icon
            extraClassnames="ToggleIcon"
            icon={toggleIcon}
            size="mini"
            alt="Show Detail"
          />
        )}
      </List.Content>
    </List.Item>
  );
}

export default ListItem;
