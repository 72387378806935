import { connect } from "react-redux";
import NavigationTabs from "./NavigationTabs";

function mapStateToProps({ organizations: { currentOrganizationUsername } }) {
  return {
    currentOrganizationUsername,
  };
}

export default connect(mapStateToProps)(NavigationTabs);
