import { includes } from "lodash";

function doesItemMatchesWithValue(item, displayValue) {
  const isMatching = includes(
    item.label.toLowerCase(),
    displayValue.toLowerCase(),
  );
  return isMatching;
}

export default function filterOptions(value, options) {
  return options.filter((item) => doesItemMatchesWithValue(item, value));
}
