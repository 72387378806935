import React, { useContext, useEffect } from "react";
import { SidebarComponentsContext } from "../SidebarComponentsWrapper";
import ScrollView from "components_new/elements/ScrollView";
import Button from "components_new/atoms/Button";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

function FormFooter({
  componentName,
  onSubmit,
  isLoading,
  disableSubmit,
  touched,
  size,
  submitTextButton,
}) {
  const {
    getComponentState,
    getFormState,
    setWarningMessage,
    setEditingForm,
    updateSidebar,
    onUnmount,
  } = useContext(SidebarComponentsContext);
  const { submitTo, index, title } = getComponentState(componentName);
  const { isEditing } = getFormState(componentName);

  useEffect(() => {
    setEditingForm(componentName, touched);
    if (isEmpty(submitTo)) {
      touched
        ? updateSidebar({ warnForm: title })
        : updateSidebar({ warnForm: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touched]);

  const onClose = () => {
    if (isEditing) {
      setWarningMessage(componentName, true);
    } else {
      onUnmount(componentName, submitTo, index);
    }
  };

  return (
    <ScrollView.StickyFooter>
      <div className="SidebarForm-footer">
        <Button
          primary
          onClick={onSubmit}
          loading={isLoading}
          disabled={disableSubmit}
          type="submit"
          size={size}
        >
          {submitTextButton}
        </Button>
        <Button onClick={onClose} secondary size={size} disabled={isLoading}>
          Cancel
        </Button>
      </div>
    </ScrollView.StickyFooter>
  );
}

FormFooter.defaultProps = {
  size: "small",
  submitTextButton: "Save Changes",
};

FormFooter.propTypes = {
  componentName: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  disableSubmit: PropTypes.bool,
  touched: PropTypes.bool,
  size: PropTypes.string,
  submitTextButton: PropTypes.string,
};

export default FormFooter;
