export const UPSERT_COLLECTION_2 = "resourcesV2/UPSERT_COLLECTION_2";
export const CLEAR_COLLECTION_2 = "resourcesV2/CLEAR_COLLECTION_2";
export const UPSERT_RESOURCE = "resourcesV2/UPSERT_RESOURCE";
export const REMOVE_RESOURCE = "resourcesV2/REMOVE_RESOURCE";

export function upsertResource(collectionName, resource) {
  return { type: UPSERT_RESOURCE, collectionName, resource };
}

export function removeResource(collectionName, resourceId) {
  return { type: REMOVE_RESOURCE, collectionName, resourceId };
}

export function receiveCollection(collectionName, collection) {
  return { type: UPSERT_COLLECTION_2, collectionName, collection };
}

export function clearCollectionV2(collectionName) {
  return {
    type: CLEAR_COLLECTION_2,
    collectionName,
  };
}
