import React, { useContext, useMemo } from "react";
import { useDispatch } from "react-redux";
import { performSearch } from "store/search";
import useOrganizations from "store/organizations/hooks";
import IntroductionForm from "./IntroductionForm";
import { useCreateIntroduction } from "components_new/SidebarComponents/forms/IntroductionForm/hooks/useCreateIntroduction";
import { SidebarComponentsContext } from "components_new/SidebarComponents/SidebarComponentsWrapper";
import { map } from "lodash";

function converToDropdownElement(element) {
  return {
    key: element.user_id,
    text: element.full_name,
    value: element.user_id,
  };
}

function IntroductionFormWrapper({ ownName }) {
  const { currentOrganization } = useOrganizations();
  const { closeSidebar } = useContext(SidebarComponentsContext);
  const { mutateAsync: createIntroduction } = useCreateIntroduction();
  const dispatch = useDispatch();

  const handleSearchContact = (scope, query) =>
    dispatch(performSearch(scope, query)).then(
      ({ results: { [scope]: resultItems } }) => resultItems,
    );

  const users = useMemo(() => {
    return map(
      currentOrganization.organization_memberships,
      converToDropdownElement,
    );
  }, [currentOrganization.organization_memberships]);

  return (
    <IntroductionForm
      users={users}
      ownName={ownName}
      onCreate={createIntroduction}
      closeSidebar={closeSidebar}
      searchContact={handleSearchContact}
    />
  );
}

export default IntroductionFormWrapper;
