import { useMutation, useQueryClient } from "react-query";
import useOrganizations from "store/organizations/hooks";
import Api from "lib/Api";

export function useUpdateSnapshot() {
  const queryClient = useQueryClient();
  const { currentOrganizationName } = useOrganizations();

  return useMutation(
    ({ snapshotId, formData }) => {
      return Api.patch(`/snapshots/${snapshotId}`, {
        snapshot: formData,
      });
    },
    {
      onSuccess: ({ data: updatedSnapshot }, { snapshotId }) => {
        const key = ["SnapshotsView", currentOrganizationName];
        const snapshotView = queryClient.getQueryData(key);

        if (snapshotView?.snapshots.length > 0) {
          queryClient.setQueryData(key, {
            snapshots: snapshotView.snapshots.map((item) =>
              item.id === snapshotId ? updatedSnapshot : item,
            ),
          });
        }
      },
    },
  );
}
