import React from "react";
import pluralize from "pluralize";

function ItemsCounter({
  title,
  totalItems,
  currentItems,
  maxItemsPerCall,
  className,
}) {
  const pluralizedTitle = pluralize(title, currentItems);
  const counter =
    totalItems > maxItemsPerCall
      ? `${currentItems} out of ${totalItems} ${pluralizedTitle}`
      : `${currentItems} ${pluralizedTitle}`;

  return <div className={className}>{counter}</div>;
}

export default ItemsCounter;
