import Api from "lib/Api";
import { collectionFetchSuccess } from "store/domain/domainActions";
import { receiveCollections } from "store/entities";

export function reloadDistributions(investmentId) {
  return (dispatch) => {
    return Api.get(`/investments/${investmentId}/distributions`).then(
      ({ data: distributions }) => {
        dispatch(collectionFetchSuccess("distributions", distributions));
        return distributions;
      },
    );
  };
}

export function bulkUpdateDistributions(investmentId, payload) {
  return (dispatch) => {
    return Api.patch(
      `/v2/investments/${investmentId}/actions/distributions/bulk`,
      {
        operations: payload,
      },
    ).then(({ data: { entities } }) => {
      dispatch(receiveCollections(entities));

      return { success: true };
    });
  };
}
