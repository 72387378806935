import React from "react";
import OrganizationMenu from "components_new/elements/OrganizationMenu/OrganizationMenu";
import useOrganizations from "store/organizations/hooks";
import { useDispatch } from "react-redux";
import useResourcesV2 from "store/resourcesV2/hooks";
import useSidebar from "store/sidebar/hooks";
import useCurrentUser from "hooks/useCurrentUser";
import { updateUserAccountInformation } from "store/users/actions";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";

function OrganizationMenuWrapper() {
  const {
    isCurrentUserOrganizationOwner,
    currentOrganization,
  } = useOrganizations();
  const { getOrganizationResourceAsCollection } = useResourcesV2();
  const {
    sidebar,
    openSidebar,
    closeSidebar,
    openGlobalFormWarn,
  } = useSidebar();
  const { warnForm } = sidebar;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();

  const organizationsList = getOrganizationResourceAsCollection(
    "organizations",
  );
  const privateOrganization = organizationsList.find((item) => item.private);

  const goToCreateOrganizationSidebar = () =>
    openSidebar("organizationForm", "Create New Organization");

  const updateUserLastViewedOrganization = (orgPath) => {
    const organization = organizationsList.find(
      (item) => item.username === orgPath,
    );
    return dispatch(
      updateUserAccountInformation(currentUser.id, {
        last_viewed_organization_param: organization.id,
      }),
    );
  };

  const navAway = (nextOrganizationUsername) => {
    navigate(`/${nextOrganizationUsername}/dashboard`);
    updateUserLastViewedOrganization(nextOrganizationUsername);
    closeSidebar();
  };

  const onOrgItemClick = (organizationPath) => {
    isEmpty(warnForm)
      ? navAway(organizationPath)
      : openGlobalFormWarn(warnForm, organizationPath, navAway);
  };

  return (
    <OrganizationMenu
      currentOrganization={currentOrganization}
      organizationsList={organizationsList}
      privateOrganizationUsername={privateOrganization.username}
      isCurrentUserOrganizationOwner={isCurrentUserOrganizationOwner}
      goToCreateOrganizationSidebar={goToCreateOrganizationSidebar}
      onOrgItemClick={onOrgItemClick}
    />
  );
}

export default OrganizationMenuWrapper;
