import { connect } from "react-redux";
import {
  createAccountFromInvitation,
  verifyInvitation,
  setCurrentInvitation,
} from "store/invitations/organization/actions";
import verifyCode from "actions/asynchronous/auth/verifyCode";
import { resendCode } from "actions/asynchronous/auth/resendCode";
import { forgotPassword } from "store/auth/actions";
import InvitationOrganization from "components/auth/invitationToOrganization/InvitationOrganization";
import { isAuthValid } from "lib/auth";
import { getOrganizationResourceAsCollection } from "store/resourcesV2/selectors";

function mapStateToProps(state, { params }) {
  const organizations = getOrganizationResourceAsCollection(
    state,
    "organizations",
  );
  const privateOrganization =
    organizations && organizations.find((item) => item.private);
  return {
    inviteCode: params.inviteCode,
    organizationMembershipId: params.organizationMembershipId,
    invitation: state.invitation,
    privateOrganization,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    verifyCode: (code) => dispatch(verifyCode(code)),
    resendCode: () => dispatch(resendCode()),
    createAccountFromInvitation: (
      user,
      inviteCode,
      organizationMembershipId,
      isRevokedInvite,
    ) => {
      return dispatch(
        createAccountFromInvitation(
          user,
          inviteCode,
          organizationMembershipId,
          isRevokedInvite,
        ),
      );
    },
    verifyInvitation: (
      inviteCode,
      organizationMembershipId,
      privateOrganization,
    ) =>
      dispatch(verifyInvitation(inviteCode, organizationMembershipId))
        .then((invitation) => {
          dispatch(setCurrentInvitation(invitation));
          if (isAuthValid()) {
            return {
              nextPath: `/${privateOrganization.username}/personal-settings/organizations`,
            };
          } else {
            return { nextPath: null };
          }
        })
        .catch(() => {
          return { nextPath: "/error" };
        }),
    forgotPassword: (email) => dispatch(forgotPassword(email)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvitationOrganization);
