import React from "react";
import { InvestmentNotes } from "../components";

function Notes({ notes }) {
  return (
    <div className="InvestmentDetails-notes">
      <InvestmentNotes notes={notes} />
    </div>
  );
}

export default Notes;
