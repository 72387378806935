import PropTypes from "prop-types";
import React from "react";
import MightyIcon from "components_new/atoms/MightyIcon";
import classnames from "classnames";
import queryString from "query-string";

const COMPANY_ICON_SIZES = {
  xlarge: { side_length: 75 },
  large: { side_length: 50 },
  medium: { side_length: 30 },
  small: { side_length: 16 },
};

const convertImageByUrl = (imageUrl, sideLength) => {
  const { url, query } = queryString.parseUrl(imageUrl);
  query.h = sideLength;
  query.w = sideLength;
  return `${url}/convert?${queryString.stringify(query)}`;
};

function CompanyIcon({
  src,
  extraClassnames = "",
  size = "medium",
  isBase64 = "",
  alt = "company icon",
}) {
  const companyIcon = {
    small: "company-sm",
    medium: "company-med",
    large: "company-lrg",
    xlarge: "company-xlrg",
  };

  const companyIconClassnames = classnames("CompanyIconWrapper", {
    [extraClassnames]: !!extraClassnames,
    [size]: !!size,
  });

  const sideLength = COMPANY_ICON_SIZES[size].side_length;

  const srcProps = {
    width: sideLength,
  };

  if (src && !isBase64) {
    const baseResolution = sideLength;
    const retinaResolution = baseResolution * 2;

    srcProps.srcSet = `${convertImageByUrl(src, baseResolution)} 1x,
        ${convertImageByUrl(src, retinaResolution)} 2x`;
  }

  const image = src ? (
    <img src={src} {...srcProps} alt={alt} />
  ) : (
    <MightyIcon icon={companyIcon[size]} />
  );
  return <div className={companyIconClassnames}>{image}</div>;
}

CompanyIcon.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  extraClassnames: PropTypes.string,
  size: PropTypes.oneOf(["xlarge", "large", "medium", "small"]),
  isBase64: PropTypes.string,
};

export default CompanyIcon;
