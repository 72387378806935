import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";

function LoginLayout({ children, extraClassnames }) {
  const loginClassnames = classnames("u-bg-utility-1 Login", {
    [extraClassnames]: !!extraClassnames,
  });

  return (
    <main role="main" className={loginClassnames}>
      {children}
    </main>
  );
}

LoginLayout.propTypes = {
  children: PropTypes.element,
  extraClassnames: PropTypes.string,
};

LoginLayout.defaultProps = {
  children: null,
  extraClassnames: "",
};

export default LoginLayout;
