import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

function OrganizationNavigate(props) {
  const { organizationUsername } = useParams();
  const navigate = useNavigate();

  const target = "/" + organizationUsername + props.to;

  useEffect(() => {
    navigate(target);
  }, [navigate, target]);

  return null;
}

export default OrganizationNavigate;
