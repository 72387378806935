import React, { useEffect, useState } from "react";
import ActiveTask from "./ActiveTask";
import TaskList from "./TaskList";
import { isEmpty, findIndex } from "lodash";
import "./TaskView.scss";

const isTaskUnread = (task) => {
  return task && !task.read_at;
};

const calculateNextActiveTask = (tasks, activeTask) => {
  const currentIndex = findIndex(tasks, ["id", activeTask.id]);
  const [next, previous] = [tasks[currentIndex + 1], tasks[currentIndex - 1]];

  return next || previous || null;
};

function TaskView({
  tasks,
  filters,
  updateFilters,
  tasksType,
  queueLabel,
  isLoading,
  organizationName,
  openSidebar,
  openRemoveTaskModal,
  markTaskAsRead,
  markTaskAsUnread,
  snoozeTask,
  completeTask,
  markAsPending,
  newTasksCount = 0,
  fetchNewTasks,
  handleOpenTaskForm,
  showSidebar,
}) {
  const [activeTask, setActiveTask] = useState(null);

  useEffect(() => {
    if (isEmpty(activeTask)) return;

    const taskIndex = findIndex(tasks, ["id", activeTask.id]);
    const freshCopy = tasks[taskIndex];

    if (activeTask !== freshCopy) {
      setActiveTask(freshCopy);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks]);

  const handleActiveTaskChange = (nextActiveTask) => {
    setActiveTask(nextActiveTask);

    if (isTaskUnread(nextActiveTask)) {
      markTaskAsRead({ taskId: nextActiveTask.id });
    }
  };

  const handleOpenRemoveTaskModal = () => {
    openRemoveTaskModal({
      taskId: activeTask.id,
      callback: () => setActiveTask(null),
    });
  };

  const handleMarkTaskUnread = () => {
    markTaskAsUnread({ taskId: activeTask.id });
    setActiveTask(null);
  };

  const handleSnoozeTask = (time) => {
    const formData = { snoozed_until: time };
    snoozeTask({ taskId: activeTask.id, formData });

    setActiveTask(null);
  };

  const handleCompleteTask = async () => {
    const nextActiveTask = calculateNextActiveTask(tasks, activeTask);
    await completeTask({ taskId: activeTask.id });

    handleActiveTaskChange(nextActiveTask);
  };

  const handleMarkAsPending = async () => {
    const nextActiveTask = calculateNextActiveTask(tasks, activeTask);
    await markAsPending({ taskId: activeTask.id });

    handleActiveTaskChange(nextActiveTask);
  };

  return (
    <div className="TaskView">
      <div className="TaskView-Alpha">
        <TaskList
          isLoading={isLoading}
          tasks={tasks}
          filters={filters}
          updateFilters={updateFilters}
          tasksType={tasksType}
          activeTask={activeTask}
          openSidebar={openSidebar}
          setActiveTask={handleActiveTaskChange}
          newTasksCount={newTasksCount}
          fetchNewTasks={fetchNewTasks}
          handleOpenTaskForm={handleOpenTaskForm}
        />
      </div>
      <div className="TaskView-Beta">
        <ActiveTask
          isLoading={isLoading}
          activeTask={activeTask}
          queueLabel={queueLabel}
          organizationName={organizationName}
          openSidebar={openSidebar}
          handleOpenTaskForm={handleOpenTaskForm}
          openRemoveTaskModal={handleOpenRemoveTaskModal}
          markTaskUnread={handleMarkTaskUnread}
          snoozeTask={handleSnoozeTask}
          completeTask={handleCompleteTask}
          markAsPending={handleMarkAsPending}
          showSidebar={showSidebar}
        />
      </div>
    </div>
  );
}

export default TaskView;
