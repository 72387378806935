import React, { createContext, useState, useContext, useCallback } from "react";
import getSidebar from "./SidebarComponents";

const SidebarContext = createContext();
const SetSidebarContext = createContext();

function SidebarProvider({ children }) {
  const [state, setState] = useState([]);
  return (
    <SidebarContext.Provider value={state}>
      <SetSidebarContext.Provider value={setState}>
        {children}
      </SetSidebarContext.Provider>
    </SidebarContext.Provider>
  );
}

function useSidebarList() {
  const context = useContext(SidebarContext);
  if (context === undefined) {
    throw new Error("useSidebarList must be used within a SidebarProvider");
  }
  return context;
}

function useSetSidebar() {
  const setState = useContext(SetSidebarContext);
  if (setState === undefined) {
    throw new Error("useSetSidebar must be used within a SetSidebarContext");
  }

  const showSidebar = useCallback(
    (sidebarName, options = {}) => {
      setState((prevState) => {
        const openedSidebar = prevState[prevState.length - 1];
        if (openedSidebar?.key === sidebarName) {
          prevState[0].props = options;
          return [...prevState];
        } else {
          return [...prevState, getSidebar(sidebarName, options)];
        }
      });
    },
    [setState],
  );

  const removeSidebar = useCallback(
    (key) =>
      setState((prevState) => prevState.filter((state) => state.key !== key)),
    [setState],
  );
  return { showSidebar, removeSidebar };
}

export { SidebarProvider, useSidebarList, useSetSidebar };
