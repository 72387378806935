export const fadeIn = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

export function animationFromLeft(argument = { delay: 0 }) {
  return {
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        ...argument,
        ease: "easeOut",
      },
    },
    hidden: {
      opacity: 0,
      x: 30,
    },
  };
}

export default {
  fadeIn,
  animationFromLeft,
};
