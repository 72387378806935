import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import DropZone from "components_new/elements/DropZone";
import Table, {
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableBody,
  TableCell,
} from "components_new/elements/Table";
import PropTypes from "prop-types";
import { documentPropType } from "PropTypes";
import FileRow from "components_new/elements/FileRow";
import "./InvestmentFiles.scss";

function InvestmentFiles({
  documents,
  saveInvestmentDocuments,
  updateFile,
  removeFile,
  currentOrganizationUsername,
}) {
  const [modifiedFileId, setModifiedFileId] = useState(null);
  const [currentAction, setCurrentAction] = useState(null);

  const setEditFile = (fileId) => {
    setCurrentAction("edit");
    setModifiedFileId(fileId);
  };

  const setDeleteFile = (fileId) => {
    setCurrentAction("delete");
    setModifiedFileId(fileId);
  };

  const resetFileActions = (fileId) => {
    if (fileId === modifiedFileId) {
      setModifiedFileId(null);
      setCurrentAction(null);
    }
  };

  const [documentsData, setDocumentsData] = useState([]);

  function uploadDocuments(documentsData) {
    documentsData.map((document) => {
      saveInvestmentDocuments(document);
    });
  }

  useEffect(() => {
    if (documentsData.length > 0) {
      uploadDocuments(documentsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentsData]);

  return (
    <div className="InvestmentFiles">
      {!isEmpty(documents) && (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>NAME</TableHeaderCell>
              <TableHeaderCell>TYPE</TableHeaderCell>
              <TableHeaderCell>UPLOAD DATE</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {documents.map((document) => {
              return (
                <TableRow key={document.id}>
                  <TableCell colSpan="3">
                    <FileRow
                      key={document.id}
                      file={document}
                      setEditFile={setEditFile}
                      setDeleteFile={setDeleteFile}
                      resetFileActions={resetFileActions}
                      currentAction={currentAction}
                      editAction={updateFile}
                      deleteAction={removeFile}
                      modifiedFileId={modifiedFileId}
                      currentOrganizationUsername={currentOrganizationUsername}
                      dropdownAlignment="left"
                      componentSize="small"
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
      <DropZone
        onChange={(values) => {
          setDocumentsData(values);
        }}
        noQueue={true}
        canMarkAsClosing={false}
        canChooseFileType={false}
      />
    </div>
  );
}

InvestmentFiles.propTypes = {
  documents: PropTypes.arrayOf(documentPropType).isRequired,
  currentOrganizationUsername: PropTypes.string.isRequired,
  investmentId: PropTypes.string,
  saveInvestmentDocuments: PropTypes.func,
  updateFile: PropTypes.func,
  removeFile: PropTypes.func,
};

export default InvestmentFiles;
