import Api from "lib/Api";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

export function useUpdateCompanyDocument() {
  const queryClient = useQueryClient();
  const { organizationUsername } = useParams();

  return useMutation(
    ({ document }) => Api.patch(`/documents/${document.id}`, { document }),
    {
      onSuccess: ({ data: updatedDocument }, { companyId }) => {
        return queryClient.setQueryData(
          ["Company", organizationUsername, companyId],
          (oldData) => ({
            ...oldData,
            companyDocuments: oldData.companyDocuments.map((document) =>
              document.id === updatedDocument.id ? updatedDocument : document,
            ),
          }),
        );
      },
    },
  );
}
