import React from "react";
import RealizedGainCard from "../../cards/RealizedGainCard";
import HoldingCard from "../../cards/HoldingCard";
import Connector from "-!svg-react-loader?!assets/icons/miscellaneous/connection.svg?name=Connector";

function LogWriteOffEvent({ historyAction, companies, organizationUsername }) {
  const {
    parent_investment: parentInvestment,
    action_params: actionParams,
  } = historyAction;
  const { cash_proceed } = actionParams;
  return (
    <div className="ContentEvent-view">
      <HoldingCard
        companyId={parentInvestment.company_id}
        companyLogoUrl={companies[parentInvestment.company_id].logo_url}
        companyName={companies[parentInvestment.company_id].name}
        investmentId={parentInvestment.id}
        investmentName={parentInvestment.name}
        investmentType={parentInvestment.type}
        costBasis={parentInvestment.cost_basis}
        quantity={parentInvestment.quantity}
        organizationUsername={organizationUsername}
      />
      <Connector className="ConnectorIcon" />
      <RealizedGainCard realizedGain={cash_proceed} />
    </div>
  );
}

export default LogWriteOffEvent;
