import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import InlineValueList from "components_new/atoms/InlineValueList";
import Icon from "components_new/atoms/Icon";
import Highlight from "components_new/atoms/Highlight";
import Avatar from "components_new/elements/Avatar";
import { Card as SemanticCard } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/card.css";
import "./Card.scss";

function renderHighlight(highlight, highlightMeta) {
  return (
    highlight && (
      <SemanticCard.Content className="borderless">
        <Highlight value={highlight} meta={highlightMeta} />
      </SemanticCard.Content>
    )
  );
}

function renderHeader(expanded, avatarInfo, handleToggle) {
  const utilityIcon = expanded ? "ri-arrow-up-s-line" : "ri-arrow-down-s-line";

  return (
    <SemanticCard.Content className="header">
      <div onClick={handleToggle} className="ToggleContainer">
        <Avatar
          title={avatarInfo.title}
          imageUrl={avatarInfo.imageUrl}
          avatarIcon={avatarInfo.avatarIcon}
          subtitle={avatarInfo.subtitle}
          imagePosition="right"
          size="medium"
          iconSize="large"
        />
        <Icon
          extraClassnames="ToggleIcon"
          icon={utilityIcon}
          alt={avatarInfo.title}
        />
      </div>
    </SemanticCard.Content>
  );
}

function renderAttributes(attributes, expanded) {
  const attributeClassNames = classNames("CardAttributesGroup", {
    hidden: !expanded,
  });
  return (
    attributes && (
      <SemanticCard.Content className={attributeClassNames}>
        <InlineValueList data={attributes} />
      </SemanticCard.Content>
    )
  );
}

function renderCardFooter(textFooter, onTextFooterClick) {
  return (
    textFooter && (
      <SemanticCard.Content
        className="CardFooter borderless"
        textAlign="center"
      >
        <a href="javascript:;" onClick={onTextFooterClick}>
          {textFooter}
        </a>
      </SemanticCard.Content>
    )
  );
}

function Card({
  title,
  imageUrl,
  subtitle,
  attributes,
  highlight,
  highlightMeta,
  textFooter,
  onTextFooterClick,
  avatarIcon,
  inline,
}) {
  const [expanded, setExpanded] = useState(true);
  const handleToggle = () => {
    setExpanded(!expanded);
  };
  const avatarInfo = {
    title,
    imageUrl,
    avatarIcon,
    subtitle,
    imagePosition: "right",
    size: "medium",
    iconSize: "large",
  };
  const cardClassname = classNames("Card", { Inline: !!inline });
  return (
    <SemanticCard className={cardClassname}>
      {renderHeader(expanded, avatarInfo, handleToggle)}
      {renderHighlight(highlight, highlightMeta)}
      {renderAttributes(attributes, expanded)}
      {renderCardFooter(textFooter, onTextFooterClick)}
    </SemanticCard>
  );
}

Card.propTypes = {
  title: PropTypes.string,
  imageUrl: PropTypes.string,
  subtitle: PropTypes.string,
  attributes: PropTypes.arrayOf(PropTypes.object),
  highlight: PropTypes.string,
  highlightMeta: PropTypes.string,
  textFooter: PropTypes.node,
  onTextFooterClick: PropTypes.func,
  avatarIcon: PropTypes.func,
  inline: PropTypes.bool,
};

export default Card;
