import React, { useContext, useEffect } from "react";
import { SidebarComponentsContext } from "../SidebarComponentsWrapper";
import Dimmer from "components_new/elements/Dimmer";
import Segment from "components_new/atoms/Segment";
import Header from "components_new/atoms/Header";
import Button from "components_new/atoms/Button";
import { makeWarningMessage } from "./config";
import classNames from "classnames";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

const FORM_WARNING_INTITIAL_VALUES = {
  innerFormWarning: {},
  nextSession: null,
  swapSessions: null,
};

function FormWarning({ componentName }) {
  const {
    getComponentState,
    updateSidebarComponent,
    unmountFormComponent,
    onUnmount,
    getFormState,
    setWarningMessage,
    clearSidebar,
    openSidebar,
  } = useContext(SidebarComponentsContext);
  const {
    index,
    title,
    submitTo,
    innerFormWarning,
    nextSession,
    swapSessions,
  } = getComponentState(componentName);
  const { showMessage, isEditing } = getFormState(componentName);
  const showWarning = showMessage || !isEmpty(innerFormWarning);

  useEffect(() => {
    if (swapSessions && !showMessage) {
      swapSessions();
    }
  }, [swapSessions, showMessage]);

  useEffect(() => {
    if (!isEmpty(nextSession)) {
      const { name, title, props } = nextSession;
      if (name !== componentName && name !== submitTo) {
        if (isEditing) {
          setWarningMessage(componentName, true);
        }
        updateSidebarComponent(componentName, {
          swapSessions: () => {
            clearSidebar();
            if (!isEmpty(submitTo)) {
              unmountFormComponent(submitTo);
            }
            unmountFormComponent(componentName);
            openSidebar(name, title, props);
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextSession]);

  const { warningMessage, warningAction } = innerFormWarning || {};
  const message = warningMessage ? warningMessage : makeWarningMessage(title);
  const warningButtonText = handleDiscard ? "Continue" : "Discard Changes";
  const handleDiscard = warningAction ? warningAction : swapSessions;

  const onDiscard = () => {
    handleDiscard ? handleDiscard() : onUnmount(componentName, submitTo, index);
  };
  const onCancel = () => {
    updateSidebarComponent(componentName, FORM_WARNING_INTITIAL_VALUES);
    setWarningMessage(componentName, false);
  };

  const formWarningClassnames = classNames("FormWarning", {
    warning: showWarning,
  });

  return (
    <Dimmer className={formWarningClassnames} active={showWarning}>
      <Segment>
        <Header as="h3" extraClassName="FormWarning-Prompt">
          Discard unsaved changes?
        </Header>
        <div className="FormWarning-Content">
          <p className="contentRegular">{message}</p>
        </div>
        <div className="FormWarning-Actions">
          <Button warning size="small" onClick={onDiscard}>
            {warningButtonText}
          </Button>
          <Button secondary size="small" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </Segment>
    </Dimmer>
  );
}

FormWarning.propTypes = {
  componentName: PropTypes.string.isRequired,
};

export default FormWarning;
