import Api from "lib/Api";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { fetchDashboardEvents } from "store/events/dashboardEvents/actions";

export function useDashboard({ organizationUsername }) {
  const dispatch = useDispatch();

  return useQuery(
    ["Dashboard", organizationUsername],
    async () => {
      const {
        data: {
          highlights: rawHighlights,
          investment_pace_items: investmentPaceItems,
          cash_flow_performance_items: cashFlowPerformanceItems,
          has_investments: hasInvestments,
        },
      } = await Api.get("/dashboard");

      await dispatch(fetchDashboardEvents({ replace: true }));

      const highlights = {
        totalCompanies: rawHighlights.total_companies,
        totalInvestments: rawHighlights.total_investments,
        totalInvestmentsAmount: rawHighlights.total_investments_amount,
        totalInvestmentsValue: rawHighlights.total_investments_value,
        totalCashReceived: rawHighlights.total_cash_received,
      };

      return {
        highlights,
        investmentPaceItems,
        cashFlowPerformanceItems,
        hasInvestments,
      };
    },
    {
      staleTime: 2000,
    },
  );
}
