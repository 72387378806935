import React, { useContext, useEffect } from "react";
import PortfolioForm from "./PortfolioForm";
import Loader from "components/shared/Loader";

import { SidebarComponentsContext } from "components_new/SidebarComponents/SidebarComponentsWrapper";
import { isEmpty } from "lodash";
import { useParams } from "react-router-dom";
import { usePortfolios } from "components_new/views/Portfolios/hooks/queries/usePortfolios";
import { useCreatePortfolio } from "components_new/views/Portfolios/hooks/mutations/useCreatePortfolio";
import { useUpdatePortfolio } from "components_new/views/Portfolios/hooks/mutations/useUpdatePortfolio";

function PortfolioFormWrapper({ ownName }) {
  const { closeSidebar, registerForm, getComponentState } = useContext(
    SidebarComponentsContext,
  );

  const { portfolioId } = getComponentState(ownName);
  const { organizationUsername } = useParams();

  const { data, isLoading } = usePortfolios(organizationUsername);
  const { mutateAsync: createPortfolio } = useCreatePortfolio();
  const { mutateAsync: updatePortfolio } = useUpdatePortfolio();

  useEffect(() => {
    if (!isEmpty(ownName)) {
      registerForm(ownName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isEditing = !!portfolioId;
  const portfolioData = data?.portfoliosById[portfolioId];

  function handleSubmitPortfolio(formData) {
    const submitAction = isEditing ? updatePortfolio : createPortfolio;
    return submitAction(formData);
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <PortfolioForm
      ownName={ownName}
      closeSidebar={closeSidebar}
      submitPortfolio={handleSubmitPortfolio}
      isEditing={isEditing}
      portfolioData={portfolioData}
      currentOrganizationUsername={organizationUsername}
    />
  );
}

export default PortfolioFormWrapper;
