const DIFF_TO_ALPHANUMERIC_AND_SPACES = /[^\d\sa-z]/gi;
const DIFF_TO_ALPHANUMERIC_AND_HYPHENS = /[^\da-z\-]/gi;

export const formatValue = (value) => {
  return value
    .trim()
    .replace(/\s\s+/g, " ")
    .split(" ")
    .join("-")
    .toLocaleLowerCase();
};

export const formatTextToName = (value) => {
  return value.replace(DIFF_TO_ALPHANUMERIC_AND_SPACES, "");
};

export const formatTextToUsername = (value) => {
  return value ? value.replace(DIFF_TO_ALPHANUMERIC_AND_HYPHENS, "") : "";
};
