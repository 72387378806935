import React, { useState } from "react";
import classnames from "classnames";
import { Form, Dropdown as SemanticDropdown } from "@ableco/semantic-ui-react";
import { isEqual } from "lodash";

import "semantic-ui-css/components/dropdown.css";
import "components_new/atoms/Dropdown/Dropdown.scss";

const SEMANTIC_ICON = "caret down";
const REMIX_ICON = "RemixIcon ri-arrow-down-s-line";
function DropdownWithCreation({
  label,
  items,
  onChange,
  defaultValue,
  extraClassnames,
  value,
  disabled = false,
  required = false,
  size = "default",
  readOnly = false,
  iconStyle = REMIX_ICON,
  onAddNew,
  errorMessage = null,
  ...restProps
}) {
  const [options, setOptions] = useState(items);
  const [newAdded, setNewAdded] = useState({});

  const handleChange = (event, { options, value }) => {
    const itemSelected = options.find((item) => item.value === value);
    if (!isEqual(itemSelected, newAdded)) onAddNew(false);
    if (onChange && itemSelected) onChange(event, itemSelected);
  };

  const handleAddNew = (event, { options, value }) => {
    const newOption = {
      key: options.length + 1,
      text: value,
      value,
    };
    setOptions([newOption, ...options]);
    setNewAdded(newOption);
    onChange(event, newOption);
    onAddNew(true);
  };

  const dropdownClassnames = classnames("Dropdown selection", {
    error: !!errorMessage,
    [extraClassnames]: !!extraClassnames,
    [size]: !!size,
  });

  const displayLabel = required ? label + "*" : label;
  const icon = iconStyle === "semantic" ? SEMANTIC_ICON : REMIX_ICON;

  return (
    <Form.Field className="DropdownWithCreation">
      {label && <label>{displayLabel}</label>}
      <SemanticDropdown
        className={dropdownClassnames}
        onChange={handleChange}
        onAddItem={handleAddNew}
        options={options}
        value={value}
        disabled={disabled}
        readOnly={readOnly}
        icon={icon}
        selection
        search
        allowAdditions
        {...restProps}
      />
      {errorMessage && <label className="errorMessage">{errorMessage}</label>}
    </Form.Field>
  );
}

export default DropdownWithCreation;
