import React, { useCallback, useState } from "react";
import ForgotPasswordView from "components/auth/forgotPassword/ForgotPasswordView";
import Errors from "lib/errors";
import Api from "lib/Api";

function ForgotPasswordContainer() {
  const [forgotPasswordSucceeded, setForgotPasswordSucceeded] = useState(false);
  const [forgotValidationErrors, setForgotValidationErrors] = useState({});

  const forgotPassword = useCallback(async (email) => {
    try {
      await Api.post(
        "/session/forgot",
        { email },
        { withoutOrganization: true, parseResponse: false },
      );
      setForgotPasswordSucceeded(true);
    } catch (error) {
      setForgotPasswordSucceeded(false);
      if (error.status === 422) {
        const { errors } = JSON.parse(error.response);
        setForgotValidationErrors(new Errors(errors).toObject());
      }
    }
  }, []);

  const handleSubmit = useCallback((email) => forgotPassword(email), [
    forgotPassword,
  ]);

  return (
    <ForgotPasswordView
      onSubmit={handleSubmit}
      forgotPasswordSucceeded={forgotPasswordSucceeded}
      forgotValidationErrors={forgotValidationErrors}
    />
  );
}

export default ForgotPasswordContainer;
