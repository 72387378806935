import Api from "lib/Api";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

export function useRemoveCoInvestment() {
  const queryClient = useQueryClient();
  const { organizationUsername } = useParams();

  return useMutation(
    ({ coInvestment }) => Api.delete(`/v2/co_investments/${coInvestment.id}`),
    {
      onSuccess: (_, { companyId, coInvestment }) => {
        const key = ["Company", organizationUsername, companyId];
        const companyView = queryClient.getQueryData(key);

        const collectionKey =
          coInvestment.investor.id === companyId
            ? "investorCoInvestments"
            : "companyCoInvestments";

        return queryClient.setQueryData(key, {
          ...companyView,
          [collectionKey]: companyView?.[collectionKey].filter(
            (currentItem) => currentItem.id !== coInvestment.id,
          ),
        });
      },
      onError: (error) => {
        throw error;
      },
    },
  );
}
