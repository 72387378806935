import Api from "lib/Api";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

export function useCreateIntroduction() {
  const { organizationUsername } = useParams();
  const queryClient = useQueryClient();

  return useMutation(
    (formData) => {
      return Api.post(`/introductions`, {
        introduction: formData,
      });
    },
    {
      onSuccess: ({ data: newIntroduction }) => {
        const key = ["Introductions", organizationUsername];
        const introductionsView = queryClient.getQueryData(key);
        if (introductionsView) {
          queryClient.setQueryData(key, [
            newIntroduction,
            ...introductionsView,
          ]);
        }
      },
      onError: (error) => {
        throw error;
      },
    },
  );
}
