import {
  SHOW_SIDEBAR_COMPONENT,
  HIDE_SIDEBAR_COMPONENT,
  RESET_COMPONENTS,
  MOUNT_SIDEBAR_COMPONENT,
  UNMOUNT_SIDEBAR_COMPONENT,
  UPDATE_SIDEBAR_COMPONENT,
} from "./actionTypes";
import reducer, { componentsInitialState } from "./componentsReducer";
import { useReducer } from "react";

export default () => {
  const [components, dispatch] = useReducer(reducer, componentsInitialState);

  const showSidebarComponent = (name) =>
    dispatch({
      type: SHOW_SIDEBAR_COMPONENT,
      name,
    });

  const hideSidebarComponent = (name) =>
    dispatch({
      type: HIDE_SIDEBAR_COMPONENT,
      name,
    });

  const resetSidebarComponents = () =>
    dispatch({
      type: RESET_COMPONENTS,
    });

  const mountSidebarComponent = (
    name,
    title,
    props = { entityId: null, data: {} },
    submitTo = "",
    displayType,
  ) =>
    !!name &&
    dispatch({
      type: MOUNT_SIDEBAR_COMPONENT,
      name,
      title,
      props,
      submitTo,
      displayType,
    });

  const unmountSidebarComponent = (name) =>
    dispatch({ type: UNMOUNT_SIDEBAR_COMPONENT, name });

  const updateSidebarComponent = (name, props = { data: {} }) => {
    dispatch({ type: UPDATE_SIDEBAR_COMPONENT, name, props });
  };

  const getComponentState = (name) => {
    const parentPolicy = components[0] && components[0].sidebarPolicy;
    const nextSession = components[0] && components[0].nextSession;
    const isMoreThanOne =
      components.filter((component) => !!component.visible).length > 1;

    return components.reduce((componentState, component, index) => {
      componentState =
        component.name === name
          ? {
              ...component,
              index,
              sidebarPolicy: parentPolicy,
              complexSidebar: isMoreThanOne,
              nextSession,
            }
          : componentState;
      return componentState;
    }, {});
  };

  return {
    componentStatus: components,
    showSidebarComponent,
    hideSidebarComponent,
    resetSidebarComponents,
    mountSidebarComponent,
    unmountSidebarComponent,
    updateSidebarComponent,
    getComponentState,
  };
};
