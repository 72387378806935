import React from "react";
import { useSetSidebar } from "components_new/elements/SidebarWrapper/Sidebar-context";

export const ToggleInvestmentSidebar = ({ title, children, investmentId }) => {
  const { showSidebar } = useSetSidebar();
  return (
    <a onClick={() => showSidebar("investmentView", { investmentId })}>
      {title || children}
    </a>
  );
};
