import { useRef } from "react";
import { fromEvent } from "rxjs";
import { debounceTime, take } from "rxjs/operators";
import scrollToElement from "lib/hooks/scrollToElement";

const views = {
  sidebar: "ScrollView",
};

const defaultOptions = {
  selector: views.sidebar,
  autofocus: true,
};

function useSmoothScroll(options = defaultOptions) {
  const mainElement = useRef(getRefMainElement(options.selector));

  const scrollView = ({ errors }) => {
    const keys = Object.keys(errors);
    const [firstElement] = getElementsWithErrors(keys);

    if (firstElement) {
      scrollToElement({
        container: mainElement,
        elementPosition: firstElement.top - 150,
      });

      options.autofocus &&
        fromEvent(mainElement.current, "scroll")
          .pipe(debounceTime(200), take(1))
          .subscribe(() => firstElement.ref.focus());
    }
  };

  return { scrollView };
}

function getRefMainElement(selector) {
  return document.getElementsByClassName(selector)?.[0] || {};
}

function getElementsWithErrors(elementsName) {
  return elementsName
    .map((elementName) => {
      const element = document.getElementsByName(elementName)?.[0] || {};

      return { ref: element, top: element.getBoundingClientRect?.().top };
    })
    .sort((a, b) => a.top - b.top);
}

export default useSmoothScroll;
