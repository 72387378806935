import Api from "lib/Api";
import {
  mutationStart,
  mutationFail,
  mutationSuccess,
} from "store/domain/domainActions";

export function updateUserAccountInformation(userId, payload) {
  return (dispatch) => {
    dispatch(mutationStart("updateUserAccountInformation"));
    return Api.patch(
      `/users/${userId}`,
      { id: userId, user: payload },
      { withoutOrganization: true },
    )
      .then(({ data: updatedUser }) => {
        dispatch(mutationSuccess("updateUserAccountInformation"));
        return { user: updatedUser };
      })
      .catch((error) => {
        dispatch(mutationFail("updateUserAccountInformation"));
        throw error;
      });
  };
}

export function updatePassword(formData) {
  return () => {
    const {
      current_password,
      new_password,
      new_password_confirmation,
    } = formData;
    return Api.patch(
      "password",
      {
        current_password,
        new_password,
        new_password_confirmation,
      },
      { withoutOrganization: true },
    )
      .then()
      .catch((error) => {
        throw error;
      });
  };
}

export function updateNotifications(formData) {
  return () => {
    return Api.patch(`/user_email_setting`, formData, {
      withoutOrganization: true,
    }).then(({ data: user }) => {
      return user;
    });
  };
}
