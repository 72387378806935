import {
  UPDATE_FORM,
  START_DOCUMENT_EDITION,
  STOP_DOCUMENT_EDITION,
  HYDRATE_FORM_DATA,
  RESET_FORM,
  RESET_DOCUMENT_FORM_DATA,
} from "./actionTypes";

const initialState = {
  formData: {},
  editionVisibilities: {},
  hasFormChangedStates: {},
  errors: {},
};

function documentsReducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE_FORM_DATA:
      return {
        ...state,
        formData: {
          ...action.formData,
        },
      };
    case UPDATE_FORM:
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.documentId]: {
            ...state.formData[action.documentId],
            [action.key]: action.value,
          },
        },
        hasFormChangedStates: {
          ...state.hasFormChangedStates,
          [action.documentId]: true,
        },
      };
    case RESET_FORM:
      return {
        ...state,
        formData: {},
        hasFormChangedStates: {},
        editionVisibilities: {},
      };

    case RESET_DOCUMENT_FORM_DATA:
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.givenDocument.id]: { ...action.givenDocument },
        },
      };
    case START_DOCUMENT_EDITION:
      return {
        ...state,
        editionVisibilities: {
          ...state.editionVisibilities,
          [action.documentId]: true,
        },
      };
    case STOP_DOCUMENT_EDITION:
      return {
        ...state,
        editionVisibilities: {
          ...state.editionVisibilities,
          [action.givenDocument.id]: false,
        },
        hasFormChangedStates: {
          ...state.hasFormChangedStates,
          [action.givenDocument.id]: false,
        },
        formData: {
          ...state.formData,
          [action.givenDocument.id]: {
            ...action.givenDocument,
          },
        },
      };
    default:
      return state;
  }
}

export default documentsReducer;
