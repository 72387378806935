import React, { Fragment } from "react";
import PropTypes from "prop-types";
import EllipsisDropdown from "components_new/elements/EllipsisDropdown";
import Dropdown from "components_new/atoms/Dropdown";

function ContactActionsDropdown({ dropdownType = "button", onDelete, onEdit }) {
  const actionsOptions = [
    {
      key: 1,
      text: "Edit Contact",
      value: 1,
      onClick: () => onEdit(),
    },
    {
      key: 2,
      text: "Delete Contact",
      value: 2,
      onClick: () => onDelete(),
    },
  ];

  return (
    <Fragment>
      {dropdownType === "button" ? (
        <Dropdown text="Actions" size="small" button options={actionsOptions} />
      ) : (
        <EllipsisDropdown
          iconOrientation="vertical"
          iconSize="small"
          options={actionsOptions}
        />
      )}
    </Fragment>
  );
}

ContactActionsDropdown.propTypes = {
  dropdownType: PropTypes.oneOf(["button", "ellipsis"]).isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default ContactActionsDropdown;
