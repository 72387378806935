const MIN_MARGIN_BETWEEN_POINTS = 60;

function filterClutteredTicks(ticks, minPoint, maxPoint, axis) {
  const totalPoints = ticks.length;

  if (totalPoints === 1) {
    const firstPointPosition = axis.toPixels(ticks[0], true);
    return firstPointPosition > minPoint + MIN_MARGIN_BETWEEN_POINTS
      ? []
      : ticks.slice(0);
  }

  const minPointPosition = axis.toPixels(minPoint, true);
  const firstPointPosition = axis.toPixels(ticks[0], true);
  const filteredTicksAtStart =
    firstPointPosition > minPointPosition + MIN_MARGIN_BETWEEN_POINTS
      ? ticks.slice(0)
      : ticks.slice(1);
  const maxPointPosition = axis.toPixels(maxPoint, true);
  const lastPointPosition = axis.toPixels(ticks[totalPoints - 1], true);
  const filteredTicksAtStartAndEnd =
    maxPointPosition > lastPointPosition + MIN_MARGIN_BETWEEN_POINTS
      ? filteredTicksAtStart.slice(0)
      : filteredTicksAtStart.slice(
          0,
          filteredTicksAtStart.length - 2 === 0
            ? 1
            : filteredTicksAtStart.length - 2,
        );

  return filteredTicksAtStartAndEnd;
}

export default function tickPositioner() {
  const min = this.dataMin;
  const max = this.dataMax;
  const ticks = this.tickPositions;
  const filteredTicks = ticks.filter((tick) => {
    return tick > min && tick < max;
  });

  const filteredClutteredTicks =
    filteredTicks.length > 0
      ? filterClutteredTicks(filteredTicks, min, max, this.chart.xAxis[0])
      : filteredTicks;
  const ticksWithMax = filteredClutteredTicks.concat([max]);
  const ticksWithMaxAndMin = [min].concat(ticksWithMax);
  const totalTicks = ticksWithMaxAndMin.length;
  const firstTickValue = ticksWithMaxAndMin[0];
  const lastTickValue = ticksWithMaxAndMin[totalTicks - 1];

  // NOTE: Force to Add info about ticks, since it is lost because the customization.
  ticksWithMaxAndMin.info = {
    unitName: "millisecond",
    higherRanks: {},
    totalRange: lastTickValue - firstTickValue,
  };

  return ticksWithMaxAndMin;
}
