import Api from "lib/Api";
import { collectionFetchSuccess } from "store/domain/domainActions";

export function filterCompanyTitle(companyTitle) {
  return (dispatch) => {
    return Api.get(`/company_titles/?name=${companyTitle}`).then(
      ({ data: suggestions }) => {
        dispatch(
          collectionFetchSuccess("companyTitleSuggestions", suggestions),
        );
      },
    );
  };
}

export function createCompanyTitle(companyTitle) {
  return () => {
    return Api.post("/company_titles", {
      company_title: { name: companyTitle },
    });
  };
}
