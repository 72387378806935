import React from "react";
import WarningButton from "components/shared/WarningButton";

function DeleteOrganization({
  organization,
  openValidateOrganizationDeletion,
  openConfirmOrganizationDeletion,
  disabled,
}) {
  const deletionAction = organization.can_be_deleted
    ? openConfirmOrganizationDeletion
    : openValidateOrganizationDeletion;
  return (
    <div className="FormSection DeleteOrganization">
      <h2 className="u-moduleHeading DeleteOrganization-title">
        Delete Organization
      </h2>
      <p className="DeleteOrganization-content">
        Before you delete an organization you first need to delete its
        <strong> Documents</strong> and <strong>Holdings</strong>.
      </p>
      <WarningButton
        onClick={() => deletionAction()}
        customClass="Button--inverse"
        disabled={disabled}
      >
        Delete Organization
      </WarningButton>
    </div>
  );
}

export default DeleteOrganization;
