export const COLUMN_HEADERS = [
  {
    value: "full_name",
    skeletonType: "name",
    title: "NAME",
    type: "string",
  },
  {
    value: "roles",
    skeletonType: "companyName",
    title: "COMPANY",
    type: "array",
  },
  {
    value: "tagline",
    skeletonType: "companyName",
    title: "Tagline",
    type: "string",
  },
  {
    value: "industries",
    skeletonType: "name",
    title: "INDUSTRY",
    type: "array",
  },
  {
    value: "tags",
    skeletonType: "tags",
    title: "TAGS",
    type: "array",
  },
  {
    value: "location",
    skeletonType: "location",
    title: "LOCATION",
    type: "string",
  },
  {
    value: "referral_count",
    skeletonType: "referrals",
    title: "REFERRALS",
    type: "numeric",
    sortOrder: "descending",
  },
  {
    value: "last_referral_date",
    skeletonType: "date_single_line",
    title: "LAST REFERRAL",
    type: "date",
  },
];
