import React, { useEffect } from "react";
import ReactHighcharts from "react-highcharts";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import classnames from "classnames";
import PropTypes from "prop-types";
import { investmentPropType, distributionPropType } from "PropTypes";
import {
  parseData,
  getDataForGraph,
  formatMoneyLabel,
  globalChartConfig,
  greenAreaSeries,
  labelsYAxis,
  xAxisChart,
  labelsXAxis,
  tooltipChart,
  plotOptionsChart,
  splineChartConfig,
  yAxisLineChart,
} from "lib/charts/historyChartConfig";
import {
  formatPriceInputPreview,
  formatNumberInputPreview,
} from "lib/abbreviatedFormat";
import formatDate from "lib/formatDate";

function ChartLabels({ numberOfDistributions, totalDistributed }) {
  return (
    <div className="ChartLabels">
      <div className="ChartLabels-content">
        Number of Distributions:{" "}
        <span className="ChartLabels-amount">{numberOfDistributions}</span>
      </div>
      <div className="ChartLabels-content">
        Total Distributions Proceeds:{" "}
        <span className="ChartLabels-amount">{totalDistributed}</span>
      </div>
    </div>
  );
}

function totalDistributedDataMapper(props) {
  return {
    y: Number(props.acumulative_proceed_amount),
  };
}

function distributionDataMapper(props) {
  const amount = props.proceed_amount;
  return {
    y: Number(amount),
    date: props.date,
    investment: props.investment,
    distribution_props: props,
  };
}

function addFakeDate(collection, firstDistributionDate) {
  const firstDate = new Date(firstDistributionDate);
  const pastDate = firstDate.getDate() - 7;
  const sevenDaysAways = new Date(firstDate.setDate(pastDate));
  const fakeData = {
    acumulative_proceed_amount: 0,
    amount: 0,
    date: sevenDaysAways,
    investment: { payout: 0 },
  };
  return collection.unshift(fakeData);
}

function renderHTMLDistributionTooltip() {
  const hasEquity = !!this.distribution_props.equity;
  const tooltipClassnames = classnames(
    "HistoryChartTooltip DistributionTooltip",
    {
      hide: this.id === null,
      hasEquity: hasEquity,
    },
  );

  return `
  <div class="${tooltipClassnames}">
    <div class="HistoryChartTooltip-date">
      ${formatDate(this.date, "ll")}
    </div>
    <span class="HistoryChartTooltip-amount">
    ${formatPriceInputPreview(Number(this.y))}
    </span>
    ${
      hasEquity
        ? `
      <div class="HistoryChartTooltip-content u-textWithEllipsis">
        <span class="HistoryChartTooltip-hasEquity">${this.distribution_props.equity.name}
      </div>
      `
        : ""
    }
  </div>`;
}

function DistributionHistoryChart({ collection, investment }) {
  useEffect(() => {
    NoDataToDisplay(ReactHighcharts.Highcharts);
  }, []);

  let reversedDistributions = collection.slice().reverse();
  reversedDistributions.map((distribution, index) => {
    if (index == 0) {
      distribution.acumulative_proceed_amount = distribution.proceed_amount;
    } else {
      distribution.acumulative_proceed_amount =
        Number(distribution.proceed_amount) +
        Number(reversedDistributions[index - 1].acumulative_proceed_amount);
    }
  });
  reversedDistributions.length > 0 &&
    addFakeDate(reversedDistributions, reversedDistributions[0].date);
  const dates =
    reversedDistributions.length > 0
      ? getDataForGraph(reversedDistributions, "date")
      : [investment.date];
  const numberOfDistributions = formatNumberInputPreview(collection.length);
  const totalDistributed = formatPriceInputPreview(
    Number(investment.total_proceed_amount),
  );
  const distributionData = parseData(
    reversedDistributions,
    distributionDataMapper,
  );
  const totalDistributionData = parseData(
    reversedDistributions,
    totalDistributedDataMapper,
  );

  const config = {
    ...globalChartConfig,
    tooltip: {
      ...tooltipChart,
    },
    plotOptions: {
      ...plotOptionsChart,
    },
    series: [
      {
        ...greenAreaSeries,
        name: "Distribution",
        type: "column",
        data: distributionData,
        tooltip: {
          pointFormatter: renderHTMLDistributionTooltip,
        },
      },
      {
        ...splineChartConfig,
        name: "Total Distributed",
        type: "spline",
        data: totalDistributionData,
      },
    ],
    xAxis: {
      ...xAxisChart,
      type: "datetime",
      labels: {
        ...labelsXAxis,
        formatter: function () {
          return formatDate(this.value, "monthAndDayNumeric");
        },
      },
      categories: dates,
    },
    yAxis: {
      ...yAxisLineChart,
      labels: {
        ...labelsYAxis,
        formatter: function () {
          return formatMoneyLabel(this.value);
        },
      },
    },
  };
  return (
    <div className="DistributionHistoryChart HistoryChart">
      <ChartLabels
        numberOfDistributions={numberOfDistributions}
        totalDistributed={totalDistributed}
      />
      <ReactHighcharts config={config} />
    </div>
  );
}

DistributionHistoryChart.propTypes = {
  collection: PropTypes.arrayOf(distributionPropType),
  investment: investmentPropType,
};

export default DistributionHistoryChart;
