import TooltipHolder from "components/shared/TooltipHolder";
import InfoIcon from "-!svg-react-loader?!assets/icons/utility/info.svg?name=InfoIcon";
import classnames from "classnames";
import PropTypes from "prop-types";
import { investmentsPropType } from "PropTypes";
import Loader from "components_new/atoms/Loader";
import Checkbox from "components_new/atoms/Checkbox";
import React from "react";

function RelatedInvestmentsTooltip({ investments, isLoading, handleChange }) {
  const tooltipContent = investments.map(
    ({
      series_name: seriesName,
      custom_name: customName,
      price_per_unit: pricePerUnit,
      id,
    }) => {
      return (
        <p key={id}>
          {customName || seriesName}
          {pricePerUnit && <span className="pps">{` (${pricePerUnit})`} </span>}
        </p>
      );
    },
  );

  const companyLabel = "Update share price for company";
  const investmentLabel =
    investments.length > 0 ? "and all related holdings" : "";

  const relatedInvestmentClassnames = classnames("RelatedInvestments Row", {
    "RelatedInvestments--loading": isLoading,
  });

  return (
    <div className={relatedInvestmentClassnames}>
      <Checkbox
        name="update_all"
        label={`${companyLabel} ${investmentLabel}`}
        className="grid-item"
        onChange={(_, { checked }) => handleChange("update_all", checked)}
      />
      {investments.length > 0 && (
        <React.Fragment>
          <div className="RelatedInvestments--tooltip">
            <TooltipHolder tooltip={tooltipContent} position="top">
              <InfoIcon className="infoIcon" />
            </TooltipHolder>
          </div>
          <Loader active={isLoading} inline size="tiny" />
        </React.Fragment>
      )}
    </div>
  );
}

export default RelatedInvestmentsTooltip;

RelatedInvestmentsTooltip.proptypes = {
  investments: investmentsPropType,
  isLoading: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};

RelatedInvestmentsTooltip.defaultProps = {
  investments: [],
};
