import React from "react";

import { Header } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/header.css";

function Highlight({ meta, size, textAlign, value }) {
  return (
    <Header size={size} textAlign={textAlign}>
      {value}
      <Header.Subheader>{meta}</Header.Subheader>
    </Header>
  );
}

Highlight.defaultProps = {
  size: "huge",
  textAlign: "center",
};

export default Highlight;
