import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import formatDate from "lib/formatDate";
import Text from "components_new/atoms/Text";

function isOverdue(date) {
  const today = new Date().setHours(0, 0, 0, 0);
  const dateToCompare = new Date(date).setHours(0, 0, 0, 0);
  return dateToCompare - today <= 0;
}

function Due({ date, unread, dateType = "monthAndDay" }) {
  const overdue = isOverdue(date);
  const dueClassnames = classNames("Due", {
    overdue,
    unread: !!unread,
  });

  return (
    <Text
      type={overdue ? "label-semibold" : "label-bold"}
      extraClassnames={dueClassnames}
      color={overdue ? "warning" : "brand-light"}
    >
      {`Due ${formatDate(date, dateType)}`}
    </Text>
  );
}

Due.propTypes = {
  date: PropTypes.string,
  overdue: PropTypes.bool,
  unread: PropTypes.bool,
  dateType: PropTypes.string,
};

export default Due;
