import Api from "lib/Api";
import { collectionFetchSuccess } from "store/domain/domainActions";

export function filterTag(tagName, tagType = "company") {
  return (dispatch) =>
    Api.get(`/tags/?name=${tagName}&tag_type=${tagType}`).then(
      ({ data: suggestions }) => {
        dispatch(collectionFetchSuccess("tagSuggestions", suggestions));
      },
    );
}

export function createTag(tagName, tagType = "company") {
  return () =>
    Api.post("/tags", {
      tag: { name: tagName, tag_type: tagType },
    });
}

export function deleteTag(tagId) {
  return () => {
    return Api.deleteMutation("removeTag", `/tags/${tagId}`, () => ({
      destructions: [{ from: "tagSuggestions", id: tagId }],
    }));
  };
}

export function editTag(tagId, tagName) {
  return () => {
    return Api.patchMutation(
      "updateTag",
      `/tags/${tagId}`,
      {
        tag: { name: tagName },
      },
      (tag) => ({
        upserts: [{ entity: tag, into: "tagSuggestions" }],
      }),
    );
  };
}

export function updateBulkTag(tagsIds) {
  return () => {
    return Api.patch("/tags/bulk_update", {
      tag: { tags_ids: tagsIds },
    });
  };
}
