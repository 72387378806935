import React, { Fragment } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Form, Input as SemanticInput } from "@ableco/semantic-ui-react";
import useInputId from "lib/hooks/useInputId";
import "semantic-ui-css/components/input.css";
import "semantic-ui-css/components/label.css";
import "./Input.scss";
import HelperMessage from "components/shared/form/HelperMessage";

import Tooltip from "../Tooltip";

function Input(props) {
  const {
    errorMessage,
    label,
    isRequired,
    showHelper,
    helperContent,
    extraClassnames,
    prefixSymbol,
    placeholder,
    size,
    textHelper,
    ...inputProps
  } = props;

  const id = useInputId();
  const inputClassnames = classNames("Input", {
    error: !!errorMessage,
    [extraClassnames]: !!extraClassnames,
  });

  const inputFiedClassnames = classNames("Input-field", {
    [size]: !!size,
    error: !!errorMessage,
  });

  const wrappedLabel = isRequired ? label + "*" : label;

  return (
    <Fragment>
      {showHelper ? (
        <Tooltip
          trigger={
            <Form.Field>
              {label && <label>{wrappedLabel}</label>}
              <SemanticInput className={inputClassnames} {...inputProps} />
              {errorMessage && (
                <label className="Input-errorMessage">{errorMessage}</label>
              )}
            </Form.Field>
          }
          content={helperContent}
          on="focus"
        />
      ) : (
        <Form.Field className={inputFiedClassnames}>
          {label && <label htmlFor={id}>{wrappedLabel}</label>}
          <SemanticInput
            id={id}
            label={prefixSymbol && { basic: true, content: prefixSymbol }}
            placeholder={placeholder}
            className={inputClassnames}
            size={size}
            aria-label={label ? label : placeholder}
            autoComplete="off"
            {...inputProps}
          />
          {textHelper && <HelperMessage text={textHelper} />}
          {errorMessage && (
            <label className="Input-errorMessage">{errorMessage}</label>
          )}
        </Form.Field>
      )}
    </Fragment>
  );
}

Input.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  showHelper: PropTypes.bool,
  helperContent: PropTypes.string,
  extraClassnames: PropTypes.string,
  prefixSymbol: PropTypes.string,
  isRequired: PropTypes.bool,
  type: PropTypes.string,
  size: PropTypes.oneOf(["tiny", "small"]),
  textHelper: PropTypes.string,
};

Input.defaultProps = {
  placeholder: null,
  disabled: false,
  errorMessage: null,
  showHelper: false,
  helperContent: null,
  prefixSymbol: null,
  isRequired: false,
  type: "text",
};
export default Input;
