import { connect } from "react-redux";
import React from "react";
import { createSelector } from "reselect";
import UserOrganizationList from "./UserOrganizationList";
import { flash } from "actions/synchronous/flash";
import { acceptInvitationToOrganization } from "store/invitations/organization/actions";
import { fetchCurrentUserOrganizationMemberships } from "store/organizations/organizationMembers/actions";
import { getCollection } from "store/domain/selectors";
import { getOrganizationResourceAsCollection } from "store/resourcesV2/selectors";
import { openInModal, ModalNames } from "store/modal";
import { fetchOrganizations } from "store/organizations/actions";
import View from "components/shared/View";
import useCurrentUser from "hooks/useCurrentUser";

function UserOrganizationListContainer(props) {
  const currentUser = useCurrentUser();

  return (
    <View
      currentUser={currentUser}
      mountAction={() => props.fetchCurrentUserOrganizationMemberships()}
      component={UserOrganizationList}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  const { invitation } = state;
  const organizations = getOrganizationResourceAsCollection(
    state,
    "organizations",
  );
  const privateOrganization = organizations.find(
    (organization) => organization.private,
  );
  const privateOrganizationUserName = privateOrganization.username;
  return {
    userOrganizationMemberships: getUserOrganizationMemberships(
      getCollection(state, "userOrganizationMemberships"),
    ),
    privateOrganizationUserName,
    invitation,
  };
}

const getUserOrganizationMemberships = createSelector(
  (memberships) => memberships,
  (memberships) => memberships,
);

function mapDispatchToProps(dispatch) {
  return {
    acceptInvitationToOrganization: (
      organizationUserName,
      organizationMembershipId,
    ) =>
      dispatch(
        acceptInvitationToOrganization(
          organizationUserName,
          organizationMembershipId,
        ),
      ).then((invitation) => {
        const organizationName = invitation.organization_name;
        dispatch(fetchOrganizations());
        dispatch(flash("acceptInvitationToOrganization", { organizationName }));
      }),
    openAcceptFromOtherInvitationModal: (invitation) =>
      dispatch(
        openInModal(ModalNames.acceptFromOtherInvitation, {
          invitation,
        }),
      ),
    openInvalidInvitationModal: () =>
      dispatch(openInModal(ModalNames.invalidInvitation)),
    fetchCurrentUserOrganizationMemberships: () =>
      dispatch(fetchCurrentUserOrganizationMemberships()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserOrganizationListContainer);
