import React from "react";
import DataCard from "components_new/elements/DataCard";
import ContactRow from "./ContactRow";

export default function CompanyContacts({
  title,
  testId,
  companyContacts,
  openContactDetailsView,
}) {
  return (
    <DataCard.Group title={title} data-testid={testId}>
      {companyContacts.map((employee) => {
        return (
          <ContactRow
            key={employee.id}
            contact={employee}
            openContactDetailsView={openContactDetailsView}
          />
        );
      })}
    </DataCard.Group>
  );
}
