import React from "react";
import { groupBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { openInModal, ModalNames } from "store/modal";
import { isSubmitting, isEntityFetching } from "store/domain/selectors";
import {
  createInvestmentPost,
  updateInvestmentPost,
  removeInvestmentPost,
} from "store/investments/actions";
import { reloadInvestmentAfterDocumentsV2 } from "store/investments/investment/actions";
import { getCollection } from "store/entities";
import DiscussionsList from "components_new/elements/Discussions/DiscussionsList";
import { getResource } from "store/resourcesV2/selectors";
import { useParams } from "react-router-dom";

function DiscussionsWrapper(props) {
  const { investmentId } = props;
  const { noteId } = useParams();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const {
    organizations,
    views: { investment: view },
  } = state;

  const currentOrganization = getResource(
    state,
    "organizations",
    organizations.currentOrganizationId,
  );
  const { organization_memberships } = currentOrganization;
  const usersById = groupBy(organization_memberships, "user_id");
  const posts = getCollection(
    state,
    "investmentPosts",
    view.investment_posts_ids,
  ).reverse();

  const createPost = (resource, payload) =>
    dispatch(createInvestmentPost(resource, payload)).then(() => {
      dispatch(reloadInvestmentAfterDocumentsV2(investmentId));
    });
  const updatePost = (resource, postId, payload) =>
    dispatch(updateInvestmentPost(resource, postId, payload)).then(() => {
      dispatch(reloadInvestmentAfterDocumentsV2(investmentId));
    });
  const removePost = (resource, postId) =>
    dispatch(removeInvestmentPost(resource, postId)).then(() => {
      dispatch(reloadInvestmentAfterDocumentsV2(investmentId));
    });
  const openDocumentsUploader = (options = {}) => {
    dispatch(openInModal(ModalNames.discussionDocuments, options));
  };

  const isSending =
    isSubmitting(state, "createInvestmentPost") ||
    isSubmitting(state, "updateInvestmentPost") ||
    isEntityFetching(state, "investments", investmentId);

  return (
    <DiscussionsList
      organizationUsername={organizations.currentOrganizationUsername}
      users={usersById}
      posts={posts}
      resource={{
        type: "Investment",
        investment_id: investmentId,
      }}
      noteToHighlight={noteId}
      isInlineAttachment={true}
      isSubmitting={isSending}
      createPost={createPost}
      updatePost={updatePost}
      removePost={removePost}
      openDocumentsUploader={openDocumentsUploader}
      containerElement="MainLayout-content"
    />
  );
}

export default DiscussionsWrapper;
