import React from "react";
import DealCard from "components/pipelines/deals/DealCard";
import { DefinitionList } from "components/shared/DefinitionList";

function DealSummary({ company, deal, organizationUsername }) {
  return (
    <div className="InvestmentSummary OverviewSection-block">
      <h5 className="OverviewSection-blockTitle">Converted Deal</h5>
      <DefinitionList>
        <DealCard
          extraClassnames="DealCard-Action"
          company={company}
          deal={deal}
          organizationUsername={organizationUsername}
        />
      </DefinitionList>
    </div>
  );
}

export default DealSummary;
