import { connect } from "react-redux";
import React from "react";
import OrganizationIntegrations from "./OrganizationIntegrations";
import { isSubmitting } from "store/domain/selectors";
import { isEmpty } from "lodash";
import {
  updateOrganizationIntegration,
  revokeSlackIntegration,
  fetchSlackChannels,
} from "store/organizations/integrations/actions";
import View from "components/shared/View";
import useOrganizations from "store/organizations/hooks";
import useCurrentUser from "hooks/useCurrentUser";

function RenderOrganizationIntegrations(props) {
  const { currentOrganization } = useOrganizations();
  const currentUser = useCurrentUser();
  const isOwner = currentOrganization.owner_id == currentUser.id;

  function actionsBeforeRender() {
    const { channels, fetchSlackChannels } = props;

    if (isEmpty(channels)) {
      fetchSlackChannels();
    }
  }

  return (
    <View
      mountAction={() => actionsBeforeRender()}
      component={OrganizationIntegrations}
      isOwner={isOwner}
      organization={currentOrganization}
      user={currentUser}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  const { config, integrations } = state;

  return {
    isSubmitting: isSubmitting(state, "updateOrganization"),
    channels: integrations.channels,
    slackUrl: config.slackClientId,
    slackAuthScope: config.slackAuthScope,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateOrganizationIntegration: (formData) =>
      dispatch(updateOrganizationIntegration(formData)),
    revokeSlackIntegration: () => dispatch(revokeSlackIntegration()),
    fetchSlackChannels: () => dispatch(fetchSlackChannels()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RenderOrganizationIntegrations);
