import { connect } from "react-redux";
import { bulkDocumentsUpdate } from "store/documents/actions";
import { ModalNames, closeModal, updateModalOptions } from "store/modal";
import DocumentsViewer from "./DocumentsViewer";

function mapStateToProps(state, { title, documents, subTitle }) {
  const {
    organizations: { currentOrganizationUsername },
  } = state;
  return { title, documents, subTitle, currentOrganizationUsername };
}

function updateModalProps(dispatch, props) {
  dispatch(updateModalOptions(ModalNames.documentsViewer, props));
}

function mapDispatchToProps(
  dispatch,
  { investmentId, fetchAfterUpdateDocuments, itemId },
) {
  return {
    bulkUpdate: (payload) =>
      dispatch(bulkDocumentsUpdate(investmentId, payload)).then((data) => {
        if (data.success) {
          updateModalProps(dispatch, {
            isUpdating: true,
          });
          fetchAfterUpdateDocuments(itemId).then((updatedDocuments) =>
            updateModalProps(dispatch, {
              documents: updatedDocuments,
              isUpdating: false,
            }),
          );
          return data;
        }
      }),
    onDone: () => dispatch(closeModal()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsViewer);
