import React, { useState } from "react";
import FormField from "components_new/atoms/Form/FormField";
import HtmlEditorExtras from "./HtmlEditorExtras";
import Textarea from "components_new/atoms/TextArea";
import Text from "components_new/atoms/Text";
import classNames from "classnames";
import "./HtmlEditor.scss";

import "semantic-ui-css/components/label.css";

function HtmlEditor({
  uploadedAttachments = [],
  isEditing,
  size,
  extraClassnames,
  onAttachFilesClick,
  removeAttachment,
  showAttachmentsList = false,
  showAddAttachmentLink = false,
  showOptions = false,
  onChangeOptions,
  isFocus,
  optionValue,
  errorMessage,
  label,
  name,
  ...otherProps
}) {
  const [isFocused, setIsFocused] = useState(false);
  const onRemoveDocument = (attachment) => {
    const attachFile = isEditing ? attachment : attachment.handle;
    removeAttachment(attachFile);
  };

  const htmlClassnames = classNames("HtmlEditor-content HtmlEditor", {
    [extraClassnames]: !!extraClassnames,
    [size]: !!size,
    "is-focus": isFocused,
  });

  const attachmentsList = Object.values(uploadedAttachments);

  return (
    <FormField className="HtmlEditor-field" size={size}>
      {label && (
        <Text as="label" htmlFor={name} type="body-2-regular">
          {label}
        </Text>
      )}
      <div className={htmlClassnames}>
        <Textarea
          autoFocus={isFocus}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          className="HtmlEditor-editor"
          data-testid="HtmlEditor"
          size={size}
          name={name}
          {...otherProps}
        />
        <HtmlEditorExtras
          showAttachmentsList={showAttachmentsList}
          showAddAttachmentLink={showAddAttachmentLink}
          attachments={attachmentsList}
          onAttachFilesClick={onAttachFilesClick}
          onRemoveDocument={onRemoveDocument}
          showOptions={showOptions}
          onChangeOptions={onChangeOptions}
          optionValue={optionValue}
        />
      </div>
      {!!errorMessage && (
        <label className="HtmlEditor-errorMessage">{errorMessage}</label>
      )}
    </FormField>
  );
}

export default HtmlEditor;
