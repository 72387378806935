import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import addPost from "lib/posts/addPost";
import Api from "lib/Api";

function addContactPost(oldData, newPost) {
  return {
    ...oldData,
    posts: addPost(oldData.posts, newPost),
  };
}

export function useCreateContactPost() {
  const queryClient = useQueryClient();
  const { organizationUsername } = useParams();

  return useMutation(
    ({ post, contactId }) =>
      Api.post(`v2/contacts/${contactId}/posts`, { post }),
    {
      onSuccess: ({ data: newPost }, { contactId }) => {
        return queryClient.setQueryData(
          ["Contacts", organizationUsername, contactId],
          (oldData) => addContactPost(oldData, newPost),
        );
      },
    },
  );
}
