import React from "react";
import PrimaryButton from "components/shared/PrimaryButton";
import AddIcon from "-!svg-react-loader?!assets/icons/utility/add.svg?name=AddIcon";
import MightyLogoIcon from "-!svg-react-loader?!assets/icons/rf-mighty-logo.svg?name=MightyLogoIcon";
import useSidebar from "store/sidebar/hooks";

function EmptyDashboard() {
  const { openSidebar } = useSidebar();
  const openPortfolioForm = () => openSidebar("portfolioForm", "Add New Fund");
  return (
    <div className="EmptyDashboard">
      <span className="EmptyDashboard-logo">
        <MightyLogoIcon />
      </span>
      <h1 className="u-heading EmptyDashboard-greet">
        Get Started! Create your First Portfolio
      </h1>
      <h2 className="u-subheading EmptyDashboard-subgreet">
        Every investment belongs in a Portfolio. You&rsquo;ll be able to create
        as many portfolios as you want, let&rsquo;s create the first one.
      </h2>
      <div className="EmptyDashboardBtn-wrap">
        <PrimaryButton
          customClass="EmptyDashboard-newPortfolioEmpty"
          size="default"
          onClick={() => openPortfolioForm()}
        >
          <AddIcon className="Button-icon" />
          <span className="Button-text">New Fund</span>
        </PrimaryButton>
      </div>
    </div>
  );
}

export default EmptyDashboard;
