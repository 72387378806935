import {
  ADD_DOCUMENTS_TO_UPLOAD_QUEUE,
  CLEAN_DOCUMENT_UPLOADS,
  REMOVE_DOCUMENT_FROM_QUEUE,
  REMOVE_UPLOADED_DOCUMENT,
  STORE_UPLOADED_DOCUMENT,
  UPDATE_UPLOADED_DOCUMENT,
  UPDATE_DOCUMENT_IN_QUEUE,
} from "actions/synchronous/documentUploads";

const initialState = {
  uploadQueue: [],
  uploadedDocuments: [],
};

function documentUploads(state = initialState, action) {
  switch (action.type) {
    case ADD_DOCUMENTS_TO_UPLOAD_QUEUE:
      return {
        ...state,
        uploadQueue: [...action.documents, ...state.uploadQueue],
      };
    case CLEAN_DOCUMENT_UPLOADS:
      return initialState;
    case REMOVE_DOCUMENT_FROM_QUEUE:
      const queueWithoutItem = state.uploadQueue.filter(
        (item) => item.id !== action.givenDocument.id,
      );

      return {
        ...state,
        uploadQueue: [...queueWithoutItem],
      };
    case REMOVE_UPLOADED_DOCUMENT:
      const withoutDocument = state.uploadedDocuments.filter(
        (item) => item.localId !== action.givenDocument.id,
      );

      return {
        ...state,
        uploadedDocuments: [...withoutDocument],
      };
    case STORE_UPLOADED_DOCUMENT:
      const uploadedDocument = action.uploadedDocument;
      uploadedDocument.localId = action.givenDocumentId;

      return {
        ...state,
        uploadedDocuments: [
          ...state.uploadedDocuments,
          action.uploadedDocument,
        ],
      };
    case UPDATE_UPLOADED_DOCUMENT:
      const updatedUploadedDocument = state.uploadedDocuments.map((item) => {
        if (item.localId !== action.localId) {
          return item;
        }

        return {
          ...item,
          ...action.options,
        };
      });
      return {
        ...state,
        uploadedDocuments: [...updatedUploadedDocument],
      };
    case UPDATE_DOCUMENT_IN_QUEUE:
      const updatedUploadQueue = state.uploadQueue.map((item) => {
        if (item.id !== action.givenDocument.id) {
          return item;
        }

        return {
          ...item,
          ...action.options,
        };
      });

      return {
        ...state,
        uploadQueue: [...updatedUploadQueue],
      };
    default:
      return state;
  }
}

export default documentUploads;
