import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import StageCard from "components/shared/StageCard";
import Button from "components_new/atoms/Button";
import Icon from "components_new/atoms/Icon";
import Link from "components_new/atoms/Link";
import preventerHandler from "lib/preventerHandler";
import actionFormType from "./shared/ActionFormType";
import reorderStagesOnDrop from "lib/reorderStagesOnDrop";
import { find } from "lodash";
import "./DealStages.scss";

const CARD_HEIGHT = 100;

function Stages({
  pipeline,
  stages,
  openStageFormModal,
  updateStagesOrder,
  isOwner,
}) {
  const showDeletAction = stages.length > 1;
  const handleDragEnd = ({ source, destination }) => {
    if (!destination || source.index === destination.index) {
      // if dropped out of bounds or back in place
      return;
    } else {
      const currentStage = find(stages, { order: source.index });
      const newStages = reorderStagesOnDrop(
        stages,
        source.index,
        destination.index,
      );

      updateStagesOrder({
        pipelineId: pipeline.id,
        stageId: currentStage.id,
        destination: destination.index,
        newStages,
      });
    }
  };

  const staticMinHeight = stages.length * CARD_HEIGHT;
  function getStyle(style, snapshot) {
    if (!snapshot.isDropAnimating) {
      return style;
    }
    return {
      ...style,
      transitionDuration: "0.001s",
    };
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId={pipeline.id} key={pipeline.id}>
        {(provided) => (
          <div
            className="DealStages-stagesSection"
            ref={provided.innerRef}
            style={{
              minHeight: staticMinHeight,
            }}
            data-testid="pipelineSettings"
            {...provided.droppableProps}
          >
            {stages.map((stage, index) => (
              <Draggable
                key={stage.id}
                // draggableId should be a string
                draggableId={stage.id.toString()}
                index={index}
                isDragDisabled={!isOwner}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getStyle(provided.draggableProps.style, snapshot)}
                  >
                    <StageCard
                      disabled={!isOwner}
                      key={stage.id}
                      stageName={stage.name}
                      stageDeals={stage.deals_count}
                      showDeleteAction={showDeletAction}
                      onClickEdit={() =>
                        openStageFormModal(actionFormType.edit, pipeline, stage)
                      }
                      onClickDelete={() =>
                        openStageFormModal(
                          actionFormType.delete,
                          pipeline,
                          stage,
                        )
                      }
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <Button
        iconPosition="left"
        primary
        disabled={!isOwner}
        onClick={preventerHandler(() =>
          openStageFormModal(actionFormType.create, pipeline),
        )}
      >
        <Icon icon="ri-add-line" />
        Add Stage
      </Button>
    </DragDropContext>
  );
}

function PipelineSettingsView(props) {
  return (
    <div className="DealStages">
      <div className="HeaderWithActiveDeals">
        <h2 className="FormSection-title u-sectionHeading">Deal Stages</h2>
        <Link
          extraClassnames="ui small secondary Button"
          to={`/${props.organizationUsername}/deals/active`}
        >
          View Deals
        </Link>
      </div>
      <Stages {...props} />
    </div>
  );
}

export default PipelineSettingsView;
