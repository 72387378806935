import Api from "lib/Api";
import useOrganizations from "store/organizations/hooks";
import { useQuery } from "react-query";

const getContactById = async (params) => {
  const contactId = params.queryKey[2];
  const { data } = await Api.get(`/v2/contacts/${contactId}`);
  return data;
};

export function useContact(contactId) {
  const {
    currentOrganization: { username: currentOrganizationUsername },
  } = useOrganizations();

  return useQuery(
    ["Contacts", currentOrganizationUsername, contactId],
    getContactById,
    {
      enabled: !!contactId,
      staleTime: 10000,
    },
  );
}
