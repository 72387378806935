import React, { useMemo } from "react";
import Dropdown from "components_new/atoms/Dropdown";
import Icon from "components_new/atoms/Icon";
import LogoGroup from "components_new/elements/LogoGroup";
import SkeletonDropdown from "./SkeletonDropdown";
import PropTypes from "prop-types";
import { portfoliosPropType } from "PropTypes";
import "./PortfoliosViewDropdown.scss";

function PortfoliosViewDropdown({
  portfolios,
  defaultValue,
  onChange,
  allPortfoliosLogoInfo,
  isLoading,
}) {
  allPortfoliosLogoInfo.name = "All Funds";

  const portfolioOptions = useMemo(() => {
    const options = portfolios.map((portfolio) => ({
      value: portfolio.id,
      key: portfolio.id,
      text: <LogoGroup items={portfolio} />,
    }));

    options.unshift({
      value: "all",
      key: "all",
      text: <LogoGroup items={allPortfoliosLogoInfo} />,
    });

    return options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolios]);

  return (
    <div className="PortfolioNameDropdown">
      {isLoading ? (
        <SkeletonDropdown />
      ) : (
        <Dropdown
          placeholder="Select Fund"
          fluid
          selection
          icon={<Icon icon="ri-arrow-down-s-line" />}
          options={portfolioOptions}
          value={defaultValue}
          onChange={onChange}
        />
      )}
    </div>
  );
}

PortfoliosViewDropdown.propTypes = {
  portfolios: portfoliosPropType,
  defaultValue: PropTypes.string,
  allPortfoliosLogoInfo: PropTypes.shape({
    name: PropTypes.string,
    companies_count: PropTypes.number,
    logo_urls: PropTypes.arrayOf(PropTypes.string),
    investments_count: PropTypes.number,
  }),
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
};

export default PortfoliosViewDropdown;
