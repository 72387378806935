import React, { useContext, useRef, useLayoutEffect } from "react";
import { SidebarComponentsContext } from "../SidebarComponentsWrapper";
import ScrollView from "components_new/elements/ScrollView";
import FormHeader from "./FormHeader";
import FormWarning from "./FormWarning";
import Dimmable from "components_new/elements/Dimmer/Dimmable";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import "./SidebarForm.scss";
import classNames from "classnames";

function SidebarForm({ componentName, children }) {
  const ref = useRef(null);
  const { getFormState, getComponentState } = useContext(
    SidebarComponentsContext,
  );
  const { showMessage } = getFormState(componentName);
  const { innerFormWarning, index } = getComponentState(componentName);
  const dimmed = showMessage || !isEmpty(innerFormWarning);

  useLayoutEffect(() => {
    if (dimmed) {
      ref.current.scrollTop = 0;
    }
  }, [dimmed]);

  const sidebarContentClassnames = classNames("SidebarForm-content", {
    "has-multipleSidebar": index !== 0,
  });

  return (
    <div ref={ref}>
      <Dimmable dimmed={dimmed}>
        <ScrollView scrollY={!dimmed}>
          <FormWarning componentName={componentName} />
          <FormHeader componentName={componentName} />
          <div className={sidebarContentClassnames}>{children}</div>
        </ScrollView>
      </Dimmable>
    </div>
  );
}

SidebarForm.propTypes = {
  componentName: PropTypes.string.isRequired,
  children: PropTypes.node,
};
export default SidebarForm;
