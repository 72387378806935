import { connect } from "react-redux";
import React from "react";
import CustomFields from "./CustomFields";
import {
  fetchCustomFields,
  createCustomField,
  editCustomField,
} from "store/customFields/actions";
import { getCollection, isSubmitting } from "store/domain/selectors";
import { openModal } from "store/modalV2";
import { flash } from "actions/synchronous/flash";
import View from "components/shared/View";
import useOrganizations from "store/organizations/hooks";
import useCurrentUser from "hooks/useCurrentUser";

function RenderCustomFields(props) {
  const currentUser = useCurrentUser();
  const {
    currentOrganization: { owner_id },
  } = useOrganizations();
  const isOwner = owner_id === currentUser.id;
  return (
    <View
      mountAction={() => props.fetchCustomFields()}
      component={CustomFields}
      isOwner={isOwner}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  return {
    customFields: getCollection(state, "customFields"),
    isSubmitting:
      isSubmitting(state, "createCustomField") ||
      isSubmitting(state, "updateCustomField"),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCustomFields: () => {
      dispatch(fetchCustomFields());
    },
    createCustomField: (formData) => {
      return dispatch(createCustomField(formData)).then(() => {
        dispatch(flash("createDeal"));
      });
    },
    editCustomField: (formData, customFieldId) => {
      return dispatch(editCustomField(formData, customFieldId)).then(() => {
        dispatch(flash("editDeal"));
      });
    },
    openDeleteCustomFieldModal: (customField) => {
      dispatch(
        openModal("deleteCustomField", {
          customField,
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderCustomFields);
