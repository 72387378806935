import React from "react";
import PropTypes from "prop-types";
import accounting from "accounting";
import numeral from "numeral";
import formatDate from "lib/formatDate";
import ListValue from "components/shared/ListValue";
import cx from "classnames";
import "./Value.scss";
import Link from "components_new/atoms/Link";
import MightyIcon from "components_new/atoms/MightyIcon";

import { formatPriceInputAbbreviated } from "lib/abbreviatedFormat";
import { isEmpty } from "lodash";

const valueTypes = {
  percentage: percentFDValue,
  text: textValue,
  quantity: quantityValue,
  currency: currencyValue,
  multiple: multipleValue,
  date: dateValue,
  tag: tagValue,
  abbreviated: abbreviatedCurrencyValue,
  multiple_choice: multipleChoice,
  gainloss: gainLossValue,
  link: linkValue,
};

function isNotNumber(value) {
  return isNaN(value) || value === "" || value === null;
}

function dateValue(value, { dateFormat = "semiDetailed" }) {
  if (isEmpty(value)) {
    return "N/A";
  } else {
    return formatDate(value, dateFormat);
  }
}

function percentFDValue(value, { precision = 2 }) {
  if (isNotNumber(value)) {
    return "--";
  }

  const number = Number(value);
  if (value < 0.1) {
    return `<${accounting.formatNumber(number, precision)}%`;
  } else {
    return `${accounting.formatNumber(number, precision)}%`;
  }
}

function tagValue(value, options) {
  if (isEmpty(value)) {
    return options.emptyValue === "" ? options.emptyValue : "N/A";
  } else {
    return value.map((tag) => tag.name).join(", ");
  }
}

function textValue(value) {
  if (isEmpty(value)) {
    return "--";
  } else {
    return value;
  }
}

const CURRENCY_DEFAULT_OPTIONS = {
  symbol: "$",
  precision: 0,
  format: {
    pos: "%s%v",
    neg: "-%s%v",
    zero: "%s%v",
  },
};
function currencyValue(value, { precision }) {
  if (isNotNumber(value)) {
    return "--";
  }

  const options = {
    ...CURRENCY_DEFAULT_OPTIONS,
    precision: precision || CURRENCY_DEFAULT_OPTIONS.precision,
  };

  return accounting.formatMoney(value, options);
}
function abbreviatedCurrencyValue(value, options) {
  if (isNotNumber(value)) {
    return "--";
  }

  const abbreviatedValue = formatPriceInputAbbreviated(value, options);
  return abbreviatedValue;
}

function quantityValue(value) {
  if (isNotNumber(value)) {
    return "--";
  } else {
    return accounting.formatNumber(value);
  }
}

function multipleValue(value) {
  if (isNotNumber(value)) {
    return "0x";
  } else {
    const multiple = numeral(value);
    return `${multiple.format("0,0.0")}x`;
  }
}

function multipleChoice(value) {
  return <ListValue list={value} attribute="label" />;
}

function gainLossValue(value, { precision }) {
  const gainLossClassNames = cx("gainloss", {
    "gainloss-gain": value > 0,
    "gainloss-loss": value < 0,
  });
  return (
    <span className={gainLossClassNames}>
      {currencyValue(value, { precision })}
    </span>
  );
}

function linkValue(value, options) {
  if (isEmpty(value)) {
    return "--";
  } else {
    return (
      <div className="link-cell">
        {options.showIcon && <MightyIcon icon="company-sm" size="mini" />}
        <Link
          title={value}
          maxCharsLength={30}
          to={options.href || "www.google.co"}
        />
      </div>
    );
  }
}

function Value({
  children,
  type,
  highlighted,
  secondary,
  extraClassName,
  light,
  ...options
}) {
  const className = cx("Value", {
    highlighted,
    secondary,
    light,
    [extraClassName]: !!extraClassName,
    gain: type === "gain",
    loss: type === "loss",
  });
  return (
    <span className={className}>{valueTypes[type](children, options)}</span>
  );
}

Value.propTypes = {
  highlighted: PropTypes.bool,
  secondary: PropTypes.bool,
  light: PropTypes.bool,
  type: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.array,
  ]),
  extraClassName: PropTypes.string,
};

Value.defaultProps = {
  type: "text",
  light: false,
};

export default Value;
