import React from "react";
import Button from "components_new/atoms/Button";
import Icon from "components_new/atoms/Icon";
import "./CircularIconButton.scss";

function CircularIconButton({ icon, children, ...rest }) {
  return (
    <Button size="mini" className="CircularIconButton" {...rest}>
      {icon && <Icon icon={icon} size="tiny" />}
    </Button>
  );
}

export default CircularIconButton;
