import Api from "lib/Api";
import {
  itemFetchStart,
  collectionFetchStart,
  itemFetchSuccess,
  collectionFetchSuccess,
  collectionFetchFail,
  singletonFetchStart,
  singletonFetchSuccess,
  singletonFetchFail,
} from "store/domain/domainActions";
import {
  buildPostResourceRoute,
  resourceTypeForValuation,
} from "lib/globalActionsHelper";
import { receiveCollection, receiveCollections } from "store/entities";
import { receiveView, updateView } from "store/views";
import {
  requestCompany,
  receiveCompany,
  insertCompany,
} from "actions/synchronous/shared";
import {
  UPDATE_COMPANY_OWNERSHIP_SUCCESS,
  UPDATE_COMPANY_VALUATION_SUCCESS,
} from "../companyView/actionTypes";
import { setResourceAvailability } from "store/resources/actions";
import { isInCurrentView } from "store/investments/investment/actions";

export function fetchCompany(companyId) {
  return (dispatch) => {
    dispatch(itemFetchStart("companies"));
    dispatch(collectionFetchStart("documents"));
    dispatch(requestCompany(companyId));
    return Api.get(`/companies/${companyId}`).then(
      ({ data: companyView }) => {
        dispatch(itemFetchSuccess("companies", companyId, companyView.company));
        dispatch(receiveCompany(companyView));
        dispatch(
          collectionFetchSuccess("documents", companyView.documents_data),
        );
      },
      () => {
        dispatch(setResourceAvailability(false));
        dispatch(collectionFetchFail("documents"));
        return Promise.reject();
      },
    );
  };
}

export function fetchCompanyV2(companyId) {
  return (dispatch) => {
    dispatch(itemFetchStart("companies"));
    dispatch(collectionFetchStart("documents"));
    dispatch(singletonFetchStart("companyView"));
    dispatch(requestCompany(companyId));
    return Api.get(`/v2/companies/${companyId}`).then(
      ({
        data: {
          company_view: companyView,
          entities,
          views: { company: view },
        },
      }) => {
        dispatch(receiveCollections(entities));
        dispatch(receiveView("company", view));

        dispatch(itemFetchSuccess("companies", companyId, companyView.company));
        dispatch(receiveCompany(companyView));
        dispatch(
          collectionFetchSuccess("documents", companyView.documents_data),
        );
        dispatch(singletonFetchSuccess("companyView"));
      },
      () => {
        dispatch(setResourceAvailability(false));
        dispatch(collectionFetchFail("documents"));
        dispatch(singletonFetchFail("companyView"));
        return Promise.reject();
      },
    );
  };
}

export function createCompany(companyName) {
  return (dispatch) => {
    const newCompany = {
      name: companyName,
    };
    return Api.post("/companies", { company: newCompany }).then(({ data }) => {
      dispatch(insertCompany(data));
      dispatch(itemFetchSuccess("allCompanies", data.id, data));
      dispatch(itemFetchSuccess("companies", data.id, data));
      return data.id;
    });
  };
}

export function createCompanyWithData(companyData) {
  return async (dispatch) => {
    const { data } = await Api.post("/companies", { company: companyData });
    dispatch(insertCompany(data));
    dispatch(itemFetchSuccess("allCompanies", data.id, data));
    dispatch(itemFetchSuccess("companies", data.id, data));
    return data.id;
  };
}

export function deleteCompany(companyId) {
  return () => {
    return Api.delete(`/companies/${companyId}`);
  };
}

export function updateCompany(companyId, formData) {
  return (dispatch) => {
    const {
      isNewCompany,
      companyName,
      acquisitions: acquisitionCompanies,
    } = formData;

    const isNewCompaniesOnAcquisition =
      acquisitionCompanies &&
      acquisitionCompanies.some((acq) => acq.isNewCompany);

    const companiesAcquisition = isNewCompaniesOnAcquisition
      ? acquisitionCompanies.map((acquisition) => {
          if (acquisition.isNewCompany) {
            return dispatch(createCompany(acquisition.companyName)).then(
              (acquisitionCompanyId) => {
                acquisition.company_id = acquisitionCompanyId;
                return acquisition;
              },
            );
          }
          return acquisition;
        })
      : [];

    const companiesAcquisitionPromise = Promise.all(companiesAcquisition);

    const companyStatusPromise = isNewCompany
      ? dispatch(createCompany(companyName))
      : Promise.resolve(formData.status_change_company_id);

    return Promise.all([
      companyStatusPromise,
      companiesAcquisitionPromise,
    ]).then((data) => {
      const updateAcquisition = [];
      formData.acquisitions &&
        formData.acquisitions.forEach((item, i) => {
          updateAcquisition.push(Object.assign({}, item, data[1][i]));
        });

      const payload = {
        ...formData,
        status_change_company_id: data[0],
        acquisitions: formData.acquisitions
          ? updateAcquisition
          : formData.acquisitions,
      };
      return Api.patchMutation(
        "updateCompany",
        `/companies/${companyId}`,
        {
          company: payload,
        },
        (company) => ({
          upserts: [{ entity: company, into: "companies" }],
        }),
      )
        .then((updatedCompany) => {
          dispatch(receiveCollections({ company_details: [updatedCompany] }));
        })
        .catch((error) => {
          throw error;
        });
    });
  };
}

export function updateCompanyV2(companyId, formData, withView = true) {
  return (dispatch) => {
    return Api.patch(`/v2/companies/${companyId}`, {
      company: formData,
    })
      .then(
        ({
          data: {
            company_view: companyView,
            entities,
            views: { company: view },
          },
        }) => {
          if (withView) {
            dispatch(receiveView("company", view));
          }
          dispatch(receiveCollections(entities));
          dispatch(receiveCompany(companyView));
        },
      )
      .catch((error) => {
        throw error;
      });
  };
}

export function saveCompanyDocuments(companyId, filepickerData) {
  return (dispatch, getState) => {
    const payload = {
      documents: {
        documentsData: filepickerData,
      },
    };

    const {
      views: { company: companyView },
    } = getState();

    return Api.postMutation(
      "createDocuments",
      `/companies/${companyId}/documents`,
      payload,
      (documents) => {
        let upserts = [];

        if (isInCurrentView(companyView, companyId)) {
          upserts = documents.map((doc) => ({
            entity: doc,
            into: "documents",
          }));

          const document_ids = documents.map((document) => document.id);
          dispatch(updateView("company", "document_ids", document_ids));
          dispatch(receiveCollection("documents", documents));
        }

        return {
          upserts: upserts,
        };
      },
    );
  };
}

export function updateCompanyLogo(companyId, { handle, filename }) {
  const formData = {
    logo_name: filename,
    logo_handle: handle,
  };
  return updateCompany(companyId, formData);
}

function updateCompanyOwnershipSuccess(fullyDilutedShares) {
  return { type: UPDATE_COMPANY_OWNERSHIP_SUCCESS, fullyDilutedShares };
}

export function updateCompanyOwnership(formData, companyId) {
  const payload = {
    company: formData,
  };

  return (dispatch) => {
    return Api.patch(`/companies/${companyId}/overview`, payload).then(() =>
      dispatch(updateCompanyOwnershipSuccess(formData.fully_diluted_shares)),
    );
  };
}

function updateCompanyValuationSuccess(valuation) {
  return { type: UPDATE_COMPANY_VALUATION_SUCCESS, valuation };
}

export function updateCompanyValuation(formData, companyId) {
  const payload = {
    company: formData,
  };

  return (dispatch) => {
    return Api.patch(`/companies/${companyId}/overview`, payload).then(() =>
      dispatch(updateCompanyValuationSuccess(formData.valuation)),
    );
  };
}

export function getRelatedInvestments(selection) {
  const resourcesType = resourceTypeForValuation(selection.type);
  const resourceId = selection.id;
  return Api.get(
    `/v2/${resourcesType}/${resourceId}/share_price_related_investments`,
  );
}

export function fetchCompanyPosts(companyId) {
  return () => {
    return Api.getCollection("companyPosts", `/companies/${companyId}/posts`);
  };
}

export function createPostOnCompany(resource, formData) {
  return () => {
    const postResourceRoute = buildPostResourceRoute(resource);
    return Api.postMutation(
      "createCompanyPost",
      `${postResourceRoute}/posts`,
      { post: formData },
      (companyPosts) => {
        return {
          upserts: [{ entity: companyPosts, into: "companyPosts" }],
        };
      },
    );
  };
}

export function updatePostOnCompany(resource, postId, formData) {
  return () => {
    const postResourceRoute = buildPostResourceRoute(resource);
    return Api.patchMutation(
      "updateCompanyPost",
      `${postResourceRoute}/posts/${postId}`,
      { post: formData },
      (companyPost) => {
        return {
          upserts: [{ entity: companyPost, into: "companyPosts" }],
        };
      },
    );
  };
}

export function removePostOnCompany(resource, postId) {
  const postResourceRoute = buildPostResourceRoute(resource);

  return () => {
    return Api.deleteMutation(
      "removeCompanyPost",
      `${postResourceRoute}/posts/${postId}`,
      () => {
        return {
          destructions: [{ from: "companyPosts", id: postId }],
        };
      },
    );
  };
}
