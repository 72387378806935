import { connect } from "react-redux";
import AcceptFromOtherInvitation from "./AcceptFromOtherInvitation";
import { closeModal } from "store/modal";
import {
  cleanInvitation,
  acceptInvitationToOrganization,
} from "store/invitations/organization/actions.js";
import { flash } from "actions/synchronous/flash";

function mapStateToProps(state) {
  const {
    organizations: { currentOrganizationUsername },
  } = state;
  return {
    currentOrganizationUsername,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    acceptInvitationToOrganization: (
      organizationUserName,
      organizationMembershipId,
    ) =>
      dispatch(
        acceptInvitationToOrganization(
          organizationUserName,
          organizationMembershipId,
        ),
      ).then((invitation) => {
        const organizationName = invitation.organization_name;
        dispatch(cleanInvitation());
        dispatch(closeModal());
        dispatch(flash("acceptInvitationToOrganization", { organizationName }));
      }),
    closeModal: () => {
      dispatch(cleanInvitation());
      dispatch(closeModal());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AcceptFromOtherInvitation);
