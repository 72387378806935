import React from "react";
import PropTypes from "prop-types";

import Menu from "components_new/atoms/Menu";
import TabMenuItem from "./TabMenuItem.js";
import "semantic-ui-css/components/menu.css";
import "./TabMenu.scss";
import classNames from "classnames";

function TabMenu({ tabs, extraClassnames, rightContent }) {
  const tabMenuClassnames = classNames("TabMenu ", {
    [extraClassnames]: !!extraClassnames,
  });
  return (
    <Menu
      className={tabMenuClassnames}
      pointing
      secondary
      data-testid="TabMenu"
    >
      {renderNewTabs(tabs)}
      {!!rightContent && <div className="right menu">{rightContent}</div>}
    </Menu>
  );
}

function renderNewTabs(tabs) {
  return tabs.map(
    (
      { name, to, active, onClick, disabled, position, count, textOnly },
      index,
    ) => (
      <TabMenuItem
        name={name}
        key={index}
        index={index}
        active={active}
        onClick={onClick}
        disabled={disabled}
        position={position}
        to={to}
        count={count}
        textOnly={textOnly}
        key={`tabmenuitem-${index}`}
      />
    ),
  );
}

const tabPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  position: PropTypes.string,
});

TabMenu.propTypes = {
  tabs: PropTypes.arrayOf(tabPropType),
  basicType: PropTypes.bool,
  extraClassnames: PropTypes.string,
  rightContent: PropTypes.node,
};

TabMenu.defaultProps = {
  basicType: false,
};

export default TabMenu;
