import { connect } from "react-redux";
import InputTag from "components_new/elements/InputTag";
import { createCompanyTitle } from "store/companyTitles/actions";

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onCreate: (companyTitleName) => {
      return dispatch(createCompanyTitle(companyTitleName));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InputTag);
