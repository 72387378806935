import { OPEN_MODAL, CLOSE_MODAL, UPDATE_MODAL_OPTIONS } from "./actionTypes";

export function openModal(modalName, props = {}) {
  return { type: OPEN_MODAL, modalName, props };
}

export function closeModal() {
  return { type: CLOSE_MODAL };
}

export function updateModalOptions(modalName, modalProps = {}) {
  return { type: UPDATE_MODAL_OPTIONS, modalName, modalProps };
}
