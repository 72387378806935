import React from "react";
import CoInvestmentsTable from "./CoInvestmentsTable";

function CompanyCoInvestmentsTab({
  companyId,
  investorCoInvestments,
  currentOrganizationUsername,
  openAddCoInvestmentForm,
  openEditCoInvestmentForm,
  openDeleteCoInvestment,
}) {
  return (
    <div className="CompanyCoInvestmentsTab">
      <CoInvestmentsTable
        companyId={companyId}
        currentOrganizationUsername={currentOrganizationUsername}
        investorCoInvestments={investorCoInvestments}
        openAddCoInvestmentForm={openAddCoInvestmentForm}
        openEditCoInvestmentForm={openEditCoInvestmentForm}
        openDeleteCoInvestment={openDeleteCoInvestment}
      />
    </div>
  );
}

export default CompanyCoInvestmentsTab;
