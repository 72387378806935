import Api from "lib/Api";
import useOrganizations from "store/organizations/hooks";
import { useQuery } from "react-query";
import { mapKeys, camelCase, uniqBy } from "lodash";

const sortByDate = (collection) => {
  return collection.sort((a, b) => new Date(a.date) - new Date(b.date));
};

function toCamelCase(data) {
  return mapKeys(data, (_, key) => camelCase(key));
}

const getCompanyById = async (params) => {
  const companyId = params.queryKey[2];
  const { data } = await Api.get(`/v2/companies/${companyId}`);

  const updatedData = toCamelCase(data);
  const sortedInvestments = sortByDate(updatedData.currentInvestments);
  const sortedExitedInvestments = sortByDate(updatedData.exitedInvestments);
  // TODO: This for now, since we need to received this up-to-date from the backend.
  const uniqueReferrers = uniqBy(updatedData.referrers, "id");
  const uniqueEmployees = uniqBy(updatedData.employees, "id");

  return {
    ...updatedData,
    exitedInvestments: sortedExitedInvestments,
    investments: sortedInvestments,
    referrers: uniqueReferrers,
    employees: uniqueEmployees,
  };
};

export function useCompany(companyId) {
  const {
    currentOrganization: { username: currentOrganizationUsername },
  } = useOrganizations();

  return useQuery(
    ["Company", currentOrganizationUsername, companyId],
    getCompanyById,
    {
      enabled: !!companyId,
      staleTime: 10000,
    },
  );
}
