import React from "react";
import classnames from "classnames";
import { itemsByPage as itemsByPageDefault } from "config/activityFeedConfig";
import ActivityFeedContent from "./ActivityFeedContent";
import Loader from "components/shared/Loader";

function ActivityFeed({
  events,
  eventsCount,
  investments,
  isFetching,
  extraClassnames = "",
  itemsByPage = itemsByPageDefault,
  fetchActivityFeed,
}) {
  const activityFeedClassnames = classnames("ActivityFeed", {
    isFetching: isFetching,
    [extraClassnames]: extraClassnames,
  });

  const hasMoreEventsToShow = eventsCount > events.length;

  return (
    <div className={activityFeedClassnames}>
      {isFetching ? (
        <Loader />
      ) : (
        <ActivityFeedContent
          events={events}
          hasMoreEventsToShow={hasMoreEventsToShow}
          investments={investments}
          isFetching={isFetching}
          itemsByPage={itemsByPage}
          fetchActivityFeed={fetchActivityFeed}
        />
      )}
    </div>
  );
}

export default ActivityFeed;
