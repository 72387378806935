export function updateTaskInScope({
  updatedTask,
  queryClient,
  organizationUsername,
  scope,
}) {
  const key = ["QueuesView", organizationUsername, scope];
  const queuesView = queryClient.getQueryData(key);

  if (queuesView?.tasks) {
    const updatedTasksList = queuesView?.tasks.map((task) =>
      task.id === updatedTask.id ? updatedTask : task,
    );

    queryClient.setQueryData(key, {
      ...queuesView,
      tasks: updatedTasksList,
    });
  }
}
