export default class customCalloutSymbol {
  constructor(_ReactHighcharts) {
    _ReactHighcharts.Highcharts.SVGRenderer.prototype.symbols.callout = this.customCallout;
  }

  customCallout = function (x, y, w, h, options) {
    let arrowLength = 8,
      halfDistance = 11,
      r = Math.min((options && options.r) || 0, w, h),
      safeDistance = r + halfDistance,
      anchorX = options && options.anchorX,
      anchorY = options && options.anchorY,
      path;
    //** */
    path = [
      "M",
      x + r,
      y,
      "L",
      x + w - r,
      y, // top side
      "C",
      x + w,
      y,
      x + w,
      y,
      x + w,
      y + r, // top-right corner
      "L",
      x + w,
      y + h - r, // right side
      "C",
      x + w,
      y + h,
      x + w,
      y + h,
      x + w - r,
      y + h, // bottom-right corner
      "L",
      x + r,
      y + h, // bottom side
      "C",
      x,
      y + h,
      x,
      y + h,
      x,
      y + h - r, // bottom-left corner
      "L",
      x,
      y + r, // left side
      "C",
      x,
      y,
      x,
      y,
      x + r,
      y, // top-left corner
    ];

    // Anchor on right side
    if (anchorX && anchorX > w) {
      // Chevron
      if (anchorY > y + safeDistance && anchorY < y + h - safeDistance) {
        path.splice(
          13,
          3,
          "L",
          x + w,
          anchorY - halfDistance,

          "L",
          x + w + arrowLength,
          anchorY - 3,

          "C",
          x + w + arrowLength + 2,
          anchorY - 1,
          x + w + arrowLength + 2,
          anchorY + 1,
          x + w + arrowLength,
          anchorY + 3,
          "L",
          x + w,
          anchorY + halfDistance,
          "L",
          x + w,
          y + h - r,
        );

        // Simple connector
      } else {
        path.splice(
          13,
          3,
          "L",
          x + w,
          h / 2,
          anchorX,
          anchorY,
          x + w,
          h / 2,
          x + w,
          y + h - r,
        );
      }

      // Anchor on left side
    } else if (anchorX && anchorX < 0) {
      // Chevron
      if (anchorY > y + safeDistance && anchorY < y + h - safeDistance) {
        path.splice(
          33,
          3,
          "L",
          x,
          anchorY + halfDistance,
          x - arrowLength,
          anchorY,
          x,
          anchorY - halfDistance,
          x,
          y + r,
        );

        // Simple connector
      } else {
        path.splice(33, 3, "L", x, h / 2, anchorX, anchorY, x, h / 2, x, y + r);
      }
    } else if (
      anchorY &&
      anchorY > h &&
      anchorX > x + safeDistance &&
      anchorX < x + w - safeDistance
    ) {
      // Arrow replace bottom
      path.splice(
        23,
        3,
        "L",
        anchorX + halfDistance,
        y + h,
        "L",
        anchorX + 3,
        y + h + arrowLength,

        "C",
        anchorX + 1,
        y + h + arrowLength + 2,
        anchorX - 1,
        y + h + arrowLength + 2,
        anchorX - 3,
        y + h + arrowLength,

        "L",
        anchorX - halfDistance,
        y + h,
        "L",
        x + r,
        y + h,
      );
    } else if (
      anchorY &&
      anchorY < 0 &&
      anchorX > x + safeDistance &&
      anchorX < x + w - safeDistance
    ) {
      // Arrow replace top
      path.splice(
        3,
        3,
        "L",
        anchorX - halfDistance,
        y,

        "L",
        anchorX - 3,
        y - arrowLength,

        "C",
        anchorX - 1,
        y - arrowLength - 2,
        anchorX + 1,
        y - arrowLength - 2,

        anchorX + 3,
        y - arrowLength,

        "L",
        anchorX + 3,
        y - arrowLength,

        "L",
        anchorX + halfDistance,
        y,

        "L",
        w - r,
        y,
      );
    }

    return path;
  };
}
