import React, { Fragment, useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import TextInput from "components/shared/form/TextInput";
import Select from "components/shared/form/Select";
import TextArea from "components/shared/form/TextArea";
import PrimaryButton from "components/shared/PrimaryButton";
import Pill from "components/shared/Pill";
import Api from "lib/Api";
import prettier from "prettier/standalone";
import jsonPrettierParser from "prettier/parser-babel";

function parseResponseBody(response, responseText) {
  const contentTypeHeader = response.headers.get("Content-Type");
  if (contentTypeHeader && contentTypeHeader.includes("application/json")) {
    return {
      contentType: "json",
      data: JSON.parse(responseText),
      rawData: responseText,
    };
  }
  return { contentType: "text", data: responseText, rawData: responseText };
}

function formatRawJSON(text) {
  return prettier.format(text, {
    parser: "json",
    plugins: [jsonPrettierParser],
  });
}

function ApiClientDevTool() {
  const [method, setMethod] = useState("get");
  const [path, setPath] = useState("");
  const [rawPayload, setRawPayload] = useState("{}");
  const [submittingState, setSubmittingState] = useState("none");
  const [responseInfo, setResponseInfo] = useState(null);

  const { parsedPayload, successfullyParsed } = useMemo(() => {
    try {
      return {
        parsedPayload: JSON.parse(rawPayload),
        successfullyParsed: true,
      };
    } catch (error) {
      return {
        parsedPayload: null,
        successfullyParsed: false,
      };
    }
  }, [rawPayload]);

  const callApi = useCallback(() => {
    switch (method) {
      case "get":
        return Api.get(path, { parseResponse: false });
      case "post":
      case "patch":
        return Api[method].call(Api, path, parsedPayload, {
          parseResponse: false,
        });
      case "delete":
        return Api.delete(path, { parseResponse: false });
    }
  }, [method, path, parsedPayload]);

  const saveResponse = useCallback((response, responseText, isError) => {
    const { contentType, data, rawData } = parseResponseBody(
      response,
      responseText,
    );
    setSubmittingState("loaded");
    setResponseInfo({
      data,
      rawData,
      response,
      contentType,
      isError,
      statusCode: response.status,
      statusText: response.statusText,
    });
  }, []);

  const sendRequest = useCallback(
    async (event) => {
      event.preventDefault();

      setSubmittingState("loading");

      const requestStartTime = Date.now();

      try {
        const { response, data: responseText } = await callApi();
        saveResponse(response, responseText, false);
      } catch (error) {
        if (error.response) {
          saveResponse(error.response, error.data, true);
        }
      } finally {
        setResponseInfo((info) => ({
          ...info,
          requestDurationInMs: Date.now() - requestStartTime,
        }));
      }
    },
    [callApi, saveResponse],
  );

  return (
    <Fragment>
      <div className="DevHome-content">
        <form onSubmit={sendRequest} className="ApiClient-form">
          <div className="ApiClient-methodInput">
            <Select
              placeholder={method}
              inputProps={[
                { label: "get", value: "get" },
                { label: "post", value: "post" },
                { label: "patch", value: "patch" },
                { label: "delete", value: "delete" },
              ]}
              onChange={setMethod}
            />
          </div>
          <div className="ApiClient-pathInput">
            <TextInput
              placeholder="e.g. /companies"
              onChange={setPath}
              value={path}
            />
          </div>
          <div className="ApiClient-submitButton">
            <PrimaryButton
              type="submit"
              filter={{
                applyingFilter: submittingState === "loading",
              }}
              disabled={
                (method === "post" || method === "patch") && !successfullyParsed
              }
            >
              Send Request
            </PrimaryButton>
          </div>
          {(method === "post" || method === "patch") && (
            <div className="ApiClient-payloadInput">
              <TextArea
                placeholder="JSON Payload"
                value={rawPayload}
                onChange={setRawPayload}
              />
            </div>
          )}
        </form>
      </div>
      {submittingState == "loaded" && responseInfo ? (
        <ResponseInfo {...responseInfo} />
      ) : null}
    </Fragment>
  );
}

function JSONTextArea({ value, ...props }) {
  const prettyValue = useMemo(() => formatRawJSON(value), [value]);
  return <TextArea value={prettyValue} {...props} />;
}

function ResponseInfo({
  statusCode,
  statusText,
  data,
  rawData,
  requestDurationInMs,
  contentType,
}) {
  const statusClassName = classNames("ApiClient-responseStatus--default", {
    "ApiClient-responseStatus--ok": statusCode >= 200 && statusCode < 300,
    "ApiClient-responseStatus--clientError":
      statusCode >= 400 && statusCode < 500,
    "ApiClient-responseStatus--serverError":
      statusCode >= 500 && statusCode < 600,
  });

  return (
    <div className="DevHome-content">
      <p>
        Api responded{" "}
        <Pill extraClassnames={statusClassName}>
          {statusCode} {statusText}
        </Pill>{" "}
        in <Pill>{requestDurationInMs} ms</Pill>
      </p>
      <div className="ApiClient-responseBody">
        {contentType === "json" && <JSONTextArea value={rawData} readOnly />}
        {contentType === "text" && <TextArea value={data} readOnly />}
      </div>
    </div>
  );
}

export default ApiClientDevTool;
