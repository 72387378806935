export const RECEIVE_PORTFOLIO_PROFILE =
  "portfolioProfile/RECEIVE_PORTFOLIO_PROFILE";
export const EXPAND_INVESTMENT_ROWS = "portfolioProfile/EXPAND_INVESTMENT_ROWS";
export const COLLAPSE_INVESTMENT_ROWS =
  "portfolioProfile/COLLAPSE_INVESTMENT_ROWS";
export const TOGGLE_INVESTMENT_ROW = "portfolioProfile/TOGGLE_INVESTMENT_ROW";
export const CLEAN_PORTFOLIO_PROFILE =
  "portfolioProfile/CLEAN_PORTFOLIO_PROFILE";

export function receivePortfolioProfile(portfolioView) {
  return { type: RECEIVE_PORTFOLIO_PROFILE, portfolioView };
}

export function expandInvestmentRows(companyId) {
  return { type: EXPAND_INVESTMENT_ROWS, companyId };
}

export function collapseInvestmentRows(companyId) {
  return { type: COLLAPSE_INVESTMENT_ROWS, companyId };
}

export function toggleInvestmentRow() {
  return { type: TOGGLE_INVESTMENT_ROW };
}

export function cleanPortfolioProfile() {
  return { type: CLEAN_PORTFOLIO_PROFILE };
}
