import DebtDetails from "./DebtDetails";
import EquityDetails from "./EquityDetails";
import FundDetails from "./FundDetails";
import LlcDetails from "./LlcDetails";
import { default as OptionOrWarrantDetails } from "./OptionDetails";

export default {
  debt: DebtDetails,
  equity: EquityDetails,
  fund: FundDetails,
  llc: LlcDetails,
  option: OptionOrWarrantDetails,
  warrant: OptionOrWarrantDetails,
};
