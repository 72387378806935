import React, { useCallback } from "react";
import { Grid } from "@ableco/semantic-ui-react";
import { useParams, useNavigate } from "react-router-dom";
import { portfoliosPropType, summaryStatsProptype } from "PropTypes";
import PortfolioTab from "./PortfolioTab";
import PortfolioMetaData from "./PortfolioMetaData";
import PropTypes from "prop-types";
import Segment from "components_new/atoms/Segment";
import PortfolioActions from "./PortfolioActions";
import PortfoliosViewDropdown from "components_new/views/Portfolios/PortfoliosViewDropdown";
import "./PortfoliosView.scss";

function PortfoliosView({
  portfolios,
  investmentsCount,
  portfolioSummaryStats,
  allPortfoliosLogoInfo,
  openEditPortfolioSidebar,
  openValidatePortfolioDeletion,
  openConfirmPortfolioDeletion,
  isPortfolioStatsLoading,
  allRowsCollapsed,
  allRowsExpanded,
  resetInvestmentsViewRows,
}) {
  const {
    id: portfolioId,
    tab: currentTab,
    organizationUsername,
  } = useParams();
  const navigate = useNavigate();

  const handleChangePortfolio = useCallback(
    (_, { value }) => {
      navigate(
        `/${organizationUsername}/portfolios/${value}/${currentTab || ""}`,
      );
    },
    [currentTab, navigate, organizationUsername],
  );

  return (
    <div className="PorftoliosView">
      <Segment shadow extraClassNames="PorftoliosView-header">
        <Grid>
          <Grid.Column width={4} className="PorftoliosView-settings">
            <PortfoliosViewDropdown
              portfolios={portfolios}
              defaultValue={portfolioId}
              onChange={handleChangePortfolio}
              allPortfoliosLogoInfo={allPortfoliosLogoInfo}
              isLoading={isPortfolioStatsLoading}
            />
            {portfolioId !== "all" && (
              <PortfolioActions
                investmentsCount={investmentsCount}
                onEdit={openEditPortfolioSidebar}
                onValidate={openValidatePortfolioDeletion}
                onDelete={openConfirmPortfolioDeletion}
                portfolioId={portfolioId}
              />
            )}
          </Grid.Column>
          <Grid.Column width={12} className="PortfolioMetaData">
            <PortfolioMetaData
              metaData={portfolioSummaryStats[portfolioId]}
              isLoading={isPortfolioStatsLoading}
              portfolioId={portfolioId}
            />
          </Grid.Column>
        </Grid>
      </Segment>
      <PortfolioTab
        data={{
          portfolioId: portfolioId,
          currentTab: currentTab,
          orgUsername: organizationUsername,
          allRowsCollapsed,
          allRowsExpanded,
          resetInvestmentsViewRows,
        }}
      />
    </div>
  );
}

PortfoliosView.defaultProps = {
  portfolios: [],
  portfolioSummaryStats: {},
  params: {
    id: "all",
    tab: null,
  },
};

PortfoliosView.propTypes = {
  portfolios: portfoliosPropType,
  fetchInvestments: PropTypes.func,
  openEditPortfolioSidebar: PropTypes.func,
  openValidatePortfolioDeletion: PropTypes.func,
  openConfirmPortfolioDeletion: PropTypes.func,
  investmentsCount: PropTypes.number,
  router: PropTypes.shape({ push: PropTypes.func }),
  portfolioSummaryStats: summaryStatsProptype,
  allPortfoliosLogoInfo: PropTypes.shape({}),
  isPortfolioStatsLoading: PropTypes.bool,
  allRowsCollapsed: PropTypes.bool,
  allRowsExpanded: PropTypes.bool,
  resetInvestmentsViewRows: PropTypes.func,
};

export default PortfoliosView;
