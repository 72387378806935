import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useToastList, useSetToast } from "./Toaster-context";
import "./Toaster.scss";

function Toaster({ children }) {
  const notifications = useToastList();
  const { removeToast } = useSetToast();

  return (
    <div className="Toaster">
      <ul>
        <AnimatePresence initial={true}>
          {notifications.map((notification) => {
            return (
              <motion.li
                key={notification.id}
                positionTransition
                initial={{ opacity: 0, y: 50, scale: 0.3 }}
                animate={{ opacity: 1, y: 0, scale: 1 }}
                exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.4 } }}
              >
                {children({ notification, removeToast })}
              </motion.li>
            );
          })}
        </AnimatePresence>
      </ul>
    </div>
  );
}

export default Toaster;
