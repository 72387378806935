import { useMatch } from "react-router-dom";

function useViewIds() {
  const investmentViewMatch = useMatch("/:org/investments/:id");
  const companyViewMatch = useMatch("/:org/companies/:id*");
  const portfolioViewMatch = useMatch("/:org/portfolios/:id*");
  const dealViewMatch = useMatch("/:org/deals/active/:id");

  const initialHoldingId = investmentViewMatch?.params?.id;
  const initialCompanyId = companyViewMatch?.params?.id;
  const initialPortfolioId = portfolioViewMatch?.params?.id;
  const initialDealId = dealViewMatch?.params?.id;

  return {
    initialHoldingId,
    initialCompanyId,
    initialPortfolioId,
    initialDealId,
  };
}

export default useViewIds;
