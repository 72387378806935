import React from "react";
import PropTypes from "prop-types";
import EmptyView from "components_new/views/Portfolios/EmptyView";
import InvestmentsDefaultTable from "./Tables/InvestmentsDefaultTable";
import InvestmentsByCompanyTable from "./Tables/InvestmentsByCompanyTable";
import useSidebar from "store/sidebar/hooks";
import { investmentsByCompanyPropType } from "PropTypes";
import "./AllInvestmentsView.scss";

function AllInvestmentsView({
  data = [],
  dataByCompany,
  isLoading,
  onSelection,
  isEmptyView,
  orgUsername,
  groupedByCompany,
  allRowsCollapsed,
  allRowsExpanded,
  resetInvestmentsViewRows,
  showClosingDocuments,
  portfolioId,
}) {
  const { openSidebar } = useSidebar();

  const openNewInvestmentSidebar = () => {
    openSidebar("investmentForm", "Add New Investment", {
      initialPortfolioId: portfolioId,
    });
  };

  if (isEmptyView) {
    return <EmptyView onClick={openNewInvestmentSidebar} />;
  }

  const TableComponent = groupedByCompany
    ? InvestmentsByCompanyTable
    : InvestmentsDefaultTable;

  const currentData = groupedByCompany ? dataByCompany : data;

  return (
    <TableComponent
      data={currentData}
      isLoading={isLoading}
      onSelection={onSelection}
      orgUsername={orgUsername}
      allRowsCollapsed={allRowsCollapsed}
      allRowsExpanded={allRowsExpanded}
      resetInvestmentsViewRows={resetInvestmentsViewRows}
      showClosingDocuments={showClosingDocuments}
    />
  );
}

AllInvestmentsView.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      company_name: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      quantity: PropTypes.number,
      fully_diluted_shares_percentage: PropTypes.number,
      cost_basis: PropTypes.string,
      total_valuation_amount: PropTypes.string,
      multiple: PropTypes.string,
    }),
  ),
  dataByCompany: PropTypes.arrayOf(investmentsByCompanyPropType),
  onSelection: PropTypes.func,
  isLoading: PropTypes.bool,
  hasSearch: PropTypes.bool,
  orgUsername: PropTypes.string,
  groupedByCompany: PropTypes.bool,
  allRowsCollapsed: PropTypes.bool,
  allRowsExpanded: PropTypes.bool,
  resetInvestmentsViewRows: PropTypes.func,
  showClosingDocuments: PropTypes.bool,
  portfolioId: PropTypes.string,
  isEmptyView: PropTypes.bool,
};

export default AllInvestmentsView;
