import React from "react";
import { Header as SemanticHeader } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/header.css";
import "./Header.scss";

function SubHeader({ ...headerProps }) {
  return <SemanticHeader.Subheader {...headerProps} />;
}

export default SubHeader;
