import React, { useEffect, useLayoutEffect, useState } from "react";
import "./DistributionForm.scss";
import FormFooter from "components_new/SidebarComponents/SidebarForm/FormFooter";
import useForm from "lib/hooks/useForm";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import getMessage from "components_new/lib/Messages";
import { isEmpty } from "lodash";
import Distribution from "./Distribution";
import Documents from "components_new/SidebarComponents/forms/groups/Documents";
import Notes from "components_new/SidebarComponents/forms/groups/Notes";
import RequiredNote from "components_new/SidebarComponents/shared/RequiredNote";
import HoldingMiniCard from "components/shared/HoldingMiniCard";

const INITIAL_VALUES = {
  formData: {
    distribution_type: "",
    date: "",
    is_cash_proceed: false,
    is_equity_proceed: false,
    cash_amount: "",
    series_name: "",
    custom_name: "",
    quantity: "",
    cost: "",
    cost_type: "",
    notes: "",
    equity_class: "",
    valuation: "",
    documents_data: [],
    company_id: "",
    certificate_number: "",
    type: "",
  },
};

function DistributionForm({
  ownName,
  company,
  investment,
  componentData,
  handleDistributionCreation,
  mountSidebarComponent,
  hideSidebarComponent,
  currentOrganizationUserName,
  closeSidebar,
  resetForm,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [showFields, setShowFields] = useState(false);
  const [proceedsType, setProceedsType] = useState("");
  const { addToast } = useSetToast();

  const onSubmit = (formData) => {
    setIsLoading(true);
    handleDistributionCreation(formData)
      .then(({ distributions }) => {
        closeSidebar();
        resetForm();
        const createdDistribution = distributions[distributions.length - 1];
        if (createdDistribution.is_equity_proceed) {
          addToast(
            getMessage("addedDistribution", {
              url: `/${currentOrganizationUserName}/investments/${createdDistribution.equity.id}`,
            }),
          );
        } else {
          addToast(getMessage("addedDistribution"));
        }
      })
      .catch((error) => {
        handleErrors(error);
        handleScrollView(errors);
      })
      .finally(() => setIsLoading(false));
  };

  const {
    formData,
    handleChange,
    handleBatchChange,
    handleSubmit,
    handleErrors,
    handleScrollView,
    errors,
    touched,
  } = useForm(INITIAL_VALUES, onSubmit);

  useEffect(() => {
    if (!isEmpty(componentData)) {
      handleBatchChange(componentData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentData]);

  useLayoutEffect(() => {
    if (!isEmpty(proceedsType)) {
      setShowFields(true);
    }
  }, [proceedsType]);

  return (
    <div className="DistributionForm">
      <div className="HoldingGroup">
        <HoldingMiniCard
          investment={investment}
          company={company}
          className="FormElement"
        />
      </div>
      <Distribution
        formData={formData}
        errors={errors}
        handleChange={handleChange}
        showAdditionalFields={showFields}
        proceedsType={proceedsType}
        setProceedsType={setProceedsType}
        componentName={ownName}
        mountSidebarComponent={mountSidebarComponent}
        hideSidebarComponent={hideSidebarComponent}
      />
      <Documents
        onChange={(documents) => handleChange("documents_data", documents)}
        documents={formData.documents_data}
        defaultDocumentType="Distribution Notice"
      />
      <Notes
        notes={formData.notes}
        onChange={(notes) => handleChange("notes", notes)}
      />
      <RequiredNote />
      <FormFooter
        componentName={ownName}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        touched={touched}
      />
    </div>
  );
}

export default DistributionForm;
