import React from "react";
import classnames from "classnames";
import numeral from "numeral";
import { maxSize } from "config/uploadFilesConfig";

function FileUploadStatus({ status, file }) {
  let statusMessage;
  const niceFileSize = numeral(file.size).format("0[.]0 b");
  const niceMaxFileSize = numeral(maxSize).format("0[.]0 b");

  if (status === "uploading") {
    statusMessage = "Uploading....";
  } else if (status === "canceled") {
    statusMessage = "Canceled";
  } else if (status === "completed") {
    statusMessage = niceFileSize;
  } else if (status === "error") {
    statusMessage = `Uploaded files cannot be larger than ${niceMaxFileSize}`;
  }

  const componentClassname = classnames(
    "FileUploadStatus",
    `FileUploadStatus-${status}`,
  );

  return <div className={componentClassname}>{statusMessage}</div>;
}

export default FileUploadStatus;
