import { isEmpty } from "lodash";
export const getAcquisitionItemAttributes = (isLocked) => ({
  company_id: "",
  date: "",
  logo_url: "",
  locked: isLocked,
});
export function createErrorNameByIndex(group, index, type) {
  return `${group}.${index}.${type}`;
}
export function withCleanAcquisitions(clientData) {
  const { acquisitions: clientAcquisitions } = clientData;
  const cleanAcquisitions = clientAcquisitions.filter(
    (aquisition) => !isEmpty(aquisition.company_id),
  );

  return { ...clientData, ...{ acquisitions: cleanAcquisitions } };
}
