import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logSale } from "./actions";
import { useQueryClient } from "react-query";
import useOrganizations from "store/organizations/hooks";

export default () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const invalidateQueries = useCallback(() => {
    queryClient.invalidateQueries(["Company"]);
  }, [queryClient]);

  const {
    currentOrganization: { username: currentOrganizationUsername },
  } = useOrganizations();

  const createLogSale = async (formData, investmentId) => {
    const sale = await dispatch(logSale(formData, investmentId));

    if (!sale.entities) {
      navigate(
        `/${currentOrganizationUsername}/investments/${
          sale.generated_investment_id || sale.investment_id
        }`,
      );
    }

    invalidateQueries();
  };

  return { createLogSale };
};
