import React, { useState } from "react";
import PropTypes from "prop-types";
import { some, every } from "lodash";
import preventHandler from "lib/preventerHandler";
import ModalBody from "components/shared/Modal/ModalBody";
import ModalHeader from "components/shared/Modal/ModalHeader";
import DropZone from "components_new/elements/DropZone";
import PrimarySecondaryButtonFooter from "../Modal/PrimarySecondaryButtonFooter";
import { isUploading } from "components_new/elements/DropZone/config";

function UploadDocuments({
  children,
  canChooseFileType,
  canMarkAsClosing,
  title,
  subTitle,
  childrenValid,
  isSubmitting,
  ...props
}) {
  const [uploadQueue, setUploadQueue] = useState([]);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);

  function shouldShowDoneButton() {
    return uploadQueue.length !== 0;
  }

  function shouldDisableCancelButton() {
    return (
      (some(uploadQueue, { isUploading: true }) ||
        every(uploadQueue, { isCanceled: true })) &&
      uploadQueue.length !== 0
    );
  }

  function shouldDisableDoneButton() {
    return (
      isUploading(uploadQueue) ||
      every(uploadQueue, "isFileTooBig") ||
      (children && !childrenValid())
    );
  }

  const onCancelButtonClick = preventHandler(() => {
    props.onCancelButtonClick();
  });

  const onDoneButtonClick = preventHandler(() => {
    props.onDoneButtonClick(uploadedDocuments);
  });

  return (
    <div className="UploadDocuments">
      <ModalHeader
        title={title}
        subTitle={subTitle}
        extraClassnames="ModalHeader--noBorder"
      />
      <ModalBody type="files">
        {children}
        <DropZone
          onChange={(values) => setUploadedDocuments(values)}
          onQueueChange={setUploadQueue}
          canMarkAsClosing={canMarkAsClosing}
          canChooseFileType={canChooseFileType}
        />
      </ModalBody>
      <PrimarySecondaryButtonFooter
        disableCancelButton={shouldDisableCancelButton()}
        disableDoneButton={shouldDisableDoneButton()}
        onCancelButtonClick={onCancelButtonClick}
        onDoneButtonClick={onDoneButtonClick}
        showDoneButton={shouldShowDoneButton()}
        isSubmitting={isSubmitting}
      />
    </div>
  );
}

UploadDocuments.propTypes = {
  canChooseFileType: PropTypes.bool,
  canMarkAsClosing: PropTypes.bool,
  onCancelButtonClick: PropTypes.func.isRequired,
  onDoneButtonClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  childrenValid: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

UploadDocuments.defaultProps = {
  canChooseFileType: true,
  canMarkAsClosing: true,
  title: "Add Files",
};

export default UploadDocuments;
