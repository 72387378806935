import { useDispatch, useSelector } from "react-redux";
import { getCollection as getDomainCollection } from "store/domain/selectors";
import { debounce } from "lodash";
import { logAcquisition } from "./actions";
import { filterTag } from "store/tags/actions";

export default () => {
  const dispatch = useDispatch();

  const filterTags = debounce((tagName) => {
    return dispatch(filterTag(tagName));
  }, 300);

  const tagSuggestions = useSelector((state) =>
    getDomainCollection(state, "tagSuggestions"),
  );

  const createLogAcquisition = async (formData, investmentId) => {
    const acquisition = await dispatch(logAcquisition(formData, investmentId));
    return acquisition;
  };

  return {
    createLogAcquisition,
    filterTags,
    tagSuggestions,
  };
};
