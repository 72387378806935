import React from "react";
import CustomFormPage from "./CustomFormPage";
import useOrganizations from "store/organizations/hooks";
import useCurrentUser from "hooks/useCurrentUser";
import { openModal } from "store/modalV2";
import { useDispatch } from "react-redux";
import useTags from "store/tags/hooks";

function CustomFormPageWrapper(props) {
  const dispatch = useDispatch();
  const { currentOrganization, updatePreferences } = useOrganizations();
  const {
    filterTags,
    tagSuggestions,
    updateBulkTag,
    createTag,
    editTag,
  } = useTags();

  const currentUser = useCurrentUser();
  const isOwner = currentOrganization.owner_id == currentUser.id;

  const onCreate = (tagName, tagType) => createTag(tagName, tagType);
  const onFilter = (tagName, tagType) => filterTags(tagName, tagType);

  const onDelete = (tag, actionAfterDelete) =>
    dispatch(
      openModal("deleteTag", {
        tag,
        actionAfterDelete,
      }),
    );

  return (
    <CustomFormPage
      isOwner={isOwner}
      organization={currentOrganization}
      updateTags={updateBulkTag}
      updatePreferences={updatePreferences}
      tagActions={{
        onCreate,
        onDelete,
        onFilter,
        onEdit: editTag,
        suggestedList: tagSuggestions,
      }}
      {...props}
    />
  );
}

export default CustomFormPageWrapper;
