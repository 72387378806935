import React, { Fragment } from "react";
import { connect } from "react-redux";
import { closeModal } from "store/modalV2/actions";
import ConfirmationModal from "components/shared/ConfirmationModal";

const deleteMessage = () => {
  return (
    <Fragment>
      Before you delete a portfolio you first need to delete its{" "}
      <strong>Documents</strong> and <strong>Holdings</strong>.
    </Fragment>
  );
};

function mapStateToProps() {
  return {
    title: "Delete Confirmation",
    message: deleteMessage(),
    cancelButtonLabel: "Got it",
    submitting: false,
    showPrimaryButton: false,
  };
}

function mergeProps(stateProps, dispatchProps) {
  const { dispatch } = dispatchProps;

  const newProps = {
    closeModal: () => dispatch(closeModal()),
    action: () => {},
  };

  return {
    ...stateProps,
    ...newProps,
  };
}

export default connect(mapStateToProps, null, mergeProps)(ConfirmationModal);
