import React, { Fragment } from "react";
import { componentSelectInputProps } from "../CustomFieldForm/config";
import TooltipHolder from "components/shared/TooltipHolder";
import EditComponent from "../CustomFieldForm";
import EllipsisDropdown from "components_new/elements/EllipsisDropdown";

const MAX_ITEMS_DISPLAYED = 3;
function tableRowActions(
  setAddCustomField,
  addCustomField,
  openRow,
  onDelete,
  customField,
  isOwner,
) {
  return [
    {
      key: "Edit",
      text: "Edit",
      disabled: !isOwner,
      onClick: () => {
        if (addCustomField) {
          setAddCustomField(false);
        }
        openRow(customField.id);
      },
    },
    {
      key: "Delete",
      text: "Delete",
      disabled: !isOwner,
      onClick: () => onDelete(customField),
    },
  ];
}

function HoldingsList({ holdings }) {
  const hasHiddenItems = holdings.length > MAX_ITEMS_DISPLAYED;
  const visibleHoldings = hasHiddenItems
    ? holdings.slice(0, MAX_ITEMS_DISPLAYED)
    : holdings;
  const tooltipContent = holdings
    .slice(MAX_ITEMS_DISPLAYED, holdings.length)
    .join("\n");
  return (
    <div className="HoldingsList">
      {visibleHoldings.join(", ")}
      {hasHiddenItems && (
        <Fragment>
          ,{" "}
          <TooltipHolder
            extraClassnames="HiddenItemsCounterWrapper"
            tooltip={tooltipContent}
            position="top"
          >
            +{holdings.length - MAX_ITEMS_DISPLAYED}
          </TooltipHolder>
        </Fragment>
      )}
    </div>
  );
}

function TableRow({
  data,
  openRow,
  isOpen,
  onSubmit,
  onDelete,
  isSubmitting,
  addCustomField,
  setAddCustomField,
  isOwner,
}) {
  //TODO swap out Dropdown element for EllipsisDropdown element
  return (
    <div className="TableRowWrapper">
      <div className="TableRow">
        <div className="ActionsTable-HeaderCell  ActionsTable-label">
          {data.field_label}
        </div>
        <div className="ActionsTable-HeaderCell  ActionsTable-type">
          {
            componentSelectInputProps.find(
              (item) => item.value === data.field_type,
            ).label
          }
        </div>
        <div className="ActionsTable-HeaderCell  ActionsTable-holdings">
          <HoldingsList holdings={data.resource_type_labels} />
        </div>
        <EllipsisDropdown
          extraClassnames="TableRow--actions Dropdown--dots"
          disabled={!isOwner}
          options={tableRowActions(
            setAddCustomField,
            addCustomField,
            openRow,
            onDelete,
            data,
            isOwner,
          )}
          iconOrientation="vertical"
          iconSize="small"
        />
      </div>
      {isOpen && (
        <div className="TableContent">
          <EditComponent
            data={data}
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
            onCancel={() => openRow(null)}
            isEditing={true}
          />
        </div>
      )}
    </div>
  );
}

export default TableRow;
