import React from "react";
import CompanyIcon from "components/shared/CompanyIcon";
import UserOrganizationMemberActions from "components/users/Organizations/shared/UserOrganizationMemberActions";
import orderBy from "lodash/orderBy";
import PropTypes from "prop-types";
import { organizationMembershipPropType, currentUserPropType } from "PropTypes";
import PrimaryButton from "components/shared/PrimaryButton";
import UserOrganizationLabel from "components/shared/UserOrganizationLabel";

function isAvailableToHaveActions(
  userOrganizationMembership,
  privateOrganizationUserName,
) {
  return (
    privateOrganizationUserName !==
    userOrganizationMembership.organization_username
  );
}

function UserOrganizationRow({
  userOrganizationMembership,
  privateOrganizationUserName,
  acceptInvitationToOrganization,
}) {
  const showActions = isAvailableToHaveActions(
    userOrganizationMembership,
    privateOrganizationUserName,
  );

  const {
    organization_logo_url,
    organization_name,
    owner,
    status,
    organization_username,
    id,
  } = userOrganizationMembership;

  const statusName = owner ? "owner" : status;

  return (
    <div className="Table--row">
      <div className="OrganizationMembersTable-Initials">
        <CompanyIcon src={organization_logo_url} size="large" />
      </div>
      <div className="OrganizationInformation">
        <div className="OrganizationMembersTable-FullName u-metaContentBold">
          {organization_name}
          {statusName !== "accepted" && (
            <UserOrganizationLabel status={statusName} />
          )}
        </div>
        <div className="OrganizationMembersTable-Email">
          @{organization_username}
        </div>
      </div>
      <div className="OrganizationMembersTable-buttons">
        {status === "pending" && (
          <PrimaryButton
            onClick={() =>
              acceptInvitationToOrganization(organization_username, id)
            }
            size="small"
          >
            Accept Invitation
          </PrimaryButton>
        )}
      </div>
      {showActions && (
        <UserOrganizationMemberActions
          userOrganizationMembership={userOrganizationMembership}
          type={statusName}
        />
      )}
    </div>
  );
}

const makeCaseInsensitive = (name) => name.toLowerCase();

function sortOrganizations(memberships) {
  return orderBy(
    memberships,
    [
      "private",
      "owner",
      "status",
      (membership) => makeCaseInsensitive(membership.organization_name),
      "email",
    ],
    ["desc", "desc", "asc", "asc", "asc"],
  );
}

class UserOrganizationList extends React.Component {
  componentDidMount() {
    const {
      invitation,
      currentUser,
      openAcceptFromOtherInvitationModal,
      openInvalidInvitationModal,
    } = this.props;
    const isInvalidInvitation =
      invitation.inviteStatus === "expired" ||
      invitation.inviteStatus === "revoked";

    if (isInvalidInvitation) {
      openInvalidInvitationModal();
    } else {
      const showModalFromDifferentInvite =
        invitation.inviteCode && invitation.inviteEmail !== currentUser.email;
      if (showModalFromDifferentInvite) {
        openAcceptFromOtherInvitationModal(invitation);
      }
    }
  }

  render() {
    const {
      userOrganizationMemberships,
      privateOrganizationUserName,
      acceptInvitationToOrganization,
    } = this.props;
    const sortedOrganizations = sortOrganizations(userOrganizationMemberships);
    return (
      <div className="UserOrganizationList">
        <h2 className="FormSection-title u-sectionHeading">Organizations</h2>
        <div className="FormSection-Body OrganizationMembers">
          <div className="OrganizationMembersTable Table">
            <div className="Table--body">
              {sortedOrganizations.map((userOrg) => (
                <UserOrganizationRow
                  key={userOrg.id}
                  privateOrganizationUserName={privateOrganizationUserName}
                  userOrganizationMembership={userOrg}
                  acceptInvitationToOrganization={
                    acceptInvitationToOrganization
                  }
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
UserOrganizationList.propTypes = {
  userOrganizationMemberships: PropTypes.arrayOf(organizationMembershipPropType)
    .isRequired,
  privateOrganizationUserName: PropTypes.string.isRequired,
  acceptInvitationToOrganization: PropTypes.func.isRequired,
  invitation: PropTypes.shape({
    inviteStatus: PropTypes.string,
    inviteCode: PropTypes.string,
    inviteEmail: PropTypes.string,
  }),
  openAcceptFromOtherInvitationModal: PropTypes.func.isRequired,
  openInvalidInvitationModal: PropTypes.func.isRequired,
  currentUser: currentUserPropType,
};
export default UserOrganizationList;
