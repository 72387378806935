import { isEmpty } from "lodash";
import { saveCompanyDocuments } from "store/companies/company/actions";
import { saveDealDocuments } from "store/pipelines/deals/actions";
import { saveInvestmentDocuments } from "store/documents/actions";

const INVESTMENT_TYPES = ["equity", "debt", "llc", "fund", "option", "warrant"];

export function resourceType(resource) {
  const [resource_type] = resource.type.split("::");
  if (INVESTMENT_TYPES.includes(resource_type)) {
    return "investment";
  }
  return resource_type.toLowerCase();
}

export function buildPostResourceRoute(resource) {
  // NOTE: After finishing we should create a generic buildPostResourceRoute function
  // that takes into consideration (search, posts)
  if (resource.type === "Deal") {
    return `/pipelines/${resource.pipeline_id}/deals/${resource.deal_id}`;
  }

  switch (resourceType(resource)) {
    case "company":
      return `/companies/${resource.company_id}`;
    case "companyprofile":
      return `/companies/${resource.company_profile_id}`;
    case "deal":
      return `/pipelines/${resource.pipeline_id}/deals/${resource.id}`;
    case "investment":
      return `/investments/${resource.id || resource.investment_id}`;
    default:
  }
}

export function uploadDocuments(filepickerData, target) {
  const type = resourceType(target);
  switch (type) {
    case "company":
      return saveCompanyDocuments(target.id, filepickerData);
    case "investment":
      return saveInvestmentDocuments(
        target.id,
        target.company_id,
        filepickerData,
      );
    case "deal":
      return saveDealDocuments(target.pipeline_id, target.id, filepickerData);
  }
}

export function buildPrefilledCompanyData(company) {
  if (isEmpty(company)) {
    return null;
  }
  return {
    ...company,
    company_id: company.id,
    title: company.name || company.title,
    type: "company",
  };
}

export function buildPrefilledContactData(contact) {
  if (isEmpty(contact)) {
    return null;
  }
  contact.title = `${contact.first_name} ${contact.last_name}`;
  contact.type = "contact";
  return contact;
}

// TODO: need to pass comapny object for company_name
export function buildPrefilledInvestmentData(investment, company) {
  if (isEmpty(investment)) {
    return null;
  }
  const companyName = investment.company_name || company.name;
  return {
    ...investment,
    title: `${companyName} (${investment.name})`,
  };
}

export function resourceTypeForValuation(type) {
  if (INVESTMENT_TYPES.includes(type)) {
    return "investments";
  } else if (type === "company") {
    return "companies";
  }
  return null;
}

export function getGlobalResourceId(resource) {
  switch (resource.type) {
    case "Company":
      return resource.company_id;
    case "Investment":
      return resource.investment_id;
    case "Deal":
      return resource.deal_id;
  }
}
