import React from "react";
import PropTypes from "prop-types";
import {
  TableBody,
  TableHeader,
  TableRow,
  SortableTableHeaderCell,
  SortedTable,
  TableHeaderCell,
} from "components_new/elements/Table";
import EmptyView from "components_new/views/Portfolios/EmptyView";
import CurrentRow from "./CurrentRow";
import GroupedCurrentRow from "./GroupedCurrentRow";
import Tooltip from "components_new/atoms/Tooltip/Tooltip";
import { investmentDataPropType, companyAvatarPropType } from "PropTypes";
import { isEmpty } from "lodash";
import { defaultOrderByCompanyName } from "components_new/views/Portfolios/config";
import getColumnHeaders from "../shared/getColumnHeaders";
import useSidebar from "store/sidebar/hooks";

export default function CurrentView({
  data,
  dataByCompany,
  onSelection,
  orgUsername,
  isLoading,
  groupedByCompany,
  allRowsExpanded,
  allRowsCollapsed,
  resetInvestmentsViewRows,
  showClosingDocuments,
  isEmptyView,
}) {
  const { openSidebar } = useSidebar();

  const openNewInvestmentSidebar = () => {
    openSidebar("investmentForm", "Add New Investment");
  };

  if (isEmptyView) {
    return <EmptyView onClick={openNewInvestmentSidebar} />;
  }

  const currentData = groupedByCompany ? dataByCompany : data;
  const RowComponent = groupedByCompany ? GroupedCurrentRow : CurrentRow;
  const columnHeaders = getColumnHeaders("currentTable");

  const renderHeaders = (handleSort, isSorted) => {
    return (
      <TableHeader>
        <TableRow>
          {groupedByCompany && <TableHeaderCell className="EmptyCell" />}
          {columnHeaders.map((columnHeader, index) => {
            return (
              <SortableTableHeaderCell
                key={index}
                isSorted={isSorted}
                handleSort={handleSort}
                isGrouped={groupedByCompany}
                {...columnHeader}
              >
                <Tooltip
                  position={columnHeader.tooltipPosition}
                  content={columnHeader.tooltipContent}
                  disabled={!columnHeader.hasTooltip}
                  trigger={<div>{columnHeader.title}</div>}
                />
              </SortableTableHeaderCell>
            );
          })}
        </TableRow>
      </TableHeader>
    );
  };

  return (
    <SortedTable
      tableData={currentData}
      onSelection={onSelection}
      isLoading={isLoading}
      renderHeaders={renderHeaders}
      skeletonStructure={{ rows: 8, headers: columnHeaders }}
      className="HoldingsTable attached"
      defaultOrder={defaultOrderByCompanyName}
      expandable={groupedByCompany}
      shadow={false}
    >
      {({ data }) => (
        <TableBody isEmptyData={isEmpty(data)}>
          {!isEmpty(data) &&
            data.map((investment) => {
              return (
                <RowComponent
                  key={investment.id}
                  investment={investment}
                  orgUsername={orgUsername}
                  allRowsExpanded={allRowsExpanded}
                  allRowsCollapsed={allRowsCollapsed}
                  resetInvestmentsViewRows={resetInvestmentsViewRows}
                  showClosingDocuments={showClosingDocuments}
                />
              );
            })}
        </TableBody>
      )}
    </SortedTable>
  );
}

CurrentView.defaultProps = {
  data: [],
  companies: [],
};

CurrentView.propTypes = {
  data: PropTypes.arrayOf(investmentDataPropType),
  dataByCompany: PropTypes.arrayOf(investmentDataPropType),
  onSelection: PropTypes.func,
  companies: PropTypes.shape({ companyAvatarPropType }),
  orgUsername: PropTypes.string,
  isLoading: PropTypes.bool,
  groupedByCompany: PropTypes.bool,
  hasSearch: PropTypes.bool,
  allRowsExpanded: PropTypes.bool,
  allRowsCollapsed: PropTypes.bool,
  resetInvestmentsViewRows: PropTypes.func,
  showClosingDocuments: PropTypes.bool,
  isEmptyView: PropTypes.bool,
};
