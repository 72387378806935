import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import "./Pill.scss";

export default function Pill({ children, className, type }) {
  const pillClassNames = classNames("Pill", {
    [className]: !!className,
    [type]: !!type,
  });

  return <span className={pillClassNames}>{children}</span>;
}

Pill.defaultProps = {
  type: "primary",
};

Pill.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};
