import React, { useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSnapshots } from "components_new/views/Snapshots/hooks/queries/useSnapshots";
import Loader from "components/shared/Loader";
import Snapshot from "./Snapshot";

function SnapshotWrapper() {
  const navigate = useNavigate();

  const { organizationUsername, id: currentSanpshotId } = useParams();
  const { data = {}, isLoading } = useSnapshots();
  const { snapshots = [] } = data;

  const snapshot = snapshots.find((item) => item.id === currentSanpshotId);

  const handleEditSnapshot = useCallback(
    (id) => {
      navigate(`/${organizationUsername}/reports/snapshots/${id}/edit`);
    },
    [navigate, organizationUsername],
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Snapshot snapshot={snapshot} handleEditSnapshot={handleEditSnapshot} />
  );
}

export default SnapshotWrapper;
