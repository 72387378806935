import React, { Fragment, useCallback, useState, useEffect } from "react";

import Text from "components_new/atoms/Text";
import DatePicker from "components_new/atoms/DatePicker";
import DropdownForm from "components_new/atoms/DropdownForm";
import Button from "components_new/atoms/Button";

import formatDate from "lib/formatDate";
import useForm from "lib/hooks/useForm";
import PropTypes from "prop-types";

// TODO: Add new text area component, after styling it
import TextArea from "components/shared/form/TextArea";

import { companyPropType } from "PropTypes";
import { valueDateFormat } from "config/dateInput";
import {
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "components_new/elements/Modal";
import "./PassFromDeal.scss";

const PASS_REASONS = [
  {
    key: "Adoption / traction",
    value: "Adoption / traction",
    text: "Adoption / traction",
  },
  {
    key: "Bad syndicate",
    value: "Bad syndicate",
    text: "Bad syndicate",
  },
  {
    key: "Boxed out by founder",
    value: "Boxed out by founder",
    text: "Boxed out by founder",
  },
  {
    key: "Boxed out by VC",
    value: "Boxed out by VC",
    text: "Boxed out by VC",
  },
  {
    key: "No more room in round",
    value: "No more room in round",
    text: "No more room in round",
  },
  {
    key: "Crowded space",
    value: "Crowded space",
    text: "Crowded space",
  },
  {
    key: "Founder / team",
    value: "Founder / team",
    text: "Founder / team",
  },
  {
    key: "Heavily invested space",
    value: "Heavily invested space",
    text: "Heavily invested space",
  },
  {
    key: "Location",
    value: "Location",
    text: "Location",
  },
  {
    key: "Over transom",
    value: "Over transom",
    text: "Over transom",
  },
  {
    key: "Poor product",
    value: "Poor product",
    text: "Poor product",
  },
  {
    key: "Sector",
    value: "Sector",
    text: "Sector",
  },
  {
    key: "Small market",
    value: "Small market",
    text: "Small market",
  },
  {
    key: "Too early",
    value: "Too early",
    text: "Too early",
  },
  {
    key: "Too late",
    value: "Too late",
    text: "Too late",
  },
  {
    key: "Demo day pass",
    value: "Demo day pass",
    text: "Demo day pass",
  },
  {
    key: "Defensibility",
    value: "Defensibility",
    text: "Defensibility",
  },
  {
    key: "Differentiation",
    value: "Differentiation",
    text: "Differentiation",
  },
  {
    key: "Derivative",
    value: "Derivative",
    text: "Derivative",
  },
  {
    key: "Not raising",
    value: "Not raising",
    text: "Not raising",
  },
  {
    key: "Poor monetization",
    value: "Poor monetization",
    text: "Poor monetization",
  },
  {
    key: "Shared pipeline pass",
    value: "Shared pipeline pass",
    text: "Shared pipeline pass",
  },
];

function PassFromDeal({
  passAction,
  deal,
  company,
  isEditing,
  isSubmitting,
  currentOrganizationUsername,
  closeModal,
}) {
  const [reasons, setReasons] = useState([]);

  useEffect(() => {
    if (!isEditing) {
      const passReasonsToString = reasons.join(", ");
      handleChange("reason_passed", passReasonsToString);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reasons, isEditing]);

  const initializeFormData = () => {
    const { decision_date, reason_passed } = deal;

    return {
      formData: {
        decision_date: isEditing ? decision_date : "",
        reason_passed: isEditing ? reason_passed : "",
      },
    };
  };

  const submit = useCallback(
    async (clientData) => {
      try {
        await passAction(clientData, isEditing, currentOrganizationUsername);
      } catch (error) {
        handleErrors(error);
      }
    },
    [currentOrganizationUsername, passAction, handleErrors, isEditing],
  );

  const {
    formData,
    handleChange,
    handleSubmit,
    handleErrors,
    errors,
  } = useForm(initializeFormData(), submit);

  const subTitle = deal.round_name
    ? `${deal.round_name} in ${company.name}`
    : `${company.name}`;

  const buttonCaption = isEditing ? "Save Changes" : "Pass on Deal";

  const isAvailableToSubmit = formData.decision_date === "";

  return (
    <Fragment>
      <ModalHeader content="Pass on Deal" subheader={subTitle} />

      <ModalContent>
        <form onSubmit={handleSubmit} className="PassFromDeal">
          <DatePicker
            name="decision_date"
            extraClassnames="FormElement FormElement--Date grid-item grid-50"
            label="Date of pass"
            required
            value={formatDate(formData.decision_date)}
            errorMessage={errors.for("decision_date")}
            onChange={(value) =>
              handleChange("decision_date", formatDate(value, valueDateFormat))
            }
            prefillToday={true}
            oldVersion
          />
          {isEditing ? (
            <TextArea
              label="Reason"
              placeholder="Enter reason for passing on this deal"
              required
              defaultValue={formData.reason_passed}
              value={formData.reason_passed}
              rows="4"
              onChange={(value) => handleChange("reason_passed", value)}
            />
          ) : (
            <div className="FormElement Reasons-multiselect">
              <label className="FormElement-label">Reason</label>
              <DropdownForm
                placeholder="Enter reason for passing on this deal"
                search
                multiple
                fluid
                items={PASS_REASONS}
                value={reasons}
                onChange={(_, { value }) => setReasons(value)}
              />
            </div>
          )}
          <Text as="p" type="label-regular">
            * Required
          </Text>
        </form>
      </ModalContent>
      <ModalFooter extraClassnames="AddValuationModal" withBorder={true}>
        <Button
          primary
          type="submit"
          onClick={handleSubmit}
          disabled={isAvailableToSubmit}
          loading={isSubmitting}
        >
          {buttonCaption}
        </Button>
        <Button secondary onClick={() => closeModal()} disabled={isSubmitting}>
          Cancel
        </Button>
      </ModalFooter>
    </Fragment>
  );
}

PassFromDeal.propTypes = {
  deal: PropTypes.shape({
    id: PropTypes.string,
    round_name: PropTypes.string,
    decision_date: PropTypes.string,
    reason_passed: PropTypes.string,
  }),
  company: companyPropType,
  passAction: PropTypes.func,
  isSubmitting: PropTypes.bool,
  isEditing: PropTypes.bool,
  currentOrganizationUsername: PropTypes.string,
  closeModal: PropTypes.func,
};

export default PassFromDeal;
