import React from "react";
import { useUpdateDeal } from "components_new/views/Deals/hooks/mutations/useUpdateDeal";
import useOrganizations from "store/organizations/hooks";
import OwnerDealForm from "./OwnerDealForm";

function OwnerDealFormWrapper({ dealId, pipelineId, owner, ...otherProps }) {
  const { mutateAsync: updateDeal } = useUpdateDeal();
  const { partnerMemberships } = useOrganizations();

  const handleUpdateDeal = (formData) => {
    return updateDeal({ formData, pipelineId, dealId });
  };
  const memberItems = partnerMemberships.map((membership) => ({
    key: membership.user_id,
    value: membership.user_id,
    text: membership.full_name,
  }));

  return (
    <OwnerDealForm
      members={memberItems}
      onChange={handleUpdateDeal}
      dealId={dealId}
      currentOwner={owner}
      {...otherProps}
    />
  );
}

export default OwnerDealFormWrapper;
