import { useState } from "react";
import Api from "lib/Api";

const REQUEST_OPTIONS = {
  blob: true,
  parseResponse: false,
  headers: {
    Accept: "application/pdf",
  },
};

const useSnapshotPDF = (snapshot) => {
  const [isFetching, setIsFetching] = useState(false);

  const generatePDF = () => {
    setIsFetching(true);

    Api.get(`/snapshots/${snapshot.id}/pdf.pdf`, {
      ...REQUEST_OPTIONS,
    })
      .then(({ data }) => {
        const file = new Blob([data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        const downloadLink = document.createElement("a");
        downloadLink.href = fileURL;
        downloadLink.target = "_blank";
        downloadLink.setAttribute(
          "download",
          snapshot.name.toLowerCase().split(" ").join("_") + ".pdf",
        );
        downloadLink.click();
      })
      .finally(() => setIsFetching(false));
  };
  return { isFetching, generatePDF };
};

export default useSnapshotPDF;
