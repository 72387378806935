import React, { useContext, useEffect } from "react";
import ContactForm from "./ContactForm";
import useOrganization from "store/organizations/hooks";
import { isEmpty } from "lodash";
import useResourcesV2 from "store/resourcesV2/hooks";
import { SidebarComponentsContext } from "components_new/SidebarComponents/SidebarComponentsWrapper";
import { useCreateContact } from "components_new/views/Contacts/hooks/mutations/useCreateContact";

function ContactFormWrapper({ ownName }) {
  const { closeSidebar, registerForm } = useContext(SidebarComponentsContext);
  const { mutateAsync: createContact } = useCreateContact();
  const {
    isAdmin,
    isCurrentUserOrganizationOwner,
    currentOrganization,
    currentOrganizationName,
    currentOrganizationUsername,
  } = useOrganization();

  const { getOrganizationResourceAsCollection } = useResourcesV2();
  const organizationtCustomFields = getOrganizationResourceAsCollection(
    "customFields",
  );

  useEffect(() => {
    if (!isEmpty(ownName)) {
      registerForm(ownName);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContactForm
      ownName={ownName}
      createContact={createContact}
      closeSidebar={closeSidebar}
      organizationCustomFields={organizationtCustomFields}
      currentOrganizationName={currentOrganizationName}
      currentOrganizationUsername={currentOrganizationUsername}
      prepopulatedContactTags={currentOrganization.prepopulated_contact_tags}
      prepopulatedSectors={currentOrganization.prepopulated_sectors}
      isOrgOwner={isCurrentUserOrganizationOwner}
      isAdmin={isAdmin}
    />
  );
}

export default ContactFormWrapper;
