import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";

import getMessage from "components_new/lib/Messages";
import Loader from "components/shared/Loader";
import PassFromDeal from "./PassFromDeal";
import useModal from "store/modalV2/hooks";

import { useDeal } from "components_new/views/Deals/hooks/queries/useDeal";
import { usePassFromDeal } from "components_new/views/Deals/hooks/mutations/usePassFromDeal";
import { useUpdateDeal } from "components_new/views/Deals/hooks/mutations/useUpdateDeal";

function PassFromDealWrapper({ dealId, pipelineId, callback }) {
  const [submitting, setSubmitting] = useState(false);
  const { organizationUsername } = useParams();
  const { closeModal } = useModal();
  const { addToast } = useSetToast();

  const { data, isLoading } = useDeal(pipelineId, dealId);
  const { mutateAsync: passFromDeal } = usePassFromDeal();
  const { mutateAsync: updateDeal } = useUpdateDeal();

  const handlePassFromDeal = async (formData) => {
    setSubmitting(true);
    try {
      await passFromDeal({ formData, pipelineId, dealId });

      addToast(getMessage("passFromDeal"));
      closeModal();

      callback?.();
    } finally {
      setSubmitting(false);
    }
  };

  const handleEditPass = async (formData) => {
    setSubmitting(true);

    try {
      await updateDeal({ formData, pipelineId, dealId });

      addToast(getMessage("editPassedDeal"));
      closeModal();

      callback?.();
    } finally {
      setSubmitting(false);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  const isEditing = data?.deal.status === "passed";

  return (
    <PassFromDeal
      deal={data?.deal}
      company={data?.company}
      currentOrganizationUsername={organizationUsername}
      isEditing={isEditing}
      isSubmitting={submitting}
      passAction={isEditing ? handleEditPass : handlePassFromDeal}
      closeModal={closeModal}
    />
  );
}

export default PassFromDealWrapper;
