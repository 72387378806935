import { connect } from "react-redux";
import DeleteOrganization from "./DeleteOrganization";
import { openModal } from "store/modalV2";

function mapStateToProps(state, ownProps) {
  return {
    organization: ownProps.organization,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openConfirmOrganizationDeletion: () =>
      dispatch(openModal("confirmOrganizationDeletion")),
    openValidateOrganizationDeletion: () =>
      dispatch(openModal("validateOrganizationDeletion")),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteOrganization);
