import { shallowEqual, useSelector } from "react-redux";
import { pick } from "lodash";

export default () => {
  const resourcesV2 = useSelector((state) => state.resourcesV2, shallowEqual);

  const getOrganizationResourcesAsObject = (resourceName) =>
    (resourcesV2[resourceName] && resourcesV2[resourceName].byId) || {};

  // The following check to see if the resource exists is needed mostly for our E2Es
  const getOrganizationResourceAsCollection = (resourceName) =>
    (resourcesV2[resourceName] &&
      Object.values(resourcesV2[resourceName].byId)) ||
    [];

  const getResourcesAsObject = (resourceName, resourceIds) => {
    return (
      resourcesV2[resourceName] &&
      pick(resourcesV2[resourceName].byId, resourceIds)
    );
  };

  return {
    getOrganizationResourcesAsObject,
    getOrganizationResourceAsCollection,
    getResourcesAsObject,
  };
};
