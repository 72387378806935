import React, { useMemo } from "react";
import CompanyContactTab from "./CompanyContactTab";

import { orderBy } from "lodash";
import { useParams } from "react-router-dom";
import { useCompany } from "components_new/views/Company/hooks/queries/useCompany";

function CompanyContactTabWrapper() {
  const { id: companyId } = useParams();
  const { data } = useCompany(companyId);
  const { referrers, employees, referrerCompanies } = data;

  const sortedReferrers = useMemo(
    () => orderBy(referrers, ["first_name"], ["asc"]),
    [referrers],
  );

  return (
    <CompanyContactTab
      referrers={sortedReferrers}
      employees={employees}
      referrerCompanies={referrerCompanies}
    />
  );
}

export default CompanyContactTabWrapper;
