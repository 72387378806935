import PropTypes from "prop-types";
import React from "react";
import Button from "components_new/atoms/Button";
import classnames from "classnames";

// TODO : Change these icons with the proper ones. (When we have them)
import CollapseIcon from "-!svg-react-loader?!assets/icons/utility/collapse.svg?name=CollapseIcon";
import ExpandIcon from "-!svg-react-loader?!assets/icons/utility/expand.svg?name=ExpandIcon";

function ExpandCollapseToggle({
  expanded,
  onExpand,
  onCollapse,
  extraClassnames,
}) {
  const expandCollapseToggleClassnames = classnames(
    "ExpandCollapseToggle",
    extraClassnames,
  );

  const handleAction = () => {
    expanded ? onCollapse() : onExpand();
  };

  return (
    <Button
      secondary
      size="small"
      onClick={handleAction}
      className={expandCollapseToggleClassnames}
    >
      {expanded ? <ExpandIcon /> : <CollapseIcon />}
    </Button>
  );
}

ExpandCollapseToggle.propTypes = {
  expanded: PropTypes.bool.isRequired,
  onExpand: PropTypes.func.isRequired,
  onCollapse: PropTypes.func.isRequired,
  extraClassnames: PropTypes.string,
};

ExpandCollapseToggle.defaultProps = {
  extraClassnames: "",
};

export default ExpandCollapseToggle;
