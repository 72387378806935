import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { documentPropType } from "PropTypes";
import FileCategory from "components_new/elements/FileCategory";
import CompanyFiles from "./folders/CompanyFiles";
import DealFiles from "./folders/DealFiles";
import InvestmentFiles from "./folders/InvestmentFiles";
import "./FileFolders.scss";

const fileCategories = [
  {
    title: "Miscellaneous",
    keys: ["Document::Company", "Document::Company::Post"],
    component: CompanyFiles,
  },
  {
    title: "Deals",
    keys: ["Document::Deal", "Document::Deal::Post"],
    component: DealFiles,
  },
  {
    title: "Investments",
    keys: [
      "Document::Investment",
      "Document::Investment::Post",
      "Document::Investment::CapitalCall",
      "Document::Investment::InvestmentSale",
      "Document::Investment::InvestmentAcquisition",
    ],
    component: InvestmentFiles,
  },
];

function FileFolders({
  currentOrganizationUsername,
  allRowsExpanded,
  allRowsCollapsed,
  updateFile,
  removeFile,
  filteredFilesResults,
}) {
  const [modifiedFileId, setModifiedFileId] = useState(null);
  const [currentAction, setCurrentAction] = useState(null);

  const setEditFile = (fileId) => {
    setCurrentAction("edit");
    setModifiedFileId(fileId);
  };

  const setDeleteFile = (fileId) => {
    setCurrentAction("delete");
    setModifiedFileId(fileId);
  };

  const resetFileActions = (fileId) => {
    if (fileId === modifiedFileId) {
      setModifiedFileId(null);
      setCurrentAction(null);
    }
  };

  const categoriesWithFiles = useMemo(
    () =>
      fileCategories.map((category) => {
        const { keys } = category;
        const files = filteredFilesResults.filter((file) =>
          keys.includes(file.type),
        );

        return { ...category, files };
      }),
    [filteredFilesResults],
  );

  return (
    <div className="FileFolders">
      <div className="FileFolders-header">
        <div className="FileFolders-headerRow">
          <div className="FileFolders-nameColumn u-label">Name</div>
          <div className="FileFolders-typeColumn u-label">Type</div>
          <div className="FileFolders-uploadDateColumn u-label">
            Upload date
          </div>
        </div>
      </div>
      <div className="FileFolders-body">
        {categoriesWithFiles.map(
          ({ component: FilesHandler, title, files }) =>
            files.length > 0 && (
              <FileCategory
                key={title}
                title={title}
                allRowsExpanded={allRowsExpanded}
                allRowsCollapsed={allRowsCollapsed}
              >
                <FilesHandler
                  key={title}
                  files={files}
                  currentOrganizationUsername={currentOrganizationUsername}
                  setEditFile={setEditFile}
                  setDeleteFile={setDeleteFile}
                  resetFileActions={resetFileActions}
                  currentAction={currentAction}
                  editAction={updateFile}
                  deleteAction={removeFile}
                  modifiedFileId={modifiedFileId}
                />
              </FileCategory>
            ),
        )}
      </div>
    </div>
  );
}

FileFolders.propTypes = {
  currentOrganizationUsername: PropTypes.string.isRequired,
  allRowsExpanded: PropTypes.bool.isRequired,
  allRowsCollapsed: PropTypes.bool.isRequired,
  isExpandedMiscellaneous: PropTypes.bool.isRequired,
  updateFile: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
  filteredFilesResults: PropTypes.arrayOf(documentPropType).isRequired,
};

export default FileFolders;
