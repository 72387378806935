import React, { Fragment, useState } from "react";
import { Outlet, useParams, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import pluralize from "pluralize";

import formatDate from "lib/formatDate";

import TabMenu from "components_new/elements/TabMenu";
import ThreePaneLayout from "components_new/layouts/ThreePaneLayout";
import RowCardList from "components_new/elements/RowCardList";
import Sidebar from "./Sidebar";
import RowCard from "components_new/elements/RowCard";
import "./Reports.scss";

// TODO: refactor ReportsRow into separate files/folder,
// give own container for currentOrg, getExport
const ReportsRow = ({
  currentOrganizationName,
  title,
  subtitle,
  imageUrl,
  itemCount,
  getExport,
  downloadUrl,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const card = {
    dimmer: isLoading,
    avatarData: {
      title,
      subtitle,
      imageUrl,
    },
    dropdownInformation: {
      dropdownIconOrientation: "vertical",
      dropdownIconSize: "small",
    },
    dropdownItems: [
      {
        icon: "file excel outline",
        text: "Download CSV",
        onClick: () => {
          setIsLoading(true);
          getExport(downloadUrl).then(({ data, fileName }) => {
            setIsLoading(false);
            const downloadLink = document.createElement("a");
            downloadLink.style.display = "none";
            downloadLink.setAttribute(
              "href",
              `data:text/csv;charset=utf-8,${encodeURI(data)}`,
            );
            const defaultFileName =
              `${currentOrganizationName} - ` +
              `${title} - ${formatDate(Date.now(), "dashes")}`;
            downloadLink.setAttribute("download", fileName || defaultFileName);
            downloadLink.click();
          });
        },
        disabled: itemCount === 0,
      },
    ],
  };

  return (
    <RowCard
      dimmer={card.dimmer}
      avatarData={card.avatarData}
      dropdownInformation={card.dropdownInformation}
      dropdownItems={card.dropdownItems}
      rightLabel={itemCount + " " + pluralize("item", itemCount)}
    />
  );
};

ReportsRow.propTypes = {
  id: PropTypes.number,
  sym: PropTypes.string,
  currentOrganizationName: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  imageUrl: PropTypes.string,
  itemCount: PropTypes.number,
  getExport: PropTypes.func,
  downloadUrl: PropTypes.string,
};

const ReportsTable = ({ currentOrganizationName, getExport, reports }) => {
  return (
    <RowCardList>
      {reports &&
        reports.map((report) => {
          return (
            <ReportsRow
              key={report.id}
              currentOrganizationName={currentOrganizationName}
              title={report.title}
              subtitle={report.subtitle}
              imageUrl={report.image_url}
              itemCount={report.count}
              getExport={getExport}
              downloadUrl={"reports/" + report.id}
            />
          );
        })}
    </RowCardList>
  );
};

ReportsTable.propTypes = {
  reports: PropTypes.arrayOf(PropTypes.shape(ReportsRow.propTypes)),
  getExport: PropTypes.func,
  currentOrganizationName: PropTypes.string,
};

const ReportsView = ({ currentOrganizationName, getExport, reports }) => {
  const { organizationUsername } = useParams();
  const { pathname: currentLocation } = useLocation();

  const route = (path) => `/${organizationUsername}/reports/${path}`;
  const isSnapshots = currentLocation.includes(route("snapshots"));

  return (
    <div className="ReportsView">
      <Fragment>
        <TabMenu
          tabs={[
            {
              name: "Snapshots",
              active: isSnapshots,
              to: route("snapshots"),
            },
            {
              name: "Reports",
              active: !isSnapshots,
              to: route(""),
            },
          ]}
        />
        {isSnapshots ? (
          <Outlet />
        ) : (
          <ThreePaneLayout
            children={
              <ReportsTable
                currentOrganizationName={currentOrganizationName}
                getExport={getExport}
                reports={reports}
              />
            }
            sidebar={<Sidebar />}
          />
        )}
      </Fragment>
    </div>
  );
};

ReportsView.propTypes = {
  reports: PropTypes.arrayOf(PropTypes.shape(ReportsRow.propTypes)),
  getExport: PropTypes.func,
  currentOrganizationName: PropTypes.string,
};

export default ReportsView;
