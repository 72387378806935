import { useQuery } from "react-query";
import Api from "lib/Api";

function convertArrayIntoHash(collection) {
  return collection.reduce((memo, item) => {
    memo[item.id] = item;
    return memo;
  }, {});
}

export function useContacts(organizationUsername, filters = {}) {
  return useQuery(
    ["ContactsDirectory", organizationUsername, filters],
    async () => {
      const { data } = await Api.get("/v2/contacts", {
        params: filters,
      });
      const contactsById = convertArrayIntoHash(data.contacts);
      return {
        contacts: data?.contacts || [],
        meta: data.meta,
        contactsById,
      };
    },
    {
      staleTime: 10000,
    },
  );
}
