import React from "react";
import PropTypes from "prop-types";

import { Grid } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/grid.css";
import "semantic-ui-css/components/container.css";

function ThreePaneLayout(props) {
  const { children, header, navigation, sidebar } = props;
  return (
    <Grid container>
      {header && (
        <Grid.Row>
          <Grid.Column>{header}</Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Column width={11}>
          {navigation}
          {children}
        </Grid.Column>{" "}
        <Grid.Column width={5}>{sidebar}</Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

ThreePaneLayout.propTypes = {
  children: PropTypes.node,
  header: PropTypes.node,
  navigation: PropTypes.node,
  sidebar: PropTypes.node,
};

export default ThreePaneLayout;
