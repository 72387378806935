import React from "react";
import classnames from "classnames";
import { Dropdown as SemanticDropdown } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/dropdown.css";
import "./Dropdown.scss";

function Dropdown({
  onChange,
  className,
  size = "default",
  options = [],
  ...restProps
}) {
  const dropdownOptions = options.map((option, index) => {
    if (option.disabled) {
      const { onClick, ...restKeys } = option;
      return restKeys;
    }
    return { key: index, ...option };
  });

  const handleChange = (event, { options, value }) => {
    const itemSelected = (options || []).find((item) => item.value === value);
    if (onChange) onChange(event, itemSelected);
  };

  const dropdownClassnames = classnames("Dropdown", {
    [className]: !!className,
    [size]: !!size,
  });

  return (
    <SemanticDropdown
      onChange={handleChange}
      className={dropdownClassnames}
      icon="RemixIcon ri-arrow-down-s-line"
      {...(restProps.children ? {} : { options: dropdownOptions })}
      {...restProps}
    />
  );
}

export default Dropdown;
