import {
  REGISTER_FORM,
  SET_IS_EDITING_FORM,
  SET_SHOW_WARNING_MESSAGE,
  RESET_FORM,
} from "./actionTypes";
import reducer, { formStatusInitialState } from "./formsStatusReducer";
import { useReducer } from "react";

export default () => {
  const [forms, dispatch] = useReducer(reducer, formStatusInitialState);
  const registerForm = (name) => {
    dispatch({
      type: REGISTER_FORM,
      name,
    });
  };

  const setEditingForm = (name, status) =>
    dispatch({
      type: SET_IS_EDITING_FORM,
      name,
      status,
    });

  const setWarningMessage = (name, status) =>
    dispatch({
      type: SET_SHOW_WARNING_MESSAGE,
      name,
      status,
    });

  const resetForm = (target = "forms") =>
    dispatch({ type: RESET_FORM, target });

  const getFormState = (name) => forms.find((form) => form.name === name) || {};

  return {
    formStatus: forms,
    registerForm,
    setEditingForm,
    setWarningMessage,
    resetForm,
    getFormState,
  };
};
