export const FETCH_DASHBOARD_PORTFOLIOS_START =
  "dashboardPortfoliosView/FETCH_DASHBOARD_PORTFOLIOS_START";
export const FETCH_DASHBOARD_PORTFOLIOS_SUCCESS =
  "dashboardPortfoliosView/FETCH_DASHBOARD_PORTFOLIOS_SUCCESS";
export const FETCH_DASHBOARD_PORTFOLIOS_FAILED =
  "dashboardPortfoliosView/FETCH_DASHBOARD_PORTFOLIOS_FAILED";
export const EDIT_DASHBOARD_PORTFOLIOS =
  "dashboardPortfoliosView/EDIT_DASHBOARD_PORTFOLIOS";
export const UPDATE_DASHBOARD_PORTFOLIOS_START =
  "dashboardPortfoliosView/UPDATE_DASHBOARD_PORTFOLIOS_START";
export const UPDATE_DASHBOARD_PORTFOLIOS_SUCCESS =
  "dashboardPortfoliosView/UPDATE_DASHBOARD_PORTFOLIOS_SUCCESS";

export function fetchDashboardPortfoliosStart() {
  return { type: FETCH_DASHBOARD_PORTFOLIOS_START };
}

export function fetchDashboardPortfoliosSuccess(portfolios) {
  return { type: FETCH_DASHBOARD_PORTFOLIOS_SUCCESS, portfolios };
}

export function fetchDashboardPortfoliosFailed(errors) {
  return { type: FETCH_DASHBOARD_PORTFOLIOS_FAILED, errors };
}

export function editDashboardPortfolios(portfolioId, value) {
  return { type: EDIT_DASHBOARD_PORTFOLIOS, portfolioId, value };
}

export function updateDashboardPortfoliosStart() {
  return { type: UPDATE_DASHBOARD_PORTFOLIOS_START };
}

export function updateDashboardPortfoliosSuccess() {
  return { type: UPDATE_DASHBOARD_PORTFOLIOS_SUCCESS };
}
