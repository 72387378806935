import React from "react";
import PropTypes from "prop-types";
import Header from "components_new/atoms/Header";
import classnames from "classnames";
import "./EmptyCard.scss";

function EmptyCard({
  title,
  label,
  nilLabel = "0",
  extraClassnames,
  ...restProps
}) {
  const classNames = classnames("EmptyState", {
    [extraClassnames]: !!extraClassnames,
  });
  return (
    <div className={classNames} {...restProps}>
      {label && <Header as="h5">{label}</Header>}
      <div>
        <span data-testid="EmptyStateText">
          {nilLabel} {title}
        </span>
      </div>
    </div>
  );
}

EmptyCard.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  nilLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  extraClassnames: PropTypes.string,
};

EmptyCard.defaultProps = {
  title: null,
  label: null,
};

export default EmptyCard;
