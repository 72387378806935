import escapeStringRegexp from "escape-string-regexp";
import React from "react";
import { isString } from "lodash";

export function parseItemResult(title, query) {
  const escapedSearchTerm = escapeStringRegexp(query);
  const searchTermRegex = new RegExp(escapedSearchTerm, "gi");
  const parsedResult = title.replace(searchTermRegex, "%$&%").split("%");
  return parsedResult.map((item, index) =>
    item.toLowerCase() === query.toLowerCase() ? (
      <span className="ResultItemAvatar-highlightMatch" key={index}>
        {item}
      </span>
    ) : (
      item
    ),
  );
}

export const SearchScopes = {
  ALL: "all",
  PPS: "pps",
  ALL_COMPANIES: "all_companies",
  COMPANIES: "companies",
  INVESTMENTS: "investments",
  DEALS: "deals",
  FUNDS: "funds",
  CONTACTS: "contacts",
};

export const singleScopes = [
  SearchScopes.ALL_COMPANIES,
  SearchScopes.COMPANIES,
  SearchScopes.INVESTMENTS,
  SearchScopes.DEALS,
  SearchScopes.FUNDS,
  SearchScopes.CONTACTS,
];

export const expectSingleCategory = (scope) =>
  !(
    Array.isArray(scope) ||
    scope === SearchScopes.ALL ||
    scope === SearchScopes.PPS
  );

export const scopeForUrl = (scope) => {
  if (isString(scope)) {
    return scope;
  }
  return encodeURIComponent(JSON.stringify(scope));
};

export const scopeToKey = (scope) => {
  switch (scope) {
    case SearchScopes.ALL_COMPANIES:
      return SearchScopes.COMPANIES;
    case SearchScopes.FUNDS:
      return SearchScopes.INVESTMENTS;
    default:
      return scope;
  }
};
