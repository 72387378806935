import React from "react";
import CashIcon from "components/investments/InvestmentDistribution/CashIcon";
import formatPrice from "lib/formatters/formatPrice";

function RealizedGainCard({ realizedGain }) {
  const realizedGainValue = realizedGain ? formatPrice(realizedGain) : "$0.00";
  return (
    <div className="EventCard RealizedGainCard">
      <div className="EventCard-Icon">
        <CashIcon />
      </div>
      <div className="EventCard-Content">
        <h3 className="u-contentBold EventCard-ContentTitle">Realized Gain</h3>
        <div
          className="u-textWithEllipsis EventCard-ContentValue"
          title={realizedGainValue}
        >
          {realizedGainValue}
        </div>
      </div>
    </div>
  );
}

export default RealizedGainCard;
