function preventerHandler(handler) {
  return (event) => {
    if (event) {
      event.preventDefault();
      handler(event);
    }
  };
}

export default preventerHandler;
