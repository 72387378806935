import { SET_CURRENT_INVITATION, CLEAN_INVITATION } from "./actionTypes";

const initialState = {
  inviteOrganizationName: null,
  inviteOrganizationUsername: null,
  inviteOrganizationMembershipId: null,
  inviteStatus: null,
  inviteCode: null,
  isExistingUser: null,
};

function invitation(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_INVITATION: {
      const {
        organization_name: inviteOrganizationName,
        organization_username: inviteOrganizationUsername,
        organization_membership_id: inviteOrganizationMembershipId,
        email: inviteEmail,
        invite_code: inviteCode,
        status: inviteStatus,
        existing_user: isExistingUser,
      } = action.value;
      return {
        ...state,
        inviteOrganizationName,
        inviteOrganizationUsername,
        inviteOrganizationMembershipId,
        inviteEmail,
        inviteCode,
        inviteStatus,
        isExistingUser,
      };
    }
    case CLEAN_INVITATION:
      return initialState;
    default:
      return state;
  }
}

export default invitation;
