import {
  RECEIVE_EMAIL_USER,
  UPDATE_CREATE_USER_FORM,
  FETCH_INVITE_EMAIL_ERROR,
  FETCH_INVITE_EMAIL_START,
  FETCH_INVITE_EMAIL_SUCCESS,
  CREATE_USER_ERROR,
} from "actions/synchronous/createAccountView";

const initialState = {
  user: {},
  error: "",
  errors: {},
  submitting: false,
  portfolioName: "",
  portfolioStatus: "",
  inviterName: "",
};

function createAccount(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_EMAIL_USER: {
      return Object.assign({}, state, {
        user: Object.assign({}, state.user, {
          email: action.email,
        }),
        portfolioName: action.portfolioName,
        portfolioStatus: action.status,
        inviterName: action.inviterName,
      });
    }
    case UPDATE_CREATE_USER_FORM: {
      return Object.assign({}, state, {
        user: Object.assign({}, state.user, { [action.key]: action.value }),
      });
    }
    case FETCH_INVITE_EMAIL_START:
      return Object.assign({}, state, { submitting: true });
    case FETCH_INVITE_EMAIL_SUCCESS:
      return Object.assign({}, state, { submitting: false });
    case FETCH_INVITE_EMAIL_ERROR: {
      return {
        ...state,
        error: action.error,
        submitting: false,
      };
    }
    case CREATE_USER_ERROR: {
      return Object.assign({}, state, {
        errors: Object.assign({}, state.errors, action.errors),
      });
    }
    default:
      return state;
  }
}

export default createAccount;
