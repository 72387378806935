import React, { useState, useEffect, Fragment } from "react";
import Icon from "components_new/atoms/Icon";
import TableCell from "../TableCell";
import TableRow from "../TableRow";
import Checkbox from "components_new/atoms/Checkbox";
import PropTypes from "prop-types";
import classnames from "classnames";
import "semantic-ui-css/components/table.css";
import "./ExpandableRow.scss";

function ExpandableRow({
  mainCells,
  selectable,
  children,
  allRowsExpanded,
  allRowsCollapsed,
  resetAllRows,
  withCheckbox,
  ...props
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (allRowsCollapsed) {
      setIsExpanded(false);
    }
  }, [allRowsCollapsed]);

  useEffect(() => {
    if (allRowsExpanded) {
      setIsExpanded(true);
    }
  }, [allRowsExpanded]);

  const toggleExpanded = () => {
    setIsExpanded((previousState) => !previousState);
    if (resetAllRows) resetAllRows();
  };

  const updatedChildren = React.Children.map(children || null, (child) => {
    const childElementClassnames = classnames("ExpandableRow-childElement", {
      [child.props.className]: child.props.className,
    });

    return React.cloneElement(child, {
      className: childElementClassnames,
    });
  });

  const expandableRowClassnames = classnames("ExpandableRow", {
    "is-expanded": isExpanded,
    WithCheckbox: withCheckbox,
  });

  return (
    <Fragment>
      <TableRow className={expandableRowClassnames} {...props}>
        {withCheckbox && (
          <TableCell collapsing>
            <Checkbox />
          </TableCell>
        )}
        <TableCell className="ExpandableRow-caret">
          <Icon icon="ri-arrow-down-s-line" onClick={toggleExpanded} />
          {selectable && <Checkbox />}
        </TableCell>
        {mainCells}
      </TableRow>

      {isExpanded && updatedChildren}
    </Fragment>
  );
}

ExpandableRow.defaultProps = {
  allRowsExpanded: false,
  allRowsCollapsed: false,
  withCheckbox: false,
};

ExpandableRow.propTypes = {
  mainCells: PropTypes.node,
  children: PropTypes.node,
  selectable: PropTypes.bool,
  allRowsExpanded: PropTypes.bool,
  allRowsCollapsed: PropTypes.bool,
  resetAllRows: PropTypes.func,
  withCheckbox: PropTypes.bool,
};

export default ExpandableRow;
