import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { closeModal, updateModalOptions } from "store/modalV2/actions";
import ConfirmationModal from "components/shared/ConfirmationModal";
import {
  deleteOrganization,
  fetchOrganizations,
  setCurrentOrganization,
} from "store/organizations/actions";
import { getOrganizationResourceAsCollection } from "store/resourcesV2";

const MODAL_NAME = "confirmOrganizationDeletion";

function ConfirmDeletionContainer({ isSubmitting = false }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const closeModalCallback = useCallback(() => dispatch(closeModal()), [
    dispatch,
  ]);

  const organizationId = useSelector(
    (state) => state.organizations.currentOrganizationId,
  );
  const organizationsList = useSelector((state) =>
    getOrganizationResourceAsCollection(state, "organizations"),
  );
  const privateOrganization = useMemo(
    () => organizationsList.find((item) => item.private),
    [organizationsList],
  );

  const deleteOrganizationCallback = useCallback(async () => {
    dispatch(updateModalOptions(MODAL_NAME, { isSubmitting: true }));
    await dispatch(deleteOrganization(organizationId));
    dispatch(setCurrentOrganization(privateOrganization));
    await dispatch(fetchOrganizations());
    dispatch(updateModalOptions(MODAL_NAME, { isSubmitting: false }));
    navigate(`/${privateOrganization.username}/dashboard/`);
    dispatch(closeModal());
  }, [dispatch, navigate, privateOrganization, organizationId]);

  return (
    <ConfirmationModal
      title="Delete Confirmation"
      confirmButtonLabel="Delete Organization"
      cancelButtonLabel="Cancel"
      primaryButtonClassnames="Button--warning"
      message="Deleting an organization will delete all of its portfolios, its associated members and data. This action cannot be undone."
      action={deleteOrganizationCallback}
      closeModal={closeModalCallback}
      submitting={isSubmitting}
    />
  );
}

export default ConfirmDeletionContainer;
