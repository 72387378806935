import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card } from "@ableco/semantic-ui-react";
import classNames from "classnames";
import { default as BaseHeader } from "components_new/atoms/Header";
import Icon from "components_new/atoms/Icon";
import InlineValueList from "components_new/atoms/InlineValueList";
import Avatar from "components_new/elements/Avatar";
import AttributeList from "components_new/elements/AttributeList";
import Tooltip from "components_new/atoms/Tooltip";
import "./VerticalSummaryCard.scss";

const Header = ({ avatarInfo, editFunc, tooltip = true, headerIconName }) => {
  return (
    <Card.Content className="header" data-testid="companyHeader">
      <Avatar
        avatarIcon="company-med"
        title={avatarInfo.title}
        initials={avatarInfo.initials}
        imageUrl={avatarInfo.imageUrl}
        label={avatarInfo.label === "Active" ? null : avatarInfo.label}
        labelColor={avatarInfo.labelColor}
        circular={avatarInfo.circular}
        titleUrl={avatarInfo.titleUrl}
        attributes={avatarInfo.avatarAttributes}
      />
      {tooltip && (
        <Tooltip
          position="top center"
          pinned
          trigger={
            <div
              className="CircularButton"
              onClick={editFunc}
              data-testid="editIcon"
            >
              <Icon
                extraClassnames="ToggleEdit"
                icon={headerIconName}
                alt="Edit"
              />
            </div>
          }
          content="Edit Company Information"
        />
      )}
    </Card.Content>
  );
};

const Description = ({ description }) => {
  return (
    <Card.Content className="description" data-testid="companyDescription">
      <span>{description}</span>
    </Card.Content>
  );
};

const Attributes = ({ attributes }) => {
  // TODO: use InlineValueList directly
  return (
    <Card.Content className="attributes" data-testid="companyAttributes">
      <AttributeList attributes={attributes} />
    </Card.Content>
  );
};

const MoreInformation = ({ moreInformation, inverted = false }) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };
  const utilityIcon = expanded ? "ri-arrow-up-s-line" : "ri-arrow-down-s-line";
  const moreInformationClassNames = classNames("MoreInformation", {
    inverted: inverted,
  });
  const moreInformationInlineValueListClassNames = classNames(
    "MoreInformationInlineValueList",
    {
      hidden: !expanded,
    },
  );
  return (
    <Card.Content className={moreInformationClassNames}>
      <div onClick={toggleExpanded} className="ToggleContainer">
        <BaseHeader size="tiny">
          {expanded ? "See less" : "See more"}
        </BaseHeader>
        <Icon
          size="mini"
          extraClassnames="ToggleIcon"
          icon={utilityIcon}
          alt="More Company Details"
        />
      </div>
      <div className={moreInformationInlineValueListClassNames}>
        <InlineValueList data={moreInformation} />
      </div>
    </Card.Content>
  );
};

const VerticalSummaryCard = ({
  title,
  titleUrl = null,
  imageUrl,
  label,
  labelColor,
  editFunc,
  description,
  attributes,
  moreInformation,
  tooltip,
  circular,
  initials,
  headerIconName = "ri-pencil-line",
  avatarAttributes,
}) => {
  const avatarInfo = {
    title,
    imageUrl,
    label,
    labelColor,
    circular,
    initials,
    titleUrl,
    avatarAttributes,
  };
  return (
    <Card className="Inline VerticalSummaryCard">
      <Header
        avatarInfo={avatarInfo}
        editFunc={editFunc}
        tooltip={tooltip}
        headerIconName={headerIconName}
      />
      {!!description && <Description description={description} />}
      {attributes && <Attributes attributes={attributes} />}
      {moreInformation && (
        <MoreInformation moreInformation={moreInformation} inverted={true} />
      )}
    </Card>
  );
};

VerticalSummaryCard.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  imageUrl: PropTypes.string,
  label: PropTypes.string,
  labelColor: PropTypes.string,
  editFunc: PropTypes.func,
  description: PropTypes.string,
  attributes: PropTypes.arrayOf(PropTypes.shape({})),
  moreInformation: PropTypes.arrayOf(PropTypes.shape({})),
  tooltip: PropTypes.bool,
  circular: PropTypes.bool,
  initials: PropTypes.string,
  titleUrl: PropTypes.string,
  headerIconName: PropTypes.string,
  avatarAttributes: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, labelType: PropTypes.string }),
  ),
};

export default VerticalSummaryCard;
