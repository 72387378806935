import React from "react";
import formatDate from "lib/formatDate";
import AttachmentIcon from "-!svg-react-loader?!assets/icons/utility/attachment.svg?name=AttachmentIcon";

function EventCellPreview({ historyAction, documents }) {
  let showDocumentsIcon = "";
  const { description, date } = historyAction;
  if (documents.length > 0) {
    showDocumentsIcon = <AttachmentIcon className="DocumentsViewer-icon" />;
  }

  return (
    <div className="EventCellPreview">
      <div className="EventCellPreview--event">
        <div className="EventCellPreview--eventName u-textWithEllipsis">
          {description}
        </div>
        <div className="EventCellPreview--eventDocuments">
          {showDocumentsIcon}
        </div>
      </div>
      {formatDate(date, "semiDetailed")}
    </div>
  );
}

export default EventCellPreview;
