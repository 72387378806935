import React from "react";
import { TableCell, TableRow } from "components_new/elements/Table";
import Value from "components_new/atoms/Value";
import SubValue from "components_new/atoms/SubValue";
import formatDate from "lib/formatDate";
import formatPrice from "lib/formatters/formatPrice";

function CompanyCashFlowRow({ cashFlow }) {
  const {
    date,
    description,
    subDescription,
    portfolio,
    amount,
    total,
  } = cashFlow;

  return (
    <TableRow>
      <TableCell className="DateColumn">
        <Value>{formatDate(date)}</Value>
      </TableCell>
      <TableCell>
        <Value>{description}</Value>
        <SubValue>{subDescription}</SubValue>
      </TableCell>
      <TableCell>
        <Value>{portfolio}</Value>
      </TableCell>
      <TableCell>
        <Value>{formatPrice(amount)}</Value>
      </TableCell>
      <TableCell>
        <Value>{formatPrice(total)}</Value>
      </TableCell>
    </TableRow>
  );
}

export default CompanyCashFlowRow;
