import PropTypes from "prop-types";
import React, { useRef, useEffect } from "react";
import Link from "components_new/atoms/Link";
import classNames from "classnames";
import { useLocation, useMatch } from "react-router-dom";

function TabContent({
  disabled,
  children,
  url,
  extraClassnames,
  currentOrganizationUsername,
}) {
  if (disabled) {
    return children;
  }
  return (
    <Link
      to={`/${currentOrganizationUsername}${url}`}
      extraClassnames={extraClassnames}
    >
      {children}
    </Link>
  );
}

function getTabHighlightSettings(tabElement) {
  if (tabElement) {
    return {
      width: tabElement.clientWidth,
      height: tabElement.clientHeight,
      left: tabElement.offsetLeft,
      top: tabElement.offsetTop,
    };
  }
  return null;
}

function Tab(props) {
  const {
    urlAlias,
    url,
    disabled = false,
    orientation,
    updateTabHighlight,
    currentOrganizationUsername,
  } = props;

  const completeUrl = currentOrganizationUsername + (url || "");
  const completeAliasUrl = currentOrganizationUsername + (urlAlias || "");

  const tabElement = useRef(null);
  const location = useLocation();
  const urlMatch = useMatch(completeUrl);
  const urlAliasMatch = useMatch(completeAliasUrl);

  const isActiveUrl = !!(
    urlMatch ||
    (urlAlias && urlAliasMatch) ||
    location.pathname.includes(completeUrl)
  );

  useEffect(() => {
    if (isActiveUrl && tabElement.current) {
      const tabHighlightSettings = getTabHighlightSettings(tabElement.current);
      updateTabHighlight(tabHighlightSettings);
    }
    // NOTE: updateTabHighlight is not created using useCallback
    //        and so it can't be used on this effect dependencies array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveUrl, tabElement.current]);

  const tabClassnames = classNames({
    "NavigationTabs-singleTab": true,
    "is-active": isActiveUrl,
    "is-disabled": disabled,
  });

  const tabLinkClassnames = classNames({
    "u-tabDefault": orientation === "horizontal",
    "u-verticalTabDefault": orientation === "vertical",
    "u-tabActive": isActiveUrl && orientation === "horizontal",
    "u-verticalTabActive": isActiveUrl && orientation === "vertical",
  });

  return (
    <div className={tabClassnames} ref={tabElement}>
      <TabContent extraClassnames={tabLinkClassnames} {...props} />
    </div>
  );
}

Tab.propTypes = {
  url: PropTypes.string.isRequired,
  urlAlias: PropTypes.string,
  children: PropTypes.node.isRequired,
  updateTabHighlight: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  orientation: PropTypes.oneOf(["vertical", "horizontal"]).isRequired,
  isActiveUrl: PropTypes.bool,
  withoutOrganization: PropTypes.bool,
  currentOrganizationUsername: PropTypes.string,
};

export default Tab;
