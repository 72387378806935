import React, { useState, useEffect } from "react";
import FormGroup from "components_new/elements/FormGroup";
import Button from "components_new/atoms/Button";
import Icon from "components_new/atoms/Icon";
import CompanySelector from "components_new/elements/CompanySelector/CompanySelector";
import Tags from "components/shared/Tags";
import Checkbox from "components_new/atoms/Checkbox";
import { isEmpty, times } from "lodash";
import classnames from "classnames";
import "./ContactRoles.scss";

const ROLE_INITIAL_VALUES = {
  company_id: null,
  company: null,
  company_titles: [],
  is_primary: true,
};
const INITIAL_ROLE_COUNT = 1;

function ContactRoles({
  onChange,
  errors,
  titleSuggestions,
  filterTitles,
  mountSidebarComponent,
  hideSidebarComponent,
  submitTo,
  roles,
  disabledCompanyCreation,
}) {
  const [roleCount, setRoleCount] = useState(
    isEmpty(roles) ? INITIAL_ROLE_COUNT : roles.length,
  );
  const [contactRoles, setContactRoles] = useState(
    isEmpty(roles) ? [ROLE_INITIAL_VALUES] : roles,
  );

  const handleAddRole = () => {
    setContactRoles([
      ...contactRoles,
      { ...ROLE_INITIAL_VALUES, is_primary: false },
    ]);
    setRoleCount(roleCount + 1);
  };

  useEffect(() => {
    const validRoles = contactRoles.filter(
      (role) => !(isEmpty(role.company) && isEmpty(role.company_titles)),
    );
    onChange("roles_attributes", validRoles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactRoles]);

  return (
    <FormGroup
      extraClassnames="ContactRolesGroup"
      title="Roles"
      subtitle="Information related to current or past professional associations."
    >
      {times(roleCount, (index) => (
        <ContactRole
          key={index}
          roleIndex={index}
          contactRoles={contactRoles}
          setContactRoles={setContactRoles}
          filterTitles={filterTitles}
          titleSuggestions={titleSuggestions}
          roleCount={roleCount}
          contactRol={contactRoles[index]}
          mountSidebarComponent={mountSidebarComponent}
          hideSidebarComponent={hideSidebarComponent}
          submitTo={submitTo}
          errors={errors}
          disabledCompanyCreation={disabledCompanyCreation}
        />
      ))}
      <Button secondary size="small" onClick={handleAddRole}>
        <Icon icon="ri-add-line" />
        Add New Role
      </Button>
    </FormGroup>
  );
}

function ContactRole({
  roleIndex,
  contactRoles,
  setContactRoles,
  filterTitles,
  titleSuggestions,
  roleCount,
  contactRol,
  mountSidebarComponent,
  hideSidebarComponent,
  submitTo,
  errors,
  disabledCompanyCreation,
}) {
  const [company, setCompany] = useState(contactRol.company);
  const [titles, setTitles] = useState(contactRol.company_titles);

  const contactRolClassnames = classnames("ContactRolesGroup", {
    [`ContactRole-${roleIndex + 1}`]: true,
    "ContactRole-last": contactRoles.length === roleIndex + 1,
  });

  useEffect(() => {
    const updatedList = [...contactRoles];
    let updatedRole = {
      ...contactRol,
      company_titles: titles,
      company_id: company && company.id,
    };
    if (company && company.isNewCompany) {
      updatedRole = { ...updatedRole, company };
    }
    updatedList[roleIndex] = { ...updatedRole };
    setContactRoles(updatedList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, titles]);

  const handleSetPrimaryRole = () => {
    const updatedList = contactRoles.map((role, index) => {
      role.is_primary = index === roleIndex ? true : false;
      return role;
    });
    setContactRoles(updatedList);
  };

  const handleCreateNewCompany = (value) => {
    if (disabledCompanyCreation) return;
    mountSidebarComponent(
      "addCompanyForm",
      "Add New Company Form",
      {
        data: { ...value },
      },
      submitTo,
    );
    hideSidebarComponent(submitTo);
  };

  return (
    <div className={contactRolClassnames}>
      <CompanySelector
        name="company"
        label="Company"
        placeHolder="Search companies"
        onChange={(_, value) => setCompany(value)}
        onCreateNewCompany={handleCreateNewCompany}
        errorMessage={errors.for(`roles[${roleIndex}].company_id`)}
        isOnSidebar
        size="tiny"
        prefilledValue={contactRoles[roleIndex].company}
        selectedCompany={contactRoles[roleIndex].company}
        disabledCompanyCreation={disabledCompanyCreation}
      />
      <Tags
        label="Titles"
        name="titles"
        taggedList={titles}
        onChange={(value) => setTitles(value)}
        onFilter={filterTitles}
        suggestedList={titleSuggestions}
        errorMessage={errors.for(`roles[${roleIndex}].company_titles`)}
        size="tiny"
      />

      {roleCount > 1 && (
        <Checkbox
          radio
          label="Primary role"
          checked={contactRol.is_primary}
          onChange={handleSetPrimaryRole}
        />
      )}
    </div>
  );
}

export default ContactRoles;
