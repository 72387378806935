import React from "react";
import NotApplicableLabel from "components/shared/NotApplicableLabel";
import PropTypes from "prop-types";

function ColumnSection({ isNotApplicable, children }) {
  if (isNotApplicable) {
    return <NotApplicableLabel />;
  }
  return children;
}

ColumnSection.propTypes = {
  isNotApplicable: PropTypes.bool,
  children: PropTypes.node,
};

export default ColumnSection;
