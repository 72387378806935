import React from "react";
import FormGroup from "components_new/elements/FormGroup";
import RadioButtonGroup from "components_new/elements/AdvancedFields/RadioButtonGroup";
import Input from "components_new/atoms/Input";

function StockDetails({ title, formData, handleChange }) {
  const { custom_name, type } = formData;

  function getClassValue(type) {
    let classData = {};
    switch (type) {
      case "equity":
      case "both":
        classData = {
          classValue: formData.equity_class,
          classLabel: "equity_class",
          certificateValue: formData.stock_certificate_number,
          certificateLabel: "stock_certificate_number",
        };
        return classData;
      case "option":
        classData = {
          classValue: formData.stock_class,
          classLabel: "stock_class",
          certificateValue: formData.certificate_number,
          certificateLabel: "certificate_number",
        };
        return classData;
      case "warrant":
        classData = {
          classValue: formData.stock_class,
          classLabel: "stock_class",
          certificateValue: formData.certificate_number,
          certificateLabel: "certificate_number",
        };
        return classData;
      default:
        throw new Error(`No registered object for ${type}.`);
    }
  }

  const stockData = getClassValue(type);

  return (
    <FormGroup
      extraClassnames="StockDetailsGroup"
      title={title}
      isExpanded={false}
      required={false}
      noBottomBorder
      subGroup
    >
      <RadioButtonGroup
        name={stockData.classLabel}
        label="Type of stock"
        inputProps={[
          { value: "common", label: "Common" },
          { value: "preferred", label: "Preferred" },
        ]}
        onChange={(value) => handleChange(stockData.classLabel, value)}
        value={stockData.classValue}
        size="tiny"
      />
      <Input
        name={stockData.certificateLabel}
        label="Certificate number"
        placeholder="e.g. 0001"
        value={stockData.certificateValue}
        onChange={(_, { value }) =>
          handleChange(stockData.certificateLabel, value)
        }
        size="tiny"
      />
      <Input
        name="custom_name"
        label="Series Nickname"
        placeholder="e.g. Partnership Investment"
        value={custom_name}
        onChange={(_, { value }) => handleChange("custom_name", value)}
        size="tiny"
      />
    </FormGroup>
  );
}

export default StockDetails;
