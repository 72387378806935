import {
  SEARCH_START,
  SEARCH_SUCCESS,
  SEARCH_FAIL,
  CLEAR_SEARCH,
} from "./actions.js";

const initialState = {
  isFetching: false,
  results: {},
  count: 0,
  query: null,
  shouldDisplayResults: false,
  error: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SEARCH_START:
      return { ...state, isFetching: true };
    case SEARCH_SUCCESS:
      const { results, count, query } = action;
      return {
        ...state,
        isFetching: false,
        shouldDisplayResults: true,
        results,
        count,
        query,
      };
    case SEARCH_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case CLEAR_SEARCH:
      return initialState;
    default:
      return state;
  }
}
