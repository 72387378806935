import { connect } from "react-redux";
import React from "react";
import PropTypes from "prop-types";
import PrimaryButton from "components/shared/PrimaryButton";
import { ModalHeader, ModalBody } from "components/shared/Modal";
import { closeModal } from "store/modal";
import AlertIcon from "-!svg-react-loader?!assets/icons/utility/alert.svg?name=AlertIcon";

function invalidLogoImageModal({ closeModal }) {
  return (
    <div className="ErrorModal">
      <ModalHeader />
      <ModalBody type="announcement">
        <div className="ErrorModal-icon">
          <AlertIcon width="36" height="36" />
        </div>
        <h1 className="u-heading">There was a problem uploading your image</h1>
        <div className="ErrorModal-note">
          The image you attempted to upload is invalid, please try again.
        </div>
        <div className="ErrorModal-actionButtons">
          <PrimaryButton onClick={() => closeModal()}>Close</PrimaryButton>
        </div>
      </ModalBody>
    </div>
  );
}

invalidLogoImageModal.propTypes = {
  closeModal: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => dispatch(closeModal()),
  };
}

export default connect(null, mapDispatchToProps)(invalidLogoImageModal);
