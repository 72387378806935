import React, { Fragment } from "react";
import formatDate from "lib/formatDate";
import Label from "components_new/atoms/Label";
import EmptyLabel from "components_new/atoms/EmptyLabel";
import EllipsisDropdown from "components_new/elements/EllipsisDropdown";
import Link from "components_new/atoms/Link";
import FileIcon from "components_new/atoms/FileIcon";
import iconType from "components_new/lib/iconType";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { documentPropType } from "PropTypes";
import numeral from "numeral";

function ValueOrEmptyLabel({ value }) {
  return isEmpty(value) ? <EmptyLabel /> : value;
}

function FileRowPreview({
  file,
  setEditFile,
  setDeleteFile,
  currentOrganizationUsername,
  dropdownAlignment = "right",
  componentSize,
}) {
  const {
    id: fileId,
    created_at: createdAt,
    document_type: documentType,
    closing_document: closingDocument,
    size,
  } = file;

  const fileRowPreviewActions = [
    {
      key: "edit",
      text: "Edit",
      onClick: () => setEditFile(fileId),
    },
    {
      key: "delete",
      text: "Delete",
      onClick: () => setDeleteFile(fileId),
    },
  ];

  const fileUrl = `/${currentOrganizationUsername}/documents/${fileId}/download`;

  const maxCharsFilename = closingDocument ? 20 : 30;

  const niceFileSize = numeral(size).format("0[.]0 b");

  return (
    <Fragment>
      {componentSize !== "mini" && (
        <div className="FileRow-iconFile">
          <FileIcon type={iconType(file.mime_type)} />
        </div>
      )}
      <div className="FileRow-fileName">
        <Link
          extraClassnames="FileRow-fileLink u-textWithEllipsis"
          title={file.file_name}
          to={fileUrl}
          maxCharsLength={maxCharsFilename}
          isExternal
        />
        {closingDocument && componentSize !== "mini" && (
          <Label size="mini" type="alternate">
            Closing
          </Label>
        )}
      </div>
      {componentSize !== "mini" && (
        <Fragment>
          <div className="FileRow-fileType">
            <ValueOrEmptyLabel value={documentType} />
          </div>
          <div className="FileRow-uploadDate">
            <ValueOrEmptyLabel value={formatDate(createdAt, "semiDetailed")} />
          </div>
        </Fragment>
      )}
      {componentSize === "mini" && (
        <div className="FileRow-fileSize">{niceFileSize}</div>
      )}
      <div className="FileRow-actions">
        <EllipsisDropdown
          extraClassnames="Dropdown--dots"
          iconOrientation="vertical"
          iconSize="regular"
          align={dropdownAlignment}
          options={fileRowPreviewActions}
        />
      </div>
    </Fragment>
  );
}

FileRowPreview.propTypes = {
  file: documentPropType.isRequired,
  setEditFile: PropTypes.func.isRequired,
  setDeleteFile: PropTypes.func.isRequired,
  currentOrganizationUsername: PropTypes.string.isRequired,
  dropdownAlignment: PropTypes.string,
  componentSize: PropTypes.string,
};

export default FileRowPreview;
