import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import Icon from "components_new/atoms/Icon";
import Input from "components_new/atoms/Input";
import Button from "components_new/atoms/Button";
import verifyEmail from "lib/verifyEmail";

function InviteMember({ inviteOrganizationMember, isSubmitting }) {
  const [formData, setFormData] = useState({});
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);

  const updateForm = (key, value) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const handleSubmit = () => {
    inviteOrganizationMember(formData);
    setFormData({ email: "" });
    setDisableSubmitButton(true);
  };

  const verifyEmailInput = (key, value) => {
    updateForm(key, value);
    if (verifyEmail(value)) {
      setDisableSubmitButton(false);
    } else {
      setDisableSubmitButton(true);
    }
  };

  return (
    <Fragment>
      <form className="OrganizationMembers-InviteMember">
        <div className="InviteMember-emailInput">
          <Input
            placeholder="Invite new organization members by email address"
            value={formData.email}
            onChange={({ target: { value } }) =>
              verifyEmailInput("email", value)
            }
            size="small"
          />
        </div>
        <div className="InviteMember-sendButton">
          <Button
            primary
            type="submit"
            onClick={() => handleSubmit()}
            disabled={disableSubmitButton}
            size="small"
            iconPosition="left"
            loading={isSubmitting}
          >
            <Icon icon="ri-add-line" />
            <span className="Button-text">Send Invite</span>
          </Button>
        </div>
      </form>
    </Fragment>
  );
}

InviteMember.propTypes = {
  inviteOrganizationMember: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

InviteMember.defaultProps = {
  isSubmitting: false,
};

export default InviteMember;
