export const ADD_DOCUMENTS_TO_UPLOAD_QUEUE =
  "documentUploads/ADD_DOCUMENTS_TO_UPLOAD_QUEUE";
export const CLEAN_DOCUMENT_UPLOADS = "documentUploads/CLEAN_DOCUMENT_UPLOADS";
export const REMOVE_DOCUMENT_FROM_QUEUE =
  "documentUploads/REMOVE_DOCUMENT_FROM_QUEUE";
export const REMOVE_UPLOADED_DOCUMENT =
  "documentUploads/REMOVE_UPLOADED_DOCUMENT";
export const STORE_UPLOADED_DOCUMENT =
  "documentUploads/STORE_UPLOADED_DOCUMENT";
export const UPDATE_UPLOADED_DOCUMENT =
  "documentUploads/UPDATE_UPLOADED_DOCUMENT";
export const UPDATE_DOCUMENT_IN_QUEUE =
  "documentUploads/UPDATE_DOCUMENT_IN_QUEUE";

export function addDocumentsToUploadQueue(documents) {
  return { type: ADD_DOCUMENTS_TO_UPLOAD_QUEUE, documents };
}

export function cleanDocumentUploads() {
  return { type: CLEAN_DOCUMENT_UPLOADS };
}

export function removeDocumentFromQueue(givenDocument) {
  return { type: REMOVE_DOCUMENT_FROM_QUEUE, givenDocument };
}

export function removeUploadedDocument(givenDocument) {
  return { type: REMOVE_UPLOADED_DOCUMENT, givenDocument };
}

export function storeUploadedDocument(givenDocumentId, uploadedDocument) {
  return { type: STORE_UPLOADED_DOCUMENT, givenDocumentId, uploadedDocument };
}

export function updateUploadedDocument(localId, options) {
  return { type: UPDATE_UPLOADED_DOCUMENT, localId, options };
}

export function updateDocumentInQueue(givenDocument, options) {
  return { type: UPDATE_DOCUMENT_IN_QUEUE, givenDocument, options };
}
