import React, { Fragment } from "react";
import ThreePaneLayout from "components_new/layouts/ThreePaneLayout";
import Sidebar from "./Sidebar";
import EllipsisDropdown from "components_new/elements/EllipsisDropdown";
import Text from "components_new/atoms/Text";
import EmptyCard from "components_new/elements/EmptyCard";
import pluralize from "pluralize";
import formatDate from "lib/formatDate";
import Link from "components_new/atoms/Link";
import CompanyInfo from "components_new/elements/CompanyInfo";
import { isEmpty, map } from "lodash";
import {
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  SortableTableHeaderCell,
  TableRow,
  SortedTable,
} from "components_new/elements/Table";
import "./Snapshots.scss";

const snapshotTypeToLabel = {
  deal: "Deals Snapshot",
  company: "Companies Snapshot",
  co_investment: "Co-Investments Snapshot",
};

const SnapshotsTable = ({
  snapshots,
  onEditSnapshot,
  onDeleteSnapshot,
  onDuplicateSnapshot,
  organizationUsername,
}) => {
  if (isEmpty(snapshots)) {
    return <EmptyCard title="snapshots" nilLabel="No" />;
  }

  return (
    <Fragment>
      <div className="SnapshotsTable-Header">
        <Text type="body-1-bold">
          {snapshots.length} {pluralize("snapshot", snapshots.length)}
        </Text>
      </div>
      <SortedTable tableData={snapshots} className="SnapshotsTable-Table">
        {({ data, handleSort, isSorted }) => (
          <Fragment>
            <TableHeader>
              <TableRow>
                <SortableTableHeaderCell
                  value="name"
                  isSorted={isSorted}
                  handleSort={handleSort}
                  type="string"
                >
                  NAME
                </SortableTableHeaderCell>
                <SortableTableHeaderCell
                  value="type"
                  isSorted={isSorted}
                  handleSort={handleSort}
                  type="string"
                >
                  COMPANY
                </SortableTableHeaderCell>
                <SortableTableHeaderCell
                  value="type"
                  isSorted={isSorted}
                  handleSort={handleSort}
                  type="string"
                >
                  TYPE
                </SortableTableHeaderCell>
                <SortableTableHeaderCell
                  value="creator_id"
                  isSorted={isSorted}
                  handleSort={handleSort}
                  type="string"
                >
                  OWNER
                </SortableTableHeaderCell>
                <SortableTableHeaderCell
                  value="created_at"
                  isSorted={isSorted}
                  handleSort={handleSort}
                  type="string"
                >
                  DATE
                </SortableTableHeaderCell>
                <TableHeaderCell collapsing />
              </TableRow>
            </TableHeader>
            <TableBody>
              {map(
                data,
                ({
                  id,
                  name,
                  description,
                  company,
                  snapshot_type,
                  creator,
                  created_at,
                }) => (
                  <TableRow key={id}>
                    <TableCell>
                      <Link
                        to={`/${organizationUsername}/reports/snapshots/${id}`}
                      >
                        <Text type="body-1-bold" link>
                          {name}
                        </Text>
                      </Link>
                      <Text>{description}</Text>
                    </TableCell>
                    <TableCell>
                      {company && (
                        <CompanyInfo
                          title={() => (
                            <div className="CompanyNameTitle">
                              <Link
                                to={`/${organizationUsername}/companies/${company.id}`}
                                extraClassnames="CompanyNameTitle-link"
                              >
                                {company?.name}
                              </Link>
                            </div>
                          )}
                          company={company}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {snapshotTypeToLabel[snapshot_type] || "Status list"}
                    </TableCell>
                    <TableCell>{creator.full_name}</TableCell>
                    <TableCell>{formatDate(created_at, "detailed")}</TableCell>
                    <TableCell collapsing>
                      <EllipsisDropdown
                        iconOrientation="vertical"
                        iconSize="mini"
                        options={[
                          {
                            text: "Edit Snapshot",
                            onClick: () => onEditSnapshot(id),
                          },
                          {
                            text: "Duplicate Snapshot",
                            onClick: () => onDuplicateSnapshot(id),
                          },
                          {
                            text: "Delete Snapshot",
                            onClick: () => onDeleteSnapshot(id),
                          },
                        ]}
                      />
                    </TableCell>
                  </TableRow>
                ),
              )}
            </TableBody>
          </Fragment>
        )}
      </SortedTable>
    </Fragment>
  );
};

const SnapshotsView = ({
  snapshots,
  snapshotIds,
  onEditSnapshot,
  onDeleteSnapshot,
  onDuplicateSnapshot,
  organizationUsername,
}) => {
  return (
    <div className="SnapshotsView">
      <ThreePaneLayout
        children={
          <div className="Pane-Wrapper">
            <SnapshotsTable
              snapshots={snapshots}
              snapshotIds={snapshotIds}
              onEditSnapshot={onEditSnapshot}
              onDeleteSnapshot={onDeleteSnapshot}
              onDuplicateSnapshot={onDuplicateSnapshot}
              organizationUsername={organizationUsername}
            />
          </div>
        }
        sidebar={
          <div className="Pane-Wrapper">
            <Sidebar />
          </div>
        }
      />
    </div>
  );
};

export default SnapshotsView;
