import React from "react";
import FormGroup from "components_new/elements/FormGroup";
import DatePicker from "components_new/atoms/DatePicker";
import Tooltip from "components_new/atoms/Tooltip";
import NumberInput from "components_new/atoms/NumberInput";
import StockSplitRatioDescription from "./StockSplitRatioDescription";
import { placeholderDateFormat } from "config/dateInput";
import PropTypes from "prop-types";
import { investmentDataPropType } from "PropTypes";
import Icon from "components_new/atoms/Icon";
import "./StockSplitDetails.scss";

function StockSplitDetails({ title, formData, onChange, errors, investment }) {
  const tooltipContent =
    "A forward split results in an increase of shares. A reverse split results in a decrease of shares. Both types maintain the same investment value.";
  const errorForRatio =
    errors.for("split_ratio_from") || errors.for("split_ratio_to");
  return (
    <FormGroup
      extraClassnames="StockSplitDetails"
      title={title}
      isExpanded={true}
      required={true}
    >
      <DatePicker
        name="date"
        placeholder={placeholderDateFormat}
        extraClassnames="FormElement"
        label="Date of Stock Split"
        onChange={(value, validated) => onChange("date", value, validated)}
        error={!!errors.for("date")}
        errorMessage={errors.for("date")}
        value={formData.date}
        prefillToday={true}
        size="tiny"
      />

      <span className="StockSplitRatio-input-label">
        Split ratio{" "}
        <Tooltip
          position="top center"
          content={tooltipContent}
          trigger={<Icon icon="ri-information-line" />}
        ></Tooltip>
      </span>

      <div className="StockSplitRatioSection">
        <NumberInput
          name="split_ratio_from"
          placeholder="0"
          extraClassnames="FormElement StockSplitRatio"
          value={formData.split_ratio_from}
          onChange={(value) => onChange("split_ratio_from", value)}
          errorMessage={errors.for("split_ratio_from")}
          inputProps={{ "aria-label": "Split ratio from" }}
          size="tiny"
        />

        <div className="StockSplitRatio-connector"> to </div>

        <NumberInput
          name="split_ratio_to"
          placeholder="0"
          extraClassnames="FormElement StockSplitRatio"
          value={formData.split_ratio_to}
          onChange={(value) => onChange("split_ratio_to", value)}
          errorMessage={errors.for("split_ratio_to")}
          inputProps={{ "aria-label": "Split ratio to" }}
          size="tiny"
        />
      </div>

      {errorForRatio && (
        <div className="u-errorMessage ErrorMessage">{errorForRatio}</div>
      )}

      <StockSplitRatioDescription investment={investment} formData={formData} />
    </FormGroup>
  );
}

StockSplitDetails.protoTypes = {
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  investment: PropTypes.objectOf(investmentDataPropType).isRequired,
};

StockSplitDetails.defaultProps = {
  title: "Stock Split Details",
};

export default StockSplitDetails;
