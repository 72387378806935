import formatPrice from "lib/formatters/formatPrice";
import { shortDateFormat } from "config/dateInput";
import moment from "moment";
import { noNegativeOptions } from "config/formattersConfig";
import isValidNumberInput from "lib/formatters/isValidNumberInput";

function formatMoneyValue(value) {
  return isValidNumberInput(value) ? formatPrice(value, noNegativeOptions) : "";
}

function companyNameOrPlural(companyNames) {
  return `${
    companyNames.length > 1
      ? `${companyNames.length} companies`
      : companyNames[0]
  }`;
}

function getHeadline({
  type,
  initial_company_names: initialCompanyNames,
  daily_initial_amount: dailyInitialAmount,
  follow_on_company_names: followOnCompanyNames,
  daily_follow_on_amount: dailyFollowOnAmount,
}) {
  const investmentPaceChartClass =
    "InvestmentPaceChart InvestmentPaceChartTooltipInvestmentAmount";
  const isInitialInvestment = type === "initial_investment";
  const typeInvestment = isInitialInvestment ? "initial" : "follow-on";
  const dailyAmount = isInitialInvestment
    ? dailyInitialAmount
    : dailyFollowOnAmount;
  const whatCompanies = companyNameOrPlural(
    isInitialInvestment ? initialCompanyNames : followOnCompanyNames,
  );

  return `<span class="${investmentPaceChartClass}">${formatMoneyValue(
    dailyAmount,
  )}</span> ${typeInvestment} investment in ${whatCompanies}`;
}

function getClosingCopy({
  type,
  initial_investments_amount: initialInvestmentsAmount,
  accumulated_initial_company_names: initialCompanyNamesAcc,
  follow_on_investments_amount: followOnInvestmentsAmount,
  accumulated_follow_on_company_names: followOnCompanyNamesAcc,
}) {
  if (type === "initial_investment") {
    return `${formatMoneyValue(
      initialInvestmentsAmount,
    )} total initial investment in ${companyNameOrPlural(
      initialCompanyNamesAcc,
    )}`;
  }

  return `${formatMoneyValue(
    followOnInvestmentsAmount,
  )} total follow-on investment in ${companyNameOrPlural(
    followOnCompanyNamesAcc,
  )}`;
}

export function formatInvestmentPaceTooltip() {
  return {
    formatMoneyValue: (amount) => `${formatMoneyValue(amount)}`,
    shortDate: (date) => `${moment(date).format(shortDateFormat)}`,
    getHeadline: (pointData) => getHeadline(pointData),
    getClosingCopy: (pointData) => getClosingCopy(pointData),
    companyNameOrPlural: (companyNames) => companyNameOrPlural(companyNames),
  };
}

export default { formatInvestmentPaceTooltip };
