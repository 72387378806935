import { RECEIVE_CONFIG } from "actions/synchronous/config";

function config(state = {}, action) {
  switch (action.type) {
    case RECEIVE_CONFIG:
      return { ...state, ...action.config };
    default:
      return state;
  }
}

export default config;
