import React from "react";
import PropTypes from "prop-types";
import { organizationProptype } from "PropTypes";
import MightyIcon from "components_new/atoms/MightyIcon";
import ProfileAvatar from "components_new/atoms/ProfileAvatar";
import classnames from "classnames";
import SubValue from "components_new/atoms/SubValue";
import Header from "components_new/atoms/Header";
import pluralize from "pluralize";

const getOrganizationInitials = ({ name }) => {
  const initials = name
    .split(" ")
    .map((item) => item[0])
    .join("")
    .slice(0, 2);

  return initials;
};

function OrganizationItem({
  extraClassnames,
  organization,
  isCurrentUserOrganizationOwner,
  isCurrentOrganization,
  privateOrganizationUsername,
  onOrgItemClick,
}) {
  const organizationItemClassnames = classnames("item", {
    [extraClassnames]: !!extraClassnames,
    "is-organization-owner": isCurrentUserOrganizationOwner,
    "is-private-organization":
      organization.username === privateOrganizationUsername,
  });

  const {
    private: isPrivate,
    number_of_members: numberOfMembers,
  } = organization;

  const subValueText = isPrivate
    ? "Personal organization"
    : pluralize("Member", numberOfMembers, true);

  return (
    <a
      role="option"
      className={organizationItemClassnames}
      onClick={() => onOrgItemClick(organization.username)}
    >
      {isCurrentOrganization ? (
        <MightyIcon icon="company-lrg" />
      ) : (
        <ProfileAvatar
          initials={getOrganizationInitials(organization)}
          imageUrl={organization.logo_url}
        />
      )}

      <div className="OrganizationInfo">
        <Header as="h3" extraClassName="OrganizationInfo-name">
          {organization.name}
        </Header>
        <SubValue>{subValueText}</SubValue>
      </div>
    </a>
  );
}

OrganizationItem.propTypes = {
  extraClassnames: PropTypes.string,
  organization: organizationProptype,
  isCurrentUserOrganizationOwner: PropTypes.bool,
  isCurrentOrganization: PropTypes.bool,
  privateOrganizationUsername: PropTypes.string,
  onOrgItemClick: PropTypes.func,
};

export default OrganizationItem;
