import React from "react";
import NumberInput from "components/shared/form/NumberInput";
import classNames from "classnames";
import {
  perUnitNumberPrecision,
  defaultNumberPrecision,
} from "config/priceInput";

function PriceInput({ extraClassnames, precision, ...restProps }) {
  const groupClassnames = classNames({
    [extraClassnames]: !!extraClassnames,
    "FormElement--priceInput": true,
  });

  const placeHolderContent =
    precision === perUnitNumberPrecision ? "0.0000" : "0.00";

  const priceInputProps = {
    placeholder: placeHolderContent,
    extraClassnames: groupClassnames,
    numberSymbol: "$",
    decimalLimit: precision,
    allowDecimal: true,
    ...restProps,
  };

  return <NumberInput {...priceInputProps} />;
}

PriceInput.defaultProps = {
  precision: defaultNumberPrecision,
};

export default PriceInput;
