import React, { useCallback, useMemo } from "react";
import CompanyCoInvestmentsTab from "./CompanyCoInvestmentsTab";
import useOrganizations from "store/organizations/hooks";
import useSidebar from "store/sidebar/hooks";

import { useCompany } from "components_new/views/Company/hooks/queries/useCompany";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openModal } from "store/modalV2";
import { isEmpty, orderBy } from "lodash";

function CompanyCoInvestmentsTabWrapper() {
  const dispatch = useDispatch();
  const { id: companyId } = useParams();
  const { openSidebar } = useSidebar();
  const { data } = useCompany(companyId);
  const {
    currentOrganization: { username: currentOrganizationUsername },
  } = useOrganizations();

  const handleOpenAddCoInvestmentForm = useCallback(
    () =>
      openSidebar("coInvestmentForm", "Add Co-Investment", {
        companyId: data?.companyDetails?.id,
        prefilledInvestor: data?.companyDetails,
        disableInvestorSelector: true,
        resourceName: "Co-Investment",
      }),
    [data, openSidebar],
  );

  const handleOpenEditCoInvestmentForm = useCallback(
    (coInvestment) =>
      openSidebar("coInvestmentForm", "Edit Co-Investment", {
        companyId: data?.companyDetails?.id,
        disableInvestorSelector: true,
        resourceName: "Co-Investment",
        coInvestment,
      }),
    [data, openSidebar],
  );

  const handleOpenDeleteCoInvestment = useCallback(
    (coInvestment) =>
      dispatch(
        openModal("removeCoInvestment", {
          companyId: data?.companyDetails?.id,
          resourceName: "Co-Investment",
          coInvestment,
        }),
      ),
    [data, dispatch],
  );

  const investorCoInvestments = useMemo(() => {
    if (!isEmpty(data)) {
      return orderBy(data.investorCoInvestments, ["company_name"], ["asc"]);
    } else {
      return [];
    }
  }, [data]);

  return (
    <CompanyCoInvestmentsTab
      companyId={companyId}
      investorCoInvestments={investorCoInvestments}
      currentOrganizationUsername={currentOrganizationUsername}
      openAddCoInvestmentForm={handleOpenAddCoInvestmentForm}
      openEditCoInvestmentForm={handleOpenEditCoInvestmentForm}
      openDeleteCoInvestment={handleOpenDeleteCoInvestment}
    />
  );
}

export default CompanyCoInvestmentsTabWrapper;
