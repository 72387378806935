import React from "react";
import { Table as SemanticTable } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/table.css";
import "./Table.scss";
import classNames from "classnames";
import PropTypes from "prop-types";

function Table({
  celled,
  sortable,
  children,
  selectedColumn,
  renderHeaders,
  className,
  shadow,
  ...otherProps
}) {
  const TableClassnames = classNames("Table", {
    [className]: !!className,
    [`SelectedColumn-${selectedColumn}`]: !!selectedColumn,
    ["shadow"]: !!shadow,
  });

  return (
    <SemanticTable
      className={TableClassnames}
      celled={celled}
      sortable={sortable}
      {...otherProps}
    >
      {renderHeaders ? renderHeaders && children : children}
    </SemanticTable>
  );
}

Table.propTypes = {
  celled: PropTypes.bool,
  sortable: PropTypes.bool,
  children: PropTypes.node,
  selectedColumn: PropTypes.number,
  renderHeaders: PropTypes.func,
  className: PropTypes.string,
  shadow: PropTypes.bool,
};

Table.defaultProps = {
  shadow: true,
};

export default Table;
