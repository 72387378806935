import React from "react";
import Icon from "components_new/atoms/Icon";
import Text from "components_new/atoms/Text";
import "./index.scss";

function ContentHeader({ section, removeSection }) {
  return (
    <div className="SectionList-SnapshotSection-SectionHeader">
      <Text type="subtitle-1-bold" as="h2">
        {section.title}{" "}
        <Text type="subtitle-1-regular" color="brand-light" as="span">
          ({section?.data?.length})
        </Text>
      </Text>
      <Icon
        icon="ri-close-line"
        onClick={() => {
          removeSection(section?.title);
        }}
        size="large"
      />
    </div>
  );
}

export default ContentHeader;
