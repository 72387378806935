import pluralize from "pluralize";
import differenceInDate from "lib/differenceInDate";

export function calculateStagedAtText(stagedAt) {
  const timeSinceStaged = differenceInDate(stagedAt);

  const text =
    timeSinceStaged === 0
      ? "TODAY"
      : pluralize("day", differenceInDate(stagedAt), true);

  return text;
}
