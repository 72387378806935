import React, { Fragment } from "react";
import PropTypes from "prop-types";

function Shortcut({ label, children }) {
  return (
    <div className="Shortcuts-shortcut">
      <span className="Shortcuts-shortcut-label">{label}</span>
      <span className="Shortcuts-shortcut-keymap">{children}</span>
    </div>
  );
}

Shortcut.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

function Shortcuts() {
  return (
    <Fragment>
      <h1 className="Shortcuts-title">Keyboard Shortcuts</h1>
      <div className="Shortcuts-body">
        <div className="Shortcuts-column">
          <Shortcut label="Search">
            <span className="Shortcuts-shortcut-keymap-details">s</span>
          </Shortcut>
          <Shortcut label="New Investment">
            <span className="Shortcuts-shortcut-keymap-details">i</span>
          </Shortcut>
          <Shortcut label="New Deal">
            <span className="Shortcuts-shortcut-keymap-details">d</span>
          </Shortcut>
          <Shortcut label="New Fund">
            <span className="Shortcuts-shortcut-keymap-details">Shift</span>+
            <span className="Shortcuts-shortcut-keymap-details">P</span>
          </Shortcut>
        </div>
        <div className="Shortcuts-column">
          <Shortcut label="Upload File">
            <span className="Shortcuts-shortcut-keymap-details">f</span>
          </Shortcut>
          <Shortcut label="Add Note">
            <span className="Shortcuts-shortcut-keymap-details">p</span>
          </Shortcut>
          <Shortcut label="Update PPS">
            <span className="Shortcuts-shortcut-keymap-details">Shift</span>+
            <span className="Shortcuts-shortcut-keymap-details">S</span>
          </Shortcut>
          <Shortcut label="Log Capital Call">
            <span className="Shortcuts-shortcut-keymap-details">c</span>
          </Shortcut>
        </div>
        <div className="Shortcuts-column">
          <Shortcut label="Org Home">
            <span className="Shortcuts-shortcut-keymap-details">h</span>
          </Shortcut>
          <Shortcut label="Expand Rows">
            <span className="Shortcuts-shortcut-keymap-details">Shift</span>+
            <span className="Shortcuts-shortcut-keymap-details">Down</span>
          </Shortcut>
          <Shortcut label="Collapse Rows">
            <span className="Shortcuts-shortcut-keymap-details">Shift</span>+
            <span className="Shortcuts-shortcut-keymap-details">Up</span>
          </Shortcut>
          <Shortcut label="Access Shortcuts">
            <span className="Shortcuts-shortcut-keymap-details">Shift</span>+
            <span className="Shortcuts-shortcut-keymap-details">/</span>
          </Shortcut>
        </div>
      </div>
    </Fragment>
  );
}

export default Shortcuts;
