import React, { useCallback } from "react";
import DealActionsDropdown from "./DealActionsDropdown";
import useOrganizations from "store/organizations/hooks";
import { useSetSidebar } from "components_new/elements/SidebarWrapper/Sidebar-context";

import { openModal } from "store/modalV2";
import { useDispatch } from "react-redux";
import { openInModal, ModalNames } from "store/modal";

export default function DealActionsDropdownWrapper({ deal }) {
  const dispatch = useDispatch();

  const {
    currentOrganizationUsername,
    isCurrentOrganizationPrivate,
  } = useOrganizations();
  const { showSidebar } = useSetSidebar();

  const handleOpenPassFromDealModal = (deal) => {
    dispatch(
      openModal("passFromDeal", {
        dealId: deal.id,
        pipelineId: deal.pipeline_id,
      }),
    );
  };

  const handleOpenCommitToDealModal = (deal) => {
    dispatch(
      openModal("commitToDeal", {
        dealId: deal.id,
        pipelineId: deal.pipeline_id,
      }),
    );
  };

  const handleOpenDeleteDealModal = (deal) => {
    dispatch(
      openModal("removeDeal", {
        dealId: deal.id,
        pipelineId: deal.pipeline_id,
      }),
    );
  };

  const handleReactivateDealModal = (deal) => {
    dispatch(
      openModal("reactivateDeal", {
        dealId: deal.id,
        pipelineId: deal.pipeline_id,
      }),
    );
  };

  const handleOpenNewDealForm = useCallback(
    (dealId) => showSidebar("dealForm", { dealId }),
    [showSidebar],
  );

  const handleChangeDealOwner = (deal) => {
    dispatch(openInModal(ModalNames.changeDealOwner, { deal }));
  };

  return (
    <DealActionsDropdown
      deal={deal}
      currentOrganizationUsername={currentOrganizationUsername}
      isCurrentOrganizationPrivate={isCurrentOrganizationPrivate}
      openDeleteDealModal={handleOpenDeleteDealModal}
      openChangeDealOwnerModal={handleChangeDealOwner}
      openCommitToDealModal={handleOpenCommitToDealModal}
      openPassFromDealModal={handleOpenPassFromDealModal}
      openReactivateDealModal={handleReactivateDealModal}
      openUpdateDealModal={handleOpenNewDealForm}
    />
  );
}
