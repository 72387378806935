import React from "react";
import { connect } from "react-redux";
import { removeDocument } from "store/documents/actions";
import { isSubmitting } from "store/domain/selectors";
import ConfirmationModal from "components/shared/ConfirmationModal";
import { ModalNames, closeModal } from "store/modal";

const createMessage = (title) => {
  return (
    <span>
      Are you sure you want to delete "<strong>{title}</strong>"?
    </span>
  );
};

function mapStateToProps(state) {
  const options = state.modal.options[ModalNames.removeDocument];
  const doc = options && options.document;
  const documentId = options && options.document.id;
  const documentTitle = options && options.document.file_name;

  return {
    confirmButtonLabel: "Delete",
    doc,
    message: createMessage(documentTitle),
    submitting: isSubmitting(state, `removeDocument-${documentId}`),
    title: "Delete Confirmation",
  };
}

function mergeProps(stateProps, dispatchProps) {
  const { doc } = stateProps;
  const { dispatch } = dispatchProps;

  const newProps = {
    action: () =>
      dispatch(removeDocument(doc, doc.investment_id)).then(() => {
        dispatch(closeModal());
      }),
  };

  return {
    ...stateProps,
    ...newProps,
  };
}

export default connect(mapStateToProps, null, mergeProps)(ConfirmationModal);
