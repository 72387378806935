import canInvestmentDoAction from "config/canInvestmentDoAction";

function createEditInvestmentActions(options) {
  const {
    investment,
    openGlobalCapitalCallSidebarForm,
    openLogAcquisitionSidebarForm,
    openLogSaleSidebarForm,
    openLogStockSplitSidebarForm,
    openLogWriteOffSidebarForm,
    openChangePortfolioOfInvestmentModal,
    openDeleteInvestmentModal,
    openValuationSidebarForm,
    openLogConversionSidebarForm,
    openLogDistributionSidebarForm,
    openEditInvestmentSidebarForm,
    source,
    company,
    /**
     * remove me after deprecation of modal forms
     */
    initialComponent,
  } = options;

  const adjustValuationAction = {
    text: "Update Valuation",
    isDisabled: investment.previous_holding,
    onClickAction: () => openValuationSidebarForm({ investment, company }),
  };
  const logDistributionAction = {
    text: "Log Distribution",
    isDisabled: investment.previous_holding,
    onClickAction: () => openLogDistributionSidebarForm(investment),
  };
  const logInvestmentSaleAction = canInvestmentDoAction(investment, "logSale")
    ? {
        text: "Log Sale",
        isDisabled: investment.previous_holding,
        onClickAction: () => openLogSaleSidebarForm(investment),
      }
    : null;
  const logAcquisitionAction = ["equity", "debt"].includes(investment.type)
    ? {
        text: "Log Acquisition",
        isDisabled: investment.previous_holding,
        onClickAction: () => openLogAcquisitionSidebarForm(investment),
      }
    : null;
  const logStockSplitActionAction = canInvestmentDoAction(
    investment,
    "logStockSplit",
  )
    ? {
        text: "Log Stock Split",
        isDisabled: investment.previous_holding,
        onClickAction: () => openLogStockSplitSidebarForm(investment),
      }
    : null;
  const logWriteOffAction = {
    text: "Log Write-Off",
    isDisabled: investment.previous_holding,
    onClickAction: () => openLogWriteOffSidebarForm(investment),
  };
  const editInvestmentAction = {
    text: "Edit Investment",
    isDisabled: !!initialComponent,
    onClickAction: () => openEditInvestmentSidebarForm(investment),
  };
  const changePortfolioAction = {
    text: "Change Portfolio",
    isDisabled: investment.previous_holding || !!initialComponent,
    onClickAction: () =>
      openChangePortfolioOfInvestmentModal({ investmentId: investment.id }),
  };
  const loanConversionAction = canInvestmentDoAction(
    investment,
    "logConversion",
  )
    ? {
        text: "Convert Loan",
        isDisabled: investment.previous_holding,
        onClickAction: () => openLogConversionSidebarForm(investment),
      }
    : null;
  const capitalCallAction = canInvestmentDoAction(investment, "logCapitalCalls")
    ? {
        text: "Add Capital Call",
        isDisabled: investment.previous_holding,
        onClickAction: () => openGlobalCapitalCallSidebarForm(investment.id),
      }
    : null;
  const deleteInvestmentAction = {
    text: "Delete Investment",
    isDisabled:
      !investment.can_be_deleted ||
      investment.previous_holding ||
      !!investment.deal ||
      !!initialComponent,
    onClickAction: () =>
      openDeleteInvestmentModal({
        investment,
        source,
      }),
  };
  const separator = {
    type: "separator",
  };

  const items = [
    loanConversionAction,
    capitalCallAction,
    adjustValuationAction,
    logDistributionAction,
    logInvestmentSaleAction,
    logStockSplitActionAction,
    logAcquisitionAction,
    logWriteOffAction,
    separator,
    editInvestmentAction,
    changePortfolioAction,
    deleteInvestmentAction,
    separator,
  ];

  return items.filter((action) => action);
}

export default createEditInvestmentActions;
