import { connect } from "react-redux";
import React from "react";
import { getCollection } from "store/domain/selectors";
import { createSelector } from "reselect";
import View from "components/shared/View";
import { fetchUserContacts } from "store/userContacts/actions";
import LogSaleForm from "./LogSaleForm";

function renderLogSaleForm(props) {
  return (
    <View
      mountAction={() => props.fetchUserContacts()}
      component={LogSaleForm}
      {...props}
    />
  );
}

const getUserContactsAsOptions = createSelector(
  (userContacts) => userContacts,
  (userContacts) =>
    userContacts.map(({ id, name }) => ({ value: id, label: name })),
);

function mapStateToProps(
  state,
  { historyAction, companies, actionType, updateForm, errors, formData },
) {
  return {
    historyAction,
    companies,
    actionType,
    updateForm,
    userContacts: getUserContactsAsOptions(
      getCollection(state, "userContacts"),
    ),
    errors,
    formData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUserContacts: () => dispatch(fetchUserContacts()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(renderLogSaleForm);
