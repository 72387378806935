import { useMutation, useQueryClient } from "react-query";
import Api from "lib/Api";

export function useUpdateInvestmentPost() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ resource, postId, postContent }) => {
      return Api.patch(
        `/investments/${resource.investment_id}/posts/${postId}`,
        {
          post: postContent,
        },
      );
    },
    {
      onSuccess: ({ data }) => {
        queryClient.invalidateQueries([
          "InvestmentPosts",
          data.resource.investment_id,
        ]);
      },
      onError: (error) => {
        throw error;
      },
    },
  );
}
