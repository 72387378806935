import React, { useState } from "react";
import Tags from "components/shared/Tags";
import Input from "components_new/atoms/Input";
import useForm from "lib/hooks/useForm";
import Form from "components_new/forms/Form";
import RequiredNote from "components_new/SidebarComponents/shared/RequiredNote";
import DropdownForm from "components_new/atoms/DropdownForm";
import Header from "components_new/atoms/Header";
import { tierDropdown } from "config/tierConfig";
import { isEmpty } from "lodash";
import Industry from "components/shared/Industry";
import ContactRoles from "components_new/SidebarComponents/forms/groups/ContactRoles";
import Button from "components_new/atoms/Button";
import FormGroup from "components_new/elements/FormGroup";
import ContactEmails from "components_new/SidebarComponents/forms/groups/ContactDetails/ContactEmails";
import "./ReferrerForm.scss";

function ReferrerForm({
  referrer,
  onChange,
  closeSidebar,
  validateContact,
  toogleSize,
  filterTags,
  tagSuggestions,
  prepopulatedContactTags,
  prepopulatedSectors,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const INITIAL_VALUES = {
    formData: {
      first_name: referrer.first_name || "",
      last_name: referrer.last_name || "",
      emails: [],
      tags: [],
      tier: null,
      roles_attributes: [],
    },
  };

  const onSubmit = (clientData) => {
    setIsLoading(true);
    validateContact(clientData)
      .then(() => {
        const [primaryRole] = clientData.roles_attributes;
        const formData = {
          ...clientData,
          company_id: !isEmpty(primaryRole) && primaryRole.company_id,
        };

        onChange(formData);
        closeSidebar();
      })
      .catch((error) => {
        handleErrors(error);
        handleScrollView(error);
      })
      .finally(() => setIsLoading(false));
  };

  const onCancel = () => {
    onChange({});
    closeSidebar();
  };

  const {
    formData,
    handleChange,
    handleSubmit,
    handleErrors,
    handleScrollView,
    errors,
  } = useForm(INITIAL_VALUES, onSubmit);

  const {
    first_name,
    last_name,
    emails,
    tags,
    location,
    tier,
    industries,
  } = formData;

  const isSubmitButtonDisabled = isEmpty(first_name) && isEmpty(last_name);

  return (
    <Form extraClassName="ReferrerForm" onSubmit={handleSubmit}>
      <Form.Header onClose={closeSidebar} onToogle={toogleSize}>
        <Header as="h3">Add a new Referrer</Header>
      </Form.Header>
      <Form.Content>
        <FormGroup
          title="Add New Referrer"
          subtitle="Information about the new referrer."
          isExpanded={true}
        >
          <Input
            name="first_name"
            label="First name"
            placeholder="e.g. John"
            value={first_name}
            isRequired
            onChange={(e, { value }) => {
              handleChange("first_name", value);
            }}
            error={!!errors.for("first_name")}
            errorMessage={errors.for("first_name")}
            size="tiny"
          />
          <Input
            name="last_name"
            label="Last name"
            placeholder="e.g. Harrington"
            isRequired
            value={last_name}
            onChange={(e, { value }) => {
              handleChange("last_name", value);
            }}
            error={!!errors.for("last_name")}
            errorMessage={errors.for("last_name")}
            size="tiny"
          />
          <ContactEmails
            value={emails}
            onChange={(value) => handleChange("emails", value)}
            errors={errors}
          />
          <Input
            label="Location"
            value={location}
            onChange={(e, { value }) => {
              handleChange("location", value);
            }}
            size="tiny"
          />
          <Industry
            label="Industries"
            createTagLabel="Create new industry"
            placeholder="Search industries"
            name="industries"
            taggedList={industries || []}
            onChange={(value) => handleChange("industries", value)}
            size="tiny"
            extraClassnames="IndustryTags"
            prepopulatedItems={prepopulatedSectors}
          />
          <Tags
            tagType="contact"
            label="Tags"
            name="tags"
            taggedList={tags}
            onChange={(value) => handleChange("tags", value)}
            onFilter={filterTags}
            suggestedList={tagSuggestions}
            error={!!errors.for("tags")}
            errorMessage={errors.for("tags")}
            size="tiny"
            prepopulatedItems={prepopulatedContactTags}
          />
          <DropdownForm
            label="Tier"
            placeholder="Select a tier"
            items={tierDropdown}
            onChange={(e, { value }) => handleChange("tier", value)}
            value={tier}
            size="tiny"
          />
        </FormGroup>
        <ContactRoles
          onChange={handleChange}
          errors={errors}
          disabledCompanyCreation
        />
        <RequiredNote />
      </Form.Content>
      <Form.Footer>
        <Button
          primary
          loading={isLoading}
          onClick={handleSubmit}
          size="small"
          disabled={isSubmitButtonDisabled}
        >
          Add Referrer
        </Button>
        <Button
          onClick={onCancel}
          disabled={isLoading}
          secondary
          type="button"
          size="small"
        >
          Cancel
        </Button>
      </Form.Footer>
    </Form>
  );
}

export default ReferrerForm;
