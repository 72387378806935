import React from "react";
import moment from "moment";
import formatDate from "lib/formatDate";
import {
  placeholderDateFormatLowerCase,
  valueDateFormat,
} from "config/dateInput";
import { defaultNumberPrecision } from "config/priceInput";
import DatePicker from "components_new/atoms/DatePicker";
import PriceInput from "components/shared/form/PriceInput";
import { formatPriceInputPreview } from "lib/abbreviatedFormat";
import Value from "components_new/atoms/Value";
import Tooltip from "components_new/atoms/Tooltip/Tooltip";
import Icon from "components_new/atoms/Icon";

function getInitialStateData(collection) {
  return {
    date: moment().format(valueDateFormat),
    description: `Capital Call ${collection.length + 1}`,
    documents_data: [],
    amount: "",
    id: 0,
  };
}

function getEmptyCapitalCallRow() {
  return {
    date: null,
    description: "- - -",
    documents_data: [],
    amount: "",
    id: 0,
    investment: {
      previous_holding: false,
    },
  };
}

function canLockItem(item) {
  return item.from_previous_holding;
}

const calculateRowNumber = (totalRows, index) => totalRows - index + 1;

function createCapitalCallColumns(investment, rowCount) {
  const columns = [
    {
      text: "#",
      renderValue: ({ rowIndex }) =>
        rowIndex ? calculateRowNumber(rowCount, rowIndex) : "-",
      renderForm: (_, _update, rowIndex) => (
        <span className="u-labelValue">
          {calculateRowNumber(rowCount, rowIndex)}
        </span>
      ),
    },
    {
      text: "Due Date",
      classNames: "CapitalCallRow-date",
      alignment: "u-leftAlignedText",
      renderValue: (item) => (
        <span>
          {item.date ? moment(item.date).format("ll") : "- - -"}{" "}
          {item.documents_data.length > 0 && (
            <Tooltip
              position="top center"
              trigger={<Icon icon="ri-attachment-2" />}
              content="Capital call notice attached"
            />
          )}
        </span>
      ),
      renderForm: (item, updateForm, index, errors) => (
        <DatePicker
          name="date"
          placeholder={placeholderDateFormatLowerCase}
          value={formatDate(item.date)}
          onChange={(value) => {
            updateForm("date", value, index, item.id);
          }}
          errorMessage={errors.for("date")}
          size="small"
          oldVersion
        />
      ),
    },
    {
      text: "Amount",
      classNames: "CapitalCallRow-amount",
      alignment: "u-rightAlignedText",
      renderValue: (item) => (
        <span className="u-smallBoldDark">
          {item.amount ? formatPriceInputPreview(item.amount) : "$ - -"}
        </span>
      ),
      renderForm: (item, updateForm, index, errors) => (
        <PriceInput
          onChange={(value) => {
            updateForm("amount", value, index, item.id);
          }}
          errorMessage={errors.for("amount")}
          precision={defaultNumberPrecision}
          value={item.amount}
          alignTo="right"
          size="small"
        />
      ),
    },
    {
      text: "% Commitment",
      classNames: "CapitalCallRow-percent-commitment",
      alignment: "u-rightAlignedText",
      renderValue: (item) => (
        <Value className="u-smallBoldDark" type="percentage">
          {(item.amount / investment.total_acquisition_amount) * 100}
        </Value>
      ),
      renderForm: (item) => (
        <Value className="u-labelValue" type="percentage">
          {(item.amount / investment.total_acquisition_amount) * 100}
        </Value>
      ),
    },
    {
      text: "Total Paid-In",
      classNames: "CapitalCallRow-totalPaidIn",
      alignment: "u-rightAlignedText",
      renderValue: (item) => (
        <span className="u-smallBoldDark">
          {item.totalPaid ? formatPriceInputPreview(item.totalPaid) : "$ - -"}
        </span>
      ),
      renderForm: (item) => (
        <span className="u-smallBoldDark u-labelValue">
          {item.totalPaid ? formatPriceInputPreview(item.totalPaid) : "$ - -"}
        </span>
      ),
    },
  ];

  return columns;
}

export {
  getInitialStateData,
  getEmptyCapitalCallRow,
  canLockItem,
  createCapitalCallColumns,
};
