import appReducer from "./appReducer";
import { LOGOUT } from "actions/synchronous/auth";

function rootReducer(state, action) {
  if (action.type === LOGOUT) {
    // Setting state to undefined force reducers to return its INITIAL_STATE.
    state = undefined;
  }
  return appReducer(state, action);
}

export default rootReducer;
