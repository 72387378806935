import { omit } from "lodash";

export default function consolidateInvestmentName(
  payload,
  edit = false,
  name = "name",
  customName = "custom_name",
) {
  let newPayload = { ...payload };

  if (payload[name] === "other") {
    if (!payload[customName]) {
      if (edit) {
        newPayload = omit(newPayload, [customName]);
        newPayload[name] = "";
      } else {
        newPayload = omit(newPayload, [name, customName]);
      }
    } else {
      newPayload[name] = payload[customName];
    }
  }

  return newPayload;
}
