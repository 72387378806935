import React from "react";
import { lowerFirst } from "lodash";
import { useNavigate } from "react-router-dom";
import DataCard from "components_new/elements/DataCard";
import TextLabel from "components_new/atoms/TextLabel";
import TextTitle from "components_new/atoms/TextTitle";
import GroupedColumnedValueList from "components_new/atoms/ColumnedValueList/GroupedColumnedValueList";
import InvestmentActionsDropdown from "../InvestmentActionsDropdown";

function InvestmentRow({ investment, companyAvatar, portfolio, link }) {
  const navigate = useNavigate();

  const currentInvestmentItemMapping = {
    debt: {
      columnedData: [
        [
          {
            label: "Conversion Cap",
            value: investment.conversion_cap,
            type: "currency",
          },
          {
            label: "Discount",
            value: investment.conversion_discount,
            type: "percentage",
          },
          { label: "Expires", value: investment.expiration_date, type: "date" },
        ],
        [
          {
            label: "Cost Basis",
            value: investment.cost_basis,
            type: "currency",
            precision: 2,
          },
          {
            label: "FMV",
            value: investment.fair_value,
            type: "currency",
            precision: 2,
          },
          {
            label: "Proceeds",
            value: investment.total_proceed_amount,
            type: "currency",
            precision: 2,
          },
          {
            label: "Gain/Loss",
            value: investment.total_return,
            type: "gainloss",
            precision: 2,
          },
        ],
      ],
    },
    equity: {
      columnedData: [
        [
          {
            label: "Shares",
            value: investment.quantity,
            type: "quantity",
          },
          {
            label: "%FD",
            value: investment.fully_diluted_shares_percentage,
            type: "percentage",
          },
          {
            label: "Per Share",
            value: investment.unit_cost_basis,
            type: "currency",
            precision: 2,
          },
        ],
        [
          {
            label: "Cost Basis",
            value: investment.cost_basis,
            type: "currency",
            precision: 2,
          },
          {
            label: "FMV",
            value: investment.fair_value,
            type: "currency",
            precision: 2,
          },
          {
            label: "Proceeds",
            value: investment.total_proceed_amount,
            type: "currency",
            precision: 2,
          },
          {
            label: "Gain/Loss",
            value: investment.total_return,
            type: "gainloss",
            precision: 2,
          },
        ],
      ],
    },
    warrant: {
      columnedData: [
        [
          { label: "Warrants", value: investment.quantity, type: "quantity" },
          {
            label: "Per Warrant",
            value: investment.unit_cost_basis,
            type: "currency",
            precision: 2,
          },
        ],
        [
          {
            label: "Cost Basis",
            value: investment.cost_basis,
            type: "currency",
            precision: 2,
          },
          {
            label: "FMV",
            value: investment.fair_value,
            type: "currency",
            precision: 2,
          },
          {
            label: "Proceeds",
            value: investment.total_proceed_amount,
            type: "currency",
            precision: 2,
          },
          {
            label: "Gain/Loss",
            value: investment.total_return,
            type: "gainloss",
            precision: 2,
          },
        ],
      ],
    },
    option: {
      columnedData: [
        [
          { label: "Options", value: investment.quantity, type: "quantity" },
          {
            label: "Per Option",
            value: investment.unit_cost_basis,
            type: "currency",
            precision: 2,
          },
        ],
        [
          {
            label: "Cost Basis",
            value: investment.cost_basis,
            type: "currency",
            precision: 2,
          },
          {
            label: "FMV",
            value: investment.fair_value,
            type: "currency",
            precision: 2,
          },
          {
            label: "Proceeds",
            value: investment.total_proceed_amount,
            type: "currency",
            precision: 2,
          },
          {
            label: "Gain/Loss",
            value: investment.total_return,
            type: "gainloss",
            precision: 2,
          },
        ],
      ],
    },
    fund: {
      columnedData: [
        [
          // TODO: Correct Value
          {
            label: "Committed",
            value: investment.amount,
            type: "currency",
            precision: 2,
          },
          {
            label: "% Called",
            value: investment.percent_called,
            type: "percentage",
          },
          {
            label: "Remaining",
            value: investment.remaining,
            type: "currency",
            precision: 2,
          },
        ],
        [
          {
            label: "Cost Basis",
            value: investment.cost_basis,
            type: "currency",
            precision: 2,
          },
          {
            label: "FMV",
            value: investment.fair_value,
            type: "currency",
            precision: 2,
          },
          {
            label: "Proceeds",
            value: investment.total_proceed_amount,
            type: "currency",
            precision: 2,
          },
          {
            label: "Gain/Loss",
            value: investment.total_return,
            type: "gainloss",
            precision: 2,
          },
        ],
      ],
    },
    llc: {
      columnedData: [
        [
          {
            label: investment.quantity ? "Units" : "% Owned",
            value: investment.quantity
              ? investment.quantity
              : investment.percentage_owned,
            type: investment.quantity ? "quantity" : "percentage",
          },
        ],
        [
          {
            label: "Cost Basis",
            value: investment.cost_basis,
            type: "currency",
            precision: 2,
          },
          {
            label: "FMV",
            value: investment.fair_value,
            type: "currency",
            precision: 2,
          },
          {
            label: "Proceeds",
            value: investment.total_proceed_amount,
            type: "currency",
            precision: 2,
          },
          {
            label: "Gain/Loss",
            value: investment.total_return,
            type: "gainloss",
            precision: 2,
          },
        ],
      ],
    },
  };

  const exitedInvestmentMapping = {
    columnedData: [
      [
        { label: "Cash Proceeds", value: investment.payout },
        { label: "Adjustments", value: investment.adjustments },
        { label: "Realized", value: investment.realized_gain },
      ],
    ],
  };

  const investmentColumnedData = investment.previous_holding
    ? exitedInvestmentMapping.columnedData
    : currentInvestmentItemMapping[investment.type].columnedData;

  return (
    <DataCard
      onClick={() => navigate(link)}
      actionMenuComponent={
        <InvestmentActionsDropdown
          investment={investment}
          company={companyAvatar}
        />
      }
    >
      <DataCard.Avatar
        imageUrl={companyAvatar ? companyAvatar.logo_url : null}
        icon="investment-med"
      />
      <DataCard.Header>
        <TextLabel data-testid="investmentType">{investment.type}</TextLabel>
        <TextTitle>{`${investment.name} (${companyAvatar.name})`}</TextTitle>
        {`${investment.type_label} ${lowerFirst(investment.origin_note)}`}{" "}
        &nbsp;·&nbsp; {portfolio.name}
      </DataCard.Header>
      <DataCard.Content>
        <GroupedColumnedValueList data={investmentColumnedData} />
      </DataCard.Content>
    </DataCard>
  );
}

export default InvestmentRow;
