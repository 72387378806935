import React from "react";
import Select from "components/shared/form/Select";

function Combobox({ ...props }) {
  return (
    <Select {...props} autocomplete openWithKeyDown supportsNoResultsMessage />
  );
}

export default Combobox;
