import React, { useState, useEffect, useRef } from "react";
import Segment from "components_new/atoms/Segment";
import Checkbox from "components_new/atoms/Checkbox";
import Button from "components_new/atoms/Button";
import Icon from "components_new/atoms/Icon";
import Dropdown, { DropdownMenu } from "components_new/atoms/Dropdown";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import { Grid } from "@ableco/semantic-ui-react";
import { capitalize, isEqual } from "lodash";
import "./TopBar.scss";

function FileFilters({
  fileTypes,
  filtersApplied,
  onApplyFilters,
  expandAllRowDetails,
  collapseAllRowDetails,
}) {
  const [checkedItems, setCheckedItems] = useState([...filtersApplied]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownMenu = useRef(null);

  useEffect(() => {
    setCheckedItems([...filtersApplied]);
  }, [filtersApplied]);

  const handleOnFilter = (filter) => {
    const newFilters = checkedItems.includes(filter)
      ? checkedItems.filter((item) => item !== filter)
      : [...checkedItems, filter];

    setCheckedItems(newFilters);
  };

  const handleClearAll = () => setCheckedItems([]);

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleApply = (items) => {
    onApplyFilters(items);
    expandAllRowDetails();
    setIsOpen(false);
  };

  const handleClearFilters = (event) => {
    onApplyFilters([]);
    collapseAllRowDetails();
    event.stopPropagation();
  };

  const handleBlur = (event) => {
    if (!dropdownMenu.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const shouldDisableApplyButton = isEqual(checkedItems, filtersApplied);

  const buttonFilterName = pluralize(
    "Filter",
    filtersApplied.length,
    filtersApplied.length > 0,
  );

  return (
    <Dropdown
      trigger={
        <Button secondary size="small">
          <Icon icon="ri-filter-3-fill" />
          <span>{buttonFilterName}</span>

          {filtersApplied.length > 0 && (
            <Icon
              icon="ri-close-circle-fill"
              size="mini"
              onClick={handleClearFilters}
            />
          )}
        </Button>
      }
      icon={null}
      open={isOpen}
      onBlur={handleBlur}
      onClick={() => {
        setIsOpen(!isOpen);
      }}
    >
      <DropdownMenu className="Menu-Container">
        <div ref={dropdownMenu} onClick={(event) => event.stopPropagation()}>
          <Segment attached="top" className="TableFilter-Containter">
            <Grid>
              <Grid.Column width={6} className="InvestmentType-Selector">
                <p className="title u-captionBlack">Categories</p>
                {fileTypes.sort().map((type, index) => (
                  <Grid.Row key={index} className="InvestmentType-Item">
                    <Checkbox
                      checked={checkedItems.includes(type)}
                      value={type}
                      onChange={(_, { value }) => handleOnFilter(value)}
                      label={capitalize(type)}
                    />
                  </Grid.Row>
                ))}
              </Grid.Column>
            </Grid>
          </Segment>
          <Segment attached="bottom" className="TableFilter-footer">
            <Grid columns="equal" className="TableFilter-actions">
              <Grid.Column>
                <Grid.Row>
                  <div className="ClearButton" onClick={handleClearAll}>
                    Clear All
                  </div>
                </Grid.Row>
              </Grid.Column>
              <Grid.Column>
                <Grid.Row className="Apply-Cancel-Buttons">
                  <Button secondary size="small" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button
                    primary
                    size="small"
                    onClick={() => handleApply(checkedItems)}
                    disabled={shouldDisableApplyButton}
                  >
                    Apply
                  </Button>
                </Grid.Row>
              </Grid.Column>
            </Grid>
          </Segment>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
}

FileFilters.propTypes = {
  fileTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  filtersApplied: PropTypes.arrayOf(PropTypes.string).isRequired,
  onApplyFilters: PropTypes.func.isRequired,
  expandAllRowDetails: PropTypes.func.isRequired,
  collapseAllRowDetails: PropTypes.func.isRequired,
};

export default FileFilters;
