import React, { Fragment } from "react";
import PropTypes from "prop-types";

import DatePicker from "components_new/atoms/DatePicker";
import CurrencyInput from "components_new/atoms/CurrencyInput";
import Text from "components_new/atoms/Text";
import Button from "components_new/atoms/Button";
import useForm from "lib/hooks/useForm";
import formatDate from "lib/formatDate";
import Form from "components_new/atoms/Form";

import { valueDateFormat } from "config/dateInput";
import {
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "components_new/elements/Modal";
import "./CommitToDealModal.scss";

function makeSubtitle(roundName, companyName) {
  return roundName ? `${roundName} in ${companyName}` : `${companyName}`;
}

function CommitToDeal({
  deal,
  company,
  isEditing,
  commitToDeal,
  editCommitment,
  isSubmitting,
  currentOrganizationUsername,
  closeModal,
}) {
  const initialValues = {
    formData: {
      decision_date: isEditing ? `${deal.decision_date}` : "",
      commitment_amount: isEditing ? `${deal.commitment_amount}` : "",
    },
  };
  const submit = (clientData) => {
    if (isEditing) {
      editCommitment(clientData).catch((error) => handleErrors(error));
    } else {
      commitToDeal(clientData, currentOrganizationUsername).catch((error) =>
        handleErrors(error),
      );
    }
  };
  const {
    formData,
    handleChange,
    handleSubmit,
    handleErrors,
    errors,
  } = useForm(initialValues, submit);

  const isAvailableToSubmit =
    formData.decision_date === "" || formData.commitment_amount === "";

  return (
    <Fragment>
      <ModalHeader
        content={isEditing ? "Edit Deal" : "Commit to Deal"}
        subheader={makeSubtitle(deal.round_name, company.name)}
      />

      <ModalContent extraClassnames="CommitToDealModal">
        <Form>
          <div className="Grid">
            <DatePicker
              name="decision_date"
              extraClassnames="FormElement FormElement--Date grid-item grid-50"
              label="Date of commitment"
              placeholder="Date received"
              required
              value={formatDate(formData.decision_date)}
              errorMessage={errors.for("decision_date")}
              onChange={(value) =>
                handleChange(
                  "decision_date",
                  formatDate(value, valueDateFormat),
                )
              }
              prefillToday={true}
              oldVersion
            />
            <CurrencyInput
              decimalLimit={2}
              iconPosition="left"
              name="commitment_amount"
              label="Allocation*"
              placeholder="0.00"
              extraClassnames="grid-item grid-50"
              onChange={(value) => handleChange("commitment_amount", value)}
              errorMessage={errors.for("commitment_amount")}
              value={formData.commitment_amount}
            />
          </div>
          <Text as="p" type="label-regular">
            * Required
          </Text>
        </Form>
      </ModalContent>

      <ModalFooter extraClassnames="AddValuationModal" withBorder={true}>
        <Button
          primary
          type="submit"
          onClick={handleSubmit}
          disabled={isAvailableToSubmit}
          loading={isSubmitting}
        >
          {isEditing ? "Save Changes" : "Commit to Deal"}
        </Button>
        <Button secondary onClick={() => closeModal()} disabled={isSubmitting}>
          Cancel
        </Button>
      </ModalFooter>
    </Fragment>
  );
}

CommitToDeal.propTypes = {
  deal: PropTypes.shape({
    id: PropTypes.string,
    round_name: PropTypes.string,
    decision_date: PropTypes.string,
    commitment_amount: PropTypes.string,
  }),
  company: PropTypes.shape({ name: PropTypes.string }),
  isEditing: PropTypes.bool,
  commitToDeal: PropTypes.func,
  editCommitment: PropTypes.func,
  isSubmitting: PropTypes.bool,
  currentOrganizationUsername: PropTypes.string,
  closeModal: PropTypes.func,
};

export default CommitToDeal;
