import PropTypes from "prop-types";
import React from "react";
import Button from "components/shared/Button";

function WarningButton(props) {
  const { children, ...restProps } = props;

  return (
    <Button {...restProps} behavior="warning">
      {children}
    </Button>
  );
}

WarningButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WarningButton;
