import React, { Fragment } from "react";
import PropTypes from "prop-types";
import EmptyPage from "components_new/elements/EmptyPage";
import Button from "components_new/atoms/Button";
import MightyIcon from "components_new/atoms/MightyIcon";
import Icon from "components_new/atoms/Icon";
import "./EmptyDeals.scss";

function EmptyDeals({ dealType, openDealForm }) {
  return (
    <EmptyPage
      renderIcon={() => <MightyIcon icon="deal-xlrg" />}
      headline={`You don't have any ${dealType} deals.`}
      clickToAction={() => {
        return (
          <Fragment>
            <Button primary onClick={() => openDealForm()}>
              <Icon icon="ri-add-line" />
              <span className="Button-text">New Deal</span>
            </Button>
            <small className="smallText"> Or, update an existing deal.</small>
          </Fragment>
        );
      }}
    />
  );
}

EmptyDeals.propTypes = {
  dealType: PropTypes.oneOf(["active", "committed", "passed"]).isRequired,
  openDealForm: PropTypes.func.isRequired,
};

EmptyDeals.defaultProps = {
  dealType: "active",
  openDealForm: () => {},
};

export default EmptyDeals;
