import React, { Component } from "react";
import PropTypes from "prop-types";

import InvitationAuth from "components/auth/invitationToOrganization/existingUser/InvitationAuth";
import ForgotPassword from "components/auth/invitationToOrganization/existingUser/ForgotPassword";

class InvitationOrganizationExistingUser extends Component {
  state = { isForgetView: false };

  toggleForgotView = (status) => {
    this.setState({ isForgotView: status });
  };

  render() {
    const {
      email,
      organizationName,
      organizationMembershipId,
      organizationUserName,
      forgotPassword,
      isRevokedInvite,
      comeFromNewUser,
    } = this.props;
    const { isForgotView } = this.state;
    return (
      <React.Fragment>
        {isForgotView ? (
          <ForgotPassword
            goToAuthenticationView={() => {
              this.toggleForgotView(false);
            }}
            forgotPassword={forgotPassword}
          />
        ) : (
          <InvitationAuth
            email={email}
            organizationName={organizationName}
            organizationMembershipId={organizationMembershipId}
            organizationUserName={organizationUserName}
            isRevokedInvite={isRevokedInvite}
            comeFromNewUser={comeFromNewUser}
            goToForgotview={() => {
              this.toggleForgotView(true);
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

InvitationOrganizationExistingUser.propTypes = {
  isRevokedInvite: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  organizationName: PropTypes.string.isRequired,
  organizationUserName: PropTypes.string.isRequired,
  organizationMembershipId: PropTypes.number.isRequired,
  forgotPassword: PropTypes.func.isRequired,
  comeFromNewUser: PropTypes.bool,
};

InvitationOrganizationExistingUser.defaultProps = {
  comeFromNewUser: false,
};

export default InvitationOrganizationExistingUser;
