import React from "react";
import MultiSelect from "components_new/elements/MultiSelect";

function Investor({
  investorList,
  searchInvestor,
  onChange,
  label,
  placeholder,
}) {
  return (
    <MultiSelect
      name={label}
      label={label}
      placeholder={placeholder}
      scope="investor_companies"
      prefilledItems={investorList}
      onFilter={searchInvestor}
      showAddNew={false}
      resourceName="investors"
      onChange={(companies) => {
        const selectedCompaniesIds = companies.map((item) => item.value.id);
        onChange(selectedCompaniesIds);
      }}
      size="tiny"
    />
  );
}

export default Investor;
