import Api from "lib/Api";
import {
  collectionFetchSuccess,
  mutationStart,
  mutationSuccess,
  mutationFail,
  itemFetchStart,
  itemFetchSuccess,
} from "store/domain/domainActions";
import { receiveCollections, receiveCollection } from "store/entities";
import { receiveView, updateView } from "store/views";
import { receivePortfolioProfile } from "store/portfolioProfile/actions";
import { setResourceAvailability } from "store/resources/actions";
import { upsertResource, removeResource } from "store/resourcesV2";

export function fetchMinimalPortfoliosV2() {
  return (dispatch) => {
    return Api.get("/v2/portfolios").then(
      ({
        data: {
          entities,
          views: { org_portfolios: view },
        },
      }) => {
        dispatch(receiveView("org_portfolios", view));
        dispatch(receiveCollections(entities));
      },
    );
  };
}

export function createPortfolio(formData) {
  return (dispatch) => {
    return Api.postMutation(
      "createPortfolio",
      "/portfolios",
      {
        portfolio: formData,
      },
      (portfolio) => ({
        upserts: [{ entity: portfolio, into: "portfolios" }],
      }),
    ).then((portfolio) => {
      dispatch(receiveCollection("minimalPortfolios", [portfolio]));
      dispatch(upsertResource("portfolios", portfolio));
      dispatch(updateView("org_portfolios", "ids", portfolio.id));
      return portfolio;
    });
  };
}

export function updatePortfolio(portfolioId, formData) {
  return (dispatch) => {
    return Api.patchMutation(
      "updatePortfolio",
      `/portfolios/${portfolioId}`,
      {
        portfolio: formData,
      },
      (portfolio) => ({
        upserts: [{ entity: portfolio, into: "portfolios" }],
      }),
    ).then(() => {
      dispatch(fetchMinimalPortfoliosV2());
    });
  };
}

export function fetchPortfolio(id) {
  return (dispatch) => {
    dispatch(itemFetchStart("portfolios", id));
    return Api.get(`/portfolios/${id}`)
      .then(({ data: { portfolio, portfolio_view: portfolioView } }) => {
        dispatch(receivePortfolioProfile(portfolioView));
        dispatch(itemFetchSuccess("portfolios", id, portfolio));
        return portfolio;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          dispatch(setResourceAvailability(false));
        }
        return Promise.reject();
      });
  };
}

export function fetchPortfolioV2(id) {
  return (dispatch) => {
    dispatch(itemFetchStart("portfolios", id));
    return Api.get(`/v2/portfolios/${id}`)
      .then(
        ({
          data: {
            portfolio,
            entities,
            views: { portfolio: view },
          },
        }) => {
          dispatch(receivePortfolioProfile(view));
          dispatch(upsertResource("portfolios", portfolio));

          dispatch(receiveView("portfolios", view));
          dispatch(receiveCollections(entities));

          dispatch(itemFetchSuccess("portfolios", id, portfolio));
          return portfolio;
        },
      )
      .catch((error) => {
        if (error.response.status === 404) {
          dispatch(setResourceAvailability(false));
        }
        return Promise.reject();
      });
  };
}

export function movePortfolio({ portfolioId, newOrganizationUsername }) {
  return (dispatch) => {
    dispatch(mutationStart("movePortfolio"));
    return Api.patch(`/portfolios/${portfolioId}/move`, {
      new_organization_username: newOrganizationUsername,
    })
      .then(({ data }) => {
        dispatch(removeResource("portfolios", portfolioId));
        dispatch(collectionFetchSuccess("portfolios", data));
        dispatch(mutationSuccess("movePortfolio"));
      })
      .catch((error) => {
        dispatch(mutationFail("movePortfolio"));
        throw error;
      });
  };
}

export function removePortfolio(portfolioId) {
  return (dispatch) => {
    return Api.delete(`/portfolios/${portfolioId}`).then(() => {
      dispatch(removeResource("portfolios", portfolioId));
    });
  };
}
