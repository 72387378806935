import React, { Fragment, useState } from "react";

import { closeModal } from "store/modalV2/actions";
import { useDispatch } from "react-redux";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";

import getMessage from "components_new/lib/Messages";
import Loader from "components/shared/Loader";
import ConfirmationModal from "components/shared/ConfirmationModal";

import { useDeal } from "components_new/views/Deals/hooks/queries/useDeal";
import { useReactivateDeal } from "components_new/views/Deals/hooks/mutations/useReactivateDeal";

const createMessage = (companyName, finalStageName, status) => {
  return (
    <Fragment>
      Undoing this {actionName(status)?.toLowerCase()} for {companyName} will
      move the deal back into the <strong> {finalStageName} </strong> stage.
    </Fragment>
  );
};

const createSubtitle = (roundName, companyName) => {
  return roundName ? `${roundName} in ${companyName}` : `${companyName}`;
};

const actionName = (dealStatus) => {
  if (dealStatus === "committed") {
    return "Commitment";
  } else if (dealStatus === "passed") {
    return "Pass";
  }
};

const getMessageKey = (dealStatus) => {
  return dealStatus === "committed" ? "undoCommitment" : "undoPass";
};

function ReactivateDealWrapper({ pipelineId, dealId, callback }) {
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const { addToast } = useSetToast();

  const { data, isLoading } = useDeal(pipelineId, dealId);
  const { mutateAsync: reactivateDeal } = useReactivateDeal();

  const finalStageName = data?.stages[data?.stages.length - 1].name;

  const handleReactivateDeal = async () => {
    setSubmitting(true);
    await reactivateDeal({ pipelineId, dealId });

    const messageKey = getMessageKey(data?.deal.status);

    dispatch(closeModal());
    addToast(getMessage(messageKey));

    callback?.();
    setSubmitting(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ConfirmationModal
      title={`Undo ${actionName(data?.deal.status)}`}
      subTitle={createSubtitle(data?.deal.round_name, data?.company.name)}
      message={createMessage(
        data?.company.name,
        finalStageName,
        data?.deal.status,
      )}
      confirmButtonLabel={`Undo ${actionName(data?.deal.status)}`}
      cancelButtonLabel="Cancel"
      primaryButtonClassnames="Button--warning"
      submitting={submitting}
      closeModal={() => dispatch(closeModal())}
      action={handleReactivateDeal}
    />
  );
}

export default ReactivateDealWrapper;
