import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { isEmpty, noop } from "lodash";
import MaskedInput from "react-text-mask";
import { PhoneNumberUtil } from "google-libphonenumber";
import FormElement from "components/shared/form/FormElement";

const COUNTRY_CODE = "US";
const ERROR_MESSAGE = "This is not a valid phone number";
const phoneUtil = PhoneNumberUtil.getInstance();
const phoneNumberMask = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

class PhoneNumberInput extends Component {
  constructor(props) {
    super(props);
    const { value } = props;
    this.state = {
      value,
      error: null,
      hasFocus: false,
    };
  }

  receiveInput = (value) => {
    this.setState(() => {
      return {
        value,
      };
    });
  };

  isValidPhoneNumber(value) {
    const number = phoneUtil.parse(value, COUNTRY_CODE);
    return phoneUtil.isValidNumber(number);
  }

  validatePhoneNumber = (value) => {
    if (value.length === 10) {
      const error = this.isValidPhoneNumber(value) ? null : ERROR_MESSAGE;
      this.setState({
        error,
      });
    }
  };

  onChange = (event) => {
    const { onChange } = this.props;
    const input = event.target.value.match(/\d+/g);
    const value = isEmpty(input) ? "" : input.join("");
    const isValid =
      value.length === 10 ? this.isValidPhoneNumber(value) : false;
    const name = event.target.name;

    this.validatePhoneNumber(value);
    this.receiveInput(value);

    if (this.state.error) {
      this.setState({
        error: null,
      });
    }

    if (onChange) {
      onChange(value, name, isValid);
    }
  };

  onFocus = () => {
    this.setState({ hasFocus: true, error: null });
  };

  onBlur = () => {
    const { value } = this.state;
    this.setState({ hasFocus: false });
    if (value) {
      this.validatePhoneNumber(value);
      this.receiveInput(value);
    }
  };

  render() {
    const {
      label,
      name,
      size,
      placeholder,
      required,
      isOptional,
      disabled,
      autoFocus,
      errorMessage,
      readOnly,
      showLock,
      submitting,
      onChange,
    } = this.props;
    const { id, error, value, hasFocus } = this.state;
    const errors = errorMessage ? errorMessage : error;
    const showErrors = errors && !hasFocus;
    const groupClassnames = classNames(
      "FormElement FormElement--textInput FormElement--phoneNumberInput",
      {
        "has-errors": showErrors && !submitting,
        "has-focus": hasFocus,
        "has-disabled": disabled,
        "has-readonly": readOnly,
        [size]: !!size,
      },
    );
    return (
      <FormElement
        extraClassnames={groupClassnames}
        label={label}
        name={name}
        readOnly={readOnly}
        showLock={showLock}
        isOptional={isOptional}
        onChange={onChange}
        errorMessage={errors}
        errorDisplayValidation={(showErrors, errors) => {
          return showErrors == null ? !!errors : showErrors;
        }}
      >
        <div className="InputHolder">
          <MaskedInput
            id={id}
            mask={phoneNumberMask}
            autoComplete="off"
            className="InputText"
            disabled={disabled}
            label={label}
            name={name}
            onChange={(e) => this.onChange(e)}
            onBlur={() => this.onBlur()}
            onFocus={this.onFocus}
            placeholder={placeholder}
            aria-label={label || placeholder}
            required={required}
            autoFocus={autoFocus}
            readOnly={readOnly}
            value={value}
          />
        </div>
      </FormElement>
    );
  }
}

PhoneNumberInput.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.oneOf(["medium", "small"]),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  isOptional: PropTypes.bool,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  errorMessage: PropTypes.string,
  showErrors: PropTypes.bool,
  readOnly: PropTypes.bool,
  showLock: PropTypes.bool,
  submitting: PropTypes.bool,
  onChange: PropTypes.func,
};

PhoneNumberInput.defaultProps = {
  value: "",
  label: "Phone number",
  name: null,
  size: "medium",
  placeholder: "(123) 456-7890",
  required: false,
  isOptional: false,
  disabled: false,
  autoFocus: false,
  errorMessage: null,
  showErrors: false,
  readOnly: false,
  showLock: false,
  submitting: false,
  onChange: noop,
};

export default PhoneNumberInput;
