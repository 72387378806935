import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Segment from "components_new/atoms/Segment";
import ContactsTopBar from "./ContactsTopBar";
import ContactsTable from "./ContactsTable";
import { isEmpty } from "lodash";
import EmptyView from "./EmptyView";
import "./ContactsView.scss";

function ContactsView({
  organizationUsername,
  isLoading,
  updateFilters,
  contacts = [],
  showSidebar,
  isSearchActive,
  filters,
  meta = {},
}) {
  const isEmptyView = isEmpty(contacts) && !isLoading && !isSearchActive;

  if (isEmptyView) {
    return <EmptyView />;
  }

  return (
    <Fragment>
      <Segment shadow extraClassNames="ContactsView">
        <ContactsTopBar
          meta={meta}
          updateFilters={updateFilters}
          filters={filters}
          contactsCount={contacts.length}
          isLoading={isLoading}
        />
        <ContactsTable
          tableData={contacts}
          isLoading={isLoading}
          organizationUsername={organizationUsername}
          showSidebar={showSidebar}
        />
      </Segment>
    </Fragment>
  );
}

ContactsView.propTypes = {
  organizationUsername: PropTypes.string,
  isLoading: PropTypes.bool,
  updateFilters: PropTypes.func,
  contacts: PropTypes.arrayOf(PropTypes.shape({})),
  showSidebar: PropTypes.func,
  meta: PropTypes.shape({}),
  filters: PropTypes.shape({}),
  isSearchActive: PropTypes.bool,
};

export default ContactsView;
