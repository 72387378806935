import { useState, useEffect, useRef } from "react";

function useDebounce(value, delay, onChange = null) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const functionTimeoutHandler = useRef(null);

  useEffect(() => {
    if (value === debouncedValue) return;
    functionTimeoutHandler.current = setTimeout(() => {
      setDebouncedValue(value);
      if (onChange) {
        onChange(value);
      }
    }, delay);

    return () => {
      clearTimeout(functionTimeoutHandler.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, delay]);

  return { debouncedValue, setDebouncedValue };
}

export default useDebounce;
