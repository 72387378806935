import React from "react";
import classnames from "classnames";

export function DefinitionList({ children }) {
  return <ul className="DefinitionList">{children}</ul>;
}

export function DefinitionItem({ name, description, title, extraClassnames }) {
  const DefinitionItemClassnames = classnames("DefinitionList-item", {
    [extraClassnames]: !!extraClassnames,
  });

  return (
    <li
      className={DefinitionItemClassnames}
      title={title}
      data-testid="DefinitionItem"
    >
      <span className="DefinitionList-name">{name}</span>
      <span className="DefinitionList-description">{description}</span>
    </li>
  );
}
