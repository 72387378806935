import React, { Fragment } from "react";
import CollapsableSection from "components_new/elements/CollapsableSection";
import CompanyInfo from "components_new/elements/CompanyInfo";
import Table, {
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
} from "components_new/elements/Table";
import ListValue from "components/shared/ListValue";
import BasicLabel from "components_new/atoms/BasicLabel";
import formatDate from "lib/formatDate";
import EmptyCard from "components_new/elements/EmptyCard";
import "./ContactRoles.scss";

function showtitle(count) {
  return (
    <Fragment>
      Roles <small>({count})</small>
    </Fragment>
  );
}

function ContactRoles({ roles }) {
  const haveRoles = roles.length > 0;
  return (
    <CollapsableSection
      expanded={haveRoles}
      title={showtitle(roles.length)}
      extraClassNames="Contact-Details-Roles"
    >
      {haveRoles ? (
        <Table singleLine basic="very" compact shadow={false}>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>Company</TableHeaderCell>
              <TableHeaderCell>Title</TableHeaderCell>
              <TableHeaderCell>Added</TableHeaderCell>
              <TableHeaderCell> </TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {roles.map((rol) => {
              return (
                <TableRow>
                  <TableCell>
                    <CompanyInfo
                      key={rol.id}
                      title={() => rol.company.name}
                      company={rol.company}
                    />
                  </TableCell>
                  <TableCell>
                    <ListValue list={rol.company_titles} attribute="name" />
                  </TableCell>
                  <TableCell>
                    {formatDate(rol.updated_at, "detailed")}
                  </TableCell>
                  <TableCell>
                    {rol.is_primary && (
                      <BasicLabel type="generic">primary</BasicLabel>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <EmptyCard title="Roles" />
      )}
    </CollapsableSection>
  );
}

export default ContactRoles;
