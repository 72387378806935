import React from "react";
import CollapsableSection from "components_new/elements/CollapsableSection";
import classNames from "classnames";
import "./FormGroup.scss";

function FormGroup({
  children,
  title,
  subtitle,
  required,
  isExpanded,
  noBottomBorder,
  withTopBorder,
  extraClassnames,
  subGroup,
}) {
  const formGroupClassNames = classNames("FormGroup", {
    [extraClassnames]: !!extraClassnames,
  });

  return (
    <div className={formGroupClassNames}>
      <CollapsableSection
        expanded={isExpanded}
        title={title}
        subtitle={subtitle}
        required={required}
        subGroup={subGroup}
        noBottomBorder={noBottomBorder}
        withTopBorder={withTopBorder}
      >
        <div className="ui form">{children}</div>
      </CollapsableSection>
    </div>
  );
}

FormGroup.defaultProps = {
  noBottomBorder: false,
  withTopBorder: false,
};

export default FormGroup;
