import React, { useState, useEffect } from "react";
import FileUploader from "components_new/elements/FileUploader";
import FileItem from "./FileItem";
import classnames from "classnames";
import PropTypes from "prop-types";
import { uploadToFilestack } from "lib/fileStackUtils";
import { every, map, reject } from "lodash";
import {
  buildFileObject,
  buildFileFromFileItem,
  buildEntriesFromPrefilled,
  fileOptions,
} from "./config";
import "./DropZone.scss";

const searchAndApplyChanges = (id, files, options) => {
  return files.map((item) => {
    if (item.localId !== id) {
      return item;
    }

    return {
      ...item,
      ...options,
    };
  });
};

function DropZone(props) {
  const {
    onChange,
    prefilledFiles,
    prefilledFilesStatus,
    extraClassnames,
    disabled,
    autoUploadEnabled,
    onQueueChange,
    canMarkAsClosing,
    canChooseFileType,
    size,
    defaultDocumentType,
    noQueue,
  } = props;

  const [uploadQueue, setUploadQueue] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    window.fileStackTokens = [];
  }, []);

  useEffect(() => {
    if (prefilledFiles.length > 0) {
      const [uploadQueue, uploadedFiles] = buildEntriesFromPrefilled(
        prefilledFiles,
        prefilledFilesStatus,
      );

      setUploadQueue(uploadQueue);
      setUploadedFiles(uploadedFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uploadFilesQueue = () => {
    uploadQueue.forEach((file) => {
      if (file.status === "pending") {
        uploadFile(file);
      }
    });
  };

  useEffect(() => {
    uploadFilesQueue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadQueue.length]);

  useEffect(() => {
    if (onChange) {
      onChange(uploadedFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFiles]);

  useEffect(() => {
    if (onQueueChange) onQueueChange(uploadQueue);
  }, [onQueueChange, uploadQueue]);

  const handleSelectFile = (files) => {
    if (files.length > 0) {
      addFilesToUploadQueue(files);
    }
  };

  const addFilesToUploadQueue = (files) => {
    const fileObjects = map(files, (file) => buildFileObject(file));
    setUploadQueue((prevFiles) => [...prevFiles, ...fileObjects]);
  };

  const updateFileInQueue = (id, options) => {
    setUploadQueue((prevFiles) =>
      searchAndApplyChanges(id, prevFiles, options),
    );
  };

  const updateUploadedFile = (id, options) => {
    setUploadedFiles((prevFiles) =>
      searchAndApplyChanges(id, prevFiles, options),
    );
  };

  const removeFile = (localId, uploadedFile) => {
    // Have an id means this file is already stored in our backend side.
    const isFilePersisted = uploadedFile && !!uploadedFile.id;
    setUploadQueue((prevFiles) => reject(prevFiles, ["localId", localId]));

    isFilePersisted
      ? updateUploadedFile(localId, { remove: true })
      : setUploadedFiles((prevFiles) =>
          reject(prevFiles, ["localId", localId]),
        );
  };

  const uploadFile = (file) => {
    if (autoUploadEnabled) {
      window.fileStackTokens[file.localId] = {};

      updateFileInQueue(file.localId, {
        progress: 0,
        status: "uploading",
      });

      uploadToFilestack(
        file.fileObject,
        {
          onProgress: ({ totalPercent: progress }) => {
            updateFileInQueue(file.localId, { progress });
          },
        },
        {},
        window.fileStackTokens[file.localId],
      )
        .then((uploadedFile) => {
          addFileToUploadedFiles(file.localId, uploadedFile);
          updateFileInQueue(file.localId, {
            status: "editing",
            progress: 100,
          });
        })
        .catch((error) => {
          if (String(error).search("Forbidden") > 0) {
            updateFileInQueue(file.localId, {
              status: "forbidden",
            });
            throw error;
          }
        });
    } else {
      // With this case, we are showing the behavior of the component
      // without upload a file to filestack.

      const fakeUploadedFile = buildFileFromFileItem(file);
      addFileToUploadedFiles(file.localId, fakeUploadedFile);
      updateFileInQueue(file.localId, {
        status: "editing",
        progress: 100,
      });
    }
  };

  const cancelUploading = (file) => {
    const fileStackObject = window.fileStackTokens[file.localId];
    if (fileStackObject.cancel) fileStackObject.cancel();

    updateFileInQueue(file.localId, {
      progress: 0,
      status: "canceled",
    });
  };

  const retryUploadFile = (file) => {
    updateFileInQueue(file.localId, {
      progress: 0,
      status: "uploading",
    });

    uploadFile(file);
  };

  const addFileToUploadedFiles = (localId, uploadedFile) => {
    setUploadedFiles((prevFiles) => [
      ...prevFiles,
      {
        ...uploadedFile,
        localId,
        closing_document: false,
        document_type: defaultDocumentType,
        status: "editing",
      },
    ]);
  };

  const evaluateUploadQueueStatus = (uploadQueue) => {
    if (noQueue && every(uploadQueue, { progress: 100 })) {
      setUploadedFiles([]);
      setUploadQueue([]);
      return false;
    }
    return true;
  };

  const dropZoneClassnames = classnames("DropZone", {
    [extraClassnames]: !!extraClassnames,
    "has-files": uploadQueue.length > 0,
    [size]: !!size,
  });

  return (
    <div className={dropZoneClassnames}>
      {uploadQueue.length > 0 && evaluateUploadQueueStatus(uploadQueue) && (
        <ul className="Files List">
          {uploadQueue.map((item, index) => {
            const uploadedFile = uploadedFiles.find(
              (file) => file.localId === item.localId,
            );

            return (
              <FileItem
                key={index}
                item={item}
                uploadedFile={uploadedFile}
                removeFile={removeFile}
                updateUploadedFile={updateUploadedFile}
                cancelUploading={cancelUploading}
                retryUploadFile={retryUploadFile}
                canMarkAsClosing={canMarkAsClosing}
                canChooseFileType={canChooseFileType}
                componentSize={size}
                defaultDocumentType={defaultDocumentType}
              />
            );
          })}
        </ul>
      )}
      <FileUploader
        onSelectFile={handleSelectFile}
        disabled={disabled}
        size={size}
      />
    </div>
  );
}

DropZone.propTypes = {
  extraClassnames: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  autoUploadEnabled: PropTypes.bool,
  onQueueChange: PropTypes.func,
  canChooseFileType: PropTypes.bool,
  canMarkAsClosing: PropTypes.bool,
  prefilledFiles: PropTypes.arrayOf(PropTypes.shape({})),
  prefilledFilesStatus: PropTypes.string,
  size: PropTypes.oneOf(["tiny", "small", "regular"]),
  defaultDocumentType: PropTypes.oneOf(fileOptions.map((op) => op.key)),
  noQueue: PropTypes.bool,
};

DropZone.defaultProps = {
  autoUploadEnabled: true,
  onQueueChange: null,
  canMarkAsClosing: true,
  canChooseFileType: true,
  prefilledFiles: [],
  size: "regular",
  defaultDocumentType: null,
  noQueue: false,
};

export default DropZone;
