import usePrevious from "lib/hooks/usePrevious";
import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import AuthenticationContext from "../AuthenticationContext";

function useRenderCount() {
  const countRef = useRef(0);
  countRef.current += 1;
  return countRef.current;
}

function strictlyPublicPage(Component) {
  const StrictlyPublicPage = (props) => {
    const navigate = useNavigate();
    const [canContinue, setCanContinue] = useState(false);

    const { currentUser, isWaitingForMfaCode } = useContext(
      AuthenticationContext,
    );
    const previousCurrentUser = usePrevious(currentUser);
    const renderCount = useRenderCount();

    useEffect(() => {
      if (currentUser && !isWaitingForMfaCode) {
        // We just logged in, because the current user is new and we just rendered
        const justLoggedIn = renderCount > 1 && !previousCurrentUser;
        if (!justLoggedIn) {
          navigate("/");
        }
      } else {
        setCanContinue(true);
      }
      // We're disabling exhaustive-props because if renderCount always changes
      // this will run even when current user haven't changed
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, currentUser, isWaitingForMfaCode]);

    if (canContinue) {
      return <Component {...props} />;
    } else {
      return null;
    }
  };

  StrictlyPublicPage.displayName = `StrictlyPublicPage(${Component.name})`;
  return StrictlyPublicPage;
}

export default strictlyPublicPage;
