import React, { Fragment } from "react";
import Link from "components_new/atoms/Link";
import Text from "components_new/atoms/Text";

function LinkToReferral({ organizationUsername, referral, showSidebar }) {
  if (referral.type === "Investment") {
    return (
      <Link to={`/${organizationUsername}/investments/${referral.id}`}>
        {referral.title}
      </Link>
    );
  }

  return (
    <Text
      type="body-1-bold"
      link
      onClick={() => showSidebar("activeDealView", { dealId: referral.id })}
    >
      {referral.title}
    </Text>
  );
}

function ContactReferrals({
  referrals = [],
  organizationUsername,
  showSidebar,
}) {
  return (
    <Fragment>
      {referrals.map((referral, index) => (
        <Fragment key={index}>
          {index > 0 && <span>, </span>}
          <LinkToReferral
            organizationUsername={organizationUsername}
            referral={referral}
            showSidebar={showSidebar}
          />
        </Fragment>
      ))}
    </Fragment>
  );
}

export default ContactReferrals;
