import React, { useCallback } from "react";
import { Outlet, useLocation, matchPath } from "react-router-dom";
import ActiveDeals from "./ActiveDeals";
import useOrganizations from "store/organizations/hooks";
import { useSetSidebar } from "components_new/elements/SidebarWrapper/Sidebar-context";
import Loader from "components/shared/Loader";
import { useQueryClient } from "react-query";
import { useDeals } from "components_new/views/Deals/hooks/queries/useDeals";

function getSelectedDealId(matchPath, pathname) {
  let selectedDealId = null;
  const activePath = matchPath(
    {
      path: ":organizationId/deals/active/:id",
    },
    pathname,
  );
  const notePath = matchPath(
    {
      path: ":organizationId/deals/active/:id/notes/:noteId",
    },
    pathname,
  );

  if (activePath) {
    selectedDealId = activePath.params.id;
  } else if (notePath) {
    selectedDealId = notePath.params.id;
  }

  return selectedDealId;
}

function ActiveDealsWrapper() {
  const location = useLocation();
  const selectedDealId = getSelectedDealId(matchPath, location.pathname);

  const { showSidebar } = useSetSidebar();
  const {
    isAdmin,
    currentOrganization,
    currentOrganization: { pipeline_id: pipelineId },
  } = useOrganizations();

  const queryClient = useQueryClient();
  const { stages } = queryClient.getQueryData(["Pipeline", pipelineId]);
  const { data, isLoading } = useDeals("ActiveDeals", pipelineId, {});

  const handleOpenNewDealForm = useCallback(
    () => showSidebar("dealForm", { size: "wide" }),
    [showSidebar],
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ActiveDeals
      stages={stages}
      activeDeals={data?.deals || []}
      isLoading={isLoading}
      selectedDealId={selectedDealId}
      organizationUsername={currentOrganization.username}
      isAdmin={isAdmin}
      openNewDealSidebar={handleOpenNewDealForm}
    >
      <Outlet />
    </ActiveDeals>
  );
}

export default ActiveDealsWrapper;
