import React from "react";
import Cash from "-!svg-react-loader?!assets/icons/utility/cash.svg?name=Cash";

export default function CashIcon() {
  return (
    <div className="cashIcon">
      <Cash />
    </div>
  );
}
