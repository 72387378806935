import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { indexOf, isEmpty, isEqual } from "lodash";
import {
  ESC_KEY,
  DOWN_ARROW_KEY,
  UP_ARROW_KEY,
  ENTER_KEY,
} from "config/keyCodes";
import getDataAttributes from "lib/getDataAttributes";
import useDebounce from "lib/hooks/useDebounce";

const SELECTABLE_ITEM = ".SearchWrapper-item";
const SCROLL_THRESHOLD = 600;
const WRAPPER_ITEMS = ".SelectList";

function SearchWrapper({
  prefilledValue,
  onChange,
  onReset,
  children,
  selectableItemClassname,
  wrapperItemsClassname,
  maxHeight,
  disabled,
  actionWhenEmpty,
  allowCreation,
}) {
  const SELECTED_ITEM = `${selectableItemClassname}.is-selected`;
  const searchAndSelectWrapper = useRef(null);
  const [open, setOpen] = useState(false);
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState("");
  const [selectedItem, setSelectedItem] = useState(prefilledValue || {});
  const { setDebouncedValue } = useDebounce(value, 300, onInputChange);

  function overrideValue(value) {
    setValue(value);
    setDebouncedValue(value);
  }

  useEffect(
    () => {
      if (value === "") {
        setOpen(false);
      }

      highlightFirstItem();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value],
  );

  useEffect(
    () => {
      if (open) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open],
  );

  useEffect(() => {
    if (!isEmpty(prefilledValue) && !isEqual(selectedItem, prefilledValue)) {
      setSelectedItem(prefilledValue);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function resetActions() {
    setValue("");
    setSelectedItem({});
    setOpen(false);
    setFocus(false);
    onReset();
  }

  function onInputChange(value) {
    if (!isEmpty(value)) {
      onChange(value);
    }

    if (!isEmpty(selectedItem) & !allowCreation) {
      setSelectedItem({});
    }
    if (!open && !isEmpty(value)) {
      setOpen(true);
    }
  }

  const handleClickOutside = (event) => {
    if (
      searchAndSelectWrapper.current &&
      searchAndSelectWrapper.current.contains(event.target)
    ) {
      return;
    }
    if (allowCreation) {
      setOpen(false);
      setFocus(false);
    } else {
      resetActions();
    }
  };

  const handleKeyDown = (event) => {
    switch (event.which) {
      case ESC_KEY:
        resetActions();
        break;
      case DOWN_ARROW_KEY:
      case UP_ARROW_KEY:
        moveDownUpIntoItems(event.which);
        break;
      case ENTER_KEY:
        onEnter();
        event.preventDefault();
        break;
      default:
    }
  };

  function onEnter() {
    if (value) {
      const selectedItem = document.querySelector(SELECTED_ITEM);
      if (selectedItem) {
        if (selectedItem.dataset) {
          setSelectedItem(getDataAttributes(selectedItem));
          setOpen(false);
          setFocus(false);
        }
      } else {
        if (actionWhenEmpty) {
          actionWhenEmpty(value, selectCustomItem);
        }
      }
    }
  }

  function moveDownUpIntoItems(action) {
    const selectableItems = document.querySelectorAll(selectableItemClassname);
    const selectedItem = document.querySelector(SELECTED_ITEM);
    const wrapperItems = document.querySelector(wrapperItemsClassname);
    const selectedItemIndex = indexOf(selectableItems, selectedItem);
    const nextItem = selectableItems.item(
      selectedItemIndex + (action === UP_ARROW_KEY ? -1 : 1),
    );

    if (selectedItem) {
      const offSet = selectedItem.offsetTop;
      wrapperItems.scrollTop = maxHeight - offSet >= 0 ? 0 : offSet - maxHeight;
    }

    if (nextItem) {
      highlightItem(nextItem);
    }

    if (action === UP_ARROW_KEY && !nextItem) {
      removeHighlight();
    }
  }

  function highlightFirstItem() {
    const firstItem = document.querySelector(selectableItemClassname);
    if (firstItem) {
      highlightItem(firstItem);
    }
  }

  function highlightItem(item) {
    removeHighlight();
    item.classList.add("is-selected");
  }

  function removeHighlight() {
    const selectedItem = document.querySelector(SELECTED_ITEM);

    if (selectedItem) {
      selectedItem.classList.remove("is-selected");
    }
  }

  function selectCustomItem(customItem) {
    if (customItem) {
      setSelectedItem(customItem);
      setOpen(false);
      setFocus(false);
    }
  }

  const searchWrapperClassnames = classNames("SearchWrapper", {
    "is-focused": focus,
    "is-disabled": disabled,
  });

  const getStateWithGetters = () => ({
    value: value,
    open: open,
    focus: focus,
    setOpen: setOpen,
    setValue: overrideValue,
    onChange: setValue,
    onKeyDown: handleKeyDown,
    resetActions: resetActions,
    selectedItem: selectedItem,
    onClickItem: onEnter,
    highlightItem: highlightItem,
    selectCustomItem: selectCustomItem,
  });

  return (
    <div
      ref={searchAndSelectWrapper}
      onClick={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      className={searchWrapperClassnames}
    >
      {children(getStateWithGetters())}
    </div>
  );
}

SearchWrapper.propTypes = {
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  children: PropTypes.func,
  selectableItemClassname: PropTypes.string,
  wrapperItemsClassname: PropTypes.string,
  maxHeight: PropTypes.number.isRequired,
  collection: PropTypes.arrayOf(PropTypes.shape({})),
  prefilledValue: PropTypes.shape({}),
  disabled: PropTypes.bool,
  actionWhenEmpty: PropTypes.func,
  allowCreation: PropTypes.bool,
};

SearchWrapper.defaultProps = {
  isOptional: false,
  onReset: () => {},
  selectableItemClassname: SELECTABLE_ITEM,
  wrapperItemsClassname: WRAPPER_ITEMS,
  maxHeight: SCROLL_THRESHOLD,
  prefilledValue: null,
  disabled: false,
  allowCreation: false,
};

export default SearchWrapper;
