import ChangePortfolio from "components/investments/ChangePortfolio";
import AcceptFromOtherInvitation from "components/users/Organizations/AcceptFromOtherInvitation";
import InvalidInvitation from "components/users/Organizations/InvalidInvitation";
import DeleteCompanyConfirmation from "components/companies/modals/DeleteCompanyConfirmation";
import InvalidLogo from "components/companies/modals/InvalidLogo";
import NewInvestmentDocuments from "components/investments/InvestmentDocuments";
import RemoveDocument from "components/documents/RemoveDocument";
import RemoveDocumentConfirmation from "components/documents/RemoveDocumentConfirmation";
import UpdatePhoneNumber from "components/users/LoginInformation/UpdatePhoneNumber";
import AttachCapitalCallFiles from "components/investments/CapitalCallHistory/AttachFiles";
import DocumentsViewer from "components/documents/DocumentsViewer/";
import CompanyDocuments from "components_new/views/Company/shared/CompanyDocuments";
import DiscussionDocuments from "components_new/elements/Discussions/DiscussionDocuments";
import AvatarFilePicker from "components/users/AccountInformationForm/AvatarFilePicker";

export default {
  acceptFromOtherInvitation: { component: AcceptFromOtherInvitation },
  attachCapitalCallFiles: { component: AttachCapitalCallFiles, size: "medium" },
  avatarFilePicker: { component: AvatarFilePicker },
  changePortfolioOfInvestment: { component: ChangePortfolio },
  companyDocuments: { component: CompanyDocuments, size: "medium" },
  deleteCompanyConfirmation: { component: DeleteCompanyConfirmation },
  discussionDocuments: { component: DiscussionDocuments, size: "medium" },
  documentsViewer: { component: DocumentsViewer, size: "medium" },
  invalidInvitation: { component: InvalidInvitation },
  invalidLogo: { component: InvalidLogo },
  newInvestmentDocuments: { component: NewInvestmentDocuments, size: "medium" },
  removeDocument: { component: RemoveDocument },
  removeDocumentConfirmation: { component: RemoveDocumentConfirmation },
  updatePhoneNumber: { component: UpdatePhoneNumber },
};
