import React, { useEffect, useState } from "react";
import Form from "components_new/atoms/Form";
import FormFooter from "components_new/SidebarComponents/SidebarForm/FormFooter";
import DropdownForm from "components_new/atoms/DropdownForm";
import Input from "components_new/atoms/Input";
import TextArea from "components_new/atoms/TextArea";
import RequiredNote from "components_new/SidebarComponents/shared/RequiredNote";
import useForm from "lib/hooks/useForm";
import Header, { SubHeader } from "components_new/atoms/Header";
import preventerHandler from "lib/preventerHandler";
import { isEmpty } from "lodash";
import "./AddCompanyForm.scss";
import FlagsCheckboxGroup from "components_new/SidebarComponents/forms/groups/CompanyBasicDetails/FlagsCheckboxGroup";
import Tags from "components/shared/Tags";
import PreFilledInput from "components_new/atoms/PreFilledInput";
import FirmType from "components_new/SidebarComponents/forms/groups/CompanyBasicDetails/FirmType";
import { tierDropdown } from "config/tierConfig";
import Industry from "components/shared/Industry";

export const WEBSITE_PREFILL = "http://";

const ACTIVITY_STATUS_OPTIONS = [
  { key: "", text: "Select status", value: "Select status" },
  { key: 0, text: "Active Fundraising", value: "Active Fundraising" },
  { key: 1, text: "Active M&A", value: "Active M&A" },
  { key: 2, text: "Raising Soon", value: "Raising Soon" },
];

const initialValues = {
  formData: {
    name: "",
    headquarters: "",
    description: "",
    interesting: false,
    double_down: false,
    stealth: false,
    is_yc: false,
    activity_status: "",
    tags: [],
    industries: [],
    firm_type: [],
    investment_domain: [],
    service_domain: [],
  },
};

function AddCompanyForm({
  ownName,
  submitTo,
  componentData,
  createCompany,
  closeSidebar,
  updateSidebarComponent,
  onUnmount,
  primarySectors,
  onChange,
  prepopulatedCompanyTags,
  prepopulatedSectors,
}) {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isEmpty(componentData)) {
      handleBatchChange(componentData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentData]);

  const onSubmit = (clientData) => {
    if (onChange) {
      onChange(clientData);
    } else {
      if (!isEmpty(submitTo)) {
        updateSidebarComponent(submitTo, { data: { company: clientData } });
        onUnmount(ownName, submitTo);
      } else {
        setIsLoading(true);
        createCompany(clientData)
          .then((_) => {
            setIsLoading(false);
            closeSidebar();
          })
          .catch((error) => {
            handleErrors(error);
            handleScrollView(error);
            setIsLoading(false);
          });
      }
    }
  };
  const {
    formData,
    handleChange,
    handleSubmit,
    handleErrors,
    handleBatchChange,
    handleScrollView,
    errors,
    touched,
  } = useForm(initialValues, onSubmit, { strictValidation: true });

  const {
    name,
    website,
    headquarters,
    description,
    interesting,
    double_down,
    stealth,
    is_yc,
    activity_status,
    tier,
    founders,
    tags,
    industries,
    firm_type,
    investment_domain,
    service_domain,
    primary_sector,
  } = formData;

  const isSubmitButtonDisabled = isEmpty(formData.name);

  return (
    <Form
      onSubmit={preventerHandler(() => handleSubmit())}
      className="AddCompanyForm"
    >
      <div className="FormGroup AddCompanyForm-Body">
        <Header as="h4">Add New Company</Header>
        <SubHeader>Information about the new company.</SubHeader>
        <Input
          name="name"
          label="Company name"
          isRequired
          value={name}
          onChange={(e, { value }) => {
            handleChange("name", value);
          }}
          error={!!errors.for("name")}
          errorMessage={errors.for("name")}
          size="tiny"
        />
        <PreFilledInput
          name="website"
          label="Company website"
          value={website}
          preFilled={WEBSITE_PREFILL}
          onChange={(value) => handleChange("website", value)}
          size="tiny"
        />
        <Input
          name="headquarters"
          label="Headquarters"
          placeholder="e.g. San Francisco, CA"
          value={headquarters}
          onChange={(e, { value }) => {
            handleChange("headquarters", value);
          }}
          size="tiny"
        />
        <Input
          name="founders"
          label="Founders"
          onChange={(_, { value }) => handleChange("founders", value)}
          value={founders}
          error={!!errors.for("founders")}
          errorMessage={errors.for("founders")}
          size="tiny"
        />
        <FlagsCheckboxGroup
          interesting={interesting}
          double_down={double_down}
          stealth={stealth}
          yc={is_yc}
          handleChange={handleChange}
          size="tiny"
        />
        <TextArea
          name="description"
          label="Description"
          value={description}
          onChange={(e, { value }) => {
            handleChange("description", value);
          }}
          size="tiny"
        />
        <DropdownForm
          fluid
          label="Primary Sector"
          name="primary_sector"
          placeholder="Select a primary sector"
          value={primary_sector}
          items={primarySectors}
          onChange={(_, { value }) => handleChange("primary_sector", value, 1)}
          extraClassnames="FormElement"
          size="tiny"
        />
        <Industry
          label="Sectors"
          createTagLabel="Create new sector"
          placeholder="Search sectors"
          name="industries"
          taggedList={industries || []}
          onChange={(value) => handleChange("industries", value)}
          size="tiny"
          errorMessage={errors.for("industries")}
          extraClassnames="IndustryTags"
          prepopulatedItems={prepopulatedSectors}
        />
        <Tags
          tagType="company"
          label="Company tags"
          name="tags"
          taggedList={tags}
          onChange={(value) => handleChange("tags", value)}
          errorMessage={errors.for("tags")}
          size="tiny"
          prepopulatedItems={prepopulatedCompanyTags}
        />
        <DropdownForm
          label="Activity Status"
          aria-label="Activity Status"
          placeholder="Select status"
          items={ACTIVITY_STATUS_OPTIONS}
          onChange={(_, { value }) => handleChange("activity_status", value)}
          value={activity_status}
          size="tiny"
        />
        <DropdownForm
          label="Tier"
          placeholder="Select a tier"
          items={tierDropdown}
          onChange={(e, { value }) => handleChange("tier", value)}
          value={tier}
          size="tiny"
          extraClassnames="TierInput"
        />
        <FirmType
          firmType={firm_type}
          investmentDomain={investment_domain}
          serviceDomain={service_domain}
          handleChange={handleChange}
          size="tiny"
        />
        <RequiredNote />
      </div>
      <FormFooter
        componentName={ownName}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        touched={touched}
        disableSubmit={isSubmitButtonDisabled}
        submitTextButton="Add Company"
      />
    </Form>
  );
}

export default AddCompanyForm;
