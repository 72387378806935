import React, { useEffect, useCallback } from "react";
import MainLayout from "components_new/layouts/Main";
import { Outlet, useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  fetchOrganizations,
  setCurrentOrganization,
} from "store/organizations/actions";
import { clearFlashes } from "actions/synchronous/flash";
import { resetResourceState } from "store/resources/actions";
import { setOrganizationV2 } from "store/organizations/organizationView/actions";

import useCurrentOrganization from "./useCurrentOrganization";
import useCurrentUser from "hooks/useCurrentUser";

function AppLayout() {
  const currentOrganization = useCurrentOrganization();
  const { organizationUsername } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { is_canary: isCanary, is_admin: isAdmin } = useCurrentUser();

  const checkAndStoreOrganization = useCallback(async () => {
    const organizations = await dispatch(fetchOrganizations());

    const wantedOrganization = organizations.find(
      (organization) => organization.username === organizationUsername,
    );

    if (wantedOrganization) {
      dispatch(setCurrentOrganization(wantedOrganization));
      dispatch(setOrganizationV2(wantedOrganization.username));
    } else {
      const privateOrganization = organizations.find(
        (organization) => organization.private,
      );
      navigate(`/${privateOrganization.username}/dashboard`);
    }
  }, [dispatch, navigate, organizationUsername]);

  useEffect(() => {
    checkAndStoreOrganization();
  }, [checkAndStoreOrganization]);

  const cleanupState = useCallback(() => {
    dispatch(clearFlashes());
    dispatch(resetResourceState());
  }, [dispatch]);

  useEffect(() => {
    cleanupState();
  }, [location, cleanupState]);

  if (!currentOrganization) {
    return null;
  }

  return (
    <MainLayout
      organizationUsername={currentOrganization.username}
      isCanary={isCanary}
      isAdmin={isAdmin}
    >
      <Outlet />
    </MainLayout>
  );
}

export default AppLayout;
