import { useMutation, useQueryClient } from "react-query";
import Api from "lib/Api";

export function useUpdateStagesOrder() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ pipelineId, stageId, destination }) =>
      Api.patch(`/pipelines/${pipelineId}/stages/bulk_update`, {
        id: stageId,
        new_order: destination,
      }),
    {
      onMutate: ({ newStages, pipelineId }) => {
        const key = ["Pipeline", pipelineId];
        const previousData = queryClient.getQueryData(key);

        queryClient.cancelQueries(key);
        queryClient.setQueryData(key, (oldData) => ({
          ...oldData,
          stages: newStages,
        }));

        return () => queryClient.setQueryData(key, previousData);
      },
      onSuccess: ({ data: stages }, { pipelineId }) => {
        return queryClient.setQueryData(
          ["Pipeline", pipelineId],
          (oldData) => ({
            ...oldData,
            stages,
          }),
        );
      },
    },
  );
}
