import React, { Fragment } from "react";
import PrimaryButton from "components/shared/PrimaryButton";
import SecondaryButton from "components/shared/SecondaryButton";
import preventerHandler from "lib/preventerHandler";
import PropTypes from "prop-types";
import {
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "components_new/elements/Modal";
import Header, { SubHeader } from "components_new/atoms/Header";
import { Form } from "@ableco/semantic-ui-react";

function ConfirmationModal(props) {
  const {
    action,
    cancelButtonLabel,
    confirmButtonLabel,
    message,
    showCancelButton,
    showPrimaryButton,
    primaryButtonClassnames,
    submitting,
    title,
    subTitle,
    closeModal,
  } = props;

  return (
    <Fragment>
      <ModalHeader>
        <Header as="h2" role="heading">
          {title}
        </Header>
        {subTitle && <SubHeader>{subTitle}</SubHeader>}
      </ModalHeader>
      <ModalContent>
        <Form data-testid="ModalContentMessage">{message}</Form>
      </ModalContent>
      <ModalFooter withBorder={false}>
        <Fragment>
          {showPrimaryButton && (
            <PrimaryButton
              type="submit"
              customClass={primaryButtonClassnames}
              onClick={preventerHandler(action)}
              filter={{
                applyingFilter: submitting,
              }}
            >
              {confirmButtonLabel}
            </PrimaryButton>
          )}
          {showCancelButton && (
            <SecondaryButton onClick={closeModal}>
              {cancelButtonLabel}
            </SecondaryButton>
          )}
        </Fragment>
      </ModalFooter>
    </Fragment>
  );
}

ConfirmationModal.propTypes = {
  action: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  confirmButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  showCancelButton: PropTypes.bool.isRequired,
  showPrimaryButton: PropTypes.bool.isRequired,
  primaryButtonClassnames: PropTypes.string,
  closeModal: PropTypes.func,
};

ConfirmationModal.defaultProps = {
  cancelButtonLabel: "Cancel",
  showCancelButton: true,
  showPrimaryButton: true,
};

export default ConfirmationModal;
