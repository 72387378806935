import PropTypes from "prop-types";
import React, { Component } from "react";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { hot } from "react-hot-loader";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import ErrorRequest from "components/errors/ErrorRequest";
import ModalDialog from "components_new/elements/ModalDialog";
import Api from "lib/Api";

import customCalloutSymbol from "lib/charts/customCalloutSymbol";
import ReactHighcharts from "react-highcharts";
import { AuthenticationContextProvider } from "./AuthenticationContext";

const queryClient = new QueryClient();

class Mighty extends Component {
  static propTypes = {
    store: PropTypes.shape({
      getState: PropTypes.func.isRequired,
      dispatch: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    hasErrors: false,
    lastError: null,
    hasServerErrors: false,
    lastServerError: null,
  };

  componentDidCatch(lastError) {
    this.setState({ hasErrors: true, lastError });
  }

  componentDidMount() {
    new customCalloutSymbol(ReactHighcharts);
    Api.configure({
      onServerError: (_state, _dispatch, _response, error) => {
        this.setState({
          hasServerErrors: true,
          lastServerError: {
            stack: error.traces["Application Trace"]
              .map((line) => line.trace)
              .join("\n"),
          },
        });
      },
    });
  }

  cleanServerErrors() {
    this.setState({
      hasServerErrors: false,
      lastServerError: null,
    });
  }

  render() {
    const { store } = this.props;

    if (this.state.hasErrors) {
      return (
        <ModalDialog isOpen={true}>
          <ErrorRequest error={this.state.lastError} />
        </ModalDialog>
      );
    }

    return (
      <QueryClientProvider client={queryClient}>
        <ModalDialog
          isOpen={this.state.hasServerErrors}
          closeModal={() => this.cleanServerErrors()}
        >
          <ErrorRequest error={this.state.lastServerError} />
        </ModalDialog>
        <Provider store={store}>
          <BrowserRouter>
            <AuthenticationContextProvider>
              <AppRoutes />
            </AuthenticationContextProvider>
          </BrowserRouter>
          <ReactQueryDevtools initialIsOpen={false} />
        </Provider>
      </QueryClientProvider>
    );
  }
}

export default hot(module)(Mighty);
