import React, { Fragment } from "react";
import CompanyDeals from "components_new/views/Company/shared/CompanyDeals";
import CompanyInvestments from "components_new/views/Company/shared/CompanyInvestments";
import CompanyContacts from "components_new/views/Company/shared/CompanyContacts";
import CompanyNotes from "components_new/views/Company/shared/CompanyNotes";
import CoInvestmentsTable from "components_new/views/Company/CompanyCoInvestmentsTab/CoInvestmentsTable";
import CompanySnapshots from "components_new/views/Company/shared/CompanySnapshots";

export default function CompanyOverviewTab({
  companyActiveDeals,
  companyAvatars,
  companySnapshots,
  currentOrganizationUsername,
  showSidebar,
  companyInvestments,
  handleEditSnapshot,
  // handleDeleteSnapshot,
  investorCoInvestments,
  portfolios,
  users,
  posts,
  resource,
  isSubmittingNotes = false,
  isPortfolio,
  isInvestor,
  createPost,
  updatePost,
  removePost,
  openDocumentsUploader,
  openAddCoInvestmentForm,
  openEditCoInvestmentForm,
  openDeleteCoInvestment,
  employees,
  openContactDetailsView,
  referrers,
  firmType,
}) {
  const isPartnerOrServiceFirm =
    firmType.includes("Partner") || firmType.includes("Service Firm");

  return (
    <div className="TabContentDiv">
      {isPortfolio && (
        <Fragment>
          <CompanyDeals
            companyDeals={companyActiveDeals}
            currentOrganizationUsername={currentOrganizationUsername}
            showSidebar={showSidebar}
          />
          <CompanyInvestments
            companyInvestments={companyInvestments}
            companyAvatars={companyAvatars}
            portfolios={portfolios}
            currentOrganizationUsername={currentOrganizationUsername}
          />
        </Fragment>
      )}

      {isInvestor && (
        <CoInvestmentsTable
          companyId={resource.company_id}
          currentOrganizationUsername={currentOrganizationUsername}
          investorCoInvestments={investorCoInvestments}
          openAddCoInvestmentForm={openAddCoInvestmentForm}
          openEditCoInvestmentForm={openEditCoInvestmentForm}
          openDeleteCoInvestment={openDeleteCoInvestment}
        />
      )}

      <CompanyContacts
        title="Contacts"
        testId="ContactsGroup"
        companyContacts={employees}
        openContactDetailsView={openContactDetailsView}
      />

      {(isInvestor || isPartnerOrServiceFirm) && (
        <Fragment>
          <CompanySnapshots
            companySnapshots={companySnapshots}
            handleEditSnapshot={handleEditSnapshot}
            // handleDeleteSnapshot={handleDeleteSnapshot}
            currentOrganizationUsername={currentOrganizationUsername}
          />
          <CompanyContacts
            title="Referrers"
            testId="ReferrersGroup"
            companyContacts={referrers}
            openContactDetailsView={openContactDetailsView}
          />
        </Fragment>
      )}

      <CompanyNotes
        currentOrganizationUsername={currentOrganizationUsername}
        users={users}
        posts={posts}
        resource={resource}
        isSubmitting={isSubmittingNotes}
        createPost={createPost}
        updatePost={updatePost}
        removePost={removePost}
        openDocumentsUploader={openDocumentsUploader}
      />
    </div>
  );
}
