import React, { useMemo, Fragment } from "react";
import Task from "components_new/elements/Task";
import CollapsableSection from "components_new/elements/CollapsableSection";
import EmptyCard from "components_new/elements/EmptyCard";
import Button from "components_new/atoms/Button";
import Icon from "components_new/atoms/Icon";
import { sortBy } from "lodash";
import "./TaskList.scss";

function showtitle(count) {
  return (
    <Fragment>
      Tasks <small>({count})</small>
    </Fragment>
  );
}

function TaskList({ tasks, onComplete, openTaskForm, undoComplete }) {
  const haveTasks = tasks?.length > 0;
  const orderedTasks = useMemo(() => {
    return sortBy(tasks, ["completed_at"]).reverse();
  }, [tasks]);

  return (
    <div className="DealTaskList">
      <Button
        size="tiny"
        secondary
        className="AddNewTaskButton"
        onClick={() => openTaskForm(null)}
      >
        <Icon icon="ri-add-line" size="tiny" />
        New Task
      </Button>
      <CollapsableSection
        expanded={haveTasks}
        title={showtitle(tasks?.length || 0)}
      >
        <Fragment>
          {haveTasks ? (
            orderedTasks.map((task) => (
              <Task
                key={task.id}
                task={task}
                onComplete={onComplete}
                undoComplete={undoComplete}
                onClick={openTaskForm}
              />
            ))
          ) : (
            <EmptyCard title="current tasks" />
          )}
        </Fragment>
      </CollapsableSection>
    </div>
  );
}

export default TaskList;
