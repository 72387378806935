import { connect } from "react-redux";
import InvestmentActions from "./InvestmentActions";
import { openInModal, ModalNames } from "store/modal";
import { openModal } from "store/modalV2";
import { openSidebar } from "store/sidebar";

function makeMapStateToProps() {
  return function mapStateToProps({ sidebar: { initialComponent } }, props) {
    const { investment, company } = props;
    // TODO: we have to change this line to get the investment via FindEntity function
    return {
      investment: investment,
      company: company,
      initialHoldingId: investment ? investment.id : null,
      /**
       * remove me after deprecation of modal forms
       */
      initialComponent,
    };
  };
}
function mapDispatchToProps(dispatch) {
  return {
    openEditInvestmentSidebarForm: (investment) =>
      dispatch(
        openSidebar("editInvestmentForm", "Edit Investment", {
          investment,
          isEditing: true,
        }),
      ),
    openLogAcquisitionSidebarForm: (investment) =>
      dispatch(
        openSidebar("logAcquisitionForm", "Log Acquisition", {
          investment,
        }),
      ),
    openLogSaleSidebarForm: (investment) =>
      dispatch(
        openSidebar("logSaleForm", "Log Sale", {
          investment,
        }),
      ),
    openLogStockSplitSidebarForm: (investment) =>
      dispatch(
        openSidebar("stockSplitForm", "Log Stock Split", {
          investment,
        }),
      ),
    openLogWriteOffSidebarForm: (investment) =>
      dispatch(openSidebar("writeOffForm", "Log Write-Off", { investment })),
    openValuationSidebarForm: (options) =>
      dispatch(
        openSidebar("valuationForm", "Add Valuation", {
          initialHoldingId: options.investment.id,
        }),
      ),
    openDeleteInvestmentModal: (investment) =>
      dispatch(openModal("deleteInvestment", investment)),
    openChangePortfolioOfInvestmentModal: (options) =>
      dispatch(openInModal(ModalNames.changePortfolioOfInvestment, options)),
    openGlobalCapitalCallSidebarForm: (initialHoldingId) =>
      dispatch(
        openSidebar("capitalCallForm", "Add Capital Call", {
          initialHoldingId,
        }),
      ),
    openLogDistributionSidebarForm: (investment) =>
      dispatch(
        openSidebar("distributionForm", "Log Distribution", { investment }),
      ),
    openLogConversionSidebarForm: (investment) =>
      dispatch(
        openSidebar("logConversionForm", "Log Conversion", {
          investment,
        }),
      ),
  };
}

export default connect(
  makeMapStateToProps,
  mapDispatchToProps,
)(InvestmentActions);
