import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCompanies } from "components_new/views/CompanyDirectory/hooks/queries/useCompanies";
import { useParams } from "react-router-dom";
import { getCollection } from "store/domain/selectors";
import { filterTag } from "store/tags/actions";
import { filterIndustry } from "store/industries/actions";
import { debounce, isEmpty, sortBy } from "lodash";
import { useQueryClient } from "react-query";

import useSidebar from "store/sidebar/hooks";
import CompanyDirectoryView from "./CompanyDirectoryView";
import mapCollectionToOptions from "components_new/lib/mapCollectionToOptions";

function CompanyDirectoryWrapper() {
  const params = useParams();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { openSidebar } = useSidebar();
  const [filters, updateFilters] = useState({});
  const { data, isLoading } = useCompanies(params.tab, filters);

  useEffect(() => {
    updateFilters({});
  }, [params.tab]);

  const headquartersOptions = useMemo(
    () => mapCollectionToOptions({ collection: data?.headquarters }),
    [data],
  );

  const coInvestorsOptions = useMemo(
    () => mapCollectionToOptions({ collection: data?.coInvestors }),
    [data],
  );

  // TODO: Move tags to React Query
  const filterTags = debounce((tagName) => dispatch(filterTag(tagName)), 300);
  const filterIndustries = debounce(
    (industry) => dispatch(filterIndustry(industry)),
    300,
  );

  const tagSuggestions = useSelector(
    (state) => getCollection(state, "tagSuggestions"),
    isEmpty,
  );

  const industriesSuggestions = useSelector(
    (state) => getCollection(state, "industries"),
    isEmpty,
  );

  const isSearchActive = useMemo(() => {
    const isSearching = Object.values(filters).some((value) => !isEmpty(value));

    return isSearching;
  }, [filters]);

  const sortedCompanies = useMemo(() => {
    if (filters.activity === "company_activity") {
      return sortBy(data?.companies, "activity_status");
    }

    return data?.companies;
  }, [data, filters]);

  const invalidateQueriesCache = useCallback(() => {
    queryClient.invalidateQueries("Companies");
  }, [queryClient]);

  const handleOpenEditCompanyForm = useCallback(
    (companyId, companyName) =>
      openSidebar("globalCompanyForm", `Edit ${companyName}`, {
        entityId: companyId,
        callback: invalidateQueriesCache,
      }),
    [invalidateQueriesCache, openSidebar],
  );

  return (
    <CompanyDirectoryView
      isLoading={isLoading}
      isSearchActive={isSearchActive}
      params={params}
      totalCompanies={data?.totalCompanies}
      coInvestors={coInvestorsOptions}
      companies={sortedCompanies}
      headquarters={headquartersOptions}
      filterTags={filterTags}
      tagSuggestions={tagSuggestions}
      updateFilters={updateFilters}
      filters={filters}
      handleOpenEditCompanyForm={handleOpenEditCompanyForm}
      filterIndustries={filterIndustries}
      industriesSuggestions={industriesSuggestions}
    />
  );
}

export default CompanyDirectoryWrapper;
