import PropTypes from "prop-types";
import React from "react";
import Link from "components_new/atoms/Link";

const PageFooter = ({ items }) => (
  <div className="u-row PageFooter">
    {items.map((item) => {
      return (
        <Link
          to={item.url}
          key={item.url}
          className="PageFooter-item u-metaContentLight"
        >
          {item.children}
        </Link>
      );
    })}
  </div>
);

PageFooter.defaultProps = {
  items: [
    {
      url: "#",
      children: "Mighty Knowledge Base",
    },
    {
      url: "/terms",
      children: "Terms of Service",
    },
    {
      url: "/privacy",
      children: "Privacy Policy",
    },
  ],
};

PageFooter.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      children: PropTypes.node.isRequired,
    }),
  ),
};

export default PageFooter;
