import React, { Fragment, useCallback } from "react";
import { capitalize } from "lodash";
import formatDate from "lib/formatDate";
import DataCard from "components_new/elements/DataCard";
import TextLabel from "components_new/atoms/TextLabel";
import TextTitle from "components_new/atoms/TextTitle";
import BasicLabel from "components_new/atoms/BasicLabel";
import GroupedColumnedValueList from "components_new/atoms/ColumnedValueList/GroupedColumnedValueList";

function DealRow({
  deal,
  companyAvatar,
  portfolioName,
  dropdownComponent,
  showSidebar,
}) {
  const referrals = deal.referrals.join(", ");
  const dealMappingByStatus = {
    active: {
      columnedData: [
        [
          { label: "Referral", value: referrals },
          { label: "Owner", value: deal.owner_name },
        ],
        [
          {
            label: "Round",
            value: deal.round_name,
          },
          {
            label: "Valuation",
            value: deal.company_valuation,
            type: "currency",
            precision: 2,
          },
          {
            label: "Raising",
            value: deal.amount_raised_in_round,
            type: "currency",
            precision: 2,
          },
        ],
      ],
    },
    committed: {
      columnedData: [
        [
          { label: "Referral", value: referrals },
          { label: "Owner", value: deal.owner_name },
        ],
        [
          {
            label: "Committed",
            value: deal.commitment_amount,
            type: "currency",
            precision: 2,
          },
          { label: "", value: " " },
        ],
      ],
    },
    passed: {
      columnedData: [
        [
          { label: "Referral", value: referrals },
          { label: "Owner", value: deal.owner_name },
        ],
        [{ label: "Pass Reason", value: deal.reason_passed }],
      ],
    },
    invested: {
      columnedData: [
        [
          { label: "Referral", value: referrals },
          { label: "Owner", value: deal.owner_name },
        ],
        [
          {
            label: "Round",
            value: deal.round_name,
          },
          {
            label: "Valuation",
            value: deal.company_valuation,
            type: "currency",
            precision: 2,
          },
          {
            label: "Raising",
            value: deal.amount_raised_in_round,
            type: "currency",
            precision: 2,
          },
          {
            label: "Invested",
            value: deal.amount_raised_in_round,
            type: "currency",
            precision: 2,
          },
        ],
      ],
    },
  };

  const dealColumnedData = dealMappingByStatus[deal.status].columnedData;
  const statusLabel =
    deal.status == "active" ? "Investment Decision" : capitalize(deal.status);

  const DropdownComponent = dropdownComponent;

  const onCardClick = useCallback(() => {
    showSidebar("activeDealView", { dealId: deal.id });
  }, [showSidebar, deal]);

  return (
    <DataCard
      onClick={onCardClick}
      actionMenuComponent={<DropdownComponent deal={deal} />}
    >
      <DataCard.Avatar
        imageUrl={companyAvatar ? companyAvatar.logo_url : null}
        icon="deal-med"
      />
      <DataCard.Header>
        <TextLabel>DEAL</TextLabel>
        <TextTitle>
          {companyAvatar.name} ({deal.name})
          <BasicLabel data-testid="dealStatus" type="generic" upperCase={true}>
            {statusLabel}
          </BasicLabel>
        </TextTitle>
        Updated {formatDate(deal.updated_at, "semiDetailed")}{" "}
        {!!portfolioName && <Fragment>&nbsp;·&nbsp; {portfolioName}</Fragment>}
      </DataCard.Header>
      <DataCard.Content>
        <GroupedColumnedValueList data={dealColumnedData} />
      </DataCard.Content>
    </DataCard>
  );
}

export default DealRow;
