import React, { useCallback, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { groupBy, isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import formatContactName from "lib/formatters/formatContactName";
import { openModal } from "store/modalV2";
import useOrganizations from "store/organizations/hooks";
import useCompanyNotes from "store/companies/companyNotes/hooks";
import { useSetSidebar } from "components_new/elements/SidebarWrapper/Sidebar-context";
import useSidebar from "store/sidebar/hooks";
import { useCompany } from "components_new/views/Company/hooks/queries/useCompany";
import { useCreateCompanyPost } from "components_new/views/Company/hooks/mutations/useCreateCompanyPost";
import { useUpdateCompanyPost } from "components_new/views/Company/hooks/mutations/useUpdateCompanyPost";
import { useRemoveCompanyPost } from "components_new/views/Company/hooks/mutations/useRemoveCompanyPost";
// import { useDeleteSnapshot } from "components_new/views/Snapshots/hooks/mutations/useDeleteSnapshot";
import CompanyOverviewTab from "./CompanyOverviewTab";

export default function CompanyOverviewTabWrapper() {
  const dispatch = useDispatch();
  const { showSidebar } = useSetSidebar();
  const { openSidebar } = useSidebar();
  const { id: companyId } = useParams();
  const navigate = useNavigate();
  const { openDocumentsUploader } = useCompanyNotes();
  const { currentOrganization } = useOrganizations();
  const currentOrganizationUsername = currentOrganization.username;

  const { data } = useCompany(companyId);
  const { mutateAsync: createPost } = useCreateCompanyPost();
  const { mutateAsync: updatePost } = useUpdateCompanyPost();
  const { mutateAsync: removePost } = useRemoveCompanyPost();
  // const { mutateAsync: deleteSnapshot } = useDeleteSnapshot();

  const {
    companyAvatars,
    investorCoInvestments,
    companyDetails,
    companyDeals,
    companyPosts,
    employees,
    exitedInvestments,
    investments,
    portfolios,
    referrers: referrersData,
    referrerCompanies,
  } = data;

  const firmType = companyDetails.firm_type;
  const isPortfolio = companyDetails.is_portfolio;
  const isInvestor = companyDetails.is_investor;

  const companyActiveDeals = useMemo(() => {
    return companyDeals
      .filter((deal) => deal.status === "active")
      .map((deal) => {
        return {
          ...deal,
          referrals: deal.contact_ids
            ? deal.contact_ids.map((id) =>
                formatContactName(
                  referrersData.find((contact) => contact.id === id),
                ),
              )
            : [],
          companyAvatar: companyAvatars.find(
            (companyAvatar) => companyAvatar.id === deal.company_id,
          ),
          portfolioName:
            deal.status == "invested" ? deal.investment["portfolio_name"] : "",
        };
      });
  }, [companyDeals, companyAvatars, referrersData]);

  const companyInvestments = investments.concat(exitedInvestments);

  const usersById = groupBy(
    currentOrganization.organization_memberships,
    "user_id",
  );

  const handleCreatePost = useCallback(
    (resource, formData) => createPost({ resource, formData }),
    [createPost],
  );

  const handleUpdatePost = useCallback(
    (resource, postId, formData) => updatePost({ resource, postId, formData }),
    [updatePost],
  );

  const handleRemovePost = useCallback(
    (resource, postId) => removePost({ resource, postId }),
    [removePost],
  );

  const openContactDetailsView = (contact) => {
    showSidebar("contactsView", { contactId: contact.id });
  };

  function addCompanyNameToReferrer(referrer) {
    const referrerCompany = referrerCompanies.find(
      (company) => company.id === referrer.company_id,
    );
    return {
      ...referrer,
      companyName: !isEmpty(referrerCompany) ? referrerCompany.name : "",
    };
  }
  const referrers = referrersData.map((referrer) =>
    addCompanyNameToReferrer(referrer),
  );

  const handleOpenAddCoInvestmentForm = useCallback(
    () =>
      openSidebar("coInvestmentForm", "Add Co-Investment", {
        companyId: data?.companyDetails?.id,
        prefilledInvestor: data?.companyDetails,
        disableInvestorSelector: true,
        resourceName: "Co-Investment",
      }),
    [data, openSidebar],
  );

  const handleOpenEditCoInvestmentForm = useCallback(
    (coInvestment) =>
      openSidebar("coInvestmentForm", "Edit Co-Investment", {
        companyId: data?.companyDetails?.id,
        prefilledInvestor: data?.companyDetails,
        disableInvestorSelector: true,
        resourceName: "Co-Investment",
        coInvestment,
      }),
    [data, openSidebar],
  );

  const handleOpenDeleteCoInvestment = useCallback(
    (coInvestment) =>
      dispatch(
        openModal("removeCoInvestment", {
          resourceName: "Co-Investment",
          companyId: data?.companyDetails?.id,
          coInvestment,
        }),
      ),
    [data, dispatch],
  );

  const handleEditSnapshot = useCallback(
    (id) => {
      navigate(`/${currentOrganizationUsername}/reports/snapshots/${id}/edit`);
    },
    [navigate, currentOrganizationUsername],
  );

  return (
    <CompanyOverviewTab
      addCompanyNameToReferrer={addCompanyNameToReferrer}
      companyActiveDeals={companyActiveDeals}
      companyAvatars={companyAvatars}
      investorCoInvestments={investorCoInvestments}
      companyInvestments={companyInvestments}
      companySnapshots={companyDetails.snapshots}
      createPost={handleCreatePost}
      currentOrganizationUsername={currentOrganizationUsername}
      employees={employees}
      firmType={firmType}
      isInvestor={isInvestor}
      isPortfolio={isPortfolio}
      openAddCoInvestmentForm={handleOpenAddCoInvestmentForm}
      openEditCoInvestmentForm={handleOpenEditCoInvestmentForm}
      openDeleteCoInvestment={handleOpenDeleteCoInvestment}
      openContactDetailsView={openContactDetailsView}
      openDocumentsUploader={openDocumentsUploader}
      portfolios={portfolios}
      posts={companyPosts}
      referrers={referrers}
      removePost={handleRemovePost}
      resource={{
        type: "Company",
        company_id: companyId,
      }}
      showSidebar={showSidebar}
      users={usersById}
      updatePost={handleUpdatePost}
      handleEditSnapshot={handleEditSnapshot}
    />
  );
}
