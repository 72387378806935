import React, { useMemo, useState } from "react";
import DropdownForm from "components_new/atoms/DropdownForm";
import UserAvatar from "components_new/elements/UserAvatar";
import "./OwnerDealForm.scss";

function OwnerDealForm({ members, onChange, currentOwner }) {
  const [isLoading, setIsloading] = useState(false);
  const triggerDropdown = useMemo(() => {
    return (
      <UserAvatar
        avatarColor={currentOwner.avatar}
        avatarUrl={currentOwner.url}
        initials={currentOwner.initials}
        fullName={currentOwner.name}
        size="small"
        tooltipPosition="top left"
        isLoading={isLoading}
        showTooltip={true}
      />
    );
  }, [currentOwner, isLoading]);

  const handleChange = async (value) => {
    setIsloading(true);
    await onChange({ owner_id: value });
    setIsloading(false);
  };

  return (
    <DropdownForm
      name="stage_id"
      selection={false}
      trigger={triggerDropdown}
      extraClassnames="OwnerDealForm"
      items={members}
      onChange={(_, { value }) => handleChange(value)}
      value={currentOwner.name}
      loading={isLoading}
      size="tiny"
    />
  );
}

export default OwnerDealForm;
