import React from "react";
import Button from "components_new/atoms/Button";
import "./SnapshotFormSubmit.scss";

const SnapshotFormSubmit = ({
  onSubmit,
  isLoading,
  submitTextButton,
  onClose,
}) => {
  return (
    <div className="SnapshotFormSubmit-footer">
      <Button
        onClick={onClose}
        type="button"
        secondary
        size="small"
        disabled={isLoading}
      >
        Cancel
      </Button>
      <Button
        primary
        onClick={onSubmit}
        loading={isLoading}
        type="submit"
        size="small"
      >
        {submitTextButton}
      </Button>
    </div>
  );
};

export default SnapshotFormSubmit;
