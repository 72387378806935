import React, { useEffect, useRef, useState } from "react";
import Button from "components_new/atoms/Button";
import Icon from "components_new/atoms/Icon";
import "./Carousel.scss";

function Carousel({ children }) {
  const carouselContentRef = useRef(null);
  const [itemWidth, setItemWidth] = useState(0);
  const [itemsShown, setItemsShown] = useState(0);
  const [totalOfItems, setTotalOfItems] = useState(0);
  const [positionLeft, setPositionLeft] = useState(0);
  const [movedItems, setMovedItems] = useState(0);

  useEffect(() => {
    carouselContentRef.current.scrollLeft = positionLeft;
  }, [positionLeft]);

  useEffect(() => {
    if (carouselContentRef.current) {
      const itemStyle =
        carouselContentRef.current.firstChild.currentStyle ||
        window.getComputedStyle(carouselContentRef.current.firstChild);
      const itemMargins =
        parseFloat(itemStyle.marginLeft) + parseFloat(itemStyle.marginRight);
      const itemWidth = carouselContentRef.current.firstChild.offsetWidth;
      const currentViewport = carouselContentRef.current.offsetWidth;
      setItemWidth(itemWidth + itemMargins);
      setTotalOfItems(children.length);
      setItemsShown(Math.floor(currentViewport / itemWidth));
    }
  }, [carouselContentRef, children.length]);

  function moveLeft() {
    setPositionLeft((prevState) => prevState - itemWidth);
    setMovedItems((prevState) => prevState - 1);
  }

  function moveRight() {
    setPositionLeft((prevState) => prevState + itemWidth);
    setMovedItems((prevState) => prevState + 1);
  }

  return (
    <div className="Carousel">
      <Button
        aria-label="Move Left"
        disabled={movedItems === 0}
        onClick={() => moveLeft()}
      >
        <Icon icon="ri-arrow-left-s-line" />
      </Button>
      <div className="Carousel-content" ref={carouselContentRef}>
        {children}
      </div>
      <Button
        aria-label="Move Right"
        disabled={movedItems === totalOfItems - itemsShown}
        onClick={() => moveRight()}
      >
        <Icon icon="ri-arrow-right-s-line" />
      </Button>
    </div>
  );
}

export default Carousel;
