import React, { useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isSubmitting } from "store/domain/selectors";
import AccountInformationForm from "./AccountInformationForm";
import { openInModal, ModalNames, closeModal } from "store/modal";
import AuthenticationContext from "components/Mighty/AuthenticationContext";
import { updateUserAccountInformation } from "store/users/actions";
import useCurrentUser from "hooks/useCurrentUser";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import getMessage from "components_new/lib/Messages";

function AccountInformationFormContainer(props) {
  const dispatch = useDispatch();
  const { addToast } = useSetToast();
  const isSubmittingInfo = useSelector((state) =>
    isSubmitting(state, "updateUserAccountInformation"),
  );
  const currentUser = useCurrentUser();
  const { setCurrentUser } = useContext(AuthenticationContext);

  const updateUserAccountInformationCallback = useCallback(
    (id, user) => {
      return dispatch(updateUserAccountInformation(id, user)).then(
        ({ user }) => {
          setCurrentUser(user);
          addToast(getMessage("updateUserInformation"));
          dispatch(closeModal());
        },
      );
    },
    [dispatch, setCurrentUser, addToast],
  );

  const openAvatarFilePickerModal = useCallback(() => {
    dispatch(openInModal(ModalNames.avatarFilePicker));
  }, [dispatch]);

  return (
    <AccountInformationForm
      isSubmitting={isSubmittingInfo}
      user={currentUser}
      updateUserAccountInformation={updateUserAccountInformationCallback}
      openAvatarFilePickerModal={openAvatarFilePickerModal}
      {...props}
    />
  );
}

export default AccountInformationFormContainer;
