import React from "react";
import NavigationTabs from "components/shared/NavigationTabs";
import { organizationProptype } from "PropTypes";
import { Outlet } from "react-router-dom";

function UserSettingsView() {
  const userSettingsTabs = [
    { groupHeader: "personal settings" },
    {
      url: "/personal-settings/account-information",
      children: <div> Account Information </div>,
    },
    {
      url: "/personal-settings/login-information",
      children: <div> Login Information </div>,
    },
    {
      url: "/personal-settings/notifications",
      children: <div> Notifications </div>,
    },
    {
      url: "/personal-settings/organizations",
      children: <div> Organizations </div>,
    },
  ];

  return (
    <div className="UserSettingsView u-row">
      <div className="UserSettingsView-NavigationTabs">
        <NavigationTabs orientation="vertical" items={userSettingsTabs} />
      </div>
      <div className="UserSettingsView-content Container">
        <Outlet />
      </div>
    </div>
  );
}

UserSettingsView.propTypes = {
  currentOrganization: organizationProptype,
};

export default UserSettingsView;
