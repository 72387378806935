import React, { useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import Input from "components_new/atoms/Input";
import Icon from "components_new/atoms/Icon";

function SearchInput({
  onChange,
  value,
  onKeyDown,
  selectedItem,
  setValue,
  placeHolder,
  onItemChange,
  isFetching,
  open,
  name,
  disabled,
  readOnly,
  allowCreation,
  onChangeOnCreation,
  size,
}) {
  const searchIconSize = size === "tiny" ? "mini" : "small";
  const searchInputClassname = classNames("SearchInput", {
    "has-results": open,
    "is-fetching": open && isFetching,
    [size]: !!size,
  });

  function filledPreviewAndUpdateForm(item) {
    if (!isEmpty(item)) {
      const newValue = item.title || item.name;
      setValue(newValue);
    }
    if (onItemChange) {
      onItemChange(name, item);
    }
  }

  useEffect(
    () => {
      filledPreviewAndUpdateForm(selectedItem);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedItem],
  );

  const handleChange = (value) => {
    if (allowCreation) {
      onChangeOnCreation(value);
    }
    onChange(value);
  };

  return (
    <div className={searchInputClassname}>
      <Input
        name={name}
        value={value}
        onChange={(event) => handleChange(event.target.value)}
        onKeyDown={onKeyDown}
        placeholder={placeHolder}
        autoComplete="off"
        disabled={disabled}
        readOnly={readOnly}
        icon={<Icon icon="ri-search-line" size={searchIconSize} />}
        size={size}
        loading={isFetching}
      />
    </div>
  );
}

SearchInput.defaultProps = {
  disabled: false,
  readOnly: false,
  allowCreation: false,
  onChangeOnCreation: () => {},
};

SearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  onKeyDown: PropTypes.func.isRequired,
  selectedItem: PropTypes.shape({}),
  setValue: PropTypes.func.isRequired,
  placeHolder: PropTypes.string.isRequired,
  onItemChange: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  allowCreation: PropTypes.bool.isRequired,
  onChangeOnCreation: PropTypes.func.isRequired,
  size: PropTypes.string,
};

export default SearchInput;
