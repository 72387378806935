import React, { Fragment } from "react";
import CollapsableSection from "components_new/elements/CollapsableSection";
import DiscussionsList from "components_new/elements/Discussions/DiscussionsList";
import "./ContactDiscussions.scss";

function ContactDiscussions({
  organizationUsername,
  currentUser,
  users,
  posts,
  resource,
  isSubmitting,
  createPost,
  updatePost,
  removePost,
  openDocumentsUploader,
}) {
  function showtitle() {
    return (
      <Fragment>
        Notes <small>({posts.length})</small>
      </Fragment>
    );
  }

  return (
    <CollapsableSection expanded title={showtitle()}>
      <DiscussionsList
        extraClassnames="ContactDiscussions"
        organizationUsername={organizationUsername}
        currentUser={currentUser}
        users={users}
        posts={posts}
        resource={resource}
        isSubmitting={isSubmitting}
        createPost={createPost}
        updatePost={updatePost}
        removePost={removePost}
        openDocumentsUploader={openDocumentsUploader}
        showResource={false}
      />
    </CollapsableSection>
  );
}

export default ContactDiscussions;
