import InvestmentForm from "./forms/InvestmentForm";
import FilesForm from "./forms/FilesForm";
import ValuationForm from "./forms/ValuationForm";
import CapitalCallForm from "./forms/CapitalCallForm";
import WriteOffForm from "./forms/WriteOffForm";
import StockSplitForm from "./forms/StockSplitForm";
import LogConversionForm from "./forms/LogConversionForm";
import PortfolioForm from "./forms/PortfolioForm/";
import DistributionForm from "./forms/DistributionForm";
import AddCompanyForm from "./forms/AddCompanyForm";
import GlobalCompanyForm from "./forms/GlobalCompanyForm";
import SaleForm from "./forms/SaleForm";
import LogAcquisitionForm from "./forms/LogAcquisitionForm";
import AddContactForm from "./forms/AddContactForm";
import EditContactForm from "./forms/EditContactForm";
import OrganizationForm from "./forms/OrganizationForm";
import BlankForm from "./forms/BlankForm";
import ContactForm from "./forms/ContactForm";
import IntroductionForm from "./forms/IntroductionForm";
import CoInvestmentForm from "./forms/CoInvestmentForm";

export default {
  portfolioForm: PortfolioForm,
  investmentForm: InvestmentForm,
  filesForm: FilesForm,
  valuationForm: ValuationForm,
  capitalCallForm: CapitalCallForm,
  writeOffForm: WriteOffForm,
  stockSplitForm: StockSplitForm,
  logConversionForm: LogConversionForm,
  distributionForm: DistributionForm,
  addCompanyForm: AddCompanyForm,
  globalCompanyForm: GlobalCompanyForm,
  logSaleForm: SaleForm,
  logAcquisitionForm: LogAcquisitionForm,
  addContactForm: AddContactForm,
  editContactForm: EditContactForm,
  organizationForm: OrganizationForm,
  editInvestmentForm: InvestmentForm,
  contactForm: ContactForm,
  introductionForm: IntroductionForm,
  coInvestmentForm: CoInvestmentForm,
  /**
   * for storybook
   */
  blankForm: BlankForm,
  blankForm2: BlankForm,
};
