import React, { useContext } from "react";
import ContactRoles from "./ContactRoles";
import useContacts from "store/contacts/hooks";
import { SidebarComponentsContext } from "components_new/SidebarComponents/SidebarComponentsWrapper";

function ContactRolesWrapper(props) {
  const { titleSuggestions, filterTitles } = useContacts();
  const sidebarProperties = useContext(SidebarComponentsContext);

  return (
    <ContactRoles
      titleSuggestions={titleSuggestions}
      filterTitles={filterTitles}
      mountSidebarComponent={sidebarProperties?.mountSidebarComponent}
      hideSidebarComponent={sidebarProperties?.hideSidebarComponent}
      {...props}
    />
  );
}

export default ContactRolesWrapper;
