import React from "react";
import Header from "components_new/atoms/Header";
import EmptyCard from "components_new/elements/EmptyCard";
import CompanyInvestorRow from "./CompanyInvestorRow";
import Button from "components_new/atoms/Button";
import Icon from "components_new/atoms/Icon";
import Table, {
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "components_new/elements/Table";
import "./CompanyInvestorsTable.scss";

function CompanyInvestorsTable({
  currentOrganizationUsername,
  companyCoInvestments,
  openAddCoInvestmentForm,
  openEditCoInvestmentForm,
  openDeleteCoInvestment,
}) {
  return (
    <div className="CompanyInvestorsTable">
      <div className="CompanyInvestorsTable-title">
        <Header as="h5">Investors</Header>
        <Button secondary size="small" onClick={openAddCoInvestmentForm}>
          <Icon icon="ri-add-line" /> Add Investor
        </Button>
      </div>

      {companyCoInvestments.length === 0 ? (
        <EmptyCard title="Investors" />
      ) : (
        <Table singleLine>
          <TableHeader className="CompanyInvestorsHeader">
            <TableRow>
              <TableHeaderCell>Company</TableHeaderCell>
              <TableHeaderCell>Round</TableHeaderCell>
              <TableHeaderCell>Tags</TableHeaderCell>
              <TableHeaderCell></TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {companyCoInvestments.map((coInvestment, index) => (
              <CompanyInvestorRow
                key={index}
                coInvestment={coInvestment}
                openEditCoInvestmentForm={openEditCoInvestmentForm}
                openDeleteCoInvestment={openDeleteCoInvestment}
                organizationUsername={currentOrganizationUsername}
              />
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
}

export default CompanyInvestorsTable;
