import React, { useState } from "react";

import { useParams } from "react-router-dom";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";

import getMessage from "components_new/lib/Messages";
import Loader from "components/shared/Loader";
import CommitToDeal from "./CommitToDeal";
import useModal from "store/modalV2/hooks";

import { useDeal } from "components_new/views/Deals/hooks/queries/useDeal";
import { useCommitToDeal } from "components_new/views/Deals/hooks/mutations/useCommitToDeal";
import { useUpdateDeal } from "components_new/views/Deals/hooks/mutations/useUpdateDeal";

function CommitToDealWrapper({ dealId, pipelineId, callback }) {
  const [submitting, setSubmitting] = useState(false);
  const { organizationUsername } = useParams();
  const { closeModal } = useModal();
  const { addToast } = useSetToast();

  const { data, isLoading } = useDeal(pipelineId, dealId);
  const { mutateAsync: commitToDeal } = useCommitToDeal();
  const { mutateAsync: updateDeal } = useUpdateDeal();

  const handleCommitToDeal = async (formData) => {
    setSubmitting(true);
    try {
      await commitToDeal({ formData, pipelineId, dealId });

      addToast(getMessage("commitToDeal"));
      closeModal();

      callback?.();
    } finally {
      setSubmitting(false);
    }
  };

  const handleEditCommitment = async (formData) => {
    setSubmitting(true);

    try {
      await updateDeal({ formData, pipelineId, dealId });

      addToast(getMessage("editCommitment"));
      closeModal();

      callback?.();
    } finally {
      setSubmitting(false);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <CommitToDeal
      deal={data?.deal}
      company={data?.company}
      isEditing={data?.deal.status === "committed"}
      isSubmitting={submitting}
      commitToDeal={handleCommitToDeal}
      editCommitment={handleEditCommitment}
      currentOrganizationUsername={organizationUsername}
      closeModal={closeModal}
    />
  );
}

export default CommitToDealWrapper;
