import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import Raven from "raven-js";
import createRavenMiddleware from "raven-for-redux";
import pick from "lodash/pick";
import rootReducer from "reducers";

function createMiddleware() {
  return applyMiddleware(
    thunk,
    createRavenMiddleware(Raven, {
      stateTransformer: (state) => pick(state, ["auth", "config"]),
    }),
  );
}

function createEnhancer() {
  window.devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
  const devToolsEnhancer = window.devToolsExtension
    ? window.devToolsExtension()
    : (f) => f;
  return compose(createMiddleware(), devToolsEnhancer);
}

export default (config) => {
  const initialState = { config };
  return createStore(rootReducer, initialState, createEnhancer());
};
