import Api from "lib/Api";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { updateTaskInScope } from "../shared/updateTaskInScope";

export function useUpdateTask() {
  const queryClient = useQueryClient();
  const { organizationUsername } = useParams();

  return useMutation(
    ({ taskId, formData }) =>
      Api.patch(`/tasks/${taskId}`, {
        task: formData,
      }),
    {
      onSuccess: ({ data: updatedTask }, { formData }) => {
        const params = {
          updatedTask,
          queryClient,
          organizationUsername,
        };
        const { selection } = formData;

        updateTaskInScope({ scope: "pending", ...params });
        updateTaskInScope({ scope: "snoozed", ...params });
        updateTaskInScope({ scope: "completed", ...params });

        queryClient.invalidateQueries(["QueuesView", organizationUsername]);
        if (selection?.type == "deal") {
          updateTaskInDealView(queryClient, selection.id, updatedTask);
        }
      },
      onError: (error) => {
        throw error;
      },
    },
  );
}

function updateTaskInDealView(queryClient, dealId, updatedTask) {
  const key = ["Deal", dealId];
  const dealView = queryClient.getQueryData(key);

  if (dealView?.deal) {
    queryClient.setQueryData(key, {
      ...dealView,
      deal: {
        ...dealView.deal,
        tasks: dealView.deal.tasks.map((task) =>
          task.id === updatedTask.id ? updatedTask : task,
        ),
      },
    });
  }
}
