import React from "react";
import PropTypes from "prop-types";
import { Form } from "@ableco/semantic-ui-react";
import Checkbox from "components_new/atoms/Checkbox";
import "./ClearableRadioGroup.scss";

function ClearableRadioGroup({
  radioButtonInfo,
  handleChange,
  disabled,
  selected,
}) {
  return (
    radioButtonInfo &&
    radioButtonInfo.map((info) => (
      <Form.Field key={info.value} className="ClearableRadioGroup-item">
        <Checkbox
          radio
          label={info.label}
          value={info.value}
          checked={info.value === selected}
          disabled={disabled}
          onChange={handleChange}
        />
      </Form.Field>
    ))
  );
}

export default ClearableRadioGroup;

ClearableRadioGroup.defaultProps = {
  disabled: false,
};

ClearableRadioGroup.propTypes = {
  radioButtonInfo: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
