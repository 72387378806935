import React, { useState, useEffect } from "react";
import Checkbox from "components_new/atoms/Checkbox";
import Due from "components_new/elements/Due";
import UserAvatar from "components_new/elements/UserAvatar";
import classNames from "classnames";
import "./Task.scss";

function Task({ task, onComplete, onClick, undoComplete }) {
  const [isCompleted, setIsCompleted] = useState(false);
  const [isAnimated, setIsAnimated] = useState(false);
  const taskClassnames = classNames("Task", {
    completed: isCompleted,
    animated: isAnimated,
  });

  useEffect(() => {
    if (!!task.completed_at && !isCompleted) {
      setIsCompleted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAction = () => {
    setIsCompleted((prevState) => !prevState);
    if (isCompleted) {
      undoComplete(task.id);
    } else {
      setIsAnimated(true);
      onComplete(task.id);
    }
  };

  return (
    <div className={taskClassnames}>
      <Checkbox
        name="task"
        checked={isCompleted}
        className="Task-checkbox"
        onChange={() => {
          handleAction();
        }}
      />
      <div className="Task-content">
        {task.subject && (
          <span
            className="Task-title u-bodySmallBold"
            onClick={() => onClick(task)}
          >
            {task.subject}
          </span>
        )}
        {task.due_date && <Due date={task.due_date} dateType="detailed" />}
      </div>
      {task.owner_initials && (
        <div className="Task-owner">
          <UserAvatar
            avatarColor={task.owner_avatar_color}
            initials={task.owner_initials}
            showTooltip={false}
            size="small"
          />
        </div>
      )}
    </div>
  );
}

export default Task;
