import React, { useState } from "react";
import PropTypes from "prop-types";
import { userPropType, currentUserPropType } from "PropTypes";
import classNames from "classnames";
import UserAvatar from "components_new/elements/UserAvatar";
import BaseConfirmDialog from "components_new/elements/BaseConfirmDialog";
import EditPost from "./Post/Edit";
import ViewPost from "./Post/View";
import DiscussionActions from "./DiscussionActions";
import RepliesSection from "./RepliesSection";
import RepliesSummary from "./RepliesSummary";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import getMessage from "components_new/lib/Messages";

function resourceVisibility(showResource, resource) {
  return showResource && ["Deal", "Investment"].includes(resource.type);
}

function ViewDiscussion({
  user,
  users,
  post,
  editAction,
  deleteAction,
  openDocumentsUploader,
  openContactDetailsView,
  currentUser,
  organizationUsername,
  replyAction,
  size,
  isInlineAttachment,
  isAvailableToReply,
  showResource,
  showDiligenceContact,
  isHighlighted,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showReplies, setShowReplies] = useState(false);
  const [focusEditor, setFocusEditor] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const viewPostClassnames = classNames("Discussion-postContent", {
    isEditing: isEditing,
    isDeleting: isDeleting,
    isShowingThread: showReplies,
    isHighlighted: isHighlighted,
  });

  const updatePost = (content) => {
    setIsSubmitting(true);
    editAction(post.resource, post.id, content)
      .then(() => {
        setIsEditing(false);
        setFocusEditor(false);
        addToast(getMessage("updateNotes"));
      })
      .finally(() => setIsSubmitting(false));
  };

  const { addToast } = useSetToast();
  const deletePost = () => {
    setIsSubmitting(true);
    deleteAction(post.resource, post.id, post.parent_post_id)
      .then(() => {
        addToast(getMessage("deleteNote"));
        setIsDeleting(false);
      })
      .finally(() => setIsSubmitting(false));
  };

  const replyFromActions = () => {
    setShowReplies(true);
    setFocusEditor(true);
  };

  const onCancelFromReplyForm = () => {
    setShowReplies(false);
    setFocusEditor(false);
  };

  return (
    <li>
      <section className={viewPostClassnames}>
        {isDeleting && (
          <BaseConfirmDialog
            title="Delete this note? You can’t undo this action."
            cancelButtonText="Delete Note"
            onConfirm={deletePost}
            onDiscard={() => setIsDeleting(false)}
            actionType="danger"
          />
        )}
        <div className="PostSection">
          <UserAvatar
            avatarColor={user.avatar_color}
            avatarUrl={user.avatar_url}
            initials={user.initials}
            showTooltip={false}
            size="small"
          />
          {isEditing ? (
            <EditPost
              post={post}
              user={user}
              placeholder="Add a note"
              onSubmit={updatePost}
              onCancel={() => setIsEditing(false)}
              openDocumentsUploader={openDocumentsUploader}
              isSubmitting={isSubmitting}
              isInlineAttachment={isInlineAttachment}
              isAvailableToReply={isAvailableToReply}
              size={size}
            />
          ) : (
            <ViewPost
              post={post}
              user={user}
              showResource={resourceVisibility(showResource, post.resource)}
              organizationUsername={organizationUsername}
              showDiligenceContact={showDiligenceContact}
              openContactDetailsView={openContactDetailsView}
            />
          )}
        </div>
        <DiscussionActions
          authorId={post.author_id}
          isAvailableToReply={isAvailableToReply}
          currentUser={currentUser}
          onEdit={setIsEditing}
          onDelete={setIsDeleting}
          showForm={replyFromActions}
          hasReplies={post.replies?.length}
          postId={post.id}
          parentId={post.parent_post_id}
        />
        {post.replies?.length > 0 && !showReplies && (
          <RepliesSummary
            replies={post.replies}
            users={users}
            onClick={() => setShowReplies(true)}
          />
        )}
        {showReplies && (
          <RepliesSection
            users={users}
            replies={post.replies}
            resource={post.resource}
            replyAction={replyAction}
            editAction={editAction}
            deleteAction={deleteAction}
            currentUser={currentUser}
            isSubmitting={isSubmitting}
            openDocumentsUploader={openDocumentsUploader}
            parentId={post.id}
            focusEditor={focusEditor}
            onCancel={onCancelFromReplyForm}
            organizationUsername={organizationUsername}
            isInlineAttachment={isInlineAttachment}
            size={size}
          />
        )}
      </section>
    </li>
  );
}

ViewDiscussion.propTypes = {
  user: userPropType,
  users: PropTypes.shape({ userPropType }),
  post: PropTypes.shape({
    id: PropTypes.number,
    content: PropTypes.string,
    date: PropTypes.string,
    deal_id: PropTypes.string,
    author_id: PropTypes.number,
    resource: PropTypes.shape({}),
    parent_post_id: PropTypes.number,
    replies: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  editAction: PropTypes.func.isRequired,
  deleteAction: PropTypes.func.isRequired,
  openDocumentsUploader: PropTypes.func.isRequired,
  currentUser: currentUserPropType,
  organizationUsername: PropTypes.string,
  isAvailableToReply: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  showResource: PropTypes.bool,
  replyAction: PropTypes.func,
  isInlineAttachment: PropTypes.bool,
  size: PropTypes.string,
  showDiligenceContact: PropTypes.bool,
  openContactDetailsView: PropTypes.func,
  isHighlighted: PropTypes.bool,
};

ViewDiscussion.defaultProps = {
  isAvailableToReply: false,
  isInlineAttachment: false,
  showResource: false,
  isHighlighted: false,
};

export default ViewDiscussion;
