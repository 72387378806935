import formatPrice from "lib/formatters/formatPrice";
import formatNumber from "lib/formatters/formatNumber";
import { toUpper } from "lodash";
import numeral from "numeral";

const abbreviatedPriceFormat = ["$1a", "$1.0a", "$1.00a"];
const abbreviatedNumberFormat = "0.00 a";
const initialLimitForMillion = 99999;
const finalLimitForMillion = 999999;

function shouldAbbreviate(value, startingFrom) {
  return value > startingFrom || value < startingFrom * -1;
}

function shouldAbbreviateToMillion(value) {
  return value > initialLimitForMillion && value < finalLimitForMillion;
}

export function formatPriceInputPreview(
  value,
  options = {},
  startingFrom = initialLimitForMillion,
  decimalPlacesAbbreviation = 2,
) {
  if (!shouldAbbreviate(value, startingFrom)) {
    return formatPrice(value, options);
  }

  return toUpper(
    numeral(value).format(abbreviatedPriceFormat[decimalPlacesAbbreviation]),
  );
}

export function formatPriceInputAbbreviated(
  value,
  { precision, convertToMillion = false, noTrailingZeros = false },
) {
  if (convertToMillion && shouldAbbreviateToMillion(value)) {
    return (
      formatPrice(value / 1000000, {
        precision: 1,
      }) + "M"
    );
  }
  const abbreviatedValue = shouldAbbreviate(value, initialLimitForMillion)
    ? formatPrice(value, { precision })
    : value;

  const formattedPrice = toUpper(
    numeral(abbreviatedValue).format(abbreviatedPriceFormat[precision]),
  );
  if (
    !noTrailingZeros ||
    precision === 0 ||
    !formattedPrice.match(/\.\d*0(?=[a-z]$)/i) // has decimal and last decimal is a zero
  ) {
    return formattedPrice;
  }
  const formattedPriceNoTrailingZeros = formattedPrice
    .replace(/0+(?=[a-z]$)/i, "") // remove trailing zeros
    .replace(/\.(?=[a-z]$)/i, ""); // remove empty decimal
  return formattedPriceNoTrailingZeros;
}

export function formatNumberInputPreview(value) {
  if (!shouldAbbreviate(value)) {
    return formatNumber(value);
  }

  return numeral(value).format(abbreviatedNumberFormat);
}
