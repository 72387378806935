import React from "react";
import PropTypes from "prop-types";
import { investmentDataPropType, companyAvatarPropType } from "PropTypes";
import EllipsisDropdown from "components_new/elements/EllipsisDropdown";

function InvestmentActionsDropdown({
  investment,
  company,
  openValuationSidebarForm,
  openLogAcquisitionSidebarForm,
  openEditInvestmentSidebarForm,
  openChangePortfolioOfInvestmentModal,
  openDeleteInvestmentModal,
  openLogSaleSidebarForm,
  openGlobalCapitalCallSidebarForm,
  openLogWriteOffSidebarForm,
  openLogStockSplitSidebarForm,
  openLogDistributionSidebarForm,
  openLogConversionSidebarForm,
  /**
   * remove me after deprecation of modal forms
   */
  initialComponent = null,
}) {
  const defaultActions = [
    !initialComponent && {
      key: "0",
      text: "Edit Investment",
      dividerTop: true,
      disabled: !!initialComponent,
      onClick: () => openEditInvestmentSidebarForm(investment),
    },
    {
      key: "1",
      text: "Change Portfolio",
      disabled: investment.previous_holding || !!initialComponent,
      onClick: () => openChangePortfolioOfInvestmentModal(investment),
    },
    {
      key: "2",
      text: "Delete Investment",
      disabled:
        !investment.can_be_deleted ||
        investment.previous_holding ||
        !!initialComponent,
      onClick: () => openDeleteInvestmentModal(investment),
    },
  ];
  const investmentActionsByType = {
    debt: [
      {
        key: "4",
        text: "Convert Loan",
        disabled: investment.previous_holding || !!initialComponent,
        onClick: () => openLogConversionSidebarForm(investment),
      },
      {
        key: "5",
        text: "Update Valuation",
        disabled: investment.previous_holding || !!initialComponent,
        onClick: () => openValuationSidebarForm({ investment, company }),
      },
      {
        key: "6",
        text: "Log Distribution",
        disabled: investment.previous_holding,
        onClick: () => openLogDistributionSidebarForm(investment),
      },
      {
        key: "7",
        text: "Log Acquisition",
        disabled: investment.previous_holding,
        onClick: () => openLogAcquisitionSidebarForm(investment),
      },
      {
        key: "8",
        text: "Log Write-Off",
        disabled: investment.previous_holding,
        onClick: () => openLogWriteOffSidebarForm(investment),
      },
      ...defaultActions,
    ],
    equity: [
      {
        key: "4",
        text: "Update Valuation",
        disabled: investment.previous_holding || !!initialComponent,
        onClick: () => openValuationSidebarForm({ investment, company }),
      },
      {
        key: "5",
        text: "Log Distribution",
        disabled: investment.previous_holding,
        onClick: () => openLogDistributionSidebarForm(investment),
      },
      {
        key: "6",
        text: "Log Sale",
        disabled: investment.previous_holding,
        onClick: () => openLogSaleSidebarForm(investment),
      },
      {
        key: "7",
        text: "Log Stock Split",
        disabled: investment.previous_holding,
        onClick: () => openLogStockSplitSidebarForm(investment),
      },
      {
        key: "8",
        text: "Log Acquisition",
        disabled: investment.previous_holding,
        onClick: () => openLogAcquisitionSidebarForm(investment),
      },
      {
        key: "9",
        text: "Log Write-Off",
        disabled: investment.previous_holding,
        onClick: () => openLogWriteOffSidebarForm(investment),
      },
      ...defaultActions,
    ],
    warrant: [
      {
        key: "4",
        text: "Update Valuation",
        disabled: investment.previous_holding || !!initialComponent,
        onClick: () => openValuationSidebarForm({ investment, company }),
      },
      {
        key: "5",
        text: "Log Distribution",
        disabled: investment.previous_holding,
        onClick: () => openLogDistributionSidebarForm(investment),
      },
      {
        key: "6",
        text: "Log Sale",
        disabled: investment.previous_holding,
        onClick: () => openLogSaleSidebarForm(investment),
      },
      {
        key: "12",
        text: "Log Stock Split ",
        disabled: investment.previous_holding,
        onClick: () => openLogStockSplitSidebarForm(investment),
      },
      {
        key: "8",
        text: "Log Write-Off",
        disabled: investment.previous_holding,
        onClick: () => openLogWriteOffSidebarForm(investment),
      },
      ...defaultActions,
    ],
    option: [
      {
        key: "4",
        text: "Update Valuation",
        disabled: investment.previous_holding || !!initialComponent,
        onClick: () => openValuationSidebarForm({ investment, company }),
      },
      {
        key: "5",
        text: "Log Distribution",
        disabled: investment.previous_holding,
        onClick: () => openLogDistributionSidebarForm(investment),
      },
      {
        key: "6",
        text: "Log Sale",
        disabled: investment.previous_holding,
        onClick: () => openLogSaleSidebarForm(investment),
      },
      {
        key: "7",
        text: "Log Stock Split",
        disabled: investment.previous_holding || !!initialComponent,
        onClick: () => openLogStockSplitSidebarForm(investment),
      },
      {
        key: "8",
        text: "Log Write-Off",
        disabled: investment.previous_holding,
        onClick: () => openLogWriteOffSidebarForm(investment),
      },
      ...defaultActions,
    ],
    fund: [
      {
        key: "4",
        text: "Add Capital Call",
        disabled: investment.previous_holding,
        onClick: () =>
          openGlobalCapitalCallSidebarForm({ investment, company }),
      },
      {
        key: "5",
        text: "Update Valuation",
        disabled: investment.previous_holding || !!initialComponent,
        onClick: () => openValuationSidebarForm({ investment, company }),
      },
      {
        key: "6",
        text: "Log Distribution",
        disabled: investment.previous_holding,
        onClick: () => openLogDistributionSidebarForm(investment),
      },
      {
        key: "7",
        text: "Log Write-Off",
        disabled: investment.previous_holding,
        onClick: () => openLogWriteOffSidebarForm(investment),
      },
      ...defaultActions,
    ],
    llc: [
      {
        key: "4",
        text: "Update Valuation",
        disabled: investment.previous_holding || !!initialComponent,
        onClick: () => openValuationSidebarForm({ investment, company }),
      },
      {
        key: "5",
        text: "Log Distribution",
        disabled: investment.previous_holding,
        onClick: () => openLogDistributionSidebarForm(investment),
      },
      {
        key: "6",
        text: "Log Write-Off",
        disabled: investment.previous_holding,
        onClick: () => openLogWriteOffSidebarForm(investment),
      },
      ...defaultActions,
    ],
  };

  return (
    <EllipsisDropdown
      iconOrientation="vertical"
      iconSize="tiny"
      options={investmentActionsByType[investment.type].filter((item) => item)}
    />
  );
}

InvestmentActionsDropdown.propTypes = {
  investment: investmentDataPropType.isRequired,
  company: companyAvatarPropType,
  openValuationSidebarForm: PropTypes.func.isRequired,
  openLogAcquisitionSidebarForm: PropTypes.func.isRequired,
  openLogWriteOffSidebarForm: PropTypes.func.isRequired,
  openEditInvestmentSidebarForm: PropTypes.func.isRequired,
  openChangePortfolioOfInvestmentModal: PropTypes.func.isRequired,
  openDeleteInvestmentModal: PropTypes.func.isRequired,
  openLogSaleSidebarForm: PropTypes.func.isRequired,
  openGlobalCapitalCallSidebarForm: PropTypes.func.isRequired,
  openLogStockSplitSidebarForm: PropTypes.func.isRequired,
  openLogDistributionSidebarForm: PropTypes.func.isRequired,
  openLogConversionSidebarForm: PropTypes.func.isRequired,
  /**
   * remove me after deprecation of modal forms
   */
  initialComponent: PropTypes.string,
};

export default InvestmentActionsDropdown;
