import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { isEmpty, isEqual } from "lodash";
import {
  historyActionProptype,
  companiesObjectPropType,
  errorsPropType,
} from "PropTypes";
import { createCellClassNames, createCertificateActions } from "../config";
import TableRowContent from "./TableRowContent";
import Dropdown from "components/shared/Dropdown";
import DotsIcon from "-!svg-react-loader?!assets/icons/utility/dots.svg?name=DotsIcon";

class TableRow extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.isOpen ||
      this.props.isOpen !== nextProps.isOpen ||
      this.props.isSubmitting !== nextProps.isSubmitting ||
      !isEqual(this.props.historyAction, nextProps.historyAction)
    );
  }

  componentDidUpdate() {
    const {
      actionType,
      isOpen,
      initializeFormData,
      historyAction,
      formData,
    } = this.props;
    if (actionType === "edit" && isOpen && isEmpty(formData)) {
      const newFormData = {
        ...historyAction.action_params,
        amount_per_quantity_or_total: "per_share",
        actionType: historyAction.type,
      };
      initializeFormData(newFormData);
    }
  }

  render() {
    const {
      historyAction,
      columns,
      isOpen,
      showEvent,
      actionType,
      companies,
      openDeleteCertificateModal,
      updateForm,
      sendForm,
      isSubmitting,
      formData,
      lastAction,
      rowIndex,
      errors,
      organizationUsername,
    } = this.props;
    const tableRowClassNames = classNames("CertificateHistoryTable--Row", {
      "is-Open": isOpen,
    });

    return (
      <div className={tableRowClassNames} data-testid="CertificateHistoryRow">
        <div className="CertificateHistoryTable--Row-Cells">
          {columns.map((columProps, columnIndex) => {
            return (
              <div
                key={columnIndex}
                className={createCellClassNames(
                  columProps,
                  "CertificateHistoryTable--Cell",
                )}
              >
                {columProps.renderValue(
                  historyAction,
                  isSubmitting,
                  lastAction,
                )}
              </div>
            );
          })}
          <div
            className="CertificateHistoryTable--Row-Actions"
            data-testid="CertificateHistoryMenu"
          >
            <Dropdown
              items={createCertificateActions(
                historyAction,
                showEvent,
                openDeleteCertificateModal,
              )}
            >
              <DotsIcon className="Button-DotsIcon" />
            </Dropdown>
          </div>
        </div>
        {actionType !== null && isOpen && (
          <TableRowContent
            historyAction={historyAction}
            actionType={actionType}
            companies={companies}
            updateForm={updateForm}
            showEvent={showEvent}
            sendForm={sendForm}
            formData={formData}
            isSubmitting={isSubmitting}
            rowIndex={rowIndex}
            errors={errors}
            organizationUsername={organizationUsername}
          />
        )}
      </div>
    );
  }
}

TableRow.propTypes = {
  historyAction: historyActionProptype,
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  isOpen: PropTypes.bool,
  showEvent: PropTypes.func,
  actionType: PropTypes.oneOf(["view", "edit", "delete"]),
  companies: companiesObjectPropType,
  initializeFormData: PropTypes.func,
  openDeleteCertificateModal: PropTypes.func,
  updateForm: PropTypes.func,
  sendForm: PropTypes.func,
  isSubmitting: PropTypes.bool,
  formData: PropTypes.shape({}),
  lastAction: PropTypes.shape({}),
  rowIndex: PropTypes.number,
  errors: errorsPropType,
  organizationUsername: PropTypes.string.isRequired,
};

export default TableRow;
