import React from "react";
import Skeleton, {
  SkeletonHeader,
  SkeletonLine,
  SkeletonImage,
} from "components_new/atoms/Skeleton";
import Segment from "components_new/atoms/Segment";
import classnames from "classnames";

function SkeletonSegment({ image, linesQuantity = 1, className }) {
  const skeletonSegmentClassnames = classnames("SkeletonSegment", {
    [`${className}`]: !!className,
  });
  const skeletonContent = image
    ? [
        <Skeleton key={0}>
          <SkeletonImage />
        </Skeleton>,
      ]
    : [];

  for (let i = 0; i < linesQuantity; i++) {
    skeletonContent.push(
      <Skeleton key={i + 1}>
        <SkeletonHeader>
          <SkeletonLine length="full" />
        </SkeletonHeader>
      </Skeleton>,
    );
  }

  return (
    <Segment basic className={skeletonSegmentClassnames}>
      {skeletonContent}
    </Segment>
  );
}

export const skeletonRowContent = {
  companyName: (
    <SkeletonSegment image linesQuantity={2} className="company-skeleton" />
  ),
  name: <SkeletonSegment linesQuantity={2} className="name-skeleton" />,
  investments_count: (
    <SkeletonSegment linesQuantity={2} className="name-skeleton" />
  ),
  transaction_name: (
    <SkeletonSegment linesQuantity={2} className="name-skeleton" />
  ),
  status: <SkeletonSegment className="status-skeleton" />,
  title: <SkeletonSegment className="title-skeleton" />,
  tags: <SkeletonSegment className="tags-skeleton" />,
  location: <SkeletonSegment className="location-skeleton" />,
  referrals: <SkeletonSegment className="referrals-skeleton" />,
  two_lines: <SkeletonSegment linesQuantity={2} className="date-skeleton" />,
  date_single_line: <SkeletonSegment className="date-singleLine" />,
  last_date: <SkeletonSegment className="date-singleLine" />,
  quantity: <SkeletonSegment className="amount-skeleton" />,
  total_quantity: <SkeletonSegment className="amount-skeleton" />,
  total_cost_basis: <SkeletonSegment className="amount-skeleton" />,
  cost_basis: (
    <SkeletonSegment linesQuantity={2} className="cost-basis-skeleton" />
  ),
  total_valuation_amount: (
    <SkeletonSegment linesQuantity={2} className="fmv-skeleton" />
  ),
  gain: (
    <SkeletonSegment image linesQuantity={2} className="gain-loss-skeleton" />
  ),
  realized_gain: (
    <SkeletonSegment image linesQuantity={2} className="gain-loss-skeleton" />
  ),
  adjustments: <SkeletonSegment className="amount-skeleton" />,
  transaction_type: <SkeletonSegment className="event-skeleton" />,
  amount: <SkeletonSegment className="amount-skeleton" />,
  total: <SkeletonSegment className="amount-skeleton" />,
};
