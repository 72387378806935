import React from "react";
import PropTypes from "prop-types";
import pluralize from "pluralize";
import { ExpandableRow } from "components_new/elements/Table";
import CurrentRow from "./CurrentRow";
import CurrentRowCells from "./CurrentRowCells";
import { investmentDataPropType, companyPropType } from "PropTypes";

function GroupedCurrentRow({
  investment,
  orgUsername,
  allRowsExpanded,
  allRowsCollapsed,
  resetInvestmentsViewRows,
}) {
  const {
    investments_count,
    last_date,
    total_quantity,
    total_cost_basis,
    total_valuation_amount,
    company,
    investments = [],
  } = investment;

  const data = {
    name: `${investments_count} ${pluralize(
      "investment",
      investments_count,
      false,
    )}`,
    date: last_date,
    quantity: total_quantity,
    cost_basis: total_cost_basis,
    total_valuation_amount: total_valuation_amount,
    company,
  };
  return (
    <ExpandableRow
      mainCells={
        <CurrentRowCells investment={data} orgUsername={orgUsername} isParent />
      }
      allRowsExpanded={allRowsExpanded}
      allRowsCollapsed={allRowsCollapsed}
      resetAllRows={resetInvestmentsViewRows}
    >
      {investments.map((investment) => (
        <CurrentRow
          key={investment.id}
          investment={investment}
          orgUsername={orgUsername}
          isChild
        />
      ))}
    </ExpandableRow>
  );
}

GroupedCurrentRow.propTypes = {
  orgUsername: PropTypes.string,
  investment: investmentDataPropType,
  allRowsExpanded: PropTypes.bool,
  allRowsCollapsed: PropTypes.bool,
  resetInvestmentsViewRows: PropTypes.func,
  companies: PropTypes.arrayOf(companyPropType),
};

export default GroupedCurrentRow;
