import React, { useCallback } from "react";
import SnapshotsView from "./SnapshotsView";
import Loader from "components/shared/Loader";

import { useParams, useNavigate } from "react-router-dom";
import { useSnapshots } from "components_new/views/Snapshots/hooks/queries/useSnapshots";
import { useDeleteSnapshot } from "components_new/views/Snapshots/hooks/mutations/useDeleteSnapshot";
import { useDuplicateSnapshot } from "components_new/views/Snapshots/hooks/mutations/useDuplicateSnapshot";

function SnapshotsViewWrapper() {
  const navigate = useNavigate();

  const { organizationUsername } = useParams();
  const { data = {}, isLoading } = useSnapshots();

  const { mutateAsync: deleteSnapshot } = useDeleteSnapshot();
  const { mutateAsync: duplicateSnapshot } = useDuplicateSnapshot();

  const handleEditSnapshot = useCallback(
    (id) => {
      navigate(`/${organizationUsername}/reports/snapshots/${id}/edit`);
    },
    [navigate, organizationUsername],
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <SnapshotsView
      snapshots={data?.snapshots}
      organizationUsername={organizationUsername}
      onEditSnapshot={handleEditSnapshot}
      onDeleteSnapshot={deleteSnapshot}
      onDuplicateSnapshot={duplicateSnapshot}
    />
  );
}

export default SnapshotsViewWrapper;
