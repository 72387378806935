import modalComponents from "./modalComponents";
import {
  OPEN_IN_MODAL,
  CLOSE_MODAL,
  UPDATE_MODAL_OPTIONS,
} from "./actionTypes";

const initialState = {
  openModalComponents: [],
  isOpen: false,
};

export default function modalReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_IN_MODAL: {
      if (!action.modalToOpen || !modalComponents[action.modalToOpen]) {
        return state;
      }

      return {
        ...state,
        isOpen: true,
        options: {
          ...state.options,
          [action.modalToOpen]: action.options,
        },
        openModalComponents: state.openModalComponents.concat(
          action.modalToOpen,
        ),
      };
    }
    case UPDATE_MODAL_OPTIONS:
      return {
        ...state,
        options: {
          ...state.options,
          [action.modalName]: {
            ...state.options[action.modalName],
            ...action.options,
          },
        },
      };
    case CLOSE_MODAL:
      const { openModalComponents } = state;
      if (openModalComponents.length > 1) {
        const openModalComponentsClone = [...openModalComponents];
        openModalComponentsClone.pop();
        return { ...state, openModalComponents: openModalComponentsClone };
      } else {
        return {
          ...state,
          isOpen: false,
          openModalComponents: [],
        };
      }
    default:
      return state;
  }
}
