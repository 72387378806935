import React, { useContext, useEffect } from "react";
import { SidebarComponentsContext } from "components_new/SidebarComponents/SidebarComponentsWrapper";
import OrganizationForm from "./OrganizationForm";
import useOrganizations from "store/organizations/hooks";
import { isEmpty } from "lodash";

function OrganizationFormWrapper({ ownName }) {
  const { registerForm, closeSidebar } = useContext(SidebarComponentsContext);

  useEffect(() => {
    if (!isEmpty(ownName)) {
      registerForm(ownName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { createOrganization } = useOrganizations();
  return (
    <OrganizationForm
      ownName={ownName}
      closeSidebar={closeSidebar}
      createOrganization={createOrganization}
    />
  );
}

export default OrganizationFormWrapper;
