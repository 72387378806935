import Api from "lib/Api";
import { useMutation, useQueryClient } from "react-query";

export function useCreateCompany() {
  const queryClient = useQueryClient();

  return useMutation(
    (formData) => {
      return Api.post(`/v2/companies`, {
        company: formData,
      });
    },
    {
      onSuccess: () => {
        setTimeout(() => {
          queryClient.invalidateQueries("Companies");
        }, 1000);
      },
    },
  );
}
