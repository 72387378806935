import {
  FLASH,
  REMOVE_FLASH,
  FLASH_MODAL,
  CLEAR_FLASHES,
} from "actions/synchronous/flash";
import { constant, uniqueId, omit } from "lodash";

const initialState = {
  flashes: {},
  showed: false,
  showInModal: false,
};

const FLASH_MESSAGES = {
  userInvited: ({ email }) => `Invitation sent to ${email}`,
  updatedForm: () => "Changes saved",
  distributionLogged: ({ type }) =>
    `${type} Distribution was logged successfully!`,
  acquisitionLogged: ({ type }) =>
    `${type} Acquisition was logged successfully`,
  revokedIntegrationAccess: ({ name }) =>
    `${name} access was revoked successfully.`,
  errorIntegration: () =>
    "There was a problem with the integration, please try again.",
  slackSettingSaved: () => "Slack settings were successfully saved!",
  dashboardPortfoliosSaved: () => "Dashboard settings updated successfully!",
  sendInvite: ({ email }) => `An invitation email has been sent to ${email}`,
  resendInvite: ({ email }) =>
    `An invitation to the organization has been resent to ${email}`,
  createMembership: ({ email }) =>
    `An invitation email has been sent to ${email}`,
  removeMembership: ({ email }) => `You removed ${email}`,
  revokeMembership: ({ email }) => `Invitation revoked for ${email}`,
  tooBigFiles: () =>
    "Sorry, the file your are trying to upload exceeds 100mb, the maximum allowed size.",
  salesLoggedSuccessfully: () => `Sale was logged successfully!`,
  loanConversionCreated: () => `Loan conversion successfully done!`,
  writeOffCreated: () => "Write off was logged successfully",
  stockSplitCreated: () => "Stock split was logged successfully",
  sentWeeklyReport: () => "Weekly report was queued to be sent.",
  generatedReminders: () => "Generated reminders for today.",
  clearedReminders: () => "All reminders were cleared.",
  removeOrRevokeMemberFromOrganization: ({ nameOrEmail }) =>
    `${nameOrEmail} has been successfully removed from the organization.`,
  leaveOrganization: () => "You have successfully left the organization",
  rejectInvitation: ({ organizationName }) =>
    `You have successfully rejected an invitation to ${organizationName}`,
  acceptInvitationToOrganization: ({ organizationName }) =>
    `You have successfully joined ${organizationName} organization`,
  deleteReferrer: ({ fullname }) => `${fullname} was successfully deleted.`,
  createdContact: () => "New contact successfully created.",
  createDeal: () => "Custom investment field successfully created.",
  editDeal: () => "Custom investment field successfully updated.",
  deleteDeal: () => "Custom investment field successfully deleted",
};

function createFlash(id, type, options) {
  const messageFunction = FLASH_MESSAGES[type] || constant("");
  return {
    id,
    type,
    options,
    message: messageFunction(options),
  };
}

function flash(state = initialState, action) {
  switch (action.type) {
    case FLASH: {
      const flashId = uniqueId();
      return {
        ...state,
        flashes: {
          ...state.flashes,
          [flashId]: createFlash(flashId, action.flashType, action.options),
        },
        showed: true,
        showInModal: false,
      };
    }
    case FLASH_MODAL: {
      const flashId = uniqueId();
      return {
        ...state,
        flashes: {
          ...state.flashes,
          [flashId]: createFlash(flashId, action.flashType, action.options),
        },
        showed: true,
        showInModal: true,
      };
    }
    case REMOVE_FLASH:
      return {
        ...state,
        flashes: omit(state.flashes, [action.flashId]),
        showed: false,
      };
    case CLEAR_FLASHES:
      return initialState;
    default:
      return state;
  }
}

export default flash;
