import React from "react";
import classNames from "classnames";
import { Table as SemanticTable } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/table.css";
import "./Table.scss";

function TableHeaderCell({
  as,
  sorted,
  extraClassnames,
  withCheckbox,
  children,
  // The following props are being destructured so they don't end up
  // in the props passed down to the actual th:
  skeletonType,
  hasTooltip,
  tooltipPosition,
  tooltipContent,
  skeletonClassName,
  ...props
}) {
  const headerCellClassnames = classNames("HeaderCell", {
    WithCheckbox: !!withCheckbox,
    [extraClassnames]: !!extraClassnames,
  });
  return (
    <SemanticTable.HeaderCell
      className={headerCellClassnames}
      as={as}
      sorted={sorted}
      {...props}
    >
      {children}
    </SemanticTable.HeaderCell>
  );
}

export default TableHeaderCell;
