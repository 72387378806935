import { useDispatch, useSelector } from "react-redux";
import { getCollection as getDomainCollection } from "store/domain/selectors";
import { debounce } from "lodash";
import {
  filterTag as filterTagHelper,
  createTag as createTagHelper,
  deleteTag as deleteTagHelper,
  editTag as editTagHelper,
  updateBulkTag as updateBulkTagHelper,
} from "./actions";

export default () => {
  const dispatch = useDispatch();

  const filterTags = debounce(
    (tagName, tagType) => dispatch(filterTagHelper(tagName, tagType)),
    300,
  );

  const tagSuggestions = useSelector((state) =>
    getDomainCollection(state, "tagSuggestions"),
  );

  const createTag = (tagName, tagType) =>
    dispatch(createTagHelper(tagName, tagType));

  const deleteTag = (tagName) => dispatch(deleteTagHelper(tagName));

  const editTag = (tagId, tagName) => dispatch(editTagHelper(tagId, tagName));

  const updateBulkTag = (tagsIds) => dispatch(updateBulkTagHelper(tagsIds));

  return {
    filterTags,
    tagSuggestions,
    createTag,
    deleteTag,
    editTag,
    updateBulkTag,
  };
};
