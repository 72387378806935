import React from "react";
import Segment from "components_new/atoms/Segment";
import { DebouncedSearchInput } from "components_new/atoms/SearchInput";
import { SkeletonStatLine } from "components_new/elements/SummaryStats/SummaryStatsSkeleton";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";

function CommittedDealsTopBar({ isLoading, fetchCommittedDeals, dealsCount }) {
  const handleChangeText = (text) => {
    const filterParams = {};
    if (!isEmpty(text)) {
      filterParams.query = text;
    }

    fetchCommittedDeals({ filters: filterParams });
  };

  return (
    <Segment extraClassNames="DealsTopBar">
      <span className="DealsCount u-bodySmallBold">
        {isLoading ? (
          <SkeletonStatLine className="DealsCount-skeleton" />
        ) : (
          pluralize("deal", dealsCount, true)
        )}
      </span>
      <DebouncedSearchInput
        onChange={handleChangeText}
        className="TopBar-item"
        placeholder="Search deals or companies"
      />
    </Segment>
  );
}

CommittedDealsTopBar.propTypes = {
  isLoading: PropTypes.bool,
  fetchCommittedDeals: PropTypes.func,
  dealsCount: PropTypes.number,
};

CommittedDealsTopBar.defaultProps = {
  isLoading: false,
};

export default CommittedDealsTopBar;
