import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { isEmpty, groupBy } from "lodash";
import useOrganizations from "store/organizations/hooks";
import { useInvestment } from "components_new/SidebarComponents/views/InvestmentView/hooks/useInvestment";
import { useInvestmentPosts } from "queries/useInvestmentPosts";
import InvestmentView from "./InvestmentView";
import { useCreateInvestmentDocument } from "mutations/useCreateInvestmentDocument";
import { useUpdateInvestmentDocument } from "mutations/useUpdateInvestmentDocument";
import { useDeleteInvestmentDocument } from "mutations/useDeleteInvestmentDocument";
import { useCreateInvestmentPost } from "mutations/useCreateInvestmentPost";
import { useUpdateInvestmentPost } from "mutations/useUpdateInvestmentPost";
import { useDeleteInvestmentPost } from "mutations/useDeleteInvestmentPost";
import { useDispatch } from "react-redux";
import { openInModal, ModalNames } from "store/modal";
import Loader from "components/shared/Loader";

function InvestmentViewWrapper({ investmentId, closeSidebar }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const initialLocation = useRef(location);
  const {
    currentOrganizationUsername,
    currentOrganization,
  } = useOrganizations();
  const { data: investment, isLoading: isLoadingInvestment } = useInvestment(
    investmentId,
  );

  const {
    data: investmentPosts,
    isLoading: isLoadingInvestmentPosts,
  } = useInvestmentPosts(investmentId);
  const {
    mutateAsync: createInvestmentDocument,
  } = useCreateInvestmentDocument();
  const {
    mutateAsync: updateInvestmentDocument,
  } = useUpdateInvestmentDocument();
  const {
    mutateAsync: deleteInvestmentDocument,
  } = useDeleteInvestmentDocument();
  const filesActions = {
    saveInvestmentDocuments: (document) =>
      createInvestmentDocument({
        document,
        investmentId: investment.details.id,
      }),
    updateFile: (document) =>
      updateInvestmentDocument({
        document,
        investmentId: investment.details.id,
      }),
    removeFile: (document) =>
      deleteInvestmentDocument({
        document,
        investmentId: investment.details.id,
      }),
  };
  const { mutateAsync: createInvestmentPost } = useCreateInvestmentPost();
  const { mutateAsync: updateInvestmentPost } = useUpdateInvestmentPost();
  const { mutateAsync: deleteInvestmentPost } = useDeleteInvestmentPost();
  const discussionActions = {
    createPost: (resource, postContent) =>
      createInvestmentPost({ resource, postContent }),
    updatePost: (resource, postId, postContent) =>
      updateInvestmentPost({ resource, postId, postContent }),
    removePost: (resource, postId) =>
      deleteInvestmentPost({ resource, postId }),
    openDocumentsUploader: (options = {}) =>
      dispatch(openInModal(ModalNames.discussionDocuments, options)),
  };
  const currentUser = currentOrganization.organization_memberships;
  const users = groupBy(
    currentOrganization.organization_memberships,
    "user_id",
  );

  // Close panel on location change
  useEffect(() => {
    if (location !== initialLocation.current) {
      closeSidebar();
    }
  }, [location, closeSidebar]);

  const isLoading =
    isLoadingInvestment || isEmpty(investment) || isLoadingInvestmentPosts;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <InvestmentView
      investment={investment}
      currentOrganizationUsername={currentOrganizationUsername}
      currentUser={currentUser}
      users={users}
      investmentPosts={investmentPosts}
      isLoadingInvestmentPosts={isLoadingInvestmentPosts}
      filesActions={filesActions}
      discussionActions={discussionActions}
      closeSidebar={closeSidebar}
    />
  );
}

export default InvestmentViewWrapper;
