import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Form, TextArea as SemanticTextArea } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/form.css";
import "./TextArea.scss";

function TextAreaInput({
  label = null,
  placeholder = "",
  defaultValue = "",
  rows = 4,
  maxLength = null,
  autoFocus = false,
  errorMessage,
  error = false,
  extraClassnames,
  size,
  ...restProps
}) {
  const [fieldValue, setFieldValue] = useState(defaultValue);
  const [currentCount, setCurrentCount] = useState(0);
  const textArea = useRef();
  const textAreaClassnames = classNames("TextArea", {
    error: !!error,
    [size]: !!size,
    "is-focus": !!autoFocus,
    [extraClassnames]: !!extraClassnames,
  });

  useEffect(() => {
    setFieldValue(defaultValue);
    autoResizeTextArea();
  }, [defaultValue]);

  useLayoutEffect(() => autoResizeTextArea());

  const handleChange = (event) => {
    const currentCount = event.target.value.length;
    autoResizeTextArea();
    if (currentCount > maxLength) {
      event.preventDefault();
    } else {
      setFieldValue(event.target.value);
      setCurrentCount(currentCount);
    }
  };

  const onFocus = () => {
    textArea.current.focus();
  };

  const autoResizeTextArea = () => {
    const currentTextArea = textArea.current.ref.current;
    currentTextArea.style.height = "auto";
    const styles = window.getComputedStyle(currentTextArea);
    const height =
      parseInt(styles.getPropertyValue("border-top-width"), 10) +
      parseInt(styles.getPropertyValue("padding-top"), 10) +
      currentTextArea.scrollHeight +
      parseInt(styles.getPropertyValue("padding-bottom"), 10) +
      parseInt(styles.getPropertyValue("border-bottom-width"), 10);

    currentTextArea.style.height = height + "px";
  };

  return (
    <Form.Field className={textAreaClassnames}>
      {label && <label>{label}</label>}
      <div className="TextArea-wrapper">
        <SemanticTextArea
          ref={textArea}
          value={fieldValue}
          autoFocus={autoFocus}
          onChange={handleChange}
          placeholder={placeholder}
          rows={rows}
          onFocus={onFocus}
          maxLength={maxLength}
          size={size}
          {...restProps}
        />
        {maxLength && (
          <span className="TextArea-counter">
            {currentCount} / {maxLength}
          </span>
        )}
      </div>
      {errorMessage && <p className="errorMessage">{errorMessage}</p>}
    </Form.Field>
  );
}

TextAreaInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  rows: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  errorMessage: PropTypes.string,
  error: PropTypes.bool,
  size: PropTypes.string,
  extraClassnames: PropTypes.string,
};

export default TextAreaInput;
