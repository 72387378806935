import Api from "lib/Api";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

export function useUpdateCoInvestment() {
  const queryClient = useQueryClient();
  const { organizationUsername } = useParams();

  return useMutation(
    ({ formData }) =>
      Api.patch(`/v2/co_investments/${formData.id}`, {
        co_investment: formData,
      }),
    {
      onSuccess: ({ data: updatedCoInvestment }, { companyId }) => {
        const key = ["Company", organizationUsername, companyId];
        const companyView = queryClient.getQueryData(key);

        const collectionKey =
          updatedCoInvestment.investor.id === companyId
            ? "investorCoInvestments"
            : "companyCoInvestments";

        queryClient.setQueryData(key, {
          ...companyView,
          [collectionKey]: companyView?.[collectionKey].map((coInvestment) =>
            coInvestment.id === updatedCoInvestment.id
              ? updatedCoInvestment
              : coInvestment,
          ),
        });
      },
      onError: (error) => {
        throw error;
      },
    },
  );
}
