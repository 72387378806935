import React from "react";
import { Outlet } from "react-router-dom";
import NavigationTabs from "components/shared/NavigationTabs";

function DefaultDevToolsText() {
  return (
    <div className="DevHome-content">
      These are some development tools to help with QA and development in
      Mighty.
    </div>
  );
}

function DevHome() {
  return (
    <div className="standard-view u-row">
      <div className="DevHome-sidebar pull-left">
        <h2 className="DevHome-title u-heading">Dev Tools</h2>
        <div className="list">
          <NavigationTabs
            orientation="vertical"
            items={[
              {
                url: "/dev/weekly-report",
                children: "Weekly Reports",
              },
              {
                url: "/dev/error",
                children: "Raise Errors",
              },
              {
                url: "/dev/api-client",
                children: "Api Client",
              },
            ]}
          />
        </div>
      </div>
      <div className="pull-left DevHome-container">
        <Outlet />
      </div>
    </div>
  );
}

export default DevHome;
export { DefaultDevToolsText };
