import React, { useState, useCallback } from "react";
import CommittedDeals from "./CommittedDeals";
import useOrganizations from "store/organizations/hooks";
import useSidebar from "store/sidebar/hooks";

import { useDispatch } from "react-redux";
import { openModal } from "store/modalV2";
import { isEmpty } from "lodash";

import { useDeals } from "components_new/views/Deals/hooks/queries/useDeals";
import { useSetSidebar } from "components_new/elements/SidebarWrapper/Sidebar-context";
import { useQueryClient } from "react-query";

function CommittedDealsWrapper() {
  const [filters, setFilters] = useState({});
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const {
    currentOrganization,
    currentOrganization: { pipeline_id: pipelineId },
  } = useOrganizations();

  const { openSidebar } = useSidebar();
  const { showSidebar } = useSetSidebar();
  const { data, isLoading } = useDeals("CommittedDeals", pipelineId, filters);

  const invalidateQueriesCache = useCallback(() => {
    queryClient.invalidateQueries(["Pipeline", pipelineId]);
    queryClient.invalidateQueries(["CommittedDeals", pipelineId, {}]);
  }, [pipelineId, queryClient]);

  const handleOpenDeleteDealModal = useCallback(
    (dealId) =>
      dispatch(
        openModal("removeDeal", {
          dealId,
          pipelineId,
        }),
      ),
    [dispatch, pipelineId],
  );

  const handleConvertToInvesment = (deal) => {
    openSidebar("investmentForm", "Add New Investment", {
      deal,
      callback: invalidateQueriesCache,
    });
  };

  const handleOpenReactivateDealModal = useCallback(
    (dealId) =>
      dispatch(
        openModal("reactivateDeal", {
          dealId,
          pipelineId,
        }),
      ),
    [dispatch, pipelineId],
  );

  const handleOpenEditDealForm = useCallback(
    (dealId) =>
      showSidebar("dealForm", {
        dealId: dealId,
      }),
    [showSidebar],
  );

  const handleOpenDealDetailsView = useCallback(
    (deal) => {
      showSidebar("activeDealView", { dealId: deal.id });
    },
    [showSidebar],
  );

  const handleOpenEditCommitmentModal = useCallback(
    (dealId) =>
      dispatch(
        openModal("commitToDeal", {
          dealId,
          pipelineId,
        }),
      ),
    [dispatch, pipelineId],
  );

  return (
    <CommittedDeals
      isLoading={isLoading}
      hasSearchOrFilter={!isEmpty(filters)}
      committedDeals={data?.deals}
      committedDealsCount={data?.deals.length}
      fetchCommittedDeals={({ filters }) => setFilters(filters)}
      organizationUsername={currentOrganization.username}
      openDeleteDealModal={handleOpenDeleteDealModal}
      openReactivateDealModal={handleOpenReactivateDealModal}
      openEditDealSidebar={handleOpenEditDealForm}
      openViewDealSidebar={handleOpenDealDetailsView}
      openEditCommitmentModal={handleOpenEditCommitmentModal}
      onConvertToInvesment={handleConvertToInvesment}
    />
  );
}

export default CommittedDealsWrapper;
