import React, { Fragment, useState } from "react";
import Button from "components_new/atoms/Button";
import Icon from "components_new/atoms/Icon";
import Loader from "components_new/atoms/Loader";

function AdvanceStagesButtons({
  deal,
  pipelineId,
  stagesOptions,
  stage,
  updateDealStage,
  openPassFromDealModal,
  openCommitToDealModal,
}) {
  const shouldDisableActions = !(deal.status === "active");
  const [isUpdating, setIsUpdating] = useState(false);
  const currentStageOptionIndex = stagesOptions.findIndex(
    (option) => option.value === stage.id,
  );
  const nextStageOption = stagesOptions[currentStageOptionIndex - 1]; // stages are ordered in reverse
  const isLastStage = !nextStageOption;
  const nextStageButtonText = isLastStage
    ? "Commit to Deal"
    : `Advance to ${nextStageOption.text}`;

  const handleUpdateDealStage = (newStageId) => {
    setIsUpdating(true);
    updateDealStage({ dealId: deal.id, stageId: newStageId, pipelineId }).then(
      () => {
        setIsUpdating(false);
      },
    );
  };
  return (
    <Fragment>
      {isLastStage ? (
        <Button
          fluid
          size="mini"
          className="SelectedDeal-ActionMenu-Btn SelectedDeal-ActionMenu-Btn-Commit"
          disabled={shouldDisableActions}
          onClick={() => openCommitToDealModal(deal.id)}
        >
          {nextStageButtonText}
          <Icon icon="ri-check-line" />
        </Button>
      ) : (
        <Button
          primary
          fluid
          size="mini"
          className="SelectedDeal-ActionMenu-Btn"
          disabled={shouldDisableActions}
          onClick={() => handleUpdateDealStage(nextStageOption.value)}
        >
          {nextStageButtonText}
          {isUpdating && <Loader active inline size="tiny" />}
        </Button>
      )}
      <Button
        basic
        fluid
        size="mini"
        className="SelectedDeal-ActionMenu-Btn red"
        disabled={shouldDisableActions}
        onClick={() => openPassFromDealModal(deal.id)}
      >
        Pass
      </Button>
    </Fragment>
  );
}

export default AdvanceStagesButtons;
