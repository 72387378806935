import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { closeModal } from "store/modalV2/actions";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import { useDeletePortfolio } from "components_new/views/Portfolios/hooks/mutations/useDeletePortfolio";

import ConfirmationModal from "components/shared/ConfirmationModal";
import getMessage from "components_new/lib/Messages";

function ConfirmDeletionContainer({ portfolioId }) {
  const [submitting, setSubmitting] = useState(false);

  const { organizationUsername } = useParams();
  const { addToast } = useSetToast();
  const { mutateAsync: deletePortfolio } = useDeletePortfolio();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const closeModalCallback = useCallback(() => dispatch(closeModal()), [
    dispatch,
  ]);

  const deletePortfolioCallback = useCallback(async () => {
    setSubmitting(true);
    addToast(getMessage("deletePortfolio"));
    await deletePortfolio(portfolioId);

    navigate(`/${organizationUsername}/portfolios/all/current`);
    setSubmitting(false);
    dispatch(closeModal());
  }, [
    addToast,
    deletePortfolio,
    portfolioId,
    dispatch,
    navigate,
    organizationUsername,
  ]);

  return (
    <ConfirmationModal
      title="Delete Portfolio"
      message="Are you sure you want to delete this portfolio?"
      confirmButtonLabel="Delete Portfolio"
      primaryButtonClassnames="Button--warning"
      closeModal={closeModalCallback}
      action={deletePortfolioCallback}
      submitting={submitting}
    />
  );
}

export default ConfirmDeletionContainer;
