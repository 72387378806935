import {
  START_DOCUMENT_CREATION,
  STOP_DOCUMENT_CREATION,
  CLEAN_DOCUMENTS,
} from "actions/synchronous/documentsView";

const initialState = {
  investmentDocuments: null,
  creatingDocument: false,
};

function documentsView(state = initialState, action) {
  switch (action.type) {
    case START_DOCUMENT_CREATION:
      return Object.assign({}, state, { creatingDocument: true });
    case STOP_DOCUMENT_CREATION:
      return Object.assign({}, state, {
        creatingDocument: false,
        receiveFile: false,
      });
    case CLEAN_DOCUMENTS:
      return initialState;
    default:
      return state;
  }
}

export default documentsView;
