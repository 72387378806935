import Api from "lib/Api";
import { buildPostResourceRoute } from "lib/globalActionsHelper";
import { receiveCollection, receiveCollections } from "store/entities";
import { updateView, removeFromView, receiveView } from "store/views";
import { isEmpty } from "lodash";

export function fetchMinimalInvestments() {
  return () => {
    return Api.getCollection("minimalInvestments", `/investments`);
  };
}

export function fetchInvestments({
  portfolioId,
  groupedByCompany,
  filters = null,
}) {
  const params = portfolioId === "all" ? {} : { portfolio_id: portfolioId };
  const hasSearch = !isEmpty(filters);

  const path = groupedByCompany
    ? "/v2/investments/by_company"
    : "/v2/investments/";

  return (dispatch) => {
    return Api.get(path, {
      params: { ...params, ...filters },
    }).then(
      ({
        data: {
          entities,
          views: { investments: view },
        },
      }) => {
        dispatch(receiveView("investments", view));
        dispatch(receiveCollections(entities));
        return hasSearch;
      },
    );
  };
}

export function fetchCurrentInvestments({
  portfolioId,
  groupedByCompany,
  filters = null,
}) {
  const params = portfolioId === "all" ? {} : { portfolio_id: portfolioId };
  const hasSearch = !isEmpty(filters);

  const path = groupedByCompany
    ? "/v2/investments/current/by_company"
    : "/v2/investments/current";

  return (dispatch) => {
    return Api.get(path, { params: { ...params, ...filters } }).then(
      ({
        data: {
          entities,
          views: { investments: view },
        },
      }) => {
        dispatch(receiveView("investments", view));
        dispatch(receiveCollections(entities));
        return hasSearch;
      },
    );
  };
}

export function fetchPreviousInvestments({
  portfolioId,
  groupedByCompany,
  filters = null,
}) {
  const params = portfolioId === "all" ? {} : { portfolio_id: portfolioId };
  const hasSearch = !isEmpty(filters);

  const path = groupedByCompany
    ? "/v2/investments/previous/by_company"
    : "/v2/investments/previous";
  return (dispatch) => {
    return Api.get(path, { params: { ...params, ...filters } }).then(
      ({
        data: {
          entities,
          views: { investments: view },
        },
      }) => {
        dispatch(receiveView("investments", view));
        dispatch(receiveCollections(entities));
        return hasSearch;
      },
    );
  };
}

export function fetchInvestmentPosts(pipelineId, investmentId) {
  return () => {
    return Api.getCollection(
      "investmentPosts",
      `/pipelines/${pipelineId}/investments/${investmentId}/posts`,
    );
  };
}

export function createInvestmentPost(resource, formData) {
  return (dispatch) => {
    const postResourceRoute = buildPostResourceRoute(resource);

    return Api.postMutation(
      "createInvestmentPost",
      `${postResourceRoute}/posts`,
      { post: formData },
      (investmentPost) =>
        dispatch(
          addResourceToInvestmentView(
            "investmentPosts",
            "investment_posts_ids",
            investmentPost,
            investmentPost.id,
          ),
        ),
    );
  };
}

export function updateInvestmentPost(resource, postId, formData) {
  return (dispatch) => {
    const postResourceRoute = buildPostResourceRoute(resource);

    return Api.patchMutation(
      "updateInvestmentPost",
      `${postResourceRoute}/posts/${postId}`,
      { post: formData },
      (investmentPost) =>
        dispatch(
          addResourceToInvestmentView(
            "investmentPosts",
            "investment_posts_ids",
            investmentPost,
            investmentPost.id,
          ),
        ),
    );
  };
}

export function removeInvestmentPost(resource, postId) {
  return (dispatch) => {
    const postResourceRoute = buildPostResourceRoute(resource);

    return Api.deleteMutation(
      "removeInvestmentPost",
      `${postResourceRoute}/posts/${postId}`,
      () => {
        dispatch(removeFromView("investment", "investment_posts_ids", postId));
      },
    );
  };
}

export function addResourceToInvestmentView(
  resourceName,
  resourceIdsName,
  resource,
  resourceId,
) {
  return (dispatch) => {
    dispatch(receiveCollection(resourceName, [resource]));
    dispatch(updateView("investment", resourceIdsName, resourceId));
  };
}
