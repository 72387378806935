import { useState, useEffect } from "react";
import Errors from "lib/errors";
import ensureFormData from "lib/shared/form/ensureFormData";
import isServerValidationError from "lib/isServerValidationError";
import preventerHandler from "lib/preventerHandler";
import { isUndefined, mapValues } from "lodash";
import useSmoothScroll from "lib/hooks/useSmoothScroll";

const useForm = (initialValues, submit, options = {}) => {
  const { preventDefault = true, strictValidation = false } = options;
  const [formData, setValues] = useState(initialValues.formData);
  const [errors, setErrors] = useState(new Errors({}));
  const [touched, setTouched] = useState(false);
  const { scrollView } = useSmoothScroll();

  useEffect(() => {
    if (!strictValidation) {
      const startClean = setTimeout(() => {
        setTouched(false);
      }, 300);
      return () => clearTimeout(startClean);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const simpleSubmit = () => {
    const clientData = ensureFormData(formData);
    submit(clientData);
  };
  const preventerSubmit = preventerHandler(simpleSubmit);
  const handleSubmit = preventDefault ? preventerSubmit : simpleSubmit;

  const handleChange = (name, value) => {
    setErrors(errors.without(name));

    if (isUndefined(value)) {
      // eslint-disable-next-line no-console
      console.error(
        `This form is not working properly: value for ${name} is undefined`,
      );
    } else {
      if (!touched) {
        setTouched(true);
      }
      setValues((formData) => ({
        ...formData,
        [name]: value,
      }));
    }
  };

  const handleBatchChange = (additionalFormData) => {
    if (!touched) {
      setTouched(true);
    }
    const freshData = mapValues(additionalFormData, (value) =>
      value === null ? "" : value,
    );
    setValues({ ...formData, ...freshData });
  };

  const resetForm = (newData = initialValues.formData) => {
    setValues(newData);
    setErrors(new Errors({}));
    setTouched(false);
  };

  const handleErrors = (errors) => {
    const processedErrors = processErrors(errors);
    setErrors(processedErrors);
  };

  const handleScrollView = (errors) => {
    const processedErrors = processErrors(errors);
    scrollView(processedErrors);
  };

  const processErrors = (errors) => {
    let processedErrors = errors;

    if (!(errors instanceof Errors)) {
      processedErrors = isServerValidationError(errors)
        ? new Errors(errors.data.errors)
        : new Errors({});
    }

    return processedErrors;
  };

  const setTouch = () => setTouched(true);

  return {
    formData,
    handleChange,
    handleBatchChange,
    handleSubmit,
    handleErrors,
    handleScrollView,
    resetForm,
    errors,
    touched,
    setTouched: setTouch,
  };
};

export default useForm;
