import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Header from "components_new/atoms/Header";
import Image from "components_new/atoms/Image";
import HeaderContent from "components_new/atoms/Header/HeaderContent";
import SubHeader from "components_new/atoms/Header/SubHeader";
import MightyIcon from "components_new/atoms/MightyIcon";
import Icon from "components_new/atoms/Icon";
import BasicLabel from "components_new/atoms/BasicLabel";
import Tooltip from "components_new/atoms/Tooltip";
import Link from "components_new/atoms/Link";
import "semantic-ui-css/components/image.css";
import "./Avatar.scss";

function imageComponent(
  imageUrl,
  avatarIcon,
  utilityIcon,
  title,
  size,
  circular,
  imagePosition,
  width,
  height,
  tooltipContent,
  tooltipStyles,
  initials,
) {
  const TooltipTrigger = () => {
    if (imageUrl) {
      return (
        <Image
          src={imageUrl}
          circular={circular}
          rounded={!circular}
          alt={title}
          floated={imagePosition}
          size={size}
          style={width || height ? { width, height } : {}}
        />
      );
    } else if (avatarIcon) {
      return <MightyIcon icon={avatarIcon} alt={title} />;
    } else if (utilityIcon) {
      return <Icon icon={utilityIcon} alt={title} />;
    } else if (initials) {
      return <div className="UserAvatar-initials">{initials}</div>;
    } else {
      return null;
    }
  };
  if (tooltipContent) {
    return (
      <Tooltip
        extraClassnames="AvatarTooltip"
        customStyles={tooltipStyles}
        position="left center"
        content={tooltipContent}
        trigger={TooltipTrigger()}
      ></Tooltip>
    );
  } else {
    return <TooltipTrigger />;
  }
}

function linkedTitle(title, titleUrl) {
  if (titleUrl) {
    return <Link to={titleUrl}>{title}</Link>;
  } else {
    return title;
  }
}

export default function Avatar({
  imageUrl,
  avatarIcon,
  utilityIcon,
  title,
  titleUrl,
  subtitle,
  label,
  labelColor = "generic",
  size,
  circular,
  imagePosition,
  miniAvatar,
  width,
  height,
  tooltipContent,
  initials,
  attributes,
}) {
  const avatarClassNames = classNames("Avatar", {
    miniAvatar: !!miniAvatar,
    "has-subtitle": !!subtitle,
    "image-left": imagePosition === "left",
    "image-right": imagePosition === "right",
  });
  return (
    <Header className={avatarClassNames}>
      <div className="Avatar-Image">
        {imageComponent(
          imageUrl,
          avatarIcon,
          utilityIcon,
          title,
          size,
          circular,
          imagePosition,
          width,
          height,
          tooltipContent,
          initials,
        )}
      </div>
      {(title || label || subtitle) && (
        <HeaderContent>
          <span className="title">{linkedTitle(title, titleUrl)}</span>
          <div className="labels">
            {label && (
              <BasicLabel upperCase={true} type={labelColor} children={label} />
            )}
            {attributes &&
              attributes.map((attribute) => (
                <BasicLabel
                  upperCase={true}
                  type={attribute.labelType}
                  children={attribute.label}
                />
              ))}
          </div>
          {subtitle && <SubHeader>{subtitle}</SubHeader>}
        </HeaderContent>
      )}
    </Header>
  );
}

Avatar.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  circular: PropTypes.bool,
  subtitle: PropTypes.string,
  label: PropTypes.string,
  labelColor: PropTypes.string,
  imageUrl: PropTypes.string,
  avatarIcon: PropTypes.string,
  utilityIcon: PropTypes.string,
  imagePosition: PropTypes.oneOf(["left", "right"]),
  miniAvatar: PropTypes.bool,
  titleUrl: PropTypes.string,
  size: PropTypes.oneOf(["mini", "small", "medium", "large"]),
  iconSize: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  tooltipContent: PropTypes.string,
  initials: PropTypes.string,
  attributes: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, labelType: PropTypes.string }),
  ),
};

Avatar.defaultProps = {
  size: "small",
  imagePosition: "left",
  titleUrl: null,
};
