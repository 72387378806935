import React, { useState } from "react";
import DropdownForm from "components_new/atoms/DropdownForm";
import Loader from "components_new/atoms/Loader";
import "./ActiveDealStageSelector.scss";

function stagesOptionsWithCommit(stagesOptions) {
  const newStagesOptions = [
    ...stagesOptions,
    {
      key: "commit-stage-option",
      value: "commit",
      text: "Commit to Deal",
      order: stagesOptions.length + 1,
    },
  ];
  return newStagesOptions;
}

function DealStageSelector({
  deal,
  stagesOptions,
  updateDealStage,
  openCommitToDealModal,
}) {
  const [isUpdating, setIsUpdating] = useState(false);
  const { id: dealId, pipeline_id: pipelineId, stage_id: stageId } = deal;

  const handleUpdateDealStage = (newStageId) => {
    setIsUpdating(true);
    if (newStageId === "commit") {
      openCommitToDealModal(deal.id);
      setIsUpdating(false);
    } else {
      updateDealStage({ dealId, stageId: newStageId, pipelineId }).then(() => {
        setIsUpdating(false);
      });
    }
  };

  return (
    <div className="DealStageSelector">
      <DropdownForm
        iconStyle="remix"
        text="Move to stage"
        items={stagesOptionsWithCommit(stagesOptions)}
        value={stageId}
        disabled={isUpdating}
        onChange={(_, { value }) => handleUpdateDealStage(value)}
      />
      {isUpdating && <Loader active={isUpdating} inline size="tiny" />}
    </div>
  );
}

export default DealStageSelector;
