import React, { useState, useEffect } from "react";
import classNames from "classnames";
import FormElement from "components/shared/form/FormElement";
import CheckBox from "components/shared/form/CheckBox";
import { cloneDeep } from "lodash";
import "stylesheets/components/shared/form/CheckboxGroup.scss";

function CheckBoxGroup(props) {
  const {
    label,
    name,
    errorMessage,
    extraClassnames,
    errorDisplayValidation,
    onChange,
    isOptional,
    inputProps,
    isDisabled,
    listStyle,
    textHelper,
    size,
  } = props;

  const initialValue = cloneDeep(inputProps);
  const [values, setValues] = useState(initialValue);

  useEffect(() => {
    if (inputProps.length !== values.length) {
      setValues(inputProps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputProps]);

  const handleChange = (index) => {
    const newValues = values.slice(0);
    newValues[index].value = !newValues[index].value;
    setValues(newValues);
    if (onChange) {
      onChange(newValues);
    }
  };

  const groupClassnames = classNames("FormElement--checkBoxGroup", {
    [extraClassnames]: !!extraClassnames,
    isDisabled: isDisabled,
    [size]: !!size,
    [listStyle]: !!listStyle,
  });

  return (
    <FormElement
      {...(errorDisplayValidation && { errorDisplayValidation })}
      errorMessage={errorMessage}
      extraClassnames={groupClassnames}
      isOptional={isOptional}
      label={label}
      textHelper={textHelper}
      name={name}
      size={size}
    >
      <div className="CheckBoxGroupHolder">
        {values.map((item, index) => {
          return (
            <CheckBox
              key={index}
              extraClassnames="CheckBoxGroupHolder-item"
              label={item.label}
              value={item.value}
              onChange={() => handleChange(index)}
            />
          );
        })}
      </div>
    </FormElement>
  );
}

export default CheckBoxGroup;
