import React, { Fragment } from "react";
import ValueUp from "-!svg-react-loader?!assets/icons/miscellaneous/value-up.svg?name=ValueUp";
import ValueDown from "-!svg-react-loader?!assets/icons/miscellaneous/value-down.svg?name=ValueDown";
import { formatPriceInputPreview } from "lib/abbreviatedFormat";

function formatValue(value) {
  const startAbbreviatingFrom = 100000000; // 100 million

  return formatPriceInputPreview(
    value,
    {
      precision: 0,
    },
    startAbbreviatingFrom,
    1,
  );
}

function IndicatePositiveOrNegativeValue({ value }) {
  const number = Number(value);
  let formattedValue = null;

  if (number <= -0.5) {
    formattedValue = (
      <Fragment>
        {formatValue(value)}
        <span className="IndicatePositiveOrNegativeValue-caret">
          <ValueDown />
        </span>
      </Fragment>
    );
  } else if (-0.5 < number && number < 0) {
    formattedValue = (
      <Fragment>
        <span className="IndicatePositiveOrNegativeValue-nonCaret">
          {formatValue(value).slice(1)}
        </span>
      </Fragment>
    );
  } else if (0 <= number && number < 0.5) {
    formattedValue = (
      <Fragment>
        <span className="IndicatePositiveOrNegativeValue-nonCaret">
          {formatValue(value)}
        </span>
      </Fragment>
    );
  } else {
    formattedValue = (
      <Fragment>
        {formatValue(value)}
        <span className="IndicatePositiveOrNegativeValue-caret">
          <ValueUp />
        </span>
      </Fragment>
    );
  }

  return (
    <span className="IndicatePositiveOrNegativeValue">{formattedValue}</span>
  );
}

export default IndicatePositiveOrNegativeValue;
