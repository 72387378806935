import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import MightyIcon from "-!svg-react-loader?!assets/images/mighty-logo-square.svg?name=MightyIcon";
import "./MightyLogo.scss";

function MightyLogo({ size, theme }) {
  const mightyLogoClassNames = classNames("MightyLogo", {
    [`is-${size}`]: !!size,
    [`is-${theme}`]: !!theme,
  });
  return (
    <div className={mightyLogoClassNames}>
      <MightyIcon />
    </div>
  );
}

MightyLogo.propTypes = {
  size: PropTypes.oneOf(["large", "medium", "small"]).isRequired,
  theme: PropTypes.oneOf(["primary", "secondary", "inverse"]).isRequired,
};

MightyLogo.defaultProps = {
  size: "medium",
  theme: "primary",
};

export default MightyLogo;
