import getTodayUTC from "lib/charts/getTodayUTC";

export default function addFakeTodaysPoint(chartPoints) {
  const totalChartPoints = chartPoints.length;
  const todayUTC = getTodayUTC();
  const todaysPointData = [
    {
      data: {
        fake_point: true,
      },
      x: todayUTC,
      y: parseFloat(chartPoints[totalChartPoints - 1].y),
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
  ];

  return chartPoints.concat(todaysPointData);
}
