import React, { useState } from "react";
import ActionsStageForm from "./ActionsStage";
import useModal from "store/modalV2/hooks";
import getMessage from "components_new/lib/Messages";

import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import { useUpdateStage } from "components_new/views/Deals/hooks/mutations/useUpdateStage";
import { useRemoveStage } from "components_new/views/Deals/hooks/mutations/useRemoveStage";
import { useCreateStage } from "components_new/views/Deals/hooks/mutations/useCreateStage";

function ActionsStageWrapper({ formType, pipeline, stage }) {
  const [submitting, setSubmitting] = useState(false);
  const { closeModal } = useModal();
  const { addToast } = useSetToast();

  const { mutate: createStage } = useCreateStage();
  const { mutate: updateStage } = useUpdateStage();
  const { mutate: removeStage } = useRemoveStage();

  const actionsByType = {
    edit: updateStage,
    create: createStage,
    delete: removeStage,
  };

  const handleSubmit = async (formData) => {
    const submitAction = actionsByType[formType];

    setSubmitting(true);
    try {
      const successMessage = getMessage(`${formType}Stage`);
      await submitAction(formData);

      addToast(successMessage);
      closeModal();
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <ActionsStageForm
      formType={formType}
      submitForm={handleSubmit}
      submitting={submitting}
      stage={stage}
      pipeline={pipeline}
      closeModal={closeModal}
    />
  );
}

export default ActionsStageWrapper;
