import {
  validateResetTokenError,
  validateResetTokenStart,
  validateResetTokenSuccess,
} from "actions/synchronous/auth";
import Api from "lib/Api";

export default function validateResetToken(resetToken) {
  return async (dispatch) => {
    dispatch(validateResetTokenStart());

    try {
      await Api.post(
        "/session/validate_reset",
        { reset_token: { token: resetToken } },
        { withoutOrganization: true },
      );
      dispatch(validateResetTokenSuccess());
      return true;
    } catch {
      dispatch(validateResetTokenError());
      return false;
    }
  };
}
