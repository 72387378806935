import React, { useCallback, useContext, useEffect } from "react";
import EditContactForm from "./EditContactForm";
import { SidebarComponentsContext } from "components_new/SidebarComponents/SidebarComponentsWrapper";
import useEntities from "store/entities/hooks";
import { isEmpty } from "lodash";
import { useQueryClient } from "react-query";
import getMessage from "components_new/lib/Messages";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import useResourcesV2 from "store/resourcesV2/hooks";
import { useUpdateContact } from "components_new/views/Contacts/hooks/mutations/useUpdateContact";
import useOrganization from "store/organizations/hooks";

function EditContactFormWrapper({ ownName }) {
  const { getComponentState, registerForm, closeSidebar } = useContext(
    SidebarComponentsContext,
  );

  const {
    componentData,
    submitTo,
    updatePrimaryForm,
    referrer,
  } = getComponentState(ownName);

  const { getEntity } = useEntities();
  const contactCompany = getEntity("companyAvatars", referrer?.company_id);

  const { mutateAsync: updateContact } = useUpdateContact();
  const { addToast } = useSetToast();
  const queryClient = useQueryClient();
  const {
    isAdmin,
    isCurrentUserOrganizationOwner,
    currentOrganizationName,
    currentOrganizationUsername,
  } = useOrganization();

  const { getOrganizationResourceAsCollection } = useResourcesV2();
  const organizationtCustomFields = getOrganizationResourceAsCollection(
    "customFields",
  );

  const handleUpdateContact = useCallback(
    async (contactId, formData) => {
      await updateContact(
        { contactId, formData },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(["ContactsDirectory"]);
            addToast(getMessage("updateReferrer"));
          },
        },
      );
    },
    [addToast, updateContact, queryClient],
  );

  useEffect(() => {
    if (!isEmpty(ownName)) {
      registerForm(ownName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EditContactForm
      ownName={ownName}
      componentData={componentData}
      submitTo={submitTo}
      closeSidebar={closeSidebar}
      updatePrimaryForm={updatePrimaryForm}
      contact={referrer || componentData}
      contactCompany={contactCompany}
      updateContact={handleUpdateContact}
      organizationCustomFields={organizationtCustomFields}
      currentOrganizationName={currentOrganizationName}
      currentOrganizationUsername={currentOrganizationUsername}
      isOrgOwner={isCurrentUserOrganizationOwner}
      isAdmin={isAdmin}
    />
  );
}

export default EditContactFormWrapper;
