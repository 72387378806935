import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentOrganization } from "./selectors";
import { fetchCurrentUserOrganizationMemberships as fetchCurrentUserOrganizationMembershipsHelper } from "store/organizations/organizationMembers/actions";
import { isEmpty, isEqual } from "lodash";
import { getView } from "store/viewsV2";
import { getResourceAsCollection } from "store/resourcesV2";
import {
  setCurrentOrganization,
  createOrganization as createNewOrganization,
  updateOrganizationPreferences,
} from "store/organizations/actions";
import useCurrentUser from "hooks/useCurrentUser";

const extractActiveMembershipsFromOrganization = (organization) => {
  if (isEmpty(organization)) return [];

  const { organization_memberships: memberships = [] } = organization;

  return Object.values(memberships)
    .filter((member) => member.status === "accepted")
    .sort((a, b) => {
      // sort by partner status first, then by name
      if (a.partner == b.partner) {
        if (a.full_name < b.full_name) {
          return -1;
        } else if (a.full_name > b.full_name) {
          return 1;
        } else {
          return 0;
        }
      } else if (a.partner) {
        return -1;
      } else {
        return 1;
      }
    })
    .map((membership) => {
      return {
        key: membership.user_id,
        value: membership.user_id,
        text: membership.full_name,
      };
    });
};

export default () => {
  const dispatch = useDispatch();

  const currentOrganization = useSelector(
    (state) => getCurrentOrganization(state),
    isEqual,
  );

  const organizationView = useSelector(
    (state) => getView(state, "organizationView", currentOrganization.id),
    isEqual,
  );

  const portfolios = useSelector(
    (state) =>
      getResourceAsCollection(
        state,
        "portfolios",
        organizationView.portfolio_ids,
      ),
    isEqual,
  );

  const {
    name: currentOrganizationName,
    username: currentOrganizationUsername,
    is_current_user_organization_owner: isCurrentUserOrganizationOwner,
    active_memberships: activeMemberships,
    partner_memberships: partnerMemberships,
  } = currentOrganization;

  const organizationMemberships = useMemo(
    () => extractActiveMembershipsFromOrganization(currentOrganization),
    [currentOrganization],
  );

  const fetchCurrentUserOrganizationMemberships = () =>
    dispatch(fetchCurrentUserOrganizationMembershipsHelper());

  const { is_admin: isAdmin } = useCurrentUser();

  const createOrganization = (formData) =>
    dispatch(createNewOrganization(formData)).then((organization) => {
      dispatch(setCurrentOrganization(organization));
      return organization;
    });

  const updatePreferences = (organizationId, formData) =>
    dispatch(updateOrganizationPreferences(organizationId, formData));

  return {
    isAdmin,
    activeMemberships,
    partnerMemberships,
    currentOrganization,
    currentOrganizationUsername,
    organizationMemberships,
    currentOrganizationName,
    organizationView,
    isCurrentUserOrganizationOwner,
    fetchCurrentUserOrganizationMemberships,
    createOrganization,
    portfolios,
    updatePreferences,
  };
};
