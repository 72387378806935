import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./ProfileAvatar.scss";

const ProfileAvatar = ({ initials, imageUrl, theme, size, alt }) => {
  const ProfileAvatarClassNames = classNames("ProfileAvatar-content", {
    [`ProfileAvatar-${theme}`]: !!theme,
    [`is-${size}`]: !!size,
    "is-avatarImage": !!imageUrl,
  });
  return (
    <div className="ProfileAvatar">
      <div className={ProfileAvatarClassNames}>
        {imageUrl ? (
          <img className="ProfileAvatar-avatarImage" src={imageUrl} alt={alt} />
        ) : (
          <div
            role="img"
            aria-label={initials}
            className="ProfileAvatar-initials"
          >
            {initials}
          </div>
        )}
      </div>
    </div>
  );
};

ProfileAvatar.propTypes = {
  initials: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  alt: PropTypes.string,
  theme: PropTypes.oneOf(["default", "inverse", "overflow"]).isRequired,
  size: PropTypes.oneOf(["medium", "small", "large", "mini"]).isRequired,
};

ProfileAvatar.defaultProps = {
  theme: "default",
  size: "small",
  imageUrl: null,
};

export default ProfileAvatar;
