import PropTypes from "prop-types";
import React, { Component } from "react";
import Tab from "./Tab";
import classNames from "classnames";
import noop from "lodash/noop";
import {
  navigationTabsItemsProptype,
  navigationTabsGroupHeaderProptype,
} from "PropTypes";

class NavigationTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabHighlightWidth: 0,
      tabHighlightHeight: 0,
      tabHighlightLeftPosition: 0,
      tabHighlightTopPosition: 0,
    };
    this.tabs = [];
  }

  updateTabHighlight({ width, height, left, top }) {
    this.setState({
      tabHighlightWidth: width,
      tabHighlightHeight: height,
      tabHighlightLeftPosition: left,
      tabHighlightTopPosition: top,
    });
  }

  render() {
    const {
      tabHighlightWidth,
      tabHighlightHeight,
      tabHighlightLeftPosition,
      tabHighlightTopPosition,
    } = this.state;

    const {
      orientation,
      items,
      itemSuffix,
      tabHighlightEnabled,
      currentOrganizationUsername,
    } = this.props;

    const tabsClassnames = classNames("NavigationTabs", {
      [`NavigationTabs--${orientation}`]: true,
    });

    const currentTabHighlightSettings =
      orientation === "vertical"
        ? {
            bottom: "auto",
            height: tabHighlightHeight,
            top: tabHighlightTopPosition,
          }
        : {
            width: tabHighlightWidth,
            left: tabHighlightLeftPosition,
            top: "auto",
          };
    return (
      <div className={tabsClassnames}>
        {items.map((item, index) => {
          if (item.groupHeader) {
            return (
              <div className="NavigationTabs-groupHeader" key={index}>
                {item.groupHeader}
              </div>
            );
          }
          const key = `tab-${index}`;
          return (
            <Tab
              isActiveUrl={item.isActiveUrl}
              key={key}
              url={item.url}
              urlAlias={item.urlAlias}
              updateTabHighlight={(tabHighlightSettings) => {
                this.updateTabHighlight(tabHighlightSettings);
              }}
              disabled={item.disabled}
              orientation={orientation}
              currentOrganizationUsername={currentOrganizationUsername}
            >
              {item.children}
            </Tab>
          );
        })}
        {itemSuffix()}
        {tabHighlightEnabled && (
          <div
            ref={(el) => {
              this.tabHighlight = el;
            }}
            className="NavigationTabs-tabHighlight"
            style={currentTabHighlightSettings}
          />
        )}
      </div>
    );
  }
}

NavigationTabs.propTypes = {
  itemSuffix: PropTypes.func,
  orientation: PropTypes.oneOf(["vertical", "horizontal"]),
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      navigationTabsItemsProptype,
      navigationTabsGroupHeaderProptype,
    ]),
  ).isRequired,
  tabHighlightEnabled: PropTypes.bool,
  currentOrganizationUsername: PropTypes.string,
};

NavigationTabs.defaultProps = {
  itemSuffix: noop,
  orientation: "horizontal",
  tabHighlightEnabled: true,
};

export default NavigationTabs;
