import { useMemo } from "react";
import accounting from "accounting";
import formatDate from "lib/formatDate";
import { isEmpty } from "lodash";

const CURRENCY_DEFAULT_OPTIONS = {
  symbol: "$",
  precision: 2,
  format: {
    pos: "%s%v",
    neg: "-%s%v",
    zero: "%s%v",
  },
};

export const useMappedInvestment = ({
  investment,
  currentOrganizationUsername,
}) => {
  const details = useMemo(() => {
    if (!investment.details) {
      return [];
    }
    switch (investment.details.type) {
      // same mapping - fall through
      case "equity":
      case "option":
      case "warrant": {
        return [
          {
            label: "Fund",
            value: investment.details.portfolio_name,
            type: "text",
          },
          {
            label: "Investment Amount",
            value: investment.details.amount,
            type: "currency",
          },
          {
            label: "Price Per Share",
            value: investment.details.unit_cost_basis,
            type: "currency",
          },
          {
            label: "Quantity",
            value: investment.details.quantity,
            type: "quantity",
          },
          { label: "Type of Stock", value: "--", type: "text" }, // TODO: which value?
          { label: "Pre-money Valuation", value: "--", type: "text" }, // TODO: which value?
          {
            label: "Amount Raised in Round",
            value: investment.details.amount_raised_in_round,
            type: "currency",
          },
          {
            label: "Shares in Series",
            value: investment.details.shares_in_series,
            type: "quantity",
          },
          {
            label: "FD Shares Outstanding",
            value: investment.details.fully_diluted_shares,
            type: "quantity",
          },
          {
            label: "Certificate Number",
            value: investment.details.certificate_number,
            type: "quantity",
          },
          {
            label: "Vesting Schedule",
            value: investment.details.vesting_schedule,
            type: "text",
          },
          {
            label: "Vesting Cliff",
            value: investment.details.vesting_cliff_selection,
            type: "text",
          },
          {
            label: "Vesting Terms",
            value: investment.details.vesting,
            type: "text",
          },
          {
            label: "Exercise Price",
            value: investment.details.option_exercise_price,
            type: "currency",
          },
          {
            label: "Vesting Start Date",
            value: investment.details.option_exercise_date,
            type: "date",
          },
          {
            label: "Expiration Date",
            value: investment.details.option_expiration_date,
            type: "date",
          },
        ];
      }
      case "fund": {
        return [
          {
            label: "Fund",
            value: investment.details.portfolio_name,
            type: "text",
          },
          {
            label: "Fund Size",
            value: investment.details.fund_size,
            type: "currency",
          },
          {
            label: "Fund Vintage",
            value: investment.details.fund_vintage,
            type: "text",
          },
          {
            label: "Commitment",
            value: investment.details.amount,
            type: "currency",
          },
          {
            label: "Paid-In Amount",
            value: investment.details.capital_called,
            type: "currency",
          },
          {
            label: "Unfunded Amount",
            value: investment.details.unfunded_amount,
            type: "currency",
          },
        ];
      }
      case "debt": {
        return [
          {
            label: "Fund",
            value: investment.details.portfolio_name,
            type: "text",
          },
          {
            label: "Investment Amount",
            value: investment.details.amount,
            type: "currency",
          },
          {
            label: "Interest Rate",
            value: investment.details.interest_rate,
            type: "percentage",
          },
          {
            label: "Interest Schedule",
            value: investment.details.interest_schedule,
            type: "text",
          },
          {
            label: "Interest Accrual Schedule",
            value: investment.details.interest_accrual_schedule,
            type: "text",
          },
          {
            label: "Qualified Financing Amount",
            value: investment.details.qualified_financing_amount,
            type: "currency",
          },
          {
            label: "Conversion Discount",
            value: investment.details.conversion_discount,
            type: "percentage",
          },
          {
            label: "Conversion Cap",
            value: investment.details.conversion_cap,
            type: "currency",
          },
          {
            label: "Conversion Terms",
            value: investment.details.conversion_terms,
            type: "text",
          },
          {
            label: "Conversion Price",
            value: investment.details.conversion_price,
            type: "currency",
          },
          {
            label: "Conversion Ratio",
            value: investment.details.conversion_ratio,
            type: "currency",
          },
        ];
      }
      case "llc": {
        return [
          {
            label: "Fund",
            value: investment.details.portfolio_name,
            type: "text",
          },
          {
            label: "Acquisition Cost",
            value: investment.details.cost_basis,
            type: "currency",
          },
          {
            label: "Vesting Schedule",
            value: investment.details.vesting_schedule,
            type: "text",
          },
          {
            label: "Vesting Cliff",
            value: investment.details.vesting_cliff_selection,
            type: "text",
          },
          {
            label: "Vesting Terms",
            value: investment.details.vesting,
            type: "text",
          },
          {
            label: "Exercise Price",
            value: investment.details.llc_exercise_price,
            type: "currency",
          },
          { label: "Vesting Start Date", value: "--", type: "text" }, // TODO: which value?
          {
            label: "Expiration Date",
            value: investment.details.llc_expiration_date,
            type: "date",
          },
        ];
      }
      default: {
        return [];
      }
    }
  }, [investment.details]);

  const performance = useMemo(() => {
    if (!investment.details) {
      return [];
    }
    switch (investment.details.type) {
      // same mapping - fall through
      case "equity":
      case "option":
      case "warrant": {
        return [
          {
            label: "Cash Cost",
            value: investment.details.amount,
            type: "currency",
          },
          {
            label: "Cost Basis",
            value: investment.details.cost_basis,
            type: "currency",
          },
          {
            label: "Fair Market Value",
            value:
              investment.details.type === "equity"
                ? investment.details.total_valuation_amount
                : investment.details.fair_value,
            type: "currency",
          },
          {
            label: "Unrealized Gain",
            value: investment.details.gain,
            type: "gainloss",
          },
          {
            label: "Cash Proceeds",
            value: investment.details.gain,
            type: "currency",
          },
          { type: "separator" },
          {
            label: "Total Return",
            value: investment.details.total_gain,
            type: "gainloss",
          },
          {
            label: "Return Multiple",
            value: investment.details.multiple,
            type: "text",
          },
        ];
      }
      case "fund": {
        return [
          {
            label: "Cost Basis",
            value: investment.details.cost_basis,
            type: "currency",
          },
          {
            label: "Fair Market Value",
            value: investment.details.total_valuation_amount,
            type: "currency",
          },
          {
            label: "Unrealized Gain",
            value: investment.details.gain,
            type: "gainloss",
          },
          {
            label: "Cash Proceeds",
            value: investment.details.payout,
            type: "currency",
          },
          { type: "separator" },
          {
            label: "Total Return",
            value: investment.details.total_gain,
            type: "gainloss",
          },
          {
            label: "Return Multiple",
            value: investment.details.multiple,
            type: "multiple",
          },
          {
            label: "TVPI Multiple",
            value: investment.details.tvpi_multiple,
            type: "multiple",
          },
        ];
      }
      case "debt": {
        return [
          {
            label: "Cash Cost",
            value: investment.details.amount,
            type: "currency",
          },
          {
            label: "Cost Basis",
            value: investment.details.cost_basis,
            type: "currency",
          },
          {
            label: "Fair Market Value",
            value: investment.details.fair_value,
            type: "currency",
          },
          {
            label: "Unrealized Gain",
            value: investment.details.gain,
            type: "gainloss",
          },
          {
            label: "Cash Proceeds",
            value: investment.details.payout,
            type: "gainloss",
          },
          { type: "separator" },
          {
            label: "Total Return",
            value: investment.details.total_gain,
            type: "gainloss",
          },
          {
            label: "Return Multiple",
            value: investment.details.multiple,
            type: "multiple",
          },
        ];
      }
      case "llc": {
        return [
          {
            label: "Cash Cost",
            value: investment.details.amount,
            type: "currency",
          },
          {
            label: "Cost Basis",
            value: investment.details.cost_basis,
            type: "currency",
          },
          {
            label: "Fair Market Value",
            value: investment.details.total_valuation_amount,
            type: "currency",
          },
          {
            label: "Unrealized Gain",
            value: investment.details.gain,
            type: "gainloss",
          },
          {
            label: "Cash Proceeds",
            value: investment.details.payout,
            type: "currency",
          },
          { type: "separator" },
          {
            label: "Total Return",
            value: investment.details.total_gain,
            type: "gainloss",
          },
          {
            label: "Return Multiple",
            value: investment.details.multiple,
            type: "multiple",
          },
        ];
      }
      default: {
        return [];
      }
    }
  }, [investment.details]);

  const concernedDescription = useMemo(() => {
    switch (investment.details.type) {
      // same mapping - fall through
      case "equity":
      case "option":
      case "warrant": {
        const formattedQuantity = accounting.formatNumber(
          investment.details.quantity,
        );
        const formattedPercentage = !!investment.details
          .fully_diluted_shares_percentage
          ? `(${accounting.toFixed(
              investment.details.fully_diluted_shares_percentage,
              2,
            )}%)`
          : "";
        const formattedUnits = investment.details.unit + "s";
        return `${formattedQuantity} ${formattedPercentage} ${formattedUnits}`;
      }
      case "debt":
      case "fund": {
        return `${accounting.formatMoney(
          investment.details.amount,
          CURRENCY_DEFAULT_OPTIONS,
        )} ${
          investment.details.type === "fund"
            ? "commitment"
            : investment.details.type_label
        }`;
      }
      case "llc": {
        return `${
          investment.details.quantity
            ? investment.details.quantity + " Units"
            : investment.details.percentage_owned || 0 + "%"
        }`;
      }
      default: {
        return null;
      }
    }
  }, [investment.details]);

  const concernedTypeLabel = useMemo(() => {
    if (!investment.details) {
      return "";
    }
    switch (investment.details.type) {
      case "fund": {
        return "Commitment";
      }
      case "llc": {
        return "Ownership";
      }
      default: {
        return investment.details.type_label;
      }
    }
  }, [investment.details]);

  const valuationHistory = useMemo(() => {
    if (
      !investment.valuationHistory ||
      investment.valuationHistory.length === 0
    ) {
      return [];
    }
    return investment.valuationHistory.map((item) => {
      return [
        {
          value: item.date,
          valueType: "date",
          valueOptions: { dateFormat: "default" },
        },
        { value: item.note || "--" },
        {
          value: item.amount,
          valueType: "currency",
          align: "right",
          valueOptions: { precision: "2" },
        },
      ];
    });
  }, [investment.valuationHistory]);

  const capitalCallHistory = useMemo(() => {
    if (
      !investment.capitalCallHistory ||
      investment.capitalCallHistory.length === 0 ||
      !investment.details
    ) {
      return { data: [], footerData: [], footNote: null };
    }
    const sortedCapitalCallHistory = investment.capitalCallHistory.sort(
      (a, b) => Date.parse(b.date) - Date.parse(a.date),
    );
    let paidInAccumulator = investment.details.capital_called;
    let lastPaidIn = 0;
    const data = sortedCapitalCallHistory.map((item, index) => {
      const totalPaidIn = paidInAccumulator - lastPaidIn;
      paidInAccumulator = totalPaidIn;
      lastPaidIn = parseFloat(item.amount);
      return [
        {
          value: sortedCapitalCallHistory.length - index,
          valueType: "text",
        },
        {
          value: item.date,
          valueType: "date",
          valueOptions: { dateFormat: "default" },
        },
        {
          value: item.amount,
          valueOptions: { precision: 2 },
          valueType: "currency",
          align: "right",
        },
        // percent commitment
        {
          value:
            (totalPaidIn / investment.details.total_acquisition_amount) * 100,
          valueType: "percentage",
          align: "right",
        },
        {
          value: totalPaidIn,
          valueType: "currency",
          valueOptions: { precision: 2 },
          align: "right",
        },
      ];
    });
    const footerData = [
      [
        { colspan: 3 },
        {
          value: "TOTAL*",
          valueType: "text",
          align: "right",
          valueLight: false,
        },
        {
          value: investment.details.capital_called,
          valueType: "currency",
          align: "right",
          valueLight: false,
          valueOptions: { precision: 2 },
        },
      ],
    ];
    const footNote = `*${parseFloat(
      investment.details.percent_called,
    )}% of initial commitment`;
    return { data, footerData, footNote };
  }, [investment.capitalCallHistory, investment.details]);

  const distributionHistory = useMemo(() => {
    if (
      !investment.distributionHistory ||
      investment.distributionHistory.length === 0
    ) {
      return { data: [], footerData: [] };
    }
    const sortedDistributionHistory = investment.distributionHistory.sort(
      (a, b) => Date.parse(b.date) - Date.parse(a.date),
    );
    const data = sortedDistributionHistory.map((item) => {
      const amount = item.is_equity_proceed
        ? item.equity.total_valuation_amount
        : item.cash_amount;
      return [
        {
          value: item.date,
          valueType: "date",
          valueOptions: { dateFormat: "default" },
        },
        {
          value: item.is_equity_proceed ? item.equity.name : "Cash Proceeds",
        },
        {
          value: item.is_equity_proceed ? item.equity.quantity : "N/A",
          valueType: "text",
          align: "right",
        },
        {
          value: amount,
          valueType: "currency",
          valueOptions: { precision: 2 },
          align: "right",
        },
      ];
    });
    const distributionTotal = sortedDistributionHistory.reduce((acc, curr) => {
      const currAmount = curr.is_equity_proceed
        ? curr.equity.total_valuation_amount
        : curr.cash_amount;
      return acc + parseFloat(currAmount);
    }, 0);
    const footerData = [
      [
        { colspan: 2 },
        {
          value: "TOTAL",
          align: "right",
          valueLight: false,
        },
        {
          value: distributionTotal,
          valueType: "currency",
          align: "right",
          valueOptions: { precision: 2 },
          valueLight: false,
        },
      ],
    ];
    return { data, footerData };
  }, [investment.distributionHistory]);

  const certificateHistory = useMemo(() => {
    if (
      !investment.certificateHistory ||
      investment.certificateHistory.length === 0
    ) {
      return [];
    }
    return investment.certificateHistory.map((item) => {
      return [
        {
          value: item.description || "--",
          valueLight: false,
          subValue: item.date,
          subValueType: "date",
        },
        {
          value: item.child_investment?.name,
          valueType: "link",
          valueOptions: {
            href: `/${currentOrganizationUsername}/investments/${item.child_investment?.id}`,
            showIcon: true,
          },
        },
        {
          value: item.child_investment?.certificate_number || "--",
        },
        { value: item.child_investment?.quantity || "--", align: "right" },
        {
          value: item.child_investment?.unit_cost_basis || "--",
          valueType: "currency",
          align: "right",
        },
      ];
    });
  }, [currentOrganizationUsername, investment.certificateHistory]);

  const customFields = useMemo(() => {
    if (
      !investment.details.custom_fields ||
      investment.details.custom_fields.length === 0
    ) {
      return [];
    }
    return Object.values(investment.details.custom_fields).map((item) => {
      return {
        type: item.field_type,
        value: item.field_value,
        index: item.id,
        label: item.field_label,
      };
    });
  }, [investment.details.custom_fields]);

  const referrers = useMemo(() => {
    if (!investment.referrers || investment.referrers.length === 0) {
      return [];
    }

    return investment.referrers.map((item) => {
      const currentReferralInvestment = item.referrals.find(
        (referral) => referral.id === investment.details.id,
      );
      const date = isEmpty(currentReferralInvestment)
        ? item.last_referral_date
        : currentReferralInvestment.created_at;
      return {
        // imageUrl: null, // TODO: contact has no imageURL
        name: item.full_name,
        subtitle: `Referred on ${formatDate(date, "semiDetailed")}`,
      };
    });
  }, [investment.referrers, investment.details.id]);

  const files = useMemo(() => {
    if (!investment.details.id || !currentOrganizationUsername) {
      return null;
    }

    return {
      documents: investment.documents,
      investmentId: investment.details.id,
      currentOrganizationUsername,
    };
  }, [
    investment.details.id,
    investment.documents,
    currentOrganizationUsername,
  ]);

  return {
    details,
    performance,
    valuationHistory,
    concernedDescription,
    concernedTypeLabel,
    capitalCallHistory,
    distributionHistory,
    certificateHistory,
    customFields,
    referrers,
    files,
  };
};
