export default function middleEllipsis(text, maxCharsLengthFileName = 35) {
  const totalChars = text.length;
  if (totalChars <= maxCharsLengthFileName) {
    return text;
  }

  const maxTotalCroppeableTextCharsPosition = totalChars - 1;
  const middleCroppeableTextPosition = parseInt(totalChars / 2, 10);
  const startInitialCropPosition = 0;
  const endRemainginCropPosition = maxTotalCroppeableTextCharsPosition;
  const diffChars =
    totalChars - maxCharsLengthFileName > 0
      ? totalChars - maxCharsLengthFileName
      : 0;
  const diffPosition = parseInt(diffChars / 2, 10);
  const endInitialCropPosition =
    middleCroppeableTextPosition - diffPosition - 2;
  const startRemainingCropPosition =
    middleCroppeableTextPosition + diffPosition + 2;
  const initialText = text.slice(
    startInitialCropPosition,
    endInitialCropPosition,
  );
  const remainingText = text.slice(
    startRemainingCropPosition,
    endRemainginCropPosition,
  );
  return `${initialText}...${remainingText}`;
}
