import React, { useState } from "react";
import preventerHandler from "lib/preventerHandler";
import TextInput from "components/shared/form/TextInput";
import classNames from "classnames";
import PhoneNumberInput from "components/shared/form/PhoneNumberInput";
import PrimaryButton from "components/shared/PrimaryButton";
import SecondaryButton from "components/shared/SecondaryButton";
import useForm from "lib/hooks/useForm";
import { useDispatch } from "react-redux";
import { closeModal, openInModal, ModalNames } from "store/modal";
import { sendValidationCode } from "store/users/asyncAuth/actions";
import { updatePassword as updatePasswordAction } from "store/users/actions";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import getMessage from "components_new/lib/Messages";

function LoginInformationView({
  currentPhoneNumber,
  currentPassword,
  cancelEdit,
}) {
  const dispatch = useDispatch();
  const { addToast } = useSetToast();
  const { formData, handleChange, handleErrors, errors } = useForm({
    formData: {},
  });

  const [phoneNumber, setPhoneNumber] = useState(currentPhoneNumber || "");
  const [isSubmitting, setIsSubmiting] = useState(false);
  const [isPhoneNunberValid, setPhoneNunberValid] = useState(false);

  const updatePhoneNumber = (value, name, isValid) => {
    setPhoneNumber(value);
    setPhoneNunberValid(isValid);
  };

  function isPhoneNumberUpdated() {
    return currentPhoneNumber !== phoneNumber && isPhoneNunberValid;
  }

  function hasNewPassword() {
    const { new_password, new_password_confirmation } = formData;
    return (
      new_password &&
      new_password_confirmation &&
      new_password.length > 0 &&
      new_password_confirmation.length > 0
    );
  }

  const openVerifyPhoneNumberModal = () => {
    dispatch(
      openInModal(ModalNames.updatePhoneNumber, {
        newPhoneNumber: phoneNumber,
      }),
    );
  };

  const updatePassword = (formData) => {
    return dispatch(updatePasswordAction(formData)).then(() => {
      dispatch(closeModal());
      addToast(getMessage("updatePassword"));
    });
  };

  const sendCode = (phoneNumber) => {
    return dispatch(sendValidationCode(phoneNumber));
  };

  const submitPasswordChange = () => {
    if (hasNewPassword()) {
      setIsSubmiting(true);
      updatePassword({ ...formData, current_password: currentPassword })
        .then(() => {
          setIsSubmiting(false);
        })
        .catch((error) => {
          setIsSubmiting(false);
          handleErrors(error);
        });
    }
  };

  const submitPhoneNumber = () => {
    if (isPhoneNumberUpdated()) {
      sendCode(phoneNumber).then(() => {
        openVerifyPhoneNumberModal(phoneNumber);
      });
    }
  };

  const handleSubmit = () => {
    submitPasswordChange();
    submitPhoneNumber();
  };

  const isFormValid = () => {
    return hasNewPassword() || isPhoneNumberUpdated();
  };

  const formClassnames = classNames({
    "submitting-form": isSubmitting,
  });
  return (
    <div className="LoginInformationForm">
      <form
        onSubmit={preventerHandler(() => handleSubmit())}
        className={formClassnames}
      >
        <h2 className="FormSection-title u-sectionHeading">
          Login Information
        </h2>
        <div className="FormSection-Body">
          <TextInput
            label="New password"
            onChange={(value) => handleChange("new_password", value)}
            errorMessage={errors.for("new_password")}
            typeOfInput="password"
          />
          <TextInput
            label="Confirm new password"
            onChange={(value) =>
              handleChange("new_password_confirmation", value)
            }
            errorMessage={errors.for("new_password_confirmation")}
            typeOfInput="password"
          />
          <PhoneNumberInput
            autoFocus
            extraClassnames="PhoneNumberField"
            value={phoneNumber}
            onChange={updatePhoneNumber}
            key={phoneNumber}
          />
        </div>
        <div className="FormSection-Footer">
          <PrimaryButton
            customClass="FormElement FormElement--SubmitButton"
            disabled={!isFormValid()}
            type="submit"
            filter={{
              applyingFilter: isSubmitting,
            }}
          >
            Save Changes
          </PrimaryButton>
          <SecondaryButton onClick={cancelEdit}>Cancel</SecondaryButton>
        </div>
      </form>
    </div>
  );
}

export default LoginInformationView;
