import { connect } from "react-redux";
import { getSearchResults, performSearch, clearSearch } from "store/search";
import SearchAndSelect from "./SearchAndSelect";
import { debounce } from "lodash";

function mapStateToProps(
  state,
  { resourceType = "resources", excludedCompanies = [] },
) {
  const { search } = state;
  const { count, query, shouldDisplayResults, isFetching } = search;
  const companies = getSearchResults(state, "companies").filter(
    (company) => !excludedCompanies.includes(company.id),
  );
  const list =
    resourceType === "referrer"
      ? [{ data: getSearchResults(state, "contacts"), title: "Contacts" }]
      : [
          { data: companies, title: "My Companies" },
          {
            data: getSearchResults(state, "investments"),
            title: "Investments",
          },
          { data: getSearchResults(state, "deals"), title: "Deals" },
        ];

  return {
    count,
    query,
    shouldDisplayResults,
    isFetching,
    list: list || [],
  };
}

function mapDispatchToProps(dispatch, { scope = "all" }) {
  return {
    performSearch: debounce((query) => {
      return dispatch(performSearch(scope, query));
    }, 300),
    clearSearch: () => dispatch(clearSearch()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchAndSelect);
