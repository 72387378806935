import { RECEIVE_COLLECTION, CLEAR_COLLECTION } from "./actions.js";
import { keyBy, property, union } from "lodash";

const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_COLLECTION:
      return {
        ...state,
        [action.name]: upsertEntities(state, action.name, action.data),
      };
    case CLEAR_COLLECTION:
      return {
        ...state,
        [action.name]: initialState,
      };
    default:
      return state;
  }
}

function upsertEntities(state, name, data) {
  if (state[name]) {
    return {
      byId: { ...state[name].byId, ...keyBy(data, property("id")) },
      ids: union(state[name].ids, data.map(property("id"))),
    };
  } else {
    return {
      byId: keyBy(data, property("id")),
      ids: data.map(property("id")),
    };
  }
}
