import React from "react";
import { tagProptype } from "PropTypes";
import PropTypes from "prop-types";
import SuggestionActions from "./SuggestionAction";

function Suggestion({
  suggestion,
  position,
  highlightItem,
  onClickSuggestion,
  isHighlighted,
  onEdit,
  onDelete,
  actionAfterUpdate,
  removeTag,
}) {
  return (
    <div
      data-position={position}
      data-index={suggestion.id}
      data-value={suggestion.name}
      onMouseEnter={(event) => highlightItem(event.currentTarget)}
      className="InputTag-actions-item Suggestions-item"
    >
      <div
        className="InputTag-actions-value"
        onClick={() => onClickSuggestion(suggestion)}
      >
        {suggestion.name}
      </div>
      {isHighlighted && (
        <SuggestionActions
          suggestion={suggestion}
          onEdit={onEdit}
          onDelete={onDelete}
          actionAfterUpdate={actionAfterUpdate}
          removeTag={removeTag}
        />
      )}
    </div>
  );
}

Suggestion.propTypes = {
  suggestion: tagProptype.isRequired,
  position: PropTypes.number.isRequired,
  highlightItem: PropTypes.func.isRequired,
  onClickSuggestion: PropTypes.func.isRequired,
  isHighlighted: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  actionAfterUpdate: PropTypes.func.isRequired,
  removeTag: PropTypes.func.isRequired,
};

export default Suggestion;
