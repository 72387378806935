import React from "react";
import PropTypes from "prop-types";
import Verified from "-!svg-react-loader?!assets/icons/miscellaneous/verified.svg";
import classnames from "classnames";

const VerifiedBadge = ({ extraClassnames = "", size = "default" }) => {
  const verifiedBadgeClassnames = classnames("VerifiedBadge", {
    "VerifiedBadge--default": size === "default",
    "VerifiedBadge--small": size === "small",
    "VerifiedBadge--large": size === "large",
    "VerifiedBadge--bigger": size === "bigger",
    [extraClassnames]: !!extraClassnames,
  });
  return (
    <Verified data-testid="VerifiedBadge" className={verifiedBadgeClassnames} />
  );
};

VerifiedBadge.propTypes = {
  extraClassnames: PropTypes.string,
  size: PropTypes.oneOf(["small", "default", "large", "bigger"]),
};

export default VerifiedBadge;
