import formatPrice from "lib/formatters/formatPrice";
import { isEmpty } from "lodash";

export default function configureDealData(deal) {
  const {
    round_name,
    amount_raised_in_round,
    company_valuation,
    owner_name,
    status,
  } = deal;

  const EMPTY = "EMPTY";

  const roundNameValue = !isEmpty(round_name) ? round_name : EMPTY;
  const amountRaisedInRoundValue = !isEmpty(amount_raised_in_round)
    ? formatPrice(amount_raised_in_round, { precision: 0 })
    : EMPTY;
  const companyValuationValue = !isEmpty(company_valuation)
    ? formatPrice(company_valuation, { precision: 0 })
    : EMPTY;
  const ownerNameValue = !isEmpty(owner_name) ? owner_name : EMPTY;
  const items = {
    roundName: {
      label: "Round Name",
      value: roundNameValue,
    },
    raising: {
      label: "Raising",
      value: amountRaisedInRoundValue,
    },
    valuation: {
      label: "Valuation",
      value: companyValuationValue,
    },
    owner: {
      label: "Owner",
      value: ownerNameValue,
    },
  };
  switch (status) {
    case "invested":
      return [items.roundName, items.raising, items.valuation, items.owner];
    default:
      return [];
  }
}
