import React from "react";
import DashboardHighlights from "./DashboardHighlights";
import CashFlowPerformanceChart from "components/shared/CashFlowPerformanceChart";
import DashboardChartsLayout from "./DashboardChartsLayout";
import InvestmentPaceChart from "components/shared/InvestmentPaceChart";
import DashboardActivityFeed from "../DashboardActivityFeed";

function DashboardView({
  chartType,
  highlights,
  investmentPaceItems,
  cashFlowPerformanceItems,
}) {
  return (
    <div className="u-row Dashboard">
      <div className="DashboardLayout">
        <div className="DashboardLayoutColumn DashboardLayoutColumn--DashboardCharts">
          <DashboardChartsLayout>
            {chartType === "portfolio-performance" ? (
              <div className="u-row InvestmentCharts CashFlowPerformanceChart">
                <CashFlowPerformanceChart
                  cashFlowPerformance={cashFlowPerformanceItems}
                />
              </div>
            ) : (
              <div className="u-row InvestmentCharts">
                <InvestmentPaceChart investmentPace={investmentPaceItems} />
              </div>
            )}
          </DashboardChartsLayout>
        </div>
        <div className="DashboardLayoutColumn DashboardLayoutColumn--DashboardHighlights">
          <DashboardHighlights {...highlights} />
        </div>
        <div className="DashboardLayoutColumn DashboardLayoutColumn--DashboardActivityFeed">
          <DashboardActivityFeed />
        </div>
      </div>
    </div>
  );
}

export default DashboardView;
