import Api from "lib/Api";
import {
  mutationStart,
  mutationFail,
  mutationSuccess,
  collectionFetchStart,
  collectionFetchSuccess,
  collectionFetchFail,
} from "store/domain/domainActions";
import { fetchPortfolioV2 } from "store/portfolios/actions";
import { fetchCompanyV2 } from "store/companies/company/actions";
import { isInCurrentView } from "store/investments/investment/actions";
import { fetchCompanyCashFlow } from "store/companies/companyCashFlow/actions";
import { receiveCollections } from "store/entities";
import { receiveView, updateView } from "store/views";

export function fetchCapitalCalls(investmentId) {
  return (dispatch, getState) => {
    dispatch(collectionFetchStart("capitalCalls"));
    return Api.get(`/investments/${investmentId}/capital_calls`).then(
      ({
        data: {
          entities,
          views: {
            investment: { document_ids },
          },
        },
      }) => {
        const { capital_call_histories: capitalCallHistories } = entities;
        const {
          views: { investment: investmentView },
        } = getState();
        if (isInCurrentView(investmentView, investmentId)) {
          dispatch(
            receiveView("investment", { ...investmentView, document_ids }),
          );
        }
        const capitalCalls = capitalCallHistories[0].capital_calls;
        dispatch(receiveCollections(entities));
        dispatch(collectionFetchSuccess("capitalCalls", capitalCalls));
        return capitalCalls;
      },
      () => {
        dispatch(collectionFetchFail("capitalCalls"));
      },
    );
  };
}

function addDocumentsToInvesmentView(dispatch, documents) {
  documents.forEach(({ id }) =>
    dispatch(updateView("investment", "document_ids", id)),
  );
}

export function createCapitalCall(investmentId, formData) {
  return (dispatch, getState) => {
    dispatch(mutationStart("createCapitalCall"));
    return Api.post(`/investments/${investmentId}/capital_calls`, {
      capital_call: formData,
    })
      .then(({ data: { entities } }) => {
        const [investment] = entities.investment_details;
        const {
          views: {
            investment: investmentView,
            company: companyView,
            portfolio: portfolioView,
          },
        } = getState();

        dispatch(receiveCollections(entities));
        dispatch(mutationSuccess("createCapitalCall"));

        if (isInCurrentView(investmentView, investment.id)) {
          const { documents } = entities;
          if (documents.length > 0) {
            addDocumentsToInvesmentView(dispatch, entities.documents);
          }
        }

        if (isInCurrentView(companyView, investment.company_id)) {
          dispatch(fetchCompanyV2(investment.company_id));
          dispatch(fetchCompanyCashFlow(investment.company_id));
        }

        if (isInCurrentView(portfolioView, investment.portfolio_id)) {
          dispatch(fetchPortfolioV2(investment.portfolio_id));
        }

        return investment;
      })
      .catch((error) => {
        dispatch(mutationFail("createCapitalCall"));
        throw error;
      });
  };
}

export function bulkCapitalCallUpdate(investmentId, payload) {
  return (dispatch) => {
    return Api.patch(`/investments/${investmentId}/capital_calls/`, {
      operations: payload,
    }).then(({ data }) => {
      const { entities } = data;
      if (entities) {
        const { documents } = entities;

        data.success = true;
        dispatch(receiveCollections(entities));
        if (documents.length > 0) {
          addDocumentsToInvesmentView(dispatch, entities.documents);
        }
      }

      return data;
    });
  };
}
