import React, { useState } from "react";
import Form from "components_new/forms/Form";
import Button from "components_new/atoms/Button";
import Header from "components_new/atoms/Header";
import useForm from "lib/hooks/useForm";
import RequiredNote from "components_new/SidebarComponents/shared/RequiredNote";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import getMessage from "components_new/lib/Messages";
import Documents from "components_new/SidebarComponents/forms/groups/Documents";
import DataList from "components_new/elements/DataList";
import { roundNameOptions } from "components_new/elements/DataList/config";
import CurrencyInput from "components_new/atoms/CurrencyInput";
import DatePicker from "components_new/atoms/DatePicker";
import formatDate from "lib/formatDate";
import { valueDateFormat } from "config/dateInput";
import DropdownForm from "components_new/atoms/DropdownForm";
import SuggestedButton from "components_new/elements/SuggestedButton";
import SplitSelect from "components_new/atoms/SplitSelect";
import CompanyInformation from "components_new/forms/shared/CompanyInformation";
import Investor from "components_new/forms/shared/Investor";
import Referrer from "components_new/forms/shared/Referrer";
import DealCompanyCard from "components_new/views/Deals/ActiveDeals/ActiveDealContent/DealCompanyCard";
import Text from "components_new/atoms/Text";
import TextArea from "components_new/atoms/TextArea";
import classnames from "classnames";
import { isEmpty } from "lodash";
import "./DealForm.scss";

const companyValuationTypes = [
  { key: "Pre-money", value: "Pre-money", text: "Pre-money" },
  { key: "Post-money", value: "Post-money", text: "Post-money" },
];

function DealForm({
  searchContact,
  currentOrganizationUserName,
  organizationMemberships,
  pipelineStagesOptions,
  currentUserID,
  closeSidebar,
  submitAction,
  isEditing,
  toogleSize,
  currentSize,
  openEditCompanyForm,
  prefilledDeal,
  prefilledCompany,
  prefilledContacts,
  prefilledDocuments,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useSetToast();

  const onSubmit = (clientData) => {
    setIsLoading(true);
    submitAction(clientData)
      .then(
        ({ data: { deal } }) => {
          const toastMessage = isEditing ? "updatedDeal" : "addedDeal";
          closeSidebar();
          addToast(
            getMessage(toastMessage, {
              url: `/${currentOrganizationUserName}/deals/active/${deal.id}`,
            }),
          );
        },
        (errors) => {
          handleErrors(errors);
          handleScrollView(errors);
        },
      )
      .finally(() => setIsLoading(false));
  };

  const [firstStage] = pipelineStagesOptions.sort(
    (stageA, stageB) => stageA.order - stageB.order,
  );

  const initialValues = {
    formData: {
      owner_id: currentUserID,
      stage_id: firstStage && firstStage.value,
      company_valuation_type: "Pre-money",
      round_name: "",
      allocation: "",
      mount_raised_in_round: null,
      company_valuation: null,
      company: prefilledCompany || {
        name: "",
        headquarters: "",
        tags: [],
        description: "",
        founders: "",
        website: "",
        logo_url: null,
        interesting: null,
        double_down: null,
        stealth: null,
        isNewCompany: false,
      },
      contacts_attributes: [],
      type: "deal",
      post: "",
      investors: [],
      documents_data: isEditing ? prefilledDocuments : [],
      ...(isEditing && prefilledDeal),
    },
  };

  const {
    formData,
    handleChange,
    handleSubmit,
    handleErrors,
    handleScrollView,
    errors,
  } = useForm(initialValues, onSubmit);

  const dealFormClassnames = classnames("DealForm", {
    "is-wide": currentSize === "wide",
  });

  const selectedCompanyValuationType = companyValuationTypes.find(
    (type) => type.value === formData.company_valuation_type,
  );

  const submitTextButton = !isEditing ? "Save Deal" : "Save Changes";
  const headerText = isEditing ? "Edit deal" : "Add New Deal";
  const isEditingAndRegular = isEditing && currentSize == "regular";

  return (
    <Form extraClassName={dealFormClassnames}>
      <Form.Header onClose={closeSidebar} onToogle={toogleSize}>
        <Header as="h3">{headerText}</Header>
      </Form.Header>
      <Form.Content>
        <div className="DealForm-content">
          {isEditingAndRegular && !isEmpty(formData.company) && (
            <DealCompanyCard
              company={formData.company}
              openEditCompanyForm={openEditCompanyForm}
            />
          )}
          <DataList
            name="round_name"
            label="Round"
            placeholder="Round"
            defaultValue={formData.round_name}
            size="tiny"
            options={roundNameOptions}
            onChange={(value) => {
              handleChange("round_name", value);
            }}
          />
          <CurrencyInput
            name="allocation"
            label="Allocation"
            decimalLimit={2}
            placeholder="0.00"
            iconPosition="left"
            value={formData.allocation}
            size="tiny"
            onChange={(value) => {
              handleChange("allocation", value);
            }}
          />
          <DropdownForm
            name="stage_id"
            label="Stage *"
            placeholder="Select pipeline stage"
            items={pipelineStagesOptions}
            onChange={(_, { value }) => handleChange("stage_id", value)}
            value={formData.stage_id}
            size="tiny"
          />
          <SuggestedButton
            label="Follow on?"
            onAdd={() => handleChange("is_follow_on", true)}
            onRemove={() => handleChange("is_follow_on", false)}
            isSelected={formData.is_follow_on}
          >
            Follow-on
          </SuggestedButton>
          <DatePicker
            name="decision_due_date"
            extraClassnames="FormElement FormElement--Date"
            label="Due date"
            placeholder="Decision due date"
            value={formatDate(formData.decision_due_date)}
            error={!!errors.for("decision_due_date")}
            errorMessage={errors.for("decision_due_date")}
            onChange={(value) =>
              handleChange(
                "decision_due_date",
                formatDate(value, valueDateFormat),
              )
            }
            oldVersion
            size="tiny"
          />
          <CurrencyInput
            name="amount_raised_in_round"
            label="Round Raise"
            decimalLimit={2}
            placeholder="0.00"
            iconPosition="left"
            value={formData.mount_raised_in_round}
            size="tiny"
            onChange={(value) => {
              handleChange("amount_raised_in_round", value);
            }}
          />
          <SplitSelect
            inputProps={{
              name: "company_valuation",
              label: "Valuation",
              decimalLimit: 2,
              placeholder: "0.00",
            }}
            dropdownProps={{
              options: companyValuationTypes,
              defaultValue: selectedCompanyValuationType,
            }}
            onChange={(_, { input, selection }) => {
              handleChange("company_valuation", input);
              if (selection) {
                handleChange("company_valuation_type", selection.value);
              }
            }}
            value={formData.company_valuation}
            size="tiny"
          />
          {!isEditingAndRegular && (
            <CompanyInformation
              onChange={handleChange}
              company={formData.company}
              errors={errors}
              size={currentSize}
              isEditing={isEditing}
            />
          )}
          <Referrer
            prefilledContacts={prefilledContacts}
            handleChange={handleChange}
            searchContact={searchContact}
          />
          <DropdownForm
            name="owner_id"
            label="Owner *"
            placeholder="Please choose"
            items={organizationMemberships}
            onChange={(_, { value }) => handleChange("owner_id", value)}
            value={formData.owner_id}
            size="tiny"
          />
          <Investor
            label="Investors"
            investors={formData.investor_companies}
            placeholder="Add your investors"
            onChange={(values) => handleChange("investors", values)}
          />
          <DatePicker
            name="date"
            extraClassnames="FormElement FormElement--Date Date-Received"
            label="Received *"
            placeholder="Date received"
            value={formatDate(formData.date)}
            error={!!errors.for("date")}
            errorMessage={errors.for("date")}
            onChange={(value) =>
              handleChange("date", formatDate(value, valueDateFormat))
            }
            prefillToday={true}
            oldVersion
            size="tiny"
          />
          {isEditing && (
            <div className="ui form lastUpdate">
              <div className="field">
                <label>Last updated</label>
                <Text type="body-2-bold">
                  {formatDate(formData.updated_at, "semiDetailed")}
                </Text>
              </div>
            </div>
          )}
        </div>
        {!isEditing && (
          <TextArea
            extraClassnames="field AddNote"
            name="note"
            label="Note"
            placeholder="Leave an initial note on this deal..."
            onChange={(_, { value }) => {
              handleChange("post", value);
            }}
            defaultValue={formData.post}
            size="tiny"
          />
        )}
        <Documents
          isExpanded={true}
          subtitle="Information relevant to closing activities, for tax purposes, etc"
          onChange={(documents) => handleChange("documents_data", documents)}
          documents={formData.documents_data}
        />
        <RequiredNote />
      </Form.Content>
      <Form.Footer>
        <Button primary loading={isLoading} onClick={handleSubmit} size="small">
          {submitTextButton}
        </Button>
        <Button
          onClick={closeSidebar}
          disabled={isLoading}
          secondary
          type="button"
          size="small"
        >
          Cancel
        </Button>
      </Form.Footer>
    </Form>
  );
}

export default DealForm;
