import React from "react";
import moment from "moment";
import { getText } from "TermsMapper";
import formatDate from "lib/formatDate";
import {
  perUnitNumberPrecision,
  defaultNumberPrecision,
} from "config/priceInput";
import {
  placeholderDateFormatLowerCase,
  valueDateFormat,
} from "config/dateInput";
import DatePicker from "components_new/atoms/DatePicker";
import PriceInput from "components/shared/form/PriceInput";
import TextInput from "components/shared/form/TextInput";
import isValidNumberInput from "lib/formatters/isValidNumberInput";
import { formatPriceInputPreview } from "lib/abbreviatedFormat";
import accounting from "accounting";

export function valuationEmptyData() {
  return {
    amount: "",
    date: moment().format(valueDateFormat),
    note: "",
    id: null,
    given_price_per_unit: "",
  };
}

export function canLockItem(item) {
  return (
    item.previous_holding ||
    item.id === item.initial_valuation_id ||
    item.historical
  );
}

// NOTE: Only for Valuation we NEED to send the given_price_per_unit and price_per_unit every time because is a virtual data
function sendPricePerUnitToUpdateForm(data, index, updateForm) {
  return updateForm(
    "given_price_per_unit",
    data.given_price_per_unit,
    index,
    data.id,
  );
}

function calculateMarketValue(amount, valuationMultiplier) {
  return amount !== null ? amount * valuationMultiplier : null;
}

export function showMarketValue(
  amount,
  historical,
  pricePerUnit,
  quantity,
  investment,
) {
  let marketValue = isValidNumberInput(amount)
    ? amount >= 0 && formatPriceInputPreview(amount)
    : "";

  if (!historical && investment.quantity) {
    // Only for non historical valuations
    // We need the numeric version of price_per_unit in order to calculate marketValue correctly
    const pricePerUnitNumeric = accounting.unformat(pricePerUnit);
    const valuationMultiplier = quantity;
    const calcMarketValue = calculateMarketValue(
      pricePerUnitNumeric,
      valuationMultiplier,
    );
    marketValue = isValidNumberInput(calcMarketValue)
      ? formatPriceInputPreview(calcMarketValue)
      : "";
  }
  return marketValue;
}

function showFormatAmount(amount, typeOfPrecision) {
  return isValidNumberInput(amount)
    ? formatPriceInputPreview(amount, {
        precision: typeOfPrecision,
      })
    : "";
}

function adjustErrorMessage(message, investmentType) {
  if (!message) {
    return;
  }
  return message.replace("per unit", investmentType);
}

export function createValuationsColumns(investment) {
  const investmentType = `per ${getText(investment.type, "valuation_unit")}`;

  const pricePerType = {
    text: `Price ${investmentType}`,
    classNames: "Table--row-item ValuationRow--price",
    alignment: "u-rightAlignedText",
    renderValue: (data) => (
      <div className="perShareValue">
        <span className="u-contentNumerical">
          {showFormatAmount(data.price_per_unit, perUnitNumberPrecision)}
        </span>
        <div>price {investmentType}</div>
      </div>
    ),
    renderForm: (data, updateForm, index, errors) => (
      <PriceInput
        name="price_per_unit"
        extraClassnames="FormElement--PricePerUnit"
        onChange={(value) => {
          updateForm("price_per_unit", value, index, data.id);
          updateForm("given_price_per_unit", value, index, data.id);
        }}
        value={data.price_per_unit}
        errorMessage={adjustErrorMessage(
          errors.for("given_price_per_unit"),
          investmentType,
        )}
        precision={perUnitNumberPrecision}
        alignTo="right"
        size="small"
      />
    ),
  };

  const previewMarketValue = {
    text: "Market Value",
    classNames: "Table--row-item ValuationRow--value",
    alignment: "u-rightAlignedText",
    renderValue: (data) => (
      <span className="u-contentNumerical">
        {showMarketValue(
          data.amount,
          investment.historical,
          data.price_per_unit,
          data.unit_count,
          investment,
        )}
      </span>
    ),
    renderForm: (data) => (
      <TextInput
        extraClassnames="FormElement--Amount"
        name="amount"
        value={showMarketValue(
          data.amount,
          investment.historical,
          data.price_per_unit,
          data.unit_count,
          investment,
        )}
        readOnly
        alignTo="right"
        size="small"
      />
    ),
  };

  const amount = {
    text: "Amount",
    classNames: "Table--row-item ValuationRow--amount",
    alignment: "u-rightAlignedText",
    renderValue: (data) => (
      <span className="u-contentNumerical">
        {showFormatAmount(data.amount, defaultNumberPrecision)}
      </span>
    ),
    renderForm: (data, updateForm, index, errors) => (
      <PriceInput
        name="amount"
        onChange={(value) => {
          updateForm("amount", value, index, data.id);
          sendPricePerUnitToUpdateForm(data, index, updateForm);
        }}
        value={data.amount}
        errorMessage={errors.for("amount")}
        precision={defaultNumberPrecision}
        readOnly={!!investment.quantity}
        alignTo="right"
        size="small"
      />
    ),
  };

  const columns = [
    {
      text: "Date",
      classNames: "Table--row-item ValuationRow--date",
      alignment: "u-leftAlignedText ",
      renderValue: (data) => moment(data.date).format("ll"),
      renderForm: (data, updateForm, index, errors) => (
        <DatePicker
          name="date"
          placeholder={placeholderDateFormatLowerCase}
          value={formatDate(data.date)}
          onChange={(value) => {
            updateForm("date", value, index, data.id);
            sendPricePerUnitToUpdateForm(data, index, updateForm);
          }}
          errorMessage={errors.for("date")}
          size="small"
          oldVersion
        />
      ),
    },
    {
      text: "Notes",
      classNames:
        "Table--row-item ValuationRow--description u-textWithEllipsis",
      alignment: "u-leftAlignedText ",
      renderValue: (data) => data.note,
      renderForm: (data, updateForm, index, errors) => (
        <TextInput
          name="note"
          extraClassnames="FormElement--note"
          value={data.note}
          onChange={(value) => {
            updateForm("note", value, index, data.id);
            sendPricePerUnitToUpdateForm(data, index, updateForm);
          }}
          size="small"
          placeholder="Add notes"
          errorMessage={errors.for("note")}
        />
      ),
    },
  ];

  if (investment.quantity) {
    const columnsToShow = [pricePerType, previewMarketValue];
    columns.push(...columnsToShow);
  } else {
    columns.push(amount);
  }

  return columns;
}
