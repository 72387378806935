import React from "react";
import CollapsableSection from "components_new/elements/CollapsableSection";
import Button from "components_new/atoms/Button";
import DropdownForm from "components_new/atoms/DropdownForm";

export default function ActiveDealDetailsSkeleton() {
  return (
    <div className="ActiveDeals-SelectedDeal ActiveDeals-SelectedDeal-Skeleton">
      <div className="ActiveDeals-SelectedDeal-Details">
        <div className="SelectedDeal-Details-Header">
          <div className="ui placeholder">
            <div className="full line"></div>
            <div className="full line"></div>
            <div className="line"></div>
          </div>
        </div>
        <div className="SelectedDeal-Details-Body">
          <div className="SelectedDeal-Details-DealCard DealCard-Skeleton">
            <div className="ui placeholder">
              <div className="full line"></div>
              <div className="full line"></div>
              <div className="line"></div>
            </div>
          </div>
          <CollapsableSection
            expanded
            title="Round Details"
            extraClassNames="no-bottom-border"
          >
            <div className="ui placeholder">
              <div className="full line"></div>
              <div className="full line"></div>
              <div className="line"></div>
            </div>
          </CollapsableSection>
          <CollapsableSection expanded title="Deal Details">
            <div className="ui placeholder">
              <div className="full line"></div>
              <div className="full line"></div>
              <div className="line"></div>
            </div>
          </CollapsableSection>
          <div className="line"></div>
          <CollapsableSection
            expanded
            title="Notes"
            extraClassNames="notesSection"
          >
            <div className="ui placeholder">
              <div className="full line"></div>
              <div className="full line"></div>
              <div className="line"></div>
            </div>
          </CollapsableSection>
        </div>
      </div>
      <div className="ActiveDeals-SelectedDeal-ActionMenu">
        <Button
          disabled
          fluid
          size="mini"
          className="SelectedDeal-ActionMenu-Btn"
        >
          Advance
        </Button>
        <Button
          disabled
          fluid
          size="mini"
          className="SelectedDeal-ActionMenu-Btn"
        >
          Pass
        </Button>
        <div className="DealStageSelector">
          <DropdownForm iconStyle="remix" disabled options={[]} />
        </div>
        <CollapsableSection
          expanded
          title="Tasks"
          extraClassNames="notesSection"
        >
          <div className="ui placeholder">
            <div className="full line"></div>
            <div className="full line"></div>
            <div className="line"></div>
          </div>
        </CollapsableSection>
      </div>
    </div>
  );
}
