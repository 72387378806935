import React from "react";
import FormGroup from "components_new/elements/FormGroup";
import DropdownForm from "components_new/atoms/DropdownForm";
import DropdownWithCreation from "components_new/atoms/DropdownWithCreation";
import Checkbox from "components_new/atoms/Checkbox";
import { FormField } from "components_new/atoms/Form";

import {
  investmentTypeDropdownItems,
  createPortfolioDropdownItems,
  investmentDetailsMapping,
} from "./config";

function InvestmentDetails({
  portfolios,
  formData,
  handleChange,
  handleTypeChange,
  showFields,
  preventTypeChange,
  errors,
}) {
  const portfolioDropdownOptions = createPortfolioDropdownItems(portfolios);
  const { portfolio_id, type, is_follow_on } = formData;

  return (
    <FormGroup
      extraClassnames="InvestmentDetailsModule"
      title="Investment Details"
      subtitle="Information that will help classify the specific investment."
      required
      isExpanded
    >
      <DropdownWithCreation
        name="portfolio_id"
        label="Fund"
        required
        placeholder="Select fund"
        items={portfolioDropdownOptions}
        onChange={(_, { value }) => handleChange("portfolio_id", value)}
        value={portfolio_id}
        size="tiny"
        error={!!errors.for("portfolio_id") || !!errors.for("portfolio_name")}
        errorMessage={
          errors.for("portfolio_id") || errors.for("portfolio_name")
        }
        onAddNew={(value) => handleChange("isNewPortfolio", value)}
      />
      <DropdownForm
        label="Type of Investment"
        required
        placeholder="Select investment type"
        value={type}
        items={investmentTypeDropdownItems}
        selectOnNavigation={!preventTypeChange}
        onChange={(_, { value }) => handleTypeChange(value)}
        size="tiny"
      />
      <FormField>
        <Checkbox
          extraClassnames=""
          label="Follow-on"
          checked={is_follow_on}
          onChange={(_, { checked }) => handleChange("is_follow_on", checked)}
        />
      </FormField>
      {showFields && (
        <ScopedInvestmentDetails
          investmentType={type}
          handleChange={handleChange}
          formData={formData}
          errors={errors}
        />
      )}
    </FormGroup>
  );
}

function ScopedInvestmentDetails({
  investmentType,
  handleChange,
  formData,
  errors,
}) {
  const TypeForm = investmentType && investmentDetailsMapping[investmentType];

  return (
    TypeForm && (
      <TypeForm
        handleChange={handleChange}
        formData={formData}
        errors={errors}
      />
    )
  );
}

export default InvestmentDetails;
