import React from "react";
import Value from "../Value";
import { getText } from "TermsMapper";
import "./SummaryStatsItem.scss";
import { isEmpty } from "lodash";

const TYPE_MAPPING = {
  investment_count: "quantity",
  number_of_companies: "quantity",
  total_invested: "currency",
  total_valuation: "currency",
  total_cash_received: "currency",
  multiple: "multiple",
};

function SummaryStatsItem({ name, value }) {
  return (
    <div className="SummaryStatsItem">
      <span className="SummaryStatsItem-name u-bodySmall">
        {getText("summary_stats", name)}:
      </span>
      {value instanceof Object && !isEmpty(value) ? (
        value
      ) : (
        <Value type={TYPE_MAPPING[name]}>{value}</Value>
      )}
    </div>
  );
}

export default SummaryStatsItem;
