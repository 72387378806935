import React from "react";
import FormGroup from "components_new/elements/FormGroup";
import DropdownForm from "components_new/atoms/DropdownForm";
import Input from "components_new/atoms/Input";
import DatePicker from "components_new/atoms/DatePicker";
import CurrencyInput from "components_new/atoms/CurrencyInput";

import { vestingScheduleItems, vestingCliffItems } from "./config";

function VestingDetails({ title, formData, handleChange }) {
  const {
    type,
    vesting_schedule,
    vesting_cliff_selection,
    vesting,
    option_exercise_date,
  } = formData;

  function getVestingValues(type) {
    let classData = {};
    switch (type) {
      case "equity":
        classData = {
          exercisePriceValue: formData.equity_exercise_price,
          exercisePriceLabel: "equity_exercise_price",
          expirationDateValue: formData.equity_expiration_date,
          expirationDateLabel: "equity_expiration_date",
        };
        return classData;
      case "option":
        classData = {
          exercisePriceValue: formData.option_exercise_price,
          exercisePriceLabel: "option_exercise_price",
          expirationDateValue: formData.option_expiration_date,
          expirationDateLabel: "option_expiration_date",
        };
        return classData;
      case "llc":
        classData = {
          exercisePriceValue: formData.llc_exercise_price,
          exercisePriceLabel: "llc_exercise_price",
          expirationDateValue: formData.llc_expiration_date,
          expirationDateLabel: "llc_expiration_date",
        };
        return classData;
      case "warrant":
        classData = {
          exercisePriceValue: formData.warrant_exercise_price,
          exercisePriceLabel: "warrant_exercise_price",
          expirationDateValue: formData.warrant_expiration_date,
          expirationDateLabel: "warrant_expiration_date",
        };
        return classData;
      default:
        throw new Error(`No registered object for ${type}.`);
    }
  }

  const vestingData = getVestingValues(type);

  return (
    <FormGroup
      extraClassnames="VestingDetailsGroup"
      title={title}
      isExpanded={false}
      required={false}
      noBottomBorder
      subGroup
    >
      <DropdownForm
        name="vesting_schedule"
        label="Vesting Schedule"
        placeholder="Select vesting schedule"
        items={vestingScheduleItems}
        value={vesting_schedule}
        onChange={(_, { value }) => {
          handleChange("vesting_schedule", value);
        }}
        size="tiny"
      />
      <DropdownForm
        name="vesting_cliff_selection"
        label="Vesting cliff"
        placeholder="Select vesting cliff"
        items={vestingCliffItems}
        value={vesting_cliff_selection}
        onChange={(_, { value }) => {
          handleChange("vesting_cliff_selection", value);
        }}
        size="tiny"
      />
      <Input
        name="vesting"
        label="Vesting Terms"
        placeholder="e.g. Graded vesting"
        value={vesting}
        onChange={(_, { value }) => {
          handleChange("vesting", value);
        }}
        size="tiny"
      />
      <CurrencyInput
        name={vestingData.exercisePriceLabel}
        label="Exercise price"
        placeholder="0.0000"
        decimalLimit={4}
        defaultValue={vestingData.exercisePriceValue}
        value={vestingData.exercisePriceValue}
        onChange={(value) => {
          handleChange(vestingData.exercisePriceLabel, value);
        }}
        size="tiny"
        iconPosition="left"
      />
      <DatePicker
        name="option_exercise_date"
        label="Vesting start date"
        prefillToday
        value={option_exercise_date}
        onChange={(value, validated) =>
          handleChange("option_exercise_date", value, validated)
        }
        size="tiny"
      />
      <DatePicker
        name={vestingData.expirationDateLabel}
        label="Expiration date"
        prefillToday
        value={vestingData.expirationDateValue}
        onChange={(value, validated) =>
          handleChange(vestingData.expirationDateLabel, value, validated)
        }
        size="tiny"
      />
    </FormGroup>
  );
}

export default VestingDetails;
