export default function iconType(type) {
  switch (type) {
    case "application/excel":
    case "application/x-excel":
    case "application/x-msexcel":
    case "application/vnd.ms-excel":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    case "text/csv":
      return "ri-file-excel-2-line";
    case "application/pdf":
      return "icon-file-preview";
    case "application/mspowerpoint":
    case "application/powerpoint":
    case "application/vnd.ms-powerpoint":
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    case "application/x-mspowerpoint":
      return "ri-file-ppt-2-line";
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "ri-file-word-2-line";
    case "image/jpeg":
    case "application/postscript":
    case "image/png":
    case "image/gif":
    case "image/bmp":
      return "ri-file-list-2-line";
    default:
      return "ri-file-list-2-line";
  }
}
