import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  TableHeader,
  TableRow,
  SortableTableHeaderCell,
  SortedTable,
  TableBody,
  ExpandableRow,
  TableHeaderCell,
} from "components_new/elements/Table";
import { defaultOrderByCompanyName } from "components_new/views/Portfolios/config";
import { Link } from "react-router-dom";
import Tooltip from "components_new/atoms/Tooltip/Tooltip";
import TableCell from "components_new/elements/Table/TableCell";
import Value from "components_new/atoms/Value";
import CompanyInfo from "components_new/elements/CompanyInfo";
import formatDate from "lib/formatDate";
import GainLossArrow from "components_new/elements/GainLossArrow";
import SubValue from "components_new/atoms/SubValue";
import pluralize from "pluralize";
import ColumnSection from "../../shared/ColumnSection";
import ChildInvestmentRow from "../Rows/ChildInvestmentRow";
import { companyAvatarPropType, investmentsByCompanyPropType } from "PropTypes";
import { isEmpty } from "lodash";
import getColumnHeaders from "../../shared/getColumnHeaders";
import ContentWithTooltip from "components_new/elements/ContentWithTooltip";

function InvestmentsByCompanyTable({
  data,
  isLoading,
  onSelection,
  orgUsername,
  allRowsExpanded,
  allRowsCollapsed,
  resetInvestmentsViewRows,
  showClosingDocuments,
}) {
  const columnHeaders = getColumnHeaders("byCompanyTable");
  const renderHeaders = (handleSort, isSorted) => {
    return (
      <TableHeader>
        <TableRow>
          <TableHeaderCell className="EmptyCell" />
          {columnHeaders.map((columnHeader, index) => {
            return (
              <SortableTableHeaderCell
                key={index}
                isGrouped={false}
                isSorted={isSorted}
                handleSort={handleSort}
                {...columnHeader}
              >
                <Tooltip
                  position={columnHeader.tooltipPosition}
                  content={columnHeader.tooltipContent}
                  disabled={!columnHeader.hasTooltip}
                  trigger={<div>{columnHeader.title}</div>}
                />
              </SortableTableHeaderCell>
            );
          })}
        </TableRow>
      </TableHeader>
    );
  };

  return (
    <SortedTable
      tableData={data}
      onSelection={onSelection}
      className="HoldingsTable attached"
      isLoading={isLoading}
      renderHeaders={renderHeaders}
      skeletonStructure={{ rows: 8, headers: columnHeaders }}
      defaultOrder={defaultOrderByCompanyName}
      expandable
      shadow={false}
    >
      {({ data }) => (
        <TableBody isEmptyData={isEmpty(data)}>
          {data &&
            data.map((groupedInvestments) => {
              const {
                company,
                investments_count,
                overall_status,
                last_date,
                total_cost_basis,
                total_valuation_amount,
                total_quantity,
                gain,
                realized_gain,
                multiple,
                investments,
              } = groupedInvestments;

              const investmentsQty = pluralize("investment", investments_count);
              const exitedCompany = overall_status === "Exited";
              const companyGain = exitedCompany ? realized_gain : gain;

              const mainCells = (
                <Fragment>
                  <TableCell>
                    <CompanyInfo
                      company={company}
                      title={(company) => {
                        return (
                          <ContentWithTooltip
                            tooltip={{
                              content: company.name,
                              position: "top center",
                              disabled: false,
                            }}
                          >
                            <Link
                              to={`/${orgUsername}/companies/${company.id}`}
                            >
                              {company.name}
                            </Link>
                          </ContentWithTooltip>
                        );
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    {`${investments_count} ${investmentsQty}`}
                  </TableCell>
                  <TableCell>{overall_status}</TableCell>
                  <TableCell className="DateColumn">
                    {formatDate(last_date)}
                  </TableCell>
                  <TableCell className="NumericColumn">
                    <ColumnSection isNotApplicable={exitedCompany}>
                      <Value type="quantity">{total_quantity}</Value>
                    </ColumnSection>
                  </TableCell>
                  <TableCell className="NumericColumn">
                    <Value type="currency">{total_cost_basis}</Value>
                  </TableCell>
                  <TableCell className="NumericColumn">
                    <ColumnSection isNotApplicable={exitedCompany}>
                      <Value type="currency">{total_valuation_amount}</Value>
                    </ColumnSection>
                  </TableCell>
                  <TableCell className="NumericColumn GainLossColumn">
                    <Value type="currency" precision="2">
                      {companyGain}
                    </Value>
                    <GainLossArrow gain={companyGain} />
                    <SubValue>
                      <Value type="multiple">{multiple}</Value>
                    </SubValue>
                  </TableCell>
                </Fragment>
              );

              return (
                <ExpandableRow
                  key={company.id}
                  mainCells={mainCells}
                  allRowsExpanded={allRowsExpanded}
                  allRowsCollapsed={allRowsCollapsed}
                  resetAllRows={resetInvestmentsViewRows}
                >
                  {investments.map((investment) => (
                    <ChildInvestmentRow
                      key={investment.id}
                      investment={investment}
                      orgUsername={orgUsername}
                      showClosingDocuments={showClosingDocuments}
                    />
                  ))}
                </ExpandableRow>
              );
            })}
        </TableBody>
      )}
    </SortedTable>
  );
}

InvestmentsByCompanyTable.defaultProps = {
  dataByCompany: [],
  companies: {},
};

InvestmentsByCompanyTable.propTypes = {
  dataByCompany: PropTypes.arrayOf(investmentsByCompanyPropType),
  companies: PropTypes.objectOf(companyAvatarPropType),
  onSelection: PropTypes.func,
  isLoading: PropTypes.bool,
  orgUsername: PropTypes.string,
  resetInvestmentsViewRows: PropTypes.func,
  allRowsExpanded: PropTypes.bool,
  allRowsCollapsed: PropTypes.bool,
  showClosingDocuments: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

export default InvestmentsByCompanyTable;
