import accounting from "accounting";
import isValidNumberInput from "lib/formatters/isValidNumberInput";
import { defaultNumberPrecision } from "config/priceInput";

const DEFAULT_FORMAT_OPTIONS = {
  symbol: "$",
  precision: defaultNumberPrecision,
  format: {
    pos: "%s%v",
    neg: "-%s%v",
    zero: "%s%v",
  },
};

export default function formatPrice(value, options) {
  options = options || {};
  const customOptions = {
    ...DEFAULT_FORMAT_OPTIONS,
    ...options,
    format: { ...DEFAULT_FORMAT_OPTIONS.format, ...options.format },
  };

  if (isValidNumberInput(value)) {
    return accounting.formatMoney(value, customOptions);
  }
  throw new Error(
    `Value, which now equals --> ${value}, cannot be undefined, null, Infinity, or empty string`,
  );
}
