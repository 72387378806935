import { shallowEqual, useSelector } from "react-redux";
import { pick } from "lodash";

export default () => {
  const entities = useSelector((state) => state.entitiesv2, shallowEqual);

  const getEntity = (collectionName, id) =>
    (entities[collectionName] && entities[collectionName].byId[id]) || {};

  const getCollectionAsObject = (collectionName, collectionIds) =>
    entities[collectionName] &&
    pick(entities[collectionName].byId, collectionIds);

  const getCollection = (collectionName, collectionIds) =>
    Object.values(getCollectionAsObject(collectionName, collectionIds) || {});

  return {
    getEntity,
    getCollection,
    getCollectionAsObject,
  };
};
