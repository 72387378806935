import React from "react";
import PropTypes from "prop-types";

function MainViewFrame({ children }) {
  return (
    <div className="MainViewFrame">
      {children}
      <i className="MainViewFrame-corner" />
      <i className="MainViewFrame-topright MainViewFrame-corner" />
      <i className="MainViewFrame-bottomright MainViewFrame-corner" />
      <i className="MainViewFrame-bottomleft MainViewFrame-corner" />
    </div>
  );
}

MainViewFrame.Proptypes = {
  children: PropTypes.node,
};

export default MainViewFrame;
