import { useEffect } from "react";
import Mousetrap from "mousetrap";

import { useSelector } from "react-redux";
import useSidebar from "store/sidebar/hooks";
import useCurrentOrganization from "components/Mighty/useCurrentOrganization";

if (window.Cypress) {
  // Cypress is too bad at recreating uppercase shortcuts
  // so this is needed for a workaround
  window.Mousetrap = Mousetrap;
}

Mousetrap.prototype.stopCallback = (_event, element) => {
  return (
    element.tagName == "INPUT" ||
    element.tagName == "SELECT" ||
    element.tagName == "TEXTAREA" ||
    (element.contentEditable && element.contentEditable == "true") ||
    element.classList.contains("js-stopGlobalShortcutPropagation")
  );
};

function useShortcutCondition() {
  const currentOrganization = useCurrentOrganization();
  const areModalsOpen = useSelector(({ modal }) => modal.isOpen);
  const { sidebar: initialComponent } = useSidebar();

  return currentOrganization && !areModalsOpen && !initialComponent.isVisible;
}

function useShortcut(shortcut, callback) {
  const shouldShortcutWork = useShortcutCondition();

  useEffect(() => {
    if (shouldShortcutWork) {
      Mousetrap.unbind(shortcut);
      Mousetrap.bind(shortcut, callback);
    }

    return () => {
      Mousetrap.unbind(shortcut);
    };
  }, [shouldShortcutWork, callback, shortcut]);
}

export default useShortcut;
