import { connect } from "react-redux";
import ModalWrapper from "./ModalWrapper";
import { closeModal } from "store/modalV2/actions";

function mapStateToProps(state) {
  const { modalV2 } = state;
  return {
    isOpen: modalV2.isOpen,
    modalNames: modalV2.modalNames,
    modalProps: modalV2.modalProps,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => {
      dispatch(closeModal());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalWrapper);
