import React, { useContext, useEffect, useCallback } from "react";
import CoInvestmentForm from "./CoInvestmentForm";
import getMessage from "components_new/lib/Messages";

import { SidebarComponentsContext } from "components_new/SidebarComponents/SidebarComponentsWrapper";
import { useCreateCoInvestment } from "components_new/views/Company/hooks/mutations/useCreateCoInvestment";
import { useUpdateCoInvestment } from "components_new/views/Company/hooks/mutations/useUpdateCoInvestment";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import { isEmpty } from "lodash";

function CoInvestmentFormWrapper({ ownName }) {
  const {
    closeSidebar,
    registerForm,
    getComponentState,
    hideSidebarComponent,
    mountSidebarComponent,
  } = useContext(SidebarComponentsContext);

  const { mutateAsync: createCoInvestment } = useCreateCoInvestment();
  const { mutateAsync: updateCoInvestment } = useUpdateCoInvestment();
  const {
    resourceName = "Co-Investment",
    companyId,
    prefilledCompany,
    prefilledInvestor,
    disableCompanySelector,
    disableInvestorSelector,
    coInvestment,
  } = getComponentState(ownName);
  const isEditing = !isEmpty(coInvestment);

  useEffect(() => {
    if (!isEmpty(ownName)) {
      registerForm(ownName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { addToast } = useSetToast();

  const handleCreate = useCallback(
    async (formData) => {
      await createCoInvestment({ companyId, formData });
      addToast(getMessage("addCoInvestment"));
      closeSidebar();
    },
    [addToast, closeSidebar, companyId, createCoInvestment],
  );

  const handleUpdate = useCallback(
    async (formData) => {
      await updateCoInvestment({ companyId, formData });
      addToast(getMessage("updateCoInvestment"));
      closeSidebar();
    },
    [addToast, closeSidebar, companyId, updateCoInvestment],
  );

  return (
    <CoInvestmentForm
      ownName={ownName}
      resourceName={resourceName}
      prefilledCompany={prefilledCompany}
      prefilledInvestor={prefilledInvestor}
      disableCompanySelector={disableCompanySelector}
      disableInvestorSelector={disableInvestorSelector}
      coInvestment={coInvestment || {}}
      isEditing={isEditing}
      submitAction={isEditing ? handleUpdate : handleCreate}
      closeSidebar={closeSidebar}
      mountSidebarComponent={mountSidebarComponent}
      hideSidebarComponent={hideSidebarComponent}
    />
  );
}

export default CoInvestmentFormWrapper;
