import React from "react";
import PropTypes from "prop-types";
import middleEllipsis from "lib/middleEllipsis";
import classnames from "classnames";
import { documentPropType } from "PropTypes";

const FILE_NAME_MAX_LENGTH = 65;

function FileNameLink({
  file,
  fileUrl,
  extraClassnames,
  maxCharsLengthFileName,
}) {
  const fileName = file.name || file.file_name || file.filename;
  const fileNameLinkClassnames = classnames("FileNameLink", {
    [extraClassnames]: !!extraClassnames,
  });

  return (
    <a
      className={fileNameLinkClassnames}
      href={fileUrl}
      rel="nofollow noopener"
      target="_blank"
    >
      {middleEllipsis(fileName, maxCharsLengthFileName)}
    </a>
  );
}

FileNameLink.defaultProps = {
  maxCharsLengthFileName: FILE_NAME_MAX_LENGTH,
};

FileNameLink.propTypes = {
  file: documentPropType.isRequired,
  fileUrl: PropTypes.string.isRequired,
  extraClassnames: PropTypes.string,
  maxCharsLengthFileName: PropTypes.number,
};

export default FileNameLink;
