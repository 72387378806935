import React, { useContext, useEffect, useMemo } from "react";
import LogSaleForm from "./SaleForm";
import { SidebarComponentsContext } from "components_new/SidebarComponents/SidebarComponentsWrapper";
import { isEmpty } from "lodash";
import { useInvestment } from "components_new/SidebarComponents/views/InvestmentView/hooks/useInvestment";
import useInvestmentLogSale from "store/investments/v2/logSale/hooks";
import Loader from "components/shared/Loader";

function SaleFormWrapper({ ownName }) {
  const { getComponentState, registerForm, closeSidebar } = useContext(
    SidebarComponentsContext,
  );

  const { investment } = getComponentState(ownName);

  useEffect(() => {
    if (!isEmpty(ownName)) {
      registerForm(ownName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { createLogSale } = useInvestmentLogSale();
  const { data, isLoading } = useInvestment(investment?.id);

  const userContacts = useMemo(() => {
    return data?.userContacts.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  }, [data]);

  if (isLoading || isEmpty(investment)) {
    return <Loader />;
  }

  return (
    <LogSaleForm
      ownName={ownName}
      company={data?.company}
      investment={data?.details}
      userContacts={userContacts}
      closeSidebar={closeSidebar}
      createLogSale={createLogSale}
    />
  );
}

export default SaleFormWrapper;
