import React from "react";
import PurchasedEvent from "./view/PurchasedEvent";
import LogAcquisitionEvent from "./view/LogAcquisitionEvent";
import LogDistributionEvent from "./view/LogDistributionEvent";
import LogConversionEvent from "./view/LogConversionEvent";
import LogWriteOffEvent from "./view/LogWriteOffEvent";
import LogStockSplitEvent from "./view/LogStockSplitEvent";
import LogSaleEvent from "./view/LogSaleEvent";
import PurchasedForm from "./form/PurchasedForm";
import LogDistributionForm from "./form/LogDistributionForm";
import LogAcquisitionForm from "./form/LogAcquisitionForm";
import LogConversionForm from "./form/LogConversionForm";
import LogWriteOffForm from "./form/LogWriteOffForm";
import LogSaleForm from "./form/LogSaleForm";
import LogStockSplitForm from "./form/LogStockSplitForm";
import DeleteMessage from "../DeleteMessage";

const CONTENT_COMPONENTS = {
  purchased: {
    view: PurchasedEvent,
    edit: PurchasedForm,
    delete: DeleteMessage,
  },
  acquired: {
    view: LogAcquisitionEvent,
    edit: LogAcquisitionForm,
    delete: DeleteMessage,
  },
  distributed: {
    view: LogDistributionEvent,
    edit: LogDistributionForm,
    delete: DeleteMessage,
  },
  converted: {
    view: LogConversionEvent,
    edit: LogConversionForm,
    delete: DeleteMessage,
  },
  sold: { view: LogSaleEvent, edit: LogSaleForm, delete: DeleteMessage },
  written_off: {
    view: LogWriteOffEvent,
    edit: LogWriteOffForm,
    delete: DeleteMessage,
  },
  stock_splitted: {
    view: LogStockSplitEvent,
    edit: LogStockSplitForm,
    delete: DeleteMessage,
  },
};

function getContent(
  historyAction,
  companies,
  actionType,
  updateForm,
  errors,
  formData,
  showEvent,
  rowIndex,
  organizationUsername,
) {
  return React.createElement(
    CONTENT_COMPONENTS[historyAction.type][actionType],
    {
      historyAction,
      companies,
      updateForm,
      errors,
      formData,
      showEvent,
      rowIndex,
      organizationUsername,
    },
  );
}

function ContentEvent({
  historyAction,
  companies,
  actionType,
  updateForm,
  errors,
  formData,
  showEvent,
  rowIndex,
  organizationUsername,
}) {
  return (
    <div className="ContentEvent">
      {getContent(
        historyAction,
        companies,
        actionType,
        updateForm,
        errors,
        formData,
        showEvent,
        rowIndex,
        organizationUsername,
      )}
    </div>
  );
}

export default ContentEvent;
