import { pick, at, isEmpty } from "lodash";

export function getCollectionAsObject(state, collectionName, collectionIds) {
  const { entitiesv2: entities } = state;
  return (
    entities[collectionName] &&
    pick(entities[collectionName].byId, collectionIds)
  );
}

export function getCollection(state, collectionName, collectionIds) {
  return Object.values(
    getCollectionAsObject(state, collectionName, collectionIds) || {},
  );
}

export function getEntity(state, collectionName, id) {
  const { entitiesv2: entities } = state;

  return (
    (!isEmpty(entities[collectionName]) && entities[collectionName].byId[id]) ||
    {}
  );
}

export function getOrderedCollection(state, collectionName, collectionIds) {
  // TODO: We need to see if this is worth it, maybe we can handle in other way.
  const { entitiesv2: entities } = state;
  return (
    (entities[collectionName] &&
      at(entities[collectionName].byId, collectionIds)) ||
    []
  );
}
