import React, { useCallback } from "react";
import EmptyDeals from "./EmptyDeals";
import { useSetSidebar } from "components_new/elements/SidebarWrapper/Sidebar-context";

function EmptyDealsWrapper(props) {
  const { showSidebar } = useSetSidebar();

  const handleOpenNewDealForm = useCallback(
    () => showSidebar("dealForm", { size: "wide" }),
    [showSidebar],
  );

  return <EmptyDeals openDealForm={handleOpenNewDealForm} {...props} />;
}

export default EmptyDealsWrapper;
