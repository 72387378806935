import React from "react";
import CompaniesTable from "components_new/views/CompanyDirectory/CompaniesTable";
import CompaniesGrid from "components_new/views/CompanyDirectory/CompaniesGrid";
import { VIEW_STYLES } from "../TableActions/ViewStyleSelector";

function ViewStyleRouter(props) {
  return (
    <div className="ViewStyleRouter">
      {props.viewStyle === VIEW_STYLES.LIST ? (
        <CompaniesTable {...props} />
      ) : (
        <CompaniesGrid {...props} />
      )}
    </div>
  );
}

export default ViewStyleRouter;
