import React from "react";
import TextInput from "components/shared/form/TextInput";
import moment from "moment";

function DTFileUploadDateEdit({ file }) {
  return (
    <div className="DTFileUploadDateEdit">
      <TextInput
        value={moment(file.created_at).format("MM/DD/YYYY")}
        alignTo="right"
        readOnly
        size="small"
      />
    </div>
  );
}

export default DTFileUploadDateEdit;
