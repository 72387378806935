import React, { useState, useEffect, Fragment, useRef } from "react";
import { indexOf, isEqual, differenceBy, isEmpty } from "lodash";
import classNames from "classnames";
import PropTypes from "prop-types";
import FormElement from "components/shared/form/FormElement";
import { tagSuggestionsProptype } from "PropTypes";
import isServerValidationError from "lib/isServerValidationError";
import Errors from "lib/errors";
import Suggestion from "./Suggestion";
import CreateTag from "./CreateTag";
import SuggestedTags from "./SuggestedTags";
import "./InputTag.scss";
import Tag from "./Tag";
import {
  BACKSPACE_KEY,
  TAB_KEY,
  ENTER_KEY,
  DOWN_ARROW_KEY,
  ESC_KEY,
  UP_ARROW_KEY,
} from "config/keyCodes";

const SELECTABLE_ITEM = ".InputTag-actions-item";
const SELECTED_ITEM = "is-selected";

function removeHighlight() {
  const removeItem = document.querySelector(".InputTag .is-selected");
  if (removeItem) {
    removeItem.classList.remove(SELECTED_ITEM);
  }
}

function InputTag({
  extraClassnames,
  size,
  label,
  isOptional,
  errorMessage,
  showLock,
  textHelper,
  taggedList,
  placeholder,
  suggestedList,
  onCreate,
  onChange,
  onEdit,
  onFilter,
  onDelete,
  showSuggestions,
  createTagLabel,
  reInit,
  setReInit,
  disabled,
  suggestedTags = [],
  prepopulatedItems = [],
}) {
  const [initialized, setInitialized] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagQuery, setTagQuery] = useState("");
  const [suggestions, setSuggestions] = useState(suggestedList);
  const [hasSuggestedTags, setHasSuggestedTags] = useState(false);
  const [prunedSuggestions, setPrunedSuggestions] = useState(null);
  const [prunedPrepopulatedItems, setPrunedPrepopulatedItems] = useState(null);
  const [showPrepopulatedItems, setShowPrepopulatedItems] = useState(false);

  const [error, setError] = useState(errorMessage);
  const [itemSelected, setItemSelected] = useState(null);

  const tagWrapper = useRef();
  const tagInput = useRef();

  function includesExactMatch(suggestions, value) {
    return (
      suggestions &&
      !!suggestions.find((suggestion) => {
        return suggestion.name === value;
      })
    );
  }

  function handleClickOutside(event) {
    const deleteTagisOpen = document.querySelector(".ModalLoader-container");
    if (
      tagWrapper.current &&
      (tagWrapper.current === event.target ||
        !tagWrapper.current.contains(event.target))
    ) {
      if (deleteTagisOpen === null) {
        resetSetTags();
        setShowPrepopulatedItems(false);
      }
    }
  }

  useEffect(() => {
    if (!hasSuggestedTags && suggestedTags.length > 0) {
      setHasSuggestedTags(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestedTags]);

  useEffect(() => {
    if (errorMessage) {
      setError(errorMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  useEffect(
    () => {
      if (!initialized && !isEmpty(taggedList)) {
        setTags(taggedList);
        setInitialized(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [taggedList],
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  useEffect(() => {
    if (reInit) {
      onChange(taggedList);
      setTags(taggedList);
      setInitialized(false);
      setReInit(false);
      return;
    }
    if (!isEqual(tags, taggedList)) {
      onChange(tags);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags, taggedList]);

  useEffect(() => {
    if (!isEqual(suggestions, suggestedList)) {
      setSuggestions(suggestedList);
      setTimeout(() => {
        highlightFirstItem();
      }, 5);
    }
  }, [suggestedList, setSuggestions, suggestions, highlightFirstItem]);

  useEffect(
    () => setPrunedSuggestions(differenceBy(suggestions, tags, "name")),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tags, suggestions],
  );

  useEffect(
    () =>
      setPrunedPrepopulatedItems(differenceBy(prepopulatedItems, tags, "name")),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tags],
  );

  useEffect(
    () => {
      highlightFirstItem();
      setShowPrepopulatedItems(shouldShowPrepopulatedItems());
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tagQuery],
  );

  function resetSetTags() {
    if (tagInput.current.value) {
      tagInput.current.value = null;
      setTagQuery("");
      setItemSelected(null);
      setSuggestions([]);
    }
  }

  function focusInput() {
    tagInput.current.focus();
  }

  function searchSuggestion(term) {
    if (!term || term.trim() === "" || term.length === 0) {
      setSuggestions([]);
      return null;
    }
    onFilter(term);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function highlightFirstItem() {
    const firstItem = document.querySelector(".InputTag-actions-item");
    if (firstItem) {
      highlightItem(firstItem);
    }
  }

  function highlightItem(item) {
    removeHighlight();
    setItemSelected(item.dataset.position);
    item.classList.add(SELECTED_ITEM);
  }

  function moveDownUpIntoSuggestions(action) {
    const selectableItems = document.querySelectorAll(SELECTABLE_ITEM);
    const selectedItem = document.querySelector(
      ".InputTag-actions .is-selected",
    );
    const selectedItemIndex = indexOf(selectableItems, selectedItem);
    const nextItem = selectableItems.item(
      selectedItemIndex + (action === UP_ARROW_KEY ? -1 : 1),
    );

    if (nextItem) {
      highlightItem(nextItem);
    }

    if (action === UP_ARROW_KEY && !nextItem) {
      setItemSelected(null);
      removeHighlight();
    }
  }

  function removeTag(index) {
    if (!initialized) {
      setInitialized(true);
    }
    const newTags = [...tags];
    newTags.splice(index, 1);
    setTags(newTags);
  }

  function removeSuggestedTag(name) {
    const newTags = tags.filter((tag) => tag.name !== name);
    setTags(newTags);
  }

  function removeTagById(tagId) {
    const newTags = tags.filter((tag) => tag.id !== tagId);
    setTags(newTags);
  }

  function addSuggestedTag(tag) {
    const newTags = [...tags, tag];
    setTags(newTags);
  }

  function onInputChange(event) {
    if (!initialized) {
      setInitialized(true);
    }

    const value = event.target.value.toLowerCase();

    removeHighlight();
    setError("");
    setTagQuery(value);
    setShowPrepopulatedItems(shouldShowPrepopulatedItems());

    showSuggestions && searchSuggestion(value);
  }

  function createTag(value) {
    if (onCreate) {
      onCreate(value)
        .then((response) => {
          setTags([...tags, response.data]);
        })
        .catch((error_) => {
          if (isServerValidationError(error_)) {
            const { data } = error_;
            const errorData = new Errors(data.errors);
            setError(errorData.for("name"));
          }
        });
      resetSetTags();
    }
  }

  function updateExistedTag(tagId, tagValue) {
    const newTags = [...tags];
    const tagExist = newTags.findIndex((tag) => tag.id === tagId);
    if (tagExist >= 0) {
      newTags.forEach((tag) => {
        if (tag.id === tagId) {
          tag.name = tagValue;
        }
      });
      setTags(newTags);
    }
  }

  function addTag(newTag) {
    const index = tags.findIndex((tag) => tag.id === newTag.id);
    if (index < 0) {
      setTags([...tags, newTag]);
    }
    resetSetTags();
  }

  function addSelectedTag(suggestion) {
    setShowPrepopulatedItems(false);

    if (!isEmpty(suggestion)) {
      addTag({
        id: +suggestion.id,
        name: suggestion.name,
      });
    }
  }

  function inputKeyDown(event) {
    const value = event.target.value.toLowerCase();
    switch (event.which) {
      case ESC_KEY:
        return resetSetTags();
      case TAB_KEY:
        if (itemSelected) {
          event.preventDefault();
          focusInput();
          return addSelectedTag();
        }
      case ENTER_KEY:
        if (value) {
          const selectedItem = name
            ? document.querySelector(`.${name} .InputTag-actions .is-selected`)
            : document.querySelector(".InputTag-actions .is-selected");
          if (selectedItem.dataset.index) {
            addTag({
              id: +selectedItem.dataset.index,
              name: selectedItem.dataset.value,
            });
          } else {
            return createTag(value);
          }
        }
      case BACKSPACE_KEY:
        if (tags.length > 0 && !value) {
          return removeTag(tags.length - 1);
        }
      case DOWN_ARROW_KEY:
        return moveDownUpIntoSuggestions(DOWN_ARROW_KEY);
      case UP_ARROW_KEY:
        return moveDownUpIntoSuggestions(UP_ARROW_KEY);
    }
  }

  function handleInputFocus() {
    removeHighlight();
    setShowPrepopulatedItems(shouldShowPrepopulatedItems());
  }

  function shouldShowPrepopulatedItems() {
    return (
      isEmpty(tagQuery) &&
      prepopulatedItems.length > 0 &&
      document.activeElement === tagInput.current
    );
  }

  const shouldSuggest =
    !isEmpty(tagQuery) && prunedSuggestions && prunedSuggestions.length > 0;

  const formElementClassnames = classNames("FormElement--tagsInput", {
    [extraClassnames]: !!extraClassnames,
    [size]: !!size,
    disabled: disabled,
  });

  const inputTagClassName = classNames("InputTag", {
    [name]: !!name,
  });

  const allowedTags = differenceBy(tags, suggestedTags, "name");

  const suggestionList = () => {
    let items = [];

    if (showPrepopulatedItems) items = prunedPrepopulatedItems;
    if (shouldSuggest) items = prunedSuggestions;

    return items.map((suggestion, index) => (
      <Suggestion
        key={index}
        position={index}
        suggestion={suggestion}
        isHighlighted={index === +itemSelected}
        highlightItem={highlightItem}
        onClickSuggestion={addSelectedTag}
        onEdit={onEdit}
        onDelete={onDelete}
        removeTag={removeTag}
        actionAfterUpdate={updateExistedTag}
      />
    ));
  };

  return (
    <FormElement
      extraClassnames={formElementClassnames}
      label={label}
      name={name}
      isOptional={isOptional}
      errorMessage={error}
      showLock={showLock}
      textHelper={textHelper}
    >
      <Fragment>
        <div className={inputTagClassName} ref={tagWrapper}>
          <ul className="InputTag-list">
            {allowedTags.map((tag, index) => (
              <Tag
                key={index}
                index={index}
                tag={tag}
                onRemove={removeTagById}
                size={size}
              />
            ))}
            <li className="InputTag-inputForm">
              <input
                aria-label={label}
                className="InputTag-input"
                type="text"
                placeholder={placeholder}
                onChange={onInputChange}
                onKeyDown={inputKeyDown}
                disabled={disabled}
                onFocus={handleInputFocus}
                ref={tagInput}
              />
              <nav className="InputTag-actions Container">
                {suggestionList()}

                {tagQuery &&
                  !includesExactMatch(suggestions, tagQuery) &&
                  onCreate && (
                    <CreateTag
                      currentValue={tagQuery}
                      onClick={createTag}
                      highlightItem={highlightItem}
                      label={createTagLabel}
                    />
                  )}
              </nav>
            </li>
          </ul>
        </div>
        {hasSuggestedTags && (
          <SuggestedTags
            selectedTags={tags}
            tags={suggestedTags}
            onAdd={addSuggestedTag}
            onRemove={(tag) => removeSuggestedTag(tag.name)}
            disabled={disabled}
          />
        )}
      </Fragment>
    </FormElement>
  );
}

InputTag.propTypes = {
  extraClassnames: PropTypes.string,
  size: PropTypes.string,
  label: PropTypes.string,
  isOptional: PropTypes.bool,
  errorMessage: PropTypes.string,
  showLock: PropTypes.bool,
  textHelper: PropTypes.string,
  taggedList: tagSuggestionsProptype,
  value: tagSuggestionsProptype,
  placeholder: PropTypes.string,
  suggestedList: tagSuggestionsProptype,
  suggestedTags: tagSuggestionsProptype,
  prepopulatedItems: tagSuggestionsProptype,
  onCreate: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  onFilter: PropTypes.func,
  onDelete: PropTypes.func,
  showSuggestions: PropTypes.bool,
  createTagLabel: PropTypes.string,
  reInit: PropTypes.bool,
  setReInit: PropTypes.func,
  disabled: PropTypes.bool,
};

InputTag.defaultProps = {
  showSuggestions: true,
  suggestedList: [],
  taggedList: [],
  disabled: false,
};

export default InputTag;
