import React from "react";
import TextInput from "components/shared/form/TextInput";
import FileIcon from "components_new/atoms/FileIcon";
import iconType from "components_new/lib/iconType";

function DTFileNameEdit({ file, updateForm, formIndex, errorMessage }) {
  return (
    <div className="DTFileNameEdit">
      <div className="DTFileNameEdit-fileIcon">
        <FileIcon type={iconType(file.mime_type || file.mimetype)} />
      </div>
      <TextInput
        name="filename"
        value={file.file_name}
        size="small"
        errorMessage={errorMessage}
        onChange={(value) => {
          updateForm("file_name", value, formIndex, file.id);
        }}
      />
    </div>
  );
}

export default DTFileNameEdit;
