import React from "react";
import { Table as SemanticTable } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/table.css";
import "./Table.scss";

function TableCell({
  as,
  active,
  children,
  collapsing,
  disabled,
  error,
  icon,
  negative,
  positive,
  selectable,
  singleLine,
  textAlign,
  verticalAlign,
  warning,
  width,
  ...props
}) {
  return (
    <SemanticTable.Cell
      as={as}
      active={active}
      collapsing={collapsing}
      disabled={disabled}
      error={error}
      icon={icon}
      negative={negative}
      positive={positive}
      selectable={selectable}
      singleLine={singleLine}
      textAlign={textAlign}
      verticalAlign={verticalAlign}
      warning={warning}
      width={width}
      {...props}
    >
      {children}
    </SemanticTable.Cell>
  );
}

export default TableCell;
