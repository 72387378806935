import React, { useEffect, useState, useCallback, useContext } from "react";
import LoginLayout from "components/auth/LoginLayout";
import TextInput from "components/shared/form/TextInput";
import PrimaryButton from "components/shared/PrimaryButton";
import { motion } from "framer-motion";
import { fadeIn, animationFromLeft } from "lib/animations";
import MightyLogo from "components_new/atoms/MightyLogo";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import validateResetToken from "actions/asynchronous/auth/validateResetToken";
import Api from "lib/Api";
import AuthenticationContext from "components/Mighty/AuthenticationContext";

function ResetPasswordView() {
  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const { setCurrentUser, persistAuthentication } = useContext(
    AuthenticationContext,
  );

  const resetPassword = useCallback(async () => {
    try {
      const response = await Api.post(
        "/session/reset_password",
        { reset_token: { password, token } },
        { withoutOrganization: true },
      );

      const { id, token: authToken, expires } = response.data.authentication;
      const expirationDate = new Date(expires);
      const user = response.data.user;

      persistAuthentication({ id, token: authToken, expirationDate });
      setCurrentUser(user);
      navigate("/");
    } catch (error) {
      if (error && error.data) {
        const errorMessage = error.data.errors.password[0];
        setErrorMessage(errorMessage);
      }
    }
  }, [navigate, password, persistAuthentication, setCurrentUser, token]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    await resetPassword();
  };

  const checkResetToken = useCallback(async () => {
    const isValid = await dispatch(validateResetToken(token));
    if (!isValid) {
      navigate("/");
    }
  }, [dispatch, navigate, token]);

  useEffect(() => {
    checkResetToken();
  }, [checkResetToken]);

  return (
    <LoginLayout extraClassnames="Login--resetPassword">
      <div className="Login-holder">
        <div className="u-bg-primary LoginWelcome">
          <div className="LoginLogoHolder">
            <MightyLogo size="large" theme="inverse" />
          </div>
          <motion.h1
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            transition={{ duration: 0.05 }}
            className="u-heading"
          >
            Create a new password to access your account!
          </motion.h1>
          <motion.p
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            transition={{ duration: 0.15 }}
            className="LoginWelcome-message"
          >
            Mighty Portfolio takes security seriously. Please choose a strong
            password.
          </motion.p>
        </div>
        <div className="u-bg-tint-0 LoginForm">
          <div className="LoginForm-holder">
            <form onSubmit={handleSubmit} className="LoginFormContainer">
              <motion.h2
                initial="hidden"
                animate="visible"
                variants={fadeIn}
                transition={{ duration: 0.05 }}
                className="u-sectionHeading  LoginTitle"
              >
                Reset password
              </motion.h2>
              <motion.p
                initial="hidden"
                animate="visible"
                variants={animationFromLeft()}
                transition={{ duration: 0.15 }}
                className=" LoginForm-message"
              >
                Choose a new password, one that’s at least 8 characters long.
              </motion.p>
              <motion.div
                initial="hidden"
                animate="visible"
                variants={animationFromLeft({ delay: 0.1 })}
                transition={{ duration: 0.15 }}
              >
                <TextInput
                  typeOfInput="password"
                  placeholder="New password"
                  extraClassnames="LoginAnimatedElement"
                  errorMessage={errorMessage}
                  onChange={setPassword}
                />
              </motion.div>
              <PrimaryButton
                type="submit"
                disabled={password.trim().length === 0}
              >
                <motion.span
                  initial="hidden"
                  animate="visible"
                  variants={animationFromLeft({ delay: 0.2 })}
                  transition={{ duration: 0.15 }}
                  className="Button-text"
                >
                  Save Password and Log In
                </motion.span>
              </PrimaryButton>
            </form>
          </div>
        </div>
      </div>
    </LoginLayout>
  );
}

export default ResetPasswordView;
