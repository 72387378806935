import { useMutation, useQueryClient } from "react-query";
import useOrganizations from "store/organizations/hooks";
import Api from "lib/Api";

export function useDuplicateSnapshot() {
  const queryClient = useQueryClient();
  const { currentOrganizationName } = useOrganizations();

  return useMutation(
    (snapshotId) => {
      return Api.get(`/snapshots/${snapshotId}/duplicate`);
    },
    {
      onSuccess: ({ data: duplicatedSnapshot }) => {
        const key = ["SnapshotsView", currentOrganizationName];
        const snapshotView = queryClient.getQueryData(key);

        if (snapshotView?.snapshots.length > 0) {
          queryClient.setQueryData(key, {
            snapshots: [...snapshotView.snapshots, duplicatedSnapshot],
          });
        }
      },
    },
  );
}
