import Api from "lib/Api";
import useOrganizations from "store/organizations/hooks";
import removePost from "lib/posts/removePost";
import { buildPostResourceRoute } from "lib/globalActionsHelper";
import { useMutation, useQueryClient } from "react-query";

export function useRemoveCompanyPost() {
  const queryClient = useQueryClient();
  const {
    currentOrganization: { username: currentOrganizationUsername },
  } = useOrganizations();

  return useMutation(
    ({ resource, postId }) => {
      const postResourceRoute = buildPostResourceRoute(resource);
      return Api.delete(`${postResourceRoute}/posts/${postId}`);
    },
    {
      onSuccess: (_, { resource: deletedPost, postId }) => {
        const companyId = deletedPost.company_id;
        const key = ["Company", currentOrganizationUsername, companyId];
        const companyView = queryClient.getQueryData(key);

        return queryClient.setQueryData(key, {
          ...companyView,
          companyPosts: removePost(companyView.companyPosts, postId),
        });
      },
      onError: (error) => {
        throw error;
      },
    },
  );
}
