import React, { Fragment } from "react";
import Input from "components_new/atoms/Input";
import Text from "components_new/atoms/Text";
import TextArea from "components_new/atoms/TextArea";

const SnapshotBasic = ({ formData, handleChange, errors }) => {
  const { name, description } = formData;
  return (
    <Fragment>
      <Text type="label-bold" color="brand-light">
        BASIC DETAILS
      </Text>
      <Text type="title-2">Snapshot Name*</Text>
      <Input
        name="name"
        placeholder="Add a name"
        value={name}
        onChange={(_, { value }) => {
          handleChange("name", value);
        }}
        isRequired
        error={!!errors.for("name")}
        errorMessage={errors.for("name")}
        size="small"
      />
      <TextArea
        name="description"
        label="Description"
        value={description}
        placeholder="Add a description"
        onChange={(_, { value }) => {
          handleChange("description", value);
        }}
        error={!!errors.for("description")}
        errorMessage={errors.for("description")}
        size="small"
      />
    </Fragment>
  );
};

export default SnapshotBasic;
