export const seriesDropdownItem = [
  { key: "Common Stock", text: "Common Stock", value: "Common Stock" },
  { key: "Series A", text: "Series A", value: "Series A" },
  { key: "Series B", text: "Series B", value: "Series B" },
  { key: "Series C", text: "Series C", value: "Series C" },
  { key: "Series D", text: "Series D", value: "Series D" },
  { key: "Series E", text: "Series E", value: "Series E" },
  { key: "Series F", text: "Series F", value: "Series F" },
  { key: "Series G", text: "Series G", value: "Series G" },
  { key: "Series H", text: "Series H", value: "Series H" },
];
export const splitSelectionOptions = [
  {
    key: "per share",
    text: "Per Share",
    value: "per_share",
  },
  {
    key: "total",
    text: "Total",
    value: "total",
  },
];
