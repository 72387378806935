import React, { useState } from "react";
import groupBy from "lodash/groupBy";
import { useDispatch, useSelector } from "react-redux";
import { openInModal, ModalNames } from "store/modal";
import { getResource } from "store/resourcesV2/selectors";
import DiscussionsList from "components_new/elements/Discussions/DiscussionsList";
import { useCreateDealPost } from "components_new/views/Deals/hooks/mutations/useCreateDealPost";
import { useUpdateDealPost } from "components_new/views/Deals/hooks/mutations/useUpdateDealPost";
import { useDeleteDealPost } from "components_new/views/Deals/hooks/mutations/useDeleteDealPost";

function DealDiscussionWrapper({
  pipelineId,
  dealId,
  posts,
  openContactDetailsView,
  noteToHighlight,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { organizations } = state;
  const currentOrganization = getResource(
    state,
    "organizations",
    organizations.currentOrganizationId,
  );
  const { organization_memberships } = currentOrganization;
  const usersById = groupBy(organization_memberships, "user_id");
  const resource = {
    type: "Deal",
    deal_id: dealId,
    pipeline_id: pipelineId,
  };

  const { mutateAsync: saveDealPost } = useCreateDealPost();
  const { mutateAsync: updateDealPost } = useUpdateDealPost();
  const { mutateAsync: deleteDealPost } = useDeleteDealPost();

  const createPost = (resource, post) => {
    setIsSubmitting(true);
    return saveDealPost({ resource, post }).then(() => setIsSubmitting(false));
  };

  const updatePost = (resource, postId, post) => {
    setIsSubmitting(true);
    return updateDealPost({ resource, postId, post }).then(() =>
      setIsSubmitting(false),
    );
  };
  const removePost = (resource, postId) => {
    setIsSubmitting(true);
    return deleteDealPost({ resource, postId }).then(() =>
      setIsSubmitting(false),
    );
  };

  const openDocumentsUploader = (options = {}) => {
    dispatch(openInModal(ModalNames.discussionDocuments, options));
  };

  return (
    <DiscussionsList
      organizationUsername={organizations.currentOrganizationUsername}
      pipelineId={pipelineId}
      dealId={dealId}
      posts={posts}
      users={usersById}
      resource={resource}
      createPost={createPost}
      updatePost={updatePost}
      removePost={removePost}
      openDocumentsUploader={openDocumentsUploader}
      isSubmitting={isSubmitting}
      openContactDetailsView={openContactDetailsView}
      showDiligenceContact={true}
      noteToHighlight={noteToHighlight}
      containerElement="ActiveDeals-SelectedDeal-Details"
      extraScrollHeight={200}
    />
  );
}

export default DealDiscussionWrapper;
