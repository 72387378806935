import Api from "lib/Api";
import { requestStart, requestSuccess, requestError } from "store/requests";
import { receiveView } from "store/views";
import { receiveCollections } from "store/entities";

export function logSale(formData, investmentId) {
  return (dispatch) => {
    dispatch(requestStart("createLogSale"));
    return Api.post(`/v2/investments/${investmentId}/actions/sales`, {
      investment_sale: formData,
    }).then(
      ({ data: sale }) => {
        const { entities } = sale;
        if (entities) {
          const {
            views: { investment },
          } = sale;
          dispatch(receiveCollections(entities));
          dispatch(receiveView("investment", investment));
        }
        dispatch(requestSuccess("createLogSale"));
        return sale;
      },
      (errors) => {
        dispatch(requestError("createLogSale"));
        throw errors;
      },
    );
  };
}
