import React, { Component } from "react";
import PropTypes from "prop-types";
import AdvancedField from "components/shared/form/AdvancedField";
import Select from "components/shared/form/Select";
import SelectAndOther from "components/shared/form/SelectAndOther";
import DatePicker from "components_new/atoms/DatePicker";
import { placeholderDateFormat } from "config/dateInput";
import { errorsPropType, equityInvestmentProptype } from "PropTypes";
import classnames from "classnames";

const TYPE_OF_VESTING_SCHEDULE = [
  { value: "monthly", label: "Monthly" },
  { value: "quarterly", label: "Quarterly" },
  { value: "annually", label: "Annually" },
];

export const VESTING_CLIFF = [
  { value: "twelve", label: "12 months" },
  { value: "twenty_four", label: "24 months" },
  { value: "thirty_six", label: "36 months" },
];

class AdvancedVestingField extends Component {
  static propTypes = {
    errors: errorsPropType,
    formData: equityInvestmentProptype.isRequired,
    updateForm: PropTypes.func.isRequired,
    extraClassnames: PropTypes.string,
  };

  static defaultProps = {
    errors: null,
  };

  clearVestingFieldsData() {
    const { updateForm } = this.props;
    return updateForm("vesting_schedule", null)
      .then(() => updateForm("vesting_cliff_other", null))
      .then(() => updateForm("vesting_cliff_selection", null));
  }

  render() {
    const { updateForm, errors, formData, extraClassnames } = this.props;
    const advancedVestingFieldExtraClassnames = classnames(
      "AdvancedVestingField has-contentWithLabel",
      {
        [extraClassnames]: !!extraClassnames,
      },
    );
    return (
      <AdvancedField
        editorProps={{
          label: "Vesting terms",
          isOptional: false,
        }}
        extraClassnames={advancedVestingFieldExtraClassnames}
        label="Vesting"
        nameCheck="vesting_enabled"
        nameString="vesting"
        valueCheck={formData.vesting_enabled}
        valueString={formData.vesting}
        errorMessage={errors.for("vesting")}
        ref={(el) => {
          this.equity_vesting = el;
        }}
        onChange={(field, value) => {
          const updateField = () => updateForm(field, value);

          if (field == "vesting_enabled" && !value) {
            return this.clearVestingFieldsData().then(updateField);
          } else if (field == "vesting_enabled" && value) {
            return updateForm(
              "vesting_cliff_selection",
              VESTING_CLIFF[0].value,
            ).then(() =>
              updateForm(
                "vesting_schedule",
                TYPE_OF_VESTING_SCHEDULE[0].value,
              ).then(updateField),
            );
          } else {
            return updateField();
          }
        }}
      >
        <Select
          extraClassnames="VestingSchedule"
          inputProps={TYPE_OF_VESTING_SCHEDULE}
          label="Vesting schedule"
          onChange={(value) => updateForm("vesting_schedule", value)}
          value={formData.vesting_schedule}
        />
        <SelectAndOther
          extraClassnames="VestingCliff"
          otherValue="other"
          onOtherSelection={() => updateForm("vesting_cliff_selection", "")}
          onChange={(value) => updateForm("vesting_cliff_selection", value)}
          selectProps={{
            label: "Cliff",
            inputProps: VESTING_CLIFF,
            isOptional: false,
          }}
          value={formData.vesting_cliff_selection}
          ref={(el) => {
            this.vesting_cliff_selection = el;
          }}
        >
          <DatePicker
            name="date"
            label="Custom cliff"
            placeholder={placeholderDateFormat}
            onChange={(value, validated) => {
              updateForm("vesting_cliff_other", value, validated);
            }}
            errorMessage={errors.for("vesting_cliff_other")}
            value={formData.vesting_cliff_other}
            prefillToday={true}
            autoFocus={true}
            oldVersion
          />
        </SelectAndOther>
      </AdvancedField>
    );
  }
}

export default AdvancedVestingField;
