import React from "react";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import EllipsisDropdown from "components_new/elements/EllipsisDropdown";
import Segment from "components_new/atoms/Segment";
import Tooltip from "components_new/atoms/Tooltip";
import Icon from "components_new/atoms/Icon";

const getOptionItemForDeleteAction = (hasDeals, onClickDelete) => {
  return hasDeals
    ? {
        content: (
          <Tooltip
            position="top center"
            content="In order to delete this stage, please move its active deals to another stage."
            trigger={<span className="text">Delete Stage</span>}
          />
        ),
        disabled: true,
      }
    : {
        text: "Delete Stage",
        onClick: onClickDelete,
      };
};

function StageCard({
  stageName,
  stageDeals,
  showDeleteAction,
  onClickEdit,
  onClickDelete,
  disabled,
}) {
  const actions = [
    { key: "editStage", text: "Edit Stage Name", onClick: onClickEdit },
  ];
  const hasDeals = stageDeals ? stageDeals > 0 : null;
  if (showDeleteAction) {
    const optionItem = getOptionItemForDeleteAction(hasDeals, onClickDelete);
    actions.push(optionItem);
  }
  return (
    <Segment extraClassNames="StageCard" border disabled={disabled}>
      <div className="StageCard-name-content">
        <Icon
          extraClassnames="StageCard-grab-icon"
          icon="icon-grab"
          size="tiny"
          origin="custom"
        />
        <div className="StageCard-name-subsection">
          <h3 className="StageCard-name">{stageName}</h3>
          {hasDeals && (
            <div className="StageCard-deals">
              {pluralize("Current Deal", stageDeals, true)}
            </div>
          )}
        </div>
      </div>
      <div className="StageCard-actions">
        <EllipsisDropdown
          extraClassnames="Dropdown--dots"
          options={actions}
          disabled={disabled}
          iconOrientation="vertical"
          iconSize="small"
        />
      </div>
    </Segment>
  );
}

StageCard.propTypes = {
  stageName: PropTypes.string.isRequired,
  onClickEdit: PropTypes.func,
  stageDeals: PropTypes.number,
  onClickDelete: PropTypes.func,
  showDeleteAction: PropTypes.bool,
  disabled: PropTypes.bool,
};

StageCard.defaultProps = {
  stageDeals: null,
  onClickEdit: () => {},
  onClickDelete: () => {},
};

export default StageCard;
