import React, {
  Fragment,
  useCallback,
  useState,
  useEffect,
  useLayoutEffect,
} from "react";
import { useNavigate, useBlocker } from "react-router-dom";

import OrganizationAvatar from "./shared/OrganizationAvatar";
import DeleteOrganization from "components/organizations/DeleteOrganization";
import AvatarUploader from "components/shared/AvatarUploader";
import Alert from "components/shared/Alert";

import Form from "components_new/atoms/Form";
import Input from "components_new/atoms/Input";
import UsernameInput from "components_new/atoms/UsernameInput";
import DropdownForm from "components_new/atoms/DropdownForm";
import TextArea from "components_new/atoms/TextArea";
import Icon from "components_new/atoms/Icon";
import Button from "components_new/atoms/Button";

import isServerValidationError from "lib/isServerValidationError";
import Errors from "lib/errors";
import { Grid } from "@ableco/semantic-ui-react";
import { isEmpty, mapValues } from "lodash";
import { maxSize } from "config/uploadFilesConfig";

const TYPE_OPTIONS = [
  {
    key: "Venture Capital Firm",
    text: "Venture Capital Firm",
    value: "Venture Capital Firm",
  },
  { key: "Angel Network", text: "Angel Network", value: "Angel Network" },
  { key: "Family Office", text: "Family Office", value: "Family Office" },
];

function scrollToTheTop() {
  // TODO : Move this 'mainLayout' variable to a useRef Hook.
  const mainLayout = document.querySelector(".MainLayout-content");
  mainLayout.scrollTop = 0;
}

function scrollToFirstFormElementWithError() {
  const mainLayout = document.querySelector(".MainLayout-content");
  const elementsWithErrors = document.querySelectorAll(".ui.error");

  if (!isEmpty(elementsWithErrors)) {
    const firstElementWithErrors = elementsWithErrors[0];
    const elementOffsetTop = firstElementWithErrors.offsetTop;

    mainLayout.scrollTop = elementOffsetTop;
  }
}

function OrganizationDetails({
  openInvalidLogoModal,
  isOwner,
  isSubmitting,
  organization,
  updateOrganization,
}) {
  const navigate = useNavigate();

  const [showAlert, setShowAlert] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);
  const [formData, setFormData] = useState({
    ...mapValues(organization, (value) => (value === null ? "" : value)),
  });
  const [errors, setErrors] = useState(() => new Errors({}));

  const handleAlertClick = useCallback(() => {
    setIsEditing(false);
    navigate(nextLocation.pathname);
  }, [setIsEditing, nextLocation, navigate]);

  const handleImageUploaded = useCallback(
    (file) => {
      setFormData({
        ...formData,
        logo_url: file.url,
        logo_handle: file.handle,
      });
    },
    [setFormData, formData],
  );

  const updateForm = useCallback(
    (key, value) => {
      setFormData({ ...formData, [key]: value });
      setIsEditing(true);
      setShowAlert(false);
      setErrors(errors.without(key));
    },
    [formData, setFormData, setIsEditing, setShowAlert, setErrors, errors],
  );

  const handleSubmit = useCallback(async () => {
    try {
      await updateOrganization(organization.id, formData);
      scrollToTheTop();
      setIsEditing(false);
    } catch (error) {
      setIsEditing(false);
      setShowAlert(false);
      if (isServerValidationError(error)) {
        const { data } = error;
        setErrors(new Errors(data.errors));
      }
    }
  }, [
    updateOrganization,
    setIsEditing,
    setShowAlert,
    setErrors,
    formData,
    organization,
  ]);

  useLayoutEffect(() => {
    if (!errors.isEmpty()) {
      scrollToFirstFormElementWithError();
    }
  }, [errors]);

  useEffect(() => {
    if (isEmpty(formData) && !isEmpty(organization)) {
      setFormData(organization);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  useBlocker(({ location }) => {
    setShowAlert(true);
    setNextLocation(location);
  }, isEditing);

  const userCannotEdit = !isOwner;

  return (
    <Fragment>
      {showAlert && (
        <Alert
          alertContent="You have unsaved changes. Do you want to exit without saving?"
          showCloseIcon={false}
          onClickAction={handleAlertClick}
          type="warning"
          showActionButton
          showAlertIcon
        />
      )}
      <Form className="FormSection OrganizationDetails">
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <h2 className="FormSection-title u-sectionHeading">
                Organization Details
              </h2>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={10}>
              <AvatarUploader
                logo_url={formData.logo_url}
                onInvalidImageUploaded={openInvalidLogoModal}
                onImageUploaded={handleImageUploaded}
                extraClassnames="FormElement"
                returnBase64={true}
                maxSize={maxSize}
                minImageDimension={250}
                disabled={userCannotEdit}
                renderComponent={(childrenProps) => (
                  <OrganizationAvatar {...childrenProps} />
                )}
              />
              <Input
                label="Organization Display Name"
                aria-label="Organization Display Name"
                extraClassnames="FormElement--DisplayName"
                placeholder="Enter name"
                name="display_name"
                onChange={(_, { value }) => updateForm("name", value)}
                error={!!errors.for("name")}
                errorMessage={errors.for("name")}
                value={formData.name}
                disabled={userCannotEdit}
              />
              <UsernameInput
                label="Organization Username"
                aria-label="Organization Username"
                extraClassnames="FormElement--Username"
                placeholder="Enter username"
                name="username"
                onChange={(value) => updateForm("username", value)}
                error={!!errors.for("username")}
                errorMessage={errors.for("username")}
                value={formData.username}
                disabled={userCannotEdit}
              />
              <DropdownForm
                label="Organization Type"
                extraClassnames="FormElement--Type"
                options={TYPE_OPTIONS}
                onChange={(_, { value }) =>
                  updateForm("organization_type", value)
                }
                placeholder="Select type"
                value={formData.organization_type}
                disabled={userCannotEdit}
              />
              <TextArea
                label="Description"
                aria-label="Description"
                name="description"
                placeholder="Enter description"
                defaultValue={formData.description}
                rows="4"
                onChange={(e, { name, value }) => {
                  updateForm(name, value);
                }}
                disabled={userCannotEdit}
              />
              <Input
                label="Website"
                aria-label="Website"
                extraClassnames="FormElement--Website"
                name="website"
                value={formData.website}
                onChange={(_, { value }) => updateForm("website", value)}
                icon={<Icon icon="ri-global-line" />}
                iconPosition="left"
                disabled={userCannotEdit}
              />
              <Input
                label="Location"
                aria-label="Location"
                extraClassnames="FormElement--Location"
                placeholder="Enter location"
                value={formData.location}
                onChange={(_, { value }) => updateForm("location", value)}
                icon={<Icon icon="ri-map-pin-2-line" />}
                iconPosition="left"
                disabled={userCannotEdit}
              />
              <Button
                primary
                className="OrganizationDetails-SubmitButton"
                onClick={handleSubmit}
                loading={isSubmitting}
                disabled={userCannotEdit}
              >
                Update Details
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
      {!organization.private && (
        <DeleteOrganization
          organization={organization}
          disabled={userCannotEdit}
        />
      )}
    </Fragment>
  );
}

export default OrganizationDetails;
