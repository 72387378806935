import React, { useCallback, useEffect, useState } from "react";
import SnapshotForm from "components_new/views/Snapshots/SnapshotForm";
import getMessage from "components_new/lib/Messages";
import Api from "lib/Api";

import { useCreateSnapshot } from "components_new/views/Snapshots/hooks/mutations/useCreateSnapshot";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import { useLocation } from "react-router-dom";
import { isEmpty } from "lodash";

function NewSnapshotWrapper() {
  const [newSnapshot, setNewSnapshot] = useState({});
  const [isBuildingSnapshot, setIsBuildingSnapshot] = useState(false);
  const { addToast } = useSetToast();

  const searchParams = new URLSearchParams(useLocation().search);
  const snapshotType = searchParams.get("type") || "deal";
  const recipientCompanyId = searchParams.get("recipient_company_id");

  const showConfirmationMessage = () => addToast(getMessage("createSnapshot"));
  const { mutateAsync: createSnapshot } = useCreateSnapshot();

  useEffect(() => {
    setIsBuildingSnapshot(true);
    Api.get("/snapshots/new", {
      params: { type: snapshotType, company_id: recipientCompanyId },
    })
      .then(({ data }) => setNewSnapshot(data))
      .finally(() => setIsBuildingSnapshot(false));
  }, [recipientCompanyId, snapshotType]);

  const handleCreateSnapshot = useCallback(
    async (formData) => {
      return createSnapshot({ formData });
    },
    [createSnapshot],
  );

  return (
    <SnapshotForm
      isLoading={isBuildingSnapshot || isEmpty(newSnapshot)}
      isEditing={false}
      snapshot={newSnapshot}
      submitAction={handleCreateSnapshot}
      showConfirmationMessage={showConfirmationMessage}
      snapshotType={snapshotType}
    />
  );
}

export default NewSnapshotWrapper;
