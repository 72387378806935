const STANDARD = 340;
const WIDE = 600;

export const isKind = (name, kind) => name.toLowerCase().includes(kind);

export const calculateWidth = (
  sidebarComponents,
  componentName,
  sidebarPolicy,
) => {
  const n = sidebarComponents.filter((c) => !!c.visible).length;
  const multiplier = n === 0 ? n : n - 1;

  const { size } = sidebarPolicy;

  let widthNumber;
  if (size === "default") {
    widthNumber = isKind(componentName, "view") ? WIDE : STANDARD;
  } else {
    widthNumber = size === "wide" ? WIDE : STANDARD;
  }

  return (multiplier * widthNumber + widthNumber).toString() + "px";
};
