import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import pluralize from "pluralize";
import TextLabel from "components_new/atoms/TextLabel";
import Icon from "components_new/atoms/Icon";
import "./SectionList.scss";

/* Section List
======================================== */
function SectionList({
  accordion,
  renderEmptySection,
  renderItem,
  renderSectionHeader,
  renderSectionFooter,
  sections,
  showCount,
  extraClassnames,
  collapseIfEmpty = false,
}) {
  const sectionListClassnames = classnames("SectionList", {
    "SectionList-Accordion": !!accordion,
    [extraClassnames]: !!extraClassnames,
  });
  return (
    <div className={sectionListClassnames}>
      {sections.map((section, index) => (
        <Section
          key={`section${index}`}
          accordion={accordion}
          renderEmptySection={renderEmptySection}
          renderSectionHeader={renderSectionHeader}
          renderSectionFooter={renderSectionFooter}
          renderItem={renderItem}
          section={section}
          showCount={showCount}
          collapseIfEmpty={collapseIfEmpty}
        />
      ))}
    </div>
  );
}

/* Section
======================================== */
function Section({
  accordion,
  renderEmptySection,
  renderItem,
  renderSectionHeader,
  renderSectionFooter,
  section,
  showCount,
  collapseIfEmpty,
}) {
  const [accordionOpen, setAccordionOpen] = useState(true);

  useEffect(() => {
    if (collapseIfEmpty && section.data.length === 0) {
      setAccordionOpen(false);
    } else if (!accordionOpen) {
      setAccordionOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapseIfEmpty, section.data.length]);

  const toggleAccordion = () => {
    if (accordion) {
      setAccordionOpen(!accordionOpen);
    }
  };

  return (
    <div className="SectionList-Section">
      {renderSectionHeader ? (
        renderSectionHeader(
          section.title,
          section,
          accordion,
          accordionOpen,
          toggleAccordion,
        )
      ) : (
        <SectionHeader
          title={section.title}
          accordion={accordion}
          accordionOpen={accordionOpen}
          count={section.data.length}
          onClick={toggleAccordion}
          showCount={showCount}
        />
      )}
      <SectionItems
        items={section.data}
        renderEmptySection={renderEmptySection}
        renderItem={renderItem}
        title={section.title}
        accordion={accordion}
        accordionOpen={accordionOpen}
      />
      {renderSectionFooter && renderSectionFooter(section)}
    </div>
  );
}

/* Default Section Header
======================================== */
function SectionHeader({
  accordion,
  accordionOpen,
  count,
  onClick,
  showCount,
  title,
}) {
  const sectionHeaderClassnames = classnames(
    "SectionList-SectionHeader-Default",
    {
      "SectionList-SectionHeader-Accordion-Open": accordion && accordionOpen,
    },
  );
  const accordionIndicatorClassnames = classnames(
    "SectionList-SectionHeader-Default-AccordionIndicator",
    {
      "SectionList-Accordion-Open": accordion && accordionOpen,
    },
  );
  return (
    <div className={sectionHeaderClassnames} onClick={onClick}>
      <TextLabel weight="bold">
        {title} {showCount && `(${count})`}
      </TextLabel>
      {accordion && (
        <div className={accordionIndicatorClassnames}>
          <Icon icon="ri-arrow-down-s-line" />
        </div>
      )}
    </div>
  );
}

/* Section Items
======================================== */
function SectionItems({
  items,
  renderEmptySection,
  renderItem,
  title,
  accordion,
  accordionOpen,
}) {
  if (accordion && !accordionOpen) {
    return null;
  }

  if (!items || items.length === 0) {
    return (
      <EmptySection title={title} renderEmptySection={renderEmptySection} />
    );
  }

  return (
    <div className="SectionList-Section-Items">
      {!!items && items.map((item, idx) => renderItem(item, idx))}
    </div>
  );
}

/* Default Empty Section
======================================== */
function EmptySection({ title, renderEmptySection }) {
  if (renderEmptySection) {
    return renderEmptySection(title);
  }
  return (
    <div className="SectionList-Section-EmptySection">0 {pluralize(title)}</div>
  );
}

SectionList.propTypes = {
  accordion: PropTypes.bool,
  renderEmptySection: PropTypes.func,
  renderItem: PropTypes.func,
  renderSectionHeader: PropTypes.func,
  renderSectionFooter: PropTypes.func,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.sting,
      data: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  ),
  showCount: PropTypes.bool,
  extraClassnames: PropTypes.string,
  collapseIfEmpty: PropTypes.bool,
};

Section.propTypes = {
  accordion: PropTypes.bool,
  renderEmptySection: PropTypes.func,
  renderItem: PropTypes.func,
  renderSectionHeader: PropTypes.func,
  renderSectionFooter: PropTypes.func,
  section: PropTypes.shape({
    title: PropTypes.sting,
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  showCount: PropTypes.bool,
  title: PropTypes.string,
  collapseIfEmpty: PropTypes.bool,
};

SectionHeader.propTypes = {
  accordion: PropTypes.bool,
  accordionOpen: PropTypes.bool,
  count: PropTypes.number,
  onClick: PropTypes.func,
  showCount: PropTypes.bool,
  title: PropTypes.string,
};

SectionItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  renderEmptySection: PropTypes.func,
  renderItem: PropTypes.func,
  title: PropTypes.string,
  accordion: PropTypes.bool,
  accordionOpen: PropTypes.bool,
};

EmptySection.propTypes = {
  renderEmptySection: PropTypes.func,
  title: PropTypes.string,
};

export default SectionList;
