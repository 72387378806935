import React, { Component } from "react";
import PropTypes from "prop-types";
import TrashIcon from "-!svg-react-loader?!assets/icons/utility/trash.svg?name=TrashIcon";
import ActionButton from "components/shared/ActionButton";
import SecondaryButton from "components/shared/SecondaryButton";
import WarningButton from "components/shared/WarningButton";

function RemoveConfirmation({
  deleteButtonText,
  deleteTitle,
  id,
  index,
  onCancel,
  onSubmit,
}) {
  return (
    <div className="RemoveConfirmation">
      <p>{deleteTitle}</p>
      <div className="RemoveConfirmation--actions">
        <SecondaryButton size="small" onClick={onCancel}>
          Nevermind
        </SecondaryButton>
        <WarningButton size="small" onClick={() => onSubmit(index, id)}>
          {deleteButtonText}
        </WarningButton>
      </div>
    </div>
  );
}

RemoveConfirmation.propTypes = {
  deleteButtonText: PropTypes.string,
  deleteTitle: PropTypes.string,
  id: PropTypes.number,
  index: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

RemoveConfirmation.defaultProps = {
  deleteButtonText: "Delete Row",
  deleteTitle: "Delete this?",
};

function RemoveButton({ showRemoveConfirmation }) {
  return (
    <ActionButton
      customClass="Button--empty"
      onClick={showRemoveConfirmation}
      aria-label="Delete"
    >
      <TrashIcon />
    </ActionButton>
  );
}
const initialState = {
  showRemoveConfirmation: false,
};

class RemoveRow extends Component {
  state = initialState;

  toggleRemoveConfirmation = () => {
    this.setState({
      showRemoveConfirmation: !this.state.showRemoveConfirmation,
    });
  };

  componentWillUnmount() {
    this.setState({
      ...initialState,
    });
  }

  render() {
    const { id, index, removeRow, deleteTitle, deleteButtonText } = this.props;
    return (
      <div className="RemoveRow">
        {this.state.showRemoveConfirmation && (
          <RemoveConfirmation
            id={id}
            index={index}
            onCancel={this.toggleRemoveConfirmation}
            onSubmit={removeRow}
            deleteTitle={deleteTitle}
            deleteButtonText={deleteButtonText}
          />
        )}
        <RemoveButton showRemoveConfirmation={this.toggleRemoveConfirmation} />
      </div>
    );
  }
}

RemoveRow.propTypes = {
  index: PropTypes.number.isRequired,
  id: PropTypes.number,
  removeRow: PropTypes.func.isRequired,
  deleteTitle: PropTypes.string,
  deleteButtonText: PropTypes.string,
};

export default RemoveRow;
