import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  validateLoanConversion,
  createLoanConversion as createLoanConversionHelper,
} from "./actions";
import { useQueryClient } from "react-query";

export default () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const invalidateQueries = useCallback(() => {
    queryClient.invalidateQueries(["Company"]);
  }, [queryClient]);

  const createLoanConversion = async (formData, investmentId) => {
    const {
      investment: validatedInvestment,
      formData: validatedFormData,
    } = await dispatch(validateLoanConversion(investmentId, formData));

    const previousInvestment = await dispatch(
      createLoanConversionHelper(validatedInvestment, validatedFormData),
    );

    invalidateQueries();
    return previousInvestment;
  };

  return { createLoanConversion };
};
