import { useMutation, useQueryClient } from "react-query";
import useOrganizations from "store/organizations/hooks";
import Api from "lib/Api";

export function useDeleteSnapshot() {
  const queryClient = useQueryClient();
  const { currentOrganizationName } = useOrganizations();

  return useMutation(
    (snapshotId) => {
      return Api.delete(`/snapshots/${snapshotId}`);
    },
    {
      onSuccess: (_, snapshotId) => {
        const key = ["SnapshotsView", currentOrganizationName];
        const snapshotView = queryClient.getQueryData(key);

        if (snapshotView?.snapshots.length > 0) {
          queryClient.setQueryData(key, {
            snapshots: snapshotView.snapshots.filter(
              (item) => item.id !== snapshotId,
            ),
          });
        }
      },
    },
  );
}
