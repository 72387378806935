import { useMutation, useQueryClient } from "react-query";
import { buildPostResourceRoute } from "lib/globalActionsHelper";
import updatePost from "lib/posts/updatePost";
import Api from "lib/Api";

function updateDealPost(oldData, newPost) {
  return {
    ...oldData,
    posts: updatePost(oldData.posts, newPost),
  };
}

export function useUpdateDealPost() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ resource, postId, post }) => {
      const postResourceRoute = buildPostResourceRoute(resource);
      return Api.patch(`${postResourceRoute}/posts/${postId}`, { post });
    },
    {
      onSuccess: ({ data: newPost }, { resource }) => {
        return queryClient.setQueryData(["Deal", resource.deal_id], (oldData) =>
          updateDealPost(oldData, newPost),
        );
      },
    },
  );
}
