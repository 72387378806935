import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { uniqueId } from "lodash";
import FormErrorMessage from "components/shared/form/FormErrorMessage";
import HelperMessage from "components/shared/form/HelperMessage";
import LockIcon from "-!svg-react-loader?!assets/icons/utility/lock.svg?name=LockIcon";
import FormLabel from "components/shared/form/FormLabel";
import Scroll from "react-scroll";

function FormElement(props) {
  const {
    children,
    errorDisplayValidation,
    errorMessage,
    extraClassnames,
    isOptional,
    label,
    labelExtraClassnames,
    name,
    disabled,
    textHelper,
    showLock,
    idOverride = null,
  } = props;
  const [id, setId] = useState(uniqueId("FormElement"));
  const [shouldShowErrors, setShouldShowErrors] = useState(false);

  useEffect(() => {
    setId(uniqueId("FormElement"));
  }, []);

  useEffect(() => {
    if (errorDisplayValidation(errorMessage)) {
      setShouldShowErrors(true);
    }
  }, [errorDisplayValidation, errorMessage]);

  const { Element } = Scroll;
  const formElementHolderClassnames = classnames("FormElement", {
    [extraClassnames]: !!extraClassnames,
    "is-disabled": !!disabled,
    "has-label": !!label,
    "has-errors": !!errorMessage,
    "has-textHelper": !!textHelper,
  });
  const chidrenWithId = React.cloneElement(children, { id });
  const formLabel = label ? (
    <FormLabel
      label={label}
      id={idOverride || id}
      isOptional={isOptional}
      extraClassnames={labelExtraClassnames}
    />
  ) : null;

  return (
    <Element
      className={formElementHolderClassnames}
      name={`FormElement--${name}`}
    >
      {formLabel}
      {chidrenWithId}
      {showLock && (
        <span className="LockedIcon">
          <LockIcon />
        </span>
      )}
      {textHelper && <HelperMessage text={textHelper} />}
      {shouldShowErrors && <FormErrorMessage errorMessage={errorMessage} />}
    </Element>
  );
}

FormElement.defaultProps = {
  disabled: false,
  extraClassnames: null,
  errorMessage: null,
  isOptional: false,
  textHelper: null,
  label: null,
  labelExtraClassnames: "",
  errorDisplayValidation: (errorMessage) => {
    return !!errorMessage;
  },
  showLock: false,
};

FormElement.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  extraClassnames: PropTypes.string,
  labelExtraClassnames: PropTypes.string,
  isOptional: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  errorDisplayValidation: PropTypes.func,
  showLock: PropTypes.bool,
  textHelper: PropTypes.string,
  idOverride: PropTypes.string,
};

export default FormElement;
