import React, { Fragment } from "react";
import FormGroup from "components_new/elements/FormGroup";
import DatePicker from "components_new/atoms/DatePicker";
import DropdownForm from "components_new/atoms/DropdownForm";
import RealizedGain from "components_new/SidebarComponents/forms/groups/RealizedGain";
import EquityProceeds from "components_new/SidebarComponents/forms/groups/EquityProceeds";
import { distributionTypes, proceedsTypeDropdownItems } from "./config";

function Distribution({
  handleChange,
  errors,
  formData,
  componentName,
  showAdditionalFields,
  proceedsType,
  setProceedsType,
  mountSidebarComponent,
  hideSidebarComponent,
}) {
  const { date, distribution_type, cash_amount, type } = formData;
  const handleProceedsTypeChange = (value) => {
    setProceedsType(value);
    if (value === "cash") {
      handleChange("is_cash_proceed", true);
      handleChange("is_equity_proceed", false);
    } else if (value === "equity") {
      handleChange("is_equity_proceed", true);
      handleChange("type", "equity");
      handleChange("is_cash_proceed", false);
    } else {
      handleChange("is_equity_proceed", true);
      handleChange("type", "both");
      handleChange("is_cash_proceed", true);
    }
    formData.type = value;
  };

  return (
    <Fragment>
      <FormGroup title="Distribution Details" isExpanded required>
        <DatePicker
          name="date"
          label="Date of distribution"
          prefillToday
          onChange={(value, validated) => {
            handleChange("date", value, validated);
          }}
          value={date}
          size="tiny"
          required
          error={!!errors.for("date")}
          errorMessage={errors.for("date")}
        />
        <DropdownForm
          name="distribution_type"
          label="Type of distribution"
          aria-label="Type of distribution"
          placeholder="Select Type of distribution"
          items={distributionTypes}
          onChange={(_, { value }) => handleChange("distribution_type", value)}
          value={distribution_type}
          error={!!errors.for("distribution_type")}
          errorMessage={errors.for("distribution_type")}
          required
          size="tiny"
        />
        <DropdownForm
          label="Type of Proceeds"
          aria-label="Type of Proceeds"
          required
          placeholder="Select proceeds type"
          value={type}
          items={proceedsTypeDropdownItems}
          onChange={(_, { value }) => handleProceedsTypeChange(value)}
          size="tiny"
        />
      </FormGroup>
      {showAdditionalFields && (
        <AdditionalDistributionDetails
          proceedsType={proceedsType}
          handleChange={handleChange}
          formData={formData}
          componentName={componentName}
          cashAmount={cash_amount}
          mountSidebarComponent={mountSidebarComponent}
          hideSidebarComponent={hideSidebarComponent}
          errors={errors}
        />
      )}
    </Fragment>
  );
}

function AdditionalDistributionDetails({
  proceedsType,
  handleChange,
  formData,
  cashAmount,
  componentName,
  mountSidebarComponent,
  hideSidebarComponent,
  errors,
}) {
  const distributionTypesMapping = {
    cash: (
      <RealizedGain
        realizedGain={cashAmount}
        onChange={handleChange}
        errors={errors}
        required
        isExpanded
      />
    ),
    equity: (
      <EquityProceeds
        formData={formData}
        handleChange={handleChange}
        componentName={componentName}
        mountSidebarComponent={mountSidebarComponent}
        hideSidebarComponent={hideSidebarComponent}
        errors={errors}
        bottomBorder
      />
    ),
    both: (
      <div>
        <RealizedGain
          realizedGain={cashAmount}
          onChange={handleChange}
          errors={errors}
          required
          isExpanded
        />
        <EquityProceeds
          formData={formData}
          handleChange={handleChange}
          componentName={componentName}
          mountSidebarComponent={mountSidebarComponent}
          hideSidebarComponent={hideSidebarComponent}
          errors={errors}
          bottomBorder
        />
      </div>
    ),
  };

  return distributionTypesMapping[proceedsType];
}

export default Distribution;
