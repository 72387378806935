import PropTypes from "prop-types";
import React from "react";
import Button from "components/shared/Button";

function ActionButton(props) {
  const { children, behavior, ...restProps } = props;

  return (
    <Button {...restProps} behavior={behavior} size="small">
      {children}
    </Button>
  );
}

ActionButton.propTypes = {
  children: PropTypes.node,
  behavior: PropTypes.string,
};

ActionButton.defaultProps = {
  behavior: "action",
};

export default ActionButton;
