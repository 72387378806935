import React from "react";
import { modalComponents } from "store/modal";
import ModalOverlay from "./ModalOverlay";
import classNames from "classnames";
import { MODAL_DEFAULT_SIZE } from "./modalSettings";
import ModalCloseButton from "./ModalCloseButton";
import FlashListModalContainer from "containers/shared/modals/FlashListModalContainer";
import { motion, AnimatePresence } from "framer-motion";

function ModalLoader({ children, size }) {
  const className = classNames("ModalLoader", `ModalLoader--${size}`);

  return (
    <div className="ModalLoader-container">
      <div className={className}>{children}</div>
    </div>
  );
}

function ModalContent({
  children,
  isFirstChild,
  isVisible,
  onCloseButtonClick,
}) {
  const className = classNames("ModalContent", {
    ["isChildHidden"]: !isVisible,
    ["isFirstChild"]: isFirstChild,
  });

  return (
    <div className={className}>
      <ModalCloseButton onClick={onCloseButtonClick} />
      {children}
    </div>
  );
}

export { ModalLoader };
export default function Modal({
  isOpen,
  openModalComponents,
  closeModal,
  modalOptions,
}) {
  let content = null;

  if (isOpen) {
    const firstComponentKey = openModalComponents[0];
    const size = modalComponents[firstComponentKey].size || MODAL_DEFAULT_SIZE;

    content = (
      <ModalOverlay>
        <FlashListModalContainer />
        <ModalLoader size={size}>
          {openModalComponents.map((key, index) => {
            const { component } = modalComponents[key];
            const child = React.createElement(
              component,
              modalOptions[key] || {},
            );
            const isFirstChild = index === 0;
            const isVisible = index === openModalComponents.length - 1;

            return (
              <ModalContent
                key={key}
                isVisible={isVisible}
                isFirstChild={isFirstChild}
                onCloseButtonClick={closeModal}
              >
                {child}
              </ModalContent>
            );
          })}
        </ModalLoader>
      </ModalOverlay>
    );
  }

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          key="child"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {content}
        </motion.div>
      )}
    </AnimatePresence>
  );
}
