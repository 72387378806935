import React from "react";
import Header from "components_new/atoms/Header";
import Icon from "components_new/atoms/Icon";
import Tooltip from "components_new/atoms/Tooltip";
import Link from "components_new/atoms/Link";
import middleEllipsis from "lib/middleEllipsis";
import formatBytes from "components_new/lib/formatBytes";

function File({ file }) {
  const fileName = middleEllipsis(file.file_name, 50);

  return (
    <div className="Attachment-File">
      <Link
        to={file.file_url}
        extraClassnames="Attachment-File-Link"
        isExternal
      >
        <Header extraClassName="Attachment-File-Title">{fileName}</Header>
        <div className="Attachment-File-Footer">
          <div className="Attachment-File-Size">{formatBytes(file.size)}</div>
          <Tooltip
            position="top center"
            pinned
            trigger={<Icon size="tiny" icon="ri-attachment-2" />}
            content="Download"
          />
        </div>
      </Link>
    </div>
  );
}
export default File;
