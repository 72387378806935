import PropTypes from "prop-types";

export const columnDefinitionPropType = PropTypes.shape({
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  subhead: PropTypes.string,
});

export const holdingsTableDataPropType = {
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fully_diluted_shares_percentage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  cost_basis: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gain: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  multiple: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  cost_basis_subhead: PropTypes.string,
  valuation_subhead: PropTypes.string,
};

export const companyRowPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  logo_url: PropTypes.string,
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  verified: PropTypes.bool,

  ...holdingsTableDataPropType,
});

export const holdingRowPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  origin_note: PropTypes.string,

  ...holdingsTableDataPropType,
});
