import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FileRow from "components_new/elements/FileRow";
import FileCategory from "components_new/elements/FileCategory";
import formatDate from "lib/formatDate";
import { map, groupBy } from "lodash";
import { documentPropType } from "PropTypes";

function DealFiles({
  files,
  currentOrganizationUsername,
  setEditFile,
  setDeleteFile,
  resetFileActions,
  currentAction,
  editAction,
  deleteAction,
  modifiedFileId,
}) {
  const filesByDeal = groupBy(files, "deal_id");

  return map(filesByDeal, (currentFiles) => {
    const {
      deal_id: dealId,
      deal_date: dealDate,
      deal_round_name: dealRoundName,
    } = currentFiles[0];
    const fileCategoryTitle = (
      <Fragment>
        {dealRoundName ? dealRoundName : "Deal from "}
        <span> ({formatDate(dealDate, "fullMonthAndYear")}) </span>
      </Fragment>
    );

    return (
      <FileCategory
        title={fileCategoryTitle}
        showFolderIcon={false}
        childFileCategory={true}
        key={dealId}
        border="top"
      >
        {map(currentFiles, (tempFile) => {
          return (
            <FileRow
              key={tempFile.id}
              file={tempFile}
              setEditFile={setEditFile}
              setDeleteFile={setDeleteFile}
              resetFileActions={resetFileActions}
              currentAction={currentAction}
              editAction={editAction}
              deleteAction={deleteAction}
              modifiedFileId={modifiedFileId}
              currentOrganizationUsername={currentOrganizationUsername}
            />
          );
        })}
      </FileCategory>
    );
  });
}

DealFiles.propTypes = {
  files: PropTypes.arrayOf(documentPropType).isRequired,
  currentOrganizationUsername: PropTypes.string.isRequired,
  setEditFile: PropTypes.func.isRequired,
  setDeleteFile: PropTypes.func.isRequired,
  resetFileActions: PropTypes.func.isRequired,
  currentAction: PropTypes.string,
  editAction: PropTypes.func.isRequired,
  deleteAction: PropTypes.func.isRequired,
  modifiedFileId: PropTypes.number,
};

export default DealFiles;
