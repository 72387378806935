import React from "react";
import PropTypes from "prop-types";
import Icon from "components_new/atoms/Icon";

function FileIcon({ type, ...otherProps }) {
  const origin = type === "icon-file-preview" ? "custom" : "remixIcon";

  return <Icon icon={type} origin={origin} {...otherProps} />;
}

FileIcon.propTypes = {
  type: PropTypes.string.isRequired,
};

export default FileIcon;
