import React from "react";
import { Sidebar as SemanticSidebar } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/sidebar.css";
import "./Sidebar.scss";

function SidebarPusher({ children, ...props }) {
  return <SemanticSidebar.Pusher {...props}>{children}</SemanticSidebar.Pusher>;
}

export default SidebarPusher;
