import React from "react";
import useInputId from "lib/hooks/useInputId";
import classNames from "classnames";
import "./ColorPicker.scss";

function ColorPicker({
  errorMessage,
  label,
  extraClassnames,
  size,
  onChange,
  ...otherProps
}) {
  const id = useInputId();
  const colorPickerclassnames = classNames("ColorPicker", {
    error: !!errorMessage,
    [extraClassnames]: !!extraClassnames,
    [size]: !!size,
  });

  return (
    <div className={colorPickerclassnames}>
      {label && <label htmlFor={id}>{label}</label>}
      <input
        type="color"
        onChange={(event) => onChange(event.target.value)}
        {...otherProps}
      />
      {errorMessage && (
        <label className="Input-errorMessage">{errorMessage}</label>
      )}
    </div>
  );
}

export default ColorPicker;
