import Api from "lib/Api";
import Cookies from "js-cookie";
import cookieName from "config/async_auth";
import {
  ASYNC_AUTH_SUCCESS,
  RECEIVE_ASYNC_AUTH_USER,
  LOGOUT,
} from "./actionsTypes";
import {
  mutationStart,
  mutationSuccess,
  mutationFail,
} from "store/domain/domainActions";
import {
  verifyCodeError,
  verifyCodeStart,
  verifyCodeSuccess,
} from "actions/synchronous/auth";

export function asyncAuthSuccess(id, token, expiresAt, userData) {
  return { type: ASYNC_AUTH_SUCCESS, id, token, expiresAt, userData };
}

export function receiveAsyncAuthUser(user) {
  return { type: RECEIVE_ASYNC_AUTH_USER, user };
}

export function asyncAuthLogout() {
  return { type: LOGOUT };
}

export function verifyUser(payload) {
  return (dispatch) => {
    dispatch(mutationStart("verifyUser"));
    return Api.post("/async_auth_token", payload, { withoutOrganization: true })
      .then((response) => {
        dispatch(mutationSuccess("verifyUser"));
        const { data } = response;
        return {
          id: data.async_auth_token_id,
          token: data.async_auth_token,
          expiresAt: data.async_auth_token_expires_at,
          phone_number: data.phone_number,
        };
      })
      .catch((error) => {
        dispatch(mutationFail("verifyUser"));
        throw error;
      });
  };
}

export function sendValidationCode(phoneNumber) {
  return (dispatch) => {
    dispatch(mutationStart("sendValidationCode"));
    return Api.post(
      `/session/resend_code`,
      { phone_number: phoneNumber },
      { withoutOrganization: true },
    )
      .then(() => {
        dispatch(mutationSuccess("sendValidationCode"));
      })
      .catch((error) => {
        dispatch(mutationFail("sendValidationCode"));
        throw error;
      });
  };
}

export function updatePhoneNumber(authCode, newPhoneNumber) {
  return (dispatch) => {
    const payload = {
      auth_code: authCode,
      new_phone_number: newPhoneNumber,
    };

    dispatch(verifyCodeStart());
    return Api.patch("/phone_number", payload, { withoutOrganization: true })
      .then((response) => {
        dispatch(verifyCodeSuccess());
        const { phone_number } = response.data;
        return phone_number;
      })
      .catch((error) => {
        const { response } = error;
        dispatch(verifyCodeError(response));
        throw error;
      });
  };
}

export function removeAsyncAuthToken() {
  return () => {
    Cookies.remove(cookieName);
  };
}

export function persistAsyncAuthToken(id, token, expires) {
  const secure = process.env.NODE_ENV === "production";
  return () => {
    const authObject = { id, token, expires };
    Cookies.set(cookieName, authObject, { expires: new Date(expires), secure });
  };
}
