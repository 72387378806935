import { connect } from "react-redux";
import UploadDocuments from "components/shared/UploadDocuments";
import { cleanDocumentUploads } from "actions/synchronous/documentUploads";
import { getEntity } from "store/entities";
import { closeModal } from "store/modal";

function mapStateToProps(state, { investment }) {
  // TODO: Please remove the conditional after we update the investment view with the new one version v2.
  const companyLink = investment.company_name
    ? investment.company_name
    : getEntity(state, "companyAvatars", investment.company_id).name;
  const portfolioLink = investment.portfolio_name
    ? investment.portfolio_name
    : getEntity(state, "portfolios", investment.portfolio_id).name;
  return {
    title: "Log Capital Call",
    subTitle: `Investment in ${companyLink} by ${portfolioLink}`,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { storeDocumentsInCapitalCalls, index, item, updateForm } = ownProps;
  return {
    onDoneButtonClick: (filepickerData) => {
      storeDocumentsInCapitalCalls(filepickerData, index, item, updateForm);
      dispatch(closeModal());
      dispatch(cleanDocumentUploads());
    },
    onCancelButtonClick: () => {
      dispatch(closeModal());
      dispatch(cleanDocumentUploads());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocuments);
