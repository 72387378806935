import React from "react";
import PropTypes from "prop-types";
import Text from "components_new/atoms/Text";
import BasicLabel from "components_new/atoms/BasicLabel";
import Avatar from "components_new/elements/Avatar";
import { Link } from "react-router-dom";
import "./TaskableAvatar.scss";

const TASKABLE_ICON = {
  Company: "company-sm",
  Investment: "investment-sm",
  Deal: "deal-sm",
};

export default function TaskableAvatar({
  taskableId,
  taskableType,
  taskableName,
  taskablePath,
}) {
  const avatarSubtitle = taskablePath ? (
    <Link to={taskablePath}>{taskableName}</Link>
  ) : (
    taskableName
  );

  if (!taskableId) {
    return null;
  }
  return (
    <div className="TaskableAvatar">
      <Text extraClassnames="TaskableAvatar-Label">
        This task is related to:
      </Text>
      <Avatar
        title={
          <BasicLabel type="transparent">
            {taskableType.toUpperCase()}
          </BasicLabel>
        }
        subtitle={avatarSubtitle}
        avatarIcon={TASKABLE_ICON[taskableType]}
        width={30}
        height={30}
      />
    </div>
  );
}

TaskableAvatar.propTypes = {
  organizationUsername: PropTypes.string,
  taskableId: PropTypes.string,
  taskableType: PropTypes.string,
  taskableName: PropTypes.string,
  taskablePath: PropTypes.string,
};
