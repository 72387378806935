import React from "react";
import Form from "components_new/atoms/Form";
import Icon from "components_new/atoms/Icon";
import ExpandIcon from "components_new/elements/ExpandIcon";
import classNames from "classnames";
import "./Form.scss";

function FormWrapper({ extraClassName, ...otherProps }) {
  const formClassnames = classNames("Form", {
    [extraClassName]: !!extraClassName,
  });
  return <Form className={formClassnames} {...otherProps} />;
}

function FormHeader({ children, onClose, onToogle }) {
  return (
    <div className="Form-header">
      <div className="Form-header--title">{children}</div>
      <ExpandIcon onClick={() => onToogle()} size="tiny" />
      <Icon
        className="Form-header--close"
        icon="ri-close-line"
        size="small"
        onClick={onClose}
      />
    </div>
  );
}

function FormContent({ children }) {
  return <div className="Form-content">{children}</div>;
}

function FormFooter({ children }) {
  return <div className="Form-footer">{children}</div>;
}

FormWrapper.Header = FormHeader;
FormWrapper.Content = FormContent;
FormWrapper.Footer = FormFooter;

export default FormWrapper;
