import React from "react";
import { Form } from "@ableco/semantic-ui-react";
import SuggestedButton from "components_new/elements/SuggestedButton";
import "./SuggestedGroup.scss";

function SuggestedGroup({ group, onChange, label }) {
  return (
    <Form.Field className="SuggestedGroup">
      {label && (
        <label>
          <span>{label}</span>
        </label>
      )}
      <div className="SuggestedGroup-items">
        {group.map((item, index) => {
          return (
            <SuggestedButton
              key={index}
              label={item.label}
              onAdd={() => onChange(item.value, true)}
              onRemove={() => onChange(item.value, null)}
              isSelected={item.isSelected}
              disabled={item.disabled}
            >
              {item.content}
            </SuggestedButton>
          );
        })}
      </div>
    </Form.Field>
  );
}

export default SuggestedGroup;
