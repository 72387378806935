import React from "react";
import TextArea from "components/shared/form/TextArea";
import PreFilledInput from "components_new/atoms/PreFilledInput";
import Tags from "components/shared/Tags";
import "./CompanySelector.scss";

function BaseCompanyDetail({ companyFormData, handleCompanyChange }) {
  return (
    <div className="CompanySelector BaseCompanyDetail">
      <TextArea
        name="description"
        extraClassnames="CompanyPrivateProfileForm-FormElement"
        label="Description"
        placeholder="Add Description"
        value={companyFormData.description}
        defaultValue={companyFormData.description}
        onChange={(value) => handleCompanyChange("description", value)}
        rows="4"
        isOptional
      />
      <PreFilledInput
        name="headquarters"
        extraClassnames="CompanyPrivateProfileForm-FormElement"
        label="Headquarters"
        placeholder="Add Headquarters"
        value={companyFormData.headquarters}
        onChange={(value) => handleCompanyChange("headquarters", value)}
        type="location"
        isOptional
      />
      <Tags
        tagType="company"
        label="Company Tags"
        placeholder="Add company tags"
        name="tags"
        extraClassnames="CompanyPrivateProfileForm-FormElement"
        taggedList={companyFormData.tags}
        onChange={(value) => handleCompanyChange("tags", value)}
        isOptional
      />
      <hr className="separator" />
    </div>
  );
}

export default BaseCompanyDetail;
