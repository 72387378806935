import formatSufixedPrice from "lib/formatSufixedPrice";
import formatPrice from "lib/formatters/formatPrice";
import { displayDateFormat } from "config/dateInput";
import moment from "moment";
import isValidNumberInput from "lib/formatters/isValidNumberInput";

export default function formatTooltip(field) {
  return {
    cash_out: {
      amount: (amount) =>
        isValidNumberInput(amount) ? formatPrice(amount) : "",
      date: (date) =>
        `Invested on ${moment.utc(date).format(displayDateFormat)}`,
    },
    cash_in: {
      amount: (amount) =>
        isValidNumberInput(amount) ? formatPrice(amount) : "",
      date: (date) =>
        `Received on ${moment.utc(date).format(displayDateFormat)}`,
    },
    cumulative_cash_flow: {
      amount: (amount) => formatSufixedPrice(amount),
      date: (date) => `${moment.utc(date).format(displayDateFormat)}`,
    },
  }[field];
}
