import React from "react";
import DocumentsTable from "components/documents/DocumentsTable";
import PropTypes from "prop-types";
import { documentsPropType } from "PropTypes";

function DocumentsInvestmentView({
  documents,
  bulkUpdateDocuments,
  openInvestmentsDocumentsModal,
}) {
  return (
    <div className="DocumentsInvestment">
      <DocumentsTable
        documents={documents}
        bulkUpdate={bulkUpdateDocuments}
        openAttachmentDocument={openInvestmentsDocumentsModal}
        behaviorUpdateButton="secondary"
      />
    </div>
  );
}

DocumentsInvestmentView.propTypes = {
  documents: documentsPropType.isRequired,
  bulkUpdateDocuments: PropTypes.func,
  openInvestmentsDocumentsModal: PropTypes.func,
};

export default DocumentsInvestmentView;
