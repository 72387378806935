import React from "react";
import Skeleton, {
  SkeletonLine,
  SkeletonParagraph,
} from "components_new/atoms/Skeleton";
import { times } from "lodash";
import "./TaskViewSkeleton.scss";

export function TaskViewListSkeleton() {
  return (
    <div className="TaskList">
      {times(5, (index) => (
        <div className="Skeleton-Item" key={index}>
          <Skeleton>
            <SkeletonParagraph>
              <SkeletonLine length="full" />
              <SkeletonLine length="medium" />
            </SkeletonParagraph>
          </Skeleton>
        </div>
      ))}
    </div>
  );
}

export function TaskViewContentSkeleton() {
  return (
    <div className="TaskView">
      <div className="TaskView-Beta">
        <div className="QueuesView-Education">
          <div className="Skeleton-Wrapper">
            <div className="Skeleton-Item Header">
              <Skeleton>
                <SkeletonParagraph>
                  <SkeletonLine length="full" />
                  <SkeletonLine length="full" />
                  <SkeletonLine length="full" />
                </SkeletonParagraph>
              </Skeleton>
            </div>

            {times(3, (index) => (
              <div className="Skeleton-Item" key={index}>
                <Skeleton>
                  <SkeletonParagraph>
                    <SkeletonLine length="full" />
                    <SkeletonLine length="medium" />
                  </SkeletonParagraph>
                </Skeleton>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
