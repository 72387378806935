import React from "react";
import formatDate from "lib/formatDate";
import ThreePaneLayout from "components_new/layouts/ThreePaneLayout";
import Text from "components_new/atoms/Text";
import CircularIconButton from "components_new/elements/CircularIconButton";
import SectionList from "components_new/elements/SectionList";
import SnapshotItem from "components_new/views/Snapshots/shared/SnapshotItem";
import Sidebar from "./Sidebar";
import { isEmpty } from "lodash";
import "./Snapshot.scss";

function SnapshotDetails({ snapshot, handleEditSnapshot }) {
  const sections = snapshot.data;
  return (
    <div className="Pane-Wrapper">
      <div className="SnapshotDetails">
        <div className="Pane-Header">
          <Text color="brand-light">
            Created by {snapshot.creator.full_name} on{" "}
            {formatDate(snapshot.created_at, "detailed")}
          </Text>
          <div className="Panel-Actions">
            <CircularIconButton
              icon="ri-pencil-line"
              onClick={() => handleEditSnapshot(snapshot.id)}
            />
          </div>
        </div>
        <div className="Pane-Body">
          <div className="SnapshotDetails-Header">
            <div className="SnapshotDetails-Header-Title">
              <Text type="title-2">{snapshot.name}</Text>
              {!isEmpty(snapshot.company) && (
                <Text
                  type="subtitle-2-bold"
                  extraClassnames="SnapshotDetails-Header-Title-Company"
                >
                  {snapshot.company.name}
                </Text>
              )}
            </div>
            {snapshot.description && <Text>{snapshot.description}</Text>}
          </div>
          <SectionList
            accordion
            extraClassnames="SectionList-DealStages"
            sections={sections}
            renderSectionHeader={(title, section) => (
              <div className="SectionList-DealStages-StageHeader">
                <Text type="subtitle-1-bold" as="h2">
                  {title}{" "}
                  <Text type="subtitle-1-regular" color="brand-light" as="span">
                    ({section?.data?.length})
                  </Text>
                </Text>
              </div>
            )}
            renderItem={(
              { avatar, title, description, id, icons, class: type, meta },
              index,
            ) => (
              <div
                className="SectionList-DealStages-Deal"
                key={`${id}-${index}`}
              >
                <SnapshotItem
                  type={type}
                  avatar={avatar}
                  title={title}
                  description={description}
                  icons={icons}
                  meta={meta}
                />
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
}

function Snapshot({ snapshot, handleEditSnapshot }) {
  return (
    <div className="SnapshotView">
      <ThreePaneLayout
        children={
          <SnapshotDetails
            snapshot={snapshot}
            handleEditSnapshot={handleEditSnapshot}
          />
        }
        sidebar={<Sidebar snapshot={snapshot} />}
      />
    </div>
  );
}

export default Snapshot;
