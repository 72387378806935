import React from "react";
import formatPrice from "lib/formatters/formatPrice";
import formatNumber from "lib/formatters/formatNumber";
import formatPercentage from "lib/formatPercentage";
import accounting from "accounting";
import moment from "moment";
import { find, capitalize } from "lodash";
import {
  noteMaturityDateTermOptions,
  interestAccrualScheduleOptions,
} from "components/investments/InvestmentDetails/config";
import isValidNumberInput from "lib/formatters/isValidNumberInput";
import InvestmentItemContentLong from "components/investments/InvestmentDetails/InvestmentItemContentLong";
import { VESTING_CLIFF } from "components/forms/investments/AdvancedVestingField";
import formatDate from "lib/formatDate";
import { getText } from "TermsMapper";
import formatContactName from "lib/formatters/formatContactName";

function renderDefaultContent(investment, detailName) {
  return investment[detailName];
}

function renderLongContent(investment, detailName) {
  return <InvestmentItemContentLong value={investment[detailName]} />;
}

function renderPriceContent(investment, detailName) {
  return isValidNumberInput(investment[detailName])
    ? formatPrice(investment[detailName])
    : "";
}

function renderNumberContent(investment, detailName) {
  return isValidNumberInput(investment[detailName])
    ? formatNumber(investment[detailName])
    : "";
}

function renderPercentageContent(investment, detailName) {
  return formatPercentage(investment[detailName]);
}

function renderDateContent(investment, detailName) {
  return moment(investment[detailName]).format("ll");
}

function renderBooleanContent(investment, detailName) {
  return <div className="u-capitalize">{investment[detailName]}</div>;
}

function renderVestingCliffSelection(investment) {
  const selection = investment.vesting_cliff_selection;
  if (selection === "other") {
    return formatDate(investment.vesting_cliff_other);
  }

  return find(VESTING_CLIFF, ["value", selection]).label;
}

function renderContact(contact) {
  return formatContactName(contact);
}

function investmentDetailLabelsAndContent() {
  return {
    distribution_schedule_or_allocation_income: {
      label: "Distribution schedule / Allocation of incomes",
      content: renderLongContent,
    },
    vesting_schedule: {
      label: "Vesting schedule",
      content: (investment, detailName) =>
        capitalize(renderDefaultContent(investment, detailName)),
    },
    vesting_cliff_selection: {
      label: "Vesting cliff",
      content: renderVestingCliffSelection,
    },
    shares_in_series: {
      label: "Number of Shares in Series",
      content: (investment, detailName) =>
        renderNumberContent(investment, detailName),
    },
    certificate_number: {
      label: "Certificate Number",
      content: (investment, detailName) =>
        renderDefaultContent(investment, detailName),
    },
    stock_certificate_number: {
      label: "Stock Certificate Number",
      content: (investment, detailName) =>
        renderDefaultContent(investment, detailName),
    },
    equity_class: {
      label: "Type of Stock",
      content: (investment, detailName) =>
        renderDefaultContent(investment, detailName),
    },
    note_maturity_date: {
      label: "Terms",
      content: (investment, detailName) =>
        renderDateContent(investment, detailName),
    },
    note_maturity_date_term: {
      label: "Terms",
      content: (investment, detailName) =>
        noteMaturityDateTermOptions[
          renderDefaultContent(investment, detailName)
        ],
    },
    interest_rate: {
      label: "Interest Rate",
      content: (investment, detailName) =>
        renderPercentageContent(investment, detailName),
    },
    interest_schedule: {
      label: "Interest Schedule",
      content: (investment, detailName) =>
        capitalize(renderDefaultContent(investment, detailName)),
    },
    interest_accrual_schedule: {
      label: "Interest Accrual Schedule",
      content: (investment, detailName) =>
        interestAccrualScheduleOptions[
          renderDefaultContent(investment, detailName)
        ],
    },
    qualified_financing_amount: {
      label: "Qualified Financing Amount",
      content: (investment, detailName) =>
        renderPriceContent(investment, detailName),
    },
    conversion_discount: {
      label: "Conversion Discount",
      content: (investment, detailName) =>
        renderPercentageContent(investment, detailName),
    },
    conversion_terms: {
      label: "Conversion Terms",
      content: (investment, detailName) =>
        renderLongContent(investment, detailName),
    },
    vesting: {
      label: "Vesting terms",
      content: (investment, detailName) =>
        renderLongContent(investment, detailName),
    },
    contacts: {
      label: "Referrers",
      content: (contact) => renderContact(contact),
    },
    conversion_cap: {
      label: "Conversion Cap",
      content: (investment, detailName) =>
        renderPriceContent(investment, detailName),
    },
    total_acquisition_amount: {
      label: (investment) =>
        getText(investment.type, "total_acquisition_amount"),
      content: (investment, detailName) =>
        renderPriceContent(investment, detailName),
    },
    capital_called: {
      label: "Paid-in Capital",
      content: (investment, detailName) => {
        const { percent_called: percentCalled } = investment;
        return `${
          isValidNumberInput(investment[detailName])
            ? formatPrice(investment[detailName])
            : ""
        } (${accounting.toFixed(percentCalled, 0)}% of commitment)`;
      },
    },
    fund_size: {
      label: "Fund size",
      content: (investment, detailName) =>
        renderPriceContent(investment, detailName),
    },
    fund_vintage: {
      label: "Fund vintage",
      content: (investment, detailName) =>
        renderDefaultContent(investment, detailName),
    },
    membership_units_or_percentage: {
      label: "Membership Units or Percentage",
      content: (investment, detailName) =>
        capitalize(renderDefaultContent(investment, detailName)),
    },
    number_of_units: {
      label: "Number of Units",
      content: (investment, detailName) =>
        renderNumberContent(investment, detailName),
    },
    number_of_units_in_class: {
      label: "Number of unit in class",
      content: (investment, detailName) =>
        renderNumberContent(investment, detailName),
    },
    stock_class: {
      label: "Type of Stock",
      content: (investment, detailName) =>
        renderDefaultContent(investment, detailName),
    },
    option_exercise_price: {
      label: "Exercise Price",
      content: (investment, detailName) =>
        renderPriceContent(investment, detailName),
    },
    option_exercise_date: {
      label: "Vesting Start Date",
      content: (investment, detailName) =>
        renderDateContent(investment, detailName),
    },
    option_expiration_date: {
      label: "Expiration Date",
      content: (investment, detailName) =>
        renderDateContent(investment, detailName),
    },
    warrant_exercise_price: {
      label: "Exercise Price",
      content: (investment, detailName) =>
        renderPriceContent(investment, detailName),
    },
    warrant_expiration_date: {
      label: "Expiration Date",
      content: (investment, detailName) =>
        renderDateContent(investment, detailName),
    },
    pre_money_company_valuation: {
      label: "Pre-money company valuation",
      content: (investment, detailName) =>
        renderPriceContent(investment, detailName),
    },
    amount_raised_in_round: {
      label: "Amount raised in round",
      content: (investment, detailName) =>
        renderPriceContent(investment, detailName),
    },
    conversion_ratio: {
      label: "Conversion ratio",
      content: (investment, detailName) =>
        renderNumberContent(investment, detailName),
    },
    conversion_price: {
      label: "Conversion price",
      content: (investment, detailName) =>
        renderPriceContent(investment, detailName),
    },
    qualified_small_business_stock: {
      label: "Qualified Small Business Stock",
      content: (investment, detailName) =>
        renderBooleanContent(investment, detailName),
    },
  };
}

export default investmentDetailLabelsAndContent();
