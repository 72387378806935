import React, { useEffect, useState, Fragment } from "react";
import { isEmpty } from "lodash";
import FormGroup from "components_new/elements/FormGroup";
import FileRow from "components_new/elements/FileRow";
import DropZone from "components_new/elements/DropZone";
import Table, {
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableBody,
  TableCell,
} from "components_new/elements/Table";
import PropTypes from "prop-types";
import classnames from "classnames";
import { documentPropType } from "PropTypes";
import "./ActiveDealFiles.scss";

function showtitle(count) {
  return (
    <Fragment>
      Files <small>({count})</small>
    </Fragment>
  );
}

function ActiveDealFiles({
  documents,
  dealId,
  pipelineId,
  saveDealDocuments,
  updateFile,
  removeFile,
  currentOrganizationUsername,
  componentSize,
  isInView = false,
}) {
  const [modifiedFileId, setModifiedFileId] = useState(null);
  const [currentAction, setCurrentAction] = useState(null);

  const setEditFile = (fileId) => {
    setCurrentAction("edit");
    setModifiedFileId(fileId);
  };

  const setDeleteFile = (fileId) => {
    setCurrentAction("delete");
    setModifiedFileId(fileId);
  };

  const resetFileActions = (fileId) => {
    if (fileId === modifiedFileId) {
      setModifiedFileId(null);
      setCurrentAction(null);
    }
  };

  const [documentsData, setDocumentsData] = useState([]);

  function uploadDocuments(documentsData) {
    documentsData.map((document) => {
      saveDealDocuments({ pipelineId, dealId, document });
    });
  }

  useEffect(() => {
    if (documentsData.length > 0) {
      uploadDocuments(documentsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentsData]);

  const activeDealFilesClassnames = classnames("ActiveDealFiles", {
    isInView: isInView,
  });

  return (
    <FormGroup
      title={showtitle(documents?.length || 0)}
      isExpanded={true}
      extraClassnames={activeDealFilesClassnames}
    >
      {!isEmpty(documents) && (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>Name</TableHeaderCell>
              {componentSize === "mini" ? (
                <TableHeaderCell>Size</TableHeaderCell>
              ) : (
                <Fragment>
                  <TableHeaderCell>Type</TableHeaderCell>
                  <TableHeaderCell>Upload date</TableHeaderCell>
                </Fragment>
              )}
            </TableRow>
          </TableHeader>
          <TableBody>
            {documents.map((document) => {
              return (
                <TableRow key={document.id}>
                  <TableCell colSpan="3">
                    <FileRow
                      key={document.id}
                      file={document}
                      setEditFile={setEditFile}
                      setDeleteFile={setDeleteFile}
                      resetFileActions={resetFileActions}
                      currentAction={currentAction}
                      editAction={updateFile}
                      deleteAction={removeFile}
                      modifiedFileId={modifiedFileId}
                      currentOrganizationUsername={currentOrganizationUsername}
                      dropdownAlignment="left"
                      componentSize={componentSize}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
      <DropZone
        onChange={(values) => {
          setDocumentsData(values);
        }}
        noQueue={true}
        canMarkAsClosing={false}
        canChooseFileType={false}
      />
    </FormGroup>
  );
}

ActiveDealFiles.propTypes = {
  documents: PropTypes.arrayOf(documentPropType).isRequired,
  currentOrganizationUsername: PropTypes.string.isRequired,
  dealId: PropTypes.string,
  pipelineId: PropTypes.string,
  saveDealDocuments: PropTypes.func,
  updateFile: PropTypes.func,
  removeFile: PropTypes.func,
  componentSize: PropTypes.string,
  isInView: PropTypes.bool,
};

export default ActiveDealFiles;
