import { map, toUpper } from "lodash";
import parseDataToChartPoints from "./parseDataToChartPoints";
import numeral from "numeral";

export function getDataForGraph(data, type) {
  return map(data, type);
}

export function parseData(data, mapper) {
  return parseDataToChartPoints(data, mapper);
}

export function formatMoneyLabel(value) {
  return toUpper(numeral(value).format("$0[.]00a"));
}

export const globalChartConfig = {
  chart: {
    marginTop: 79,
    zoomType: "x",
    backgroundColor: null,
    style: {
      zIndex: 1,
    },
  },
  credits: {
    enabled: false,
  },
  lang: {
    noData: "Not enough data",
  },
  legend: {
    align: "top",
    verticalAlign: "top",
    layout: "horizontal",
    y: 0,
    x: -10,
    itemStyle: {
      color: "#656976",
      fontSize: "12px",
      fontFamily: "Lato-Bold",
      letterSpacing: "0.03em",
      textTransform: "uppercase",
      cursor: "default",
    },
    itemHoverStyle: {
      color: "#656976",
    },
    lineHeight: 20,
    symbolPadding: 8,
    symbolRadius: 2, // Increase this number to get more circle shape.
    symbolHeight: 15,
    reversed: true,
  },
  noData: {
    style: {
      fontFamily: "Lato-Regular",
      fontSize: "14px",
      fontWeight: "normal",
      lineHeight: "16px",
      color: "#656976",
    },
    position: {
      align: "center",
      verticalAlign: "center",
      y: 100,
    },
  },
  title: {
    text: null,
  },
};

const labelYAxisStyle = {
  color: "#656976",
  fontSize: "12px",
  fontFamily: "Lato-Bold",
  letterSpacing: "0.03em",
  lineHeight: "20px",
};

export const greenAreaSeries = {
  index: 0,
  legendIndex: 1,
  type: "area",
  borderWidth: 0,
  color: "#98C069",
};

export const yAxisChart = {
  lineColor: "rgba(128,132,148,0.25)",
  lineWidth: 1,
  tickWidth: 0,
  softMax: 1,
  title: {
    enabled: false,
  },
  plotLines: [
    {
      color: "rgba(183, 186, 195, 0.8)",
      width: 1,
      value: 0,
    },
  ],
};

export const labelsYAxis = {
  style: {
    ...labelYAxisStyle,
    paddingRight: "6px",
    textOverflow: "ellipsis",
  },
  overflow: "justify",
};

export const xAxisChart = {
  lineColor: "rgba(128,132,148,0.25)",
  lineWidth: 1,
  tickWidth: 0,
  gridLineWidth: 1,
  maxPadding: 0,
  minPadding: 0,
};

export const tooltipChart = {
  backgroundColor: "#000000",
  borderRadius: 6,
  borderWidth: 0,
  padding: 0,
  shadow: false,
  useHTML: true,
  headerFormat: "",
};

export const labelsXAxis = {
  useHTML: true,
  lineWidth: 0,
  style: {
    ...labelYAxisStyle,
    paddingTop: "6px",
  },
};

export const plotOptionsChart = {
  area: {
    lineWidth: 0,
    fillOpacity: 1,
    marker: {
      enabled: true,
      lineColor: "#fff",
      lineWidth: 2,
      radius: 3.5,
      symbol: "circle",
      width: 9,
      height: 9,
      states: {
        hover: {
          enabled: true,
        },
      },
    },
    events: {
      legendItemClick() {
        return false;
      },
    },
  },
  line: {
    events: {
      legendItemClick() {
        return false;
      },
    },
  },
  spline: {
    events: {
      legendItemClick() {
        return false;
      },
    },
  },
  series: {
    pointPlacement: "on",
    events: {
      legendItemClick() {
        return false;
      },
    },
  },
};

export const lineChartConfig = {
  borderWidth: 2,
  color: "#B7BAC3",
  marker: {
    enabled: false,
  },
  tooltip: {
    pointFormatter: function () {
      return;
      // Returning nothing means no tooltip on each point
    },
  },
  states: {
    hover: {
      enabled: false,
    },
  },
  stickyTracking: false,
  index: 1,
  legendIndex: 0,
};

export const splineChartConfig = {
  borderWidth: 3,
  color: "#B7BAC3",
  marker: {
    enabled: false,
  },
  tooltip: {
    pointFormatter: function () {
      return;
      // Returning nothing means no tooltip on each point
    },
  },
  states: {
    hover: {
      enabled: false,
    },
  },
  stickyTracking: false,
  index: 1,
  legendIndex: 0,
};

export const yAxisPlotlineLineChart = {
  color: "#B7BAC3",
  width: 3,
  zIndex: 4,
};

export const yAxisLineChart = {
  lineColor: "rgba(128,132,148,0.25)",
  lineWidth: 1,
  tickWidth: 0,
  softMax: 1,
  title: {
    enabled: false,
  },
};
