import React, { useState, useMemo } from "react";
import RequiredNote from "components_new/SidebarComponents/shared/RequiredNote";
import Form from "components_new/forms/Form";
import Button from "components_new/atoms/Button";
import FormGroup from "components_new/elements/FormGroup";
import Input from "components_new/atoms/Input";
import TextArea from "components_new/atoms/TextArea";
import { SearchAndSelectField as SearchAndSelect } from "components_new/elements/SearchAndSelect";
import DropdownForm from "components_new/atoms/DropdownForm";
import DatePicker from "components_new/atoms/DatePicker";
import formatDate from "lib/formatDate";
import { valueDateFormat } from "config/dateInput";
import useForm from "lib/hooks/useForm";
import { merge } from "lodash";
import "./TaskForm.scss";

const INITIAL_VALUES = {
  formData: {
    creator_id: null,
    subject: "",
    body: "",
    selection: {},
    owner_id: null,
    secondary_owner_id: null,
    due_date: null,
    remind_at: null,
    snoozed_until: null,
  },
};

function addTaskToForm(
  task,
  currentUserID,
  prefilledSelection,
  prefilledOwner,
) {
  return task
    ? {
        formData: {
          ...task,
          creator_id: currentUserID,
          selection: task.taskable_id && {
            id: task.taskable_id,
            type: task.taskable_type.toLowerCase(),
            title: task.taskable_name,
          },
        },
      }
    : {
        formData: {
          creator_id: currentUserID,
          selection: prefilledSelection && {
            id: prefilledSelection.id,
            type: prefilledSelection.type.toLowerCase(),
            title: prefilledSelection.name,
          },
          owner_id: prefilledOwner,
        },
      };
}

function TaskForm({
  currentUserID,
  task = null,
  closeSidebar,
  submitAction,
  organizationMemberships,
  showConfirmationMessage,
  openRemoveTaskModal,
  prefilledSelection = null,
  prefilledOwner = null,
  isEditing,
  toogleSize,
}) {
  const initialValues = useMemo(() => {
    return merge(
      {},
      INITIAL_VALUES,
      addTaskToForm(task, currentUserID, prefilledSelection, prefilledOwner),
    );
  }, [task, currentUserID, prefilledSelection, prefilledOwner]);

  const [isLoading, setIsLoading] = useState(false);
  const submitTextButton = isEditing ? "Save Changes" : "Save Task";
  const headerFormText = isEditing ? "Update Task" : "Add new Task";

  const { formData, handleChange, handleErrors, errors } = useForm(
    initialValues,
  );

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await submitAction(formData);
      closeSidebar();
      showConfirmationMessage();
    } catch (error) {
      handleErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  const isAvailableToSubmit =
    formData.owner_id !== null &&
    formData.subject !== "" &&
    formData.owner_id !== "";

  return (
    <Form onSubmit={handleSubmit} extraClassName="TaskForm">
      <Form.Header onClose={closeSidebar} onToogle={toogleSize}>
        {headerFormText}
      </Form.Header>
      <Form.Content>
        {isEditing && (
          <Button
            onClick={() => openRemoveTaskModal({ taskId: formData.id })}
            primary
            inverted
            type="button"
            size="tiny"
          >
            Delete Task
          </Button>
        )}

        <FormGroup
          extraClassnames="TaskDetailGroup"
          title="Task Details*"
          subtitle="Information about the new task"
          isExpanded
          isRequired
        >
          <Input
            name="subject"
            label="Title"
            value={formData.subject}
            onChange={(_, { value }) => {
              handleChange("subject", value);
            }}
            isRequired
            error={!!errors.for("subject")}
            errorMessage={errors.for("subject")}
            size="tiny"
          />
          <TextArea
            name="body"
            label="Description"
            defaultValue={formData.body}
            placeholder="Add a description"
            onChange={(_, { value }) => {
              handleChange("body", value);
            }}
            error={!!errors.for("body")}
            errorMessage={errors.for("body")}
            size="tiny"
          />
          <SearchAndSelect
            name="selection"
            label="Company, Investment, or Deal"
            placeHolder="Select a company, investment, or deal"
            onChange={(value) => handleChange("selection", value)}
            scope="all"
            size="tiny"
            initialSelection={formData.selection}
          />
          <DropdownForm
            label="Primary Owner*"
            placeholder="Select an owner"
            items={organizationMemberships}
            onChange={(_, { value }) => handleChange("owner_id", value)}
            value={formData.owner_id}
            errorMessage={errors.for("owner_id")}
            size="tiny"
          />
          <DropdownForm
            label="Secondary Owner"
            placeholder="Select an owner"
            items={organizationMemberships}
            onChange={(_, { value }) =>
              handleChange("secondary_owner_id", value)
            }
            value={formData.secondary_owner_id}
            size="tiny"
          />
          <DatePicker
            name="due_date"
            extraClassnames="FormElement FormElement--Date"
            label="Due Date"
            placeholder="MM/DD/YYYY"
            value={formatDate(formData.due_date)}
            error={!!errors.for("due_date")}
            errorMessage={errors.for("due_date")}
            onChange={(value) =>
              handleChange("due_date", formatDate(value, valueDateFormat))
            }
            size="tiny"
          />
          <DatePicker
            name="remind_at"
            extraClassnames="FormElement FormElement--Date"
            label="Reminder"
            placeholder="MM/DD/YYYY"
            value={formatDate(formData.remind_at)}
            error={!!errors.for("remind_at")}
            errorMessage={errors.for("remind_at")}
            onChange={(value) =>
              handleChange("remind_at", formatDate(value, valueDateFormat))
            }
            size="tiny"
          />
          <DatePicker
            name="snoozed_until"
            extraClassnames="FormElement FormElement--Date"
            label="Snoozed Until"
            placeholder="MM/DD/YYYY"
            value={formatDate(formData.snoozed_until)}
            error={!!errors.for("snoozed_until")}
            errorMessage={errors.for("snoozed_until")}
            onChange={(value) =>
              handleChange("snoozed_until", formatDate(value, valueDateFormat))
            }
            size="tiny"
          />
        </FormGroup>
        <RequiredNote />
      </Form.Content>
      <Form.Footer>
        <Button
          disabled={!isAvailableToSubmit}
          primary
          loading={isLoading}
          type="submit"
          size="small"
        >
          {submitTextButton}
        </Button>
        <Button
          onClick={closeSidebar}
          disabled={isLoading}
          secondary
          type="button"
          size="small"
        >
          Cancel
        </Button>
      </Form.Footer>
    </Form>
  );
}

export default TaskForm;
