import { connect } from "react-redux";
import LogAcquisitionForm from "./LogAcquisitionForm";
import { getCollection } from "store/domain/selectors";

function mapStateToProps(
  state,
  { historyAction, companies, actionType, updateForm, errors },
) {
  const allCompanies = getCollection(state, "companies");
  return {
    historyAction,
    companies,
    actionType,
    updateForm,
    allCompanies,
    errors,
  };
}

export default connect(mapStateToProps, null)(LogAcquisitionForm);
