import Api from "lib/Api";
import { buildPostResourceRoute } from "lib/globalActionsHelper";
import { updateView, removeFromView } from "store/views";
import { receiveCollection } from "store/entities";

export function createPostOnCompany(resource, formData) {
  return (dispatch) => {
    const postResourceRoute = buildPostResourceRoute(resource);
    return Api.post(`${postResourceRoute}/posts`, {
      post: formData,
    }).then(({ data }) => {
      dispatch(updateView("company", "company_posts_ids", data.id));
      dispatch(receiveCollection("companyPosts", [data]));
    });
  };
}

export function updatePostOnCompany(resource, postId, formData) {
  return (dispatch) => {
    const postResourceRoute = buildPostResourceRoute(resource);
    return Api.patch(`${postResourceRoute}/posts/${postId}`, {
      post: formData,
    }).then(({ data }) => {
      dispatch(receiveCollection("companyPosts", [data]));
    });
  };
}

export function removePostOnCompany(resource, postId) {
  const postResourceRoute = buildPostResourceRoute(resource);

  return (dispatch, getState) => {
    const {
      views: { company: view },
    } = getState();
    const currentPosts = view.company_posts_ids;
    return Api.delete(`${postResourceRoute}/posts/${postId}`).then(() => {
      if (currentPosts.includes(postId)) {
        dispatch(removeFromView("company", "company_posts_ids", postId));
      }
    });
  };
}
