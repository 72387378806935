import React, { useContext, useEffect } from "react";
import StockSplitForm from "./StockSplitForm";
import useInvestmentStockSplit from "store/investments/v2/logStockSplit/hooks";
import { SidebarComponentsContext } from "components_new/SidebarComponents/SidebarComponentsWrapper";
import { useInvestment } from "components_new/SidebarComponents/views/InvestmentView/hooks/useInvestment";
import { isEmpty } from "lodash";
import Loader from "components/shared/Loader";

function StockSplitFormWrapper({ ownName }) {
  const { getComponentState, registerForm, closeSidebar } = useContext(
    SidebarComponentsContext,
  );

  const { investment } = getComponentState(ownName);

  useEffect(() => {
    if (!isEmpty(ownName)) {
      registerForm(ownName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { createStockSplit } = useInvestmentStockSplit();
  const { data, isLoading } = useInvestment(investment?.id);

  if (isLoading || isEmpty(investment)) {
    return <Loader />;
  }

  return (
    <StockSplitForm
      ownName={ownName}
      company={data?.company}
      investment={data?.details}
      createStockSplit={createStockSplit}
      closeSidebar={closeSidebar}
    />
  );
}

export default StockSplitFormWrapper;
