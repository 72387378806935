import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { isEmpty, forEach, cloneDeep } from "lodash";
import SecondaryButton from "components/shared/SecondaryButton";
import PrimaryButton from "components/shared/PrimaryButton";
import Loader from "components/shared/Loader";
import TableHeader from "components/shared/Table/TableHeader";
import TableRow from "components/shared/Table/TableRow";

const initialState = {
  isAvailableToEdit: false,
  isShowingLessRows: false,
  isSubmitting: false,
  isUpdated: false,
  isCanceled: false,
  updateData: {},
  removeData: {},
  removeIds: [],
  errorsResults: [],
  collection: [],
};

function TableActions({
  isAvailableToEdit,
  sendForm,
  onDone,
  cancelForm,
  startEditing,
}) {
  let primaryAction = onDone;
  let secondaryAction = startEditing;
  let primaryButtonText = "Done";
  let secondaryButtonText = "Edit";

  if (isAvailableToEdit) {
    primaryAction = sendForm;
    secondaryAction = cancelForm;
    primaryButtonText = "Save Changes";
    secondaryButtonText = "Cancel";
  }

  return (
    <div className="Table-actionsOnModal">
      <PrimaryButton onClick={primaryAction}>{primaryButtonText}</PrimaryButton>
      <SecondaryButton onClick={secondaryAction}>
        {secondaryButtonText}
      </SecondaryButton>
    </div>
  );
}

function TableLoader() {
  return (
    <div className="TableLoader">
      <Loader />
    </div>
  );
}

function showEmptyItems(collection, emptyItem) {
  return !collection.length && emptyItem;
}

class DocumentViewerTable extends Component {
  state = initialState;

  static propTypes = {
    isFetching: PropTypes.bool,
    isCreating: PropTypes.bool,
    isUpdating: PropTypes.bool,
    columns: PropTypes.arrayOf(PropTypes.shape({})),
    emptyItem: PropTypes.shape({}),
    collection: PropTypes.arrayOf(PropTypes.shape({})),
    bulkUpdate: PropTypes.func,
    onDone: PropTypes.func,
    deleteTitle: PropTypes.string,
    deleteButtonText: PropTypes.string,
    renderRow: PropTypes.func.isRequired,
    rowInitialState: PropTypes.shape({}),
    viewMoreTooltipContent: PropTypes.string,
  };

  static defaultProps = {
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    columns: null,
    deleteTitle: "Delete this document?",
    deleteButtonText: "Delete Row",
    viewMoreTooltipContent: "",
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.fillStateCollection(nextProps.collection);
  }

  componentDidMount() {
    this.fillStateCollection(this.props.collection);
  }

  fillStateCollection(collection) {
    this.setState({ collection: cloneDeep(collection) });
  }

  startEditing = () => {
    this.setState({ isAvailableToEdit: true });
  };

  resetForm = () => {
    this.setState({
      ...initialState,
      collection: cloneDeep(this.props.collection),
    });
  };

  cancelForm = () => {
    this.setState({ isCanceled: true }, this.resetForm);
  };

  updateForm = (name, value, index, id) => {
    this.setState((prevState) => {
      let nextAttributeObj = {};
      if (prevState.updateData.hasOwnProperty(index)) {
        nextAttributeObj = {
          ...prevState.updateData[index].attributes,
          [name]: value,
        };
      } else {
        nextAttributeObj = { [name]: value };
      }
      prevState.collection[index - 1][name] = value;
      return {
        updateData: {
          ...prevState.updateData,
          [index]: {
            id: id,
            attributes: nextAttributeObj,
          },
        },
      };
    });
  };

  removeRow = (index, id) => {
    this.setState((prevState) => {
      return {
        removeIds: [...prevState.removeIds, id],
        removeData: {
          ...prevState.removeData,
          [index]: { id: id },
        },
      };
    });
  };

  createPayload() {
    const { updateData, removeData, removeIds } = this.state;
    let payload = [];
    if (!isEmpty(updateData)) {
      forEach(updateData, (value, key) => {
        if (!removeIds.includes(value.id)) {
          payload.push({
            index: key,
            type: "update",
            id: value.id,
            attributes: value.attributes,
          });
        }
      });
    }
    if (!isEmpty(removeData)) {
      forEach(removeData, (value, key) => {
        payload.push({ index: key, type: "destroy", id: value.id });
      });
    }

    return payload;
  }

  sendForm = () => {
    const payload = this.createPayload();
    this.setState({ isSubmitting: true });
    this.props.bulkUpdate(payload).then((data) => {
      if (data.success) {
        this.setState({ isUpdated: true }, this.resetForm);
      } else {
        this.setState({ isSubmitting: false, errorsResults: data.results });
      }
    });
  };

  render() {
    const {
      columns,
      isFetching,
      isCreating,
      isUpdating,
      renderRow,
      deleteTitle,
      deleteButtonText,
      onDone,
      emptyItem,
    } = this.props;

    if (isFetching || isCreating || isUpdating) {
      return <TableLoader />;
    }

    const {
      isAvailableToEdit,
      removeIds,
      isUpdated,
      collection,
      errorsResults,
      isSubmitting,
      isCanceled,
    } = this.state;

    const tableClassNames = classnames("Table", {
      isEditable: isAvailableToEdit,
      isSubmitting: isSubmitting,
      isShowingEmptyItems: showEmptyItems(collection, emptyItem),
    });

    const actualcollection = showEmptyItems(collection, emptyItem)
      ? [emptyItem]
      : collection;

    return (
      <div className="TableWrapper">
        <div className={tableClassNames}>
          <TableLoader />
          <TableHeader columns={columns} />
          <div className="Table--body">
            {!isUpdated &&
              !isCanceled &&
              actualcollection.map((data, index) => {
                return (
                  <TableRow
                    index={index + 1}
                    data={data}
                    renderRow={renderRow}
                    key={index}
                    updateForm={this.updateForm}
                    removeRow={this.removeRow}
                    columns={columns}
                    removeIds={removeIds}
                    errorsResults={errorsResults}
                    deleteTitle={deleteTitle}
                    deleteButtonText={deleteButtonText}
                  />
                );
              })}
          </div>
          <TableActions
            isAvailableToEdit={isAvailableToEdit}
            onDone={onDone}
            sendForm={this.sendForm}
            cancelForm={this.cancelForm}
            startEditing={this.startEditing}
          />
        </div>
      </div>
    );
  }
}

export default DocumentViewerTable;
