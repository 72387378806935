import React, { useState, Fragment } from "react";
import { Outlet } from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";
import LeftAlignedDetailPageLayout from "components_new/layouts/LeftAlignedDetailPageLayout";
import CompanyCallToActionSidebar from "components_new/views/Company/shared/CompanyCallToActionSidebar";
import CompanyLeftSidebar from "components_new/views/Company/shared/CompanyLeftSidebar";
import ContentNavigation from "components_new/views/Company/ContentNavigation";
import classNames from "classnames";
import "./CompanyView.scss";

//A small visibility sensor for detecting if a scrollable panel
// has been scrolled more than 50 pixels.
const PanelVisibilitySensor = ({ onChange }) => {
  return (
    <div style={{ position: "absolute", top: 30, left: 0 }}>
      <VisibilitySensor onChange={onChange}>
        <div style={{ height: 1, width: 1 }} />
      </VisibilitySensor>
    </div>
  );
};

function CompanyView(props) {
  const {
    companyDetails,
    companyAvatars,
    companyHoldingTotals,
    investments,
    params,
    investmentsCount,
    contactsCount,
    notesCount,
    documentsCount,
    dealsCount,
    investorCoInvestments,
    location,
    onEditClick,
    investorFirm,
  } = props;
  const companyName = companyDetails.name;
  const companyId = companyDetails.id;
  const [leftScroll, setLeftScroll] = useState(false);
  const [rightScroll, setRightScroll] = useState(false);

  function isInCurrentView(view) {
    return location.pathname.includes(view);
  }

  const companyViewClassNames = classNames("CompanyView", {
    ActiveTabOverview: isInCurrentView("overview"),
    ActiveTabInvestments: isInCurrentView("investments"),
    ActiveTabDeals: isInCurrentView("deals"),
    ActiveTabContacts: isInCurrentView("contacts"),
    ActiveTabNotes: isInCurrentView("notes"),
    ActiveTabFiles: isInCurrentView("files"),
  });

  return (
    <div className={companyViewClassNames}>
      <LeftAlignedDetailPageLayout
        children={
          <Fragment>
            <PanelVisibilitySensor onChange={(v) => setRightScroll(!v)} />
            <Outlet />
          </Fragment>
        }
        leftSidebar={
          <Fragment>
            <PanelVisibilitySensor onChange={(v) => setLeftScroll(!v)} />
            <CompanyLeftSidebar
              companyDetails={companyDetails}
              companyAvatars={companyAvatars}
              companyHoldingTotals={companyHoldingTotals}
              investments={investments}
              displayFixedContent={leftScroll}
              onEditClick={() => onEditClick(companyId, companyName)}
            />
          </Fragment>
        }
        navigation={
          <ContentNavigation
            investorFirm={investorFirm}
            routeParams={params}
            location={location}
            investmentsCount={investmentsCount}
            documentsCount={documentsCount}
            notesCount={notesCount}
            dealsCount={dealsCount}
            contactsCount={contactsCount}
            investorCoInvestments={investorCoInvestments}
            displayFixedContent={rightScroll}
          />
        }
        rightSidebar={
          <CompanyCallToActionSidebar companyDetails={companyDetails} />
        }
      />
    </div>
  );
}

export default CompanyView;
