import { useSelector } from "react-redux";
import { getResource } from "store/resourcesV2";

// TODO: Make it a global hook
function useCurrentOrganization() {
  return useSelector((state) => {
    return getResource(
      state,
      "organizations",
      state.organizations.currentOrganizationId,
    );
  });
}

export default useCurrentOrganization;
