import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import PrimaryButton from "components/shared/PrimaryButton";

class PortalSaveButton extends Component {
  constructor(props) {
    super(props);
    this.el = document.createElement("div");
  }

  componentDidMount() {
    const { portalSelector } = this.props;
    document.querySelector(portalSelector).append(this.el);
  }

  componentWillUnmount() {
    const { portalSelector } = this.props;
    document.querySelector(portalSelector).removeChild(this.el);
  }

  render() {
    const { onSave, size, isSubmitting, extraClassnames } = this.props;
    return ReactDOM.createPortal(
      <Fragment>
        <PrimaryButton
          customClass={extraClassnames}
          size={size}
          onClick={onSave}
          filter={{
            applyingFilter: isSubmitting,
          }}
        >
          Save Changes
        </PrimaryButton>
      </Fragment>,
      this.el,
    );
  }
}

PortalSaveButton.propTypes = {
  onSave: PropTypes.func.isRequired,
  size: PropTypes.string,
  isSubmitting: PropTypes.bool,
  portalSelector: PropTypes.string,
  extraClassnames: PropTypes.string,
};

PortalSaveButton.defaultProps = {
  size: "default",
  isSubmitting: false,
};

export default PortalSaveButton;
