import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { noop, isEmpty, forEach, cloneDeep, isEqual } from "lodash";
import SecondaryButton from "components/shared/SecondaryButton";
import PrimaryButton from "components/shared/PrimaryButton";
import ActionButton from "components/shared/ActionButton";
import TooltipHolder from "components/shared/TooltipHolder";
import AddIcon from "-!svg-react-loader?!assets/icons/utility/add.svg?name=AddIcon";
import Loader from "components/shared/Loader";
import TableHeader from "./TableHeader";
import NewEntry from "./NewEntry";
import TableRow from "./TableRow";
import TableFooter from "./TableFooter";
import PortalSaveButton from "./PortalSaveButton";
import preventerHandler from "lib/preventerHandler";

const initialState = {
  isAvailableToCreate: false,
  isAvailableToEdit: false,
  isShowingLessRows: false,
  isSubmitting: false,
  isUpdated: false,
  isCanceled: false,
  createData: {},
  updateData: {},
  removeData: {},
  removeIds: [],
  removeIndexes: [],
  errorsResults: [],
  collection: [],
};

function EditAndUpdateActions({
  onEdit,
  onCreate,
  collection,
  updateButtonExtraClassnames,
  updateButtonText,
  showModalLaunchButton,
  isEditButtonDisabled,
  editButtonDisabledTooltipText,
  disabledActionsTooltipText,
  disabledActions,
  behaviorUpdateButton,
}) {
  const canEdit = collection.length > 0;
  const updateButtonClassnames = classnames(updateButtonExtraClassnames);
  const tooltipText = disabledActions
    ? disabledActionsTooltipText
    : editButtonDisabledTooltipText;
  const launchModalButton = (
    <PrimaryButton disabled={disabledActions} size="small" onClick={onCreate}>
      {updateButtonText}
    </PrimaryButton>
  );
  const updateButton = (
    <TooltipHolder
      tooltip={tooltipText}
      position="top"
      showTooltip={disabledActions}
    >
      <ActionButton
        customClass={updateButtonClassnames}
        onClick={onCreate}
        size="small"
        disabled={disabledActions}
        behavior={behaviorUpdateButton}
      >
        <AddIcon className="Button-icon" />
        <span className="Button-text">{updateButtonText}</span>
      </ActionButton>
    </TooltipHolder>
  );
  const editButton = (
    <TooltipHolder
      tooltip={tooltipText}
      position="top"
      showTooltip={isEditButtonDisabled || disabledActions}
    >
      <SecondaryButton
        size="small"
        onClick={onEdit}
        disabled={isEditButtonDisabled || disabledActions}
      >
        Edit
      </SecondaryButton>
    </TooltipHolder>
  );
  return (
    <Fragment>
      {canEdit && editButton}
      {showModalLaunchButton ? launchModalButton : updateButton}
    </Fragment>
  );
}

function CancelAndSubmitButton({
  onCancel,
  onSave,
  saveChangesButtonExtraClassnames,
}) {
  return (
    <Fragment>
      <SecondaryButton size="small" onClick={onCancel}>
        Cancel
      </SecondaryButton>
      <PrimaryButton
        size="small"
        onClick={onSave}
        customClass={saveChangesButtonExtraClassnames}
      >
        Save Changes
      </PrimaryButton>
    </Fragment>
  );
}

function TableLoader() {
  return (
    <div className="TableLoader">
      <Loader />
    </div>
  );
}

function showMore(collection, isOnModal) {
  return collection ? isOnModal && collection.length > 3 : false;
}

function showEmptyItems(collection, emptyItem) {
  return !collection.length && emptyItem;
}

class Table extends Component {
  state = {
    ...initialState,
    createData: { 0: { ...this.props.rowInitialState } },
    isAvailableToCreate: this.props.isOnModal,
  };

  static propTypes = {
    isFetching: PropTypes.bool,
    isCreating: PropTypes.bool,
    isUpdating: PropTypes.bool,
    onResize: PropTypes.func,
    rounded: PropTypes.bool,
    title: PropTypes.string,
    wideTitle: PropTypes.bool,
    columns: PropTypes.arrayOf(PropTypes.shape({})),
    emptyItem: PropTypes.shape({}),
    collection: PropTypes.arrayOf(PropTypes.shape({})),
    onCreate: PropTypes.func,
    onEdit: PropTypes.func,
    canLockItem: PropTypes.func,
    bulkUpdate: PropTypes.func,
    isOnModal: PropTypes.bool,
    deleteTitle: PropTypes.string,
    deleteButtonText: PropTypes.string,
    renderRow: PropTypes.func.isRequired,
    rowInitialState: PropTypes.shape({}),
    updateButtonExtraClassnames: PropTypes.string,
    submitPortalSelector: PropTypes.string,
    viewMoreTooltipContent: PropTypes.string,
    updateButtonText: PropTypes.string,
    showFooter: PropTypes.bool,
    footerAmount: PropTypes.string,
    footerDescription: PropTypes.string,
    showModalLaunchButton: PropTypes.bool,
    isEditButtonDisabled: PropTypes.bool,
    editButtonDisabledTooltipText: PropTypes.string,
    disabledActionsTooltipText: PropTypes.string,
    lockTooltipContent: PropTypes.string,
    disabledActions: PropTypes.bool,
    saveChangesButtonExtraClassnames: PropTypes.string,
    behaviorUpdateButton: PropTypes.string,
    onCollectionChange: PropTypes.func,
    onDeleteRow: PropTypes.func,
  };

  static defaultProps = {
    onResize: noop,
    isOnModal: false,
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    rounded: false,
    canLockItem: () => false,
    title: "",
    wideTitle: false,
    updateButtonExtraClassnames: "",
    deleteTitle: "",
    columns: null,
    viewMoreTooltipContent: "",
    updateButtonText: "Update",
    showFooter: false,
    showModalLaunchButton: false,
    isEditButtonDisabled: false,
    lockTooltipContent: "Cost is not editable",
    editButtonDisabledTooltipText: "No editable valuations",
    disabledActionsTooltipText: "No editable valuations",
    disabledActions: false,
    behaviorUpdateButton: "action",
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.collection, nextProps.collection)) {
      this.fillStateCollection(nextProps.collection);
      this.showLessRows(nextProps.collection);
    }
  }

  componentDidMount() {
    this.fillStateCollection(this.props.collection);
    this.showLessRows(this.props.collection);
  }

  fillStateCollection(collection) {
    this.setState({ collection: cloneDeep(collection) });
  }

  showLessRows(collection) {
    if (showMore(collection, this.props.isOnModal)) {
      this.setState({ isShowingLessRows: true });
      this.props.onResize();
    }
  }

  startEditing = () => {
    this.setState(() => {
      return { isAvailableToEdit: true };
    });
  };

  startCreating = () => {
    this.setState(() => {
      return { isAvailableToCreate: true };
    });
  };

  showAllRows = () => {
    this.setState({ isShowingLessRows: false });
  };

  resetForm = () => {
    this.setState(() => {
      return {
        ...initialState,
        createData: { 0: { ...this.props.rowInitialState } },
        collection: cloneDeep(this.props.collection),
      };
    });
  };

  cancelForm = () => {
    this.setState({ isCanceled: true }, () => {
      this.resetForm();
    });
  };

  createForm = (name, value, index) => {
    this.setState((prevState) => {
      return {
        createData: {
          [index]: { ...prevState.createData[index], [name]: value },
        },
      };
    });
  };

  updateForm = (name, value, index, id) => {
    this.setState(
      (prevState) => {
        let nextAttributeObj = {};
        if (prevState.updateData.hasOwnProperty(index)) {
          nextAttributeObj = {
            ...prevState.updateData[index].attributes,
            [name]: value,
          };
        } else {
          nextAttributeObj = { [name]: value };
        }
        prevState.collection[index - 1][name] = value;
        return {
          updateData: {
            ...prevState.updateData,
            [index]: {
              id: id,
              attributes: nextAttributeObj,
            },
          },
        };
      },
      () => {
        const { onCollectionChange } = this.props;
        if (onCollectionChange) onCollectionChange(this.state.collection);
      },
    );
  };

  removeRow = (index, id) => {
    this.setState((prevState) => {
      return {
        removeIds: [...prevState.removeIds, id],
        removeIndexes: [...prevState.removeIndexes, index],
        removeData: {
          ...prevState.removeData,
          [index]: { id: id },
        },
      };
    });
  };

  createPayload() {
    const {
      createData,
      updateData,
      removeData,
      removeIds,
      isAvailableToCreate,
    } = this.state;
    let payload = [];
    if (isAvailableToCreate) {
      forEach(createData, (value, key) => {
        payload.push({ index: key, type: "create", attributes: value });
      });
    }
    if (!isEmpty(updateData)) {
      forEach(updateData, (value, key) => {
        if (!removeIds.includes(value.id)) {
          payload.push({
            index: key,
            type: "update",
            id: value.id,
            attributes: value.attributes,
          });
        }
      });
    }
    if (!isEmpty(removeData)) {
      forEach(removeData, (value, key) => {
        payload.push({ index: key, type: "destroy", id: value.id });
      });
    }

    return payload;
  }

  sendForm = () => {
    const payload = this.createPayload();
    this.setState({ isSubmitting: true });
    const hasDeletions = !isEmpty(this.state.removeData);
    const { onDeleteRow } = this.props;
    this.props.bulkUpdate(payload).then((data) => {
      if (data.success) {
        if (hasDeletions && onDeleteRow) {
          onDeleteRow();
        }
        this.setState({ isUpdated: true }, () => {
          this.resetForm();
        });
      } else {
        this.setState(() => {
          return { isSubmitting: false, errorsResults: data.results };
        });
      }
    });
  };

  render() {
    const {
      isOnModal,
      columns,
      title,
      isFetching,
      isCreating,
      isUpdating,
      renderRow,
      canLockItem,
      deleteTitle,
      deleteButtonText,
      updateButtonExtraClassnames,
      wideTitle,
      emptyItem,
      onCreate,
      rounded,
      submitPortalSelector,
      viewMoreTooltipContent,
      updateButtonText,
      showFooter,
      footerAmount,
      footerDescription,
      showModalLaunchButton,
      isEditButtonDisabled,
      editButtonDisabledTooltipText,
      lockTooltipContent,
      disabledActions,
      disabledActionsTooltipText,
      saveChangesButtonExtraClassnames,
      behaviorUpdateButton,
    } = this.props;

    if ((isFetching || isCreating) && !isUpdating) {
      return <TableLoader />;
    }

    const {
      isAvailableToEdit,
      isAvailableToCreate,
      isShowingLessRows,
      removeIndexes,
      isUpdated,
      collection,
      errorsResults,
      isSubmitting,
      createData,
      isCanceled,
    } = this.state;

    const renderActions =
      isAvailableToEdit || isAvailableToCreate ? (
        <CancelAndSubmitButton
          onCancel={this.cancelForm}
          onSave={this.sendForm}
          saveChangesButtonExtraClassnames={saveChangesButtonExtraClassnames}
        />
      ) : (
        <EditAndUpdateActions
          updateButtonExtraClassnames={updateButtonExtraClassnames}
          onEdit={this.startEditing}
          onCreate={preventerHandler(onCreate || this.startCreating)}
          collection={collection}
          updateButtonText={updateButtonText}
          showModalLaunchButton={showModalLaunchButton}
          isEditButtonDisabled={isEditButtonDisabled}
          disabledActions={disabledActions}
          editButtonDisabledTooltipText={editButtonDisabledTooltipText}
          disabledActionsTooltipText={disabledActionsTooltipText}
          behaviorUpdateButton={behaviorUpdateButton}
        />
      );
    const tableClassNames = classnames("Table", {
      "Table--rounded": rounded,
      isEditable: isAvailableToEdit,
      isSubmitting: isSubmitting || isUpdating,
      isShowingLessRows: isShowingLessRows,
      isShowingEmptyItems: showEmptyItems(collection, emptyItem),
    });
    const titleClassNames = classnames("TableWrapper--title", {
      wide: wideTitle,
    });
    const actualcollection = showEmptyItems(collection, emptyItem)
      ? [emptyItem]
      : collection;
    return (
      <div className="TableWrapper" data-testid={title}>
        {!isOnModal && (
          <div className={titleClassNames}>
            <h2 className="u-sectionHeading">{title}</h2>
            <div className="TableWrapper--actions">{renderActions}</div>
          </div>
        )}
        <div className={tableClassNames}>
          <TableLoader />
          <TableHeader columns={columns} />
          <div className="Table--body">
            {isAvailableToCreate && (
              <NewEntry
                renderRow={renderRow}
                updateForm={this.createForm}
                rowInitialState={createData[0]}
                columns={columns}
                errorsResults={errorsResults}
              />
            )}
            {!isUpdated &&
              !isCanceled &&
              actualcollection.map((data, index) => {
                return (
                  <TableRow
                    index={index + 1}
                    data={data}
                    renderRow={renderRow}
                    key={index}
                    updateForm={this.updateForm}
                    removeRow={this.removeRow}
                    showAllRows={this.showAllRows}
                    isShowingLessRows={isShowingLessRows}
                    canLockItem={canLockItem}
                    columns={columns}
                    removeIndexes={removeIndexes}
                    errorsResults={errorsResults}
                    deleteTitle={deleteTitle}
                    deleteButtonText={deleteButtonText}
                    viewMoreTooltipContent={viewMoreTooltipContent}
                    lockTooltipContent={lockTooltipContent}
                  />
                );
              })}
          </div>
          {showFooter && (
            <TableFooter
              amount={footerAmount}
              description={footerDescription}
            />
          )}
        </div>
        {isOnModal && (
          <PortalSaveButton
            portalSelector={submitPortalSelector}
            size="default"
            onSave={this.sendForm}
            isSubmitting={isSubmitting || isUpdating}
            extraClassnames={saveChangesButtonExtraClassnames}
          />
        )}
      </div>
    );
  }
}

export default Table;
