export const ACQUISITION_TEMPLATE_ATTRIBUTES = { company_id: "", date: "" };
export const WEBSITE_PREFILL = "http://";
export const TWITTER_PREFILL = "twitter.com/";
export const LINKEDIN_PREFILL = "linkedin.com/company/";
export const EMPLOYEES_COUNT_OPTIONS = [
  { value: "1-to-10", label: "1 - 10" },
  { value: "11-to-50", label: "11 - 50" },
  { value: "51-to-100", label: "51 - 100" },
  { value: "101-to-250", label: "101 - 250" },
  { value: "251-to-500", label: "251 - 500" },
  { value: "501-to-1000", label: "501 - 1,000" },
  { value: "1001-to-2500", label: "1,001 - 2,500" },
  { value: "2501-to-5000", label: "2,501 - 5,000" },
  { value: "5001-to-10000", label: "5,001 - 10,000" },
  { value: "more-than-10000", label: "10,001+" },
];
export const STATUS_OPTIONS = [
  { value: "Active", label: "Active" },
  { value: "Acquired", label: "Acquired" },
  { value: "Failed", label: "Failed" },
  { value: "IPO", label: "IPO" },
];

export function removePreFilledAndHttp(value, preFilled) {
  const valueWithoutProtocol = value.replace(/(^\w+:|^)\/\//, "");
  const valueWithoutWww =
    preFilled !== WEBSITE_PREFILL
      ? valueWithoutProtocol.replace("www.", "")
      : valueWithoutProtocol;
  const valueWithoutPrefill = valueWithoutWww.replace(`${preFilled}`, "");
  return valueWithoutPrefill;
}

export function isValidContent(value) {
  return Array.isArray(value) ? value.length > 0 : !!value;
}

export function createErrorNameByIndex(group, index, type) {
  return `${group}.${index}.${type}`;
}
