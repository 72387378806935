import React from "react";
import classNames from "classnames";
import "./EmptyContent.scss";

function EmptyContent({ children, extraClassnames }) {
  const emptyContentClassnames = classNames("EmptyContent", {
    [extraClassnames]: !!extraClassnames,
  });
  return <div className={emptyContentClassnames}>{children}</div>;
}

export default EmptyContent;
