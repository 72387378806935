import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Radio as SemanticRadio } from "@ableco/semantic-ui-react";
import "./RadioButton.scss";

export default function Radio({
  extraClassName,
  primary,
  error,
  disabled,
  readOnly,
  focused,
  onChange,
  ...props
}) {
  const [pristine, setPristine] = useState(true);

  const handleChange = (event, data) => {
    setPristine(false);
    onChange && onChange(event, data);
  };

  const radioClasses = classNames("RadioButton", {
    [extraClassName]: !!extraClassName,
    primary: !!primary && !error,
    error: !!error && pristine,
    disabled: !!disabled,
    readOnly: !!readOnly,
    focused: !!focused,
  });

  return (
    <SemanticRadio
      disabled={disabled}
      readOnly={readOnly}
      className={radioClasses}
      onChange={handleChange}
      {...props}
    />
  );
}

Radio.propTypes = {
  extraClassName: PropTypes.string,
  primary: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  focused: PropTypes.bool,
  onChange: PropTypes.func,
};
