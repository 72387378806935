import React from "react";
import Header from "components_new/atoms/Header";
import PropTypes from "prop-types";
import "./SummaryCard.scss";
import classNames from "classnames";
import { SkeletonStatLine } from "components_new/elements/SummaryStats/SummaryStatsSkeleton";

function SummaryCard({ title, value, isLoading, extraClassnames }) {
  const summaryCardClassnames = classNames("SummaryCard", {
    "is-loading": !!isLoading,
    [extraClassnames]: !!extraClassnames,
  });
  return (
    <div className={summaryCardClassnames}>
      <label className="SummaryCard-title">{title}</label>
      {isLoading ? (
        <SkeletonStatLine className="SummaryCard-skeletonValue" />
      ) : (
        <Header as="h3">{value}</Header>
      )}
    </div>
  );
}

SummaryCard.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isLoading: PropTypes.bool,
  extraClassnames: PropTypes.string,
};

export default SummaryCard;
