import { getResource } from "store/resourcesV2/selectors";

const isFunction = (param) => typeof param === "function";

export function getCurrentOrganization(state) {
  const currentState = isFunction(state) ? state() : state;

  const {
    organizations: { currentOrganizationId },
  } = currentState;
  return getResource(currentState, "organizations", currentOrganizationId);
}
