import React from "react";
import Icon from "components_new/atoms/Icon";
import SnapshotItem from "components_new/views/Snapshots/shared/SnapshotItem";
import "./ContentBody.scss";

function ContentBody({ data, removeItem }) {
  const { type, avatar, title, description, icons, meta } = data;
  return (
    <div className="SectionList-SnapshotSections-Item">
      <SnapshotItem
        type={type}
        avatar={avatar}
        title={title}
        description={description}
        meta={meta}
        icons={icons}
        iconsColor="red"
      />
      <div className="SnapshotSections-Item-ItemRemove">
        <Icon
          icon="ri-close-line"
          onClick={() => {
            removeItem(title);
          }}
          size="tiny"
        />
      </div>
    </div>
  );
}

export default ContentBody;
