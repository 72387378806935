import React from "react";
import SuggestedButton from "components_new/elements/SuggestedButton";

function SuggestedTags({ tags, onAdd, onRemove, selectedTags, disabled }) {
  return (
    <div className="SuggestedTags">
      {tags.map((tag) => {
        const tagExist = selectedTags.some((el) => el.name === tag.name);
        return (
          <SuggestedButton
            key={tag.id}
            suggestion={tag}
            onAdd={onAdd}
            onRemove={onRemove}
            isSelected={tagExist}
            disabled={disabled}
          >
            {tag.name}
          </SuggestedButton>
        );
      })}
    </div>
  );
}

export default SuggestedTags;
