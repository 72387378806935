import React from "react";
import ExpandCollapseToggle from "components_new/elements/ExpandCollapseToggle";
import FileFilters from "./FileFilters";
import PropTypes from "prop-types";
import "./TopBar.scss";

function TopBar({
  fileTypes,
  filtersApplied,
  onApplyFilters,
  allRowsExpanded,
  expandAllRowDetails,
  collapseAllRowDetails,
}) {
  return (
    <div className="TopBar">
      <div className="TopBar-filters">
        <FileFilters
          fileTypes={fileTypes}
          filtersApplied={filtersApplied}
          onApplyFilters={onApplyFilters}
          expandAllRowDetails={expandAllRowDetails}
          collapseAllRowDetails={collapseAllRowDetails}
        />
      </div>
      <ExpandCollapseToggle
        extraClassnames="TopBar-toggle"
        expanded={allRowsExpanded}
        onExpand={expandAllRowDetails}
        onCollapse={collapseAllRowDetails}
      />
    </div>
  );
}

TopBar.propTypes = {
  fileTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  filtersApplied: PropTypes.arrayOf(PropTypes.string).isRequired,
  onApplyFilters: PropTypes.func.isRequired,
  allRowsExpanded: PropTypes.bool.isRequired,
  expandAllRowDetails: PropTypes.func.isRequired,
  collapseAllRowDetails: PropTypes.func.isRequired,
};

export default TopBar;
