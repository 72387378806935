import React from "react";
import PropTypes from "prop-types";
import { Button as SemanticButton } from "@ableco/semantic-ui-react";
import classNames from "classnames";
import "semantic-ui-css/components/button.css";
import "./Button.scss";

function Button(props) {
  const {
    children,
    warning,
    iconPosition,
    transparent,
    className,
    ...restProps
  } = props;
  const extraClassName = classNames(`Button Button-icon-${iconPosition}`, {
    warning: !!warning,
    transparent: !!transparent,
    [className]: !!className,
  });

  const buttonProps = {
    className: extraClassName,
    ...restProps,
  };

  if (restProps.loading) {
    buttonProps["aria-label"] = "Loading...";
  }

  return <SemanticButton {...buttonProps}>{children}</SemanticButton>;
}

Button.propTypes = {
  warning: PropTypes.bool,
  iconPosition: PropTypes.oneOf(["left", "middle", "right"]),
  transparent: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  loading: PropTypes.bool,
};
Button.defaultProps = {
  iconPosition: "middle",
};

export default Button;
