import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Input, Form } from "@ableco/semantic-ui-react";
import Icon from "components_new/atoms/Icon";
import useInputId from "lib/hooks/useInputId";
import "semantic-ui-css/components/icon.css";
import "semantic-ui-css/components/label.css";
import "./SearchInput.scss";

function SearchInput(props) {
  const {
    icon,
    onChange,
    extraClassnames,
    loading,
    value,
    size,
    iconPosition,
    label,
    ...inputProps
  } = props;

  const [displayValue, setDisplayValue] = useState(value || "");
  const id = useInputId();

  useEffect(() => {
    if (value !== displayValue) {
      setDisplayValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = ({ target: { value } }) => {
    setDisplayValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  const handeClearInput = () => {
    setDisplayValue("");
    if (onChange) {
      onChange("");
    }
  };

  const searchInputClassnames = classnames("SearchInput", {
    [extraClassnames]: !!extraClassnames,
    "show-clear-button": !!displayValue,
    [iconPosition]: !!iconPosition,
    [size]: !!size,
  });

  return (
    <Form.Field>
      {label && <label htmlFor={id}>{label}</label>}
      <Input
        id={id}
        className={searchInputClassnames}
        loading={loading}
        readOnly={loading}
        iconPosition={iconPosition}
        {...inputProps}
      >
        <Icon icon="ri-search-line" size="mini" />
        <input
          className="SearchInput-inputElement"
          onChange={handleChange}
          value={displayValue}
          autoComplete="off"
        />

        <Icon icon="ri-close-circle-fill" onClick={handeClearInput} />
      </Input>
    </Form.Field>
  );
}

SearchInput.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  extraClassnames: PropTypes.string,
  icon: PropTypes.string,
  loading: PropTypes.bool,
  value: PropTypes.string,
  iconPosition: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
};

SearchInput.defaultProps = {
  icon: "search",
  iconPosition: "left",
  size: "regular",
};

export default SearchInput;
