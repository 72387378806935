import { useMutation, useQueryClient } from "react-query";
import Api from "lib/Api";

export function useUpdateStage() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ pipelineId, stageId, name }) =>
      Api.patch(`/pipelines/${pipelineId}/stages/${stageId}`, { name }),
    {
      onSuccess: ({ data: updatedStage }, { pipelineId }) => {
        return queryClient.setQueryData(
          ["Pipeline", pipelineId],
          (oldData) => ({
            ...oldData,
            stages: oldData.stages.map((stage) =>
              stage.id === updatedStage.id ? updatedStage : stage,
            ),
          }),
        );
      },
      onError: (error) => {
        throw error;
      },
    },
  );
}
