import { connect } from "react-redux";
import InvestmentActionsDropdown from "./InvestmentActionsDropdown";
import { openInModal, ModalNames } from "store/modal";
import { openModal } from "store/modalV2";
import { openSidebar } from "store/sidebar";

function mapStateToProps({
  /**
   * remove me after deprecation of modal forms
   */
  sidebar: { initialComponent },
}) {
  return { initialComponent };
}

function mapDispatchToProps(dispatch) {
  return {
    openEditInvestmentSidebarForm: (investment) =>
      dispatch(
        openSidebar("editInvestmentForm", "Edit Investment", {
          investment,
          isEditing: true,
        }),
      ),
    openLogStockSplitSidebarForm: (investment) =>
      dispatch(
        openSidebar("stockSplitForm", "Log Stock Split", {
          investment,
        }),
      ),
    openValuationSidebarForm: ({ investment, company }) => {
      return dispatch(
        openSidebar("valuationForm", "Add Valuation", {
          initialHoldingId: investment.id,
          initialCompanyId: company.id,
        }),
      );
    },
    openDeleteInvestmentModal: (investment) =>
      dispatch(
        openModal("deleteInvestment", { investment, source: "companies" }),
      ),
    openChangePortfolioOfInvestmentModal: (investment) =>
      dispatch(
        openInModal(ModalNames.changePortfolioOfInvestment, {
          investmentId: investment.id,
          source: "companies",
        }),
      ),
    openLogWriteOffSidebarForm: (investment) =>
      dispatch(openSidebar("writeOffForm", "Log Write-Off", { investment })),
    openLogSaleSidebarForm: (investment) =>
      dispatch(openSidebar("logSaleForm", "Log Sale", { investment })),
    openLogAcquisitionSidebarForm: (investment) =>
      dispatch(
        openSidebar("logAcquisitionForm", "Log Acquisition", { investment }),
      ),
    openGlobalCapitalCallSidebarForm: ({ investment, company }) =>
      dispatch(
        openSidebar("capitalCallForm", "Add Capital Call", {
          initialHoldingId: investment.id,
          initialCompanyId: company.id,
        }),
      ),
    openLogDistributionSidebarForm: (investment) =>
      dispatch(
        openSidebar("distributionForm", "Log Distribution", {
          investment,
        }),
      ),

    openLogConversionSidebarForm: (investment) =>
      dispatch(
        openSidebar("logConversionForm", "Log Conversion", { investment }),
      ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvestmentActionsDropdown);
