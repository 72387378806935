import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";
import SearchInput from "components_new/atoms/SearchInput";
import PropTypes from "prop-types";
import ContactList from "./Lists/ContactList";
import useDebounce from "lib/hooks/useDebounce";
import { noop } from "lodash";
import "./SearchSelect.scss";

function EmptyResult({ query }) {
  return (
    <ul className="SearchSelect-list">
      <li className="SearchSelect-empty">No results for {query}</li>
    </ul>
  );
}

function SearchSelect({
  onChange,
  onFilter,
  children,
  placeholder,
  label,
  scope,
  errorMessage,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [errors, setErrors] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");
  const searchSelect = useRef(null);
  const { debouncedValue: debouncedSearchTerm } = useDebounce(searchText, 300);

  const searchSelectClassnames = classnames("SearchSelect", {
    "has-suggestions": suggestions.length > 0,
    "has-errorMessage": !!errors,
  });

  function cleanSearch() {
    if (noResults) {
      setNoResults(false);
    }
    if (errors) {
      setErrors(null);
    }
    setSuggestions([]);
  }

  const handleSearch = async (value) => {
    setIsLoading(true);
    const response = await onFilter(scope, value);
    if (response.length > 0) {
      setSuggestions(response);
      setNoResults(false);
    } else {
      setNoResults(true);
    }
    setIsLoading(false);
  };

  const handleClick = (event) => {
    if (searchSelect.current.contains(event.target)) {
      return;
    }
    // when user clicks outside
    const suggestionList = document.querySelector(".SearchSelect-list");
    if (suggestionList) {
      setSuggestions([]);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  useEffect(() => {
    if (errorMessage) {
      setErrors(errorMessage);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      handleSearch(debouncedSearchTerm);
    } else if (debouncedSearchTerm === "") {
      onChange(null);
      cleanSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const onHandleSelect = (selection, value) => {
    if (value) {
      if (onChange) {
        onChange(selection);
        setSelectedValue(value);
        cleanSearch();
      }
    }
  };

  return (
    <div ref={searchSelect} className={searchSelectClassnames}>
      <SearchInput
        onChange={(value) => setSearchText(value)}
        loading={isLoading}
        iconPosition="right"
        value={selectedValue}
        placeholder={placeholder}
        label={label}
        autoComplete="off"
        size="tiny"
      />
      {suggestions.length > 0 &&
        children({
          suggestions,
          onChange: onHandleSelect,
        })}
      {noResults && <EmptyResult query={searchText} />}
      {errors && <label className="Input-errorMessage">{errors}</label>}
    </div>
  );
}

SearchSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  extraclassNames: PropTypes.string,
  scope: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  size: PropTypes.oneOf(["regular", "tiny"]),
};

SearchSelect.defaultProps = {
  onChange: noop,
  onFilter: noop,
  children: (props) => <ContactList {...props} />,
};

export default SearchSelect;
