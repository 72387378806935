import React, { Fragment } from "react";
import Link from "components_new/atoms/Link";
import TooltipHolder from "./TooltipHolder";
import CompanyIcon from "./CompanyIcon";

function HoldingView({ holding, company, organizationUsername }) {
  const { id: investmentId, name: investmentName } = holding;

  return (
    <Fragment>
      {company && (
        <div className="HoldingCellPreview-companySection">
          <TooltipHolder
            key={`company-${company.id}`}
            extraClassnames="CompanyThumb"
            tooltip={company.name}
            position="top"
          >
            <Link to={`/${organizationUsername}/companies/${company.id}`}>
              <CompanyIcon src={company.logo_url} size="medium" />
            </Link>
          </TooltipHolder>
        </div>
      )}
      <div className="HoldingCellPreview-holdingSection u-textWithEllipsis">
        <Link
          extraClassnames="secondaryLink"
          to={`/${organizationUsername}/investments/${investmentId}`}
        >
          {investmentName}
        </Link>
      </div>
    </Fragment>
  );
}

function HoldingCellPreview({
  holding,
  companies,
  isTerminalAction,
  organizationUsername,
}) {
  return (
    <div className="HoldingCellPreview">
      {isTerminalAction ? (
        "- - -"
      ) : (
        <HoldingView
          holding={holding}
          company={companies[holding.company_id]}
          organizationUsername={organizationUsername}
        />
      )}
    </div>
  );
}

export default HoldingCellPreview;
