import React from "react";
import StockDetails from "components_new/SidebarComponents/forms/groups/StockDetails";
import VestingDetails from "components_new/SidebarComponents/forms/groups/VestingDetails";
import DatePicker from "components_new/atoms/DatePicker";
import SplitSelect from "components_new/atoms/SplitSelect";
import NumberInput from "components_new/atoms/NumberInput";
import DropdownForm from "components_new/atoms/DropdownForm";
import {
  seriesDropdownItem,
  splitSelectionOptions,
  getDefaultSelectedOption,
} from "./config";

function EquityInvestmentDetails({
  handleChange,
  formData,
  isEditing,
  errors,
}) {
  const {
    series_name,
    name,
    date,
    per_quantity_or_total_option,
    quantity,
  } = formData;

  const handleAmountChange = (input, selection) => {
    handleChange("per_share_or_total_amount", input);
    if (selection) {
      handleChange("per_quantity_or_total_option", selection.value);
    }
  };

  const defaultSelectedOption = getDefaultSelectedOption(
    splitSelectionOptions,
    per_quantity_or_total_option,
    "value",
  );

  function getAmount(option) {
    let data = {};
    if (option === "per_quantity") {
      data = {
        value: formData.unit_acquisition_amount,
        label: "unit_acquisition_amount",
      };
    }
    if (option === "total") {
      data = {
        value: formData.total_acquisition_amount,
        label: "total_acquisition_amount",
      };
    }
    return data;
  }

  const amountData = getAmount(defaultSelectedOption.value);
  const isReadOnly = isEditing && formData.previous_holding;
  const seriesNameLabel = isEditing ? "series_name" : "name";
  const seriesNameValue = isEditing ? series_name : name;

  return (
    <div className="EquityInvestmentDetails BasicDetails ui form">
      <DropdownForm
        name="name"
        label="Series"
        placeholder="Select series"
        items={seriesDropdownItem}
        onChange={(_, { value }) => handleChange(seriesNameLabel, value)}
        value={seriesNameValue}
        required
        size="tiny"
        error={!!errors.for("name")}
        errorMessage={errors.for("name")}
        readOnly={isReadOnly}
      />
      <DatePicker
        name="date"
        label="Purchase Date"
        onChange={(value, validated) => handleChange("date", value, validated)}
        value={date}
        prefillToday={true}
        required
        size="tiny"
        isReadOnly={isReadOnly}
      />
      <SplitSelect
        inputProps={{
          name: "total_acquisition_amount",
          label: "Amount*",
          decimalLimit: 4,
          placeholder: "0.0000",
          defaultValue: amountData.value,
        }}
        dropdownProps={{
          options: splitSelectionOptions,
          defaultValue: defaultSelectedOption,
        }}
        onChange={(_, { input, selection }) =>
          handleAmountChange(input, selection)
        }
        value={amountData.value}
        extraClassnames="on-sidebar"
        size="tiny"
        error={!!errors.for("total_acquisition_amount")}
        errorMessage={errors.for("total_acquisition_amount")}
      />
      <NumberInput
        name="quantity"
        label="Quantity"
        aria-label="Quantity"
        onChange={(value) => handleChange("quantity", value)}
        value={quantity}
        defaultValue={quantity}
        size="tiny"
        required
        error={!!errors.for("quantity")}
        errorMessage={errors.for("quantity")}
      />
      <StockDetails
        title="Equity Details"
        formData={formData}
        handleChange={handleChange}
      />
      <VestingDetails
        title="Vesting Details"
        formData={formData}
        handleChange={handleChange}
      />
    </div>
  );
}

export default EquityInvestmentDetails;
