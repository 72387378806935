import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Button from "components_new/atoms/Button";
import Icon from "components_new/atoms/Icon";
import Dropdown, { DropdownMenu } from "components_new/atoms/Dropdown";
import Segment from "components_new/atoms/Segment";
import classnames from "classnames";
import pluralize from "pluralize";
import { Grid } from "@ableco/semantic-ui-react";
import { isEmpty } from "lodash";

function countSelectedFilters(filters) {
  return Object.values(filters).reduce((sum, count) => sum + count);
}

function TableFilter({
  children,
  className,
  onApplyFilters,
  initialFilterValues,
  values,
  buttonFilterText = "Filter",
}) {
  const [reInit, setReInit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [filters, setFilters] = useState(initialFilterValues);
  const [filtersAppliedCount, setFiltersAppliedCount] = useState({});
  //When filters are applied, fall back to the following working filter state on cancel:
  const [workingFilters, setWorkingFilters] = useState({});
  const [workingFiltersCount, setWorkingFiltersCount] = useState(0);
  const dropdownMenu = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReset = useCallback(() => {
    setReInit(true);
    setFilters(initialFilterValues);
    setFiltersAppliedCount({});
    setWorkingFiltersCount(0);
    setWorkingFilters({});
  }, [initialFilterValues]);

  const handleCancel = useCallback(() => {
    setReInit(true);
    setFilters(workingFilters);
  }, [workingFilters]);

  const handleApplyFilters = () => {
    setIsOpen(false);
    onApplyFilters(filters);
    setWorkingFilters(filters);
    setWorkingFiltersCount(countSelectedFilters(filtersAppliedCount));
  };

  const buttonFilterName = useMemo(
    () =>
      pluralize(buttonFilterText, workingFiltersCount, workingFiltersCount > 0),
    [buttonFilterText, workingFiltersCount],
  );
  const tableFilterClassnames = classnames("TableFilter Filter", {
    [className]: !!className,
    FiltersApplied: workingFiltersCount > 0,
  });

  const handleClose = useCallback(() => {
    if (workingFiltersCount > 0) {
      handleCancel();
    } else {
      handleReset();
    }
    setIsOpen(false);
  }, [handleCancel, handleReset, workingFiltersCount]);

  const handleClearFilters = useCallback(
    (event) => {
      handleReset();
      onApplyFilters(initialFilterValues, []);
      event?.stopPropagation();
    },
    [handleReset, initialFilterValues, onApplyFilters],
  );

  useEffect(() => {
    if (isEmpty(values)) handleReset();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const shouldDisabledButton = useMemo(() => filtersAppliedCount === 0, [
    filtersAppliedCount,
  ]);

  const handleClickOutside = (event) => {
    if (dropdownMenu.current && !dropdownMenu.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const updateFilters = (filterName, filterValue, filterCount = 1) => {
    setFilters({ ...filters, [filterName]: filterValue });
    setFiltersAppliedCount({
      ...filtersAppliedCount,
      [filterName]: filterCount,
    });
  };

  return (
    <Dropdown
      className={tableFilterClassnames}
      trigger={
        <Button secondary size="small">
          <Icon icon="ri-filter-3-fill" />
          <span>{buttonFilterName}</span>

          {workingFiltersCount > 0 && (
            <Icon
              icon="ri-close-circle-fill"
              size="small"
              onClick={handleClearFilters}
            />
          )}
        </Button>
      }
      icon={null}
      open={isOpen}
      openOnFocus={false}
      onClick={() => {
        setIsOpen(!isOpen);
      }}
    >
      <DropdownMenu className="Menu-Container">
        <div ref={dropdownMenu} onClick={(event) => event.stopPropagation()}>
          <Segment attached="top" className="TableFilter-Containter">
            {children(filters, updateFilters, reInit, setReInit)}
          </Segment>
          <Segment attached="bottom" className="TableFilter-footer">
            <Grid columns="equal" className="TableFilter-actions">
              <Grid.Column>
                <Grid.Row>
                  <div className="ClearButton" onClick={handleReset}>
                    Clear All
                  </div>
                </Grid.Row>
              </Grid.Column>
              <Grid.Column>
                <Grid.Row className="Apply-Cancel-Buttons">
                  <Button secondary size="small" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    primary
                    size="small"
                    disabled={shouldDisabledButton}
                    onClick={handleApplyFilters}
                  >
                    Apply
                  </Button>
                </Grid.Row>
              </Grid.Column>
            </Grid>
          </Segment>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
}
export default TableFilter;
