import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { currentUserPropType, postResourceProptype } from "PropTypes";
import UserAvatar from "components_new/elements/UserAvatar";
import CreatePost from "./Post/Create";

function CreateDiscussion({
  createAction,
  user,
  openDocumentsUploader,
  placeholder,
  showPreviewForm,
  parentId,
  resource,
  onCancel,
  isInlineAttachment,
  size,
  isAReply = false,
}) {
  const [showPreview, setShowPreview] = useState(showPreviewForm);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const submitForm = (postContent) => {
    setIsSubmitting(true);
    createAction(resource, postContent)
      .then(() => {
        setShowPreview(true);
      })
      .finally(() => setIsSubmitting(false));
  };

  useEffect(() => {
    setShowPreview(showPreviewForm);
  }, [showPreviewForm]);

  return (
    <section className="Discussion-createForm">
      <div className="PostSection">
        <UserAvatar
          avatarColor={user.avatar_color}
          avatarUrl={user.avatar_url}
          initials={user.initials}
          showTooltip={false}
          size="small"
        />
        {showPreview ? (
          <div
            className="FormElement Fake-Input PostSection-content"
            onClick={() => setShowPreview(false)}
          >
            {placeholder}
          </div>
        ) : (
          <CreatePost
            user={user}
            isEditing={true}
            isSubmitting={isSubmitting}
            placeholder={placeholder}
            onSubmit={submitForm}
            resource={resource}
            parentId={parentId}
            onCancel={() => {
              onCancel ? onCancel() : setShowPreview(true);
            }}
            openDocumentsUploader={openDocumentsUploader}
            isInlineAttachment={isInlineAttachment}
            isAReply={isAReply}
            size={size}
          />
        )}
      </div>
    </section>
  );
}

CreateDiscussion.propTypes = {
  createAction: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  user: currentUserPropType,
  openDocumentsUploader: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  showPreviewForm: PropTypes.bool,
  resource: postResourceProptype,
  parentId: PropTypes.number,
  isInlineAttachment: PropTypes.bool,
  size: PropTypes.string,
  isAReply: PropTypes.bool,
};

CreateDiscussion.defaultProps = {
  showPreviewForm: true,
  isInlineAttachment: false,
};

export default CreateDiscussion;
