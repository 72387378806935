import React, { useState } from "react";
import Button from "components_new/atoms/Button";
import Input from "components_new/atoms/Input";
import "./AddSectionForm.scss";
import { isEmpty } from "lodash";

const AddSectionForm = ({ addSection }) => {
  const [sectionName, setSectionName] = useState("");
  const handleAddSection = () => {
    if (sectionName !== "") {
      addSection(sectionName);
      setSectionName("");
    }
  };
  return (
    <form onSubmit={handleAddSection} className="AddSectionForm">
      <div className="AddSectionForm-FieldGroup">
        <Input
          name="newSectionName"
          placeholder="Enter a section name"
          value={sectionName}
          onChange={(_, { value }) => {
            setSectionName(value);
          }}
          size="small"
          extraClassnames="AddSectionForm-Input"
        />
        <div className="AddSectionForm-Submit">
          <Button
            secondary
            onClick={() => handleAddSection()}
            type="submit"
            size="small"
            disabled={isEmpty(sectionName)}
          >
            Add Section
          </Button>
        </div>
      </div>
    </form>
  );
};

export default AddSectionForm;
