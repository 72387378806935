import React from "react";
import Loader from "components/shared/Loader";
import EmptyDashboard from "./EmptyDashboard";
import DashboardView from "./Dashboard";
import { useParams } from "react-router-dom";
import { useDashboard } from "components_new/views/Dashboard/hooks/queries/useDashboard";

function DashboardWrapper() {
  const { organizationUsername, chartType } = useParams();

  const { data, isLoading } = useDashboard({
    organizationUsername,
  });

  if (isLoading) {
    return <Loader />;
  }

  if (!data?.hasInvestments) {
    return <EmptyDashboard />;
  }

  return (
    <DashboardView
      chartType={chartType}
      highlights={data.highlights}
      investmentPaceItems={data.investmentPaceItems}
      cashFlowPerformanceItems={data.cashFlowPerformanceItems}
    />
  );
}

export default DashboardWrapper;
