import { REQUEST_START, REQUEST_SUCCESS, REQUEST_ERROR } from "./actions";

const REQUEST_NAMES = [
  "fetchCompany",
  "fetchInvestment",
  "createLogDistribution",
  "createLogSale",
  "createOrganization",
  "createLogAcquisition",
  "createLoanConversion",
  "validateLoanConversion",
];

const generateInitialStates = () => {
  return REQUEST_NAMES.reduce((requestStates, requestName) => {
    return {
      ...requestStates,
      [requestName]: {
        processing: false,
        done: false,
        failed: false,
      },
    };
  }, {});
};

const initialState = generateInitialStates();

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_START: {
      return {
        ...state,
        [action.requestName]: {
          processing: true,
          done: false,
          failed: false,
        },
      };
    }
    case REQUEST_SUCCESS: {
      return {
        ...state,
        [action.requestName]: {
          processing: false,
          done: true,
          failed: false,
        },
      };
    }
    case REQUEST_ERROR: {
      return {
        ...state,
        [action.requestName]: {
          processing: false,
          done: false,
          failed: true,
        },
      };
    }
    default:
      return state;
  }
}
