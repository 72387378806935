import React from "react";
import PropTypes from "prop-types";
import middleEllipsis from "lib/middleEllipsis";
import classnames from "classnames";
import { Link as RouterLink } from "react-router-dom";

const TITLE_MAX_LENGTH = 65;

function Link({
  title,
  to,
  extraClassnames,
  maxCharsLength,
  isExternal,
  children,
  ...linkProps
}) {
  const externalLinkClassnames = classnames("Link", {
    [extraClassnames]: !!extraClassnames,
  });
  if (!isExternal) {
    return (
      <RouterLink {...linkProps} to={to} className={externalLinkClassnames}>
        {title ? middleEllipsis(title, maxCharsLength) : children}
      </RouterLink>
    );
  }
  return (
    <a
      className={externalLinkClassnames}
      href={to}
      rel="nofollow noopener"
      target="_blank"
    >
      {title ? middleEllipsis(title, maxCharsLength) : children}
    </a>
  );
}

Link.defaultProps = {
  maxCharsLength: TITLE_MAX_LENGTH,
  title: "",
  isExternal: false,
};

Link.propTypes = {
  title: PropTypes.string,
  to: PropTypes.string,
  extraClassnames: PropTypes.string,
  maxCharsLength: PropTypes.number,
  isExternal: PropTypes.bool,
  children: PropTypes.node,
};

export default Link;
