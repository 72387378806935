import React from "react";
import moment from "moment";
import Icon from "components_new/atoms/Icon";
import PropTypes from "prop-types";
import { monthYearFormat } from "config/customCalendarConfig";

function CalendarHeader({ changeMonth, calendarMonthIndex, calendarYear }) {
  const date = moment({
    year: calendarYear,
    month: calendarMonthIndex,
    day: 1,
  });

  return (
    <div className="CalendarHeader">
      <div className="CalendarHeader-prev" onClick={(e) => changeMonth(e, -1)}>
        <Icon icon="ri-arrow-left-s-line" size="tiny" />
      </div>
      <span className="CalendarHeader-monthName u-label">{`${moment(
        date,
      ).format(monthYearFormat)}`}</span>
      <div className="CalendarHeader-next" onClick={(e) => changeMonth(e, 1)}>
        <Icon icon="ri-arrow-right-s-line" size="tiny" />
      </div>
    </div>
  );
}

CalendarHeader.propTypes = {
  changeMonth: PropTypes.func,
  calendarMonthIndex: PropTypes.number,
  calendarYear: PropTypes.number,
};

export default CalendarHeader;
