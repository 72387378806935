import React from "react";
import { placeholderDateFormat } from "config/dateInput";
import FormGroup from "components_new/elements/FormGroup";
import DatePicker from "components_new/atoms/DatePicker";
import NumberInput from "components_new/atoms/NumberInput";
import Autocomplete from "components_new/elements/Autocomplete";
import SplitSelect from "components_new/atoms/SplitSelect";
import { isNumber, capitalize } from "lodash";
import { getInvestmentSaleMessage } from "./config";
import Label from "components_new/atoms/Label";
import { getText } from "TermsMapper";
import "./SaleDetail.scss";

const getTypeOfPriceList = (type, value) => {
  if (!type) {
    return [];
  }
  return [
    {
      key: value,
      value: value,
      text: `Per ${capitalize(getText(type, "valuation_unit"))}`,
    },
    { key: "total", value: "total", text: "Total" },
  ];
};

function SaleDetail({
  userContacts,
  subtitle,
  formData,
  onChange,
  errors,
  investment,
  required = false,
}) {
  const {
    date,
    buyer_name,
    quantity,
    per_quantity_or_total_option,
    price_per_unit,
  } = formData;
  const salePriceTypes = getTypeOfPriceList(investment.type, "per_unit");
  const defaultSalePriceType =
    salePriceTypes.find(
      (type) => type.value === per_quantity_or_total_option,
    ) || {};
  return (
    <FormGroup
      extraClassnames="SaleDetailGroup"
      title="Sale Details"
      required={required}
      subtitle={subtitle}
      isExpanded
    >
      <DatePicker
        name="date"
        placeholder={placeholderDateFormat}
        extraClassnames="FormElement"
        label="Date of sale"
        onChange={(value, validated) => {
          onChange("date", value, validated);
        }}
        error={!!errors.for("date")}
        errorMessage={errors.for("date")}
        value={date}
        prefillToday={true}
        oldVersion
        size="tiny"
        required
      />
      <Autocomplete
        name="investment_buyer_id"
        label="Buyer*"
        options={userContacts}
        onChange={(value) => onChange("buyer_name", value)}
        errorMessage={errors.for("investment_buyer_id")}
        value={buyer_name}
        autoFocus
        size="tiny"
        required
      />
      <NumberInput
        name="quantity"
        label="Number of shares sold"
        onChange={(value) => onChange("quantity", value)}
        value={quantity}
        errorMessage={errors.for("quantity")}
        size="tiny"
        required
      />
      <SplitSelect
        inputProps={{
          name: "price_per_unit",
          label: "Sale price*",
          decimalLimit: 2,
          placeholder: "0.00",
        }}
        dropdownProps={{
          options: salePriceTypes,
          defaultValue: defaultSalePriceType,
        }}
        onChange={(_, { input, selection }) => {
          onChange("price_per_unit", input);
          if (selection) {
            onChange("per_quantity_or_total_option", selection.value);
          }
        }}
        value={price_per_unit}
        size="tiny"
        error={!!errors.for("price_per_unit")}
        errorMessage={errors.for("price_per_unit")}
      />
      {isNumber(price_per_unit) && (
        <Label>{getInvestmentSaleMessage(formData, investment)}</Label>
      )}
    </FormGroup>
  );
}

export default SaleDetail;
