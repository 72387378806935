import ReactDOM from "react-dom";
import { isElementFullDisplayedInContainer } from "lib/isElementDisplayedInContainer";
import findOptionIndex from "lib/shared/form/select/findOptionIndex";

function scrollToOption({
  selectOptionRef,
  selectPopupRef,
  direction,
  selectedOption,
  options,
}) {
  if (selectOptionRef && selectPopupRef) {
    const selectPopupDOMNode = getDOMNode(selectPopupRef);
    const selectOptionDOMNode = getDOMNode(selectOptionRef);
    const isSelectedOptionFullDisplayed = isElementFullDisplayedInContainer(
      selectOptionDOMNode,
      selectPopupDOMNode,
    );

    if (!isSelectedOptionFullDisplayed) {
      const directions = { up: -1, down: 1 };
      const indexItem = findOptionIndex(selectedOption, options);
      const shouldScrollOneStep =
        !!directions[direction] &&
        !!indexItem &&
        indexItem !== 0 &&
        indexItem !== options.length - 1;
      const scrollPositionMovedOneStep = shouldScrollOneStep
        ? selectPopupDOMNode.scrollTop +
          selectOptionDOMNode.clientHeight * directions[direction]
        : 0;
      const newScrollPosition = shouldScrollOneStep
        ? scrollPositionMovedOneStep
        : selectOptionDOMNode.offsetTop;
      scrollDOMNodeTo(selectPopupDOMNode, newScrollPosition);
    }
  }
}

function getDOMNode(ref) {
  return ReactDOM.findDOMNode(ref);
}

function scrollDOMNodeTo(DOMNode, offsetTop) {
  if (DOMNode) {
    DOMNode.scrollTop = offsetTop;
  }
}

export default scrollToOption;
