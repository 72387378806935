import React, { Fragment, useCallback } from "react";
import PropTypes from "prop-types";
import { dealProptype } from "PropTypes";
import {
  SortedTable,
  SortableTableHeaderCell,
  TableCell,
  TableHeader,
  TableRow,
  TableBody,
} from "components_new/elements/Table";
import Value from "components_new/atoms/Value";
import EllipsisDropdown from "components_new/elements/EllipsisDropdown";
import CompanyInfo from "components_new/elements/CompanyInfo";
import Link from "components_new/atoms/Link";
import Text from "components_new/atoms/Text";
import CompanyLinkValue from "components_new/views/Company/shared/CompanyLinkValue";
import { isEmpty } from "lodash";
import { WEBSITE_PREFILL } from "components_new/lib/miscellaneous";

const COLUMN_HEADERS = [
  {
    className: "CompanyNameColumn",
    value: "company_name",
    title: "Company",
    type: "string",
    skeletonType: "companyName",
  },
  {
    className: "DealNameColumn",
    value: "name",
    title: "Deal Name",
    type: "string",
    skeletonType: "date_single_line",
  },
  {
    className: "OwnerColumn",
    value: "owner_name",
    title: "Owner",
    type: "string",
    skeletonType: "date_single_line",
  },
  {
    className: "DateColumn u-textAlignRight",
    value: "decision_date",
    title: "Date passed",
    type: "date",
    skeletonType: "date_single_line",
  },
  {
    className: "TagsColumn u-textAlignRight",
    value: "tags",
    title: "Tags",
    type: "array",
    skeletonType: "tags",
  },
  {
    className: "ReasonColumn u-textAlignRight",
    value: "reason_passed",
    title: "Reason",
    type: "string",
    skeletonType: "cost_basis",
  },
  {
    className: "ActionColumn u-textAlignRight",
    value: "",
    title: "",
    type: "string",
    skeletonType: "status",
  },
];

const renderHeaders = (handleSort, isSorted) => {
  return (
    <TableHeader>
      <TableRow>
        {COLUMN_HEADERS.map((columnHeader, index) => {
          return (
            <SortableTableHeaderCell
              key={index}
              className={columnHeader.className}
              isSorted={isSorted}
              handleSort={handleSort}
              {...columnHeader}
            >
              {columnHeader.title}
            </SortableTableHeaderCell>
          );
        })}
      </TableRow>
    </TableHeader>
  );
};

function PassedDealsTable({
  organizationUsername,
  passedDeals,
  isLoading,
  openReactivateDealModal,
  openDeleteDealModal,
  openViewDealSidebar,
  openEditDealSidebar,
  openEditPassDealSidebar,
  selectedDealId,
}) {
  const buildCompanyUrl = (companyId) =>
    `/${organizationUsername}/companies/${companyId}`;

  const passedDealsActions = useCallback(
    (deal) => [
      {
        key: "0",
        text: "Undo Pass",
        onClick: () => openReactivateDealModal(deal.id),
      },
      {
        key: "1",
        text: "Edit Pass",
        onClick: () => openEditPassDealSidebar(deal.id),
      },
      {
        key: "2",
        text: "View Deal",
        onClick: () => openViewDealSidebar(deal),
      },
      {
        key: "3",
        text: "Edit Deal",
        onClick: () => openEditDealSidebar(deal.id),
      },
      {
        key: "4",
        text: "Delete Deal",
        onClick: () => openDeleteDealModal(deal.id),
      },
    ],
    [
      openReactivateDealModal,
      openViewDealSidebar,
      openEditDealSidebar,
      openDeleteDealModal,
      openEditPassDealSidebar,
    ],
  );

  return (
    <SortedTable
      tableData={passedDeals}
      className="PassedDealsTable"
      isLoading={isLoading}
      renderHeaders={renderHeaders}
      singleLine
      skeletonStructure={{ rows: 5, headers: COLUMN_HEADERS }}
      shadow={false}
    >
      {({ data }) => (
        <TableBody isEmptyData={isEmpty(data)}>
          {data &&
            data.map((deal) => (
              <TableRow key={deal.id} active={selectedDealId === deal.id}>
                <TableCell className="CompanyNameColumn">
                  <CompanyInfo
                    title={(company) => (
                      <Link
                        extraClassnames="CompanyName"
                        title={company.name}
                        to={buildCompanyUrl(company.id)}
                      />
                    )}
                    content={(company) => (
                      <Fragment>
                        {company.website && (
                          <CompanyLinkValue
                            url={company.website}
                            preFilled={WEBSITE_PREFILL}
                          />
                        )}
                        {company.headquarters && (
                          <Value extraClassnames="CompanyLocation">
                            {company.headquarters}
                          </Value>
                        )}
                      </Fragment>
                    )}
                    company={deal.company}
                  ></CompanyInfo>
                </TableCell>
                <TableCell className="DealNameColumn TextColumn">
                  <Text
                    type="body-1-bold"
                    link
                    onClick={() => openViewDealSidebar(deal, deal.company.name)}
                  >
                    {deal.name}
                  </Text>
                </TableCell>
                <TableCell
                  className="OwnerColumn TextColumn"
                  data-testid="ownerName"
                >
                  <Value type="text" light>
                    {deal.owner_name}
                  </Value>
                </TableCell>
                <TableCell className="DateColumn  u-textAlignRight">
                  <Value type="date" dateFormat="default" light>
                    {deal.decision_date}
                  </Value>
                </TableCell>
                <TableCell className="TagsColumn u-textAlignRight">
                  <Value type="tag">{deal.company.tags}</Value>
                </TableCell>
                <TableCell className="ReasonColumn u-textAlignRight">
                  <Value type="text" light>
                    {deal.reason_passed}
                  </Value>
                </TableCell>
                <TableCell className="ActionColumn u-textAlignRight">
                  <EllipsisDropdown
                    iconOrientation="vertical"
                    iconSize="small"
                    options={passedDealsActions(deal)}
                    align="left"
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      )}
    </SortedTable>
  );
}

PassedDealsTable.propTypes = {
  isLoading: PropTypes.bool,
  passedDeals: PropTypes.arrayOf(dealProptype),
  organizationUsername: PropTypes.string.isRequired,
  openReactivateDealModal: PropTypes.func.isRequired,
  openDeleteDealModal: PropTypes.func,
  openViewDealSidebar: PropTypes.func,
  openEditDealSidebar: PropTypes.func,
  openEditPassDealSidebar: PropTypes.func,
  selectedDealId: PropTypes.string,
};

export default PassedDealsTable;
