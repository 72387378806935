import React from "react";
import PropTypes from "prop-types";
import Icon from "components_new/atoms/Icon";
import MightyIcon from "components_new/atoms/MightyIcon";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Tooltip from "components_new/atoms/Tooltip";
import "./SidebarList.scss";

function SidebarList({ isExpanded, list }) {
  const sidebarListClassnames = classNames("SidebarList", {
    isExpanded: isExpanded,
  });

  return (
    <ul className={sidebarListClassnames}>
      {list.map((item, key) => {
        const sidebarListItem = classNames("SidebarList-item", {
          "is-active": item.isActive,
          "is-disabled": item.isDisabled,
          "has-divided": item.hasDivided,
        });
        return (
          <Tooltip
            key={key}
            size="small"
            disabled={isExpanded}
            position="right center"
            trigger={
              <li className={sidebarListItem}>
                <Link to={item.linkTo} className="SidebarList-link">
                  {item.isMightyIcon && <MightyIcon icon={item.iconName} />}
                  {!item.isMightyIcon && (
                    <Icon icon={item.iconName} size="large" />
                  )}
                  {item.isBeta && (
                    <Icon icon="icon-beta" size="mini" origin="custom" />
                  )}
                  <span className="name">{item.name}</span>
                </Link>
              </li>
            }
            content={item.name}
            basic={false}
            extraClassnames="SidebarList-Tooltip"
          />
        );
      })}
    </ul>
  );
}

SidebarList.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      isActive: PropTypes.bool.isRequired,
      isDisabled: PropTypes.bool.isRequired,
      linkTo: PropTypes.string,
      iconName: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      hasDivided: PropTypes.bool,
      notifications: PropTypes.number,
      isBeta: PropTypes.bool,
    }),
  ),
};

export default SidebarList;
