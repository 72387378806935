import React from "react";
import EmptyPage from "components_new/elements/EmptyPage";
import Button from "components_new/atoms/Button";
import MightyIcon from "components_new/atoms/MightyIcon";
import Icon from "components_new/atoms/Icon";

function EmptyCustomFieldView({ showNewCustomFieldForm, isOwner }) {
  return (
    <EmptyPage
      headline="You haven’t created any custom holding fields"
      renderIcon={() => (
        <MightyIcon className="EmptyPage-emptyIcon" icon="note-xlrg" />
      )}
      subHeadline="Create custom fields to track data related to your holdings."
      clickToAction={() => {
        return (
          <Button
            onClick={() => showNewCustomFieldForm()}
            primary
            size="medium"
            disabled={!isOwner}
            iconPosition="left"
          >
            <Icon icon="ri-add-line" />
            New Custom Fields
          </Button>
        );
      }}
      customView="light"
    />
  );
}

export default EmptyCustomFieldView;
