import React, { Fragment } from "react";
import Icon from "components_new/atoms/Icon";
import PropTypes from "prop-types";
import { organizationProptype } from "PropTypes";
import Dropdown from "components_new/atoms/Dropdown";
import OrganizationItem from "./OrganizationItem";
import "./OrganizationMenu.scss";

const DROPDOWN_SELECTOR = "OrganizationMenu-dropdown";

function OrganizationMenu({
  currentOrganization,
  organizationsList,
  privateOrganizationUsername,
  isCurrentUserOrganizationOwner,
  goToCreateOrganizationSidebar,
  onOrgItemClick,
}) {
  const availableOrganizations = organizationsList.filter(
    (item) => item.username !== currentOrganization.username,
  );

  return (
    <div className="OrganizationMenu">
      <Dropdown
        inline
        icon={
          <Icon
            extraClassnames="ri-arrow-down-s-line"
            origin="remixIcon"
            size="large"
          />
        }
        className={DROPDOWN_SELECTOR}
        text={currentOrganization.name}
      >
        <div className="menu transition">
          <div className="OrganizationMenu-content">
            <OrganizationItem
              extraClassnames="OrganizationMenu-currentOrganization"
              isCurrentOrganization={true}
              isCurrentUserOrganizationOwner={isCurrentUserOrganizationOwner}
              organization={currentOrganization}
              privateOrganizationUsername={privateOrganizationUsername}
              onOrgItemClick={onOrgItemClick}
            />

            <div className="divider"></div>

            {availableOrganizations.map((organization, index) => (
              <Fragment key={index}>
                <OrganizationItem
                  extraClassnames="OrganizationMenu-item"
                  organization={organization}
                  isCurrentUserOrganizationOwner={
                    isCurrentUserOrganizationOwner
                  }
                  privateOrganizationUsername={privateOrganizationUsername}
                  onOrgItemClick={onOrgItemClick}
                />
                <div className="divider"></div>
              </Fragment>
            ))}

            <div
              role="option"
              className="item OrganizationMenu-newOrganization"
              onClick={goToCreateOrganizationSidebar}
            >
              <Icon icon="ri-add-line" size="large" />
              <div> New organization</div>
            </div>
          </div>
        </div>
      </Dropdown>
    </div>
  );
}

OrganizationItem.propTypes = {
  organizationsList: PropTypes.arrayOf(organizationProptype),
  goToCreateOrganizationSidebar: PropTypes.func,
};

export default OrganizationMenu;
