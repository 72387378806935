const FIRM_TYPE_LIST = ["VC/Investment Firm", "Service Firm", "Partner"];
const INVESTMENT_DOMAIN_LIST = [
  "VC - Incubator/Accelerator",
  "VC - Seed",
  "VC - Series A-D",
  "VC - Late Stage",
  "VC - Private Equity",
  "VC - Venture Debt",
  "VC - Strategic",
  "VC - Social Responsibility",
  "Hedge Fund",
  "Investment Bank",
];
const SERVICE_DOMAIN_LIST = [
  "Accounting",
  "Branding / Marketing / Press / PR",
  "Business Strategy",
  "Consulting",
  "Corporate / Strategic Partner",
  "Design/Development",
  "HR / Recruiting",
  "Legal",
  "Non-profit",
  "Political Consultant",
  "Real Estate",
  "Strategy",
  "UI / Design / Web Design",
  "Other",
];

export const FIRM_TYPE = FIRM_TYPE_LIST.map((type) => {
  return { key: type, text: type, value: type };
});

export const INVESTMENT_DOMAIN = INVESTMENT_DOMAIN_LIST.map((domain) => {
  return { key: domain, text: domain, value: domain };
});

export const SERVICE_DOMAIN = SERVICE_DOMAIN_LIST.map((domain) => {
  return { key: domain, text: domain, value: domain };
});
