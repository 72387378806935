import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { stageProptype, dealProptype } from "PropTypes";
import PropTypes from "prop-types";
import SectionList from "components_new/elements/SectionList";
import ActiveDealCard from "../ActiveDealCard";
import StageSectionHeader from "../StageSectionHeader";
import "./StagesSection.scss";

function StagesSection({
  stages,
  deals,
  selectedDealId,
  organizationUsername,
  onClickAddDeal,
  isEmptyResult,
}) {
  const navigate = useNavigate();
  const sectionListData = useMemo(() => {
    return stages.map((stage) => {
      const sectionDeals = deals
        .filter((deal) => deal.stage_id === stage.id)
        .sort((a, b) => new Date(a.staged_at) - new Date(b.staged_at));
      return {
        title: stage.name,
        color: stage.color,
        data: sectionDeals,
      };
    });
  }, [stages, deals]);

  const handleDealCardClick = (dealId) =>
    navigate(`/${organizationUsername}/deals/active/${dealId}`);

  if (isEmptyResult) {
    return (
      <div className="ActiveDeals-Stages">
        <div className="EmptyMessage">
          <label>0 results matching your filters</label>
        </div>
      </div>
    );
  }

  return (
    <div className="ActiveDeals-Stages">
      <SectionList
        sections={sectionListData}
        accordion
        collapseIfEmpty
        showCount
        renderEmptySection={(title) => {
          const description = `0 deals in the ${title} stage. Update an existing deal or `;
          return (
            <div className="ActiveDeals-Stages-EmptySection">
              {description}
              <a onClick={onClickAddDeal} className="AddDealLink">
                add a new deal.
              </a>
            </div>
          );
        }}
        renderSectionHeader={(
          title,
          section,
          accordion,
          accordionOpen,
          toggleAccordion,
        ) => (
          <StageSectionHeader
            title={title}
            section={section}
            accordion={accordion}
            accordionOpen={accordionOpen}
            toggleAccordion={toggleAccordion}
          />
        )}
        renderItem={(item) => {
          return (
            <ActiveDealCard
              key={`DealCard-${item.id}`}
              onClick={handleDealCardClick}
              deal={item}
              selectedDealId={selectedDealId}
            />
          );
        }}
      />
    </div>
  );
}

StagesSection.propTypes = {
  stages: PropTypes.arrayOf(stageProptype),
  deals: PropTypes.arrayOf(dealProptype),
  onClickAddDeal: PropTypes.func,
  selectedDealId: PropTypes.string,
  organizationUsername: PropTypes.string,
  isEmptyResult: PropTypes.bool,
};

export default StagesSection;
