import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import {
  createIndustry as createIndustryHelper,
  filterIndustry as filterIndustryHelper,
} from "./actions";
import { getCollection } from "store/domain/selectors";

//TODO: move actions to react-query
export default () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const industrySuggestions = getCollection(state, "industries");

  const createIndustry = (industryName) =>
    dispatch(createIndustryHelper(industryName));

  const filterIndustries = debounce((contactTitle) => {
    return dispatch(filterIndustryHelper(contactTitle));
  }, 300);

  return {
    createIndustry,
    filterIndustries,
    industrySuggestions,
  };
};
