import {
  RECEIVE_PORTFOLIO_PROFILE,
  EXPAND_INVESTMENT_ROWS,
  COLLAPSE_INVESTMENT_ROWS,
  CLEAN_PORTFOLIO_PROFILE,
  TOGGLE_INVESTMENT_ROW,
} from "./actions";

const initialState = {
  id: null,
  portfolioData: {
    invested: null,
    payout: null,
    investmentIds: [],
    companies: {},
    investments: {},
    documents: {},
  },
  allRowsExpanded: false,
  allRowsCollapsed: false,
  displayInvestments: {},
  isSubmitting: false,
};

function displayInvestments(state = {}, action) {
  if (action.companyId) {
    switch (action.type) {
      case EXPAND_INVESTMENT_ROWS:
        return Object.assign({}, state, {
          [action.companyId]: true,
        });
      case COLLAPSE_INVESTMENT_ROWS:
        return Object.assign({}, state, {
          [action.companyId]: false,
        });
      default:
        return state;
    }
  } else {
    return {};
  }
}

function allRowsExpanded(state = false, action) {
  if (!action.companyId) {
    switch (action.type) {
      case EXPAND_INVESTMENT_ROWS:
        return true;
      case TOGGLE_INVESTMENT_ROW:
      case COLLAPSE_INVESTMENT_ROWS:
        return false;
      default:
        return state;
    }
  } else {
    return state;
  }
}

function allRowsCollapsed(state = false, action) {
  if (!action.companyId) {
    switch (action.type) {
      case TOGGLE_INVESTMENT_ROW:
      case EXPAND_INVESTMENT_ROWS:
        return false;
      case COLLAPSE_INVESTMENT_ROWS:
        return true;
      default:
        return state;
    }
  } else {
    return state;
  }
}

function portfolioView(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_PORTFOLIO_PROFILE: {
      const { portfolioView } = action;
      return {
        ...state,
        id: portfolioView.id,
      };
    }
    case EXPAND_INVESTMENT_ROWS:
    case COLLAPSE_INVESTMENT_ROWS:
    case TOGGLE_INVESTMENT_ROW:
      return {
        ...state,
        allRowsExpanded: allRowsExpanded(state.allRowsExpanded, action),
        allRowsCollapsed: allRowsCollapsed(state.allRowsCollapsed, action),
        displayInvestments: displayInvestments(
          state.displayInvestments,
          action,
        ),
      };
    case CLEAN_PORTFOLIO_PROFILE:
      return initialState;
    default:
      return state;
  }
}

export default portfolioView;
