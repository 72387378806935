import React from "react";
import CommittedDealsTable from "./CommittedDealsTable";
import CommittedDealsTopBar from "./CommittedDealsTopBar";
import Segment from "components_new/atoms/Segment";
import EmptyDeals from "components_new/views/Deals/EmptyDeals";
import { matchPath, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { dealProptype } from "PropTypes";
import "./CommittedDeals.scss";

function getSelectedDealId(matchPath, pathname) {
  let selectedDealId = null;

  const match = matchPath(
    { path: ":organizationId/deals/committed/:id" },
    pathname,
  );

  if (match) {
    selectedDealId = match.params.id;
  }

  return selectedDealId;
}

function CommittedDeals({
  isLoading,
  hasSearchOrFilter,
  committedDeals,
  committedDealsCount,
  fetchCommittedDeals,
  organizationUsername,
  openReactivateDealModal,
  openDeleteDealModal,
  openViewDealSidebar,
  openEditDealSidebar,
  onConvertToInvesment,
  openEditCommitmentModal,
}) {
  const location = useLocation();
  const selectedDealId = getSelectedDealId(matchPath, location.pathname);

  if (!isLoading && !hasSearchOrFilter && committedDeals.length === 0) {
    return <EmptyDeals dealType="committed" />;
  }

  return (
    <Segment extraClassNames="CommittedDeals" shadow>
      <CommittedDealsTopBar
        isLoading={isLoading}
        dealsCount={committedDealsCount}
        fetchCommittedDeals={fetchCommittedDeals}
      />
      <CommittedDealsTable
        isLoading={isLoading}
        committedDeals={committedDeals}
        organizationUsername={organizationUsername}
        openReactivateDealModal={openReactivateDealModal}
        openDeleteDealModal={openDeleteDealModal}
        openViewDealSidebar={openViewDealSidebar}
        openEditDealSidebar={openEditDealSidebar}
        onConvertToInvesment={onConvertToInvesment}
        openEditCommitmentModal={openEditCommitmentModal}
        selectedDealId={selectedDealId}
      />
    </Segment>
  );
}

CommittedDeals.propTypes = {
  isLoading: PropTypes.bool,
  hasSearchOrFilter: PropTypes.bool,
  committedDeals: PropTypes.arrayOf(dealProptype),
  committedDealsCount: PropTypes.number,
  fetchCommittedDeals: PropTypes.func.isRequired,
  organizationUsername: PropTypes.string,
  openReactivateDealModal: PropTypes.func,
  openDeleteDealModal: PropTypes.func.isRequired,
  openViewDealSidebar: PropTypes.func.isRequired,
  openEditDealSidebar: PropTypes.func.isRequired,
  onConvertToInvesment: PropTypes.func.isRequired,
  openEditCommitmentModal: PropTypes.func.isRequired,
};

export default CommittedDeals;
