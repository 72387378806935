import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import TableHeaderCell from "./TableHeaderCell";

const columnValue = (value, grouped_value, isGrouped) =>
  isGrouped && grouped_value ? grouped_value : value;

export default function SortableTableHeaderCell({
  children,
  value,
  groupedValue,
  type,
  groupedValueType,
  isGrouped,
  isSorted,
  handleSort,
  sortOrder,
  ...props
}) {
  const columnValueName = useMemo(
    () => columnValue(value, groupedValue, isGrouped),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const columnType = useMemo(
    () => columnValue(type, groupedValueType, isGrouped),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onClick = useCallback(
    () => handleSort(columnValueName, sortOrder, columnType),
    [handleSort], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <TableHeaderCell
      onClick={onClick}
      sorted={isSorted(columnValueName)}
      {...props}
    >
      <span className="SortableHeader">{children}</span>
    </TableHeaderCell>
  );
}

SortableTableHeaderCell.propTypes = {
  isGrouped: PropTypes.bool,
  isSorted: PropTypes.func.isRequired,
  handleSort: PropTypes.func.isRequired,
  sortOrder: PropTypes.string,
  value: PropTypes.string,
  groupedValue: PropTypes.string,
  groupedValueType: PropTypes.string,
  type: PropTypes.string.isRequired,
  children: PropTypes.node,
};
