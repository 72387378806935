import React from "react";
import formatDate from "lib/formatDate";
import Link from "components_new/atoms/Link";
import AttachmentSingleValue from "components/shared/AttachmentSingleValue";
import BasicLabel from "components_new/atoms/BasicLabel";
import { useMarked } from "components_new/lib/useMarked";
import { isEmpty } from "lodash";

function ShowPostFrom({ name, url }) {
  return (
    <span className="u-metaContentLight">
      added a post to{" "}
      <Link className="Link Link--underline" to={url}>
        {name}
      </Link>{" "}
    </span>
  );
}

function Breadcrumb({ items }) {
  return (
    <nav aria-label="breadcrumb" className="Post-Breadcrumb">
      <ol className="Breadcrumb">
        {items.map((item, index) => {
          return (
            <li key={index} extraClassnames="Breadcrumb-item">
              <Link key={index} to={item.url}>
                {item.name}
              </Link>
            </li>
          );
        })}
      </ol>
    </nav>
  );
}

const shouldShowDiligenceInfo = (post, showDiligenceContact) => {
  return (
    post.note_type === "diligence" &&
    !isEmpty(post.diligence_contact) &&
    showDiligenceContact
  );
};

function ViewPost({
  user,
  post,
  organizationUsername,
  showResource,
  showDiligenceContact,
  openContactDetailsView,
}) {
  const { content, documents_data, edited: isEdited, resource } = post;
  const html = useMarked(content || "");
  const defineResourcePathsByType = !isEmpty(resource) && {
    Investment: {
      child: `/${organizationUsername}/investments/${resource.breadcrumb.child_id}`,
      parent: `/${organizationUsername}/portfolios/${resource.breadcrumb.parent_id}/investments`,
      root: `/${organizationUsername}/companies/${resource.breadcrumb.root_id}`,
    },
    Deal: {
      child: `/${organizationUsername}/deals/active/${resource.breadcrumb.child_id}`,
      parent: `/${organizationUsername}/pipelines/active`,
      root: `/${organizationUsername}/companies/${resource.breadcrumb.root_id}`,
    },
  };

  const showDiligenceContactInfo = shouldShowDiligenceInfo(
    post,
    showDiligenceContact,
  );

  return (
    <article className="PostSection-content">
      <header className="PostSection-contentHeader">
        {showDiligenceContactInfo ? (
          <h3
            className="u-metaContentBlack clickable"
            onClick={() => openContactDetailsView(post.diligence_contact)}
          >
            {post.diligence_contact.full_name}
          </h3>
        ) : (
          <h3 className="u-metaContentBlack">{user.full_name}</h3>
        )}

        {showResource && (
          <ShowPostFrom
            name={resource.breadcrumb.child_name}
            url={defineResourcePathsByType[resource.type].child}
          />
        )}
        <span className="u-metaContentLight">
          {formatDate(post.created_at, "ll")} {isEdited && " (edited) "}
        </span>
        {post.note_type && (
          <BasicLabel type="generic" size="small">
            {post.note_type}
          </BasicLabel>
        )}
      </header>

      {showDiligenceContactInfo && (
        <p className="OriginalAuthor">
          added by <strong> {user.full_name} </strong>
        </p>
      )}

      <div dangerouslySetInnerHTML={{ __html: html }} />
      {documents_data && (
        <div className="PostSection-attachments">
          {documents_data.map((doc, index) => (
            <AttachmentSingleValue
              key={index}
              currentOrganizationUsername={organizationUsername}
              attachment={doc}
            />
          ))}
        </div>
      )}
      {showResource && (
        <Breadcrumb
          items={[
            {
              name: resource.breadcrumb.root_name,
              url: defineResourcePathsByType[resource.type].root,
            },
            {
              name: resource.breadcrumb.parent_name,
              url: defineResourcePathsByType[resource.type].parent,
            },
            {
              name: resource.breadcrumb.child_name,
              url: defineResourcePathsByType[resource.type].child,
            },
          ]}
        />
      )}
    </article>
  );
}

export default ViewPost;
