import React from "react";
import Dropdown from "components_new/atoms/Dropdown";
import ColumnedValueList from "components_new/atoms/ColumnedValueList";
import formatDate from "lib/formatDate";
import Icon from "components_new/atoms/Icon";
import Text from "components_new/atoms/Text";

function PassedDealHeader({
  deal,
  openEditDealForm,
  openDeleteDealModal,
  openReactivateDealModal,
  openEditPassModal,
  closeSidebar,
}) {
  const columnData = [
    { label: "REASON", value: deal.reason_passed },
    {
      label: "PASSED",
      value: formatDate(deal.decision_date, "semiDetailed"),
    },
  ];

  const actionsOptions = [
    {
      key: 1,
      text: "Edit Pass",
      value: 1,
      onClick: () => openEditPassModal(deal.id),
    },
    {
      key: 2,
      text: "Undo Pass",
      value: 2,
      onClick: () => openReactivateDealModal(deal.id),
    },
    {
      key: 2,
      text: "Edit Deal",
      value: 3,
      onClick: () => openEditDealForm(deal.id),
    },
    {
      key: 2,
      text: "Delete Deal",
      value: 4,
      onClick: () => openDeleteDealModal(deal.id),
    },
  ];

  return (
    <div className="PassedDealHeader">
      <div className="PassedDealActions">
        <Dropdown text="Actions" size="small" button options={actionsOptions} />
        <Icon
          icon="ri-close-line"
          size="large"
          extraClassnames="Sidebar-close"
          onClick={closeSidebar}
        />
      </div>
      <div className="PassedDealInformation">
        <Text as="h4" type="subtitle-2-bold">
          Pass Details
        </Text>
        <ColumnedValueList
          data={columnData}
          ellipsize={false}
          extraClassname="PassedDealInformation-ColumnedValueList"
        />
      </div>
    </div>
  );
}

export default PassedDealHeader;
