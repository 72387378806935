import { useMutation, useQueryClient } from "react-query";
import Api from "lib/Api";
import useOrganizations from "store/organizations/hooks";
import { buildPostResourceRoute } from "lib/globalActionsHelper";
import updatePost from "lib/posts/updatePost";

export function useUpdateCompanyPost() {
  const queryClient = useQueryClient();
  const {
    currentOrganization: { username: currentOrganizationUsername },
  } = useOrganizations();

  return useMutation(
    ({ resource, postId, formData }) => {
      const postResourceRoute = buildPostResourceRoute(resource);
      return Api.patch(`${postResourceRoute}/posts/${postId}`, {
        post: formData,
      });
    },
    {
      onSuccess: ({ data: updatedPost }) => {
        const companyId = updatedPost.resource.company_id;
        const key = ["Company", currentOrganizationUsername, companyId];
        const companyQuery = queryClient.getQueryData(key);

        queryClient.setQueryData(key, {
          ...companyQuery,
          companyPosts: updatePost(companyQuery.companyPosts, updatedPost),
        });
      },
      onError: (error) => {
        throw error;
      },
    },
  );
}
