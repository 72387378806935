import React, { Fragment } from "react";
import FormGroup from "components_new/elements/FormGroup";
import DropdownForm from "components_new/atoms/DropdownForm";
import SearchAndSelect from "components/shared/form/SearchAndSelect";
import Input from "components_new/atoms/Input";
import PreFilledInput from "components_new/atoms/PreFilledInput";
import Tags from "components/shared/Tags";
import CompanyAliases from "components/shared/CompanyAliases";
import TextArea from "components_new/atoms/TextArea";
import DatePicker from "components_new/atoms/DatePicker";
import Industry from "components/shared/Industry";
import { buildPrefilledCompanyData } from "lib/globalActionsHelper";
import PropTypes from "prop-types";
import { tierDropdown } from "config/tierConfig";
import FlagsCheckboxGroup from "./FlagsCheckboxGroup";
import FirmType from "./FirmType";
import {
  companyPropType,
  errorsPropType,
  tagSuggestionsProptype,
  tagProptype,
} from "PropTypes";
import {
  STATUS_OPTIONS,
  WEBSITE_PREFILL,
  EMPLOYEES_COUNT_OPTIONS,
  ACTIVITY_STATUS_OPTIONS,
} from "./config";
import "./CompanyBasicDetails.scss";
import InterestingReason from "./InterestingReason";
import YcClass from "./YcClass";

function CompanyBasicDetails({
  formData,
  handleChange,
  errors,
  acquirerCompany,
  isVerifiedCompany,
  primarySectors,
  prepopulatedCompanyTags,
  prepopulatedSectors,
}) {
  const {
    name,
    status,
    status_change_date,
    description,
    website,
    headquarters,
    founders,
    tags,
    year_founded,
    employees_range,
    aliases,
    interesting,
    interesting_reason,
    double_down,
    stealth,
    is_yc,
    yc_class,
    activity_status,
    tier,
    industries,
    firm_type,
    investment_domain,
    service_domain,
    primary_sector,
  } = formData;

  const prefilledCompanyValue = buildPrefilledCompanyData(acquirerCompany);

  return (
    <FormGroup
      extraClassnames="CompanyBasicDetailsGroup"
      title="Basic Details *"
      isExpanded
    >
      <Input
        name="name"
        extraClassnames="CompanySelected"
        label="Company Name*"
        onChange={(_, { value }) => handleChange("name", value)}
        value={name}
        error={!!errors.for("name")}
        errorMessage={errors.for("name")}
        disabled={isVerifiedCompany}
        size="tiny"
      />
      <DropdownForm
        name="status"
        label="Status*"
        aria-label="Status*"
        placeholder="Select status"
        items={STATUS_OPTIONS}
        onChange={(_, { value }) => handleChange("status", value)}
        value={status}
        size="tiny"
      />
      {status === "Acquired" && (
        <Fragment>
          <SearchAndSelect
            name="status_change_company_id"
            label="Acquired by"
            placeHolder="Search a company"
            scope="all_companies"
            onChange={(_, value) =>
              handleChange("status_change_company_id", value.id)
            }
            prefilledValue={prefilledCompanyValue}
            size="tiny"
          />

          <DatePicker
            name="status_change_date"
            label="Acquisition date"
            value={status_change_date}
            onChange={(value) => handleChange("status_change_date", value)}
            prefillToday={true}
            size="tiny"
          />
        </Fragment>
      )}
      <TextArea
        name="description"
        label="Description"
        aria-label="Description"
        value={description}
        onChange={(_, { value }) => {
          handleChange("description", value);
        }}
        rows={null}
        disabled={isVerifiedCompany}
        size="tiny"
      />
      <PreFilledInput
        name="website"
        label="Website"
        value={website}
        preFilled={WEBSITE_PREFILL}
        onChange={(value) => handleChange("website", value)}
        readOnly={isVerifiedCompany}
        disabled={isVerifiedCompany}
        size="tiny"
      />
      <Input
        name="headquarters"
        label="Headquarters"
        aria-label="Headquarters"
        value={headquarters}
        onChange={(_, { value }) => handleChange("headquarters", value)}
        disabled={isVerifiedCompany}
        size="tiny"
      />
      <DropdownForm
        fluid
        label="Primary Sector"
        name="primary_sector"
        placeholder="Select a primary sector"
        value={primary_sector}
        items={primarySectors}
        onChange={(_, { value }) => handleChange("primary_sector", value, 1)}
        extraClassnames="FormElement"
        size="tiny"
      />
      <Industry
        label="Sectors"
        createTagLabel="Create new sector"
        placeholder="Search sectors"
        name="industries"
        taggedList={industries || []}
        onChange={(value) => handleChange("industries", value)}
        size="tiny"
        errorMessage={errors.for("industries")}
        extraClassnames="SectorTags"
        prepopulatedItems={prepopulatedSectors}
      />
      <Input
        name="founders"
        label="Founders"
        onChange={(_, { value }) => handleChange("founders", value)}
        value={founders}
        error={!!errors.for("founders")}
        errorMessage={errors.for("founders")}
        size="tiny"
      />
      <Tags
        tagType="company"
        extraClassnames="CompanyTags"
        name="tags"
        label="Company Tags"
        size="tiny"
        onChange={(value) => handleChange("tags", value)}
        taggedList={tags}
        prepopulatedItems={prepopulatedCompanyTags}
      />
      <FlagsCheckboxGroup
        interesting={interesting}
        double_down={double_down}
        stealth={stealth}
        yc={is_yc}
        handleChange={handleChange}
        size="tiny"
      />
      {interesting && (
        <InterestingReason
          interestingReason={interesting_reason}
          handleChange={handleChange}
          size="tiny"
        />
      )}
      {is_yc && (
        <YcClass ycClass={yc_class} handleChange={handleChange} size="tiny" />
      )}
      <Input
        name="year_founded"
        label="Year Founded"
        value={year_founded}
        onChange={(_, { value }) => {
          handleChange("year_founded", value);
        }}
        disabled={isVerifiedCompany}
        size="tiny"
      />
      <DropdownForm
        name="employees_range"
        label="Number of employees"
        aria-label="Number of employees"
        placeholder="Select range"
        items={EMPLOYEES_COUNT_OPTIONS}
        onChange={(_, { value }) => handleChange("employees_range", value)}
        value={employees_range}
        disabled={isVerifiedCompany}
        size="tiny"
      />
      <CompanyAliases
        name="aliases"
        extraClassnames="CompanyAliases"
        label="Aliases"
        size="tiny"
        onChange={(value) => handleChange("aliases", value)}
        taggedList={aliases}
      />
      <DropdownForm
        label="Activity Status"
        aria-label="Activity Status"
        placeholder="Select status"
        items={ACTIVITY_STATUS_OPTIONS}
        onChange={(_, { value }) => handleChange("activity_status", value)}
        value={activity_status}
        size="tiny"
      />
      <DropdownForm
        label="Tier"
        placeholder="Select a tier"
        items={tierDropdown}
        onChange={(e, { value }) => handleChange("tier", value)}
        value={tier}
        size="tiny"
      />
      <FirmType
        firmType={firm_type}
        investmentDomain={investment_domain}
        serviceDomain={service_domain}
        handleChange={handleChange}
        size="tiny"
      />
    </FormGroup>
  );
}

CompanyBasicDetails.protoTypes = {
  errors: errorsPropType.isRequired,
  formData: companyPropType.isRequired,
  handleChange: PropTypes.func.isRequired,
  acquirerCompany: companyPropType,
  isVerifiedCompany: PropTypes.bool.isRequired,
  filterCompanyTags: PropTypes.func,
  companyTagSuggestions: tagSuggestionsProptype,
  companyAliases: tagProptype,
};

export default CompanyBasicDetails;
