import React from "react";
import DataCard from "components_new/elements/DataCard";
import DealRow from "./DealRow";
import DealActionsDropdown from "./DealActionsDropdown";

export default function CompanyDeals({
  title = "Company Deals",
  testId = "activeDealCard",
  companyDeals,
  currentOrganizationUsername,
  showSidebar,
}) {
  const buildDealURL = (dealId) =>
    `/${currentOrganizationUsername}/deals/active/${dealId}`;
  return (
    <DataCard.Group title={title} data-testid={testId}>
      {companyDeals.map((deal) => {
        return (
          <DealRow
            key={deal.id}
            deal={deal}
            companyAvatar={deal.companyAvatar}
            dropdownComponent={DealActionsDropdown}
            link={buildDealURL(deal.pipeline_id, deal.id)}
            showSidebar={showSidebar}
          />
        );
      })}
    </DataCard.Group>
  );
}
