import React from "react";
import MarkIcon from "-!svg-react-loader?!assets/icons/rf-mark-icon.svg?name=MarkIcon";

const NotFound = () => (
  <div className="NotFound">
    <h1 className="u-heading">
      The page you&apos;re looking for doesn&apos;t exist.
    </h1>
    <h3 className="u-subheading NotFound-subheading">
      The link you followed may be broken, or the page may have been removed.
    </h3>
    <MarkIcon className="NotFound-markIcon" />
  </div>
);

export default NotFound;
