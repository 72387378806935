import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import "./BasicLabel.scss";

function BasicLabel({
  extraClassnames,
  size,
  upperCase,
  children,
  type,
  ...restProps
}) {
  const basicLabelClassname = classNames("BasicLabel", type, size, {
    [extraClassnames]: !!extraClassnames,
    styled: type !== "transparent",
    "upper-case": upperCase,
  });

  return (
    <label className={basicLabelClassname} {...restProps}>
      {children}
    </label>
  );
}

BasicLabel.propTypes = {
  extraClassnames: PropTypes.string,
  upperCase: PropTypes.bool,
  size: PropTypes.oneOf(["small", "regular", "large"]),
  type: PropTypes.oneOf([
    "transparent",
    "notice",
    "generic",
    "success",
    "warning",
    "primary",
  ]),
  children: PropTypes.node,
};

BasicLabel.defaultProps = {
  extraClassnames: null,
  size: "regular",
  type: "transparent",
  upperCase: false,
};

export default BasicLabel;
