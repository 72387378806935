import moment from "moment";
import { pick, isNumber } from "lodash";
import accounting from "accounting";

const today = moment().format("YYYY-MM-DD");

export const INITIAL_FORMDATA_VALUES = {
  formData: {
    company: {
      name: "",
      headquarters: "",
      tags: [],
      description: "",
      interesting: false,
      double_down: false,
      stealth: false,
    },
    contact_ids: [],
    contacts_attributes: [],
    portfolio_id: "",
    isNewPortfolio: false,
    custom_fields: {},
    type: "",
    date: today,
    per_share_or_total_amount: null,
    quantity: "",
    per_quantity_or_total_option: "per_quantity",
    equity_class: "",
    certificate_number: "",
    series_nickname: "",
    vesting_schedule: "",
    vesting_cliff: "",
    vesting_terms: "",
    vesting_start_date: today,
    exercise_price: "",
    vesting_expiration_date: today,
    fully_diluted_shares: "",
    shares_in_series: "",
    pre_money_valuation: "",
    amount_raised: "",
    documents: [],
    name: "",
    total_acquisition_amount: "",
    note_maturity_date: "",
    note_maturity_date_term: "",
    interest_rate: "",
    interest_schedule: "",
    interest_accrual_schedule: "",
    qualified_financing_amount: "",
    conversion_discount: "",
    conversion_cap: "",
    conversion_terms: "",
    conversion_price: "",
    conversion_ratio: "",
    notes: "",
    membership_units_or_percentage: "",
    is_safe: false,
    fund_size: "",
    fund_vintage: "",
  },
};

export const FIELDS_BY_INVESTMENT_TYPE = {
  equity: [
    "name",
    "date",
    "per_share_or_total_amount",
    "quantity",
    "equity_class",
    "certificate_number",
    "certificate_number",
    "series_nickname",
    "vesting_schedule",
    "vesting_terms",
    "exercise_price",
    "vesting_start_date",
    "vesting_expiration_date",
    "per_quantity_or_total_option",
  ],
  debt: [
    "name",
    "date",
    "total_acquisition_amount",
    "note_maturity_date_term",
    "note_maturity_date",
    "interest_rate",
    "interest_schedule",
    "interest_accrual_schedule",
    "qualified_financing_amount",
    "conversion_discount",
    "conversion_cap",
    "conversion_terms",
    "conversion_price",
    "conversion_ratio",
  ],
  fund: [
    "name",
    "date",
    "total_acquisition_amount",
    "fund_size",
    "fund_vintage",
  ],
  llc: [
    "name",
    "total_acquisition_amount",
    "date",
    "equity_class",
    "quantity",
    "vesting_schedule",
    "vesting_terms",
    "exercise_price",
    "vesting_start_date",
    "vesting_expiration_date",
  ],
  option: [
    "name",
    "date",
    "quantity",
    "total_acquisition_amount",
    "equity_class",
    "certificate_number",
    "certificate_number",
    "series_nickname",
    "vesting_schedule",
    "vesting_terms",
    "exercise_price",
    "vesting_start_date",
    "vesting_expiration_date",
  ],
  warrant: [
    "name",
    "date",
    "quantity",
    "total_acquisition_amount",
    "equity_class",
    "certificate_number",
    "certificate_number",
    "series_nickname",
    "vesting_schedule",
    "vesting_terms",
    "exercise_price",
    "vesting_start_date",
    "vesting_expiration_date",
  ],
  safe: [
    "name",
    "date",
    "total_acquisition_amount",
    "qualified_financing_amount",
    "conversion_discount",
    "conversion_cap",
    "conversion_terms",
    "conversion_price",
    "conversion_ratio",
  ],
};

export const verifyDetailHasChanged = (formData, investment_type) => {
  const fields = FIELDS_BY_INVESTMENT_TYPE[investment_type];
  const fieldValues = pick(formData, fields);
  const initialValues = getInitialValuesForType(investment_type);

  return Object.entries(fieldValues).some(([key, value]) => {
    const hasChanged =
      key === "name"
        ? value !== initialValues[key] && value !== "Convertible Note"
        : value !== initialValues[key];

    return hasChanged;
  });
};

export const getInitialValuesForType = (investment_type) => {
  const { formData: initialValuesObject } = INITIAL_FORMDATA_VALUES;
  const fields = FIELDS_BY_INVESTMENT_TYPE[investment_type];
  const initialValues = pick(initialValuesObject, fields);

  return initialValues;
};

export const ensureRequest = (clientData) => {
  const {
    type,
    per_quantity_or_total_option,
    per_share_or_total_amount,
    quantity,
  } = clientData;

  if (type === "safe") {
    return {
      ...clientData,
      type: "debt",
    };
  }

  if (type === "equity") {
    const parsedAmount = accounting.unformat(per_share_or_total_amount);
    const parsedQuantity = accounting.unformat(quantity);
    if (
      !isNumber(parsedAmount) ||
      parsedAmount <= 0 ||
      !isNumber(parsedQuantity)
    ) {
      return clientData;
    }
    if (per_quantity_or_total_option === "per_quantity" && parsedQuantity > 0) {
      return {
        ...clientData,
        total_acquisition_amount: parsedAmount * parsedQuantity,
      };
    }
    return {
      ...clientData,
      total_acquisition_amount: parsedAmount,
    };
  } else {
    return clientData;
  }
};
