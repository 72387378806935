import React, { useState, useEffect, useMemo } from "react";
import ContactsView from "components_new/views/Contacts/ContactsView";
import useOrganization from "store/organizations/hooks";
import { useLocation } from "react-router-dom";
import { useContacts } from "components_new/views/Contacts/hooks/queries/useContacts";
import { useSetSidebar } from "components_new/elements/SidebarWrapper/Sidebar-context";
import isEmptyValue from "components_new/lib/isEmptyValue";
import { isEmpty } from "lodash";

function useSearchQuery() {
  return new URLSearchParams(useLocation().search);
}

function ContactsViewWrapper(props) {
  const [filters, updateFilters] = useState({});
  const { currentOrganization } = useOrganization();
  const query = useSearchQuery();
  const contactId = query.get("contactId");
  const organizationUsername = currentOrganization.username;
  const { showSidebar } = useSetSidebar();
  const { data, isLoading } = useContacts(organizationUsername, filters);

  useEffect(() => {
    if (contactId && !isEmpty(data)) {
      showSidebar("contactsView", { contactId: contactId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId, data]);

  const isSearchActive = useMemo(() => {
    const isSearching = Object.values(filters).some(
      (value) => !isEmptyValue(value),
    );

    return isSearching;
  }, [filters]);

  return (
    <ContactsView
      organizationUsername={organizationUsername}
      isLoading={isLoading}
      isSearchActive={isSearchActive}
      updateFilters={updateFilters}
      filters={filters}
      contacts={data?.contacts}
      meta={data?.meta}
      showSidebar={showSidebar}
      {...props}
    />
  );
}

export default ContactsViewWrapper;
