import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import InvestmentView from "./InvestmentView";
import { resetForm } from "store/documents/actions";
import { fetchInvestmentDetailsV2 } from "store/investments/investment/actions";
import View from "components/shared/View";
import {
  getEntity,
  getOrderedCollection,
  getCollectionAsObject,
  getCollection,
} from "store/entities";
import { getEntityLoadingState } from "store/domain/selectors";

function InvestmentViewContainer(props) {
  return (
    <View
      mountAction={() => props.fetchInvestment(props.id)}
      component={InvestmentView}
      unmountAction={() => props.resetDocumentsForm()}
      attributeChangedAction={() => props.fetchInvestment(props.id)}
      attributeToVerify={props.id}
      showLoader={props.isFetching}
      {...props}
    />
  );
}

InvestmentViewContainer.propTypes = {
  id: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  resetDocumentsForm: PropTypes.func.isRequired,
  fetchInvestment: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  const {
    params: { id: investmentId },
  } = props;
  const {
    views: { investment: view },
    organizations: { currentOrganizationUsername },
  } = state;
  const investment = getEntity(state, "investmentDetails", investmentId);
  const distributions = getEntity(state, "distributionHistory", investmentId)
    .distributions;
  const valuations = getEntity(state, "valuationHistories", investmentId)
    .valuations;
  const capitalCalls = getEntity(state, "capitalCallHistories", investmentId)
    .capital_calls;
  const convertedDeal = getEntity(state, "deals", view.deal_ids);
  const actions = getOrderedCollection(
    state,
    "historyActions",
    view.history_action_ids,
  );
  const company = getEntity(state, "companyAvatars", investment.company_id);
  const portfolio = getEntity(state, "portfolios", investment.portfolio_id);
  const contacts = getCollection(state, "contacts", view.contact_ids);
  const companies = getCollectionAsObject(
    state,
    "companyAvatars",
    view.company_avatar_ids,
  );
  investment["contacts"] = contacts;
  // Assigning actions to history_actions to keep the data structure needed for FundDetails
  // TODO remove this assignment and pass actions as a prop to FundDetails
  investment["history_actions"] = actions;

  const combinedFetchCallStatuses = getEntityLoadingState(state, {
    id: investmentId,
    collectionName: "investments",
  });
  const isFetching = !investment || !combinedFetchCallStatuses.loaded;

  return {
    relatedResourcesLoadingStates: false,
    investment,
    id: investmentId,
    valuations,
    capitalCalls,
    distributions,
    company,
    actions,
    companies,
    portfolio,
    convertedDeal,
    isFetching,
    organizationUsername: currentOrganizationUsername,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchInvestment: (investmentId) =>
      dispatch(fetchInvestmentDetailsV2(investmentId)),
    resetDocumentsForm: () => dispatch(resetForm()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvestmentViewContainer);
