import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import Api from "lib/Api";

function buildUrl(groupedByCompany) {
  const byCompany = groupedByCompany ? "/by_company" : "";
  return "/v2/cash_flows" + byCompany;
}

export function useCashFlow(type, portfolioId, groupedByCompany, filters) {
  const { organizationUsername } = useParams();

  const url = buildUrl(groupedByCompany);
  const params = portfolioId === "all" ? {} : { portfolio_id: portfolioId };

  return useQuery(
    [
      "CashFlow",
      organizationUsername,
      portfolioId,
      { groupedByCompany, filters },
    ],
    async () => {
      const { data } = await Api.get(url, {
        params: { ...params, ...filters },
      });

      const { summary_stats, total_transactions, total_cash_outs } = data.meta;
      return {
        cashFlow: data.cash_flow,
        summaryStats: {
          ...summary_stats,
          total_transactions,
          total_cash_outs,
        },
      };
    },
    {
      enabled: type === "cashFlow",
      staleTime: 10000,
    },
  );
}
