import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Input from "components_new/atoms/Input";
import Icon from "components_new/atoms/Icon";
import "./UsernameInput.scss";

const DIFF_TO_ALPHANUMERIC_AND_HYPHENS = /[^\da-z\-]/gi;
function formatTextToUsername(value) {
  return value ? value.replace(DIFF_TO_ALPHANUMERIC_AND_HYPHENS, "") : "";
}

function UsernameInput(props) {
  const {
    extraClassnames,
    onChange,
    value,
    prefixSymbol,
    ...inputProps
  } = props;
  const [displayValue, setDisplayValue] = useState(value);

  useEffect(() => {
    if (value !== displayValue) {
      setDisplayValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleOnChange = ({ target }) => {
    const formattedValue = formatTextToUsername(target.value);
    setDisplayValue(formattedValue);
    if (onChange) {
      onChange(formattedValue);
    }
  };

  const usernameInputClassnames = classnames("UsernameInput", {
    [extraClassnames]: !!extraClassnames,
  });

  return (
    <Input
      value={displayValue}
      onChange={handleOnChange}
      icon={<Icon icon="ri-at-line" />}
      iconPosition="left"
      extraClassnames={usernameInputClassnames}
      {...inputProps}
    />
  );
}

UsernameInput.propTypes = {
  onChange: PropTypes.func,
  extraClassnames: PropTypes.string,
  value: PropTypes.string,
  prefixSymbol: PropTypes.string,
};

export default UsernameInput;
