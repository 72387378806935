import {
  resendCodeError,
  resendCodeStart,
  resendCodeSuccess,
} from "actions/synchronous/auth";
import Api from "lib/Api";

function sendCode(getState, phoneNumber = null) {
  const {
    auth: { intermediateToken },
  } = getState();

  return Api.post(
    "/session/resend_code",
    {
      authentication: {
        intermediate_auth_token: intermediateToken,
      },
      phone_number: phoneNumber,
    },
    { withoutOrganization: true },
  );
}

export function resendCode() {
  return (dispatch, getState) => {
    dispatch(resendCodeStart());
    const {
      auth: {
        currentUser: { phone_number: phoneNumber },
      },
    } = getState();

    return sendCode(getState, phoneNumber).then(
      () => {
        dispatch(resendCodeSuccess());
      },
      () => {
        dispatch(resendCodeError());
      },
    );
  };
}
