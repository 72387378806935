import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { isFunction, throttle } from "lodash";

const { ResizeSensor } = require("css-element-queries");

class ModalBody extends Component {
  componentDidMount() {
    const $modalBody = this.modalBodyDomRef;

    new ResizeSensor($modalBody, () => {
      this.handleResize();
    });

    this.handleResize();

    window.addEventListener("resize", this.handleResize);

    this.toggleEmptyClass();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  componentDidUpdate() {
    this.toggleEmptyClass();
  }

  setModalBodyDomRef = (element) => {
    this.modalBodyDomRef = element;
  };

  handleResize = throttle(() => {
    this.resize();
  }, 300);

  resize = () => {
    const gcs = window.getComputedStyle;
    const viewportHeight = window.innerHeight;

    // If the ref is not defined (e.g. when calling resize() out of this context) try traversing the DOM
    const $modalBody =
      this.modalBodyDomRef || document.querySelector(".ModalBody");
    //When test is executing, this not recognize the `closet` function, that's why we are returning null.
    if ($modalBody && !$modalBody.closest) return null;
    const $wrapper = $modalBody.parentElement.parentElement; // TODO: Find a better way to get the real wrapper
    const $modalFooter = $wrapper.querySelector(".ModalFooter");
    const $modalHeader = $wrapper.querySelector(".ModalHeader");
    const $modalLoader = $modalBody.closest(".ModalLoader");

    const modalBodyMarginBottom = parseInt(gcs($modalBody).marginBottom);
    const modalBodyTotalHeight = $modalBody.scrollHeight;
    const modalFooterHeight = $modalFooter
      ? parseInt(gcs($modalFooter).height)
      : 0;
    const modalHeaderHeight = parseInt(gcs($modalHeader).height);
    const modalLoaderMarginBottom = parseInt(gcs($modalLoader).marginBottom);
    const modalLoaderMarginTop = parseInt(gcs($modalLoader).marginTop);

    const modalLoaderTotalHeight =
      modalHeaderHeight + modalBodyTotalHeight + modalFooterHeight;
    const viewableAreaHeight =
      modalLoaderTotalHeight + modalLoaderMarginTop + modalLoaderMarginBottom;

    if (viewableAreaHeight > viewportHeight) {
      const newHeight =
        viewportHeight -
        modalLoaderMarginTop -
        modalLoaderMarginBottom -
        modalHeaderHeight -
        modalFooterHeight -
        modalBodyMarginBottom;

      $modalBody.style.height = `${newHeight}px`;
      $modalBody.style.overflowY = "auto";
    } else {
      $modalBody.removeAttribute("style");
    }
  };

  // We are doing this manually (instead of using :empty CSS pseudoselector)
  // because Resizer adds an element inside .ModalBody
  toggleEmptyClass() {
    const $modalBody = this.modalBodyDomRef;
    $modalBody.classList.toggle("ModalBody--empty", !this.props.children);
  }

  render() {
    const { children, type } = this.props;
    const classnames = classNames("ModalBody", `ModalBody--${type}`);

    return (
      <div className={classnames} ref={this.setModalBodyDomRef}>
        {isFunction(children) ? children(this.resize) : children}
      </div>
    );
  }
}

ModalBody.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(["dialog", "tables", "files", "announcement"]),
};

ModalBody.defaultProps = {
  type: "dialog",
};

export default ModalBody;
