import React from "react";
import classNames from "classnames";
import { Placeholder as SemanticSkeleton } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/placeholder.css";
import "./Skeleton.scss";

function Skeleton({ rightAligned, ...props }) {
  const skeletonClassnames = classNames("Skeleton", {
    rightAligned: !!rightAligned,
  });
  return (
    <SemanticSkeleton
      className={skeletonClassnames}
      {...props}
    ></SemanticSkeleton>
  );
}

export default Skeleton;
