import Api from "lib/Api";
import { RECEIVE_SLACK_CHANNELS } from "./actionTypes";
import { upsertResource } from "store/resourcesV2/actions";

export function updateOrganizationIntegration(formData) {
  return (dispatch) => {
    return Api.post("/organization_slack_integrations/register_channel", {
      organization_slack_integration: formData,
    }).then(({ data: organization }) => {
      dispatch(upsertResource("organizations", organization));
    });
  };
}

export function revokeSlackIntegration() {
  return (dispatch) => {
    return Api.post("/organization_slack_integrations/disconnect").then(
      ({ data: organization }) => {
        dispatch(upsertResource("organizations", organization));
      },
    );
  };
}

export function fetchSlackChannels() {
  return (dispatch) => {
    return Api.get("/organization_slack_integrations/channels").then(
      (response) => {
        dispatch(receiveSlackChannels(response.data));
      },
    );
  };
}

export function receiveSlackChannels(channels) {
  return { type: RECEIVE_SLACK_CHANNELS, channels };
}
