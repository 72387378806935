import { connect } from "react-redux";
import InvitationRevoked from "components/invitations/InvitationRevoked";

function mapStateToProps({ createAccount }) {
  return {
    portfolioName: createAccount.portfolioName,
    inviterName: createAccount.inviterName,
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(InvitationRevoked);
