import React from "react";
import FilterLoader from "components/shared/FilterLoader";

function ActivityFeedLoadMore({ onClick, isFetching }) {
  return (
    <div className="u-bg-tint-0 ActivityFeedLoadMoreHolder">
      <div onClick={onClick} className="ActivityFeedLoadMore">
        Load More Events{" "}
        {isFetching && <FilterLoader extraClassnames="LoadMoreWheel" />}
      </div>
    </div>
  );
}

export default ActivityFeedLoadMore;
