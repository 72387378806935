import React from "react";
import classnames from "classnames";
import { Draggable } from "react-beautiful-dnd";

import "./DraggableItem.scss";

function DraggableItem({
  index,
  draggableId,
  shouldRespectForceTouch = false,
  children,
  extraClassnames,
}) {
  return (
    <Draggable
      draggableId={draggableId}
      index={index}
      shouldRespectForceTouch={shouldRespectForceTouch}
    >
      {(provided, snapshot) => {
        const draggableItemClassnames = classnames("DraggableItem", {
          "is-dragging": snapshot.isDragging,
          [extraClassnames]: !!extraClassnames,
        });
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={draggableItemClassnames}
          >
            {children}
          </div>
        );
      }}
    </Draggable>
  );
}

export default DraggableItem;
