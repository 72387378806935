import Api from "lib/Api";

import { useMutation, useQueryClient } from "react-query";
import { isEmpty, uniqBy } from "lodash";
import { useParams } from "react-router-dom";

export function useUpdateDeal() {
  const queryClient = useQueryClient();
  const { organizationUsername } = useParams();

  return useMutation(
    ({ formData, pipelineId, dealId }) => {
      const deal = { ...formData };

      if (!isEmpty(formData.documents_data)) {
        deal.documents_data = Object.values(formData.documents_data);
      }

      return Api.patch(`/pipelines/${pipelineId}/deals/${dealId}`, {
        deal,
      });
    },
    {
      onSuccess: ({ data }, { pipelineId }) => {
        updateActiveDealsView(queryClient, pipelineId, data);
        updateDealView(queryClient, data);
        updateCompanyView(queryClient, organizationUsername, data);

        queryClient.invalidateQueries(["CommittedDeals"]);
        queryClient.invalidateQueries(["PassedDeals"]);
        queryClient.invalidateQueries(["SearchDeals"]);
        queryClient.invalidateQueries(["SearchDeals", pipelineId]);
        queryClient.invalidateQueries(["ActiveDeals", pipelineId]);
      },
    },
    {
      onError: (error) => {
        throw error;
      },
    },
  );
}

function updateActiveDealsView(queryClient, pipelineId, data) {
  const key = ["ActiveDeals", pipelineId, {}];
  const activeDealsView = queryClient.getQueryData(key);

  if (activeDealsView?.deals) {
    const { deal: updatedDeal } = data;

    queryClient.setQueryData(key, {
      ...activeDealsView,
      deals: activeDealsView.deals.map((deal) =>
        deal.id === updatedDeal.id ? updatedDeal : deal,
      ),
    });
  }
}

function updateDealView(queryClient, data) {
  const { deal: updatedDeal } = data;

  const key = ["Deal", updatedDeal.id];
  const selectedDealView = queryClient.getQueryData(key);

  if (selectedDealView?.deal) {
    queryClient.setQueryData(key, {
      ...selectedDealView,
      deal: updatedDeal,
    });
  }
}

function updateCompanyView(queryClient, organizationUsername, data) {
  const { deal: updatedDeal, contacts: createdContacts } = data;

  const key = ["Company", organizationUsername, updatedDeal.company_id];
  const companyView = queryClient.getQueryData(key);

  if (companyView?.companyDeals) {
    const referrers = [...companyView.referrers, ...createdContacts];
    const uniqueReferrer = uniqBy(referrers, "id");
    queryClient.setQueryData(key, {
      ...companyView,
      companyDeals: companyView.companyDeals.map((deal) =>
        deal.id === updatedDeal.id ? updatedDeal : deal,
      ),
      referrers: uniqueReferrer,
    });
  }
}
