import React from "react";
import FormGroup from "components_new/elements/FormGroup";
import Input from "components_new/atoms/Input";
import CurrencyInput from "components_new/atoms/CurrencyInput";
import "./RoundDetails.scss";

function RoundDetails({ formData, handleChange, withTopBorder }) {
  const {
    fully_diluted_shares,
    shares_in_series,
    pre_money_company_valuation,
    amount_raised_in_round,
  } = formData;

  return (
    <FormGroup
      extraClassnames="RoundDetailsGroup"
      title="Round Details"
      subtitle="Information that provides addition context for the investment"
      withTopBorder={withTopBorder}
    >
      <Input
        name="fully_diluted_shares"
        label="Fully diluted shares outstanding"
        placeholder="e.g. 100"
        value={fully_diluted_shares}
        size="tiny"
        onChange={(e, { value }) => {
          handleChange("fully_diluted_shares", value);
        }}
      />
      <Input
        name="shares_in_series"
        label="Shares in series"
        placeholder="e.g. 100"
        value={shares_in_series}
        size="tiny"
        onChange={(e, { value }) => {
          handleChange("shares_in_series", value);
        }}
      />
      <CurrencyInput
        name="pre_money_company_valuation"
        label="Pre-money company valuation"
        placeholder="0.0000"
        decimalLimit={4}
        value={pre_money_company_valuation}
        defaultValue={pre_money_company_valuation}
        value={pre_money_company_valuation}
        size="tiny"
        iconPosition="left"
        onChange={(value) => {
          handleChange("pre_money_company_valuation", value);
        }}
      />
      <CurrencyInput
        name="amount_raised_in_round"
        label="Amount raised in round"
        placeholder="0.0000"
        decimalLimit={4}
        defaultValue={amount_raised_in_round}
        value={amount_raised_in_round}
        size="tiny"
        iconPosition="left"
        onChange={(value) => {
          handleChange("amount_raised_in_round", value);
        }}
      />
    </FormGroup>
  );
}

export default RoundDetails;
