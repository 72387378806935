import PropTypes from "prop-types";
import React from "react";
import Tooltip from "components_new/atoms/Tooltip/Tooltip";
import Loader from "components_new/atoms/Loader";
import classNames from "classnames";
import "./UserAvatar.scss";

const UserAvatar = ({
  initials,
  avatarColor,
  avatarUrl,
  fullName,
  showTooltip,
  tooltipPosition,
  theme,
  isLoading = false,
  size,
}) => {
  const userAvatarClassNames = classNames("UserAvatar-content", {
    [`UserAvatar-${theme}`]: !!theme,
    [`is-${size}`]: !!size,
  });

  const backgroundStyle = avatarColor
    ? { backgroundColor: avatarColor, borderColor: avatarColor }
    : null;
  const colorStyle = avatarColor ? { color: "white" } : null;

  return (
    <div className="UserAvatar" data-testid="UserAvatar">
      {isLoading ? (
        <Loader active={true} inline size="tiny" />
      ) : (
        <Tooltip
          position={tooltipPosition}
          disabled={!showTooltip}
          trigger={
            <div
              className={userAvatarClassNames}
              style={{ ...backgroundStyle }}
            >
              {avatarUrl ? (
                <img
                  className="UserAvatar-avatarImage"
                  alt="Avatar"
                  src={avatarUrl}
                />
              ) : (
                <div className="UserAvatar-initials" style={{ ...colorStyle }}>
                  {initials}
                </div>
              )}
            </div>
          }
          content={fullName}
        />
      )}
    </div>
  );
};

UserAvatar.propTypes = {
  initials: PropTypes.string.isRequired,
  fullName: PropTypes.string,
  showTooltip: PropTypes.bool.isRequired,
  avatarUrl: PropTypes.string,
  avatarColor: PropTypes.string,
  tooltipPosition: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  theme: PropTypes.oneOf(["default", "inverse"]).isRequired,
  size: PropTypes.oneOf(["large", "medium", "small", "mini", "tiny"])
    .isRequired,
};

UserAvatar.defaultProps = {
  showTooltip: true,
  theme: "default",
  size: "medium",
  avatarUrl: null,
  avatarColor: null,
  isLoading: false,
  tooltipPosition: "top center",
};

export default UserAvatar;
