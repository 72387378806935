import React from "react";
import Alert from "components/shared/Alert";

function DeleteCertificateMessage({ showEvent, rowIndex, historyAction }) {
  let content = `Only the most current action can be deleted. To delete this action you
  will need to delete all the actions above it.`;
  if (rowIndex === 0 && !historyAction.can_be_deleted) {
    content = `In order to delete this event, you need to delete all the existing distributions.`;
  }
  return (
    <Alert
      alertContent={content}
      onClickCloseIcon={() =>
        showEvent({ openActiontId: null, actionType: null })
      }
    />
  );
}

export default DeleteCertificateMessage;
