import { connect } from "react-redux";
import Modal from "./Modal";
import { closeModal } from "store/modal";

function mapStateToProps(state) {
  return {
    isOpen: state.modal.isOpen,
    openModalComponents: state.modal.openModalComponents,
    modalOptions: state.modal.options,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => {
      dispatch(closeModal());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
