import { useMutation, useQueryClient } from "react-query";
import Api from "lib/Api";

export function useCreateStage() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ pipelineId, name }) =>
      Api.post(`/pipelines/${pipelineId}/stages`, { name }),
    {
      onSuccess: ({ data: newStage }, { pipelineId }) => {
        return queryClient.setQueryData(
          ["Pipeline", pipelineId],
          (oldData) => ({
            ...oldData,
            stages: [...oldData.stages, newStage],
          }),
        );
      },
      onError: (error) => {
        throw error;
      },
    },
  );
}
