import React from "react";
import ExitsRowCells from "./ExitsRowCells";
import { TableRow } from "components_new/elements/Table";

function ExitsRow({
  investment,
  company,
  orgUsername,
  isChild,
  className,
  isParent,
  showClosingDocuments,
}) {
  return (
    <TableRow key={investment.id} className={className}>
      <ExitsRowCells
        investment={investment}
        company={company}
        orgUsername={orgUsername}
        isChild={isChild}
        isParent={isParent}
        showClosingDocuments={showClosingDocuments}
      />
    </TableRow>
  );
}

export default ExitsRow;
