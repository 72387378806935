import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import FormGroup from "components_new/elements/FormGroup";
import FormFooter from "components_new/SidebarComponents/SidebarForm/FormFooter";
import useForm from "lib/hooks/useForm";
import Input from "components_new/atoms/Input";
import RequiredNote from "components_new/SidebarComponents/shared/RequiredNote";
import { formatValue, formatTextToName, formatTextToUsername } from "./config";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import getMessage from "components_new/lib/Messages";

const INITIAL_VALUES = {
  formData: { name: "", username: "" },
};

function OrganizationForm({ ownName, createOrganization, closeSidebar }) {
  const [isLoading, setIsLoading] = useState(false);
  const [usernameTouched, setUsernameTouched] = useState(false);

  const navigate = useNavigate();
  const { addToast } = useSetToast();

  const onSubmit = async (clientData) => {
    setIsLoading(true);
    try {
      const response = await createOrganization(clientData);
      if (response) {
        setIsLoading(false);
        closeSidebar();
        addToast(getMessage("addedOrganization"));
        navigate(`/${response.username}/dashboard`);
      }
    } catch (error) {
      handleErrors(error);
      handleScrollView(error);
      setIsLoading(false);
    }
  };

  const handleNameChange = (value) => {
    const formattedValue = formatTextToName(value);
    handleChange("name", formattedValue);
    if (!usernameTouched) {
      handleChange("username", formatValue(formattedValue));
    }
  };

  const handleUserNameChange = (value) => {
    setUsernameTouched(true);
    handleChange("username", formatTextToUsername(value));
  };

  const {
    formData,
    handleChange,
    handleSubmit,
    handleErrors,
    handleScrollView,
    errors,
    touched,
  } = useForm(INITIAL_VALUES, onSubmit);
  const { name, username } = formData;

  return (
    <div className="OrganizationForm">
      <FormGroup
        extraClassnames="OrganizationDetailGroup"
        title="Organization Detail"
        isExpanded
      >
        <Input
          name="name"
          extraClassnames="OrganizationName"
          label="Organization Display Name"
          placeholder="Enter name"
          value={name}
          onChange={(_, { value }) => {
            handleNameChange(value);
          }}
          errorMessage={errors.for("name")}
          size="tiny"
        />
        <Input
          name="username"
          icon="at"
          iconPosition="left"
          label="Organization Username"
          value={username}
          onChange={(_, { value }) => {
            handleUserNameChange(value);
          }}
          errorMessage={errors.for("username")}
          size="tiny"
        />
      </FormGroup>
      <RequiredNote />
      <FormFooter
        componentName={ownName}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        disableSubmit={false}
        touched={touched}
        submitTextButton="Save Organization"
      />
    </div>
  );
}

export default OrganizationForm;
