import React, { useEffect } from "react";
import Input from "components_new/atoms/Input";
import DatePicker from "components_new/atoms/DatePicker";
import CurrencyInput from "components_new/atoms/CurrencyInput";
import InterestDetails from "./InterestDetails";
import ConversionDetails from "./ConversionDetails";
import { isEmpty } from "lodash";

function DebtInvestmentDetails({ handleChange, formData, errors }) {
  const { name, date, total_acquisition_amount } = formData;

  useEffect(() => {
    if (isEmpty(name)) {
      handleChange("name", "Convertible Note");
    }

    return () => handleChange("name", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="DebtInvestmentDetails BasicDetails ui form">
      <Input
        name="name"
        label="Loan Name"
        placeholder="Add a name"
        value={name}
        onChange={(_, { value }) => {
          handleChange("name", value);
        }}
        isRequired
        size="tiny"
        error={!!errors.for("name")}
        errorMessage={errors.for("name")}
      />
      <DatePicker
        name="date"
        label="Issuance Date"
        onChange={(value, validated) => handleChange("date", value, validated)}
        value={date}
        prefillToday={true}
        required
        size="tiny"
        error={!!errors.for("date")}
        errorMessage={errors.for("date")}
      />
      <CurrencyInput
        name="total_acquisition_amount"
        label="Note amount*"
        placeholder="0.00"
        decimalLimit={2}
        defaultValue={total_acquisition_amount}
        onChange={(value) => {
          handleChange("total_acquisition_amount", value);
        }}
        size="tiny"
        iconPosition="left"
        error={!!errors.for("total_acquisition_amount")}
        errorMessage={errors.for("total_acquisition_amount")}
      />

      <InterestDetails formData={formData} handleChange={handleChange} />
      <ConversionDetails formData={formData} handleChange={handleChange} />
    </div>
  );
}

export default DebtInvestmentDetails;
