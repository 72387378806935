import Api from "lib/Api";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import useViewIds from "lib/hooks/useViewIds";

export function useCreateContact() {
  const { organizationUsername } = useParams();
  const { initialCompanyId: companyId } = useViewIds();
  const queryClient = useQueryClient();

  return useMutation(
    (formData) => {
      return Api.post(`/v2/contacts`, {
        contact: formData,
      });
    },
    {
      onSuccess: ({ data: newContact }) => {
        const { contacts } = newContact;
        const params = {
          queryClient,
          organizationUsername,
          companyId,
          newContact: contacts,
        };

        updateContactsView(params);
        updateCompanyView(params);
      },
      onError: (error) => {
        throw error;
      },
    },
  );
}

function updateContactsView({ queryClient, organizationUsername, newContact }) {
  const key = ["ContactsDirectory", organizationUsername, {}];
  const contactsView = queryClient.getQueryData(key);

  if (contactsView?.contacts) {
    queryClient.setQueryData(key, {
      ...contactsView,
      contacts: [newContact, ...contactsView.contacts],
      contactsById: {
        ...contactsView.contactsById,
        [`${newContact.id}`]: { ...newContact },
      },
    });
  }
}

function updateCompanyView({
  queryClient,
  organizationUsername,
  companyId,
  newContact,
}) {
  const key = ["Company", organizationUsername, companyId];
  const companyView = queryClient.getQueryData(key);

  const companyIds = newContact.roles.map((role) => role.company.id);
  const belongsToCurrentCompany = companyIds.includes(companyId);

  if (companyView?.employees && belongsToCurrentCompany) {
    queryClient.setQueryData(key, {
      ...companyView,
      employees: [...companyView.employees, newContact],
    });
  }
}
