import React, { useMemo } from "react";
import formatContactName from "lib/formatters/formatContactName";
import { partition } from "lodash";
import CompanyDeals from "components_new/views/Company/shared/CompanyDeals";

export default function CompanyDealTab({
  deals,
  companyAvatars,
  referrers,
  dropdownComponent,
  currentOrganizationUsername,
  showSidebar,
}) {
  const allDeals = useMemo(() => {
    return deals.map((deal) => {
      return {
        ...deal,
        referrals: deal.contact_ids
          ? deal.contact_ids.map((id) =>
              formatContactName(referrers.find((contact) => contact.id === id)),
            )
          : [],
        companyAvatar: companyAvatars.find(
          (companyAvatar) => companyAvatar.id === deal.company_id,
        ),
        portfolioName:
          deal.status == "invested" ? deal.investment["portfolio_name"] : "",
      };
    });
  }, [deals, companyAvatars, referrers]);

  const [activeDeals, previousDeals] = partition(
    allDeals,
    (deal) => deal.status === "active",
  );

  return (
    <div className="TabContentDiv">
      <CompanyDeals
        title="Active Deals"
        testId="activeDealsCard"
        companyDeals={activeDeals}
        dealDropdownComponent={dropdownComponent}
        currentOrganizationUsername={currentOrganizationUsername}
        showSidebar={showSidebar}
      />
      <CompanyDeals
        title="Previous Deals"
        testId="previousDealsCard"
        companyDeals={previousDeals}
        dealDropdownComponent={dropdownComponent}
        currentOrganizationUsername={currentOrganizationUsername}
        showSidebar={showSidebar}
      />
    </div>
  );
}
