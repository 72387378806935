export const UPDATE_COMPANY_OWNERSHIP_SUCCESS =
  "companyView/UPDATE_COMPANY_OWNERSHIP_SUCCESS";
export const UPDATE_COMPANY_VALUATION_SUCCESS =
  "companyView/UPDATE_COMPANY_VALUATION_SUCCESS";
export const UPDATE_COMPANY_VIEW_INVESTMENT =
  "companyView/UPDATE_COMPANY_VIEW_INVESTMENT";
export const CLEAN_COMPANY_VIEW = "companyView/CLEAN_COMPANY_VIEW";
export const EXPAND_ALL_ROW_DETAILS = "companyView/EXPAND_ALL_ROW_DETAILS";
export const COLLAPSE_ALL_ROW_DETAILS = "companyView/COLLAPSE_ALL_ROW_DETAILS";
export const RESET_EXPAND_AND_COLLAPSE =
  "companyView/RESET_EXPAND_AND_COLLAPSE";
export const EXPAND_MISCELLANEOUS = "companyView/EXPAND_MISCELLANEOUS";
export const COLLAPSE_MISCELLANEOUS = "companyView/COLLAPSE_MISCELLANEOUS";
