export default function reorderStagesOnDrop(list, sourceIndex, targetIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(sourceIndex, 1);
  result.splice(targetIndex, 0, removed);

  return result.map((s, i) => {
    s.order = i;
    return s;
  });
}
