import React from "react";
import useSidebar from "store/sidebar/hooks";
import useComponentStatus from "./componentStatus/hooks";
import useFormStatus from "./formStatus/hooks";
import SidebarComponents from "./SidebarComponents";

export const SidebarComponentsContext = React.createContext();

function SidebarComponentsWrapper() {
  const {
    sidebar,
    openSidebar,
    showSidebar,
    hideSidebar,
    updateSidebar,
    clearSidebar,
    closeSidebar,
  } = useSidebar();

  const {
    componentStatus,
    showSidebarComponent,
    hideSidebarComponent,
    resetSidebarComponents,
    mountSidebarComponent,
    unmountSidebarComponent,
    updateSidebarComponent,
    getComponentState,
  } = useComponentStatus();

  const {
    formStatus,
    registerForm,
    setWarningMessage,
    setEditingForm,
    resetForm,
    getFormState,
  } = useFormStatus();

  const unmountFormComponent = (componentName) => {
    unmountSidebarComponent(componentName);
    resetForm(componentName);
  };

  const onUnmount = (componentName, submitTo, index = 1) => {
    if (index === 0) {
      closeSidebar();
    } else {
      unmountFormComponent(componentName);
      showSidebarComponent(submitTo);
    }
  };

  return (
    <SidebarComponentsContext.Provider
      value={{
        sidebar,
        componentStatus,
        formStatus,
        getFormState,
        getComponentState,
        openSidebar,
        showSidebar,
        hideSidebar,
        updateSidebar,
        clearSidebar,
        closeSidebar,
        showSidebarComponent,
        hideSidebarComponent,
        resetSidebarComponents,
        mountSidebarComponent,
        unmountSidebarComponent,
        updateSidebarComponent,
        registerForm,
        setWarningMessage,
        setEditingForm,
        resetForm,
        unmountFormComponent,
        onUnmount,
      }}
    >
      <SidebarComponents />
    </SidebarComponentsContext.Provider>
  );
}

export default SidebarComponentsWrapper;
