import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { isEqual } from "lodash";
import { flashPropType } from "PropTypes";
import Alert from "components/shared/Alert";
import { durationInMiliseconds } from "config/flashConfig";
import { motion } from "framer-motion";
import { fadeIn } from "lib/animations";

class FlashList extends Component {
  element = document.createElement("div");
  flashPlaceholder = null;

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { scheduleFlashRemoval } = this.props;
    if (this.willOverrideExistingFlash(nextProps)) {
      const firstFlash = nextProps.flashes[0];
      const { alert } = firstFlash.options;
      if (!alert.avoidAutoClose) {
        scheduleFlashRemoval(firstFlash, 0);
      }
    }

    if (
      this.willOverrideAppFlash(nextProps) ||
      this.willOverrideModalFlash(nextProps)
    ) {
      nextProps.flashes.forEach((flash) => {
        const { alert } = flash.options;
        if (!alert.avoidAutoClose) {
          scheduleFlashRemoval(flash, durationInMiliseconds);
        }
      });
    }
  }

  componentDidMount() {
    if (this.flashPlaceholder) {
      this.flashPlaceholder.append(this.element);
    }
  }

  componentWillUnmount() {
    if (this.flashPlaceholder) {
      this.flashPlaceholder.removeChild(this.element);
    }
  }

  willOverrideAppFlash(nextProps) {
    return !isEqual(this.props.flashes, nextProps.flashes);
  }

  // TODO: Search why when the flash show in the modal the flashes props and nextprops are always the same
  willOverrideModalFlash(nextProps) {
    const { flashes, showInModal } = this.props;
    return isEqual(flashes, nextProps.flashes) && showInModal;
  }

  willOverrideExistingFlash(nextProps) {
    const { showed } = this.props;
    return (
      nextProps.showed === showed && showed && nextProps.flashes.length > 1
    );
  }

  render() {
    const flashClassnames = classNames("Flash u-row", {
      "is-showing": this.props.flashes.length,
    });

    const flashesBlock = (
      <motion.div
        initial="hidden"
        animate="visible"
        variants={fadeIn}
        transition={{ duration: 0.05 }}
        className={flashClassnames}
      >
        {this.props.flashes.map((flash) => {
          const { alert } = flash.options;
          const { onRemoveFlash } = this.props;
          return (
            <Alert
              key={flash.id}
              alertContent={flash.message}
              onClickCloseIcon={() => onRemoveFlash(flash)}
              showActionButton={alert.showActionButton}
              buttonActionText={alert.buttonActionText}
              linkTo={alert.linkTo}
              onClickAction={() => {
                if (alert.onClickAction) {
                  alert.onClickAction();
                }
                onRemoveFlash(flash);
              }}
              type="success"
            />
          );
        })}
      </motion.div>
    );

    if (this.props.showInModal) {
      this.flashPlaceholder = document.querySelector(
        ".Flash--modal-placeholder",
      );

      if (this.flashPlaceholder) {
        this.flashPlaceholder.append(this.element);
      }

      return ReactDOM.createPortal(flashesBlock, this.element);
    }

    return flashesBlock;
  }
}

FlashList.propTypes = {
  flashes: PropTypes.arrayOf(flashPropType).isRequired,
  scheduleFlashRemoval: PropTypes.func.isRequired,
  showed: PropTypes.bool,
  showInModal: PropTypes.bool.isRequired,
  onRemoveFlash: PropTypes.func.isRequired,
};

export default FlashList;
