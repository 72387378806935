import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FormGroup from "components_new/elements/FormGroup";
import redirectFromInitialValue from "components_new/lib/redirectFromInitialValue";
import FormFooter from "components_new/SidebarComponents/SidebarForm/FormFooter";
import { SearchAndSelectField as SearchAndSelect } from "components_new/elements/SearchAndSelect";
import DatePicker from "components_new/atoms/DatePicker";
import SplitSelect from "components_new/atoms/SplitSelect";
import RelatedInvestmentsTooltip from "./RelatedInvestmentsTooltip";
import Documents from "components_new/SidebarComponents/forms/groups/Documents";
import Notes from "components_new/SidebarComponents/forms/groups/Notes";
import RequiredNote from "components_new/SidebarComponents/shared/RequiredNote";
import { isEmpty, merge } from "lodash";
import useForm from "lib/hooks/useForm";
import {
  addTaskIdToForm,
  addTaskAttachmentsToFormDocuments,
} from "components_new/SidebarComponents/forms/config";
import { isUploading } from "components_new/elements/DropZone/config";

const SELECTION_OPTIONS = [
  {
    key: "per share",
    text: "Per Share",
    value: "per_share",
  },
  {
    key: "total",
    text: "Total",
    value: "total",
  },
];

const isInvestmentWithoutUnits = (type) => {
  return ["fund", "debt"].includes(type);
};

function ValuationForm({
  ownName,
  prefilledSelection,
  taskId,
  taskAttachments,
  addValuation,
  getRelatedInvestments,
  closeSidebar,
  showConfirmationMessage,
  currentOrganizationUsername,
}) {
  const INITIAL_VALUES = {
    formData: {
      selection: prefilledSelection || {},
      date: null,
      amount: null,
      given_price_per_unit: "per_share",
      update_all: false,
      task_id: null,
      documents_data: [],
      note: "",
    },
  };

  const [isLoading, setIsLoading] = useState(false);
  const [
    isRelatedInvestmentsFetching,
    setIsRelatedInvestmentsFetching,
  ] = useState(false);
  const [amountDefaultType, setAmountDefaultType] = useState(
    SELECTION_OPTIONS[0],
  );
  const [relatedInvestments, setRelatedInvestments] = useState([]);
  const [uploadQueue, setUploadQueue] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [
    showRelatedInvestmentsCheckbox,
    setShowRelatedInvestmentsCheckbox,
  ] = useState(false);

  const initialValues = merge(
    {},
    INITIAL_VALUES,
    addTaskIdToForm(taskId),
    addTaskAttachmentsToFormDocuments(taskId, taskAttachments),
  );

  const navigate = useNavigate();

  const submit = (clientData) => {
    setIsLoading(true);
    addValuation(clientData)
      .then(() => {
        closeSidebar();
        navigate(
          redirectFromInitialValue({
            formData,
            prefilledSelection,
            currentOrganizationUsername,
          }),
        );
        showConfirmationMessage();
      })
      .catch((error) => {
        handleErrors(error);
        handleScrollView(error);
      })
      .finally(() => setIsLoading(false));
  };

  const {
    formData,
    handleChange,
    handleSubmit,
    handleErrors,
    handleScrollView,
    errors,
    touched,
  } = useForm(initialValues, submit);

  const { selection, date, amount, documents_data, note } = formData;

  useEffect(() => {
    setDisableSubmit(
      isEmpty(selection.id) ||
        isEmpty(date) ||
        amount === null ||
        isUploading(uploadQueue),
    );
  }, [selection, date, amount, uploadQueue]);

  useEffect(() => {
    if (!isEmpty(selection)) {
      const hasUnits = !isInvestmentWithoutUnits(selection.type);
      setAmountDefaultType(SELECTION_OPTIONS[hasUnits ? 0 : 1]);
      setIsRelatedInvestmentsFetching(true);
      getRelatedInvestments(selection).then((response) => {
        setRelatedInvestments(response.data);
        setIsRelatedInvestmentsFetching(false);
      });
    }
  }, [selection]); // eslint-disable-line react-hooks/exhaustive-deps

  // relatedInvestments async updates every time selection is changed
  useEffect(() => {
    if (!isEmpty(selection)) {
      const hasUnits = !isInvestmentWithoutUnits(selection.type);
      const hasRelatedInvestments = !isEmpty(relatedInvestments);
      setShowRelatedInvestmentsCheckbox(hasRelatedInvestments || hasUnits);
    }
  }, [relatedInvestments]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="ValuationForm">
      {/* TODO: move to own component */}
      <FormGroup
        extraClassnames="ValuationGroup"
        title="Valuation Details"
        required={true}
        isExpanded={true}
      >
        <SearchAndSelect
          name="selection"
          label="Company or Investment"
          placeHolder="Select a company or investment"
          onChange={(value) => handleChange("selection", value)}
          scope="pps"
          size="tiny"
          initialSelection={formData.selection}
        />
        <DatePicker
          name="date"
          label="Date"
          extraClassnames="grid-50 grid-item"
          onChange={(value, validated) =>
            handleChange("date", value, validated)
          }
          errorMessage={errors.for("date")}
          error={!!errors.for("date")}
          value={formData.date}
          prefillToday={true}
          size="tiny"
        />
        <SplitSelect
          inputProps={{
            name: "amount",
            label: "Amount",
            decimalLimit: 4,
            placeholder: "0.0000",
          }}
          dropdownProps={{
            options: SELECTION_OPTIONS,
            defaultValue: amountDefaultType,
            readOnly: true,
          }}
          onChange={(_, { input }) => handleChange("amount", input)}
          size="tiny"
        />
        {showRelatedInvestmentsCheckbox && (
          <RelatedInvestmentsTooltip
            isLoading={isRelatedInvestmentsFetching}
            investments={relatedInvestments}
            handleChange={handleChange}
            formData={formData}
          />
        )}
      </FormGroup>
      <Documents
        isExpanded
        subtitle="Attach files to a company or investment"
        onChange={(documents) => handleChange("documents_data", documents)}
        onQueueChange={setUploadQueue}
        documents={documents_data}
        prefilledFilesStatus="editing"
      />
      <Notes
        name="notes"
        onChange={(value) => handleChange("note", value)}
        notes={note}
      />
      <RequiredNote />
      <FormFooter
        componentName={ownName}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        disableSubmit={disableSubmit}
        touched={touched}
      />
    </div>
  );
}

export default ValuationForm;
