import Api from "lib/Api";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { updateTaskInScope } from "../shared/updateTaskInScope";
import { removeTaskFromScope } from "../shared/removeTaskFromScope";

export function useMarkAsPending() {
  const queryClient = useQueryClient();
  const { organizationUsername } = useParams();

  return useMutation(
    ({ taskId }) => Api.patch(`/tasks/${taskId}/mark_as_pending`),
    {
      onSuccess: ({ data: updatedTask }) => {
        const params = {
          taskId: updatedTask.id,
          updatedTask,
          queryClient,
          organizationUsername,
        };

        removeTaskFromScope({ scope: "completed", ...params });
        updateTaskInScope({ scope: "pending", ...params });
        updateTaskInScope({ scope: "snoozed", ...params });

        queryClient.invalidateQueries(["QueuesView", organizationUsername]);
        if (updatedTask.taskable_type == "Deal") {
          queryClient.invalidateQueries(["Deal", updatedTask.taskable_id]);
        }
      },
      onError: (error) => {
        throw error;
      },
    },
  );
}
