import React, { Fragment, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  setCurrentOrganization,
  fetchOrganizations,
} from "store/organizations/actions";
import { useDispatch } from "react-redux";
import { closeModal, updateModalOptions } from "store/modalV2/actions";
import {
  removeUserOrganizationMembership,
  fetchCurrentUserOrganizationMemberships,
} from "store/organizations/organizationMembers/actions";
import ConfirmationModal from "components/shared/ConfirmationModal";
import { flash } from "actions/synchronous/flash";

function LeaveOrganizationContainer({
  organizationMembership,
  privateOrganization,
  isSubmitting = false,
  type,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const closeModalCallback = useCallback(() => dispatch(closeModal()), [
    dispatch,
  ]);

  const leaveOrganization = useCallback(async () => {
    dispatch(updateModalOptions("leaveOrganization", { isSubmitting: true }));
    await dispatch(
      removeUserOrganizationMembership(
        organizationMembership,
        privateOrganization,
      ),
    );
    dispatch(fetchOrganizations());
    if (type === "fromMember") {
      navigate(`/${privateOrganization.username}/dashboard`);
      dispatch(setCurrentOrganization(privateOrganization));
    } else if (type === "fromUser") {
      dispatch(fetchCurrentUserOrganizationMemberships());
    }
    dispatch(updateModalOptions("leaveOrganization", { isSubmitting: false }));
    dispatch(closeModal());
    dispatch(flash("leaveOrganization"));
  }, [dispatch, navigate, type, organizationMembership, privateOrganization]);

  return (
    <ConfirmationModal
      title="Leave Organization"
      confirmButtonLabel="Leave Organization"
      cancelButtonLabel="Cancel"
      primaryButtonClassnames="Button--warning"
      submitting={isSubmitting}
      message={
        <Fragment>
          Are you sure you want to leave{" "}
          <strong> {organizationMembership.organization_name} </strong> ? You
          will lose all privileges to view and manage portfolios.
        </Fragment>
      }
      action={leaveOrganization}
      closeModal={closeModalCallback}
    />
  );
}

export default LeaveOrganizationContainer;
