import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

function ModalHeader({ title, subTitle, extraClassnames }) {
  const componentClassnames = classNames("ModalHeader", extraClassnames);

  return (
    <div className={componentClassnames}>
      <div className="ModalHeader-inner">
        {title && <h1 className="ModalHeader-title">{title}</h1>}
        {subTitle && <h2 className="ModalHeader-subtitle">{subTitle}</h2>}
      </div>
    </div>
  );
}

ModalHeader.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  extraClassnames: PropTypes.string,
};

export default ModalHeader;
