import {
  OVERRIDE_COLLECTION,
  RESET_COLLECTION,
  UPDATE_COLLECTION,
  UPSERT_ENTITY,
  REMOVE_ENTITY,
  COLLECTION_FETCH_START,
  COLLECTION_FETCH_SUCCESS,
  COLLECTION_FETCH_FAIL,
  SINGLETON_FETCH_START,
  SINGLETON_FETCH_SUCCESS,
  SINGLETON_FETCH_FAIL,
  UPDATE_SINGLETON,
  ITEM_FETCH_START,
  ITEM_FETCH_SUCCESS,
  MUTATION_START,
  MUTATION_SUCCESS,
  MUTATION_FAIL,
} from "./domainActionTypes";

export function collectionFetchStart(collectionName) {
  return { type: COLLECTION_FETCH_START, collectionName };
}

export function collectionFetchSuccess(
  collectionName,
  entities,
  overrideCollection = true,
) {
  return (dispatch) => {
    if (overrideCollection) {
      dispatch({ type: OVERRIDE_COLLECTION, collectionName, entities });
    } else {
      dispatch({ type: UPDATE_COLLECTION, collectionName, entities });
    }
    dispatch({ type: COLLECTION_FETCH_SUCCESS, collectionName, entities });
  };
}

export function collectionFetchFail(collectionName) {
  return { type: COLLECTION_FETCH_FAIL, collectionName };
}

export function resetCollection(collectionName) {
  return { type: RESET_COLLECTION, collectionName };
}

export function itemFetchStart(collectionName, id) {
  return { type: ITEM_FETCH_START, collectionName, id };
}

export function itemFetchSuccess(collectionName, id, entity) {
  return (dispatch) => {
    dispatch({ type: UPSERT_ENTITY, collectionName, entity });
    dispatch({ type: ITEM_FETCH_SUCCESS, collectionName, id, entity });
  };
}

export function itemFetchFail(collectionName, id) {
  return { type: ITEM_FETCH_SUCCESS, collectionName, id };
}

export function singletonFetchStart(singletonName) {
  return { type: SINGLETON_FETCH_START, singletonName };
}

export function singletonFetchSuccess(singletonName, singleton) {
  return (dispatch) => {
    dispatch({ type: UPDATE_SINGLETON, singletonName, singleton });
    dispatch({ type: SINGLETON_FETCH_SUCCESS, singletonName });
  };
}

export function singletonFetchFail(singletonName) {
  return { type: SINGLETON_FETCH_FAIL, singletonName };
}

export function mutationStart(mutationName) {
  return { type: MUTATION_START, mutationName };
}

export function mutationSuccess(mutationName, changeset = {}) {
  return (dispatch) => {
    const upserts = changeset.upserts || [];
    const destructions = changeset.destructions || [];

    upserts.forEach(({ entity, into: collectionName }) => {
      dispatch({ type: UPSERT_ENTITY, collectionName, entity });
    });

    destructions.forEach(({ id, from: collectionName }) => {
      dispatch({ type: REMOVE_ENTITY, collectionName, id });
    });

    dispatch({ type: MUTATION_SUCCESS, mutationName, changeset });
  };
}

export function mutationFail(mutationName) {
  return { type: MUTATION_FAIL, mutationName };
}
