import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

function HeaderCell({ cell }) {
  const cellClassName = classNames("Table--row-item u-label", {
    [cell.classNames]: cell.classNames,
    [cell.alignment]: cell.alignment,
  });

  return (
    <div key={cell.text} className={cellClassName} data-testid="HeaderCell">
      {cell.text}
    </div>
  );
}

function TableHead({ columns }) {
  return (
    <div className="Table--row Table--header TableHeader">
      {columns.map((cell, i) => (
        <HeaderCell key={i} cell={cell} />
      ))}
    </div>
  );
}

TableHead.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})),
};

export default TableHead;
