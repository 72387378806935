import React, { Fragment } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

function TableColumns({ columns, renderColumn }) {
  return (
    <Fragment>
      {columns.map((columProps, columnIndex) => {
        const tableColumnClassNames = classnames("Table--row-item", {
          [columProps.classNames]: columProps.classNames,
          [columProps.alignment]: columProps.alignment,
        });
        return (
          <div key={columnIndex} className={tableColumnClassNames}>
            {renderColumn(columProps)}
          </div>
        );
      })}
    </Fragment>
  );
}

TableColumns.propTypes = {
  renderColumn: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})),
};

export default TableColumns;
