// This helper function will return a `Classnames` object with the following logic:
// `createOrUpdateClassname` will be used on the creation and update of the object field
// `deleteClassname` will be used on the deletion of the object field
// If no `deleteClassname` is provided it will append "Striked" to `createOrUpdateClassname`

const formatFieldTypeClassnames = ({
  after,
  before,
  createOrUpdateClassname,
  deleteClassname = `${createOrUpdateClassname}Striked`,
}) => ({
  [createOrUpdateClassname]: (!!after && !before) || (!!after && !!before),
  [deleteClassname]: !!before && !after,
});

export default formatFieldTypeClassnames;
