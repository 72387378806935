import React from "react";
import CompanySelector from "components_new/elements/CompanySelector/CompanySelector";
import Button from "components_new/atoms/Button";
import Text from "components_new/atoms/Text";
import "./Sidebar.scss";

const Sidebar = ({ formData, onExcludeStealh, onChange, errors }) => {
  const { company } = formData;
  return (
    <div className="SnapshotForm-Sidebar">
      <Text type="label-bold" color="brand-light">
        Customize Snapshot
      </Text>
      <CompanySelector
        name="company_id"
        label="Company"
        placeHolder="Search"
        onChange={(_, value) => onChange("company", value)}
        prefilledValue={company}
        selectedCompany={company}
        disabledCompanyCreation
        errorMessage={errors.for("company_id")}
        isOnSidebar
        size="tiny"
      />

      <Button secondary fluid size="mini" onClick={onExcludeStealh}>
        Exclude all stealth companies
      </Button>
    </div>
  );
};

export default Sidebar;
