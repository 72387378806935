import "whatwg-fetch";
import queryString from "query-string";
import getOrganizationPathname from "lib/pathname/getOrganizationPathname";

function createFullUrl(baseUrl, path, params) {
  const slashLessBaseUrl = baseUrl.replace(/\/+$/, "");
  const slashLessPath = path.replace(/^\/+/, "");

  const queryLessUrl = `${slashLessBaseUrl}/${slashLessPath}`.replace(
    /^\/+/,
    "/",
  );
  if (Object.keys(params).length === 0) {
    return queryLessUrl;
  } else {
    return `${queryLessUrl}?${queryString.stringify(params, {
      arrayFormat: "bracket",
    })}`;
  }
}

function handleResponse(config, state, response, data) {
  const { ok, status } = response;

  if (ok) {
    return { response, data };
  } else {
    if (status >= 400 && status <= 499) {
      config.onClientError(state, config.store.dispatch, response, data);
    } else if (status >= 500 && status <= 599) {
      config.onServerError(state, config.store.dispatch, response, data);
    }
    throw { response, data };
  }
}

function buildFetchOptions(request, config) {
  const { payload, method, headers: requestHeaders } = request;
  const headers = { ...config.headers, ...requestHeaders };
  const fetchOptions = { method, headers };
  if (payload) {
    fetchOptions.body = JSON.stringify(payload);
  }
  return fetchOptions;
}

function completeRequestWithDefaults(request) {
  return {
    ...request,
    withoutOrganization: request.withoutOrganization || false,
    headers: request.headers || {},
    params: request.params || {},
  };
}

function makeRequest(config, givenRequest) {
  const request = completeRequestWithDefaults(givenRequest);
  const { path, parseResponse = true, blob = false } = request;
  const state = config.store.getState();
  let baseUrl = config.baseUrl;
  if (!givenRequest.withoutOrganization) {
    const currentPathname = getOrganizationPathname();
    baseUrl = `${config.baseUrl}/${currentPathname}`;
  }
  const url = createFullUrl(baseUrl, path, request.params);
  const fetchOptions = buildFetchOptions(request, config);
  let requestResponse = null;

  return fetch(url, fetchOptions)
    .then((response) => {
      requestResponse = response;
      if (blob) {
        return response.blob();
      }
      if (parseResponse) {
        return response.json();
      }
      return response.text();
    })
    .then((data) => handleResponse(config, state, requestResponse, data));
}

export default makeRequest;
