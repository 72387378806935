import React, { Component } from "react";
import { getText } from "TermsMapper";
import classNames from "classnames";
import formatNumber from "lib/formatters/formatNumber";
import TooltipHolder from "components/shared/TooltipHolder";
import formatPercentage from "lib/formatters/formatPercentage";
import { createHighlightContent } from "../config";

function quantityValue(historyAction) {
  const {
    quantity,
    membership_units_or_percentage,
    percentage_owned,
  } = historyAction;
  if (quantity === null && membership_units_or_percentage != "percentage") {
    return null;
  }
  return membership_units_or_percentage !== "percentage"
    ? formatNumber(quantity)
    : formatPercentage(percentage_owned);
}

function QuantityView({ historyAction, isUpdated, lastAction }) {
  const { quantity, type, membership_units_or_percentage } = historyAction;
  if (
    (quantity === null && membership_units_or_percentage != "percentage") ||
    type === "debt" ||
    type === "fund"
  ) {
    return "N/A";
  }

  const quantityLabel =
    membership_units_or_percentage === "percentage"
      ? "percentage"
      : getText(type, "valuation_unit_plural");
  const tooltipText = isUpdated ? createHighlightContent(lastAction) : "";
  return (
    <div className="QuantityView">
      <TooltipHolder
        extraClassnames="QuantityView-value"
        tooltip={tooltipText}
        showTooltip={isUpdated}
        position="top"
      >
        {quantityValue(historyAction)}
      </TooltipHolder>
      <div>{quantityLabel}</div>
    </div>
  );
}

class QuantityCellPreview extends Component {
  state = {
    isUpdated: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { historyAction, isSubmitting } = this.props;
    const {
      historyAction: nextHistory,
      isSubmitting: nextIsSubmitting,
    } = nextProps;
    if (
      nextHistory &&
      quantityValue(nextHistory) !== quantityValue(historyAction)
    ) {
      this.setState({ isUpdated: true });
    } else if (this.state.isUpdated && nextIsSubmitting !== isSubmitting) {
      this.setState({ isUpdated: false });
    }
  }

  render() {
    const { historyAction, isTerminalAction, lastAction } = this.props;
    const quantityCellClassNames = classNames("QuantityCellPreview", {
      "is-updated": this.state.isUpdated,
    });
    return (
      <div className={quantityCellClassNames}>
        {!isTerminalAction ? (
          <QuantityView
            historyAction={historyAction}
            lastAction={lastAction}
            isUpdated={this.state.isUpdated}
          />
        ) : (
          "- - -"
        )}
      </div>
    );
  }
}

export default QuantityCellPreview;
