import React from "react";
import classnames from "classnames";
import { companyHasBadge } from "config/companyProfileConfig";

function CompanyStatusBadge({ company, align, size }) {
  if (!companyHasBadge(company)) {
    return null;
  }

  const status = company.status && company.status.toLowerCase();
  const companyStatusClassnames = classnames("CompanyStatusBadge", {
    [size]: !!size,
    top: align === "top",
    "is-acquired": status === "acquired",
    "is-ipo": status === "ipo",
    "is-failed": status === "failed",
  });

  return <div className={companyStatusClassnames}>{company.status}</div>;
}

export default CompanyStatusBadge;
