import React from "react";
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import CompanyInvestmentPerformanceSidepane from "./CompanyInvestmentPerformanceSidepane";
import useOrganizations from "store/organizations/hooks";

function CompanyInvestmentPerformanceSidepaneWrapper() {
  const { id: companyId } = useParams();
  const {
    currentOrganization: {
      username: currentOrganizationUsername,
      name: currentOrganizationName,
    },
  } = useOrganizations();

  const queryClient = useQueryClient();

  const {
    companyHoldingTotals,
    companyDetails,
    portfolios,
    portfolioIds,
  } = queryClient.getQueryData([
    "Company",
    currentOrganizationUsername,
    companyId,
  ]);

  const holdings = {
    currentHoldings: companyHoldingTotals.current.current_holding_count,
    investorSince: companyHoldingTotals.first_investment_date,
    sharesOwned: companyHoldingTotals.total_quantity,
    fullyDilutedSharesPercentage:
      companyHoldingTotals.total_percent_fully_diluted,
    lastUpdatedInvestmentDate:
      companyHoldingTotals.last_updated_investment_date,
  };

  const performance = {
    costBasis: companyHoldingTotals.cost_basis,
    currentMarketValue: companyHoldingTotals.value,
    unrealizedGain: companyHoldingTotals.gain,
    realizedGain: companyHoldingTotals.payout,
    totalReturn: companyHoldingTotals.total_gain,
    multiple: companyHoldingTotals.multiple,
  };

  const capitalization = {
    sharesOutstanding: companyDetails.fully_diluted_shares,
    valuation: companyDetails.valuation,
    pricePerShare: companyDetails.current_pps,
  };

  return (
    <CompanyInvestmentPerformanceSidepane
      holdings={holdings}
      performance={performance}
      capitalization={capitalization}
      portfolios={portfolios}
      portfolioIds={portfolioIds}
      organizationName={currentOrganizationName}
      organizationUsername={currentOrganizationUsername}
    />
  );
}

export default CompanyInvestmentPerformanceSidepaneWrapper;
