import React from "react";
import CompanyInvestorsTable from "components_new/views/Company/shared/CompanyInvestorsTable";
import CompanyInvestments from "components_new/views/Company/shared/CompanyInvestments";
import CompanyCashFlowTable from "./CompanyCashFlowTable";
import "./CompanyInvestmentTab.scss";

function CompanyInvestmentTab({
  companyAvatars,
  cashFlows,
  investments,
  portfolios,
  exitedInvestments,
  currentOrganizationUsername,
  companyCoInvestments,
  openAddCoInvestmentForm,
  openEditCoInvestmentForm,
  openDeleteCoInvestment,
}) {
  return (
    <div className="TabContentDiv">
      <CompanyInvestments
        title="Current Investments"
        testId="currentInvestmentCard"
        companyInvestments={investments}
        companyAvatars={companyAvatars}
        portfolios={portfolios}
        currentOrganizationUsername={currentOrganizationUsername}
      />
      <CompanyInvestments
        title="Exited Investments"
        testId="exitedInvestmentCard"
        companyInvestments={exitedInvestments}
        companyAvatars={companyAvatars}
        portfolios={portfolios}
        currentOrganizationUsername={currentOrganizationUsername}
      />
      <CompanyCashFlowTable
        cashFlows={cashFlows}
        portfolios={portfolios}
        data-testid="cashFlowTable"
      />
      <CompanyInvestorsTable
        currentOrganizationUsername={currentOrganizationUsername}
        companyCoInvestments={companyCoInvestments}
        openAddCoInvestmentForm={openAddCoInvestmentForm}
        openEditCoInvestmentForm={openEditCoInvestmentForm}
        openDeleteCoInvestment={openDeleteCoInvestment}
      />
    </div>
  );
}

export default CompanyInvestmentTab;
