import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tooltip from "components_new/atoms/Tooltip/Tooltip";
import "./ContentWithTooltip.scss";

function ContentWithTooltip({ tooltip, maxWidth, children, breakLine }) {
  const { position, content, disabled } = tooltip;
  const [isTruncated, setIsTruncated] = useState(false);
  const truncatedContent = useRef(null);

  useEffect(() => {
    const { current } = truncatedContent;
    if (current.firstChild.scrollWidth > current.scrollWidth) {
      setIsTruncated(true);
    }
  }, []);

  return (
    <div
      ref={truncatedContent}
      className="ContentWithTooltip u-textWithEllipsis"
      style={{ maxWidth: maxWidth }}
    >
      <Tooltip
        position={position}
        disabled={disabled || !isTruncated}
        trigger={<div className="ContentWithTooltip-child">{children}</div>}
        content={content}
        breakLine={breakLine}
      />
    </div>
  );
}

ContentWithTooltip.propTypes = {
  children: PropTypes.element,
  tooltip: PropTypes.shape({
    position: PropTypes.string,
    content: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  maxWidth: PropTypes.string,
  breakLine: PropTypes.bool,
};

ContentWithTooltip.defaultProps = {
  tooltip: {
    position: "top center",
    content: null,
    disabled: false,
  },
  maxWidth: "190px",
  breakLine: false,
};

export default ContentWithTooltip;
