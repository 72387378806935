import React from "react";
import PropTypes from "prop-types";

function TableFooter({ amount, description }) {
  return (
    <div className="Table-footer">
      <div className="label"> Total </div>
      <div className="resume">
        <div className="amount">{amount}</div>
        <div className="description">{description}</div>
      </div>
    </div>
  );
}

TableFooter.propTypes = {
  amount: PropTypes.string,
  description: PropTypes.string,
};

TableFooter.defaultProps = {
  amount: "",
  description: "",
};

export default TableFooter;
