import React, { Fragment, useState } from "react";
import SearchWrapper from "components/shared/form/SearchWrapper";
import SelectList from "components/shared/form/SearchAndSelect/SelectList";
import FormElement from "components/shared/form/FormElement";
import SearchBoxInput from "./SearchBoxInput";
import Api from "lib/Api";
import useOrganizations from "store/organizations/hooks";

import { useQuery } from "react-query";
import { isEmpty } from "ramda";

function SearchBox({
  clearSearch,
  label,
  onChange,
  maxHeight,
  placeHolder,
  name,
}) {
  const {
    currentOrganization: { name: organizationName },
  } = useOrganizations();
  const [searchTerm, setSearchTerm] = useState("");

  const { isLoading, data } = useQuery(
    ["GlobalSearch", searchTerm],
    async () => {
      if (isEmpty(searchTerm)) return;

      const sanitizedQuery = searchTerm.replace(/[^\d\sa-z]/gi, "");
      const {
        data: {
          count,
          results: { companies, deals, investments, contacts },
        },
      } = await Api.get(`search/all/${sanitizedQuery}`);

      return {
        count,
        list: [
          { data: companies, title: "My Companies" },
          { data: investments, title: "Investments" },
          { data: deals, title: "Deals" },
          { data: contacts, title: "Contacts" },
        ],
      };
    },
  );

  const placeholderText = "Search " + organizationName;
  return (
    <FormElement
      name={name}
      label={label}
      extraClassnames="SearchAndSelect SearchBox js-stopGlobalShortcutPropagation large"
    >
      <SearchWrapper
        onChange={setSearchTerm}
        onReset={clearSearch}
        maxHeight={maxHeight}
      >
        {(wrapperProps) => (
          <Fragment>
            <SearchBoxInput
              {...wrapperProps}
              placeHolder={placeHolder}
              onItemChange={onChange}
              isFetching={isLoading}
              name={name}
              placeholderText={placeholderText}
            />
            {wrapperProps.open && !isLoading && (
              <SelectList
                {...wrapperProps}
                count={data.count}
                list={data.list}
              />
            )}
          </Fragment>
        )}
      </SearchWrapper>
    </FormElement>
  );
}

export default SearchBox;
