import { connect } from "react-redux";
import UploadDocuments from "components/shared/UploadDocuments";
import { cleanDocumentUploads } from "actions/synchronous/documentUploads";
import { closeModal } from "store/modal";

function mapDispatchToProps(dispatch, { afterUpload }) {
  return {
    onDoneButtonClick: (filepickerData) => {
      if (afterUpload) {
        afterUpload(filepickerData);
      }
      dispatch(closeModal());
      dispatch(cleanDocumentUploads());
    },
    onCancelButtonClick: () => {
      dispatch(closeModal());
      dispatch(cleanDocumentUploads());
    },
  };
}

export default connect(null, mapDispatchToProps)(UploadDocuments);
