import Api from "lib/Api";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import useViewIds from "lib/hooks/useViewIds";

export function useUpdateContact() {
  const { organizationUsername } = useParams();
  const { initialCompanyId: companyId } = useViewIds();
  const queryClient = useQueryClient();

  return useMutation(
    async ({ contactId, formData }) => {
      return await Api.patch(`/v2/contacts/${contactId}`, {
        contact: formData,
      });
    },
    {
      onSuccess: ({ data: updatedContact }, { contactId }) => {
        queryClient.setQueryData(
          ["Contacts", organizationUsername, contactId],
          {
            ...updatedContact,
          },
        );
        updateCompanyView({
          queryClient,
          organizationUsername,
          companyId,
          updatedContact,
        });
      },
    },
  );
}

function updateCompanyView({
  queryClient,
  organizationUsername,
  companyId,
  updatedContact,
}) {
  const key = ["Company", organizationUsername, companyId];
  const companyView = queryClient.getQueryData(key);

  const replaceContact = (contacts) =>
    contacts.map((contact) =>
      contact.id == updatedContact.id ? updatedContact : contact,
    );

  if (companyView?.employees || companyView?.referrers) {
    queryClient.setQueryData(key, {
      ...companyView,
      employees: replaceContact(companyView.employees),
      referrers: replaceContact(companyView.referrers),
    });
  }
}
