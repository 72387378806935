import useCurrentUser from "hooks/useCurrentUser";
import React from "react";
import { useLocation, Navigate } from "react-router-dom";

function privatePage(Component) {
  const PrivatePage = (props) => {
    const location = useLocation();
    const currentUser = useCurrentUser();

    if (currentUser) {
      return <Component {...props} />;
    } else {
      return (
        <Navigate
          to="/login"
          replace
          state={{
            nextPathName: location.pathname,
          }}
        />
      );
    }
  };

  PrivatePage.displayName = `PrivatePage(${Component.name})`;
  return PrivatePage;
}

export default privatePage;
