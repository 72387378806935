import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import Api from "lib/Api";

function removeContactDocument(oldData, deletedDocument) {
  return {
    ...oldData,
    documents: oldData.documents.filter(
      (document) => document.id !== deletedDocument.id,
    ),
  };
}

export function useRemoveContactDocument() {
  const queryClient = useQueryClient();
  const { organizationUsername } = useParams();

  return useMutation(
    ({ document }) => Api.delete(`/documents/${document.id}`),
    {
      onSuccess: (_, { document: deletedDocument, contactId }) => {
        return queryClient.setQueryData(
          ["Contacts", organizationUsername, contactId],
          (oldData) => removeContactDocument(oldData, deletedDocument),
        );
      },
    },
  );
}
