export const CUSTOM_RANGE_VALUE = "customRange";

export const RADIO_DATE_OPTIONS = [
  {
    value: "last30",
    label: "Last 30 days",
  },
  {
    value: "last60",
    label: "Last 60 days",
  },
  {
    value: "last90",
    label: "Last 90 days",
  },
  {
    value: "quarterToDate",
    label: "QTD",
  },
  {
    value: "yearToDate",
    label: "YTD",
  },
  {
    value: CUSTOM_RANGE_VALUE,
    label: "Dates between",
  },
];

export default {
  CUSTOM_RANGE_VALUE,
  RADIO_DATE_OPTIONS,
};
