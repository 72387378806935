import { Icon as SemanticIcon } from "@ableco/semantic-ui-react";
import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "semantic-ui-css/components/icon.css";
import "./Icon.scss";

function Icon(props) {
  const {
    size,
    extraClassnames,
    className,
    icon,
    origin,
    ...iconProps
  } = props;
  const iconClassname = classnames(className, {
    [icon]: !!icon,
    Icon: origin === "custom",
    RemixIcon: origin === "remixIcon",
    [extraClassnames]: !!extraClassnames,
  });

  return <SemanticIcon className={iconClassname} size={size} {...iconProps} />;
}

Icon.propTypes = {
  size: PropTypes.oneOf(["mini", "tiny", "small", "large", "big"]),
  icon: PropTypes.string,
  origin: PropTypes.oneOf(["custom", "remixIcon"]),
  extraClassnames: PropTypes.string,
  className: PropTypes.string,
};

Icon.defaultProps = {
  size: "small",
  origin: "remixIcon",
};
export default Icon;
