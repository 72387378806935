import PropTypes from "prop-types";
import React from "react";
import Button from "components/shared/Button";

function PrimaryButton(props) {
  const { children, ...restProps } = props;

  return (
    <Button {...restProps} behavior="primary">
      {children}
    </Button>
  );
}

PrimaryButton.propTypes = {
  children: PropTypes.node,
};

PrimaryButton.defaultProps = {
  children: null,
};

export default PrimaryButton;
