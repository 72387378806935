import { useQuery } from "react-query";
import Api from "lib/Api";

function convertArrayIntoHash(collection) {
  return collection.reduce((memo, item) => {
    memo[item.id] = item;
    return memo;
  }, {});
}

export function usePortfolios(organizationUsername) {
  return useQuery(
    ["Portfolios", organizationUsername],
    async () => {
      const { data } = await Api.get("/v2/portfolios");

      const summaryStats = convertArrayIntoHash(data.summary_stats);
      const portfoliosById = convertArrayIntoHash(data.portfolios);

      return {
        portfolios: data.portfolios,
        companiesCount: data.companies_count,
        investmentsCount: data.investments_count,
        logoUrls: data.logo_urls,
        summaryStats,
        portfoliosById,
      };
    },
    {
      staleTime: 10000,
    },
  );
}
