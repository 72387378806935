import React, { Fragment } from "react";
import PropTypes from "prop-types";
import pluralize from "pluralize";
import InviteMember from "./InviteMember";
import orderBy from "lodash/orderBy";
import RowCardList from "components_new/elements/RowCardList";
import MemberRow from "./MemberRow";
import { organizationProptype } from "PropTypes";
import useCurrentUser from "hooks/useCurrentUser";

function sortMemberships(memberships) {
  return orderBy(
    memberships,
    ["owner", "initials", "email"],
    ["desc", "asc", "asc"],
  );
}

function OrganizationMembersTable({
  currentOrganization,
  organizationMemberships,
  currentUserID,
}) {
  const sortedMemberships = sortMemberships(organizationMemberships);
  return (
    <RowCardList>
      {sortedMemberships.map((organizationMembership) => (
        <MemberRow
          key={organizationMembership.id}
          organizationMembership={organizationMembership}
          currentOrganization={currentOrganization}
          currentUserID={currentUserID}
        ></MemberRow>
      ))}
    </RowCardList>
  );
}

function OrganizationMembersHeader({
  currentOrganization,
  organizationMemberships,
  inviteOrganizationMember,
}) {
  const title = pluralize(
    "Member",
    Object.keys(organizationMemberships).length,
    true,
  );

  const showInviteForm =
    currentOrganization.is_current_user_organization_owner &&
    !currentOrganization.private;
  return (
    <div className="OrganizationMembers-header">
      <h2 className="OrganizationMembers-counter u-heading">{title}</h2>
      {showInviteForm && (
        <InviteMember
          organizationMemberships={organizationMemberships}
          inviteOrganizationMember={inviteOrganizationMember}
          organizationUsername={currentOrganization.username}
        />
      )}
    </div>
  );
}

function OrganizationMembers({
  inviteOrganizationMember,
  currentOrganization,
}) {
  const { id: currentUserID } = useCurrentUser();
  const {
    organization_memberships: organizationMemberships,
  } = currentOrganization;

  return (
    <div className="OrganizationMembers u-row">
      <Fragment>
        <OrganizationMembersHeader
          organizationMemberships={organizationMemberships}
          inviteOrganizationMember={inviteOrganizationMember}
          currentOrganization={currentOrganization}
          currentUserID={currentUserID}
        />
        <OrganizationMembersTable
          organizationMemberships={organizationMemberships}
          currentOrganization={currentOrganization}
          currentUserID={currentUserID}
        />
      </Fragment>
    </div>
  );
}

OrganizationMembers.propTypes = {
  inviteOrganizationMember: PropTypes.func.isRequired,
  currentUserID: PropTypes.number.isRequired,
  currentOrganization: organizationProptype.isRequired,
};

export default OrganizationMembers;
