import React, { useState, useEffect } from "react";
import FormGroup from "components_new/elements/FormGroup";
import NumberInput from "components_new/atoms/NumberInput";
import SplitSelect from "components_new/atoms/SplitSelect";
import { isEmpty, isEqual } from "lodash";

const SELECTION_OPTIONS = [
  {
    key: "per share",
    text: "Per Share",
    value: "per_share",
  },
  {
    key: "total",
    text: "Total",
    value: "total",
  },
];

function Valuation({
  title = "Valuation",
  formData,
  handleChange,
  subtitle = "",
  required = false,
  isExpanded = false,
}) {
  const { fully_diluted_shares, valuation, current_pps } = formData;

  const [init, setInit] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(SELECTION_OPTIONS[0]);
  const [storedDropdownValue, setStoredDropdownValue] = useState(null);
  const [onMountValuation, setOnMountValution] = useState("");
  const [onMountPps, setOnMountPps] = useState("");
  const [onMountFullyDiluted, setOnMountFullyDiluted] = useState("");

  useEffect(() => {
    const initialize = setTimeout(() => {
      setInit(true);
    }, 500);
    return () => clearTimeout(initialize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setOnMountDisplayValue = (valuation, pps) => {
    if (!isEmpty(pps) || isEmpty(valuation)) {
      setStoredDropdownValue(SELECTION_OPTIONS[0]);
    } else {
      setDropdownValue(SELECTION_OPTIONS[1]);
      setStoredDropdownValue(SELECTION_OPTIONS[1]);
    }
  };

  useEffect(() => {
    if (!isEmpty(current_pps) && !isEqual(current_pps, onMountPps)) {
      setOnMountPps(current_pps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current_pps]);

  useEffect(() => {
    if (!isEmpty(valuation) && !isEqual(valuation, onMountValuation)) {
      setOnMountValution(valuation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuation]);

  useEffect(() => {
    if (
      fully_diluted_shares > 0 &&
      !isEqual(fully_diluted_shares, onMountFullyDiluted)
    ) {
      setOnMountFullyDiluted(fully_diluted_shares);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fully_diluted_shares]);

  useEffect(() => {
    if (
      !init &&
      (!isEmpty(onMountValuation) || !isEmpty(onMountPps)) &&
      isEqual(onMountValuation, valuation) &&
      isEqual(onMountPps, current_pps)
    ) {
      setOnMountDisplayValue(onMountValuation, onMountPps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onMountPps, onMountValuation]);

  const resetRelatedValue = (option) => {
    let currentField;
    if (option === "total") {
      currentField = "valuation";
      if (current_pps > 0) {
        handleChange("current_pps", "");
      }
    } else {
      currentField = "current_pps";
      if (valuation > 0) {
        handleChange("valuation", "");
      }
    }
    return currentField;
  };

  const handleAmountChange = ({ input, selection }) => {
    const { value: selectionValue } = selection;
    if (
      !isEmpty(selectionValue) &&
      !isEqual(storedDropdownValue, selectionValue)
    ) {
      const newVal = SELECTION_OPTIONS.find(
        (object) => object.value === selectionValue,
      );
      storedDropdownValue && resetRelatedValue(storedDropdownValue.value);
      setDropdownValue(newVal);
      setStoredDropdownValue(newVal);
    }
    if (init) {
      const fieldName = resetRelatedValue(selectionValue);
      handleChange(fieldName, input);
    }
  };

  const handleSharesChange = (value) => {
    resetRelatedValue(dropdownValue.value);
    handleChange("fully_diluted_shares", value);
  };

  const isExpandedOnMount =
    isExpanded ||
    !isEmpty(onMountValuation) ||
    !isEmpty(onMountPps) ||
    onMountFullyDiluted > 0;

  const splitSelectPrecision = dropdownValue.value === "total" ? 2 : 4;

  const splitSelectPlaceholder =
    dropdownValue.value === "total" ? "0.00" : "0.0000";

  const splitSelectValue =
    dropdownValue.value === "total" ? valuation : current_pps;

  return (
    <FormGroup
      extraClassnames="ValuationGroup"
      title={title}
      required={required}
      subtitle={subtitle}
      isExpanded={isExpandedOnMount}
    >
      <SplitSelect
        inputProps={{
          name: "amount",
          label: "Amount",
          decimalLimit: splitSelectPrecision,
          placeholder: splitSelectPlaceholder,
        }}
        dropdownProps={{
          options: SELECTION_OPTIONS,
          defaultValue: dropdownValue,
        }}
        value={splitSelectValue}
        extraClassnames="on-sidebar"
        onChange={(_, input) => handleAmountChange(input)}
        size="tiny"
      />
      <NumberInput
        name="fully_diluted_shares"
        label="Shares outstanding"
        defaultValue={fully_diluted_shares}
        placeholder="e.g. 1,000,000"
        value={fully_diluted_shares}
        onChange={(value) => handleSharesChange(value)}
        decimalLimit={2}
        size="tiny"
      />
    </FormGroup>
  );
}

export default Valuation;
