import React from "react";
import Table from "./DocumentViewerTable";
import DocumentViewerRow from "./DocumentViewerRow";
import { ModalBody, ModalHeader } from "components/shared/Modal";
import { createColumns, emptyDocumentItem } from "./config";

const columns = createColumns();
const emptyItem = emptyDocumentItem();

function DocumentsViewer({
  title,
  documents,
  bulkUpdate,
  onDone,
  isUpdating,
  subTitle,
  currentOrganizationUsername,
}) {
  return (
    <div className="DocumentsViewer">
      <ModalHeader title={title} subTitle={subTitle} />
      <ModalBody type="tables">
        <div className="DocumentsTable">
          <Table
            bulkUpdate={bulkUpdate}
            collection={documents}
            columns={columns}
            deleteButtonText="Delete File"
            deleteTitle="Delete this File?"
            isUpdating={isUpdating}
            emptyItem={emptyItem}
            onDone={onDone}
            renderRow={(props) => (
              <DocumentViewerRow
                currentOrganizationUsername={currentOrganizationUsername}
                {...props}
              />
            )}
            viewMoreTooltipContent="View entire files"
          />
        </div>
      </ModalBody>
    </div>
  );
}

export default DocumentsViewer;
