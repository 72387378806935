import React, { Fragment, useCallback, useState } from "react";
import PrimaryButton from "components/shared/PrimaryButton";
import Api from "lib/Api";

function ErrorModalDevTool() {
  const [didRaiseError, setDidRaiseError] = useState(false);
  const [isLoadingError, setIsLoadingError] = useState(false);

  const raiseError = useCallback(() => {
    setDidRaiseError(true);
  }, []);

  const raiseServerError = useCallback(async () => {
    setIsLoadingError(true);
    try {
      await Api.get("/error", { withoutOrganization: true });
    } finally {
      setIsLoadingError(false);
    }
  }, []);

  if (didRaiseError) {
    return <span>{this.will.cause.an.error}</span>;
  }

  return (
    <Fragment>
      <div className="DevHome-content">
        <p>
          This will raise a JS error on the rendering process. (Unrecoverable,
          typically a programming error)
        </p>
        <div>
          <PrimaryButton onClick={raiseError}>Raise Error</PrimaryButton>
        </div>
      </div>
      <div className="DevHome-content">
        <p>
          This will call an API endpoint that will raise an error. This is how
          we react to <code>500</code> errors from the backend.
        </p>
        <div>
          <PrimaryButton onClick={raiseServerError}>
            {isLoadingError ? <>Loading error...</> : <>Raise Server Error</>}
          </PrimaryButton>
        </div>
      </div>
    </Fragment>
  );
}

export default ErrorModalDevTool;
