import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Label as SemanticLabel } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/label.css";
import "./Label.scss";

function Label(props) {
  const { content, size, type, ...labelProps } = props;
  const labelClassname = classnames("Label", { [type]: !!type });

  return (
    <SemanticLabel
      className={labelClassname}
      content={content}
      size={size}
      {...labelProps}
    />
  );
}

Label.propTypes = {
  content: PropTypes.string,
  alternate: PropTypes.bool,
  type: PropTypes.oneOf(["alternate", "simple"]),
  size: PropTypes.oneOf(["mini", "tiny", "small"]),
};

export default Label;
