import Api from "lib/Api";
import { REQUEST_USER_CONTACTS, RECEIVE_USER_CONTACTS } from "./actionTypes";

export function requestUserContacts() {
  return { type: REQUEST_USER_CONTACTS };
}

export function receiveUserContacts(userContacts) {
  return { type: RECEIVE_USER_CONTACTS, userContacts };
}

export function fetchUserContacts() {
  return () => {
    return Api.getCollection("userContacts", "/user_contacts", {
      withoutOrganization: true,
    });
  };
}
