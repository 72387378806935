import { isEmpty } from "lodash";

const buildEmailsData = (emails) => {
  const nextEmails = [];

  emails.forEach((element) => {
    if (isEmpty(element.value) || nextEmails.includes(element.value)) {
      return;
    }

    nextEmails.push(element.value);
  });

  return nextEmails.map((value, key) => ({ key, value }));
};

const buildRolesData = (roles) => {
  const isEmptyRoles = roles.every(
    (role) => isEmpty(role.company) && isEmpty(role.company_titles),
  );

  return isEmptyRoles ? null : roles;
};

export const sanitizeContactData = (contactData) => {
  const { roles_attributes: roles, emails } = contactData;

  return {
    ...contactData,
    roles_attributes: buildRolesData(roles),
    emails: buildEmailsData(emails),
  };
};
