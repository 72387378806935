import React from "react";

function NoteEvent({ content }) {
  return (
    <div className="NoteEvent">
      <h3 className="u-contentBold">Notes </h3>
      <div className="NoteEvent-content">{content}</div>
    </div>
  );
}

export default NoteEvent;
