import moment from "moment";
import { displayDateFormat } from "config/dateInput";

export default function addFakeInitialPoint(
  chartPoints,
  initialPointDifferenceAmount,
  initialPointDifferenceTypePeriod,
) {
  const initialDate = moment
    .utc(moment(chartPoints[0].x).format(displayDateFormat), displayDateFormat)
    .add(1, "day")
    .subtract(initialPointDifferenceAmount, initialPointDifferenceTypePeriod)
    .valueOf();

  const initialFakePoint = [
    {
      data: {
        fake_point: true,
      },
      x: initialDate,
      y: 0,
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
  ];

  return initialFakePoint.concat(chartPoints);
}
