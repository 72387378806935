import { signUpRequestError } from "actions/synchronous/signUpRequestForm";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import Api from "lib/Api";
import { useState } from "react";

async function signUpRequest(signUpForm) {
  const { data } = await Api.post("/access_requests", signUpForm, {
    withoutOrganization: true,
    headers: {},
    params: {},
  });
  return data;
}

export default function useSignUp() {
  const { mutate } = useMutation(signUpRequest);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [signUpSuccess, setSignUpSuccess] = useState(false);
  const dispatch = useDispatch();

  const handleError = (response, data) => {
    if (response.status === 422) {
      dispatch(signUpRequestError(data.errors));
    } else {
      throw response;
    }
  };

  const doSignUp = async (formData) => {
    setIsSubmitting(true);
    await mutate(formData, {
      onSuccess: () => setSignUpSuccess(true),
      onError: (error) => handleError(error.response, error.data),
    });
    setIsSubmitting(false);
  };

  return { doSignUp, isSubmitting, signUpSuccess };
}
