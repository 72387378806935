import React, { useMemo, useContext, useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  buildPrefilledInvestmentData,
  buildPrefilledCompanyData,
} from "lib/globalActionsHelper";

import AuthenticationContext from "components/Mighty/AuthenticationContext";
import getMessage from "components_new/lib/Messages";
import useOrganizations from "store/organizations/hooks";
import useCurrentUser from "hooks/useCurrentUser";
import NoteForm from "./NoteForm";
import Loader from "components/shared/Loader";

import { useDeal } from "components_new/views/Deals/hooks/queries/useDeal";
import { useCompany } from "components_new/views/Company/hooks/queries/useCompany";
import { useInvestment } from "components_new/SidebarComponents/views/InvestmentView/hooks/useInvestment";
import { useCreateNote } from "components_new/views/Notes/hooks/mutations/useCreateNote";
import { useEditNote } from "components_new/views/Notes/hooks/mutations/useEditNote";
import { useDeleteNote } from "components_new/views/Notes/hooks/mutations/useDeleteNote";
import { updateUserAccountInformation } from "store/users/actions";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";

function NoteFormWrapper({
  initialHoldingId,
  initialCompanyId,
  initialDealId,
  taskId,
  taskAttachments,
  closeSidebar,
  toogleSize,
}) {
  const dispatch = useDispatch();
  const { addToast } = useSetToast();
  const { setCurrentUser } = useContext(AuthenticationContext);
  const {
    currentOrganizationUsername,
    currentOrganization: { pipeline_id: pipelineId },
  } = useOrganizations();

  const {
    data: investmentData,
    isLoading: isLoadingInvestment,
  } = useInvestment(initialHoldingId);

  const { data: dealData, isLoading: isLoadingDeal } = useDeal(
    pipelineId,
    initialDealId,
  );

  const { data: companyData, isLoading: isLoadingCompany } = useCompany(
    initialCompanyId,
  );

  const { mutateAsync: createNote } = useCreateNote();
  const { mutateAsync: editNote } = useEditNote();
  const { mutateAsync: deleteNote } = useDeleteNote();

  const currentUser = useCurrentUser();

  const updateUserAccountInformationCallback = useCallback(
    (id, formData) => {
      return dispatch(updateUserAccountInformation(id, formData)).then(
        ({ user }) => {
          setCurrentUser(user);
        },
      );
    },
    [dispatch, setCurrentUser],
  );

  const prefilledSelection = useMemo(() => {
    if (initialCompanyId) {
      return buildPrefilledCompanyData(companyData?.companyDetails);
    }

    if (initialHoldingId) {
      return buildPrefilledInvestmentData(
        investmentData?.details,
        investmentData?.company,
      );
    }

    if (initialDealId) {
      return {
        ...dealData?.deal,
        type: "deal",
      };
    }
  }, [
    initialCompanyId,
    initialDealId,
    initialHoldingId,
    companyData,
    dealData,
    investmentData,
  ]);

  const showConfirmationMessage = () => addToast(getMessage("addedNotes"));

  if (isLoadingCompany || isLoadingInvestment || isLoadingDeal) {
    return <Loader />;
  }

  return (
    <NoteForm
      prefilledSelection={prefilledSelection}
      taskId={taskId}
      taskAttachments={taskAttachments}
      createNote={createNote}
      editNote={editNote}
      deleteNote={deleteNote}
      closeSidebar={closeSidebar}
      toogleSize={toogleSize}
      currentUser={currentUser}
      showConfirmationMessage={showConfirmationMessage}
      currentOrganizationUsername={currentOrganizationUsername}
      updateUserAccountInformation={updateUserAccountInformationCallback}
    />
  );
}
export default NoteFormWrapper;
