import { connect } from "react-redux";
import React from "react";
import OrganizationDetails from "./OrganizationDetails";
import { isSubmitting } from "store/domain/selectors";
import { flash } from "actions/synchronous/flash";
import { openInModal, ModalNames } from "store/modal";
import View from "components/shared/View";
import {
  fetchOrganizations,
  updateOrganization,
} from "store/organizations/actions";
import useOrganizations from "store/organizations/hooks";
import useCurrentUser from "hooks/useCurrentUser";

function RenderOrganizationDetail({ ...restProps }) {
  const { currentOrganization } = useOrganizations();
  const currentUser = useCurrentUser();
  const isOwner = currentOrganization.owner_id == currentUser.id;

  return (
    <View
      mountAction={() => restProps.fetchOrganizations()}
      attributeToVerify={currentOrganization.id}
      attributeChangedAction={() => restProps.fetchOrganizations()}
      component={OrganizationDetails}
      isOwner={isOwner}
      organization={currentOrganization}
      {...restProps}
    />
  );
}

function mapStateToProps(state) {
  return {
    isSubmitting: isSubmitting(state, "updateOrganization"),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateOrganization: (organizationId, formData) =>
      dispatch(updateOrganization(organizationId, formData)).then(() => {
        dispatch(flash("updatedForm"));
      }),
    openInvalidLogoModal: () => dispatch(openInModal(ModalNames.invalidLogo)),
    fetchOrganizations: () => dispatch(fetchOrganizations()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RenderOrganizationDetail);
