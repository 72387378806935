import React from "react";
import CompanyIcon from "components/shared/CompanyIcon";
import classnames from "classnames";
import Link from "components_new/atoms/Link";

function CompanyLogo({ company, extraClassnames, organizationUsername }) {
  const companyLogoClassnames = classnames("CompanyLogo", {
    [extraClassnames]: !!extraClassnames,
  });
  return (
    <div className={companyLogoClassnames}>
      <Link to={`/${organizationUsername}/companies/${company.id}`}>
        <CompanyIcon src={company.logo_url} size="xlarge" />
      </Link>
    </div>
  );
}

export default CompanyLogo;
