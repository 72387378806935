import React from "react";
import TaskView from "./TaskView";

import { openSidebar as openSidebarHelper } from "store/sidebar";
import { useDispatch } from "react-redux";
import { openModal } from "store/modalV2";
import { useMarkAsRead } from "components_new/views/Queues/hooks/mutations/useMarkAsRead";
import { useMarkAsPending } from "components_new/views/Queues/hooks/mutations/useMarkAsPending";
import { useMarkAsUnread } from "components_new/views/Queues/hooks/mutations/useMarkAsUnread";
import { useSnoozeTask } from "components_new/views/Queues/hooks/mutations/useSnoozeTask";
import { useCompleteTask } from "components_new/views/Queues/hooks/mutations/useCompleteTask";
import { useSetSidebar } from "components_new/elements/SidebarWrapper/Sidebar-context";
import useOrganization from "store/organizations/hooks";

function TaskViewWrapper({
  tasks,
  filters,
  updateFilters,
  tasksType,
  isLoading,
  newTasksCount,
  fetchNewTasks,
  queueLabel,
}) {
  const dispatch = useDispatch();
  const { currentOrganizationName } = useOrganization();
  const { showSidebar } = useSetSidebar();

  const { mutateAsync: markTaskAsRead } = useMarkAsRead();
  const { mutateAsync: markTaskAsUnread } = useMarkAsUnread();
  const { mutateAsync: snoozeTask } = useSnoozeTask();
  const { mutateAsync: completeTask } = useCompleteTask();
  const { mutateAsync: markAsPending } = useMarkAsPending();

  const openSidebar = (name, title, props) =>
    dispatch(openSidebarHelper(name, title, props));

  const openRemoveTaskModal = ({ taskId, callback }) =>
    dispatch(
      openModal("confirmTaskDeletion", {
        taskId,
        callback,
      }),
    );

  const handleOpenTaskForm = (task) => showSidebar("taskForm", { task });

  return (
    <TaskView
      tasks={tasks}
      filters={filters}
      updateFilters={updateFilters}
      tasksType={tasksType}
      queueLabel={queueLabel}
      isLoading={isLoading}
      organizationName={currentOrganizationName}
      openSidebar={openSidebar}
      openRemoveTaskModal={openRemoveTaskModal}
      markTaskAsRead={markTaskAsRead}
      markTaskAsUnread={markTaskAsUnread}
      snoozeTask={snoozeTask}
      completeTask={completeTask}
      markAsPending={markAsPending}
      newTasksCount={newTasksCount}
      fetchNewTasks={fetchNewTasks}
      handleOpenTaskForm={handleOpenTaskForm}
      showSidebar={showSidebar}
    />
  );
}

export default TaskViewWrapper;
