const MESSAGES = {
  addedInvestment: {
    id: "addedInvestment",
    title: "Investment successfully added",
    urlTitle: "New Investment",
    type: "success",
  },
  addedDeal: {
    id: "addedDeal",
    title: "Deal successfully added",
    urlTitle: "New Deal",
    type: "success",
  },
  addedTask: {
    id: "addedTask",
    title: "Task successfully added",
    type: "success",
  },
  editedTask: {
    id: "editedTask",
    title: "Task successfully edited",
    type: "success",
  },
  addedValuation: {
    id: "addedValuation",
    title: "Valuation successfully added",
    type: "success",
  },
  addedFiles: {
    id: "addedFiles",
    title: "Files successfully added",
    type: "success",
  },
  addedNotes: {
    id: "addedNotes",
    title: "Notes successfully added",
    type: "success",
  },
  addedCapitalCall: {
    id: "addedCapitalCall",
    title: "Capital Call successfully added",
    urlTitle: "See Investment",
    type: "success",
  },
  addedPortfolio: {
    id: "addedPortfolio",
    title: "Portfolio successfully added",
    type: "success",
  },
  addedOrganization: {
    id: "addedOrganization",
    title: "A new organization has been successfully created",
    type: "success",
  },
  addedDistribution: {
    id: "addedDistribution",
    title: "Distribution successfully added",
    urlTitle: "See Investment",
    type: "success",
  },
  addedAcquisition: {
    id: "addedAcquisition",
    title: "Acquisition successfully added",
    urlTitle: "See Investment",
    type: "success",
  },
  copiedToClipboard: {
    id: "copiedToClipboard",
    title: "Copied to clipboard",
    type: "success",
  },
  addedContact: {
    id: "addedContact",
    title: "Contact successfully added.",
    urlTitle: "See Contacts",
    type: "success",
  },
  addedStockSplit: {
    id: "addedStockSplit",
    title: "Stock split successfully added",
    type: "success",
  },
  addedWriteOff: {
    id: "addedWriteOff",
    title: "Write-off successfully added",
    type: "success",
  },
  addedSale: {
    id: "addedSale",
    title: "Sale successfully added",
    type: "success",
  },
  addedLoanConversion: {
    id: "addedLoanConversion",
    title: "Loan conversion successfully added",
    urlTitle: "See Investment",
    type: "success",
  },
  createIntroduction: {
    id: "createIntroduction",
    title: "Introduction successfully added.",
    type: "success",
  },
  updatedCompany: {
    id: "updatedCompany",
    title: "Company successfully updated",
    type: "success",
  },
  createdCompany: {
    id: "createdCompany",
    title: "Company successfully created",
    urlTitle: "See Company",
    type: "success",
  },
  updatedDeal: {
    id: "updatedDeal",
    title: "Deal successfully updated",
    type: "success",
  },
  commitToDeal: {
    id: "commitToDeal",
    title: "Commitment successfully logged",
    type: "success",
  },
  passFromDeal: {
    id: "passFromDeal",
    title: "Deal successfully passed",
    type: "success",
  },
  editCommitment: {
    id: "editCommitment",
    title: "Commitment information successfully updated",
    type: "success",
  },
  undoCommitment: {
    id: "undoCommitment",
    title: "Commitment successfully undone",
    type: "success",
  },
  undoPass: {
    id: "undoPass",
    title: "Pass successfully undone",
    type: "success",
  },
  editPassedDeal: {
    id: "editPassedDeal",
    title: "Pass successfully updated",
  },
  createSnapshot: {
    id: "createSnapshot",
    title: "Snapshot successfully created",
    type: "success",
  },
  editSnapshot: {
    id: "editSnapshot",
    title: "Snapshot successfully updated",
    type: "success",
  },
  createStage: {
    id: "createStage",
    title: "Stage successfully added",
    type: "success",
  },
  editStage: {
    id: "editStage",
    title: "Stage successfully updated",
    type: "success",
  },
  deleteStage: {
    id: "deleteStage",
    title: "Stage successfully deleted",
    type: "success",
  },
  updateReferrer: {
    id: "updateReferrer",
    title: "Referrer details have been updated.",
    type: "success",
  },
  deleteReferrer: {
    id: "deleteReferrer",
    title: "Contact was successfully deleted.",
    type: "success",
  },
  deleteInvestment: {
    id: "deleteInvestment",
    title: "Investment successfully deleted",
    type: "success",
  },
  deleteDeal: {
    id: "deleteDeal",
    title: "Deal successfully deleted",
    type: "success",
  },
  deleteValuation: {
    id: "deleteValuation",
    title: "Valuation successfully deleted",
    type: "success",
  },
  deleteFile: {
    id: "deleteFile",
    title: "File successfully deleted",
    type: "success",
  },
  deleteNote: {
    id: "deleteNote",
    title: "Note successfully deleted",
    type: "success",
  },
  deleteCapitalCall: {
    id: "deleteCapitalCall",
    title: "Capital call successfully deleted",
    type: "success",
  },
  deleteDistribution: {
    id: "deleteDistribution",
    title: "Distribution successfully deleted",
    type: "success",
  },
  deletePortfolio: {
    id: "deletePortfolio",
    title: "Fund successfully deleted",
    type: "success",
  },
  updateInvestment: {
    id: "updateInvestment",
    title: "Investment successfully updated",
    type: "success",
  },
  updateValuation: {
    id: "updateValuation",
    title: "Valuation successfully updated",
    type: "success",
  },
  updateFiles: {
    id: "updateFiles",
    title: "Files successfully updated",
    type: "success",
  },
  updateNotes: {
    id: "updateNotes",
    title: "Note successfully updated",
    type: "success",
  },
  updateCapitalCall: {
    id: "updateCapitalCall",
    title: "Capital call successfully updated",
    type: "success",
  },
  updatePortfolio: {
    id: "updatePortfolio",
    title: "Portfolio successfully updated",
    type: "success",
  },
  updateUserAvatar: {
    id: "updateUserAvatar",
    title: "User avatar successfully uploaded",
    type: "success",
  },
  updateUserInformation: {
    id: "updateUserInformation",
    title: "User information successfully updated",
    type: "success",
  },
  updatePassword: {
    id: "updatePassword",
    title: "Your password successfully updated",
    type: "success",
  },
  updateNotifications: {
    id: "updateNotifications",
    title: "Your notifications successfully updated",
    type: "success",
  },
  addCoInvestment: {
    id: "addCoInvestment",
    title: "Co-investment successfully added",
    type: "success",
  },
  updateCoInvestment: {
    id: "updateCoInvestment",
    title: "Co-investment successfully updated",
    type: "success",
  },
  deleteCoInvestment: {
    id: "deleteCoInvestment",
    title: "Co-investment successfully deleted",
    type: "success",
  },
  completeTask: {
    id: "completeTask",
    title: "Your Task successfully completed",
    type: "success",
  },
  pendingTask: {
    id: "completeTask",
    title: "Your Task successfully move to pending",
    type: "success",
  },
  addSuggestedTag: {
    id: "addSuggestedTag",
    title: "Suggested Tags successfully added",
    type: "success",
  },
};

function getMessage(type, options = {}) {
  const messageTemplate = MESSAGES[type];
  return { ...messageTemplate, ...options };
}

export default getMessage;
