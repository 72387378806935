import React from "react";
import ActivityEvent from "components/shared/ActivityEvent";
import ActivityObject from "components/shared/ActivityObject";
import { uniqueId } from "lodash";
import PropTypes from "prop-types";
import {
  investmentEventPropType,
  companyPropType,
  investmentPropType,
} from "PropTypes";

const eventTypes = () => ({
  // TODO: These names were suggested by the design, please refactor for consistency's sake
  added_to_portfolio: { name: "addedToPortfolio", layout: [ActivityEvent] },
  capital_call_created: {
    name: "capitalCall",
    layout: [ActivityEvent, ActivityObject],
  },
  capital_call_deleted: {
    name: "deletedCapitalCall",
    layout: [ActivityEvent, ActivityObject],
  },
  capital_call_updated: {
    name: "updatedCapitalCall",
    layout: [ActivityEvent, ActivityObject],
  },
  investment_created: {
    name: "createdInvestment",
    layout: [ActivityEvent, ActivityObject],
  },
  investment_moved: {
    name: "movedAnInvestment",
    layout: [ActivityEvent, ActivityObject],
  },
  investment_note_created: {
    name: "addedANote",
    layout: [ActivityEvent, ActivityObject],
  },
  investment_updated: {
    name: "updatedInvestment",
    layout: [ActivityEvent, ActivityObject],
  },
  sale_logged: { name: "saleLogged", layout: [ActivityEvent, ActivityObject] },
  valuation_created: {
    name: "createdValuation",
    layout: [ActivityEvent, ActivityObject],
  },
  valuation_deleted: {
    name: "deletedValuation",
    layout: [ActivityEvent, ActivityObject],
  },
  valuation_updated: {
    name: "updatedValuation",
    layout: [ActivityEvent, ActivityObject],
  },
  company_field_added: {
    name: "addedCompanyField",
    layout: [ActivityEvent, ActivityObject],
  },
  company_field_removed: {
    name: "removedCompanyField",
    layout: [ActivityEvent, ActivityObject],
  },
  company_field_changed: {
    name: "changedCompanyField",
    layout: [ActivityEvent, ActivityObject],
  },
  loan_converted: {
    name: "convertedLoan",
    layout: [ActivityEvent, ActivityObject],
  },
  company_note_created: {
    name: "addedANoteToCompany",
    layout: [ActivityEvent, ActivityObject],
  },
  document_moved: {
    name: "movedDocument",
    layout: [ActivityEvent, ActivityObject],
  },
  document_updated: {
    name: "updatedDocument",
    layout: [ActivityEvent, ActivityObject],
  },
  documents_uploaded: {
    name: "uploadedDocuments",
    layout: [ActivityEvent, ActivityObject],
  },
  document_removed: { name: "removedDocuments", layout: [ActivityEvent] },
  company_deleted: { name: "companyDeleted", layout: [ActivityEvent] },
  company_purchased: {
    name: "purchasedCompany",
    layout: [ActivityEvent, ActivityObject],
  },
  company_acquired: {
    name: "acquiredCompany",
    layout: [ActivityEvent, ActivityObject],
  },
  all_documents_removed: {
    name: "removedAllDocuments",
    layout: [ActivityEvent],
  },
  investment_deleted: { name: "deletedInvestment", layout: [ActivityEvent] },
  distribution_logged: {
    name: "loggedDistribution",
    layout: [ActivityEvent, ActivityObject],
  },
  investment_acquisition: {
    name: "loggedAcquisition",
    layout: [ActivityEvent, ActivityObject],
  },
  portfolio_deleted: { name: "deletedPortfolio", layout: [ActivityEvent] },
  organization_username_updated: {
    name: "organizationUsernameUpdated",
    layout: [ActivityEvent, ActivityObject],
  },
  organization_name_updated: {
    name: "organizationNameUpdated",
    layout: [ActivityEvent, ActivityObject],
  },
  member_created: { name: "addOrganizationMember", layout: [ActivityEvent] },
  member_deleted: {
    name: "deletedOrganizationMember",
    layout: [ActivityEvent],
  },
  written_off: { name: "writtenOff", layout: [ActivityEvent, ActivityObject] },
});

const ActivityItem = ({ values, type, organizationUsername }) => {
  if (!eventTypes()[type]) {
    return null;
  }
  return (
    <div className="ActivityItem">
      {eventTypes()[type].layout.map((Component) => (
        <Component
          key={uniqueId()}
          type={eventTypes()[type].name}
          values={values}
          organizationUsername={organizationUsername}
        />
      ))}
    </div>
  );
};

ActivityItem.propTypes = {
  type: PropTypes.string.isRequired,
  values: PropTypes.shape({
    event: investmentEventPropType,
    investment: investmentPropType,
    company: companyPropType,
  }).isRequired,
  organizationUsername: PropTypes.string.isRequired,
};

export default ActivityItem;
