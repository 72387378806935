import React from "react";
import PropTypes from "prop-types";
import Button from "components_new/atoms/Button";
import Icon from "components_new/atoms/Icon";
import Header from "components_new/atoms/Header";
import Tooltip from "components_new/atoms/Tooltip";
import BasicLabel from "components_new/atoms/BasicLabel";
import Text from "components_new/atoms/Text";
import { calculateStagedAtText } from "components_new/views/Deals/ActiveDeals/ActiveDealCard/config";
import { formatPriceInputAbbreviated } from "lib/abbreviatedFormat";
import { dealProptype } from "PropTypes";
import ReplyByDatePicker from "../ReplyByDatePicker";
import "./ActiveDealHeader.scss";

function buildDealTitle(deal) {
  const name = deal.round_name ? deal.round_name : deal.name;
  const allocation = deal.allocation
    ? ` (${formatPriceInputAbbreviated(deal.allocation, {
        precision: 2,
        noTrailingZeros: true,
      })})`
    : "";
  return name + " " + allocation;
}

function buildRaisedAndValuation(deal) {
  if (!deal.amount_raised_in_round && !deal.company_valuation) {
    return null;
  }
  const amountRaised = formatPriceInputAbbreviated(
    deal.amount_raised_in_round,
    {
      precision: 0,
    },
  );
  const valuation = formatPriceInputAbbreviated(deal.company_valuation, {
    precision: 0,
  });
  const preOrPost =
    deal.company_valuation_type === "Pre-money" ? "pre" : "post";
  // If just amount raised, “Raising $XXM”
  if (deal.amount_raised_in_round && !deal.company_valuation) {
    return `Raising ${amountRaised}`;
  }
  // If just a valuation, “Raising on $YYM pre”
  if (deal.company_valuation && !deal.amount_raised_in_round) {
    return `Raising on ${valuation} ${preOrPost}`;
  }
  return `Raising ${amountRaised} on ${valuation} ${preOrPost}`;
}

function ActiveDealHeader({
  deal,
  dealStage,
  pipelineId,
  openDeleteDealModal,
  openEditDealForm,
  updateDeal,
}) {
  const shouldDisableOption = !(deal.status === "active");
  return (
    <div className="SelectedDeal-Details-Header">
      <div className="SelectedDeal-Details-Header-Column-Alpha">
        <Header as="h2" extraClassName="SelectedDeal-Details-Header-Title">
          {buildDealTitle(deal)}
        </Header>
        <div className="SelectedDeal-Details-Header-Labels">
          {deal.is_follow_on && (
            <BasicLabel type="primary">Follow-on</BasicLabel>
          )}
        </div>
        <div className="SelectedDeal-Details-Header-SubActions">
          <Tooltip
            position="top center"
            pinned
            style={{ top: "-20px" }}
            trigger={
              <Button
                aria-label="Edit Deal"
                size="mini"
                className="transparent"
                onClick={openEditDealForm}
              >
                <Icon icon="ri-pencil-line" size="tiny" />
              </Button>
            }
            content="Edit Deal"
          />
          <Tooltip
            position="top center"
            pinned
            style={{ top: "-20px" }}
            trigger={
              <Button
                aria-label="Delete Deal"
                size="mini"
                className="transparent"
                disabled={shouldDisableOption}
                onClick={() => openDeleteDealModal(deal.id)}
              >
                <Icon icon="ri-delete-bin-line" size="tiny" />
              </Button>
            }
            content="Delete Deal"
          />
        </div>
        <div className="SelectedDeal-Details-Header-Financing-Details">
          <Text type="subtitle-2-regular" color="brand-light">
            {buildRaisedAndValuation(deal)}
          </Text>
        </div>
      </div>
      <div className="SelectedDeal-Details-Header-Column-Beta">
        {deal.staged_at && (
          <BasicLabel type="success">
            {dealStage.name} ({calculateStagedAtText(deal.staged_at)})
          </BasicLabel>
        )}
        {deal.status === "active" && (
          <ReplyByDatePicker
            deal={deal}
            pipelineId={pipelineId}
            updateDeal={updateDeal}
          />
        )}
      </div>
    </div>
  );
}

ActiveDealHeader.propTypes = {
  deal: dealProptype,
  dealStage: PropTypes.shape({
    name: PropTypes.string,
    staged_at: PropTypes.string,
  }),
  pipelineId: PropTypes.string,
  openDeleteDealModal: PropTypes.func,
  stagesOptions: PropTypes.arrayOf(PropTypes.object),
  updateDealStage: PropTypes.func,
  openEditDealForm: PropTypes.func,
  updateDeal: PropTypes.func,
};

export default ActiveDealHeader;
