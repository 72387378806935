import React from "react";
import PropTypes from "prop-types";
import { organizationMembershipPropType } from "PropTypes";
import Dropdown from "components/shared/Dropdown";
import DotsIcon from "-!svg-react-loader?!assets/icons/utility/dots.svg?name=DotsIcon";

function UserOrganizationMemberActions({
  userOrganizationMembership,
  openLeaveOrganizationModal,
  openRejectInvitationModal,
  removeExpiredInvitation,
  type,
}) {
  const getOrganizationMembershipActions = () => {
    const actions = {
      view: {
        text: "View Organization",
        linkTo: `/${userOrganizationMembership.organization_username}/dashboard`,
      },
      leave: {
        text: "Leave Organization",
        onClickAction: () =>
          openLeaveOrganizationModal(userOrganizationMembership),
      },
      reject: {
        text: "Reject Invitation",
        onClickAction: () =>
          openRejectInvitationModal({ userOrganizationMembership }),
      },
      remove: {
        text: "Remove Invitation",
        onClickAction: () =>
          removeExpiredInvitation(userOrganizationMembership.id),
      },
    };

    const statusActions = {
      owner: ["view"],
      accepted: ["view", "leave"],
      pending: ["reject"],
      expired: ["remove"],
    };
    return statusActions[type].map((item) => actions[item]);
  };

  return (
    <div className="OrganizationMembersTable-Actions">
      <Dropdown items={getOrganizationMembershipActions()}>
        <DotsIcon className="Button-icon" />
      </Dropdown>
    </div>
  );
}

UserOrganizationMemberActions.propTypes = {
  userOrganizationMembership: organizationMembershipPropType.isRequired,
  type: PropTypes.string.isRequired,
  openLeaveOrganizationModal: PropTypes.func.isRequired,
  openRejectInvitationModal: PropTypes.func.isRequired,
  removeExpiredInvitation: PropTypes.func.isRequired,
};

export default UserOrganizationMemberActions;
