import React, { useState, useEffect } from "react";
import CurrencyInput from "components_new/atoms/CurrencyInput";
import Dropdown from "components_new/atoms/DropdownForm";
import PropTypes from "prop-types";
import {
  perUnitNumberPrecision,
  defaultNumberPrecision,
} from "config/priceInput";
import classNames from "classnames";
import { Form } from "@ableco/semantic-ui-react";
import { isEqual, isEmpty } from "lodash";
import { dropdownOptionProptype, dropdownOptionsPropType } from "PropTypes";
import "semantic-ui-css/components/dropdown.css";
import "./SplitSelect.scss";

const PER_SHARE_PLACEHOLDER = "0.0000";
const TOTAL_PLACEHOLDER = "0.00";

function SplitSelect(props) {
  const {
    onChange,
    inputProps,
    readOnly,
    dropdownProps,
    extraClassnames,
    size,
    error,
    errorMessage,
    value = null,
  } = props;

  const { name, label, ...restInputProps } = inputProps;
  const { defaultValue, options, ...restDropdownProps } = dropdownProps;

  const [decimalLimit, setDecimalLimit] = useState(inputProps.decimalLimit);
  const [placeholder, setPlaceholder] = useState(inputProps.placeholder);
  const [currentInput, setCurrentInput] = useState("");
  const [currentSelection, setCurrentSelection] = useState(defaultValue);

  const splitSelectClassnames = classNames("SplitSelect", {
    "is-readOnly": readOnly,
    [extraClassnames]: !!extraClassnames,
    [size]: !!size,
    error: !!error,
  });

  useEffect(() => {
    if ((!isEmpty(value) || value > 0) && !isEqual(currentInput, value)) {
      setCurrentInput(parseFloat(value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (!isEqual(dropdownProps.defaultValue, currentSelection)) {
      handleSelectionChange(null, dropdownProps.defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownProps.defaultValue]);

  const handleSelectionChange = (_, itemSelected) => {
    setCurrentSelection(itemSelected);
    if (itemSelected.value === "per_share") {
      setPlaceholder(PER_SHARE_PLACEHOLDER);
      setDecimalLimit(perUnitNumberPrecision);
    }
    if (itemSelected.value === "total") {
      setPlaceholder(TOTAL_PLACEHOLDER);
      setDecimalLimit(defaultNumberPrecision);
    }
  };

  const handleInputChange = (value) => {
    setCurrentInput(value);
  };

  useEffect(() => {
    onChange(name, { input: currentInput, selection: currentSelection });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelection, currentInput]);

  return (
    <Form.Field className={splitSelectClassnames}>
      <div className="SplitSelectControl">
        <CurrencyInput
          {...restInputProps}
          name={inputProps.name}
          decimalLimit={decimalLimit}
          placeholder={placeholder}
          label={inputProps.label}
          iconPosition="left"
          onChange={handleInputChange}
          value={currentInput}
          size={size}
          error={error}
        />
        <Dropdown
          value={currentSelection && currentSelection.value}
          onChange={handleSelectionChange}
          options={dropdownProps.options}
          size={size}
          placeholder={`Dropdown for ${placeholder}.`}
          error={error}
          {...restDropdownProps}
        />
      </div>
      {errorMessage && <label className="u-errorMessage">{errorMessage}</label>}
    </Form.Field>
  );
}

SplitSelect.propTypes = {
  onChange: PropTypes.func,
  inputProps: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    decimalLimit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    placeholder: PropTypes.string,
  }),
  dropdownProps: PropTypes.shape({
    defaultValue: dropdownOptionProptype,
    options: dropdownOptionsPropType,
  }),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  readOnly: PropTypes.bool,
  extraClassnames: PropTypes.string,
  size: PropTypes.oneOf(["tiny", "regular"]),
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default SplitSelect;
