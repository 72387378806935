import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import Button from "components/shared/Button";
import CloseIcon from "-!svg-react-loader?!assets/icons/utility/close.svg?name=CloseIcon";
import SecondaryButton from "components/shared/SecondaryButton";
import AlertIcon from "-!svg-react-loader?!assets/icons/utility/alert.svg?";

function Alert({
  alertContent,
  showCloseIcon = true,
  showAlertIcon = false,
  showActionButton = false,
  onClickCloseIcon,
  onClickAction,
  buttonActionText = "Exit without saving",
  linkTo,
  type = "generic",
}) {
  const navigate = useNavigate();

  const handleClickAction = () => {
    if (linkTo) {
      navigate(linkTo);
    } else {
      onClickAction();
    }
  };

  const alertClassNames = classNames("Alert", {
    [`is-${type}`]: !!type,
    "Alert-withButton": showActionButton,
    "Alert-withActionAndClose": showCloseIcon && showActionButton,
  });
  return (
    <div className={alertClassNames}>
      {showAlertIcon && (
        <AlertIcon className="Alert-icon" width="18" height="18" />
      )}
      <div className="Alert-body">
        <p>{alertContent}</p>
      </div>
      <div className="Alert-close">
        {showCloseIcon && (
          <Button
            customClass="Button--empty Alert-closeIcon"
            size="small"
            onClick={onClickCloseIcon}
          >
            <CloseIcon />
          </Button>
        )}
        {showActionButton && (
          <SecondaryButton
            customClass="Alert-closeButton"
            size="small"
            onClick={handleClickAction}
          >
            {buttonActionText}
          </SecondaryButton>
        )}
      </div>
    </div>
  );
}

Alert.propTypes = {
  alertContent: PropTypes.string.isRequired,
  showCloseIcon: PropTypes.bool,
  showAlertIcon: PropTypes.bool,
  showActionButton: PropTypes.bool,
  onClickCloseIcon: PropTypes.func,
  onClickAction: PropTypes.func,
  buttonActionText: PropTypes.string,
  linkTo: PropTypes.string,
  type: PropTypes.oneOf(["generic", "warning", "success"]),
};

export default Alert;
