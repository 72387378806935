import React, { useEffect, useState } from "react";
import FormGroup from "components_new/elements/FormGroup";
import FormFooter from "components_new/SidebarComponents/SidebarForm/FormFooter";
import Input from "components_new/atoms/Input";
import TextAreaInput from "components_new/atoms/TextArea";
import Button from "components_new/atoms/Button";
import useForm from "lib/hooks/useForm";
import { isEmpty } from "lodash";

const initialValues = {
  formData: {
    name: "",
    headquarters: "",
    description: "",
  },
};

function BlankForm({
  ownName,
  submitTo,
  updateSidebarComponent,
  mountSidebarComponent,
  hideSidebarComponent,
  onUnmount,
  componentData,
  closeSidebar,
  pattern,
  complexSidebar,
}) {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!isEmpty(componentData)) {
      handleBatchChange(componentData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentData]);
  const onSubmit = (clientData) => {
    if (!isEmpty(submitTo)) {
      updateSidebarComponent(submitTo, { data: clientData });
      onUnmount(ownName, submitTo);
    } else {
      setIsLoading(true);
      closeSidebar();
      setIsLoading(false);
    }
  };
  const {
    formData,
    handleChange,
    handleSubmit,
    handleBatchChange,
    errors,
    touched,
  } = useForm(initialValues, onSubmit);

  const policy = pattern === "split" ? "Side-by-side" : "Stacked";

  const { name, headquarters, description } = formData;
  return (
    <div className="BlankForm">
      <FormGroup
        extraClassnames="AddCompanyGroup"
        title="Add New Company"
        isExpanded
        required
      >
        <Input
          label="Company name"
          isRequired
          placeholder="e.g. Inky"
          value={name}
          onChange={(e, { value }) => {
            handleChange("name", value);
          }}
          error={!!errors.for("name")}
          errorMessage={errors.for("name")}
        />
        <Input
          label="Headquarters"
          placeholder="e.g. Chicago"
          value={headquarters}
          onChange={(e, { value }) => {
            handleChange("headquarters", value);
          }}
        />
        <TextAreaInput
          label="Description"
          placeholder="e.g. anything you want"
          value={description}
          onChange={(e, { value }) => {
            handleChange("description", value);
          }}
        />
      </FormGroup>
      <br />
      {isEmpty(submitTo) && !complexSidebar && (
        <Button
          onClick={() => {
            mountSidebarComponent(
              "blankForm2",
              "Add Data From Stacked Form",
              {
                data: formData,
              },
              ownName,
            );
            if (pattern !== "split") {
              hideSidebarComponent(ownName);
            }
          }}
        >
          Open {policy} Form
        </Button>
      )}
      <FormFooter
        componentName={ownName}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        touched={touched}
      />
    </div>
  );
}

export default BlankForm;
