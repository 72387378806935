import React from "react";
import Input from "components_new/atoms/Input";
import DatePicker from "components_new/atoms/DatePicker";
import CurrencyInput from "components_new/atoms/CurrencyInput";
import FundAdditionalDetails from "./FundAdditionalDetails";

function FundInvestmentDetails({ formData, handleChange, errors }) {
  const { name, date, total_acquisition_amount } = formData;

  return (
    <div className="FundInvestmentDetails BasicDetails ui form">
      <Input
        name="name"
        label="Fund Name"
        placeholder="Name your fund"
        value={name}
        size="tiny"
        onChange={(_, { value }) => {
          handleChange("name", value);
        }}
        isRequired
        size="tiny"
        error={!!errors.for("name")}
        errorMessage={errors.for("name")}
        focus
      />
      <DatePicker
        name="date"
        label="Investment date"
        value={date}
        size="tiny"
        prefillToday
        onChange={(value) => {
          handleChange("date", value);
        }}
        size="tiny"
        required
        error={!!errors.for("date")}
        errorMessage={errors.for("date")}
      />
      <CurrencyInput
        name="total_acquisition_amount"
        label="Commitment amount*"
        placeholder="e.g. 100"
        decimalLimit={2}
        defaultValue={total_acquisition_amount}
        value={total_acquisition_amount}
        size="tiny"
        onChange={(value) => {
          handleChange("total_acquisition_amount", value);
        }}
        iconPosition="left"
        error={!!errors.for("total_acquisition_amount")}
        errorMessage={errors.for("total_acquisition_amount")}
      />
      <FundAdditionalDetails formData={formData} handleChange={handleChange} />
    </div>
  );
}

export default FundInvestmentDetails;
