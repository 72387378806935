export const termsItems = [
  { key: 0, text: "12 months", value: "twelve" },
  { key: 1, text: "24 months", value: "twenty_four" },
  { key: 2, text: "36 months", value: "thirty_six" },
  { key: 3, text: "Other", value: "other" },
];

export const accrualScheduleItems = [
  { key: 0, text: "Per Annum", value: "per_annum" },
  { key: 1, text: "Per Month", value: "per_month" },
  { key: 2, text: "Per Day", value: "per_day" },
];

export const interestScheduleItems = [
  { key: 0, text: "Simple", value: "simple" },
  { key: 1, text: "Compounding", value: "compounding" },
];
