import React, { useState } from "react";
import { omit, isEqual, cloneDeep } from "lodash";
import HtmlEditor from "components_new/elements/HtmlEditor";
import Form from "components_new/atoms/Form";
import Button from "components/shared/Button";
import useForm from "lib/hooks/useForm";
import formatDate from "lib/formatDate";
import { checkIfHtmlHasContent } from "lib/editor";
import DropZone from "components_new/elements/DropZone";

const allDocumentsWillBeDeleted = (documentsData) => {
  return Object.values(documentsData).every((document) => document.remove);
};

function EditPost({
  user,
  post,
  isSubmitting,
  onSubmit,
  onCancel,
  placeholder,
  openDocumentsUploader,
  isInlineAttachment,
  isAvailableToReply,
  size,
}) {
  const [inlineDocuments, setInlineDocuments] = useState([]);
  const [showAddAttachmentLink, setShowAddAttachmentLink] = useState(true);

  const postToEdit = cloneDeep(post);
  const initialValues = {
    formData: {
      content: postToEdit.content,
      documents_data: { ...postToEdit.documents_data },
      note_type: postToEdit.note_type,
    },
  };
  const submit = (clientData) => {
    const newClientData = {
      ...clientData,
      documents_data: isInlineAttachment
        ? [...Object.values(clientData.documents_data), ...inlineDocuments]
        : [...Object.values(clientData.documents_data)],
    };
    onSubmit(newClientData);
  };
  const { formData, handleChange, handleSubmit } = useForm(
    initialValues,
    submit,
  );

  const createDocument = (filepickerData) => {
    const givenDocuments = filepickerData.reduce(
      (documents_data, givenDocument) => ({
        ...documents_data,
        [givenDocument.handle]: givenDocument,
      }),
      {},
    );

    handleChange("documents_data", {
      ...formData.documents_data,
      ...givenDocuments,
    });
  };

  const removeAttachment = (attachment) => {
    if (attachment.handle) {
      handleChange(
        "documents_data",
        omit(formData.documents_data, attachment.handle),
      );
    } else {
      for (let index in formData.documents_data) {
        const attach = formData.documents_data[index];
        if (attach.id === attachment.id) {
          attach["remove"] = true;
        }
      }
      handleChange("documents_data", formData.documents_data);
    }
  };

  const onAttachFilesClick = () => {
    if (isInlineAttachment) {
      setShowAddAttachmentLink(false);
    } else {
      openDocumentsUploader({
        afterUpload: createDocument,
      });
    }
  };

  const getAttachmentsList = () => {
    return {
      ...Object.values(formData.documents_data).filter((att) => !att.remove),
    };
  };

  const disableSubmitButton = () => {
    const hasContent = checkIfHtmlHasContent(formData.content);
    const contentHasChanged = formData.content !== postToEdit.content;
    const typeHasChanged = formData.note_type !== postToEdit.note_type;
    const attachmentsHaveChanged = !isEqual(getAttachmentsList(), {
      ...postToEdit.documents_data,
    });
    const hasAtLeastOneDocument = !allDocumentsWillBeDeleted(
      formData.documents_data,
    );

    return !(
      (hasContent &&
        (contentHasChanged || attachmentsHaveChanged || typeHasChanged)) ||
      (hasAtLeastOneDocument && (contentHasChanged || attachmentsHaveChanged))
    );
  };

  return (
    <Form onSubmit={handleSubmit} role="form">
      <article className="PostSection-content">
        <header className="PostSection-contentHeader">
          <h3 className="u-metaContentBold">{user.full_name}</h3>
          <span className="u-metaContentLight">
            {formatDate(Date.now(), "ll")}
          </span>
        </header>
        <HtmlEditor
          name="content"
          extraClassnames="PostSection-content"
          defaultValue={formData.content}
          placeholder={placeholder}
          onChange={(_, { value }) => handleChange("content", value)}
          uploadedAttachments={getAttachmentsList()}
          onAttachFilesClick={onAttachFilesClick}
          removeAttachment={removeAttachment}
          showAddAttachmentLink={showAddAttachmentLink}
          showOptions={isAvailableToReply}
          onChangeOptions={(value) => handleChange("note_type", value)}
          optionValue={formData.note_type}
        />
        {isInlineAttachment && !showAddAttachmentLink && (
          <DropZone
            onChange={(values) => setInlineDocuments(values)}
            canMarkAsClosing={true}
            canChooseFileType={true}
            size={size}
          />
        )}
        <div className="Discussion-submitForm">
          <Button
            filter={{
              applyingFilter: isSubmitting,
            }}
            size="small"
            type="submit"
            disabled={disableSubmitButton()}
          >
            Save Changes
          </Button>
          <Button
            size="small"
            onClick={onCancel}
            behavior="secondary"
            disabled={isSubmitting}
          >
            Cancel
          </Button>
        </div>
      </article>
    </Form>
  );
}

export default EditPost;
