import React, { Fragment } from "react";
import Table, {
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
} from "components_new/elements/Table";
import CollapsableSection from "components_new/elements/CollapsableSection";
import EmptyCard from "components_new/elements/EmptyCard";
import formatDate from "lib/formatDate";
import { isEmpty } from "lodash";
import "./ContactIntroductions.scss";

function showtitle(count) {
  return (
    <Fragment>
      Introductions <small>({count})</small>
    </Fragment>
  );
}

function ContactIntroductions({ introductions }) {
  const hasIntroductions = introductions.length > 0;
  return (
    <CollapsableSection
      expanded={hasIntroductions}
      title={showtitle(introductions.length)}
      extraClassNames="Contact-Introductions-Details"
    >
      {hasIntroductions ? (
        <Table singleLine basic="very" compact shadow={false}>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>Requested by</TableHeaderCell>
              <TableHeaderCell>Introduction to</TableHeaderCell>
              <TableHeaderCell>Status</TableHeaderCell>
              <TableHeaderCell>Made by</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {introductions.map((introduction) => {
              return (
                <TableRow>
                  <TableCell>{introduction.requested_by.full_name}</TableCell>
                  <TableCell>
                    {introduction.introduction_to.full_name}
                    {!isEmpty(introduction.additional_introduction_to) && (
                      <span>
                        {introduction.additional_introduction_to.full_name}
                      </span>
                    )}
                  </TableCell>
                  <TableCell>{introduction.status}</TableCell>
                  <TableCell>
                    {introduction.made_by.full_name}
                    <span>
                      {formatDate(introduction.created_at, "detailed")}
                    </span>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <EmptyCard title="Introductions" />
      )}
    </CollapsableSection>
  );
}

export default ContactIntroductions;
