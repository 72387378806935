import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import Api from "lib/Api";

function updateContactDocument(oldData, newDocument) {
  return {
    ...oldData,
    documents: oldData.documents.map((document) =>
      document.id === newDocument.id ? newDocument : document,
    ),
  };
}

export function useUpdateContactDocument() {
  const queryClient = useQueryClient();
  const { organizationUsername } = useParams();

  return useMutation(
    ({ document }) => Api.patch(`/documents/${document.id}`, { document }),
    {
      onSuccess: ({ data: newDocument }, { contactId }) => {
        return queryClient.setQueryData(
          ["Contacts", organizationUsername, contactId],
          (oldData) => updateContactDocument(oldData, newDocument),
        );
      },
    },
  );
}
