import React, { useCallback, useRef, useState } from "react";
import classNames from "classnames";
import Dropdown from "components/shared/Dropdown";
import Button from "components_new/atoms/Button";
import ColumnedValueList from "components_new/atoms/ColumnedValueList";
import Header from "components_new/atoms/Header";
import Icon from "components_new/atoms/Icon";
import Tooltip from "components_new/atoms/Tooltip";
import EmptyCard from "components_new/elements/EmptyCard";
import GroupedVerticalButtonMenu from "components_new/elements/GroupedVerticalButtonMenu";
import formatDate from "lib/formatDate";
import moment from "moment-timezone";
import VisibilitySensor from "react-visibility-sensor";
import sanitizeHtml from "sanitize-html";
import CircularIconButton from "components_new/elements/CircularIconButton";
import Education from "components_new/views/Queues/shared/Education";
import File from "components_new/views/Queues/shared/File";
import TaskableAvatar from "./TaskableAvatar";
import { TaskViewContentSkeleton } from "../TaskViewSkeleton";
import "./ActiveTask.scss";

const dropdownItems = (snoozeTask) => {
  return [
    {
      text: "Tomorrow",
      onClickAction: () => snoozeTask(moment().set({ hour: 8 }).add(1, "day")),
    },
    {
      text: "Next Monday",
      onClickAction: () =>
        snoozeTask(
          moment()
            .startOf("isoWeek")
            .set({ hour: 8 })
            .add(1, "week")
            .day("monday"),
        ),
    },
    {
      text: "One week",
      onClickAction: () => snoozeTask(moment().set({ hour: 8 }).add(1, "week")),
    },
    {
      text: "One month",
      onClickAction: () =>
        snoozeTask(moment().set({ hour: 8 }).add(1, "month")),
    },
  ];
};

const ActiveTask = ({
  isLoading,
  activeTask,
  queueLabel,
  organizationName,
  openSidebar,
  openRemoveTaskModal,
  markTaskUnread,
  snoozeTask,
  completeTask,
  markAsPending,
  handleOpenTaskForm,
  showSidebar,
}) => {
  const [stickyEnabled, setStickyEnabled] = useState(false);
  const containmentEl = useRef(null);

  const openSidebarHelper = useCallback(
    (form, title) => {
      return () =>
        openSidebar(form, title, {
          taskId: activeTask.id,
          taskAttachments: Object.values(activeTask.attachments),
        });
    },
    [openSidebar, activeTask],
  );

  const taskActionItems = [
    {
      title: "Update Valuation",
      onClick: openSidebarHelper("valuationForm", "Update a valuation"),
    },
    {
      title: "Add Note",
      onClick: () =>
        showSidebar("noteForm", {
          taskId: activeTask.id,
          taskAttachments: Object.values(activeTask.attachments),
        }),
    },
    {
      title: "Add Files",
      onClick: openSidebarHelper("filesForm", "Add new files"),
    },
    {
      title: "Add Capital Call",
      onClick: openSidebarHelper("capitalCallForm", "Add a capital call"),
    },
  ];

  if (isLoading) {
    return <TaskViewContentSkeleton />;
  }

  return activeTask ? (
    <div className="ActiveTask">
      <div className="ActiveTask-Detail" ref={containmentEl}>
        <VisibilitySensor
          onChange={(v) => setStickyEnabled(!v)}
          containment={containmentEl.current}
        >
          <div className="VisibilitySensorPixel" />
        </VisibilitySensor>
        <div
          className={classNames("ActiveTask-Detail-Header", {
            "Task-Detail-Header-Sticky": stickyEnabled,
          })}
        >
          <Header as="h2" extraClassName="ActiveTask-Detail-Title">
            {activeTask.subject || "(No Subject)"}
          </Header>
          <div className="ActiveTask-Detail-SubActions">
            <div style={{ display: "block" }}>
              <Tooltip
                position="top center"
                pinned
                style={{ top: "-20px" }}
                trigger={
                  <CircularIconButton
                    icon="ri-mail-unread-line"
                    className="CircularIconBtn"
                    onClick={markTaskUnread}
                  />
                }
                content="Mark as Unread"
              />
            </div>
            <div>
              <Tooltip
                position="top center"
                pinned
                style={{ top: "-20px" }}
                trigger={
                  <Dropdown
                    items={dropdownItems(snoozeTask)}
                    renderButton={() => (
                      <Button size="mini" className="CircularIconBtn">
                        <Icon size="tiny" icon="ri-alarm-line" />
                      </Button>
                    )}
                  />
                }
                content="Snooze Task"
              />
            </div>
            <div>
              <Tooltip
                position="top center"
                pinned
                style={{ top: "-20px" }}
                trigger={
                  <CircularIconButton
                    icon="ri-pencil-line"
                    className="CircularIconBtn"
                    onClick={() => handleOpenTaskForm(activeTask)}
                  />
                }
                content="Edit Task"
              />
            </div>
            <div>
              <Tooltip
                position="top center"
                pinned
                style={{ top: "-20px" }}
                trigger={
                  <CircularIconButton
                    icon="ri-delete-bin-line"
                    className="CircularIconBtn"
                    onClick={openRemoveTaskModal}
                  />
                }
                content="Delete Task"
              />
            </div>
          </div>
        </div>
        <TaskableAvatar
          taskableId={activeTask.taskable_id}
          taskableType={activeTask.taskable_type}
          taskableName={activeTask.taskable_name}
          taskablePath={activeTask.taskable_client_path}
        />
        <div className="ActiveTask-Detail-MetaCard">
          <ColumnedValueList
            data={[
              {
                label: "Created by",
                value: activeTask.creator || activeTask.from,
              },
              {
                label: "Created at",
                value: formatDate(activeTask.created_at, "semiDetailed"),
              },
            ]}
          />
        </div>
        <div className="ActiveTask-Detail-Body">
          {activeTask.attachments.length > 0 && (
            <div className="ActiveTask-Detail-Attachments">
              <Header extraClassName="Attachments-Title">
                <Icon icon="ri-attachment-2" size="tiny" />
                Attachments
              </Header>
              {activeTask.attachments.map((attachment, index) => (
                <File file={attachment} key={index} />
              ))}
            </div>
          )}
          <TaskDetailBody activeTask={activeTask} />
        </div>
      </div>
      <div className="ActiveTask-Actions">
        <Button
          {...{
            primary: !activeTask.completed_at,
            secondary: activeTask.completed_at,
          }}
          fluid
          size="mini"
          className="MarkCompleteBtn"
          onClick={!activeTask.completed_at ? completeTask : markAsPending}
        >
          <Icon icon="ri-check-line" />
          {!activeTask.completed_at
            ? "Mark as Complete"
            : "Move Task to Pending"}
        </Button>
        {activeTask && (
          <GroupedVerticalButtonMenu
            title="Take Action"
            items={taskActionItems}
          />
        )}
      </div>
    </div>
  ) : (
    <Education
      currentOrganizationName={organizationName}
      queueLabel={queueLabel}
    />
  );
};

const TaskDetailBody = ({ activeTask }) => {
  if (activeTask.raw_html) {
    return (
      <div
        className="Detail-Body-Email"
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(activeTask.raw_html, {
            allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img"]),
            allowedAttributes: false,
          }),
        }}
      />
    );
  } else if (activeTask.body) {
    return <div className="Detail-Body-Email">{activeTask.body}</div>;
  }

  return <EmptyCard title="Email body is empty" />;
};

export default ActiveTask;
