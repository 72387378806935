export const OVERRIDE_COLLECTION = "entities/OVERRIDE_COLLECTION";
export const RESET_COLLECTION = "entities/RESET_COLLECTION";
export const UPDATE_COLLECTION = "entities/UPDATE_COLLECTION";
export const UPSERT_ENTITY = "entities/UPSERT_ENTITY";
export const REMOVE_ENTITY = "entities/REMOVE_ENTITY";
export const UPDATE_SINGLETON = "entities/UPDATE_SINGLETON";
export const COLLECTION_FETCH_START = "requests/COLLECTION_FETCH_START";
export const COLLECTION_FETCH_SUCCESS = "requests/COLLECTION_FETCH_SUCCESS";
export const COLLECTION_FETCH_FAIL = "requests/COLLECTION_FETCH_FAIL";
export const ITEM_FETCH_START = "requests/ITEM_FETCH_START";
export const ITEM_FETCH_SUCCESS = "requests/ITEM_FETCH_SUCCESS";
export const ITEM_FETCH_FAIL = "requests/ITEM_FETCH_FAIL";
export const MUTATION_START = "requests/MUTATION_START";
export const MUTATION_SUCCESS = "requests/MUTATION_SUCCESS";
export const MUTATION_FAIL = "requests/MUTATION_FAIL";
export const SINGLETON_FETCH_START = "requests/SINGLETON_FETCH_START";
export const SINGLETON_FETCH_SUCCESS = "requests/SINGLETON_FETCH_SUCCESS";
export const SINGLETON_FETCH_FAIL = "requests/SINGLETON_FETCH_FAIL";
