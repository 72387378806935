import React, { useState, useCallback } from "react";
import PassedDeals from "./PassedDeals";
import useOrganizations from "store/organizations/hooks";

import { openModal } from "store/modalV2";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { isEmpty, debounce } from "lodash";
import { filterTag as fetchTags } from "store/tags/actions";
import { getCollection } from "store/domain/selectors";
import { useSetSidebar } from "components_new/elements/SidebarWrapper/Sidebar-context";
import { useDeals } from "components_new/views/Deals/hooks/queries/useDeals";
import { usePipeline } from "components_new/views/Deals/hooks/queries/usePipeline";

function buildOptions(items) {
  if (!items) return;

  return items.map((item, index) => ({
    key: index,
    value: item,
    text: item,
  }));
}

function PassedDealsWrapper() {
  const [filters, setFilters] = useState({});
  const dispatch = useDispatch();

  const {
    currentOrganization,
    currentOrganization: { pipeline_id: pipelineId },
  } = useOrganizations();

  const { showSidebar } = useSetSidebar();
  const { data, isLoading } = useDeals("PassedDeals", pipelineId, filters);
  const { data: pipeline } = usePipeline(pipelineId);

  const handleOpenDeleteDealModal = useCallback(
    (dealId) =>
      dispatch(
        openModal("removeDeal", {
          dealId,
          pipelineId,
        }),
      ),
    [dispatch, pipelineId],
  );

  const handleOpenReactivateDealModal = useCallback(
    (dealId) =>
      dispatch(
        openModal("reactivateDeal", {
          dealId,
          pipelineId,
        }),
      ),
    [dispatch, pipelineId],
  );

  const handleOpenEditDealForm = useCallback(
    (dealId) =>
      showSidebar("dealForm", {
        dealId: dealId,
      }),
    [showSidebar],
  );

  const handleOpenDealDetailsView = useCallback(
    (deal) => {
      showSidebar("activeDealView", { dealId: deal.id });
    },
    [showSidebar],
  );

  const handleOpenPassFromDealModal = useCallback(
    (dealId) =>
      dispatch(
        openModal("passFromDeal", {
          dealId,
          pipelineId,
        }),
      ),
    [dispatch, pipelineId],
  );

  // TODO: Move tags to React Query
  // Temporary code
  const filterTags = debounce((tagName) => {
    return dispatch(fetchTags(tagName));
  }, 300);

  const tagSuggestions = useSelector(
    (state) => getCollection(state, "tagSuggestions"),
    shallowEqual,
  );

  return (
    <PassedDeals
      isLoading={isLoading}
      hasSearchOrFilter={!isEmpty(filters)}
      passedDeals={data?.deals}
      passedDealsCount={data?.deals.length}
      fetchPassedDeals={({ filters }) => setFilters(filters)}
      organizationUsername={currentOrganization.username}
      headquarters={buildOptions(data?.headquarters)}
      owners={buildOptions(data?.owners)}
      openDeleteDealModal={handleOpenDeleteDealModal}
      openReactivateDealModal={handleOpenReactivateDealModal}
      openEditDealSidebar={handleOpenEditDealForm}
      openViewDealSidebar={handleOpenDealDetailsView}
      openEditPassDealSidebar={handleOpenPassFromDealModal}
      filterTags={filterTags}
      tagSuggestions={tagSuggestions}
      totalDeals={pipeline?.passedDealsCount}
    />
  );
}

export default PassedDealsWrapper;
