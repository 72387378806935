import React, { useState, useRef, useEffect } from "react";
import { Form, Label } from "@ableco/semantic-ui-react";
import classnames from "classnames";
import CloseIcon from "-!svg-react-loader?!assets/icons/utility/close.svg?name=CloseIcon";
import Icon from "components_new/atoms/Icon";
import Dropdown from "components_new/atoms/Dropdown";
import PropTypes from "prop-types";
import useDebounce from "lib/hooks/useDebounce";
import Spinner from "components/shared/Spinner";
import filterBy from "components_new/lib/filterBy";
import { isEmpty } from "lodash";
import {
  getTextFromElement,
  withoutSelectedItems,
  compareWith,
} from "./config";

import ResultCard from "components_new/elements/ResultCard";
import {
  ENTER_KEY,
  DOWN_ARROW_KEY,
  UP_ARROW_KEY,
  SPACE_KEY,
} from "config/keyCodes";
import "semantic-ui-css/components/form.css";
import "./MultiSelect.scss";

const NON_TRIGGEREABLE_KEYS = [DOWN_ARROW_KEY, UP_ARROW_KEY, SPACE_KEY];
const DROPDOWN_SELECTOR = "MultiSelect-suggestionsList";

const buildOptionsItems = (elements, query) => {
  return elements.map((element) => ({
    key: element.id,
    caption: getTextFromElement(element),
    value: element,
    className: "SuggestionItem",
    text: (
      <ResultCard
        query={query}
        title={getTextFromElement(element)}
        description={element.description}
        type={element.type}
        initials={element.initials}
      />
    ),
  }));
};

export const buildSuggestionList = (
  elements,
  query = null,
  entityName,
  handleOpenForm,
  hasOptions,
  showAddNew,
) => {
  const isNotExactMacth = !(
    elements.length === 1 && compareWith(elements[0].title, query)
  );

  const options = buildOptionsItems(elements, query);

  if (isNotExactMacth && query.length > 0 && !hasOptions && showAddNew) {
    options.push({
      key: "add-new-element",
      onClick: () => handleOpenForm(),
      className: "MultiSelect-addNewElement",
      text: `+ New ${entityName}: ${query}`,
    });
  }

  return options;
};

const isNotAllowedKey = (event) => {
  const { which: keyCode } = event;
  return (
    !(event instanceof KeyboardEvent) ||
    (event instanceof KeyboardEvent && !NON_TRIGGEREABLE_KEYS.includes(keyCode))
  );
};

function MultiSelect({
  label,
  scope,
  entityName,
  resourceName,
  placeholder,
  prefilledItems = [],
  onFilter,
  onChange,
  onClickAddNew,
  options,
  optionsSearchBy,
  showAddNew,
  size,
}) {
  const [selectedItems, setSelectedItems] = useState(prefilledItems);
  const [preselectedItem, setPreselectedItem] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showNewElementForm, setShowNewElementForm] = useState(false);
  const [hasOptions, setHasOptions] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const inputRef = useRef(null);
  const { debouncedValue: debouncedSearchTerm } = useDebounce(inputValue, 300);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  useEffect(() => {
    if (!isEmpty(options)) {
      setSuggestions(buildOptionsItems(options));
      setHasOptions(true);
    }
  }, [options]);

  useEffect(() => {
    const query = debouncedSearchTerm;
    // All the logic to search and filter only if the component have options
    if (hasOptions) {
      const filteredOptions =
        query.length === 0
          ? withoutSelectedItems(options, selectedItems)
          : filterBy(options, query, optionsSearchBy);

      const suggestions = buildSuggestionList(
        filteredOptions,
        query,
        entityName,
        handleOpenForm,
        hasOptions,
        showAddNew,
      );
      const filteredValues = withoutSelectedItems(suggestions, selectedItems);
      setSuggestions(filteredValues);
      setPreselectedItem(null);
      setShowSuggestions(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const query = debouncedSearchTerm;

    if (!hasOptions) {
      if (query.trim().length > 1) {
        setIsLoading(true);
        onFilter(scope, query)
          .then((options) => {
            setSuggestions([]);
            const suggestions = buildSuggestionList(
              options,
              query,
              entityName,
              handleOpenForm,
              hasOptions,
              showAddNew,
            );

            const filteredValues = withoutSelectedItems(
              suggestions,
              selectedItems,
            );

            setSuggestions(filteredValues);
            setPreselectedItem(null);
            setShowSuggestions(true);
          })
          .finally(() => setIsLoading(false));
      } else {
        setShowSuggestions(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (hasOptions) {
      const filteredValues = withoutSelectedItems(
        buildOptionsItems(options),
        selectedItems,
      );
      setSuggestions(filteredValues);
    }
    if (onChange) {
      onChange(selectedItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const handleSelectItem = (event, item) => {
    const itShouldChange = isNotAllowedKey(event);
    if (itShouldChange) {
      handleAddNewItem(item);
    } else {
      setPreselectedItem(item);
    }
  };

  const handleOpenForm = () => {
    setShowSuggestions(false);
    setInputValue("");
    onClickAddNew(handleAddNewItem, debouncedSearchTerm);
  };

  const handleAddNewItem = (item) => {
    clearSelections();
    setSelectedItems((prevItems) => [...prevItems, { ...item }]);
  };

  const handleRemoveItem = (removedItem) => {
    setSelectedItems((prevItems) =>
      prevItems.filter((item) => item.key !== removedItem.key),
    );
  };

  const handleOnFocus = () => {
    if (hasOptions) {
      setShowSuggestions(true);
    }
  };

  const handleKeyDown = (event) => {
    const { which: keyCode } = event;
    const preselected = preselectedItem;

    switch (keyCode) {
      case ENTER_KEY:
        if (preselected && showSuggestions) {
          clearSelections();
          setSelectedItems((prevItems) => [...prevItems, preselected]);
        }
        break;
      case SPACE_KEY:
        if (showSuggestions) setInputValue(inputValue + " ");
    }
  };

  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setShowSuggestions(false);
    }
  };

  const clearSelections = () => {
    setInputValue("");
    setPreselectedItem(null);
    setShowSuggestions(false);
    setShowNewElementForm(false);
  };

  const multiSelectClassnames = classnames("MultiSelect ui input", {
    "MultiSelect--showSuggestions": showSuggestions,
    "MultiSelect--showForm": showNewElementForm,
    "has-options": hasOptions,
    [size]: !!size,
  });

  const shouldShowHeader = suggestions.length > 1;
  return (
    <div className="ui form MultiSelect-form">
      <Form.Field>
        {label && <label className="MultiSelect-label">{label}</label>}
        <div className={multiSelectClassnames} ref={inputRef}>
          <div className="MultiSelect-list">
            {selectedItems.map((selectedItem, index) => (
              <Label key={index}>
                {selectedItem.caption}
                <span
                  className="CloseIcon"
                  onClick={() => handleRemoveItem(selectedItem)}
                >
                  <CloseIcon />
                </span>
              </Label>
            ))}

            <div className="MultiSelect-inputForm">
              <input
                className="MultiSelect-input"
                onChange={({ target: { value } }) => setInputValue(value)}
                onKeyDown={handleKeyDown}
                value={inputValue}
                onFocus={handleOnFocus}
                readOnly={showNewElementForm}
                placeholder={placeholder}
              />
            </div>
            <div className="MultiSelect-iconContainer">
              {isLoading ? (
                <Spinner />
              ) : (
                <Icon
                  size={size}
                  icon="ri-search-line"
                  extraClassnames="MultiSelect-icon"
                />
              )}
            </div>
          </div>
          <Dropdown
            className={DROPDOWN_SELECTOR}
            options={suggestions}
            onChange={handleSelectItem}
            open={showSuggestions}
            header={shouldShowHeader && resourceName}
          />
        </div>
      </Form.Field>
    </div>
  );
}

MultiSelect.defaultProps = {
  entityName: "contact",
  resourceName: "contacts",
  prefilledItems: [],
  value: [],
  size: "large",
  optionsSearchBy: ["title", "description"],
  showAddNew: true,
};

MultiSelect.propTypes = {
  onFilter: PropTypes.func,
  placeholder: PropTypes.string,
  entityName: PropTypes.string,
  resourceName: PropTypes.string,
  label: PropTypes.string,
  prefilledItems: PropTypes.arrayOf(PropTypes.shape({})),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  optionsSearchBy: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  onValidateNewElement: PropTypes.func,
  scope: PropTypes.string,
  onClickAddNew: PropTypes.func,
  size: PropTypes.string,
  showAddNew: PropTypes.bool,
};

export default MultiSelect;
