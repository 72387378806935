import { Modal as SemanticModal } from "@ableco/semantic-ui-react";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "semantic-ui-css/components/modal.css";
import "./Modal.scss";

function Modal({ size, handleClose, isOpen, children }) {
  const modalClassnames = classNames("Modal");
  return (
    <SemanticModal
      className={modalClassnames}
      size={size}
      open={isOpen}
      onClose={handleClose}
      closeIcon
    >
      {children}
    </SemanticModal>
  );
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  size: PropTypes.string,
  handleClose: PropTypes.func,
  children: PropTypes.element,
};

Modal.defaultProps = {
  size: "small",
  isOpen: false,
  handleClose: () => {},
};
export default Modal;
