import React from "react";
import PropTypes from "prop-types";

function FormErrorMessage({ errorMessage }) {
  return <p className="u-errorMessage ErrorMessage">{errorMessage}</p>;
}

FormErrorMessage.propTypes = {
  errorMessage: PropTypes.string,
};

export default FormErrorMessage;
