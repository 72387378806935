import React from "react";
import FileIcon from "components_new/atoms/FileIcon";
import iconType from "components_new/lib/iconType";

function FileTypeIcon({ file, size = "small" }) {
  return (
    <FileIcon type={iconType(file.mime_type || file.mimetype)} size={size} />
  );
}

export default FileTypeIcon;
