import React from "react";
import PropTypes from "prop-types";
import "./TextTitle.scss";

function TextTitle({ children }) {
  return (
    <div data-testid="TextTitle" className="TextTitle">
      {children}
    </div>
  );
}

function SubTitle({ children }) {
  return <div className="TextTitle-SubTitle">{children}</div>;
}

TextTitle.SubTitle = SubTitle;

TextTitle.propTypes = {
  children: PropTypes.node,
};

SubTitle.propTypes = {
  children: PropTypes.node,
};

export default TextTitle;
