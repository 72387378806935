import { compose, isEmpty, prop, toLower } from "ramda";

export const parseString = (value) => (!value ? "" : toLower(value));
export const parseNumber = (value) => (value === null ? 0 : parseFloat(value));
export const parseDate = (value) => (value === null ? 0 : Date.parse(value));
export const parseArray = (value) =>
  isEmpty(value) ? "" : toLower(JSON.stringify(value));

export const parseStringColumn = (column) => compose(parseString, prop(column));
export const parseNumericColumn = (column) =>
  compose(parseNumber, prop(column));
export const parseDateColumn = (column) => compose(parseDate, prop(column));
export const parseArrayColumn = (column) => compose(parseArray, prop(column));

export const propFunction = {
  numeric: parseNumericColumn,
  string: parseStringColumn,
  date: parseDateColumn,
  array: parseArrayColumn,
};
