import { useQuery } from "react-query";
import Api from "lib/Api";

export function useQueueRefresh(lastTaskId) {
  return useQuery(
    ["QueuesView", lastTaskId],
    async () => {
      const { data: newTasksCount } = await Api.get("/tasks/last_added", {
        params: {
          last_task_id: lastTaskId,
        },
      });

      return newTasksCount;
    },
    {
      refetchInterval: 15000,
    },
  );
}
