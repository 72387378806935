import React from "react";
import classNames from "classnames";

function CertificateCellPreview({ historyAction, isTerminalAction }) {
  const showCertificateNumber =
    historyAction && historyAction.certificate_number && !isTerminalAction;
  const certificateClassNames = classNames("CertificateCellPreview", {
    hasCertificate: showCertificateNumber,
  });
  return (
    <div className={certificateClassNames}>
      {showCertificateNumber ? historyAction.certificate_number : "- - -"}
    </div>
  );
}

export default CertificateCellPreview;
