import React, { useContext, useEffect } from "react";
import BlankForm from "./BlankForm";
import { SidebarComponentsContext } from "components_new/SidebarComponents/SidebarComponentsWrapper";
import { isEmpty } from "lodash";

function BlankFormWrapper({ ownName }) {
  const {
    getComponentState,
    registerForm,
    closeSidebar,
    updateSidebarComponent,
    hideSidebarComponent,
    mountSidebarComponent,
    onUnmount,
  } = useContext(SidebarComponentsContext);
  const {
    componentData,
    submitTo,
    index,
    sidebarPolicy,
    hideMe,
    complexSidebar,
  } = getComponentState(ownName);

  useEffect(() => {
    if (!isEmpty(ownName)) {
      registerForm(ownName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!hideMe) {
      hideSidebarComponent(ownName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BlankForm
      index={index}
      ownName={ownName}
      closeSidebar={closeSidebar}
      submitTo={submitTo}
      componentData={componentData}
      updateSidebarComponent={updateSidebarComponent}
      hideSidebarComponent={hideSidebarComponent}
      mountSidebarComponent={mountSidebarComponent}
      onUnmount={onUnmount}
      pattern={sidebarPolicy && sidebarPolicy.pattern}
      complexSidebar={complexSidebar}
    />
  );
}

export default BlankFormWrapper;
