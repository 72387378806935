import React from "react";

import { Segment } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/segment.css";
import "./RowCardList.scss";

function CardList({ children }) {
  return <Segment.Group className="RowCardList">{children}</Segment.Group>;
}

export default CardList;
