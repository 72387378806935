import React, { Component } from "react";
import PropTypes from "prop-types";
import filterErrors from "lib/shared/form/filterErrors";

class NewEntry extends Component {
  componentDidMount() {
    const { rowInitialState, updateForm } = this.props;
    if (rowInitialState) {
      const keys = Object.keys(rowInitialState);
      keys.map((key) => {
        updateForm(key, rowInitialState[key], 0, null);
      });
    }
  }

  render() {
    const {
      updateForm,
      renderRow,
      rowInitialState,
      columns,
      errorsResults,
    } = this.props;
    const errors = filterErrors(errorsResults, 0);
    return (
      <div className="Table--row Table--newEntry">
        {renderRow({
          index: 0,
          data: rowInitialState,
          columns: columns,
          updateForm: updateForm,
          errors: errors,
        })}
      </div>
    );
  }
}

NewEntry.propTypes = {
  rowInitialState: PropTypes.shape({}),
  data: PropTypes.shape({}),
  renderRow: PropTypes.func.isRequired,
  updateForm: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  errorsResults: PropTypes.arrayOf(PropTypes.shape({})),
};

export default NewEntry;
