import React from "react";
import Tooltip from "components_new/atoms/Tooltip/Tooltip";
import "./NoClosingDocIndicator.scss";

function NoClosingDocIndicator() {
  return (
    <Tooltip
      position="top center"
      trigger={<div className="NoClosingDocIndicator"></div>}
      content="Missing closing documents"
    />
  );
}

export default NoClosingDocIndicator;
