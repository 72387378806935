import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Button } from "@ableco/semantic-ui-react";
import classnames from "classnames";
import "./ButtonGroup.scss";

function ButtonGroup({ buttons, children, size, extraClassnames }) {
  const buttonGroupClassnames = classnames("ButtonGroup", {
    [extraClassnames]: !!extraClassnames,
    [size]: !!size,
  });

  const renderButtonList = () => {
    return (
      <Fragment>
        {buttons &&
          buttons.map((button, index) => (
            <Button key={index} onClick={button.onClick}>
              {button.name}
            </Button>
          ))}
        {children}
      </Fragment>
    );
  };

  return (
    <Button.Group className={buttonGroupClassnames}>
      {renderButtonList()}
    </Button.Group>
  );
}

ButtonGroup.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  children: PropTypes.node,
  size: PropTypes.oneOf(["small", "large"]),
  extraClassnames: PropTypes.string,
};

export default ButtonGroup;
