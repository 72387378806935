export const recentActivityItemsByPage = 10;
export const statusWithLogAcquisition = "Acquired";
const statusWithoutBadges = ["Active"];

export function companyHasBadge(company) {
  return (
    company.status !== undefined &&
    !statusWithoutBadges.includes(company.status)
  );
}

export default {
  recentActivityItemsByPage,
};
