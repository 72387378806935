import React from "react";
import detailTypes from "./detailTypes";
import MainViewFrame from "components/shared/MainView/MainViewFrame";

function InvestmentDetails(props) {
  const { investment } = props;
  const InvestmentDetailsComponent = detailTypes[investment.type];

  if (!InvestmentDetailsComponent) {
    return null;
  }

  return (
    <div className="InvestmentDetails">
      <MainViewFrame>
        <InvestmentDetailsComponent {...props} />
      </MainViewFrame>
    </div>
  );
}

export default InvestmentDetails;
