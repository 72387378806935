export const SHOW_SIDEBAR_COMPONENT = "sidebarComponent/SHOW";
export const HIDE_SIDEBAR_COMPONENT = "sidebarComponent/HIDE";
export const RESET_COMPONENTS = "sidebarComponent/RESET_COMPONENTS";

export const MOUNT_SIDEBAR_COMPONENT =
  "sidebarComponent/MOUNT_SIDEBAR_COMPONENT";
export const UNMOUNT_SIDEBAR_COMPONENT =
  "sidebarComponent/UNMOUNT_SIDEBAR_COMPONENT";

export const UPDATE_SIDEBAR_COMPONENT =
  "sidebarComponent/UPDATE_SIDEBAR_COMPONENT";
