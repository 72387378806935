import React, { Fragment } from "react";
import { getText } from "TermsMapper";
import { placeholderDateFormat } from "config/dateInput";
import PriceInput from "components/shared/form/PriceInput";
import DatePicker from "components_new/atoms/DatePicker";
import NumberInput from "components/shared/form/NumberInput";
import HelperContent from "components/shared/HelperContent";
import { customPurchasedErrors } from "../../config";

function PurchasedOptionForm({ formData, updateForm, errors, historyAction }) {
  const {
    date_helper_text: dateHelperContent,
    quantity_helper_text: numberHelperContent,
  } = historyAction;
  return (
    <Fragment>
      <PriceInput
        name="total_acquisition_amount"
        label={getText("option", "total_acquisition_amount")}
        placeholder="0.00"
        onChange={(value) => updateForm("total_acquisition_amount", value)}
        errorMessage={customPurchasedErrors(
          errors,
          "total_acquisition_amount",
          formData.type,
        )}
        value={formData.total_acquisition_amount}
        size="small"
      />
      <DatePicker
        name="date"
        label={getText("option", "date")}
        placeholder={placeholderDateFormat}
        onChange={(value, validated) => updateForm("date", value, validated)}
        extraClassnames="FormElement FormElement--Date"
        error={!!errors.for("date")}
        errorMessage={errors.for("date")}
        value={formData.date}
        helperContent={() => <HelperContent content={dateHelperContent} />}
        showHelper={!!dateHelperContent}
        size="small"
        oldVersion
      />
      <NumberInput
        name="quantity"
        label={getText("option", "quantity")}
        onChange={(value) => updateForm("quantity", value)}
        errorMessage={errors.for("quantity")}
        value={formData.quantity}
        helperContent={() => <HelperContent content={numberHelperContent} />}
        errorDisplayValidation={() => !numberHelperContent}
        showHelper={!!numberHelperContent}
        size="small"
      />
    </Fragment>
  );
}

export default PurchasedOptionForm;
