import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "components_new/atoms/Button";
import preventerHandler from "lib/preventerHandler";
import classNames from "classnames";
import "./BaseConfirmDialog.scss";

function BaseConfirmDialog({
  cancelButtonText,
  discardButtonText,
  onDiscard,
  title,
  actionType,
  onConfirm,
}) {
  const [submitting, isSubmitting] = useState(false);
  const handleConfirmClick = () => {
    isSubmitting(true);
    onConfirm();
  };

  const isWarningModal = actionType === "danger";

  const companyEventOverlayClassnames = classNames("CompanyEventOverlay", {
    "CompanyEventOverlay--danger": actionType === "danger",
  });

  return (
    <div
      className={companyEventOverlayClassnames}
      data-testid="BaseConfirmDialog"
    >
      <div className="CompanyEventOverlay-contentWrap">
        <div className="CompanyEventOverlay-content">
          <h3 className="CompanyEventOverlay-title">{title}</h3>
          <div className="CompanyEventOverlay-actions">
            <Button
              primary={!isWarningModal}
              warning={isWarningModal}
              loading={submitting}
              onClick={preventerHandler(handleConfirmClick)}
              size="small"
            >
              {cancelButtonText}
            </Button>
            <Button
              warning={isWarningModal}
              secondary={!isWarningModal}
              inverted={isWarningModal}
              onClick={preventerHandler(onDiscard)}
              size="small"
            >
              {discardButtonText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

BaseConfirmDialog.defaultProps = {
  cancelButtonText: "Cancel",
  discardButtonText: "Nevermind",
  actionType: "default",
};

BaseConfirmDialog.propTypes = {
  actionType: PropTypes.oneOf(["default", "danger"]),
  onConfirm: PropTypes.func.isRequired,
  onDiscard: PropTypes.func.isRequired,
  cancelButtonText: PropTypes.string,
  discardButtonText: PropTypes.string,
  title: PropTypes.string,
};

export default BaseConfirmDialog;
