import React, { Fragment } from "react";
import Header from "components_new/atoms/Header";
import DiscussionsList from "components_new/elements/Discussions/DiscussionsList";
import "./CompanyNotes.scss";

export default function CompanyNotes({
  currentOrganizationUsername,
  users,
  posts,
  resource,
  isSubmitting,
  createPost,
  updatePost,
  removePost,
  openDocumentsUploader,
}) {
  return (
    <Fragment>
      <Header as="h5">Notes</Header>
      <DiscussionsList
        extraClassnames="CompanyNoteTab"
        organizationUsername={currentOrganizationUsername}
        users={users}
        posts={posts}
        resource={resource}
        isSubmitting={isSubmitting}
        createPost={createPost}
        updatePost={updatePost}
        removePost={removePost}
        openDocumentsUploader={openDocumentsUploader}
        showResource={false}
        showDiligenceContact={true}
      />
    </Fragment>
  );
}
