export const investorTypes = [
  {
    label: "Individual Investor",
    value: "individual",
  },
  {
    label: "Family Office",
    value: "family_office",
  },
  {
    label: "Venture Capital",
    value: "venture_capital",
  },
  {
    label: "Angel Group",
    value: "angel_group",
  },
];

export const yearlyInvestmentsCountRanges = [
  {
    label: "1-3",
    value: "one_to_three",
  },
  {
    label: "3-5",
    value: "three_to_five",
  },
  {
    label: "5-10",
    value: "five_to_ten",
  },
  {
    label: "10+",
    value: "more_than_ten",
  },
];

export const portfoliosCountRanges = [
  {
    label: "1",
    value: "one",
  },
  {
    label: "2-5",
    value: "two_to_five",
  },
  {
    label: "5-10",
    value: "five_to_ten",
  },
  {
    label: "10+",
    value: "more_than_ten",
  },
];

export default {
  investorTypes,
  yearlyInvestmentsCountRanges,
  portfoliosCountRanges,
};
