import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import Api from "lib/Api";

function addContactDocument(oldData, newDocument) {
  return {
    ...oldData,
    documents: [...oldData.documents, ...newDocument],
  };
}

export function useSaveContactDocument() {
  const queryClient = useQueryClient();
  const { organizationUsername } = useParams();

  return useMutation(
    ({ document, contactId }) =>
      Api.post(`v2/contacts/${contactId}/documents`, {
        documents: {
          documentsData: [document],
        },
      }),
    {
      onSuccess: ({ data: newDocument }, { contactId }) => {
        return queryClient.setQueryData(
          ["Contacts", organizationUsername, contactId],
          (oldData) => addContactDocument(oldData, newDocument),
        );
      },
    },
  );
}
