import React, { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { closeModal } from "store/modalV2/actions";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import { useDispatch } from "react-redux";

import getMessage from "components_new/lib/Messages";
import useInvestment from "store/investments/investment/hooks";
import ConfirmationModal from "components/shared/ConfirmationModal";

const createMessage = (investmentName) => {
  return (
    <Fragment>
      Are you sure you want to delete the holding{" "}
      <strong>{investmentName}</strong> and any associated documents?
    </Fragment>
  );
};

function DeleteInvestmentWrapper({ investment }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    id: investmentId,
    name: investmentName,
    portfolio_id: portfolioId,
    company_id: companyId,
  } = investment;

  const navigate = useNavigate();
  const { organizationUsername } = useParams();
  const { removeInvestment } = useInvestment();
  const { addToast } = useSetToast();
  const dispatch = useDispatch();

  const handleAction = async () => {
    setIsSubmitting(true);
    await removeInvestment(investmentId, companyId, portfolioId);

    navigate(`/${organizationUsername}/portfolios/${portfolioId}`);
    addToast(getMessage("deleteInvestment"));

    dispatch(closeModal());
    setIsSubmitting(false);
  };

  return (
    <ConfirmationModal
      action={handleAction}
      submitting={isSubmitting}
      confirmButtonLabel="Delete Investment"
      message={createMessage(investmentName)}
      title="Delete Investment"
      primaryButtonClassnames="Button--warning"
    />
  );
}

export default DeleteInvestmentWrapper;
