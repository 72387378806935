import accounting from "accounting";

export default function formatSufixedPrice(amount) {
  const formatted = accounting
    .formatMoney(amount.toString(), "")
    .replace("-", "");

  if (amount === 0) {
    return `$${formatted}`;
  } else if (amount < 0) {
    return `-$${formatted}`;
  }
  return `+$${formatted}`;
}
