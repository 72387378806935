import Api from "lib/Api";
import { useQuery } from "react-query";

export function useInvestment(investmentId) {
  return useQuery(
    ["Investment", investmentId],
    async () => {
      const { data } = await Api.get(`/v2/investments/${investmentId}/details`);

      return {
        // ...data.entities,
        details: data.entities.investment_details[0],
        valuationHistory: data.entities.valuation_histories[0].valuations,
        capitalCallHistory:
          data.entities.capital_call_histories.length > 0
            ? data.entities.capital_call_histories[0].capital_calls
            : [],
        distributionHistory:
          data.entities.distribution_history[0].distributions,
        certificateHistory: data.entities.history_actions,
        referrers: data.entities.contacts,
        posts: data.entities.investment_posts,
        documents: data.entities.documents,
        company_avatar: data.entities.company_avatars[0],

        // Added values:
        portfolio: data.entities.investment_portfolio[0],
        company: data.entities.investment_company[0],
        userContacts: data.entities.user_contacts,
      };
    },
    {
      enabled: !!investmentId,
    },
  );
}
