import { useMutation, useQueryClient } from "react-query";
import Api from "lib/Api";

export function useSaveDealDocument() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ document, pipelineId, dealId }) =>
      Api.post(`/pipelines/${pipelineId}/deals/${dealId}/documents`, {
        documents: {
          documentsData: [document],
        },
      }),
    {
      onSuccess: ({ data: newDocument }, { dealId }) => {
        return queryClient.setQueryData(["Deal", dealId], (oldData) => ({
          ...oldData,
          documents: [...oldData.documents, ...newDocument],
        }));
      },
    },
  );
}
