import { connect } from "react-redux";
import CertificateHistory from "./CertificateHistory";
import { isSubmitting } from "store/domain/selectors";
import { fetchInvestmentDocuments } from "store/documents/actions";
import { updateCertificateHistory } from "store/investments/certificateHistory/actions";
import { reloadInvestment } from "store/investments/investment/actions";
import { openInModal, ModalNames } from "store/modal";
import { openModal } from "store/modalV2";
import { fetchCapitalCalls } from "store/investments/capitalCalls/actions";

function mapStateToProps(state, { collection }) {
  const {
    organizations: { currentOrganizationUsername },
  } = state;
  return {
    historyActions: collection,
    isSubmitting: isSubmitting(state, "updateInvestment"),
    organizationUsername: currentOrganizationUsername,
  };
}

function mapDispatchToProps(dispatch, { investment }) {
  const { id: investmentId, type } = investment;
  return {
    updateCertificateHistory: (certificateId, formData) =>
      dispatch(
        updateCertificateHistory(investmentId, certificateId, formData),
      ).then(() => {
        if (formData.documents_data) {
          dispatch(fetchInvestmentDocuments(investmentId));
        }
      }),
    fetchAfterUpdateDocuments: (itemId) => {
      return dispatch(fetchInvestmentDocuments(investmentId)).then(() => {
        //TODO: Remove fetchCapitalCalls after refactor documents in redux (documents repeated 3 times in redux)
        if (type === "fund") {
          dispatch(fetchCapitalCalls(investmentId));
        }
        return dispatch(reloadInvestment(investmentId)).then(
          (updatedInvestment) => {
            const updatedActions = updatedInvestment.history_actions.find(
              (action) => action.id === itemId,
            );
            return updatedActions.documents || [];
          },
        );
      });
    },
    openDocumentsViewerModal: (options) =>
      dispatch(openInModal(ModalNames.documentsViewer, options)),
    openDeleteCertificateModal: (options) =>
      dispatch(openModal("deleteCertificate", options)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CertificateHistory);
