import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Label = ({ message, type, size, inverse }) => {
  const style = classNames(`Label Label-${type}`, `Label-${size}`, {
    [`Label-${type}--inverse`]: inverse,
  });
  return <span className={style}>{message}</span>;
};

Label.propTypes = {
  message: PropTypes.string,
  type: PropTypes.oneOf(["bright", "generic", "warning", "success", "gray"]),
  size: PropTypes.oneOf(["small", "medium"]),
  inverse: PropTypes.bool,
};
Label.defaultProps = {
  message: "",
  type: "bright",
  size: "small",
  inverse: false,
};
export default Label;
