import { useMutation, useQueryClient } from "react-query";
import Api from "lib/Api";

export function useDeleteInvestmentPost() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ resource, postId }) => {
      return Api.delete(
        `/investments/${resource.investment_id}/posts/${postId}`,
      );
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries([
          "InvestmentPosts",
          variables.resource.investment_id,
        ]);
      },
      onError: (error) => {
        throw error;
      },
    },
  );
}
