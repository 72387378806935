import React from "react";
import CloseIcon from "-!svg-react-loader?!assets/icons/utility/close.svg?name=CloseIcon";

function ModalCloseButton({ onClick }) {
  return (
    <span className="ModalCloseButton" onClick={onClick}>
      <CloseIcon />
    </span>
  );
}

export default ModalCloseButton;
