import React, { useContext } from "react";
import { SidebarComponentsContext } from "../SidebarComponentsWrapper";
import Icon from "components_new/atoms/Icon";
import PropTypes from "prop-types";
import classNames from "classnames";

function ViewHeader({ componentName }) {
  const { getComponentState, onUnmount } = useContext(SidebarComponentsContext);

  const { submitTo, index } = getComponentState(componentName);
  const iconName = index === 0 ? "ri-close-line" : "ri-arrow-left-line";
  const sidebarHeaderClassnames = classNames("SidebarView-header", {
    "has-multipleSidebar": index !== 0,
  });

  const onIconClick = () => onUnmount(componentName, submitTo, index);

  return (
    <div className={sidebarHeaderClassnames}>
      <Icon icon={iconName} size="large" onClick={onIconClick} />
    </div>
  );
}

ViewHeader.propTypes = {
  componentName: PropTypes.string.isRequired,
};

export default ViewHeader;
