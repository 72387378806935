import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { investmentsObjectPropType, investmentEventsPropType } from "PropTypes";
import ActivityFeedEventsContainer from "./ActivityFeedEventsContainer";
import EmptyPage from "components_new/elements/EmptyPage";
import ActivityFeedLoadMore from "./ActivityFeedLoadMore";
import getNextActivityPage from "lib/getNextActivityPage";

function ActivityFeedContent({
  events,
  hasMoreEventsToShow,
  investments,
  fetchActivityFeed,
  itemsByPage,
  isFetching,
}) {
  const nextPage = getNextActivityPage(events, itemsByPage);

  if (isEmpty(events)) {
    return (
      <EmptyPage
        headline="You don't have any recent activities!"
        subHeadline="No activity to show."
      />
    );
  }

  return (
    <div>
      <ActivityFeedEventsContainer events={events} investments={investments} />
      {hasMoreEventsToShow && (
        <ActivityFeedLoadMore
          isFetching={isFetching}
          onClick={() => fetchActivityFeed(nextPage, itemsByPage, true)}
        />
      )}
    </div>
  );
}

ActivityFeedContent.propTypes = {
  events: investmentEventsPropType.isRequired,
  hasMoreEventsToShow: PropTypes.bool.isRequired,
  investments: investmentsObjectPropType.isRequired,
  isFetching: PropTypes.bool.isRequired,
  fetchActivityFeed: PropTypes.func.isRequired,
  itemsByPage: PropTypes.number,
};

ActivityFeedContent.defaultProps = {
  isFetching: false,
};

export default ActivityFeedContent;
