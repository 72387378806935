import React, { Fragment, useMemo, useState, useEffect } from "react";
import FileFolders from "./FileFolders";
import TopBar from "./TopBar";
import EmptyCard from "components_new/elements/EmptyCard";
import PropTypes from "prop-types";
import { documentPropType } from "PropTypes";
import { uniq } from "lodash";
import "./CompanyFilesTab.scss";

function CompanyFilesTab({
  files,
  filtersApplied,
  applyFilters,
  updateFile,
  removeFile,
  currentOrganizationUsername,
  allRowsExpanded,
  allRowsCollapsed,
  isExpandedMiscellaneous,
  expandAllRowDetails,
  collapseAllRowDetails,
}) {
  const [filteredFiles, setFilteredFiles] = useState([...files]);

  useEffect(() => {
    const filtered =
      filtersApplied.length > 0
        ? files.filter((item) => filtersApplied.includes(item.category))
        : files;

    setFilteredFiles(filtered);
  }, [files, filtersApplied]);

  const fileTypes = useMemo(() => {
    return uniq(files.map((file) => file.category));
  }, [files]);

  const handleOnApplyFilters = (filters) => {
    applyFilters(filters);
  };

  return (
    <div className="CompanyFilesTab">
      {files.length > 0 ? (
        <Fragment>
          <TopBar
            fileTypes={fileTypes}
            filtersApplied={filtersApplied}
            onApplyFilters={handleOnApplyFilters}
            allRowsExpanded={allRowsExpanded}
            expandAllRowDetails={expandAllRowDetails}
            collapseAllRowDetails={collapseAllRowDetails}
          />
          <FileFolders
            filteredFilesResults={filteredFiles}
            updateFile={updateFile}
            removeFile={removeFile}
            currentOrganizationUsername={currentOrganizationUsername}
            allRowsExpanded={allRowsExpanded}
            allRowsCollapsed={allRowsCollapsed}
            isExpandedMiscellaneous={isExpandedMiscellaneous}
          />
        </Fragment>
      ) : (
        <EmptyCard title="Files" />
      )}
    </div>
  );
}

CompanyFilesTab.propTypes = {
  files: PropTypes.arrayOf(documentPropType).isRequired,
  filtersApplied: PropTypes.arrayOf(PropTypes.string),
  applyFilters: PropTypes.func.isRequired,
  updateFile: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
  currentOrganizationUsername: PropTypes.string.isRequired,
  allRowsExpanded: PropTypes.bool.isRequired,
  allRowsCollapsed: PropTypes.bool.isRequired,
  isExpandedMiscellaneous: PropTypes.bool.isRequired,
  expandAllRowDetails: PropTypes.func.isRequired,
  collapseAllRowDetails: PropTypes.func.isRequired,
};

export default CompanyFilesTab;
