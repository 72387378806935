import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import useOrganizations from "store/organizations/hooks";
import CompanyNoteTab from "./CompanyNoteTab";
import useCompanyNotes from "store/companies/companyNotes/hooks";
import groupBy from "lodash/groupBy";

import { useSetSidebar } from "components_new/elements/SidebarWrapper/Sidebar-context";
import { useCompany } from "components_new/views/Company/hooks/queries/useCompany";
import { useCreateCompanyPost } from "components_new/views/Company/hooks/mutations/useCreateCompanyPost";
import { useUpdateCompanyPost } from "components_new/views/Company/hooks/mutations/useUpdateCompanyPost";
import { useRemoveCompanyPost } from "components_new/views/Company/hooks/mutations/useRemoveCompanyPost";

function CompanyNoteTabWrapper(props) {
  const { currentOrganization } = useOrganizations();
  const usersById = groupBy(
    currentOrganization.organization_memberships,
    "user_id",
  );

  const { showSidebar } = useSetSidebar();
  const { openDocumentsUploader } = useCompanyNotes();
  const { id: companyId, noteId } = useParams();

  const {
    data: { companyPosts },
  } = useCompany(companyId);

  const { mutateAsync: createPost } = useCreateCompanyPost();
  const { mutateAsync: updatePost } = useUpdateCompanyPost();
  const { mutateAsync: removePost } = useRemoveCompanyPost();

  const handleCreatePost = useCallback(
    (resource, formData) => createPost({ resource, formData }),
    [createPost],
  );

  const handleUpdatePost = useCallback(
    (resource, postId, formData) => updatePost({ resource, postId, formData }),
    [updatePost],
  );

  const handleRemovePost = useCallback(
    (resource, postId) => removePost({ resource, postId }),
    [removePost],
  );

  const openContactDetailsView = (contact) => {
    showSidebar("contactsView", { contactId: contact.id });
  };

  return (
    <CompanyNoteTab
      {...props}
      organizationUsername={currentOrganization.username}
      users={usersById}
      posts={companyPosts}
      createPost={handleCreatePost}
      updatePost={handleUpdatePost}
      removePost={handleRemovePost}
      openDocumentsUploader={openDocumentsUploader}
      openContactDetailsView={openContactDetailsView}
      noteToHighlight={noteId}
      resource={{
        type: "Company",
        company_id: companyId,
      }}
    />
  );
}

export default CompanyNoteTabWrapper;
