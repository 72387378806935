import React, { Fragment } from "react";
import Value from "components_new/atoms/Value";
import SubValue from "components_new/atoms/SubValue";
import CompanyInfo from "components_new/elements/CompanyInfo";
import Link from "components_new/atoms/Link";
import Icon from "components_new/atoms/Icon";
import BadgeIcon from "components_new/atoms/BadgeIcon";
import ShowFunds from "components_new/views/CompanyDirectory/shared/CompanyFunds";
import CompanyFlags from "components_new/views/CompanyDirectory/shared/CompanyFlags";
import BasicLabel from "components_new/atoms/BasicLabel";

export const WEBSITE_PREFILL = "http://";

function CompaniesGridItem({
  company,
  organizationUsername,
  handleOpenEditCompanyForm,
}) {
  const {
    location,
    tags,
    status,
    total_invested: totalInvested,
    activity_status: activityStatus,
  } = company;

  const statusInfo = status === "Active" ? "" : `${status.toUpperCase()}`;
  const companyDetailUrl = `/${organizationUsername}/companies/${company.id}`;

  return (
    <div className="CompaniesGridItem">
      <div className="CompaniesGridItem-card">
        <Link to={companyDetailUrl} extraClassnames="CompanyNameTitle-link">
          <CompanyInfo
            handleEdit={handleOpenEditCompanyForm}
            title={(company) => (
              <div className="CompanyTitle">
                <span className="CompanyNameTitle-link">
                  {company.name}
                  {company.verified && (
                    <BadgeIcon size="small" color="primary" />
                  )}
                  <span className="CompanyNameTitle-status">{statusInfo}</span>
                </span>
                <CompanyFlags company={company} />
              </div>
            )}
            content={(company) => (
              <Fragment>
                {company.tier && (
                  <BasicLabel
                    upperCase={true}
                    type="generic"
                    children={`tier ${company.tier}`}
                  />
                )}
                <SubValue textLinesLimit={2}>{company.description}</SubValue>
              </Fragment>
            )}
            company={company}
          />

          {location && (
            <div className="CompanyHeadquarters">
              <Icon icon="ri-map-pin-2-line" size="mini" />
              {location}
            </div>
          )}

          {company.portfolio_names && (
            <div className="CompanyPortfolios">
              <Icon icon="ri-line-chart-line" size="mini" />
              <ShowFunds companyFunds={company.portfolio_names} />
            </div>
          )}

          {tags.length > 0 && (
            <div className="CompanyTags">
              <Icon icon="ri-price-tag-3-line" size="mini" />
              {tags.join(", ")}
            </div>
          )}

          {activityStatus && (
            <div className="CompanyActivityStatus">
              <Icon icon="ri-pushpin-fill" size="mini" />
              {activityStatus}
            </div>
          )}

          <div className="CompanyTotalInvested">
            <span className="CompanyTotalInvested-label">TOTAL INVESTED</span>
            <Value type="currency" precision="2">
              {totalInvested}
            </Value>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default CompaniesGridItem;
