import React, { Fragment } from "react"; // useCallback, useMemo, useState
import Icon from "components_new/atoms/Icon";
import Text from "components_new/atoms/Text";
import TabMenu from "components_new/elements/TabMenu";
import { useDispatch } from "react-redux";
import { openModal } from "store/modalV2";
import { Outlet, useLocation } from "react-router-dom";
import "./QueuesView.scss";

function QueuesView({
  organizationName,
  organizationUsername,
  queueLabel,
  pendingTasksCount,
  snoozedTasksCount,
  completedTasksCount,
  filters,
  updateFilters,
}) {
  const dispatch = useDispatch();
  const location = useLocation();

  const buildPathname = (tab) => {
    return `/${organizationUsername}/queues/${tab}`;
  };

  const isActive = (tab) => {
    return location.pathname === buildPathname(tab);
  };

  return (
    <Fragment>
      <div className="QueuesView">
        <TabMenu
          tabs={[
            {
              name: "Pending Tasks",
              active: isActive("pending"),
              to: buildPathname("pending"),
              count: pendingTasksCount,
              textOnly: true,
            },
            {
              name: "Snoozed Tasks",
              active: isActive("snoozed"),
              to: buildPathname("snoozed"),
              count: snoozedTasksCount,
              textOnly: true,
            },
            {
              name: "Completed",
              active: isActive("completed"),
              to: buildPathname("completed"),
              count: completedTasksCount,
              textOnly: true,
            },
          ]}
          rightContent={
            <a
              className="QueuesView-Help"
              onClick={() =>
                dispatch(
                  openModal("queueModal", {
                    currentOrganizationName: organizationName,
                    queueLabel,
                    isOnModal: true,
                  }),
                )
              }
            >
              <Icon icon="ri-question-line" />
              <Text as="span" type="body-1-bold" color="brand-secondary">
                How it works
              </Text>
            </a>
          }
        />

        <div className="QueuesView-wrapper">
          {React.cloneElement(Outlet().props.children.props.element, {
            queueLabel,
            filters,
            updateFilters,
          })}
        </div>
      </div>
    </Fragment>
  );
}

export default QueuesView;
