import React from "react";
import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";
import NavigationTabs from "components/shared/NavigationTabs";
import useOrganizations from "store/organizations/hooks";

function OrganizationSettingsView() {
  const {
    currentOrganization: { private: isCurrentOrganizationPrivate },
  } = useOrganizations();
  const tabs = [
    {
      url: "/settings/details",
      children: <div>Organization Details</div>,
    },
    {
      url: "/settings/integrations",
      children: <div>Integrations</div>,
    },
    {
      url: "/settings/pipeline",
      children: <div>Deal Stages</div>,
    },
    {
      url: "/settings/custom-fields",
      children: <div>Custom Fields</div>,
    },
    {
      url: "/settings/extras",
      children: <div>Extras</div>,
    },
  ];

  if (!isCurrentOrganizationPrivate) {
    tabs.push({
      url: "/settings/members",
      children: <div>Members</div>,
    });
  }

  return (
    <div className="u-row OrganizationSettingsView">
      <div className="OrganizationSettingsView-sidebar">
        <NavigationTabs orientation="vertical" items={tabs} />
      </div>
      <div className="OrganizationSettingsView-content Container">
        <Outlet />
      </div>
    </div>
  );
}

OrganizationSettingsView.propTypes = {
  isCurrentOrganizationPrivate: PropTypes.bool,
};

export default OrganizationSettingsView;
