import { useMutation, useQueryClient } from "react-query";
import Api from "lib/Api";

export function useUpdateInvestmentDocument() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ document }) => Api.patch(`/documents/${document.id}`, { document }),
    {
      onSuccess: ({ data: newDocument }, { investmentId }) => {
        return queryClient.setQueryData(
          ["Investment", investmentId],
          (oldData) => {
            return {
              ...oldData,
              documents: oldData.documents.map((document) =>
                document.id === newDocument.id ? newDocument : document,
              ),
            };
          },
        );
      },
    },
  );
}
