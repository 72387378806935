export const seriesDropdownItem = [
  { key: 0, text: "Common Stock", value: "Common Stock" },
  { key: 1, text: "Series Seed", value: "Series Seed" },
  { key: 2, text: "Series A", value: "Series A" },
  { key: 3, text: "Series B", value: "Series B" },
  { key: 4, text: "Series C", value: "Series C" },
  { key: 5, text: "Series D", value: "Series D" },
  { key: 6, text: "Series E", value: "Series E" },
  { key: 7, text: "Series F", value: "Series F" },
  { key: 8, text: "Series G", value: "Series G" },
  { key: 9, text: "Series H", value: "Series H" },
];
export const splitSelectionOptions = [
  {
    key: "per_quantity",
    text: "Per Share",
    value: "per_quantity",
  },
  {
    key: "total",
    text: "Total",
    value: "total",
  },
];

export function getDefaultSelectedOption(options, value, param) {
  const defaultOption = options.find((option) => option[param] === value);
  return defaultOption;
}
