import React from "react";
import Input from "components_new/atoms/Input";
import CurrencyInput from "components_new/atoms/CurrencyInput";
import DatePicker from "components_new/atoms/DatePicker";
import LlCAdditionalDetails from "./LlcAdditionalDetails";
import VestingDetails from "components_new/SidebarComponents/forms/groups/VestingDetails";

function LlcInvestmentDetails({ formData, handleChange, errors }) {
  const { name, total_acquisition_amount, date } = formData;

  return (
    <div className="LlcInvestmentDetails BasicDetails ui form">
      <Input
        name="name"
        label="Investment Name"
        placeholder="Name your investment"
        value={name}
        size="tiny"
        onChange={(_, { value }) => {
          handleChange("name", value);
        }}
        isRequired
        focus
        error={!!errors.for("name")}
        errorMessage={errors.for("name")}
      />
      <CurrencyInput
        name="total_acquisition_amount"
        label="Acquisition cost*"
        placeholder="e.g. 100"
        decimalLimit={2}
        defaultValue={total_acquisition_amount}
        value={total_acquisition_amount}
        onChange={(value) => {
          handleChange("total_acquisition_amount", value);
        }}
        size="tiny"
        iconPosition="left"
        error={!!errors.for("total_acquisition_amount")}
        errorMessage={errors.for("total_acquisition_amount")}
      />
      <DatePicker
        name="date"
        label="Acquisition date"
        value={date}
        prefillToday
        onChange={(value) => {
          handleChange("date", value);
        }}
        size="tiny"
        required
        error={!!errors.for("date")}
        errorMessage={errors.for("date")}
      />
      <LlCAdditionalDetails
        formData={formData}
        handleChange={handleChange}
        errors={errors}
      />
      <VestingDetails
        title="Vesting Details"
        formData={formData}
        handleChange={handleChange}
      />
    </div>
  );
}

export default LlcInvestmentDetails;
