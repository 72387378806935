import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { userPropType, postResourceProptype } from "PropTypes";
import CreateDiscussion from "./CreateDiscussion";
import ViewDiscussion from "./ViewDiscussion";
import { UnknownUser } from "components_new/lib/unknownUser";
import useCurrentUser from "hooks/useCurrentUser";
import scrollToElement from "lib/hooks/scrollToElement";
import { isEmpty } from "lodash";
import "./Discussions.scss";

function DiscussionsList({
  createPost,
  updatePost,
  removePost,
  openDocumentsUploader,
  openContactDetailsView,
  posts,
  users,
  organizationUsername,
  extraClassnames,
  resource,
  size,
  showResource = false,
  showDiligenceContact = false,
  isInlineAttachment = false,
  noteToHighlight,
  containerElement,
  extraScrollHeight = 0,
}) {
  const currentUser = useCurrentUser();
  const [hasScrolled, setHasScrolled] = useState(false);
  const [discussion, setDiscussion] = useState(posts);

  useEffect(() => {
    setDiscussion(posts);
  }, [posts]);

  useEffect(() => {
    if (noteToHighlight && !isEmpty(discussion) && !hasScrolled) {
      setHasScrolled(true);
      const container =
        document.getElementsByClassName(containerElement)?.[0] || {};
      const highlightedElement =
        document.getElementsByClassName("isHighlighted")?.[0] || {};

      setTimeout(() => {
        scrollToElement({
          container: container,
          elementPosition:
            highlightedElement.offsetParent.offsetTop + extraScrollHeight,
        });
      }, 500);
    }
  }, [
    discussion,
    hasScrolled,
    noteToHighlight,
    containerElement,
    extraScrollHeight,
  ]);

  function removePostManually(resource, postId, parentId = null) {
    return removePost(resource, postId).then(() => {
      // In here we remove the reply post inside the post collection if that exist
      if (parentId) {
        const newDiscussion = [...discussion];
        const parentPostIndex = newDiscussion.findIndex(
          (post) => post.id === parentId,
        );
        const replyIndex = newDiscussion[parentPostIndex].replies.findIndex(
          (reply) => reply.id === postId,
        );
        newDiscussion[parentPostIndex].replies.splice(replyIndex, 1);
        setDiscussion(newDiscussion);
      }
    });
  }

  const discussionClassname = classNames("Discussion Container", {
    [extraClassnames]: !!extraClassnames,
  });

  return (
    <section className={discussionClassname}>
      <CreateDiscussion
        createAction={createPost}
        user={currentUser}
        openDocumentsUploader={openDocumentsUploader}
        resource={resource}
        placeholder="Add a note"
        isInlineAttachment={isInlineAttachment}
        size={size}
      />
      {discussion.map((post, index) => {
        const user = users[post.author_id]
          ? users[post.author_id][0]
          : UnknownUser;
        const isHighlighted = noteToHighlight === post.id.toString();

        return (
          <ul className="Discussion-posts u-groupSelectText" key={index}>
            <ViewDiscussion
              key={index}
              user={user}
              post={post}
              users={users}
              currentUser={currentUser}
              editAction={updatePost}
              deleteAction={removePostManually}
              openDocumentsUploader={openDocumentsUploader}
              organizationUsername={organizationUsername}
              replyAction={createPost}
              size={size}
              isAvailableToReply={true}
              isInlineAttachment={isInlineAttachment}
              showResource={showResource}
              showDiligenceContact={showDiligenceContact}
              openContactDetailsView={openContactDetailsView}
              isHighlighted={isHighlighted}
            />
          </ul>
        );
      })}
    </section>
  );
}

DiscussionsList.propTypes = {
  users: PropTypes.shape({ userPropType }),
  createPost: PropTypes.func.isRequired,
  updatePost: PropTypes.func.isRequired,
  removePost: PropTypes.func.isRequired,
  openDocumentsUploader: PropTypes.func.isRequired,
  organizationUsername: PropTypes.string.isRequired,
  resource: postResourceProptype,
  extraClassnames: PropTypes.string,
  showResource: PropTypes.bool,
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      content: PropTypes.string,
      date: PropTypes.string,
      deal_id: PropTypes.string,
      author_id: PropTypes.number,
    }),
  ),
  isInlineAttachment: PropTypes.bool,
  size: PropTypes.string,
  showDiligenceContact: PropTypes.bool,
  openContactDetailsView: PropTypes.func,
  noteToHighlight: PropTypes.string,
  containerElement: PropTypes.string,
  extraScrollHeight: PropTypes.number,
};

DiscussionsList.defaultProps = {
  showResource: false,
  noteToHighlight: null,
  containerElement: "PageContent",
};

export default DiscussionsList;
