import React from "react";
import PropTypes from "prop-types";
import EmptyPage from "components_new/elements/EmptyPage";
import Button from "components_new/atoms/Button";
import MightyIcon from "components_new/atoms/MightyIcon";
import Icon from "components_new/atoms/Icon";

function EmptyPipelines({
  isOrganizationOwner,
  createPipeline,
  isSubmitting,
  hasPipelines,
}) {
  return (
    <EmptyPage
      renderIcon={() => <MightyIcon icon="deal-xlrg" />}
      headline={
        isOrganizationOwner
          ? "You haven't created any deal stage yet!"
          : "Your organization hasn't created any deal stage yet!"
      }
      subHeadline={
        isOrganizationOwner
          ? "Create a deal stage to collaborate with your team and track your organization's deals."
          : "Contact your organization's owner to have them create a deal stage for your team."
      }
      clickToAction={
        isOrganizationOwner
          ? () => {
              return (
                <Button
                  primary
                  onClick={() => createPipeline()}
                  loading={isSubmitting}
                  disabled={isSubmitting || hasPipelines}
                >
                  <Icon icon="ri-add-line" />
                  <span className="Button-text">New Pipeline</span>
                </Button>
              );
            }
          : null
      }
    />
  );
}

EmptyPipelines.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  hasPipelines: PropTypes.bool.isRequired,
  isOrganizationOwner: PropTypes.bool.isRequired,
  createPipeline: PropTypes.func.isRequired,
};

EmptyPipelines.defaultProps = {
  isOrganizationOwner: true,
  createPipeline: () => {},
  isSubmitting: false,
  hasPipelines: false,
};

export default EmptyPipelines;
