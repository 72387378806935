import React, { useRef, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import TextInput from "components/shared/form/TextInput";
import LoginLayout from "components/auth/LoginLayout";
import classNames from "classnames";
import Select from "components/shared/form/Select";
import MightyLogoIcon from "-!svg-react-loader?!assets/icons/rf-mighty-logo.svg?name=MightyLogoIcon";
import PrimaryButton from "components/shared/PrimaryButton";
import Scroll from "react-scroll";
import {
  investorTypes,
  yearlyInvestmentsCountRanges,
  portfoliosCountRanges,
} from "config/signUpRequestConfig";
import { useSelector, useDispatch } from "react-redux";
import Errors from "lib/errors";
import usePrevious from "lib/hooks/usePrevious";
import {
  resetSignUpRequestForm,
  updateSignUpRequestForm,
} from "actions/synchronous/signUpRequestForm";
import useSignUp from "store/auth/useSignUp";
import { motion } from "framer-motion";
import { fadeIn, animationFromLeft } from "lib/animations";

function Thanks() {
  return (
    <div>
      <div className="LoginLogoHolder">
        <MightyLogoIcon className="LoginLogo" />
        <div className="AlphaLogoTextHolder">
          <div className="u-signupRequestAlphaLogo AlphaLogoText">alpha</div>
        </div>
      </div>
      <motion.h1
        initial="hidden"
        animate="visible"
        variants={fadeIn}
        transition={{ duration: 0.05 }}
        className="u-heading SignUpRequestTitle"
      >
        Thanks!
      </motion.h1>
      <motion.p
        initial="hidden"
        animate="visible"
        variants={fadeIn}
        transition={{ duration: 0.15 }}
        className="u-signUpRequestMessage SignUpRequestMessage"
      >
        Your request has been sent and we&rsquo;ll be in touch shortly.
      </motion.p>
    </div>
  );
}

function SignupRequestForm({ handleSignUp, submitting }) {
  const dispatch = useDispatch();
  const { formData, errors } = useSelector(
    ({ signUpRequestForm: { formData, errors } }) => {
      return {
        formData,
        errors: new Errors(errors),
      };
    },
  );

  const updateForm = useCallback(
    (key, value) => dispatch(updateSignUpRequestForm(key, value)),
    [dispatch],
  );

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      await handleSignUp(formData);
      window.scrollTo(0, 0);
    },
    [handleSignUp, formData],
  );

  const signupRequestForm = useRef();

  const previousErrors = usePrevious(errors);

  useEffect(() => {
    if (previousErrors !== errors && Object.keys(errors).length > 0) {
      const elementsWithErrors = signupRequestForm.current.getElementsByClassName(
        "FormElement has-errors",
      );

      if (elementsWithErrors.length > 0) {
        const firstElementWithErrors = elementsWithErrors[0];
        const elementName = firstElementWithErrors.getAttribute("name");
        Scroll.scroller.scrollTo(elementName, {
          duration: 1200,
          delay: 100,
          offset: -101,
          smooth: true,
        });
      }
    }
  }, [previousErrors, errors]);

  const isFormValid = useCallback(() => {
    return (
      formData.name &&
      formData.name !== "" &&
      formData.email &&
      formData.email !== "" &&
      formData.investor_type &&
      formData.yearly_investments_count_range &&
      formData.portfolios_count_range
    );
  }, [formData]);

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="LoginFormContainer"
        ref={signupRequestForm}
      >
        <div className="LoginLogoHolder">
          <MightyLogoIcon className="LoginLogo" />
          <div className="AlphaLogoTextHolder">
            <div className="u-signupRequestAlphaLogo AlphaLogoText">alpha</div>
          </div>
        </div>
        <motion.h1
          initial="hidden"
          animate="visible"
          variants={animationFromLeft()}
          transition={{ duration: 0.15 }}
          className="u-heading SignUpRequestTitle"
        >
          Sign Up for the Private Alpha
        </motion.h1>
        <motion.p
          initial="hidden"
          animate="visible"
          variants={animationFromLeft({ delay: 0.1 })}
          transition={{ duration: 0.15 }}
          className="u-signUpRequestMessage SignUpRequestMessage"
        >
          Mighty Portfolio is currently in private alpha to a select number of
          investors.
        </motion.p>
        <motion.p
          initial="hidden"
          animate="visible"
          variants={animationFromLeft({ delay: 0.2 })}
          transition={{ duration: 0.15 }}
          className="u-signUpRequestMessage SignUpRequestMessage"
        >
          We&rsquo;ll be inviting more soon, so please tell us about yourself.
        </motion.p>
        <motion.div
          initial="hidden"
          animate="visible"
          variants={animationFromLeft({ delay: 0.3 })}
          transition={{ duration: 0.15 }}
        >
          <TextInput
            extraClassnames="LoginAnimatedElement"
            label="Name"
            name="name"
            onChange={(value) => updateForm("name", value)}
            autoFocus
          />
        </motion.div>
        <motion.div
          initial="hidden"
          animate="visible"
          variants={animationFromLeft({ delay: 0.4 })}
          transition={{ duration: 0.15 }}
        >
          <TextInput
            label="Email address"
            name="email"
            onChange={(value) => updateForm("email", value)}
            errorMessage={errors.for("email")}
          />
        </motion.div>
        <motion.div
          initial="hidden"
          animate="visible"
          variants={animationFromLeft({ delay: 0.5 })}
          transition={{ duration: 0.15 }}
        >
          <TextInput
            extraClassnames="FormElement--company"
            label="Company"
            name="company"
            onChange={(value) => updateForm("company", value)}
            errorMessage={errors.for("company")}
            isOptional
          />
        </motion.div>
        <motion.div
          initial="hidden"
          animate="visible"
          variants={animationFromLeft({ delay: 0.6 })}
          transition={{ duration: 0.15 }}
        >
          <Select
            label="What type of investor are you?"
            name="investor_type"
            placeholder="Please choose.."
            value={formData.investor_type}
            inputProps={investorTypes}
            errorMessage={errors.for("investor_type")}
            onChange={(value) => updateForm("investor_type", value)}
          />
        </motion.div>
        <motion.div
          initial="hidden"
          animate="visible"
          variants={animationFromLeft({ delay: 0.7 })}
          transition={{ duration: 0.15 }}
        >
          <Select
            extraClassnames="LoginAnimatedElement"
            label="How many investments do you make per year?"
            name="yearly_investments_count_range"
            placeholder="Please choose.."
            value={formData.yearly_investments_count_range}
            inputProps={yearlyInvestmentsCountRanges}
            errorMessage={errors.for("yearly_investments_count_range")}
            onChange={(value) =>
              updateForm("yearly_investments_count_range", value)
            }
          />
        </motion.div>
        <motion.div
          initial="hidden"
          animate="visible"
          variants={animationFromLeft({ delay: 0.8 })}
          transition={{ duration: 0.15 }}
        >
          <Select
            extraClassnames="LoginAnimatedElement FormElement--portfolioCountRange"
            label="How many portfolios do you manage?"
            name="portfolios_count_range"
            placeholder="Please choose.."
            value={formData.portfolios_count_range}
            inputProps={portfoliosCountRanges}
            errorMessage={errors.for("portfolios_count_range")}
            onChange={(value) => updateForm("portfolios_count_range", value)}
          />
        </motion.div>
        <PrimaryButton
          customClass="LoginAnimatedElement"
          type="submit"
          filter={{
            applyingFilter: submitting,
          }}
          disabled={!isFormValid()}
        >
          <motion.span
            initial="hidden"
            animate="visible"
            variants={animationFromLeft({ delay: 0.9 })}
            transition={{ duration: 0.15 }}
            className="Button-text"
          >
            Send Request
          </motion.span>
        </PrimaryButton>
        <Link
          className="LoginAnimatedElement BackToLoginHolder BackToLogin"
          to="/login"
        >
          <motion.div
            initial="hidden"
            animate="visible"
            variants={animationFromLeft({ delay: 1 })}
            transition={{ duration: 0.15 }}
          >
            Already have an account? Sign in here
          </motion.div>
        </Link>
      </form>
    </div>
  );
}

function SignUpRequest({ submitting }) {
  const loginFormHolderClassnames = classNames({
    "submitting-form": submitting,
    "LoginForm-holder": true,
  });

  const dispatch = useDispatch();
  const resetForm = useCallback(() => dispatch(resetSignUpRequestForm()), [
    dispatch,
  ]);

  const { doSignUp, signUpSuccess, isSubmitting } = useSignUp();

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  return (
    <LoginLayout extraClassnames="Login--signUpRequest">
      <div className="Login-holder">
        <div className="u-bg-tint-0 LoginForm">
          <div className={loginFormHolderClassnames}>
            {signUpSuccess ? (
              <Thanks />
            ) : (
              <SignupRequestForm
                handleSignUp={doSignUp}
                submitting={isSubmitting}
              />
            )}
          </div>
        </div>
      </div>
    </LoginLayout>
  );
}

export default SignUpRequest;
