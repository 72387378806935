import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { openModal } from "store/modalV2";
import ContactActionsDropdown from "./ContactActionsDropdown";
import { SidebarComponentsContext } from "components_new/SidebarComponents/SidebarComponentsWrapper";
import useSidebar from "store/sidebar/hooks";

function ContactActionsDropdownWrapper(props) {
  const { contact, callback, sidebarParent } = props;
  const dispatch = useDispatch();
  const { openSidebar } = useSidebar();
  const openConfirmDeleteContact = () =>
    dispatch(
      openModal("confirmDeleteContact", {
        contact,
        callback,
      }),
    );

  let openContactSidebarForm;

  const sidebarContext = useContext(SidebarComponentsContext);
  if (sidebarContext) {
    const { mountSidebarComponent, hideSidebarComponent } = sidebarContext;
    openContactSidebarForm = () => {
      hideSidebarComponent(sidebarParent);
      mountSidebarComponent(
        "editContactForm",
        "Edit Contact",
        { referrer: contact },
        sidebarParent,
      );
    };
  } else {
    openContactSidebarForm = () =>
      dispatch(
        openSidebar("editContactForm", "Edit Contact", { referrer: contact }),
      );
  }

  return (
    <ContactActionsDropdown
      onDelete={openConfirmDeleteContact}
      onEdit={openContactSidebarForm}
      {...props}
    />
  );
}

export default ContactActionsDropdownWrapper;
