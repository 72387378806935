import React from "react";
import CompanyInfo from "components_new/elements/CompanyInfo";
import CompanyFlags from "components_new/views/CompanyDirectory/shared/CompanyFlags";
import CompanyLinkValue from "components_new/views/Company/shared/CompanyLinkValue";
import Link from "components_new/atoms/Link";
import EllipsisDropdown from "components_new/elements/EllipsisDropdown";
import { TableCell, TableRow } from "components_new/elements/Table";

const WEBSITE_PREFILL = "http://";

function CompanyInvestorRow({
  organizationUsername,
  openEditCoInvestmentForm,
  openDeleteCoInvestment,
  coInvestment,
}) {
  const { round_name: roundName, company } = coInvestment;

  const investorActions = [
    {
      key: "edit",
      text: "Edit Co-Investment",
      onClick: () => openEditCoInvestmentForm(coInvestment),
    },
    {
      key: "delete",
      text: "Delete Co-Investment",
      onClick: () => openDeleteCoInvestment(coInvestment),
    },
  ];

  const companyDetailUrl = `/${organizationUsername}/companies/${company.id}`;
  const statusInfo =
    status === "Active" ? "" : `${company.status.toUpperCase()}`;

  return (
    <TableRow>
      <TableCell className="CompanyColumn">
        <CompanyInfo
          title={() => (
            <div className="CompanyName">
              <div className="CompanyNameTitle">
                <Link
                  to={companyDetailUrl}
                  extraClassnames="CompanyNameTitle-link"
                >
                  {company.name}
                </Link>
                <span className="CompanyNameTitle-status">{statusInfo}</span>
              </div>
              {company.website && (
                <CompanyLinkValue
                  url={company.website}
                  label={company.website}
                  preFilled={WEBSITE_PREFILL}
                />
              )}
            </div>
          )}
          content={(company) => <CompanyFlags company={company} />}
          company={company}
          tier={company.tier}
        />
      </TableCell>
      <TableCell className="RoundNameColumn">{roundName}</TableCell>
      <TableCell className="ActivityStatusColumn">
        {company.activity_status}
      </TableCell>
      <TableCell className="ActionColumn u-textAlignRight">
        <EllipsisDropdown
          options={investorActions}
          iconOrientation="vertical"
          iconSize="small"
        />
      </TableCell>
    </TableRow>
  );
}

export default CompanyInvestorRow;
