import React, { useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Icon from "components_new/atoms/Icon";
import Link from "components_new/atoms/Link";
import Message from "components_new/atoms/Message";
import { isEmpty } from "lodash";
import "./Alert.scss";

const DEFAULT_TIME = 6000;

function Alert({
  type,
  title,
  subTitle,
  url,
  urlTitle,
  onClose,
  preserve = false,
  id = new Date().getTime().toString(8),
}) {
  const alertClassnames = classNames("AlertWrapper", {
    [type]: !!type,
  });

  const alertIcon = {
    informational: (
      <Icon extraClassnames="Alert-icon" icon="ri-information-line" />
    ),
    success: (
      <Icon extraClassnames="Alert-icon" icon="ri-checkbox-circle-line" />
    ),
    warning: <Icon extraClassnames="Alert-icon" icon="ri-alert-line" />,
    error: <Icon extraClassnames="Alert-icon" icon="ri-forbid-line" />,
  };

  useEffect(() => {
    if (preserve) return;

    const timer = setTimeout(() => {
      onClose(id);
    }, DEFAULT_TIME);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preserve, id]);

  return (
    <Message className={alertClassnames}>
      <div className="Alert-content">
        {alertIcon[type]}
        <div className="Alert-message">
          <span className="Alert-title">{title}.</span> {subTitle && subTitle}
        </div>
        <Icon
          data-testid="Alert-close"
          extraClassnames="Alert-close"
          onClick={onClose}
          icon="ri-close-line"
        />
        {!isEmpty(url) && (
          <Link extraClassnames="Alert-link" title={urlTitle} to={url} />
        )}
      </div>
    </Message>
  );
}

Alert.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  urlTitle: PropTypes.string,
  url: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  preserve: PropTypes.bool,
  inline: PropTypes.bool,
};

export default Alert;
