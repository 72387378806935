import React from "react";
import TableRow from "./TableRow";

function CustomFieldTable({
  data,
  onDelete,
  onSubmit,
  isSubmitting,
  addCustomField,
  setAddCustomField,
  openItem,
  setOpenItem,
  isOwner,
}) {
  function onEdit(value, customFieldId) {
    return onSubmit(value, customFieldId).then(() => {
      setOpenItem(null);
    });
  }
  return (
    <div className="Table">
      <div className="TableHeader">
        <div className="u-label ActionsTable-HeaderCell ActionsTable-label">
          Label
        </div>
        <div className="u-label ActionsTable-HeaderCell ActionsTable-type">
          Field Type
        </div>
        <div className="u-label ActionsTable-HeaderCell ActionsTable-holdings">
          Holdings
        </div>
      </div>
      {data.map((item, index) => {
        return (
          <TableRow
            key={index}
            rowIndex={index}
            data={item}
            addCustomField={addCustomField}
            setAddCustomField={setAddCustomField}
            isSubmitting={isSubmitting}
            onSubmit={(value) => onEdit(value, item.id)}
            onDelete={onDelete}
            openRow={setOpenItem}
            isOpen={openItem === item.id}
            isOwner={isOwner}
          />
        );
      })}
    </div>
  );
}

export default CustomFieldTable;
