import React from "react";
import { placeholderDateFormat } from "config/dateInput";
import FormGroup from "components_new/elements/FormGroup";
import DatePicker from "components_new/atoms/DatePicker";
import Checkbox from "components_new/atoms/Checkbox";
import "./WriteOff.scss";

function WriteOff({
  title = "Write Off Details",
  subtitle,
  formData,
  onChange,
  errors,
  isExpanded = true,
  required = false,
}) {
  return (
    <FormGroup
      extraClassnames="WriteOffGroup"
      title={title}
      required={required}
      subtitle={subtitle}
      isExpanded={isExpanded}
    >
      <DatePicker
        name="date"
        placeholder={placeholderDateFormat}
        extraClassnames="FormElement"
        label="Date of write-off"
        onChange={(value, validated) => {
          onChange("date", value, validated);
        }}
        error={!!errors.for("date")}
        errorMessage={errors.for("date")}
        value={formData.date}
        prefillToday={true}
        oldVersion
        size="tiny"
      />
      <Checkbox
        name="is_update_company_status_checked"
        label="Update company status as failed"
        checked={formData.is_update_company_status_checked}
        extraClassName="FormElement"
        size="tiny"
        onChange={(_, { checked }) =>
          onChange("is_update_company_status_checked", checked)
        }
      />
    </FormGroup>
  );
}

export default WriteOff;
