import { camelCase } from "lodash";

export const RECEIVE_COLLECTION = "entities/RECEIVE_COLLECTION";
export const CLEAR_COLLECTION = "entities/CLEAR_COLLECTION";

export function receiveCollection(name, data) {
  return { type: RECEIVE_COLLECTION, name, data };
}

export function clearCollection(name) {
  return { type: CLEAR_COLLECTION, name };
}

export function receiveCollections(collections) {
  return (dispatch) => {
    for (let collectionName in collections) {
      if (collections.hasOwnProperty(collectionName)) {
        dispatch(
          receiveCollection(
            camelCase(collectionName),
            collections[collectionName],
          ),
        );
      }
    }
  };
}
