import { removeFlash } from "actions/synchronous/flash";

export function scheduleFlashRemoval(flash, seconds) {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(removeFlash(flash.id));
    }, seconds);
  };
}

export default {
  scheduleFlashRemoval,
};
