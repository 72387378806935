import React from "react";
import PropTypes from "prop-types";
import { documentPropType } from "PropTypes";
import AttachmentRow from "./AttachmentRow";
import AttachmentIcon from "-!svg-react-loader?!assets/icons/utility/attachment.svg?name=AttachmentIcon";

function AttachmentsList({
  attachments,
  onRemoveDocument,
  onAttachFilesClick,
  showAddAttachmentLink,
}) {
  return (
    <div className="AttachmentsList">
      {showAddAttachmentLink && (
        <span
          className="Link Link--underline AttachmentsList-addAttachmentButton"
          onClick={onAttachFilesClick}
          tabIndex="0"
        >
          <AttachmentIcon />
          <span>Attach a File</span>
        </span>
      )}
      {attachments.length > 0 && (
        <div className="AttachmentsList-content">
          {attachments.map((givenAttachment, key) => (
            <AttachmentRow
              key={key}
              documentFile={givenAttachment}
              onRemoveDocument={onRemoveDocument}
            />
          ))}
        </div>
      )}
    </div>
  );
}

AttachmentsList.propTypes = {
  attachments: PropTypes.arrayOf(documentPropType).isRequired,
  onRemoveDocument: PropTypes.func.isRequired,
  onAttachFilesClick: PropTypes.func.isRequired,
  showAddAttachmentLink: PropTypes.bool,
};

AttachmentsList.defaultProps = {
  showAddAttachmentLink: true,
};

export default AttachmentsList;
