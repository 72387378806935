import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Dropdown as SemanticDropdown } from "@ableco/semantic-ui-react";
import Icon from "components_new/atoms/Icon";
import classnames from "classnames";
import "./EllipsisDropdown.scss";

const EllipsisDropdown = ({
  options,
  iconOrientation,
  iconSize,
  disabled,
  align,
  extraClassnames,
}) => {
  const iconName =
    iconOrientation === "vertical" ? "ri-more-2-fill" : "ri-more-fill";

  const ellipsisDropdownClassnames = classnames("EllipsisDropdown", {
    [`align-${align}`]: !!align,
    [extraClassnames]: !!extraClassnames,
  });

  return (
    <SemanticDropdown
      className={ellipsisDropdownClassnames}
      data-testid="EllipsisDropdown"
      icon={null}
      trigger={<Icon icon={iconName} size={iconSize} />}
      disabled={disabled}
    >
      <SemanticDropdown.Menu>
        {options &&
          options.map((option, index) => {
            const { key, dividerTop, dividerBottom, ...itemProps } = option;
            return (
              <Fragment key={key || index}>
                {dividerTop && <SemanticDropdown.Divider />}
                <SemanticDropdown.Item {...itemProps} />
                {dividerBottom && <SemanticDropdown.Divider />}
              </Fragment>
            );
          })}
      </SemanticDropdown.Menu>
    </SemanticDropdown>
  );
};

EllipsisDropdown.propTypes = {
  iconOrientation: PropTypes.oneOf(["vertical", "horizontal"]),
  iconSize: PropTypes.oneOf(["mini", "tiny", "small", "regular", "large"]),
  align: PropTypes.oneOf(["left", "right"]),
  options: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  extraClassnames: PropTypes.string,
};

EllipsisDropdown.defaultProps = {
  iconOrientation: "vertical",
  align: "right",
};

export default EllipsisDropdown;
