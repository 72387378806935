import { ascend, descend, sortWith } from "ramda";
import {
  parseDateColumn,
  parseStringColumn,
} from "components_new/elements/Table/config";

export const defaultOrderByDate = sortWith([
  descend(parseDateColumn("date")),
  ascend(parseStringColumn("company_name")),
  ascend(parseStringColumn("name")),
]);

export const defaultOrderByCompanyName = sortWith([
  ascend(parseStringColumn("company_name")),
  ascend(parseStringColumn("name")),
  descend(parseDateColumn("date")),
]);
