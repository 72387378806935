import {
  RECEIVE_LOAN,
  CREATE_LOAN_CONVERSION_START,
  CREATE_LOAN_CONVERSION_SUCCESS,
  VALIDATE_LOAN_CONVERSION_ERRORS,
  FETCH_EXISTING_LOAN_START,
  FETCH_EXISTING_LOAN_SUCCESS,
  FETCH_EXISTING_LOAN_ERRORS,
  UPDATE_LOAN_CONVERSION_FORM,
  REMOVE_VALUATION_AMOUNT,
  STOP_ERROR_SCROLLING_ON_LOAN_CONVERSION,
  START_ERROR_SCROLLING_ON_LOAN_CONVERSION,
  VALIDATE_LOAN_CONVERSION_SUCCESS,
  VALIDATE_LOAN_CONVERSION_START,
  UPDATE_DOCUMENT,
  REGISTER_LOG_LOAN_CONVERSION_DOCUMENTS,
} from "./actionTypes";
import { omit } from "lodash";

const initialState = {
  documents: {},
  formData: {
    custom_name: "",
    create_extra_valuation: false,
    accrued_interest: "0",
    principal_amount: "",
    calculated_conversion_amount: "",
  },
  loan: [],
  submitting: false,
  isFetching: false,
  showModalConfirmation: false,
  scrollToFirstFormElementWithError: false,
  errors: {},
};

function loanConversionForm(state = initialState, action) {
  switch (action.type) {
    case UPDATE_LOAN_CONVERSION_FORM:
      const keyForm = action.key === "custom_name" ? "series_name" : action.key;

      return {
        ...state,
        formData: { ...state.formData, [action.key]: action.value },
        errors: { ...state.errors, [keyForm]: null },
      };
    case REMOVE_VALUATION_AMOUNT:
      return {
        ...state,
        formData: omit(state.formData, ["valuation_amount"]),
      };
    case RECEIVE_LOAN:
      return {
        ...state,
        loan: action.loan || state.loan,
      };
    case CREATE_LOAN_CONVERSION_START:
      return { ...state, submitting: true };
    case CREATE_LOAN_CONVERSION_SUCCESS:
      return initialState;

    case VALIDATE_LOAN_CONVERSION_START:
      return {
        ...state,
        submitting: true,
      };
    case VALIDATE_LOAN_CONVERSION_SUCCESS:
      return {
        ...state,
        submitting: false,
      };
    case VALIDATE_LOAN_CONVERSION_ERRORS:
      return {
        ...state,
        formData: {
          ...state.formData,
          series_name: state.formData.custom_name
            ? "other"
            : state.formData.series_name,
        },
        errors: { ...state.errors, ...action.errors },
        submitting: false,
      };
    case FETCH_EXISTING_LOAN_START:
      return { ...state, isFetching: true };
    case FETCH_EXISTING_LOAN_SUCCESS:
      return { ...state, isFetching: false };
    case FETCH_EXISTING_LOAN_ERRORS:
      return {
        ...state,
        errors: { ...state.errors, ...action.errors },
        isFetching: false,
      };
    case REGISTER_LOG_LOAN_CONVERSION_DOCUMENTS:
      const givenDocuments = action.filepickerData.reduce(
        (documents, givenDocument) => ({
          ...documents,
          [givenDocument.handle]: givenDocument,
        }),
        {},
      );
      return {
        ...state,
        documents: givenDocuments,
        currentDocument: state.currentDocument + 1,
      };
    case START_ERROR_SCROLLING_ON_LOAN_CONVERSION:
      return {
        ...state,
        scrollToFirstFormElementWithError: true,
      };
    case STOP_ERROR_SCROLLING_ON_LOAN_CONVERSION:
      return {
        ...state,
        scrollToFirstFormElementWithError: false,
      };
    case UPDATE_DOCUMENT:
      return {
        ...state,
        documents: {
          ...state.documents,
          [action.documentHandle]: {
            ...state.documents[action.documentHandle],
            [action.key]: action.value,
          },
        },
      };
    default:
      return state;
  }
}

export default loanConversionForm;
