import React from "react";
import Icon from "components_new/atoms/Icon";
import UserAvatar from "components_new/elements/UserAvatar";
import EmptyCard from "components_new/elements/EmptyCard";
import Due from "components_new/elements/Due";
import formatDate from "lib/formatDate";
import classNames from "classnames";
import { isEmpty } from "lodash";

function TaskListItems({ activeTask, setActiveTask, tasks }) {
  if (tasks.length === 0) {
    <EmptyCard title="tasks in your Queue" />;
  }

  return tasks.map((task) => {
    const tasklistItemClassnames = classNames("TaskList-Item", {
      active: task.id === activeTask?.id,
      unread: !task.read_at,
    });
    return (
      <div
        className={tasklistItemClassnames}
        onClick={() => setActiveTask(task)}
        key={`tasklistitem-${task.id}`}
      >
        <div className="TaskList-Item-Alpha">
          <div className="TaskList-Item-Title">
            {task.subject || "(No subject)"}
          </div>
          <div className="TaskList-Item-Regarding">{task?.taskable_name}</div>
          <div className="TaskList-Item-ForwardAddress">
            <Icon icon="ri-user-line" size="mini" />
            <div className="TaskList-Item-addedBy">
              Added by <span>{`${task.creator || task.from}`}</span>
            </div>
          </div>
        </div>
        <div className="TaskList-Item-Alerts">
          {task.due_date && <Due date={task.due_date} unread={!task.read_at} />}
          {task.snoozed && (
            <div className="TaskList-Item-Snoozed-Date">
              <Icon size="tiny" icon="ri-alarm-line" />
              {` until ${formatDate(task.snoozed_until, "monthAndDay")}`}
            </div>
          )}
        </div>
        {(!isEmpty(task.owners) || task.attachments.length > 0) && (
          <div className="TaskList-Item-Info">
            {!isEmpty(task.owners) && (
              <div className="TaskList-Item-Owner">
                <UserAvatar
                  avatarColor={task.owner_avatar_color}
                  initials={task.owner_initials}
                  showTooltip={false}
                  size="small"
                />
              </div>
            )}
            {task.attachments.length > 0 && (
              <div className="TaskList-Item-Attachment">
                <Icon icon="ri-attachment-2" size="tiny" />
              </div>
            )}
          </div>
        )}
      </div>
    );
  });
}

export default TaskListItems;
