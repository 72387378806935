import React from "react";
import { useParams } from "react-router-dom";

function withParamProp(Component) {
  const WithParamProp = (props) => {
    const params = useParams();
    return <Component {...props} params={params} />;
  };

  WithParamProp.displayName = `WithParamProp(${Component.name})`;
  return WithParamProp;
}

export default withParamProp;
