import React from "react";
import PropTypes from "prop-types";
import PrimaryButton from "components/shared/PrimaryButton";
import SecondaryButton from "components/shared/SecondaryButton";
import { noop } from "lodash";
import ModalFooter from "components/shared/Modal/ModalFooter";

function PrimarySecondaryButtonFooter({
  disableCancelButton,
  disableDoneButton,
  onCancelButtonClick,
  onContinueButtonClick,
  onDoneButtonClick,
  showCancelButton,
  showContinueButton,
  showDoneButton,
  isSubmitting,
}) {
  const continueButton = showContinueButton ? (
    <PrimaryButton onClick={onContinueButtonClick}>Continue</PrimaryButton>
  ) : null;

  const cancelButton = showCancelButton ? (
    <SecondaryButton
      onClick={onCancelButtonClick}
      disabled={disableCancelButton}
    >
      Cancel
    </SecondaryButton>
  ) : null;

  const doneButton = showDoneButton ? (
    <PrimaryButton
      filter={{
        applyingFilter: isSubmitting,
      }}
      onClick={onDoneButtonClick}
      disabled={disableDoneButton}
    >
      Done
    </PrimaryButton>
  ) : null;

  return (
    <ModalFooter>
      <div className="ModalFooter-inner">
        <div className="ModalFooter-actionButtons">
          {continueButton}
          {doneButton}
          {cancelButton}
        </div>
      </div>
    </ModalFooter>
  );
}

PrimarySecondaryButtonFooter.propTypes = {
  disableCancelButton: PropTypes.bool,
  disableDoneButton: PropTypes.bool,
  onCancelButtonClick: PropTypes.func,
  onContinueButtonClick: PropTypes.func,
  onDoneButtonClick: PropTypes.func,
  showCancelButton: PropTypes.bool,
  showContinueButton: PropTypes.bool,
  showDoneButton: PropTypes.bool,
  showUploader: PropTypes.bool,
  isSubmitting: PropTypes.bool,
};

PrimarySecondaryButtonFooter.defaultProps = {
  disableCancelButton: false,
  disableDoneButton: false,
  onCancelButtonClick: noop,
  onContinueButtonClick: noop,
  onDoneButtonClick: noop,
  showCancelButton: true,
  showContinueButton: false,
  showDoneButton: false,
  showUploader: true,
  isSubmitting: false,
};

export default PrimarySecondaryButtonFooter;
