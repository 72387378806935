import Api from "lib/Api";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

export function useCreateCoInvestment() {
  const queryClient = useQueryClient();
  const { organizationUsername } = useParams();

  return useMutation(
    ({ formData }) =>
      Api.post(`/v2/co_investments`, {
        co_investment: formData,
      }),
    {
      onSuccess: ({ data: newCoInvestment }, { companyId }) => {
        const key = ["Company", organizationUsername, companyId];

        const collectionKey =
          newCoInvestment.investor.id === companyId
            ? "investorCoInvestments"
            : "companyCoInvestments";

        queryClient.setQueryData(key, (oldData) => ({
          ...oldData,
          [collectionKey]: [...oldData?.[collectionKey], newCoInvestment],
        }));
      },
    },
  );
}
