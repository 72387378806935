import { combineReducers } from "redux";
import { reducer as domain } from "store/domain";
import { reducer as documents } from "store/documents";
import { reducer as portfolioProfile } from "store/portfolioProfile";
import { reducer as search } from "store/search";
import { reducer as companyView } from "store/companies/companyView";
import { reducer as loanConversionForm } from "store/investments/loanConversion";
import { reducer as modal } from "store/modal";
import { reducer as modalV2 } from "store/modalV2";
import { reducer as sidebar } from "store/sidebar";
import { reducer as organizations } from "store/organizations";
import { reducer as integrations } from "store/organizations/integrations";
import { reducer as events } from "store/events";
import { reducer as invitation } from "store/invitations/organization";
import { reducer as entitiesv2 } from "store/entities";
import { reducer as views } from "store/views";
import { reducer as viewsV2 } from "store/viewsV2";
import { reducer as resourcesV2 } from "store/resourcesV2";
import { reducer as requests } from "store/requests";
import companyLogoPicker from "reducers/companyLogoPicker";
import config from "reducers/config";
import createAccount from "reducers/createAccount";
import dashboardPortfoliosView from "reducers/dashboardPortfoliosView";
import documentUploads from "reducers/documentUploads/documentUploads";
import documentsView from "reducers/documentsView";
import entities from "reducers/entities";
import { reducer as filter } from "store/filter";
import flash from "reducers/flash";
import newDocumentForm from "reducers/newDocumentForm";
import portfoliosView from "reducers/portfoliosView";
import signUpRequestForm from "reducers/signUpRequestForm";
import resourceAvailable from "store/resources/reducer";

const appReducer = combineReducers({
  resourceAvailable,
  config,
  companyLogoPicker,
  companyView,
  createAccount,
  events,
  dashboardPortfoliosView,
  documentUploads,
  documentsView,
  documents,
  domain,
  entities,
  entitiesv2,
  filter,
  flash,
  modal,
  modalV2,
  newDocumentForm,
  loanConversionForm,
  organizations,
  portfolioProfile,
  portfoliosView,
  search,
  signUpRequestForm,
  integrations,
  invitation,
  views,
  sidebar,
  viewsV2,
  resourcesV2,
  requests,
});

export default appReducer;
