import roundDecimals from "lib/roundDecimals";
import moment from "moment";
import numeral from "numeral";
import { valueDateFormat } from "config/dateInput";

const YEAR_DAYS = 365;

export default function calculateDebtInterest(debt, conversionDate) {
  const {
    date,
    total_acquisition_amount: totalAcquisitionAmount,
    interest_rate: interestRate,
  } = debt;

  const daysDifference = moment(conversionDate).diff(
    moment(date).format(valueDateFormat),
    "days",
  );

  let accruedInterest = 0;
  if (conversionDate && daysDifference > 0) {
    accruedInterest = simpleInterest(
      interestRate,
      daysDifference,
      totalAcquisitionAmount,
    );
  }

  const calculatedConversionAmount = numeral(totalAcquisitionAmount)
    .add(accruedInterest)
    .value();

  const roundedAccruedInterset = roundDecimals(accruedInterest, 2);
  const roundedCalculatedConversionAmount = roundDecimals(
    calculatedConversionAmount,
    2,
  );

  return {
    accruedInterest: roundedAccruedInterset,
    calculatedConversionAmount: roundedCalculatedConversionAmount,
  };
}

function simpleInterest(interestRate, days, amount) {
  return amount * (interestRate / (100 * YEAR_DAYS)) * days;
}
