import React, { useState, useRef, useContext } from "react";
import { ModalHeader, ModalBody, ModalFooter } from "components/shared/Modal";
import SecondaryButton from "components/shared/SecondaryButton";
import PrimaryButton from "components/shared/PrimaryButton";
import DropHandler from "components/shared/DropHandler";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { uploadToFilestack } from "lib/fileStackUtils";
import LogoReceptor from "lib/LogoReceptor";
import AuthenticationContext from "components/Mighty/AuthenticationContext";
import useCurrentUser from "hooks/useCurrentUser";

const MIN_WIDTH = 250; // pixels
const MAX_WIDTH = 600; // pixels
const MODAL_WIDTH = 540; // pixels

const getFilesFromEvent = (event) => {
  return event instanceof DragEvent
    ? event.dataTransfer.files
    : event.target.files;
};

const extractMimeType = (chain) => {
  return chain.split(";base64,")[0].replace("data:", "");
};

const getCroppedImg = (image, crop, fileName) => {
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext("2d");

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  );

  return new Promise((resolve, _reject) => {
    canvas.toBlob((blob) => {
      blob.name = fileName;
      resolve(blob);
    }, "image/png");
  });
};

function AvatarFilePicker({ updateUserAvatar }) {
  const currentUser = useCurrentUser();
  const { setCurrentUser } = useContext(AuthenticationContext);

  const imageRef = useRef(null);
  const [imageSource, setImageSource] = useState(null);
  const [croppedImageSource, setCroppedImageSource] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [necessaryMargin, setNecessaryMargin] = useState(0);
  const [crop, setCrop] = useState({
    unit: "px",
    width: 250,
    aspect: 1,
  });

  const handleSelectFile = (event) => {
    const files = getFilesFromEvent(event);
    if (files.length > 0) {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        const fileMimeType = extractMimeType(reader.result);
        if (LogoReceptor.isValidType(fileMimeType)) {
          setImageSource(reader.result);
        }
      });

      reader.readAsDataURL(files[0]);
    }
  };

  const handleCropChange = (crop) => {
    setCrop(crop);
  };

  const handleImageLoaded = (image) => {
    const { width: imageWidth } = image;
    imageRef.current = image;

    if (imageWidth < MODAL_WIDTH) {
      setNecessaryMargin((MODAL_WIDTH - imageWidth) / 2);
    }
  };

  const handleCropComplete = (crop) => {
    if (imageRef && crop.width && crop.height) {
      getCroppedImg(imageRef.current, crop, `${currentUser.id}-avatar`).then(
        (result) => {
          setCroppedImageSource(result);
        },
      );
    }
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    uploadToFilestack(croppedImageSource, {}, {}, {}).then((uploadedFile) => {
      updateUserAvatar(currentUser.id, {
        avatar_handle: uploadedFile.handle,
      }).then(({ user }) => {
        setCurrentUser(user);
        setIsSubmitting(false);
      });
    });
  };

  return (
    <div className="AvatarFilePicker">
      <ModalHeader title="Upload Profile Photo" />
      <ModalBody>
        {imageSource ? (
          <div style={{ marginLeft: necessaryMargin }}>
            <ReactCrop
              className="AvatarFilePicker-ReactCrop"
              crop={crop}
              minWidth={MIN_WIDTH}
              maxWidth={MAX_WIDTH}
              src={imageSource}
              circularCrop={true}
              keepSelection={true}
              onChange={handleCropChange}
              onImageLoaded={handleImageLoaded}
              onComplete={handleCropComplete}
            />
          </div>
        ) : (
          <DropHandler
            extraClassnames="DocumentsFilePicker"
            onDrop={handleSelectFile}
          >
            <input
              type="file"
              name="filepicker"
              id="filepicker"
              accept={LogoReceptor.getAcceptedMimeTypes()}
              onChange={handleSelectFile}
            />
            <div className="DocumentsFilePicker-upload">
              Drop one document here, or{" "}
              <label htmlFor="filepicker">browse for files</label> to upload
            </div>
          </DropHandler>
        )}
      </ModalBody>
      <ModalFooter
        render={(closeModal) => (
          <div className="ModalFooter-actionButtons">
            <PrimaryButton
              type="submit"
              onClick={handleSubmit}
              disabled={!croppedImageSource}
              filter={{
                applyingFilter: isSubmitting,
              }}
            >
              Set As Profile Photo
            </PrimaryButton>
            <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
          </div>
        )}
      />
    </div>
  );
}

export default AvatarFilePicker;
