import { useDispatch } from "react-redux";
import {
  cleanDocumentUploads as cleanDocumentUploadsHelper,
  updateDocumentInQueue as updateDocumentInQueueHelper,
} from "actions/synchronous/documentUploads";
import { uploadDocuments as uploadDocumentsHelper } from "lib/globalActionsHelper";
import { useQueryClient } from "react-query";

export default () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const uploadDocuments = (filepickerData, target) =>
    dispatch(uploadDocumentsHelper(filepickerData, target)).then(() =>
      queryClient.invalidateQueries(["Company"]),
    );

  const updateDocumentInQueue = (doc, options) =>
    dispatch(updateDocumentInQueueHelper(doc, options));

  const cleanDocumentUploads = () => dispatch(cleanDocumentUploadsHelper);

  return {
    uploadDocuments,
    updateDocumentInQueue,
    cleanDocumentUploads,
  };
};
