import React from "react";
import ContentWithTooltip from "components_new/elements/ContentWithTooltip";
import MightyIcon from "components_new/atoms/MightyIcon";
import Image from "components_new/atoms/Image";
import Value from "components_new/atoms/Value";
import SubValue from "components_new/atoms/SubValue";
import BasicLabel from "components_new/atoms/BasicLabel";
import "./CompanyInfo.scss";
import Icon from "components_new/atoms/Icon";

const CompanyInfo = ({ company, title, content, tier, handleEdit }) => {
  const { logo_url, status } = company;
  const isActive = status === "Active";
  const align = isActive ? "middle" : "top";

  const logoRender = logo_url ? (
    <Image
      src={logo_url}
      size="mini"
      spaced
      verticalAlign={align}
      alt={`Logo for ${company.name}`}
    />
  ) : (
    <div className="NoCompanyLogo">
      <MightyIcon icon="company-sm" />
    </div>
  );

  return (
    <div className="CompanyInfo">
      <div className="CompanyIcon">
        {logoRender}
        {handleEdit && (
          <div
            className="EditButton"
            onClick={(e) => {
              e.preventDefault();
              handleEdit(company.id, company.name);
            }}
          >
            <Icon
              extraClassnames="ToggleEdit"
              icon="ri-pencil-line"
              alt="Edit"
            />
          </div>
        )}
      </div>
      <div className="CompanyName">
        {title(company)}
        {content(company)}
      </div>
      {tier && (
        <BasicLabel upperCase type="generic" children={`tier ${tier}`} />
      )}
    </div>
  );
};

CompanyInfo.defaultProps = {
  title: (childrenProps) => (
    <ContentWithTooltip
      tooltip={{
        content: childrenProps.name,
        position: "top center",
        disabled: false,
      }}
    >
      <Value>{childrenProps.name}</Value>
    </ContentWithTooltip>
  ),
  content: (childrenProps) =>
    childrenProps.status !== "Active" && (
      <SubValue>{childrenProps.status}</SubValue>
    ),
  tier: null,
  handleEdit: null,
};

export default CompanyInfo;
