import React, { Fragment } from "react";
import { placeholderDateFormat } from "config/dateInput";
import PriceInput from "components/shared/form/PriceInput";
import DatePicker from "components_new/atoms/DatePicker";
import HelperContent from "components/shared/HelperContent";
import formatNumber from "lib/formatters/formatNumber";

function LogWriteOffForm({ formData, updateForm, historyAction, errors }) {
  const { date_helper_text: dateHelperContent } = historyAction;
  return (
    <Fragment>
      <DatePicker
        name="date"
        label="Date of write off"
        placeholder={placeholderDateFormat}
        value={formData.date}
        onChange={(value) => updateForm("date", value)}
        helperContent={() => <HelperContent content={dateHelperContent} />}
        showHelper={!!dateHelperContent}
        extraClassnames="FormElement FormElement--Date"
        error={!!errors.for("date")}
        errorMessage={errors.for("date")}
        size="small"
        oldVersion
      />
      <PriceInput
        name="cash_returned"
        label="Cash returned"
        placeholder={
          formData.cash_proceed
            ? formatNumber(formData.cash_proceed, 2)
            : "0.00"
        }
        onChange={(value) => updateForm("cash_proceed", value)}
        value={formData.cash_proceed}
        errorMessage={errors.for("cash_proceed")}
        size="small"
      />
    </Fragment>
  );
}

export default LogWriteOffForm;
