import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./Text.scss";

function Text({
  as,
  children,
  extraClassnames,
  link,
  onClick,
  type,
  color,
  ...restProps
}) {
  const textClassnames = classnames("Atom-Text", {
    [extraClassnames]: !!extraClassnames,
    textLink: !!link,
    textClickable: !!onClick,
    [type]: !!type,
    [`color-${color}`]: !!color,
  });
  return React.createElement(
    as,
    { className: textClassnames, onClick, ...restProps },
    children,
  );
}

Text.propTypes = {
  as: PropTypes.oneOf([
    "div",
    "p",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "span",
    "li",
    "label",
  ]),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  extraClassnames: PropTypes.string,
  link: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.oneOf([
    "title-1",
    "title-2",
    "subtitle-1-bold",
    "subtitle-1-regular",
    "subtitle-2-bold",
    "subtitle-2-regular",
    "body-1-bold",
    "body-1-regular",
    "body-2-bold",
    "body-2-regular",
    "label-bold",
    "label-regular",
    "label-semibold",
  ]),
  color: PropTypes.oneOf([
    "brand-primary",
    "brand-primary-hover",
    "brand-dark",
    "brand-secondary",
    "brand-light",
    "white",
    "black",
    "green",
    "yellow",
    "orange",
    "warning",
    "warning-light",
    "warning-dark",
    "background-error",
    "tint-1",
    "tint-2",
    "tint-3",
    "tint-4",
    "tint-5",
    "tint-6",
    "tint-7",
    "tint-8",
  ]),
};

Text.defaultProps = {
  as: "div",
  link: false,
};

export default Text;
