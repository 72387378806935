import React from "react";
import { Dropdown as SemanticDropdown } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/dropdown.css";
import "./Dropdown.scss";

function DropdownMenu(props) {
  return <SemanticDropdown.Menu {...props} />;
}

export default DropdownMenu;
