import { includes } from "lodash";

const IMAGE_MIME_TYPES = ["image/png", "image/jpeg", "image/jpg", "image/gif"];
const IMAGE_MIME_TYPES_WITHOUT_GIF = ["image/png", "image/jpeg", "image/jpg"];

const LogoReceptor = {
  isValidType(type) {
    return includes(IMAGE_MIME_TYPES, type);
  },
  isValidTypeWithoutGif(type) {
    return includes(IMAGE_MIME_TYPES_WITHOUT_GIF, type);
  },
  getAcceptedMimeTypes() {
    return IMAGE_MIME_TYPES.join(",");
  },
};

export default LogoReceptor;
