import React, { Fragment } from "react";
import Message from "components_new/atoms/Message";
import PropTypes from "prop-types";

function FormEmptyState({ groupContent }) {
  return (
    <Fragment>
      <Message>{groupContent}</Message>
    </Fragment>
  );
}

export default FormEmptyState;

FormEmptyState.propTypes = {
  groupContent: PropTypes.node,
};
