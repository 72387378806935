import PropTypes from "prop-types";
import React, { Component } from "react";
import classNames from "classnames";
import FormElement from "components/shared/form/FormElement";
import verifyValidText from "lib/verifyValidText";
import { uniqueId } from "lodash";

class TextInput extends Component {
  static propTypes = {
    autoComplete: PropTypes.oneOf(["on", "off"]),
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    errorDisplayValidation: PropTypes.func,
    errorMessage: PropTypes.string,
    extraClassnames: PropTypes.string,
    initialValue: PropTypes.string,
    isOptional: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    showErrors: PropTypes.bool,
    submitting: PropTypes.bool,
    typeOfInput: PropTypes.string,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    validator: PropTypes.func,
    readOnly: PropTypes.bool,
    size: PropTypes.oneOf(["tiny", "medium", "small"]),
    alignTo: PropTypes.oneOf(["left", "right"]),
    showLock: PropTypes.bool,
    textHelper: PropTypes.string,
    prefixSymbol: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    inputProps: PropTypes.object,
  };

  static defaultProps = {
    autoComplete: "on",
    autoFocus: false,
    disabled: false,
    errorDisplayValidation: (showErrors, errorMessage) => {
      return showErrors == null ? !!errorMessage : showErrors;
    },
    errorMessage: null,
    extraClassnames: "",
    initialValue: null,
    isOptional: false,
    label: null,
    name: null,
    onChange: null,
    onKeyDown: null,
    placeholder: "",
    required: false,
    showErrors: null,
    submitting: false,
    typeOfInput: "text",
    value: undefined,
    defaultValue: undefined,
    validator: verifyValidText,
    readOnly: false,
    showLock: false,
    textHelper: null,
    size: "medium",
    alignTo: "left",
    prefixSymbol: null,
    inputProps: {},
  };

  state = {
    id: uniqueId(),
  };

  componentDidMount() {
    const { autoFocus } = this.props;

    if (autoFocus) {
      // TODO: find a better way to focus the text input when it is inside a modal
      // without using setTimeout. CSS animation of the modal might have something
      // to do with this issue.
      setTimeout(() => this.focus(), 500);
    }
  }

  onChange(e) {
    const value = e.target.value;
    const name = e.target.name;
    const { required, onChange, validator } = this.props;
    const isValid = required ? validator(value) : true;
    if (onChange) {
      onChange(value, name, isValid);
    }
  }

  onKeyDown(event) {
    if (this.props.onKeyDown) {
      this.props.onKeyDown(event);
    }
  }

  setCursorAtEnd() {
    this.input.selectionStart = this.input.selectionEnd = this.input.value.length;
  }

  focus() {
    if (this.input) {
      this.input.focus();
      this.setCursorAtEnd();
    }
  }

  render() {
    const {
      autoComplete,
      disabled,
      errorDisplayValidation,
      errorMessage,
      extraClassnames,
      isOptional,
      textHelper,
      label,
      name,
      placeholder,
      required,
      showErrors,
      typeOfInput,
      readOnly,
      showLock,
      value,
      defaultValue,
      alignTo,
      size,
      prefixSymbol,
      inputProps,
    } = this.props;
    const formElementClassnames = classNames("FormElement--textInput", {
      alignToRight: alignTo === "right",
      [extraClassnames]: !!extraClassnames,
      [size]: !!size,
    });

    return (
      <FormElement
        extraClassnames={formElementClassnames}
        label={label}
        isOptional={isOptional}
        errorMessage={errorMessage}
        name={name}
        readOnly={readOnly}
        showLock={showLock}
        textHelper={textHelper}
        errorDisplayValidation={() => {
          return errorDisplayValidation(errorMessage, showErrors);
        }}
        idOverride={this.state.id}
      >
        <div className="InputHolder">
          <input
            id={this.state.id}
            autoComplete={autoComplete}
            className="InputText"
            disabled={disabled}
            label={label}
            name={name}
            onKeyDown={(event) => this.onKeyDown(event)}
            onChange={(event) => this.onChange(event)}
            placeholder={placeholder}
            required={required}
            type={typeOfInput}
            value={value}
            defaultValue={defaultValue}
            readOnly={readOnly}
            ref={(input) => {
              this.input = input;
            }}
            {...inputProps}
          />
          {prefixSymbol && (
            <p
              className="PrefixSymbol"
              ref={(symbol) => {
                this.symbol = symbol;
              }}
            >
              {prefixSymbol}
            </p>
          )}
        </div>
      </FormElement>
    );
  }
}

export default TextInput;
