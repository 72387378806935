import Api from "lib/Api";
import { omit } from "lodash";
import { getCurrentOrganization } from "store/organizations/selectors";
import { upsertResource } from "store/resourcesV2/actions";

export function inviteOrganizationMember(payload) {
  return (dispatch, getState) => {
    return Api.post("/organization_memberships", {
      organization_membership: payload,
    }).then(({ data: newMembership }) => {
      const currentOrganization = getCurrentOrganization(getState);
      const updatedOrganization = {
        ...currentOrganization,
        organization_memberships: [
          ...currentOrganization.organization_memberships,
          newMembership,
        ],
      };

      dispatch(upsertResource("organizations", updatedOrganization));
      return newMembership;
    });
  };
}

export function revokeOrganizationMembership(organizationMembershipId) {
  return (dispatch, getState) => {
    return Api.patch(
      `/organization_memberships/${organizationMembershipId}/revoke`,
    ).then(({ data: revokedMembership }) => {
      const updatedOrganization = removeMembershipFromOrganization(
        getState,
        revokedMembership,
      );
      dispatch(upsertResource("organizations", updatedOrganization));
      return revokedMembership;
    });
  };
}

export function removeOrganizationMembership(organizationMembership) {
  return (dispatch, getState) => {
    return Api.delete(
      `/organization_memberships/${organizationMembership.id}`,
    ).then(() => {
      const updatedOrganization = removeMembershipFromOrganization(
        getState,
        organizationMembership,
      );
      dispatch(upsertResource("organizations", updatedOrganization));
      return organizationMembership;
    });
  };
}

function removeMembershipFromOrganization(getState, revokedMembership) {
  const currentOrganization = getCurrentOrganization(getState);
  return {
    ...currentOrganization,
    organization_memberships: omit(
      currentOrganization.organization_memberships,
      revokedMembership.id,
    ),
  };
}

export function fetchCurrentUserOrganizationMemberships() {
  return () => {
    return Api.getCollection(
      "userOrganizationMemberships",
      "/current_user/organization_memberships",
      { withoutOrganization: true },
    );
  };
}

export function removeUserOrganizationMembership(organizationMembership) {
  return () => {
    return Api.delete(
      `/${organizationMembership.organization_username}/organization_memberships/${organizationMembership.id}`,
      { withoutOrganization: true },
    );
  };
}

export function revokeUserOrganizationMembership(organizationMembershipId) {
  return () => {
    return Api.patchMutation(
      "removeOrganizationMembership",
      `/organization_memberships/${organizationMembershipId}/revoke`,
      {},
      () => ({
        destructions: [
          { from: "userOrganizationMemberships", id: organizationMembershipId },
        ],
      }),
    );
  };
}
