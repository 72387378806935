export const distributionTypes = [
  { value: "profit_share", label: "Profit Share" },
  { value: "dividend", label: "Dividend" },
  { value: "interest", label: "Interest" },
  { value: "return_of_capital", label: "Return of Capital" },
  { value: "in_kind_distribution", label: "In-Kind Distribution" },
  { value: "other", label: "Other" },
];

export default {
  distributionTypes,
};
