import React from "react";
import Header from "components_new/atoms/Header";
import classNames from "classnames";

function ModalHeader({ content, subTitle, extraClassnames, ...otherProps }) {
  const modalHeaderClassnames = classNames("Modal-header", extraClassnames);
  return (
    <Header
      className={modalHeaderClassnames}
      content={content}
      subheader={subTitle}
      {...otherProps}
    />
  );
}

export default ModalHeader;
