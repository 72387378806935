import React from "react";
import FormGroup from "components_new/elements/FormGroup";
import DropZone from "components_new/elements/DropZone";

function Documents({
  title = "Files",
  required,
  isExpanded,
  subtitle,
  onChange,
  onQueueChange,
  documents,
  defaultDocumentType,
  prefilledFilesStatus,
}) {
  const handleChange = (documents) => {
    if (onChange) {
      onChange(documents);
    }
  };

  return (
    <FormGroup
      extraClassnames="DocumentsGroup"
      title={title}
      required={required}
      subtitle={subtitle}
      isExpanded={isExpanded}
    >
      <DropZone
        prefilledFiles={documents}
        prefilledFilesStatus={prefilledFilesStatus}
        size="tiny"
        onChange={(documents) => {
          handleChange(documents);
        }}
        onQueueChange={onQueueChange}
        defaultDocumentType={defaultDocumentType}
      />
    </FormGroup>
  );
}

export default Documents;
