import React from "react";
import Label from "components/shared/Label";
import FileNameLink from "components/shared/FileNameLink";
import FileIcon from "components_new/atoms/FileIcon";
import iconType from "components_new/lib/iconType";

function DTFileNamePreview({ file, currentOrganizationUsername }) {
  const fileUrl = `/${currentOrganizationUsername}/documents/${file.id}/download`;
  return (
    <div className="DTFileNamePreview">
      <div className="DTFileNamePreview-fileIcon">
        <a
          className="DTFileNamePreview-url"
          href={`/${currentOrganizationUsername}/documents/${file.id}/download`}
          rel="nofollow noopener"
          target="_blank"
        >
          <FileIcon type={iconType(file.mime_type)} />
        </a>
      </div>
      <div className="DTFileNamePreview-fileName">
        <FileNameLink
          file={file}
          fileUrl={fileUrl}
          maxCharsLengthFileName={60}
          extraClassnames="DTFileNamePreview-url"
        />
      </div>
      {file.closing_document && (
        <div className="DTFileNamePreview-closing">
          <Label message="Closing" type="bright" />
        </div>
      )}
    </div>
  );
}

export default DTFileNamePreview;
