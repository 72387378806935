import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Form, Grid } from "@ableco/semantic-ui-react";
import Input from "components_new/atoms/Input";
import PrimaryButton from "components/shared/PrimaryButton";
import SecondaryButton from "components/shared/SecondaryButton";
import Tags from "components/shared/Tags";
import CompanyTitleContainer from "components/shared/CompanyTitleContainer";
import useForm from "lib/hooks/useForm";
import {
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "components_new/elements/Modal";
import { tagSuggestionsProptype } from "PropTypes";
import baseReferralLogic from "lib/hooks/baseReferralLogic.js";
import verifyEmail from "lib/verifyEmail";
import { getTextFromElement } from "components_new/elements/MultiSelect/config";
import CompanySelector from "components_new/elements/CompanySelector/CompanySelector";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import getMessage from "components_new/lib/Messages";
import { isEmpty } from "lodash";

const getUniqueIdFromTime = () => new Date().getTime().toString(8);

function ReferrerModal({
  initialCompany,
  referrer,
  closeModal,
  updateContact,
  onSubmitForm,
  isReferrerEditing,
  validateEmail,
  filterCompanyTitle,
  companyTitleSuggestions,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const invalidErrorMessage = "Please enter a valid email address";
  const { addToast } = useSetToast();
  const handleAddNewElement = (element) => {
    const {
      first_name,
      last_name,
      email,
      location,
      company_id,
      tags,
      company_titles,
    } = element;
    const newElement = {
      key: `${getUniqueIdFromTime()}`,
      caption: getTextFromElement(element),
      value: {
        first_name: first_name,
        last_name: last_name,
        email,
        location,
        company_id,
        tags,
        company_titles,
        isNewRecord: true,
      },
    };
    onSubmitForm(newElement);
  };

  let initialValues = {
    formData: baseReferralLogic(referrer),
  };
  const submit = (clientData) => {
    const { email: emailInput } = clientData;
    if (!isEmpty(emailInput) && !verifyEmail(emailInput)) {
      setEmailError(invalidErrorMessage);
    } else {
      const payload = emailInput === referrer.email ? "a@b.c" : emailInput;
      setIsSubmitting(true);
      validateEmail(payload)
        .then(() => {
          if (isReferrerEditing) {
            // In the next line we are cleanning the `clienData` in order to match the `updateContact` from `actions`, it should be awesome if we refactor this modal, but I think this is going to be replaced by the new sidebar
            const cleanFormData = {
              ...clientData,
              company: {
                id: clientData.company_id,
                isNewCompany: false, // In line 212 we are disabling the CompanyCreation
              },
            };
            updateContact(referrer.id, cleanFormData)
              .catch((error) => handleErrors(error))
              .then(() => {
                addToast(getMessage("updateReferrer"));
                setIsSubmitting(false);
              });
          } else {
            handleAddNewElement(clientData);
            closeModal();
          }
        })
        .catch((error) => {
          setIsSubmitting(false);
          if (error) {
            setEmailError(error.data.error);
          }
        });
    }
  };
  const {
    formData,
    handleChange,
    handleSubmit,
    handleErrors,
    errors,
  } = useForm(initialValues, submit);

  const handleEmailChange = (value) => {
    if (emailError) {
      handleChange("email", value);
      setEmailError(null);
    } else {
      handleChange("email", value);
    }
  };

  const handleCompanyChange = (value) => {
    if (isEmpty(value)) {
      handleChange("company_id", null);
      handleChange("company_titles", []);
    } else {
      handleChange("company_id", value.id);
    }
  };

  const { Row, Column } = Grid;
  const addModalHeaderContent = isReferrerEditing
    ? "Edit Referrer"
    : "Create Referrer";
  const isSubmitButtonDisabled =
    isEmpty(formData.first_name) && isEmpty(formData.last_name);
  return (
    <Fragment>
      <ModalHeader content={addModalHeaderContent} />
      <ModalContent>
        <Form>
          <Grid>
            <Row columns={2}>
              <Column>
                <Input
                  name="first_name"
                  label="First name"
                  placeholder="Add first name"
                  value={formData.first_name}
                  onChange={(_, { value }) => handleChange("first_name", value)}
                />
              </Column>
              <Column>
                <Input
                  label="Last name"
                  placeholder="Add last name"
                  value={formData.last_name}
                  onChange={(_, { value }) => handleChange("last_name", value)}
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <Input
                  name="email"
                  label="Email address (optional)"
                  placeholder="e.g. name@domain.com"
                  value={formData.email}
                  errorMessage={errors.for("email") || emailError}
                  onChange={(_, { value }) => handleEmailChange(value)}
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <Input
                  name="location"
                  label="Location (optional)"
                  value={location}
                  value={formData.location}
                  onChange={(_, { value }) => handleChange("location", value)}
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <Tags
                  tagType="contact"
                  label="Tags"
                  placeholder="Add tags"
                  name="tags"
                  taggedList={formData.tags || []}
                  onChange={(value) => handleChange("tags", value)}
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <CompanySelector
                  name="company"
                  label="Company (optional)"
                  placeHolder="Search a company"
                  onChange={(_, value) => handleCompanyChange(value)}
                  errorMessage={errors.for("company_id")}
                  prefilledValue={initialCompany}
                  disabledCompanyCreation
                  size="large"
                />
              </Column>
            </Row>
            {formData.company_id && (
              <Row>
                <Column>
                  <CompanyTitleContainer
                    label="Title at Company (optional)"
                    createTagLabel="Create new title"
                    placeholder="Add title(s)"
                    name="company_titles"
                    taggedList={formData.company_titles || []}
                    onChange={(value) => handleChange("company_titles", value)}
                    onFilter={filterCompanyTitle}
                    suggestedList={companyTitleSuggestions}
                  />
                </Column>
              </Row>
            )}
          </Grid>
        </Form>
      </ModalContent>
      <ModalFooter withBorder={false}>
        <PrimaryButton
          type="submit"
          onClick={handleSubmit}
          disabled={isSubmitButtonDisabled}
          filter={{
            applyingFilter: isSubmitting,
          }}
        >
          Save Changes
        </PrimaryButton>
        <SecondaryButton onClick={() => closeModal()}>Cancel</SecondaryButton>
      </ModalFooter>
    </Fragment>
  );
}

ReferrerModal.defaultProps = {
  referrer: {
    id: null,
    first_name: null,
    last_name: null,
    email: null,
    location: null,
    company_id: null,
    tags: [],
    company_titles: [],
  },
};

ReferrerModal.propTypes = {
  referrer: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    location: PropTypes.string,
    company_id: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.shape({})),
    company_titles: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  isSubmitting: PropTypes.bool,
  closeModal: PropTypes.func,
  updateContact: PropTypes.func,
  isEditing: PropTypes.bool,
  tagSuggestions: tagSuggestionsProptype,
  filterTag: PropTypes.func.isRequired,
  onSubmitForm: PropTypes.func,
  isReferrerEditing: PropTypes.bool,
  validateEmail: PropTypes.func,
  ensureFormData: PropTypes.func,
  makeNewElement: PropTypes.func,
  initialCompany: PropTypes.shape({}),
  filterCompanyTitle: PropTypes.func,
  companyTitleSuggestions: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ReferrerModal;
