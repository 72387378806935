import React from "react";
import CheckBox from "components/shared/form/CheckBox";

function DTFileClosingEdit({ errorMessage, file, formIndex, updateForm }) {
  return (
    <div className="DTFileClosingEdit">
      <CheckBox
        label="Closing"
        value={file.closing_document}
        errorMessage={errorMessage}
        onChange={(name, value) => {
          updateForm("closing_document", value, formIndex, file.id);
        }}
      />
    </div>
  );
}

export default DTFileClosingEdit;
