import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { TableCell } from "components_new/elements/Table";
import ContentWithTooltip from "components_new/elements/ContentWithTooltip";
import CompanyInfo from "components_new/elements/CompanyInfo";
import formatDate from "lib/formatDate";
import Value from "components_new/atoms/Value";
import SubValue from "components_new/atoms/SubValue";
import Tooltip from "components_new/atoms/Tooltip/Tooltip";
import GainLossArrow from "components_new/elements/GainLossArrow";
import NoClosingDocIndicator from "components_new/elements/NoClosingDocIndicator";
import { ToggleInvestmentSidebar } from "components_new/SidebarComponents/views/InvestmentView/ToggleInvestmentSidebar";
import { isEmpty } from "lodash";

function ExitsRowCells({
  investment,
  isChild,
  isParent,
  orgUsername,
  showClosingDocuments,
}) {
  return (
    <Fragment key={investment.id}>
      {isChild ? (
        <Fragment>
          <TableCell className="EmptyCell" />
          <TableCell className="EmptyCell" />
        </Fragment>
      ) : (
        <TableCell className="CompanyNameColumn">
          {!isEmpty(investment.company) && (
            <CompanyInfo
              company={investment.company}
              tier={investment.company.tier}
              title={(company) => {
                return (
                  <ContentWithTooltip
                    tooltip={{
                      content: company.name,
                      position: "top center",
                      disabled: false,
                    }}
                  >
                    <Link to={`/${orgUsername}/companies/${company.id}`}>
                      {company.name}
                    </Link>
                  </ContentWithTooltip>
                );
              }}
            />
          )}
        </TableCell>
      )}
      <TableCell className="InvestmentNameColumn">
        <div className="InvestmentNameColumn-content">
          {isParent ? (
            investment.name
          ) : (
            <Fragment>
              <span className="InvestmentNameColumn-text">
                <ContentWithTooltip
                  tooltip={{
                    content: investment.name,
                    position: "top center",
                    disabled: false,
                  }}
                >
                  <ToggleInvestmentSidebar investmentId={investment.id}>
                    {investment.name}
                  </ToggleInvestmentSidebar>
                </ContentWithTooltip>
                <SubValue>{investment.type_label}</SubValue>
              </span>
              {showClosingDocuments && !investment.has_closing_documents && (
                <NoClosingDocIndicator />
              )}
            </Fragment>
          )}
        </div>
      </TableCell>
      <TableCell className="DateColumn">
        {formatDate(investment.date)}
        <SubValue extraClassName="u-capitalize">
          {investment.acquired_type}
        </SubValue>
      </TableCell>

      <TableCell className="NumericColumn">
        <Value type="quantity">{investment.quantity}</Value>
        {investment.fully_diluted_shares_percentage && (
          <Tooltip
            position="top center"
            trigger={
              <span>
                <SubValue>
                  <Value type="percentage">
                    {investment.fully_diluted_shares_percentage}
                  </Value>{" "}
                  FD
                </SubValue>
              </span>
            }
            content="% Fully diluted"
          />
        )}
      </TableCell>

      <TableCell className="NumericColumn">
        <Value type="currency" precision="2">
          {investment.cost_basis}
        </Value>
        {investment.unit_valuation_amount && (
          <SubValue>
            <Value type="currency" precision="2">
              {investment.unit_valuation_amount}
            </Value>{" "}
            / share
          </SubValue>
        )}
      </TableCell>
      <TableCell className="NumericColumn">
        <Value type="currency" precision="2">
          {investment.total_valuation_amount}
        </Value>
        {investment.unit_cost_basis && (
          <SubValue>
            <Value type="currency" precision="2">
              {investment.unit_cost_basis}
            </Value>{" "}
            / {investment.unit}
          </SubValue>
        )}
      </TableCell>
      <TableCell className="NumericColumn Adjustments">
        <Value type="currency" precision="2">
          {investment.adjustments}
        </Value>
      </TableCell>
      <TableCell className="NumericColumn GainLossColumn">
        <Value type="currency" precision="2">
          {investment.realized_gain}
        </Value>
        <GainLossArrow gain={investment.realized_gain} />
      </TableCell>
    </Fragment>
  );
}

export default ExitsRowCells;
