import React from "react";
import ContentWithTooltip from "components_new/elements/ContentWithTooltip";

function ShowFunds({ companyFunds }) {
  return (
    <ContentWithTooltip
      maxWidth="calc(100% - 40px)"
      tooltip={{
        content: companyFunds.split(",").join("\n"),
        position: "top center",
        disabled: false,
      }}
      breakLine
    >
      {companyFunds}
    </ContentWithTooltip>
  );
}

export default ShowFunds;
