import { SET_RESOURCE_AVAILABILITY, RESET_RESOURCE } from "./actionTypes";

const initialState = {
  available: true,
};

function resourceAvailable(state = initialState, action) {
  switch (action.type) {
    case SET_RESOURCE_AVAILABILITY:
      return {
        available: action.isAvailable,
      };
    case RESET_RESOURCE:
      return initialState;
    default:
      return state;
  }
}

export default resourceAvailable;
