import React from "react";
import { Dimmer as SemanticDimmer } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/dimmer.css";
import classNames from "classnames";
import PropTypes from "prop-types";

function Dimmer({ as, children, extraClassnames, className, ...otherProps }) {
  const dimmerClassnames = classNames("Dimmer", {
    [className]: !!className,
    [extraClassnames]: !![extraClassnames],
  });

  return (
    <SemanticDimmer as={as} className={dimmerClassnames} {...otherProps}>
      {children}
    </SemanticDimmer>
  );
}

Dimmer.propTypes = {
  as: PropTypes.oneOf([PropTypes.string, PropTypes.func]),
  children: PropTypes.node,
  className: PropTypes.string,
  extraClassnames: PropTypes.string,
};

export default Dimmer;
