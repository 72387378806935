import React from "react";
import classNames from "classnames";
import { Segment as SemanticSegment } from "@ableco/semantic-ui-react";
import PropTypes from "prop-types";
import "./Segment.scss";

function Segment(props) {
  const { border, shadow, extraClassNames, transparent, ...inputProps } = props;
  const segmentClassnames = classNames("Segment", {
    "with-border": !!border,
    "with-shadow": !!shadow,
    "is-transparent": !!transparent,
    [extraClassNames]: !!extraClassNames,
  });
  return <SemanticSegment className={segmentClassnames} {...inputProps} />;
}

Segment.propTypes = {
  border: PropTypes.bool,
  shadow: PropTypes.bool,
  transparent: PropTypes.bool,
  extraClassNames: PropTypes.string,
};

Segment.defaultProps = {
  border: false,
  shadow: false,
  transparent: false,
};
export default Segment;
