import React, { useCallback } from "react";
import DataCard from "components_new/elements/DataCard";
import TextTitle from "components_new/atoms/TextTitle";
import TextLabel from "components_new/atoms/TextLabel";
import TagList from "components_new/atoms/TagList";
import ContactActionsDropdown from "components_new/views/Company/shared/ContactActionsDropdown";
import formatContactName from "lib/formatters/formatContactName";
import { isEmpty } from "lodash";

function mapTags(object) {
  return Object.values(object).map((item) => {
    return { name: item.name };
  });
}

function ContactRow({ contact, openContactDetailsView }) {
  const companyTitles = contact.company_titles.join(", ");

  /*  eslint-disable unicorn/no-nested-ternary */
  const contactCompanyLabel = isEmpty(companyTitles)
    ? contact.companyName
    : contact.companyName
    ? contact.companyName.concat(", ", companyTitles)
    : companyTitles;
  /*  eslint-enable unicorn/no-nested-ternary */

  const onCardClick = useCallback(() => openContactDetailsView(contact), [
    contact,
    openContactDetailsView,
  ]);

  return (
    <DataCard
      onClick={onCardClick}
      actionMenuComponent={
        <ContactActionsDropdown contact={contact} dropdownType="ellipsis" />
      }
    >
      <DataCard.Avatar icon="contact-med" />
      <DataCard.Header>
        <TextLabel>{contactCompanyLabel}</TextLabel>
        <TextTitle>{formatContactName(contact)}</TextTitle>
        {contact.email ? contact.email : "N/A"} &nbsp;·&nbsp; {contact.location}
      </DataCard.Header>
      <DataCard.Content>
        <TagList tags={mapTags(contact.tags)} />
      </DataCard.Content>
    </DataCard>
  );
}

export default ContactRow;
