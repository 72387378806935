import React from "react";
import copy from "copy-to-clipboard";
import getMessage from "components_new/lib/Messages";
import GroupedVerticalButtonMenu from "components_new/elements/GroupedVerticalButtonMenu";
import useSnapshotPDF from "components_new/views/Snapshots/hooks/useSnapshotPDF";
import Text from "components_new/atoms/Text";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";

export default function Sidebar({ snapshot }) {
  const { isFetching, generatePDF } = useSnapshotPDF(snapshot);
  const { addToast } = useSetToast();

  const snapshotAddress = window.location.href;
  const showCopyConfirmationMessage = () => {
    addToast(getMessage("copiedToClipboard"));
  };

  return (
    <div className="SnapshotSidebar">
      <Text type="label-bold" color="brand-light">
        SHARING OPTIONS
      </Text>
      <div className="SnapshotSidebar-Actions">
        <GroupedVerticalButtonMenu
          items={[
            {
              title: "Generate PDF",
              disabled: isFetching,
              isLoading: isFetching,
              icon: "ri-file-pdf-line",
              onClick: generatePDF,
            },
            {
              title: "Copy link",
              onClick: () =>
                copy(snapshotAddress, {
                  format: "text/plain",
                  onCopy: showCopyConfirmationMessage,
                }),
              icon: "ri-links-line",
            },
          ]}
        />
      </div>
    </div>
  );
}
