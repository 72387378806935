import React, { useCallback, useMemo, useState } from "react";
import Checkbox from "components/shared/form/CheckBox";
import PrimaryButton from "components/shared/PrimaryButton";
import { isEqual } from "lodash";

function NotificationsView({ updateNotifications, notificationsSettings }) {
  const [formData, setFormData] = useState(notificationsSettings);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const hasFormChanged = useMemo(() => {
    return !isEqual(notificationsSettings, formData);
  }, [notificationsSettings, formData]);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setIsSubmitting(true);
      await updateNotifications(formData);
      setIsSubmitting(false);
    },
    [updateNotifications, formData],
  );

  const updateForm = useCallback(
    (key, value) => {
      setFormData({ ...formData, [key]: value });
    },
    [formData],
  );

  return (
    <form
      className="NotificationsView AccountInformationForm"
      onSubmit={handleSubmit}
    >
      <h2 className="FormSection-title u-sectionHeading">Notifications</h2>
      <div className="FormSection-Body">
        <p className="u-metaContentLight FormElement">
          Please indicate which email you want to receive.
        </p>
        <Checkbox
          label="Weekly summaries (sent on Friday)"
          name="weekly_reports"
          value={formData.weekly_reports}
          onChange={updateForm}
        />
      </div>
      <div className="FormSection-Footer">
        <PrimaryButton
          type="submit"
          disabled={!hasFormChanged}
          filter={{
            applyingFilter: isSubmitting,
          }}
        >
          Save Changes
        </PrimaryButton>
      </div>
    </form>
  );
}

export default NotificationsView;
