import { openInModal, closeModal, updateModalOptions } from "./actions";
import modalComponents from "./modalComponents";

const ModalNames = Object.keys(modalComponents).reduce((names, modalName) => {
  return { ...names, [modalName]: modalName };
}, {});

export { default as reducer } from "./modalReducer";
export {
  openInModal,
  closeModal,
  ModalNames,
  modalComponents,
  updateModalOptions,
};
