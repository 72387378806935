import {
  OVERRIDE_COLLECTION,
  UPDATE_COLLECTION,
  UPSERT_ENTITY,
  REMOVE_ENTITY,
  UPDATE_SINGLETON,
} from "./domainActionTypes";
import { COLLECTION_NAMES, SINGLETON_NAMES } from "./config";
import { keyBy, property, uniq, omit, without } from "lodash";

// NOTE: Shape example (given an entity called companies and a singleton named session):
// {
//   companies: {
//     byId: {
//       hhf7smab: { name: "Company Name", ... },
//       asmdsd: { name: "Company Name", ... }
//     },
//     ids: ["hhf7smab", "asmdsd", ...]
//   },
//   session: { authId, authToken }
// }
const initialState = generateInitialEntityStates(
  COLLECTION_NAMES,
  SINGLETON_NAMES,
);

function generateInitialEntityStates(collectionNames, singletonNames) {
  const collectionStates = collectionNames.reduce(
    (entityStates, collectionName) => {
      return {
        ...entityStates,
        [collectionName]: { ids: [], byId: {} },
      };
    },
    {},
  );

  const singletonStates = singletonNames.reduce(
    (states, singletonName) => ({ ...states, [singletonName]: null }),
    {},
  );

  return { ...collectionStates, ...singletonStates };
}

export default function entitiesReducer(state = initialState, action) {
  // NOTE: Is the an issue between resourcesV2 <-> entitiesV2
  //       so we're commenting this verifyng to avoid warnings
  // if (
  //   action.collectionName &&
  //   !COLLECTION_NAMES.includes(action.collectionName)
  // ) {
  //   throw `To modify entities, action.collectionName should be one of: ${COLLECTION_NAMES}. Received ${action.collectionName}.`;
  // }

  switch (action.type) {
    case OVERRIDE_COLLECTION:
      return {
        ...state,
        [action.collectionName]: {
          byId: keyBy(action.entities, property("id")),
          ids: action.entities.map(property("id")),
        },
      };
    case UPDATE_COLLECTION:
      return {
        ...state,
        [action.collectionName]: {
          byId: {
            ...state[action.collectionName].byId,
            ...keyBy(action.entities, property("id")),
          },
          ids: uniq([
            ...state[action.collectionName].ids,
            ...action.entities.map(property("id")),
          ]),
        },
      };
    case UPSERT_ENTITY:
      return {
        ...state,
        [action.collectionName]: {
          byId: {
            ...state[action.collectionName].byId,
            [action.entity.id]: {
              ...(state[action.collectionName].byId[action.entity.id] || {}),
              ...action.entity,
            },
          },
          ids: uniq([
            ...state[action.collectionName].ids,
            ...[action.entity.id],
          ]),
        },
      };
    case REMOVE_ENTITY:
      return {
        ...state,
        [action.collectionName]: {
          byId: omit(state[action.collectionName].byId, action.id),
          ids: without(state[action.collectionName].ids, action.id),
        },
      };
    case UPDATE_SINGLETON:
      return {
        ...state,
        [action.singletonName]: action.singleton,
      };
    default:
      return state;
  }
}
