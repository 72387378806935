import React from "react";
import Label from "components/shared/Label";

function PreviousHolding() {
  return (
    <div className="InvestmentDetails-previousHolding">
      <Label message="Previous Investment" type="bright" />
    </div>
  );
}

export default PreviousHolding;
