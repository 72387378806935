import React from "react";
import FormGroup from "components_new/elements/FormGroup";
import CompanySelector from "components_new/elements/CompanySelector/CompanySelector";
import "./CompanyDetails.scss";

function CompanyDetails({
  handleChange,
  company,
  submitTo,
  mountSidebarComponent,
  hideSidebarComponent,
  errors,
  comesFromDeal,
}) {
  const onCreateNewCompany = (value) => {
    handleChange("company", value);
    mountSidebarComponent(
      "addCompanyForm",
      "Add New Company",
      {
        data: { ...value },
      },
      submitTo,
    );
    hideSidebarComponent(submitTo);
  };

  return (
    <FormGroup
      extraClassnames="CompanyDetailsGroup"
      title="Company Details"
      subtitle="Information about the company being invested in."
      required
      isExpanded
    >
      <CompanySelector
        name="company_id"
        label="Company*"
        placeHolder="Search"
        onChange={(_, value) => handleChange("company", value)}
        prefilledValue={company}
        onCreateNewCompany={onCreateNewCompany}
        errorMessage={errors.for("company_id")}
        disabled={comesFromDeal}
        selectedCompany={company}
        isOnSidebar
        size="tiny"
      />
    </FormGroup>
  );
}

export default CompanyDetails;
