import React from "react";
import Icon from "components_new/atoms/Icon";
import "./CompanyFlags.scss";

function CompanyFlags({ company }) {
  const { interesting, double_down: doubleDown, stealth } = company;

  return (
    <div className="CompanyFlags">
      {interesting && <Icon icon="ri-fire-fill" size="tiny" color="orange" />}
      {doubleDown && <Icon icon="ri-rocket-fill" size="tiny" color="green" />}
      {stealth && <Icon icon="ri-eye-off-fill" size="tiny" color="purple" />}
    </div>
  );
}

export default CompanyFlags;
