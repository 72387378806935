import PropTypes from "prop-types";
import React, { Component } from "react";
import { isEqual, uniqueId } from "lodash";
import classNames from "classnames";
import FormElement from "components/shared/form/FormElement";

class RadioButtonGroup extends Component {
  state = {
    value: this.props.value,
  };

  static propTypes = {
    errorMessage: PropTypes.string,
    errorDisplayValidation: PropTypes.func,
    extraClassnames: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    inputProps: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    onChange: PropTypes.func,
    value: PropTypes.string,
    size: PropTypes.string,
    isOptional: PropTypes.bool,
    autoFocus: PropTypes.bool,
    isDisabled: PropTypes.bool,
    listStyle: PropTypes.oneOf(["wide", "inline"]),
    defaultValue: PropTypes.string,
    textHelper: PropTypes.string,
  };

  static defaultProps = {
    errorMessage: null,
    extraClassnames: null,
    name: null,
    inputProps: null,
    onChange: null,
    value: null,
    isOptional: false,
    isDisabled: false,
    size: "medium",
    listStyle: "inline",
    textHelper: null,
  };

  componentDidMount() {
    const id = uniqueId("FormElement");
    const { defaultValue } = this.props;
    this.setState({ id });
    if (this.props.value === null && defaultValue !== this.props.value) {
      this.updateRadioButton(defaultValue);
    }
  }

  UNSAFE_componentWillReceiveProps({ value }) {
    if (value && value !== this.state.value) {
      this.setState({ value: value });
    }
  }

  onChange = (event) => {
    const value = event.target.value;
    this.updateRadioButton(value);
  };

  updateRadioButton = (value) => {
    this.setState({ value });
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  onClick = (event) => {
    const { value } = event.target;
    if (value != null && value === this.state.value) {
      this.updateRadioButton(null);
    }
  };

  renderRadioGroupItem(item, index) {
    const { name, isDisabled } = this.props;
    const { value } = this.state;
    return (
      <label className="RadioGroupItem" key={index}>
        <input
          type="radio"
          name={name}
          className="RadioButton"
          defaultValue={item.value}
          onChange={this.onChange}
          onClick={this.onClick}
          checked={isEqual(value, item.value)}
          id={`RadioGroupItem-${item.value}`}
          disabled={isDisabled}
        />
        <span className="RadioGroupItem-label">{item.label}</span>
      </label>
    );
  }

  render() {
    const {
      label,
      name,
      errorMessage,
      extraClassnames,
      errorDisplayValidation,
      isOptional,
      inputProps,
      isDisabled,
      listStyle,
      textHelper,
      size,
    } = this.props;
    const { id } = this.state;
    const groupClassnames = classNames("FormElement--radioButtonGroup", {
      [extraClassnames]: !!extraClassnames,
      isDisabled: isDisabled,
      [size]: !!size,
      [listStyle]: !!listStyle,
    });

    return (
      <FormElement
        {...(errorDisplayValidation && { errorDisplayValidation })}
        errorMessage={errorMessage}
        extraClassnames={groupClassnames}
        isOptional={isOptional}
        label={label}
        textHelper={textHelper}
        name={name}
      >
        <div className="RadioGroupHolder" htmlFor={id}>
          {inputProps.map((item, index) =>
            this.renderRadioGroupItem(item, index),
          )}
        </div>
      </FormElement>
    );
  }
}

export default RadioButtonGroup;
