import React, { useEffect, useLayoutEffect, useState } from "react";
import FormFooter from "components_new/SidebarComponents/SidebarForm/FormFooter";
import HoldingMiniCard from "components/shared/HoldingMiniCard";
import useForm from "lib/hooks/useForm";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import getMessage from "components_new/lib/Messages";
import Acquisition from "./Acquisition";
import Documents from "components_new/SidebarComponents/forms/groups/Documents";
import Notes from "components_new/SidebarComponents/forms/groups/Notes";
import RequiredNote from "components_new/SidebarComponents/shared/RequiredNote";
import PropTypes from "prop-types";
import {
  investmentPropType,
  companyAvatarPropType,
  tagSuggestionsProptype,
} from "PropTypes";
import { isEmpty } from "lodash";

const INITIAL_VALUES = {
  formData: {
    date: "",
    is_cash_proceed: false,
    is_equity_proceed: false,
    cash_amount: null,
    series_name: "",
    custom_name: null,
    quantity: "",
    cost: "",
    cost_type: "",
    notes: "",
    equity_class: "",
    valuation: "",
    documents_data: [],
    certificate_number: "",
    is_update_company_status_checked: false,
    type: "",
  },
};

function LogAcquisitionForm({
  ownName,
  investment,
  company,
  closeSidebar,
  createLogAcquisition,
  filterTags,
  tagSuggestions,
  componentData,
  mountSidebarComponent,
  hideSidebarComponent,
  currentOrganizationUserName,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [showFields, setShowFields] = useState(false);
  const [proceedsType, setProceedsType] = useState("");
  const { addToast } = useSetToast();

  const onSubmit = (clientData) => {
    setIsLoading(true);
    createLogAcquisition(clientData, investment.id)
      .then((acquisition) => {
        closeSidebar();
        if (acquisition.is_equity_proceed) {
          addToast(
            getMessage("addedAcquisition", {
              url: `/${currentOrganizationUserName}/investments/${acquisition.equity_id}`,
            }),
          );
        } else {
          addToast(getMessage("addedAcquisition"));
        }
      })
      .catch((error) => {
        handleErrors(error);
        handleScrollView(error);
      })
      .finally(() => setIsLoading(false));
  };

  const {
    formData,
    handleChange,
    handleBatchChange,
    handleSubmit,
    handleErrors,
    handleScrollView,
    errors,
  } = useForm(INITIAL_VALUES, onSubmit);

  useEffect(() => {
    if (!isEmpty(componentData)) {
      const { company, ...restData } = componentData;
      handleBatchChange({ acquiring_company: company, ...restData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentData]);

  const { documents_data, notes } = formData;

  useLayoutEffect(() => {
    if (!isEmpty(proceedsType)) {
      setShowFields(true);
    }
  }, [proceedsType]);

  return (
    <div className="LogAcquisitionForm">
      <div className="HoldingGroup">
        <HoldingMiniCard
          investment={investment}
          company={company}
          className="FormElement"
        />
      </div>

      <Acquisition
        formData={formData}
        componentName={ownName}
        errors={errors}
        handleChange={handleChange}
        showAdditionalFields={showFields}
        proceedsType={proceedsType}
        setProceedsType={setProceedsType}
        filterTags={filterTags}
        tagSuggestions={tagSuggestions}
        mountSidebarComponent={mountSidebarComponent}
        hideSidebarComponent={hideSidebarComponent}
      />
      {showFields && (
        <div className="initially-hidden-fields">
          <Documents
            onChange={(documents) => {
              handleChange("documents_data", [...documents_data, ...documents]);
            }}
            documents={documents_data}
            isExpanded
          />
          <Notes
            name="notes"
            notes={notes}
            onChange={(notes) => {
              handleChange("notes", notes);
            }}
            isExpanded
          />
        </div>
      )}
      <RequiredNote />
      <FormFooter
        componentName={ownName}
        data={formData}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        disableSubmit={isLoading}
      />
    </div>
  );
}
LogAcquisitionForm.propTypes = {
  ownName: PropTypes.string.isRequired,
  investment: PropTypes.objectOf(investmentPropType).isRequired,
  company: PropTypes.objectOf(companyAvatarPropType).isRequired,
  createLogAcquisition: PropTypes.func.isRequired,
  closeSidebar: PropTypes.func.isRequired,
  filterTags: PropTypes.func,
  tagSuggestions: tagSuggestionsProptype,
  componentData: PropTypes.shape({ company: PropTypes.shape({}) }),
  mountSidebarComponent: PropTypes.func,
  hideSidebarComponent: PropTypes.func,
  currentOrganizationUserName: PropTypes.string,
};
export default LogAcquisitionForm;
