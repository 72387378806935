import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import FolderIcon from "-!svg-react-loader?!assets/icons/utility/folder-icon.svg?name=FolderIcon";
import EmptyLabel from "components_new/atoms/EmptyLabel";
import Icon from "components_new/atoms/Icon";
import "./FileCategory.scss";

function FileCategory({
  title,
  border,
  children,
  showFolderIcon,
  childFileCategory,
  allRowsExpanded,
  allRowsCollapsed,
}) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (allRowsCollapsed) {
      setIsOpen(false);
    }
  }, [allRowsCollapsed]);

  useEffect(() => {
    if (allRowsExpanded) {
      setIsOpen(true);
    }
  }, [allRowsExpanded]);

  const toggleFileCategory = () => {
    setIsOpen(!isOpen);
  };

  const fileCategoryClassnames = classNames("FileCategoryUI", {
    [`border-${border}`]: border,
    "FileCategory-childFileCategory": childFileCategory,
    "is-open": isOpen,
  });

  return (
    <div className={fileCategoryClassnames}>
      <Icon icon="ri-arrow-down-s-line" onClick={toggleFileCategory} />
      <div className="FileCategory-wrapper">
        <div className="FileCategory-header" onClick={toggleFileCategory}>
          {/* We are still using the old folder icon since we don't have a folder icon in the new MightyIcons yet. */}
          {showFolderIcon && <FolderIcon className="FileCategory-FolderIcon" />}
          <div className="FileCategory-title">{title}</div>
          <div className="FileCategory-defaultValue typeValue">
            <EmptyLabel />
          </div>
          <div className="FileCategory-defaultValue">
            <EmptyLabel />
          </div>
        </div>
        {isOpen && <div className="FileCategory-content"> {children} </div>}
      </div>
    </div>
  );
}

FileCategory.defaultProps = {
  border: "bottom",
  showFolderIcon: true,
  childFileCategory: false,
};

FileCategory.propTypes = {
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  children: PropTypes.node,
  border: PropTypes.string,
  showFolderIcon: PropTypes.bool,
  childFileCategory: PropTypes.bool,
  allRowsCollapsed: PropTypes.bool,
  allRowsExpanded: PropTypes.bool,
};

export default FileCategory;
