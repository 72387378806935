import { getText } from "TermsMapper";
import accounting from "accounting";
import { perUnitNumberPrecision } from "config/priceInput";
import { isNumber } from "lodash";

function calculatePricePerUnit({
  price_per_unit,
  quantity,
  per_quantity_or_total_option,
}) {
  const parsedPrice = parseFloat(price_per_unit);
  const parsedQuantity = parseFloat(quantity);
  if (!isNumber(parsedPrice) || !isNumber(quantity)) {
    return;
  }
  if (per_quantity_or_total_option === "total") {
    return parsedPrice / parsedQuantity;
  }
  return price_per_unit;
}

function calculateSoldPercent(
  { quantity: soldQuantity },
  { quantity: ownedQuantity },
) {
  const parsedSoldQuantity = parseFloat(soldQuantity);
  const parsedOwnedQuantity = parseFloat(ownedQuantity);

  if (!soldQuantity) {
    return;
  }
  return (parsedSoldQuantity / parsedOwnedQuantity) * 100;
}

const getInvestmentSaleMessage = (formData, investment) => {
  if (!investment.type) {
    return null;
  }
  const { quantity, per_quantity_or_total_option, price_per_unit } = formData;
  const pricePerUnit = calculatePricePerUnit(formData);
  const soldPercent = calculateSoldPercent(formData, investment);
  const total = pricePerUnit * quantity;

  const saleMessage =
    !!per_quantity_or_total_option && per_quantity_or_total_option == "total"
      ? `a total sale price of $ ${accounting.formatMoney(total, "", 2)}`
      : `$ ${accounting.formatMoney(
          price_per_unit,
          "",
          perUnitNumberPrecision,
        )} / ${getText(investment.type, "valuation_unit")}`;
  return `You sold ${accounting.formatMoney(
    soldPercent,
    "",
    2,
  )}% of your investment for ${saleMessage}`;
};
export { getInvestmentSaleMessage };
