import React from "react";

import { Placeholder as SemanticSkeleton } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/placeholder.css";
import "./Skeleton.scss";

function SkeletonImage({ ...props }) {
  return <SemanticSkeleton.Image className="SkeletonImage" {...props} />;
}

export default SkeletonImage;
