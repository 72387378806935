import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import ButtonGroup from "components_new/elements/ButtonGroup";
import Button from "components_new/atoms/Button";
import Icon from "components_new/atoms/Icon";
import "./ViewStyleSelector.scss";

export const VIEW_STYLES = {
  LIST: "LIST",
  GRID: "GRID",
};

function ViewStyleSelector({ setViewStyle, viewStyle }) {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const buttonClassNames = ["ui small secondary", { animate: shouldAnimate }];
  const listButtonClassnames = classnames(buttonClassNames, {
    selected: viewStyle === VIEW_STYLES.LIST,
  });
  const gridButtonClassnames = classnames(buttonClassNames, {
    selected: viewStyle === VIEW_STYLES.GRID,
  });
  const handleSetViewStyle = (style) => {
    setViewStyle(style);
    setShouldAnimate(true);
  };
  return (
    <ButtonGroup size="small" extraClassnames="ViewStyleSelector">
      <Button
        className={listButtonClassnames}
        onClick={() => handleSetViewStyle(VIEW_STYLES.LIST)}
        aria-label="View as List"
        size="small"
        toggle
        secondary
        icon
      >
        <Icon icon="ri-list-check" size="tiny" />
      </Button>
      <Button
        className={gridButtonClassnames}
        onClick={() => handleSetViewStyle(VIEW_STYLES.GRID)}
        aria-label="View as Grid"
        size="small"
        toggle
        secondary
        icon
      >
        <Icon icon="ri-layout-grid-line" size="tiny" />
      </Button>
    </ButtonGroup>
  );
}

export default ViewStyleSelector;

ViewStyleSelector.propTypes = {
  setViewStyle: PropTypes.func,
  viewStyle: PropTypes.string,
};
