import React, { useState, useCallback } from "react";
import Header, { SubHeader } from "components_new/atoms/Header";
import Form from "components_new/atoms/Form";
import FormFooter from "components_new/SidebarComponents/SidebarForm/FormFooter";
import CompanySelector from "components_new/elements/CompanySelector/CompanySelector";
import DataList from "components_new/elements/DataList";
import useForm from "lib/hooks/useForm";
import { roundNameOptions } from "components_new/elements/DataList/config";
import { isEmpty } from "lodash";
import "./CoInvestmentForm.scss";

function CoInvestmentForm({
  isEditing,
  ownName,
  resourceName,
  prefilledCompany,
  prefilledInvestor,
  coInvestment,
  submitAction,
  mountSidebarComponent,
  hideSidebarComponent,
  disableCompanySelector,
  disableInvestorSelector,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    formData: {
      round_name: "",
      company: prefilledCompany || {},
      investor: prefilledInvestor || {},
      ...coInvestment,
    },
  };

  const { formData, handleChange, errors, touched } = useForm(
    initialValues,
    submitAction,
  );

  const handleSubmit = async () => {
    setIsLoading(true);
    await submitAction(formData);
    setIsLoading(false);
  };

  const onCreateNewCompany = useCallback((key, value) => {
    handleChange(key, value);
    mountSidebarComponent(
      "addCompanyForm",
      "Add New Company Form",
      {
        data: { ...value },
      },
      ownName,
    );
    hideSidebarComponent(ownName);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shouldBeDisabled = isEmpty(formData.investor);
  const formTitle = isEditing ? `Edit ${resourceName}` : `Add ${resourceName}`;

  return (
    <Form onSubmit={handleSubmit} className="CoInvestmentForm">
      <div className="FormGroup CoInvestmentForm-Body">
        <Header as="h4">{formTitle}</Header>
        <SubHeader>Information about the {resourceName}</SubHeader>
        <CompanySelector
          name="company_id"
          label="Company*"
          placeHolder="Search company"
          onChange={(_, value) => handleChange("company", value)}
          onCreateNewCompany={(value) => onCreateNewCompany("company", value)}
          errorMessage={errors.for("company_id")}
          isOnSidebar
          size="tiny"
          prefilledValue={formData.company}
          disabled={disableCompanySelector}
        />
        <CompanySelector
          name="investor_id"
          label="Investor*"
          placeHolder="Search company"
          onChange={(_, value) => handleChange("investor", value)}
          onCreateNewCompany={(value) => onCreateNewCompany("investor", value)}
          errorMessage={errors.for("investor_id")}
          isOnSidebar
          size="tiny"
          prefilledValue={formData.investor}
          disabled={disableInvestorSelector}
        />
        <DataList
          name="round_name"
          label="Round"
          placeholder="Round"
          size="tiny"
          options={roundNameOptions}
          defaultValue={coInvestment.round_name}
          value={formData.round_name}
          onChange={(value) => handleChange("round_name", value)}
        />
      </div>
      <FormFooter
        componentName={ownName}
        isLoading={isLoading}
        disableSubmit={isLoading || shouldBeDisabled}
        touched={touched}
        submitTextButton={isEditing ? "Save changes" : `Add ${resourceName}`}
      />
    </Form>
  );
}

export default CoInvestmentForm;
