import React, { useState } from "react";
import SearchInput from "./SearchInput";
import useDebounce from "lib/hooks/useDebounce";

export default function DebouncedSearchInput({
  onChange,
  className,
  ...props
}) {
  const [searchValue, setSearchValue] = useState("");

  useDebounce(searchValue, 300, onChange);

  return (
    <SearchInput
      extraClassnames={className}
      value={searchValue}
      size="small"
      onChange={(value) => setSearchValue(value)}
      {...props}
    />
  );
}
