import React, { useState } from "react";
import Input from "components_new/atoms/Input";
import CheckBox from "components_new/atoms/Checkbox";
import Dropdown from "components_new/atoms/Dropdown";
import FileIcon from "components_new/atoms/FileIcon";
import Button from "components_new/atoms/Button";
import iconType from "components_new/lib/iconType";
import PropTypes from "prop-types";
import classNames from "classnames";
import useForm from "lib/hooks/useForm";
import { documentPropType } from "PropTypes";
import { isEqual } from "lodash";
import { fileOptions } from "components_new/elements/DropZone/config";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import getMessage from "components_new/lib/Messages";

function FileRowEdit({ file, editAction, resetFileActions, size = "regular" }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { addToast } = useSetToast();
  const { id: fileId } = file;

  const initialValues = {
    formData: {
      ...file,
    },
  };

  const submit = (clientData) => {
    setIsSubmitting(true);

    editAction(clientData)
      .then(() => {
        addToast(getMessage("updateFiles"));
        resetFileActions(fileId);
      })
      .catch((error) => {
        handleErrors(error);
      })
      .finally(() => setIsSubmitting(false));
  };

  const {
    formData,
    handleChange,
    handleSubmit,
    handleErrors,
    errors,
  } = useForm(initialValues, submit);

  const filenameHasErrors = errors.for("file_name") !== null;
  const fileRowEditClassnames = classNames("FileRowEdit", {
    "has-errors": filenameHasErrors,
    [size]: !!size,
  });

  const fileHasChanged = !isEqual(formData, file);
  return (
    <div className={fileRowEditClassnames}>
      <div className="FileRowEdit-filename">
        <FileIcon type={iconType(file.mime_type)} />
        <Input
          name="filename"
          value={formData.file_name}
          size="small"
          errorMessage={errors.for("file_name")}
          onChange={({ target }) => handleChange("file_name", target.value)}
        />
      </div>
      <CheckBox
        label="Closing"
        checked={formData.closing_document}
        errorMessage={errors.for("closing_document")}
        className="FileRowEdit-closing"
        onChange={(_, { checked }) => handleChange("closing_document", checked)}
      />
      <Dropdown
        selection
        upward={false}
        placeholder="Document type"
        size="small"
        options={fileOptions}
        value={formData.document_type}
        className="FileRowEdit-documentType"
        onChange={(_, target) => {
          const value = target ? target.value : null;
          handleChange("document_type", value);
        }}
      />
      <div className="FileRowEdit-actions">
        <Button secondary size="small" onClick={() => resetFileActions(fileId)}>
          Cancel
        </Button>
        <Button
          primary
          size="small"
          onClick={handleSubmit}
          disabled={!fileHasChanged}
          loading={isSubmitting}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

FileRowEdit.propTypes = {
  file: documentPropType.isRequired,
  editAction: PropTypes.func.isRequired,
  resetFileActions: PropTypes.func.isRequired,
  size: PropTypes.oneOf(["small", "regular"]),
};

export default FileRowEdit;
