import React, { Component } from "react";
import { investmentPacePropType } from "PropTypes";
import { camelCase, toUpper, isEqual } from "lodash";
import { formatInvestmentPaceTooltip } from "./formatInvestmentPace";
import parseDateToUTC from "lib/parseDateToUTC";
import numeral from "numeral";
import ReactHighcharts from "react-highcharts";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import {
  initialPointDifferenceAmount,
  initialPointDifferenceTypePeriod,
  initialInvestmentsSeries,
  followOnSeries,
  globalChartConfig,
  labelsYAxis,
  plotOptionsChart,
  tooltipChart,
  yAxisChart,
  xAxisChart,
} from "./investmentPaceChartConfig";
import parseDataToChartPoints from "lib/charts/parseDataToChartPoints";
import addFakeTodaysPoint from "lib/charts/addFakeTodaysPoint";
import addFakeInitialPoint from "lib/charts/addFakeInitialPoint";
import tickPositioner from "lib/charts/tickPositioner";
import getTodayUTC from "lib/charts/getTodayUTC";

const today_utc = getTodayUTC();

function dataMapper(item, { amountKey, shouldShowPointKey, type }) {
  const {
    date,
    [amountKey]: amount,
    [shouldShowPointKey]: shouldShowPoint,
    ...rest
  } = item;
  return {
    x: parseDateToUTC(date),
    y: parseFloat(amount),
    data: {
      date,
      type,
      [amountKey]: amount,
      should_show_tooltip: shouldShowPoint,
      ...rest,
    },
    marker: {
      enabled: shouldShowPoint,
      states: {
        hover: {
          enabled: shouldShowPoint,
        },
      },
    },
  };
}

function getFollowOnData(investmentPace) {
  if (investmentPace.length === 0) {
    return [];
  }

  const chartPoints = parseDataToChartPoints(investmentPace, dataMapper, {
    amountKey: "follow_on_investments_amount",
    shouldShowPointKey: "has_follow_on_investments",
    type: "follow_on",
  });
  const hasTodayPoint = chartPoints.some(({ x }) => x === today_utc);

  if (chartPoints.length >= 1 && !hasTodayPoint) {
    return addFakeTodaysPoint(chartPoints);
  }

  if (chartPoints.length === 1 && chartPoints[0].x === today_utc) {
    return addFakeInitialPoint(
      chartPoints,
      initialPointDifferenceAmount,
      initialPointDifferenceTypePeriod,
    );
  }

  return chartPoints;
}

function getInitialInvestmentData(investmentPace) {
  if (investmentPace.length === 0) {
    return [];
  }

  const chartPoints = parseDataToChartPoints(investmentPace, dataMapper, {
    amountKey: "initial_investments_amount",
    shouldShowPointKey: "has_initial_investments",
    type: "initial_investment",
  });
  const hasTodayPoint = chartPoints.some(({ x }) => x === today_utc);

  if (chartPoints.length >= 1 && !hasTodayPoint) {
    return addFakeTodaysPoint(chartPoints);
  }

  if (chartPoints.length === 1 && chartPoints[0].x === today_utc) {
    return addFakeInitialPoint(
      chartPoints,
      initialPointDifferenceAmount,
      initialPointDifferenceTypePeriod,
    );
  }

  return chartPoints;
}

class InvestmentPaceChart extends Component {
  componentDidMount() {
    NoDataToDisplay(ReactHighcharts.Highcharts);
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.investmentPace, nextProps.investmentPace);
  }

  formatCharYAxisLabels() {
    return toUpper(numeral(this.value).format("$0[.]00a"));
  }

  renderHTMLChartTooltip() {
    const {
      data: {
        date,
        should_show_tooltip: shouldShowTooltip,
        fake_point: fakePoint,
      },
      series: { name: investmentType },
    } = this.point;
    const formattedTooltip = formatInvestmentPaceTooltip();

    if (!shouldShowTooltip || fakePoint) {
      return null;
    }

    return `
      <div class="InvestmentPaceChartTooltip InvestmentPaceChartTooltip--${camelCase(
        investmentType,
      )}">
        <p class="InvestmentPaceChartTooltipInvestmentDate">
          ${formattedTooltip.shortDate(date)}
        </p>
        <p class="InvestmentPaceChartTooltip-header">
          ${formattedTooltip.getHeadline(this.point.data)}
        </p>
        <p class="u-investmentPaceDetailTooltip">${formattedTooltip.getClosingCopy(
          this.point.data,
        )}</p>
      </div>
    `;
  }

  renderChart() {
    const { investmentPace } = this.props;
    const followOnData = getFollowOnData(investmentPace);
    const initialInvestmentsData = getInitialInvestmentData(investmentPace);
    const defaultConfig = {
      ...globalChartConfig,
      tooltip: {
        ...tooltipChart,
        formatter: this.renderHTMLChartTooltip,
      },
      plotOptions: plotOptionsChart,
      series: [
        {
          ...followOnSeries,
          data: followOnData,
        },
        {
          ...initialInvestmentsSeries,
          data: initialInvestmentsData,
        },
      ],
      xAxis: {
        tickPositioner,
        ...xAxisChart,
      },
      yAxis: {
        ...yAxisChart,
        labels: {
          ...labelsYAxis,
          formatter: this.formatCharYAxisLabels,
        },
      },
    };

    return <ReactHighcharts config={defaultConfig} />;
  }

  render() {
    return <div className="InvestmentPaceChart">{this.renderChart()}</div>;
  }
}

InvestmentPaceChart.propTypes = {
  investmentPace: investmentPacePropType.isRequired,
};

export default InvestmentPaceChart;
