import React, { useCallback, useContext, useEffect } from "react";
import useOrganization from "store/organizations/hooks";
import GlobalCompanyForm from "./GlobalCompanyForm";
import Loader from "components/shared/Loader";
import getMessage from "components_new/lib/Messages";
import useResourcesV2 from "store/resourcesV2/hooks";
import mapCollectionToOptions from "components_new/lib/mapCollectionToOptions";

import { SidebarComponentsContext } from "components_new/SidebarComponents/SidebarComponentsWrapper";
import { useUpdateCompany } from "components_new/views/Company/hooks/mutations/useUpdateCompany";
import { useCreateCompany } from "components_new/views/Company/hooks/mutations/useCreateCompany";
import { useCompany } from "components_new/views/Company/hooks/queries/useCompany";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import { isEmpty } from "lodash";

function GlobalCompanyFormWrapper({ ownName }) {
  const {
    getComponentState,
    registerForm,
    closeSidebar,
    onUnmount,
  } = useContext(SidebarComponentsContext);

  const { addToast } = useSetToast();
  const { submitTo, callback, entityId: companyId } = getComponentState(
    ownName,
  );

  const {
    isAdmin,
    isCurrentUserOrganizationOwner,
    currentOrganization,
    currentOrganizationName,
    currentOrganizationUsername,
  } = useOrganization();

  const { data, isLoading } = useCompany(companyId);
  const { mutateAsync: updateCompany } = useUpdateCompany();
  const { mutateAsync: createCompany } = useCreateCompany();

  const { getOrganizationResourceAsCollection } = useResourcesV2();
  const organizationtCustomFields = getOrganizationResourceAsCollection(
    "customFields",
  );

  const isEditing = !isEmpty(companyId);
  const isVerifiedCompany = data?.companyDetails.verified;
  const showWarningMessage = !isVerifiedCompany && isEditing;
  const primarySectors = mapCollectionToOptions({
    collection: currentOrganization.primary_sectors,
    attrName: "name",
  });

  useEffect(() => {
    if (!isEmpty(ownName)) {
      registerForm(ownName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseSidebar = useCallback(() => {
    if (!isEmpty(submitTo)) {
      onUnmount(ownName, submitTo);
    } else {
      closeSidebar();
    }

    callback?.();
  }, [callback, closeSidebar, onUnmount, ownName, submitTo]);

  const handleShowNotification = useCallback(
    (company) => {
      if (isEditing) {
        addToast(getMessage("updatedCompany"));
      } else {
        addToast(
          getMessage("createdCompany", {
            url: `/${currentOrganizationUsername}/companies/${company.id}`,
          }),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEditing, currentOrganizationUsername],
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isEditing && !data) {
    return null;
  }

  return (
    <GlobalCompanyForm
      isAdmin={isAdmin}
      ownName={ownName}
      company={data?.companyDetails || {}}
      currentOrganizationUsername={currentOrganizationUsername}
      submitAction={isEditing ? updateCompany : createCompany}
      submitTextButton={isEditing ? "Save Changes" : "Save Company"}
      showNotification={handleShowNotification}
      onCloseSidebar={onCloseSidebar}
      acquirerCompany={data?.acquirerCompany}
      relatedCompanies={data?.companyAvatars}
      isVerifiedCompany={isVerifiedCompany}
      showMessage={showWarningMessage}
      isCurrentUserOrganizationOwner={isCurrentUserOrganizationOwner}
      currentOrganizationName={currentOrganizationName}
      organizationCustomFields={organizationtCustomFields}
      primarySectors={primarySectors}
      prepopulatedCompanyTags={currentOrganization.prepopulated_company_tags}
      prepopulatedSectors={currentOrganization.prepopulated_sectors}
    />
  );
}

export default GlobalCompanyFormWrapper;
