import {
  RECEIVE_VIEW,
  UPDATE_VIEW,
  REMOVE_FROM_VIEW,
  UPDATE_VIEW_STATE,
  RESET_VIEW_STATE,
} from "./actions.js";
import { isEmpty } from "lodash";

const initialViewState = {};
const investmentsInitialViewState = {
  allRowsCollapsed: true,
  allRowsExpanded: false,
};

const initialState = {
  company: initialViewState,
  portfolio: initialViewState,
  companyDirectory: initialViewState,
  investment: initialViewState,
  companies: initialViewState,
  deal: initialViewState,
  pipeline: initialViewState,
  cashFlow: initialViewState,
  investments: investmentsInitialViewState,
  queue: initialViewState,
  deals: initialViewState,
  org_portfolios: initialViewState,
  contacts: initialViewState,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_VIEW:
      const initialViewState = initialState[action.name] || {};

      return {
        ...state,
        [action.name]: { ...initialViewState, ...action.data },
      };
    case UPDATE_VIEW: {
      const { name, nameField, id } = action;

      if (isEmpty(state[name][nameField])) {
        return {
          ...state,
          [name]: {
            ...state[name],
            [nameField]: [id],
          },
        };
      }

      const isAnExistingID = state[name][nameField].includes(id);

      if (!isAnExistingID) {
        const updatedCurrentView = {
          ...state[name],
          [nameField]: state[name][nameField].concat(id),
        };
        return {
          ...state,
          [name]: updatedCurrentView,
        };
      }
      return {
        ...state,
      };
    }
    case REMOVE_FROM_VIEW: {
      const { name, nameField, id } = action;
      const viewEntry = state[name][nameField];
      const isAnExistingID = viewEntry && viewEntry.includes(id);

      if (isAnExistingID) {
        const updatedCurrentView = {
          ...state[name],
          [nameField]: state[name][nameField].filter(
            (currentId) => currentId !== id,
          ),
        };
        return {
          ...state,
          [name]: updatedCurrentView,
        };
      }

      return {
        ...state,
      };
    }
    case UPDATE_VIEW_STATE: {
      const { name, data } = action;

      return {
        ...state,
        [name]: {
          ...state[name],
          ...data,
        },
      };
    }
    case RESET_VIEW_STATE: {
      const { name } = action;

      return {
        ...state,

        [name]: {
          ...initialState[name],
        },
      };
    }
    default:
      return state;
  }
}
