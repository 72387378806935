import React, { useState, useEffect, useMemo } from "react";
import CompanySelector from "components_new/elements/CompanySelector/CompanySelector";
import TextArea from "components_new/atoms/TextArea";
import Input from "components_new/atoms/Input";
import Tags from "components/shared/Tags";
import PreFilledInput from "components_new/atoms/PreFilledInput";
import Avatar from "components_new/elements/Avatar";
import SuggestedGroup from "components_new/elements/SuggestedGroup";
import { WEBSITE_PREFILL } from "components_new/lib/miscellaneous";
import classnames from "classnames";
import "./CompanyInformation.scss";

function CompanyInformation({
  onChange,
  company,
  isEditing,
  errors,
  openSidebar,
  closeSidebar,
  size,
  prepopulatedCompanyTags,
}) {
  const [selectedCompany, setSelectCompany] = useState(company);

  useEffect(() => {
    onChange("company", selectedCompany);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany]);

  const companyInformationClassnames = classnames("CompanyInformation", {
    "is-wide": size === "wide",
  });

  const handleChange = (name, value) => {
    setSelectCompany((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const flagsGroup = useMemo(() => {
    return [
      {
        content: "Interesting",
        value: "interesting",
        isSelected: selectedCompany.interesting,
        disabled: selectedCompany.id,
      },
      {
        content: "Double down",
        value: "double_down",
        isSelected: selectedCompany.double_down,
        disabled: selectedCompany.id,
      },
      {
        content: "Stealth",
        value: "stealth",
        isSelected: selectedCompany.stealth,
        disabled: selectedCompany.id,
      },
    ];
  }, [selectedCompany]);

  const onAddNewCompany = (value) => {
    setSelectCompany(value);
    closeSidebar("addCompanyForm");
  };

  const onCreateNewCompany = (value) => {
    openSidebar("addCompanyForm", "Add New Company Form", {
      componentData: value,
      onChange: onAddNewCompany,
    });
  };

  return (
    <div className={companyInformationClassnames}>
      <Avatar
        avatarIcon="company-med"
        imageUrl={selectedCompany.logo_url || selectedCompany.url}
        size="mini"
      />
      <div className="CompanyInformation-content">
        <CompanySelector
          name="company_id"
          label="Company*"
          placeHolder="Search"
          onChange={(_, value) => setSelectCompany(value)}
          disabled={isEditing}
          prefilledValue={selectedCompany}
          selectedCompany={selectedCompany}
          errorMessage={errors.for("company_id")}
          onCreateNewCompany={(value) => onCreateNewCompany(value)}
          allowInlineCreation={size === "wide"}
          isOnSidebar
          size="tiny"
        />
        <TextArea
          extraClassnames="field"
          name="description"
          label="Description"
          placeholder="Add description"
          onChange={(_, { value }) => {
            handleChange("description", value);
          }}
          disabled={selectedCompany.id}
          defaultValue={selectedCompany.description}
          size="tiny"
        />
        <SuggestedGroup
          label="Flags"
          onChange={handleChange}
          group={flagsGroup}
        />
        <PreFilledInput
          name="website"
          label="URL"
          value={selectedCompany.website}
          preFilled={WEBSITE_PREFILL}
          onChange={(value) => handleChange("website", value)}
          disabled={selectedCompany.id}
          size="tiny"
        />
        <Input
          name="location"
          label="Location"
          extraClassnames="field"
          placeholder="e.g. San Francisco, CA"
          value={selectedCompany.headquarters}
          onChange={(e, { value }) => {
            handleChange("headquarters", value);
          }}
          disabled={selectedCompany.id}
          size="tiny"
        />
        <Tags
          tagType="company"
          label="Tags"
          name="tags"
          extraClassnames="field"
          taggedList={selectedCompany.tags}
          onChange={(value) => handleChange("tags", value)}
          errorMessage={errors.for("tags")}
          disabled={selectedCompany.id}
          size="tiny"
          prepopulatedItems={prepopulatedCompanyTags}
        />
        <Input
          name="founders"
          label="Founders"
          extraClassnames="field"
          onChange={(_, { value }) => handleChange("founders", value)}
          value={selectedCompany.founders}
          error={!!errors.for("founders")}
          errorMessage={errors.for("founders")}
          disabled={selectedCompany.id}
          size="tiny"
        />
      </div>
    </div>
  );
}

export default CompanyInformation;
