import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import preventerHandler from "lib/preventerHandler";
import { userPropType, organizationProptype } from "PropTypes";
import { integrationList } from "config/integrationListConfig";
import Button from "components_new/atoms/Button";
import Icon from "components_new/atoms/Icon";
import Dropdown from "components_new/atoms/Dropdown";
import useForm from "lib/hooks/useForm";

function OrganizationIntegrations({
  organization,
  updateOrganizationIntegration,
  revokeSlackIntegration,
  user,
  slackUrl,
  slackAuthScope,
  channels,
  isSubmitting,
  isOwner,
}) {
  return (
    <form className="Container FormSection IntegrationsForm">
      <h2 className="FormSection-title u-sectionHeading">Integrations</h2>
      <IntegrationHeader
        organization={organization}
        user={user}
        revokeSlackIntegration={revokeSlackIntegration}
        isOwner={isOwner}
        slackUrl={slackUrl}
        slackAuthScope={slackAuthScope}
      />
      {organization.integrated ? (
        <IntegrationForm
          isSubmitting={isSubmitting}
          organization={organization}
          channels={channels}
          updateOrganizationIntegration={updateOrganizationIntegration}
          isOwner={isOwner}
        />
      ) : null}
    </form>
  );
}

function IntegrationHeader({
  organization,
  revokeSlackIntegration,
  isOwner,
  slackUrl,
  user,
  slackAuthScope,
}) {
  function authorizeSlackIntegration() {
    window.location = `https://slack.com/oauth/authorize?client_id=${slackUrl}&scope=${slackAuthScope}&state=${user.id}-${organization.id}`;
  }
  const { logoUrl, integrationDescription, name } = integrationList.slack;
  const integrationButton = organization.integrated ? (
    <Button
      secondary
      disabled={!isOwner}
      onClick={preventerHandler(() => revokeSlackIntegration())}
    >
      Revoke Access
    </Button>
  ) : (
    <Button
      iconPosition="left"
      primary
      size="large"
      disabled={!isOwner}
      onClick={preventerHandler(() => authorizeSlackIntegration())}
    >
      <Icon icon="ri-add-line" />
      Add Integration
    </Button>
  );
  return (
    <div className="IntegrationHeader">
      <img
        className="IntegrationLogo"
        src={logoUrl}
        alt={name}
        height="50"
        width="50"
      />
      <div className="IntegrationHeader-info">
        <h4 className="u-moduleHeading IntegrationTitle">{name}</h4>
        <p className="u-integrationDescription IntegrationDescription">
          {integrationDescription}
        </p>
      </div>
      <div className="IntegrationButton">{integrationButton}</div>
    </div>
  );
}

function IntegrationForm({
  isSubmitting,
  organization,
  channels,
  updateOrganizationIntegration,
  isOwner,
}) {
  const filterChannels = channels.map(({ id, name }) => ({
    key: id,
    text: name,
    value: name,
  }));
  const inputRef = useRef(null);

  const [organizationSlackChannelId, setOrganizationSlackChannelId] = useState(
    organization.slack_channel_id,
  );
  const [
    organizationSlackChannelName,
    setorganizationSlackChannelName,
  ] = useState(organization.slack_channel_name);

  const [isIntegrationSelected, setIntegrationSelected] = useState(false);
  const [selectedChannelValue, setSelectedChannelValue] = useState(
    organizationSlackChannelName,
  );
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  useEffect(() => {
    if (organizationSlackChannelId !== organization.slack_channel_id) {
      setOrganizationSlackChannelId(organization.slack_channel_id);
      setorganizationSlackChannelName(organization.slack_channel_name);
    }
  }, [organization, organizationSlackChannelId]);

  const initialValues = {
    formData: {
      slack_channel_id: organizationSlackChannelId,
      slack_channel_name: organizationSlackChannelName,
    },
  };
  const submit = (clientData) => {
    updateOrganizationIntegration(clientData).then(() => {
      setIntegrationSelected(false);
    });
  };
  const { handleChange, handleSubmit } = useForm(initialValues, submit);

  const handleCancel = () => {
    handleChange("slack_channel_id", organizationSlackChannelId);
    handleChange("slack_channel_name", organizationSlackChannelName);
    setIntegrationSelected(false);
  };
  const handleSelectItem = (_, option) => {
    handleChange("slack_channel_id", option.key);
    handleChange("slack_channel_name", option.text);
    setIntegrationSelected(true);
    setSelectedChannelValue(option.text);
  };
  const handleClickOutside = (event) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      selectedChannelValue
    ) {
      setSelectedChannelValue(selectedChannelValue);
    }
  };

  return (
    <div className="IntegrationBody">
      <div className="ChannelSection">
        <div className="OrganizationName">{organization.name}</div>
        <div ref={inputRef} className="ChannelsSelect">
          <Dropdown
            selection
            fluid
            onChange={handleSelectItem}
            options={filterChannels}
            value={selectedChannelValue}
            scrolling
            placeholder="Select channel in Slack"
            disabled={!isOwner}
          />
        </div>
      </div>
      <div className="Submit">
        <Button
          primary
          className="AcceptButton"
          loading={isSubmitting}
          disabled={!isIntegrationSelected}
          onClick={handleSubmit}
        >
          Save Settings
        </Button>
        <Button
          secondary
          className="CancelButton"
          disabled={isSubmitting || !isIntegrationSelected}
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}

OrganizationIntegrations.propTypes = {
  organization: organizationProptype,
  updateOrganizationIntegration: PropTypes.func,
  revokeSlackIntegration: PropTypes.func,
  fetchSlackChannels: PropTypes.func,
  user: userPropType,
  slackUrl: PropTypes.string,
  slackAuthScope: PropTypes.string,
  channels: PropTypes.arrayOf(PropTypes.shape({})),
  isSubmitting: PropTypes.bool,
  isOwner: PropTypes.bool,
};

OrganizationIntegrations.defaultProps = {
  isSubmitting: false,
};

export default OrganizationIntegrations;
