import { OPEN, SHOW, HIDE, UPDATE, CLEAR, CLOSE } from "./actionTypes";
import { useSelector, useDispatch } from "react-redux";
import useModal from "store/modalV2/hooks";

export default () => {
  const sidebar = useSelector((state) => state.sidebar);
  const dispatch = useDispatch();
  const { openModal } = useModal();

  const openSidebar = (name, title, props) =>
    dispatch({
      type: OPEN,
      name,
      title,
      props: {
        sidebarPolicy: { pattern: "", size: "default" },
        ...props,
      },
    });

  const showSidebar = () =>
    dispatch({
      type: SHOW,
    });

  const hideSidebar = () =>
    dispatch({
      type: HIDE,
    });

  const updateSidebar = (props) => dispatch({ type: UPDATE, props });

  const clearSidebar = () => {
    dispatch({
      type: CLEAR,
    });
  };

  const closeSidebar = () => {
    dispatch({
      type: CLOSE,
    });
  };

  const openGlobalFormWarn = (title, path, action) => {
    openModal("globalFormWarn", {
      size: "tiny",
      formTitle: title,
      organizationPath: path,
      navAction: action,
    });
  };

  return {
    sidebar,
    openSidebar,
    showSidebar,
    hideSidebar,
    updateSidebar,
    clearSidebar,
    closeSidebar,
    openGlobalFormWarn,
  };
};
