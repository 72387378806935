import React, { useState, useCallback, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import TextInput from "components/shared/form/TextInput";
import PrimaryButton from "components/shared/PrimaryButton";
import LoginLayout from "components/auth/LoginLayout";
import { motion } from "framer-motion";
import { fadeIn } from "lib/animations";
import MightyLogo from "components_new/atoms/MightyLogo";
import preventerHandler from "lib/preventerHandler";
import useLogin from "store/auth/useLogin";
import { isEmpty } from "lodash";
import classNames from "classnames";
import Errors from "lib/errors";

function getNextPath(location) {
  return location.state && location.state.nextPathName !== "/"
    ? location.state.nextPathName
    : null;
}

function useNextPath() {
  const location = useLocation();
  return useMemo(() => getNextPath(location), [location]);
}

function LoginView() {
  const nextPath = useNextPath();

  const { doLogin, isSubmitting } = useLogin();

  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [validationErrors, setValidationErrors] = useState(new Errors({}));
  const [authenticationError, setAuthenticationError] = useState(null);

  const resetValidationErrors = useCallback(() => {
    setValidationErrors(new Errors({}));
  }, []);

  const canSubmit = useMemo(
    () => !isEmpty(emailInput) && !isEmpty(passwordInput),
    [emailInput, passwordInput],
  );

  const handleEmailChange = useCallback(
    (data) => {
      setEmailInput(data);
      resetValidationErrors();
    },
    [resetValidationErrors],
  );

  const handlePasswordChange = useCallback(
    (data) => {
      setPasswordInput(data);
      resetValidationErrors();
    },
    [resetValidationErrors],
  );

  const handleSubmit = useCallback(async () => {
    const insensitiveInput = emailInput.toLowerCase();
    try {
      await doLogin(insensitiveInput, passwordInput, nextPath);
    } catch (error) {
      if (error.hasValidationErrors) {
        setValidationErrors(new Errors(error.errors));
      } else if (error.isAuthError) {
        setAuthenticationError(error.errorMessage);
      }
    }
  }, [emailInput, passwordInput, nextPath, doLogin]);

  const groupClass = classNames("LoginFormContainer", {
    "submitting-form": isSubmitting,
  });

  return (
    <LoginLayout extraClassnames="Login--welcomeMighty">
      <div className="Login-holder">
        <div className="u-bg-primary LoginWelcome">
          <div className="LoginLogoHolder">
            <MightyLogo size="large" theme="inverse" />
          </div>
          <motion.h1
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            transition={{ duration: 0.05 }}
            className="u-heading"
          >
            Welcome to Mighty Portfolio!
          </motion.h1>
          <motion.p
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            transition={{ duration: 0.15 }}
            className="LoginWelcome-message"
          >
            Managing your private investments is about to get way easier. Please
            log in to your account.
          </motion.p>
        </div>
        <div className="u-bg-tint-0 LoginForm">
          <div className="LoginForm-holder">
            <form
              className={groupClass}
              onSubmit={preventerHandler(() => handleSubmit())}
            >
              <motion.h2
                initial="hidden"
                animate="visible"
                variants={fadeIn}
                transition={{ duration: 0.05 }}
                className="u-sectionHeading  LoginTitle"
              >
                Login
              </motion.h2>
              <TextInput
                typeOfInput="text"
                extraClassnames="LoginAnimatedElement"
                placeholder="Email Address"
                inputProps={{
                  "aria-label": "Email Address",
                  autoFocus: true,
                }}
                showErrors={
                  !!authenticationError || !!validationErrors.for("email")
                }
                errorMessage={validationErrors.for("email")}
                name="emailInput"
                submitting={isSubmitting}
                onChange={(data, _) => handleEmailChange(data)}
              />
              <TextInput
                typeOfInput="password"
                placeholder="Password"
                inputProps={{ "aria-label": "Password" }}
                showErrors={!!authenticationError}
                errorMessage={authenticationError}
                name="passwordInput"
                submitting={isSubmitting}
                onChange={(data, _) => handlePasswordChange(data)}
              />
              <Link className="ForgotLink" to="/forgot-password">
                Forgot your password?
              </Link>
              <PrimaryButton
                customClass="js-LoginSubmit"
                type="submit"
                disabled={!canSubmit}
                filter={{
                  applyingFilter: isSubmitting,
                }}
              >
                <span className="Button-text">Log In</span>
              </PrimaryButton>
              <Link className="SignUpRequestLink" to="/signup-request">
                Don’t have an account yet? Sign up!
              </Link>
            </form>
          </div>
        </div>
      </div>
    </LoginLayout>
  );
}

export default LoginView;
