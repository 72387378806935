import React, { useState } from "react";
import FormFooter from "components_new/SidebarComponents/SidebarForm/FormFooter";
import HoldingMiniCard from "components/shared/HoldingMiniCard";
import useForm from "lib/hooks/useForm";
import SaleDetail from "components_new/SidebarComponents/forms/groups/SaleDetail";
import Documents from "components_new/SidebarComponents/forms/groups/Documents";
import Notes from "components_new/SidebarComponents/forms/groups/Notes";
import RequiredNote from "components_new/SidebarComponents/shared/RequiredNote";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import getMessage from "components_new/lib/Messages";

function SaleForm({
  ownName,
  investment,
  company,
  createLogSale,
  closeSidebar,
  userContacts,
}) {
  const initialValues = {
    formData: {
      buyer_name: null,
      quantity: null,
      per_quantity_or_total_option: "per_unit",
    },
  };
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useSetToast();

  const onSubmit = (clientData) => {
    setIsLoading(true);
    createLogSale(clientData, investment.id)
      .then((_) => {
        setIsLoading(false);
        closeSidebar();
        addToast(getMessage("addedSale"));
      })
      .catch((error) => {
        handleErrors(error);
        handleScrollView(error);
      })
      .finally(() => setIsLoading(false));
  };

  const {
    formData,
    handleChange,
    handleSubmit,
    handleErrors,
    handleScrollView,
    errors,
    touched,
  } = useForm(initialValues, onSubmit);
  const { documents_data, notes } = formData;

  return (
    <div className="SaleForm">
      <div className="HoldingGroup">
        <HoldingMiniCard
          investment={investment}
          company={company}
          className="FormElement"
        />
      </div>
      <SaleDetail
        formData={formData}
        onChange={handleChange}
        errors={errors}
        userContacts={userContacts}
        investment={investment}
      />
      <Documents
        onChange={(documents) => {
          handleChange("documents_data", [...documents_data, ...documents]);
        }}
        documents={documents_data}
      />
      <Notes
        name="notes"
        notes={notes}
        onChange={(notes) => {
          handleChange("notes", notes);
        }}
      />
      <RequiredNote />
      <FormFooter
        componentName={ownName}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        touched={touched}
      />
    </div>
  );
}

export default SaleForm;
