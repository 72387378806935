//import Api from "lib/Api";
import {
  SEARCH_START,
  SEARCH_SUCCESS,
  SEARCH_FAIL,
  CLEAR_SEARCH,
} from "./actions.js";
import { useSelector, useDispatch } from "react-redux";
import { performSearch } from "./actions";

export default () => {
  const search = useSelector((state) => state.search);
  const dispatch = useDispatch();

  const searchStart = (query) => dispatch({ type: SEARCH_START, query });

  const searchSuccess = ({ results, count, query }) =>
    dispatch({ type: SEARCH_SUCCESS, results, count, query });

  const searchFail = (error) => dispatch({ type: SEARCH_FAIL, error });

  // const performSearch = (scope, query) => {
  //   console.log("scope ", scope, " query ", query);
  //   dispatch(searchStart(query));
  //   return Api.get(`/search/${scope}/${query}`)
  //     .then(({ results: { [scope]: resultItems } }) => {
  //       console.log("result items in search hooks ", resultItems)
  //       dispatch(searchSuccess(resultItems));
  //       return resultItems;
  //     })
  //     .catch(resp => {
  //       dispatch(searchFail(resp.error));
  //     });
  // };

  const clearSearch = () => dispatch({ type: CLEAR_SEARCH });

  return {
    search,
    searchStart,
    searchSuccess,
    searchFail,
    performSearch,
    clearSearch,
  };
};
