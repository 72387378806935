import Api from "lib/Api";
import { receiveView, removeFromView } from "store/views";
import { receiveCollections, receiveCollection } from "store/entities";
import { setResourceAvailability } from "store/resources/actions";
import { createCompanyWithData } from "store/companies/company/actions";
import { isEmpty } from "lodash";

export function validateContact(contact) {
  return () => {
    return Api.post(`/v2/contacts/validate`, contact);
  };
}

export function updateContact(id, formData) {
  return async (dispatch) => {
    const { company } = formData;
    const companyId = company.isNewCompany
      ? await dispatch(createCompanyWithData(company))
      : company.id;

    const payload = {
      ...formData,
      company_id: companyId,
    };

    try {
      const {
        data: {
          views: { contacts },
          entities,
        },
      } = await Api.patch(`/v2/contacts/${id}`, { contact: payload });
      dispatch(receiveCollections(entities));
      dispatch(receiveView("contacts", contacts));
    } catch (error) {
      throw error;
    }
  };
}

export function createContact(formData) {
  return async (dispatch) => {
    const { roles_attributes } = formData;
    const rolesPromises = roles_attributes?.map(async (role) => {
      const { company } = role;
      role["company_id"] = company.isNewCompany
        ? await dispatch(createCompanyWithData(company))
        : company.id;
      return role;
    });

    const contactRoles = rolesPromises
      ? await Promise.all(rolesPromises)
      : null;
    const payload = {
      ...formData,
      roles_attributes: contactRoles,
      company_id: contactRoles?.find((role) => role.is_primary).company_id,
    };

    try {
      const {
        data: {
          views: { contacts, company },
          entities,
        },
      } = await Api.post(`/v2/contacts`, { contact: payload });

      dispatch(receiveView("contacts", contacts));
      dispatch(receiveView("company", company));
      dispatch(receiveCollections(entities));

      return entities;
    } catch (error) {
      throw error;
    }
  };
}

//TODO: After company use querySelector, remove the function bellow
export function fetchContacts({ filters } = {}) {
  return (dispatch) => {
    return Api.get(`/v2/contacts`, { params: filters })
      .then(({ data: { contacts } }) => {
        dispatch(receiveCollection("contacts", Object.values(contacts)));
      })
      .catch((error) => {
        if (error.response.status === 404) {
          dispatch(setResourceAvailability(false));
        }
        return Promise.reject();
      });
  };
}

export function deleteContact(contactId) {
  return (dispatch) => {
    return Api.delete(`/v2/contacts/${contactId}`)
      .then((_) => {
        dispatch(removeFromView("contacts", "ids", contactId));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function isInCurrentViewByPathname(view, currentPathname) {
  return !isEmpty(view) && window.location.pathname.includes(currentPathname);
}
