import React from "react";
//import ExternalLinkIcon from "-!svg-react-loader?!assets/icons/utility/external-link.svg";
import { removePreFilledAndHttp } from "./companyProfileConfig";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./CompanyLinkValue.scss";

function CompanyLinkValue({
  url,
  label,
  preFilled,
  platform,
  extraClassnames,
}) {
  const valueWithoutPrefill = removePreFilledAndHttp(url, preFilled);
  const urlAddress = `${preFilled}${valueWithoutPrefill}`;
  const displayLabel = platform ? `View on ${platform}` : label || urlAddress;

  const companyLinkNameClassnames = classnames(
    "CompanyProfile-link link-item u-textWithEllipsis",
    {
      [extraClassnames]: !!extraClassnames,
    },
  );
  return (
    <a
      className={companyLinkNameClassnames}
      href={urlAddress}
      target="_blank"
      rel="nofollow noopener"
    >
      {displayLabel}
    </a>
  );
}

CompanyLinkValue.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string,
  preFilled: PropTypes.string.isRequired,
  platform: PropTypes.string,
  extraClassnames: PropTypes.string,
};

export default CompanyLinkValue;
