import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import NumberInput from "components_new/atoms/NumberInput";
import { Form } from "@ableco/semantic-ui-react";
import Input from "components_new/atoms/Input";
import HelperMessage from "components/shared/form/HelperMessage";
import "./PercentageInput.scss";

function PercentageInput(props) {
  const {
    label,
    decimalLimit,
    allowDecimal,
    placeholder,
    iconPosition,
    defaultValue,
    textHelper,
    size,
    ...inputProps
  } = props;

  if (inputProps.disabled) {
    const inputClassnames = classNames("Input PercentageInput", {
      left: iconPosition !== "right",
    });

    return (
      <Fragment>
        <Input
          className={inputClassnames}
          disabled
          placeholder={placeholder}
          defaultValue={defaultValue && defaultValue.toString() + "%"}
          {...inputProps}
        />
        {textHelper && <HelperMessage text={textHelper} />}
      </Fragment>
    );
  }

  const percentageInputClassnames = classNames("Input PercentageInput", {
    [size]: !!size,
  });

  return (
    <Form.Field
      className={percentageInputClassnames}
      control={NumberInput}
      label={label}
      placeholder={placeholder}
      allowDecimal={allowDecimal}
      decimalLimit={decimalLimit}
      defaultValue={defaultValue}
      textHelper={textHelper}
      aria-label={label ? label : placeholder}
      size={size}
      icon={<i className="icon">%</i>}
      iconPosition={iconPosition}
      {...inputProps}
    />
  );
}

NumberInput.propTypes = {
  fieldLabel: PropTypes.string,
  decimalLimit: PropTypes.number,
  allowDecimal: PropTypes.bool,
  placeholder: PropTypes.string,
  iconPosition: PropTypes.oneOf(["left", "right"]),
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.string,
};

NumberInput.defaultProps = {
  fieldLabel: null,
  allowDecimal: false,
  decimalLimit: 1,
  placeholder: "",
  iconPosition: "right",
  defaultValue: null,
};

export default PercentageInput;
