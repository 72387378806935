export const REQUEST_DOCUMENTS = "documentsView/REQUEST_DOCUMENTS";
export const RECEIVE_DOCUMENTS = "documentsView/RECEIVE_DOCUMENTS";
export const REQUEST_INVESTMENT_DOCUMENTS =
  "documentsView/REQUEST_INVESTMENT_DOCUMENTS";
export const RECEIVE_INVESTMENT_DOCUMENTS =
  "documentsView/RECEIVE_INVESTMENT_DOCUMENTS";
export const START_DOCUMENT_CREATION = "documentsView/START_DOCUMENT_CREATION";
export const STOP_DOCUMENT_CREATION = "documentsView/STOP_DOCUMENT_CREATION";
export const CLEAN_DOCUMENTS = "documentsView/CLEAN_DOCUMENTS";

export function startDocumentCreation() {
  return { type: START_DOCUMENT_CREATION };
}

export function stopDocumentCreation() {
  return { type: STOP_DOCUMENT_CREATION };
}

export function cleanDocuments() {
  return { type: CLEAN_DOCUMENTS };
}
