import React from "react";
import ContactDetailsView from "./ContactDetailsView";
import useOrganizations from "store/organizations/hooks";
import { useSaveContactDocument } from "components_new/views/Contacts/hooks/mutations/useSaveContactDocument";
import { useUpdateContactDocument } from "components_new/views/Contacts/hooks/mutations/useUpdateContactDocument";
import { useRemoveContactDocument } from "components_new/views/Contacts/hooks/mutations/useRemoveContactDocument";
import { useCreateContactPost } from "components_new/views/Contacts/hooks/mutations/useCreateContactPost";
import { useUpdateContactPost } from "components_new/views/Contacts/hooks/mutations/useUpdateContactPost";
import { useRemoveContactPost } from "components_new/views/Contacts/hooks/mutations/useRemoveContactPost";
import { useOpenDocumentsUploader } from "components_new/views/Contacts/hooks/mutations/useOpenDocumentsUploader";
import { useContact } from "components_new/views/Contacts/hooks/queries/useContact";
import Loader from "components/shared/Loader";
import groupBy from "lodash/groupBy";
import { isEmpty } from "lodash";

function ContactDetailsViewWrapper({ contactId, closeSidebar }) {
  const {
    currentOrganizationUsername: organizationUsername,
    currentOrganization,
  } = useOrganizations();

  const { mutateAsync: saveContactDocument } = useSaveContactDocument();
  const { mutateAsync: updateContactDocument } = useUpdateContactDocument();
  const { mutateAsync: removeContactDocument } = useRemoveContactDocument();
  const { mutateAsync: saveContactPost } = useCreateContactPost();
  const { mutateAsync: updateContactPost } = useUpdateContactPost();
  const { mutateAsync: removeContactPost } = useRemoveContactPost();
  const { mutateAsync: openDocumentsUploader } = useOpenDocumentsUploader();

  const { data, isLoading } = useContact(contactId);

  if (isLoading) {
    return <Loader />;
  }

  const handleUpdateContactDocument = (document) => {
    return updateContactDocument({
      document,
      contactId: contactId,
    });
  };

  const handleRemoveContactDocument = (document) => {
    return removeContactDocument({
      document,
      contactId: contactId,
    });
  };

  const handleCreatePost = (resource, post) => {
    return saveContactPost({ post, contactId: resource.contact_id });
  };

  const handleUpdatePost = (resource, postId, content) => {
    return updateContactPost({
      content,
      contactId: resource.contact_id,
      postId,
    });
  };

  const handleRemovePost = (resource, postId) => {
    return removeContactPost({
      contactId: resource.contact_id,
      postId,
    });
  };

  const currentUser = currentOrganization.organization_memberships;
  const usersById = groupBy(
    currentOrganization.organization_memberships,
    "user_id",
  );

  const resource = {
    type: "Contact",
    contact_id: contactId,
  };

  // When the Sidebar view is close the `updatedContact` is Empty but since the animation of closing the sidebar is happening it show an error.
  return isEmpty(data) ? null : (
    <ContactDetailsView
      contact={data}
      saveContactDocuments={saveContactDocument}
      updateContactDocument={handleUpdateContactDocument}
      removeContactDocument={handleRemoveContactDocument}
      organizationUsername={organizationUsername}
      currentUser={currentUser}
      users={usersById}
      createPost={handleCreatePost}
      updatePost={handleUpdatePost}
      removePost={handleRemovePost}
      resource={resource}
      openDocumentsUploader={openDocumentsUploader}
      closeSidebar={closeSidebar}
    />
  );
}

export default ContactDetailsViewWrapper;
