import React from "react";
import { connect } from "react-redux";
import FlashListContainer from "containers/shared/modals/FlashListContainer";

function FlashListModalContainer({ showInModal }) {
  return showInModal ? <FlashListContainer /> : null;
}

function mapStateToProps({ flash }) {
  return {
    showInModal: flash.showInModal,
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FlashListModalContainer);
