import React from "react";
import DealsView from "./DealsView";
import Loader from "components/shared/Loader";
import useOrganizations from "store/organizations/hooks";
import { usePipeline } from "components_new/views/Deals/hooks/queries/usePipeline";

function DealsViewWrapper() {
  const {
    currentOrganization: {
      has_pipelines: hasPipelines,
      pipeline_id: pipelineId,
    },
  } = useOrganizations();
  const { data, isLoading } = usePipeline(pipelineId);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <DealsView
      hasPipelines={hasPipelines}
      pipelineId={pipelineId}
      activeDealsCount={data?.activeDealsCount}
      committedDealsCount={data?.committedDealsCount}
      passedDealsCount={data?.passedDealsCount}
    />
  );
}

export default DealsViewWrapper;
