import { useMutation, useQueryClient } from "react-query";
import { buildPostResourceRoute } from "lib/globalActionsHelper";
import removePost from "lib/posts/removePost";
import Api from "lib/Api";

function removeDealPost(oldData, postId) {
  return {
    ...oldData,
    posts: removePost(oldData.posts, postId) || [],
  };
}

export function useDeleteDealPost() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ resource, postId }) => {
      const postResourceRoute = buildPostResourceRoute(resource);
      return Api.delete(`${postResourceRoute}/posts/${postId}`);
    },
    {
      onSuccess: (_, { resource, postId }) => {
        return queryClient.setQueryData(["Deal", resource.deal_id], (oldData) =>
          removeDealPost(oldData, postId),
        );
      },
    },
  );
}
