import React, { useState } from "react";
import { omit } from "lodash";
import HtmlEditor from "components_new/elements/HtmlEditor";
import Form from "components_new/atoms/Form";
import Button from "components/shared/Button";
import { checkIfHtmlHasContent } from "lib/editor";
import useForm from "lib/hooks/useForm";
import formatDate from "lib/formatDate";
import DropZone from "components_new/elements/DropZone";

function CreatePost({
  user,
  isSubmitting,
  onSubmit,
  onCancel,
  placeholder,
  openDocumentsUploader,
  parentId,
  isInlineAttachment,
  size,
  isAReply,
}) {
  const [inlineDocuments, setInlineDocuments] = useState([]);
  const [showAddAttachmentLink, setShowAddAttachmentLink] = useState(true);
  const initialValues = {
    formData: {
      content: "",
      documents_data: {},
      parent_post_id: parentId,
      note_type: null,
    },
  };
  const submit = (clientData) => {
    const newFormData = {
      ...clientData,
      documents_data: isInlineAttachment
        ? inlineDocuments
        : [...Object.values(clientData.documents_data)],
    };
    onSubmit(newFormData);
  };
  const { formData, handleChange, handleSubmit } = useForm(
    initialValues,
    submit,
  );

  const createDocument = (filepickerData) => {
    const givenDocuments = filepickerData.reduce(
      (documents_data, givenDocument) => ({
        ...documents_data,
        [givenDocument.handle]: givenDocument,
      }),
      {},
    );

    handleChange("documents_data", {
      ...formData.documents_data,
      ...givenDocuments,
    });
  };

  const removeAttachment = (handle) => {
    handleChange("documents_data", omit(formData.documents_data, handle));
  };

  const onAttachFilesClick = () => {
    if (isInlineAttachment) {
      setShowAddAttachmentLink(false);
    } else {
      openDocumentsUploader({
        afterUpload: createDocument,
      });
    }
  };

  const disableSubmitButton = () => {
    const hasContent = checkIfHtmlHasContent(formData.content);
    const hasAttachments = Object.values(formData.documents_data).length > 0;
    return !(hasContent || hasAttachments);
  };

  return (
    <Form onSubmit={handleSubmit} role="form">
      <article className="PostSection-content">
        <header className="PostSection-contentHeader">
          <h3 className="u-metaContentBold">{user.full_name}</h3>
          <span className="u-metaContentLight">
            {formatDate(Date.now(), "ll")}
          </span>
        </header>
        <HtmlEditor
          name="content"
          extraClassnames="PostSection-content"
          value={formData.content}
          placeholder={placeholder}
          onChange={(_, { value }) => handleChange("content", value)}
          uploadedAttachments={formData.documents_data}
          onAttachFilesClick={onAttachFilesClick}
          removeAttachment={removeAttachment}
          showAddAttachmentLink={showAddAttachmentLink}
          showAttachmentsList={isInlineAttachment}
          showOptions={!isAReply}
          onChangeOptions={(value) => handleChange("note_type", value)}
          optionValue={formData.note_type}
        />
        {isInlineAttachment && !showAddAttachmentLink && (
          <DropZone
            onChange={(values) => setInlineDocuments(values)}
            canMarkAsClosing={true}
            canChooseFileType={true}
            size={size}
          />
        )}
        <div className="Discussion-submitForm">
          <Button
            filter={{
              applyingFilter: isSubmitting,
            }}
            size="small"
            type="submit"
            disabled={disableSubmitButton()}
          >
            Submit
          </Button>
          <Button
            size="small"
            onClick={onCancel}
            behavior="secondary"
            disabled={isSubmitting}
          >
            Cancel
          </Button>
        </div>
      </article>
    </Form>
  );
}

export default CreatePost;
