import React from "react";
import CommittedDealHeader from "./CommittedDealHeader";
import PassedDealHeader from "./PassedDealHeader";
import ActiveDealHeader from "./ActiveDealHeader";
import "./DealSidebarHeader.scss";

function DealSidebarHeader({
  deal,
  openEditDealForm,
  onConvertToInvesment,
  openDeleteDealModal,
  openReactivateDealModal,
  openEditCommitmentModal,
  openEditPassModal,
  stagesOptions,
  updateDealStage,
  openCommitToDealModal,
  openPassFromDealModal,
  closeSidebar,
}) {
  switch (deal.status) {
    case "active":
      return (
        <ActiveDealHeader
          deal={deal}
          stagesOptions={stagesOptions}
          updateDealStage={updateDealStage}
          openEditDealForm={openEditDealForm}
          openDeleteDealModal={openDeleteDealModal}
          openCommitToDealModal={openCommitToDealModal}
          openPassFromDealModal={openPassFromDealModal}
          closeSidebar={closeSidebar}
        />
      );
    case "committed":
      return (
        <CommittedDealHeader
          deal={deal}
          closeSidebar={closeSidebar}
          openEditDealForm={openEditDealForm}
          onConvertToInvesment={onConvertToInvesment}
          openDeleteDealModal={openDeleteDealModal}
          openReactivateDealModal={openReactivateDealModal}
          openEditCommitmentModal={openEditCommitmentModal}
        />
      );
    case "passed":
      return (
        <PassedDealHeader
          deal={deal}
          closeSidebar={closeSidebar}
          openEditDealForm={openEditDealForm}
          openDeleteDealModal={openDeleteDealModal}
          openReactivateDealModal={openReactivateDealModal}
          openEditPassModal={openEditPassModal}
        />
      );
  }
}

export default DealSidebarHeader;
