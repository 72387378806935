export const RECEIVE_VIEW = "views/RECEIVE_VIEW";
export const UPDATE_VIEW = "views/UPDATE_VIEW";
export const REMOVE_FROM_VIEW = "views/REMOVE_FROM_VIEW";
export const UPDATE_VIEW_STATE = "views/UPDATE_VIEW_STATE";
export const RESET_VIEW_STATE = "views/RESET_VIEW_STATE";

export function receiveView(name, data) {
  return { type: RECEIVE_VIEW, name, data };
}

export function updateView(name, nameField, id) {
  return { type: UPDATE_VIEW, name, nameField, id };
}

export function removeFromView(name, nameField, id) {
  return { type: REMOVE_FROM_VIEW, name, nameField, id };
}

export function updateViewState(name, data) {
  return { type: UPDATE_VIEW_STATE, name, data };
}

export function resetViewState(name) {
  return { type: RESET_VIEW_STATE, name };
}

export function expandInvestmentsViewRows() {
  return updateViewState("investments", {
    allRowsCollapsed: false,
    allRowsExpanded: true,
  });
}

export function collapseInvestmentsViewRows() {
  return updateViewState("investments", {
    allRowsCollapsed: true,
    allRowsExpanded: false,
  });
}

export function resetInvestmentsViewRows() {
  return updateViewState("investments", {
    allRowsCollapsed: false,
    allRowsExpanded: false,
  });
}
