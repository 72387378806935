import Errors from "lib/errors";

// TODO: Rename `filterErrors` function name and `errors` variable name
export default function filterErrors(errors, index) {
  const errorsByIndex = errors.find(
    (errorResult) =>
      errorResult.success === false && errorResult.index === index.toString(),
  );
  return errorsByIndex ? new Errors(errorsByIndex.errors) : new Errors({});
}
