import {
  UPDATE_FORM,
  SIGNUP_REQUEST_ERROR,
  SIGNUP_REQUEST_START,
  SIGNUP_REQUEST_SUCCESS,
  RESET_SIGNUP_REQUEST_FORM,
} from "actions/synchronous/signUpRequestForm";

const initialState = {
  formData: {},
  errors: {},
  lastSubmitErrors: {},
  submitting: false,
  signUpRequestSuccess: false,
};

function signUpRequest(state = initialState, action) {
  switch (action.type) {
    case UPDATE_FORM:
      return {
        ...state,
        formData: { ...state.formData, [action.key]: action.value },
        errors: { ...state.errors, [action.key]: null },
      };
    case SIGNUP_REQUEST_START:
      return { ...state, submitting: true, lastSubmitErrors: {} };
    case SIGNUP_REQUEST_SUCCESS:
      return { ...state, submitting: false, signUpRequestSuccess: true };
    case SIGNUP_REQUEST_ERROR:
      return {
        ...state,
        errors: { ...state.errors, ...action.errors },
        submitting: false,
        lastSubmitErrors: { ...state.errors, ...action.errors },
      };
    case RESET_SIGNUP_REQUEST_FORM:
      return initialState;
    default:
      return state;
  }
}

export default signUpRequest;
