import Api from "lib/Api";
import {
  receiveOrganizationIdInvited,
  receiveAuthorizedUser,
  intermediateAuthSuccess,
} from "actions/synchronous/auth";
import { itemFetchSuccess } from "store/domain/domainActions";
import { SET_CURRENT_INVITATION, CLEAN_INVITATION } from "./actionTypes";

export function setCurrentInvitation(invitation) {
  return { type: SET_CURRENT_INVITATION, value: invitation };
}

export function cleanInvitation() {
  return { type: CLEAN_INVITATION };
}

export function verifyInvitation(invite_code, organization_membership_id) {
  return () => {
    return Api.post(
      "/organization_memberships/verify_invitation",
      {
        invite_code,
        organization_membership_id,
      },
      { withoutOrganization: true },
    )
      .then((invitation) => {
        return invitation.data;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function createAccountFromInvitation(
  user,
  invite_code,
  organization_membership_id,
) {
  return (dispatch) => {
    return Api.post(
      "/users",
      {
        user,
        invite_code,
        organization_membership_id,
      },
      {
        withoutOrganization: true,
      },
    ).then((response) => {
      const { user, authentication, invite_organization_id } = response.data;
      dispatch(receiveOrganizationIdInvited(invite_organization_id));
      dispatch(receiveAuthorizedUser(user));
      if (authentication.intermediate_auth_token !== undefined) {
        const intermediateToken = authentication.intermediate_auth_token;
        dispatch(intermediateAuthSuccess(intermediateToken));
      }
      return response;
    });
  };
}

export function acceptInvitationToOrganization(
  organizationUserName,
  organizationMembershipId,
) {
  return (dispatch) => {
    return Api.patch(
      `${organizationUserName}/organization_memberships/${organizationMembershipId}/accept`,
      {},
      { withoutOrganization: true },
    ).then(({ data }) => {
      dispatch(itemFetchSuccess("userOrganizationMemberships", data.id, data));
      return data;
    });
  };
}
