import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { parseElements } from "components_new/elements/MultiSelect/config";
import { getCollection } from "store/entities";
import {
  updateInvestment as updateInvestmentHelper,
  removeInvestment as removeInvestmentHelper,
  createInvestmentV2,
} from "store/investments/investment/actions";
import useSearch from "store/search/hooks";
import { useQueryClient } from "react-query";
import { isEqual } from "lodash";

export default () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { performSearch } = useSearch();

  const invalidateQueries = useCallback(() => {
    queryClient.invalidateQueries(["Investments"]);
    queryClient.invalidateQueries(["Portfolios"]);
    queryClient.invalidateQueries(["Company"]);
    queryClient.invalidateQueries(["Dashboard"]);
  }, [queryClient]);

  const prefilledContacts = useSelector(
    (state) =>
      parseElements(
        getCollection(
          state,
          "contacts",
          state.views.investment ? state.views.investment.contact_ids : [],
        ),
      ),
    isEqual,
  );

  const searchContact = (scope, query) =>
    dispatch(performSearch(scope, query)).then(
      ({ results: { [scope]: resultItems } }) => resultItems,
    );

  const createInvestment = async (formData) => {
    const createdInvestment = await dispatch(createInvestmentV2(formData));
    await invalidateQueries();

    return createdInvestment;
  };

  const updateInvestment = async (formData) => {
    await dispatch(
      updateInvestmentHelper(formData.id, formData, formData.documents_data),
    );
    await invalidateQueries();
  };

  const removeInvestment = async (investmentId, companyId, portfolioId) => {
    await dispatch(
      removeInvestmentHelper(investmentId, companyId, portfolioId),
    );
    await invalidateQueries();
  };

  return {
    createInvestment,
    updateInvestment,
    removeInvestment,
    prefilledContacts,
    searchContact,
  };
};
