import { OPEN_MODAL, CLOSE_MODAL, UPDATE_MODAL_OPTIONS } from "./actionTypes";

const initialState = {
  modalNames: [],
  isOpen: false,
  modalProps: {},
};

let prevStates = [];

export default function modalV2Reducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_MODAL: {
      prevStates.push(state);
      return {
        ...state,
        isOpen: true,
        modalNames: state.modalNames.concat(action.modalName),
        modalProps: {
          ...state.modalProps,
          [action.modalName]: action.props,
        },
      };
    }
    case UPDATE_MODAL_OPTIONS:
      return {
        ...state,
        modalProps: {
          ...state.modalProps,
          [action.modalName]: {
            ...state.modalProps[action.modalName],
            ...action.modalProps,
          },
        },
      };
    case CLOSE_MODAL: {
      const prevState = prevStates[prevStates.length - 1];
      prevStates.pop();
      return prevState;
    }
    default:
      return state;
  }
}
