import React, { useState, useEffect, useCallback } from "react";
import Segment from "components_new/atoms/Segment";
import { DebouncedSearchInput } from "components_new/atoms/SearchInput";
import DownloadCsvButton from "components_new/elements/DownloadCsvButton";
import { SkeletonStatLine } from "components_new/elements/SummaryStats/SummaryStatsSkeleton";
import ItemsCounter from "components_new/elements/ItemsCounter";
import ContactTableFilter from "./ContactTableFilter";
import isEmptyValue from "components_new/lib/isEmptyValue";
import { isEmpty, omitBy } from "lodash";

const MAX_CONTACTS_PER_CALL = 250;

function TopBar({ updateFilters, filters, meta, contactsCount, isLoading }) {
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    updateFilters((filters) =>
      omitBy({ ...filters, query: searchText }, isEmpty),
    );
  }, [searchText, updateFilters]);

  const applyFilters = useCallback(
    (appliedFilters) => {
      updateFilters((currentFilters) =>
        omitBy({ ...currentFilters, ...appliedFilters }, isEmptyValue),
      );
    },
    [updateFilters],
  );

  return (
    <Segment extraClassNames="TopBar">
      <Segment
        attached="top"
        transparent
        clearing
        extraClassNames="TopBar-FilterRow"
      >
        {isLoading ? (
          <SkeletonStatLine className="Stat-normalLine" />
        ) : (
          <ItemsCounter
            title="contact"
            className="ContactCount u-bodySmallBold"
            totalItems={meta?.total_contacts}
            currentItems={contactsCount}
            maxItemsPerCall={MAX_CONTACTS_PER_CALL}
          />
        )}
        <DebouncedSearchInput
          onChange={setSearchText}
          className="TopBar-item"
          placeholder="Search contacts"
        />
        <ContactTableFilter
          meta={meta}
          onApplyFilters={applyFilters}
          filters={filters}
        />
        <DownloadCsvButton
          className="TopBar-item"
          collection="contacts"
          params={filters}
        />
      </Segment>
    </Segment>
  );
}

export default TopBar;
