import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import TextLabel from "components_new/atoms/TextLabel";
import Icon from "components_new/atoms/Icon";

StageSectionHeader.propTypes = {
  title: PropTypes.string,
  section: PropTypes.shape({
    color: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  accordion: PropTypes.bool,
  accordionOpen: PropTypes.bool,
  toggleAccordion: PropTypes.func,
};

export default function StageSectionHeader({
  title,
  section,
  accordion,
  accordionOpen,
  toggleAccordion,
}) {
  const sectionHeaderClassnames = classnames(
    "SectionList-SectionHeader-Default",
    {
      "SectionList-SectionHeader-Accordion-Open": accordion && accordionOpen,
      "SectionList-SectionHeader-TextLight": section.color,
    },
  );
  const accordionIndicatorClassnames = classnames(
    "SectionList-SectionHeader-Default-AccordionIndicator",
    {
      "SectionList-Accordion-Open": accordion && accordionOpen,
    },
  );
  const backgroundStyle = section.color
    ? { backgroundColor: section.color }
    : null;
  return (
    <div
      className={sectionHeaderClassnames}
      onClick={toggleAccordion}
      style={{ ...backgroundStyle }}
    >
      <TextLabel weight="bold">
        {title} {`(${section.data.length})`}
      </TextLabel>
      <div className={accordionIndicatorClassnames}>
        <Icon icon="ri-arrow-down-s-line" />
      </div>
    </div>
  );
}
