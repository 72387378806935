import { combineReducers } from "redux";
import entitiesReducer from "./entitiesReducer";
import requestsReducer from "./requestsReducer";
import idCollectionsReducer from "./idCollectionsReducer";

const reducer = combineReducers({
  requests: requestsReducer,
  entities: entitiesReducer,
  idCollections: idCollectionsReducer,
});

export { reducer };
export {
  collectionFetchStart,
  collectionFetchSuccess,
  collectionFetchFail,
  itemFetchStart,
  itemFetchSuccess,
  itemFetchFail,
  mutationStart,
  mutationSuccess,
  mutationFail,
} from "./domainActions";
