import React from "react";
import PropTypes from "prop-types";
import { investmentPropType } from "PropTypes";
import investmentDetailLabelsAndContent from "lib/investments/investmentDetailLabelsAndContent";
import { isFunction } from "lodash";
import Icon from "components_new/atoms/Icon";

function shouldHideNoteMaturityDateTerm(investment, detailName) {
  return (
    detailName === "note_maturity_date_term" &&
    investment[detailName] === "other"
  );
}

const InvestmentDetailItem = ({
  investment,
  detailName,
  openReferrerModal,
  openContactDetailsView,
}) => {
  const isContactsList =
    Array.isArray(investment[detailName]) && detailName === "contacts";
  const { label, content } = investmentDetailLabelsAndContent[detailName];
  if (shouldHideNoteMaturityDateTerm(investment, detailName)) {
    return null;
  }
  const actualLabel = isFunction(label) ? label(investment) : label;

  return (
    <div className="InvestmentDetailItem">
      <div className="InvestmentDetailItem-inner">
        <div className="InvestmentDetailItem-title">{actualLabel}</div>
        <div className="InvestmentDetailItem-content">
          {isContactsList ? (
            investment[detailName].map((item) => {
              const onEditReferrerClick = () => openReferrerModal(item);
              const onContactCardClick = () => openContactDetailsView(item);
              return (
                <div key={item.id} className="InvestmentDetailItem-referrer">
                  <div onClick={onContactCardClick}>{content(item)}</div>
                  <Icon
                    icon="ri-pencil-line"
                    onClick={onEditReferrerClick}
                  />{" "}
                  <br />
                </div>
              );
            })
          ) : (
            <div className="InvestmentDetailItem-content">
              {content(investment, detailName)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

InvestmentDetailItem.propTypes = {
  investment: investmentPropType.isRequired,
  detailName: PropTypes.string.isRequired,
  openReferrerModal: PropTypes.func,
  openContactDetailsView: PropTypes.func,
};

export default InvestmentDetailItem;
