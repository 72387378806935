import React from "react";
import Value from "components_new/atoms/Value";
import SubValue from "components_new/atoms/SubValue";
import BasicLabel from "components_new/atoms/BasicLabel";

function ContactInfo({ contact, showSidebar }) {
  return (
    <div
      className="ContactsTable-ContactName"
      onClick={() =>
        showSidebar("contactsView", {
          contactId: contact.id,
        })
      }
    >
      <Value type="text" extraClassName="u-textWithEllipsis">
        {contact.full_name}
      </Value>
      {contact.tier && (
        <div className="ContactsTable-contactName-Tier">
          <BasicLabel
            upperCase={true}
            type="generic"
            children={`Tier ${contact.tier}`}
          />
        </div>
      )}
      {contact.company_titles.length > 0 && (
        <SubValue>{contact.company_titles.join(", ")}</SubValue>
      )}
      <SubValue>{contact.email}</SubValue>
    </div>
  );
}

export default ContactInfo;
