import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import TextInput from "components/shared/form/TextInput";
import Label from "components/shared/Label";

function PreviewComponent({ extraClassnames, children, componentProps }) {
  const previewComponentClassnames = classNames("PreviewComponent", {
    [extraClassnames]: !!extraClassnames,
  });

  return (
    <div className={previewComponentClassnames}>
      <Label message="Preview" type="gray" inverse={true} />
      {React.cloneElement(children, {
        ...componentProps,
        label: componentProps.label || "Field label",
        size: "small",
      })}
    </div>
  );
}

PreviewComponent.propTypes = {
  children: PropTypes.node.isRequired,
  componentProps: PropTypes.shape({
    label: PropTypes.string,
  }),
  extraClassnames: PropTypes.string,
};

PreviewComponent.defaultProps = {
  children: <TextInput />,
};

export default PreviewComponent;
