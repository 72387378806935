import React from "react";
import { Placeholder as SemanticSkeleton } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/placeholder.css";
import "./Skeleton.scss";

function SkeletonLine({ length, ...props }) {
  return (
    <SemanticSkeleton.Line
      className="SkeletonLine"
      length={length}
      {...props}
    />
  );
}
export default SkeletonLine;
