import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@ableco/semantic-ui-react";
import DownloadCsvButton from "components_new/elements/DownloadCsvButton";
import Segment from "components_new/atoms/Segment";
import { DebouncedSearchInput } from "components_new/atoms/SearchInput";
import SelectableButton from "components_new/atoms/SelectableButton";
import PropTypes from "prop-types";
import pluralize from "pluralize";
import TableFilter from "./Filters";
import SummaryStats from "components_new/elements/SummaryStats";
import { isEmpty, isEqual, pickBy } from "lodash";
import { SkeletonStatLine } from "components_new/elements/SummaryStats/SummaryStatsSkeleton";
import "./TopBar.scss";
import { summaryStatsProptype } from "PropTypes";

function TopBar({
  portfolioId,
  filters,
  updateFilters,
  summaryStatsInfo,
  groupedByCompany,
  showClosingDocuments,
  type,
  investmentCount,
  currentTab,
  udpateUserInformation,
  isLoading,
}) {
  const [searchText, setSearchText] = useState("");
  const [downloadParams, setDownloadParams] = useState({});
  const [showClosingDocumentsParam, setShowClosingDocumentsParam] = useState(
    showClosingDocuments,
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (isEmpty(filters.query)) {
      setSearchText("");
    }
  }, [filters]);

  useEffect(() => {
    updateFilters((currentFilters) => buildFilters(currentFilters, searchText));
  }, [searchText, updateFilters, buildFilters]);

  useEffect(() => {
    const newFilters = buildFilters(filters, searchText);

    setDownloadParams({ ...newFilters, portfolio_id: portfolioId });
  }, [buildFilters, filters, portfolioId, searchText]);

  useEffect(() => {
    udpateUserInformation({
      show_closing_documents: showClosingDocumentsParam,
    });
  }, [showClosingDocumentsParam, udpateUserInformation]);

  const buildFilters = useCallback((filters, searchText) => {
    const { query, ...restFilters } = filters;

    if (!isEmpty(searchText)) {
      restFilters.query = searchText;
    }

    return restFilters;
  }, []);

  const applyFilters = useCallback(
    (type, date, showClosingDocumentsFilter) => {
      const types = Object.keys(pickBy(type));
      const clearNewFilter = (currentFilters) => {
        if (isEmpty(date)) {
          delete currentFilters.dates;
          delete currentFilters.range_start;
          delete currentFilters.range_end;
        }
        return currentFilters;
      };
      const newFilters = { types, ...date };

      if (!isEqual(filters, newFilters)) {
        updateFilters((currentFilters) => ({
          ...clearNewFilter(currentFilters),
          ...newFilters,
        }));
      }

      setShowClosingDocumentsParam(showClosingDocumentsFilter);
    },
    [filters, updateFilters],
  );

  const toggleGroupedByCompany = useCallback(() => {
    const { pathname } = location;
    const switchedValue = !groupedByCompany;
    const newPath = switchedValue ? pathname + "?grouped_by=company" : pathname;

    navigate(newPath);
  }, [groupedByCompany, navigate]);

  const filteredSummaryStats = useMemo(() => {
    return {
      number_of_companies: summaryStatsInfo.number_of_companies,
      total_invested: summaryStatsInfo.total_invested,
      total_valuation:
        type === "cashFlow" || type === "exits"
          ? null
          : summaryStatsInfo.total_valuation,
      total_cash_received: summaryStatsInfo.total_cash_received,
      multiple: summaryStatsInfo.multiple,
    };
  }, [summaryStatsInfo, type]);

  const investmentCountText = pluralize(
    `${investmentCount} investment`,
    investmentCount,
  );

  return (
    <Segment extraClassNames="TopBar">
      <Segment
        attached="top"
        transparent
        clearing
        extraClassNames="TopBar-FilterRow"
      >
        <span className="InvestmentsCount u-bodySmallBold">
          {isLoading ? (
            <SkeletonStatLine className="Stat-normalLine" />
          ) : (
            investmentCountText
          )}
        </span>
        <DebouncedSearchInput
          onChange={setSearchText}
          className="TopBar-item"
          placeholder="Search companies or investments"
          value={searchText}
        />
        <TableFilter
          onApplyFilters={applyFilters}
          values={filters}
          showClosingDocuments={showClosingDocumentsParam}
          className="TopBar-item"
          currentTab={currentTab}
        />
        {type !== "cashFlow" && (
          <SelectableButton
            size="small"
            className="TopBar-item"
            selected={groupedByCompany}
            onClick={toggleGroupedByCompany}
          >
            {(selected) =>
              selected ? "Grouped by company" : "Group by company"
            }
          </SelectableButton>
        )}
        <DownloadCsvButton
          className="TopBar-item"
          collection="investments"
          params={downloadParams}
        />
      </Segment>
      <Segment
        attached="bottom"
        transparent
        clearing
        extraClassNames="TopBar-FilterRow"
      >
        <Grid.Column>
          <Grid.Row>
            <SummaryStats
              isLoading={isLoading}
              summaryStats={filteredSummaryStats}
            />
          </Grid.Row>
        </Grid.Column>
      </Segment>
    </Segment>
  );
}

TopBar.propTypes = {
  investmentCount: PropTypes.number,
  portfolioId: PropTypes.string,
  fetchCurrentAction: PropTypes.func,
  summaryStatsInfo: summaryStatsProptype,
  groupedByCompany: PropTypes.bool,
  showClosingDocuments: PropTypes.bool,
  type: PropTypes.string,
  setGroupedByCompany: PropTypes.func,
  location: PropTypes.shape({ pathname: PropTypes.string }),
  currentTab: PropTypes.string,
  currentUserId: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  filters: PropTypes.shape({ query: PropTypes.string }),
  updateFilters: PropTypes.func,
  udpateUserInformation: PropTypes.func,
};

export default TopBar;
