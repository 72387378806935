import React, { useState, useMemo, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { isEmpty, forOwn, debounce } from "lodash";
import { Search, Label } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/search.css";
import "semantic-ui-css/components/transition.css";
import ResultItemAvatar from "./ResultItemAvatar";
import Icon from "components_new/atoms/Icon";
import classNames from "classnames";
import "./SearchAndSelect.scss";
import DefaultCompanyIcon from "-!svg-react-loader?!assets/icons/MightyIcons/company-med.svg";
import {
  SearchScopes,
  parseItemResult,
  singleScopes,
  expectSingleCategory,
  scopeForUrl,
  scopeToKey,
} from "./config.js";

// NOTE after this component begins to be used Remove components/shared/ResultCard

const SearchCategoryLayout = ({ categoryContent, resultsContent }) => (
  <div className="Category">
    <h3 className="Category-name">{categoryContent}</h3>
    <div className="results">{resultsContent}</div>
  </div>
);

const itemRenderer = (
  { title, description, type, subdescription, logo_url, verified },
  query,
  newOptionRenderer,
) => {
  if (type !== "add_new")
    return (
      <ResultItemAvatar
        title={parseItemResult(title, query)}
        description={description}
        subdescription={subdescription}
        imageUrl={logo_url}
        type={type}
        isVerified={verified}
        avatarIcon={DefaultCompanyIcon}
      />
    );
  else {
    if (newOptionRenderer) {
      return newOptionRenderer(query);
    }
    return <Label as="span">Add New</Label>;
  }
};

const getResults = (data, scope, isSingleCategory, addNew) => {
  const results = {};
  forOwn(data, (collection, collectionName) => {
    if (!isEmpty(collection)) {
      results[collectionName] = { name: collectionName, results: collection };
    }
  });
  if (isSingleCategory) {
    const categoryResults = results[scopeToKey(scope)]
      ? results[scopeToKey(scope)].results
      : [];
    if (addNew)
      return categoryResults.concat({
        title: "Add New",
        type: "add_new",
      });
    return categoryResults;
  }
  return results;
};

export default function SearchAndSelect({
  scope,
  fetchResults,
  onChange,
  onAddNew,
  newOptionRenderer,
  initialSelection,
  isFetching,
  results,
  size = "small",
  placeholder,
}) {
  const searchAndSelectClassnames = classNames("NewSearchAndSelect", {
    [size]: !!size,
  });
  const [value, setValue] = useState("");

  useEffect(() => {
    if (initialSelection && initialSelection.title !== value) {
      setValue(initialSelection.title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSelection]);

  const isSingleCategory = expectSingleCategory(scope);

  const processedResults = useMemo(
    () => getResults(results, scope, isSingleCategory, !!onAddNew),
    [results, scope, isSingleCategory, onAddNew],
  );

  const handleResultSelect = (e, { result }) => {
    if (result.type === "add_new") {
      onAddNew && onAddNew(value);
      return;
    }
    setValue(result.title);
    onChange && onChange(result);
  };

  const handleSearchChange = (e, { value }) => {
    setValue(value);
    if (!isEmpty(value)) fetchResults(value, scopeForUrl(scope));
  };

  const resultItemRenderer = useCallback(
    (props) => itemRenderer(props, value, newOptionRenderer),
    [value, newOptionRenderer],
  );

  return (
    <Search
      data-testid="SearchAndSelect"
      icon={<Icon icon="ri-search-line" size={size} />}
      className={searchAndSelectClassnames}
      category={!isSingleCategory}
      categoryLayoutRenderer={SearchCategoryLayout}
      loading={isFetching}
      onResultSelect={handleResultSelect}
      onSearchChange={debounce(handleSearchChange, 500, {
        leading: true,
      })}
      results={processedResults}
      value={value}
      resultRenderer={resultItemRenderer}
      placeholder={placeholder}
    />
  );
}

SearchAndSelect.propTypes = {
  scope: PropTypes.oneOfType([
    PropTypes.oneOf(Object.values(SearchScopes)),
    PropTypes.arrayOf(PropTypes.oneOf(singleScopes)),
  ]),
  fetchResults: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onAddNew: PropTypes.func,
  newOptionRenderer: PropTypes.func,
  initialSelection: PropTypes.shape({ title: PropTypes.string }),
  isFetching: PropTypes.bool,
  results: PropTypes.shape({}),
  size: PropTypes.string,
  placeholder: PropTypes.string,
};

SearchAndSelect.defaultProps = {
  scope: "all",
  onChange: null,
  onAddNew: null,
  newOptionRenderer: null,
  isFetching: false,
};
