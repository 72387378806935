export const AUTH_SUCCESS = "auth/AUTH_SUCCESS";
export const AUTH_ERROR = "auth/AUTH_ERROR";
export const ADD_LOGIN_VALIDATION_ERRORS = "auth/ADD_LOGIN_VALIDATION_ERRORS";
export const LOGOUT = "auth/LOGOUT";
export const VERIFY_CODE_START = "auth/VERIFY_CODE_START";
export const VERIFY_CODE_SUCCESS = "auth/VERIFY_CODE_SUCCESS";
export const VERIFY_CODE_ERROR = "auth/VERIFY_CODE_ERROR";
export const RESEND_CODE_START = "auth/RESEND_CODE_START";
export const RESEND_CODE_SUCCESS = "auth/RESEND_CODE_SUCCESS";
export const RESEND_CODE_ERROR = "auth/RESEND_CODE_ERROR";
export const INTERMEDIATE_AUTH_SUCCESS = "auth/INTERMEDIATE_AUTH_SUCCESS";
export const RECEIVE_USER = "auth/RECEIVE_USER";
export const FORGOT_PASSWORD_SUCCESS = "auth/FORGOT_PASSWORD_SUCCESS";
export const ADD_FORGOT_PASSWORD_VALIDATION_ERRORS =
  "auth/ADD_FORGOT_PASSWORD_VALIDATION_ERRORS";
export const RESET_PASSWORD_START = "auth/RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "auth/RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "auth/RESET_PASSWORD_ERROR";
export const VALIDATE_RESET_TOKEN_START = "auth/VALIDATE_RESET_TOKEN_START";
export const VALIDATE_RESET_TOKEN_SUCCESS = "auth/VALIDATE_RESET_TOKEN_SUCCESS";
export const VALIDATE_RESET_TOKEN_ERROR = "auth/VALIDATE_RESET_TOKEN_ERROR";
export const UPDATE_AUTH_FORM = "auth/UPDATE_AUTH_FORM";
export const RESET_AUTH = "auth/RESET_AUTH";
export const UPDATE_CODE_COMPLETED_STATUS = "auth/UPDATE_CODE_COMPLETED_STATUS";
export const ORGANIZATION_ID_INVITED = "auth/ORGANIZATION_ID_INVITED";
export const ACCEPT_ORGANIZATION_INVITED = "auth/ACCEPT_ORGANIZATION_INVITED";
export const CLEAR_ORGANIZATION_INVITED = "auth/CLEAR_ORGANIZATION_INVITED";

export function authSuccess(id, token, expires) {
  return { type: AUTH_SUCCESS, auth: { id, token, expires } };
}

export function authError(status, error) {
  return { type: AUTH_ERROR, payload: { status, error } };
}

export function authLogout() {
  return { type: LOGOUT };
}

export function addLoginValidationErrors(errors) {
  return { type: ADD_LOGIN_VALIDATION_ERRORS, errors };
}

export function addForgotPasswordValidationErrors(errors) {
  return { type: ADD_FORGOT_PASSWORD_VALIDATION_ERRORS, errors };
}

export function verifyCodeStart() {
  return { type: VERIFY_CODE_START };
}

export function verifyCodeSuccess() {
  return { type: VERIFY_CODE_SUCCESS };
}

export function verifyCodeError(message) {
  return { type: VERIFY_CODE_ERROR, message };
}

export function resendCodeStart() {
  return { type: RESEND_CODE_START };
}

export function resendCodeSuccess() {
  return { type: RESEND_CODE_SUCCESS };
}

export function resendCodeError() {
  return { type: RESEND_CODE_ERROR };
}

export function updateCodeCompletedStatus(isCodeCompleted) {
  return { type: UPDATE_CODE_COMPLETED_STATUS, isCodeCompleted };
}

export function intermediateAuthSuccess(intermediateToken, nextPath) {
  return { type: INTERMEDIATE_AUTH_SUCCESS, intermediateToken, nextPath };
}

export function receiveAuthorizedUser(user) {
  return { type: RECEIVE_USER, user };
}

export function forgotPasswordSuccess() {
  return { type: FORGOT_PASSWORD_SUCCESS };
}

export function resetPasswordStart() {
  return { type: RESET_PASSWORD_START };
}

export function resetPasswordSuccess() {
  return { type: RESET_PASSWORD_SUCCESS };
}

export function resetPasswordError(errors) {
  return { type: RESET_PASSWORD_ERROR, errors };
}

export function validateResetTokenStart() {
  return { type: VALIDATE_RESET_TOKEN_START };
}

export function validateResetTokenSuccess() {
  return { type: VALIDATE_RESET_TOKEN_SUCCESS };
}

export function validateResetTokenError() {
  return { type: VALIDATE_RESET_TOKEN_ERROR };
}

export function updateAuthForm(key, value) {
  return { type: UPDATE_AUTH_FORM, key, value };
}

export function resetAuth() {
  return { type: RESET_AUTH };
}

export function receiveOrganizationIdInvited(organizationIdInvited) {
  return { type: ORGANIZATION_ID_INVITED, organizationIdInvited };
}

export function receiveOrganizationInvitation(invitation) {
  return { type: ACCEPT_ORGANIZATION_INVITED, invitation };
}

export function clearOrganizationInvitation() {
  return { type: CLEAR_ORGANIZATION_INVITED };
}
