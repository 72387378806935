/* eslint react/prop-types: "off" */
import React from "react";
import Link from "components_new/atoms/Link";
import VerifiedBadge from "components/shared/VerifiedBadge";

const renderInvestmentLink = ({ investment, investmentLink, investmentName }) =>
  investment ? (
    <Link
      extraClassnames="u-link u-textHoverColor-utility-2 u-noTextDecoration"
      to={investmentLink}
    >
      {" "}
      {investmentName}{" "}
    </Link>
  ) : (
    <span className="ActivityEvent-removedInvestmentName">
      {investmentName}
    </span>
  );

const renderMovedAnInvestment = ({
  company: { verified },
  event: {
    params: {
      after,
      before,
      investment_name: investmentName,
      company_name: companyName,
    },
  },
}) => (
  <span>
    moved{" "}
    <span className="ActivityEvent-removedCompanyName CompanyNameWithBudge">
      {companyName} {verified && <VerifiedBadge />} · {investmentName}
    </span>{" "}
    from {before} to {after}
  </span>
);

const renderCovertedLoan = (
  {
    event: {
      params: { debt_name: investmentName, debt_id: debtId },
    },
    company: { id: companyId, name: companyName, verified },
    investment,
  },
  organizationUsername,
) => {
  const investmentLink = `/${organizationUsername}/investments/${debtId}`;
  return (
    <span>
      converted a note on{" "}
      <Link
        extraClassnames="u-link u-textHoverColor-utility-2 u-noTextDecoration CompanyNameWithBudge"
        to={`/${organizationUsername}/companies/${companyId}/investments`}
      >
        {companyName} {verified && <VerifiedBadge />}
      </Link>{" "}
      ·
      {renderInvestmentLink({
        investment,
        investmentLink,
        investmentName,
      })}
      :
    </span>
  );
};

const renderMovedDocument = (
  {
    event: {
      params: {
        previous_investment_id: previousInvestmentId,
        previous_investment_name: previousInvestmentName,
        new_investment_id: newInvestmentId,
        new_investment_name: newInvestmentName,
        previous_company_id: previousCompanyId,
        previous_company_name: previousCompanyName,
        new_company_id: newCompanyId,
        new_company_name: newCompanyName,
      },
    },
  },
  organizationUsername,
) => (
  <span>
    moved a document from{" "}
    <Link
      extraClassnames="u-link u-textHoverColor-utility-2 u-noTextDecoration"
      to={`/${organizationUsername}/companies/${previousCompanyId}`}
    >
      {previousCompanyName}
    </Link>
    <span className="u-link u-textHoverColor-utility-2 u-noTextDecoration">
      {" · "}
    </span>
    <Link
      extraClassnames="u-link u-textHoverColor-utility-2 u-noTextDecoration"
      to={`/${organizationUsername}/investments/${previousInvestmentId}`}
    >
      {previousInvestmentName}
    </Link>
    {" to "}
    <Link
      extraClassnames="u-link u-textHoverColor-utility-2 u-noTextDecoration"
      to={`/${organizationUsername}/companies/${newCompanyId}/investments`}
    >
      {newCompanyName}
    </Link>
    <span className="u-link u-textHoverColor-utility-2 u-noTextDecoration">
      {" · "}
    </span>
    <Link
      extraClassnames="u-link u-textHoverColor-utility-2 u-noTextDecoration"
      to={`/${organizationUsername}/investments/${newInvestmentId}`}
    >
      {newInvestmentName}
    </Link>
    :
  </span>
);

const renderUploadedDocuments = (
  {
    company: { verified },
    event: {
      params: {
        company_name: companyName,
        uploaded_files: uploadedFiles,
        investment_name: investmentName,
        investment_id: investmentId,
      },
      company_id: companyId,
    },
  },
  organizationUsername,
) => {
  const investmentLink = (
    <span>
      <span className="u-link u-textHoverColor-utility-2 u-noTextDecoration">
        {" · "}
      </span>
      <Link
        extraClassnames="u-link u-textHoverColor-utility-2 u-noTextDecoration"
        to={`/${organizationUsername}/investments/${investmentId}`}
      >
        {investmentName}
      </Link>
    </span>
  );
  return (
    <span>
      uploaded
      {uploadedFiles.length === 1
        ? " a new document "
        : ` ${uploadedFiles.length} new documents `}
      to{" "}
      <Link
        extraClassnames="u-link u-textHoverColor-utility-2 u-noTextDecoration CompanyNameWithBudge"
        to={`/${organizationUsername}/companies/${companyId}/investments`}
      >
        {companyName} {verified && <VerifiedBadge />}
      </Link>
      {investmentId && investmentLink}:
    </span>
  );
};

const renderRemovedDocuments = (
  {
    company: { verified },
    event: {
      params: {
        file_name: fileName,
        company_name: companyName,
        investment_id: investmentId,
        investment_name: investmentName,
      },
      company_id: companyId,
    },
    investment,
  },
  organizationUsername,
) => {
  const investmentLink = `/investments/${investmentId}`;
  if (!investmentId) {
    return (
      <span>
        deleted{" "}
        <span className="ActivityEvent-removedFilename">{fileName}</span> from{" "}
        <Link
          extraClassnames="u-link u-textHoverColor-utility-2 u-noTextDecoration CompanyNameWithBudge"
          to={`/${organizationUsername}/companies/${companyId}/investments`}
        >
          {companyName} {verified && <VerifiedBadge />}
        </Link>
      </span>
    );
  }
  return (
    <span>
      deleted <span className="ActivityEvent-removedFilename">{fileName}</span>{" "}
      from{" "}
      <Link
        extraClassnames="u-link u-textHoverColor-utility-2 u-noTextDecoration CompanyNameWithBudge"
        to={`/${organizationUsername}/companies/${companyId}/investments`}
      >
        {companyName} {verified && <VerifiedBadge />}
      </Link>{" "}
      ·
      {renderInvestmentLink({
        investment,
        investmentLink,
        investmentName,
      })}
    </span>
  );
};

const renderDeletedCompany = ({
  event: {
    params: { company_name: companyName },
  },
}) => {
  return (
    <span>
      deleted{" "}
      <span className="ActivityEvent-removedCompanyName">{companyName}</span>
    </span>
  );
};

const renderAddedToPortfolio = (
  {
    event: {
      params: {
        portfolio_name: portfolioName,
        portfolio_param: portfolioParam,
      },
    },
  },
  organizationUsername,
) => {
  return (
    <span>
      was added as a manager of{" "}
      <Link
        extraClassnames="u-link u-textHoverColor-utility-2 u-noTextDecoration"
        to={`/${organizationUsername}/portfolios/${portfolioParam}`}
      >
        {portfolioName}
      </Link>
    </span>
  );
};

const renderPurchasedCompany = ({
  event: {
    params: {
      acquired_company_name: acquiredCompanyName,
      buyer_company_name: buyerCompanyName,
    },
  },
}) => (
  <span>
    logged an acquisition:
    <span className="ActivityEvent-acquiredCompanyName">
      {" "}
      {acquiredCompanyName}{" "}
    </span>
    was acquired by
    <span className="ActivityEvent-buyerCompanyName">
      {" "}
      {buyerCompanyName}:{" "}
    </span>
  </span>
);

const renderAcquiredCompany = ({
  event: {
    params: {
      acquired_company_name: acquiredCompanyName,
      buyer_company_name: buyerCompanyName,
    },
  },
}) => {
  return (
    <span>
      logged an acquisition:
      <span className="ActivityEvent-buyerCompanyName">
        {" "}
        {buyerCompanyName}{" "}
      </span>
      acquired
      <span className="ActivityEvent-acquiredCompanyName">
        {" "}
        {acquiredCompanyName}{" "}
      </span>
    </span>
  );
};

const renderRemovedAllDocuments = (
  {
    company: { verified },
    event: {
      params: {
        removed_files: removedFiles,
        company_name: companyName,
        investment_id: investmentId,
        investment_name: investmentName,
      },
      company_id: companyId,
    },
    investment,
  },
  organizationUsername,
) => {
  const investmentLink = `/investments/${investmentId}`;
  return (
    <span>
      deleted
      <span className="ActivityEvent-removedDocumentName">
        {removedFiles.map((file, index, files) => {
          if (files.length !== index + 1) {
            return ` ${file.file_name},`;
          }
          return ` ${file.file_name} `;
        })}
      </span>
      from{" "}
      <Link
        extraClassnames="u-link u-textHoverColor-utility-2 u-noTextDecoration CompanyNameWithBudge"
        to={`/${organizationUsername}/companies/${companyId}/investments`}
      >
        {companyName} {verified && <VerifiedBadge />}
      </Link>
      {investmentId && " · "}
      {investmentId &&
        renderInvestmentLink({
          investment,
          investmentLink,
          investmentName,
        })}
    </span>
  );
};

const renderDeletedInvestment = (
  {
    company: { verified },
    event: {
      params: {
        investment_name: investmentName,
        investment_portfolio_name: portfolioName,
        investment_portfolio_id: portfolioId,
      },
    },
    company: { name: companyName, id: companyId },
  },
  organizationUsername,
) => (
  <span>
    deleted{" "}
    <span className=".ActivityEvent-removedInvestmentName">
      {investmentName} -
    </span>{" "}
    <Link
      extraClassnames="u-link u-textHoverColor-utility-2 u-noTextDecoration CompanyNameWithBudge"
      to={`/${organizationUsername}/companies/${companyId}/investments`}
    >
      {companyName}
      {verified && <VerifiedBadge />}
    </Link>{" "}
    from{" "}
    <Link
      extraClassnames="u-link u-textHoverColor-utility-2 u-noTextDecoration"
      to={`/${organizationUsername}/portfolios/${portfolioId}`}
    >
      {portfolioName}
    </Link>
  </span>
);

const renderCreatedValuation = ({ event: { params } }) => {
  return `updated a valuation for ${params.investment_name}:`;
};

const renderDeletedPortfolio = ({
  event: {
    params: { portfolio_name: portfolioName },
  },
}) => (
  <span>
    deleted{" "}
    <span className="ActivityEvent-removedPortfolioName">{portfolioName} </span>
  </span>
);

const renderAddedOrganizationMember = (
  {
    event: {
      params: { organization_name: organizationName },
    },
  },
  organizationUsername,
) => (
  <span>
    was added to the organization{" "}
    <Link
      extraClassnames="u-link u-textHoverColor-utility-2 u-noTextDecoration"
      to={`/${organizationUsername}/dashboard`}
    >
      {organizationName}
    </Link>
  </span>
);

const renderDeletedOrganizationMember = (
  {
    event: {
      params: { organization_name: organizationName },
    },
  },
  organizationUsername,
) => (
  <span>
    was removed from the organization{" "}
    <Link
      extraClassnames="u-link u-textHoverColor-utility-2 u-noTextDecoration"
      to={`/${organizationUsername}/dashboard`}
    >
      {organizationName}
    </Link>
  </span>
);

const activities = {
  addedANote: "added a note:",
  addedToPortfolio: renderAddedToPortfolio,
  capitalCall: "logged a capital call from a holding:",
  createdInvestment: "created a holding:",
  createdValuation: renderCreatedValuation,
  companyDeleted: renderDeletedCompany,
  deletedCapitalCall: "deleted a capital call from an investment:",
  updatedCapitalCall: "edited a capital call entry for a holding:",
  deletedValuation: "deleted a valuation entry for a holding:",
  updatedValuation: "edited a valuation entry for a holding:",
  movedAnInvestment: renderMovedAnInvestment,
  updatedInvestment: "updated a holding:",
  saleLogged: "logged a sale:",
  addedCompanyField: "updated a company detail:",
  removedCompanyField: "removed a company detail:",
  changedCompanyField: "updated a company detail:",
  convertedLoan: renderCovertedLoan,
  addedANoteToCompany: "added a note:",
  movedDocument: renderMovedDocument,
  updatedDocument: "updated a document:",
  uploadedDocuments: renderUploadedDocuments,
  removedDocuments: renderRemovedDocuments,
  purchasedCompany: renderPurchasedCompany,
  acquiredCompany: renderAcquiredCompany,
  removedAllDocuments: renderRemovedAllDocuments,
  deletedInvestment: renderDeletedInvestment,
  deletedPortfolio: renderDeletedPortfolio,
  loggedDistribution: "logged a distribution:",
  loggedAcquisition: "logged an acquisition:",
  organizationUsernameUpdated: "updated the organization username:",
  organizationNameUpdated: "updated the organization name:",
  addOrganizationMember: renderAddedOrganizationMember,
  deletedOrganizationMember: renderDeletedOrganizationMember,
  writtenOff: "logged a write off:",
};

const renderMessage = (type) => (values, organizationUsername) => {
  if (typeof activities[type] === "string") {
    return activities[type];
  }
  return activities[type](values, organizationUsername);
};

export default renderMessage;
