import React, { useEffect } from "react";
import Input from "components_new/atoms/Input";
import DatePicker from "components_new/atoms/DatePicker";
import CurrencyInput from "components_new/atoms/CurrencyInput";
import ConversionDetails from "../DebtForm/ConversionDetails";

function SAFEInvestmentDetails({ formData, handleChange, errors }) {
  const { name, date, total_acquisition_amount, is_safe } = formData;

  useEffect(() => {
    if (!is_safe) {
      handleChange("is_safe", true);
    }

    return () => handleChange("is_safe", false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="SAFEInvestmentDetails">
      <Input
        name="name"
        label="Investment Name"
        placeholder="Add a name"
        value={name}
        onChange={(_, { value }) => {
          handleChange("name", value);
        }}
        isRequired
        focus
        size="tiny"
        error={!!errors.for("name")}
        errorMessage={errors.for("name")}
      />
      <DatePicker
        name="date"
        label="Issuance Date"
        onChange={(value, validated) => handleChange("date", value, validated)}
        value={date}
        prefillToday={true}
        required
        size="tiny"
      />
      <CurrencyInput
        name="total_acquisition_amount"
        label="SAFE amount*"
        placeholder="0.00"
        decimalLimit={2}
        value={total_acquisition_amount}
        onChange={(value) => {
          handleChange("total_acquisition_amount", value);
        }}
        size="tiny"
        iconPosition="left"
        error={!!errors.for("total_acquisition_amount")}
        errorMessage={errors.for("total_acquisition_amount")}
      />
      <ConversionDetails formData={formData} handleChange={handleChange} />
    </div>
  );
}

export default SAFEInvestmentDetails;
