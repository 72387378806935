import React, { Fragment } from "react";
import formatPrice from "lib/formatters/formatPrice";
import formatNumber from "lib/formatters/formatNumber";
import { getText } from "TermsMapper";
import { investmentDataPropType, stockSplitPropType } from "PropTypes";
import "./StockSplitRatioDescription.scss";

const invalidRatio = (ratio) => {
  return [null, "", 0].includes(ratio);
};

const invalidQuantity = (investment, formData) => {
  return newQuantity(investment, formData) < 1;
};

const newQuantity = (investment, formData) => {
  return Math.floor(
    investment.quantity * (formData.split_ratio_to / formData.split_ratio_from),
  );
};

const newPricePerUnit = (investment, formData) => {
  return investment.cost_basis / newQuantity(investment, formData);
};

const filledRatios = (investment, formData) => {
  const { split_ratio_from, split_ratio_to } = formData;
  if (
    invalidRatio(split_ratio_from) ||
    invalidRatio(split_ratio_to) ||
    invalidQuantity(investment, formData)
  ) {
    return false;
  }
  return true;
};

const stockSplitType = (formData) => {
  if (formData.split_ratio_from < formData.split_ratio_to) {
    return "Forward Split.";
  }
  return "Reverse Split.";
};

function StockSplitRatioDescription({ investment, formData }) {
  const description = filledRatios(investment, formData) && investment.type && (
    <p>
      <span className="RatioType">{stockSplitType(formData)}</span>
      {`Your number of ${getText(
        investment.type,
        "per_unit_label",
      )} will be updated to ${formatNumber(
        newQuantity(investment, formData),
      )}. The current price ${getText(
        investment.type,
        "quantity_label",
      )} will be updated to ${formatPrice(
        newPricePerUnit(investment, formData),
      )}. The value of your investment remains the same at ${formatPrice(
        investment.cost_basis,
      )}.`}
    </p>
  );
  return (
    <Fragment>
      {description && <div className="RatioDescription">{description}</div>}
    </Fragment>
  );
}

StockSplitRatioDescription.propTypes = {
  investment: investmentDataPropType.isRequired,
  formData: stockSplitPropType.isRequired,
};

export default StockSplitRatioDescription;
