import React, { useCallback, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import usePrevious from "lib/hooks/usePrevious";
import "./ModalDialog.scss";
import { AnimatePresence, motion } from "framer-motion";
import CloseIcon from "-!svg-react-loader?!assets/icons/utility/close.svg?name=CloseIcon";

let isReady = false;
let rootElement = null;
let portalRootElement = null;

const ESCAPE_KEY_CODE = 27;

function ModalOverlay({
  children,
  closeModal,
  shouldCloseOnOverlayClick,
  shouldCloseOnEscape,
}) {
  const overlayRef = useRef();

  const handleClick = useCallback(
    (event) => {
      const isOnOverlay = event.target === overlayRef.current;
      if (shouldCloseOnOverlayClick && isOnOverlay) {
        closeModal();
      }
    },
    [shouldCloseOnOverlayClick, closeModal],
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (shouldCloseOnEscape && event.which === ESCAPE_KEY_CODE) {
        closeModal();
      }
    },
    [shouldCloseOnEscape, closeModal],
  );

  useEffect(() => {
    document.body.addEventListener("keydown", handleKeyDown);
    return () => {
      document.body.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <motion.div
      ref={overlayRef}
      className="ModalOverlay"
      onClick={handleClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ type: "tween", duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
}

function CloseButton({ closeModal }) {
  return (
    <button
      aria-label="Close Modal"
      className="ModalDialogClose"
      onClick={closeModal}
    >
      <CloseIcon />
    </button>
  );
}

function ModalDialogLayout({ children, closeModal }) {
  return (
    <motion.div
      className="ModalDialogLayout"
      initial={{ y: "15%" }}
      animate={{ y: "0%" }}
      exit={{ y: "15%" }}
    >
      <CloseButton closeModal={closeModal} />
      {children}
    </motion.div>
  );
}

function ModalDialog({
  isOpen,
  closeModal,
  children,
  closeOnEscape = true,
  closeOnOverlayClick = true,
}) {
  const previousIsOpen = usePrevious(isOpen);

  useEffect(() => {
    if (isOpen !== previousIsOpen && isReady) {
      if (isOpen) {
        rootElement.setAttribute("aria-hidden", true);
        rootElement.setAttribute("tabindex", "-1");
        portalRootElement.focus();
      } else {
        rootElement.removeAttribute("aria-hidden");
        rootElement.removeAttribute("tabindex");
      }
    }
  }, [isOpen, previousIsOpen]);

  if (!isReady) {
    throw new Error("You should call ModalDialog.setup");
  }

  return createPortal(
    <AnimatePresence initial={true}>
      {isOpen ? (
        <ModalOverlay
          shouldCloseOnEscape={closeOnEscape}
          shouldCloseOnOverlayClick={closeOnOverlayClick}
          closeModal={closeModal}
        >
          <ModalDialogLayout closeModal={closeModal}>
            {children}
          </ModalDialogLayout>
        </ModalOverlay>
      ) : null}
    </AnimatePresence>,
    portalRootElement,
  );
}

function createPortalElement() {
  const portalElement = document.createElement("div");
  portalElement.setAttribute("id", "portal");

  return portalElement;
}

ModalDialog.setup = ({ rootElement: givenRootElement }) => {
  rootElement = givenRootElement;
  portalRootElement = createPortalElement();
  rootElement.parentNode.append(portalRootElement);
  isReady = true;
};

export default ModalDialog;
