import React, { Fragment } from "react";
import {
  Actions,
  Amount,
  Body,
  DetailsList,
  Masthead,
  Notes,
  PreviousHolding,
} from "../layout";
import CompanyLogo from "components/shared/CompanyLogo";
import { InvestmentAmount, ObtainedText } from "../components";
import { detailFieldsByInvestment } from "../config";
import getLlcQuantityValue from "lib/getLlcQuantityValue";

function LlcDetails({
  company,
  investment,
  openReferrerModal,
  organizationUsername,
  openContactDetailsView,
}) {
  const {
    membership_units_or_percentage,
    notes,
    previous_holding,
    type,
  } = investment;

  let quantityValue = getLlcQuantityValue(investment);

  let detailsList;
  const detailFields = detailFieldsByInvestment[type];

  if (membership_units_or_percentage) {
    detailsList = {
      simple: ["total_acquisition_amount", ...detailFields.simple],
      long: [...detailFields.long],
    };
  }

  return (
    <Fragment>
      <Actions investment={investment} company={company} />
      <Body>
        <CompanyLogo
          company={company}
          extraClassnames="InvestmentDetails-companyLogo"
          organizationUsername={organizationUsername}
        />
        <Masthead investment={investment} company={company} />
        {previous_holding && <PreviousHolding />}
        <Amount>
          <InvestmentAmount
            obtainedText={<ObtainedText investment={investment} />}
            quantity={quantityValue}
          />
        </Amount>
        <DetailsList
          investment={investment}
          list={detailsList}
          openReferrerModal={openReferrerModal}
          openContactDetailsView={openContactDetailsView}
        />
        <Notes notes={notes} />
      </Body>
    </Fragment>
  );
}

export default LlcDetails;
