import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logWriteOff } from "store/investments/v2/writeOffV2/actions";

export default () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentOrganizationUsername = useSelector(
    (state) => state.organizations.currentOrganizationUsername,
  );

  const createWriteOff = (formData, investmentId) =>
    dispatch(logWriteOff(formData, investmentId)).then(() => {
      navigate(`/${currentOrganizationUsername}/investments/${investmentId}`);
    });

  return { createWriteOff };
};
