import React, { useMemo } from "react";
import VerticalSummaryCard from "components_new/elements/VerticalSummaryCard";
import ListValue from "components/shared/ListValue";
import ContactFiles from "./ContactFiles";
import ContactReferrals from "./ContactReferrals";
import ContactRoles from "./ContactRoles";
import ContactDiscussions from "./ContactDiscussions";
import ContactIntroductions from "./ContactIntroductions";
import ContactActionsDropdown from "components_new/views/Company/shared/ContactActionsDropdown";
import ContactDiligenceNotes from "./ContactDiligenceNotes";
import Header from "components_new/atoms/Header";
import Icon from "components_new/atoms/Icon";
import "./ContactDetailsView.scss";

function getContactAttributes(contact) {
  return [
    { icon: "ri-links-line", label: contact.email },
    { icon: "ri-map-pin-2-line", label: contact.location },
    { icon: "ri-home-line", label: contact.role },
    {
      icon: "ri-price-tag-3-line",
      label: contact.tags?.length > 0 && (
        <ListValue list={contact.tags} attribute="name" />
      ),
    },
  ];
}

function ContactDescription({ contact }) {
  const roles = useMemo(() => {
    const primaryRole = contact.roles.find((role) => role.is_primary);

    if (primaryRole) {
      const titles = primaryRole.company_titles
        .map((title) => title.name)
        .join(", ");

      return `${titles}, ${primaryRole.company.name}`;
    }
  }, [contact.roles]);

  return (
    <dl className="ContactDescription">
      {roles && <dt className="roles">{roles}</dt>}
      {contact.tagline && <dt className="tagline">{contact.tagline}</dt>}
    </dl>
  );
}

function ContactDetailsView({
  contact = {},
  closeSidebar,
  updateContactDocument,
  removeContactDocument,
  saveContactDocuments,
  organizationUsername,
  currentUser,
  users,
  resource,
  isSubmitting = false,
  createPost,
  updatePost,
  removePost,
  openDocumentsUploader,
}) {
  const contactAttributes = getContactAttributes(contact);
  const tier = contact.tier ? `Tier ${contact.tier}` : "";

  return (
    <div className="ContactDetailsView">
      <div className="ContactActions">
        <Header as="h3">{contact.full_name}</Header>
        <Icon
          icon="ri-close-line"
          size="large"
          extraClassnames="Sidebar-close"
          onClick={closeSidebar}
        />
        <ContactActionsDropdown
          contact={contact}
          dropdownType="button"
          callback={closeSidebar}
        />
      </div>
      <div className="ContactInformation">
        <VerticalSummaryCard
          className="ContactSummaryCard"
          title={contact.full_name}
          attributes={contactAttributes}
          initials={contact.initials}
          label={tier}
          labelColor="generic"
          description={<ContactDescription contact={contact} />}
          tooltip={false}
          circular
        />
        <ContactRoles roles={contact.roles} />
        <ContactDiligenceNotes
          diligenceNotes={contact.deal_diligence_notes}
          organizationUsername={organizationUsername}
        />
        <ContactReferrals
          referrals={contact.referrals}
          organizationUsername={organizationUsername}
        />
        <ContactIntroductions introductions={contact.introductions} />
        <ContactFiles
          documents={contact.documents}
          updateContactDocument={updateContactDocument}
          removeContactDocument={removeContactDocument}
          saveContactDocuments={saveContactDocuments}
          currentOrganizationUsername={organizationUsername}
          contactId={contact.id}
        />
        <ContactDiscussions
          organizationUsername={organizationUsername}
          currentUser={currentUser}
          users={users}
          posts={contact.posts}
          resource={resource}
          isSubmitting={isSubmitting}
          createPost={createPost}
          updatePost={updatePost}
          removePost={removePost}
          openDocumentsUploader={openDocumentsUploader}
          resource={resource}
          showResource={false}
        />
      </div>
    </div>
  );
}

export default ContactDetailsView;
