import Api from "lib/Api";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";

export function useDeletePortfolio() {
  const { organizationUsername } = useParams();
  const queryClient = useQueryClient();

  return useMutation(
    (portfolioId) => Api.delete(`/portfolios/${portfolioId}`),
    {
      onSuccess: (_, portfolioId) => {
        const key = ["Portfolios", organizationUsername];
        const portfoliosView = queryClient.getQueryData(key);

        if (portfoliosView?.portfolios) {
          const updatedPortfolios = portfoliosView.portfolios.filter(
            (portfolio) => portfolio.id !== portfolioId,
          );

          queryClient.setQueryData(key, {
            ...portfoliosView,
            portfolios: updatedPortfolios,
          });
        }
      },
      onError: (error) => {
        throw error;
      },
    },
  );
}
