import React, { Fragment } from "react";
import SearchWrapper from "components/shared/form/SearchWrapper";
import SearchInput from "./SearchInput";
import SelectList from "./SelectList";
import FormElement from "components/shared/form/FormElement";
import PropTypes from "prop-types";
import classNames from "classnames";

function SearchAndSelect({
  prefilledValue,
  performSearch,
  list,
  clearSearch,
  errorMessage,
  label,
  onChange,
  count,
  maxHeight,
  placeHolder,
  isFetching,
  name,
  disabled,
  extraClassnames,
  newOptionComponent,
  actionWhenEmpty,
  errorDisplayValidation,
  readOnly,
  allowCreation,
  onChangeOnCreation,
  size,
}) {
  const componentClassName = classNames("SearchAndSelect", {
    [extraClassnames]: !!extraClassnames,
    [size]: !!size,
  });
  return (
    <FormElement
      name={name}
      label={label}
      extraClassnames={componentClassName}
      errorMessage={errorMessage}
      errorDisplayValidation={errorDisplayValidation}
    >
      <SearchWrapper
        onChange={performSearch}
        onReset={clearSearch}
        maxHeight={maxHeight}
        prefilledValue={prefilledValue}
        disabled={disabled}
        actionWhenEmpty={actionWhenEmpty}
        allowCreation={allowCreation}
      >
        {(wrapperProps) => (
          <Fragment>
            <SearchInput
              {...wrapperProps}
              placeHolder={placeHolder}
              onItemChange={onChange}
              isFetching={isFetching}
              name={name}
              disabled={disabled}
              readOnly={readOnly}
              allowCreation={allowCreation}
              onChangeOnCreation={onChangeOnCreation}
              size={size}
            />
            {wrapperProps.open && !isFetching && (
              <Fragment>
                <SelectList
                  {...wrapperProps}
                  count={count}
                  list={list}
                  hasNewOption={!!newOptionComponent}
                />
                {newOptionComponent &&
                  !allowCreation &&
                  newOptionComponent(
                    wrapperProps.value,
                    wrapperProps.selectCustomItem,
                  )}
              </Fragment>
            )}
          </Fragment>
        )}
      </SearchWrapper>
    </FormElement>
  );
}

SearchAndSelect.defaultProps = {
  prefilledValue: null,
  selectedItem: {},
  disabled: false,
  errorMessage: null,
  readOnly: false,
  allowCreation: false,
};

SearchAndSelect.propTypes = {
  prefilledValue: PropTypes.shape({}),
  performSearch: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placeHolder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  extraClassnames: PropTypes.string,
  size: PropTypes.string,
  newOptionComponent: PropTypes.func,
  actionWhenEmpty: PropTypes.func,
  errorDisplayValidation: PropTypes.func,
  allowCreation: PropTypes.bool,
  onChangeOnCreation: PropTypes.func,
  list: PropTypes.arrayOf(PropTypes.shape({})),
};

export default SearchAndSelect;
