export const CLEAN_FORM = "shared/CLEAN_FORM";
export const RECEIVE_COMPANY = "shared/RECEIVE_COMPANY";
export const INSERT_COMPANY = "shared/INSERT_COMPANY";
export const RECEIVE_DASHBOARD_INVESTMENTS =
  "shared/RECEIVE_DASHBOARD_INVESTMENTS";
export const RECEIVE_EVENTS = "shared/RECEIVE_EVENTS";
export const RECEIVE_INVESTMENT = "shared/RECEIVE_INVESTMENT";
export const RECEIVE_INVESTMENT_VALUATIONS =
  "shared/RECEIVE_INVESTMENT_VALUATIONS";
export const RECEIVE_PORTFOLIO = "shared/RECEIVE_PORTFOLIO";
export const RECEIVE_PORTFOLIOS = "shared/RECEIVE_PORTFOLIOS";
export const RECEIVE_USER = "shared/RECEIVE_USER";
export const RECEIVE_USERS = "shared/RECEIVE_USERS";
export const REQUEST_COMPANY = "shared/REQUEST_COMPANY";
export const REQUEST_EVENTS = "shared/REQUEST_EVENTS";
export const REQUEST_PORTFOLIOS = "shared/REQUEST_PORTFOLIOS";
export const REQUEST_USERS = "shared/REQUEST_USERS";
export const REQUEST_OWNED_PORTFOLIOS = "shared/REQUEST_OWNED_PORTFOLIOS";
export const RECEIVE_OWNED_PORTFOLIOS = "shared/RECEIVE_OWNED_PORTFOLIOS";

export function receivePortfolio(id, response) {
  const {
    portfolioView,
    portfolio,
    companies,
    investments,
    documents,
  } = response.data;
  return {
    type: RECEIVE_PORTFOLIO,
    payload: {
      portfolioView,
      portfolio,
      companies,
      investments,
      documents,
    },
  };
}

export function requestPortfolios() {
  return { type: REQUEST_PORTFOLIOS };
}

export function receivePortfolios(portfolios) {
  return { type: RECEIVE_PORTFOLIOS, portfolios };
}

export function requestEvents() {
  return { type: REQUEST_EVENTS };
}

export function receiveEvents(events) {
  return { type: RECEIVE_EVENTS, events };
}

export function requestUsers() {
  return { type: REQUEST_USERS };
}

export function receiveUsers(users) {
  return { type: RECEIVE_USERS, users };
}

export function receiveUser(user) {
  return { type: RECEIVE_USER, user };
}

export function requestCompany(companyId) {
  return { type: REQUEST_COMPANY, companyId };
}

export function receiveCompany({
  company,
  portfolio_ids,
  portfolios,
  investments,
  documents,
  acquiring_companies,
  acquired_companies,
  fully_diluted_shares,
  is_fully_diluted_shares_current,
  valuation,
  is_valuation_current,
  current_investments_count,
  oldest_investment_date,
  shares_owned,
  capital_invested,
  current_market_value,
  gain,
  payout,
  realized_gain,
  total_gain,
  cost_basis,
  price_per_share,
  multiple,
  fully_diluted_shares_percentage,
  last_updated_investment_date,
}) {
  return {
    type: RECEIVE_COMPANY,
    company,
    portfolios,
    investments,
    documents,
    portfolioIds: portfolio_ids,
    acquiringCompanies: acquiring_companies,
    acquiredCompanies: acquired_companies,
    fullyDilutedShares: fully_diluted_shares,
    isFullyDilutedSharesCurrent: is_fully_diluted_shares_current,
    valuation,
    isValuationCurrent: is_valuation_current,
    currentHoldings: current_investments_count,
    investorSince: oldest_investment_date,
    sharesOwned: shares_owned,
    capitalInvested: capital_invested,
    currentMarketValue: current_market_value,
    unrealizedGain: gain,
    realizedGain: realized_gain,
    payout,
    totalReturn: total_gain,
    costBasis: cost_basis,
    pricePerShare: price_per_share,
    multiple: multiple,
    fullyDilutedSharesPercentage: fully_diluted_shares_percentage,
    lastUpdatedInvestmentDate: last_updated_investment_date,
  };
}

export function insertCompany(company) {
  return { type: INSERT_COMPANY, company };
}

export function receiveInvestment(investment) {
  return { type: RECEIVE_INVESTMENT, investment };
}

export function cleanForm() {
  return { type: CLEAN_FORM };
}

export function requestOwnedPortfolios() {
  return { type: REQUEST_OWNED_PORTFOLIOS };
}

export function receiveOwnedPortfolios(ownedPortfolios) {
  return { type: RECEIVE_OWNED_PORTFOLIOS, ownedPortfolios };
}

export function receiveDashboardInvestments(dashboardInvestments) {
  return { type: RECEIVE_DASHBOARD_INVESTMENTS, dashboardInvestments };
}
