import React from "react";
import PropTypes from "prop-types";

function HelperMessage({ text }) {
  return (
    <div className="HelperMessage" data-testid="HelperMessage">
      {text}
    </div>
  );
}

HelperMessage.propTypes = {
  text: PropTypes.string,
};

export default HelperMessage;
