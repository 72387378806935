import React from "react";
import DropdownForm from "components_new/atoms/DropdownForm";
import "./InterestingReason.scss";

const SUGGESTIONS = [
  "Up & Coming",
  "Hot List / Break-Out",
  "ROI $",
  "Interesting Technology",
  "Realized M&A Returns",
].map((item) => ({ key: item, text: item, value: item }));

function InterestingReason({ handleChange, size, interestingReason }) {
  return (
    <div className="InterestingReason">
      <DropdownForm
        label="Interesting Reason"
        search
        fluid
        placeholder="Select interesting reason"
        value={interestingReason}
        items={SUGGESTIONS}
        onChange={(_, { value }) => handleChange("interesting_reason", value)}
        size={size}
      />
    </div>
  );
}

export default InterestingReason;
