import React, { useState, useEffect } from "react";
import FormGroup from "components_new/elements/FormGroup";
import Form from "components_new/atoms/Form";
import DropdownForm from "components_new/atoms/DropdownForm";
import FormFooter from "components_new/SidebarComponents/SidebarForm/FormFooter";
import RequiredNote from "components_new/SidebarComponents/shared/RequiredNote";
import SearchSelect from "components_new/elements/SearchSelect";
import TextArea from "components_new/atoms/TextArea";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import getMessage from "components_new/lib/Messages";
import useForm from "lib/hooks/useForm";
import "./IntroductionForm.scss";

const purposeDropdownItem = [
  { key: 0, text: "Fundraising", value: "Fundraising" },
  { key: 1, text: "Corp Dev / M&A", value: "Corp Dev / M&A" },
  { key: 2, text: "Biz Dev", value: "Biz Dev" },
  { key: 3, text: "Recruiting", value: "Recruiting" },
  { key: 4, text: "Operations / Advice", value: "Operations / Advice" },
  { key: 5, text: "PR & Marketing", value: "PR & Marketing" },
  {
    key: 6,
    text: "Real Estate / Office Space",
    value: "Real Estate / Office Space",
  },
  { key: 7, text: "Other", value: "Other" },
];

const statusDropdownItem = [
  { key: 8, text: "Requested", value: "Requested" },
  { key: 9, text: "Made", value: "Made" },
  { key: 10, text: "Rejected", value: "Rejected" },
];

const initialValues = {
  formData: {
    requested_by_id: null,
    introduction_to_id: null,
    additional_introduction_to_id: null,
    purpose: null,
    status: null,
    description: "",
    made_by_id: null,
  },
};

function IntroductionForm({
  users,
  onCreate,
  ownName,
  closeSidebar,
  searchContact,
}) {
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useSetToast();

  const onSubmit = (clientData) => {
    setIsLoading(true);
    onCreate(clientData)
      .then(
        () => {
          closeSidebar();
          addToast(getMessage("createIntroduction"));
        },
        (errors) => {
          handleErrors(errors);
          handleScrollView(errors);
        },
      )
      .finally(() => setIsLoading(false));
  };

  const {
    formData,
    handleChange,
    handleSubmit,
    handleErrors,
    handleScrollView,
    errors,
  } = useForm(initialValues, onSubmit);

  useEffect(() => {
    if (
      formData.requested_by_id &&
      formData.introduction_to_id &&
      formData.purpose &&
      formData.status &&
      formData.made_by_id
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [
    formData.requested_by_id,
    formData.introduction_to_id,
    formData.purpose,
    formData.status,
    formData.made_by_id,
  ]);

  return (
    <Form className="IntroductionForm">
      <FormGroup title="Introduction Details" isRequired isExpanded={true}>
        <SearchSelect
          name="requested_by_id"
          label="Requested by *"
          placeholder="Select a contact"
          scope="contacts"
          onChange={(value) => handleChange("requested_by_id", value)}
          onFilter={searchContact}
          errorMessage={errors.for("requested_by")}
        />
        <SearchSelect
          name="introduction_to_id"
          label="Introduction to *"
          placeholder="Select a contact"
          scope="contacts"
          onChange={(value) => handleChange("introduction_to_id", value)}
          onFilter={searchContact}
          errorMessage={errors.for("introduction_to")}
        />
        <SearchSelect
          name="additional_introduction_to_id"
          label="Aditional introduction to"
          placeholder="Select a contact"
          scope="contacts"
          onChange={(value) =>
            handleChange("additional_introduction_to_id", value)
          }
          onFilter={searchContact}
          errorMessage={errors.for("aditional_introduction_to")}
        />
        <DropdownForm
          name="purpose"
          label="Purpose"
          placeholder="Select a purpose"
          items={purposeDropdownItem}
          onChange={(_, selection) => handleChange("purpose", selection.value)}
          required
          size="tiny"
          error={!!errors.for("purpose")}
          errorMessage={errors.for("purpose")}
        />
        <DropdownForm
          name="status"
          label="Status"
          placeholder="Select a status"
          items={statusDropdownItem}
          onChange={(_, selection) => handleChange("status", selection.value)}
          required
          size="tiny"
          error={!!errors.for("status")}
          errorMessage={errors.for("status")}
        />
        <TextArea
          name="description"
          label="Description"
          placeholder="Add description"
          value={formData.description}
          onChange={(_, { value }) => {
            handleChange("description", value);
          }}
          size="tiny"
        />
        <DropdownForm
          name="status"
          label="Made by"
          placeholder="Select a user"
          items={users}
          onChange={(_, selection) =>
            handleChange("made_by_id", selection.value)
          }
          required
          fluid
          search
          selection
          size="tiny"
          error={!!errors.for("made_by_id")}
          errorMessage={errors.for("made_by_id")}
        />
      </FormGroup>
      <RequiredNote />
      <FormFooter
        data={formData}
        componentName={ownName}
        isLoading={isLoading}
        onSubmit={handleSubmit}
        disableSubmit={isDisabled}
        submitTextButton="Save Introduction"
      />
    </Form>
  );
}

export default IntroductionForm;
