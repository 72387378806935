import isEmpty from "lodash/isEmpty";

const baseReferralLogic = (formData) => {
  const { name } = formData;
  if (isEmpty(name)) {
    return formData;
  }

  if (name.includes("@")) {
    formData.email = name;
  } else {
    const words = name.trim().split(" ");
    const [firstWord, ...lastWords] = words;

    const firstName = [firstWord, ...lastWords.slice(0, -1)].join(" ");
    const lastName = lastWords.slice(-1).pop();

    formData.first_name = firstName;
    formData.last_name = lastName ? lastName : null;
  }

  return formData;
};

export default baseReferralLogic;
