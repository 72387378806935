import React, { Fragment } from "react";
import {
  Actions,
  Amount,
  Body,
  DetailsList,
  Masthead,
  Notes,
  PreviousHolding,
} from "../layout";
import CompanyLogo from "components/shared/CompanyLogo";
import { InvestmentAmount, ObtainedText } from "../components";
import formatNumber from "lib/formatters/formatNumber";
import { getText } from "TermsMapper";

function OptionDetails({
  investment,
  company,
  openReferrerModal,
  organizationUsername,
  openContactDetailsView,
}) {
  const { quantity, type } = investment;
  const quantityValue = `${formatNumber(quantity)} ${getText(
    type,
    "per_unit_label",
  )}`;

  return (
    <Fragment>
      <Actions investment={investment} company={company} />
      <Body>
        <CompanyLogo
          company={company}
          extraClassnames="InvestmentDetails-companyLogo"
          organizationUsername={organizationUsername}
        />
        <Masthead investment={investment} company={company} />
        {investment.previous_holding && <PreviousHolding />}
        <Amount>
          <InvestmentAmount
            obtainedText={<ObtainedText investment={investment} />}
            quantity={quantityValue}
          />
        </Amount>
        <DetailsList
          investment={investment}
          openReferrerModal={openReferrerModal}
          openContactDetailsView={openContactDetailsView}
        />
        <Notes notes={investment.notes} />
      </Body>
    </Fragment>
  );
}

export default OptionDetails;
