import { useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { getCollection, getCollectionAsObject } from "store/entities/selectors";
import { getCollection as getDomainCollection } from "store/domain/selectors";
import {
  createCompany as createCompanyAction,
  updateCompany as updateCompanyAction,
  updateCompanyV2 as updateCompanyV2Action,
  fetchCompanyV2 as fetchCompanyAction,
} from "./actions";
import { filterTag } from "store/tags/actions";
import { isEqual, debounce } from "lodash";

export default () => {
  const [isLoadingCompany, setIsLoadingCompany] = useState(false);
  const dispatch = useDispatch();
  const view = useSelector((state) => state.views.company, shallowEqual);

  const deals = useSelector(
    (state) => getCollection(state, "deals", view.deal_ids),
    isEqual,
  );
  const dealsContacts = useSelector(
    (state) => getCollectionAsObject(state, "contacts", view.deals_contact_ids),
    isEqual,
  );
  const companyAvatars = useSelector(
    (state) =>
      getCollectionAsObject(state, "companyAvatars", view.company_avatar_ids),
    isEqual,
  );

  const tagSuggestions = useSelector(
    (state) => getDomainCollection(state, "tagSuggestions"),
    shallowEqual,
  );

  const filterTags = debounce((tagName) => {
    return dispatch(filterTag(tagName));
  }, 300);

  const fetchCompany = (companyId) => {
    setIsLoadingCompany(true);
    dispatch(fetchCompanyAction(companyId)).finally(() =>
      setIsLoadingCompany(false),
    );
  };

  const createCompany = (companyName) =>
    dispatch(createCompanyAction(companyName));

  const updateCompany = (companyId, formData) =>
    dispatch(updateCompanyAction(companyId, formData));

  const updateCompanyV2 = (companyId, formData) =>
    dispatch(updateCompanyV2Action(companyId, formData));

  return {
    deals,
    dealsContacts,
    companyAvatars,
    isLoadingCompany,
    fetchCompany,
    createCompany,
    updateCompany,
    updateCompanyV2,
    filterCompanyTags: filterTags,
    companyTagSuggestions: tagSuggestions,
  };
};
