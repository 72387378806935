import React, { Fragment } from "react";
import getHistoryMessages from "./getHistoryMessages";

export default function ObtainedText({ investment }) {
  const messages = getHistoryMessages(investment);

  if (messages.length === 0) {
    return null;
  }

  return (
    <Fragment>
      {messages.reduce((result, item) => (
        <Fragment>
          {result}
          {<span className="pipe">|</span>}
          {item}
        </Fragment>
      ))}
    </Fragment>
  );
}
