import React, { Fragment } from "react";

const COLUMN_HEADERS = {
  defaultTable: [
    {
      value: "company_name",
      skeletonType: "companyName",
      title: "COMPANY",
      sortOrder: "descending",
      type: "string",
    },
    {
      value: "name",
      skeletonType: "name",
      title: "NAME",
      type: "string",
      groupedValue: "investments_count",
      groupedValueType: "numeric",
    },
    {
      value: "previous_holding",
      skeletonType: "status",
      title: "STATUS",
      type: "boolean",
    },
    {
      className: "DateColumn u-textAlignRight",
      value: "date",
      skeletonType: "two_lines",
      title: "DATE",
      type: "date",
      groupedValue: "last_date",
    },
    {
      className: "NumericColumn u-textAlignRight",
      value: "quantity",
      sortOrder: "descending",
      skeletonType: "quantity",
      title: "QUANTITY",
      type: "numeric",
      groupedValue: "total_quantity",
    },
    {
      className: "NumericColumn u-textAlignRight",
      value: "cost_basis",
      sortOrder: "descending",
      skeletonType: "cost_basis",
      title: "COST BASIS",
      type: "numeric",
      groupedValue: "total_cost_basis",
    },
    {
      className: "NumericColumn u-textAlignRight",
      value: "total_valuation_amount",
      sortOrder: "descending",
      skeletonType: "total_valuation_amount",
      title: "FMV",
      hasTooltip: true,
      tooltipPosition: "top center",
      tooltipContent: "Fair Market Value",
      type: "numeric",
    },
    {
      className: "NumericColumn u-textAlignRight",
      value: "gain",
      skeletonType: "gain",
      sortOrder: "descending",
      title: "TOTAL GAIN/LOSS",
      hasTooltip: true,
      tooltipPosition: "top center",
      tooltipContent:
        "Gain/Loss is calculated as (Fair Market Value + Realized Gain) – Cost Basis",
      type: "numeric",
    },
  ],
  byCompanyTable: [
    {
      value: "company_name",
      skeletonType: "companyName",
      sortOrder: "descending",
      className: "AutoColumn",
      title: "COMPANY",
      type: "string",
    },
    {
      value: "investments_count",
      skeletonType: "investments_count",
      title: "NAME",
      type: "string",
      className: "AutoColumn",
      groupedValue: "investments_count",
      groupedValueType: "numeric",
    },
    {
      value: "previous_holding",
      skeletonType: "previous_holding",
      title: "STATUS",
      className: "ShortColumn",
      type: "boolean",
      groupedValue: "overall_status",
      groupedValueType: "numeric",
    },
    {
      className: "ShortColumn DateColumn u-textAlignRight",
      skeletonType: "last_date",
      value: "last_date",
      title: "DATE",
      type: "date",
      groupedValue: "last_date",
    },
    {
      className: "ShortColumn NumericColumn u-textAlignRight",
      value: "total_quantity",
      skeletonType: "total_quantity",
      title: "QUANTITY",
      type: "numeric",
      groupedValue: "total_quantity",
    },
    {
      className: "MediumColumn NumericColumn u-textAlignRight",
      skeletonType: "total_cost_basis",
      value: "total_cost_basis",
      title: "COST BASIS",
      type: "numeric",
      groupedValue: "total_cost_basis",
    },
    {
      className: "MediumColumn NumericColumn u-textAlignRight",
      value: "total_valuation_amount",
      skeletonType: "total_valuation_amount",
      title: "FMV",
      hasTooltip: true,
      tooltipPosition: "top center",
      tooltipContent: "Fair Market Value",
      type: "numeric",
    },
    {
      className: "MediumColumn NumericColumn u-textAlignRight",
      value: "gain",
      skeletonType: "gain",
      title: "GAIN/LOSS",
      hasTooltip: true,
      tooltipPosition: "top center",
      tooltipContent:
        "Gain/Loss is calculated as (Fair Market Value + Realized Gain) – Cost Basis",
      type: "numeric",
    },
  ],
  cashFlowTable: [
    {
      value: "company_name",
      skeletonType: "companyName",
      title: "COMPANY",
      type: "string",
    },
    {
      value: "transaction_name",
      skeletonType: "transaction_name",
      title: "NAME",
      type: "string",
    },
    {
      value: "transaction_type",
      skeletonType: "transaction_type",
      title: "EVENT",
      type: "string",
    },
    {
      className: "DateColumn u-textAlignRight",
      skeletonType: "date",
      skeletonClassName: "date-singleLine",
      value: "date",
      title: "DATE",
      type: "date",
    },
    {
      className: "NumericColumn u-textAlignRight",
      skeletonType: "amount",
      value: "amount",
      title: "AMOUNT",
      sortOrder: "descending",
      type: "numeric",
    },
    {
      className: "NumericColumn u-textAlignRight",
      skeletonType: "total",
      value: "total",
      title: "TOTAL",
      sortOrder: "descending",
      type: "numeric",
    },
  ],
  currentTable: [
    {
      value: "company_name",
      skeletonType: "companyName",
      title: "COMPANY",
      className: "AutoColumn",
      type: "string",
      sortOrder: "descending",
    },
    {
      value: "name",
      title: "NAME",
      skeletonType: "name",
      type: "string",
      className: "AutoColumn",
      groupedValue: "investments_count",
      groupedValueType: "numeric",
    },
    {
      className: "ShortColumn DateColumn u-textAlignRight",
      value: "date",
      skeletonType: "date",
      title: "DATE",
      type: "date",
      groupedValue: "last_date",
    },
    {
      className: "ShortColumn NumericColumn u-textAlignRight",
      value: "quantity",
      skeletonType: "quantity",
      sortOrder: "descending",
      title: "QUANTITY",
      type: "numeric",
      groupedValue: "total_quantity",
    },
    {
      className: "MediumColumn NumericColumn u-textAlignRight",
      skeletonType: "cost_basis",
      value: "cost_basis",
      sortOrder: "descending",
      title: "COST BASIS",
      type: "numeric",
      groupedValue: "total_cost_basis",
    },
    {
      className: "MediumColumn NumericColumn u-textAlignRight",
      value: "total_valuation_amount",
      skeletonType: "total_valuation_amount",
      sortOrder: "descending",
      title: "FMV",
      type: "numeric",
    },
    {
      className: "MediumColumn NumericColumn u-textAlignRight",
      value: "gain",
      skeletonType: "gain",
      sortOrder: "descending",
      title: (
        <Fragment>
          <div>UNREALIZED</div>
          <span> GAIN/LOSS</span>
        </Fragment>
      ),
      hasTooltip: true,
      tooltipPosition: "top center",
      tooltipContent:
        "Unrealized Gain/Loss is calculated as Fair Market Value – Cost Basis",
      type: "numeric",
    },
  ],
  exitsTable: [
    {
      value: "company_name",
      skeletonType: "companyName",
      title: "COMPANY",
      type: "string",
      className: "AutoColumn",
      sortOrder: "descending",
    },
    {
      value: "name",
      skeletonType: "name",
      title: "NAME",
      type: "string",
      className: "AutoColumn",
      groupedValue: "investments_count",
      groupedValueType: "numeric",
    },
    {
      className: "ShortColumn DateColumn u-textAlignRight",
      value: "date",
      skeletonType: "date",
      title: "DATE",
      type: "date",
      groupedValue: "last_date",
    },
    {
      className: "ShortColumn NumericColumn u-textAlignRight",
      value: "quantity",
      skeletonType: "quantity",
      sortOrder: "descending",
      title: "QUANTITY",
      type: "numeric",
      groupedValue: "total_quantity",
    },
    {
      className: "MediumColumn NumericColumn u-textAlignRight",
      value: "cost_basis",
      skeletonType: "cost_basis",
      sortOrder: "descending",
      title: "COST BASIS",
      type: "numeric",
      groupedValue: "total_cost_basis",
    },
    {
      className: "MediumColumn NumericColumn u-textAlignRight",
      value: "total_valuation_amount",
      skeletonType: "total_valuation_amount",
      sortOrder: "descending",
      title: "CASH PROCEEDS",
      type: "numeric",
    },
    {
      className: "MediumColumn NumericColumn u-textAlignRight",
      value: "adjustments",
      skeletonType: "adjustments",
      sortOrder: "descending",
      title: "ADJUSTMENTS",
      type: "numeric",
    },
    {
      className: "MediumColumn NumericColumn u-textAlignRight",
      value: "realized_gain",
      skeletonType: "realized_gain",
      sortOrder: "descending",
      title: (
        <Fragment>
          <div>REALIZED</div>
          <span> GAIN/LOSS</span>
        </Fragment>
      ),
      hasTooltip: true,
      tooltipPosition: "top center",
      tooltipContent:
        "Realized Gain/Loss is calculated as Realized Gain – Cost Basis",
      type: "numeric",
    },
  ],
};
export default function getColumnHeaders(originTable) {
  return COLUMN_HEADERS[originTable];
}
