import Api from "lib/Api";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { updateTaskInScope } from "../shared/updateTaskInScope";
import { removeTaskFromScope } from "../shared/removeTaskFromScope";

export function useSnoozeTask() {
  const queryClient = useQueryClient();
  const { organizationUsername } = useParams();

  return useMutation(
    ({ taskId, formData }) =>
      Api.patch(`/tasks/${taskId}/snooze`, {
        task: formData,
      }),
    {
      onSuccess: ({ data: updatedTask }) => {
        const params = {
          taskId: updatedTask.id,
          updatedTask,
          queryClient,
          organizationUsername,
        };

        updateTaskInScope({ scope: "snoozed", ...params });
        removeTaskFromScope({ scope: "pending", ...params });
        removeTaskFromScope({ scope: "completed", ...params });

        queryClient.invalidateQueries(["QueuesView", organizationUsername]);
      },
      onError: (error) => {
        throw error;
      },
    },
  );
}
