import Api from "lib/Api";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { updateTaskInScope } from "../shared/updateTaskInScope";

export function useMarkAsUnread() {
  const queryClient = useQueryClient();
  const { organizationUsername } = useParams();

  return useMutation(({ taskId }) => Api.patch(`/tasks/${taskId}/unread`), {
    onSuccess: ({ data: updatedTask }) => {
      const params = {
        updatedTask,
        queryClient,
        organizationUsername,
      };

      updateTaskInScope({ scope: "pending", ...params });
      updateTaskInScope({ scope: "snoozed", ...params });
      updateTaskInScope({ scope: "completed", ...params });

      queryClient.invalidateQueries(["QueuesView", organizationUsername]);
    },
    onError: (error) => {
      throw error;
    },
  });
}
