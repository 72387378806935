import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  ENTER_KEY,
  ESC_KEY,
  TAB_KEY,
  UP_ARROW_KEY,
  DOWN_ARROW_KEY,
} from "config/keyCodes";
import SelectPopup from "components/shared/form/select/SelectPopup";
import setIsNewOptionAsFalse from "lib/shared/form/select/setIsNewOptionAsFalse";
import { inputPropsPropType, selectOptionPropType } from "PropTypes";
import findOptionIndex from "lib/shared/form/select/findOptionIndex";
import scrollToOption from "lib/shared/form/select/scrollToOption";
import isOptionEnabled from "lib/shared/form/select/isOptionEnabled";
import { isEmpty, lowerCase } from "lodash";

function findFirstLetterCoincidenceInOptions(
  letterToMatch,
  options,
  selectedItem,
) {
  const allFirstLetterCoincidences = options.filter((option) => {
    return (
      isOptionEnabled(option) &&
      lowerCase(option.label.charAt(0)) === letterToMatch
    );
  });

  if (isEmpty(allFirstLetterCoincidences)) {
    return null;
  }

  const totalCoincidences = allFirstLetterCoincidences.length;
  const currentMatchedItemIndex = selectedItem
    ? allFirstLetterCoincidences.findIndex(
        (item) => item.value === selectedItem.value,
      )
    : -1;
  const newMatchedItemIndex = currentMatchedItemIndex + 1;
  const newMatchedItem =
    newMatchedItemIndex >= totalCoincidences
      ? allFirstLetterCoincidences[0]
      : allFirstLetterCoincidences[newMatchedItemIndex];

  return newMatchedItem;
}

class SelectPopupManager extends Component {
  static propTypes = {
    autocomplete: PropTypes.bool.isRequired,
    autoFocus: PropTypes.bool,
    children: PropTypes.node.isRequired,
    changeFocusOnEnterOrEscape: PropTypes.bool,
    lastQuery: PropTypes.string,
    extraClassnames: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    options: inputPropsPropType.isRequired,
    openWithKeyDown: PropTypes.bool.isRequired,
    popupProps: PropTypes.object, //eslint-disable-line react/forbid-prop-types
    preSelectedOption: selectOptionPropType,
    selectedOption: selectOptionPropType,
    showNewOption: PropTypes.bool,
    tabIndex: PropTypes.string,
    toggleSelect: PropTypes.func.isRequired,
    updateDisplayValue: PropTypes.func.isRequired,
    updatePreSelectedOption: PropTypes.func.isRequired,
    updateSelectedOption: PropTypes.func.isRequired,
  };

  static defaultProps = {
    autoFocus: false,
    extraClassnames: null,
    preSelectedOption: null,
    selectedOption: null,
    showNewOption: false,
    changeFocusOnEnterOrEscape: true,
  };

  keyDownActions = {
    [ENTER_KEY]: (event) => this.handleEnterSelect(event),
    [TAB_KEY]: (event) => this.handleTabSelect(event),
    [ESC_KEY]: (event) => this.handleEscapeSelect(event),
    [UP_ARROW_KEY]: (event) => this.handleGoUpInSelect(event),
    [DOWN_ARROW_KEY]: (event) => this.handleGoDownInSelect(event),
  };
  selectPopupManagerRef = null;
  selectPopupRef = null;
  selectOptionRef = null;

  handleKeyDown = (event) => {
    const { keyCode } = event;
    const action = this.keyDownActions[keyCode];
    if (action) {
      action(event);
    } else {
      this.selectByFirstLetter(keyCode);
    }
  };

  moveSelection(direction) {
    const newSelectedOption = this.getNewSelectedOptionByDirection(direction);
    if (newSelectedOption) {
      this.updateSelectionFromGivenOption(newSelectedOption, direction);
    }
  }

  componentDidMount() {
    const { autoFocus } = this.props;

    if (autoFocus) {
      // TODO: find a better way to focus the select when it is inside a modal
      // without using setTimeout. CSS animation of the modal might have something
      // to do with this issue.
      setTimeout(() => {
        this.setFocusOnSelectPopupManager();
      }, 500);
    }
  }

  getNewSelectedOptionByDirection(direction) {
    const { options, lastQuery } = this.props;
    const createNewOption = {
      value: null,
      isNewOption: true,
      label: lastQuery,
    };

    if (direction === "down" && isEmpty(options) && lastQuery.trim() !== "") {
      return createNewOption;
    }

    const { showNewOption, preSelectedOption } = this.props;
    const isMoveUp = direction === "up";
    // TODO: Find a max position based on max option enabled.
    const maxOptionPosition =
      options.filter((item) => isOptionEnabled(item)).length - 1;
    const step = isMoveUp ? -1 : 1;
    const currentSelectionPosition =
      preSelectedOption && preSelectedOption.value !== null
        ? findOptionIndex(preSelectedOption, options)
        : -1;
    const newSelectionPosition = parseInt(currentSelectionPosition, 10) + step;
    const newSelectedOption = options[newSelectionPosition];

    if (newSelectedOption && isOptionEnabled(newSelectedOption)) {
      return newSelectedOption;
    }

    const resetOptionPosition = isMoveUp ? maxOptionPosition : 0;
    const resetOption = options[resetOptionPosition];
    const shouldSelectCreateNewOption =
      showNewOption &&
      newSelectionPosition !== resetOptionPosition &&
      newSelectionPosition !== -2;

    return shouldSelectCreateNewOption ? createNewOption : resetOption;
  }

  getPreSelectedOptionOrNewOption() {
    const { preSelectedOption, lastQuery, autocomplete } = this.props;

    if (preSelectedOption) {
      return preSelectedOption;
    }

    if (autocomplete && lastQuery && lastQuery.trim() !== "") {
      return {
        value: null,
        label: lastQuery,
        isNewOption: true,
      };
    }

    return null;
  }

  handleEnterSelect(event) {
    const {
      isOpen,
      toggleSelect,
      updateSelectedOption,
      changeFocusOnEnterOrEscape,
    } = this.props;
    if (isOpen) {
      const selectedOption = this.getPreSelectedOptionOrNewOption();
      updateSelectedOption(selectedOption);
      toggleSelect(false);
      if (changeFocusOnEnterOrEscape) {
        this.setFocusOnSelectPopupManager();
      }
    }
    event.preventDefault();
  }

  handleTabSelect() {
    const { isOpen, toggleSelect, updateSelectedOption } = this.props;
    if (isOpen) {
      const selectedOption = this.getPreSelectedOptionOrNewOption();
      updateSelectedOption(selectedOption);
      toggleSelect(false);
    }
  }

  handleEscapeSelect() {
    this.props.toggleSelect(false);
    if (this.props.changeFocusOnEnterOrEscape) {
      this.setFocusOnSelectPopupManager();
    }
  }

  handleGoUpInSelect(event) {
    this.moveSelection("up");
    event.preventDefault();
  }

  handleGoDownInSelect(event) {
    const { isOpen, toggleSelect, openWithKeyDown } = this.props;
    if (!isOpen && openWithKeyDown) {
      toggleSelect(true);
    } else {
      this.moveSelection("down");
    }
    event.preventDefault();
  }

  setFocusOnSelectPopupManager() {
    if (this.selectPopupManagerRef) {
      this.selectPopupManagerRef.focus();
    }
  }

  selectByFirstLetter(keyCode) {
    if (this.props.autocomplete) {
      return;
    }

    const { preSelectedOption, updatePreSelectedOption } = this.props;
    const allowedCharactersRegex = /[\da-z]/;
    const letterToMatch = String.fromCharCode(keyCode).toLowerCase();

    if (!letterToMatch.match(allowedCharactersRegex)) {
      return;
    }

    const { options } = this.props;
    const optionCoincidence = findFirstLetterCoincidenceInOptions(
      letterToMatch,
      options,
      preSelectedOption,
    );

    if (!optionCoincidence) {
      return;
    }

    const optionCoincidenceWithNewOptionFalse = setIsNewOptionAsFalse(
      optionCoincidence,
    );

    updatePreSelectedOption(optionCoincidenceWithNewOptionFalse, () =>
      scrollToOption({
        selectPopupRef: this.selectPopupRef,
        selectOptionRef: this.preSelectedOptionRef,
        selectedOption: optionCoincidenceWithNewOptionFalse,
        options,
      }),
    );
  }

  scrollToSelectedOption() {
    const { options, selectedOption } = this.props;
    scrollToOption({
      selectPopupRef: this.selectPopupRef,
      selectOptionRef: this.preSelectedOptionRef,
      options,
      selectedOption: selectedOption,
    });
  }

  updateSelectionFromGivenOption(option, direction = "down") {
    const {
      autocomplete,
      updatePreSelectedOption,
      isOpen,
      updateSelectedOption,
      updateDisplayValue,
      options,
    } = this.props;
    if (isOpen) {
      updatePreSelectedOption(option, () => {
        if (autocomplete) {
          updateDisplayValue(option.label);
        }
        scrollToOption({
          selectPopupRef: this.selectPopupRef,
          selectOptionRef: this.preSelectedOptionRef,
          direction,
          selectedOption: option,
          options,
        });
      });
    } else if (!autocomplete) {
      updateSelectedOption(option);
    }
  }

  render() {
    const {
      children,
      extraClassnames,
      isOpen,
      lastQuery,
      options,
      popupProps,
      showNewOption,
      tabIndex,
    } = this.props;

    const selectPopupManagerClassNames = classNames({
      [extraClassnames]: !!extraClassnames,
      isOpen: isOpen,
    });
    return (
      <div
        className={selectPopupManagerClassNames}
        onKeyDown={this.handleKeyDown}
        ref={(selectPopupManager) =>
          (this.selectPopupManagerRef = selectPopupManager)
        }
        tabIndex={tabIndex}
      >
        {children}
        <SelectPopup
          {...popupProps}
          lastQuery={lastQuery}
          isOpen={isOpen}
          options={options}
          showNewOption={showNewOption}
          preSelectedOptionRef={(preSelectedOptionRef) =>
            (this.preSelectedOptionRef = preSelectedOptionRef)
          }
          ref={(selectPopup) => (this.selectPopupRef = selectPopup)}
        />
      </div>
    );
  }
}

export default SelectPopupManager;
