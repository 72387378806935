import Api from "lib/Api";
import { useQuery } from "react-query";

export function usePipeline(pipelineId) {
  return useQuery(
    ["Pipeline", pipelineId],
    async () => {
      const { data } = await Api.get(`/v2/pipelines/${pipelineId}`);
      const [pipeline] = data.pipeline;

      return {
        pipeline,
        stages: data.stages,
        activeDealsCount: data.active_deals_count,
        passedDealsCount: data.passed_deals_count,
        committedDealsCount: data.committed_deals_count,
      };
    },
    {
      enabled: !!pipelineId,
      staleTime: 10000,
    },
  );
}
