import React from "react";
import investmentDetailLabelsAndContent from "lib/investments/investmentDetailLabelsAndContent";

const InvestmentDetailItemLong = ({ investment, detailName }) => {
  const { label, content } = investmentDetailLabelsAndContent[detailName];
  return (
    <div className="InvestmentDetailItem">
      <div className="InvestmentDetailItem-inner">
        <div className="InvestmentDetailItem-title">{label}</div>
        <div className="InvestmentDetailItem-content">
          <span>{content(investment, detailName)}</span>
        </div>
      </div>
    </div>
  );
};

export default InvestmentDetailItemLong;
