import React from "react";
import FormGroup from "components_new/elements/FormGroup";
import CurrencyInput from "components_new/atoms/CurrencyInput";
import NumberInput from "components_new/atoms/NumberInput";

function FundAdditionalDetails({ formData, handleChange }) {
  const today = new Date();
  let fundVintageYear = today.getFullYear();
  const { fund_size, fund_vintage } = formData;

  return (
    <FormGroup
      title="Fund Details"
      extraClassnames="FundAdditionalDetails"
      isExpanded={false}
      required={false}
      noBottomBorder
      subGroup
    >
      <CurrencyInput
        name="fund_size"
        label="Fund size"
        placeholder="e.g. 100"
        decimalLimit={2}
        defaultValue={fund_size}
        value={fund_size}
        size="small"
        onChange={(value) => {
          handleChange("fund_size", value);
        }}
        size="tiny"
        iconPosition="left"
      />
      <NumberInput
        name="fund_vintage"
        label="Fund vintage"
        placeholder="YYYY"
        onChange={(value) => {
          handleChange("fund_vintage", value);
        }}
        value={fund_vintage}
        defaultValue={fund_vintage ? fund_vintage : fundVintageYear}
        maxLength={4}
        includeThousandsSeparator={false}
        size="tiny"
      />
    </FormGroup>
  );
}

export default FundAdditionalDetails;
