import React, { useCallback } from "react";
import ActiveDealContent from "./ActiveDealContent";
import useOrganizations from "store/organizations/hooks";
import useSidebar from "store/sidebar/hooks";
import { useParams, useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { openModal } from "store/modalV2";
import { useDeal } from "components_new/views/Deals/hooks/queries/useDeal";
import { useUpdateDeal } from "components_new/views/Deals/hooks/mutations/useUpdateDeal";
import { useUpdateDealStage } from "components_new/views/Deals/hooks/mutations/useUpdateDealStage";
import { useSaveDealDocument } from "components_new/views/Deals/hooks/mutations/useSaveDealDocument";
import { useUpdateDealDocument } from "components_new/views/Deals/hooks/mutations/useUpdateDealDocument";
import { useRemoveDealDocument } from "components_new/views/Deals/hooks/mutations/useRemoveDealDocument";
import { useCompleteTask } from "components_new/views/Queues/hooks/mutations/useCompleteTask";
import { useMarkAsPending } from "components_new/views/Queues/hooks/mutations/useMarkAsPending";
import { useSetSidebar } from "components_new/elements/SidebarWrapper/Sidebar-context";
import useCurrentUser from "hooks/useCurrentUser";
import getMessage from "components_new/lib/Messages";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";

function buildOptionsFromStages(stages) {
  return stages.map((stage) => ({
    key: stage.id,
    value: stage.id,
    text: stage.name,
    order: stage.order,
  }));
}

function ActiveDealContentWrapper() {
  const { id: selectedDealId, noteId } = useParams();
  const { openSidebar } = useSidebar();
  const { addToast } = useSetToast();

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showSidebar } = useSetSidebar();
  const {
    currentOrganization: { username: orgUsername, pipeline_id: pipelineId },
  } = useOrganizations();
  const { id: currentUserID } = useCurrentUser();

  const { data, isLoading } = useDeal(pipelineId, selectedDealId);

  const { mutateAsync: updateDeal } = useUpdateDeal();
  const { mutateAsync: updateDealStage } = useUpdateDealStage();
  const { mutateAsync: saveDealDocument } = useSaveDealDocument();
  const { mutateAsync: updateDealDocument } = useUpdateDealDocument();
  const { mutateAsync: removeDealDocument } = useRemoveDealDocument();
  const { mutateAsync: completeTask } = useCompleteTask();
  const { mutateAsync: markAsPending } = useMarkAsPending();

  const invalidateQueriesCache = useCallback(() => {
    queryClient.invalidateQueries(["ActiveDeals", pipelineId, {}]);
    queryClient.invalidateQueries(["Deal", selectedDealId]);
  }, [pipelineId, queryClient, selectedDealId]);

  const openContactDetailsView = (contact) => {
    showSidebar("contactsView", { contactId: contact.id });
  };

  const navigateToActiveDeals = useCallback(() => {
    navigate(`/${orgUsername}/deals/active`);
  }, [navigate, orgUsername]);

  const handleOpenEditCompanyForm = useCallback(
    (companyId, companyName) =>
      openSidebar("globalCompanyForm", `Edit ${companyName}`, {
        entityId: companyId,
        callback: invalidateQueriesCache,
      }),
    [invalidateQueriesCache, openSidebar],
  );

  const handleOpenEditDealForm = useCallback(
    () =>
      showSidebar("dealForm", {
        dealId: selectedDealId,
      }),
    [showSidebar, selectedDealId],
  );

  const handleOpenDeleteDealModal = useCallback(
    (dealId) =>
      dispatch(
        openModal("removeDeal", {
          dealId,
          pipelineId,
          callback: navigateToActiveDeals,
        }),
      ),
    [dispatch, navigateToActiveDeals, pipelineId],
  );

  const handleOpenPassFromDealModal = useCallback(
    (dealId) =>
      dispatch(
        openModal("passFromDeal", {
          dealId,
          pipelineId,
          callback: navigateToActiveDeals,
        }),
      ),
    [dispatch, navigateToActiveDeals, pipelineId],
  );

  const handleOpenCommitToDealModal = useCallback(
    (dealId) =>
      dispatch(
        openModal("commitToDeal", {
          dealId,
          pipelineId,
          callback: navigateToActiveDeals,
        }),
      ),
    [dispatch, navigateToActiveDeals, pipelineId],
  );

  const handleUpdateDealDocument = (document) => {
    return updateDealDocument({ document, pipelineId, dealId: selectedDealId });
  };

  const handleRemoveDealDocument = (document) => {
    return removeDealDocument({ document, pipelineId, dealId: selectedDealId });
  };

  const handleOpenTaskForm = (task) =>
    showSidebar("taskForm", {
      task,
      prefilledSelection: {
        id: selectedDealId,
        type: "deal",
        name: data?.deal.title,
      },
      prefilledOwner: currentUserID,
    });

  const handleCompleteTask = async (taskId) => {
    await completeTask({ taskId: taskId });
    addToast(getMessage("completeTask"));
  };

  const handlePendingTask = async (taskId) => {
    await markAsPending({ taskId: taskId });
    addToast(getMessage("pendingTask"));
  };

  return (
    <ActiveDealContent
      isLoading={isLoading}
      pipelineId={pipelineId}
      deal={data?.deal}
      stage={data?.stage}
      contacts={data?.contacts}
      company={data?.company}
      companyAvatars={data?.companyAvatars}
      companyHoldingTotals={data?.companyHoldingTotals}
      investments={data?.companyInvestments}
      documents={data?.documents}
      tasks={data?.deal?.tasks}
      stagesOptions={buildOptionsFromStages(data?.stages || [])}
      updateDeal={updateDeal}
      updateDealStage={updateDealStage}
      saveDealDocuments={saveDealDocument}
      updateDealDocument={handleUpdateDealDocument}
      removeDealDocument={handleRemoveDealDocument}
      openEditCompanyForm={handleOpenEditCompanyForm}
      openEditDealForm={handleOpenEditDealForm}
      openDeleteDealModal={handleOpenDeleteDealModal}
      openPassFromDealModal={handleOpenPassFromDealModal}
      openCommitToDealModal={handleOpenCommitToDealModal}
      openContactDetailsView={openContactDetailsView}
      onCompleteDealTask={handleCompleteTask}
      onMovePendingDealTask={handlePendingTask}
      openTaskForm={handleOpenTaskForm}
      currentOrganizationUsername={orgUsername}
      noteToHighlight={noteId}
      posts={data?.posts}
    />
  );
}

export default ActiveDealContentWrapper;
