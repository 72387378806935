import React from "react";
import { Link } from "react-router-dom";
import { TableRow, TableCell } from "components_new/elements/Table";
import formatDate from "lib/formatDate";
import Value from "components_new/atoms/Value";
import SubValue from "components_new/atoms/SubValue";
import ContentWithTooltip from "components_new/elements/ContentWithTooltip";
import Tooltip from "components_new/atoms/Tooltip/Tooltip";
import GainLossArrow from "components_new/elements/GainLossArrow";
import ColumnSection from "../../shared/ColumnSection";
import FMVSubvalue from "../../shared/FMVSubvalue";
import NoClosingDocIndicator from "components_new/elements/NoClosingDocIndicator";
import PropTypes from "prop-types";
import { companyAvatarPropType, investmentPropType } from "PropTypes";

function ChildInvestmentRow({
  className,
  investment,
  orgUsername,
  showClosingDocuments,
}) {
  const investmentGain = investment.previous_holding
    ? investment.realized_gain
    : investment.gain;

  const hideClosingDocIndicator =
    !showClosingDocuments && investment.has_closing_documents;

  return (
    <TableRow key={investment.id} className={className}>
      <TableCell className="EmptyCell"></TableCell>
      <TableCell className="EmptyCell"></TableCell>
      <TableCell className="InvestmentNameColumn u-textWithEllipsis">
        <div className="InvestmentNameColumn-content">
          <span className="InvestmentNameColumn-text">
            <ContentWithTooltip
              tooltip={{
                content: investment.name,
                position: "top center",
                disabled: false,
              }}
            >
              <Link to={`/${orgUsername}/investments/${investment.id}`}>
                {investment.name}
              </Link>
            </ContentWithTooltip>
            <SubValue>{investment.type_label}</SubValue>
          </span>
          {hideClosingDocIndicator && <NoClosingDocIndicator />}
        </div>
      </TableCell>
      <TableCell>
        {investment.previous_holding ? "Exited" : "Current"}
      </TableCell>
      <TableCell className="DateColumn">
        {formatDate(investment.date)}
        <SubValue extraClassName="u-capitalize">
          {investment.acquired_type}
        </SubValue>
      </TableCell>
      <TableCell className="NumericColumn">
        <ColumnSection isNotApplicable={investment.previous_holding}>
          <Value type="quantity">{investment.quantity}</Value>
          {investment.fully_diluted_shares_percentage && (
            <Tooltip
              position="top center"
              trigger={
                <span>
                  <SubValue>
                    <Value type="percentage">
                      {investment.fully_diluted_shares_percentage}
                    </Value>{" "}
                    FD
                  </SubValue>
                </span>
              }
              content="% Fully diluted"
            />
          )}
        </ColumnSection>
      </TableCell>
      <TableCell className="NumericColumn">
        <Value type="currency" precision="2">
          {investment.cost_basis}
        </Value>
        {investment.unit_cost_basis && (
          <SubValue>
            <Value type="currency" precision="2">
              {investment.unit_cost_basis}
            </Value>{" "}
            / {investment.unit}
          </SubValue>
        )}
      </TableCell>
      <TableCell className="NumericColumn">
        <ColumnSection isNotApplicable={investment.previous_holding}>
          <Value type="currency" precision="2">
            {investment.total_valuation_amount}
          </Value>
          <FMVSubvalue investment={investment} />
        </ColumnSection>
      </TableCell>
      <TableCell className="NumericColumn GainLossColumn">
        <Value type="currency" precision="2">
          {investmentGain}
        </Value>
        <GainLossArrow gain={investmentGain} />
        <SubValue>
          <Value type="multiple">{investment.multiple}</Value>
        </SubValue>
      </TableCell>
    </TableRow>
  );
}

ChildInvestmentRow.propTypes = {
  investment: investmentPropType,
  company: companyAvatarPropType,
  orgUsername: PropTypes.string,
  className: PropTypes.string,
  showClosingDocuments: PropTypes.bool,
};

export default ChildInvestmentRow;
