import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import VerifyCodeForm from "components/auth/VerifyCodeForm";
import CreateUserForm from "components/auth/invitationToOrganization/newUser/CreateUserForm";

class InvitationOrganizationNewUser extends Component {
  state = {
    isVerifyCodeStep: false,
  };

  handleUserCreated = () => {
    this.setState({ isVerifyCodeStep: true });
  };

  render() {
    const { isVerifyCodeStep } = this.state;
    const {
      verifyCode,
      resendCode,
      organizationName,
      createAccountFromInvitation,
      inviteCode,
      organizationMembershipId,
      email,
      isRevokedInvite,
      onComeFromNewUserClick,
    } = this.props;
    const title = isVerifyCodeStep ? "Verify Login" : "Welcome!";
    return (
      <Fragment>
        <h1 className="u-heading LoginAnimatedElement">{title}</h1>
        {isVerifyCodeStep ? (
          <VerifyCodeForm verifyCode={verifyCode} resendCode={resendCode} />
        ) : (
          <CreateUserForm
            email={email}
            organizationName={organizationName}
            onCreateAccountClick={createAccountFromInvitation}
            inviteCode={inviteCode}
            organizationMembershipId={organizationMembershipId}
            onUserCreated={this.handleUserCreated}
            isRevokedInvite={isRevokedInvite}
            onComeFromNewUserClick={onComeFromNewUserClick}
          />
        )}
      </Fragment>
    );
  }
}

InvitationOrganizationNewUser.propTypes = {
  email: PropTypes.string,
  organizationName: PropTypes.string,
  inviteCode: PropTypes.string.isRequired,
  organizationMembershipId: PropTypes.number,
  verifyCodeError: PropTypes.bool,
  validateInviteCode: PropTypes.func,
  createAccountFromInvitation: PropTypes.func,
  verifyCode: PropTypes.func,
  resendCode: PropTypes.func,
  isResendingCode: PropTypes.bool.isRequired,
  isRevokedInvite: PropTypes.bool.isRequired,
  onComeFromNewUserClick: PropTypes.func,
};

InvitationOrganizationNewUser.defaultProps = {
  email: "",
  organizationName: null,
  organizationMembershipId: null,
};

export default InvitationOrganizationNewUser;
