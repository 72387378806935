export const UPDATE_FORM = "signUpRequestForm/UPDATE_FORM";
export const SIGNUP_REQUEST_START = "signUpRequestForm/SIGNUP_REQUEST_START";
export const SIGNUP_REQUEST_SUCCESS =
  "signUpRequestForm/SIGNUP_REQUEST_SUCCESS";
export const SIGNUP_REQUEST_ERROR =
  "signUpRequestForm/CREATE_SIGNUP_REQUEST_ERROR";
export const RESET_SIGNUP_REQUEST_FORM =
  "signUpRequestForm/RESET_SIGNUP_REQUEST_FORM";

export function updateSignUpRequestForm(key, value) {
  return { type: UPDATE_FORM, key, value };
}

export function signUpRequestStart() {
  return { type: SIGNUP_REQUEST_START };
}

export function signUpRequestSuccess() {
  return { type: SIGNUP_REQUEST_SUCCESS };
}

export function signUpRequestError(errors) {
  return { type: SIGNUP_REQUEST_ERROR, errors };
}

export function resetSignUpRequestForm() {
  return { type: RESET_SIGNUP_REQUEST_FORM };
}
