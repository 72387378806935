import React from "react";
import ResultCard from "components_new/elements/ResultCard";
import EmptyResultView from "components/shared/EmptyResultView";
import PropTypes from "prop-types";
import { companyPropType, investmentPropType, dealProptype } from "PropTypes";

function SelectListGroup({ group, query, title, highlightItem, onClickItem }) {
  if (group.length === 0) {
    return null;
  }

  return (
    <div className="SelectListGroup">
      <h4 className="SelectListGroup-title">{title}</h4>
      <ul className="SelectListGroup-items">
        {group.map((item) => {
          return (
            <li
              key={item.id}
              className="SelectListGroup-item SearchWrapper-item"
              onClick={onClickItem}
              onMouseEnter={(event) => highlightItem(event.currentTarget)}
              data-prop={JSON.stringify(item)}
            >
              <ResultCard
                title={item.title}
                query={query}
                description={item.description}
                subdescription={item.subdescription}
                type={item.type}
                isVerified={item.verified}
                logoUrl={item.logo_url}
                initials={item.initials}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function SelectList({
  list,
  onClickItem,
  highlightItem,
  count,
  value,
  hasNewOption,
}) {
  return count == 0 && !hasNewOption ? (
    <EmptyResultView query={value} />
  ) : (
    <div className="SelectList">
      {list.map((item, key) => {
        return (
          <SelectListGroup
            key={key}
            group={item.data}
            title={item.title}
            onClickItem={onClickItem}
            highlightItem={highlightItem}
            query={value}
          />
        );
      })}
    </div>
  );
}

SelectList.propTypes = {
  companies: PropTypes.arrayOf(companyPropType),
  deals: PropTypes.arrayOf(dealProptype),
  investments: PropTypes.arrayOf(investmentPropType),
  onClickItem: PropTypes.func.isRequired,
  highlightItem: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
      title: PropTypes.title,
    }),
  ),
  count: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  hasNewOption: PropTypes.bool,
};

SelectList.defaultProps = {
  list: [],
};

export default SelectList;
