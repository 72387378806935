import { connect } from "react-redux";
import ReferrerModal from "./ReferrerModal";
import { closeModal } from "store/modalV2";
import { validateEmail } from "store/contacts/actions";
import { getCollection } from "store/domain/selectors";
import { debounce } from "lodash";
import { filterTag } from "store/tags/actions";
import { filterCompanyTitle } from "store/companyTitles/actions";

function mapStateToProps(state, ownProps) {
  return {
    tagSuggestions: getCollection(state, "tagSuggestions"),
    companyTitleSuggestions: getCollection(state, "companyTitleSuggestions"),
    initialCompany: ownProps.company,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => {
      dispatch(closeModal());
    },
    filterTag: debounce((tagName) => {
      return dispatch(filterTag(tagName));
    }, 300),
    filterCompanyTitle: debounce((companyTitle) => {
      return dispatch(filterCompanyTitle(companyTitle));
    }, 300),
    validateEmail: (email) => {
      return dispatch(validateEmail(email));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferrerModal);
