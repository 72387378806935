import { connect } from "react-redux";
import WeeklyReportDevTool from "./WeeklyReportDevTool";
import { sendWeeklyReport } from "store/devTools/actions";
import { flash } from "actions/synchronous/flash";

function mapDispatchToProps(dispatch) {
  return {
    sendWeeklyReport: () =>
      dispatch(sendWeeklyReport()).then(() =>
        dispatch(flash("sentWeeklyReport")),
      ),
  };
}

export default connect(null, mapDispatchToProps)(WeeklyReportDevTool);
