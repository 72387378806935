import formatDate from "lib/formatDate";

export function getCurrentActionMessage(historyAction, investment) {
  const currentActionFormattedDate = formatDate(historyAction.date, "ll");

  if (historyAction.type === "purchased") {
    if (investment.type === "fund") {
      return `Commitment on ${currentActionFormattedDate}`;
    } else {
      return `Purchased on ${currentActionFormattedDate}`;
    }
  }

  if (historyAction.type === "distributed") {
    return `Received from distribution on ${currentActionFormattedDate}`;
  }

  if (historyAction.type === "acquired") {
    return `Acquired on ${currentActionFormattedDate}`;
  }

  if (historyAction.type === "converted") {
    return `Converted on ${currentActionFormattedDate}`;
  }

  if (historyAction.type === "sold") {
    return `Results from sale on ${currentActionFormattedDate}`;
  }
}

export function getNextActionMessage(nextHistoryAction) {
  if (nextHistoryAction !== null) {
    const nextActionFormattedDate = formatDate(nextHistoryAction.date, "ll");
    // TODO: let's remove  it after implementing history actions for write_offs
    if (nextHistoryAction.type === "sold") {
      return `Sold on ${nextActionFormattedDate}`;
    }

    if (nextHistoryAction.type === "acquired") {
      return `Acquired by ${nextHistoryAction.action_params.acquiring_company_name} on ${nextActionFormattedDate}`;
    }

    if (nextHistoryAction.type === "converted") {
      return `Converted on ${nextActionFormattedDate}`;
    }
  }
}

export default function getHistoryMessages(investment) {
  const messages = [];
  const {
    history_actions: historyActions,
    previous_holding: previousHolding,
  } = investment;

  const currentHistoryAction = historyActions.find(
    (action) =>
      action.child_investment && action.child_investment.id == investment.id,
  );
  const currentActionMessage = getCurrentActionMessage(
    currentHistoryAction,
    investment,
  );

  if (currentActionMessage) {
    messages.push(currentActionMessage);
  }

  if (previousHolding) {
    const nextHistoryAction = historyActions.find(
      (action) =>
        action.parent_investment &&
        action.parent_investment.id == investment.id,
    );
    // TODO: let's remove  it after implementing history actions for write_offs
    if (nextHistoryAction !== undefined) {
      const nextActionMessage = getNextActionMessage(nextHistoryAction);
      if (nextActionMessage) {
        messages.push(nextActionMessage);
      }
    }
  }

  return messages;
}
