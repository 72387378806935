import formatNumber from "lib/formatters/formatNumber";
import formatPrice from "lib/formatters/formatPrice";
import formatPercentage from "lib/formatters/formatPercentage";
import { getText } from "TermsMapper";

export default function getLlcQuantityValue(investment) {
  const {
    membership_units_or_percentage,
    percentage_owned,
    quantity,
    total_acquisition_amount,
    type,
  } = investment;

  if (membership_units_or_percentage === "units") {
    return (
      quantity &&
      `${formatNumber(quantity)} ${getText(type, "valuation_unit_plural")}`
    );
  } else if (membership_units_or_percentage === "percentage") {
    return percentage_owned && formatPercentage(percentage_owned);
  } else if (!quantity) {
    return total_acquisition_amount && formatPrice(total_acquisition_amount);
  }
}
