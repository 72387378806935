import React, { Fragment } from "react";
import amountPerShareOrTotal from "config/amountPerShareOrTotalConfig";
import { placeholderDateFormat } from "config/dateInput";
import PriceInput from "components/shared/form/PriceInput";
import DatePicker from "components_new/atoms/DatePicker";
import NumberInput from "components/shared/form/NumberInput";
import HelperContent from "components/shared/HelperContent";
import Select from "components/shared/form/Select";
import { formatPriceInputPrecision } from "../../config";

function LogDistributionForm({ formData, updateForm, errors, historyAction }) {
  const {
    date_helper_text: dateHelperContent,
    quantity_helper_text: numberHelperContent,
  } = historyAction;
  const priceInputPrecision = formatPriceInputPrecision(
    formData.amount_per_quantity_or_total,
  );
  return (
    <Fragment>
      <DatePicker
        name="date"
        label="Date of distribution"
        placeholder={placeholderDateFormat}
        onChange={(value, validated) => updateForm("date", value, validated)}
        value={formData.date}
        helperContent={() => <HelperContent content={dateHelperContent} />}
        showHelper={!!dateHelperContent}
        extraClassnames="FormElement FormElement--Date"
        size="small"
        oldVersion
      />
      <NumberInput
        name="quantity"
        label="Number of Shares"
        onChange={(value) => updateForm("quantity", value)}
        errorMessage={errors.for("quantity")}
        value={formData.quantity}
        helperContent={() => <HelperContent content={numberHelperContent} />}
        errorDisplayValidation={() => !numberHelperContent}
        showHelper={!!numberHelperContent}
        size="small"
      />
      <div className="GroupInputs GroupInputs-quantityOrTotal small">
        <PriceInput
          extraClassnames="CompoundSelect-placeholder"
          name="total_acquisition_amount"
          label="Investment Amount"
          onChange={(value) => updateForm("per_share_or_total", value)}
          value={formData.per_share_or_total}
          errorMessage={errors.for("cost_basis")}
          errorDisplayValidation={() => false}
          precision={priceInputPrecision}
          size="small"
        />
        <Select
          extraClassnames="CompoundSelect-option"
          inputProps={amountPerShareOrTotal}
          onChange={(value) =>
            updateForm("amount_per_quantity_or_total", value)
          }
          value={formData.amount_per_quantity_or_total}
          size="small"
        />
        <p className="u-errorMessage QuantityErrorMessage">
          {errors.for("cost_basis")}
        </p>
      </div>
    </Fragment>
  );
}

export default LogDistributionForm;
