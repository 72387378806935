import React, { Fragment, useState } from "react";
import Input from "components_new/atoms/Input";
import PropTypes from "prop-types";
import preventerHandler from "lib/preventerHandler";
import Errors from "lib/errors";
import isServerValidationError from "lib/isServerValidationError";
import {
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "components_new/elements/Modal";
import { stageProptype, pipelineProptype } from "PropTypes";
import Form from "components_new/atoms/Form";
import actionFormType from "../shared/ActionFormType";

import Button from "components_new/atoms/Button";

const ActionsStageForm = ({
  pipeline: { id: pipelineId },
  stage: { id: stageId, name: currentName },
  submitForm,
  formType,
  submitting,
  closeModal,
}) => {
  const [name, setName] = useState(currentName);
  const [errors, setErrors] = useState(new Errors({}));
  const isDeleteAction = actionFormType.delete === formType;
  const disabled = submitting || (!isDeleteAction && name === currentName);

  let titleForm = "";
  switch (formType) {
    case actionFormType.create:
      titleForm = "Add Stage";
      break;
    case actionFormType.edit:
      titleForm = "Edit Stage Name";
      break;
    case actionFormType.delete:
      titleForm = "Delete Stage";
      break;
  }

  const handleSubmit = preventerHandler(() => {
    submitForm({ pipelineId, stageId, name }).catch((error) => {
      if (isServerValidationError(error)) {
        const { data } = error;
        setErrors(new Errors(data.errors));
      }
    });
  });
  const submitButtonText = isDeleteAction ? "Delete Stage" : "Save Changes";
  return (
    <Fragment>
      <ModalHeader content={titleForm} />

      <ModalContent extraClassnames="CommitToDealModal">
        <Form>
          {isDeleteAction && (
            <span className="DeleteStage-message">
              Deleting this stage will remove it from your organization's
              pipeline.
            </span>
          )}
          <Input
            type="text"
            value={name}
            disabled={isDeleteAction}
            onChange={(_, { value }) => setName(value)}
            errorMessage={errors.for("name")}
            autoFocus
          />
        </Form>
      </ModalContent>

      <ModalFooter extraClassnames="AddValuationModal" withBorder={true}>
        <Button
          primary
          type="submit"
          onClick={handleSubmit}
          disabled={disabled}
          loading={submitting}
        >
          {submitButtonText}
        </Button>
        <Button secondary onClick={closeModal} disabled={submitting}>
          Cancel
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

ActionsStageForm.propTypes = {
  submitting: PropTypes.bool,
  submitForm: PropTypes.func,
  stage: stageProptype,
  pipeline: pipelineProptype,
  formType: PropTypes.oneOf(Object.keys(actionFormType)),
  closeModal: PropTypes.func,
};

ActionsStageForm.defaultProps = {
  stage: { name: "" },
};

export default ActionsStageForm;
