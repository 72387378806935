import React from "react";
import { Header as SemanticHeader } from "@ableco/semantic-ui-react";
import classnames from "classnames";
import "semantic-ui-css/components/header.css";
import "./Header.scss";

function Header({ children, extraClassName, ...headerProps }) {
  const headerClassnames = classnames("Header", {
    [extraClassName]: !!extraClassName,
  });
  return (
    <SemanticHeader className={headerClassnames} {...headerProps}>
      {children}
    </SemanticHeader>
  );
}

export default Header;
