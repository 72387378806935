export const roundNameOptions = [
  "Bridge",
  "Convertible Note (Post)",
  "Convertible Note (Pre)",
  "Pre-seed",
  "SAFE",
  "SAFT",
  "Seed",
  "Seed Extension",
  "Series A",
  "Series B",
  "Series C",
  "Series D",
  "Series E",
  "Series Seed",
  "Token Sale",
];
