import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import Api from "lib/Api";

const urlByTab = {
  all: "",
  current: "/current",
  exits: "/previous",
};

function buildUrl(type, groupedByCompany) {
  const urlByType = urlByTab[type];
  const byCompany = groupedByCompany ? "/by_company" : "";

  return "/v2/investments" + urlByType + byCompany;
}

export function useInvestments(type, portfolioId, groupedByCompany, filters) {
  const { organizationUsername } = useParams();

  const url = buildUrl(type, groupedByCompany);
  const params = portfolioId === "all" ? {} : { portfolio_id: portfolioId };

  return useQuery(
    [
      "Investments",
      organizationUsername,
      type,
      portfolioId,
      { groupedByCompany, filters },
    ],
    async () => {
      const { data } = await Api.get(url, {
        params: { ...params, ...filters },
      });

      return {
        investments: data.investments,
        investmentsByCompany: data.investments_by_company,
        summaryStats: data.meta.summary_stats,
      };
    },
    {
      enabled: type !== "cashFlow",
      staleTime: 10000,
    },
  );
}
