import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "components/shared/form/Select";
import FormElement from "components/shared/form/FormElement";
import classNames from "classnames";

// NOTE: Forcing to being a class component since
// there are some places that need to access to its react ref.

/*eslint-disable react/prefer-stateless-function */
class SelectAndOtherContent extends Component {
  componentDidUpdate({ showOther, manualOtherFocus }) {
    if (this.props.showOther && !showOther && manualOtherFocus) {
      this.otherContent.focus();
    }
  }

  render() {
    const {
      autoFocus,
      selectProps,
      children,
      value,
      otherValue,
      onChange,
      onOtherSelection,
      showOther,
      errorMessage,
    } = this.props;
    const { inputProps, ...restSelectProps } = selectProps;
    const inputPropsWithOther = [
      ...inputProps,
      { value: otherValue, label: "Other" },
    ];
    const selectErrorMessage = !showOther ? errorMessage : null;
    const childreWithRef = React.cloneElement(children, {
      ref: (el) => {
        this.otherContent = el;
      },
      autoFocus: true,
    });

    return (
      <div className="SelectAndOtherContent">
        <Select
          autoFocus={autoFocus}
          {...restSelectProps}
          errorMessage={selectErrorMessage}
          extraClassnames="SelectAndOtherContent-select"
          inputProps={inputPropsWithOther}
          value={value}
          onChange={(value) => {
            if (onOtherSelection && showOther) {
              onOtherSelection();
            }

            if (onChange) {
              onChange(value);
            }
          }}
        />
        <div className="SelectAndOtherContent-other">
          {showOther && childreWithRef}
        </div>
      </div>
    );
  }
}

class SelectAndOther extends Component {
  static propTypes = {
    autoFocus: PropTypes.bool,
    errorMessage: PropTypes.string,
    extraClassnames: PropTypes.string,
    children: PropTypes.node.isRequired,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    otherValue: PropTypes.string,
    onOtherSelection: PropTypes.func,
    manualOtherFocus: PropTypes.bool,
    onChange: PropTypes.func,
    name: PropTypes.string,
    selectProps: PropTypes.object, //eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    autoFocus: false,
    errorMessage: null,
    extraClassnames: "",
    onOtherSelection: () => {},
    manualOtherFocus: false,
    onChange: () => {},
    name: null,
    defaultValue: null,
  };

  shouldShowOther() {
    const {
      selectProps: { inputProps },
      value,
    } = this.props;
    const givenOption = inputProps.find((item) => item.value === value);
    const hideOther =
      (value == undefined && value == null) ||
      (value !== undefined && value !== null && !!givenOption);
    return !hideOther;
  }

  render() {
    const {
      autoFocus,
      children,
      extraClassnames,
      selectProps,
      name,
      value,
      otherValue,
      onOtherSelection,
      manualOtherFocus,
      onChange,
      errorMessage,
      defaultValue,
    } = this.props;
    const showOther = this.shouldShowOther();
    const selectAndOtherClassnames = classNames({
      SelectAndOther: true,
      "is-showingOther": showOther,
      [extraClassnames]: !!extraClassnames,
    });

    return (
      <FormElement
        name={name}
        extraClassnames={selectAndOtherClassnames}
        errorMessage={errorMessage}
        errorDisplayValidation={() => false}
      >
        <SelectAndOtherContent
          autoFocus={autoFocus}
          onChange={onChange}
          selectProps={selectProps}
          value={value || defaultValue}
          otherValue={otherValue}
          onOtherSelection={onOtherSelection}
          manualOtherFocus={manualOtherFocus}
          showOther={showOther}
          errorMessage={errorMessage}
        >
          {children}
        </SelectAndOtherContent>
      </FormElement>
    );
  }
}

export default SelectAndOther;
