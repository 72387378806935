import React, { Fragment } from "react";
import {
  perUnitNumberPrecision,
  defaultNumberPrecision,
} from "config/priceInput";
import { placeholderDateFormat } from "config/dateInput";
import { getText } from "TermsMapper";
import { capitalize } from "lodash";
import Autocomplete from "components_new/elements/Autocomplete";
import PriceInput from "components/shared/form/PriceInput";
import DatePicker from "components_new/atoms/DatePicker";
import NumberInput from "components/shared/form/NumberInput";
import Select from "components/shared/form/Select";
import HelperContent from "components/shared/HelperContent";

function getTypeOfPriceList(type, value) {
  return [
    {
      value: value,
      label: `Per ${capitalize(getText(type, "valuation_unit"))}`,
    },
    { value: "total", label: "Total" },
  ];
}

function LogSaleForm({
  formData,
  updateForm,
  userContacts,
  errors,
  historyAction,
}) {
  const {
    date_helper_text: dateHelperContent,
    quantity_helper_text: numberHelperContent,
    is_terminal_action: isTerminalAction,
    parent_investment: parentInvestment,
  } = historyAction;
  // TODO: We need to save the "per_unit" value in the backend so we can know, meanwhile we are going to use "per_quantity"
  const pricePerUnitPrecision =
    formData.per_quantity_or_total_option === "per_quantity"
      ? perUnitNumberPrecision
      : defaultNumberPrecision;
  const price = isTerminalAction
    ? formData.cash_proceed
    : formData.amount_price_per_unit;
  const selectPrice = isTerminalAction
    ? "total"
    : formData.per_quantity_or_total_option;
  return (
    <Fragment>
      <DatePicker
        name="date"
        placeholder={placeholderDateFormat}
        label="Date of sale"
        onChange={(value, validated) => updateForm("date", value, validated)}
        extraClassnames="FormElement FormElement--Date"
        error={!!errors.for("date")}
        errorMessage={errors.for("date")}
        value={formData.date}
        helperContent={() => <HelperContent content={dateHelperContent} />}
        showHelper={!isTerminalAction && !!dateHelperContent}
        size="small"
        oldVersion
      />
      <Autocomplete
        name="buyer_name"
        label="Buyer"
        options={userContacts}
        onChange={(value) => updateForm("buyer_name", value)}
        errorMessage={errors.for("investment_buyer_name")}
        value={formData.buyer_name}
        size="small"
      />
      <NumberInput
        name="quantity"
        label="Quantity"
        placeholder="#"
        onChange={(value) => updateForm("quantity", value)}
        value={formData.quantity}
        errorMessage={errors.for("quantity")}
        helperContent={() => <HelperContent content={numberHelperContent} />}
        errorDisplayValidation={() => !numberHelperContent}
        showHelper={!isTerminalAction && !!numberHelperContent}
        readOnly={isTerminalAction}
        showLock={isTerminalAction}
        size="small"
      />
      <div className="GroupInputs GroupInputs-quantityOrTotal small">
        <PriceInput
          name="price_per_share"
          label="Price"
          extraClassnames="CompoundSelect-placeholder"
          onChange={(value) => updateForm("amount_price_per_unit", value)}
          value={price}
          errorMessage={errors.for("price_per_unit")}
          precision={pricePerUnitPrecision}
          errorDisplayValidation={() => false}
          readOnly={isTerminalAction}
          showLock={isTerminalAction}
          size="small"
        />
        <Select
          extraClassnames="CompoundSelect-option"
          inputProps={getTypeOfPriceList(parentInvestment.type, "per_quantity")}
          onChange={(type) => updateForm("per_quantity_or_total_option", type)}
          value={selectPrice}
          readOnly={isTerminalAction}
          showLock={isTerminalAction}
          size="small"
        />
        <p className="u-errorMessage QuantityErrorMessage">
          {errors.for("price_per_unit")}
        </p>
      </div>
    </Fragment>
  );
}

export default LogSaleForm;
