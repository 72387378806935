import { SET_RESOURCE_AVAILABILITY, RESET_RESOURCE } from "./actionTypes";

export function setResourceAvailability(isAvailable) {
  return {
    type: SET_RESOURCE_AVAILABILITY,
    isAvailable,
  };
}

export function resetResourceState() {
  return {
    type: RESET_RESOURCE,
  };
}
