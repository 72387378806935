import React from "react";
import Skeleton, {
  SkeletonHeader,
  SkeletonLine,
} from "components_new/atoms/Skeleton";
import classNames from "classnames";
import "./SkeletonStatLine.scss";

function SkeletonStatLine({ className }) {
  const SkeletonStatLineClassnames = classNames("SkeletonStatLine", {
    [`${className}`]: !!className,
  });
  return (
    <Skeleton className={SkeletonStatLineClassnames}>
      <SkeletonHeader>
        <SkeletonLine length="full" />
      </SkeletonHeader>
    </Skeleton>
  );
}
export default SkeletonStatLine;
