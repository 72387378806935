import persistAuthToken from "./persistAuthToken";
import {
  authSuccess,
  receiveAuthorizedUser,
  verifyCodeError,
  verifyCodeStart,
  verifyCodeSuccess,
} from "actions/synchronous/auth";
import Api from "lib/Api";

function verifyCode(authCode, intermediateToken, nextPath) {
  return async (dispatch, getState) => {
    const { invitation } = getState();
    let nextPathName = "/dashboard";
    dispatch(verifyCodeStart());

    try {
      const response = await Api.post(
        "/session/verify",
        {
          auth_code: authCode,
          authentication: {
            intermediate_auth_token: intermediateToken,
          },
        },
        { withoutOrganization: true },
      );
      const { id, token, expires } = response.data.authentication;
      const expirationDate = new Date(expires);
      const user = response.data.user;

      dispatch(verifyCodeSuccess());
      dispatch(authSuccess(id, token, expirationDate));
      dispatch(persistAuthToken(id, token, expirationDate));
      dispatch(receiveAuthorizedUser(user));

      if (invitation.inviteCode) {
        nextPathName = "/personal-settings/organizations";
      }

      return {
        nextPath,
        nextPathName,
        id,
        token,
        expirationDate,
      };
    } catch (error) {
      dispatch(verifyCodeError(error.data));
      return { nextPath: null };
    }
  };
}

export default verifyCode;
