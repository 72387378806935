import React, { useContext, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Api from "lib/Api";

import strictlyPublicPage from "./strictlyPublicPage";
import pageRequiringIntermediateToken from "./pageRequiringIntermediateToken";
import devToolPage from "./devtoolPage";
import privatePage from "./privatePage";
import withParamProp from "./withParamProp";

import Root from "./Root";
import OrganizationRoot from "./OrganizationRoot";
import LoginView from "components/auth/LoginView";
import ForgotPasswordContainer from "components/auth/forgotPassword/ForgotPasswordContainer";
import ResetPasswordView from "components/auth/ResetPasswordView";
import SignUpRequest from "../../auth/SignUpRequest";
import ReportsView from "components_new/views/Reports";
import SnapshotsView from "components_new/views/Snapshots";
import NewSnapshot from "components_new/views/Snapshots/NewSnapshot";
import SnapshotView from "components_new/views/Snapshots/Snapshot";
import EditSnapshot from "components_new/views/Snapshots/EditSnapshot";
import NotFound from "components/NotFound";
import VerifyLoginView from "components/auth/VerifyLoginView";
import InvitationOrganizationContainer from "components/auth/invitationToOrganization/InvitationOrganizationContainer";
import InvitationRevokedContainer from "containers/invitations/InvitationRevokedContainer";
import AppLayout from "components/Mighty/AppLayout";
import InvestmentViewContainer from "components/investments/InvestmentView/InvestmentViewContainer";
import Dashboard from "components_new/views/Dashboard/Dashboard";
import PrivacyPolicy from "components/pages/PrivacyPolicy";
import TermsOfService from "components/pages/TermsOfService";
import PipelineSettings from "components_new/views/Settings/DealStages";
import OrganizationDetails from "components/organizations/settings/OrganizationDetails";
import OrganizationIntegrations from "components/organizations/settings/OrganizationIntegrations";
import OrganizationCustomFields from "components/customFields";
import OrganizationMembers from "components/organizations/OrganizationMembers";
import OrganizationCustomForm from "components_new/views/Organization/CustomFormPage";

import UserSettingsView from "components/users/UserSettingsView";
import OrganizationSettingsView from "components/organizations/OrganizationSettingsView";
import AccountInformationForm from "components/users/AccountInformationForm";
import LoginInformation from "components/users/LoginInformation";
import NotificationsViewContainer from "components/users/notifications/NotificationsViewContainer";
import UserOrganizationListContainer from "components/users/Organizations/UserOrganizationListContainer";
import PortfoliosView from "components_new/views/Portfolios";
import QueuesView from "components_new/views/Queues";
import PendingTasks from "components_new/views/Queues/tabs/PendingTasks";
import CompletedTasks from "components_new/views/Queues/tabs/CompletedTasks";
import SnoozedTasks from "components_new/views/Queues/tabs/SnoozedTasks";

import ContactsView from "components_new/views/Contacts";
import CompanyDirectoryView from "components_new/views/CompanyDirectory";
import CompanyContactTab from "components_new/views/Company/CompanyContactTab";
import CompanyOverviewTab from "components_new/views/Company/CompanyOverviewTab";
import CompanyInvestmentTab from "components_new/views/Company/CompanyInvestmentTab";
import CompanyNoteTab from "components_new/views/Company/CompanyNoteTab";
import CompanyFilesTab from "components_new/views/Company/CompanyFilesTab";
import CompanyDealTab from "components_new/views/Company/CompanyDealTab";
import CompanyCoInvestmentsTab from "components_new/views/Company/CompanyCoInvestmentsTab";
import CompanyView from "components_new/views/Company";
import DealsView from "components_new/views/Deals";
import ActiveDeals from "components_new/views/Deals/ActiveDeals";
import ActiveDealContent from "components_new/views/Deals/ActiveDeals/ActiveDealContent";
import PassedDeals from "components_new/views/Deals/PassedDeals";
import CommittedDeals from "components_new/views/Deals/CommittedDeals";

import DevHome, { DefaultDevToolsText } from "components/devTools/DevHome";
import WeeklyReportDevTool from "components/devTools/WeeklyReportDevTool";
import ErrorModalDevTool from "components/devTools/ErrorModalDevTool";
import ApiClientDevTool from "components/devTools/ApiClientDevTool";
import OrganizationNavigate from "./OrganizationNavigate";
import AuthenticationContext from "../AuthenticationContext";
import useCurrentUser from "hooks/useCurrentUser";

const AppLayoutPage = privatePage(AppLayout);

const LoginPage = strictlyPublicPage(LoginView);
const SignUpRequestPage = strictlyPublicPage(SignUpRequest);
const ForgotPasswordPage = strictlyPublicPage(ForgotPasswordContainer);
const ResetPasswordPage = strictlyPublicPage(ResetPasswordView);
const VerifyLoginPage = pageRequiringIntermediateToken(VerifyLoginView);
const VerifyLoginPageForDevs = devToolPage(VerifyLoginView);
const InvitationOrganizationPage = strictlyPublicPage(
  withParamProp(InvitationOrganizationContainer),
);

const RootPage = privatePage(Root);
const OrganizationRootPage = privatePage(OrganizationRoot);
const DashboardPage = privatePage(Dashboard);

const ReportsPage = privatePage(ReportsView);
const SnapshotsPage = privatePage(SnapshotsView);
const NewSnapshotPage = privatePage(NewSnapshot);
const SnapshotPage = privatePage(SnapshotView);
const EditSnapshotPage = privatePage(EditSnapshot);

const OrganizationSettingsPage = privatePage(OrganizationSettingsView);
const OrganizationDetailsPage = privatePage(OrganizationDetails);
const OrganizationIntegrationsPage = privatePage(OrganizationIntegrations);
const PipelineSettingsPage = privatePage(PipelineSettings);
const OrganizationCustomFieldsPage = privatePage(OrganizationCustomFields);
const OrganizationMembersPage = privatePage(OrganizationMembers);
const OrganizationCustomFormPage = privatePage(OrganizationCustomForm);

const UserSettingsPage = privatePage(UserSettingsView);
const AccountInformationPage = privatePage(AccountInformationForm);
const LoginInformationPage = privatePage(LoginInformation);
const NotificationsPage = privatePage(NotificationsViewContainer);
const UserOrganizationListPage = privatePage(UserOrganizationListContainer);

const PortfoliosPage = privatePage(PortfoliosView);
const QueuesPage = privatePage(QueuesView);
const PendingTasksPage = privatePage(PendingTasks);
const CompletedTasksPage = privatePage(CompletedTasks);
const SnoozedTasksPage = privatePage(SnoozedTasks);

const ContactsPage = privatePage(ContactsView);
const InvestmentViewPage = privatePage(withParamProp(InvestmentViewContainer));

const DealsPage = privatePage(DealsView);
const ActiveDealsPage = privatePage(ActiveDeals);
const PassedDealsPage = privatePage(PassedDeals);
const CommittedDealsPage = privatePage(CommittedDeals);
const ActiveDealContentPage = privatePage(ActiveDealContent);

const CompanyDirectoryPage = privatePage(withParamProp(CompanyDirectoryView));
const CompanyPage = privatePage(withParamProp(CompanyView));
const CompanyOverviewTabPage = privatePage(CompanyOverviewTab);
const CompanyInvestmentTabPage = privatePage(CompanyInvestmentTab);
const CompanyContactTabPage = privatePage(CompanyContactTab);
const CompanyNoteTabPage = privatePage(CompanyNoteTab);
const CompanyFilesTabPage = privatePage(CompanyFilesTab);
const CompanyDealTabPage = privatePage(CompanyDealTab);
const CompanyCoInvestmentsTabPage = privatePage(CompanyCoInvestmentsTab);

const DevHomePage = privatePage(DevHome);
const WeeklyReportDevToolPage = privatePage(WeeklyReportDevTool);
const ErrorModalDevToolPage = privatePage(ErrorModalDevTool);
const ApiClientDevToolPage = privatePage(ApiClientDevTool);

const PrivacyPolicyPage = privatePage(PrivacyPolicy);
const TermsOfServicePage = privatePage(TermsOfService);

function AppRoutes() {
  const currentUser = useCurrentUser();
  const { logout } = useContext(AuthenticationContext);

  useEffect(() => {
    Api.configure({
      onClientError: (_state, _dispatch, response) => {
        if (response.status === 401 && currentUser) {
          logout();
        }
      },
    });
  }, [logout, currentUser]);

  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/login" element={<LoginPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
      <Route path="/signup-request" element={<SignUpRequestPage />} />
      <Route path="/verify-login" element={<VerifyLoginPage />} />
      <Route path="/dev/verify-login" element={<VerifyLoginPageForDevs />} />
      <Route
        path="/account/create/:inviteCode/:organizationMembershipId"
        element={<InvitationOrganizationPage />}
      />
      <Route
        path="/revoked-invitation"
        element={<InvitationRevokedContainer />}
      />

      {/* Private Routes */}
      <Route path="/" element={<RootPage />} />
      <Route path="/:organizationUsername/*" element={<AppLayoutPage />}>
        <Route path="/" element={<OrganizationRootPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/dashboard/:chartType" element={<DashboardPage />} />
        <Route path="/reports" element={<ReportsPage />}>
          <Route path="/snapshots" element={<SnapshotsPage />} />
          <Route path="/snapshots/new" element={<NewSnapshotPage />} />
          <Route path="/snapshots/:id" element={<SnapshotPage />} />
          <Route path="/snapshots/:id/edit" element={<EditSnapshotPage />} />
        </Route>
        <Route path="/settings" element={<OrganizationSettingsPage />}>
          <Route
            path="/"
            element={<OrganizationNavigate to="/settings/details" />}
          />
          <Route path="/details" element={<OrganizationDetailsPage />} />
          <Route
            path="/integrations"
            element={<OrganizationIntegrationsPage />}
          />
          <Route path="/pipeline" element={<PipelineSettingsPage />} />
          <Route
            path="/custom-fields"
            element={<OrganizationCustomFieldsPage />}
          />
          <Route path="/extras" element={<OrganizationCustomFormPage />} />
          <Route path="/members" element={<OrganizationMembersPage />} />
        </Route>
        <Route path="/personal-settings" element={<UserSettingsPage />}>
          <Route
            path="/"
            element={
              <OrganizationNavigate to="/personal-settings/account-information" />
            }
          />
          <Route
            path="/account-information"
            element={<AccountInformationPage />}
          />
          <Route path="/login-information" element={<LoginInformationPage />} />
          <Route path="/notifications" element={<NotificationsPage />} />
          <Route path="/organizations" element={<UserOrganizationListPage />} />
        </Route>
        <Route path="/investments/:id" element={<InvestmentViewPage />} />
        <Route
          path="/investments/:id/notes/:noteId"
          element={<InvestmentViewPage />}
        />
        <Route path="/portfolios/:id" element={<PortfoliosPage />} />
        <Route path="/portfolios/:id/:tab" element={<PortfoliosPage />} />
        <Route path="/queues/:id" element={<QueuesPage />} />
        <Route path="/contacts" element={<ContactsPage />} />
        <Route
          path="/companies"
          element={<OrganizationNavigate to="/companydirectory" />}
        />
        <Route path="/companydirectory" element={<CompanyDirectoryPage />} />
        <Route
          path="/companydirectory/:tab"
          element={<CompanyDirectoryPage />}
        />
        <Route path="/companies/:id" element={<CompanyPage />}>
          <Route path="/" element={<Navigate to="overview" replace />} />
          <Route path="/overview" element={<CompanyOverviewTabPage />} />
          <Route path="/investments" element={<CompanyInvestmentTabPage />} />
          <Route path="/contacts" element={<CompanyContactTabPage />} />
          <Route path="/notes" element={<CompanyNoteTabPage />} />
          <Route path="/notes/:noteId" element={<CompanyNoteTabPage />} />
          <Route path="/files" element={<CompanyFilesTabPage />} />
          <Route path="/deals" element={<CompanyDealTabPage />} />
          <Route
            path="/co-investments"
            element={<CompanyCoInvestmentsTabPage />}
          />
        </Route>
        <Route path="/deals" element={<DealsPage />}>
          <Route
            path="/"
            element={<OrganizationNavigate to="/deals/active" />}
          />
          <Route path="/active" element={<ActiveDealsPage />}>
            <Route path="/:id" element={<ActiveDealContentPage />} />
            <Route
              path="/:id/notes/:noteId"
              element={<ActiveDealContentPage />}
            />
          </Route>
          <Route path="/passed" element={<PassedDealsPage />}>
            <Route path="/:id" element={<PassedDealsPage />} />
          </Route>
          <Route path="/committed" element={<CommittedDealsPage />}>
            <Route path="/:id" element={<CommittedDealsPage />} />
          </Route>
        </Route>

        <Route path="/queues" element={<QueuesPage />}>
          <Route path="/" element={<Navigate to="pending" />} />
          <Route path="/pending" element={<PendingTasksPage />} />
          <Route path="/snoozed" element={<SnoozedTasksPage />} />
          <Route path="/completed" element={<CompletedTasksPage />} />
        </Route>

        {process.env.APP_ENV !== "production" ? (
          <Route path="/dev" element={<DevHomePage />}>
            <Route path="/" element={<DefaultDevToolsText />} />
            <Route
              path="/weekly-report"
              element={<WeeklyReportDevToolPage />}
            />
            <Route path="/error" element={<ErrorModalDevToolPage />} />
            <Route path="/api-client" element={<ApiClientDevToolPage />} />
          </Route>
        ) : null}
        <Route path="/privacy" element={<PrivacyPolicyPage />} />
        <Route path="/terms" element={<TermsOfServicePage />} />
      </Route>

      <Route path="/error" element={<NotFound />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AppRoutes;
