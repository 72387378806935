import React, { Fragment, useCallback } from "react";
import { useDispatch } from "react-redux";
import ConfirmationModal from "components/shared/ConfirmationModal";
import { closeModal, updateModalOptions } from "store/modalV2/actions";
import { useParams, useNavigate } from "react-router-dom";
import { deleteCertificateHistory } from "store/investments/certificateHistory/actions";
import { fetchInvestmentDetailsV2 } from "store/investments/investment/actions";
import { fetchInvestmentDocuments } from "store/documents/actions";
import { fetchValuations } from "store/investments/v2/valuations/actions";

function DeleteCertificateConfirmationContainer({
  historyAction,
  submitting = false,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { organizationUsername } = useParams();

  const deleteCertificate = useCallback(async () => {
    const {
      child_investment: childInvestment,
      parent_investment: parentInvestment,
      is_terminal_action: isTerminalAction,
      id: certificateId,
      type,
    } = historyAction;
    const holding = isTerminalAction ? parentInvestment : childInvestment;
    const investmentId = holding.id;
    const isLastEvent = type === "purchased" || type === "distributed";

    dispatch(updateModalOptions("deleteCertificate", { submitting: true }));
    await dispatch(deleteCertificateHistory(investmentId, certificateId));

    if (isLastEvent) {
      navigate(
        `/${organizationUsername}/companies/${childInvestment.company_id}/investments`,
      );
    } else {
      if (
        type === "acquired" ||
        type === "converted" ||
        type === "stock_splitted" ||
        isTerminalAction
      ) {
        dispatch(fetchInvestmentDetailsV2(parentInvestment.id));
      }
      if (type === "written_off" || type === "stock_splitted") {
        dispatch(fetchValuations(parentInvestment.id));
        dispatch(fetchInvestmentDocuments(parentInvestment.id));
      }
      navigate(`/${organizationUsername}/investments/${parentInvestment.id}`);
    }

    dispatch(
      updateModalOptions("deleteCertificate", {
        submitting: false,
      }),
    );
    dispatch(closeModal());
  }, [dispatch, navigate, organizationUsername, historyAction]);

  const closeModalCallback = useCallback(() => dispatch(closeModal()), [
    dispatch,
  ]);

  return (
    <ConfirmationModal
      title="Are you sure?"
      confirmButtonLabel="Remove"
      message={
        <Fragment>
          Are you sure you want to remove the event{" "}
          <strong> {historyAction.description} </strong>
          and any associated documents?
        </Fragment>
      }
      action={deleteCertificate}
      closeModal={closeModalCallback}
      submitting={submitting}
    />
  );
}

export default DeleteCertificateConfirmationContainer;
