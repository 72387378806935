import React, { Fragment } from "react";
import { formatDisplayUrl } from "config/urlFormattingConfig";
import Text from "components_new/atoms/Text";
import Icon from "components_new/atoms/Icon";
import BasicLabel from "components_new/atoms/BasicLabel";
import CompanyLinkValue from "components_new/views/Company/shared/CompanyLinkValue";
import { WEBSITE_PREFILL } from "components_new/lib/miscellaneous";
import Avatar from "components_new/elements/Avatar";
import "./SnapshotItem.scss";
import { isEmpty } from "lodash";

export default function SnapshotItem({
  avatar,
  title,
  description,
  meta,
  icons,
  iconsColor = "purple",
}) {
  return (
    <div className="SnapshotItem">
      <div className="SnapshotItem-Avatar">
        <Avatar imageUrl={avatar} avatarIcon="deal-med" />
      </div>
      <div className="SnapshotItem-Description">
        <div className="SnapshotItem-Description-Title">
          <Text
            type="body-1-bold"
            extraClassnames="SnapshotItem-Description-CompanyName"
          >
            {title}
          </Text>
          {icons && icons.includes("stealth") && (
            <Icon
              icon="ri-eye-off-fill"
              size="small"
              extraClassnames={iconsColor}
            />
          )}
          {icons && icons.includes("follow_on") && (
            <BasicLabel type="primary">Follow-on</BasicLabel>
          )}
        </div>
        <Text>{description}</Text>
        {!isEmpty(meta) && (
          <Text
            as="span"
            color="brand-light"
            extraClassnames="SnapshotItem-Description-CompanyAttributes"
          >
            {meta.website && (
              <CompanyLinkValue
                url={meta.website}
                label={formatDisplayUrl(meta.website)}
                preFilled={WEBSITE_PREFILL}
              />
            )}
            {meta.website && meta.headquarters && <Fragment> &#183; </Fragment>}
            {meta.headquarters && meta.headquarters}
            {(meta.website || meta.headquarters) && meta.founders && (
              <Fragment> &#183; </Fragment>
            )}
            {meta.founders && meta.founders}
          </Text>
        )}
      </div>
    </div>
  );
}
