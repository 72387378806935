import React, { useState } from "react";
import SearchAndSelect from "components/shared/form/SearchAndSelect";
import BaseCompanyDetail from "components_new/elements/CompanySelector/BaseCompanyDetail";
import classNames from "classnames";
import "./CompanySelector.scss";

const COMPANY_DATA = {
  isNewCompany: true,
  type: "company",
  name: "",
  title: "",
  description: "",
  headquarters: "",
  interesting: false,
  double_down: false,
  stealth: false,
};

function CompanySelector({
  prefilledValue,
  performSearch,
  companies,
  clearSearch,
  errorMessage,
  label,
  onChange,
  count,
  maxHeight,
  placeHolder,
  isFetching,
  name,
  disabled,
  filterCompanyTag,
  suggestedCompanyList,
  selectedItem,
  extraClassnames,
  disabledCompanyCreation,
  excludedCompanies,
  onCreateNewCompany,
  readOnly,
  selectedCompany = {},
  isOnSidebar = false,
  allowInlineCreation = false,
  size,
}) {
  const [isNewCompany, setIsNewCompany] = useState(false);
  const handleNewCompany = (value, selectCustomItem) => {
    const newCompany = { ...COMPANY_DATA, name: value, title: value };
    if (isOnSidebar) {
      setIsNewCompany(true);
      if (onCreateNewCompany) {
        onCreateNewCompany(newCompany);
      }
    }
    selectCustomItem(newCompany);
  };

  const handleInlineCompany = (value, selectCustomItem) => {
    setIsNewCompany(true);
    const newCompany = { ...COMPANY_DATA, name: value, title: value };
    selectCustomItem(newCompany);
  };

  const handleOnChangeNewCompanyName = (value) => {
    if (isNewCompany) {
      const newCompany = { ...selectedCompany, name: value, title: value };
      onChange(name, newCompany);
    }
  };

  const handleOnChange = (_, value) => {
    if (value?.id && isNewCompany && isOnSidebar) {
      setIsNewCompany(false);
    }
    onChange(name, value);
  };

  const renderNewOption = (value, selectCustomItem) => {
    if (disabledCompanyCreation) {
      return null;
    }
    if (allowInlineCreation) {
      handleInlineCompany(value, selectCustomItem);
    } else {
      return (
        <a
          data-testid="openNewCompany"
          className="CompanySelector-link"
          onClick={() => handleNewCompany(value, selectCustomItem)}
        >
          {`+ New Company: ${value}`}
        </a>
      );
    }
  };

  return (
    <div className={classNames("CompanySelector FormElement", extraClassnames)}>
      <SearchAndSelect
        name={name}
        label={label}
        placeHolder={placeHolder}
        performSearch={performSearch}
        companies={companies}
        clearSearch={clearSearch}
        errorMessage={errorMessage}
        scope="all_companies"
        onChange={handleOnChange}
        onChangeOnCreation={handleOnChangeNewCompanyName}
        disabled={disabled}
        prefilledValue={prefilledValue}
        isFetching={isFetching}
        maxHeight={maxHeight}
        count={count}
        newOptionComponent={renderNewOption}
        actionWhenEmpty={(value, selectCustomItem) =>
          handleNewCompany(value, selectCustomItem)
        }
        excludedCompanies={excludedCompanies}
        errorMessage={errorMessage}
        readOnly={readOnly}
        allowCreation={isNewCompany}
        size={size}
      />
      {selectedItem && selectedItem.isNewCompany && !onCreateNewCompany && (
        <BaseCompanyDetail
          companyFormData={selectedItem}
          filterCompanyTag={filterCompanyTag}
          suggestedCompanyList={suggestedCompanyList}
          handleCompanyChange={(name, value) => {
            const company = { ...selectedItem, [name]: value };
            onChange(null, company);
          }}
        />
      )}
    </div>
  );
}

export default CompanySelector;
