import React, { useState, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Tooltip from "components_new/atoms/Tooltip/Tooltip";
import Icon from "components_new/atoms/Icon";
import {
  Label,
  Segment,
  Header,
  Dimmer,
  Loader,
} from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/header.css";
import "semantic-ui-css/components/segment.css";
import "semantic-ui-css/components/label.css";

import "./GroupedVerticalButtonMenu.scss";

function GroupVerticalButtonMenu({
  title,
  showCount,
  showExpandCollapse,
  startsOpen,
  disabled,
  items,
  itemType,
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(
    showExpandCollapse ? startsOpen : true,
  );
  const [iconClassName, setIconClassName] = useState(null);
  const handleClick = () => {
    if (!disabled) {
      setIsMenuOpen(!isMenuOpen);
    }
  };
  useLayoutEffect(() => {
    if (!isMenuOpen) {
      setIconClassName("ri-add-line");
    }
    if (isMenuOpen && !disabled) {
      setIconClassName("icon-less");
    }
  }, [disabled, isMenuOpen]);

  const menuClassnames = classNames("GroupedVerticalButtonMenu", {
    withCount: showCount,
    withAvatar: itemType === "avatar",
    withIcon: showExpandCollapse,
    isClosed: !isMenuOpen,
  });
  return (
    <div className={menuClassnames}>
      {title && (
        <Header
          disabled={disabled}
          className="MenuTitle"
          onClick={showExpandCollapse ? () => handleClick() : () => {}}
          sub
        >
          {showExpandCollapse && (
            <span className="ToggleIcon" onClick={() => handleClick()}>
              <Icon
                data-testid="icon"
                origin={
                  iconClassName === "ri-add-line" ? "remixIcon" : "custom"
                }
                link
                className={iconClassName}
              />
            </span>
          )}
          {title}
          {showCount && <Label size="mini">{items.length}</Label>}
        </Header>
      )}
      <Segment.Group className="RowCardList">
        {isMenuOpen && renderMenu(items, itemType)}{" "}
      </Segment.Group>
    </div>
  );
}

function renderMenu(items) {
  return items.map(
    (
      { onClick, disabled, tooltip, tooltipPosition, title, icon, isLoading },
      index,
    ) => {
      const menuItem = (
        <Segment
          key={index}
          onClick={onClick}
          className={disabled ? "disabled" : ""}
        >
          <Icon icon={icon || "ri-add-line"} alt={title} />
          {isLoading && (
            <Dimmer active={true} inverted>
              <Loader size="small" indeterminate></Loader>
            </Dimmer>
          )}
          <span>{title}</span>
        </Segment>
      );
      return tooltip ? (
        <Tooltip
          key={index}
          trigger={menuItem}
          content={tooltip}
          position={tooltipPosition}
        />
      ) : (
        menuItem
      );
    },
  );
}

const itemPropType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  imageUrl: PropTypes.string,
  avatarIcon: PropTypes.string,
  imagePosition: PropTypes.oneOf(["left", "right"]),
  onClick: PropTypes.func,
  active: PropTypes.bool,
});

GroupVerticalButtonMenu.propTypes = {
  title: PropTypes.string,
  showCount: PropTypes.bool,
  showExpandCollapse: PropTypes.bool,
  startsOpen: PropTypes.bool,
  disabled: PropTypes.bool,
  items: PropTypes.arrayOf(itemPropType).isRequired,
  itemType: PropTypes.oneOf(["icon", "avatar", "default"]),
};

GroupVerticalButtonMenu.defaultProps = {
  showCount: false,
  showAvatar: false,
  showExpandCollapse: false,
  disabled: false,
  startsOpen: false,
  itemType: "default",
};

export default GroupVerticalButtonMenu;
