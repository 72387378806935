import {
  APPLY_FILTER_CRITERIAS,
  CLEAR_FILTER_CRITERIAS,
  RESET_FILTER_CRITERIAS,
} from "./actionTypes";

const initialState = {
  filterCriterias: {},
};

function filterReducer(state = initialState, action) {
  switch (action.type) {
    case APPLY_FILTER_CRITERIAS:
      return {
        ...state,
        filterCriterias: {
          ...state.filterCriterias,
          [action.criteriaKey]: action.filterCriterias,
        },
      };
    case CLEAR_FILTER_CRITERIAS:
      return {
        ...state,
        filterCriterias: {
          ...state.filterCriterias,
          [action.criteriaKey]: [],
        },
      };
    case RESET_FILTER_CRITERIAS:
      return initialState;
    default:
      return state;
  }
}

export default filterReducer;
