import renderActivityListItem from "lib/renderActivityListItem";
import PropTypes from "prop-types";
import {
  investmentPropType,
  investmentEventPropType,
  investmentEventsPropType,
  companyPropType,
  portfoliosPropType,
} from "PropTypes";

function ActivityFeedEvent({
  event,
  index,
  events,
  company,
  investment,
  portfolios,
  organizationUsername,
}) {
  return renderActivityListItem(
    event,
    index,
    events,
    company,
    investment,
    portfolios,
    organizationUsername,
  );
}

ActivityFeedEvent.propTypes = {
  company: companyPropType.isRequired,
  event: investmentEventPropType.isRequired,
  events: investmentEventsPropType.isRequired,
  index: PropTypes.number.isRequired,
  investment: investmentPropType,
  portfolios: portfoliosPropType,
};

ActivityFeedEvent.defaultProps = {
  investment: {},
  portfolios: [],
};

export default ActivityFeedEvent;
