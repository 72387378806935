import React from "react";
import PropTypes from "prop-types";
import formatPrice from "lib/formatters/formatPrice";
import formatNumber from "lib/formatters/formatNumber";
import formatDate from "lib/formatDate";
import ListValue from "components/shared/ListValue";

const InvestmentCustomFieldItem = ({ label, value, type }) => {
  const typeOfContent = {
    text: { content: value },
    currency: { content: formatPrice(value) },
    textarea: { content: value },
    date: { content: formatDate(value, "semiDetailed") },
    single_choice: { content: value },
    number: { content: formatNumber(value) },
    multiple_choice: {
      content: Array.isArray(value) && (
        <ListValue list={value} attribute="label" />
      ),
    },
  };

  return (
    <div className="InvestmentDetailItem">
      <div className="InvestmentDetailItem-inner">
        <div className="InvestmentDetailItem-title">{label}</div>
        <div className="InvestmentDetailItem-content">
          {typeOfContent[type].content}
        </div>
      </div>
    </div>
  );
};

InvestmentCustomFieldItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf({ label: PropTypes.string, value: PropTypes.bool }),
  ]),
  type: PropTypes.string.isRequired,
};

export default InvestmentCustomFieldItem;
