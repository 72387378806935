import React, { Fragment } from "react";
import { getText } from "TermsMapper";
import { placeholderDateFormat } from "config/dateInput";
import PriceInput from "components/shared/form/PriceInput";
import DatePicker from "components_new/atoms/DatePicker";
import NumberInput from "components/shared/form/NumberInput";
import amountPerQuantityOrTotal from "config/amountPerQuantityOrTotalConfig";
import Select from "components/shared/form/Select";
import HelperContent from "components/shared/HelperContent";
import { formatPriceInputPrecision, customPurchasedErrors } from "../../config";

function PurchasedEquityForm({ formData, updateForm, errors, historyAction }) {
  const {
    date_helper_text: dateHelperContent,
    quantity_helper_text: numberHelperContent,
  } = historyAction;
  const priceInputPrecision = formatPriceInputPrecision(
    formData.per_quantity_or_total_option,
  );
  return (
    <Fragment>
      <DatePicker
        name="date"
        label={getText("equity", "date")}
        placeholder={placeholderDateFormat}
        onChange={(value, validated) => updateForm("date", value, validated)}
        extraClassnames="FormElement FormElement--Date"
        error={!!errors.for("date")}
        errorMessage={errors.for("date")}
        value={formData.date}
        helperContent={() => <HelperContent content={dateHelperContent} />}
        showHelper={!!dateHelperContent}
        size="small"
        oldVersion
      />
      <div className="GroupInputs GroupInputs-quantityOrTotal small">
        <PriceInput
          extraClassnames="CompoundSelect-placeholder"
          name="total_acquisition_amount"
          label={getText("equity", "total_acquisition_amount")}
          onChange={(value) => updateForm("per_share_or_total", value)}
          value={formData.per_share_or_total}
          errorMessage={
            !formData.per_share_or_total
              ? errors.for("total_acquisition_amount")
              : null
          }
          errorDisplayValidation={() => false}
          precision={priceInputPrecision}
          size="small"
        />
        <Select
          extraClassnames="CompoundSelect-option"
          inputProps={amountPerQuantityOrTotal}
          onChange={(value) =>
            updateForm("per_quantity_or_total_option", value)
          }
          value={formData.per_quantity_or_total_option}
          size="small"
        />
        <p className="u-errorMessage QuantityErrorMessage">
          {!formData.per_share_or_total
            ? customPurchasedErrors(
                errors,
                "total_acquisition_amount",
                formData.type,
              )
            : null}
        </p>
      </div>
      <NumberInput
        name="quantity"
        label={getText("equity", "quantity")}
        onChange={(value) => updateForm("quantity", value)}
        errorMessage={errors.for("quantity")}
        helperContent={() => <HelperContent content={numberHelperContent} />}
        showHelper={!!numberHelperContent}
        errorDisplayValidation={() => !numberHelperContent}
        value={formData.quantity}
        size="small"
      />
    </Fragment>
  );
}

export default PurchasedEquityForm;
