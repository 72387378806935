import React from "react";
import PropTypes from "prop-types";
import { Table } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/table.css";

function TableEmptyRow({ colSpan, children, ...props }) {
  return (
    <Table.Row className="EmptyRow" {...props}>
      <Table.Cell colSpan={colSpan}>
        <div className="EmptyRowMessage">{children}</div>
      </Table.Cell>
    </Table.Row>
  );
}

TableEmptyRow.propTypes = {
  colSpan: PropTypes.number,
  children: PropTypes.node,
};

TableEmptyRow.defaultProps = {
  colSpan: 100,
  children: "0 results found matching your filters",
};

export default TableEmptyRow;
