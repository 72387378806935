import React, { Fragment } from "react";
import {
  Actions,
  Amount,
  Body,
  DetailsList,
  Masthead,
  Notes,
  PreviousHolding,
} from "../layout";
import CompanyLogo from "components/shared/CompanyLogo";
import { InvestmentAmount, ObtainedText } from "../components";
import isValidNumberInput from "lib/formatters/isValidNumberInput";
import formatPrice from "lib/formatters/formatPrice";

function DebtDetails({
  investment,
  company,
  openReferrerModal,
  organizationUsername,
  openContactDetailsView,
}) {
  const { total_acquisition_amount } = investment;
  const quantityValue =
    isValidNumberInput(total_acquisition_amount) &&
    formatPrice(total_acquisition_amount);

  return (
    <Fragment>
      <Actions investment={investment} company={company} />
      <Body>
        <CompanyLogo
          company={company}
          extraClassnames="InvestmentDetails-companyLogo"
          organizationUsername={organizationUsername}
        />
        <Masthead investment={investment} company={company} />
        {investment.previous_holding && <PreviousHolding />}
        <Amount>
          <InvestmentAmount
            obtainedText={<ObtainedText investment={investment} />}
            quantity={quantityValue}
          />
        </Amount>
        <DetailsList
          investment={investment}
          openReferrerModal={openReferrerModal}
          openContactDetailsView={openContactDetailsView}
        />
        <Notes notes={investment.notes} />
      </Body>
    </Fragment>
  );
}

export default DebtDetails;
