import { combineReducers } from "redux";
import {
  RECEIVE_DASHBOARD_INVESTMENTS,
  INSERT_COMPANY,
  REQUEST_OWNED_PORTFOLIOS,
  RECEIVE_OWNED_PORTFOLIOS,
  REQUEST_PORTFOLIOS,
  RECEIVE_PORTFOLIOS,
} from "actions/synchronous/shared";

const receivedInitialState = {
  portfolios: false,
};

function requested(state = {}, action) {
  switch (action.type) {
    case REQUEST_PORTFOLIOS:
      return Object.assign({}, state, {
        portfolios: true,
        minimalPortfolios: true,
      });
    case REQUEST_OWNED_PORTFOLIOS:
      return Object.assign({}, state, { ownedPortfolios: true });
    default:
      return state;
  }
}

function received(state = receivedInitialState, action) {
  switch (action.type) {
    case RECEIVE_PORTFOLIOS:
      return Object.assign({}, state, { portfolios: true });
    default:
      return state;
  }
}

function companies(state = [], action) {
  switch (action.type) {
    case INSERT_COMPANY:
      return [...state, action.company];
    default:
      return state;
  }
}

function portfolios(state = [], action) {
  switch (action.type) {
    case RECEIVE_PORTFOLIOS:
      return action.portfolios;
    default:
      return state;
  }
}

// TODO: Include isFetching as a part of each entity to avoid to include in each view.
function isPortfoliosFetching(state = false, action) {
  switch (action.type) {
    case REQUEST_PORTFOLIOS:
      return true;
    case RECEIVE_PORTFOLIOS:
      return false;
    default:
      return state;
  }
}

function ownedPortfolios(state = [], action) {
  switch (action.type) {
    case RECEIVE_OWNED_PORTFOLIOS:
      return action.ownedPortfolios;
    default:
      return state;
  }
}

function dashboardInvestments(state = {}, action) {
  switch (action.type) {
    case RECEIVE_DASHBOARD_INVESTMENTS:
      return Object.assign({}, state, action.dashboardInvestments);
    default:
      return state;
  }
}

export default combineReducers({
  requested,
  received,
  companies,
  portfolios,
  isPortfoliosFetching,
  ownedPortfolios,
  dashboardInvestments,
});
