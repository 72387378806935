import React, { useCallback, useState } from "react";
import ConfirmationModal from "components/shared/ConfirmationModal";

import { useDispatch } from "react-redux";
import { closeModal as closeModalHelper } from "store/modalV2/actions";
import { useRemoveTask } from "components_new/views/Queues/hooks/mutations/useRemoveTask";

function ConfirmDeletionWrapper({ taskId, callback }) {
  const [isLoading, setIsloading] = useState(false);
  const dispatch = useDispatch();
  const { mutateAsync: removeTask } = useRemoveTask();

  const closeModal = useCallback(() => dispatch(closeModalHelper()), [
    dispatch,
  ]);

  const deleteTask = useCallback(async () => {
    setIsloading(true);
    await removeTask({ taskId });

    closeModal();
    callback?.();
    setIsloading(false);
  }, [removeTask, taskId, closeModal, callback]);

  return (
    <ConfirmationModal
      title="Delete Task"
      message="Are you sure you want to delete this task?"
      confirmButtonLabel="Delete Task"
      primaryButtonClassnames="Button--warning"
      submitting={isLoading}
      closeModal={closeModal}
      action={deleteTask}
    />
  );
}

export default ConfirmDeletionWrapper;
