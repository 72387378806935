import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import SearchInput from "components_new/atoms/SearchInput";
import Button from "components_new/atoms/Button";
import Icon from "components_new/atoms/Icon";
import Link from "components_new/atoms/Link";
import ScrollView from "components_new/elements/ScrollView";
import StagesSection from "./StagesSection";
import EmptyContent from "components_new/elements/EmptyContent";
import filterBy from "components_new/lib/filterBy";
import { stageProptype, dealProptype } from "PropTypes";
import { isEmpty } from "lodash";
import MightyIcon from "components_new/atoms/MightyIcon";
import Header, { SubHeader } from "components_new/atoms/Header";
import "./ActiveDeals.scss";

function EmptyDealContent({ openNewDealSidebar }) {
  return (
    <EmptyContent>
      <div className="EmptyDealsMessage">
        <MightyIcon icon="deal-xlrg" />
        <Header as="h2">You don't have any active deals.</Header>
        <Button
          size="small"
          primary
          onClick={openNewDealSidebar}
          className="EmptyDealsCreateButton"
        >
          <Icon icon="ri-add-line" />
          New Deal
        </Button>
      </div>
    </EmptyContent>
  );
}

function UnselectedDealContent() {
  return (
    <EmptyContent>
      <div className="UnselectedDealsLandingContent">
        <div className="UnselectedDealsText">
          <Header as="h2">No deal selected</Header>
          <SubHeader>
            Select a deal from the list to view its details.
          </SubHeader>
        </div>
      </div>
    </EmptyContent>
  );
}

function ActiveDeals({
  isLoading,
  stages,
  activeDeals,
  selectedDealId,
  organizationUsername,
  openNewDealSidebar,
  children,
  isAdmin,
}) {
  const [query, setQuery] = useState("");

  const filteredDeals = useMemo(() => {
    if (isEmpty(query)) return activeDeals;
    const filtered = filterBy(activeDeals, query, [
      "name",
      "title",
      "owner_name",
      "round_name",
    ]);
    return filtered;
  }, [query, activeDeals]);

  const isEmptyResult = !isEmpty(query) && filteredDeals.length === 0;

  return (
    <div className="DealsView-ActiveDeals DealsView-BodyGrid">
      <div className="DealsView-BodyGrid-Alpha">
        <ScrollView
          scrollY={true}
          extraClassnames="ActiveDeals-SectionListMenu"
        >
          <ScrollView.StickyHeader extraClassnames="ActiveDeals-SectionListMenu-Header">
            <SearchInput
              onChange={setQuery}
              value={query}
              placeholder={`Search ${activeDeals.length} active deals`}
              size="small"
            />
            <Button
              size="small"
              secondary
              onClick={openNewDealSidebar}
              className="ActiveDeals-SectionListMenu-NewDealButton"
            >
              <Icon icon="ri-add-line" />
              New Deal
            </Button>
          </ScrollView.StickyHeader>
          <StagesSection
            deals={filteredDeals}
            stages={stages}
            organizationUsername={organizationUsername}
            selectedDealId={selectedDealId}
            onClickAddDeal={openNewDealSidebar}
            isLoading={isLoading}
            isEmptyResult={isEmptyResult}
          />
          {isAdmin && (
            <Link
              extraClassnames="ActiveDeals-SectionListMenu-ManageStagesBtn"
              to={`/${organizationUsername}/settings/pipeline`}
            >
              Manage Deal Stages
            </Link>
          )}
        </ScrollView>
      </div>
      <div className="DealsView-BodyGrid-Beta">
        {isEmpty(activeDeals) && (
          <EmptyDealContent openNewDealSidebar={openNewDealSidebar} />
        )}
        {!isEmpty(activeDeals) && isEmpty(selectedDealId) && (
          <UnselectedDealContent />
        )}
        {!isEmpty(activeDeals) && !isEmpty(selectedDealId) && children}
      </div>
    </div>
  );
}

ActiveDeals.propTypes = {
  isLoading: PropTypes.bool,
  stages: PropTypes.arrayOf(stageProptype),
  stagesMenuState: PropTypes.shape({}),
  updateStagesState: PropTypes.func,
  activeDeals: PropTypes.arrayOf(dealProptype),
  isLoadingDeal: PropTypes.bool,
  selectedDealId: PropTypes.string,
  selectedDeal: dealProptype,
  organizationUsername: PropTypes.string,
  openNewDealSidebar: PropTypes.func,
  query: PropTypes.string,
  updateDealsQuery: PropTypes.func,
  children: PropTypes.node,
  selectedDealStage: stageProptype,
  isAdmin: PropTypes.bool,
};

export default ActiveDeals;
