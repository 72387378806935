import Api from "lib/Api";
import { itemsByPage } from "config/activityFeedConfig";
import {
  UPDATE_DASHBOARD_EVENTS_COUNT,
  UPDATE_EVENT_RELATED_INVESTMENTS,
  CLEAN_DASHBOARD_EVENTS,
} from "./actionTypes";
import {
  collectionFetchStart,
  collectionFetchSuccess,
  collectionFetchFail,
} from "store/domain/domainActions";

export function updateDashboardEventsCount(eventsCount) {
  return { type: UPDATE_DASHBOARD_EVENTS_COUNT, eventsCount };
}

export function updateEventRelatedInvestments(eventRelatedInvestments) {
  const eventRelatedInvestmentsObject = {};

  eventRelatedInvestments.forEach((investment) => {
    eventRelatedInvestmentsObject[investment.id] = investment;
  });

  return {
    type: UPDATE_EVENT_RELATED_INVESTMENTS,
    eventRelatedInvestments: eventRelatedInvestmentsObject,
  };
}

export function cleanDashboardEvents() {
  return { type: CLEAN_DASHBOARD_EVENTS };
}

export function fetchDashboardEvents(
  { page = 1, perPage = itemsByPage, replace = true } = {
    page: 1,
    perPage: itemsByPage,
    replace: true,
  },
) {
  return (dispatch) => {
    dispatch(collectionFetchStart("dashboardEvents"));
    return Api.get("/events", {
      params: {
        per_page: perPage,
        page,
      },
    })
      .then(({ data, response }) => {
        const { events, investments, companies, portfolios } = data;
        const totalEventsCount = parseInt(response.headers.get("X-Total"));
        dispatch(updateDashboardEventsCount(totalEventsCount));
        dispatch(collectionFetchSuccess("dashboardEvents", events, replace));
        // TODO: Remove this when we find a better solution to load all the investment information in the Dashboard Events
        dispatch(updateEventRelatedInvestments(investments));
        dispatch(collectionFetchSuccess("investments", investments, false));
        dispatch(collectionFetchSuccess("companies", companies, false));
        dispatch(collectionFetchSuccess("portfolios", portfolios, true));
        return events;
      })
      .catch((error) => {
        dispatch(collectionFetchFail("dashboardEvents"));
        return error;
      });
  };
}
