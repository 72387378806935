import React from "react";

function CreateTag({ currentValue, onClick, highlightItem, label }) {
  return (
    <div
      className="CreateTag InputTag-actions-item"
      data-index={null}
      data-value={currentValue}
      onMouseEnter={(event) => highlightItem(event.currentTarget)}
      onClick={() => onClick(currentValue)}
    >
      <div className="InputTag-actions-value">
        {`${label}:`}
        <strong className="CreateTag-value">"{currentValue}"</strong>
      </div>
    </div>
  );
}

CreateTag.defaultProps = {
  label: "Create new tag",
};

export default CreateTag;
