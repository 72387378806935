import React, { memo } from "react";
import PropTypes from "prop-types";
import { investmentsObjectPropType, investmentEventsPropType } from "PropTypes";
import ActivityFeed from "./ActivityFeed";
import { isEqual } from "lodash";

const PureActivityFeed = memo(ActivityFeed, isEqual);

function DashboardActivityFeed({
  events,
  fetchDashboardEvents,
  eventsCount,
  investments,
  isFetching,
  isEventFetching,
}) {
  const activityFeed = (
    <PureActivityFeed
      extraClassnames="DashboardActivityFeed"
      events={events}
      eventsCount={eventsCount}
      investments={investments}
      fetchActivityFeed={fetchDashboardEvents}
      isEventFetching={isEventFetching}
      isFetching={isFetching}
    />
  );

  return (
    <div>
      <h3 className="u-sectionHeading DashboardActivityFeedLayoutHeading">
        Activity
      </h3>
      <div className="DashboardActivityFeedLayout">{activityFeed}</div>
    </div>
  );
}

DashboardActivityFeed.propTypes = {
  events: investmentEventsPropType.isRequired,
  eventsCount: PropTypes.number,
  fetchDashboardEvents: PropTypes.func.isRequired,
  investments: investmentsObjectPropType.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isEventFetching: PropTypes.bool.isRequired,
};

export default DashboardActivityFeed;
