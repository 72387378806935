import React from "react";
import SummaryStatsItem from "components_new/atoms/SummaryStatsItem";
import SkeletonStatLine from "./SkeletonStatLine";

const SKELETON_SUMMARY_STATS = {
  number_of_companies: <SkeletonStatLine className="Stat-shortLine" />,
  total_invested: <SkeletonStatLine className="Stat-largeLine" />,
  total_valuation: <SkeletonStatLine className="Stat-largeLine" />,
  total_cash_received: <SkeletonStatLine className="Stat-largeLine" />,
  multiple: <SkeletonStatLine className="Stat-shortLine" />,
};

function SummaryStatsSkeleton() {
  return (
    <div className="SummaryStats">
      {Object.keys(SKELETON_SUMMARY_STATS).map((name, index) => (
        <SummaryStatsItem
          key={index}
          name={name}
          value={SKELETON_SUMMARY_STATS[name]}
        />
      ))}
    </div>
  );
}

export default SummaryStatsSkeleton;
