import React from "react";
import InvitationOrganizationNewUser from "components/auth/invitationToOrganization/newUser/InvitationOrganizationNewUser";
import InvitationOrganizationExistingUser from "components/auth/invitationToOrganization/existingUser/InvitationOrganizationExistingUser";
import PropTypes from "prop-types";
import LoginLayout from "components/auth/LoginLayout";
import { motion } from "framer-motion";
import { animationFromLeft } from "lib/animations";
import MightyLogo from "components_new/atoms/MightyLogo";
import { organizationProptype, currentUserPropType } from "PropTypes";
import { Navigate } from "react-router-dom";

class InvitationOrganization extends React.Component {
  state = {
    comeFromNewUser: false,
  };

  handleComeFromNewUser = () => {
    this.setState({ comeFromNewUser: true, nextPath: null });
  };

  UNSAFE_componentWillMount() {
    const {
      inviteCode,
      organizationMembershipId,
      verifyInvitation,
      privateOrganization,
    } = this.props;
    verifyInvitation(
      inviteCode,
      organizationMembershipId,
      privateOrganization,
    ).then(({ nextPath }) => {
      this.setState({ nextPath });
    });
  }

  render() {
    const {
      inviteCode,
      verifyCodeError,
      invitation,
      verifyCode,
      createAccountFromInvitation,
      forgotPassword,
      resendCode,
      isResendingCode,
    } = this.props;
    const { comeFromNewUser, nextPath } = this.state;
    if (!invitation.inviteCode) return null;

    if (nextPath) {
      return <Navigate to={nextPath} />;
    }

    return (
      <div className="u-row InvitationOrganization">
        <LoginLayout extraClassnames="InvitationLayout">
          <div className="FormSection Invitation-holder Container">
            <div className="InvitationForm-holder FormSection-group">
              <motion.div
                initial="hidden"
                animate="visible"
                variants={animationFromLeft()}
                transition={{ duration: 0.15 }}
              >
                <div className="LogoHolder">
                  <MightyLogo size="medium" />
                </div>
                {invitation.isExistingUser || comeFromNewUser ? (
                  <InvitationOrganizationExistingUser
                    email={invitation.inviteEmail}
                    organizationName={invitation.inviteOrganizationName}
                    organizationUserName={invitation.inviteOrganizationUsername}
                    organizationMembershipId={
                      invitation.inviteOrganizationMembershipId
                    }
                    forgotPassword={forgotPassword}
                    isRevokedInvite={invitation.inviteStatus === "revoked"}
                    comeFromNewUser={comeFromNewUser}
                  />
                ) : (
                  <InvitationOrganizationNewUser
                    email={invitation.inviteEmail}
                    organizationName={invitation.inviteOrganizationName}
                    organizationMembershipId={
                      invitation.inviteOrganizationMembershipId
                    }
                    verifyCode={verifyCode}
                    resendCode={resendCode}
                    isResendingCode={isResendingCode}
                    inviteCode={inviteCode}
                    createAccountFromInvitation={createAccountFromInvitation}
                    verifyCodeError={verifyCodeError}
                    isRevokedInvite={invitation.inviteStatus === "revoked"}
                    onComeFromNewUserClick={this.handleComeFromNewUser}
                  />
                )}
              </motion.div>
            </div>
          </div>
        </LoginLayout>
      </div>
    );
  }
}

InvitationOrganization.propTypes = {
  inviteCode: PropTypes.string.isRequired,
  verifyCodeError: PropTypes.bool,
  organizationMembershipId: PropTypes.string.isRequired,
  invitation: PropTypes.shape({
    inviteCode: PropTypes.string,
    isExistingUser: PropTypes.bool,
    inviteStatus: PropTypes.string,
    inviteOrganizationMembershipId: PropTypes.string,
    inviteOrganizationUsername: PropTypes.string,
    inviteOrganizationName: PropTypes.string,
    inviteEmail: PropTypes.string,
  }),
  verifyCode: PropTypes.func.isRequired,
  resendCode: PropTypes.func.isRequired,
  isResendingCode: PropTypes.bool.isRequired,
  verifyInvitation: PropTypes.func.isRequired,
  createAccountFromInvitation: PropTypes.func.isRequired,
  forgotPassword: PropTypes.func.isRequired,
  privateOrganization: organizationProptype,
  currentUser: currentUserPropType,
};

export default InvitationOrganization;
