import { useMutation, useQueryClient } from "react-query";
import Api from "lib/Api";

export function useRemoveDealDocument() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ document }) => Api.delete(`/documents/${document.id}`),
    {
      onSuccess: (_, { document: deletedDocument, dealId }) => {
        return queryClient.setQueryData(["Deal", dealId], (oldData) => ({
          ...oldData,
          documents: oldData.documents.filter(
            (document) => document.id !== deletedDocument.id,
          ),
        }));
      },
    },
  );
}
