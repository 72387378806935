import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FileRow from "components_new/elements/FileRow";
import FileCategory from "components_new/elements/FileCategory";
import formatDate from "lib/formatDate";
import { map, sortBy, groupBy } from "lodash";
import { documentPropType } from "PropTypes";

function InvestmentFiles({
  files,
  currentOrganizationUsername,
  setEditFile,
  setDeleteFile,
  resetFileActions,
  currentAction,
  editAction,
  deleteAction,
  modifiedFileId,
}) {
  const filesByInvestment = groupBy(files, "investment_id");

  return map(filesByInvestment, (currentFiles) => {
    const {
      investment_id: investmentId,
      investment_name: investmentName,
      investment_created_at: investmentCreatedAt,
    } = currentFiles[0];

    const sortedFiles = sortBy(currentFiles, ["created_at"]).reverse();

    const fileCategoryTitle = (
      <Fragment>
        {investmentName}{" "}
        <span> ({formatDate(investmentCreatedAt, "fullMonthAndYear")}) </span>
      </Fragment>
    );

    return (
      <FileCategory
        title={fileCategoryTitle}
        showFolderIcon={false}
        childFileCategory={true}
        key={investmentId}
        border="top"
      >
        {sortedFiles.map((tempFile) => (
          <FileRow
            key={tempFile.id}
            file={tempFile}
            setEditFile={setEditFile}
            setDeleteFile={setDeleteFile}
            resetFileActions={resetFileActions}
            currentAction={currentAction}
            editAction={editAction}
            deleteAction={deleteAction}
            modifiedFileId={modifiedFileId}
            currentOrganizationUsername={currentOrganizationUsername}
          />
        ))}
      </FileCategory>
    );
  });
}

InvestmentFiles.propTypes = {
  files: PropTypes.arrayOf(documentPropType).isRequired,
  currentOrganizationUsername: PropTypes.string.isRequired,
  setEditFile: PropTypes.func.isRequired,
  setDeleteFile: PropTypes.func.isRequired,
  resetFileActions: PropTypes.func.isRequired,
  currentAction: PropTypes.string,
  editAction: PropTypes.func.isRequired,
  deleteAction: PropTypes.func.isRequired,
  modifiedFileId: PropTypes.number,
};

export default InvestmentFiles;
