import React, { useContext } from "react";
import PropTypes from "prop-types";
import { investmentPropType } from "PropTypes";
import { companyRowPropType } from "components/shared/HoldingsTable/HoldingsTablePropTypes.js";
import createEditInvestmentActions from "lib/createEditInvestmentActions";
import Dropdown from "components/shared/Dropdown";
import HoldingsTableContext from "lib/hooks/HoldingsTableContext";
import useCurrentUser from "hooks/useCurrentUser";

const InvestmentActions = (props) => {
  const {
    investment,
    company,
    openLogAcquisitionSidebarForm,
    openLogWriteOffSidebarForm,
    openLogSaleSidebarForm,
    openLogStockSplitSidebarForm,
    openGlobalCapitalCallSidebarForm,
    openChangePortfolioOfInvestmentModal,
    openLogConversionSidebarForm,
    openDeleteInvestmentModal,
    openValuationSidebarForm,
    openEditInvestmentSidebarForm,
    dropdownPlaceholder,
    canShowDropDown,
    extraClassname,
    mainClassName,
    source,
    openLogDistributionSidebarForm,
    /**
     * remove me after deprecation of modal forms
     */
    initialComponent,
  } = props;

  const isCurrentUserCanary = useCurrentUser().is_canary;

  const {
    portfolio: portfolioByContext,
    company: companyByContext,
  } = useContext(HoldingsTableContext);
  if (
    investment &&
    portfolioByContext &&
    companyByContext &&
    ["companies", "portfolios"].includes(source)
  ) {
    investment.portfolio_id = portfolioByContext.id;
    investment.portfolio_name = portfolioByContext.name;

    if (source === "companies") {
      investment.company_id = companyByContext.id;
      investment.company_name = companyByContext.name;
    }

    if (source === "portfolios") {
      investment.company_id = company.id;
      investment.company_name = company.name;
    }
  }

  return (
    <div className={mainClassName}>
      {investment && canShowDropDown && (
        <div className={extraClassname}>
          <Dropdown
            items={createEditInvestmentActions({
              investment,
              openLogAcquisitionSidebarForm,
              openLogSaleSidebarForm,
              openLogWriteOffSidebarForm,
              openLogStockSplitSidebarForm,
              openGlobalCapitalCallSidebarForm,
              openChangePortfolioOfInvestmentModal,
              openDeleteInvestmentModal,
              openLogConversionSidebarForm,
              openValuationSidebarForm,
              openEditInvestmentSidebarForm,
              source,
              company,
              isCurrentUserCanary,
              openLogDistributionSidebarForm,
              initialComponent,
            })}
          >
            {dropdownPlaceholder}
          </Dropdown>
        </div>
      )}
    </div>
  );
};

InvestmentActions.propTypes = {
  investment: investmentPropType.isRequired,
  company: companyRowPropType,
  openLogAcquisitionSidebarForm: PropTypes.func.isRequired,
  openLogWriteOffSidebarForm: PropTypes.func.isRequired,
  openLogSaleSidebarForm: PropTypes.func.isRequired,
  openLogStockSplitSidebarForm: PropTypes.func.isRequired,
  openGlobalCapitalCallSidebarForm: PropTypes.func.isRequired,
  openChangePortfolioOfInvestmentModal: PropTypes.func.isRequired,
  openDeleteInvestmentModal: PropTypes.func.isRequired,
  openValuationSidebarForm: PropTypes.func.isRequired,
  openLogDistributionSidebarForm: PropTypes.func.isRequired,
  openEditInvestmentSidebarForm: PropTypes.func.isRequired,
  openLogConversionSidebarForm: PropTypes.func.isRequired,
  canShowDropDown: PropTypes.bool.isRequired,
  extraClassname: PropTypes.string,
  mainClassName: PropTypes.string,
  dropdownPlaceholder: PropTypes.node,
  source: PropTypes.oneOf(["companies", "holding", "portfolios"]),
  /**
   * remove me after deprecation of modal forms
   */
  initialComponent: PropTypes.string,
};

InvestmentActions.defaultProps = {
  canShowDropDown: true,
};

export default InvestmentActions;
