import React, { useCallback } from "react";
import ActiveDealView from "./ActiveDealView";
import useSidebar from "store/sidebar/hooks";
import useOrganizations from "store/organizations/hooks";

import { useDeal } from "components_new/views/Deals/hooks/queries/useDeal";
import { useUpdateDealStage } from "components_new/views/Deals/hooks/mutations/useUpdateDealStage";
import { useSaveDealDocument } from "components_new/views/Deals/hooks/mutations/useSaveDealDocument";
import { useUpdateDealDocument } from "components_new/views/Deals/hooks/mutations/useUpdateDealDocument";
import { useRemoveDealDocument } from "components_new/views/Deals/hooks/mutations/useRemoveDealDocument";
import { useSetSidebar } from "components_new/elements/SidebarWrapper/Sidebar-context";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { openModal } from "store/modalV2";
import useCurrentUser from "hooks/useCurrentUser";
import { useCompleteTask } from "components_new/views/Queues/hooks/mutations/useCompleteTask";
import { useMarkAsPending } from "components_new/views/Queues/hooks/mutations/useMarkAsPending";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import getMessage from "components_new/lib/Messages";

function buildOptionsFromStages(stages) {
  return stages.map((stage) => ({
    key: stage.id,
    value: stage.id,
    text: stage.name,
    order: stage.order,
  }));
}

function ActiveDealViewWrapper({ dealId, closeSidebar }) {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const {
    currentOrganization: {
      pipeline_id: pipelineId,
      username: currentOrganizationUsername,
    },
  } = useOrganizations();
  const { id: currentUserID } = useCurrentUser();
  const { openSidebar } = useSidebar();
  const { showSidebar } = useSetSidebar();
  const { addToast } = useSetToast();

  const { data, isLoading } = useDeal(pipelineId, dealId);
  const { mutateAsync: updateDealStage } = useUpdateDealStage();
  const { mutateAsync: saveDealDocument } = useSaveDealDocument();
  const { mutateAsync: updateDealDocument } = useUpdateDealDocument();
  const { mutateAsync: removeDealDocument } = useRemoveDealDocument();
  const { mutateAsync: completeTask } = useCompleteTask();
  const { mutateAsync: markAsPending } = useMarkAsPending();

  const invalidateQueriesCache = useCallback(() => {
    queryClient.invalidateQueries(["CommittedDeals", pipelineId]);
    queryClient.invalidateQueries(["PassedDeals", pipelineId]);
    queryClient.invalidateQueries(["Deal", dealId]);
    queryClient.invalidateQueries(["Pipeline", pipelineId]);
  }, [pipelineId, queryClient, dealId]);

  const invalidateAndCloseSidebar = useCallback(() => {
    queryClient.invalidateQueries(["CommittedDeals", pipelineId]);
    queryClient.invalidateQueries(["PassedDeals", pipelineId]);
    queryClient.invalidateQueries(["Pipeline", pipelineId]);
    queryClient.cancelQueries(["Deal", dealId]);
  }, [dealId, pipelineId, queryClient]);

  const handleEditCompany = (companyId, companyName) =>
    dispatch(
      openSidebar("globalCompanyForm", `Edit ${companyName}`, {
        entityId: companyId,
        callback: invalidateQueriesCache,
      }),
    );

  const handleOpenDeleteDealModal = useCallback(
    (dealId) =>
      dispatch(
        openModal("removeDeal", {
          dealId,
          pipelineId,
          callback: invalidateAndCloseSidebar,
        }),
      ),
    [dispatch, invalidateAndCloseSidebar, pipelineId],
  );

  const handleEditDeal = () =>
    dispatch(
      showSidebar("dealForm", {
        dealId,
        callback: invalidateQueriesCache,
      }),
    );

  const handleOpenReactivateDealModal = useCallback(
    (dealId) =>
      dispatch(
        openModal("reactivateDeal", {
          dealId,
          pipelineId,
          callback: invalidateAndCloseSidebar,
        }),
      ),
    [dispatch, invalidateAndCloseSidebar, pipelineId],
  );

  const handleOpenEditCommitmentModal = useCallback(
    (dealId) =>
      dispatch(
        openModal("commitToDeal", {
          dealId,
          pipelineId,
          callback: invalidateQueriesCache,
        }),
      ),
    [dispatch, invalidateQueriesCache, pipelineId],
  );

  const handleOpenEditPassModal = useCallback(
    (dealId) =>
      dispatch(
        openModal("passFromDeal", {
          dealId,
          pipelineId,
          callback: invalidateQueriesCache,
        }),
      ),
    [dispatch, invalidateQueriesCache, pipelineId],
  );

  const handleConvertToInvesment = (deal) =>
    dispatch(
      openSidebar("investmentForm", "", {
        deal,
        callback: invalidateAndCloseSidebar,
      }),
    );

  const handleOpenPassFromDealModal = useCallback(
    (dealId) =>
      dispatch(
        openModal("passFromDeal", {
          dealId,
          pipelineId,
          callback: invalidateAndCloseSidebar,
        }),
      ),
    [dispatch, invalidateAndCloseSidebar, pipelineId],
  );

  const handleOpenCommitToDealModal = useCallback(
    (dealId) =>
      dispatch(
        openModal("commitToDeal", {
          dealId,
          pipelineId,
          callback: invalidateAndCloseSidebar,
        }),
      ),
    [dispatch, invalidateAndCloseSidebar, pipelineId],
  );

  const handleUpdateDealDocument = (document) => {
    return updateDealDocument({ document, pipelineId, dealId });
  };

  const handleRemoveDealDocument = (document) => {
    return removeDealDocument({ document, pipelineId, dealId });
  };

  const openContactDetailsView = (contact) => {
    showSidebar("contactsView", { contactId: contact.id });
  };

  const handleCompleteTask = async (taskId) => {
    await completeTask({ taskId: taskId });
    addToast(getMessage("completeTask"));
  };

  const handlePendingTask = async (taskId) => {
    await markAsPending({ taskId: taskId });
    addToast(getMessage("pendingTask"));
  };

  const handleOpenTaskForm = (task) =>
    showSidebar("taskForm", {
      task,
      prefilledSelection: {
        id: dealId,
        type: "deal",
        name: data?.deal.title,
      },
      prefilledOwner: currentUserID,
    });

  return (
    <ActiveDealView
      isLoading={isLoading}
      deal={data?.deal}
      stage={data?.stage}
      company={data?.company}
      documents={data?.documents}
      stagesOptions={buildOptionsFromStages(data?.stages || [])}
      openEditCompanyForm={handleEditCompany}
      openDeleteDealModal={handleOpenDeleteDealModal}
      openEditDealForm={handleEditDeal}
      saveDealDocuments={saveDealDocument}
      removeFile={handleRemoveDealDocument}
      updateFile={handleUpdateDealDocument}
      updateDealStage={updateDealStage}
      openReactivateDealModal={handleOpenReactivateDealModal}
      openEditCommitmentModal={handleOpenEditCommitmentModal}
      openEditPassModal={handleOpenEditPassModal}
      onConvertToInvesment={handleConvertToInvesment}
      currentOrganizationUsername={currentOrganizationUsername}
      openCommitToDealModal={handleOpenCommitToDealModal}
      openPassFromDealModal={handleOpenPassFromDealModal}
      posts={data?.posts}
      closeSidebar={closeSidebar}
      openContactDetailsView={openContactDetailsView}
      onCompleteDealTask={handleCompleteTask}
      onMovePendingDealTask={handlePendingTask}
      openTaskForm={handleOpenTaskForm}
    />
  );
}

export default ActiveDealViewWrapper;
