import React from "react";
import PrimaryButton from "components/shared/PrimaryButton";
import SecondaryButton from "components/shared/SecondaryButton";
import PropTypes from "prop-types";
import { ModalBody, ModalHeader } from "components/shared/Modal";
import DefaultCompanyIcon from "-!svg-react-loader?!assets/icons/primary/company.svg?name=DefaultCompanyIcon";

function AcceptFromOtherInvitation(props) {
  const { closeModal, invitation, acceptInvitationToOrganization } = props;

  return (
    <div className="CreatedOrganization">
      <ModalHeader />
      <ModalBody type="announcement">
        <DefaultCompanyIcon className="CreatedOrganization-icon" />
        <h1 className="CreatedOrganization-title u-heading">
          Join Organization?
        </h1>
        <p className="CreatedOrganization-message">
          You're accepting an invitation sent to{" "}
          <strong>{invitation.inviteEmail}</strong> to collaborate with{" "}
          <strong>{invitation.inviteOrganizationName}</strong> on Mighty, but
          you're currently logged in using a different email address. Would you
          like to proceed?
        </p>
        <div className="ModalInvitation-actionButtons">
          <PrimaryButton
            onClick={() => {
              acceptInvitationToOrganization(
                invitation.inviteOrganizationUsername,
                invitation.inviteOrganizationMembershipId,
              );
            }}
            autoFocus
          >
            Accept Invitation
          </PrimaryButton>
          <SecondaryButton
            onClick={() => {
              closeModal();
            }}
            autoFocus
          >
            Decline
          </SecondaryButton>
        </div>
      </ModalBody>
    </div>
  );
}

AcceptFromOtherInvitation.propTypes = {
  closeModal: PropTypes.func,
  currentOrganizationUsername: PropTypes.string,
  acceptInvitationToOrganization: PropTypes.func.isRequired,
  invitation: PropTypes.shape({
    inviteEmail: PropTypes.string,
    inviteOrganizationName: PropTypes.string,
    inviteOrganizationUsername: PropTypes.string,
    inviteOrganizationMembershipId: PropTypes.string,
  }),
};

export default AcceptFromOtherInvitation;
