import Api from "lib/Api";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { updateTaskInScope } from "../shared/updateTaskInScope";
import { removeTaskFromScope } from "../shared/removeTaskFromScope";

export function useCompleteTask() {
  const queryClient = useQueryClient();
  const { organizationUsername } = useParams();

  return useMutation(({ taskId }) => Api.patch(`/tasks/${taskId}/complete`), {
    onSuccess: ({ data: updatedTask }) => {
      const params = {
        taskId: updatedTask.id,
        updatedTask,
        queryClient,
        organizationUsername,
      };

      updateTaskInScope({ scope: "completed", ...params });
      removeTaskFromScope({ scope: "pending", ...params });
      removeTaskFromScope({ scope: "snoozed", ...params });

      queryClient.invalidateQueries(["QueuesView", organizationUsername]);

      if (updatedTask.taskable_type == "Deal") {
        queryClient.invalidateQueries(["Deal", updatedTask.taskable_id]);
      }
    },
    onError: (error) => {
      throw error;
    },
  });
}
