import { connect } from "react-redux";
import ConfirmationModal from "components/shared/ConfirmationModal";
import { ModalNames, closeModal } from "store/modal";

function mapStateToProps(state) {
  const options = state.modal.options[ModalNames.removeDocumentConfirmation];
  const givenDocument = options && options.doc;
  const onRemove = options && options.onRemove;

  return {
    doc: givenDocument,
    onRemove: onRemove,
    title: "Delete Confirmation",
    message:
      givenDocument &&
      `Are you sure you want to delete ${givenDocument.filename}?`,
    confirmButtonLabel: "Delete",
    submitting: false,
  };
}

function mergeProps(stateProps, dispatchProps) {
  const { doc, onRemove } = stateProps;
  const { dispatch } = dispatchProps;

  const newProps = {
    action: () => {
      onRemove(doc.handle);
      dispatch(closeModal());
    },
  };

  return {
    ...stateProps,
    ...newProps,
  };
}

export default connect(mapStateToProps, null, mergeProps)(ConfirmationModal);
