import classNames from "classnames";
import Icon from "components_new/atoms/Icon";
import SidebarList from "components_new/elements/SidebarList";
import useCurrentUser from "hooks/useCurrentUser";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import useOrganizations from "store/organizations/hooks";
import "./SidebarMenu.scss";

const V2_DASHBOARD = ["v2/dashboard"];

function isActiveUrl(currentPathname, pathname) {
  const arrayPath = currentPathname.split("/");

  if (V2_DASHBOARD.includes(pathname)) {
    return `${arrayPath[2]}/${arrayPath[3]}` === pathname;
  }
  //TODO we need to rollback when we finish the dashbord v2: currentPathname.split("/").includes(pathname);
  return arrayPath[2] == pathname;
}

function SidebarMenu() {
  const { currentOrganization } = useOrganizations();
  const { pathname: currentPath } = useLocation();
  const { username: currentOrganizationPathname } = currentOrganization;
  const { is_canary: isCanary } = useCurrentUser();

  // We need to do this, because if we use the CustomLink component, we are going to have problems to add it into the storybook, because of the connect-redux
  const addCurrentOrganizationPathname = (pathname) => {
    return `/${currentOrganizationPathname}/${pathname}`;
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const expandSidebar = () => {
    setIsExpanded(!isExpanded);
  };
  const expanderClassname = classNames({
    isExpanded: isExpanded,
  });

  const sidebarConfig = [
    {
      linkTo: addCurrentOrganizationPathname("dashboard"),
      isActive: isActiveUrl(currentPath, "dashboard"),
      isDisabled: false,
      isMightyIcon: true,
      iconName: "dashboard-sm",
      name: "Dashboard",
    },
    isCanary && {
      linkTo: addCurrentOrganizationPathname("v2/dashboard"),
      isActive: isActiveUrl(currentPath, "v2/dashboard"),
      isDisabled: false,
      isMightyIcon: true,
      iconName: "dashboard-sm",
      name: "Dashboard (beta)",
      isBeta: true,
    },
    {
      linkTo: addCurrentOrganizationPathname("queues/pending"),
      isActive: isActiveUrl(currentPath, "queues"),
      isDisabled: false,
      isMightyIcon: true,
      iconName: "queue-sm",
      name: "Queue",
    },
    {
      linkTo: addCurrentOrganizationPathname("portfolios/all"),
      isActive: isActiveUrl(currentPath, "portfolios"),
      isDisabled: false,
      isMightyIcon: true,
      iconName: "investment-sm",
      name: "Investments",
    },
    {
      linkTo: addCurrentOrganizationPathname("deals"),
      isActive: isActiveUrl(currentPath, "deals"),
      isDisabled: false,
      isMightyIcon: true,
      iconName: "deal-sm",
      name: "Deals",
    },
    {
      linkTo: addCurrentOrganizationPathname("companydirectory"),
      isActive: isActiveUrl(currentPath, "companydirectory"),
      isDisabled: false,
      isMightyIcon: true,
      iconName: "company-sm",
      name: "Companies",
    },
    {
      linkTo: addCurrentOrganizationPathname("reports/snapshots"),
      isActive: isActiveUrl(currentPath, "reports"),
      isDisabled: false,
      isMightyIcon: true,
      iconName: "report-sm",
      name: "Reports",
    },
    {
      linkTo: addCurrentOrganizationPathname("contacts"),
      isActive: isActiveUrl(currentPath, "contacts"),
      isDisabled: false,
      isMightyIcon: true,
      iconName: "contact-sm",
      name: "Contacts",
    },
    {
      linkTo: addCurrentOrganizationPathname("settings"),
      isActive: isActiveUrl(currentPath, "settings"),
      isDisabled: false,
      isMightyIcon: false,
      hasDivided: true,
      iconName: "ri-settings-2-line",
      name: "Settings",
    },
  ].filter(Boolean);

  return (
    <nav className="SidebarMenu">
      <SidebarList list={sidebarConfig} isExpanded={isExpanded} />
      <div className="SidebarMenuFooter">
        <a className={expanderClassname} onClick={() => expandSidebar(true)}>
          <Icon icon="ri-arrow-right-line" />
        </a>
      </div>
    </nav>
  );
}

export default SidebarMenu;
