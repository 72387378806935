import React from "react";
import { connect } from "react-redux";
import { orderBy } from "lodash";

import { getCollection } from "store/domain/selectors";
import { fetchReports } from "store/reports/actions";
import { getExport } from "store/exports/actions.js";

import View from "components/shared/View";
import ReportsView from "./ReportsView";

const mapState = (state) => {
  const { currentOrganizationName } = state.organizations;
  const reports = orderBy(getCollection(state, "reports"), ["id"], ["asc"]);
  return {
    currentOrganizationName,
    reports,
  };
};

const mapDispatch = (dispatch) => {
  return {
    fetchReports: () => dispatch(fetchReports()),
    getExport: (url, options) => getExport(url, options),
  };
};

const renderReportsView = (props) => {
  return (
    <View
      mountAction={() => props.fetchReports()}
      component={ReportsView}
      {...props}
    />
  );
};

export default connect(mapState, mapDispatch)(renderReportsView);
