import React, { useEffect, useState } from "react";
import Button from "components_new/atoms/Button";
import ButtonGroup from "components_new/elements/ButtonGroup";
import Icon from "components_new/atoms/Icon";
import { isEmpty } from "lodash";
import "./QuickFilterButtonGroup.scss";

export default function QuickFilterButtonGroup({ applyFilters, filters }) {
  const [activeFilter, setActiveFilter] = useState("all");

  useEffect(() => {
    if (isEmpty(filters)) {
      setActiveFilter("all");
    }
  }, [filters]);

  useEffect(() => {
    switch (activeFilter) {
      case "all":
        applyFilters({ flags: null, activity: null });
        break;
      case "company_activity":
      case "investment_activity":
        applyFilters({ flags: null, activity: activeFilter });
        break;
      case "interesting":
      case "double_down":
      case "stealth":
        applyFilters({ flags: activeFilter, activity: null });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilter]);

  return (
    <ButtonGroup size="small" extraClassnames="QuickFilterButtonGroup">
      <Button
        data-testid="QuickFilter-all"
        size="small"
        secondary
        title="All"
        toggle
        active={activeFilter === "all"}
        onClick={() => setActiveFilter("all")}
      >
        All
      </Button>
      <Button
        data-testid="QuickFilter-interesting"
        size="small"
        secondary
        title="Interesting"
        toggle
        active={activeFilter === "interesting"}
        onClick={() => setActiveFilter("interesting")}
        icon
      >
        <Icon icon="ri-fire-fill" size="small" color="orange" />
      </Button>
      <Button
        data-testid="QuickFilter-doubleDown"
        size="small"
        secondary
        title="Double down"
        toggle
        active={activeFilter === "double_down"}
        onClick={() => setActiveFilter("double_down")}
        icon
      >
        <Icon icon="ri-rocket-fill" size="small" color="green" />
      </Button>
      <Button
        data-testid="QuickFilter-stealth"
        size="small"
        secondary
        title="Stealth"
        toggle
        active={activeFilter === "stealth"}
        onClick={() => setActiveFilter("stealth")}
        icon
      >
        <Icon icon="ri-eye-off-fill" size="small" color="purple" />
      </Button>
      <Button
        data-testid="QuickFilter-companyActivity"
        size="small"
        secondary
        title="Activity Status"
        toggle
        active={activeFilter === "company_activity"}
        onClick={() => setActiveFilter("company_activity")}
        icon
      >
        <Icon icon="ri-pushpin-fill" size="small" />
      </Button>
      <Button
        data-testid="QuickFilter-investmentActivity"
        size="small"
        secondary
        title="Recent Investments"
        toggle
        active={activeFilter === "investment_activity"}
        onClick={() => setActiveFilter("investment_activity")}
        icon
      >
        <Icon icon="ri-history-line" size="small" />
      </Button>
    </ButtonGroup>
  );
}
