import React, { Fragment } from "react";
import formatDate from "lib/formatDate";
import differenceInDate from "lib/differenceInDate";
import { hasCustomFieldsValues } from "components/investments/InvestmentDetails/components/InvestmentDetailsList.js";
import InlineValueList from "components_new/atoms/InlineValueList";
import CustomDetails from "components_new/views/Deals/ActiveDeals/CustomDetails";
import Link from "components_new/atoms/Link";
import "./ActiveDealAttributes.scss";

function buildCommaList(list, displayKey) {
  return list.map((item, index) => {
    return `${displayKey ? item[displayKey] : item}${
      index + 1 < list.length ? ", " : ""
    }`;
  });
}

function buildReferrerList(referrers, openContactDetailsView) {
  return referrers.map((referrer, index) => (
    <Fragment>
      <a
        key={referrer.id}
        onClick={() => openContactDetailsView(referrer)}
        className="SelectedDeal-Details-Attributes-ReferrerLink"
      >
        {referrer.full_name}
      </a>
      {index + 1 < referrers.length ? ", " : ""}
    </Fragment>
  ));
}

function buildInvestorList(investors, currentOrganizationUsername) {
  return investors.map((investor, index) => (
    <Fragment>
      <Link
        key={investor.id}
        to={`/${currentOrganizationUsername}/companies/${investor.id}`}
      >
        {investor.name}
      </Link>
      {index + 1 < investors.length ? ", " : ""}
    </Fragment>
  ));
}

function getDealDetailsAlpha(
  deal,
  openContactDetailsView,
  currentOrganizationUsername,
) {
  return [
    {
      label: "Referrer",
      value: buildReferrerList(deal.referrers, openContactDetailsView),
    },
    {
      label: "Founder",
      value: deal.company.founders,
    },
    {
      label: "Investors",
      value: buildInvestorList(
        deal.investor_companies,
        currentOrganizationUsername,
      ),
    },
  ];
}

function getDealDetailsBeta(deal, timeSinceCreated) {
  return [
    { label: "Owner", value: deal.owner_name },
    {
      label: "Date received",
      value: formatDate(deal.created_at, "semiDetailed"),
    },
    {
      label: "Last updated",
      value: formatDate(deal.updated_at, "semiDetailed"),
    },
    {
      label: "Days in pipeline",
      value: timeSinceCreated ? timeSinceCreated.toString() : null, //For past or committ deals we dont have days in stage.
    },
    {
      label: "Tags",
      value: buildCommaList(deal.company.tags, "name"),
    },
  ];
}

function ActiveDealAttributes({
  deal,
  openContactDetailsView,
  currentOrganizationUsername,
}) {
  const dealDetailsAlpha = getDealDetailsAlpha(
    deal,
    openContactDetailsView,
    currentOrganizationUsername,
  );
  const timeSinceCreated = differenceInDate(deal.created_at);
  const dealDetailsBeta = getDealDetailsBeta(deal, timeSinceCreated);
  const customFields = Object.values(deal.custom_fields);
  const hasCustomFields = hasCustomFieldsValues(customFields);
  return (
    <Fragment>
      <div className="SelectedDeal-Details-Attributes">
        <div className="SelectedDeal-Details-Attributes-Alpha">
          <InlineValueList data={dealDetailsAlpha} />
        </div>
        <div className="SelectedDeal-Details-Attributes-Beta">
          <InlineValueList data={dealDetailsBeta} />
        </div>
      </div>
      {hasCustomFields && <CustomDetails details={customFields} />}
    </Fragment>
  );
}

export default ActiveDealAttributes;
