import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Icon from "components_new/atoms/Icon";
import classnames from "classnames";
import Text from "components_new/atoms/Text";
import "./ReferrerList.scss";

function ReferrerList({ referrers = [], iconSize = "mini" }) {
  return (
    <div className="ReferrerList">
      <Icon icon="ri-user-received-line" size={iconSize} />
      {referrers.length === 0 ? (
        "No referrer yet"
      ) : (
        <Text as="span" type="label-bold" color="brand-light">
          {referrers &&
            referrers.map((referrer, index) => {
              return (
                <Fragment
                  key={`referrerlistreferrer-${referrer.name}-${index}`}
                >
                  <span
                    className={classnames("ReferrerList-Referrer", {
                      clickable: !!referrer.onClick,
                    })}
                    onClick={referrer.onClick}
                  >
                    {referrer.full_name}
                  </span>
                  {index + 1 < referrers.length ? ", " : ""}
                </Fragment>
              );
            })}
        </Text>
      )}
    </div>
  );
}

ReferrerList.propTypes = {
  referrers: PropTypes.arrayOf(PropTypes.object),
  iconSize: PropTypes.string,
};

export default ReferrerList;
