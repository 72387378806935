import React from "react";
import { Droppable } from "react-beautiful-dnd";
import classnames from "classnames";
import "./DraggableList.scss";

function DraggableList({
  listId,
  listType,
  direction,
  isCombineEnabled,
  children,
  extraClassnames,
}) {
  const draggableListContainerClassname = classnames("DraggableList", {
    [extraClassnames]: !!extraClassnames,
  });
  return (
    <div className={draggableListContainerClassname} key={listId}>
      <Droppable
        droppableId={listId}
        type={listType}
        isCombineEnabled={isCombineEnabled}
        direction={direction}
      >
        {(provided) => {
          return (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {children}
              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </div>
  );
}

export default DraggableList;
