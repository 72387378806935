import { RECEIVE_SLACK_CHANNELS } from "./actionTypes";
const initialState = {
  channels: [],
};

function integrationsReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_SLACK_CHANNELS:
      return {
        ...state,
        channels: [...state.channels, ...action.channels],
      };
    default:
      return state;
  }
}

export default integrationsReducer;
