import EquityForm from "./EquityForm";
import DebtForm from "./DebtForm";
import WarrantForm from "./WarrantForm";
import LlcForm from "./LlcForm";
import OptionForm from "./OptionForm";
import FundForm from "./FundForm";
import SAFEForm from "./SAFEForm";

export const createPortfolioDropdownItems = (portfolios = []) => {
  return portfolios.map((portfolio, i) => ({
    key: i,
    text: portfolio.name,
    value: portfolio.id,
  }));
};

export const investmentTypeDropdownItems = [
  { key: 0, value: "equity", text: "Equity" },
  { key: 1, value: "debt", text: "Debt" },
  { key: 2, value: "fund", text: "Fund" },
  { key: 3, value: "llc", text: "LLC" },
  { key: 4, value: "option", text: "Option" },
  { key: 5, value: "warrant", text: "Warrant" },
  { key: 6, value: "safe", text: "SAFE" },
];

export const investmentDetailsMapping = {
  equity: EquityForm,
  warrant: WarrantForm,
  llc: LlcForm,
  fund: FundForm,
  option: OptionForm,
  safe: SAFEForm,
  debt: DebtForm,
};
