import React, { Fragment } from "react";
import { placeholderDateFormat } from "config/dateInput";
import NumberInput from "components/shared/form/NumberInput";
import DatePicker from "components_new/atoms/DatePicker";
import HelperContent from "components/shared/HelperContent";

function LogStockSplitForm({ formData, updateForm, historyAction, errors }) {
  const { date_helper_text: dateHelperContent } = historyAction;
  return (
    <Fragment>
      <DatePicker
        name="date"
        label="Date of stock split"
        placeholder={placeholderDateFormat}
        value={formData.date}
        onChange={(value) => updateForm("date", value)}
        helperContent={() => <HelperContent content={dateHelperContent} />}
        showHelper={!!dateHelperContent}
        extraClassnames="FormElement FormElement--Date"
        error={!!errors.for("date")}
        errorMessage={errors.for("date")}
        size="small"
        oldVersion
      />
      <NumberInput
        name="split_ratio_from"
        label="Split Ratio From"
        placeholder={formData.split_ratio_from}
        onChange={(value) => updateForm("split_ratio_from", value)}
        value={formData.split_ratio_from}
        errorMessage={errors.for("ratios")}
        size="small"
      />
      <NumberInput
        name="split_ratio_to"
        label="Split Ratio To"
        placeholder={formData.split_ratio_to}
        onChange={(value) => updateForm("split_ratio_to", value)}
        value={formData.split_ratio_to}
        errorMessage={errors.for("ratios")}
        size="small"
      />
    </Fragment>
  );
}

export default LogStockSplitForm;
