import React from "react";
import Link from "components_new/atoms/Link";
import formatPrice from "lib/formatters/formatPrice";
import formatNumber from "lib/formatters/formatNumber";
import formatDate from "lib/formatDate";
import formatAttributeType from "lib/formatAttributeType";
import formatFieldTypeClassnames from "lib/events/formatFieldTypeClassnames";
import { capitalize, isEmpty, find } from "lodash";
import classnames from "classnames";
import { distributionTypes } from "config/logDistributionConfig";
import { getText } from "TermsMapper";
import Linkify from "react-linkify";
import {
  perUnitNumberPrecision,
  defaultNumberPrecision,
} from "config/priceInput";
import isValidNumberInput from "lib/formatters/isValidNumberInput";
import CompanyIconWithBadge from "components/shared/CompanyIconWithBadge";
import VerifiedBadge from "components/shared/VerifiedBadge";
import ActivityEventDocumentIcon from "./ActivityEventDocumentIcon";

/* eslint react/prop-types: "off" */
import PropTypes from "prop-types";

import {
  investmentEventPropType,
  companyPropType,
  investmentPropType,
} from "PropTypes";

// TODO: find a better place to put this map, similar to the
// backend mappings.
const QUANTITY_LABEL_MAP = {
  equity: "Shares purchased",
  llc: "Units purchased",
  option: "Options received",
  warrant: "Warrants purchased",
};

const renderPortfolioNamesFromPortfolios = (portfolios) =>
  portfolios.map((portfolio, index) => {
    if (index + 1 === portfolios.length) {
      return portfolio.name;
    }
    return `${portfolio.name}, `;
  });

const removeConvertedAndFromNoteText = (text) =>
  text.replace(/ \(From Note\)/, "").replace(/ \(Converted\)/, "");

const renderActivityObjectInfo = ({
  company,
  companyName,
  companyId,
  investmentId,
  investmentName,
  portfolios,
  investment,
  verified,
  organizationUsername,
}) => {
  const renderInvestment = investment ? (
    <Link
      extraClassnames="u-link u-textWithBreakWord u-textHoverColor-utility-2 u-noTextDecoration"
      to={`/${organizationUsername}/investments/${investmentId}`}
    >
      {investmentName}
    </Link>
  ) : (
    <span className="ActivityObject-copyEmphasis">{investmentName}</span>
  );

  return (
    <div className="u-displayInlineBlock ActivityObject-info">
      <div className="ActivityObject-companyLogo">
        <CompanyIconWithBadge company={company} />
      </div>
      <div className="u-displayInlineBlock ActivityObject-basicInfo">
        <Link
          extraClassnames="u-link u-textWithBreakWord u-textHoverColor-utility-2 u-noTextDecoration CompanyNameWithBudge"
          to={`/${organizationUsername}/companies/${companyId}/investments`}
        >
          {companyName}
          {verified && <VerifiedBadge />}{" "}
        </Link>
        {investmentName && <span> · </span>}
        {investmentName && renderInvestment}
        {portfolios && (
          <p className="u-textWithBreakWord ActivityObject-companyName">
            {renderPortfolioNamesFromPortfolios(portfolios)}
          </p>
        )}
      </div>
    </div>
  );
};

const RenderUpdatedExtraInfo = ({ fieldName, previousValue, newValue }) => {
  return (
    <div className="ActivityObject has-extraInfo">
      <div className="ActivityObject-extraInfo has-indentation">
        {previousValue && (
          <p className="ActivityObject-copyStriked">
            {fieldName}:{" "}
            <span className="ActivityObject-copyEmphasis">{previousValue}</span>
          </p>
        )}

        {newValue && (
          <p className="u-textWithLineBreaks">
            New {fieldName}:{" "}
            <span className="ActivityObject-copyEmphasis">{newValue}</span>
          </p>
        )}
      </div>
    </div>
  );
};

const renderLoggedDistributionType = (isCashProceed, isEquityProceed) => {
  if (isCashProceed && isEquityProceed) return "Cash & Equity";
  if (isCashProceed && !isEquityProceed) return "Cash";
  if (!isCashProceed && isEquityProceed) return "Equity";
};

const renderCreatedInvestment = (
  {
    company,
    event: {
      params: {
        investment_amount: investmentValuation,
        investment_company_id: companyId,
        investment_id: investmentId,
        investment_type: investmentType,
        investment_name: investmentName,
        investment_portfolio_name: portfolioName,
      },
    },
    investment,
  },
  organizationUsername,
) => {
  const { name: companyName, verified } = company;

  return (
    <div className="ActivityObject">
      <div className="ActivityObject-header">
        {renderActivityObjectInfo({
          company,
          companyName,
          companyId,
          investmentId,
          investmentName,
          portfolios: [{ name: portfolioName }],
          investment,
          verified,
          organizationUsername,
        })}
        <div className="u-displayInlineBlock ActivityObject-valuation">
          <span className="ActivityObject-copyEmphasis">
            {getText(investmentType, "total_valuation_amount")}
          </span>
          <p className="ActivityObject-copy">
            {isValidNumberInput(investmentValuation)
              ? formatPrice(investmentValuation)
              : ""}
          </p>
        </div>
      </div>
    </div>
  );
};

const renderUpdatedInvestment = (
  {
    company,
    event: {
      params: {
        updated_investment_fields: updatedInvestmentFields,
        investment_company_id: companyId,
        investment_id: investmentId,
        investment_name: investmentName,
        investment_portfolio_name: portfolioName,
      },
    },
    investment,
  },
  organizationUsername,
) => {
  const { name: companyName, verified } = company;

  return (
    <div className="ActivityObject has-extraInfo">
      <div className="ActivityObject-header">
        {renderActivityObjectInfo({
          company,
          companyName,
          companyId,
          investmentId,
          investmentName,
          portfolios: [{ name: portfolioName }],
          investment,
          verified,
          organizationUsername,
        })}
      </div>
      <div className="u-textWithLineBreaks ActivityObject-extraInfo has-indentation">
        {updatedInvestmentFields.map((field) => {
          const {
            after,
            attribute_type: attributeType,
            attribute,
            before,
          } = field;
          const attributeClassnames = classnames(
            formatFieldTypeClassnames({
              after,
              before,
              createOrUpdateClassname: "ActivityObject-copy",
              deleteClassname: "ActivityObject-copyStriked",
            }),
            {
              ActivityObjectContentHolder: true,
            },
          );
          const attributeNameClassnames = classnames(
            formatFieldTypeClassnames({
              after,
              before,
              createOrUpdateClassname: "ActivityObject-copyEmphasis",
            }),
          );
          const isAttributeTypeString = attributeType === "string";
          const isNewValueBlank = after === "" || after == null;
          const isPastValueBlank = before === "";
          const activityContent = (
            <div>
              {attribute === "quantity"
                ? QUANTITY_LABEL_MAP[investment.type]
                : capitalize(attribute)}
              :
              <span className={attributeNameClassnames}>
                {isAttributeTypeString && isNewValueBlank && isPastValueBlank
                  ? " No notes added"
                  : formatAttributeType(
                      after || before,
                      attributeType,
                      attribute,
                    )}
              </span>
            </div>
          );
          return (
            <div key={attribute} className={attributeClassnames}>
              <Linkify
                properties={{ target: "_blank", rel: "nofollow noopener" }}
              >
                {activityContent}
              </Linkify>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const renderSaleLogged = (
  {
    company,
    event: {
      company_id: companyId,
      params: {
        investment_name: investmentName,
        investment_id: investmentId,
        investment_portfolio_name: portfolioName,
        buyer_name: buyerName,
        quantity,
        price_per_unit: pricePerUnit,
        valuation_unit: valuationUnit,
        total_price: totalPrice,
        notes,
        uploaded_files: uploadedFiles,
      },
    },
    investment,
  },
  organizationUsername,
) => {
  const { name: companyName, verified } = company;

  return (
    <div>
      <div className="ActivityObject has-extraInfo has-loggedDocuments">
        <div className="ActivityObject-header">
          {renderActivityObjectInfo({
            company,
            companyName,
            companyId,
            investmentId,
            investmentName,
            portfolios: [{ name: portfolioName }],
            investment,
            verified,
            organizationUsername,
          })}
        </div>
        <div className="ActivityObject-extraInfo has-indentation">
          <p className="u-textWithLineBreaks">
            Buyer:{" "}
            <span className="ActivityObject-copyEmphasis">{buyerName}</span>
          </p>
          <p className="u-textWithLineBreaks">
            Number of {valuationUnit}s:
            <span className="ActivityObject-copyEmphasis">
              {formatAttributeType(quantity, "integer")}
            </span>
          </p>
          <p className="u-textWithLineBreaks">
            Sale amount:
            <span className="ActivityObject-copyEmphasis">
              {`${formatAttributeType(totalPrice, "decimal")} (${
                isValidNumberInput(pricePerUnit)
                  ? formatPrice(pricePerUnit, {
                      format: { neg: "%s%v" },
                      precision: perUnitNumberPrecision,
                    })
                  : ""
              } / ${valuationUnit})`}
            </span>
          </p>
          {notes && (
            <p className="u-textWithLineBreaks">
              <Linkify
                properties={{ target: "_blank", rel: "nofollow noopener" }}
              >
                {notes}
              </Linkify>
            </p>
          )}
        </div>
      </div>
      {!isEmpty(uploadedFiles) &&
        renderMultipleDocuments({
          uploadedFiles: uploadedFiles,
          activityObjectClassnames: "LoggedDocumentsActivityObject",
          investmentId,
        })}
    </div>
  );
};

const renderWrittenOff = (
  {
    company,
    event: {
      company_id: companyId,
      params: {
        investment_name: investmentName,
        investment_id: investmentId,
        investment_portfolio_name: portfolioName,
        cash_amount: cashAmount,
        notes,
        uploaded_files: uploadedFiles,
      },
    },
    investment,
  },
  organizationUsername,
) => {
  const { name: companyName, verified } = company;
  const activityObjectClassnames = classnames("ActivityObject", {
    "has-extraInfo": cashAmount || notes,
    "has-loggedDocuments": !isEmpty(uploadedFiles),
  });
  return (
    <div>
      <div className={activityObjectClassnames}>
        <div className="ActivityObject-header">
          {renderActivityObjectInfo({
            company,
            companyName,
            companyId,
            investmentId,
            investmentName,
            portfolios: [{ name: portfolioName }],
            investment,
            verified,
            organizationUsername,
          })}
        </div>
        {(cashAmount || notes) && (
          <div className="ActivityObject-extraInfo has-indentation">
            {cashAmount && (
              <p className="u-textWithLineBreaks">
                Cash returned:{" "}
                <span className="ActivityObject-copyEmphasis">
                  {formatPrice(cashAmount, {
                    format: { neg: "%s%v" },
                    precision: defaultNumberPrecision,
                  })}
                </span>
              </p>
            )}
            {notes && (
              <p className="u-textWithLineBreaks">
                Notes:{" "}
                <Linkify
                  properties={{ target: "_blank", rel: "nofollow noopener" }}
                >
                  {notes}
                </Linkify>
              </p>
            )}
          </div>
        )}
      </div>
      {!isEmpty(uploadedFiles) &&
        renderMultipleDocuments({
          uploadedFiles: uploadedFiles,
          activityObjectClassnames: "LoggedDocumentsActivityObject",
          investmentId,
        })}
    </div>
  );
};

const renderMovedDocument = ({ event: { params } }, organizationUsername) => {
  const { file_name: documentName, id } = params;

  return (
    <div className="ActivityObject has-document">
      <span className="ActivityObject-documentIcon">
        <ActivityEventDocumentIcon doc={params} size="large" />
      </span>
      <Link
        extraClassnames="ActivityObject-documentName u-textWithBreakWord u-link u-textHoverColor-utility-2 u-noTextDecoration"
        to={`/${organizationUsername}/documents/${id}/download`}
      >
        {documentName}
      </Link>
    </div>
  );
};

const renderDocumentActivityItem = (
  attributeLabel,
  itemClassName,
  key,
  attributeValue,
  attributeType,
  attribute,
) => (
  <p className={`ActivityObject-${itemClassName}`} key={key}>
    {attributeLabel}:
    <span className="ActivityObject-copyEmphasis">
      {formatAttributeType(attributeValue, attributeType, attribute)}
    </span>
  </p>
);

//TODO: Use map to iterate updated fields.
const renderUpdatedDocument = ({ event: { params } }, organizationUsername) => {
  let changedFields = [];
  const {
    document_id: documentId,
    file_name: fileName,
    updated_document_fields: updatedDocFields,
  } = params;
  const filename = find(updatedDocFields, { attribute: "file name" });
  const closingDocument = find(updatedDocFields, {
    attribute: "closing document",
  });
  const documentType = find(updatedDocFields, {
    attribute: "document type",
  });

  if (filename) {
    changedFields.push(
      renderDocumentActivityItem(
        "Name",
        "copyStriked",
        changedFields.length,
        filename.before,
        filename.attribute_type,
        filename.attribute,
      ),
    );

    changedFields.push(
      renderDocumentActivityItem(
        "New Name",
        "copy",
        changedFields.length,
        filename.after,
        filename.attribute_type,
        filename.attribute,
      ),
    );
  }

  if (closingDocument) {
    changedFields.push(
      renderDocumentActivityItem(
        "Closing Document",
        "copyStriked",
        changedFields.length,
        closingDocument.before,
        closingDocument.attribute_type,
        closingDocument.attribute,
      ),
    );

    changedFields.push(
      renderDocumentActivityItem(
        "Closing Document",
        "copy",
        changedFields.length,
        closingDocument.after,
        closingDocument.attribute_type,
        closingDocument.attribute,
      ),
    );
  }

  if (documentType) {
    const { before, after, attribute_type, attribute } = documentType;

    if (documentType.before) {
      changedFields.push(
        renderDocumentActivityItem(
          "Category",
          "copyStriked",
          changedFields.length,
          before,
          attribute_type,
          attribute,
        ),
      );

      changedFields.push(
        renderDocumentActivityItem(
          "New Category",
          "copy",
          changedFields.length,
          after,
          attribute_type,
          attribute,
        ),
      );
    } else {
      changedFields.push(
        renderDocumentActivityItem(
          "Category",
          "copy",
          changedFields.length,
          after,
          attribute_type,
          attribute,
        ),
      );
    }
  }

  return (
    <div>
      <div className="ActivityObject has-extraInfo">
        <div className="ActivityObject-header">
          <div className="ActivityObject-info u-displayInlineBlock">
            <span className="ActivityObject-documentIcon">
              <ActivityEventDocumentIcon doc={params} size="large" />
            </span>
            <Link
              className="ActivityObject-documentName u-textWithBreakWord u-link u-textHoverColor-utility-2 u-noTextDecoration"
              to={`/${organizationUsername}/documents/${documentId}/download`}
              target="_blank"
            >
              {fileName}
            </Link>
          </div>
        </div>
        <div className="ActivityObject-extraInfo has-indentation">
          {changedFields}
        </div>
      </div>
    </div>
  );
};

const renderCreatedValuation = (
  {
    company,
    event: {
      params: {
        after,
        investment_id: investmentId,
        investment_name: investmentName,
        investment_company_id: companyId,
        investment_portfolio_name: portfolioName,
        valuation_unit: valuationUnit,
        investment_quantity: investmentQuantity,
      },
    },
    investment,
  },
  organizationUsername,
) => {
  const numberPrecision =
    (valuationUnit && investment.type !== "llc") ||
    investment.membership_units_or_percentage === "units"
      ? perUnitNumberPrecision
      : defaultNumberPrecision;
  const { name: companyName, verified } = company;

  return (
    <div className="ActivityObject has-extraInfo">
      <div className="ActivityObject-header">
        {renderActivityObjectInfo({
          company,
          companyName,
          companyId,
          investmentId,
          investmentName,
          portfolios: [{ name: portfolioName }],
          investment,
          verified,
          organizationUsername,
        })}
      </div>
      <div className="ActivityObject-extraInfo has-indentation">
        <p className="ActivityObject-copy">
          Valuation:
          <span className="ActivityObject-copyEmphasis">
            {" "}
            {isValidNumberInput(after)
              ? formatPrice(after, { precision: numberPrecision })
              : ""}{" "}
          </span>
          {investmentQuantity ? `/ ${valuationUnit}` : null}
        </p>
      </div>
    </div>
  );
};

const renderDeletedValuation = (
  {
    company,
    event: {
      params: {
        valuation_deleted: deletedValuation,
        investment_id: investmentId,
        investment_name: investmentName,
        investment_company_id: companyId,
        investment_company_name: companyName,
        investment_portfolio_name: portfolioName,
      },
    },
    investment,
  },
  organizationUsername,
) => {
  const { verified } = company;
  return (
    <div className="ActivityObject has-extraInfo">
      <div className="ActivityObject-header">
        {renderActivityObjectInfo({
          company,
          companyName,
          companyId,
          investmentId,
          investmentName,
          portfolios: [{ name: portfolioName }],
          investment,
          verified,
          organizationUsername,
        })}
      </div>
      <div className="ActivityObject-extraInfo has-indentation">
        <p className="ActivityObject-copyStriked">
          Valuation:
          <span className="ActivityObject-copyEmphasis">
            {" "}
            {isValidNumberInput(deletedValuation)
              ? formatPrice(deletedValuation)
              : ""}
          </span>
        </p>
      </div>
    </div>
  );
};

const renderUpdatedValuation = (
  {
    company,
    event: {
      params: {
        investment_id: investmentId,
        investment_name: investmentName,
        investment_company_id: companyId,
        investment_portfolio_name: portfolioName,
      },
    },
    investment,
  },
  organizationUsername,
) => {
  const { name: companyName, verified } = company;
  const valuationAmount = investment.unit_valuation_amount
    ? investment.unit_valuation_amount
    : investment.total_valuation_amount;

  return (
    <div className="ActivityObject has-extraInfo">
      <div className="ActivityObject-header">
        {renderActivityObjectInfo({
          company,
          companyName,
          companyId,
          investmentId,
          investmentName,
          portfolios: [{ name: portfolioName }],
          investment,
          verified,
          organizationUsername,
        })}
      </div>
      <div className="ActivityObject-extraInfo has-indentation">
        <p className="ActivityObject-copy">
          Valuation:
          <span className="ActivityObject-copyEmphasis">
            {" "}
            {isValidNumberInput(valuationAmount)
              ? `${formatPrice(valuationAmount, { precision: 4 })}`
              : ""}{" "}
          </span>
          {investment.unit_valuation_amount ? `/ share` : null}
        </p>
      </div>
    </div>
  );
};

const renderAddedANote = (
  {
    company,
    event: {
      params: {
        note_content: noteContent,
        company_id: companyId,
        company_name: companyName,
        investment_id: investmentId,
        investment_name: investmentName,
        portfolio_name: portfolioName,
      },
    },
    investment,
  },
  organizationUsername,
) => {
  const { verified } = company;
  return (
    <div className="ActivityObject has-extraInfo">
      <div className="ActivityObject-header">
        {renderActivityObjectInfo({
          company,
          companyName,
          companyId,
          investmentId,
          investmentName,
          portfolios: [{ name: portfolioName }],
          investment,
          verified,
          organizationUsername,
        })}
      </div>
      <div className="ActivityObject-extraInfo has-indentation">
        <p className="u-textWithLineBreaks">
          <Linkify properties={{ target: "_blank", rel: "nofollow noopener" }}>
            {noteContent}
          </Linkify>
        </p>
      </div>
    </div>
  );
};

const renderMovedAnInvestment = (
  {
    company,
    event: {
      params: {
        after,
        investment_name: investmentName,
        company_id: companyId,
        company_name: companyName,
        investment_id: investmentId,
      },
    },
    investment,
  },
  organizationUsername,
) => {
  const { verified } = company;
  return (
    <div className="ActivityObject">
      <div className="ActivityObject-header">
        {renderActivityObjectInfo({
          company,
          companyName,
          companyId,
          investmentId,
          investmentName,
          portfolios: [{ name: after }],
          investment,
          verified,
          organizationUsername,
        })}
      </div>
    </div>
  );
};

const renderCapitalCall = (
  {
    company,
    event: {
      params: {
        capital_call_amount: capitalCallAmount,
        capital_call_note: capitalCallNote,
        investment_id: investmentId,
        investment_name: investmentName,
        investment_company_id: companyId,
        investment_company_name: companyName,
        investment_portfolio_name: portfolioName,
        uploaded_files: uploadedFiles,
      },
    },
    investment,
  },
  organizationUsername,
) => {
  const { verified } = company;
  return (
    <div>
      <div className="ActivityObject has-extraInfo has-loggedDocuments">
        <div className="ActivityObject-header">
          {renderActivityObjectInfo({
            company,
            companyName,
            companyId,
            investmentId,
            investmentName,
            portfolios: [{ name: portfolioName }],
            investment,
            verified,
            organizationUsername,
          })}
        </div>
        <div className="ActivityObject-extraInfo has-indentation">
          <p className="ActivityObject-copy">
            Capital Call:
            <span className="ActivityObject-copyEmphasis">
              {" "}
              {isValidNumberInput(capitalCallAmount)
                ? formatPrice(capitalCallAmount)
                : ""}
            </span>
          </p>
          {capitalCallNote && (
            <p className="ActivityObject-copy">{capitalCallNote}</p>
          )}
        </div>
      </div>
      {!isEmpty(uploadedFiles) &&
        renderMultipleDocuments({
          uploadedFiles: uploadedFiles,
          activityObjectClassnames: "LoggedDocumentsActivityObject",
          investmentId,
        })}
    </div>
  );
};

const renderDeletedCapitalCall = (
  {
    company,
    event: {
      params: {
        capital_call_amount: capitalCallAmount,
        investment_id: investmentId,
        investment_name: investmentName,
        investment_company_id: companyId,
        investment_company_name: companyName,
        investment_portfolio_name: portfolioName,
      },
    },
    investment,
  },
  organizationUsername,
) => {
  const { verified } = company;
  return (
    <div>
      <div className="ActivityObject has-extraInfo">
        <div className="ActivityObject-header">
          {renderActivityObjectInfo({
            company,
            companyName,
            companyId,
            investmentId,
            investmentName,
            portfolios: [{ name: portfolioName }],
            investment,
            verified,
            organizationUsername,
          })}
        </div>
        <div className="ActivityObject-extraInfo has-indentation">
          <p className="ActivityObject-copyStriked">
            Capital Call:
            <span className="ActivityObject-copyEmphasis">
              {" "}
              {isValidNumberInput(capitalCallAmount)
                ? formatPrice(capitalCallAmount)
                : ""}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

const renderUpdatedCapitalCall = (
  {
    company,
    event: {
      params: {
        capital_call_amount: capitalCallAmount,
        investment_id: investmentId,
        investment_name: investmentName,
        investment_company_id: companyId,
        investment_company_name: companyName,
        investment_portfolio_name: portfolioName,
        uploaded_files: uploadedFiles,
      },
    },
    investment,
  },
  organizationUsername,
) => {
  const { verified } = company;
  return (
    <div>
      <div className="ActivityObject has-extraInfo has-loggedDocuments">
        <div className="ActivityObject-header">
          {renderActivityObjectInfo({
            company,
            companyName,
            companyId,
            investmentId,
            investmentName,
            portfolios: [{ name: portfolioName }],
            investment,
            verified,
            organizationUsername,
          })}
        </div>
        <div className="ActivityObject-extraInfo has-indentation">
          <p className="ActivityObject-copy">
            Capital Call:
            <span className="ActivityObject-copyEmphasis">
              {" "}
              {isValidNumberInput(capitalCallAmount)
                ? formatPrice(capitalCallAmount)
                : ""}
            </span>
          </p>
          <p className="ActivityObject-copy">
            Date:
            <span className="ActivityObject-copy">
              {" "}
              {isValidNumberInput(investment.created_at)
                ? formatDate(investment.created_at)
                : ""}
            </span>
          </p>
        </div>
      </div>
      {!isEmpty(uploadedFiles) &&
        renderMultipleDocuments({
          uploadedFiles: uploadedFiles,
          activityObjectClassnames: "LoggedDocumentsActivityObject",
          investmentId,
        })}
    </div>
  );
};

const renderAddedCompanyField = (
  {
    company,
    event: {
      params: { attribute, after, attribute_type: attributeType },
    },
    portfolios,
    investment,
  },
  organizationUsername,
) => {
  const { id: companyId, name: companyName, verified } = company;

  return (
    <div className="ActivityObject has-extraInfo">
      <div className="ActivityObject-header">
        {renderActivityObjectInfo({
          company,
          companyName,
          companyId,
          portfolios,
          investment,
          verified,
          organizationUsername,
        })}
      </div>
      <div className="ActivityObject-extraInfo has-indentation">
        <p className="ActivityObject-copy">
          {capitalize(attribute)}:
          <span className="ActivityObject-copyEmphasis">
            {formatAttributeType(after, attributeType, attribute)}
          </span>
        </p>
      </div>
    </div>
  );
};

const renderRemovedCompanyField = (
  {
    company,
    event: {
      params: { attribute, before, attribute_type: attributeType },
    },
    portfolios,
    investment,
  },
  organizationUsername,
) => {
  const { id: companyId, name: companyName, verified } = company;

  return (
    <div className="ActivityObject has-extraInfo">
      <div className="ActivityObject-header">
        {renderActivityObjectInfo({
          company,
          companyName,
          companyId,
          portfolios,
          investment,
          verified,
          organizationUsername,
        })}
      </div>
      <div className="ActivityObject-extraInfo has-indentation">
        <p className="ActivityObject-copyStriked">
          <Linkify properties={{ target: "_blank", rel: "nofollow noopener" }}>
            {capitalize(attribute)}:
            <span className="ActivityObject-copyEmphasisStriked">
              {formatAttributeType(before, attributeType, attribute)}
            </span>
          </Linkify>
        </p>
      </div>
    </div>
  );
};

const renderUpdatedCompanyField = (
  {
    company,
    event: {
      params: { updated_company_fields: updatedCompanyFields },
    },
    portfolios,
    investment,
  },
  organizationUsername,
) => {
  const { id: companyId, name: companyName, verified } = company;

  return (
    <div className="ActivityObject has-extraInfo">
      <div className="ActivityObject-header">
        {renderActivityObjectInfo({
          company,
          companyName,
          companyId,
          portfolios,
          investment,
          verified,
          organizationUsername,
        })}
      </div>
      <div className="ActivityObject-extraInfo has-indentation">
        {updatedCompanyFields &&
          updatedCompanyFields.map((field) => {
            const attributeClassnames = classnames(
              formatFieldTypeClassnames({
                after: field.after,
                before: field.before,
                createOrUpdateClassname: "ActivityObject-copy",
                deleteClassname: "ActivityObject-copyStriked",
              }),
            );
            const attributeNameClassnames = classnames(
              formatFieldTypeClassnames({
                after: field.after,
                before: field.before,
                createOrUpdateClassname:
                  "ActivityObject-copyEmphasis u-textWithLineBreaks",
              }),
            );
            return (
              <p className={attributeClassnames}>
                <Linkify
                  properties={{ target: "_blank", rel: "nofollow noopener" }}
                >
                  {capitalize(field.attribute)}:
                  <span className={attributeNameClassnames}>
                    {formatAttributeType(
                      field.after || field.before,
                      field.attribute_type,
                      field.attribute,
                    )}
                  </span>
                </Linkify>
              </p>
            );
          })}
      </div>
    </div>
  );
};

const renderConvertedLoan = (
  {
    company,
    event: {
      params: {
        equity_name: equityName,
        equity_id: equityId,
        equity_portfolio_name: equityPortfolioName,
        note: note,
        payout_amount: cashReceived,
        quantity: equityReceived,
      },
    },
    investment,
  },
  organizationUsername,
) => {
  const { id: companyId, name: companyName, verified } = company;

  return (
    <div className="ActivityObject has-extraInfo">
      <div className="ActivityObject-header">
        {renderActivityObjectInfo({
          company,
          companyName,
          companyId,
          portfolios: [{ name: equityPortfolioName }],
          investmentId: equityId,
          investmentName: removeConvertedAndFromNoteText(equityName),
          investment,
          verified,
          organizationUsername,
        })}
      </div>
      <div className="ActivityObject-extraInfo has-indentation">
        {note && (
          <p className="u-textWithLineBreaks">
            Description:{" "}
            <span className="ActivityObject-copyEmphasis">{note}</span>
          </p>
        )}
        {equityReceived && (
          <p className="u-textWithLineBreaks">
            Equity received:{" "}
            <span className="ActivityObject-copyEmphasis">
              {equityReceived} Shares
            </span>
          </p>
        )}
        {cashReceived && (
          <p className="u-textWithLineBreaks">
            Cash received:{" "}
            <span className="ActivityObject-copyEmphasis">
              {isValidNumberInput(cashReceived)
                ? formatPrice(cashReceived)
                : ""}
            </span>
          </p>
        )}
      </div>
    </div>
  );
};

const renderAddedANoteToCompany = (
  {
    company,
    portfolios,
    event: {
      params: { note_content: noteContent },
    },
    investment,
  },
  organizationUsername,
) => {
  const { id: companyId, name: companyName, verified } = company;

  return (
    <div className="ActivityObject has-extraInfo">
      <div className="ActivityObject-header">
        {renderActivityObjectInfo({
          company,
          companyName,
          companyId,
          portfolios,
          investment,
          verified,
          organizationUsername,
        })}
      </div>
      <div className="ActivityObject-extraInfo has-indentation">
        <p className="u-textWithLineBreaks">
          <Linkify properties={{ target: "_blank", rel: "nofollow noopener" }}>
            {noteContent}
          </Linkify>
        </p>
      </div>
    </div>
  );
};

const renderMultipleDocuments = (
  { uploadedFiles, activityObjectClassnames },
  organizationUsername,
) => (
  <div className={activityObjectClassnames}>
    {uploadedFiles.map((doc) => {
      return (
        <div key={doc.id} className="ActivityObject-documentItem">
          {doc ? (
            <span className="ActivityObject-documentIcon">
              <ActivityEventDocumentIcon doc={doc} />
            </span>
          ) : null}
          {doc ? (
            <Link
              extraClassnames="ActivityObject-documentName
                u-link
                u-textWithBreakWord
                u-textHoverColor-utility-2
                u-noTextDecoration
                ActivityObject-filename"
              to={`/${organizationUsername}/documents/${doc.id}/download`}
              target="_blank"
            >
              {doc.file_name}
            </Link>
          ) : (
            <span className="ActivityObject-deletedFilename">
              Document deleted
            </span>
          )}
        </div>
      );
    })}
  </div>
);

const renderUploadedDocuments = (
  {
    event: {
      params: { uploaded_files: uploadedFiles, investment_id: investmentId },
    },
  },
  organizationUsername,
) => {
  const activityObjectClassnames = classnames({
    ActivityObject: true,
    "has-document": uploadedFiles.length === 1,
    "has-documents": uploadedFiles.length > 1,
  });
  const doc = uploadedFiles[0];

  if (uploadedFiles.length === 1) {
    return (
      <div className={activityObjectClassnames}>
        {doc ? (
          <span className="ActivityObject-documentIcon">
            <ActivityEventDocumentIcon doc={doc} size="large" />
          </span>
        ) : null}
        {doc ? (
          <Link
            extraClassnames="ActivityObject-documentName u-textWithBreakWord u-link u-textHoverColor-utility-2 u-noTextDecoration"
            to={`/${organizationUsername}/documents/${doc.id}/download`}
            target="_blank"
          >
            {doc.file_name}
          </Link>
        ) : (
          <span className="ActivityObject-deletedFilename">
            Document deleted
          </span>
        )}
      </div>
    );
  }
  return renderMultipleDocuments(
    {
      uploadedFiles,
      activityObjectClassnames,
      investmentId,
    },
    organizationUsername,
  );
};

const renderPurchasedCompany = (
  {
    company,
    event: {
      params: {
        acquired_company_name: companyName,
        acquired_company_id: companyId,
        acquired_company_portfolio_names: portfolioNames,
      },
    },
    investment,
  },
  organizationUsername,
) => {
  const { verified } = company;
  return (
    <div className="ActivityObject has-extraInfo">
      <div className="ActivityObject-header">
        {renderActivityObjectInfo({
          company,
          companyName,
          companyId,
          investmentId: null,
          investmentName: null,
          portfolios: portfolioNames.map((portfolioName) => ({
            name: portfolioName,
          })),
          investment,
          verified,
          organizationUsername,
        })}
      </div>
    </div>
  );
};

const renderAcquiredCompany = (
  {
    company,
    event: {
      params: {
        acquired_company_name: companyName,
        acquired_company_id: companyId,
        purchased_investments: purchasedInvestments,
        purchased_investments_is_an_array_of_names: purchasedInvestmentsIsAnArrayOfNames,
        uploaded_files: uploadedFiles,
      },
    },
    investment,
  },
  organizationUsername,
) => {
  const firstPurchasedInvestment = purchasedInvestments[0];
  const { verified } = company;

  if (purchasedInvestmentsIsAnArrayOfNames) {
    return (
      <div>
        <div className="ActivityObject has-loggedDocuments">
          <div className="ActivityObject-header">
            {renderActivityObjectInfo({
              company,
              companyName,
              companyId,
              investmentId: null,
              investmentName: purchasedInvestments[0],
              portfolios: null,
              investment,
              verified,
              organizationUsername,
            })}
          </div>
        </div>
        {!isEmpty(uploadedFiles) &&
          renderMultipleDocuments({
            uploadedFiles: uploadedFiles,
            activityObjectClassnames:
              "LoggedDocumentsActivityObject has-acquiredDocuments",
            investmentId: null,
          })}
      </div>
    );
  }
  if (!purchasedInvestmentsIsAnArrayOfNames) {
    return (
      <div>
        <div className="ActivityObject has-loggedDocuments">
          <div className="ActivityObject-header">
            {renderActivityObjectInfo({
              company,
              companyName,
              companyId,
              investmentId: firstPurchasedInvestment.investment_id,
              investmentName: firstPurchasedInvestment.investment_name,
              portfolios: [{ name: firstPurchasedInvestment.portfolio_name }],
              investment,
              verified,
              organizationUsername,
            })}
          </div>
        </div>
        {!isEmpty(uploadedFiles) &&
          renderMultipleDocuments({
            uploadedFiles: uploadedFiles,
            activityObjectClassnames:
              "LoggedDocumentsActivityObject has-acquiredDocuments",
            investmentId: null,
          })}
      </div>
    );
  }
  return null;
};

const renderLoggedDistribution = (
  {
    company,
    event: {
      company_id: companyId,
      params: {
        investment_name: investmentName,
        investment_id: investmentId,
        investment_portfolio_name: portfolioName,
        is_cash_proceed: isCashProceed,
        is_equity_proceed: isEquityProceed,
        distribution_type: distributionType,
        cash_amount: cashAmount,
        equity_quantity: equityQuantity,
        equity_company_name: equityCompanyName,
        equity_company_id: equityCompanyId,
        uploaded_files: uploadedFiles,
      },
    },
    investment,
  },
  organizationUsername,
) => {
  const { name: companyName, verified } = company;
  return (
    <div>
      <div className="ActivityObject has-extraInfo has-loggedDocuments">
        <div className="ActivityObject-header">
          {renderActivityObjectInfo({
            company,
            companyName,
            companyId,
            investmentId,
            investmentName,
            portfolios: [{ name: portfolioName }],
            investment,
            verified,
            organizationUsername,
          })}
        </div>
        <div className="ActivityObject-extraInfo has-indentation">
          {distributionType && (
            <p className="u-textWithLineBreaks">
              Distribution Type:{" "}
              <span className="ActivityObject-copyEmphasis">
                {
                  distributionTypes.find(
                    (distributionTypeItem) =>
                      distributionTypeItem.value === distributionType,
                  ).label
                }{" "}
                - {renderLoggedDistributionType(isCashProceed, isEquityProceed)}
              </span>
            </p>
          )}
          {cashAmount && (
            <p className="u-textWithLineBreaks">
              Amount:{" "}
              <span className="ActivityObject-copyEmphasis">
                {formatPrice(cashAmount)}
              </span>
            </p>
          )}
          {equityQuantity && (
            <p className="u-textWithLineBreaks">
              Number of shares:{" "}
              <span className="ActivityObject-copyEmphasis">
                {formatNumber(equityQuantity)} in{" "}
                <Link
                  className="u-link u-textHoverColor-utility-2 u-noTextDecoration"
                  to={`/${organizationUsername}/companies/${equityCompanyId}/investments`}
                >
                  {equityCompanyName}
                </Link>
              </span>
            </p>
          )}
        </div>
      </div>
      {!isEmpty(uploadedFiles) &&
        renderMultipleDocuments({
          uploadedFiles: uploadedFiles,
          activityObjectClassnames: "LoggedDocumentsActivityObject",
          investmentId,
        })}
    </div>
  );
};

const renderLoggedAcquisition = (
  {
    company: company,
    event: {
      company_id: companyId,
      params: {
        investment_id: investmentId,
        acquired_company_name: acquiredCompanyName,
        acquiring_company_name: acquiringCompanyName,
        acquisition_date: acquisitionDate,
        cash_amount: cashAmount,
        investment_name: investmentName,
        uploaded_files: uploadedFiles,
        acquisition_description: acquisitionDescription,
        equity_class: equityClass,
        equity_value: equityValue,
        equity_name: equityName,
        equity_quantity: equityQuantity,
      },
    },
    investment,
  },
  organizationUsername,
) => {
  const companyName = acquiredCompanyName;
  const { portfolio_name: portfolioName } = investment;
  const { verified } = company;

  return (
    <div>
      <div className="ActivityObject has-extraInfo has-loggedDocuments">
        <div className="ActivityObject-header">
          {renderActivityObjectInfo({
            company,
            companyName,
            companyId,
            investmentId,
            investmentName,
            portfolios: [{ name: portfolioName }],
            investment,
            verified,
            organizationUsername,
          })}
        </div>
        <div className="ActivityObject-extraInfo has-indentation">
          {acquiredCompanyName && (
            <p className="u-textWithLineBreaks">
              Acquired company:{" "}
              <span className="ActivityObject-copyEmphasis">
                {acquiredCompanyName}
              </span>
            </p>
          )}
          {acquisitionDate && (
            <p className="u-textWithLineBreaks">
              Acquiring date:{" "}
              <span className="ActivityObject-copyEmphasis">
                {formatDate(acquisitionDate)}
              </span>
            </p>
          )}
          {acquiringCompanyName && (
            <p className="u-textWithLineBreaks">
              Acquiring company:{" "}
              <span className="ActivityObject-copyEmphasis">
                {acquiringCompanyName}
              </span>
            </p>
          )}
          {cashAmount && (
            <p className="u-textWithLineBreaks">
              Amount:{" "}
              <span className="ActivityObject-copyEmphasis">
                {isValidNumberInput(cashAmount) ? formatPrice(cashAmount) : ""}
              </span>
            </p>
          )}
          {equityClass && (
            <p className="u-textWithLineBreaks">
              Type of stock:{" "}
              <span className="ActivityObject-copyEmphasis">{equityClass}</span>
            </p>
          )}
          {equityName && (
            <p className="u-textWithLineBreaks">
              Series name:{" "}
              <span className="ActivityObject-copyEmphasis">{equityName}</span>
            </p>
          )}
          {equityQuantity && (
            <p className="u-textWithLineBreaks">
              Number of shares:{" "}
              <span className="ActivityObject-copyEmphasis">
                {formatNumber(equityQuantity)}
              </span>
            </p>
          )}
          {equityValue && (
            <p className="u-textWithLineBreaks">
              Value:{" "}
              <span className="ActivityObject-copyEmphasis">
                {isValidNumberInput(equityValue)
                  ? formatPrice(equityValue)
                  : ""}
              </span>
            </p>
          )}
          {acquisitionDescription && (
            <p className="u-textWithLineBreaks">
              Description:{" "}
              <span className="ActivityObject-copyEmphasis">
                {acquisitionDescription}
              </span>
            </p>
          )}
        </div>
      </div>
      {!isEmpty(uploadedFiles) &&
        renderMultipleDocuments({
          uploadedFiles: uploadedFiles,
          activityObjectClassnames: "LoggedDocumentsActivityObject",
          investmentId,
        })}
    </div>
  );
};

const renderOrganizationUsernameUpdated = ({
  event: {
    params: {
      organization_new_username: organizationNewUsername,
      organization_previous_username: organizationPreviousUsername,
    },
  },
}) => {
  return (
    <RenderUpdatedExtraInfo
      fieldName="Username"
      newValue={organizationNewUsername}
      previousValue={organizationPreviousUsername}
    />
  );
};

const renderOrganizationNameUpdated = ({
  event: {
    params: {
      organization_new_name: organizationNewName,
      organization_previous_name: organizationPreviousName,
    },
  },
}) => {
  return (
    <RenderUpdatedExtraInfo
      fieldName="Name"
      newValue={organizationNewName}
      previousValue={organizationPreviousName}
    />
  );
};

const activities = {
  addedANote: renderAddedANote,
  capitalCall: renderCapitalCall,
  deletedCapitalCall: renderDeletedCapitalCall,
  updatedCapitalCall: renderUpdatedCapitalCall,
  convertedNote: renderCreatedInvestment,
  createdInvestment: renderCreatedInvestment,
  createdValuation: renderCreatedValuation,
  updatedValuation: renderUpdatedValuation,
  deletedValuation: renderDeletedValuation,
  movedAnInvestment: renderMovedAnInvestment,
  updatedInvestment: renderUpdatedInvestment,
  saleLogged: renderSaleLogged,
  addedCompanyField: renderAddedCompanyField,
  removedCompanyField: renderRemovedCompanyField,
  changedCompanyField: renderUpdatedCompanyField,
  convertedLoan: renderConvertedLoan,
  addedANoteToCompany: renderAddedANoteToCompany,
  movedDocument: renderMovedDocument,
  updatedDocument: renderUpdatedDocument,
  uploadedDocuments: renderUploadedDocuments,
  purchasedCompany: renderPurchasedCompany,
  acquiredCompany: renderAcquiredCompany,
  loggedDistribution: renderLoggedDistribution,
  loggedAcquisition: renderLoggedAcquisition,
  organizationUsernameUpdated: renderOrganizationUsernameUpdated,
  organizationNameUpdated: renderOrganizationNameUpdated,
  writtenOff: renderWrittenOff,
};

const ActivityObject = ({ type, values, organizationUsername }) =>
  activities[type](values, organizationUsername);

renderActivityObjectInfo.defaultProps = {};

ActivityObject.propTypes = {
  type: PropTypes.string.isRequired,
  values: PropTypes.shape({
    event: investmentEventPropType,
    investment: investmentPropType,
    company: companyPropType,
  }).isRequired,
};

export default ActivityObject;
