import React, { useContext, useEffect, useMemo } from "react";
import ValuationForm from "./ValuationForm";
import {
  buildPrefilledInvestmentData,
  buildPrefilledCompanyData,
} from "lib/globalActionsHelper";
import { SidebarComponentsContext } from "components_new/SidebarComponents/SidebarComponentsWrapper";
import { getRelatedInvestments } from "store/companies/company/actions";
import { isEmpty } from "lodash";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import useInvestmentValuations from "store/investments/v2/valuations/hooks";
import getMessage from "components_new/lib/Messages";
import useOrganizations from "store/organizations/hooks";
import Loader from "components/shared/Loader";
import { useCompany } from "components_new/views/Company/hooks/queries/useCompany";
import { useInvestment } from "components_new/SidebarComponents/views/InvestmentView/hooks/useInvestment";

function ValuationFormWrapper({ ownName }) {
  const { addToast } = useSetToast();
  const { currentOrganizationUsername } = useOrganizations();
  const { getComponentState, registerForm, closeSidebar } = useContext(
    SidebarComponentsContext,
  );

  const {
    initialHoldingId,
    initialCompanyId,
    taskId,
    taskAttachments,
  } = getComponentState(ownName);

  const { addValuation } = useInvestmentValuations();

  useEffect(() => {
    if (!isEmpty(ownName)) {
      registerForm(ownName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showConfirmationMessage = () => addToast(getMessage("addedValuation"));

  const { data: companyData, isLoading: isLoadingCompany } = useCompany(
    initialCompanyId,
  );

  const {
    data: investmentData,
    isLoading: isLoadingInvestment,
  } = useInvestment(initialHoldingId);

  const prefilledSelection = useMemo(() => {
    if (initialCompanyId) {
      return buildPrefilledCompanyData(companyData?.companyDetails);
    }

    if (initialHoldingId) {
      return buildPrefilledInvestmentData(
        investmentData?.details,
        investmentData?.company,
      );
    }
  }, [initialCompanyId, initialHoldingId, companyData, investmentData]);

  if (isLoadingCompany || isLoadingInvestment) {
    return <Loader />;
  }

  return (
    <ValuationForm
      ownName={ownName}
      prefilledSelection={prefilledSelection}
      taskId={taskId}
      taskAttachments={taskAttachments}
      addValuation={addValuation}
      getRelatedInvestments={getRelatedInvestments}
      closeSidebar={closeSidebar}
      showConfirmationMessage={showConfirmationMessage}
      currentOrganizationUsername={currentOrganizationUsername}
    />
  );
}
export default ValuationFormWrapper;
