import { connect } from "react-redux";
import UploadDocuments from "components/shared/UploadDocuments";
import { cleanDocumentUploads } from "actions/synchronous/documentUploads";
import { ModalNames, closeModal } from "store/modal";
import { saveInvestmentDocuments } from "store/documents/actions";
import { reloadInvestmentAfterDocumentsV2 } from "store/investments/investment/actions";

function mapStateToProps({ modal }) {
  const options = modal.options[ModalNames.newInvestmentDocuments];
  const {
    canChooseFileType,
    canMarkAsClosing,
    companyId,
    investmentId,
  } = options;

  return { canChooseFileType, canMarkAsClosing, companyId, investmentId };
}

function mergeProps(stateProps, dispatchProps) {
  const {
    canChooseFileType,
    canMarkAsClosing,
    companyId,
    investmentId,
  } = stateProps;
  const { dispatch } = dispatchProps;

  return {
    canChooseFileType,
    canMarkAsClosing,
    onDoneButtonClick: (uploadedDocuments) => {
      dispatch(
        saveInvestmentDocuments(investmentId, companyId, uploadedDocuments),
      ).then(() => dispatch(reloadInvestmentAfterDocumentsV2(investmentId)));
      dispatch(closeModal());
      dispatch(cleanDocumentUploads());
    },
    onCancelButtonClick: () => {
      dispatch(closeModal());
      dispatch(cleanDocumentUploads());
    },
  };
}

export default connect(mapStateToProps, null, mergeProps)(UploadDocuments);
