import React from "react";
import DataCard from "components_new/elements/DataCard";
import Text from "components_new/atoms/Text";
import Link from "components_new/atoms/Link";
import formatDate from "lib/formatDate";

export default function CompanySnapshots({
  companySnapshots,
  handleEditSnapshot,
  // handleDeleteSnapshot,
  currentOrganizationUsername,
}) {
  const buildSnapshotURL = (snapshotParam) =>
    `/${currentOrganizationUsername}/reports/snapshots/${snapshotParam}`;

  return (
    <DataCard.Group
      title="Snapshots"
      extraClassnames="CompanyOverviewSnapshots"
      data-testid="SnapshotsGroup"
    >
      {companySnapshots.map((snapshot) => {
        return (
          <DataCard
            key={snapshot.id}
            actionMenuOptions={[
              {
                text: "Edit Snapshot",
                onClick: () => handleEditSnapshot(snapshot.id),
              },
              // TODO: add delete snapshot with confirmation loading indicator
              // {
              //   text: "Delete Snapshot",
              //   onClick: () => handleDeleteSnapshot(snapshot.param),
              // },
            ]}
          >
            <DataCard.Header>
              <Link to={buildSnapshotURL(snapshot.id)}>
                <Text type="body-1-bold" link>
                  {snapshot.name}
                </Text>
              </Link>
              <Text type="body-1-regular" data-testid="snapshotDescription">
                {snapshot.description}
              </Text>
              {snapshot.creator.full_name}
              &nbsp;·&nbsp;
              {formatDate(snapshot.created_at, "detailed")}
            </DataCard.Header>
          </DataCard>
        );
      })}
    </DataCard.Group>
  );
}
