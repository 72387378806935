import React from "react";
import { connect } from "react-redux";
import { deleteCustomField } from "store/customFields/actions";
import { closeModal, updateModalOptions } from "store/modalV2/actions";
import { flash } from "actions/synchronous/flash";
import ConfirmationModal from "components/shared/ConfirmationModal";
import PreviewComponent from "components/shared/PreviewComponent";
import { componentType } from "../CustomFieldForm/config";

const createMessage = (customField) => {
  const Component = componentType[customField.field_type];
  return (
    <div className="DeleteCustomFieldModal">
      Deleting this custom field will disable your organization's ability to
      track this information. Any data for this field will be permanently
      deleted.
      <PreviewComponent
        extraClassnames="FormElement"
        componentProps={{
          label: customField.field_label,
          textHelper: customField.helper_text,
          inputProps: customField.field_data,
        }}
      >
        <Component />
      </PreviewComponent>
    </div>
  );
};

function mapStateToProps(state, { customField, isSubmitting = false }) {
  return {
    customField: customField,
    title: "Delete Custom Field",
    message: createMessage(customField),
    confirmButtonLabel: "Delete Field",
    cancelButtonLabel: "Cancel",
    primaryButtonClassnames: "Button--warning",
    submitting: isSubmitting,
  };
}

function updateModalProps(dispatch, props) {
  dispatch(updateModalOptions("deleteCustomField", props));
}

function dispatchToProps(dispatch, { customField }) {
  return {
    closeModal: () => dispatch(closeModal()),
    action: () => {
      updateModalProps(dispatch, {
        isSubmitting: true,
      });
      dispatch(deleteCustomField(customField.id)).then(() => {
        updateModalProps(dispatch, {
          isSubmitting: false,
        });
        dispatch(closeModal());
        dispatch(flash("deleteDeal"));
      });
    },
  };
}

export default connect(mapStateToProps, dispatchToProps)(ConfirmationModal);
