import React from "react";
import ColumnedValueList from "./ColumnedValueList";

export default function GroupedColumnValueList({ data }) {
  return (
    <div className="GroupedColumnedValueList">
      {data.map((groupData, index) => {
        return (
          <div
            className="GroupedColumnedValueList-Group"
            key={`GroupedColumnValueList-${index}`}
          >
            <ColumnedValueList data={groupData} />
          </div>
        );
      })}
    </div>
  );
}
