import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import Autocomplete from "components_new/elements/Autocomplete";
import Textarea from "components_new/atoms/TextArea";
import PriceInput from "components/shared/form/PriceInput";
import NumberInput from "components_new/atoms/NumberInput";
import CurrencyInput from "components_new/atoms/CurrencyInput";
import Input from "components_new/atoms/Input";
import classNames from "classnames";
import FormElement from "components/shared/form/FormElement";
import RadioButtonGroup from "components_new/elements/AdvancedFields/RadioButtonGroup";
import Select from "components/shared/form/Select";
import SelectAndOther from "components/shared/form/SelectAndOther";
import DatePicker from "components_new/atoms/DatePicker";
import CheckBoxGroup from "components/shared/form/CheckBoxGroup";
import CaretIcon from "-!svg-react-loader?!assets/icons/utility/caret.svg?name=CaretIcon";
import { manageCustomFieldProps } from "components/customFields/CustomFieldForm/config.js";
import { motion } from "framer-motion";
import { animationFromLeft } from "lib/animations";

const ALLOWED_EDITOR_TYPES = {
  textarea: Textarea,
  text: Input,
  price: PriceInput,
  number: NumberInput,
  autocomplete: Autocomplete,
  radioButton: RadioButtonGroup,
  select: Select,
  selectAndOther: SelectAndOther,
  date: DatePicker,
  multiple_choice: CheckBoxGroup,
  single_choice: RadioButtonGroup,
  currency: CurrencyInput,
};

function buildSelectAndOtherProps(editorProps) {
  const { otherValue, onOtherSelection, inputProps, placeholder } = editorProps;
  return {
    otherValue,
    onOtherSelection,
    selectProps: { inputProps, placeholder },
  };
}

function renderEditorChildComponent(editorProps) {
  const { childType, childProps } = editorProps;
  const { onChange, name, ...restProps } = childProps;
  const EditorChildComponent = ALLOWED_EDITOR_TYPES[childType];
  return (
    <EditorChildComponent
      onChange={(value) => {
        if (onChange) {
          return onChange(name, value);
        }
      }}
      {...restProps}
    />
  );
}

function AdvancedFieldContentEditor({
  editorProps,
  errorMessage,
  onChange,
  name,
  value,
  editorType,
  options,
  ...restProps
}) {
  const EditorComponent = ALLOWED_EDITOR_TYPES[editorType];
  const wrappedEditorProps =
    editorType === "selectAndOther"
      ? buildSelectAndOtherProps(editorProps)
      : editorProps;

  const customFieldProps = manageCustomFieldProps(editorType, {
    ...wrappedEditorProps,
  });
  return (
    <EditorComponent
      extraClassName="AdvancedFieldContentTextArea"
      placeholder={restProps.placeholder}
      onChange={(value, target) => {
        if (onChange) {
          onChange(
            name,
            ["text", "textarea"].includes(editorType) ? target.value : value,
          );
        }
      }}
      options={options}
      errorMessage={errorMessage}
      value={value}
      defaultValue={value}
      name={name}
      {...customFieldProps}
    >
      {editorProps.childType && renderEditorChildComponent(editorProps)}
    </EditorComponent>
  );
}

function AdvancedFieldContent(props) {
  const { children, isAdvanceFieldOpen } = props;

  return (
    <div className="AdvancedFieldContent">
      <motion.div
        initial="hidden"
        animate="visible"
        variants={animationFromLeft({ delay: 0.1 })}
        transition={{ duration: 0.15 }}
      >
        {isAdvanceFieldOpen && children}
        {isAdvanceFieldOpen && <AdvancedFieldContentEditor {...props} />}
      </motion.div>
    </div>
  );
}

function AdvancedFieldTitle({ label, toggleAdvanceFieldState }) {
  return (
    <div
      className="AdvancedFieldTitle"
      onClick={() => toggleAdvanceFieldState()}
      data-testid="AdvancedFieldTitle"
    >
      <CaretIcon className="AdvancedFieldTitle--icon" />
      <p className="AdvancedFieldTitle--label"> {label} </p>
    </div>
  );
}

function AdvancedField(props) {
  const { errorDisplayValidation, extraClassnames, label, value } = props;
  const [isAdvanceFieldOpen, setAdvancedFieldState] = useState(value || false);
  const toggleAdvanceFieldState = () => {
    setAdvancedFieldState(!isAdvanceFieldOpen);
  };

  useEffect(() => {
    const { name, onChange } = props;
    if (!isAdvanceFieldOpen && onChange) {
      onChange(name, null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdvanceFieldOpen]);

  const advancedFieldExtraClassnames = classNames(
    "FormElement--advancedField",
    {
      [extraClassnames]: !!extraClassnames,
      "is-open": !!isAdvanceFieldOpen,
    },
  );

  return (
    <FormElement
      {...(errorDisplayValidation && { errorDisplayValidation })}
      extraClassnames={advancedFieldExtraClassnames}
    >
      <div>
        <AdvancedFieldTitle
          label={label}
          toggleAdvanceFieldState={toggleAdvanceFieldState}
        />
        <AdvancedFieldContent
          {...props}
          isAdvanceFieldOpen={isAdvanceFieldOpen}
        />
      </div>
    </FormElement>
  );
}

AdvancedField.propTypes = {
  editorProps: PropTypes.shape({}),
  extraClassnames: PropTypes.string,
  editorType: PropTypes.string,
  errorMessage: PropTypes.string,
  errorDisplayValidation: PropTypes.func,
  inverseDisplay: PropTypes.bool,
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updateForm: PropTypes.func,
};

AdvancedField.defaultProps = {
  checkboxExtraAction: null,
  children: null,
  editorType: "textarea",
  editorProps: {},
  errorMessage: null,
  errorDisplayValidation: null,
  extraClassnames: null,
  inverseDisplay: false,
  label: null,
  onChange: null,
  updateForm: null,
  value: "",
  placeholder: "Add your description",
};

export default AdvancedField;
