import React from "react";
import { Table as SemanticTable } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/table.css";
import "./Table.scss";
import TableEmptyRow from "./TableEmptyRow";

function TableBody({ as, children, isEmptyData, ...props }) {
  return (
    <SemanticTable.Body as={as} {...props}>
      {isEmptyData && <TableEmptyRow></TableEmptyRow>}
      {!isEmptyData && children}
    </SemanticTable.Body>
  );
}

export default TableBody;
