import Api from "lib/Api";
import { receiveCollections } from "store/entities";
import { requestStart, requestSuccess, requestError } from "store/requests";
import { receiveView } from "store/views";

export function logDistribution(formData, investmentId) {
  return (dispatch) => {
    dispatch(requestStart("createLogDistribution"));
    return Api.post(`/v2/investments/${investmentId}/actions/distributions`, {
      distribution: formData,
    }).then(
      ({
        data: {
          entities,
          views: { investment: view },
        },
      }) => {
        dispatch(receiveView("investment", view));
        dispatch(receiveCollections(entities));
        dispatch(requestSuccess("createLogDistribution"));
        return entities.distribution_history[0];
      },
      (errors) => {
        dispatch(requestError("createLogDistribution"));
        throw errors;
      },
    );
  };
}
