import { isEqual } from "lodash";
import formatContactName from "lib/formatters/formatContactName";

export const parseElements = (elements) =>
  elements.map((element) => ({
    key: element.id,
    caption: getTextFromElement(element),
    value: element,
  }));

export const getTextFromElement = (element) => {
  const { title } = element;
  return title ? title : formatContactName(element);
};

export const withoutSelectedItems = (suggestions, selectedItems) => {
  const selectedKeys = selectedItems.map((item) => item.key);
  return suggestions.filter(
    (suggestion) => !selectedKeys.includes(suggestion.key),
  );
};

export const compareWith = (title, query) => {
  return isEqual(title.trim(), query.trim());
};
