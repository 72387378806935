import Api from "lib/Api";
import {
  singletonFetchStart,
  singletonFetchSuccess,
} from "store/domain/domainActions";
import { UPDATE_DASHBOARD, CLEAN_DASHBOAD } from "./actionTypes";

export function updateDashboard(values) {
  return { type: UPDATE_DASHBOARD, ...values };
}

export function cleanDashboard() {
  return { type: CLEAN_DASHBOAD };
}

export function fetchInvestmentHighlights() {
  return (dispatch) => {
    dispatch(singletonFetchStart("dashboardHighlights"));
    return Api.get(`/highlights`).then(({ data }) => {
      dispatch(
        singletonFetchSuccess("dashboardHighlights", {
          totalCompanies: data.total_companies,
          totalInvestments: data.total_investments,
          multiple: data.multiple,
          totalInvestmentsAmount: data.total_investments_amount,
          totalInvestmentsValue: data.total_investments_value,
          totalCashReceived: data.total_cash_received,
        }),
      );
    });
  };
}

export function fetchDashboardInvestmentPace() {
  return (dispatch) => {
    dispatch(singletonFetchStart("dashboardInvestmentPace"));
    return Api.get(`/investments_paces`).then(({ data }) => {
      dispatch(
        singletonFetchSuccess("dashboardInvestmentPace", data.investment_pace),
      );
    });
  };
}

export function fetchdashboardCashFlowPerformance(periodType) {
  return (dispatch) => {
    dispatch(singletonFetchStart("dashboardCashFlowPerformance"));
    return Api.get(`/cash_flow_performances`, {
      params: { period_type: periodType },
    }).then(({ data }) => {
      dispatch(singletonFetchSuccess("dashboardCashFlowPerformance", data));
    });
  };
}
