import React, { Fragment } from "react";
import CollapsableSection from "components_new/elements/CollapsableSection";
import EmptyCard from "components_new/elements/EmptyCard";
import BasicLabel from "components_new/atoms/BasicLabel";
import Link from "components_new/atoms/Link";
import formatDate from "lib/formatDate";

import { useMarked } from "components_new/lib/useMarked";
import "./ContactDiligenceNotes.scss";

function showtitle(count) {
  return (
    <Fragment>
      Diligence Notes <small>({count})</small>
    </Fragment>
  );
}

function DiligenceNote({ note, organizationUsername }) {
  const { author, deal, content, edited: isEdited } = note;
  const { company } = deal;
  const html = useMarked(content || "");

  return (
    <article className="DiligenceNote">
      <header className="DiligenceNote-header">
        <p className="u-metaContentBlack">{author.full_name}</p>
        <span className="u-metaContentLight">
          {formatDate(note.created_at, "ll")} {isEdited && " (edited) "}
        </span>
        <BasicLabel type="generic" size="small">
          {deal.status}
        </BasicLabel>
      </header>

      <div
        className="DiligenceNote-content"
        dangerouslySetInnerHTML={{ __html: html }}
      />

      <footer className="DiligenceNote-footer">
        <Link to={`/${organizationUsername}/companies/${company.id}/overview`}>
          {company.name}
        </Link>
        <span>&#183;</span>
        <Link to={`/${organizationUsername}/deals/${deal.status}/${deal.id}`}>
          {deal.name}
        </Link>
      </footer>
    </article>
  );
}

function ContactDiligenceNotes({ diligenceNotes, organizationUsername }) {
  const haveNotes = diligenceNotes.length > 0;

  return (
    <CollapsableSection
      expanded={haveNotes}
      title={showtitle(diligenceNotes.length)}
      extraClassNames="Contact-DiligenceNotes"
    >
      {haveNotes ? (
        diligenceNotes.map((note) => (
          <DiligenceNote
            note={note}
            organizationUsername={organizationUsername}
          />
        ))
      ) : (
        <EmptyCard title="Diligence Notes" />
      )}
    </CollapsableSection>
  );
}

export default ContactDiligenceNotes;
