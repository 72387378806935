import Api from "lib/Api";
import {
  collectionFetchStart,
  collectionFetchSuccess,
} from "store/domain/domainActions";

export function fetchCompanyCashFlow(companyId) {
  return (dispatch) => {
    dispatch(collectionFetchStart("companyCashFlow"));
    return Api.get(`/companies/${companyId}/cash_flow`).then(
      ({ data: { cash_flow: cashFlow } }) => {
        dispatch(collectionFetchSuccess("companyCashFlow", cashFlow));
      },
    );
  };
}
