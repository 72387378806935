var React = require('react');

function Verified (props) {
    return React.createElement("svg",props,[React.createElement("path",{"id":"Shape","fill":"#3F5BA8","d":"M8.737,17.314l-1.279,0.573c-0.556,0.249-1.21,0.073-1.567-0.42L5.07,16.332\r\n\tc-0.107-0.148-0.273-0.244-0.456-0.263l-1.394-0.144c-0.606-0.062-1.085-0.541-1.147-1.146l-0.143-1.395\r\n\tc-0.019-0.182-0.115-0.348-0.263-0.455l-1.135-0.82c-0.494-0.357-0.669-1.012-0.42-1.568l0.573-1.278\r\n\tc0.075-0.167,0.075-0.358,0-0.525L0.112,7.458c-0.249-0.556-0.074-1.21,0.42-1.567L1.667,5.07c0.148-0.107,0.244-0.273,0.263-0.455\r\n\tl0.143-1.394c0.062-0.606,0.542-1.085,1.147-1.147l1.394-0.143C4.797,1.912,4.963,1.816,5.07,1.668l0.821-1.136\r\n\tc0.357-0.494,1.012-0.669,1.567-0.42l1.279,0.573c0.167,0.075,0.358,0.075,0.525,0l1.278-0.573c0.557-0.249,1.211-0.074,1.568,0.42\r\n\tl0.82,1.136c0.107,0.148,0.273,0.244,0.455,0.263l1.395,0.143c0.605,0.062,1.084,0.542,1.146,1.147l0.144,1.394\r\n\tc0.019,0.182,0.114,0.348,0.263,0.455l1.136,0.821c0.493,0.357,0.669,1.011,0.42,1.567l-0.573,1.279\r\n\tc-0.074,0.167-0.074,0.358,0,0.525l0.573,1.278c0.249,0.557,0.073,1.211-0.42,1.568l-1.136,0.82\r\n\tc-0.148,0.107-0.244,0.273-0.263,0.455l-0.144,1.395c-0.062,0.605-0.541,1.084-1.146,1.146l-1.395,0.144\r\n\tc-0.182,0.019-0.348,0.114-0.455,0.263l-0.82,1.136c-0.357,0.493-1.012,0.669-1.568,0.42l-1.278-0.573\r\n\tC9.096,17.24,8.904,17.24,8.737,17.314z","key":0}),React.createElement("path",{"id":"Fill-1-Copy-2","fill":"#FFFFFF","d":"M13.049,6.019L13.049,6.019c-0.351-0.345-0.926-0.345-1.276,0l-3.788,4.77L5.86,8.867\r\n\tc-0.351-0.345-0.926-0.345-1.277,0s-0.351,0.909,0,1.254l2.963,2.613c0.176,0.172,0.441,0.226,0.672,0.226\r\n\tc0.232,0,0.414-0.054,0.589-0.226L13.1,7.305C13.451,6.96,13.4,6.363,13.049,6.019","key":1})]);
}

Verified.defaultProps = {"version":"1.1","id":"Layer_1","x":"0px","y":"0px","width":"18px","height":"18px","viewBox":"0 0 18 18","enableBackground":"new 0 0 18 18","xmlSpace":"preserve"};

module.exports = Verified;

Verified.default = Verified;
