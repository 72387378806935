import React from "react";
import InputTag from "components_new/elements/InputTag";
import useIndustries from "store/industries/hooks";

function IndustryWrapper(props) {
  const {
    createIndustry,
    filterIndustries,
    industrySuggestions,
  } = useIndustries();

  return (
    <InputTag
      onCreate={createIndustry}
      onFilter={filterIndustries}
      suggestedList={industrySuggestions}
      {...props}
    />
  );
}

export default IndustryWrapper;
