import React from "react";
import copy from "copy-to-clipboard";
import DotsIcon from "-!svg-react-loader?!assets/icons/utility/dots.svg?name=DotsIcon";
import ReplyIcon from "-!svg-react-loader?!assets/icons/utility/reply.svg?name=ReplyIcon";
import TooltipHolder from "components/shared/TooltipHolder";
import Dropdown from "components/shared/Dropdown";
import ActionButton from "components/shared/ActionButton";

function createShareableNoteURL(postId) {
  const pathname = window.location.href;
  const pathnameAsArray = pathname.split("/");
  const isAnoteURL = pathnameAsArray.includes("notes");
  if (isAnoteURL) {
    return pathnameAsArray.at(-1) === "notes"
      ? `${pathname}/${postId}`
      : pathname;
  } else {
    return pathnameAsArray.at(-1) === "overview"
      ? `${pathnameAsArray.slice(0, -1).join("/")}/notes/${postId}`
      : `${pathname}/notes/${postId}`;
  }
}

function DiscussionActions({
  currentUser,
  authorId,
  onEdit,
  onDelete,
  showForm,
  isAvailableToReply,
  hasReplies,
  parentId,
  postId,
}) {
  const noteAdress = createShareableNoteURL(postId);
  const dropdownOptions = [
    {
      text: "Edit",
      onClickAction: () => {
        onEdit(true);
      },
    },
    {
      text: "Delete",
      isDisabled: !!hasReplies,
      onClickAction: () => {
        onDelete(true);
      },
    },
    !parentId && {
      text: "Copy Link",
      onClickAction: () => copy(noteAdress),
    },
  ].filter(Boolean);

  return (
    <div className="PostSection-actions">
      {isAvailableToReply && (
        <TooltipHolder tooltip="Add reply" position="top">
          <ActionButton onClick={showForm}>
            <ReplyIcon />
          </ActionButton>
        </TooltipHolder>
      )}
      {currentUser.id === authorId && (
        <Dropdown items={dropdownOptions}>
          <DotsIcon className="Button-icon" />
        </Dropdown>
      )}
    </div>
  );
}

export default DiscussionActions;
