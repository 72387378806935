import React, { Fragment } from "react";
import { getText } from "TermsMapper";
import { placeholderDateFormat } from "config/dateInput";
import { isEqual } from "lodash";
import PriceInput from "components/shared/form/PriceInput";
import DatePicker from "components_new/atoms/DatePicker";
import PercentInput from "components/shared/form/PercentInput";
import RadioButtonGroup from "components/shared/form/RadioButtonGroup";
import NumberInput from "components/shared/form/NumberInput";
import HelperContent from "components/shared/HelperContent";
import { customPurchasedErrors } from "../../config";

function PurchasedLlcForm({ formData, updateForm, errors, historyAction }) {
  const { date_helper_text: dateHelperContent } = historyAction;
  return (
    <Fragment>
      <PriceInput
        name="total_acquisition_amount"
        label={getText("llc", "total_acquisition_amount")}
        placeholder="0.00"
        onChange={(value) => updateForm("total_acquisition_amount", value)}
        value={formData.total_acquisition_amount}
        errorMessage={customPurchasedErrors(
          errors,
          "total_acquisition_amount",
          formData.type,
        )}
        size="small"
      />
      <DatePicker
        name="date"
        label={getText("llc", "date")}
        placeholder={placeholderDateFormat}
        onChange={(value, validated) => updateForm("date", value, validated)}
        extraClassnames="FormElement FormElement--Date"
        error={!!errors.for("date")}
        errorMessage={errors.for("date")}
        value={formData.date}
        helperContent={() => <HelperContent content={dateHelperContent} />}
        showHelper={!!dateHelperContent}
        size="small"
        oldVersion
      />
      <RadioButtonGroup
        label="Membership units or percentage"
        name="units-or-percentage"
        inputProps={[
          { value: "units", label: "Units" },
          { value: "percentage", label: "Percentage" },
        ]}
        onChange={(value) => {
          updateForm("membership_units_or_percentage", value);
        }}
        errorMessage={errors.for("membership_units_or_percentage")}
        value={formData.membership_units_or_percentage}
        defaultValue={formData.membership_units_or_percentage}
        size="small"
      />
      {isEqual(formData.membership_units_or_percentage, "units") && (
        <NumberInput
          name="quantity"
          label={getText("llc", "quantity")}
          placeholder={getText("llc", "quantity")}
          onChange={(value) => updateForm("quantity", value)}
          errorMessage={errors.for("quantity")}
          value={formData.quantity}
          size="small"
        />
      )}
      {isEqual(formData.membership_units_or_percentage, "percentage") && (
        <PercentInput
          label={getText("llc", "percentage_owned")}
          onChange={(value) => {
            updateForm("percentage_owned", value);
          }}
          errorMessage={errors.for("percentage_owned")}
          value={formData.percentage_owned}
          size="small"
        />
      )}
    </Fragment>
  );
}

export default PurchasedLlcForm;
