import { OPEN, SHOW, HIDE, UPDATE, CLEAR, CLOSE } from "./actionTypes";

export function openSidebar(name, title, props = {}) {
  return {
    type: OPEN,
    name,
    title,
    props: {
      sidebarPolicy: { pattern: "", size: "default" },
      ...props,
    },
  };
}

export function showSidebar() {
  return { type: SHOW };
}

export function hideSidebar() {
  return { type: HIDE };
}

export function updateSidebar(props) {
  return { type: UPDATE, props };
}

export function clearSidebar() {
  return { type: CLEAR };
}

export function closeSidebar() {
  return { type: CLOSE };
}
