import React, { useCallback } from "react";
import { useQueryClient } from "react-query";
import DealForm from "./DealForm";
import Loader from "components/shared/Loader";
import useOrganizations from "store/organizations/hooks";
import { buildPrefilledCompanyData } from "lib/globalActionsHelper";
import { parseElements } from "components_new/elements/MultiSelect/config";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import useSidebar from "store/sidebar/hooks";
import { performSearch } from "store/search";
import { validateEmail } from "store/contacts/actions";

import useResourcesV2 from "store/resourcesV2/hooks";
import { useDeal } from "components_new/views/Deals/hooks/queries/useDeal";
import { usePipeline } from "components_new/views/Deals/hooks/queries/usePipeline";
import { useCreateDeal } from "components_new/views/Deals/hooks/mutations/useCreateDeal";
import { useUpdateDeal } from "components_new/views/Deals/hooks/mutations/useUpdateDeal";
import useCurrentUser from "hooks/useCurrentUser";

function buildOptionsFromStages(stages) {
  return stages.map((stage) => ({
    key: stage.id,
    value: stage.id,
    text: stage.name,
    order: stage.order,
  }));
}

function DealFormWrapper({
  dealId,
  initialCompanyId,
  callback,
  closeSidebar,
  toogleSize,
  currentSize,
}) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const {
    isAdmin,
    currentOrganizationName,
    isCurrentUserOrganizationOwner,
    organizationMemberships,
    currentOrganization: {
      username: currentOrganizationUsername,
      pipeline_id: pipelineId,
    },
  } = useOrganizations();
  const { openSidebar } = useSidebar();

  const { getOrganizationResourceAsCollection } = useResourcesV2();
  const organizationtCustomFields = getOrganizationResourceAsCollection(
    "customFields",
  );

  const isEditing = !isEmpty(dealId);

  const onCloseSidebar = useCallback(() => {
    closeSidebar();

    callback?.();
  }, [callback, closeSidebar]);

  const { data: pipeline, isLoading: isLoadingPipeline } = usePipeline(
    pipelineId,
  );
  const { data, isLoading } = useDeal(pipelineId, dealId);
  const { mutateAsync: createDeal } = useCreateDeal();
  const { mutateAsync: updateDeal } = useUpdateDeal();

  const { id: currentUserID } = useCurrentUser();

  const handleSearchContact = (scope, query) =>
    dispatch(performSearch(scope, query)).then(
      ({ results: { [scope]: resultItems } }) => resultItems,
    );

  const handleValidateEmail = (email) => dispatch(validateEmail(email));

  const handleCreateDeal = (formData) => {
    return createDeal({ formData, pipelineId });
  };

  const handleUpdateDeal = (formData) => {
    return updateDeal({ formData, pipelineId, dealId });
  };

  const handleOpenEditCompanyForm = useCallback(
    (companyId, companyName) =>
      openSidebar("globalCompanyForm", `Edit ${companyName}`, {
        entityId: companyId,
      }),
    [openSidebar],
  );

  let prefilledCompany = {};

  if (isEditing) {
    prefilledCompany = data?.company;
  } else if (initialCompanyId) {
    const { companyDetails } = queryClient.getQueryData([
      "Company",
      currentOrganizationUsername,
      initialCompanyId,
    ]);

    prefilledCompany = companyDetails;
  }

  if (isLoading || isLoadingPipeline) {
    return <Loader />;
  }

  return (
    <DealForm
      isAdmin={isAdmin}
      isOrgOwner={isCurrentUserOrganizationOwner}
      isEditing={isEditing}
      prefilledDeal={data?.deal}
      prefilledDocuments={data?.documents}
      prefilledCompany={buildPrefilledCompanyData(prefilledCompany)}
      prefilledContacts={parseElements(data?.contacts || [])}
      pipelineId={pipelineId}
      organizationCustomFields={organizationtCustomFields}
      currentOrganizationName={currentOrganizationName}
      currentOrganizationUserName={currentOrganizationUsername}
      closeSidebar={onCloseSidebar}
      submitAction={isEditing ? handleUpdateDeal : handleCreateDeal}
      organizationMemberships={organizationMemberships}
      currentUserID={currentUserID}
      searchContact={handleSearchContact}
      validateEmail={handleValidateEmail}
      pipelineStagesOptions={buildOptionsFromStages(pipeline?.stages || [])}
      toogleSize={toogleSize}
      currentSize={currentSize}
      openEditCompanyForm={handleOpenEditCompanyForm}
    />
  );
}

export default DealFormWrapper;
