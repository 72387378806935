import React, { useEffect, useState } from "react";
import Loader from "components/shared/Loader";
import PropTypes from "prop-types";
import NotFound from "components/NotFound";

function View(props) {
  const {
    mountAction,
    unmountAction,
    attributeChangedAction,
    attributeToVerify,
    component,
    resourceAvailable,
    showLoader,
  } = props;

  const [isLoading, setIsLoading] = useState(true);
  const storedAttribute = React.useRef(attributeToVerify);

  useEffect(() => {
    if (mountAction) {
      setIsLoading(true);
      Promise.resolve(mountAction()).then(() => setIsLoading(false));
      if (unmountAction) {
        return () => unmountAction();
      }
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      storedAttribute.current &&
      storedAttribute.current !== attributeToVerify
    ) {
      storedAttribute.current = attributeToVerify;
      setIsLoading(true);
      Promise.resolve(attributeChangedAction()).then(() => setIsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributeToVerify]);

  if (!resourceAvailable) {
    return <NotFound />;
  }

  if (isLoading || showLoader) {
    return <Loader />;
  } else {
    return React.createElement(component, props);
  }
}

View.defaultProps = {
  resourceAvailable: true,
};

View.propTypes = {
  mountAction: PropTypes.func,
  unmountAction: PropTypes.func,
  attributeChangedAction: PropTypes.func,
  attributeToVerify: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  component: PropTypes.func,
  resourceAvailable: PropTypes.bool,
  showLoader: PropTypes.bool,
};

export default View;
