import React from "react";
import Select from "components/shared/form/Select";

function DTFileTypeDateEdit({
  errorMessage,
  file,
  fileTypes,
  formIndex,
  updateForm,
}) {
  const options = fileTypes.map((f) => ({ value: f, label: f }));

  return (
    <div className="DTFileTypeDateEdit">
      <Select
        key={file.id}
        placeholder="Choose type"
        size="small"
        inputProps={options}
        value={file.document_type}
        errorMessage={errorMessage}
        onChange={(value) => {
          updateForm("document_type", value, formIndex, file.id);
        }}
      />
    </div>
  );
}

export default DTFileTypeDateEdit;
