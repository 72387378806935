import React, { useState, Fragment } from "react";
import getMessage from "components_new/lib/Messages";
import ConfirmationModal from "components/shared/ConfirmationModal";
import { useRemoveCoInvestment } from "components_new/views/Company/hooks/mutations/useRemoveCoInvestment";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import { closeModal } from "store/modalV2/actions";
import { useDispatch } from "react-redux";
import { useCompany } from "components_new/views/Company/hooks/queries/useCompany";

const createMessage = (companyName, resourceName) => {
  return (
    <Fragment>
      Are you sure you want to delete this {resourceName} from
      <strong> {companyName} </strong> ?
    </Fragment>
  );
};

function RemoveCoInvestmentWrapper({
  coInvestment,
  companyId,
  resourceName = "Co-Investment",
}) {
  const [submitting, setSubmitting] = useState(false);
  const { mutateAsync: removeCoInvestment } = useRemoveCoInvestment();

  const dispatch = useDispatch();
  const { addToast } = useSetToast();
  const { data } = useCompany(companyId);

  const handleInvestor = async () => {
    setSubmitting(true);

    await removeCoInvestment({ coInvestment, companyId });
    dispatch(closeModal());
    setSubmitting(false);
    addToast(getMessage("deleteCoInvestment"));
  };

  return (
    <ConfirmationModal
      title={`Delete ${resourceName}`}
      message={createMessage(data?.companyDetails.name, resourceName)}
      confirmButtonLabel={`Delete ${resourceName}`}
      cancelButtonLabel="Cancel"
      primaryButtonClassnames="Button--warning"
      submitting={submitting}
      closeModal={() => dispatch(closeModal())}
      action={handleInvestor}
    />
  );
}

export default RemoveCoInvestmentWrapper;
