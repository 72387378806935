import React from "react";
import formatBytes from "components_new/lib/formatBytes";
import iconType from "components_new/lib/iconType";
import Button from "components_new/atoms/Button";
import Checkbox from "components_new/atoms/Checkbox";
import Dropdown from "components_new/atoms/Dropdown";
import Icon from "components_new/atoms/Icon";
import FileIcon from "components_new/atoms/FileIcon";
import Label from "components_new/atoms/Label";
import Progress from "components_new/atoms/Progress";
import PropTypes from "prop-types";
import classnames from "classnames";
import { fileOptions, maxSize } from "./config";
import { Grid, Header } from "@ableco/semantic-ui-react";
import {
  fileItemProptype,
  uploadedDocPropType,
} from "components_new/customPropTypes";
import "semantic-ui-css/components/header.css";
import "./FileItem.scss";
import numeral from "numeral";

const STATUS_TO_COMPONENT = {
  error: ErrorFile,
  forbidden: ErrorFile,
  saved: FileInformation,
  closing: FileInformation,
  editing: EditFile,
  uploading: UploadIndicator,
  pending: UploadIndicator,
  canceled: CancelFile,
  deleting: DeleteFile,
};

const MAX_FILE_SIZE = numeral(maxSize).format("0[.]0 b");

const SUBHEADER_MESSAGES = {
  error: `Uploaded files cannot be larger than ${MAX_FILE_SIZE}`,
  forbidden: `The type of the file is forbidden`,
  canceled: "Canceled",
};

function FileInformation({ uploadedFile, handleChange }) {
  const {
    closing_document: closing,
    document_type: documentType,
  } = uploadedFile;

  return (
    <ul className="FileInformation">
      {closing && (
        <li>
          <Label type="alternate">Closing</Label>
        </li>
      )}
      {documentType && <li>{documentType}</li>}
      <li
        className="edit-file"
        onClick={() => {
          handleChange("status", "editing");
          handleChange("update", true);
        }}
      >
        <Icon icon="ri-pencil-line" />
      </li>
    </ul>
  );
}

function UploadIndicator({ item, cancelUploading }) {
  const { progress } = item;
  return (
    <ul className="UploadIndicator">
      <li>
        <Progress percent={progress} indicating />
      </li>
      <li>
        <Icon
          icon="ri-close-line"
          size="large"
          onClick={() => cancelUploading(item)}
        />
      </li>
    </ul>
  );
}

function EditFile({
  uploadedFile,
  handleChange,
  canMarkAsClosing,
  canChooseFileType,
  componentSize,
}) {
  const { closing_document, document_type, isSaving } = uploadedFile;
  const extraClassname = classnames("List", { isSaving: !!isSaving });

  const checkboxElement = canMarkAsClosing && (
    <Checkbox
      label="Closing"
      checked={closing_document}
      onChange={(_, { checked }) => handleChange("closing_document", checked)}
    />
  );

  return (
    <ul className={extraClassname}>
      {componentSize === "regular" && <li>{checkboxElement}</li>}
      <li>
        {canChooseFileType && (
          <Dropdown
            placeholder="Document type"
            search
            fluid
            selection
            value={document_type}
            options={fileOptions}
            className="document-type"
            onChange={(_, target) => {
              const value = target ? target.value : null;
              handleChange("document_type", value);
            }}
            size={componentSize}
          />
        )}
        {componentSize !== "regular" && checkboxElement}
      </li>
      {isSaving ? (
        <li>
          <Icon icon="ri-refresh-line" />
        </li>
      ) : (
        <li onClick={() => handleChange("status", "deleting")}>
          <Icon icon="ri-delete-bin-line" />
        </li>
      )}
    </ul>
  );
}

function DeleteFile({ handleChange, removeFile, uploadedFile }) {
  const { localId } = uploadedFile;
  return (
    <ul className="Buttons">
      <li onClick={() => handleChange("status", "editing")}>
        <Button size="small" warning inverted content="Cancel" />
      </li>
      <li onClick={() => removeFile(localId, uploadedFile)}>
        <Button size="small" warning content="Delete" />
      </li>
    </ul>
  );
}

function ErrorFile({ removeFile, item: { localId } }) {
  return (
    <a className="dismiss-button" onClick={() => removeFile(localId)}>
      Dismiss
    </a>
  );
}

function CancelFile({ item, retryUploadFile }) {
  return <Icon icon="ri-refresh-line" onClick={() => retryUploadFile(item)} />;
}

function getFileInformation(item, uploadedFile) {
  const { fileObject } = item;

  const status = uploadedFile.status || item.status;
  const filename = uploadedFile.file_name || fileObject.name;
  const filetype = uploadedFile.mime_type || fileObject.type;
  const size = uploadedFile.size || (fileObject && fileObject.size);

  const documentType = uploadedFile.document_type;
  const closingDocument = uploadedFile.closing_document;

  return {
    filetype,
    filename,
    status,
    documentType,
    closingDocument,
    size,
  };
}

function FileItem({
  item,
  uploadedFile = {},
  updateUploadedFile,
  componentSize,
  defaultDocumentType,
  ...restProps
}) {
  const { localId } = item;
  const {
    size,
    filetype,
    filename,
    status: itemStatus,
    closingDocument,
    documentType,
  } = getFileInformation(item, uploadedFile);

  // TODO : We are adding this extra clause because in the backend we weren't getting the size
  // So we have some documents with a property size set as null.
  const formattedSize = size ? formatBytes(size) : "Unavailable";
  const subheaderMessage = SUBHEADER_MESSAGES[itemStatus] || formattedSize;
  const FileComponent = STATUS_TO_COMPONENT[itemStatus];

  const handleChange = (name, value) => {
    updateUploadedFile(localId, {
      [name]: value,
    });
  };

  const fileItemClassnames = classnames(
    "FileItem",
    `FileItem-${componentSize}`,
    {
      [itemStatus]: !!itemStatus,
      closing: closingDocument,
      "has-document-type": !!documentType,
    },
  );
  return (
    <li className={fileItemClassnames} data-testid={`fileItem-${itemStatus}`}>
      <Grid>
        <Grid.Column textAlign="left" verticalAlign="middle">
          {itemStatus === "deleting" ? (
            <ul className="Delete-message">
              <Icon icon="ri-alert-line" size="large" />
              <p>Delete this file? </p>
            </ul>
          ) : (
            <Header
              size="tiny"
              content={filename}
              subheader={subheaderMessage}
              icon={() => <FileIcon type={iconType(filetype)} />}
            />
          )}
        </Grid.Column>
        <Grid.Column textAlign="right" verticalAlign="middle">
          <FileComponent
            item={item}
            handleChange={handleChange}
            uploadedFile={uploadedFile}
            componentSize={componentSize}
            defaultDocumentType={defaultDocumentType}
            {...restProps}
          />
        </Grid.Column>
      </Grid>
    </li>
  );
}

FileItem.propTypes = {
  item: fileItemProptype,
  uploadedFile: uploadedDocPropType,
  updateUploadedFile: PropTypes.func,
  componentSize: PropTypes.oneOf(["tiny", "small", "regular"]),
  defaultDocumentType: PropTypes.string,
};

export default FileItem;
