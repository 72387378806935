import { isEmpty } from "lodash";

export default function mapCollectionToOptions({ collection, attrName }) {
  if (isEmpty(collection)) return [];

  return collection
    .filter((item) => !isEmpty(item))
    .map((item, index) => ({
      key: index,
      value: attrName ? item[attrName] : item,
      text: attrName ? item[attrName] : item,
    }));
}
