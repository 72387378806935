import React from "react";
import CollapsableSection from "components_new/elements/CollapsableSection";
import InlineValueList from "components_new/atoms/InlineValueList";
import PropTypes from "prop-types";
import { customFieldProptype } from "PropTypes";

const MULTIPLE_CHOICE = "multiple_choice";

const TYPES_FOR_VALUE = {
  text: "text",
  currency: "currency",
  number: "quantity",
  textarea: "text",
  date: "date",
  multiple_choice: MULTIPLE_CHOICE,
  single_choice: "text",
};

function getCustomDetailsInfo(details) {
  return details.map((detail) => {
    const {
      field_value: value,
      field_label: label,
      field_type: type,
      field_data: multipleChoiceValues,
    } = detail;

    const multipleChoicesWithValues = multipleChoiceValues.filter((choice) =>
      Boolean(choice.value),
    );

    const customValue =
      type === MULTIPLE_CHOICE ? multipleChoicesWithValues : value;

    return { label: label, value: customValue, type: TYPES_FOR_VALUE[type] };
  });
}

function CustomDetails({ details }) {
  return (
    <CollapsableSection
      expanded
      title="Custom Details"
      extraClassNames="no-bottom-border"
    >
      <div className="DealDetails">
        <InlineValueList data={getCustomDetailsInfo(details)} />
      </div>
    </CollapsableSection>
  );
}

CustomDetails.propTypes = {
  details: PropTypes.arrayOf(customFieldProptype),
};

export default CustomDetails;
