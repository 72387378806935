import React, { useState, useEffect, Fragment } from "react";
import { isEmpty } from "lodash";
import FormGroup from "components_new/elements/FormGroup";
import FileRow from "components_new/elements/FileRow";
import DropZone from "components_new/elements/DropZone";
import Table, {
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableBody,
  TableCell,
} from "components_new/elements/Table";
import "./ContactFiles.scss";

function show_title(count) {
  return (
    <Fragment>
      Files <small>({count})</small>
    </Fragment>
  );
}

function ContactFiles({
  documents = {},
  updateContactDocument,
  removeContactDocument,
  saveContactDocuments,
  currentOrganizationUsername,
  contactId,
}) {
  const [documentsData, setDocumentsData] = useState([]);

  const [modifiedFileId, setModifiedFileId] = useState(null);
  const [currentAction, setCurrentAction] = useState(null);

  const setEditFile = (fileId) => {
    setCurrentAction("edit");
    setModifiedFileId(fileId);
  };

  const setDeleteFile = (fileId) => {
    setCurrentAction("delete");
    setModifiedFileId(fileId);
  };

  const resetFileActions = (fileId) => {
    if (fileId === modifiedFileId) {
      setModifiedFileId(null);
      setCurrentAction(null);
    }
  };

  function uploadDocuments(documentsData) {
    documentsData.map((document) => {
      saveContactDocuments({ contactId, document });
    });
  }

  useEffect(() => {
    if (documentsData.length > 0) {
      uploadDocuments(documentsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentsData]);

  return (
    <FormGroup
      title={show_title(documents.length)}
      isExpanded={true}
      extraClassnames="ContactFiles"
    >
      {!isEmpty(documents) && (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHeaderCell extraClassnames="Name">Name</TableHeaderCell>
              <TableHeaderCell extraClassnames="Type">Type</TableHeaderCell>
              <TableHeaderCell extraClassnames="Uploaded">
                Uploaded
              </TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {documents.map((document) => {
              return (
                <TableRow key={document.id}>
                  <TableCell colSpan="3">
                    <FileRow
                      key={document.id}
                      file={document}
                      setEditFile={setEditFile}
                      setDeleteFile={setDeleteFile}
                      resetFileActions={resetFileActions}
                      currentAction={currentAction}
                      currentOrganizationUsername={currentOrganizationUsername}
                      editAction={updateContactDocument}
                      deleteAction={removeContactDocument}
                      modifiedFileId={modifiedFileId}
                      dropdownAlignment="left"
                      componentSize="small"
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
      <DropZone
        onChange={(values) => {
          setDocumentsData(values);
        }}
        noQueue={true}
        canMarkAsClosing={false}
        canChooseFileType={false}
      />
    </FormGroup>
  );
}

export default ContactFiles;
