import { useQuery } from "react-query";
import Api from "lib/Api";

export function useInvestmentPosts(investmentId) {
  return useQuery(
    ["InvestmentPosts", investmentId],
    async () => {
      const { data } = await Api.get(`/investments/${investmentId}/posts`);
      return data;
    },
    {
      enabled: !!investmentId,
    },
  );
}
