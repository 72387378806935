import React from "react";
import FlashListAppContainer from "containers/shared/modals/FlashListAppContainer";
import PageFooter from "components/pages/shared/PageFooter";
import ModalWrapper from "components/shared/ModalWrapper";
import Modal from "components/shared/Modal";
import Header from "../Header";
import MainContentWithSidebarComponents from "components_new/layouts/MainContentWithSidebarComponents";
import SidebarMenu from "../SidebarMenu";
import { ToastProvider } from "components_new/elements/Toaster/Toaster-context";
import { SidebarProvider } from "components_new/elements/SidebarWrapper/Sidebar-context";
import Notifications from "components_new/elements/Toaster/Notifications";
import SidebarWrapper from "components_new/elements/SidebarWrapper";
import GlobalShortcuts from "components/Mighty/GlobalShortcuts";
import "./MainLayout.scss";

function MainLayout({ children, organizationUsername, isCanary, isAdmin }) {
  return (
    <ToastProvider>
      <SidebarProvider>
        <GlobalShortcuts>
          <MainContentWithSidebarComponents>
            <div className="MainLayout">
              <Notifications />
              <Header organizationUsername={organizationUsername} />
              <div className="MainLayout-wrapper">
                <SidebarMenu isCanary={isCanary} isAdmin={isAdmin} />
                <main role="main" className="MainLayout-content">
                  <FlashListAppContainer />
                  {children}
                </main>
              </div>
              <SidebarWrapper />
            </div>
          </MainContentWithSidebarComponents>
          <Modal />
          <ModalWrapper />
          <PageFooter />
        </GlobalShortcuts>
      </SidebarProvider>
    </ToastProvider>
  );
}

export default MainLayout;
