import React, { Fragment } from "react";
import modalComponents from "store/modalV2/modalComponents";
import { Modal } from "components_new/elements/Modal";

function ModalWrapper({ isOpen, modalNames, modalProps, closeModal }) {
  if (isOpen) {
    return (
      <Fragment>
        {modalNames.map((modalName, index) => {
          const children = getModalChildren(modalName, modalProps[modalName]);
          return (
            <Modal
              key={index}
              isOpen={isOpen}
              handleClose={closeModal}
              size={modalComponents[modalName].size}
              closeIcon
            >
              {children}
            </Modal>
          );
        })}
      </Fragment>
    );
  }

  return null;
}

function getModalChildren(modalName, modalProps) {
  const component =
    modalComponents[modalName] && modalComponents[modalName].component;
  return React.createElement(component, modalProps);
}

export default ModalWrapper;
