import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthenticationContext from "../AuthenticationContext";

function pageRequiringIntermediateToken(Component) {
  const PageRequiringIntermediateToken = (props) => {
    const navigate = useNavigate();
    const [canContinue, setCanContinue] = useState(false);
    const { isWaitingForMfaCode } = useContext(AuthenticationContext);

    useEffect(() => {
      if (isWaitingForMfaCode) {
        setCanContinue(true);
      } else {
        navigate("/", { replace: true });
      }
    }, [isWaitingForMfaCode, navigate]);

    if (canContinue) {
      return <Component {...props} />;
    } else {
      return null;
    }
  };

  PageRequiringIntermediateToken.displayName = `PageRequiringIntermediateToken(${Component.name})`;
  return PageRequiringIntermediateToken;
}

export default pageRequiringIntermediateToken;
