import { OPEN, SHOW, HIDE, UPDATE, CLEAR, CLOSE } from "./actionTypes";

const sidebarInitialState = {
  visible: false,
  initialComponent: null,
  initialTitle: null,
  initialProps: {},
  warnForm: null,
};

export default function sidebarReducer(state = sidebarInitialState, action) {
  switch (action.type) {
    case OPEN: {
      if (state.initialComponent) {
        return {
          ...state,
          initialProps: {
            ...state.initialProps,
            nextSession: {
              name: action.name,
              title: action.title,
              props: action.props,
            },
          },
        };
      }

      return {
        visible: true,
        initialComponent: action.name,
        initialTitle: action.title,
        initialProps: action.props,
        warnForm: null,
      };
    }

    case SHOW: {
      return {
        ...state,
        visible: true,
      };
    }
    case HIDE: {
      return {
        ...state,
        visible: false,
      };
    }

    case UPDATE: {
      return {
        ...state,
        ...action.props,
      };
    }

    case CLEAR: {
      return {
        ...sidebarInitialState,
        visible: true,
      };
    }

    case CLOSE: {
      return {
        ...sidebarInitialState,
      };
    }

    default: {
      return state;
    }
  }
}
