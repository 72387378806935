import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback,
} from "react";
import PortfolioTabPane from "./PortfolioTabPane";
import queryString from "query-string";
import AuthenticationContext from "components/Mighty/AuthenticationContext";
import Api from "lib/Api";

import { useParams, useLocation } from "react-router-dom";
import { useInvestments } from "components_new/views/Portfolios/hooks/queries/useInvestments";
import { useCashFlow } from "components_new/views/Portfolios/hooks/queries/useCashFlow";
import { isEmpty } from "lodash";

function PortfoliosViewWrapper({ type, ...parentProps }) {
  const [filters, setFilters] = useState({});
  const location = useLocation();

  const {
    id: portfolioId,
    tab: currentTab,
    organizationUsername,
  } = useParams();

  const {
    currentUser: {
      id: currentUserId,
      show_closing_documents: showClosingDocuments,
    },
    setCurrentUser,
  } = useContext(AuthenticationContext);

  useEffect(() => {
    setFilters({});
  }, [portfolioId, type]);

  const groupedByCompany = useMemo(() => {
    const query = queryString.parse(location.search);
    return query.grouped_by === "company";
  }, [location]);

  const isSearchActive = useMemo(() => {
    const { types, dates, query } = filters;

    const isSearching = [types, dates, query].some(
      (filter) => !isEmpty(filter),
    );

    return isSearching;
  }, [filters]);

  const handleUpdateUser = useCallback(
    async (payload) => {
      const { data: updatedUser } = await Api.patch(
        `/users/${currentUserId}`,
        { user: payload },
        { withoutOrganization: true },
      );

      setCurrentUser(updatedUser);
    },
    [currentUserId, setCurrentUser],
  );

  const { data: investmentData, isLoading } = useInvestments(
    type,
    portfolioId,
    groupedByCompany,
    filters,
  );

  const { data: cashFlowData, isLoading: isLoadingCashFlow } = useCashFlow(
    type,
    portfolioId,
    groupedByCompany,
    filters,
  );

  const tabRelatedData = useMemo(() => {
    if (type === "cashFlow") {
      return {
        isLoading: isLoadingCashFlow,
        data: cashFlowData?.cashFlow,
        companies: cashFlowData?.companies,
        summaryStats: cashFlowData?.summaryStats || {},
      };
    }

    return {
      isLoading,
      data: investmentData?.investments,
      companies: investmentData?.companies,
      summaryStats: investmentData?.summaryStats || {},
      dataByCompany: investmentData?.investmentsByCompany || [],
    };
  }, [investmentData, cashFlowData, type, isLoading, isLoadingCashFlow]);

  return (
    <PortfolioTabPane
      {...tabRelatedData}
      type={type}
      currentTab={currentTab}
      organizationUsername={organizationUsername}
      isSearchActive={isSearchActive}
      portfolioId={portfolioId}
      filters={filters}
      updateFilters={setFilters}
      groupedByCompany={groupedByCompany}
      location={location}
      showClosingDocuments={showClosingDocuments}
      udpateUserInformation={handleUpdateUser}
      allRowsExpanded={false}
      allRowsCollapsed={false}
      resetInvestmentsViewRows={() => {}}
      {...parentProps}
    />
  );
}

export default PortfoliosViewWrapper;
