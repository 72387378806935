import PropTypes from "prop-types";
import React from "react";
import NavigationTabs from "components/shared/NavigationTabs";

function DashboardChartsLayout({ children }) {
  return (
    <div className="u-row DashboardCharts">
      <div className="DashboardHomeColumn">
        <h3 className="u-sectionHeading DashboardLayoutColumn-title">
          Organization Investments
        </h3>
        <NavigationTabs
          items={[
            {
              url: "/dashboard/investment-pace",
              urlAlias: "/dashboard",
              children: "Investment Pace",
            },
            {
              url: "/dashboard/portfolio-performance",
              children: "Portfolio Performance",
            },
          ]}
        />
        <div className="u-bg-tint-0 DashboardChartsContent">{children}</div>
      </div>
    </div>
  );
}

DashboardChartsLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardChartsLayout;
