import React, { useEffect } from "react";
import PropTypes from "prop-types";
import FileRowPreview from "./FileRowPreview";
import FileRowEdit from "./FileRowEdit";
import FileRowDelete from "./FileRowDelete";
import classNames from "classnames";
import { documentPropType } from "PropTypes";
import "./FileRow.scss";

function FileRow({
  file,
  setEditFile,
  setDeleteFile,
  resetFileActions,
  currentAction,
  editAction,
  deleteAction,
  modifiedFileId,
  currentOrganizationUsername,
  dropdownAlignment = "right",
  componentSize,
}) {
  useEffect(
    () => {
      return () => {
        resetFileActions(modifiedFileId);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const isFileBeingModified = file.id === modifiedFileId;

  const fileRowComponents = {
    edit: FileRowEdit,
    delete: FileRowDelete,
  };

  const ComponentByAction = isFileBeingModified
    ? fileRowComponents[currentAction]
    : FileRowPreview;

  const fileRowClassnames = classNames("FileRow", {
    "is-editing": isFileBeingModified,
    [componentSize]: componentSize,
  });

  return (
    <div className={fileRowClassnames}>
      <ComponentByAction
        file={file}
        setEditFile={setEditFile}
        setDeleteFile={setDeleteFile}
        editAction={editAction}
        deleteAction={deleteAction}
        resetFileActions={resetFileActions}
        currentOrganizationUsername={currentOrganizationUsername}
        dropdownAlignment={dropdownAlignment}
        componentSize={componentSize}
      />
    </div>
  );
}

FileRow.defaultProps = {
  resetDocumentActions: null,
};

FileRow.propTypes = {
  file: documentPropType.isRequired,
  setEditFile: PropTypes.func.isRequired,
  setDeleteFile: PropTypes.func.isRequired,
  resetFileActions: PropTypes.func.isRequired,
  currentAction: PropTypes.string,
  editAction: PropTypes.func.isRequired,
  deleteAction: PropTypes.func.isRequired,
  modifiedFileId: PropTypes.number,
  currentOrganizationUsername: PropTypes.string.isRequired,
  dropdownAlignment: PropTypes.string,
  componentSize: PropTypes.string,
};

export default FileRow;
