import FormField from "components_new/atoms/Form/FormField";
import React from "react";
import SearchAndSelect from "./SearchAndSelectContainer";
import "semantic-ui-css/components/form.css";

export default function SearchAndSelectField({
  fieldLabel,
  errorMessage,
  errorPointingDirection,
  placeholder,
  searchComponent,
  ...props
}) {
  return (
    <FormField
      error={
        errorMessage && {
          content: errorMessage,
          pointing: errorPointingDirection,
        }
      }
      label={fieldLabel}
      control={searchComponent || SearchAndSelect}
      placeholder={placeholder}
      {...props}
    />
  );
}
