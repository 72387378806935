import React from "react";
import { Header as SemanticHeader } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/header.css";
import "./Header.scss";

function HeaderContent({ children, ...props }) {
  return <SemanticHeader.Content {...props}>{children}</SemanticHeader.Content>;
}

export default HeaderContent;
