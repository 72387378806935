import { connect } from "react-redux";
import { openInModal, ModalNames } from "store/modal";

function ModalOpener({ render, openInModal }) {
  return render(openInModal, ModalNames);
}

function mapDispatchToProps(dispatch) {
  return {
    openInModal: (modalName) => dispatch(openInModal(modalName)),
  };
}

export default connect(null, mapDispatchToProps)(ModalOpener);
