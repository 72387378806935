import React from "react";
import BaseConfirmDialog from "components_new/elements/BaseConfirmDialog";
import PropTypes from "prop-types";
import { documentPropType } from "PropTypes";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import getMessage from "components_new/lib/Messages";

function FileRowDelete({ file, deleteAction, resetFileActions }) {
  const { id: fileId } = file;
  const { addToast } = useSetToast();

  const deleteFile = () => {
    deleteAction(file).then(() => {
      addToast(getMessage("deleteFile"));
      resetFileActions(fileId);
    });
  };

  return (
    <div className="FileRowDelete">
      <BaseConfirmDialog
        title="Delete this file? You can’t undo this action."
        cancelButtonText="Delete"
        onConfirm={deleteFile}
        onDiscard={() => resetFileActions(fileId)}
        actionType="danger"
      />
    </div>
  );
}

FileRowDelete.propTypes = {
  file: documentPropType.isRequired,
  deleteAction: PropTypes.func.isRequired,
  resetFileActions: PropTypes.func.isRequired,
};

export default FileRowDelete;
