import React, { useState, useEffect } from "react";
import Button from "components_new/atoms/Button";
import Icon from "components_new/atoms/Icon";
import classnames from "classnames";
import PropTypes from "prop-types";
import "./SelectableButton.scss";

function SelectableButton({ size, className, selected, onClick, children }) {
  const [value, setValue] = useState(selected);

  useEffect(() => {
    if (selected !== value) {
      setValue(selected);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const handleClick = () => {
    setValue((value) => !value);
    if (onClick) onClick();
  };

  const selectableButtonClassnames = classnames("SelectableButton", {
    [className]: !!className,
    selected: value,
  });

  return (
    <Button
      secondary
      size={size}
      className={selectableButtonClassnames}
      onClick={handleClick}
      secondary
    >
      <span className="content">{children(value)}</span>
      {value && <Icon icon="ri-close-circle-fill" size="small" />}
    </Button>
  );
}

SelectableButton.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.func,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default SelectableButton;
