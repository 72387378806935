import {
  EDIT_DASHBOARD_PORTFOLIOS,
  FETCH_DASHBOARD_PORTFOLIOS_START,
  FETCH_DASHBOARD_PORTFOLIOS_SUCCESS,
  UPDATE_DASHBOARD_PORTFOLIOS_START,
  UPDATE_DASHBOARD_PORTFOLIOS_SUCCESS,
} from "actions/synchronous/dashboardPortfoliosView";

const initialState = {
  portfolios: [],
  isFetching: false,
  isEditing: false,
  submitting: false,
};

function editDashboardPortfolios(portfolios, portfolioId, value) {
  return portfolios.map((elem) =>
    elem.portfolio_id === portfolioId
      ? Object.assign({}, elem, { is_visible: value })
      : elem,
  );
}

function dashboardPortfoliosView(state = initialState, action) {
  switch (action.type) {
    case FETCH_DASHBOARD_PORTFOLIOS_START:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_DASHBOARD_PORTFOLIOS_SUCCESS:
      return {
        ...state,
        portfolios: action.portfolios.data,
        isFetching: false,
      };
    case EDIT_DASHBOARD_PORTFOLIOS: {
      const portfolios = editDashboardPortfolios(
        state.portfolios,
        action.portfolioId,
        action.value,
      );
      return {
        ...state,
        portfolios,
        isEditing: true,
      };
    }
    case UPDATE_DASHBOARD_PORTFOLIOS_START:
      return {
        ...state,
        submitting: true,
      };
    case UPDATE_DASHBOARD_PORTFOLIOS_SUCCESS:
      return {
        ...state,
        submitting: false,
        isEditing: false,
      };
    default:
      return state;
  }
}

export default dashboardPortfoliosView;
