import TaskForm from "components_new/forms/TaskForm";
import NoteForm from "components_new/forms/NoteForm";
import DealForm from "components_new/forms/DealForm";
import ReferrerForm from "components_new/forms/ReferrerForm";
import ContactDetailsView from "components_new/SidebarComponents/views/ContactDetailsView";
import ActiveDealView from "components_new/SidebarComponents/views/ActiveDealView";
import InvestmentView from "components_new/SidebarComponents/views/InvestmentView";

function getSidebar(sidebarName, options = {}) {
  const SIDEBAR = {
    taskForm: {
      key: "taskForm",
      component: TaskForm,
      size: "regular",
    },
    noteForm: {
      key: "noteForm",
      component: NoteForm,
      size: "regular",
    },
    dealForm: {
      key: "dealForm",
      component: DealForm,
      size: "regular",
    },
    referrerForm: {
      key: "referrerForm",
      component: ReferrerForm,
      size: "regular",
    },
    contactsView: {
      key: "contactsView",
      component: ContactDetailsView,
      size: "wide",
    },
    activeDealView: {
      key: "activeDealView",
      component: ActiveDealView,
      size: "wide",
    },
    investmentView: {
      key: "investmentView",
      component: InvestmentView,
      size: "wide",
    },
  };

  const currentSidebar = SIDEBAR[sidebarName];
  return { ...currentSidebar, props: options };
}

export default getSidebar;
