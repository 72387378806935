import { useDispatch } from "react-redux";
import { addValuation as addValuationHelper } from "store/investments/v2/valuations/actions";
import { useQueryClient } from "react-query";

export default () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const addValuation = (formData) => {
    return dispatch(addValuationHelper(formData)).then(() => {
      queryClient.invalidateQueries(["Company"]);
    });
  };

  return {
    addValuation,
  };
};
