import React, { useRef, useState, useEffect } from "react";
import classNames from "classnames";

const preventAndStopPropagation = (event) => {
  event.preventDefault();
  event.stopPropagation();
};

function DropHandler({ extraClassnames, children, onDrop, disabled }) {
  const divBoxRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const isComponentEnabled = !disabled;

  const handleDragIn = (event) => {
    preventAndStopPropagation(event);
    setIsDragging(true);
  };

  const handleDragOut = (event) => {
    preventAndStopPropagation(event);
    setIsDragging(false);
  };

  const handleDrag = (event) => {
    preventAndStopPropagation(event);
    setIsDragging(true);
  };

  const handleDrop = (event) => {
    preventAndStopPropagation(event);
    setIsDragging(false);

    if (onDrop && isComponentEnabled) {
      onDrop(event);
    }
  };

  useEffect(
    () => {
      const divRef = divBoxRef.current;
      divRef.addEventListener("dragenter", handleDragIn);
      divRef.addEventListener("dragleave", handleDragOut);
      divRef.addEventListener("dragover", handleDrag);
      divRef.addEventListener("drop", handleDrop);

      return () => {
        divRef.removeEventListener("dragenter", handleDragIn);
        divRef.removeEventListener("dragleave", handleDragOut);
        divRef.removeEventListener("dragover", handleDrag);
        divRef.removeEventListener("drop", handleDrop);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [divBoxRef],
  );

  const dropHandlerClassnames = classNames({
    [extraClassnames]: !!extraClassnames,
    "is-dragging": isDragging && isComponentEnabled,
  });

  return (
    <div className={dropHandlerClassnames} ref={divBoxRef}>
      {children}
    </div>
  );
}

export default DropHandler;
