import { isEmpty } from "lodash";

export const addTaskIdToForm = (taskId) => {
  return taskId
    ? {
        formData: {
          task_id: taskId,
        },
      }
    : {};
};

export const addTaskAttachmentsToFormDocuments = (taskId, taskAttachments) => {
  return !isEmpty(taskAttachments)
    ? {
        formData: {
          documents_data: taskAttachments.map((taskAttachment) => {
            return {
              ...taskAttachment,
              id: undefined, // id on file related to task, not document
              localId: taskAttachment.id,
              handle: taskAttachment.file_handle,
              filename: taskAttachment.file_name,
              mimetype: taskAttachment.mime_type,
              task_id: taskId,
              status: "editing",
            };
          }),
        },
      }
    : {};
};
