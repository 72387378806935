import Api from "lib/Api";
import {
  itemFetchStart,
  itemFetchSuccess,
  itemFetchFail,
  mutationStart,
  mutationFail,
  mutationSuccess,
  singletonFetchStart,
  singletonFetchSuccess,
  singletonFetchFail,
} from "store/domain/domainActions";
import Errors from "lib/errors";
import { getCollection } from "store/domain/selectors";
import { createCompany, fetchCompanyV2 } from "store/companies/company/actions";
import { receiveInvestment } from "actions/synchronous/shared";
import { setResourceAvailability } from "store/resources/actions";
import { receiveCollections } from "store/entities";
import { isEmpty } from "lodash";
import { receiveView, updateView } from "store/views";
import { receiveCollection } from "store/resourcesV2";
import { receiveCollection as oldReceiveCollection } from "store/entities/actions";

export function fetchInvestment(investmentId) {
  return (dispatch) => {
    dispatch(itemFetchStart("investments", investmentId));
    return Api.get(`/investments/${investmentId}`).then(
      ({ data: investment }) => {
        dispatch(
          itemFetchSuccess("minimalInvestments", investmentId, investment),
        );
        dispatch(receiveInvestment(investment));
        dispatch(itemFetchSuccess("investments", investmentId, investment));
        return investment;
      },
      (error) => {
        dispatch(setResourceAvailability(false));
        dispatch(itemFetchFail("investments", investmentId));
        throw error;
      },
    );
  };
}

export function fetchInvestmentDetailsV2(investmentId) {
  return (dispatch) => {
    dispatch(itemFetchStart("investments", investmentId));
    return Api.get(`/v2/investments/${investmentId}/details`).then(
      ({
        data: {
          entities,
          views: { investment: view },
        },
      }) => {
        dispatch(receiveView("investment", view));
        dispatch(receiveCollections(entities));
        dispatch(itemFetchFail("investments", investmentId)); //Just to change the state
        const [investment] = entities.investment_details;
        return investment;
      },
    );
  };
}

export function fetchInvestmentV2(investmentId) {
  // TODO: This is a POC, we need to add support to handle the request status
  return (dispatch) => {
    return Api.get(`/v2/investments/${investmentId}`).then(
      ({
        data: {
          entities,
          views: { investment: view },
        },
      }) => {
        dispatch(receiveView("investment", view));
        dispatch(receiveCollections(entities));
      },
    );
  };
}

export function reloadInvestment(investmentId) {
  return (dispatch) => {
    return Api.get(`/investments/${investmentId}`).then(
      ({ data: investment }) => {
        dispatch(itemFetchSuccess("investments", investment.id, investment));
        return investment;
      },
    );
  };
}

// NOTE: This a temporary fix until we define how we are use the new
// view structure to update the investment resources
export function reloadInvestmentAfterDocumentsV2(investmentId) {
  return (dispatch) => {
    dispatch(mutationStart("createDocuments"));
    return Api.get(`/v2/investments/${investmentId}/details`).then(
      ({
        data: {
          entities,
          views: { investment: view },
        },
      }) => {
        dispatch(receiveView("investment", view));
        dispatch(receiveCollections(entities));
        const [investment] = entities.investment_details;
        dispatch(mutationSuccess("createDocuments"));
        return investment;
      },
    );
  };
}

export function updateInvestment(investmentId, formData, documents) {
  return (dispatch) => {
    dispatch(mutationStart("updateInvestment"));

    let customName = formData.name;
    if (formData.type === "equity") {
      customName = !!formData.series_name
        ? formData.series_name
        : formData.name;
    }
    const payload = {
      ...formData,
      name: customName,
      documents_data: Object.values(documents),
    };

    return Api.patch(`/investments/${investmentId}`, {
      investment: payload,
    })
      .then(
        ({
          data: {
            entities,
            views: { investment: view },
          },
        }) => {
          dispatch(receiveView("investment", view));
          dispatch(receiveCollections(entities));

          dispatch(mutationSuccess("createDocuments"));
          dispatch(mutationSuccess("updateInvestment"));

          const [investment] = entities.investment_details;
          return investment;
        },
      )
      .catch((error) => {
        dispatch(mutationFail("updateInvestment"));
        dispatch(mutationFail("createDocuments"));
        throw error;
      });
  };
}

async function findOrCreatePortfolio(dispatch, getState, newPortfolioName) {
  try {
    const portfolios = getCollection(getState(), "portfolios");
    const foundPortfolio = portfolios.find(
      (portfolio) => portfolio.name === newPortfolioName,
    );

    if (foundPortfolio) {
      return Promise.resolve(foundPortfolio.id);
    } else {
      const { data } = await Api.post("/portfolios", {
        name: newPortfolioName,
      });
      dispatch(itemFetchSuccess("portfolios", data.id, data));
      return data.id;
    }
  } catch (error) {
    // Explanation: We tried to solved this by changing the response in the backend (that's a good idea IMHO, but after doing that it seems we were affecting the bulkupdate and capital call specs, so in order to send this fix I took the approach to changed it in the frontend)
    let customErrors = new Errors({});
    customErrors.errors["portfolio_name"] = error.data.errors.name;
    throw customErrors;
  }
}

export function findOrCreateCompany(dispatch, getState, newCompanyName) {
  const allCompanies = getCollection(getState(), "companies");
  const foundCompany = allCompanies.find(
    (company) => company.name === newCompanyName,
  );
  if (foundCompany) {
    return Promise.resolve(foundCompany.id);
  } else {
    return dispatch(createCompany(newCompanyName));
  }
}

export function createInvestmentV2(formData) {
  return async (dispatch, getState) => {
    try {
      dispatch(mutationStart("createInvestment"));
      const { isNewPortfolio, portfolio_id } = formData;

      const portfolioId = isNewPortfolio
        ? await findOrCreatePortfolio(dispatch, getState, portfolio_id)
        : portfolio_id;

      const payload = {
        ...formData,
        portfolio_id: portfolioId,
        documents_data: Object.values(formData.documents),
        deal_id: formData.deal && formData.deal.id,
      };

      const {
        data: { entities },
      } = await Api.post("/investments", { investment: payload });
      const [createdInvestment] = entities.investment_details;
      const state = getState();
      createInvestmentCallbacks(entities, dispatch, state);
      dispatch(mutationSuccess("createInvestment"));
      return createdInvestment;
    } catch (error) {
      dispatch(mutationFail("createInvestment"));
      throw error;
    }
  };
}

function createInvestmentCallbacks(entities, dispatch, state) {
  const {
    views: { company: companyView, contacts: contactsView },
  } = state;

  const [createdInvestment] = entities.investment_details;
  const [organization] = entities.organization;

  if (isInCurrentView(companyView, createdInvestment.company_id)) {
    dispatch(singletonFetchStart("companyView"));
    dispatch(fetchCompanyV2(createdInvestment.company_id))
      .then(() => dispatch(singletonFetchSuccess("companyView")))
      .catch((error) => {
        dispatch(singletonFetchFail("companyView"));
        throw error;
      });
  }

  if (isInCurrentViewByPathname(contactsView, "contacts")) {
    dispatch(oldReceiveCollection("contacts", entities.contacts));
    entities.contacts.forEach((contact) => {
      dispatch(updateView("contacts", "ids", contact.id));
    });
  }

  dispatch(receiveCollection("organizations", [organization]));
  dispatch(receiveCollection("portfolios", entities.portfolios));
}

export function moveInvestment(
  investmentId,
  { isNewPortfolio, portfolioName, portfolioId: selectedPortfolioId },
) {
  return (dispatch, getState) => {
    const portfolioStatusPromise = isNewPortfolio
      ? findOrCreatePortfolio(dispatch, getState, portfolioName)
      : Promise.resolve(selectedPortfolioId);

    return portfolioStatusPromise.then((portfolioId) => {
      const payload = { portfolio_id: portfolioId };

      dispatch(mutationStart("moveInvestment"));
      return Api.patch(`/investments/${investmentId}/move`, {
        investment: payload,
      })
        .then(
          ({
            data: {
              entities,
              views: { investment: view },
            },
          }) => {
            const [investment] = entities.investment_data;

            dispatch(receiveView("investment", view));
            dispatch(receiveCollections(entities));

            dispatch(mutationSuccess("moveInvestment"));
            return investment;
          },
        )
        .catch((error) => {
          dispatch(mutationFail("moveInvestment"));
          throw error;
        });
    });
  };
}

export function removeInvestment(investmentId) {
  return () => {
    return Api.delete(`/investments/${investmentId}`);
  };
}

export function isInCurrentView(view, id) {
  return !isEmpty(view) && view.id === id;
}

export function isInCurrentViewByPathname(view, currentPathname) {
  return !isEmpty(view) && window.location.pathname.includes(currentPathname);
}
