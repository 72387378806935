import React from "react";
import Dropdown from "components_new/atoms/Dropdown";
import ActiveDealStageSelector from "components_new/views/Deals/ActiveDeals/ActiveDealContent/ActiveDealStageSelector";
import Icon from "components_new/atoms/Icon";

function ActiveDealHeader({
  deal,
  stagesOptions,
  updateDealStage,
  openEditDealForm,
  openDeleteDealModal,
  openCommitToDealModal,
  openPassFromDealModal,
  closeSidebar,
}) {
  const actionsOptions = [
    {
      key: 1,
      text: "Commit to Deal",
      value: 1,
      onClick: () => openCommitToDealModal(deal.id),
    },
    {
      key: 2,
      text: "Pass on Deal",
      value: 2,
      onClick: () => openPassFromDealModal(deal.id),
    },
    {
      key: 3,
      text: "Edit deal",
      value: 3,
      onClick: () => openEditDealForm(deal.id),
    },
    {
      key: 4,
      text: "Delete deal",
      value: 4,
      onClick: () => openDeleteDealModal(deal.id),
    },
  ];

  return (
    <div className="ActiveDealHeader">
      <div className="ActiveDealActions">
        <Dropdown text="Actions" size="small" button options={actionsOptions} />
        <ActiveDealStageSelector
          deal={deal}
          stagesOptions={stagesOptions}
          updateDealStage={updateDealStage}
          openCommitToDealModal={() => openCommitToDealModal(deal.id)}
        />
        <Icon
          icon="ri-close-line"
          size="large"
          extraClassnames="Sidebar-close"
          onClick={closeSidebar}
        />
      </div>
    </div>
  );
}

export default ActiveDealHeader;
