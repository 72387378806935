import React, { useCallback, useMemo } from "react";
import DealStages from "./DealStages";

import Loader from "components/shared/Loader";
import useOrganizations from "store/organizations/hooks";

import { openModal } from "store/modalV2";
import { useDispatch } from "react-redux";
import { usePipeline } from "components_new/views/Deals/hooks/queries/usePipeline";
import { useUpdateStagesOrder } from "components_new/views/Deals/hooks/mutations/useUpdateStagesOrder";

function DealsViewWrapper() {
  const {
    isCurrentUserOrganizationOwner,
    currentOrganization: {
      has_pipelines: hasPipelines,
      pipeline_id: pipelineId,
      username: organizationUsername,
    },
  } = useOrganizations();

  const dispatch = useDispatch();
  const { mutate: updateStagesOrder } = useUpdateStagesOrder();

  const { data, isLoading } = usePipeline(pipelineId);

  const handleOpenStageFormModal = useCallback(
    (formType, pipeline, stage) =>
      dispatch(
        openModal("actionsStage", {
          pipeline,
          stage,
          formType,
        }),
      ),
    [dispatch],
  );

  const orderedStages = useMemo(
    () => data?.stages.sort((a, b) => a.order - b.order),
    [data],
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <DealStages
      pipeline={data?.pipeline}
      stages={orderedStages}
      isOwner={isCurrentUserOrganizationOwner}
      hasPipelines={hasPipelines}
      pipelineId={pipelineId}
      organizationUsername={organizationUsername}
      openStageFormModal={handleOpenStageFormModal}
      updateStagesOrder={updateStagesOrder}
    />
  );
}

export default DealsViewWrapper;
