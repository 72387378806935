import React from "react";
import { connect } from "react-redux";
import { values } from "lodash";
import FlashList from "components/shared/flash/FlashList";
import { removeFlash } from "actions/synchronous/flash";
import { scheduleFlashRemoval } from "actions/asynchronous/flash";

function FlashListContainer(props) {
  return <FlashList {...props} />;
}

function mapStateToProps({ flash }) {
  return {
    flashes: values(flash.flashes),
    showed: flash.showed,
    showInModal: flash.showInModal,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onRemoveFlash: (flash) => {
      dispatch(removeFlash(flash.id));
    },
    scheduleFlashRemoval: (flash, seconds) =>
      dispatch(scheduleFlashRemoval(flash, seconds)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FlashListContainer);
