import React, { useCallback, useMemo } from "react";
import SnapshotContents from "./SnapshotContents";
import Api from "lib/Api";
import { isEmpty, mapKeys } from "lodash";

const searchForItem = ({ type, query }) =>
  Api.get("/snapshots/search_item", {
    params: { type, query },
  });

function SnapshotContentsWrapper({ formData, handleChange }) {
  const { data, snapshot_type: snapshotType } = formData;

  const addSection = useCallback(
    (name) => {
      handleChange("data", [
        ...data,
        { title: name, description: "", data: [] },
      ]);
    },
    [data, handleChange],
  );

  const removeSection = useCallback(
    (id) => {
      handleChange(
        "data",
        data.filter((section) => section.title !== id),
      );
    },
    [data, handleChange],
  );

  const addItem = useCallback(
    ({ sectionId, item }) => {
      if (!isEmpty(item)) {
        handleChange(
          "data",
          data.map((section) => {
            if (section.title === sectionId) {
              return { ...section, data: [...section.data, item] };
            }
            return section;
          }),
        );
      }
    },
    [data, handleChange],
  );

  const removeItem = useCallback(
    (id) => {
      handleChange(
        "data",
        data.map((section) => {
          return {
            ...section,
            data: section.data.filter((item) => item.title !== id),
          };
        }),
      );
    },
    [data, handleChange],
  );

  const memoizeSection = useMemo(() => mapKeys(data, "title"), [data]);

  const orderedColumns = useMemo(() => Object.keys(memoizeSection), [
    memoizeSection,
  ]);

  return (
    <SnapshotContents
      data={data}
      handleChange={handleChange}
      orderedColumns={orderedColumns}
      sections={memoizeSection}
      snapshotType={snapshotType}
      addSection={addSection}
      removeSection={removeSection}
      addItem={addItem}
      removeItem={removeItem}
      searchForItem={searchForItem}
    />
  );
}

export default SnapshotContentsWrapper;
