import React from "react";
import CreateSnapshotButton from "components_new/elements/CreateSnapshotButton";
import Text from "components_new/atoms/Text";
import "./Sidebar.scss";

const Sidebar = () => {
  return (
    <div className="SnapshotsSidebar">
      <CreateSnapshotButton primary snapshotType="deal" />
      <CreateSnapshotButton primary snapshotType="company" />
      <Text type="body-2-regular" color="brand-light">
        Customize and save a deals or companies snapshot
      </Text>
    </div>
  );
};

export default Sidebar;
