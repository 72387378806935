import React from "react";
import { Dimmer as SemanticDimmer } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/dimmer.css";

function Dimmable({ as, children, ...props }) {
  return (
    <SemanticDimmer.Dimmable as={as} {...props}>
      {children}
    </SemanticDimmer.Dimmable>
  );
}

export default Dimmable;
