import React, { Fragment, useCallback } from "react";
import {
  SortedTable,
  SortableTableHeaderCell,
  TableCell,
  TableHeader,
  TableRow,
  TableBody,
} from "components_new/elements/Table";
import Value from "components_new/atoms/Value";
import Text from "components_new/atoms/Text";
import BasicLabel from "components_new/atoms/BasicLabel";
import Button from "components_new/atoms/Button";
import PropTypes from "prop-types";
import CompanyInfo from "components_new/elements/CompanyInfo";
import Link from "components_new/atoms/Link";
import EllipsisDropdown from "components_new/elements/EllipsisDropdown";
import CompanyLinkValue from "components_new/views/Company/shared/CompanyLinkValue";
import pluralize from "pluralize";

import { dealProptype } from "PropTypes";
import { isEmpty } from "lodash";
import { WEBSITE_PREFILL } from "components_new/lib/miscellaneous";

const COLUMN_HEADERS = [
  {
    value: "company_name",
    title: "Company",
    type: "string",
    skeletonType: "companyName",
  },
  {
    value: "name",
    title: "Deal name",
    type: "string",
    skeletonType: "name",
  },
  {
    value: "owner_name",
    title: "Owner",
    type: "string",
    skeletonType: "name",
  },
  {
    className: "DateColumn u-textAlignRight",
    value: "decision_date",
    title: "Date Committed",
    type: "date",
    skeletonType: "date_single_line",
  },
  {
    className: "NumericColumn u-textAlignRight",
    value: "commitment_amount",
    title: "Commitment Amount",
    type: "numeric",
    skeletonType: "amount",
  },
  {
    value: "",
    title: "",
    type: "",
    skeletonType: "date_single_line",
  },
];

const renderHeaders = (handleSort, isSorted) => {
  return (
    <TableHeader>
      <TableRow>
        {COLUMN_HEADERS.map((columnHeader, index) => {
          return (
            <SortableTableHeaderCell
              key={index}
              className={columnHeader.className}
              isSorted={isSorted}
              handleSort={handleSort}
              {...columnHeader}
            >
              {columnHeader.title}
            </SortableTableHeaderCell>
          );
        })}
      </TableRow>
    </TableHeader>
  );
};

function PendingTasks({ counter }) {
  if (counter === 0) return null;

  const text = pluralize("pending task", counter);
  return (
    <Text type="label-bold" color="warning">
      {counter} {text}
    </Text>
  );
}

function CommittedDealsTable({
  isLoading,
  committedDeals,
  onConvertToInvesment,
  organizationUsername,
  openReactivateDealModal,
  openDeleteDealModal,
  openViewDealSidebar,
  openEditDealSidebar,
  openEditCommitmentModal,
  selectedDealId,
}) {
  const buildCompanyUrl = (companyId) =>
    `/${organizationUsername}/companies/${companyId}`;

  const commitmentActions = useCallback(
    (deal) => [
      {
        key: "0",
        text: "View Deal",
        onClick: () => openViewDealSidebar(deal),
      },
      {
        key: "1",
        text: "Edit Deal",
        onClick: () => openEditDealSidebar(deal.id),
      },
      {
        key: "2",
        text: "Edit Commitment",
        onClick: () => openEditCommitmentModal(deal.id),
      },
      {
        key: "3",
        text: "Undo Commitment",
        onClick: () => openReactivateDealModal(deal.id),
      },
      {
        key: "4",
        text: "Delete Deal",
        onClick: () => openDeleteDealModal(deal.id),
      },
    ],
    [
      openViewDealSidebar,
      openEditDealSidebar,
      openEditCommitmentModal,
      openReactivateDealModal,
      openDeleteDealModal,
    ],
  );

  return (
    <div className="CommittedDealsTable">
      <SortedTable
        isLoading={isLoading}
        renderHeaders={renderHeaders}
        skeletonStructure={{ rows: 5, headers: COLUMN_HEADERS }}
        singleLine
        tableData={committedDeals}
        shadow={false}
      >
        {({ data }) => (
          <TableBody isEmptyData={isEmpty(data)}>
            {data &&
              data.map((deal) => (
                <TableRow key={deal.id} active={selectedDealId === deal.id}>
                  <TableCell>
                    <CompanyInfo
                      title={(company) => (
                        <Link
                          extraClassnames="CompanyName"
                          title={company.name}
                          to={buildCompanyUrl(company.id)}
                        />
                      )}
                      content={(company) => (
                        <Fragment>
                          {company.website && (
                            <CompanyLinkValue
                              url={company.website}
                              preFilled={WEBSITE_PREFILL}
                            />
                          )}
                          {company.headquarters && (
                            <Value extraClassnames="CompanyLocation">
                              {company.headquarters}
                            </Value>
                          )}
                        </Fragment>
                      )}
                      company={deal.company}
                    />
                  </TableCell>
                  <TableCell className="TextColumn">
                    <div>
                      <Text
                        type="body-1-bold"
                        link
                        onClick={() =>
                          openViewDealSidebar(deal, deal.company.name)
                        }
                        as="span"
                      >
                        {deal.name}
                      </Text>
                      {deal.is_follow_on && (
                        <BasicLabel type="primary">Follow-on</BasicLabel>
                      )}
                    </div>
                    {!isEmpty(deal.raise_oneliner) && (
                      <div>
                        <Value type="text" light>
                          {deal.raise_oneliner}
                        </Value>
                      </div>
                    )}
                    <PendingTasks counter={deal.pending_tasks_count} />
                  </TableCell>
                  <TableCell className="TextColumn" data-testid="ownerName">
                    <Value type="text" light>
                      {deal.owner_name}
                    </Value>
                  </TableCell>
                  <TableCell className="DateColumn">
                    <Value type="date" dateFormat="default" light>
                      {deal.decision_date}
                    </Value>
                  </TableCell>
                  <TableCell className="NumericColumn">
                    <Value type="currency" precision="2">
                      {deal.commitment_amount}
                    </Value>
                  </TableCell>

                  <TableCell className="ActionColumn">
                    <div className="ActionColumn-wrapper">
                      <Button
                        size="small"
                        secondary
                        onClick={() => onConvertToInvesment(deal)}
                      >
                        Convert to Investment
                      </Button>
                      <EllipsisDropdown
                        iconOrientation="vertical"
                        iconSize="small"
                        options={commitmentActions(deal)}
                        align="left"
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        )}
      </SortedTable>
    </div>
  );
}

CommittedDealsTable.propTypes = {
  isLoading: PropTypes.bool,
  committedDeals: PropTypes.arrayOf(dealProptype),
  onConvertToInvesment: PropTypes.func,
  organizationUsername: PropTypes.string,
  openReactivateDealModal: PropTypes.func,
  openDeleteDealModal: PropTypes.func,
  openViewDealSidebar: PropTypes.func,
  openEditDealSidebar: PropTypes.func,
  openEditCommitmentModal: PropTypes.func,
  selectedDealId: PropTypes.string,
};

export default CommittedDealsTable;
