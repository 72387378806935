import React from "react";

import { Header } from "@ableco/semantic-ui-react";
import Segment from "components_new/atoms/Segment";
import "semantic-ui-css/components/header.css";

const Sidebar = () => {
  return (
    <Segment basic transparent textAlign="center">
      <Header size="small" content="Report Creation Coming Soon" />
      <span>
        In the meantime, if there's a report you want that you don't see, please
        email us and we will create it for you.
      </span>
      <br />
      <br />
      <a href="mailto:support@mighty.co">support@mighty.co</a>
    </Segment>
  );
};

export default Sidebar;
