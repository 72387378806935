export const RECEIVE_EMAIL_USER = "createAccountView/RECEIVE_EMAIL_USER";
export const UPDATE_CREATE_USER_FORM =
  "createAccountView/UPDATE_CREATE_USER_FORM";
export const CREATE_USER_START = "createAccountView/CREATE_USER_START";
export const CREATE_USER_SUCCESS = "createAccountView/CREATE_USER_SUCCESS";
export const CREATE_USER_ERROR = "createAccountView/CREATE_USER_ERROR";
export const FETCH_INVITE_EMAIL_START =
  "createAccountView/FETCH_INVITE_EMAIL_START";
export const FETCH_INVITE_EMAIL_SUCCESS =
  "createAccountView/FETCH_INVITE_EMAIL_SUCCESS";
export const FETCH_INVITE_EMAIL_ERROR =
  "createAccountView/FETCH_INVITE_EMAIL_ERROR";

export function receiveEmailUser(status, email, portfolioName, inviterName) {
  return {
    type: RECEIVE_EMAIL_USER,
    status,
    email,
    portfolioName,
    inviterName,
  };
}

export function updateCreateUserform(key, value) {
  return { type: UPDATE_CREATE_USER_FORM, key, value };
}
export function fetchInviteEmailStart() {
  return { type: FETCH_INVITE_EMAIL_START };
}

export function fetchInviteEmailSuccess() {
  return { type: FETCH_INVITE_EMAIL_SUCCESS };
}

export function fetchInviteEmailError(error) {
  return { type: FETCH_INVITE_EMAIL_ERROR, error };
}

export function createUserStart() {
  return { type: CREATE_USER_START };
}

export function createUserSuccess() {
  return { type: CREATE_USER_SUCCESS };
}

export function createUserError(errors) {
  return { type: CREATE_USER_ERROR, errors };
}
