import React from "react";
import { TableRow, TableCell } from "components_new/elements/Table";
import Value from "components_new/atoms/Value";
import CompanyInfo from "components_new/elements/CompanyInfo";
import Link from "components_new/atoms/Link";
import CompanyLinkValue from "components_new/views/Company/shared/CompanyLinkValue";
import BadgeIcon from "components_new/atoms/BadgeIcon";
import ShowFunds from "components_new/views/CompanyDirectory/shared/CompanyFunds";
import CompanyFlags from "components_new/views/CompanyDirectory/shared/CompanyFlags";
import { isEmpty } from "lodash";

export const WEBSITE_PREFILL = "http://";

function CompaniesRow({
  company,
  organizationUsername,
  handleOpenEditCompanyForm,
}) {
  const {
    location,
    tags,
    status,
    total_invested,
    activity_status,
    all_sectors,
  } = company;
  const statusInfo = status === "Active" ? "" : `[${status.toUpperCase()}]`;
  const companyDetailUrl = `/${organizationUsername}/companies/${company.id}`;
  const companyName = (
    <span>
      {company.name}
      {company.verified && <BadgeIcon size="small" color="primary" />}
    </span>
  );

  const renderSectors = !isEmpty(all_sectors)
    ? all_sectors.map((item) => item?.name).join(", ")
    : "";
  return (
    <TableRow>
      <TableCell className="CompanyNameColumn">
        <CompanyInfo
          handleEdit={handleOpenEditCompanyForm}
          title={() => (
            <div className="CompanyNameTitle">
              <Link
                to={companyDetailUrl}
                extraClassnames="CompanyNameTitle-link"
              >
                {companyName}
                <span className="CompanyNameTitle-status">{statusInfo}</span>
              </Link>
              {company.website && (
                <CompanyLinkValue
                  url={company.website}
                  label={company.website}
                  preFilled={WEBSITE_PREFILL}
                />
              )}
            </div>
          )}
          content={(company) => <CompanyFlags company={company} />}
          company={company}
          tier={company.tier}
        />
      </TableCell>
      <TableCell>{location}</TableCell>
      <TableCell className="CompanyTagsColumn">
        {company.portfolio_names ? (
          <ShowFunds companyFunds={company.portfolio_names} />
        ) : (
          "N/A"
        )}
      </TableCell>
      <TableCell className="CompanyFirstInvestmentDateColumn">
        <Value type="date">{company.first_investment_date}</Value>
      </TableCell>
      <TableCell className="CompanySectorsColumn">{renderSectors}</TableCell>
      <TableCell className="CompanyTagsColumn">{tags.join(", ")}</TableCell>
      <TableCell>{activity_status}</TableCell>
      <TableCell className="right aligned">
        <Value type="currency" precision="2">
          {total_invested}
        </Value>
      </TableCell>
    </TableRow>
  );
}

export default CompaniesRow;
