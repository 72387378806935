import PropTypes from "prop-types";
import React, { Component } from "react";
import DropdownMenu from "components/shared/DropdownMenu";
import TooltipHolder from "components/shared/TooltipHolder";
import ActionButton from "components/shared/ActionButton";
import CaretIcon from "-!svg-react-loader?!assets/icons/utility/caret.svg?name=CaretIcon";
import classnames from "classnames";
import reactDomContains from "lib/reactDomContains";

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.hideMenuListener = this.createHideMenu();
  }

  componentDidMount() {
    document.addEventListener("click", this.hideMenuListener, true);
  }

  UNSAFE_componentWillUpdate() {
    if (typeof this.props.onComponentWillUpdate === "function") {
      this.props.onComponentWillUpdate();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.hideMenuListener, true);
  }

  createHideMenu() {
    return (event) => this.hideMenu(event);
  }

  // TODO: Area should reference to the items container not to whole dropdown component,
  // this will allow us to close the menu when the user clicks outside the menu container.
  hideMenu(event) {
    const isMenuOpen = this.state.open;

    if (isMenuOpen) {
      const { target: clicker } = event;
      const outsideMenuArea = !reactDomContains(this.area, clicker);
      const outsideActionButton = !reactDomContains(this.actionButton, clicker);

      if (outsideMenuArea && outsideActionButton) {
        this.setState({ open: false });
      }
    }
  }

  toggleDropdown() {
    this.setState({ open: !this.state.open });
  }

  renderDropdownButton() {
    const {
      children,
      renderButton,
      extraButtonClassnames,
      behavior,
    } = this.props;
    const buttonProps = {
      customClass: extraButtonClassnames,
      behavior: behavior,
    };
    const buttonChildren = [
      children,
      <CaretIcon key="caretIcon" className="Button-icon" />,
    ];

    return renderButton(buttonProps, buttonChildren);
  }

  renderDropdown(dropdownContent) {
    const { showTooltip, tooltipText, tooltipPosition } = this.props;
    return showTooltip && !this.state.open ? (
      <TooltipHolder tooltip={tooltipText} position={tooltipPosition}>
        {dropdownContent}
      </TooltipHolder>
    ) : (
      dropdownContent
    );
  }

  render() {
    const { items, extraClassnames } = this.props;
    const dropdownClassnames = classnames("Dropdown", extraClassnames, {
      "Dropdown-isOpen": this.state.open,
      "Dropdown-isClosed": !this.state.open,
    });
    const itemsWithToggleDropdown = items.map((item) => ({
      ...item,
      toggleDropdown: () => this.toggleDropdown(),
    }));

    const dropdownContent = (
      <div className={dropdownClassnames}>
        <div
          className="u-displayInlineBlock DropdownButton"
          onClick={() => this.toggleDropdown()}
          ref={(actionButton) => (this.actionButton = actionButton)}
        >
          {this.renderDropdownButton()}
        </div>
        <DropdownMenu
          open={this.state.open}
          items={itemsWithToggleDropdown}
          ref={(area) => {
            this.area = area;
          }}
        />
      </div>
    );

    return this.renderDropdown(dropdownContent);
  }
}

Dropdown.defaultProps = {
  children: null,
  extraClassnames: "",
  onComponentWillUpdate: null,
  extraButtonClassnames: "",
  renderButton: (props, children) => (
    <ActionButton {...props}>{children}</ActionButton>
  ),
  showTooltip: false,
  tooltipText: null,
  tooltipPosition: "bottom",
  behavior: "action",
};

Dropdown.propTypes = {
  children: PropTypes.node,
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        text: PropTypes.string,
        onClickAction: PropTypes.func,
        isDisabled: PropTypes.bool,
      }),
      PropTypes.shape({
        text: PropTypes.string,
        linkTo: PropTypes.string,
        isDisabled: PropTypes.bool,
      }),
    ]),
  ).isRequired,
  extraButtonClassnames: PropTypes.string,
  extraClassnames: PropTypes.string,
  onComponentWillUpdate: PropTypes.func,
  renderButton: PropTypes.func,
  showTooltip: PropTypes.bool,
  tooltipPosition: PropTypes.string,
  tooltipText: PropTypes.string,
  behavior: PropTypes.string,
};

export default Dropdown;
