import React from "react";
import Header from "components_new/atoms/Header";
import EmptyCard from "components_new/elements/EmptyCard";
import CoInvestmentRow from "./CoInvestmentRow";
import Button from "components_new/atoms/Button";
import Icon from "components_new/atoms/Icon";
import CreateSnapshotButton from "components_new/elements/CreateSnapshotButton";
import Table, {
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "components_new/elements/Table";
import "./CoInvestmentsTable.scss";

function CoInvestmentsTable({
  companyId,
  currentOrganizationUsername,
  investorCoInvestments,
  openAddCoInvestmentForm,
  openEditCoInvestmentForm,
  openDeleteCoInvestment,
}) {
  return (
    <div className="CoInvestmentsTable">
      <div className="CoInvestmentsTable-title">
        <Header as="h5">Co-Investments</Header>

        <div className="CoInvestmentsTable-actions">
          <CreateSnapshotButton
            disabled={false}
            kind="secondary"
            buttonText="Create Snapshot"
            snapshotType="co_investment"
            recipientCompanyId={companyId}
            size="small"
          />

          <Button secondary size="small" onClick={openAddCoInvestmentForm}>
            <Icon icon="ri-add-line" /> Add Co-Investment
          </Button>
        </div>
      </div>

      {investorCoInvestments.length === 0 ? (
        <EmptyCard title="Co-Investments" />
      ) : (
        <Table singleLine>
          <TableHeader className="CoInvestmentsHeader">
            <TableRow>
              <TableHeaderCell>Company</TableHeaderCell>
              <TableHeaderCell>Round</TableHeaderCell>
              <TableHeaderCell>Activity status</TableHeaderCell>
              <TableHeaderCell></TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {investorCoInvestments.map((coInvestment, index) => (
              <CoInvestmentRow
                key={index}
                coInvestment={coInvestment}
                openEditCoInvestmentForm={openEditCoInvestmentForm}
                openDeleteCoInvestment={openDeleteCoInvestment}
                organizationUsername={currentOrganizationUsername}
              />
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
}

export default CoInvestmentsTable;
