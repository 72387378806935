import React, { Component } from "react";
import PrimaryButton from "components/shared/PrimaryButton";
import PropTypes from "prop-types";
import { ModalHeader, ModalBody, ModalFooter } from "components/shared/Modal";
import VerifyCodeForm from "components/auth/VerifyCodeForm";

class UpdatePhoneNumber extends Component {
  state = {
    isPhoneNumberUpdated: false,
    hasPhoneBeenAlreadyVerified: false,
  };

  shouldRenderVerifyStep() {
    const { isPhoneNumberUpdated, hasPhoneBeenAlreadyVerified } = this.state;
    return !isPhoneNumberUpdated && !hasPhoneBeenAlreadyVerified;
  }

  shouldRenderPhoneHasBeenAlreadyVerifiedMessage() {
    const { hasPhoneBeenAlreadyVerified } = this.state;
    return hasPhoneBeenAlreadyVerified;
  }

  shouldRenderSuccessMessage() {
    const { isPhoneNumberUpdated } = this.state;
    return isPhoneNumberUpdated;
  }

  renderSuccessMessage() {
    return this.renderMessage("Your new number has been verified, thank you!");
  }

  renderPhoneHasBeenAlreadyVerifiedMessage() {
    return this.renderMessage(
      "This number has already been verified, thank you!",
    );
  }

  renderMessage(message) {
    const { newPhoneNumber } = this.props;
    return (
      <div className="UpdatePhoneNumberMessage">
        <ModalBody>
          <p className="u-sectionHeading">{newPhoneNumber}</p>
          <p>{message}</p>
        </ModalBody>
        <ModalFooter
          render={(closeModal) => (
            <div className="ModalFooter-actionButtons">
              <PrimaryButton type="submit" onClick={() => closeModal()}>
                Done
              </PrimaryButton>
            </div>
          )}
        />
      </div>
    );
  }

  renderVerifyCodeStep() {
    return (
      <ModalBody>
        <VerifyCodeForm
          afterSendingCode={() => this.setState({ isPhoneNumberUpdated: true })}
          doNotRedirect
        />
      </ModalBody>
    );
  }

  renderStep() {
    if (this.shouldRenderVerifyStep()) {
      return this.renderVerifyCodeStep();
    } else if (this.shouldRenderPhoneHasBeenAlreadyVerifiedMessage()) {
      return this.renderPhoneHasBeenAlreadyVerifiedMessage();
    } else if (this.shouldRenderSuccessMessage()) {
      return this.renderSuccessMessage();
    }
    return null;
  }

  render() {
    return (
      <div className="UpdatePhoneNumber">
        <ModalHeader title="Update Phone Number" />
        {this.renderStep()}
      </div>
    );
  }
}

UpdatePhoneNumber.propTypes = {
  sendCode: PropTypes.func.isRequired,
  verifyCode: PropTypes.func.isRequired,
  newPhoneNumber: PropTypes.string.isRequired,
};

export default UpdatePhoneNumber;
