import {
  REGISTER_FORM,
  SET_IS_EDITING_FORM,
  SET_SHOW_WARNING_MESSAGE,
  RESET_FORM,
} from "./actionTypes";

export const formStatusInitialState = [];

const formInitialState = {
  isEditing: false,
  showMessage: false,
};

export default function formsReducer(forms = formStatusInitialState, action) {
  switch (action.type) {
    case REGISTER_FORM: {
      return !forms.find((form) => form.name === action.name)
        ? [
            ...forms,
            {
              name: action.name,
              ...formInitialState,
            },
          ]
        : [...forms];
    }

    case SET_IS_EDITING_FORM: {
      return forms.map((form) => {
        if (form.name === action.name) {
          form.isEditing = action.status;
        }
        return form;
      });
    }

    case SET_SHOW_WARNING_MESSAGE: {
      return forms.map((form) => {
        if (form.name === action.name) {
          form.showMessage = action.status;
        }
        return form;
      });
    }

    case RESET_FORM: {
      if (action.target === "forms") {
        return formStatusInitialState;
      } else {
        return forms.map((form) =>
          form.name === action.target ? { ...form, ...formInitialState } : form,
        );
      }
    }

    default:
      return forms;
  }
}
