import { connect } from "react-redux";
import UserOrganizationMemberActions from "./UserOrganizationMemberActions";
import { openModal } from "store/modalV2";
import { revokeUserOrganizationMembership } from "store/organizations/organizationMembers/actions";

function mapDispatchToProps(dispatch) {
  return {
    openLeaveOrganizationModal: (organizationMembership) =>
      dispatch(
        openModal("leaveOrganization", {
          organizationMembership,
          type: "fromUser",
        }),
      ),
    openRejectInvitationModal: (organizationName) =>
      dispatch(openModal("rejectInvitation", organizationName)),
    removeExpiredInvitation: (userOrganizationMembershipId) =>
      dispatch(revokeUserOrganizationMembership(userOrganizationMembershipId)),
  };
}

export default connect(null, mapDispatchToProps)(UserOrganizationMemberActions);
