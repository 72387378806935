import React, { useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Text from "components_new/atoms/Text";
import ProfileAvatar from "./ProfileAvatar";
import "./ProfileAvatarGroup.scss";

const ProfileAvatarGroup = ({
  name,
  initials,
  subtitle,
  imageUrl,
  size,
  theme,
  extraClassNames,
}) => {
  const ProfileAvatarGroupClassNames = classNames("ProfileAvatarGroup", {
    [extraClassNames]: !!extraClassNames,
  });
  // if not initials, extract initials from name
  const concernedInitials = useMemo(() => {
    if (initials) {
      return initials;
    } else if (name) {
      const nameArr = name.split(" ");
      return `${nameArr[0][0]}${nameArr[nameArr.length - 1][0]}`;
    } else {
      return null;
    }
  }, [name, initials]);

  return (
    <div className={ProfileAvatarGroupClassNames}>
      <ProfileAvatar
        initials={concernedInitials}
        size={size}
        imageUrl={imageUrl}
        alt={concernedInitials}
        theme={theme}
      />
      <div>
        <Text type="body-2-bold">{name}</Text>
        <Text type="body-1-regular">{subtitle}</Text>
      </div>
    </div>
  );
};

ProfileAvatarGroup.propTypes = {
  name: PropTypes.string,
  initials: PropTypes.string,
  subtitle: PropTypes.string,
  imageUrl: PropTypes.string,
  size: PropTypes.oneOf(["medium", "small", "large", "mini"]).isRequired,
  theme: PropTypes.oneOf(["default", "inverse", "overflow"]).isRequired,
  extraClassNames: PropTypes.string,
};

export default ProfileAvatarGroup;
