import React from "react";
import PropTypes from "prop-types";
import TextInput from "components/shared/form/TextInput";

function AutocompleteCurrentSelection({
  autoFocus,
  displayValue,
  inputPlaceholder,
  inputRef,
  onInputChange,
  errorMessage,
  size,
}) {
  return (
    <div className="AutocompleteCurrentSelection">
      <TextInput
        autoFocus={autoFocus}
        autoComplete="off"
        className="InputText"
        onChange={onInputChange}
        placeholder={inputPlaceholder}
        type="text"
        ref={inputRef}
        value={displayValue}
        errorMessage={errorMessage}
        size={size}
      />
    </div>
  );
}

AutocompleteCurrentSelection.propTypes = {
  autoFocus: PropTypes.bool,
  displayValue: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  inputPlaceholder: PropTypes.string.isRequired,
  inputRef: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  size: PropTypes.string,
};

AutocompleteCurrentSelection.defaultProps = {
  autoFocus: false,
  selectedItem: null,
};

export default AutocompleteCurrentSelection;
