import React, { Fragment, useMemo } from "react";
import FormGroup from "components_new/elements/FormGroup";
import FormEmptyState from "components_new/elements/FormGroup/FormEmptyState";
import Link from "components_new/atoms/Link";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import Icon from "components_new/atoms/Icon";
import Tooltip from "components_new/atoms/Tooltip";
import "./CustomFields.scss";
import {
  AdvancedCustomFieldForm,
  updateCustomFields,
  initializeCustomFields,
} from "components_new/SidebarComponents/forms/AdvancedCustomFieldForm";
import { customFieldProptype } from "PropTypes";

const EDIT_CUSTOM_FIELDS_MSG =
  "Custom fields are editable by your organization's administrator" +
  " in the 'Custom Fields' tab of the organization settings page or by " +
  "clicking here on the tooltip.";
const emptyCustomFieldsMessage = (resource_type) =>
  `Your organization currently has no custom fields for ${resource_type}. Your organization` +
  "administrators can create custom fields under 'Organization Settings' or by clicking this tooltip";

function CustomFields({
  organizationCustomFields,
  formData,
  isAdmin,
  isOrgOwner,
  handleChange,
  organizationName,
  organizationUsername,
  resource_type,
}) {
  const { custom_fields, type } = formData;
  const updateCustomFieldsForm = (key, value, customField) =>
    updateCustomFields(key, value, customField, (customFieldStructure) =>
      handleChange("custom_fields", {
        ...custom_fields,
        [customFieldStructure.id]: {
          ...customFieldStructure,
        },
      }),
    );
  const emptyStateContent =
    isAdmin || isOrgOwner ? (
      <p>
        There aren't any custom fields yet. Go to{" "}
        <Link to={`/${organizationUsername}/settings/custom-fields`} isExternal>
          Organization Settings
        </Link>{" "}
        to create and manage custom fields.
      </p>
    ) : (
      <p>
        There aren't any custom fields yet. Contact the organization admin to
        add custom fields.
      </p>
    );

  const selectedCustomFields = organizationCustomFields.filter((field) =>
    field.resource_types.includes(type),
  );

  const initializedCustomFields = initializeCustomFields(
    selectedCustomFields,
    formData.custom_fields,
  );
  const toolTipMessage = useMemo(
    () =>
      isEmpty(selectedCustomFields)
        ? emptyCustomFieldsMessage(resource_type)
        : EDIT_CUSTOM_FIELDS_MSG,
    [resource_type], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <FormGroup
      extraClassnames="CustomFieldsGroup"
      isExpanded={!isEmpty(custom_fields)}
      title={
        <Fragment>
          Custom Details
          <Link
            to={`/${organizationUsername}/settings/custom-fields`}
            isExternal
          >
            <Tooltip
              content={toolTipMessage}
              position="top center"
              basic={false}
              trigger={<Icon icon="ri-question-line" />}
            ></Tooltip>
          </Link>
        </Fragment>
      }
      subtitle={"Custom fields specifically defined by " + organizationName}
    >
      {isEmpty(initializedCustomFields) ? (
        <FormEmptyState groupContent={emptyStateContent} />
      ) : (
        <AdvancedCustomFieldForm
          organizationName={organizationName}
          updateForm={updateCustomFieldsForm}
          formData={initializedCustomFields}
          fields={selectedCustomFields}
          resource_type={resource_type}
          isOptional
          organizationUsername={organizationUsername}
          fieldSize="tiny"
          showHeading={false}
        />
      )}
    </FormGroup>
  );
}

CustomFields.propTypes = {
  organizationCustomFields: PropTypes.arrayOf(customFieldProptype),
  formData: PropTypes.shape({
    custom_fields: PropTypes.objectOf(customFieldProptype),
    type: PropTypes.string,
  }),
  handleChange: PropTypes.func,
  organizationName: PropTypes.string,
  organizationUsername: PropTypes.string,
  resource_type: PropTypes.string,
  isAdmin: PropTypes.bool,
  isOrgOwner: PropTypes.bool,
};

CustomFields.defaultProps = {
  isAdmin: false,
};

export default CustomFields;
