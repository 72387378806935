import {
  CLEAN_COMPANY_VIEW,
  UPDATE_COMPANY_VIEW_INVESTMENT,
  EXPAND_ALL_ROW_DETAILS,
  COLLAPSE_ALL_ROW_DETAILS,
  RESET_EXPAND_AND_COLLAPSE,
  EXPAND_MISCELLANEOUS,
  COLLAPSE_MISCELLANEOUS,
} from "./actionTypes";

export function cleanCompanyView() {
  return { type: CLEAN_COMPANY_VIEW };
}

export function updateCompanyViewInvestment(investment) {
  return { type: UPDATE_COMPANY_VIEW_INVESTMENT, investment };
}

export function expandAllRowDetails() {
  return { type: EXPAND_ALL_ROW_DETAILS };
}

export function collapseAllRowDetails() {
  return { type: COLLAPSE_ALL_ROW_DETAILS };
}

export function resetExpandAndCollapse() {
  return { type: RESET_EXPAND_AND_COLLAPSE };
}

export function collapseMiscellaneous() {
  return { type: COLLAPSE_MISCELLANEOUS };
}

export function expandMiscellaneous() {
  return { type: EXPAND_MISCELLANEOUS };
}
