import React, { useContext, useEffect, useMemo } from "react";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import { SidebarComponentsContext } from "components_new/SidebarComponents/SidebarComponentsWrapper";
import { buildPrefilledInvestmentData } from "lib/globalActionsHelper";
import { isEmpty } from "lodash";
import CapitalCallForm from "./CapitalCallForm";
import useCapitalCalls from "store/capitalCalls/hooks";
import useOrganizations from "store/organizations/hooks";
import getMessage from "components_new/lib/Messages";
import { useInvestment } from "components_new/SidebarComponents/views/InvestmentView/hooks/useInvestment";
import Loader from "components/shared/Loader";

function CapitalCallFormWrapper({ ownName }) {
  const { getComponentState, registerForm, closeSidebar } = useContext(
    SidebarComponentsContext,
  );
  const { currentOrganizationUsername } = useOrganizations();
  const { addToast } = useSetToast();

  const { initialHoldingId, taskId, taskAttachments } = getComponentState(
    ownName,
  );

  useEffect(() => {
    if (!isEmpty(ownName)) {
      registerForm(ownName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showConfirmationMessage = (url) =>
    addToast(getMessage("addedCapitalCall", { url }));

  const { createCapitalCall } = useCapitalCalls();
  const { data, isLoading } = useInvestment(initialHoldingId);

  const prefilledSelection = useMemo(() => {
    return buildPrefilledInvestmentData(data?.details, data?.company) || {};
  }, [data]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <CapitalCallForm
      ownName={ownName}
      taskId={taskId}
      taskAttachments={taskAttachments}
      createCapitalCall={createCapitalCall}
      closeSidebar={closeSidebar}
      prefilledSelection={prefilledSelection}
      showConfirmationMessage={showConfirmationMessage}
      currentOrganizationUsername={currentOrganizationUsername}
    />
  );
}
export default CapitalCallFormWrapper;
