import React, { useCallback, useMemo } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useCompany } from "components_new/views/Company/hooks/queries/useCompany";
import { useQueryClient } from "react-query";
import useOrganizations from "store/organizations/hooks";
import useSidebar from "store/sidebar/hooks";
import Loader from "components/shared/Loader";
import CompanyView from "./CompanyView";

function CompanyViewWrapper() {
  const params = useParams();
  const companyId = params.id;
  const { data, isLoading } = useCompany(companyId);
  const { openSidebar } = useSidebar();
  const {
    currentOrganization: { username: currentOrganizationUsername },
  } = useOrganizations();
  const location = useLocation();

  const queryClient = useQueryClient();

  const invalidateQueriesCache = useCallback(() => {
    queryClient.invalidateQueries([
      "Company",
      currentOrganizationUsername,
      companyId,
    ]);
  }, [queryClient, currentOrganizationUsername, companyId]);

  const handleOpenEditCompanyForm = useCallback(
    (companyId, companyName) =>
      openSidebar("globalCompanyForm", `Edit ${companyName}`, {
        entityId: companyId,
        callback: invalidateQueriesCache,
      }),
    [invalidateQueriesCache, openSidebar],
  );

  const counters = useMemo(() => {
    return {
      investmentsCount:
        data?.currentInvestments.length + data?.exitedInvestments.length,
      documentsCount: data?.companyDocuments.length,
      notesCount: data?.companyPosts.length,
      dealsCount: data?.companyDeals.length,
      contactsCount: data?.referrers.length + data?.employees.length,
      investorCoInvestments: data?.investorCoInvestments?.length,
    };
  }, [data]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <CompanyView
      {...data}
      {...counters}
      investorFirm={data?.companyDetails.is_investor}
      location={location}
      onEditClick={handleOpenEditCompanyForm}
      params={params}
    />
  );
}

export default CompanyViewWrapper;
