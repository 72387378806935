export const UPDATE_FORM = "newDocumentForm/UPDATE_FORM";
export const DRAG_DOCUMENT_START = "newDocumentForm/DRAG_DOCUMENT_START";
export const SAVE_DOCUMENTS_START = "newDocumentForm/SAVE_DOCUMENTS_START";
export const SAVE_DOCUMENTS_SUCCESS = "newDocumentForm/SAVE_DOCUMENTS_SUCCESS";
export const CREATE_DOCUMENT_ERROR = "newDocumentForm/CREATE_DOCUMENT_ERROR";
export const USER_EDITED_TITLE = "newDocumentForm/USER_EDITED_TITLE";
export const AUTOMATIC_EDITED_TITLE = "newDocumentForm/AUTOMATIC_EDITED_TITLE";
export const CLEAN_DOCUMENT_FORM = "newDocumentForm/CLEAN_FORM";
export const UPDATE_FILEPICKER_DATA = "newDocumentForm/UPDATE_FILEPICKER_DATA";
export const ADD_DOCUMENTS_TO_UPLOAD_QUEUE =
  "newDocumentForm/ADD_DOCUMENTS_TO_UPLOAD_QUEUE";
export const UPDATE_DOCUMENT_IN_QUEUE =
  "newDocumentForm/UPDATE_DOCUMENT_IN_QUEUE";
export const REMOVE_DOCUMENT_FROM_QUEUE =
  "newDocumentForm/REMOVE_DOCUMENT_FROM_QUEUE";
export const REMOVE_UPLOADED_DOCUMENT =
  "newDocumentForm/REMOVE_UPLOADED_DOCUMENT";
export const SET_DOCUMENTS_LENGTH = "newDocumentForm/SET_DOCUMENTS_LENGTH";
export const STORE_UPLOADED_DOCUMENT =
  "newDocumentForm/STORE_UPLOADED_DOCUMENT";

export function updateNewDocumentForm(key, value) {
  return { type: UPDATE_FORM, key, value };
}

export function setDocumentslength(documentsLength) {
  return { type: SET_DOCUMENTS_LENGTH, documentsLength };
}

export function updateFilepickerData(filepickerData) {
  return { type: UPDATE_FILEPICKER_DATA, filepickerData };
}
export function dragDocumentStart() {
  return { type: DRAG_DOCUMENT_START };
}

export function saveDocumentsStart() {
  return { type: SAVE_DOCUMENTS_START };
}

export function saveDocumentsSuccess() {
  return { type: SAVE_DOCUMENTS_SUCCESS };
}

export function createDocumentError(errors) {
  return { type: CREATE_DOCUMENT_ERROR, errors };
}

export function notifyUserEditedTitle() {
  return { type: USER_EDITED_TITLE };
}

export function notifyAutomaticEditedTitle() {
  return { type: AUTOMATIC_EDITED_TITLE };
}

export function addDocumentsToUploadQueue(documents) {
  return { type: ADD_DOCUMENTS_TO_UPLOAD_QUEUE, documents };
}

export function updateDocumentInQueue(doc, options) {
  return { type: UPDATE_DOCUMENT_IN_QUEUE, doc, options };
}

export function removeDocumentFromQueue(doc) {
  return { type: REMOVE_DOCUMENT_FROM_QUEUE, doc };
}

export function removeUploadedDocument(doc) {
  return { type: REMOVE_UPLOADED_DOCUMENT, doc };
}

export function cleanDocumentForm() {
  return { type: CLEAN_DOCUMENT_FORM };
}

export function storeUploadedDocument(docId, uploadedDocument) {
  return { type: STORE_UPLOADED_DOCUMENT, docId, uploadedDocument };
}
