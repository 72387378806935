import { useDispatch, useSelector } from "react-redux";
import { removeFromView } from "store/views";
import { isEmpty } from "lodash";

// TODO: Remove this function and this file when we migrated Company View
// into React Query.

export function useRemoveContactFromInvestment() {
  const investmentView = useSelector((state) => state.views.investment);
  const dispatch = useDispatch();
  return (contactId) => {
    if (!isEmpty(investmentView)) {
      if (investmentView.contact_ids.includes(contactId)) {
        dispatch(removeFromView("investment", "contact_ids", contactId));
      }
    }
  };
}
