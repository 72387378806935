export function globalAddDealIsAvailable(organization) {
  return organization.has_pipelines;
}

export function globalUploadFileIsAvailable(organization) {
  return (
    organization.has_companies ||
    organization.has_deals ||
    organization.has_investments
  );
}

export function globalPostIsAvailable(organization) {
  return (
    organization.has_investments ||
    organization.has_deals ||
    organization.has_companies
  );
}

export function globalUpdatePPSIsAvailable(organization) {
  return organization.has_investments || organization.has_companies;
}

export function globalLogCapitalCallIsAvailable(organization) {
  return organization.has_investments || organization.has_companies;
}
