import React, { useState } from "react";
import classnames from "classnames";

function Tabs({ extraClassnames = "", children }) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const tabsClassnames = classnames("TabsWithoutNavigation", {
    [extraClassnames]: extraClassnames,
  });

  const showLabels = (child, index) => {
    const navigationTabClassnames = classnames("NavigationTabs-singleTab", {
      "is-active": selectedIndex === index,
      [child.props.extraClassnames]: !!child.props.extraClassnames,
    });
    const showLabelsClassnames = classnames("u-tabDefault", {
      "u-tabActive": selectedIndex === index,
    });
    return (
      <div
        className={navigationTabClassnames}
        key={index}
        aria-controls={`panel${index}`}
      >
        <a
          className={showLabelsClassnames}
          onClick={() => setSelectedIndex(index)}
        >
          {child.props.label}
        </a>
      </div>
    );
  };

  return (
    <div className={tabsClassnames}>
      <div className="NavigationTabs NavigationTabs--horizontal">
        {children.map((child, index) => showLabels(child, index))}
      </div>
      <div className="Tabs--content">{children[selectedIndex]}</div>
    </div>
  );
}

export default Tabs;
