import React from "react";
import { Sidebar as SemanticSidebar } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/sidebar.css";
import "./Sidebar.scss";

function SidebarPushable({ as, children, ...props }) {
  return (
    <SemanticSidebar.Pushable as={as} {...props}>
      {children}
    </SemanticSidebar.Pushable>
  );
}

export default SidebarPushable;
