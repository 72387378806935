import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { isEqual } from "lodash";
import { openInModal, ModalNames } from "store/modal";
import { getCollection } from "store/entities/selectors";
import {
  createPostOnCompany as createPostOnCompanyHelper,
  updatePostOnCompany as updatePostOnCompanyHelper,
  removePostOnCompany as removePostOnCompanyHelper,
} from "./actions";
import useCurrentUser from "hooks/useCurrentUser";

export default () => {
  const dispatch = useDispatch();
  const view = useSelector((state) => state.views.company, shallowEqual);

  const notes = useSelector(
    (state) => getCollection(state, "companyPosts", view.company_posts_ids),
    isEqual,
  );

  const currentUser = useCurrentUser();

  const createPost = (resource, payload) =>
    dispatch(createPostOnCompanyHelper(resource, payload));
  const updatePost = (resource, postId, payload) =>
    dispatch(updatePostOnCompanyHelper(resource, postId, payload));
  const removePost = (resource, postId) =>
    dispatch(removePostOnCompanyHelper(resource, postId));
  const openDocumentsUploader = (options = {}) =>
    dispatch(openInModal(ModalNames.discussionDocuments, options));

  return {
    view,
    notes,
    currentUser,

    createPost,
    updatePost,
    removePost,
    openDocumentsUploader,
  };
};
