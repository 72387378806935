import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import AvatarFilePicker from "./AvatarFilePicker";
import AuthenticationContext from "components/Mighty/AuthenticationContext";
import { updateUserAccountInformation } from "store/users/actions";
import { closeModal } from "store/modal";
import { fetchOrganizations } from "store/organizations/actions";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import getMessage from "components_new/lib/Messages";

function AvatarFilePickerContainer() {
  const dispatch = useDispatch();
  const { setCurrentUser } = useContext(AuthenticationContext);
  const { addToast } = useSetToast();

  const handleUpdateUserAvatar = (id, user) => {
    return dispatch(updateUserAccountInformation(id, user)).then(({ user }) => {
      setCurrentUser(user);
      addToast(getMessage("updateUserAvatar"));
      dispatch(fetchOrganizations());
      dispatch(closeModal());
      return { user };
    });
  };

  return <AvatarFilePicker updateUserAvatar={handleUpdateUserAvatar} />;
}

export default AvatarFilePickerContainer;
