import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  investmentPropType,
  valuationProptype,
  errorsPropType,
} from "PropTypes";
import TableColumns from "components/shared/Table/TableColumns";

function ValuationRowTemplate({ index, data, columns, updateForm, errors }) {
  return (
    <TableColumns
      columns={columns}
      renderColumn={({ renderValue, renderForm }) => (
        <Fragment>
          <div className="PreviewRow u-textWithEllipsis">
            {renderValue(data)}
          </div>
          <div className="EditRow">
            {renderForm(data, updateForm, index, errors)}
          </div>
        </Fragment>
      )}
    />
  );
}

ValuationRowTemplate.propTypes = {
  index: PropTypes.number,
  updateForm: PropTypes.func,
  data: valuationProptype,
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  investment: investmentPropType,
  errors: errorsPropType,
};

export default ValuationRowTemplate;
