import React, { useState } from "react";
import PropTypes from "prop-types";
import { customFieldProptype } from "PropTypes";
import classnames from "classnames";
import Button from "components_new/atoms/Button";
import Icon from "components_new/atoms/Icon";
import EmptyCustomFieldView from "./EmptyCustomFieldView/index.js";
import CustomFieldView from "./CustomFieldView";

function showCustomField(
  props,
  addCustomField,
  setAddCustomField,
  openItem,
  setOpenItem,
  onCreate,
  isOwner,
) {
  const {
    customFields,
    editCustomField,
    openDeleteCustomFieldModal,
    isSubmitting,
  } = props;

  if (customFields.length > 0 || addCustomField) {
    return (
      <CustomFieldView
        setAddCustomField={setAddCustomField}
        customFields={customFields}
        addCustomField={addCustomField}
        isSubmitting={isSubmitting}
        onSubmit={onCreate}
        onDelete={openDeleteCustomFieldModal}
        onEdit={editCustomField}
        openItem={openItem}
        setOpenItem={setOpenItem}
        isOwner={isOwner}
      />
    );
  }

  return (
    <EmptyCustomFieldView
      isOwner={isOwner}
      showNewCustomFieldForm={() => setAddCustomField(true)}
    />
  );
}

function CustomFields(props) {
  const { createCustomField, customFields, isOwner } = props;
  const [addCustomField, setAddCustomField] = useState(false);
  const [openItem, setOpenItem] = useState(null);

  function onCreate(formData) {
    return createCustomField(formData).then(() => {
      setAddCustomField(false);
    });
  }

  const customFieldsClassnames = classnames("FormSection CustomFieldsView", {
    isEmpty: customFields.length === 0,
  });

  return (
    <section className={customFieldsClassnames}>
      <h2 className="FormSection-title u-sectionHeading">Custom Fields</h2>
      <Button
        primary
        iconPosition="left"
        className="AddCustomFieldButton"
        disabled={addCustomField || !isOwner}
        size="small"
        onClick={() => {
          if (openItem) {
            setOpenItem(null);
          }
          setAddCustomField(true);
        }}
      >
        <Icon icon="ri-add-line" />
        Add Custom Field
      </Button>
      {showCustomField(
        props,
        addCustomField,
        setAddCustomField,
        openItem,
        setOpenItem,
        onCreate,
        isOwner,
      )}
    </section>
  );
}

CustomFields.defaultProps = {
  customFields: [],
};

CustomFields.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  editCustomField: PropTypes.func.isRequired,
  createCustomField: PropTypes.func.isRequired,
  openDeleteCustomFieldModal: PropTypes.func.isRequired,
  customFields: PropTypes.arrayOf(customFieldProptype),
  isOwner: PropTypes.bool,
};

export default CustomFields;
