import React from "react";
import LoginLayout from "components/auth/LoginLayout";
import MightyLogoIcon from "-!svg-react-loader?!assets/icons/rf-mighty-logo.svg?name=MightyLogoIcon";
import VerifyCodeForm from "./VerifyCodeForm";
import { motion } from "framer-motion";
import { fadeIn, animationFromLeft } from "lib/animations";
import useCurrentUser from "hooks/useCurrentUser";

function VerifyLoginView() {
  const currentUser = useCurrentUser();

  return (
    <LoginLayout extraClassnames="Login--verifyLogin">
      <div className="Login-holder">
        <div className="u-bg-primary LoginWelcome">
          <div className="LoginLogoHolder">
            <MightyLogoIcon className="LoginLogo" />
            <div className="AlphaLogoTextHolder">
              <div className="u-pills AlphaLogoText">alpha</div>
            </div>
          </div>
          <motion.h1
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            transition={{ duration: 0.05 }}
            className="u-heading"
          >
            <span className="LoginCurrentUser">
              Hi {currentUser.full_name}!
            </span>
            One more step before accessing your account.
          </motion.h1>
          <motion.p
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            transition={{ duration: 0.15 }}
            className="LoginWelcome-message"
          >
            Mighty Porfolio takes security seriously. We’ll always send you a
            2-factor authentication code on login.
          </motion.p>
        </div>
        <div className="u-bg-tint-0 LoginForm">
          <div className="LoginForm-holder">
            <VerifyCodeForm>
              <motion.h2
                initial="hidden"
                animate="visible"
                variants={animationFromLeft()}
                transition={{ duration: 0.15 }}
                className="u-sectionHeading  LoginTitle"
              >
                Verify login
              </motion.h2>
            </VerifyCodeForm>
          </div>
        </div>
      </div>
    </LoginLayout>
  );
}

export default VerifyLoginView;
