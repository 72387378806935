import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import TextInput from "components/shared/form/TextInput";
import ActionButton from "components/shared/ActionButton";
import Checkbox from "components_new/atoms/Checkbox";

import TrashIcon from "-!svg-react-loader?!assets/icons/utility/trash.svg?name=TrashIcon";

const MULTIPLE_CHOICE_ATTRIBUTES = { label: "", value: false };
const SINGLE_CHOICE_ATTRIBUTES = { label: "", value: "" };

function MutipleChoiceListMaker({
  values,
  name,
  onChange,
  permanentItemsCount,
  type,
}) {
  const [choices, setChoices] = useState(values || []);

  useEffect(() => {
    if (onChange) {
      onChange(name, choices);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choices]);

  function handleChange(value, index) {
    const newChoices = [...choices];
    newChoices[index].label = value;
    if (type === "radioButton") {
      newChoices[index].value = value;
    }
    setChoices(newChoices);
  }

  const addChoice = () => {
    if (type === "radioButton") {
      setChoices((choices) => [...choices, { ...SINGLE_CHOICE_ATTRIBUTES }]);
    } else if (type === "checkBox") {
      setChoices((choices) => [...choices, { ...MULTIPLE_CHOICE_ATTRIBUTES }]);
    }
  };

  const removeChoice = (choicesIndex) => {
    const newChoices = [...choices];
    newChoices.splice(choicesIndex, 1);
    setChoices(newChoices);
  };

  return (
    <div className="MutipleChoiceListMaker">
      {choices.map((choice, index) => {
        return (
          <div className="ChoiceList" key={index}>
            <Checkbox radio={type === "radioButton"} readOnly />
            <TextInput
              value={choice.label || ""}
              onChange={(value) => handleChange(value, index)}
              size="small"
            />
            {index > permanentItemsCount - 1 && (
              <TrashIcon
                className="ChoiceList-delete"
                onClick={() => removeChoice(index)}
              />
            )}
          </div>
        );
      })}

      <ActionButton
        customClass="Choice-add"
        onClick={() => addChoice()}
        size="small"
      >
        + Add
      </ActionButton>
    </div>
  );
}

MutipleChoiceListMaker.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  permanentItemsCount: PropTypes.number,
  type: PropTypes.oneOf(["checkBox", "radioButton"]),
  values: PropTypes.arrayOf(PropTypes.shape({})),
};

MutipleChoiceListMaker.defaultProps = {
  permanentItemsCount: 0,
  type: "checkBox",
};

export default MutipleChoiceListMaker;
