import React, { useEffect, useState } from "react";
import FormGroup from "components_new/elements/FormGroup";
import DatePicker from "components_new/atoms/DatePicker";
import CurrencyInput from "components_new/atoms/CurrencyInput";
import Checkbox from "components_new/atoms/Checkbox";
import formatPrice from "lib/formatters/formatPrice";
import Tooltip from "components_new/atoms/Tooltip";
import calculateDebtInterest from "lib/calculateDebtInterest";
import moment from "moment";
import { placeholderDateFormat, valueDateFormat } from "config/dateInput";
import Icon from "components_new/atoms/Icon";
import "./ConversionDetails.scss";

function SuggestedAmountLabel({
  calculated_conversion_amount,
  accrued_interest,
  total_acquisition_amount,
}) {
  const suggestedAmount =
    calculated_conversion_amount && formatPrice(calculated_conversion_amount);
  const tooltipContent = (
    <div className="SuggestedAmountTooltip">
      <div className="suggestedAmountTooltip-principal">
        <span>Principal</span>
        <span>
          {total_acquisition_amount && formatPrice(total_acquisition_amount)}
        </span>
      </div>
      <div className="suggestedAmountTooltip-accruedInterest">
        <span>Accrued Interest</span>
        <span>{accrued_interest && formatPrice(accrued_interest)}</span>
      </div>
      <div className="suggestedAmountTooltip-total">
        <span>TOTAL</span>
        <span>
          {calculated_conversion_amount &&
            formatPrice(calculated_conversion_amount)}
        </span>
      </div>
    </div>
  );
  return (
    <div className="SuggestedAmount">
      {`Use suggested amount of: ${suggestedAmount}`}{" "}
      <Tooltip
        position="top center"
        content={tooltipContent}
        trigger={<Icon icon="ri-information-line" />}
      ></Tooltip>
    </div>
  );
}

function ConversionDetails({ onChange, formData, errors, investment }) {
  const {
    date,
    conversion_amount,
    calculated_conversion_amount,
    accrued_interest,
  } = formData;

  const [usingSuggestedAmount, setUsingSuggestedAmount] = useState(false);

  useEffect(() => {
    setAccruedInterest(moment().format(valueDateFormat));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setAccruedInterest = (conversionDate) => {
    const debtInterest = calculateDebtInterest(investment, conversionDate);
    onChange("accrued_interest", debtInterest.accruedInterest);
    onChange(
      "calculated_conversion_amount",
      debtInterest.calculatedConversionAmount,
    );
  };

  const handleOnConversionAmountChange = (conversionAmount) => {
    onChange("conversion_amount", conversionAmount);
    setUsingSuggestedAmount(conversionAmount === calculated_conversion_amount);
  };
  const handleOnUsingSuggestedAmount = (useSuggestedAmount) => {
    if (useSuggestedAmount) {
      handleOnConversionAmountChange(calculated_conversion_amount);
    } else {
      onChange("conversion_amount", null);
      setUsingSuggestedAmount(false);
    }
  };

  return (
    <FormGroup
      extraClassnames="ConversionDetails"
      title="Conversion Details"
      isExpanded
      required
    >
      <DatePicker
        name="date"
        placeholder={placeholderDateFormat}
        label="Date of conversion"
        prefillToday
        onChange={(value, validated) => {
          setAccruedInterest(value);
          onChange("date", value, validated);
        }}
        error={!!errors.for("date")}
        errorMessage={errors.for("date")}
        value={date}
        size="tiny"
        required
      />
      <CurrencyInput
        name="conversion_amount"
        label="Conversion amount"
        placeholder="0.00"
        value={conversion_amount}
        onChange={(value) => {
          handleOnConversionAmountChange(value);
        }}
        //error={!!errors.for("conversion_amount")}
        errorMessage={errors.for("conversion_amount")}
        decimalLimit={2}
        iconPosition="left"
        size="tiny"
      />
      <Checkbox
        label={
          <SuggestedAmountLabel
            calculated_conversion_amount={calculated_conversion_amount}
            accrued_interest={accrued_interest}
            total_acquisition_amount={investment.total_acquisition_amount}
          />
        }
        checked={usingSuggestedAmount}
        size="tiny"
        extraClassName="FormElement"
        onChange={(e, { checked }) => {
          handleOnUsingSuggestedAmount(checked);
        }}
      />
    </FormGroup>
  );
}

export default ConversionDetails;
