import {
  UPDATE_DASHBOARD_EVENTS_COUNT,
  UPDATE_EVENT_RELATED_INVESTMENTS,
  CLEAN_DASHBOARD_EVENTS,
} from "./dashboardEvents/actionTypes";

const initialState = {
  dashboardEventsCount: null,
  eventRelatedInvestments: {},
};

function eventsReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_DASHBOARD_EVENTS_COUNT:
      return {
        ...state,
        dashboardEventsCount: action.eventsCount,
      };
    case UPDATE_EVENT_RELATED_INVESTMENTS:
      return {
        ...state,
        eventRelatedInvestments: action.eventRelatedInvestments,
      };
    case CLEAN_DASHBOARD_EVENTS:
      return initialState;
    default:
      return state;
  }
}

export default eventsReducer;
