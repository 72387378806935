import React, { useState } from "react";
import FormFooter from "components_new/SidebarComponents/SidebarForm/FormFooter";
import FormGroup from "components_new/elements/FormGroup";
import DropdownForm from "components_new/atoms/DropdownForm";
import CurrencyInput from "components_new/atoms/CurrencyInput";
import DatePicker from "components_new/atoms/DatePicker";
import Input from "components_new/atoms/Input";
import PercentageInput from "components_new/atoms/PercentageInput";
import RequiredNote from "components_new/SidebarComponents/shared/RequiredNote";
import useForm from "lib/hooks/useForm";
import formatPrice from "lib/formatters/formatPrice";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import getMessage from "components_new/lib/Messages";
import { useNavigate } from "react-router-dom";

function PortfolioForm({
  ownName,
  closeSidebar,
  submitPortfolio,
  isEditing,
  portfolioData,
  currentOrganizationUsername,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useSetToast();
  const navigate = useNavigate();
  const initialValues = {
    formData: isEditing
      ? { ...portfolioData }
      : {
          name: "",
          portfolio_type: "",
          limit: null,
          inception_date: null,
          closing_date: null,
          amount_raised: null,
          carry_percentage: null,
          fee_percentage: null,
        },
  };

  function submit(formData) {
    setIsLoading(true);
    submitPortfolio(formData)
      .then(
        ({ data: newPortfolio }) => {
          closeSidebar();
          if (isEditing) {
            addToast(getMessage("updatePortfolio"));
          } else {
            addToast(getMessage("addedPortfolio"));
            navigate(
              `/${currentOrganizationUsername}/portfolios/${newPortfolio.id}`,
            );
          }
        },
        (error) => {
          handleErrors(error);
          handleScrollView(error);
        },
      )
      .finally(() => {
        setIsLoading(false);
      });
  }

  const {
    formData,
    handleChange,
    handleSubmit,
    handleErrors,
    handleScrollView,
    errors,
    touched,
  } = useForm(initialValues, submit);
  const {
    name,
    portfolio_type,
    limit,
    inception_date,
    closing_date,
    amount_raised,
    carry_percentage,
    fee_percentage,
  } = formData;

  const portfolioTypeItems = [
    { key: 0, text: "Fund", value: "fund" },
    { key: 1, text: "Holding company", value: "holdingCompany" },
    { key: 2, text: "General portfolio", value: "generalPortfolio" },
    { key: 3, text: "SPV", value: "spv" },
  ];

  return (
    <div className="PortfolioForm">
      <FormGroup
        title="Fund Details"
        subtitle="Information that will help organize your investments."
        required
        isExpanded={true}
      >
        <Input
          name="name"
          label="Fund Name"
          value={name}
          onChange={(_, { value }) => {
            handleChange("name", value);
          }}
          isRequired
          error={!!errors.for("name")}
          errorMessage={errors.for("name")}
          size="tiny"
        />
        <DropdownForm
          name="portfolio_type"
          label="Type of fund"
          placeholder="Select fund type"
          items={portfolioTypeItems}
          onChange={(_, { value }) => handleChange("portfolio_type", value)}
          value={portfolio_type}
          size="tiny"
        />
        <CurrencyInput
          name="limit"
          label="Fund limit"
          decimalLimit={2}
          iconPosition="left"
          placeholder="0.00"
          onChange={(value) => handleChange("limit", value)}
          defaultValue={limit && formatPrice(limit)}
          value={limit}
          size="tiny"
        />
        <CurrencyInput
          name="amount_raised"
          label="Amount Raised"
          decimalLimit={0}
          iconPosition="left"
          placeholder="0"
          value={amount_raised}
          onChange={(value) => handleChange("amount_raised", value)}
          defaultValue={amount_raised && amount_raised}
          size="tiny"
        />
        <PercentageInput
          name="carry_percentage"
          label="Carry %"
          decimalLimit={2}
          allowDecimal
          iconPosition="right"
          placeholder="e.g 0"
          value={carry_percentage}
          defaultValue={carry_percentage && carry_percentage}
          onChange={(value) => handleChange("carry_percentage", value)}
          size="tiny"
        />
        <PercentageInput
          name="fee_percentage"
          label="Fee %"
          decimalLimit={2}
          allowDecimal
          iconPosition="right"
          placeholder="e.g 0"
          value={fee_percentage}
          defaultValue={fee_percentage && fee_percentage}
          onChange={(value) => handleChange("fee_percentage", value)}
          size="tiny"
        />
        <DatePicker
          name="inception_date"
          label="Date of inception"
          onChange={(value, validated) =>
            handleChange("inception_date", value, validated)
          }
          value={inception_date}
          prefillToday={false}
          size="tiny"
        />
        <DatePicker
          name="closing_date"
          label="Date of closing"
          onChange={(value, validated) =>
            handleChange("closing_date", value, validated)
          }
          value={closing_date}
          prefillToday={false}
          size="tiny"
        />
      </FormGroup>
      <RequiredNote />
      <FormFooter
        componentName={ownName}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        disableSubmit={isLoading}
        touched={touched}
        submitTextButton="Save Fund"
      />
    </div>
  );
}

export default PortfolioForm;
