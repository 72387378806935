import React, { Fragment } from "react";
import {
  TableHeader,
  TableRow,
  SortedTable,
  SortableTableHeaderCell,
  TableBody,
} from "components_new/elements/Table";
import EmptyPage from "components_new/elements/EmptyPage";
import CompaniesRow from "./CompaniesRow";
import "./CompaniesPartnerTable.scss";
import { isEmpty } from "lodash";

const COLUMN_HEADERS = [
  {
    value: "name",
    title: "Company",
    skeletonType: "companyName",
    type: "string",
  },
  {
    value: "location",
    title: "Location",
    skeletonType: "date_single_line",
    type: "string",
  },
  {
    value: "tags",
    title: "Tags",
    skeletonType: "date_single_line",
    type: "array",
  },
  {
    value: "firm_type",
    title: "Firm Type",
    skeletonType: "date_single_line",
    type: "array",
  },
  {
    value: "service_domain",
    title: "Service Domain",
    skeletonType: "date_single_line",
    type: "array",
  },
  {
    value: "companies_contacts",
    title: "Contacts",
    skeletonType: "date_single_line",
    type: "array",
  },
];

function CompaniesPartnerTable({
  data,
  isLoading,
  isEmptyView,
  organizationUsername,
  handleOpenEditCompanyForm,
}) {
  if (isEmptyView) {
    return <EmptyPage headline={`You don't have any companies.`} />;
  }
  return (
    <SortedTable
      tableData={data}
      isLoading={isLoading}
      skeletonStructure={{ rows: 6, headers: COLUMN_HEADERS }}
      className="CompaniesTable CompaniesPartnerTable"
      attached
      data-testid="CompaniesPartnerTable"
    >
      {({ data, handleSort, isSorted }) => (
        <Fragment>
          <TableHeader>
            <TableRow>
              {COLUMN_HEADERS.map((columnHeader, index) => {
                return (
                  <SortableTableHeaderCell
                    key={index}
                    className={columnHeader.className}
                    isSorted={isSorted}
                    handleSort={handleSort}
                    {...columnHeader}
                  >
                    {columnHeader.title}
                  </SortableTableHeaderCell>
                );
              })}
            </TableRow>
          </TableHeader>
          <TableBody isEmptyData={isEmpty(data)}>
            {data &&
              data.map((company, index) => (
                <CompaniesRow
                  key={index}
                  company={company}
                  organizationUsername={organizationUsername}
                  handleOpenEditCompanyForm={handleOpenEditCompanyForm}
                />
              ))}
          </TableBody>
        </Fragment>
      )}
    </SortedTable>
  );
}

export default CompaniesPartnerTable;
