import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  investmentPropType,
  capitalCallPropType,
  errorsPropType,
} from "PropTypes";
import TableColumns from "components/shared/Table/TableColumns";

function CapitalCallRowTemplate({
  totalPaidIn,
  index,
  data,
  columns,
  updateForm,
  errors,
  openAttachCapitalCallModal,
  fetchAfterUpdateDocuments,
  investment,
}) {
  const dataWithTotalPaidIn = {
    ...data,
    totalPaid: totalPaidIn[index - 1],
    rowIndex: index,
  };

  return (
    <TableColumns
      columns={columns}
      renderColumn={({ renderValue, renderForm }) => (
        <Fragment>
          <div className="PreviewRow">
            {renderValue(
              dataWithTotalPaidIn,
              fetchAfterUpdateDocuments,
              investment,
            )}
          </div>
          <div className="EditRow">
            {renderForm(
              dataWithTotalPaidIn,
              updateForm,
              index,
              errors,
              openAttachCapitalCallModal,
              investment,
            )}
          </div>
        </Fragment>
      )}
    />
  );
}

CapitalCallRowTemplate.propTypes = {
  index: PropTypes.number,
  updateForm: PropTypes.func,
  openAttachCapitalCallModal: PropTypes.func,
  fetchAfterUpdateDocuments: PropTypes.func,
  data: capitalCallPropType,
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  investment: investmentPropType,
  errors: errorsPropType,
  totalPaidIn: PropTypes.arrayOf(PropTypes.number),
};

export default CapitalCallRowTemplate;
