import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { upsertResource } from "store/resourcesV2/actions";
import Api from "lib/Api";

export function useCreatePipeline() {
  const dispatch = useDispatch();

  return useMutation(() => Api.post(`/pipelines`), {
    onSuccess: ({ data }) => {
      // TODO: Remove this integration with redux when we migrated organization
      // into React Query.

      dispatch(upsertResource("organizations", data.organization));
    },
  });
}
