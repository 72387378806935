import React from "react";
import PropTypes from "prop-types";
import EllipsisDropdown from "components_new/elements/EllipsisDropdown";

function PortfolioActions({
  investmentsCount,
  onEdit,
  onValidate,
  onDelete,
  portfolioId,
}) {
  const portfolioMenuItems = [
    {
      text: "Edit Portfolio",
      onClick: () => {
        onEdit(portfolioId);
      },
    },
    {
      text: "Delete Portfolio",
      onClick: () => {
        investmentsCount > 0 ? onValidate() : onDelete(portfolioId);
      },
    },
  ];
  return (
    <EllipsisDropdown
      iconOrientation="vertical"
      iconSize="small"
      options={portfolioMenuItems}
    />
  );
}

PortfolioActions.propTypes = {
  investmentsCount: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  portfolioId: PropTypes.string.isRequired,
};

export default PortfolioActions;
