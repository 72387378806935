import React, { Fragment } from "react";
import {
  TableHeader,
  TableRow,
  SortedTable,
  SortableTableHeaderCell,
  TableBody,
} from "components_new/elements/Table";
import EmptyPage from "components_new/elements/EmptyPage";
import CompaniesRow from "./CompaniesRow";
import { isEmpty } from "lodash";
import "./CompaniesTable.scss";

const COLUMN_HEADERS = [
  {
    value: "name",
    title: "Company",
    skeletonType: "companyName",
    type: "string",
  },
  {
    value: "location",
    title: "Headquarters",
    skeletonType: "date_single_line",
    type: "string",
  },
  {
    value: "portfolio_names",
    title: "Funds",
    skeletonType: "date_single_line",
    type: "array",
  },
  {
    value: "first_investment_date",
    title: "First Investment",
    skeletonType: "date_single_line",
    type: "date",
  },
  {
    value: "sectors",
    title: "Sectors",
    skeletonType: "date_single_line",
    type: "array",
  },
  {
    value: "tags",
    title: "Tags",
    skeletonType: "date_single_line",
    type: "array",
  },
  {
    value: "activity_status",
    title: "Activity Status",
    skeletonType: "date_single_line",
    type: "string",
  },
  {
    className: "NumericColumn right aligned",
    value: "total_invested",
    skeletonType: "amount",
    title: "Total Invested",
    type: "numeric",
  },
];

function CompaniesDefaultTable({
  data,
  isLoading,
  isEmptyView,
  organizationUsername,
  handleOpenEditCompanyForm,
}) {
  if (isEmptyView) {
    return <EmptyPage headline={`You don't have any companies.`} />;
  }

  return (
    <SortedTable
      tableData={data}
      isLoading={isLoading}
      skeletonStructure={{ rows: 8, headers: COLUMN_HEADERS }}
      className="CompaniesTable"
      attached
      data-testid="CompaniesTable"
    >
      {({ data, handleSort, isSorted }) => (
        <Fragment>
          <TableHeader>
            <TableRow>
              {COLUMN_HEADERS.map((columnHeader, index) => {
                return (
                  <SortableTableHeaderCell
                    key={index}
                    className={columnHeader.className}
                    isSorted={isSorted}
                    handleSort={handleSort}
                    {...columnHeader}
                  >
                    {columnHeader.title}
                  </SortableTableHeaderCell>
                );
              })}
            </TableRow>
          </TableHeader>

          <TableBody isEmptyData={isEmpty(data)}>
            {data &&
              data.map((company, index) => (
                <CompaniesRow
                  key={index}
                  company={company}
                  organizationUsername={organizationUsername}
                  handleOpenEditCompanyForm={handleOpenEditCompanyForm}
                />
              ))}
          </TableBody>
        </Fragment>
      )}
    </SortedTable>
  );
}

export default CompaniesDefaultTable;
