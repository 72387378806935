import React from "react";
import EmptyPage from "components_new/elements/EmptyPage";
import CompaniesGridItem from "./CompaniesGridItem";
import CompaniesGridSkeleton from "./CompaniesGridSkeleton";
import "./CompaniesGrid.scss";
import { isEmpty } from "lodash";

function CompaniesGrid({
  data,
  isLoading,
  isEmptyView,
  organizationUsername,
  handleOpenEditCompanyForm,
}) {
  if (isEmptyView) {
    return <EmptyPage headline={`You don't have any companies`} />;
  }
  if (isLoading) {
    return <CompaniesGridSkeleton />;
  }
  if (isEmpty(data)) {
    return (
      <div className="CompaniesGrid">
        <div className="EmptyGridMessage">
          0 results found matching your filters
        </div>
      </div>
    );
  }
  return (
    <div className="CompaniesGrid" data-testid="CompaniesGrid">
      {data &&
        data.map((company, index) => (
          <CompaniesGridItem
            key={index}
            company={company}
            organizationUsername={organizationUsername}
            handleOpenEditCompanyForm={handleOpenEditCompanyForm}
          />
        ))}
    </div>
  );
}

export default CompaniesGrid;
