import React from "react";
import { isEmpty } from "lodash";
import { useSetSidebar } from "components_new/elements/SidebarWrapper/Sidebar-context";
import CompanyContacts from "components_new/views/Company/shared/CompanyContacts";

function CompanyContactTab({ referrers, referrerCompanies, employees }) {
  const referrersData = referrers.map((referrer) =>
    addCompanyNameToReferrer(referrer),
  );
  const { showSidebar } = useSetSidebar();

  const openContactDetailsView = (contact) => {
    showSidebar("contactsView", { contactId: contact.id });
  };

  function addCompanyNameToReferrer(referrer) {
    const referrerCompany = referrerCompanies.find(
      (company) => company.id === referrer.company_id,
    );
    return {
      ...referrer,
      companyName: !isEmpty(referrerCompany) ? referrerCompany.name : "",
    };
  }

  return (
    <div className="TabContentDiv">
      <CompanyContacts
        title="Contacts"
        testId="ContactsGroup"
        companyContacts={employees}
        openContactDetailsView={openContactDetailsView}
      />
      <CompanyContacts
        title="Referrers"
        testId="ReferrersGroup"
        companyContacts={referrersData}
        openContactDetailsView={openContactDetailsView}
      />
    </div>
  );
}

export default CompanyContactTab;
