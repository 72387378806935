import React from "react";
import FormGroup from "components_new/elements/FormGroup";
import PercentageInput from "components_new/atoms/PercentageInput";
import CurrencyInput from "components_new/atoms/CurrencyInput";
import TextArea from "components_new/atoms/TextArea";

function ConversionDetails({ formData, handleChange }) {
  const {
    qualified_financing_amount,
    conversion_discount,
    conversion_cap,
    conversion_terms,
    conversion_price,
    conversion_ratio,
  } = formData;

  return (
    <FormGroup
      title="Conversion Details"
      extraClassnames="ConversionDetails"
      isExpanded={false}
      required={false}
      noBottomBorder
      subGroup
    >
      <CurrencyInput
        name="qualified_financing_amount"
        label="Qualified financing amount"
        placeholder="0.00"
        decimalLimit={2}
        defaultValue={qualified_financing_amount}
        value={qualified_financing_amount}
        onChange={(value) => {
          handleChange("qualified_financing_amount", value);
        }}
        size="tiny"
        iconPosition="left"
      />
      <PercentageInput
        name="conversion_discount"
        label="Conversion discount"
        onChange={(value) => handleChange("conversion_discount", value)}
        defaultValue={conversion_discount}
        value={conversion_discount}
        allowDecimal
        size="tiny"
      />
      <CurrencyInput
        name="conversion_cap"
        label="Conversion cap"
        placeholder="0.00"
        decimalLimit={2}
        defaultValue={conversion_cap}
        value={conversion_cap}
        onChange={(value) => {
          handleChange("conversion_cap", value);
        }}
        size="tiny"
        iconPosition="left"
      />
      <TextArea
        name="conversion_terms"
        label="Conversion terms"
        placeholder="Add your description"
        value={conversion_terms}
        onChange={(_, { value }) => {
          handleChange("conversion_terms", value);
        }}
        rows={null}
        size="tiny"
      />
      <CurrencyInput
        name="conversion_price"
        label="Conversion price"
        placeholder="0.00"
        decimalLimit={2}
        defaultValue={conversion_price}
        value={conversion_price}
        onChange={(value) => {
          handleChange("conversion_price", value);
        }}
        size="tiny"
        iconPosition="left"
      />
      <CurrencyInput
        name="conversion_ratio"
        label="Conversion ratio"
        placeholder="0.00"
        decimalLimit={2}
        defaultValue={conversion_ratio}
        value={conversion_ratio}
        onChange={(value) => {
          handleChange("conversion_ratio", value);
        }}
        size="tiny"
        iconPosition="left"
      />
    </FormGroup>
  );
}

export default ConversionDetails;
