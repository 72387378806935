import React from "react";
import PropTypes from "prop-types";
import { organizationProptype } from "PropTypes";
import GroupedVerticalButtonMenu from "components_new/elements/GroupedVerticalButtonMenu";
import useSidebar from "store/sidebar/hooks";
import { useSetSidebar } from "components_new/elements/SidebarWrapper/Sidebar-context";
import {
  globalAddDealIsAvailable,
  globalUploadFileIsAvailable,
  globalPostIsAvailable,
  globalUpdatePPSIsAvailable,
  globalLogCapitalCallIsAvailable,
} from "lib/globalActionsAvailability";

import "./CompanyCallToActionSidebar.scss";

function CompanyCallToActionSidebar({
  companyDetails,
  companyId,
  currentOrganization,
  isCurrentUserOrganizationOwner,
  openFilesSidebarForm,
  openNotesSidebarForm,
  openValuationSidebarForm,
  openGlobalCapitalCallSidebarForm,
}) {
  const tooltipContent = isCurrentUserOrganizationOwner
    ? "Please visit the pipeline tab\nto be able to create a deal."
    : "Please contact your org owner\nto set up a pipeline.";
  const { openSidebar } = useSidebar();
  const { showSidebar } = useSetSidebar();
  const addToCompanyItems = [
    {
      title: "New Capital Call",
      disabled: !globalLogCapitalCallIsAvailable(currentOrganization),
      onClick: () => {
        openGlobalCapitalCallSidebarForm(companyId);
      },
    },
    {
      title: "New Valuation",
      disabled: !globalUpdatePPSIsAvailable,
      onClick: () => {
        openValuationSidebarForm(companyId);
      },
    },
    {
      title: "New Note",
      //avatarIcon: "note-sm",
      disabled: !globalPostIsAvailable(currentOrganization),
      onClick: () => {
        openNotesSidebarForm(companyId);
      },
    },
    {
      title: "New File",
      //avatarIcon: "report-sm",
      disabled: !globalUploadFileIsAvailable(currentOrganization),
      onClick: () => {
        openFilesSidebarForm(companyId);
      },
    },
    {
      title: "New Deal",
      //avatarIcon: "deal-sm",
      disabled: !globalAddDealIsAvailable(currentOrganization),
      tooltip: !globalAddDealIsAvailable(currentOrganization) && tooltipContent,
      tooltipPosition: "top center",
      onClick: () => {
        showSidebar("dealForm", { initialCompanyId: companyId, size: "wide" });
      },
    },
    {
      title: "New Investment",
      onClick: () => {
        openSidebar("investmentForm", "Add New Investment", { companyId });
      },
    },
    {
      title: "New Contact",
      onClick: () => {
        openSidebar("contactForm", "Add New Contact");
      },
    },
  ];

  return (
    <div className="CompanyCallToActionSidebarWrapper">
      <GroupedVerticalButtonMenu
        title={`Add to ${companyDetails.name}`}
        items={addToCompanyItems}
      />
    </div>
  );
}

CompanyCallToActionSidebar.propTypes = {
  companyDetails: PropTypes.shape({ name: PropTypes.string }),
  companyId: PropTypes.string,
  currentOrganization: organizationProptype,
  isCurrentUserOrganizationOwner: PropTypes.bool,
  openFilesSidebarForm: PropTypes.func,
  openNotesSidebarForm: PropTypes.func,
  openReferrerModal: PropTypes.func,
  openValuationSidebarForm: PropTypes.func,
  openGlobalCapitalCallSidebarForm: PropTypes.func,
  createContact: PropTypes.func,
};

export default CompanyCallToActionSidebar;
