import React from "react";
import ScrollView from "components_new/elements/ScrollView";
import CollapsableSection from "components_new/elements/CollapsableSection";
import DiscussionsDeal from "./Discussions";
import ActiveDealHeader from "./ActiveDealHeader";
import ActiveDealAttributes from "./ActiveDealAttributes";
import DealCompanyCard from "./DealCompanyCard";

function ActiveDealDetails({
  deal,
  dealStage,
  pipelineId,
  company,
  openEditCompanyForm,
  openDeleteDealModal,
  stagesOptions,
  updateDeal,
  updateDealStage,
  openEditDealForm,
  openContactDetailsView,
  currentOrganizationUsername,
  noteToHighlight,
  posts,
}) {
  return (
    <ScrollView
      scrollY={true}
      extraClassnames="ActiveDeals-SelectedDeal-Details"
    >
      <div className="SelectedDeal-Details-Body">
        <ScrollView.StickyHeader>
          <ActiveDealHeader
            deal={deal}
            dealStage={dealStage}
            openDeleteDealModal={openDeleteDealModal}
            stagesOptions={stagesOptions}
            updateDealStage={updateDealStage}
            openEditDealForm={openEditDealForm}
            updateDeal={updateDeal}
            pipelineId={pipelineId}
          />
        </ScrollView.StickyHeader>
        <DealCompanyCard
          company={company}
          openEditCompanyForm={openEditCompanyForm}
          currentOrganizationUsername={currentOrganizationUsername}
        />
        <ActiveDealAttributes
          deal={deal}
          openContactDetailsView={openContactDetailsView}
          currentOrganizationUsername={currentOrganizationUsername}
        />
        <CollapsableSection
          expanded
          title="Notes"
          extraClassNames="SelectedDeal-Details-Notes"
        >
          <DiscussionsDeal
            posts={posts}
            pipelineId={deal.pipeline_id}
            dealId={deal.id}
            openContactDetailsView={openContactDetailsView}
            noteToHighlight={noteToHighlight}
          />
        </CollapsableSection>
      </div>
    </ScrollView>
  );
}

export default ActiveDealDetails;
