import React, { useMemo } from "react";
import DropdownForm from "components_new/atoms/DropdownForm";
import { FIRM_TYPE, INVESTMENT_DOMAIN, SERVICE_DOMAIN } from "./config";
import "./FirmType.scss";

function FirmType({
  firmType,
  investmentDomain,
  serviceDomain,
  handleChange,
  size,
}) {
  const showInvestmentDomain = useMemo(
    () => firmType.includes("VC/Investment Firm"),
    [firmType],
  );

  const showServiceDomain = useMemo(
    () =>
      firmType.some((type) => type === "Service Firm" || type === "Partner"),
    [firmType],
  );

  return (
    <div className="FirmType">
      <DropdownForm
        label="Firm type"
        search
        multiple
        fluid
        placeholder="Add firm type"
        value={firmType}
        items={FIRM_TYPE}
        onChange={(_, { value }) => handleChange("firm_type", value)}
        size={size}
      />
      {showInvestmentDomain && (
        <DropdownForm
          label="Investment Domain"
          search
          multiple
          fluid
          placeholder="Add investment domain"
          value={investmentDomain}
          items={INVESTMENT_DOMAIN}
          onChange={(_, { value }) => handleChange("investment_domain", value)}
          size={size}
        />
      )}
      {showServiceDomain && (
        <DropdownForm
          label="Service Domain"
          search
          multiple
          fluid
          placeholder="Add service domain"
          value={serviceDomain}
          items={SERVICE_DOMAIN}
          onChange={(_, { value }) => handleChange("service_domain", value)}
          size={size}
        />
      )}
    </div>
  );
}

export default FirmType;
