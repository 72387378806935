import React, { useState, useEffect } from "react";
import Form from "components_new/atoms/Form";
import Button from "components_new/atoms/Button";
import InputTag from "components_new/elements/InputTag";
import Industry from "components/shared/Industry";
import getMessage from "components_new/lib/Messages";

import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import { Grid } from "@ableco/semantic-ui-react";

function getInitialValues({
  company_suggested_tags = [],
  contact_suggested_tags = [],
  primary_sectors = [],
}) {
  return { company_suggested_tags, contact_suggested_tags, primary_sectors };
}

function CustomFormPage({
  isOwner,
  tagActions,
  organization,
  updatePreferences,
}) {
  const [formData, setFormData] = useState(getInitialValues(organization));
  const [formHasChanged, setFormHasChanged] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { addToast } = useSetToast();

  useEffect(() => {
    setFormHasChanged(false);
  }, [organization]);

  const handleOnChange = (key, values) => {
    setFormHasChanged(true);
    setFormData((prevData) => ({ ...prevData, [key]: values }));
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await updatePreferences(organization.id, formData);

    addToast(getMessage("addSuggestedTag"));
    setIsSubmitting(false);
  };

  const disableSubmitButton = !formHasChanged;
  const userCannotEdit = !isOwner;

  return (
    <Form className="FormSection OrganizationDetails">
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <h2 className="FormSection-title u-sectionHeading">Extras</h2>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={10}>
            <InputTag
              label="Add company suggested Tags"
              placeholder="Type to filter or select a tag"
              disabled={userCannotEdit}
              taggedList={formData.company_suggested_tags}
              suggestedList={tagActions.suggestedList}
              onDelete={tagActions.onDelete}
              onEdit={tagActions.onEdit}
              onCreate={(tagName) => tagActions.onCreate(tagName, "company")}
              onFilter={(tagName) => tagActions.onFilter(tagName, "company")}
              onChange={(values) =>
                handleOnChange("company_suggested_tags", values)
              }
            />
            <InputTag
              label="Add contact suggested Tags"
              placeholder="Type to filter or select a tag"
              disabled={userCannotEdit}
              taggedList={formData.contact_suggested_tags}
              suggestedList={tagActions.suggestedList}
              onDelete={tagActions.onDelete}
              onEdit={tagActions.onEdit}
              onCreate={(tagName) => tagActions.onCreate(tagName, "contact")}
              onFilter={(tagName) => tagActions.onFilter(tagName, "contact")}
              onChange={(values) =>
                handleOnChange("contact_suggested_tags", values)
              }
            />
            <Industry
              label="Add primary sectors"
              createTagLabel="Create new sector"
              placeholder="Type to filter or select a sector"
              taggedList={formData.primary_sectors}
              onChange={(value) => handleOnChange("primary_sectors", value)}
            />
            <Button
              primary
              className="OrganizationDetails-SubmitButton"
              disabled={disableSubmitButton || userCannotEdit}
              onClick={handleSubmit}
              loading={isSubmitting}
            >
              Update Details in Organization
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
}

export default CustomFormPage;
