import Api from "lib/Api";
import { resetCollection } from "store/domain/domainActions";
import { resetViewState } from "store/views";
import { cleanDashboardEvents } from "store/events/dashboardEvents/actions";
import { cleanDashboard } from "store/dashboard/actions";
import { clearCollection } from "store/entities";
import { clearCollectionV2, receiveCollection } from "store/resourcesV2";
import { receiveEntitiesFromView } from "store/viewsV2";
import { requestStart, requestSuccess, requestError } from "store/requests";
import { setCurrentOrganization } from "store/organizations/actions";
import { isEmpty } from "lodash";

export function setOrganizationV2(organizationUsername) {
  // TODO: Maybe we can have a kind of loader in the entire app,
  // to avoid get errors associated with the data we are cleanning in this action.

  return (dispatch) => {
    dispatch(cleanDashboardEvents());
    dispatch(cleanDashboard());

    dispatch(clearCollectionV2("portfolios"));
    dispatch(clearCollectionV2("customFields"));
    dispatch(resetCollection("minimalInvestments"));
    dispatch(resetCollection("portfolios"));
    dispatch(resetCollection("companies"));
    dispatch(resetCollection("allCompanies"));
    dispatch(resetCollection("queues"));
    dispatch(resetCollection("tasks"));
    dispatch(clearCollection("deals"));
    dispatch(clearCollection("companies"));
    dispatch(clearCollection("tasks"));
    dispatch(resetViewState("contacts"));
    dispatch(clearCollection("contacts"));
    dispatch(resetViewState("investments"));
    dispatch(resetViewState("cashFlow"));
    dispatch(clearCollection("portfolioSummaryStats"));

    dispatch(requestStart("organizationFetch"));
    return Api.get(`/organizations/${organizationUsername}`, {
      withoutOrganization: true,
    }).then(
      ({ data: organization }) => {
        dispatch(setCurrentOrganization(organization));
        dispatch(receiveEntitiesFromView({ organizations: [organization] }));
        dispatch(requestSuccess("organizationFetch"));

        if (!isEmpty(organization.custom_fields)) {
          dispatch(
            receiveCollection("customFields", organization.custom_fields),
          );
        }

        return new Promise((resolve) => {
          resolve(organization);
        });
      },
      () => {
        dispatch(requestError("organizationFetch"));
      },
    );
  };
}
