import React, { Fragment } from "react";
import Text from "components_new/atoms/Text";
import EmptyCard from "components_new/elements/EmptyCard";

function EmptyContent() {
  return (
    <Fragment>
      <Text type="label-bold" color="brand-light">
        SNAPSHOT CONTENTS
      </Text>
      <div className="SnapshotContentsGroup-empty">
        <EmptyCard title="snapshot sections" nilLabel="No" />
      </div>
    </Fragment>
  );
}

export default EmptyContent;
