export default function getInvestmentOrigin(
  investmentType,
  currentHistoryActionType,
) {
  const originTypes = {
    distributed: "Received from distribution",
    acquired: "Via acquisition",
    converted: "Converted",
    sold: "Results from sale",
    stock_splitted: "Stock splitted",
    purchased: "Purchased",
  };
  if (currentHistoryActionType == "purchased" && investmentType == "fund")
    return "Commitment";

  return originTypes[currentHistoryActionType];
}
