import React from "react";
import CompaniesDefaultTable from "components_new/views/CompanyDirectory/CompaniesDefaultTable";
import CompaniesInvestorTable from "components_new/views/CompanyDirectory/CompaniesInvestorTable";
import CompaniesPartnerTable from "components_new/views/CompanyDirectory/CompaniesPartnerTable";

function CompaniesTable(props) {
  if (props.type == "investors") {
    return <CompaniesInvestorTable {...props} />;
  } else if (props.type == "partners") {
    return <CompaniesPartnerTable {...props} />;
  } else {
    return <CompaniesDefaultTable {...props} />;
  }
}

export default CompaniesTable;
