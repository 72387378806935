import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { isEqual, isEmpty } from "lodash";
import FormElement from "components/shared/form/FormElement";
import AutocompleteCurrentSelection from "components/shared/form/autocomplete/AutocompleteCurrentSelection";
import SelectPopupManager from "components/shared/form/select/SelectPopupManager";
import reactDomContains from "lib/reactDomContains";
import { inputPropsPropType } from "PropTypes";
import filterOptions from "lib/shared/form/select/filterOptions";
import verifyValidText from "lib/verifyValidText";
import "./Autocomplete.scss";

class Autocomplete extends Component {
  state = {
    displayValue: this.props.value || "",
    filteredOptions: this.props.options,
    isOpen: false,
    preSelectedOption: null,
    selectedOption: null,
  };
  selectPopupManagerRef = null;

  outEventListener = ({ target }) => {
    const { isOpen } = this.state;

    if (!isOpen && !this.selectPopupManagerRef) {
      const outside = !reactDomContains(this.selectPopupManagerRef, target);

      if (outside) {
        this.toggleSelect();
      }
    }
  };

  handleClickOption = (selectedOption) => {
    this.updateSelectedOption(selectedOption, () => {
      this.toggleSelect();
    });
  };

  handleMouseOverOption = (option) => {
    this.updatePreSelectedOption(option);
  };

  handleInputChange = (value) => {
    const isValueValid = verifyValidText(value);

    if (!isValueValid) {
      this.setState({ displayValue: "", filteredOptions: [] }, () =>
        this.toggleSelect(false),
      );
      return;
    }

    const filteredOptions = filterOptions(value, this.props.options);
    this.setState(
      {
        displayValue: value,
        filteredOptions,
      },
      () => {
        const { isOpen } = this.state;
        if (isEmpty(filteredOptions)) {
          this.toggleSelect(false);
        } else if (!isOpen) {
          this.toggleSelect(true);
        }
      },
    );
  };

  updatePreSelectedOption = (option, callback) => {
    this.setState({ preSelectedOption: option }, callback);
  };

  updateSelectedOption = (option, callback) => {
    const { placeholder } = this.props;
    this.setState(
      {
        preSelectedOption: option,
        selectedOption: option,
        displayValue: option ? option.label : placeholder,
      },
      callback,
    );
  };

  updateDisplayValue = (displayValue) => {
    this.setState({ displayValue });
  };

  toggleSelect = (isOpen = !this.state.isOpen) => {
    this.setState({ isOpen, selectedOption: null, preSelectedOption: null });
  };

  componentDidMount() {
    window.document.addEventListener("click", this.outEventListener);
  }

  componentDidUpdate(prevProps, prevState) {
    const { displayValue: prevDisplayValue } = prevState;
    const { displayValue: currentDisplayValue } = this.state;
    const { onChange } = this.props;
    if (prevDisplayValue !== currentDisplayValue && onChange) {
      onChange(currentDisplayValue);
    }
  }

  componentWillUnmount() {
    window.document.removeEventListener("click", this.outEventListener);
  }

  UNSAFE_componentWillReceiveProps({ options: nextOptions, value }) {
    if (!isEqual(nextOptions, this.props.options)) {
      this.setState({ filteredOptions: nextOptions });
    }
    if (value && value !== this.state.displayValue) {
      this.setState({ displayValue: value });
    }
  }

  render() {
    const {
      autoFocus,
      errorDisplayValidation,
      errorMessage,
      extraClassnames,
      formatItemContent,
      inputPlaceholder,
      isOptional,
      label,
      name,
      size,
      tabIndex,
    } = this.props;
    const {
      displayValue,
      isOpen,
      filteredOptions,
      selectedOption,
      preSelectedOption,
    } = this.state;
    const formElementClassnames = classnames("FormElement--autocomplete", {
      [extraClassnames]: extraClassnames,
      [size]: !!size,
      "has-errors": !!errorMessage,
    });
    const selectClassnames = classnames(
      "Autocomplete js-stopGlobalShortcutPropagation",
      {
        [`Autocomplete--${size}Size`]: true,
        "is-open": isOpen,
      },
    );
    return (
      <FormElement
        {...(errorDisplayValidation && { errorDisplayValidation })}
        extraClassnames={formElementClassnames}
        isOptional={isOptional}
        label={label}
        name={name}
      >
        <SelectPopupManager
          autocomplete
          changeFocusOnEnterOrEscape={false}
          displayValue={displayValue}
          extraClassnames={selectClassnames}
          isOpen={isOpen}
          lastQuery={displayValue}
          openWithKeyDown={false}
          options={filteredOptions}
          popupProps={{
            formatItemContent,
            supportsNoResultsMessage: false,
            onClickOption: this.handleClickOption,
            onMouseOverOption: this.updatePreSelectedOption,
            preSelectedOption,
          }}
          preSelectedOption={preSelectedOption}
          ref={(selectPopupManagerRef) =>
            (this.selectPopupManagerRef = selectPopupManagerRef)
          }
          selectedOption={selectedOption}
          tabIndex={tabIndex}
          toggleSelect={this.toggleSelect}
          updateDisplayValue={this.updateDisplayValue}
          updatePreSelectedOption={this.updatePreSelectedOption}
          updateSelectedOption={this.updateSelectedOption}
        >
          <AutocompleteCurrentSelection
            displayValue={displayValue}
            errorMessage={errorMessage}
            inputPlaceholder={inputPlaceholder}
            inputRef={(inputRef) => (this.inputRef = inputRef)}
            onInputChange={this.handleInputChange}
            errorMessage={errorMessage}
            autoFocus={autoFocus}
            size={size}
          />
        </SelectPopupManager>
      </FormElement>
    );
  }
}

Autocomplete.propTypes = {
  autoFocus: PropTypes.bool,
  errorMessage: PropTypes.string,
  errorDisplayValidation: PropTypes.func,
  extraClassnames: PropTypes.string,
  formatItemContent: PropTypes.func,
  inputPlaceholder: PropTypes.string,
  options: inputPropsPropType.isRequired,
  isOptional: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  newOptionLabel: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(["tiny", "medium", "small"]),
  tabIndex: PropTypes.string,
  value: PropTypes.string,
};

Autocomplete.defaultProps = {
  autoFocus: false,
  errorDisplayValidation: null,
  errorMessage: null,
  extraClassnames: null,
  formatItemContent: ({ label }) => label,
  grouped: false,
  inputPlaceholder: "",
  options: [],
  isOptional: false,
  label: null,
  name: "",
  newOptionLabel: "Option",
  onChange: null,
  placeholder: "",
  tabIndex: "0",
  size: "medium",
  supportsNewOptionCreation: false,
};

export default Autocomplete;
