import React from "react";
import Text from "components_new/atoms/Text";
import SnapshotItemSearch from "../SnapshotItemSearch";
import "./AddItemForm.scss";

const TYPE_TO_LABEL = {
  company: "Company",
  deal: "Deal",
  contact: "Contact",
  co_investment: "Company",
};

const AddItemForm = ({ sectionId, addItem, searchForItem, snapshotType }) => {
  return (
    <div className="AddItemForm">
      <Text type="body-1-bold">Add {TYPE_TO_LABEL[snapshotType]}</Text>
      <div className="AddItemForm-FieldGroup">
        <div className="AddItemForm-SnapshotItemSearch">
          <SnapshotItemSearch
            size="tiny"
            onChange={(item) => addItem({ item, sectionId })}
            searchForItem={searchForItem}
            type={snapshotType}
          />
        </div>
      </div>
    </div>
  );
};

export default AddItemForm;
