import React, { createContext, useCallback, useState } from "react";
import { useQuery } from "react-query";
import Api from "lib/Api";
import Cookies from "js-cookie";
import loadTermsMapper from "initialization/loadTermsMapper";
import { noop } from "lodash";
import { useDispatch } from "react-redux";
import { setCurrentOrganization } from "store/organizations/actions";
import { clearCollectionV2 } from "store/resourcesV2";
import { useNavigate } from "react-router-dom";

const AuthenticationContext = createContext({
  currentUser: null,
  setCurrentUser: noop,
  persistAuthentication: noop,
  logout: noop,
});

const COOKIE_KEYS = {
  auth: "auth",
  asyncAuth: "async_auth",
};

function getCookieHeaders() {
  const { id, token } = Cookies.getJSON(COOKIE_KEYS.auth) || {};
  const { token: asyncToken } = Cookies.getJSON(COOKIE_KEYS.asyncAuth) || {};

  return {
    "X-Auth-Id": id,
    "X-Auth-Token": token,
    "X-Async-Auth-Token": asyncToken,
  };
}

function clearCookies({ keys = ["auth", "asyncAuth"] } = {}) {
  keys.forEach((key) => {
    Cookies.remove(COOKIE_KEYS[key]);
  });
}

function persistAuthentication({ key = "auth", id, token, expirationDate }) {
  const secure = process.env.NODE_ENV === "production";
  Cookies.set(
    COOKIE_KEYS[key],
    {
      id,
      token,
      expires: expirationDate,
    },
    { expires: expirationDate, secure },
  );
  configureApiHeaders();
}

function configureApiHeaders() {
  const cookieHeaders = getCookieHeaders();
  Api.configure({
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...cookieHeaders,
    },
  });
}

function clearApiHeaders() {
  Api.configure({
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

function AuthenticationContextProvider({ children, defaultCurrentUser }) {
  const [currentUser, currentUserSetter] = useState(defaultCurrentUser);

  const [isWaitingForMfaCode, setIsWaitingForMfaCode] = useState(false);
  const [mfaToken, setMfaToken] = useState(null);
  const [mfaNextPath, setMfaNextPath] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const setCurrentUser = useCallback((user, { mfa = null } = {}) => {
    if (mfa) {
      setIsWaitingForMfaCode(true);
      setMfaToken(mfa.token);
      setMfaNextPath(mfa.nextPath);
    }
    currentUserSetter(user);
  }, []);

  const completeMfaAuthentication = useCallback(() => {
    setIsWaitingForMfaCode(false);
    setMfaToken(null);
    setMfaNextPath(null);
  }, []);

  const validateCurrentAuthentication = useCallback(async () => {
    if (defaultCurrentUser) {
      return { valid: true, user: defaultCurrentUser };
    }

    const cookieHeaders = getCookieHeaders();

    if (!cookieHeaders["X-Auth-Id"]) {
      return { valid: false };
    }

    try {
      const response = await Api.get("/session", {
        headers: cookieHeaders,
        withoutOrganization: true,
      });
      return { valid: true, user: response.data.user };
    } catch (error) {
      return { valid: false };
    }
  }, [defaultCurrentUser]);

  const logout = useCallback(() => {
    // Doesn't wait for session to be deleted to improve the perceived performance
    Api.delete("session", { withoutOrganization: true });
    clearCookies();
    clearApiHeaders();
    setCurrentUser(null);
    dispatch(setCurrentOrganization({ value: null }));
    dispatch(clearCollectionV2("organizations"));
    navigate("/", { state: { nextPathName: null } });
  }, [setCurrentUser, dispatch, navigate]);

  const { isLoading } = useQuery(
    "currentAuthenticationValidation",
    validateCurrentAuthentication,
    {
      refetchOnWindowFocus: false,
      onSettled: (data) => {
        if (data) {
          const { valid, user } = data;

          if (valid) {
            setCurrentUser(user);
            configureApiHeaders();

            if (!defaultCurrentUser) {
              loadTermsMapper();
            }
          } else {
            setCurrentUser(null);
            clearApiHeaders();
            clearCookies();
          }
        }
      },
    },
  );

  if (isLoading && !defaultCurrentUser) {
    // TODO: Show something better
    return null;
  }

  return (
    <AuthenticationContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        persistAuthentication,
        clearCookies,
        logout,
        isWaitingForMfaCode,
        mfaToken,
        mfaNextPath,
        completeMfaAuthentication,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}

export default AuthenticationContext;
export { AuthenticationContextProvider };
