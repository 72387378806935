import Api from "lib/Api";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

const urlByTab = {
  portfolio: "/portfolio",
  investors: "/investors",
  partners: "/partners",
};

function buildUrl(type) {
  const activeTab = urlByTab[type] || "";
  return "/v2/companies" + activeTab;
}

export function useCompanies(type, filters = {}) {
  const { organizationUsername } = useParams();
  const url = buildUrl(type);

  return useQuery(
    ["Companies", organizationUsername, type, filters],
    async () => {
      const {
        data: { companies, meta },
      } = await Api.get(url, { params: filters });

      return {
        companies,
        headquarters: meta.headquarters,
        totalCompanies: meta.total_companies,
        coInvestors: meta.co_investors,
      };
    },
    {
      staleTime: 5000,
    },
  );
}
