import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Table from "components/shared/Table";
import CapitalCallRowTemplate from "./CapitalCallRowTemplate";
import {
  getInitialStateData,
  createCapitalCallColumns,
  canLockItem,
  getEmptyCapitalCallRow,
} from "./config";
import {
  formatPriceInputPreview,
  formatNumberInputPreview,
} from "lib/abbreviatedFormat";
import { capitalCallPropType, investmentPropType } from "PropTypes";
import getTotalPaidInValues from "lib/getTotalPaidIn";
import getMessage from "components_new/lib/Messages";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";

function CapitalCallTable({
  investment,
  collection,
  isFetching,
  isUpdating,
  bulkUpdate,
  isOnModal,
  onResize,
  submitPortalSelector,
  viewMoreTooltipContent,
  openAttachCapitalCallModal,
  fetchAfterUpdateDocuments,
  openGlobalCapitalCallSidebarForm,
}) {
  const [totalPaidInColumn, setTotalPaidInColumn] = useState(
    getTotalPaidInValues(collection),
  );

  const columns = useMemo(
    () => createCapitalCallColumns(investment, collection.length),
    [collection.length, investment],
  );

  useEffect(() => {
    setTotalPaidInColumn(getTotalPaidInValues(collection));
  }, [collection]);

  const handleCollectionChange = (collection) => {
    setTotalPaidInColumn(getTotalPaidInValues(collection));
  };

  const footerDescription = `${formatNumberInputPreview(
    investment.percent_called,
  )}% of initial commitment`;
  const footerAmount = formatPriceInputPreview(investment.capital_called);
  const { addToast } = useSetToast();
  return (
    <Table
      onCreate={openGlobalCapitalCallSidebarForm}
      onResize={onResize}
      columns={columns}
      submitPortalSelector={submitPortalSelector}
      title="Capital Call History"
      deleteTitle="Delete this capital call?"
      updateButtonText="Add"
      collection={collection}
      investment={investment}
      isFetching={isFetching}
      isUpdating={isUpdating}
      canLockItem={canLockItem}
      bulkUpdate={bulkUpdate}
      isOnModal={isOnModal}
      rowInitialState={getInitialStateData(collection)}
      emptyItem={getEmptyCapitalCallRow()}
      viewMoreTooltipContent={viewMoreTooltipContent}
      showFooter={true}
      footerAmount={footerAmount}
      footerDescription={footerDescription}
      onCollectionChange={handleCollectionChange}
      onDeleteRow={() => addToast(getMessage("deleteCapitalCall"))}
      renderRow={(childrenProps) => (
        <CapitalCallRowTemplate
          totalPaidIn={totalPaidInColumn}
          investment={investment}
          openAttachCapitalCallModal={openAttachCapitalCallModal}
          fetchAfterUpdateDocuments={fetchAfterUpdateDocuments}
          {...childrenProps}
        />
      )}
    />
  );
}

CapitalCallTable.propTypes = {
  investment: investmentPropType,
  collection: PropTypes.arrayOf(capitalCallPropType),
  bulkUpdate: PropTypes.func,
  openAttachCapitalCallModal: PropTypes.func,
  fetchAfterUpdateDocuments: PropTypes.func,
  isFetching: PropTypes.bool,
  isUpdating: PropTypes.bool,
  isOnModal: PropTypes.bool,
  onResize: PropTypes.func,
  submitPortalSelector: PropTypes.string,
  viewMoreTooltipContent: PropTypes.string,
  openGlobalCapitalCallSidebarForm: PropTypes.func,
};

CapitalCallTable.defaultProps = {
  isFetching: false,
  isUpdating: false,
  isOnModal: false,
};

export default CapitalCallTable;
