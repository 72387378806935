import React, { useContext, useEffect, useMemo } from "react";
import FilesForm from "./FilesForm";
import {
  buildPrefilledInvestmentData,
  buildPrefilledCompanyData,
} from "lib/globalActionsHelper";
import { SidebarComponentsContext } from "components_new/SidebarComponents/SidebarComponentsWrapper";
import useDocuments from "store/documents/hooks";
import { isEmpty } from "lodash";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import getMessage from "components_new/lib/Messages";
import useOrganizations from "store/organizations/hooks";
import Loader from "components/shared/Loader";

import { useDeal } from "components_new/views/Deals/hooks/queries/useDeal";
import { useCompany } from "components_new/views/Company/hooks/queries/useCompany";
import { useInvestment } from "components_new/SidebarComponents/views/InvestmentView/hooks/useInvestment";

function FilesFormWrapper({ ownName }) {
  const { getComponentState, registerForm, closeSidebar } = useContext(
    SidebarComponentsContext,
  );
  const { addToast } = useSetToast();
  const { uploadDocuments } = useDocuments();
  const {
    currentOrganizationUsername,
    currentOrganization: { pipeline_id: pipelineId },
  } = useOrganizations();

  const {
    initialHoldingId,
    initialCompanyId,
    initialDealId,
    taskId,
    taskAttachments,
  } = getComponentState(ownName);

  useEffect(() => {
    if (!isEmpty(ownName)) {
      registerForm(ownName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data: investmentData,
    isLoading: isLoadingInvestment,
  } = useInvestment(initialHoldingId);

  const { data: dealData, isLoading: isLoadingDeal } = useDeal(
    pipelineId,
    initialDealId,
  );

  const { data: companyData, isLoading: isLoadingCompany } = useCompany(
    initialCompanyId,
  );

  const prefilledSelection = useMemo(() => {
    if (initialCompanyId) {
      return buildPrefilledCompanyData(companyData?.companyDetails);
    }

    if (initialHoldingId) {
      return buildPrefilledInvestmentData(
        investmentData?.details,
        investmentData?.company,
      );
    }

    if (initialDealId) {
      return {
        ...dealData?.deal,
        type: "deal",
      };
    }
  }, [
    initialCompanyId,
    initialDealId,
    initialHoldingId,
    companyData,
    dealData,
    investmentData,
  ]);

  const showConfirmationMessage = () => addToast(getMessage("addedFiles"));

  if (isLoadingCompany || isLoadingInvestment || isLoadingDeal) {
    return <Loader />;
  }

  return (
    <FilesForm
      ownName={ownName}
      prefilledSelection={prefilledSelection}
      taskId={taskId}
      taskAttachments={taskAttachments}
      uploadDocuments={uploadDocuments}
      closeSidebar={closeSidebar}
      showConfirmationMessage={showConfirmationMessage}
      currentOrganizationUsername={currentOrganizationUsername}
    />
  );
}
export default FilesFormWrapper;
