import React from "react";
import Table from "components/shared/Table";
import { createColumns, emptyDocumentItem } from "./config";
import DocumentRow from "./DocumentRow";
import getMessage from "components_new/lib/Messages";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";

const columns = createColumns();
const emptyItem = emptyDocumentItem();

function DocumentsTable({
  bulkUpdate,
  documents,
  isUpdating,
  openAttachmentDocument,
  currentOrganizationUsername,
  behaviorUpdateButton,
}) {
  const { addToast } = useSetToast();
  return (
    <div className="DocumentsTable">
      <Table
        bulkUpdate={bulkUpdate}
        collection={documents}
        columns={columns}
        deleteButtonText="Delete File"
        deleteTitle="Delete this file?"
        emptyItem={emptyItem}
        isUpdating={isUpdating}
        onCreate={openAttachmentDocument}
        renderRow={(props) => (
          <DocumentRow
            currentOrganizationUsername={currentOrganizationUsername}
            {...props}
          />
        )}
        rounded={true}
        title="Files"
        updateButtonExtraClassnames="Button--action-over-body"
        updateButtonText="Add Files"
        wideTitle={true}
        behaviorUpdateButton={behaviorUpdateButton}
        onDeleteRow={() => addToast(getMessage("deleteFile"))}
      />
    </div>
  );
}

export default DocumentsTable;
