import PropTypes from "prop-types";
import React, { Component } from "react";
import classNames from "classnames";
import FormElement from "components/shared/form/FormElement";
import { noop } from "lodash";

const SMALL_TEXT_AREA_CONFIG = {
  rows: 1,
};

class TextArea extends Component {
  state = { textAreaInitialHeight: null, value: this.props.value };

  static propTypes = {
    autoFocus: PropTypes.bool,
    extraClassnames: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    rows: PropTypes.string,
    cols: PropTypes.string,
    defaultValue: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    isOptional: PropTypes.bool,
    errorDisplayValidation: PropTypes.func,
    value: PropTypes.string,
    readOnly: PropTypes.bool,
    showLock: PropTypes.bool,
    size: PropTypes.string,
    textHelper: PropTypes.string,
  };

  static defaultProps = {
    autoFocus: false,
    extraClassnames: null,
    disabled: false,
    required: false,
    errorMessage: null,
    label: null,
    className: null,
    rows: null,
    cols: null,
    onChange: null,
    onBlur: noop,
    placeholder: "",
    isOptional: false,
    errorDisplayValidation: null,
    readOnly: false,
    showLock: false,
    value: undefined,
    size: "medium",
    textHelper: null,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.value,
    });
  }

  componentDidMount() {
    setTimeout(() => {
      const textarea = this.textarea;
      if (textarea) {
        const textareaHeight = this.props.defaultValue
          ? textarea.scrollHeight
          : textarea.offsetHeight;

        const textareaPadding =
          textarea.scrollHeight > textarea.offsetHeight
            ? parseFloat(
                window
                  .getComputedStyle(textarea, null)
                  .getPropertyValue("padding-bottom"),
              ) / 2
            : 0;

        this.setState({
          textAreaInitialHeight: textarea.offsetHeight,
        });
        textarea.style.height = textareaHeight + textareaPadding + "px";
      }
    }, 500);
  }

  itemChange(e) {
    const value = e.target.value;
    this.setState({ value });
    this.autoResizeTextarea();

    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  handleBlur = (event) => {
    this.props.onBlur(event);
  };

  handleKeyDown = () => {
    if (!this.props.disabled) {
      this.autoResizeTextarea();
    }
  };

  autoResizeTextarea() {
    const textarea = this.textarea;
    const { textAreaInitialHeight } = this.state;
    if (
      textAreaInitialHeight &&
      textarea.scrollHeight > textAreaInitialHeight
    ) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight + 4}px`;
      textarea.scrollTop = textarea.scrollHeight;
    }
  }

  render() {
    const {
      errorMessage,
      extraClassnames,
      placeholder,
      disabled,
      cols,
      defaultValue,
      label,
      isOptional,
      errorDisplayValidation,
      autoFocus,
      readOnly,
      showLock,
      textHelper,
      size,
    } = this.props;
    const { id, value } = this.state;
    const formElementClassnames = classNames(
      "FormElement FormElement--textarea",
      {
        "has-readOnly": readOnly,
        [extraClassnames]: !!extraClassnames,
        [size]: !!size,
      },
    );

    const rows = this.props.rows || SMALL_TEXT_AREA_CONFIG["rows"];

    return (
      <FormElement
        extraClassnames={formElementClassnames}
        label={label}
        isOptional={isOptional}
        errorMessage={errorMessage}
        name={name}
        showLock={showLock}
        textHelper={textHelper}
        {...(errorDisplayValidation && { errorDisplayValidation })}
      >
        <textarea
          id={id}
          className="Textarea"
          disabled={disabled}
          placeholder={placeholder}
          rows={rows}
          cols={cols}
          value={value}
          defaultValue={defaultValue}
          ref={(textarea) => {
            this.textarea = textarea;
          }}
          onBlur={this.handleBlur}
          onChange={(event) => this.itemChange(event)}
          onKeyDown={this.handleKeyDown}
          autoFocus={autoFocus}
          readOnly={readOnly}
        />
      </FormElement>
    );
  }
}

export default TextArea;
