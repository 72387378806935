import React, { useCallback, useEffect, useState } from "react";
import Segment from "components_new/atoms/Segment";
import { DebouncedSearchInput } from "components_new/atoms/SearchInput";
import PropTypes from "prop-types";
import { tagSuggestionsProptype } from "PropTypes";
import { isEmpty, pickBy, omitBy } from "lodash";
import DownloadCsvButton from "components_new/elements/DownloadCsvButton";
import { SkeletonStatLine } from "components_new/elements/SummaryStats/SummaryStatsSkeleton";
import QuickFilterButtonGroup from "./QuickFilterButtonGroup";
import TableFilter from "./Filters";
import CompanyInvestorFilter from "./Filters/CompanyInvestorFilter";
import CompanyPartnerFilter from "./Filters/CompanyPartnerFilter";
import ViewStyleSelector from "./ViewStyleSelector";
import { pluck } from "ramda";
import ItemsCounter from "components_new/elements/ItemsCounter";
import CreateSnapshotButton from "components_new/elements/CreateSnapshotButton";

const MAX_COMPANIES_PER_CALL = 250;

export default function TopBar({
  updateFilters,
  totalCompanies,
  coInvestors,
  curentCompanies,
  type,
  filterTags,
  tagSuggestions,
  headquarters,
  isLoading,
  viewStyle,
  setViewStyle,
  filterIndustries,
  industriesSuggestions,
  filters,
}) {
  const [searchText, setSearchText] = useState("");
  const subCollection = type !== "all" ? `/${type}` : "";

  useEffect(() => {
    updateFilters((filters) =>
      omitBy({ ...filters, query: searchText }, isEmpty),
    );
  }, [searchText, updateFilters]);

  const applyFilters = useCallback(
    (appliedFilters) => {
      const {
        status,
        tags: tagObjects,
        headquarter,
        tier,
        industries: industriesObjects = {},
        investment_domain,
        service_domain,
        co_investor,
      } = appliedFilters;
      const statuses = Object.keys(pickBy(status));
      const tags = pluck("name", tagObjects);
      const industries = pluck("name", industriesObjects);
      const newFilters = {
        statuses,
        tags,
        headquarter,
        tier,
        industries,
        investment_domain,
        service_domain,
        co_investor,
      };
      updateFilters((currentFilters) =>
        omitBy({ ...currentFilters, ...newFilters }, isEmpty),
      );
    },
    [updateFilters],
  );

  const applyQuickFilter = useCallback(
    (quickFilters) => {
      updateFilters((currentFilters) =>
        omitBy({ ...currentFilters, ...quickFilters }, isEmpty),
      );
    },
    [updateFilters],
  );

  return (
    <Segment extraClassNames="TopBar">
      <Segment
        attached="top"
        transparent
        clearing
        extraClassNames="TopBar-FilterRow"
      >
        <span className="CompanyCount u-bodySmallBold">
          {isLoading ? (
            <SkeletonStatLine className="Stat-normalLine" />
          ) : (
            <ItemsCounter
              title="company"
              totalItems={totalCompanies}
              currentItems={curentCompanies}
              maxItemsPerCall={MAX_COMPANIES_PER_CALL}
            />
          )}
        </span>
        <DebouncedSearchInput
          onChange={setSearchText}
          className="TopBar-item"
          placeholder="Search companies"
          value={searchText}
        />
        {["all", "portfolio"].includes(type) && (
          <TableFilter
            onApplyFilters={applyFilters}
            values={filters}
            className="TopBar-item"
            filterTags={filterTags}
            tagSuggestions={tagSuggestions}
            headquarters={headquarters}
            coInvestors={coInvestors}
          />
        )}
        {type === "investors" && (
          <CompanyInvestorFilter
            onApplyFilters={applyFilters}
            values={filters}
            className="TopBar-item"
            filterTags={filterTags}
            tagSuggestions={tagSuggestions}
            headquarters={headquarters}
            filterIndustries={filterIndustries}
            industriesSuggestions={industriesSuggestions}
          />
        )}
        {type === "partners" && (
          <CompanyPartnerFilter
            onApplyFilters={applyFilters}
            values={filters}
            className="TopBar-item"
            filterTags={filterTags}
            tagSuggestions={tagSuggestions}
            headquarters={headquarters}
            filterIndustries={filterIndustries}
            industriesSuggestions={industriesSuggestions}
          />
        )}
        {["all", "portfolio"].includes(type) && (
          <QuickFilterButtonGroup
            applyFilters={applyQuickFilter}
            filters={filters}
          />
        )}
        <ViewStyleSelector setViewStyle={setViewStyle} viewStyle={viewStyle} />
        <DownloadCsvButton
          className="TopBar-item"
          collection={`companies${subCollection}`}
          params={filters}
        />
        <CreateSnapshotButton
          className="TopBar-item"
          kind="secondary"
          snapshotType="company"
          size="small"
        />
      </Segment>
    </Segment>
  );
}

TopBar.propTypes = {
  totalCompanies: PropTypes.number,
  curentCompanies: PropTypes.number,
  type: PropTypes.string,
  currentTab: PropTypes.string,
  updateFilters: PropTypes.func,
  filterTags: PropTypes.func,
  headquarters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  isLoading: PropTypes.bool,
  tagSuggestions: tagSuggestionsProptype,
  setViewStyle: PropTypes.func,
  viewStyle: PropTypes.string,
  filters: PropTypes.shape({}),
  filterIndustries: PropTypes.func,
  coInvestors: PropTypes.arrayOf(PropTypes.string),
  industriesSuggestions: tagSuggestionsProptype,
};
