import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import EmptyCard from "components_new/elements/EmptyCard";
import { isEmpty } from "lodash";

const FlatList = ({
  title,
  data,
  renderItem,
  emptyTitle,
  nilLabel,
  renderEmpty,
  extraClassNames,
}) => {
  const flatListClassNames = classNames("FlatList", {
    [extraClassNames]: !!extraClassNames,
  });
  if (isEmpty(data)) {
    return (
      <Empty
        title={emptyTitle || title}
        nilLabel={nilLabel}
        renderEmpty={renderEmpty}
      />
    );
  }
  return (
    <div className={flatListClassNames}>
      {data.map((item) => renderItem(item))}
    </div>
  );
};

FlatList.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  renderItem: PropTypes.func,
  emptyTitle: PropTypes.string,
  nilLabel: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  renderEmpty: PropTypes.func,
  extraClassNames: PropTypes.string,
};

/* Default Empty
======================================== */
function Empty({ title, nilLabel, renderEmpty }) {
  if (renderEmpty) {
    return renderEmpty();
  }
  return title ? <EmptyCard title={title} nilLabel={nilLabel} /> : null;
}

Empty.propTypes = {
  title: PropTypes.string,
  nilLabel: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  renderEmpty: PropTypes.func,
};

export default FlatList;
