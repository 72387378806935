import React from "react";
import { Modal as SemanticModal } from "@ableco/semantic-ui-react";
import classNames from "classnames";
import "semantic-ui-css/components/modal.css";

function ModalContent({ extraClassnames, children, ...props }) {
  const modalContentClassnames = classNames("Modal-content", extraClassnames);
  return (
    <SemanticModal.Content className={modalContentClassnames} {...props}>
      {children}
    </SemanticModal.Content>
  );
}
export default ModalContent;
