import React from "react";
import InvestmentActions from "components/investments/InvestmentActions";
import classnames from "classnames";

function Actions({ investment, company }) {
  const componentClassnames = classnames("InvestmentActionsDropdown", {
    "has-fundActions": investment.type === "fund",
  });
  return (
    <div className="InvestmentDetails-actions">
      <InvestmentActions
        investment={investment}
        company={company}
        dropdownPlaceholder={<span>Actions</span>}
        mainClassName={componentClassnames}
        source="holding"
      />
    </div>
  );
}

export default Actions;
