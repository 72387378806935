import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import Icon from "components_new/atoms/Icon";
import Button from "components_new/atoms/Button";
import Api from "lib/Api";
import "./DownloadCsvButton.scss";
import classnames from "classnames";

// TODO: Remove this loader when we have a new one.
import Spinner from "components/shared/Spinner";

const REQUEST_OPTIONS = {
  parseResponse: false,
  headers: {
    Accept: "text/csv",
    "Content-Type": "text/csv",
  },
};

// Index where the filename starts in the Content-Disposition header
const FILENAME_START = 22;

function createDownloadableLink(data, fileName) {
  const a = document.createElement("a");
  a.setAttribute("href", `data:text/csv;charset=utf-8,${encodeURI(data)}`);
  a.setAttribute("download", fileName);
  return a;
}

function setDownloadPath(collection, params) {
  return (
    `/v2/${collection}` +
    (params.pipeline_id ? `/${params.pipeline_id}` : "") +
    ".csv"
  );
}

function extractFileName(contentDisposition) {
  const fileName = contentDisposition.slice(FILENAME_START, -1);
  return fileName;
}

function DownloadCsvButton({ collection, params = {}, className }) {
  const [isFetching, setIsFetching] = useState(false);

  const downloadCSV = () => {
    setIsFetching(true);

    if (params.portfolio_id === "all") {
      delete params.portfolio_id;
    }

    const path = setDownloadPath(collection, params);
    Api.get(path, {
      ...REQUEST_OPTIONS,
      params: params,
    })
      .then(({ data, response }) => {
        const contentDisposition = response.headers.get("Content-Disposition");
        const fileName = extractFileName(contentDisposition);
        const downloadableLink = createDownloadableLink(data, fileName);
        downloadableLink.click();
      })
      .finally(() => setIsFetching(false));
  };

  const downloadCsvButtonClassnames = classnames("DownloadCsvButton icon", {
    [className]: !!className,
  });

  return (
    <Button
      className={downloadCsvButtonClassnames}
      onClick={downloadCSV}
      disabled={isFetching}
      size="small"
      secondary
    >
      {isFetching ? (
        <Spinner />
      ) : (
        <Fragment>
          <Icon icon="ri-download-line" size="mini" />
          <span className="caption">Download CSV</span>
        </Fragment>
      )}
    </Button>
  );
}

DownloadCsvButton.prototypes = {
  collection: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default DownloadCsvButton;
