import React from "react";
import Loader from "components/shared/Loader";
import SnapshotForm from "./SnapshotForm";

function SnapshotFormWrapper({
  isLoading,
  isEditing,
  snapshot,
  submitAction,
  showConfirmationMessage,
  snapshotType,
}) {
  if (isLoading) {
    return <Loader />;
  }

  return (
    <SnapshotForm
      isEditing={isEditing}
      snapshot={snapshot}
      submitAction={submitAction}
      showConfirmationMessage={showConfirmationMessage}
      snapshotType={snapshotType}
    />
  );
}

export default SnapshotFormWrapper;
