import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useOrganizations from "store/organizations/hooks";

function OrganizationRoot() {
  const navigate = useNavigate();
  const { currentOrganization } = useOrganizations();

  useEffect(() => {
    navigate(`/${currentOrganization.username}/dashboard`);
  }, [navigate, currentOrganization]);

  return null;
}

export default OrganizationRoot;
