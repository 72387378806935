import React, { Fragment } from "react";
import Image from "components_new/atoms/Image";
import MightyIcon from "components_new/atoms/MightyIcon";
import classnames from "classnames";
import "./LogoGroup.scss";
import { times } from "lodash";
import pluralize from "pluralize";

function LogoComponent({ logoUrl }) {
  const image = logoUrl ? (
    <Image src={logoUrl} />
  ) : (
    <MightyIcon icon="company-sm" />
  );
  return <div className="LogoGroup-logos--item">{image}</div>;
}

function Logos({ logoUrls }) {
  return logoUrls.map((logoUrl, index) => (
    <LogoComponent logoUrl={logoUrl} key={index} />
  ));
}

function LogosWithOverflowCount({ logoUrls, companyCount }) {
  const overflowItemsCount = companyCount - 2;

  return (
    <>
      {times(2, (index) => (
        <LogoComponent logoUrl={logoUrls[index]} key={index} />
      ))}
      <div className="LogoGroup-logos--item OverflowItemsCounter-number u-captionBlack">
        {`+${overflowItemsCount}`}
      </div>
    </>
  );
}

function LogoGroup({ items }) {
  const logoGroupClassnames = classnames("LogoGroup", {
    "LogoGroup--hasLogos": items.companies_count >= 1,
  });

  return (
    <div className={logoGroupClassnames}>
      <Fragment>
        {items.companies_count > 0 && (
          <div className="LogoGroup-logos">
            {items.companies_count <= 3 ? (
              <Logos logoUrls={items.logo_urls} />
            ) : (
              <LogosWithOverflowCount
                logoUrls={items.logo_urls}
                companyCount={items.companies_count}
              />
            )}
          </div>
        )}
        <div className="LogoGroup-content">
          {items.name}
          <div className="LogoGroup-content-counter">
            {pluralize("investment", items.investments_count, true)}
          </div>
        </div>
      </Fragment>
    </div>
  );
}

export default LogoGroup;
