import React from "react";
import DataCard from "components_new/elements/DataCard";
import InvestmentRow from "./InvestmentRow";

export default function CompanyInvestments({
  title = "Company Investments",
  testId = "investmentsGroup",
  companyInvestments,
  companyAvatars,
  portfolios,
  investmentDropdownComponent,
  currentOrganizationUsername,
}) {
  const buildInvestmentURL = (investmentId) =>
    `/${currentOrganizationUsername}/investments/${investmentId}`;
  return (
    <DataCard.Group title={title} data-testid={testId}>
      {companyInvestments.map((investment) => {
        // TODO: Each companyInvestment should have the company data
        // in the presenter in order to avoid calculating in every render
        const companyAvatar = companyAvatars.find(
          (companyAvatar) => companyAvatar.id === investment.company_id,
        );
        const portfolio = portfolios.find(
          (portfolio) => portfolio.id === investment.portfolio_id,
        );
        return (
          <InvestmentRow
            key={`investmentrow-${investment.id}`}
            investment={investment}
            portfolio={portfolio}
            companyAvatar={companyAvatar}
            dropdownComponent={investmentDropdownComponent}
            link={buildInvestmentURL(investment.id)}
          />
        );
      })}
    </DataCard.Group>
  );
}
