import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import EllipsisDropdown from "components_new/elements/EllipsisDropdown";
import Header from "components_new/atoms/Header";
import Image from "components_new/atoms/Image";
import MightyIcon from "components_new/atoms/MightyIcon";
import EmptyCard from "components_new/elements/EmptyCard";
import "./DataCard.scss";

/* DataCard
======================================== */

function DataCard({
  children,
  actionMenuComponent,
  actionMenuOptions,
  onClick,
  extraClassnames,
}) {
  const classNames = classnames("DataCard", {
    [extraClassnames]: !!extraClassnames,
    clickable: !!onClick,
  });
  return (
    <div className={classNames} onClick={onClick}>
      {(actionMenuComponent || actionMenuOptions) && (
        <ActionMenu options={actionMenuOptions}>
          {actionMenuComponent}
        </ActionMenu>
      )}
      {children}
    </div>
  );
}

/* DataCard ActionMenu
======================================== */

function ActionMenu({ children, options }) {
  return (
    <div className="DataCard-ActionMenu" data-testid="ActionMenu">
      {children ||
        (options && (
          <EllipsisDropdown
            iconOrientation="vertical"
            iconSize="tiny"
            options={options}
          />
        ))}
    </div>
  );
}

/* DataCard Compound Components
======================================== */

function Group({ title, children, extraClassnames, ...restProps }) {
  const classNames = classnames("DataCard-Group", {
    [extraClassnames]: !!extraClassnames,
  });
  if (!children || children.length === 0)
    return (
      <EmptyCard
        title={title}
        label={title}
        extraClassnames="DataCard-Group-EmptyState"
        {...restProps}
      />
    );
  return (
    <div className={classNames} {...restProps}>
      {title && (
        <Header extraClassName="DataCard-Group-Title" as="h5">
          {title}
        </Header>
      )}
      {children}
    </div>
  );
}

function Masthead({ children, extraClassnames }) {
  const classNames = classnames("DataCard-Header", {
    [extraClassnames]: !!extraClassnames,
  });
  return (
    <div className={classNames} data-testid="DataCardHeader">
      {children}
    </div>
  );
}

function Avatar({ imageUrl, icon, size, iconAlignment = "standard" }) {
  const avatarClassNames = classnames("DataCard-Avatar", {
    [`DataCard-Avatar-${size}`]: !!size,
    "align-top": iconAlignment === "top",
  });
  const defaultIcon = size && size === "small" ? "company-sm" : "company-med";
  return (
    <div className={avatarClassNames}>
      {imageUrl ? (
        <Image src={imageUrl} extraClassNames="DataCard-Avatar-Icon" />
      ) : (
        <div className="DataCard-Avatar-Icon">
          <MightyIcon icon={icon || defaultIcon} />
        </div>
      )}
    </div>
  );
}

function Content({ children, extraClassnames }) {
  const classNames = classnames("DataCard-Content", {
    [extraClassnames]: !!extraClassnames,
  });
  return (
    <div className={classNames} data-testid="DataCardContent">
      {children}
    </div>
  );
}

DataCard.Group = Group;
DataCard.Content = Content;
DataCard.Header = Masthead;
DataCard.Avatar = Avatar;

/* PropTypes
======================================== */

DataCard.propTypes = {
  children: PropTypes.node,
  actionMenuComponent: PropTypes.node,
  actionMenuOptions: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func,
  extraClassnames: PropTypes.string,
};

ActionMenu.propTypes = {
  children: PropTypes.node,
  options: PropTypes.arrayOf(PropTypes.object),
};

Group.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  extraClassnames: PropTypes.string,
};

Masthead.propTypes = {
  children: PropTypes.node,
  extraClassnames: PropTypes.string,
};

Avatar.propTypes = {
  imageUrl: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium"]),
  iconAlignment: PropTypes.oneOf(["top", "standard"]),
};

Content.propTypes = {
  children: PropTypes.node,
  extraClassnames: PropTypes.string,
};

export default DataCard;
