import React from "react";
import FormGroup from "components_new/elements/FormGroup";
import RadioButtonGroup from "components_new/elements/AdvancedFields/RadioButtonGroup";
import NumberInput from "components_new/atoms/NumberInput";
import PercentageInput from "components_new/atoms/PercentageInput";
import { isEqual } from "lodash";

function LlcAdditionalDetails({ formData, handleChange, errors }) {
  const {
    membership_units_or_percentage,
    quantity,
    percentage_owned,
  } = formData;

  function renderUnitFields(quantity, handleChange) {
    return (
      <NumberInput
        name="quantity"
        label="Number of units in class"
        placeholder="e.g. 100"
        value={quantity}
        size="small"
        onChange={(value) => {
          handleChange("quantity", value);
        }}
        size="tiny"
        error={!!errors.for("quantity")}
        errorMessage={errors.for("quantity")}
      />
    );
  }

  function renderPercentageFields(percentage_owned, handleChange) {
    return (
      <PercentageInput
        name="percentage_owned"
        label="Percentage"
        onChange={(value) => handleChange("percentage_owned", value)}
        value={percentage_owned}
        size="tiny"
        error={!!errors.for("percentage_owned")}
        errorMessage={errors.for("percentage_owned")}
      />
    );
  }

  return (
    <FormGroup
      title="LLC Details"
      extraClassnames="LlcAdditionalDetails"
      isExpanded={false}
      required={false}
      noBottomBorder
      subGroup
    >
      <RadioButtonGroup
        name="membership_units_or_percentage"
        label="Membership units or percentage"
        inputProps={[
          { value: "units", label: "Units" },
          { value: "percentage", label: "Percentage" },
        ]}
        defaultValue={membership_units_or_percentage}
        onChange={(value) =>
          handleChange("membership_units_or_percentage", value)
        }
        value={membership_units_or_percentage}
        size="tiny"
      />
      {isEqual(membership_units_or_percentage, "units") &&
        renderUnitFields(quantity, handleChange, errors)}
      {isEqual(membership_units_or_percentage, "percentage") &&
        renderPercentageFields(percentage_owned, handleChange, errors)}
    </FormGroup>
  );
}

export default LlcAdditionalDetails;
