import React, { useState, useEffect } from "react";
import Icon from "components_new/atoms/Icon";
import classNames from "classnames";
import FilterLoader from "components/shared/FilterLoader";

import { useSetSidebar } from "components_new/elements/SidebarWrapper/Sidebar-context";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";

function SearchBoxInput({
  onChange,
  onKeyDown,
  isFetching,
  resetActions,
  selectedItem,
  name,
  value,
  placeholderText,
}) {
  const [isFocus, setIsFocus] = useState(false);
  const searchBoxInputClassname = classNames("SearchBoxInput", {
    "is-fetching": isFetching,
    "is-focusing": value.length > 0 && isFocus,
  });

  const navigate = useNavigate();
  const { showSidebar } = useSetSidebar();

  useEffect(
    () => {
      if (!isEmpty(selectedItem)) {
        const { meta } = selectedItem;

        if (meta && meta.open_in_sidebar) {
          showSidebar(meta.sidebar_view, meta.sidebar_params);
        } else {
          navigate(selectedItem.path);
        }

        resetActions();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedItem],
  );

  return (
    <div className={searchBoxInputClassname}>
      <Icon icon="ri-search-line" size="mini" />
      <FilterLoader size="small" />
      <input
        spellCheck="false"
        className="InputText"
        placeholder={placeholderText}
        id="js-SearchInput"
        type="text"
        value={value}
        name={name}
        onKeyDown={onKeyDown}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        onChange={(event) => onChange(event.target.value)}
        autoComplete="off"
      />
      <div className="SearchBox-clearButton" onMouseDown={() => resetActions()}>
        <Icon icon="ri-close-line" size="mini" />
      </div>
    </div>
  );
}

export default SearchBoxInput;
