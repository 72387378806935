import Api from "lib/Api";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

export function useRemoveCompanyDocument() {
  const queryClient = useQueryClient();
  const { organizationUsername } = useParams();

  return useMutation(
    ({ document }) => Api.delete(`/documents/${document.id}`),
    {
      onSuccess: (_, { document: deletedDocument, companyId }) => {
        return queryClient.setQueryData(
          ["Company", organizationUsername, companyId],
          (oldData) => ({
            ...oldData,
            companyDocuments: oldData.companyDocuments.filter(
              (document) => document.id !== deletedDocument.id,
            ),
          }),
        );
      },
    },
  );
}
