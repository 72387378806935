import React, { useState, useCallback, useContext } from "react";
import PasswordCheck from "./PasswordCheck";
import LoginInformationView from "./LoginInformation";
import moment from "moment";
import AuthenticationContext from "components/Mighty/AuthenticationContext";

function LoginInformationViewContainer() {
  const [tokenInfo, setTokenInfo] = useState({
    id: null,
    token: null,
    expiresAt: null,
    password: null,
    phoneNumber: null,
  });

  const { persistAuthentication, clearCookies } = useContext(
    AuthenticationContext,
  );

  const updateTokenInfo = useCallback(
    (tokenInfo) => {
      const { id, token, expiresAt } = tokenInfo;
      setTokenInfo(tokenInfo);
      persistAuthentication({
        key: "asyncAuth",
        id,
        token,
        expirationDate: new Date(expiresAt),
      });
    },
    [persistAuthentication],
  );

  const cancelEdit = useCallback(() => {
    setTokenInfo({ token: null, expiresAt: null, phoneNumber: null });
    clearCookies({ keys: ["asyncAuth"] });
  }, [clearCookies]);

  if (tokenInfo.token && moment(tokenInfo.expiresAt).isSameOrAfter(moment())) {
    return (
      <LoginInformationView
        cancelEdit={cancelEdit}
        currentPhoneNumber={tokenInfo.phoneNumber}
        currentPassword={tokenInfo.password}
      />
    );
  } else {
    return <PasswordCheck setTokenInfo={updateTokenInfo} />;
  }
}

export default LoginInformationViewContainer;
