import React, { useContext, useEffect } from "react";
import DistributionForm from "./DistributionForm";
import { SidebarComponentsContext } from "components_new/SidebarComponents/SidebarComponentsWrapper";
import { isEmpty } from "lodash";
import { useInvestment } from "components_new/SidebarComponents/views/InvestmentView/hooks/useInvestment";
import useDistribution from "store/investments/v2/distributions/hooks";
import useOrganizations from "store/organizations/hooks";
import Loader from "components/shared/Loader";

function DistributionFormWithWrapper({ ownName }) {
  const {
    getComponentState,
    resetForm,
    registerForm,
    mountSidebarComponent,
    hideSidebarComponent,
    closeSidebar,
  } = useContext(SidebarComponentsContext);
  const {
    investment,
    componentData,
    taskId,
    taskAttachments,
  } = getComponentState(ownName);

  const {
    currentOrganization: { username: currentOrganizationUserName },
  } = useOrganizations();

  useEffect(() => {
    if (!isEmpty(ownName)) {
      registerForm(ownName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { createDistribution } = useDistribution();
  const { data, isLoading } = useInvestment(investment?.id);

  const handleDistributionCreation = (formData) => {
    return createDistribution(formData, investment.id);
  };

  if (isLoading || isEmpty(investment)) {
    return <Loader />;
  }

  return (
    <DistributionForm
      ownName={ownName}
      company={data?.company}
      investment={data?.details}
      taskId={taskId}
      componentData={componentData}
      taskAttachments={taskAttachments}
      handleDistributionCreation={handleDistributionCreation}
      mountSidebarComponent={mountSidebarComponent}
      hideSidebarComponent={hideSidebarComponent}
      currentOrganizationUserName={currentOrganizationUserName}
      closeSidebar={closeSidebar}
      resetForm={resetForm}
    />
  );
}

export default DistributionFormWithWrapper;
