import PropTypes from "prop-types";
import React from "react";
import CompanyIcon from "components/shared/CompanyIcon";
import CompanyStatusBadge from "components/shared/CompanyLogo/CompanyStatusBadge";
import { companyPropType } from "PropTypes";

function CompanyIconWithBadge({ company, size }) {
  if (!company) {
    return null;
  }

  return (
    <div className="CompanyIconWithBadge">
      <CompanyStatusBadge company={company} size={size} />
      <CompanyIcon
        extraClassnames="u-flexNoShrinkNoGrow"
        src={company.logo_url}
        size={size}
      />
    </div>
  );
}

CompanyIconWithBadge.defaultProps = {
  size: "medium",
};

CompanyIconWithBadge.propTypes = {
  company: companyPropType,
  size: PropTypes.oneOf(["xlarge", "large", "medium", "small"]),
};

export default CompanyIconWithBadge;
