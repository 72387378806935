import React, { useMemo } from "react";
import PropTypes from "prop-types";
import TabMenu from "components_new/elements/TabMenu";
import classNames from "classnames";

function ContentNavigation({
  location,
  routeParams,
  investmentsCount,
  notesCount,
  documentsCount,
  contactsCount,
  dealsCount,
  investorCoInvestments,
  displayFixedContent,
  investorFirm,
}) {
  const companyTabs = useMemo(() => {
    const tabs = [
      {
        name: "Overview",
        active: location.pathname == route(routeParams, "/overview"),
        to: route(routeParams, "/overview"),
        count: -1,
        textOnly: true,
      },
      {
        name: "Investments",
        active: location.pathname == route(routeParams, "/investments"),
        to: route(routeParams, "/investments"),
        count: investmentsCount || 0,
        textOnly: true,
      },
      {
        name: "Deals",
        active: location.pathname == route(routeParams, "/deals"),
        to: route(routeParams, "/deals"),
        count: dealsCount || 0,
        textOnly: true,
      },
      {
        name: "Contacts",
        active: location.pathname == route(routeParams, "/contacts"),
        to: route(routeParams, "/contacts"),
        count: contactsCount || 0,
        textOnly: true,
      },
      {
        name: "Notes",
        active: location.pathname == route(routeParams, "/notes"),
        to: route(routeParams, "/notes"),
        count: notesCount || 0,
        textOnly: true,
      },
      {
        name: "Files",
        active: location.pathname == route(routeParams, "/files"),
        to: route(routeParams, "/files"),
        count: documentsCount || 0,
        textOnly: true,
      },
    ];

    if (investorFirm) {
      tabs.push({
        name: "Co-Investments",
        active: location.pathname == route(routeParams, "/co-investments"),
        to: route(routeParams, "/co-investments"),
        count: investorCoInvestments || 0,
        textOnly: true,
      });
    }

    return tabs;
  }, [
    investorCoInvestments,
    contactsCount,
    dealsCount,
    documentsCount,
    investmentsCount,
    investorFirm,
    location.pathname,
    notesCount,
    routeParams,
  ]);

  return (
    <TabMenu
      extraClassnames={classNames("no-bottom-border", {
        NavShadow: !!displayFixedContent,
      })}
      tabs={companyTabs}
    />
  );
}

function route({ organizationUsername, id }, path) {
  return `/${organizationUsername}/companies/${id}${path}`;
}

ContentNavigation.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string }),
  routeParams: PropTypes.shape({
    organizationUsername: PropTypes.string,
    id: PropTypes.string,
  }),
  investmentsCount: PropTypes.number,
  notesCount: PropTypes.number,
  documentsCount: PropTypes.number,
  dealsCount: PropTypes.number,
  contactsCount: PropTypes.number,
  investorCoInvestments: PropTypes.number,
  displayFixedContent: PropTypes.bool,
  investorFirm: PropTypes.bool,
};

export default ContentNavigation;
