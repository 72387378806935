// NOTE: Define a chore to add lodash-humps into the redux-store
// import humps from "lodash-humps";
export const RECEIVE_VIEW = "viewsV2/RECEIVE_VIEW";
import { receiveCollection } from "store/resourcesV2";

export function receiveView(viewName, viewId, view) {
  // const diggestedView = humps(view);
  return { type: RECEIVE_VIEW, viewName, viewId, view };
}

export function receiveEntitiesFromView(entities) {
  // const diggestedEntities = humps(entities);
  return (dispatch) => {
    for (let collectionName in entities) {
      dispatch(receiveCollection(collectionName, entities[collectionName]));
    }
  };
}
