import React, { Fragment } from "react";
import SecondaryButton from "components/shared/SecondaryButton";
import PrimaryButton from "components/shared/PrimaryButton";
import Combobox from "components/shared/form/Combobox";
import PropTypes from "prop-types";
import { portfolioPropType, investmentPropType } from "PropTypes";
import { ModalBody, ModalHeader, ModalFooter } from "components/shared/Modal";
import classNames from "classnames";
import useForm from "lib/hooks/useForm";

function ChangePortfolio(props) {
  const {
    isSubmitting,
    moveInvestment,
    portfoliosAvailableToSwitch,
    currentPortfolio,
    investmentDetail: { portfolio_id: previousPortfolioId },
  } = props;

  const initialValues = {
    formData: {
      isNewPortfolio: false,
      portfolioId: null,
      portfolioName: null,
    },
  };
  const submit = (clientData) => {
    const { isNewPortfolio, portfolioName, portfolioId } = clientData;
    moveInvestment({
      isNewPortfolio,
      portfolioName,
      portfolioId,
      previousPortfolioId,
    }).catch((error) => handleErrors(error));
  };
  const {
    formData,
    handleChange,
    handleSubmit,
    handleErrors,
    errors,
  } = useForm(initialValues, submit);

  const portfolioSelect = Object.values(portfoliosAvailableToSwitch).map(
    (portfolio) => ({
      value: portfolio.id,
      label: portfolio.name,
    }),
  );

  const handlePortfolioChange = (
    portfolioId,
    isNewPortfolio,
    portfolioName,
  ) => {
    if (portfolioId) {
      handleChange("portfolioId", portfolioId);
    }
    if (isNewPortfolio) {
      handleChange("isNewPortfolio", isNewPortfolio);
    }
    if (portfolioName) {
      handleChange("portfolioName", portfolioName);
    }
  };

  const isFormDisabled = isSubmitting || !formData.portfolioName;
  const formClassName = classNames({ "submitting-form": isSubmitting });
  return (
    <Fragment>
      <ModalHeader title="Change Portfolio" />
      <form className={formClassName}>
        <ModalBody>
          <div>
            Change the portfolio of this investment from{" "}
            <div>
              <strong>{currentPortfolio.name}</strong> to:
            </div>
          </div>
          <Combobox
            label="Portfolio"
            onChange={(
              portfolioId,
              { isNewOption: isNewPortfolio, label: portfolioName },
            ) =>
              handlePortfolioChange(portfolioId, isNewPortfolio, portfolioName)
            }
            inputProps={portfolioSelect}
            placeholder="Select a portfolio"
            value={formData.portfolioId}
            supportsNewOptionCreation
            newOptionLabel="Portfolio"
            errorMessage={
              errors.for("portfolio_id") || errors.for("portfolio_name")
            }
            autoFocus
          />
        </ModalBody>
        <ModalFooter
          render={(closeModal) => (
            <div className="ModalFooter-actionButtons">
              <PrimaryButton
                type="submit"
                onClick={handleSubmit}
                disabled={isFormDisabled}
                filter={{
                  applyingFilter: isSubmitting,
                }}
              >
                Save Changes
              </PrimaryButton>
              <SecondaryButton onClick={() => closeModal()}>
                Cancel
              </SecondaryButton>
            </div>
          )}
        />
      </form>
    </Fragment>
  );
}

ChangePortfolio.propTypes = {
  investmentDetail: investmentPropType,
  currentPortfolio: portfolioPropType,
  isSubmitting: PropTypes.bool,
  moveInvestment: PropTypes.func.isRequired,
  portfoliosAvailableToSwitch: portfolioPropType,
};

ChangePortfolio.defaultProps = {
  investmentDetail: null,
};

export default ChangePortfolio;
