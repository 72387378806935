import React from "react";

function Loader() {
  return (
    <div className="Loader" alt="Loading...">
      <div className="LoaderHolder">
        <div className="Loader-bounce" />
        <div className="Loader-bounce" />
        <div className="Loader-bounce" />
      </div>
    </div>
  );
}

Loader.propTypes = {};

export default Loader;
