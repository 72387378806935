import accounting from "accounting";
import isValidNumberInput from "lib/formatters/isValidNumberInput";
import { defaultNumberPrecision } from "config/priceInput";

export default function formatPercentage(value) {
  if (isValidNumberInput(value)) {
    return accounting.formatMoney(value, {
      symbol: "%",
      precision: defaultNumberPrecision,
      format: {
        pos: "%v%s",
        neg: "-%v%s",
        zero: "%v%s",
      },
    });
  }

  throw new Error("Value can not be undefined, null, or an empty string");
}
