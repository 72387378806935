import React from "react";
import HoldingCard from "../../cards/HoldingCard";
import Connector from "-!svg-react-loader?!assets/icons/miscellaneous/connection.svg?name=Connector";

function LogStockSplitEvent({
  historyAction,
  companies,
  organizationUsername,
}) {
  const {
    child_investment: childInvestment,
    parent_investment: parentInvestment,
  } = historyAction;
  return (
    <div className="ContentEvent-view">
      <HoldingCard
        companyId={parentInvestment.company_id}
        companyLogoUrl={companies[parentInvestment.company_id].logo_url}
        companyName={companies[parentInvestment.company_id].name}
        investmentId={parentInvestment.id}
        investmentName={parentInvestment.name}
        investmentType={parentInvestment.type}
        costBasis={parentInvestment.cost_basis}
        quantity={parentInvestment.quantity}
        organizationUsername={organizationUsername}
      />
      <Connector className="ConnectorIcon" />
      <HoldingCard
        companyId={childInvestment.company_id}
        companyLogoUrl={companies[childInvestment.company_id].logo_url}
        companyName={companies[childInvestment.company_id].name}
        investmentId={childInvestment.id}
        investmentName={childInvestment.name}
        investmentType={childInvestment.type}
        costBasis={childInvestment.cost_basis}
        quantity={childInvestment.quantity}
        organizationUsername={organizationUsername}
      />
    </div>
  );
}

export default LogStockSplitEvent;
