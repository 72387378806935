import React from "react";
import FormGroup from "components_new/elements/FormGroup";
import TextArea from "components_new/atoms/TextArea";
import PropTypes from "prop-types";

function Notes({
  title,
  subtitle,
  notes,
  onChange,
  isExpanded,
  required,
  name,
}) {
  return (
    <FormGroup
      extraClassnames="NotesGroup"
      title={title}
      required={required}
      subtitle={subtitle}
      isExpanded={isExpanded}
    >
      <TextArea
        name={name}
        value={notes}
        isFocus={!!notes}
        placeholder="Add note"
        onChange={(_, { value }) => {
          onChange(value);
        }}
        size="tiny"
      />
    </FormGroup>
  );
}

Notes.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  subtitle: PropTypes.string,
  notes: PropTypes.string,
  onChange: PropTypes.func,
  isExpanded: PropTypes.bool,
  required: PropTypes.bool,
  isEditing: PropTypes.bool,
  showOptions: PropTypes.bool,
  onChangeOptions: PropTypes.func,
  optionValue: PropTypes.string,
};

Notes.defaultProps = {
  title: "Notes",
  notes: "",
  isExpanded: false,
  required: false,
};

export default Notes;
