import { uniqueId, some } from "lodash";

const ONE_MEGABYTE = 1e6;
export const maxSize = 100 * ONE_MEGABYTE;

const options = [
  "Presentation",
  "Financials",
  "Stock Purchase Agreement",
  "Company Update",
  "Cap Table",
  "Capital Call Notice",
  "Investor Rights Agreement",
  "Voting Agreement",
  "Note Purchase Agreement",
  "Partnership Agreement",
  "Subscription",
  "Distribution Notice",
].map((type) => {
  return {
    key: type,
    value: type,
    text: type,
  };
});

const defaultOption = { key: "", value: "", text: "Document type" };
const lastOption = { key: "Other", value: "Other", text: "Other" };
export const fileOptions = [defaultOption, ...options, lastOption];

export const buildFileObject = (file) => {
  const isFileTooBig = file.size > maxSize;
  return {
    localId: uniqueId(),
    status: isFileTooBig ? "error" : "pending",
    progress: 0,
    isFileTooBig,
    fileObject: file,
  };
};

export function buildFileFromFileItem({ fileObject: { name } }) {
  return {
    handle: name,
    filename: name,
    closing_document: false,
    document_type: "",
  };
}

export function isUploading(uploadQueue) {
  return (
    some(uploadQueue, { status: "pending" }) ||
    some(uploadQueue, { status: "uploading" })
  );
}

export function buildEntriesFromPrefilled(
  prefilledFiles,
  prefilledFilesStatus = "saved",
) {
  const toUploadQueue = prefilledFiles.map((file) => ({
    localId: file.localId || file.id,
    status: prefilledFilesStatus,
  }));

  const toUploadedFiles = prefilledFiles.map((file) => ({
    ...file,
    localId: file.localId || file.id,
    status: prefilledFilesStatus,
  }));

  return [toUploadQueue, toUploadedFiles];
}
