import React, { Component } from "react";
import PropTypes from "prop-types";
import { selectOptionPropType } from "PropTypes";
import TextInput from "components/shared/form/TextInput";
import CaretIcon from "-!svg-react-loader?!assets/icons/utility/caret.svg?name=CaretIcon";
import classnames from "classnames";

class SelectCurrentSelection extends Component {
  handleInputChange = (value) => {
    this.props.onInputChange(value);
  };

  handleClick = () => {
    const { toggleSelect } = this.props;
    toggleSelect();
  };

  shouldComponentUpdate(nextProps) {
    const {
      displayValue: currentDisplayValue,
      selectedOption,
      isOpen,
    } = this.props;
    const hasCurrentDisplayValueChanged =
      currentDisplayValue !== nextProps.displayValue;
    const hasSelectedOptionChanged =
      selectedOption !== nextProps.selectedOption;
    const hasIsOpenChanged = isOpen !== nextProps.isOpen;

    return (
      hasCurrentDisplayValueChanged ||
      hasSelectedOptionChanged ||
      hasIsOpenChanged
    );
  }

  render() {
    const {
      autocomplete,
      disabled,
      displayValue,
      formatDisplayValue,
      inputPlaceholder,
      inputRef,
      isOpen,
      selectedOption,
    } = this.props;
    const shouldShowInput = autocomplete && isOpen;
    const hasSelection =
      selectedOption &&
      (selectedOption.value !== null || selectedOption.isNewOption);
    const currentSelectionTextClassnames = classnames({
      "Select-currentSelectionText": true,
      "Select-currentSelectionText--noSelection":
        (isOpen && !autocomplete) || !hasSelection,
    });
    return (
      <div className="SelectCurrentSelection">
        {shouldShowInput && (
          <TextInput
            autoComplete="off"
            className="InputText"
            onChange={this.handleInputChange}
            placeholder={inputPlaceholder}
            type="text"
            ref={inputRef}
            value={displayValue}
          />
        )}
        <div
          className="SelectCurrentSelection-controller"
          disabled={disabled}
          onClick={this.handleClick}
          type="button"
        >
          <span className={currentSelectionTextClassnames}>
            {!hasSelection ? displayValue : formatDisplayValue(displayValue)}
          </span>
          <CaretIcon className="ToggleButton" />
        </div>
      </div>
    );
  }
}

SelectCurrentSelection.propTypes = {
  autocomplete: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  displayValue: PropTypes.string.isRequired,
  formatDisplayValue: PropTypes.func.isRequired,
  inputPlaceholder: PropTypes.string.isRequired,
  inputRef: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  selectedOption: selectOptionPropType,
  onInputChange: PropTypes.func.isRequired,
  toggleSelect: PropTypes.func.isRequired,
};

SelectCurrentSelection.defaultProps = {
  selectedOption: null,
};

export default SelectCurrentSelection;
