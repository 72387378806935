export const COLLECTION_NAMES = [
  "acquisitions",
  // TODO: minimalCompanies and minimalPortfolios were created because the
  // cash flow endpoint violated the principle we want to enforce now. Remove those alongside
  // a refactor to that endpoint.
  "minimalCompanies",
  "minimalInvestments",
  "minimalPortfolios",
  "investments",
  "allCompanies",
  "companies",
  "companyAvatars",
  "companyHoldingTotals",
  "portfolios",
  "capitalCalls",
  "companyActivityEvents",
  "notes",
  "documents",
  "portfolioCashFlow",
  "companyCashFlow",
  "userContacts",
  "companyFundingRounds",
  "company",
  "portfolioInvestmentPaces",
  "portfolioHighlights",
  "portfolioCashFlowPerformances",
  "distributions",
  "organizations",
  "sales",
  "dashboardEvents",
  "portfolioEvents",
  "userOrganizationMemberships",
  "writeOffs",
  "stockSplits",
  "pipelines",
  "reports",
  "stages",
  "deals",
  "customFields",
  "dealPosts",
  "tagSuggestions",
  "companyTitleSuggestions",
  "companyPosts",
  "investmentsTableCashFlow",
  "queues",
  "tasks",
  "contacts",
  "industries",
];

export const MUTATION_NAMES = [
  "createCapitalCall",
  "updateNotificationsSettings",
  "createDocuments",
  "updateDocument",
  "removeDocument",
  "removeDocuments",
  "removeInvestmentDistribution",
  "updateInvestmentDistribution",
  "createValuation",
  "updatePortfolio",
  "updateCompanyFundingRounds",
  "createPortfolio",
  "moveInvestment",
  "movePortfolio",
  "createOrganization",
  "updateOrganization",
  "inviteOrganizationMember",
  "updateUserAccountInformation",
  "verifyUser",
  "sendValidationCode",
  "createPipelines",
  "createDeal",
  "updateDeal",
  "reactivateDeal",
  "createCustomField",
  "updateCustomField",
  "removeCustomField",
  "removeTag",
  "updateTag",
  "createCompanyPost",
  "updateCompanyPost",
  "removeCompanyPost",
  "createGlobalPost",
  "removeTask",
  "updateTask",
  "createInvestment",
];

export const SINGLETON_NAMES = [
  "dashboardHighlights",
  "dashboardInvestmentPace",
  "companyView",
];
