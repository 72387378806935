import React from "react";
import PropTypes from "prop-types";
import "./SubValue.scss";
import cx from "classnames";

function SubValue({ children, extraClassName, textLinesLimit }) {
  const lineLimits = { 1: "is-oneLine", 2: "is-twoLines" };
  const className = cx("SubValue", {
    [extraClassName]: !!extraClassName,
    ["is-truncated"]: !!textLinesLimit,
    [`${lineLimits[textLinesLimit]}Truncated`]: !!textLinesLimit,
  });

  return <div className={className}>{children}</div>;
}

SubValue.propTypes = {
  children: PropTypes.node,
  textLinesLimit: PropTypes.oneOf([null, 1, 2]),
  extraClassName: PropTypes.string,
};
SubValue.defaultProps = {
  textLinesLimit: null,
};
export default SubValue;
