import React from "react";
import formatPrice from "lib/formatters/formatPrice";
import formatMultiple from "components/shared/MultipleLabel/formatMultiple";
import IndicatePositiveOrNegativeValue from "components/shared/IndicatePositiveOrNegativeValue";
import formatDescription from "lib/formatDescription";
import {
  DefinitionList,
  DefinitionItem,
} from "components/shared/DefinitionList";

function InvestmentSummary({ performance }) {
  return (
    <div className="InvestmentSummary OverviewSection-block">
      <h5 className="OverviewSection-blockTitle">Performance</h5>
      <DefinitionList>
        <DefinitionItem
          name="Cash Cost"
          extraClassnames="InvestmentOverviewSection--item"
          description={formatDescription(performance.cashCost)}
          title={formatPrice(performance.cashCost)}
        />
        <DefinitionItem
          name="Cost Basis"
          extraClassnames="InvestmentOverviewSection--item"
          description={formatDescription(performance.costBasis)}
          title={formatPrice(performance.costBasis)}
        />
        <DefinitionItem
          name="Fair Market Value"
          extraClassnames="InvestmentOverviewSection--item"
          description={formatDescription(performance.fairMarketValue)}
          title={formatPrice(performance.fairMarketValue)}
        />
        <DefinitionItem
          name="Unrealized Gain"
          extraClassnames="InvestmentOverviewSection--item"
          title={formatPrice(performance.unrealizedGain)}
          description={
            <IndicatePositiveOrNegativeValue
              value={performance.unrealizedGain}
            />
          }
        />
        <DefinitionItem
          name="Realized Gain"
          extraClassnames="InvestmentOverviewSection--item"
          description={formatDescription(performance.realizedGain)}
          title={formatPrice(performance.realizedGain)}
        />
        <DefinitionItem
          name="Total Gain"
          extraClassnames="InvestmentOverviewSection--item"
          title={formatPrice(performance.totalReturn)}
          description={
            <IndicatePositiveOrNegativeValue value={performance.totalReturn} />
          }
        />
        <DefinitionItem
          name="Return Multiple"
          extraClassnames="InvestmentOverviewSection--item OverviewSection-multiple"
          description={formatMultiple(performance.multiple, "alternate")}
        />
      </DefinitionList>
    </div>
  );
}

export default InvestmentSummary;
