import React from "react";
import VerticalSummaryCard from "components_new/elements/VerticalSummaryCard";
import formatPrice from "lib/formatters/formatPrice";
import formatDate from "lib/formatDate";
import Value from "components_new/atoms/Value";
import ListValue from "components/shared/ListValue";
import { formatDisplayUrl } from "config/urlFormattingConfig";
import { isEmpty } from "lodash";
import BadgeIcon from "components_new/atoms/BadgeIcon";

function createCompanyAttributes(
  companyDetails,
  companyHoldingTotals,
  investments,
) {
  const companyInvestmentIds = companyHoldingTotals.investment_ids.filter(
    (id) => investments[id],
  );

  return [
    {
      icon: "ri-links-line",
      label: companyDetails.website
        ? formatDisplayUrl(companyDetails.website)
        : null,
      url: companyDetails.website,
    },
    { icon: "ri-map-pin-2-line", label: companyDetails.headquarters },
    // TODO: add tag links: see links for portfolios on company investment performance
    {
      icon: "ri-price-tag-3-line",
      label: companyDetails.tags.length > 0 && (
        <ListValue list={companyDetails.tags} attribute="name" showEllipsis />
      ),
    },
    {
      icon: "ri-shield-user-line",
      label: companyDetails.founders && companyDetails.founders,
    },
    {
      icon: "ri-scan-2-line",
      label: companyDetails.all_sectors.length > 0 && (
        <ListValue list={companyDetails.all_sectors} attribute="name" />
      ),
    },
    // TODO: use crunchbase funding rounds
    {
      icon: "ri-money-dollar-circle-line",
      label:
        companyInvestmentIds.length +
        " Funding Rounds (" +
        formatPrice(companyHoldingTotals.cost_basis / 1000000, {
          precision: 1,
        }) +
        "M)",
      list: companyInvestmentIds.map((investment_id) => {
        const investment = investments[investment_id];
        return {
          label: `${formatDate(investment.date, "monthAndYear")} (${
            investment.series_name
          })`,
          value: `${formatPrice(investment.cost_basis / 1000, {
            precision: 0,
          })}K`,
        };
      }),
    },
  ];
}

const customFieldValueTypes = {
  text: "text",
  textarea: "text",
  single_choice: "text",
  currency: "currency",
  date: "date",
  number: "quantity",
};

const buildCustomFieldValue = (field) => {
  const { field_type, field_value, field_data } = field;
  if (
    (!field_value && isEmpty(field_data)) ||
    (field_type === "single_choice" && !field_value) ||
    (field_type === "multiple_choice" &&
      isEmpty(field_data.filter(({ value }) => value === true)))
  ) {
    return <Value type="text">N/A</Value>;
  }
  if (field_type === "multiple_choice") {
    const list = field_data.filter(({ value }) => value === true);
    return <ListValue list={list} attribute="label" />;
  } else {
    return (
      <Value type={customFieldValueTypes[field_type]}>{field_value}</Value>
    );
  }
};

function createCompanyCustomFields(companyDetails) {
  const customFields = Object.values(companyDetails.custom_fields).map(
    (field) => {
      return {
        label: field.field_label,
        type: "text",
        value: buildCustomFieldValue(field),
      };
    },
  );
  return customFields.length > 0 ? customFields : null;
}

const CompanySummaryCard = ({
  companyAvatars,
  companyDetails,
  companyHoldingTotals,
  companyEditFunc,
  investments,
}) => {
  const companyName = (
    <span>
      {companyDetails.name}
      {companyDetails.verified && <BadgeIcon size="small" color="primary" />}
    </span>
  );

  return (
    <VerticalSummaryCard
      className="CompanySummaryCard"
      title={companyName}
      label={companyDetails.status}
      labelColor="generic"
      imageUrl={
        (companyDetails && companyDetails.logo_url) ||
        (companyAvatars && Object.values(companyAvatars)[0].logo_url)
      }
      editFunc={companyEditFunc}
      description={companyDetails.description}
      attributes={createCompanyAttributes(
        companyDetails,
        companyHoldingTotals,
        investments,
      )}
      moreInformation={createCompanyCustomFields(companyDetails)}
      avatarAttributes={
        companyDetails.tier
          ? [
              {
                label: `tier ${companyDetails.tier}`,
                labelType: "generic",
              },
            ]
          : null
      }
    />
  );
};

export default CompanySummaryCard;
