/** @module lib/Api */

import noop from "lodash/noop";
import {
  collectionFetchStart,
  collectionFetchSuccess,
  mutationStart,
  mutationSuccess,
  mutationFail,
} from "store/domain/domainActions";

import makeRequest from "./makeRequest";

const BareConfiguration = {
  store: { getState: () => null },
  baseUrl: "/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  onClientError: noop,
  onServerError: noop,
};
const Configuration = { ...BareConfiguration };

/**
 * Allows to customize the Api calls behavior.
 *
 * @param {Object} configuration A configuration object so the options passed can be named.
 * @param {Object} configuration.store A Redux store. **Default**: An object that responds to <code>getState</code>.
 * @param {function} configuration.store.getState A function that returns the store state.
 * @param {function} configuration.store.dispatch A function that can dispatch store actions.
 * @param {string} configuration.baseUrl The base url of the api. **Default**: "/"
 * @param {function(Object):Object} configuration.headers An object of headers for every request. **Default**: Accept and Content-Type with application/json
 * @param {function(Object, function, Object)} configuration.onServerError A function that's called when there are server errors (5xx). It receives the <code>state</code>, the <code>dispatch</code> function, and the <code>response</code> as parameters. **Default**: A function that does nothing.
 * @param {function(Object, function, Object)} configuration.onClientError A function that's called when there are client-related server errors (4xx). It receives the <code>state</code>, the <code>dispatch</code> function, and the <code>response</code> as parameters. **Default**: A function that does nothing.
 */
function configure({ store, baseUrl, headers, onServerError, onClientError }) {
  if (store) {
    Configuration.store = store;
  }
  if (baseUrl) {
    Configuration.baseUrl = baseUrl;
  }
  if (headers) {
    Configuration.headers = headers;
  }
  if (onServerError) {
    Configuration.onServerError = onServerError;
  }
  if (onClientError) {
    Configuration.onClientError = onClientError;
  }
}

const DEFAULT_REQUEST_OPTIONS = { headers: {}, params: {} };

/**
 * It makes a <code>GET</code> request to a path. The url is completed with the base url passed on the configuration.
 *
 * @param {string} path The path of the request. **Example:** <code>/stuff</code>.
 * @param {Object} requestOptions A set of optional options.
 * @param {Object} requestOptions.headers An object with additional headers.
 * @param {Object} requestOptions.params An object with data for the query string.
 * @param {Object} requestOptions.parseResponse A flag to control whether to parse the response or not. **Default**: <code>true</code>.
 * @param {Object} requestOptions.withoutOrganization A flag to control whether to prefix the organization to the url. **Default**: <code>false</code>.
 * @returns {Promise} A promise that resolves an object with a `response` (see <https://developer.mozilla.org/en-US/docs/Web/API/Response>) and the `data`, which is the parsed response body. (unless <code>requestOptions.parseResponse</code> is true)
 */
function get(path, requestOptions = DEFAULT_REQUEST_OPTIONS) {
  return makeRequest(Configuration, { method: "get", path, ...requestOptions });
}

/**
 * It makes a <code>GET</code> request to a path. The difference with <code>get</code> is that it **ignores the configuration passed on the Api configuration**.
 *
 * @param {string} path The path of the request. **Example:** <code>/stuff</code>.
 * @param {Object} requestOptions A set of optional options.
 * @param {Object} requestOptions.headers An object with additional headers.
 * @param {Object} requestOptions.params An object with data for the query string.
 * @param {Object} requestOptions.parseResponse A flag to control whether to parse the response or not. **Default**: <code>true</code>.
 * @param {Object} requestOptions.withoutOrganization A flag to control whether to prefix the organization to the url. **Default**: <code>false</code>.
 * @returns {Promise} A promise that resolves an object with a `response` (see <https://developer.mozilla.org/en-US/docs/Web/API/Response>) and the `data`, which is the parsed response body. (unless <code>requestOptions.parseResponse</code> is true)
 */
function bareGet(path, requestOptions = DEFAULT_REQUEST_OPTIONS) {
  return makeRequest(BareConfiguration, {
    method: "GET",
    path,
    ...requestOptions,
  });
}

/**
 * It makes a <code>POST</code> request to a path..
 *
 * @param {string} path The path of the request. **Example:** <code>/stuff</code>.
 * @param {Object} payload The payload to send with the request.
 * @param {Object} requestOptions A set of optional options.
 * @param {Object} requestOptions.headers An object with additional headers.
 * @param {Object} requestOptions.params An object with data for the query string.
 * @param {Object} requestOptions.parseResponse A flag to control whether to parse the response or not. **Default**: <code>true</code>.
 * @param {Object} requestOptions.withoutOrganization A flag to control whether to prefix the organization to the url. **Default**: <code>false</code>.
 * @returns {Promise} A promise that resolves an object with a `response` (see <https://developer.mozilla.org/en-US/docs/Web/API/Response>) and the `data`, which is the parsed response body. (unless <code>requestOptions.parseResponse</code> is true)
 */
function post(path, payload, requestOptions = DEFAULT_REQUEST_OPTIONS) {
  return makeRequest(Configuration, {
    method: "POST",
    path,
    payload,
    ...requestOptions,
  });
}

/**
 * It makes a <code>PATCH</code> request to a path..
 *
 * @param {string} path The path of the request. **Example:** <code>/stuff</code>.
 * @param {Object} payload The payload to send with the request.
 * @param {Object} requestOptions A set of optional options.
 * @param {Object} requestOptions.headers An object with additional headers.
 * @param {Object} requestOptions.params An object with data for the query string.
 * @param {Object} requestOptions.parseResponse A flag to control whether to parse the response or not. **Default**: <code>true</code>.
 * @param {Object} requestOptions.withoutOrganization A flag to control whether to prefix the organization to the url. **Default**: <code>false</code>.
 * @returns {Promise} A promise that resolves an object with a `response` (see <https://developer.mozilla.org/en-US/docs/Web/API/Response>) and the `data`, which is the parsed response body. (unless <code>requestOptions.parseResponse</code> is true) (unless <code>requestOptions.parseResponse</code> is true)
 */
function patch(path, payload, requestOptions = DEFAULT_REQUEST_OPTIONS) {
  return makeRequest(Configuration, {
    method: "PATCH",
    path,
    payload,
    ...requestOptions,
  });
}

/**
 *
 * It makes a <code>DELETE</code> request to a path..
 *
 * @function delete
 *
 * @param {string} path The path of the request. **Example:** <code>/stuff</code>.
 * @param {Object} requestOptions A set of optional options.
 * @param {Object} requestOptions.headers An object with additional headers.
 * @param {Object} requestOptions.params An object with data for the query string.
 * @param {Object} requestOptions.parseResponse A flag to control whether to parse the response or not. **Default**: <code>false</code>.
 * @param {Object} requestOptions.withoutOrganization A flag to control whether to prefix the organization to the url. **Default**: <code>false</code>.
 * @returns {Promise} A promise that resolves an object with a `response` (see <https://developer.mozilla.org/en-US/docs/Web/API/Response>) and the `data`, which is usually empty.
 */
function del(path, requestOptions = DEFAULT_REQUEST_OPTIONS) {
  return makeRequest(Configuration, {
    method: "DELETE",
    path,
    parseResponse: false,
    ...requestOptions,
  });
}

/**
 * This a **domain-integrated** function. It has knowledge of the <code>store.dispatch</code> function so it can dispatch domain actions. This one dispatches <code>collectionFetchStart</code> and <code>collectionFetchSuccess</code> around a request and nothing else.
 *
 * @param {string} collectionName The name of a collection.
 * @param {string} path The path of the collection in the API.
 * @param {Object} requestOptions A set of optional options.
 * @param {Object} requestOptions.headers An object with additional headers.
 * @param {Object} requestOptions.params An object with data for the query string.
 * @param {Object} requestOptions.parseResponse A flag to control whether to parse the response or not. **Default**: <code>true</code>.
 * @param {Object} requestOptions.withoutOrganization A flag to control whether to prefix the organization to the url. **Default**: <code>false</code>.
 * @returns {Promise} A promise that resolves an object with the parsed `collection`.`
 */
function getCollection(
  collectionName,
  path,
  requestOptions = DEFAULT_REQUEST_OPTIONS,
) {
  const { dispatch } = Configuration.store;
  dispatch(collectionFetchStart(collectionName));
  return get(path, requestOptions).then(({ data }) => {
    dispatch(collectionFetchSuccess(collectionName, data));
    return data;
  });
}

function requestWithMutation(
  mutationKey,
  requestFunction,
  path,
  payload,
  buildChangeset,
  delayMutation = false,
  requestOptions,
) {
  const { dispatch } = Configuration.store;
  dispatch(mutationStart(mutationKey));
  return requestFunction(path, payload, requestOptions).then(
    ({ data }) => {
      if (delayMutation) {
        return {
          data,
          finishMutation: () => {
            return dispatch(mutationSuccess(mutationKey, buildChangeset(data)));
          },
        };
      } else {
        dispatch(mutationSuccess(mutationKey, buildChangeset(data)));
        return data;
      }
    },
    (error) => {
      dispatch(mutationFail(mutationKey));
      throw error;
    },
  );
}

/**
 * This a **domain-integrated** function. It has knowledge of the <code>store.dispatch</code> function so it can dispatch domain actions. This one dispatches <code>mutationStart</code>, and <code>mutationSuccess</code> or <code>mutationFail</code> around a <code>POST</code> request with a given changeset that defines how to update the state.
 *
 * @param {string} mutationKey The name of the mutation to keep track of the request.
 * @param {string} path The path in the API.
 * @param {Object} payload The payload to send with the request.
 * @param {function(Object)} buildChangeset A function that returns a changeset, which is an object with instructions of how to update the domain state.
 * @param {Object} options An optional set of arguments.
 * @param {delayMutation} options.delayMutation A flag that will delay the mutation end until a resolved function is called. **Default**: <code>false</code>.
 * @param {Object} requestOptions A set of optional options.
 * @param {Object} requestOptions.withoutOrganization A flag to control whether to prefix the organization to the url. **Default**: <code>false</code>.
 * @returns {Promise} A promise that resolves an object including the parsed response body in a <code>data</code>property if the <code>options.delayMutation</code> is false, or a <code>finishMutation</code> function that will dispatch the final <code>mutationSuccess</code> if <code>options.delayMutation</code> is false.
 *
 * @example
 * Api.postMutation("createThings", "/things", { name: "Stuff" }, stuff => ({
 *   upserts: [{ entity: stuff, into: "things" }]
 * }));
 *
 * @example
 * Api.postMutation("createThings", "/things", { name: "Stuff" }, stuff => ({
 *   upserts: [{ entity: stuff, into: "things" }]
 * }), { delayMutation: true }, requestOptions).then(({ finishMutation }) => {
 *   fetchOtherData().then(finishMutation);
 * });
 */
function postMutation(
  mutationKey,
  path,
  payload,
  buildChangeset,
  { delayMutation } = { delayMutation: false },
  requestOptions = DEFAULT_REQUEST_OPTIONS,
) {
  return requestWithMutation(
    mutationKey,
    post,
    path,
    payload,
    buildChangeset,
    delayMutation,
    requestOptions,
  );
}

/**
 * This a **domain-integrated** function. It has knowledge of the <code>store.dispatch</code> function so it can dispatch domain actions. This one dispatches <code>mutationStart</code>, and <code>mutationSuccess</code> or <code>mutationFail</code> around a <code>PATCH</code> request with a given changeset that defines how to update the state.
 *
 * @param {string} mutationKey The name of the mutation to keep track of the request.
 * @param {string} path The path in the API.
 * @param {Object} payload The payload to send with the request.
 * @param {function(Object)} buildChangeset A function that returns a changeset, which is an object with instructions of how to update the domain state.
 * @param {Object} options An optional set of arguments.
 * @param {delayMutation} options.delayMutation A flag that will delay the mutation end until a resolved function is called. **Default**: <code>false</code>.
 * @param {Object} requestOptions A set of optional options.
 * @param {Object} requestOptions.withoutOrganization A flag to control whether to prefix the organization to the url. **Default**: <code>false</code>.
 * @returns {Promise} A promise that resolves an object including the parsed response body in a <code>data</code>property if the <code>options.delayMutation</code> is false, or a <code>finishMutation</code> function that will dispatch the final <code>mutationSuccess</code> if <code>options.delayMutation</code> is false.
 *
 * @example
 * Api.patchMutation("updateThing", "/things/1", { name: "Stuff" }, stuff => ({
 *   upserts: [{ entity: stuff, into: "things" }]
 * }));
 *
 * @example
 * Api.patchMutation("updateThing", "/things/1", { name: "Stuff" }, stuff => ({
 *   upserts: [{ entity: stuff, into: "things" }]
 * }), { delayMutation: true }, requestOptions).then(({ finishMutation }) => {
 *   fetchOtherData().then(finishMutation);
 * });
 */
function patchMutation(
  mutationKey,
  path,
  payload,
  buildChangeset,
  { delayMutation } = { delayMutation: false },
  requestOptions = DEFAULT_REQUEST_OPTIONS,
) {
  return requestWithMutation(
    mutationKey,
    patch,
    path,
    payload,
    buildChangeset,
    delayMutation,
    requestOptions,
  );
}

/**
 * This a **domain-integrated** function. It has knowledge of the <code>store.dispatch</code> function so it can dispatch domain actions. This one dispatches <code>mutationStart</code>, and <code>mutationSuccess</code> or <code>mutationFail</code> around a <code>DELETE</code> request with a given changeset that defines how to update the state.
 *
 * @param {string} mutationKey The name of the mutation to keep track of the request.
 * @param {string} path The path in the API.
 * @param {function(Object)} buildChangeset A function that returns a changeset, which is an object with instructions of how to update the domain state.
 * @param {delayMutation} options.delayMutation A flag that will delay the mutation end until a resolved function is called. **Default**: <code>false</code>.
 * @param {Object} requestOptions A set of optional options.
 * @param {Object} requestOptions.withoutOrganization A flag to control whether to prefix the organization to the url. **Default**: <code>false</code>.
 * @returns {Promise} A promise that resolves an object including the response body in a <code>data</code>property.
 *
 * @example
 * Api.deleteMutation("deleteThing", "/things/1", { name: "Stuff" }, stuff => ({
 *   destructions: [{ id: stuff.id, from: "things" }]
 * });
 *
 * @example
 * Api.deleteMutation("deleteThing", "/things/1", { name: "Stuff" }, stuff => ({
 *   destructions: [{ id: stuff.id, from: "things" }]
 * }), { delayMutation: true }, requestOptions).then(({ finishMutation }) => {
 *   fetchOtherData().then(finishMutation);
 * });
 */
function deleteMutation(
  mutationKey,
  path,
  buildChangeset,
  { delayMutation } = { delayMutation: false },
  requestOptions = DEFAULT_REQUEST_OPTIONS,
) {
  return requestWithMutation(
    mutationKey,
    del,
    path,
    undefined,
    buildChangeset,
    delayMutation,
    requestOptions,
  );
}

export default {
  configure,
  bareGet,
  get,
  post,
  patch,
  delete: del,
  getCollection,
  postMutation,
  patchMutation,
  deleteMutation,
};
