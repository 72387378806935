import React from "react";
import { Tab as SemanticTab } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/menu.css";
import "semantic-ui-css/components/tab.css";
import "./Tab.scss";

export default function Tab({ basicType = false, ...props }) {
  const menu = basicType ? { text: true } : { secondary: true, pointing: true };
  return <SemanticTab menu={menu} className="Tab" {...props}></SemanticTab>;
}
