import React, { useEffect, useRef, useState, useCallback } from "react";
import { Grid } from "@ableco/semantic-ui-react";
import moment from "moment";
import Segment from "components_new/atoms/Segment";
import Checkbox from "components_new/atoms/Checkbox";
import Button from "components_new/atoms/Button";
import ClearableRadioGroup from "components_new/atoms/ClearableRadioGroup";
import DatePicker, { valueDateFormat } from "components_new/atoms/DatePicker";
import "./TableFilter.scss";
import { capitalize, upperCase, isEmpty } from "lodash";
import Icon from "components_new/atoms/Icon";
import Dropdown, { DropdownMenu } from "components_new/atoms/Dropdown";
import pluralize from "pluralize";
import classnames from "classnames";
import PropTypes from "prop-types";

const getFilterOptions = (currentTab) => {
  return currentTab == "cashflow"
    ? INITIAL_TRANSACTION_TYPE_FILTERS
    : INITIAL_INVESTMENT_TYPE_FILTERS;
};

const INITIAL_INVESTMENT_TYPE_FILTERS = {
  debt: false,
  equity: false,
  fund: false,
  llc: false,
  option: false,
  warrant: false,
};

const INITIAL_TRANSACTION_TYPE_FILTERS = {
  "Initial Investment": false,
  "Capital Call": false,
  "Write-off": false,
  Acquistion: false,
  Sale: false,
};

const CUSTOM_RANGE_VALUE = "customRange";

const RADIO_DATE_OPTIONS = [
  {
    value: "last30",
    label: "Last 30 days",
  },
  {
    value: "last60",
    label: "Last 60 days",
  },
  {
    value: "last90",
    label: "Last 90 days",
  },
  {
    value: "quarterToDate",
    label: "QTD",
  },
  {
    value: "yearToDate",
    label: "YTD",
  },
  {
    value: CUSTOM_RANGE_VALUE,
    label: "Dates between",
  },
];

const validateDateRange = (start, end) => {
  if (!start || !end) return true;
  return moment(start, valueDateFormat).isSameOrBefore(
    moment(end, valueDateFormat),
  );
};

function TableFilter({
  onApplyFilters,
  values,
  showClosingDocuments,
  currentTab,
  className,
}) {
  const [typeFilters, setTypeFilters] = useState(
    INITIAL_INVESTMENT_TYPE_FILTERS,
  );
  const [dateFilter, setDateFilter] = useState("");
  const [customRangeStart, setCustomRangeStart] = useState(null);
  const [customRangeEnd, setCustomRangeEnd] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(0);
  //When filters are applied, fall back to the following working filter state on cancel:
  const [workingFilters, setWorkingFilters] = useState(null);
  const [showClosingDocumentsFilter, setShowClosingDocumentsFilter] = useState(
    showClosingDocuments,
  );

  const buttonFilterName = pluralize(
    "Filter",
    filtersApplied,
    filtersApplied > 0,
  );

  const dropdownMenu = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (isEmpty(values)) {
      handleReset();
      setFiltersApplied(0);
    }
  }, [handleReset, values]);

  const handleReset = useCallback(() => {
    setDateFilter("");
    setCustomRangeStart("");
    setCustomRangeEnd("");
    setTypeFilters(getFilterOptions(currentTab));
  }, [currentTab]);

  const handleCancel = () => {
    const {
      type_filters,
      date_filters: { dates, range_start, range_end },
    } = workingFilters;
    setDateFilter(dates);
    setCustomRangeStart(range_start);
    setCustomRangeEnd(range_end);
    setTypeFilters(type_filters);
  };

  const getDateFilterParams = () => {
    if (isEmpty(dateFilter)) return {};
    if (dateFilter === CUSTOM_RANGE_VALUE) {
      if (!customRangeStart && !customRangeEnd) return {};
      return {
        dates: dateFilter,
        range_start: customRangeStart,
        range_end: customRangeEnd,
      };
    }
    return { dates: dateFilter };
  };

  const handleApplyFilters = () => {
    setIsOpen(false);
    const dateFilters = getDateFilterParams();
    onApplyFilters(typeFilters, dateFilters, showClosingDocumentsFilter);
    setWorkingFilters({ type_filters: typeFilters, date_filters: dateFilters });
    const filtersCount = countSelectedFilters();
    setFiltersApplied(filtersCount);
  };

  const countSelectedFilters = () => {
    return (
      Object.values(typeFilters).filter((isSelected) => isSelected).length +
      (dateFilter.length > 0)
    );
  };

  const handleClose = () => {
    if (filtersApplied > 0) {
      handleCancel();
    } else {
      handleReset();
    }
    setIsOpen(false);
  };

  const handleTypeEvent = (event) => {
    setTypeFilters({ ...typeFilters, [event.value]: event.checked });
  };

  const handleDateToggleEvent = (_, { value }) => {
    if (value === dateFilter) {
      setDateFilter("");
    } else {
      setDateFilter(value);
    }
  };

  const handleCustomRangeStart = (value, validated) => {
    if (validated && validateDateRange(value, customRangeEnd)) {
      setCustomRangeStart(value);
    }
    dropdownMenu.current.focus();
  };

  const handleCustomRangeEnd = (value, validated) => {
    if (validated && validateDateRange(customRangeStart, value)) {
      setCustomRangeEnd(value);
    }
    dropdownMenu.current.focus();
  };

  const handleShowClosingDocuments = (value) => {
    setShowClosingDocumentsFilter(value.checked);
  };

  const handleClearFilters = (event) => {
    handleReset();
    onApplyFilters(getFilterOptions(currentTab), {}, showClosingDocuments);
    setFiltersApplied(0);
    event.stopPropagation();
  };

  const handleClickOutside = (event) => {
    if (dropdownMenu.current && !dropdownMenu.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const shouldDisabledButton =
    filtersApplied === 0 &&
    countSelectedFilters() === 0 &&
    showClosingDocumentsFilter === showClosingDocuments;

  const tableFilterClassnames = classnames("TableFilter Filter", {
    [className]: !!className,
  });

  return (
    <Dropdown
      className={tableFilterClassnames}
      trigger={
        <Button secondary size="small">
          <Icon icon="ri-filter-3-fill" />
          <span>{buttonFilterName}</span>

          {filtersApplied > 0 && (
            <Icon
              icon="ri-close-circle-fill"
              size="small"
              onClick={handleClearFilters}
            />
          )}
        </Button>
      }
      icon={null}
      open={isOpen}
      openOnFocus={false}
      onClick={() => {
        setIsOpen(!isOpen);
      }}
    >
      <DropdownMenu className="Menu-Container">
        <div ref={dropdownMenu} onClick={(event) => event.stopPropagation()}>
          <Segment attached="top" className="TableFilter-Containter">
            <Grid>
              <Grid.Column width={6} className="InvestmentType-Selector">
                {currentTab == "cashflow" ? (
                  <p className="u-captionBlack">SELECT TRANSACTION TYPE</p>
                ) : (
                  <p className="u-captionBlack">SELECT INVESTMENT TYPE</p>
                )}
                {typeFilters &&
                  Object.keys(typeFilters)
                    .sort()
                    .map((type, index) => {
                      return (
                        <Grid.Row key={index} className="InvestmentType-Item">
                          <Checkbox
                            checked={typeFilters[type]}
                            value={type}
                            onChange={(event, value) => handleTypeEvent(value)}
                            label={
                              type === "llc"
                                ? upperCase(type)
                                : capitalize(type)
                            }
                          />
                        </Grid.Row>
                      );
                    })}
              </Grid.Column>
              <Grid.Column width={9} className="InvestmentDate-Selector">
                <p className="u-captionBlack">SELECT INVESTMENT DATE</p>
                <Grid.Row className="Radio-Button-Group-Row">
                  <ClearableRadioGroup
                    disabled={false}
                    handleChange={handleDateToggleEvent}
                    radioButtonInfo={RADIO_DATE_OPTIONS}
                    selected={dateFilter}
                  />
                </Grid.Row>
                <div className="DateInput-Container">
                  <DatePicker
                    className="DateInput"
                    size="small"
                    value={customRangeStart}
                    onChange={handleCustomRangeStart}
                    disabled={dateFilter !== CUSTOM_RANGE_VALUE}
                  />
                  <span className="DateInput-divider"> - </span>
                  <DatePicker
                    className="DateInput"
                    size="small"
                    value={customRangeEnd}
                    onChange={handleCustomRangeEnd}
                    disabled={dateFilter !== CUSTOM_RANGE_VALUE}
                  />
                </div>
              </Grid.Column>
            </Grid>
            <div className="ClosingDocumentsFlag">
              <Checkbox
                checked={showClosingDocumentsFilter}
                value="show_closing_documents"
                onChange={(event, value) => handleShowClosingDocuments(value)}
                label="Flag investments without closing documents"
              />
            </div>
          </Segment>
          <Segment attached="bottom" className="TableFilter-footer">
            <Grid columns="equal" className="TableFilter-actions">
              <Grid.Column>
                <Grid.Row>
                  <div className="ClearButton" onClick={handleReset}>
                    Clear All
                  </div>
                </Grid.Row>
              </Grid.Column>
              <Grid.Column>
                <Grid.Row className="Apply-Cancel-Buttons">
                  <Button secondary size="small" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    primary
                    size="small"
                    disabled={shouldDisabledButton}
                    onClick={handleApplyFilters}
                  >
                    Apply
                  </Button>
                </Grid.Row>
              </Grid.Column>
            </Grid>
          </Segment>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
}

TableFilter.propTypes = {
  onApplyFilters: PropTypes.func.isRequired,
  values: PropTypes.shape({
    dates: PropTypes.string,
    types: PropTypes.arrayOf(PropTypes.string),
    range_start: PropTypes.string,
    range_end: PropTypes.string,
  }),
  className: PropTypes.string,
  showClosingDocuments: PropTypes.bool,
  currentTab: PropTypes.string,
};

export default TableFilter;
