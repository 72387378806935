import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import useSidebar from "store/sidebar/hooks";
import { useParams, useNavigate } from "react-router-dom";
import {
  expandInvestmentsViewRows,
  collapseInvestmentsViewRows,
} from "store/views";
import {
  collapseAllRowDetails,
  expandAllRowDetails,
} from "store/companies/companyView/actions";
import {
  expandInvestmentRows,
  collapseInvestmentRows,
} from "store/portfolioProfile/actions";
import {
  globalAddDealIsAvailable,
  globalUploadFileIsAvailable,
  globalPostIsAvailable,
  globalUpdatePPSIsAvailable,
  globalLogCapitalCallIsAvailable,
} from "lib/globalActionsAvailability";
import ModalDialog from "components_new/elements/ModalDialog";
import Shortcuts from "components/shared/Shortcuts";
import useCurrentOrganization from "./useCurrentOrganization";
import useShortcut from "lib/hooks/useShortcut";
import useViewIds from "lib/hooks/useViewIds";
import { useSetSidebar } from "components_new/elements/SidebarWrapper/Sidebar-context";

function focusSearchBox(event) {
  if (event && event.preventDefault) {
    event.preventDefault();
  }
  document.querySelector("#js-SearchInput").focus();
}

function GlobalShortcuts({ children }) {
  const dispatch = useDispatch();
  const { showSidebar } = useSetSidebar();
  const { organizationUsername } = useParams();
  const navigate = useNavigate();

  const currentOrganization = useCurrentOrganization();

  const {
    initialHoldingId,
    initialCompanyId,
    initialDealId,
    initialPortfolioId,
  } = useViewIds();

  const { openSidebar } = useSidebar();

  const openPortfolioForm = useCallback(
    () => openSidebar("portfolioForm", "Add New Portfolio"),
    [openSidebar],
  );

  const goToHome = useCallback(
    () => navigate(`/${organizationUsername}/dashboard`),
    [navigate, organizationUsername],
  );
  const goToInvestmentCreation = useCallback(() => {
    openSidebar("investmentForm", "Add New Investment", {
      initialPortfolioId,
      companyId: initialCompanyId,
    });
  }, [openSidebar, initialPortfolioId, initialCompanyId]);

  const expandTableRows = useCallback(() => {
    dispatch(expandInvestmentRows());
    dispatch(expandAllRowDetails());
    dispatch(expandInvestmentsViewRows());
  }, [dispatch]);

  const collapseTableRows = useCallback(() => {
    dispatch(collapseInvestmentRows());
    dispatch(collapseAllRowDetails());
    dispatch(collapseInvestmentsViewRows());
  }, [dispatch]);

  const openNewDealModal = useCallback(() => {
    if (globalAddDealIsAvailable(currentOrganization)) {
      showSidebar("dealForm", { initialCompanyId, size: "wide" });
    }
  }, [showSidebar, initialCompanyId, currentOrganization]);

  const openNotesSidebarForm = useCallback(() => {
    if (globalPostIsAvailable(currentOrganization)) {
      showSidebar("noteForm", {
        initialHoldingId,
        initialCompanyId,
        initialDealId,
      });
    }
  }, [
    showSidebar,
    currentOrganization,
    initialHoldingId,
    initialCompanyId,
    initialDealId,
  ]);

  const openFilesSidebarForm = useCallback(() => {
    if (globalUploadFileIsAvailable(currentOrganization)) {
      openSidebar("filesForm", "Add new files", {
        initialHoldingId,
        initialCompanyId,
        initialDealId,
      });
    }
  }, [
    openSidebar,
    currentOrganization,
    initialHoldingId,
    initialCompanyId,
    initialDealId,
  ]);

  const openValuationSidebarForm = useCallback(() => {
    if (globalUpdatePPSIsAvailable(currentOrganization)) {
      openSidebar("valuationForm", "Add Valuation", {
        initialHoldingId,
        initialCompanyId,
      });
    }
  }, [currentOrganization, openSidebar, initialHoldingId, initialCompanyId]);

  const openGlobalCapitalCallSidebarForm = useCallback(() => {
    if (globalLogCapitalCallIsAvailable(currentOrganization)) {
      openSidebar("capitalCallForm", "Add Capital Call", { initialHoldingId });
    }
  }, [openSidebar, currentOrganization, initialHoldingId]);

  const [isShortcutsModalOpen, setIsShortcutsModalOpen] = useState(false);

  const openShortcutsModal = useCallback(() => {
    setIsShortcutsModalOpen(true);
  }, []);

  const closeShortcutsModal = useCallback(() => {
    setIsShortcutsModalOpen(false);
  }, []);

  useShortcut("h", goToHome);
  useShortcut("i", goToInvestmentCreation);
  useShortcut("P", openPortfolioForm);
  useShortcut("s", focusSearchBox);
  useShortcut("?", openShortcutsModal);
  useShortcut("shift+down", expandTableRows);
  useShortcut("shift+up", collapseTableRows);
  useShortcut("d", openNewDealModal);
  useShortcut("p", openNotesSidebarForm);
  useShortcut("f", openFilesSidebarForm);
  useShortcut("S", openValuationSidebarForm);
  useShortcut("c", openGlobalCapitalCallSidebarForm);

  return (
    <>
      <ModalDialog
        isOpen={isShortcutsModalOpen}
        closeModal={closeShortcutsModal}
      >
        <Shortcuts />
      </ModalDialog>
      {children}
    </>
  );
}

export default GlobalShortcuts;
