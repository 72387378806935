import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isCollectionFetching } from "store/domain/selectors";
import { fetchInvestmentDocuments } from "store/documents/actions";
import { reloadInvestment } from "store/investments/investment/actions";
import { ModalNames, openInModal } from "store/modal";
import { openSidebar } from "store/sidebar";
import CapitalCallHistoryChart from "./CapitalCallHistoryChart";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import getMessage from "components_new/lib/Messages";
import {
  bulkCapitalCallUpdate,
  fetchCapitalCalls,
} from "store/investments/capitalCalls/actions";
import CapitalCallTable from "./CapitalCallTable";

function CapitalCallHistoryContainer({
  investment,
  collectionForGraph,
  collection,
}) {
  const { id: investmentId } = investment;
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const isFetching = isCollectionFetching(state, "capitalCalls");
  const { addToast } = useSetToast();

  const bulkUpdate = (payload) => {
    return dispatch(bulkCapitalCallUpdate(investmentId, payload)).then(
      (data) => {
        if (data.success) {
          return Promise.all([
            dispatch(reloadInvestment(investmentId)),
            dispatch(fetchInvestmentDocuments(investmentId)),
          ]).then(() => {
            addToast(getMessage("updateCapitalCall"));
            return data;
          });
        } else {
          return data;
        }
      },
    );
  };

  const openAttachCapitalCallModal = (options) =>
    dispatch(openInModal(ModalNames.attachCapitalCallFiles, options));

  const fetchAfterUpdateDocuments = (itemId) => {
    return dispatch(fetchCapitalCalls(investmentId)).then((updatedData) => {
      return updatedData.find((data) => data.id === itemId).documents_data;
    });
  };

  const openGlobalCapitalCallSidebarForm = () =>
    dispatch(
      openSidebar("capitalCallForm", "Add Capital Call", {
        initialHoldingId: investmentId,
      }),
    );

  return (
    <div className="CapitalCallHistory">
      <CapitalCallHistoryChart
        collection={collectionForGraph}
        investment={investment}
      />
      <CapitalCallTable
        collection={collection}
        investment={investment}
        isFetching={isFetching}
        bulkUpdate={bulkUpdate}
        openAttachCapitalCallModal={openAttachCapitalCallModal}
        fetchAfterUpdateDocuments={fetchAfterUpdateDocuments}
        openGlobalCapitalCallSidebarForm={openGlobalCapitalCallSidebarForm}
      />
    </div>
  );
}

export default CapitalCallHistoryContainer;
