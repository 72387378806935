import Api from "lib/Api";
import { mutationFail, mutationSuccess } from "store/domain/domainActions";
import { receiveCollections } from "store/entities";
import { receiveView } from "store/views";
import { receivePortfolioProfile } from "store/portfolioProfile/actions";
import { fetchInvestmentDetailsV2 } from "store/investments/investment/actions";
import { fetchValuations } from "store/investments/v2/valuations/actions";
import { fetchInvestmentDocuments } from "store/documents/actions";

export function registerLogStockSplit(formData, investmentId) {
  return (dispatch) => {
    const payload = {
      ...formData,
      documents_data: Object.values(formData.documents_data),
    };
    return Api.postMutation(
      "createLogStockSplit",
      `/investments/${investmentId}/stock_splits`,
      {
        investment_stock_split: payload,
      },
      (stockSplit) => ({
        upserts: [{ entity: stockSplit, into: "stockSplits" }],
      }),
    )
      .then(({ entities, views: { portfolio: portfolioView } }) => {
        const { stock_split: stockSplit, ...restEntities } = entities;

        dispatch(mutationSuccess("createLogStockSplit"));
        dispatch(receiveCollections(restEntities));
        dispatch(receivePortfolioProfile(portfolioView));
        dispatch(receiveView("portfolio", portfolioView));
        dispatch(fetchInvestmentDetailsV2(investmentId));
        dispatch(fetchValuations(investmentId));
        dispatch(fetchInvestmentDocuments(investmentId));
        return stockSplit;
      })
      .catch((error) => {
        dispatch(mutationFail("createLogStockSplit"));
        throw error;
      });
  };
}
