import React, { Fragment } from "react";
import SummaryStatsItem from "components_new/atoms/SummaryStatsItem";
import PropTypes from "prop-types";
import { summaryStatsProptype } from "PropTypes";
import SummaryStatsSkeleton from "./SummaryStatsSkeleton";
import "./SummaryStats.scss";

function SummaryStats({ summaryStats, isLoading }) {
  return (
    <Fragment>
      {isLoading ? (
        <SummaryStatsSkeleton />
      ) : (
        <div className="SummaryStats">
          {Object.keys(summaryStats).map((name, index) => (
            <SummaryStatsItem
              key={index}
              name={name}
              value={summaryStats[name]}
            />
          ))}
        </div>
      )}
    </Fragment>
  );
}

SummaryStats.propTypes = {
  summaryStats: summaryStatsProptype,
  isLoading: PropTypes.bool,
};

export default SummaryStats;
