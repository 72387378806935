import { connect } from "react-redux";
import UploadDocuments from "components/shared/UploadDocuments";
import { saveCompanyDocuments } from "store/companies/company/actions.js";
import { cleanDocumentUploads } from "actions/synchronous/documentUploads";
import { closeModal } from "store/modal";
import { expandMiscellaneous } from "store/companies/companyView/actions";

function mapDispatchToProps(dispatch, { companyId }) {
  return {
    onDoneButtonClick: (filepickerData) => {
      dispatch(saveCompanyDocuments(companyId, filepickerData));
      dispatch(closeModal());
      dispatch(cleanDocumentUploads());
      dispatch(expandMiscellaneous());
    },
    onCancelButtonClick: () => {
      dispatch(closeModal());
      dispatch(cleanDocumentUploads());
    },
  };
}

export default connect(null, mapDispatchToProps)(UploadDocuments);
