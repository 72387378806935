import Api from "lib/Api";

export const SEARCH_START = "search/SEARCH_START";
export const SEARCH_SUCCESS = "search/SEARCH_SUCCESS";
export const SEARCH_FAIL = "search/SEARCH_FAIL";
export const CLEAR_SEARCH = "search/CLEAR_SEARCH";

export function performSearch(scope, query) {
  return (dispatch) => {
    dispatch(searchStart(query));
    return Api.get(`/search/${scope}/${query}`)
      .then(({ data }) => {
        dispatch(searchSuccess(data));
        return data;
      })
      .catch((error) => {
        dispatch(searchFail(error.error));
      });
  };
}

export function searchStart(query) {
  return { type: SEARCH_START, query };
}

export function searchSuccess({ results, count, query }) {
  return { type: SEARCH_SUCCESS, results, count, query };
}

export function searchFail(error) {
  return { type: SEARCH_FAIL, error };
}

export function clearSearch() {
  return { type: CLEAR_SEARCH };
}
