import React from "react";
import ExitsTable from "../PortfolioTables/Exits";
import CurrentTable from "../PortfolioTables/Current";
import CashFlowsTable from "../PortfolioTables/CashFlows";
import AllTable from "../PortfolioTables/All";
import PortfolioTabPane from "./PortfolioTabPane";

export function panes(props) {
  return [
    {
      menuItem: "All Investments",
      render: () => (
        <PortfolioTabPane
          type="all"
          render={(childrenProps) => <AllTable {...childrenProps} />}
          {...props}
        />
      ),
      path: null,
    },
    {
      menuItem: "Current",
      render: () => (
        <PortfolioTabPane
          type="current"
          render={(childrenProps) => <CurrentTable {...childrenProps} />}
          {...props}
        />
      ),
      path: "current",
    },
    {
      menuItem: "Exits",
      render: () => (
        <PortfolioTabPane
          type="exits"
          render={(childrenProps) => <ExitsTable {...childrenProps} />}
          {...props}
        />
      ),
      path: "exits",
    },

    {
      menuItem: "Cash Flow",
      render: () => (
        <PortfolioTabPane
          type="cashFlow"
          render={(childrenProps) => <CashFlowsTable {...childrenProps} />}
          {...props}
        />
      ),
      path: "cashflow",
    },
  ];
}

export const panesTabIndex = { all: 0, current: 1, exits: 2, cashflow: 3 };
