import React from "react";
import AddIcon from "-!svg-react-loader?!assets/icons/utility/add.svg?name=AddIcon";
import EditIcon from "-!svg-react-loader?!assets/icons/utility/edit.svg?name=EditIcon";
import CloseIcon from "-!svg-react-loader?!assets/icons/utility/close.svg?name=CloseIcon";
import DocumentIcon from "-!svg-react-loader?!assets/icons/utility/document.svg?name=DocumentIcon";
import renderActivityEventMessage from "lib/renderActivityEventMessage";
import PropTypes from "prop-types";
import {
  investmentEventPropType,
  companyPropType,
  investmentPropType,
} from "PropTypes";

const icons = {
  add: <AddIcon />,
  close: <CloseIcon />,
  document: <DocumentIcon />,
  edit: <EditIcon />,
};

const correspondentIcons = {
  addedANote: "document",
  addedToPortfolio: "add",
  capitalCall: "add",
  createdInvestment: "add",
  createdValuation: "add",
  companyDeleted: "close",
  deletedCapitalCall: "close",
  updatedCapitalCall: "edit",
  deletedValuation: "close",
  movedAnInvestment: "edit",
  updatedInvestment: "edit",
  saleLogged: "add",
  updatedValuation: "edit",
  addedCompanyField: "add",
  removedCompanyField: "close",
  changedCompanyField: "edit",
  convertedLoan: "add",
  addedANoteToCompany: "add",
  movedDocument: "edit",
  updatedDocument: "edit",
  uploadedDocuments: "add",
  removedDocuments: "close",
  purchasedCompany: "add",
  acquiredCompany: "add",
  removedAllDocuments: "close",
  deletedInvestment: "close",
  loggedDistribution: "add",
  loggedAcquisition: "add",
  deletedPortfolio: "close",
  organizationUsernameUpdated: "edit",
  organizationNameUpdated: "edit",
  addOrganizationMember: "add",
  deletedOrganizationMember: "close",
  writtenOff: "add",
};

const renderActivityEventIcon = (icon, type) => (
  <span className="u-bg-tint-6 u-displayInlineBlock ActivityEvent-icon">
    {icon ? icons[icon] : icons[correspondentIcons[type]]}
  </span>
);

const eventEntity = (type, { owner, params }) => {
  if (type === "deletedOrganizationMember") {
    return params["member_full_name"];
  }
  return owner.full_name;
};

const ActivityEvent = ({ icon, type, values, organizationUsername }) => {
  const { event } = values;
  return (
    <div className="ActivityEvent">
      {renderActivityEventIcon(icon, type)}
      <span className="u-displayInlineBlock ActivityEvent-info">
        <span className="ActivityEvent-name">{eventEntity(type, event)}</span>
        <span className="ActivityEvent-copy">
          {renderActivityEventMessage(type)(values, organizationUsername)}
        </span>
      </span>
    </div>
  );
};

ActivityEvent.defaultProps = {
  icon: "",
};

ActivityEvent.propTypes = {
  icon: PropTypes.string,
  type: PropTypes.string.isRequired,
  values: PropTypes.shape({
    event: investmentEventPropType,
    investment: investmentPropType,
    company: companyPropType,
  }).isRequired,
  organizationUsername: PropTypes.string.isRequired,
};

export default ActivityEvent;
