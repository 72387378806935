import { connect } from "react-redux";
import { performSearch, clearSearch } from "store/search";
import SearchAndSelect from "./SearchAndSelect";

function mapStateToProps(state) {
  const { search } = state;
  const { isFetching, results } = search;

  return {
    isFetching,
    results,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchResults: (query, scope) => dispatch(performSearch(scope, query)),
    clearSearch: () => dispatch(clearSearch()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchAndSelect);
