export const recentActivityItemsByPage = 6;
export const detailFieldsByInvestment = {
  equity: {
    simple: [
      "total_acquisition_amount",
      "equity_class",
      "pre_money_company_valuation",
      "amount_raised_in_round",
      "stock_certificate_number",
      "shares_in_series",
      "conversion_price",
      "conversion_ratio",
      "vesting_schedule",
      "vesting_cliff_selection",
      "qualified_small_business_stock",
      "shares_held_electronically",
      "contacts",
    ],
    long: ["vesting"],
  },
  debt: {
    simple: [
      "note_maturity_date",
      "note_maturity_date_term",
      "interest_rate",
      "interest_schedule",
      "interest_accrual_schedule",
      "qualified_financing_amount",
      "conversion_discount",
      "conversion_cap",
      "pre_money_company_valuation",
      "amount_raised_in_round",
      "conversion_price",
      "conversion_ratio",
      "qualified_small_business_stock",
      "shares_held_electronically",
      "contacts",
    ],
    long: ["conversion_terms", "repayment_rights"],
  },
  fund: {
    simple: ["fund_size", "fund_vintage", "contacts"],
    long: [],
  },
  llc: {
    simple: [
      // "total_acquisition_amount", // will be added dynamically
      "pre_money_company_valuation",
      "amount_raised_in_round",
      "number_of_units_in_class",
      "pre_money_company_valuation",
      "amount_raised_in_round",
      "conversion_price",
      "conversion_ratio",
      "vesting_schedule",
      "vesting_cliff_selection",
      "qualified_small_business_stock",
      "shares_held_electronically",
      "contacts",
    ],
    long: ["vesting", "distribution_schedule_or_allocation_income"],
  },
  option: {
    simple: [
      "total_acquisition_amount",
      "stock_class",
      "option_exercise_price",
      "option_expiration_date",
      "option_exercise_date",
      "pre_money_company_valuation",
      "amount_raised_in_round",
      "vesting_schedule",
      "vesting_cliff_selection",
      "certificate_number",
      "conversion_price",
      "conversion_ratio",
      "qualified_small_business_stock",
      "shares_held_electronically",
      "contacts",
    ],
    long: ["vesting"],
  },
  warrant: {
    simple: [
      "total_acquisition_amount",
      "stock_class",
      "warrant_exercise_price",
      "warrant_expiration_date",
      "option_exercise_date",
      "pre_money_company_valuation",
      "amount_raised_in_round",
      "vesting_schedule",
      "vesting_cliff_selection",
      "certificate_number",
      "conversion_price",
      "conversion_ratio",
      "qualified_small_business_stock",
      "shares_held_electronically",
      "contacts",
    ],
    long: ["vesting"],
  },
};

export const noteMaturityDateTermOptions = {
  twelve: "12 months",
  twenty_four: "24 months",
  thirty_six: "36 months",
  other: "Other",
};

export const interestAccrualScheduleOptions = {
  per_annum: "Per Annum",
  per_month: "Per Month",
  per_day: "Per Day",
};
