import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import Api from "lib/Api";

const buildParams = (filters) => {
  const params = {};

  if (!isEmpty(filters.query)) params.query = filters.query;
  if (filters.showAll) params.show_all = filters.showAll;

  return params;
};

export function useQueues({ filters = {} }) {
  const { organizationUsername } = useParams();
  const params = buildParams(filters);
  return useQuery(
    ["QueuesView", organizationUsername, filters.showAll],
    async () => {
      const { data } = await Api.get(`/queues`, { params });

      return {
        label: data?.label,
        pendingTasksCount: data?.pending_tasks_count,
        snoozedTasksCount: data?.snoozed_tasks_count,
        completedTasksCount: data?.completed_tasks_count,
      };
    },
    {
      staleTime: 5000,
    },
  );
}
