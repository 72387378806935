import React from "react";
import PropTypes from "prop-types";
import "./TextLabel.scss";
import classNames from "classnames";

function TextLabel({ weight, children, ...restProps }) {
  return (
    <div
      className={classNames("TextLabel", {
        "TextLabel-Bold": weight === "bold",
      })}
      {...restProps}
    >
      {children}
    </div>
  );
}

TextLabel.propTypes = {
  weight: PropTypes.string,
  children: PropTypes.node,
};

TextLabel.defaultProps = {
  weight: "regular",
};

export default TextLabel;
