import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useCompany } from "components_new/views/Company/hooks/queries/useCompany";
import { useUpdateCompanyDocument } from "components_new/views/Company/hooks/mutations/useUpdateCompanyDocument";
import { useRemoveCompanyDocument } from "components_new/views/Company/hooks/mutations/useRemoveCompanyDocument";
import {
  collapseAllRowDetails,
  expandAllRowDetails,
} from "store/companies/companyView/actions";
import CompanyFilesTab from "./CompanyFilesTab";

function CompanyFilesTabWrapper() {
  const [filters, setFilters] = useState([]);
  const { id: companyId, organizationUsername } = useParams();
  const dispatch = useDispatch();

  const { data } = useCompany(companyId);
  const { mutateAsync: updateDocument } = useUpdateCompanyDocument();
  const { mutateAsync: removeDocument } = useRemoveCompanyDocument();

  const rowsState = useSelector((state) => {
    const { companyView: view } = state;

    return {
      allRowsExpanded: view.allRowsExpanded,
      allRowsCollapsed: view.allRowsCollapsed,
      isExpandedMiscellaneous: view?.isExpandedMiscellaneous,
    };
  });

  const expandTableRows = useCallback(() => {
    dispatch(expandAllRowDetails());
  }, [dispatch]);

  const collapseTableRows = useCallback(
    () => dispatch(collapseAllRowDetails()),
    [dispatch],
  );

  const handleUpdateCompanyDocument = useCallback(
    (document) => updateDocument({ document, companyId }),
    [companyId, updateDocument],
  );

  const handleRemoveCompanyDocument = useCallback(
    (document) => removeDocument({ document, companyId }),
    [companyId, removeDocument],
  );

  return (
    <CompanyFilesTab
      filtersApplied={filters}
      files={data?.companyDocuments}
      currentOrganizationUsername={organizationUsername}
      allRowsExpanded={rowsState.allRowsExpanded}
      allRowsCollapsed={rowsState.allRowsCollapsed}
      isExpandedMiscellaneous={rowsState.isExpandedMiscellaneous}
      expandAllRowDetails={expandTableRows}
      collapseAllRowDetails={collapseTableRows}
      applyFilters={setFilters}
      updateFile={handleUpdateCompanyDocument}
      removeFile={handleRemoveCompanyDocument}
    />
  );
}

export default CompanyFilesTabWrapper;
