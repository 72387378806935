export const distributionTypes = [
  { key: "profit_share", value: "profit_share", text: "Profit Share" },
  { key: "dividend", value: "dividend", text: "Dividend" },
  { key: "interest", value: "interest", text: "Interest" },
  {
    key: "return_of_capital",
    value: "return_of_capital",
    text: "Return of Capital",
  },
  {
    key: "in_kind_distribution",
    value: "in_kind_distribution",
    text: "In-Kind Distribution",
  },
  { key: "other", value: "other", text: "Other" },
];

export const proceedsTypeDropdownItems = [
  { key: 0, value: "cash", text: "Cash" },
  { key: 1, value: "equity", text: "Equity" },
  { key: 2, value: "both", text: "Cash + Equity" },
];
