import React, { useState, useRef } from "react";
import VisibilitySensor from "react-visibility-sensor";
import SearchInput from "components_new/atoms/SearchInput";
import Icon from "components_new/atoms/Icon";
import TaskListItems from "./TaskListItems";
import Button from "components_new/atoms/Button";
import classNames from "classnames";
import pluralize from "pluralize";
import Checkbox from "components_new/atoms/Checkbox";
import { TaskViewListSkeleton } from "../TaskViewSkeleton";
import "./TaskList.scss";

function TaskList({
  isLoading,
  tasks,
  filters,
  updateFilters,
  tasksType,
  activeTask,
  setActiveTask,
  newTasksCount,
  fetchNewTasks,
  handleOpenTaskForm,
}) {
  const [stickyEnabled, setStickyEnabled] = useState(false);
  const containmentEl = useRef(null);
  const tasklistHeaderClasses = classNames("TaskList-Header", {
    "TaskList-Header-Sticky": stickyEnabled,
  });

  const handleUpdateFilters = (key, value) => {
    updateFilters((filters) => ({
      ...filters,
      [key]: value,
    }));
  };

  return (
    <div className="TaskList" ref={containmentEl}>
      <VisibilitySensor
        onChange={(v) => setStickyEnabled(!v)}
        containment={containmentEl.current}
      >
        <div className="VisibilitySensorPixel" />
      </VisibilitySensor>
      <div className={tasklistHeaderClasses}>
        <div className="TaskList-TopBar">
          <SearchInput
            size="small"
            placeholder={`Search ${tasksType} tasks`}
            onChange={(query) => handleUpdateFilters("query", query?.trim())}
            value={filters.query}
          />
          <Button
            secondary
            size="small"
            iconPosition="left"
            onClick={() => handleOpenTaskForm(null)}
          >
            <Icon icon="ri-add-line" />
            New Task
          </Button>
        </div>

        <Checkbox
          size="tiny"
          label="Show only my tasks"
          checked={!filters.showAll}
          onChange={() => handleUpdateFilters("showAll", !filters.showAll)}
        />
      </div>
      {newTasksCount > 0 && (
        <div className="TaskList-NewTasks" onClick={fetchNewTasks}>
          <Icon icon="ri-refresh-line" /> {newTasksCount} New{" "}
          {pluralize("Task", newTasksCount)}
        </div>
      )}

      {isLoading ? (
        <TaskViewListSkeleton />
      ) : (
        <TaskListItems
          activeTask={activeTask}
          setActiveTask={setActiveTask}
          tasks={tasks}
        />
      )}
    </div>
  );
}

export default TaskList;
