import { useMutation, useQueryClient } from "react-query";
import useOrganizations from "store/organizations/hooks";
import Api from "lib/Api";
import useCurrentUser from "hooks/useCurrentUser";

export function useCreateSnapshot() {
  const queryClient = useQueryClient();
  const currentUser = useCurrentUser();
  const { currentOrganizationName } = useOrganizations();

  return useMutation(
    ({ formData }) => {
      return Api.post("/snapshots", {
        snapshot: { ...formData, creator_id: currentUser.id },
      });
    },
    {
      onSuccess: ({ data: createdSnapshot }) => {
        const key = ["SnapshotsView", currentOrganizationName];
        const snapshotView = queryClient.getQueryData(key);

        if (snapshotView?.snapshots) {
          queryClient.setQueryData(key, {
            snapshots: [...snapshotView.snapshots, createdSnapshot],
          });
        }
      },
    },
  );
}
