import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Icon from "components_new/atoms/Icon";
import classnames from "classnames";
import "./TagList.scss";

function TagList({ tags = [], iconSize = "mini" }) {
  return (
    <div className="TagList">
      {tags.length > 0 && <Icon icon="ri-price-tag-3-line" size={iconSize} />}
      {tags &&
        tags.map((tag, index) => {
          return (
            <Fragment key={`taglisttag-${tag.name}-${index}`}>
              <span
                className={classnames("TagList-Tag", {
                  clickable: !!tag.onClick,
                })}
                onClick={tag.onClick}
              >
                {tag.name}
              </span>
              {index + 1 < tags.length ? ", " : ""}
            </Fragment>
          );
        })}
    </div>
  );
}

TagList.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.object),
  iconSize: PropTypes.string,
};

export default TagList;
