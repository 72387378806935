import React from "react";
import classNames from "classnames";

import { Placeholder as SemanticSkeleton } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/placeholder.css";
import "./Skeleton.scss";

function SkeletonHeader(props) {
  const { image, type, reverse, children } = props;
  const skeletonHeaderClassnames = classNames("SkeletonHeader", {
    [type]: !!type,
    reverse: !!reverse,
  });

  return (
    <SemanticSkeleton.Header className={skeletonHeaderClassnames} image={image}>
      {children}
    </SemanticSkeleton.Header>
  );
}

export default SkeletonHeader;
