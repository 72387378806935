import {
  OPEN_IN_MODAL,
  CLOSE_MODAL,
  UPDATE_MODAL_OPTIONS,
} from "./actionTypes";

export function openInModal(modalToOpen, options = {}) {
  return { type: OPEN_IN_MODAL, modalToOpen, options };
}

export function updateModalOptions(modalName, options = {}) {
  return { type: UPDATE_MODAL_OPTIONS, modalName, options };
}

export function closeModal() {
  return { type: CLOSE_MODAL };
}
