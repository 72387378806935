import { at, pick } from "lodash";

export function getResource(state, resourceName, resourceId) {
  const { resourcesV2 } = state;
  let entity;
  if (
    resourcesV2[resourceName] &&
    (entity = resourcesV2[resourceName].byId[resourceId])
  ) {
    return entity;
  }
}

export function getResourcesAsObject(state, resourceName, resourceIds) {
  const { resourcesV2 } = state;
  return (
    resourcesV2[resourceName] &&
    pick(resourcesV2[resourceName].byId, resourceIds)
  );
}

export function getResourceAsCollection(state, resourceName, resourceIds) {
  const { resourcesV2 } = state;
  return (
    (resourcesV2[resourceName] &&
      at(resourcesV2[resourceName].byId, resourceIds)) ||
    []
  );
}

export function getOrganizationResourcesAsObject(state, resourceName) {
  const { resourcesV2 } = state;
  return (resourcesV2[resourceName] && resourcesV2[resourceName].byId) || {};
}

export function getOrganizationResourceAsCollection(state, resourceName) {
  const { resourcesV2 } = state;
  return Object.values(resourcesV2[resourceName].byId) || [];
}
