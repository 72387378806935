var React = require('react');

function AlertIcon (props) {
    return React.createElement("svg",props,[React.createElement("defs",{"key":0},React.createElement("path",{"id":"icon-alert","d":"M17.6225462 15.5011356c.4258454.8676259.0677115 1.9161915-.7999143 2.3420369-.2399736.1177831-.503741.1790244-.7710613.1790244H2.01374309c-.96649832 0-1.75-.7835017-1.75-1.75 0-.2608902.05833138-.5184795.1707255-.7539179L7.3559899 1.01937513C7.77236721.14716591 8.81697298-.22235887 9.6891822.19401845c.3555705.16974311.6434549.45451113.8170578.8082132l7.1163062 14.49890395zM9.04296693 1.5476823c-.12460132-.05948247-.27383072-.00669322-.33331319.1179081L1.78813245 16.1644943c-.01605631.0336341-.02438936.0704325-.02438936.1077026 0 .1380711.11192881.25.25.25H16.0515706c.0381886 0 .0758696-.0087488.1101516-.0255749.1239465-.0608351.1751085-.2106302.1142734-.3345768L9.15968947 1.66314133c-.02480041-.05052886-.06592675-.09121001-.11672254-.11545903zM9.791 11.948H8.223l-.294-5.286h2.142l-.28 5.286zm-.784 3.192c-.616 0-1.12-.518-1.12-1.12 0-.616.504-1.134 1.12-1.134.616 0 1.134.518 1.134 1.134 0 .602-.518 1.12-1.134 1.12z"})),React.createElement("use",{"fill":"#414042","fillRule":"evenodd","xlinkHref":"#icon-alert","key":1})]);
}

AlertIcon.defaultProps = {"width":"18","height":"18","viewBox":"0 0 18 18"};

module.exports = AlertIcon;

AlertIcon.default = AlertIcon;
