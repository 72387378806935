const allowedInvestmentTypesForSale = ["equity", "warrant", "option"];
const allowedInvestmentTypesForStockSplit = ["equity", "warrant", "option"];
const allowedInvestmentTypesForLogConversion = ["debt"];
const allowedInvestmentTypesForViewOrLogCapitalCalls = ["fund"];
const allowedInvestmentTypesForAcquisition = ["equity", "debt"];

function canInvestmentDoAction(investment, action) {
  switch (action) {
    case "logSale":
      return allowedInvestmentTypesForSale.includes(investment.type);
    case "logConversion":
      return allowedInvestmentTypesForLogConversion.includes(investment.type);
    case "logCapitalCalls":
      return allowedInvestmentTypesForViewOrLogCapitalCalls.includes(
        investment.type,
      );
    case "logStockSplit":
      return allowedInvestmentTypesForStockSplit.includes(investment.type);
    case "logAcquisition":
      return allowedInvestmentTypesForAcquisition.includes(investment.type);
    default:
      return false;
  }
}

export default canInvestmentDoAction;
