import Api from "lib/Api";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

export function useDeleteNote() {
  const { organizationUsername } = useParams();
  const queryClient = useQueryClient();

  return useMutation(
    (formData) => {
      return Api.delete(`/v2/notes/${formData.id}`, {
        post: { id: formData.id },
      });
    },
    {
      onSuccess: (_, formData) => {
        const params = {
          queryClient,
          organizationUsername,
          formData,
        };

        removePostInDealView(params);
        removePostInCompanyView(params);
      },
      onError: (error) => {
        throw error;
      },
    },
  );
}

function removePostInDealView({ queryClient, formData }) {
  const { selection, id } = formData;
  if (selection.type.toLowerCase() === "deal") {
    const key = ["Deal", selection.id];
    const dealView = queryClient.getQueryData(key);
    if (dealView) {
      queryClient.setQueryData(key, {
        ...dealView,
        posts: dealView.posts.filter((post) => post.id !== id),
      });
    }
  }
}

function removePostInCompanyView({
  queryClient,
  organizationUsername,
  formData,
}) {
  const { selection, id } = formData;
  if (selection.type.toLowerCase() === "company") {
    const companyId = selection.id;
    const key = ["Company", organizationUsername, companyId];
    const companyView = queryClient.getQueryData(key);
    if (companyView) {
      queryClient.setQueryData(key, {
        ...companyView,
        companyPosts: companyView.companyPosts.filter((post) => post.id !== id),
      });
    }
  }
}
