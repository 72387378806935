export default function differenceInDate(previousDate) {
  if (!previousDate) {
    return null;
  }

  const today = new Date();
  const prevDate = new Date(previousDate);
  const timeDiff = Math.abs(today.getTime() - prevDate.getTime());
  return Math.floor(timeDiff / (1000 * 3600 * 24));
}
