import React from "react";
import Dropdown from "components_new/atoms/Dropdown";
import Icon from "components_new/atoms/Icon";
import classnames from "classnames";
import { isEmpty } from "lodash";
import "./RemoteSearch.scss";

function RemoteSearch({
  query,
  value,
  options,
  isLoading,
  placeholder,
  search,
  onChange,
  label,
  name,
  size,
  extraClassnames,
  placeholderResults = "Type your search",
}) {
  const noResultsMessage = isEmpty(query) ? placeholderResults : "No results";

  const remoteSearchClassnames = classnames("RemoteSearch", {
    [size]: !!size,
    [extraClassnames]: !!extraClassnames,
  });

  return (
    <div className={remoteSearchClassnames}>
      {label && <label for={name}> {label} </label>}
      <Dropdown
        fluid
        selection
        search
        value={value}
        name={name}
        placeholder={placeholder}
        loading={isLoading}
        options={options}
        onSearchChange={({ target: { value } }) => search(value)}
        onChange={(_, { value }) => onChange(value)}
        noResultsMessage={noResultsMessage}
        size={size}
        icon={<Icon icon="ri-search-line" size={size} />}
        selectOnNavigation={false}
      />
    </div>
  );
}

export default RemoteSearch;
