export const UPDATE_LOAN_CONVERSION_FORM =
  "loanConversion/UPDATE_LOAN_CONVERSION_FORM";
export const REMOVE_VALUATION_AMOUNT = "loanConversion/REMOVE_VALUATION_AMOUNT";
export const RECEIVE_LOAN = "loanConversion/RECEIVE_LOAN";
export const CREATE_LOAN_CONVERSION_ERROR =
  "loanConversion/CREATE_LOAN_CONVERSION_ERROR";
export const VALIDATE_LOAN_CONVERSION_START =
  "loanConversion/VALIDATE_LOAN_CONVERSION_START";
export const VALIDATE_LOAN_CONVERSION_SUCCESS =
  "loanConversion/VALIDATE_LOAN_CONVERSION_SUCCESS";
export const VALIDATE_LOAN_CONVERSION_ERRORS =
  "loanConversion/VALIDATE_LOAN_CONVERSION_ERRORS";
export const CREATE_LOAN_CONVERSION_START =
  "loanConversion/CREATE_LOAN_CONVERSION_START";
export const CREATE_LOAN_CONVERSION_SUCCESS =
  "loanConversion/CREATE_LOAN_CONVERSION_SUCCESS";
export const CREATE_LOAN_CONVERSION_ERRORS =
  "loanConversion/CREATE_LOAN_CONVERSION_ERRORS";
export const FETCH_EXISTING_LOAN_START =
  "loanConversion/FETCH_EXISTING_LOAN_START";
export const FETCH_EXISTING_LOAN_SUCCESS =
  "loanConversion/FETCH_EXISTING_LOAN_SUCCESS";
export const FETCH_EXISTING_LOAN_ERRORS =
  "loanConversion/FETCH_EXISTING_LOAN_ERRORS";
export const START_ERROR_SCROLLING_ON_LOAN_CONVERSION =
  "loanConversion/START_ERROR_SCROLLING_ON_LOAN_CONVERSION";
export const STOP_ERROR_SCROLLING_ON_LOAN_CONVERSION =
  "loanConversion/STOP_ERROR_SCROLLING_ON_LOAN_CONVERSION";
export const UPDATE_DOCUMENT = "loanConversion/UPDATE_DOCUMENT";
export const REGISTER_LOG_LOAN_CONVERSION_DOCUMENTS =
  "loanConversion/REGISTER_LOG_LOAN_CONVERSION_DOCUMENTS";
