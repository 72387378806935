import React, { useMemo, Fragment } from "react";
import TableFilter from "components_new/elements/TableFilter";
import DropdownForm from "components_new/atoms/DropdownForm";
import { tierDropdown } from "config/tierConfig";
import { uniqBy, uniq } from "lodash";
import "./ContactTableFilter.scss";

const cleanArrayOfObjectSchema = (items, value_name) =>
  items.reduce((accumulator, current) => {
    const arrayOfValues = Object.values(current);
    let currentValues = [];
    if (arrayOfValues.length > 0) {
      currentValues = arrayOfValues.map((value) => {
        return {
          key: value[value_name],
          value: value[value_name],
          text: value[value_name],
        };
      });
    }
    return [...accumulator, ...currentValues];
  }, []);

const INITIAL_VALUES = {
  company_titles: [],
  industries: [],
  tags: [],
  location: "",
  tier: "",
};

function ContactTableFilter({ meta, onApplyFilters, filters }) {
  const {
    locations = [],
    industries = [],
    tags = [],
    company_titles = [],
  } = meta;

  const tagsForFiltering = useMemo(() => {
    return uniqBy(cleanArrayOfObjectSchema(tags, "tag_name"), "key");
  }, [tags]);

  const industriesForFiltering = useMemo(() => {
    return uniqBy(cleanArrayOfObjectSchema(industries, "name"), "key");
  }, [industries]);

  const locationForFiltering = useMemo(() => {
    const uniqLocations = uniq(locations);
    return uniqLocations.map((location) => {
      return {
        key: location,
        value: location,
        text: location,
      };
    });
  }, [locations]);

  const titlesForFiltering = useMemo(() => {
    const titleForDropdown = company_titles.reduce((acc, current) => {
      const { company_titles } = current;
      const titles = company_titles.map((value) => {
        return {
          key: value["name"],
          value: value["name"],
          text: value["name"],
        };
      });
      return [...acc, ...titles];
    }, []);
    return uniqBy(titleForDropdown, "key");
  }, [company_titles]);

  return (
    <div className="ContactTableFilter">
      <TableFilter
        initialFilterValues={INITIAL_VALUES}
        onApplyFilters={onApplyFilters}
        values={filters}
      >
        {(filters, updateFilters) => (
          <Fragment>
            <span className="u-captionBlack">Titles</span>
            <DropdownForm
              placeholder="Search titles"
              search
              multiple
              fluid
              items={titlesForFiltering}
              value={filters.company_titles}
              onChange={(_, { value }) =>
                updateFilters("company_titles", value)
              }
              size="tiny"
            />
            <span className="u-captionBlack">Industries</span>
            <DropdownForm
              placeholder="Search industries"
              search
              multiple
              fluid
              items={industriesForFiltering}
              value={filters.industries}
              onChange={(_, { value }) => updateFilters("industries", value)}
              size="tiny"
            />
            <span className="u-captionBlack">Tags</span>
            <DropdownForm
              placeholder="Search tags"
              search
              multiple
              fluid
              items={tagsForFiltering}
              value={filters.tags}
              onChange={(_, { value }) => updateFilters("tags", value)}
              size="tiny"
            />
            <span className="u-captionBlack">Location</span>
            <DropdownForm
              placeholder="Select location"
              items={locationForFiltering}
              value={filters.location}
              onChange={(_, { value }) => updateFilters("location", value)}
              size="tiny"
            />
            <span className="u-captionBlack">Tier</span>
            <DropdownForm
              placeholder="Select a tier"
              items={tierDropdown}
              value={filters.tier}
              onChange={(_, { value }) => updateFilters("tier", value)}
              size="tiny"
            />
          </Fragment>
        )}
      </TableFilter>
    </div>
  );
}

export default ContactTableFilter;
