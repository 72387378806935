import React, { useContext, useEffect } from "react";
import WriteOffForm from "./WriteOffForm";
import { SidebarComponentsContext } from "components_new/SidebarComponents/SidebarComponentsWrapper";
import useInvestmentWriteOff from "store/investments/v2/writeOffV2/hooks";
import { useInvestment } from "components_new/SidebarComponents/views/InvestmentView/hooks/useInvestment";
import { isEmpty } from "lodash";
import Loader from "components/shared/Loader";

function WriteOffFormWrapper({ ownName }) {
  const { getComponentState, registerForm, closeSidebar } = useContext(
    SidebarComponentsContext,
  );

  const { investment } = getComponentState(ownName);

  useEffect(() => {
    if (!isEmpty(ownName)) {
      registerForm(ownName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { createWriteOff } = useInvestmentWriteOff();
  const { data, isLoading } = useInvestment(investment?.id);

  if (isLoading || isEmpty(investment)) {
    return <Loader />;
  }

  return (
    <WriteOffForm
      ownName={ownName}
      company={data?.company}
      investment={data?.details}
      createWriteOff={createWriteOff}
      closeSidebar={closeSidebar}
    />
  );
}

export default WriteOffFormWrapper;
