import React, { Component } from "react";
import NumberInput from "components/shared/form/NumberInput";
import classNames from "classnames";

/*eslint-disable react/prefer-stateless-function */
class PercentInput extends Component {
  focus() {
    this.numberInput.focus();
  }

  render() {
    const { extraClassnames, ...restProps } = this.props;
    const groupClassnames = classNames({
      [extraClassnames]: true,
      "FormElement--percentInput": true,
    });
    const numberSymbol = this.props.showLock || this.props.readOnly ? "" : "%";
    const priceInputProps = {
      extraClassnames: groupClassnames,
      numberSymbol: numberSymbol,
      allowDecimal: true,
      includeThousandsSeparator: false,
      ...restProps,
    };

    return (
      <NumberInput
        {...priceInputProps}
        ref={(el) => {
          this.numberInput = el;
        }}
      />
    );
  }
}

export default PercentInput;
