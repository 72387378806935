import React, { useState, useRef, createContext, useContext } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import VisibilitySensor from "components_new/atoms/VisibilitySensor";
import "./ScrollView.scss";

const StickyContext = createContext();
const useStickyContext = () => useContext(StickyContext);

function ScrollView({ children, scrollX, scrollY, extraClassnames }) {
  const containmentElement = useRef(null);
  const [stickyEnabled, setStickyEnabled] = useState(false);
  const scrollViewClassnames = classnames("ScrollView", {
    "ScrollView-ScrollX": scrollX,
    "ScrollView-ScrollY": scrollY,
    [extraClassnames]: !![extraClassnames],
  });
  return (
    <StickyContext.Provider value={stickyEnabled}>
      <div className={scrollViewClassnames} ref={containmentElement}>
        <VisibilitySensor
          onChange={(v) => setStickyEnabled(!v)}
          containmentElement={containmentElement.current}
        />
        {children}
      </div>
    </StickyContext.Provider>
  );
}

function StickyHeader({ children, extraClassnames }) {
  const stickyEnabled = useStickyContext();
  return (
    <div
      className={classnames("ScrollView-StickyHeader", {
        "ScrollView-StickyHeader-Enabled": stickyEnabled,
        [extraClassnames]: !!extraClassnames,
      })}
    >
      {children}
    </div>
  );
}

function StickyFooter({ children, extraClassnames }) {
  const stickyEnabled = useStickyContext();
  return (
    <div
      className={classnames("ScrollView-StickyFooter", {
        "ScrollView-StickyFooter-Enabled": stickyEnabled,
        [extraClassnames]: !!extraClassnames,
      })}
    >
      {children}
    </div>
  );
}

ScrollView.StickyHeader = StickyHeader;
ScrollView.StickyFooter = StickyFooter;

ScrollView.propTypes = {
  children: PropTypes.node,
  scrollX: PropTypes.bool,
  scrollY: PropTypes.bool,
  extraClassnames: PropTypes.string,
};

ScrollView.defaultProps = {
  scrollX: false,
  scrollY: false,
};

export default ScrollView;
