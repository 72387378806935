import React, { useCallback, useContext } from "react";
import { useDispatch } from "react-redux";
import NotificationsView from "components/users/notifications/NotificationsView";
import { updateNotifications } from "store/users/actions";
import AuthenticationContext from "components/Mighty/AuthenticationContext";
import useCurrentUser from "hooks/useCurrentUser";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import getMessage from "components_new/lib/Messages";

function NotificationsViewContainer(props) {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const { setCurrentUser } = useContext(AuthenticationContext);
  const { addToast } = useSetToast();
  const notificationsSettings = currentUser.settings[0].settings;

  const updateNotificationsCallback = useCallback(
    (formData) =>
      dispatch(updateNotifications(formData)).then((user) => {
        setCurrentUser(user);
        addToast(getMessage("updateNotifications"));
      }),
    [dispatch, setCurrentUser, addToast],
  );

  return (
    <NotificationsView
      notificationsSettings={notificationsSettings}
      updateNotifications={updateNotificationsCallback}
      {...props}
    />
  );
}

export default NotificationsViewContainer;
