export const FLASH = "flash/FLASH";
export const FLASH_MODAL = "flash/FLASH_MODAL";
export const REMOVE_FLASH = "flash/REMOVE_FLASH";
export const CLEAR_FLASHES = "flash/CLEAR_FLASHES";

export function flash(flashType, options = {}) {
  return {
    type: FLASH,
    flashType,
    options: { alert: {}, ...options },
  };
}

export function flashModal(flashType, options = {}) {
  return { type: FLASH_MODAL, flashType, options };
}

export function removeFlash(flashId) {
  return { type: REMOVE_FLASH, flashId };
}

export function clearFlashes() {
  return { type: CLEAR_FLASHES };
}
