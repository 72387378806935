import numeral from "numeral";

const multipleFormats = {
  default: ["0,0.0", "0,0.0a"],
  alternate: ["0,0.00", "0,0.00a"],
};

const formatMultiple = (multiple, format = "default") => {
  const multipleToNumber = numeral(multiple);
  const formatType =
    multipleToNumber.value() > 99999
      ? multipleFormats[format][1]
      : multipleFormats[format][0];
  return multiple ? `${multipleToNumber.format(formatType)}x` : "N/A";
};

export default formatMultiple;
