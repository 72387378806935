import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { fadeIn, animationFromLeft } from "lib/animations";
import LoginLayout from "components/auth/LoginLayout";
import MightyLogo from "components_new/atoms/MightyLogo";

function ForgotConfirmation() {
  return (
    <LoginLayout extraClassnames="Login--passwordSent">
      <div className="Login-holder">
        <div className="u-bg-primary LoginWelcome">
          <div className="LoginLogoHolder">
            <MightyLogo size="large" theme="inverse" />
          </div>
          <motion.h1
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            transition={{ duration: 0.05 }}
            className="u-heading"
          >
            Instructions Sent!
          </motion.h1>
          <motion.p
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            transition={{ duration: 0.15 }}
            className=" LoginWelcome-message"
          >
            You’ll receive an email from us soon. Please follow the instructions
            to create your new password.
          </motion.p>
        </div>
        <div className="u-bg-tint-0 LoginForm">
          <div className="LoginForm-holder">
            <div className="LoginFormContainer">
              <motion.h2
                initial="hidden"
                animate="visible"
                variants={animationFromLeft()}
                transition={{ duration: 0.15 }}
                className="u-sectionHeading LoginTitle"
              >
                Check Your Inbox
              </motion.h2>
              <motion.p
                initial="hidden"
                animate="visible"
                variants={animationFromLeft({ delay: 0.1 })}
                transition={{ duration: 0.15 }}
                className="LoginAnimatedElement LoginForm-message"
              >
                Instructions to reset your password have been emailed.
              </motion.p>
              <Link className="LoginAnimatedElement BackToLogin" to="/login">
                <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={animationFromLeft({ delay: 0.4 })}
                  transition={{ duration: 0.15 }}
                >
                  Back to login
                </motion.div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </LoginLayout>
  );
}

export default ForgotConfirmation;
