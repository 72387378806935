import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";

const Pill = ({ children, bgColor, extraClassnames }) => {
  const pillClassnames = classnames({
    "u-pills": true,
    [`u-bg-${bgColor}`]: !!bgColor,
    "u-bg-tint-2": !bgColor,
    Pill: true,
    [extraClassnames]: !!extraClassnames,
  });
  return <span className={pillClassnames}>{children}</span>;
};

Pill.defaultProps = {
  extraClassnames: "",
  bgColor: "",
};

Pill.propTypes = {
  children: PropTypes.node.isRequired,
  extraClassnames: PropTypes.string,
  bgColor: PropTypes.string,
};

export default Pill;
