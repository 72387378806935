import { REQUEST_COMPANY, RECEIVE_COMPANY } from "actions/synchronous/shared";
import {
  UPDATE_COMPANY_OWNERSHIP_SUCCESS,
  UPDATE_COMPANY_VALUATION_SUCCESS,
  UPDATE_COMPANY_VIEW_INVESTMENT,
  CLEAN_COMPANY_VIEW,
  EXPAND_ALL_ROW_DETAILS,
  COLLAPSE_ALL_ROW_DETAILS,
  RESET_EXPAND_AND_COLLAPSE,
  EXPAND_MISCELLANEOUS,
  COLLAPSE_MISCELLANEOUS,
} from "store/companies/companyView/actionTypes";

const initialState = {
  id: null,
  isFetching: false,
  portfolioIds: [],
  portfolios: {},
  investments: {},
  documents: {},
  acquiringCompanies: null,
  acquiredCompanies: null,
  fullyDilutedShares: null,
  isFullyDilutedSharesCurrent: false,
  valuation: null,
  isValuationCurrent: false,
  currentHoldings: 0,
  investorSince: null,
  sharesOwned: null,
  capitalInvested: null,
  currentMarketValue: null,
  unrealizedGain: null,
  realizedGain: null,
  totalReturn: null,
  costBasis: null,
  pricePerShare: null,
  multiple: null,
  fullyDilutedSharesPercentage: null,
  lastUpdatedInvestmentDate: null,
  allRowsExpanded: false,
  allRowsCollapsed: true,
  acquisitionCompanies: [],
  expandedMiscellaneous: false,
};

function companyView(state = initialState, action) {
  switch (action.type) {
    case REQUEST_COMPANY:
      return {
        ...state,
        id: action.companyId,
        isFetching: true,
      };
    case UPDATE_COMPANY_VIEW_INVESTMENT:
      const investmentId = action.investment.id;
      const investments = { ...state.investments };
      const investment = {
        ...investments[investmentId],
        ...action.investment,
      };

      investments[investmentId] = investment;

      return {
        ...state,
        investments,
      };
    case RECEIVE_COMPANY:
      return {
        ...state,
        id: action.company.id,
        isFetching: false,
        portfolioIds: action.portfolioIds,
        portfolios: action.portfolios,
        investments: action.investments,
        documents: action.documents,
        acquiringCompanies: action.acquiringCompanies,
        acquiredCompanies: action.acquiredCompanies,
        fullyDilutedShares: action.fullyDilutedShares,
        isFullyDilutedSharesCurrent: action.isFullyDilutedSharesCurrent,
        valuation: action.valuation,
        isValuationCurrent: action.isValuationCurrent,
        currentHoldings: action.currentHoldings,
        investorSince: action.investorSince,
        sharesOwned: action.sharesOwned,
        acquisitionCompanies: action.company.acquisitions,
        capitalInvested: action.capitalInvested,
        currentMarketValue: action.currentMarketValue,
        unrealizedGain: action.unrealizedGain,
        realizedGain: action.realizedGain,
        totalReturn: action.totalReturn,
        costBasis: action.costBasis,
        pricePerShare: action.pricePerShare,
        multiple: action.multiple,
        lastUpdatedInvestmentDate: action.lastUpdatedInvestmentDate,
        current_pps: action.current_pps,
        fullyDilutedSharesPercentage: action.fullyDilutedSharesPercentage,
      };
    case EXPAND_ALL_ROW_DETAILS:
      return {
        ...state,
        allRowsExpanded: true,
        allRowsCollapsed: false,
        expandedMiscellaneous: false,
      };
    case COLLAPSE_ALL_ROW_DETAILS:
      return {
        ...state,
        allRowsExpanded: false,
        allRowsCollapsed: true,
        expandedMiscellaneous: false,
      };
    case RESET_EXPAND_AND_COLLAPSE:
      return {
        ...state,
        allRowsExpanded: false,
        allRowsCollapsed: false,
        expandedMiscellaneous: false,
      };
    case CLEAN_COMPANY_VIEW:
      return initialState;
    case UPDATE_COMPANY_OWNERSHIP_SUCCESS:
      return {
        ...state,
        fullyDilutedShares: action.fullyDilutedShares,
      };
    case UPDATE_COMPANY_VALUATION_SUCCESS:
      return {
        ...state,
        valuation: action.valuation,
      };
    case EXPAND_MISCELLANEOUS:
      return { ...state, expandedMiscellaneous: true };
    case COLLAPSE_MISCELLANEOUS:
      return { ...state, expandedMiscellaneous: false };
    default:
      return state;
  }
}

export default companyView;
