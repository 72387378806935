import React, { Fragment } from "react";
import { getText } from "TermsMapper";
import classNames from "classnames";
import formatDate from "lib/formatDate";
import EventCellPreview from "./cellTypes/EventCellPreview";
import CertificateCellPreview from "./cellTypes/CertificateCellPreview";
import QuantityCellPreview from "./cellTypes/QuantityCellPreview";
import CostBasisCellPreview from "./cellTypes/CostBasisCellPreview";
import HoldingCellPreview from "components/shared/HoldingCellPreview";
import {
  perUnitNumberPrecision,
  defaultNumberPrecision,
} from "config/priceInput";

function createCellClassNames(cellProps, perCellClassNames) {
  return classNames(perCellClassNames, {
    [cellProps.classNames]: cellProps.classNames,
    "u-leftAlignedText": cellProps.alignment === "left",
    "u-rightAlignedText": cellProps.alignment === "right",
  });
}

function createCertificateActions(
  historyAction,
  showEvent,
  openDeleteCertificateModal,
) {
  const { id: eventId, can_be_deleted: canBeDeleted } = historyAction;
  const deleteAction = !canBeDeleted
    ? () => showEvent({ openActiontId: eventId, actionType: "delete" })
    : () => {
        showEvent({ openActiontId: null, actionType: null });
        openDeleteCertificateModal({ historyAction });
      };
  const certificateActions = [
    {
      text: "View Event",
      onClickAction: () =>
        showEvent({ openActiontId: eventId, actionType: "view" }),
    },
    {
      text: "Edit Event",
      onClickAction: () =>
        showEvent({ openActiontId: eventId, actionType: "edit" }),
    },
    {
      text: "Delete Event",
      onClickAction: deleteAction,
    },
  ];

  return certificateActions;
}

function createCertificateColumns(companies, organizationUsername) {
  return [
    {
      text: "Event",
      classNames: "CertificateHistory--Event",
      alignment: "left ",
      renderValue: (
        historyAction,
        openDocumentsViewerModal,
        fetchAfterUpdateDocuments,
      ) => (
        <EventCellPreview
          historyAction={historyAction}
          documents={historyAction.documents}
          openDocumentsViewerModal={openDocumentsViewerModal}
          fetchAfterUpdateDocuments={fetchAfterUpdateDocuments}
        />
      ),
    },
    {
      text: "Investment",
      classNames: "CertificateHistory--Holding",
      alignment: "left ",
      renderValue: (historyAction) => (
        <HoldingCellPreview
          holding={historyAction.child_investment}
          companies={companies}
          isTerminalAction={historyAction.is_terminal_action}
          organizationUsername={organizationUsername}
        />
      ),
    },
    {
      text: "Certificate",
      classNames: "CertificateHistory--Certificate",
      alignment: "",
      renderValue: (historyAction) => (
        <CertificateCellPreview
          historyAction={historyAction.child_investment}
          isTerminalAction={historyAction.is_terminal_action}
        />
      ),
    },
    {
      text: "Quantity",
      classNames: "CertificateHistory--Quantity",
      alignment: "right",
      renderValue: (
        historyAction,
        openDocumentsViewerModal,
        fetchAfterUpdateDocuments,
        isSubmitting,
        lastAction,
      ) => (
        <QuantityCellPreview
          historyAction={historyAction.child_investment}
          isTerminalAction={historyAction.is_terminal_action}
          isSubmitting={isSubmitting}
          lastAction={lastAction}
        />
      ),
    },
    {
      text: "Cost Basis",
      classNames: "CertificateHistory--CostBasis",
      alignment: "right",
      renderValue: (
        historyAction,
        openDocumentsViewerModal,
        fetchAfterUpdateDocuments,
        isSubmitting,
        lastAction,
      ) => {
        const {
          is_terminal_action: isTerminalAction,
          child_investment: childInvestment,
          parent_investment: parentInvestment,
        } = historyAction;
        const holding = isTerminalAction ? parentInvestment : childInvestment;
        return (
          <CostBasisCellPreview
            historyAction={holding}
            isTerminalAction={isTerminalAction}
            isSubmitting={isSubmitting}
            lastAction={lastAction}
          />
        );
      },
    },
  ];
}

function calculatePurchasedTermMapperValue(type) {
  return type === "debt" || type === "fund"
    ? "total_acquisition_amount"
    : "quantity";
}

function createHighlightContent(historyAction) {
  if (!historyAction) {
    return null;
  }
  const type =
    historyAction.type === "purchased"
      ? historyAction.child_investment.type
      : historyAction.type;
  const description = historyAction.description;
  const date = historyAction.date;
  const termMapperValue = calculatePurchasedTermMapperValue(type);
  return (
    <Fragment>
      You changed your {getText(type, termMapperValue)} on the{" "}
      <strong>{description}</strong> event from{" "}
      {formatDate(date, "semiDetailed")}.
    </Fragment>
  );
}

function formatPriceInputPrecision(type) {
  return type === "per_share" ? perUnitNumberPrecision : defaultNumberPrecision;
}

function shouldValidateQuantity(formData) {
  const typeOfAction =
    formData.type !== undefined ? formData.type : formData.actionType;
  return !["llc", "debt", "fund", "warrant", "option"].includes(typeOfAction);
}

const customErrors = {
  equity: {
    total_acquisition_amount: "Investment Amount can't be blank",
  },
  fund: { total_acquisition_amount: "Amount can't be blank" },
  debt: { total_acquisition_amount: "Note Amount can't be blank" },
  llc: {
    total_acquisition_amount: "Acquisition Price can't be blank",
  },
  option: {
    total_acquisition_amount: "Acquisition Cost can't be blank",
  },
  warrant: {
    total_acquisition_amount: "Acquisition Cost can't be blank",
  },
};

function customPurchasedErrors(errors, label, type) {
  return errors.for(label) ? customErrors[type][label] : null;
}

export {
  createCellClassNames,
  createCertificateColumns,
  createCertificateActions,
  formatPriceInputPrecision,
  customPurchasedErrors,
  shouldValidateQuantity,
  createHighlightContent,
};
