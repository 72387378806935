import React from "react";
import moment from "moment";
import classNames from "classnames";
import { times } from "lodash";
import PropTypes from "prop-types";
const DAYS_NAME = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
const DAYS_WEEK = 6;

const getPlaceholderDays = (referenceDate) => {
  const [beginningOfMonth, endOfMonth] = [
    moment(referenceDate).startOf("month"),
    moment(referenceDate).endOf("month"),
  ];

  const daysBefore = times(beginningOfMonth.day(), (index) => {
    const daysToSubstract = index - beginningOfMonth.day();
    const date = moment(beginningOfMonth).add(daysToSubstract, "days");

    return (
      <span
        key={date + index}
        role="row"
        className="CalendarDay CalendarDay--placeholder"
      >
        {date.date()}
      </span>
    );
  });

  const daysToFill = DAYS_WEEK - endOfMonth.day();

  const daysAfter = times(daysToFill, (index) => {
    const daysToAdd = index + 1;
    const date = moment(endOfMonth).add(daysToAdd, "days");

    return (
      <span
        key={date + index}
        role="row"
        className="CalendarDay CalendarDay--placeholder"
      >
        {date.date()}
      </span>
    );
  });

  return [daysBefore, daysAfter];
};

function CalendarBody({
  onDateClick,
  preselectedDate,
  calendarMonthIndex,
  calendarYear,
  isCurrentDate,
}) {
  const referenceDate = moment({
    year: calendarYear,
    month: calendarMonthIndex,
    day: 1,
  });

  const monthDays = times(referenceDate.daysInMonth(), (index) => {
    const date = index + 1;
    const isToday = isCurrentDate(calendarYear, calendarMonthIndex, date);
    const month = `${("0" + (calendarMonthIndex + 1)).slice(-2)}`;
    const completeDate = `${calendarYear}-${month}-${date}`;
    const dayClass = classNames({
      CalendarDay: true,
      "CalendarDay--isToday": isToday,
      "CalendarDay--isPreselected": preselectedDate === completeDate,
    });

    const dateProps = {
      key: date + referenceDate.day(),
      role: "row",
      className: dayClass,
      children: date,
      "data-date": completeDate,
      onClick: onDateClick
        ? (e) => {
            onDateClick(e.target.getAttribute("data-date"));
          }
        : null,
    };

    return onDateClick ? <a {...dateProps}>{date}</a> : <span {...dateProps} />;
  });

  const [daysBefore, daysAfter] = getPlaceholderDays(referenceDate);
  const calendarDays = [...daysBefore, ...monthDays, ...daysAfter];

  const daysNameRow = DAYS_NAME.map((dayName, index) => (
    <div key={index} index={dayName} role="row" className="CalendarDayName">
      {dayName}
    </div>
  ));

  return (
    <div className="CalendarBody">
      <div className="CalendarBody-daysName">{daysNameRow}</div>
      <div className="CalendarBody-days">{calendarDays}</div>
    </div>
  );
}

CalendarBody.propTypes = {
  onDateClick: PropTypes.func,
  preselectedDate: PropTypes.string,
  calendarMonthIndex: PropTypes.number,
  calendarYear: PropTypes.number,
  isCurrentDate: PropTypes.func,
};

export default CalendarBody;
