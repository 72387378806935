import { Header } from "@ableco/semantic-ui-react";
import React from "react";
import "./ResultItemAvatar.scss";
import VerifiedBadge from "components_new/atoms/VerifiedBadge";
import ResourceIcon from "./ResourceIcon";
import PropTypes from "prop-types";

export default function ResultItemAvatar({
  imageUrl,
  avatarIcon,
  title,
  description,
  subdescription,
  isVerified,
  type,
  size,
}) {
  const imageProps = imageUrl
    ? { src: imageUrl, alt: title }
    : { as: avatarIcon, alt: title };

  return (
    <Header size={size} className="ResultItemAvatar">
      <ResourceIcon type={type} imageProps={imageProps} />
      <Header.Content className="ResultItemAvatar-content">
        <span className="ResultItemAvatar-content--title">
          {title}
          {isVerified && (
            <span className="VerifiedBadge">
              <VerifiedBadge />
            </span>
          )}
        </span>
        {description && (
          <Header.Subheader className="ResultItemAvatar-content--description">
            {description}
          </Header.Subheader>
        )}
        {subdescription && (
          <Header.Subheader className="ResultItemAvatar-content--subdescription">
            {subdescription}
          </Header.Subheader>
        )}
      </Header.Content>
    </Header>
  );
}

ResultItemAvatar.propTypes = {
  type: PropTypes.string,
  imageUrl: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  description: PropTypes.string,
  subdescription: PropTypes.string,
  avatarIcon: PropTypes.func,
  isVerified: PropTypes.bool,
  size: PropTypes.string,
};
