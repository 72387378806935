import React, { Fragment, useEffect } from "react";
import FormGroup from "components_new/elements/FormGroup";
import FieldGroup from "components/shared/form/FieldGroup";
import SearchAndSelect from "components/shared/form/SearchAndSelect";
import DatePicker from "components_new/atoms/DatePicker";
import formatDate from "lib/formatDate";
import { isEmpty } from "lodash";
import { buildPrefilledCompanyData } from "lib/globalActionsHelper";
import { getAcquisitionItemAttributes } from "./config";
import "./Acquisitions.scss";

function AcquisitionItem({
  index,
  formData,
  updateForm,
  relatedCompanies = {},
  acquisitions = [],
  disabled,
}) {
  let initialCompany =
    acquisitions.length - 1 >= index
      ? relatedCompanies[acquisitions[index].company_id]
      : "";
  initialCompany = buildPrefilledCompanyData(initialCompany);

  useEffect(() => {
    if (!isEmpty(acquisitions[index])) {
      updateForm("date", acquisitions[index].date, index);
      updateForm("company_id", acquisitions[index].company_id, index);
      updateForm("logo_url", acquisitions[index].logo_url, index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <SearchAndSelect
        name="company_id"
        label="Company name"
        placeHolder="Search a company"
        scope="all_companies"
        onChange={(_, value) => updateForm("company_id", value.id, index)}
        prefilledValue={initialCompany}
        disabled={disabled}
        size="tiny"
      />
      <DatePicker
        name={`aquisition_date-${index}`}
        label="Acquisition date"
        value={formatDate(formData.date)}
        onChange={(value) => {
          updateForm("date", value, index);
        }}
        readOnly={disabled}
        disabled={disabled}
        size="tiny"
      />
    </Fragment>
  );
}

function Acquisitions({
  title = "Acquisitions",
  acquisitions,
  company,
  relatedCompanies,
  handleChange,
  subtitle,
  required = false,
  isExpanded = false,
  isVerifiedCompany,
}) {
  const removeAcquisitionGroup = (index) => {
    const newAcquisitions = acquisitions.slice();
    newAcquisitions.splice(index, 1);
    handleChange("acquisitions", newAcquisitions);
  };
  const startWithOne = acquisitions.length === 0;

  const attributes = getAcquisitionItemAttributes(isVerifiedCompany);

  return (
    <FormGroup
      extraClassnames="AcquisitionsGroup"
      title={title}
      required={required}
      subtitle={subtitle}
      isExpanded={isExpanded}
    >
      <FieldGroup
        name="acquisitions"
        extraClassnames="FormElement fieldGroup"
        values={company.acquisitions}
        attributesTemplate={attributes}
        onChange={(values) => handleChange("acquisitions", values)}
        onRemove={removeAcquisitionGroup}
        infiniteItems
        startWithOne={startWithOne}
        readOnly={isVerifiedCompany}
        render={(childrenProps) => (
          <AcquisitionItem
            relatedCompanies={relatedCompanies}
            acquisitions={company.acquisitions}
            disabled={isVerifiedCompany}
            {...childrenProps}
          />
        )}
      />
    </FormGroup>
  );
}

export default Acquisitions;
