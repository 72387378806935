import React, { useState, useCallback, useMemo } from "react";
import FormFooter from "components_new/SidebarComponents/SidebarForm/FormFooter";
import CompanyBasicDetails from "../groups/CompanyBasicDetails";
import Valuation from "../groups/Valuation";
import Acquisitions from "../groups/Acquisitions";
import SocialMedia from "../groups/SocialMedia";
import Icon from "components_new/atoms/Icon";
import Message from "components_new/atoms/Message";
import MessageHeader from "components_new/atoms/Message/MessageHeader";
import MessageContent from "components_new/atoms/Message/MessageContent";
import RequiredNote from "components_new/SidebarComponents/shared/RequiredNote";
import CustomFields from "components_new/SidebarComponents/forms/groups/CustomFields";
import PropTypes from "prop-types";
import useForm from "lib/hooks/useForm";

import {
  companyPropType,
  tagSuggestionsProptype,
  customFieldProptype,
} from "PropTypes";
import { initialValues } from "./config";
import { withCleanAcquisitions } from "../groups/Acquisitions/config";
import { isEmpty } from "lodash";
import "./GlobalCompanyForm.scss";

function GlobalCompanyForm({
  ownName,
  company,
  submitAction,
  submitTextButton,
  showNotification,
  onCloseSidebar,
  acquirerCompany,
  relatedCompanies,
  isVerifiedCompany,
  showMessage,
  filterCompanyTags,
  companyTagSuggestions,
  isAdmin,
  isCurrentUserOrganizationOwner,
  currentOrganizationName,
  currentOrganizationUsername,
  organizationCustomFields,
  primarySectors,
  prepopulatedCompanyTags,
  prepopulatedSectors,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const submit = useCallback(
    async (clientData) => {
      setIsLoading(true);

      try {
        const cleanedData = withCleanAcquisitions(clientData);
        const { data: company } = await submitAction(cleanedData);

        showNotification(company);
        onCloseSidebar();
      } catch (error) {
        handleErrors(error);
        handleScrollView(error);
      } finally {
        setIsLoading(false);
      }
    },
    [
      submitAction,
      showNotification,
      onCloseSidebar,
      handleErrors,
      handleScrollView,
    ],
  );

  const initialValuesForm = {
    formData: {
      ...initialValues.formData,
      ...company,
    },
  };

  const {
    formData,
    handleChange,
    handleSubmit,
    handleErrors,
    handleScrollView,
    errors,
    touched,
  } = useForm(initialValuesForm, submit);

  const shouldDisableSubmit = useMemo(
    () => isEmpty(formData.status) || isEmpty(formData.name) || isLoading,
    [formData, isLoading],
  );

  return (
    <div className="GlobalCompanyForm">
      {showMessage && (
        <Message extraClassNames="CompanyFormMessage" icon size="mini">
          <Icon icon="ri-information-line" size="mini" />
          <div className="MessageWrapper">
            <MessageHeader>Private Profile</MessageHeader>
            <MessageContent>
              These details are only visible to members of your organization.
            </MessageContent>
          </div>
        </Message>
      )}
      <CompanyBasicDetails
        formData={formData}
        handleChange={handleChange}
        acquirerCompany={acquirerCompany}
        errors={errors}
        isVerifiedCompany={isVerifiedCompany}
        filterCompanyTags={filterCompanyTags}
        companyTagSuggestions={companyTagSuggestions}
        primarySectors={primarySectors}
        prepopulatedCompanyTags={prepopulatedCompanyTags}
        prepopulatedSectors={prepopulatedSectors}
      />
      <Valuation
        formData={formData}
        handleChange={handleChange}
        isVerifiedCompany={isVerifiedCompany}
      />
      <Acquisitions
        company={formData}
        acquisitions={formData.acquisitions}
        relatedCompanies={relatedCompanies}
        handleChange={handleChange}
        isVerifiedCompany={isVerifiedCompany}
      />
      <SocialMedia
        formData={formData}
        handleChange={handleChange}
        isVerifiedCompany={isVerifiedCompany}
      />
      <CustomFields
        resource_type="company"
        formData={formData}
        isAdmin={isAdmin}
        isOrgOwner={isCurrentUserOrganizationOwner}
        handleChange={handleChange}
        organizationCustomFields={organizationCustomFields}
        organizationName={currentOrganizationName}
        organizationUsername={currentOrganizationUsername}
      />
      <RequiredNote />
      <FormFooter
        componentName={ownName}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        disableSubmit={shouldDisableSubmit}
        submitTextButton={submitTextButton}
        touched={touched}
      />
    </div>
  );
}

GlobalCompanyForm.propTypes = {
  ownName: PropTypes.string.isRequired,
  company: companyPropType.isRequired,
  currentOrganizationUsername: PropTypes.string.isRequired,
  submitAction: PropTypes.func.isRequired,
  submitTextButton: PropTypes.string,
  showNotification: PropTypes.func,
  onCloseSidebar: PropTypes.func.isRequired,
  relatedCompanies: PropTypes.shape({}),
  acquirerCompany: companyPropType,
  isVerifiedCompany: PropTypes.bool,
  showMessage: PropTypes.bool,
  filterCompanyTags: PropTypes.func,
  companyTagSuggestions: tagSuggestionsProptype,
  isAdmin: PropTypes.bool,
  isCurrentUserOrganizationOwner: PropTypes.bool,
  currentOrganizationName: PropTypes.string,
  organizationCustomFields: PropTypes.arrayOf(customFieldProptype),
  primarySectors: PropTypes.arrayOf(PropTypes.shape({})),
  prepopulatedCompanyTags: PropTypes.arrayOf(PropTypes.shape({})),
  prepopulatedSectors: PropTypes.arrayOf(PropTypes.shape({})),
};

export default GlobalCompanyForm;
