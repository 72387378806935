import React from "react";
import NumberInput from "components_new/atoms/NumberInput";
import Input from "components_new/atoms/Input";
import CurrencyInput from "components_new/atoms/CurrencyInput";
import DatePicker from "components_new/atoms/DatePicker";
import StockDetails from "components_new/SidebarComponents/forms/groups/StockDetails";
import VestingFields from "components_new/SidebarComponents/forms/groups/VestingDetails";

function OptionInvestmentDetails({ formData, handleChange, errors }) {
  const { name, total_acquisition_amount, date, quantity } = formData;

  return (
    <div className="OptionInvestmentDetails BasicDetails ui form">
      <Input
        name="name"
        label="Investment Name"
        placeholder="Name your investment"
        value={name}
        size="tiny"
        onChange={(_, { value }) => {
          handleChange("name", value);
        }}
        isRequired
        size="tiny"
        error={!!errors.for("name")}
        errorMessage={errors.for("name")}
        focus
      />
      <NumberInput
        name="quantity"
        label="Number of options"
        aria-label="Number of options"
        placeholder="e.g. 100"
        defaultValue={quantity}
        size="tiny"
        onChange={(value) => {
          handleChange("quantity", value);
        }}
        size="tiny"
        required
        error={!!errors.for("quantity")}
        errorMessage={errors.for("quantity")}
      />
      <CurrencyInput
        name="total_acquisition_amount"
        label="Acquisition cost*"
        placeholder="e.g. 100"
        decimalLimit={2}
        value={total_acquisition_amount}
        defaultValue={total_acquisition_amount}
        size="tiny"
        onChange={(value) => {
          handleChange("total_acquisition_amount", value);
        }}
        error={!!errors.for("total_acquisition_amount")}
        errorMessage={errors.for("total_acquisition_amount")}
      />
      <DatePicker
        name="date"
        label="Acquisition date"
        value={date}
        size="tiny"
        prefillToday
        onChange={(value) => {
          handleChange("date", value);
        }}
        size="tiny"
        required
        error={!!errors.for("date")}
        errorMessage={errors.for("date")}
      />
      <StockDetails
        title="Option Details"
        formData={formData}
        handleChange={handleChange}
      />
      <VestingFields
        title="Vesting Details"
        formData={formData}
        handleChange={handleChange}
      />
    </div>
  );
}

export default OptionInvestmentDetails;
