import {
  UPDATE_FORM,
  START_DOCUMENT_EDITION,
  STOP_DOCUMENT_EDITION,
  UPDATE_DOCUMENT_FAILED,
  HYDRATE_FORM_DATA,
  RESET_FORM,
  RESET_DOCUMENT_FORM_DATA,
} from "./actionTypes";
import {
  mutationSuccess,
  collectionFetchStart,
  collectionFetchSuccess,
} from "store/domain/domainActions";
import {
  reloadInvestment,
  isInCurrentView,
} from "store/investments/investment/actions";
import { receiveCollection } from "store/entities";
import { updateView, removeFromView } from "store/views";
import Api from "lib/Api";

export function updateForm(key, value, documentId) {
  return { type: UPDATE_FORM, key, value, documentId };
}

export function startDocumentEdition(documentId) {
  return { type: START_DOCUMENT_EDITION, documentId };
}

export function stopDocumentEdition(givenDocument) {
  return { type: STOP_DOCUMENT_EDITION, givenDocument };
}

export function updateDocumentFailed(errors) {
  return { type: UPDATE_DOCUMENT_FAILED, errors };
}

export function hydrateFormData(formData) {
  return { type: HYDRATE_FORM_DATA, formData };
}

export function resetForm() {
  return { type: RESET_FORM };
}

export function resetDocumentFormData(givenDocument) {
  return { type: RESET_DOCUMENT_FORM_DATA, givenDocument };
}

export function updateDocument(givenDocument) {
  return (dispatch) => {
    const { id: documentId } = givenDocument;

    return Api.patchMutation(
      `updateDocument-${documentId}`,
      `/documents/${documentId}`,
      givenDocument,
      (givenDocument) => ({
        upserts: [{ entity: givenDocument, into: "documents" }],
      }),
      { delayMutation: false },
    ).then((updatedDocument) => {
      dispatch(receiveCollection("documents", [updatedDocument]));
      return updatedDocument;
    });
  };
}

export function removeDocument(givenDocument, investmentId) {
  return (dispatch) => {
    const { id: documentId } = givenDocument;
    return Api.deleteMutation(
      `removeDocument-${documentId}`,
      `/documents/${documentId}`,
      () => ({
        destructions: [{ from: "documents", id: documentId }],
      }),
      { delayMutation: false },
    ).then(() => {
      // TODO: We should to check if this reloadInvestment is necessary
      // to refresh any investment on store
      dispatch(removeFromView("company", "document_ids", documentId));
      if (investmentId !== null) {
        dispatch(reloadInvestment(investmentId));
      }
    });
  };
}

function buildChangeSetsFromResults(results) {
  const destructions = [];
  const upserts = [];

  results.forEach((r) => {
    if (r.type === "destroy" && r.success) {
      destructions.push({
        id: r.resource.id,
        from: "documents",
      });
    }

    if (r.type === "update" && r.success) {
      upserts.push({
        entity: r.resource,
        into: "documents",
      });
    }
  });

  return { destructions, upserts };
}

export function bulkDocumentsUpdate(investmentId, payload) {
  return (dispatch) => {
    return Api.patch(`/investments/${investmentId}/documents`, {
      operations: payload,
    }).then(({ data }) => {
      if (data.success) {
        const { destructions, upserts } = buildChangeSetsFromResults(
          data.results,
        );

        dispatch(
          mutationSuccess("removeDocuments", {
            destructions,
          }),
        );

        dispatch(
          mutationSuccess("documents", {
            upserts,
          }),
        );
      }

      return data;
    });
  };
}

export function saveInvestmentDocuments(
  investmentId,
  companyId,
  uploadedDocuments,
) {
  return (dispatch, getState) => {
    const payload = {
      documents: {
        company_id: companyId,
        investment_id: investmentId,
        documentsData: uploadedDocuments,
      },
    };
    const {
      views: { investment: investmentView },
    } = getState();

    return Api.postMutation(
      "createDocuments",
      `/investments/${investmentId}/documents`,
      payload,
      (documents) => {
        const upserts = isInCurrentView(investmentView, investmentId)
          ? documents.map((document) =>
              addResourceToInvestmentView(
                "documents",
                "document_ids",
                document,
                document.id,
              ),
            )
          : [];

        upserts.forEach((upsertAction) => dispatch(upsertAction));
      },
    );
  };
}

export function addResourceToInvestmentView(
  resourceName,
  resourceIdsName,
  resource,
  resourceId,
) {
  return (dispatch) => {
    dispatch(receiveCollection(resourceName, [resource]));
    dispatch(updateView("investment", resourceIdsName, resourceId));
  };
}

export function fetchInvestmentDocuments(investmentId) {
  return (dispatch) => {
    dispatch(collectionFetchStart("documents"));
    return Api.get(`/investments/${investmentId}/documents`).then(
      ({ data: documents }) => {
        documents.forEach(
          (doc) => (doc.requested_investment_id = investmentId),
        );
        dispatch(collectionFetchSuccess("documents", documents));
        return documents;
      },
    );
  };
}
