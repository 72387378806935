import PropTypes from "prop-types";
import React from "react";
import PrimaryButton from "components/shared/PrimaryButton";

function WeeklyReportDevTool({ sendWeeklyReport }) {
  return (
    <div className="DevHome-content">
      <p>
        This will send a weekly report to all users of the system. Use it wisely
        because we only run it once a week normally.
      </p>
      <p>
        <strong>
          IMPORTANT: Use this button carefully as you could send unwanted emails
          to someone. Please be sure you don't have real-world user emails in
          the database.
        </strong>
      </p>
      <p>
        <PrimaryButton onClick={sendWeeklyReport}>
          Send Weekly Report
        </PrimaryButton>
      </p>
    </div>
  );
}

WeeklyReportDevTool.propTypes = {
  sendWeeklyReport: PropTypes.func,
};

export default WeeklyReportDevTool;
