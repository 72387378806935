import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";

import useSidebar from "store/sidebar/hooks";
import useOrganizations from "store/organizations/hooks";
import CompanyCallToActionSidebar from "./CompanyCallToActionSidebar";
import { useSetSidebar } from "components_new/elements/SidebarWrapper/Sidebar-context";

function CompanyCallToActionSidebarWrapper() {
  const { id: companyId } = useParams();
  const { openSidebar } = useSidebar();
  const { showSidebar } = useSetSidebar();
  const { currentOrganization } = useOrganizations();
  const queryClient = useQueryClient();

  const { companyDetails } = queryClient.getQueryData([
    "Company",
    currentOrganization.username,
    companyId,
  ]);

  const openNotesSidebarForm = useCallback(
    (initialCompanyId) =>
      showSidebar("noteForm", {
        initialCompanyId,
      }),
    [showSidebar],
  );

  const openFilesSidebarForm = useCallback(
    (initialCompanyId) =>
      openSidebar("filesForm", "Add new files", {
        initialCompanyId,
        canMarkAsClosing: false,
      }),
    [openSidebar],
  );

  const openValuationSidebarForm = useCallback(
    (initialCompanyId) =>
      openSidebar("valuationForm", "Add Valuation", {
        initialCompanyId,
      }),
    [openSidebar],
  );

  const openGlobalCapitalCallSidebarForm = useCallback(
    (initialCompanyId) =>
      openSidebar("capitalCallForm", "Add Capital Call", {
        initialCompanyId,
      }),
    [openSidebar],
  );

  return (
    <CompanyCallToActionSidebar
      companyId={companyId}
      currentOrganization={currentOrganization}
      openNotesSidebarForm={openNotesSidebarForm}
      openFilesSidebarForm={openFilesSidebarForm}
      companyDetails={companyDetails}
      openValuationSidebarForm={openValuationSidebarForm}
      openGlobalCapitalCallSidebarForm={openGlobalCapitalCallSidebarForm}
    />
  );
}

export default CompanyCallToActionSidebarWrapper;
