import PropTypes from "prop-types";
import React, { Component } from "react";
import classNames from "classnames";
import { uniqueId } from "lodash";
import FormErrorMessage from "components/shared/form/FormErrorMessage";

class Checkbox extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    errorDisplayValidation: PropTypes.func,
    errorMessage: PropTypes.string,
    extraClassnames: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.bool,
    inverseLabelDisplay: PropTypes.bool,
    defaultValue: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
    errorDisplayValidation: null,
    errorMessage: null,
    extraClassnames: "",
    inverseLabelDisplay: true,
    label: null,
    name: null,
    onChange: null,
    value: false,
    defaultValue: false,
  };

  UNSAFE_componentWillMount() {
    const id = uniqueId("FormElement");
    this.setState({ id });
  }

  onChange = () => {
    const { onChange, name, value } = this.props;
    this.setState({ checked: !value });
    if (onChange) {
      onChange(name, !value);
    }
  };

  render() {
    const {
      label,
      name,
      disabled,
      errorMessage,
      extraClassnames,
      value,
    } = this.props;
    const { id, checked } = this.state;
    const shouldShowErrors = !!errorMessage;
    const checkboxExtraClassnames = classNames(
      "FormElement FormElement--checkbox",
      {
        [extraClassnames]: !!extraClassnames,
        "is-disabled": !!disabled,
        "is-checked": checked,
        "has-label": !!label,
        "has-errors": !!errorMessage,
      },
    );

    return (
      <div className={checkboxExtraClassnames} name={`FormElement--${name}`}>
        <input
          type="checkbox"
          name={name}
          className="Checkbox"
          onChange={this.onChange}
          checked={value}
          disabled={disabled}
          id={id}
        />
        <label className="Checkbox-label" htmlFor={id}>
          <span className="Checkbox-labelHolder u-textWithLineBreaks">
            {label}
          </span>
        </label>
        {shouldShowErrors && <FormErrorMessage errorMessage={errorMessage} />}
      </div>
    );
  }
}

export default Checkbox;
