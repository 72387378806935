import React from "react";
import PropTypes from "prop-types";
import {
  organizationMembershipPropType,
  organizationProptype,
} from "PropTypes";
import EllipsisDropdown from "components_new/elements/EllipsisDropdown";

function OrganizationMemberActions({
  organizationMembership,
  openRemoveOrganizationMemberModal,
  openLeaveOrganizationModal,
  revokeInvitation,
  resendInvitation,
  isOrgOwnerPerformingAction,
  privateOrganization,
}) {
  const { status } = organizationMembership;
  const owner = isOrgOwnerPerformingAction ? "owner" : "member";
  const notAccepted = ["pending", "expired"].includes(status)
    ? "notAccepted"
    : null;
  const type = notAccepted || owner;

  const actions = {
    leave: {
      text: "Leave Organization",
      onClick: () =>
        openLeaveOrganizationModal(organizationMembership, privateOrganization),
    },
    remove: {
      text: "Remove from Organization",
      onClick: () => openRemoveOrganizationMemberModal(organizationMembership),
    },
    revoke: {
      text: "Revoke Invitation",
      onClick: () => revokeInvitation(organizationMembership),
    },
    resend: {
      text: "Resend Invitation",
      onClick: () => resendInvitation(organizationMembership),
    },
  };

  const memberActions = {
    owner: ["remove"],
    notAccepted: ["revoke", "resend"],
    member: ["leave"],
  };

  const items = memberActions[type].map((item) => actions[item]);

  return <EllipsisDropdown options={items} />;
}

OrganizationMemberActions.propTypes = {
  organizationMembership: organizationMembershipPropType.isRequired,
  privateOrganization: organizationProptype,
  openRemoveOrganizationMemberModal: PropTypes.func.isRequired,
  openLeaveOrganizationModal: PropTypes.func.isRequired,
  revokeInvitation: PropTypes.func.isRequired,
  resendInvitation: PropTypes.func.isRequired,
  isOrgOwnerPerformingAction: PropTypes.bool.isRequired,
};

export default OrganizationMemberActions;
