import React from "react";
import { useParams } from "react-router-dom";
import CompanyDealTab from "./CompanyDealTab";
import useOrganizations from "store/organizations/hooks";
import { useSetSidebar } from "components_new/elements/SidebarWrapper/Sidebar-context";
import { useCompany } from "components_new/views/Company/hooks/queries/useCompany";

function CompanyDealTabWrapper(props) {
  const { id: companyId } = useParams();
  const {
    currentOrganization: { username: currentOrganizationUsername },
  } = useOrganizations();

  const { data } = useCompany(companyId);
  const { companyDeals, companyAvatars, referrers } = data;
  const { showSidebar } = useSetSidebar();

  return (
    <CompanyDealTab
      deals={companyDeals}
      referrers={referrers}
      companyAvatars={companyAvatars}
      currentOrganizationUsername={currentOrganizationUsername}
      showSidebar={showSidebar}
      {...props}
    />
  );
}

export default CompanyDealTabWrapper;
