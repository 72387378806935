import React from "react";
import EmptyPage from "components_new/elements/EmptyPage";
import AddIcon from "-!svg-react-loader?!assets/icons/utility/add.svg?name=AddIcon";
import PrimaryButton from "components/shared/PrimaryButton";
import { isEmpty } from "lodash";

function EmptyView({ portfolio, onClick }) {
  const portfolioText = !isEmpty(portfolio) ? ` in ${portfolio.name}` : "";
  return (
    <EmptyPage
      headline={`You don't have any investments${portfolioText}`}
      subHeadline="Add your first investment to track portfolio events, review cashflow
        activity and view portfolio insights."
      clickToAction={() => {
        return (
          <PrimaryButton onClick={() => onClick()}>
            <AddIcon className="Button-icon" />
            <span className="Button-text">New Investment</span>
          </PrimaryButton>
        );
      }}
    />
  );
}

export default EmptyView;
