import { connect } from "react-redux";
import DashboardActivityFeed from "./DashboardActivityFeed";
import { fetchDashboardEvents } from "store/events/dashboardEvents/actions";
import { getOrderedEvents } from "store/events/selectors";
import { isCollectionFetching } from "store/domain/selectors";

function mapStateToProps(state) {
  const { events } = state;
  const {
    dashboardEventsCount: eventsCount,
    eventRelatedInvestments: investments,
  } = events;

  return {
    events: getOrderedEvents(state, "dashboardEvents"),
    isFetching: isCollectionFetching(state, "dashboardEvents"),
    eventsCount,
    investments,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchDashboardEvents: (page, perPage) =>
      dispatch(fetchDashboardEvents({ page, perPage, replace: false })),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardActivityFeed);
