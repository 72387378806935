import { useMutation, useQueryClient } from "react-query";
import Api from "lib/Api";

export function useCreateInvestmentDocument() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ document, investmentId }) =>
      Api.post(`/investments/${investmentId}/documents/`, {
        documents: {
          documentsData: [document],
        },
      }),
    {
      onError: (_err, _newDoc, rollback) => rollback(),
      onSuccess: ({ data: newDocument }, { investmentId }) => {
        return queryClient.setQueryData(
          ["Investment", investmentId],
          (oldData) => ({
            ...oldData,
            documents: [...oldData.documents, ...newDocument],
          }),
        );
      },
    },
  );
}
