import React from "react";
import PropTypes from "prop-types";

import "semantic-ui-css/components/grid.css";
import "semantic-ui-css/components/container.css";
import "./LeftAlignedDetailPageLayout.scss";

const SIDEBAR_WIDTH = 278;

function LeftAlignedDetailPageLayout(props) {
  const { children, leftSidebar, rightSidebar, navigation } = props;
  return (
    <div className="PageContainer">
      <div
        className="PageSidebar"
        style={{
          width: SIDEBAR_WIDTH,
        }}
      >
        {leftSidebar}
      </div>
      <div className="PageContentWrapper">
        <div className="PageNavigation">{navigation}</div>
        <div className="PageContent">
          <div className="ChildrenContainer">{children}</div>
          <div className="RightSidebarContainer">{rightSidebar}</div>
        </div>
      </div>
    </div>
  );
}

LeftAlignedDetailPageLayout.propTypes = {
  children: PropTypes.node,
  navigation: PropTypes.node,
  leftSidebar: PropTypes.node,
  rightSidebar: PropTypes.node,
};

export default LeftAlignedDetailPageLayout;
