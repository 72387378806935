import React from "react";
import PropTypes from "prop-types";

function CalendarFooter({ onClickToday }) {
  return (
    <div className="CalendarFooter">
      <div
        onClick={onClickToday}
        className="CalendarFooter-selectToday u-bodyMediumBold"
      >
        Today
      </div>
    </div>
  );
}

CalendarFooter.propTypes = {
  onClickToday: PropTypes.func,
};

export default CalendarFooter;
