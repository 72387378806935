import accounting from "accounting";

export default function formatPercentage(
  number,
  preventPercentPrepend = false,
  places = 2,
) {
  let formatted = null;
  if (number) {
    formatted = accounting.formatNumber(number, places, "");
  }

  if (preventPercentPrepend) {
    return formatted;
  }

  return `${formatted}%`;
}
