import React from "react";
import ReferrerForm from "./ReferrerForm";
import useContacts from "store/contacts/hooks";
import useOrganization from "store/organizations/hooks";

function ReferrerFormWrapper({ closeSidebar, referrer, onChange, toogleSize }) {
  const { validateContact, filterTags, tagSuggestions } = useContacts();
  const { currentOrganization } = useOrganization();

  return (
    <ReferrerForm
      referrer={referrer}
      onChange={onChange}
      closeSidebar={closeSidebar}
      validateContact={validateContact}
      toogleSize={toogleSize}
      filterTags={filterTags}
      tagSuggestions={tagSuggestions}
      prepopulatedContactTags={currentOrganization.prepopulated_contact_tags}
      prepopulatedSectors={currentOrganization.prepopulated_sectors}
    />
  );
}

export default ReferrerFormWrapper;
