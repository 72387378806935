import React, { useState, useEffect } from "react";
import useForm from "lib/hooks/useForm";
import HoldingMiniCard from "components/shared/HoldingMiniCard";
import ConversionDetails from "components_new/SidebarComponents/forms/groups/ConversionDetails";
import EquityProceeds from "components_new/SidebarComponents/forms/groups/EquityProceeds";
import CustomFields from "components_new/SidebarComponents/forms/groups/CustomFields";
import RoundDetails from "components_new/SidebarComponents/forms/groups/RoundDetails";
import RealizedGain from "components_new/SidebarComponents/forms/groups/RealizedGain";
import Documents from "components_new/SidebarComponents/forms/groups/Documents";
import Notes from "components_new/SidebarComponents/forms/groups/Notes";
import RequiredNote from "components_new/SidebarComponents/shared/RequiredNote";
import FormFooter from "components_new/SidebarComponents/SidebarForm/FormFooter";
import { isUploading } from "../../../elements/DropZone/config";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import getMessage from "components_new/lib/Messages";
import moment from "moment";

const today = moment().format("YYYY-MM-DD");
const INITIAL_VALUES = {
  formData: {
    date: today,
    conversion_amount: null,
    calculated_conversion_amount: null,
    name: "",
    type: "equity",
    create_extra_valuation: null,
    accrued_interest: null,
    principal_amount: null,
    series_name: "",
    quantity: null,
    cost: null,
    cost_type: null,
    currentValuation: null,
    realized_gain: null,
    equity_class: "",
    certificate_number: "",
    custom_name: "",
    custom_fields: {},
    fully_diluted_shares: "",
    shares_in_series: "",
    pre_money_valuation: "",
    amount_raised: "",
    cash_amount: null,
    documents_data: [],
    notes: "",
  },
};

function LogConversionForm({
  ownName,
  investment,
  company,
  isAdmin,
  isOrgOwner,
  investmentCustomFields,
  currentOrganizationName,
  currentOrganizationUserName,
  createLoanConversion,
  closeSidebar,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [uploadQueue, setUploadQueue] = useState([]);
  const { addToast } = useSetToast();

  const initializeFormData = () => {
    return {
      formData: {
        ...INITIAL_VALUES.formData,
        principal_amount: investment.total_acquisition_amount,
      },
    };
  };

  const initialFormData = initializeFormData();

  const onSubmit = (clientData) => {
    setIsLoading(true);
    createLoanConversion(clientData, investment.id)
      .then(({ history_actions }) => {
        closeSidebar();
        const [createdInvestment] = history_actions;
        addToast(
          getMessage("addedLoanConversion", {
            url: `/${currentOrganizationUserName}/investments/${createdInvestment.child_investment.id}`,
          }),
        );
      })
      .catch((error) => {
        handleErrors(error);
        handleScrollView(error);
      })
      .finally(() => setIsLoading(false));
  };

  const {
    formData,
    handleChange,
    handleSubmit,
    handleErrors,
    handleScrollView,
    errors,
  } = useForm(initialFormData, onSubmit);

  const { cash_amount, realized_gain, documents_data, notes } = formData;

  useEffect(() => {
    setDisableSubmit(isUploading(uploadQueue));
  }, [uploadQueue]);

  useEffect(() => {
    handleChange("realized_gain", cash_amount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cash_amount]);

  return (
    <div className="LogConversionForm">
      <div className="HoldingGroup">
        <HoldingMiniCard
          investment={investment}
          company={company}
          className="FormElement"
        />
      </div>
      <ConversionDetails
        formData={formData}
        onChange={handleChange}
        errors={errors}
        investment={investment}
      />
      <EquityProceeds
        formData={formData}
        handleChange={handleChange}
        componentName={ownName}
        bottomBorder
        valuationSameAsCost
        prefilledCompany={company}
        errors={errors}
      />
      <CustomFields
        resource_type="investments"
        formData={formData}
        isAdmin={isAdmin}
        isOrgOwner={isOrgOwner}
        handleChange={handleChange}
        organizationCustomFields={investmentCustomFields}
        organizationName={currentOrganizationName}
        organizationUsername={currentOrganizationUserName}
      />
      <RoundDetails formData={formData} handleChange={handleChange} />
      <RealizedGain
        realizedGain={realized_gain}
        onChange={handleChange}
        errors={errors}
      />
      <Documents
        onChange={(documents) => handleChange("documents_data", documents)}
        documents={documents_data}
        onQueueChange={setUploadQueue}
      />
      <Notes notes={notes} onChange={(notes) => handleChange("notes", notes)} />
      <RequiredNote />
      <FormFooter
        componentName={ownName}
        data={formData}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        disableSubmit={disableSubmit}
      />
    </div>
  );
}

export default LogConversionForm;
