import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { registerLogStockSplit as logStockSplitHelper } from "store/investments/logStockSplit/actions";
import { useQueryClient } from "react-query";

export default () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const invalidateQueries = useCallback(() => {
    queryClient.invalidateQueries(["Company"]);
  }, [queryClient]);

  const createStockSplit = async (formData) => {
    return dispatch(logStockSplitHelper(formData, formData.investmentId)).then(
      () => {
        invalidateQueries();
      },
    );
  };

  return { createStockSplit };
};
