import Api from "lib/Api";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { removeTaskFromScope } from "../shared/removeTaskFromScope";
import useViewIds from "lib/hooks/useViewIds";

export function useRemoveTask() {
  const queryClient = useQueryClient();
  const { organizationUsername } = useParams();
  const { initialDealId: dealId } = useViewIds();

  return useMutation(({ taskId }) => Api.delete(`/tasks/${taskId}`), {
    onSuccess: (_, { taskId }) => {
      const params = {
        taskId,
        queryClient,
        organizationUsername,
      };

      removeTaskFromScope({ scope: "pending", ...params });
      removeTaskFromScope({ scope: "snoozed", ...params });
      removeTaskFromScope({ scope: "completed", ...params });
      queryClient.invalidateQueries(["QueuesView", organizationUsername]);
      if (dealId) {
        queryClient.invalidateQueries(["Deal", dealId]);
      }
    },
  });
}
