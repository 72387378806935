import React from "react";
import PropTypes from "prop-types";
import { activityFeedDocumentPropType } from "PropTypes";
import FileIcon from "components_new/atoms/FileIcon";
import iconType from "components_new/lib/iconType";

function ActivityEventDocumentIcon({ doc, size }) {
  return (
    <FileIcon type={iconType(doc.mime_type || doc.mimetype)} size={size} />
  );
}

ActivityEventDocumentIcon.propTypes = {
  doc: activityFeedDocumentPropType,
  size: PropTypes.oneOf(["small", "large"]),
};

ActivityEventDocumentIcon.defaultProps = {
  size: "small",
};

export default ActivityEventDocumentIcon;
