import React, { useMemo } from "react";
import Segment from "components_new/atoms/Segment";
import TopBar from "../TableActions/TopBar";

import { TabPane } from "components_new/elements/Tab";
import { isEmpty } from "lodash";

function PortfolioTabPane(props) {
  const {
    data,
    dataByCompany,
    portfolioId,
    summaryStats,
    render,
    isLoading,
    handleIsStatsLoading,
    location,
    type,
    currentTab,
    filters,
    updateFilters,
    groupedByCompany,
    showClosingDocuments,
    udpateUserInformation,
    isSearchActive,
  } = props;

  const isEmptyView = useMemo(() => {
    return (
      isEmpty(data) && isEmpty(dataByCompany) && !isSearchActive && !isLoading
    );
  }, [data, dataByCompany, isSearchActive, isLoading]);

  const investmentCount = useMemo(() => {
    if (!isEmpty(data)) return data.length;

    if (!isEmpty(dataByCompany))
      return dataByCompany.reduce((memo, item) => {
        memo += item.investments.length;
        return memo;
      }, 0);

    return 0;
  }, [data, dataByCompany]);

  return (
    <TabPane>
      <Segment shadow extraClassNames="InvestmentsTab">
        {!isEmptyView && (
          <TopBar
            portfolioId={portfolioId}
            investmentCount={investmentCount}
            isLoading={isLoading}
            filters={filters}
            updateFilters={updateFilters}
            groupedByCompany={groupedByCompany}
            summaryStatsInfo={summaryStats}
            handleIsStatsLoading={handleIsStatsLoading}
            location={location}
            type={type}
            currentTab={currentTab}
            showClosingDocuments={showClosingDocuments}
            udpateUserInformation={udpateUserInformation}
          />
        )}
        {render({
          ...props,
          isEmptyView,
          showClosingDocuments,
        })}
      </Segment>
    </TabPane>
  );
}

export default PortfolioTabPane;
