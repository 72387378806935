import React from "react";
import classNames from "classnames";
import { historyActionsProptype, companiesObjectPropType } from "PropTypes";
import PropTypes from "prop-types";
import TableHeader from "./Table/TableHeader";
import TableBody from "./Table/TableBody";
import { createCertificateColumns } from "./config";

function CertificateHistory({
  historyActions,
  companies,
  fetchAfterUpdateDocuments,
  openDocumentsViewerModal,
  openDeleteCertificateModal,
  updateCertificateHistory,
  isSubmitting,
  organizationUsername,
}) {
  const certificateTableClassNames = classNames("CertificateHistoryTable", {
    hasManyHistories: historyActions.length > 1,
  });
  return (
    <div className="CertificateHistory">
      <h2 className="u-sectionHeading">Certificate History</h2>
      <div className={certificateTableClassNames}>
        <TableHeader
          columns={createCertificateColumns()}
          wrapperClassNames="CertificateHistoryTable--Header TableHeader"
          perCellClassNames="CertificateHistoryTable--Cell u-label"
        />
        <TableBody
          columns={createCertificateColumns(companies, organizationUsername)}
          collection={historyActions}
          companies={companies}
          openDocumentsViewerModal={openDocumentsViewerModal}
          fetchAfterUpdateDocuments={fetchAfterUpdateDocuments}
          updateCertificateHistory={updateCertificateHistory}
          openDeleteCertificateModal={openDeleteCertificateModal}
          isSubmitting={isSubmitting}
          organizationUsername={organizationUsername}
        />
      </div>
    </div>
  );
}

CertificateHistory.propTypes = {
  historyActions: historyActionsProptype,
  companies: companiesObjectPropType,
  fetchAfterUpdateDocuments: PropTypes.func,
  openDocumentsViewerModal: PropTypes.func,
  openDeleteCertificateModal: PropTypes.func,
  updateCertificateHistory: PropTypes.func,
  isSubmitting: PropTypes.bool,
  organizationUsername: PropTypes.string.isRequired,
};

export default CertificateHistory;
