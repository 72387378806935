import React, { useState, useEffect } from "react";
import Button from "components/shared/Button";
import classNames from "classnames";
import isServerValidationError from "lib/isServerValidationError";
import Errors from "lib/errors";
import TextInput from "components/shared/form/TextInput";
import DotsIcon from "-!svg-react-loader?!assets/icons/utility/dots.svg?name=DotsIcon";
import { ENTER_KEY, ESC_KEY } from "config/keyCodes";

function SuggestionAction({
  suggestion,
  onEdit,
  onDelete,
  actionAfterUpdate,
  removeTag,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [errors, setError] = useState(new Errors({}));
  const [updatedValue, setValue] = useState(suggestion.name);
  let suggestionActionWrapper = React.createRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  function handleClickOutside(event) {
    if (
      suggestionActionWrapper.current &&
      !suggestionActionWrapper.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  }

  function inputKeyDown(event) {
    switch (event.which) {
      case ENTER_KEY:
        return onEdit(suggestion.id, updatedValue)
          .then(() => {
            actionAfterUpdate(suggestion.id, updatedValue);
            setIsOpen(false);
          })
          .catch((error) => {
            if (isServerValidationError(error)) {
              const { data } = error;
              setError(new Errors(data.errors));
            }
          });
      case ESC_KEY:
        return setIsOpen(false);
    }
  }

  const onDeleteTag = () => {
    onDelete(suggestion, removeTag);
    setIsOpen(false);
  };

  const suggestionActionClassnames = classNames("SuggestionAction", {
    "is-open": isOpen,
  });

  return (
    <div
      className={suggestionActionClassnames}
      ref={suggestionActionWrapper}
      onMouseDown={(event) => handleClickOutside(event)}
    >
      <Button
        onClick={() => setIsOpen(!isOpen)}
        customClass="Suggestions-actionButton Button--empty"
        size="small"
      >
        <DotsIcon />
      </Button>
      {isOpen && (
        <div className="Suggestions-actions Container">
          <TextInput
            onKeyDown={inputKeyDown}
            onChange={(value) => {
              setValue(value);
              setError(new Errors({}));
            }}
            value={updatedValue}
            errorMessage={errors.for("name")}
            size="small"
            autoFocus={true}
          />
          <Button
            onClick={onDeleteTag}
            customClass="Suggestions-deleteButton Button--warning"
            size="small"
          >
            Delete Tag
          </Button>
        </div>
      )}
    </div>
  );
}

export default SuggestionAction;
