import React from "react";
import PropTypes from "prop-types";
import Table from "components/shared/Table";
import DistributionRowTemplate from "./DistributionRowTemplate";
import {
  initialStateData,
  createDistributionColumns,
  canLockItem,
  emptyDistributionItem,
} from "./config";
import { formatPriceInputPreview } from "lib/abbreviatedFormat";
import {
  distributionsPropType,
  investmentPropType,
  companyPropType,
} from "PropTypes";
import getMessage from "components_new/lib/Messages";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";

function DistributionTable({
  investment,
  companies,
  collection,
  fetchAfterUpdateDocuments,
  openDocumentsViewerModal,
  isFetching,
  bulkUpdate,
  isOnModal,
  openLogDistributionSidebarForm,
  submitPortalSelector,
  viewMoreTooltipContent,
  organizationUsername,
}) {
  const columns = createDistributionColumns(
    companies,
    fetchAfterUpdateDocuments,
    openDocumentsViewerModal,
    organizationUsername,
  );
  const footerAmount = formatPriceInputPreview(
    investment.total_proceed_amount || "0.00",
  );
  const isEditButtonDisabled = !collection.some((distribution) => {
    return distribution.is_cash_proceed;
  });
  const { addToast } = useSetToast();
  return (
    <Table
      columns={columns}
      submitPortalSelector={submitPortalSelector}
      title="Distribution History"
      deleteTitle="Delete this distribution?"
      updateButtonText="Log Distribution"
      showModalLaunchButton
      collection={collection}
      investment={investment}
      isFetching={isFetching}
      canLockItem={canLockItem}
      lockTooltipContent="Holdings are only editable from the Certificate History table."
      bulkUpdate={bulkUpdate}
      onCreate={() => openLogDistributionSidebarForm(investment)}
      isOnModal={isOnModal}
      rowInitialState={initialStateData()}
      emptyItem={emptyDistributionItem}
      viewMoreTooltipContent={viewMoreTooltipContent}
      showFooter={true}
      footerAmount={footerAmount}
      isEditButtonDisabled={isEditButtonDisabled}
      disabledActions={investment.previous_holding}
      editButtonDisabledTooltipText="Only cash distributions can be edited"
      onDeleteRow={() => addToast(getMessage("deleteDistribution"))}
      renderRow={(childrenProps) => (
        <DistributionRowTemplate
          investment={investment}
          {...childrenProps}
          organizationUsername={organizationUsername}
        />
      )}
    />
  );
}

DistributionTable.propTypes = {
  investment: investmentPropType,
  companies: PropTypes.objectOf(companyPropType),
  collection: distributionsPropType,
  bulkUpdate: PropTypes.func.isRequired,
  fetchAfterUpdateDocuments: PropTypes.func.isRequired,
  openDocumentsViewerModal: PropTypes.func.isRequired,
  openLogDistributionSidebarForm: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  isOnModal: PropTypes.bool,
  submitPortalSelector: PropTypes.string,
  viewMoreTooltipContent: PropTypes.string,
  organizationUsername: PropTypes.string,
};

DistributionTable.defaultProps = {
  isFetching: false,
  isOnModal: false,
};

export default DistributionTable;
