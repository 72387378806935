import Api from "lib/Api";
import { requestStart, requestSuccess, requestError } from "store/requests";
import { receiveView } from "store/views";
import { receiveCollections } from "store/entities";
import consolidateInvestmentName from "lib/consolidateInvestmentName";
import { fetchInvestmentDetailsV2 } from "store/investments/investment/actions";

export function logAcquisition(formData, investmentId) {
  return (dispatch) => {
    dispatch(requestStart("createLogAcquisition"));

    const payload = {
      ...consolidateInvestmentName(formData, false, "series_name"),
    };

    return Api.post(`/v2/investments/${investmentId}/actions/acquisitions`, {
      investment_acquisition: payload,
    }).then(
      ({
        data: {
          investment_acquisition,
          entities,
          views: { portfolio: view },
        },
      }) => {
        dispatch(fetchInvestmentDetailsV2(investmentId));
        dispatch(receiveView("portfolio", view));
        dispatch(receiveCollections(entities));
        dispatch(requestSuccess("createLogAcquisition"));
        return investment_acquisition;
      },
      (errors) => {
        dispatch(requestError("createLogAcquisition"));
        throw errors;
      },
    );
  };
}
