import {
  fetchOrganizations,
  setCurrentOrganization,
} from "store/organizations/actions";
import getOrganizationPathname from "lib/pathname/getOrganizationPathname";
import isAValidOrganizationPathname from "lib/pathname/isAValidOrganizationPathname";

export default function loadOrganizations(
  dispatch,
  nextPathname = getOrganizationPathname(),
) {
  return dispatch(fetchOrganizations()).then((organizations) => {
    // Our default organization is the private one
    let currentOrganization = organizations.find(
      (organization) => organization.private,
    );
    const findOrganizationIndex = isAValidOrganizationPathname(
      nextPathname,
      organizations,
    );

    if (findOrganizationIndex > -1) {
      currentOrganization = organizations[findOrganizationIndex];
    }

    dispatch(setCurrentOrganization(currentOrganization));

    return { currentOrganization, organizations };
  });
}
