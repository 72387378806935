import React from "react";
import PropTypes from "prop-types";
import escapeStringRegexp from "escape-string-regexp";
import UserAvatar from "components_new/elements/UserAvatar";
import MightyIcon from "components_new/atoms/MightyIcon";
import CompanyIcon from "components_new/atoms/CompanyIcon";
import VerifiedBadge from "components_new/atoms/VerifiedBadge";
import Image from "components_new/atoms/Image";
import classnames from "classnames";
import { isEmpty } from "lodash";
import "./ResultCard.scss";

const DEFAULT_AVATAR = "/assets/images/user-avatar.png";

export function parseItemResult(title, query) {
  const escapedSearchTerm = escapeStringRegexp(query);
  const searchTermRegex = new RegExp(escapedSearchTerm, "gi");
  const parsedItemResult = title.replace(searchTermRegex, "%$&%").split("%");

  return parsedItemResult;
}

function ResultIcon({ type, logoUrl, size, initials }) {
  switch (type) {
    case "company":
      return logoUrl ? (
        <Image src={logoUrl} size={size} />
      ) : (
        <MightyIcon icon="company-med" />
      );
    case "contact":
      return (
        <UserAvatar
          showTooltip={false}
          initials={initials && initials}
          avatarUrl={!isEmpty(initials) && DEFAULT_AVATAR}
          size={size}
        />
      );
    case "tags":
      return null;
    default:
      const intialName = type === "deal" ? "D" : "H";
      return (
        <div className="ResultIcon">
          <span className="ResultIcon-text">{intialName}</span>
          <div className="ResultIcon-company">
            <CompanyIcon size="small" src={logoUrl} />
          </div>
        </div>
      );
  }
}

function ResultCard({
  title,
  description,
  subdescription,
  type,
  logoUrl,
  query,
  size,
  isVerified,
  initials,
}) {
  let customTitle = title;
  if (query) {
    const parsedResult = parseItemResult(title, query);
    customTitle = parsedResult.map((item, index) =>
      item.toLowerCase() === query.toLowerCase() ? (
        <span className="ResultCard-highlightMatch" key={index}>
          {item}
        </span>
      ) : (
        item
      ),
    );
  }

  const resultCardClassnames = classnames("ResultCard", {
    [type]: !!type,
    [size]: !!size,
  });

  return (
    <section className={resultCardClassnames}>
      <div className="ResultCard-icon">
        <ResultIcon
          type={type}
          logoUrl={logoUrl}
          size={size}
          initials={initials}
        />
      </div>
      <div className="ResultCard-content">
        <div className="ResultCard-title">
          <div className="ResultCard-titleContent u-textWithEllipsis">
            {customTitle}
          </div>
          {isVerified && (
            <span className="VerifiedBadge">
              <VerifiedBadge />
            </span>
          )}
        </div>
        {description && (
          <div className="ResultCard-description u-textWithEllipsis">
            {description}
          </div>
        )}
        {subdescription && (
          <span className="ResultCard-subdescription">{subdescription}</span>
        )}
      </div>
    </section>
  );
}

ResultCard.propTypes = {
  size: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  subdescription: PropTypes.string,
  query: PropTypes.string,
  type: PropTypes.string.isRequired,
  logoUrl: PropTypes.string,
  isVerified: PropTypes.bool,
  initials: PropTypes.string,
};

ResultCard.defaultProps = {
  size: "large",
  query: null,
  isVerified: false,
};

export default ResultCard;
