import React, { useContext, useEffect } from "react";
import LogConversionForm from "./LogConversionForm";
import useInvestmentConversion from "store/investments/v2/logConversion/hooks";
import useOrganizations from "store/organizations/hooks";
import useResourcesV2 from "store/resourcesV2/hooks";
import { SidebarComponentsContext } from "components_new/SidebarComponents/SidebarComponentsWrapper";
import { isEmpty } from "lodash";
import { useInvestment } from "components_new/SidebarComponents/views/InvestmentView/hooks/useInvestment";
import Loader from "components/shared/Loader";

function LogConversionFormWrapper({ ownName }) {
  const { getComponentState, registerForm, closeSidebar } = useContext(
    SidebarComponentsContext,
  );

  const {
    isAdmin,
    currentOrganizationName,
    isCurrentUserOrganizationOwner,
    currentOrganization: { username: currentOrganizationUserName },
  } = useOrganizations();

  const { investment } = getComponentState(ownName);

  const { getOrganizationResourceAsCollection } = useResourcesV2();
  const investmentCustomFields = getOrganizationResourceAsCollection(
    "customFields",
  );

  useEffect(() => {
    if (!isEmpty(ownName)) {
      registerForm(ownName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { createLoanConversion } = useInvestmentConversion();
  const { data, isLoading } = useInvestment(investment?.id);

  if (isLoading || isEmpty(investment)) {
    return <Loader />;
  }

  return (
    <LogConversionForm
      ownName={ownName}
      company={data?.company}
      investment={data?.details}
      createLoanConversion={createLoanConversion}
      closeSidebar={closeSidebar}
      isAdmin={isAdmin}
      isOrgOwner={isCurrentUserOrganizationOwner}
      investmentCustomFields={investmentCustomFields}
      currentOrganizationName={currentOrganizationName}
      currentOrganizationUserName={currentOrganizationUserName}
    />
  );
}

export default LogConversionFormWrapper;
