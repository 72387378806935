import React, { useState, useEffect } from "react";
import FormGroup from "components_new/elements/FormGroup";
import FormFooter from "components_new/SidebarComponents/SidebarForm/FormFooter";
import { SearchAndSelectField as SearchAndSelect } from "components_new/elements/SearchAndSelect";
import RequiredNote from "components_new/SidebarComponents/shared/RequiredNote";
import DatePicker from "components_new/atoms/DatePicker";
import CurrencyInput from "components_new/atoms/CurrencyInput";
import Documents from "components_new/SidebarComponents/forms/groups/Documents";

import { isEmpty, merge } from "lodash";
import useForm from "lib/hooks/useForm";
import {
  addTaskIdToForm,
  addTaskAttachmentsToFormDocuments,
} from "components_new/SidebarComponents/forms/config";
import { isUploading } from "components_new/elements/DropZone/config";

function generateInvestmentUrl(organizationUsername, investmentId) {
  return `/${organizationUsername}/investments/${investmentId}`;
}

function CapitalCallForm({
  ownName,
  taskId,
  taskAttachments,
  createCapitalCall,
  closeSidebar,
  prefilledSelection,
  showConfirmationMessage,
  currentOrganizationUsername,
}) {
  const INITIAL_VALUES = {
    formData: {
      selection: prefilledSelection || {},
      task_id: null,
      date: null,
      amount: null,
      documents_data: [],
      note: "",
    },
  };
  const [isLoading, setIsLoading] = useState(false);
  const [uploadQueue, setUploadQueue] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const initialValues = merge(
    {},
    INITIAL_VALUES,
    //TO-DO: separate task related items on props
    addTaskIdToForm(taskId),
    addTaskAttachmentsToFormDocuments(taskId, taskAttachments),
  );

  const submit = (clientData) => {
    setIsLoading(true);
    createCapitalCall(clientData.selection.id, clientData)
      .then((response) => {
        showConfirmationMessage(
          generateInvestmentUrl(currentOrganizationUsername, response.id),
        );
        closeSidebar();
      })
      .catch((error) => {
        handleErrors(error);
        handleScrollView(error);
      })
      .finally(() => setIsLoading(false));
  };
  const {
    formData,
    handleChange,
    handleSubmit,
    handleErrors,
    handleScrollView,
    errors,
    touched,
  } = useForm(initialValues, submit);
  // const { selection, date, amount, documents_data, note } = formData;
  const { selection, date, amount, documents_data } = formData;

  useEffect(() => {
    setDisableSubmit(
      isEmpty(selection.id) ||
        isEmpty(date) ||
        amount === null ||
        isUploading(uploadQueue),
    );
  }, [selection, date, amount, uploadQueue]);

  return (
    <div className="CapitalCallForm">
      {/* TODO: move to own component */}
      <FormGroup
        extraClassnames="CapitalCallGroup"
        title="Capital Call Details"
        required={true}
        isExpanded={true}
      >
        <SearchAndSelect
          name="selection"
          label="Funds"
          placeHolder="Select a fund"
          onChange={(value) => handleChange("selection", value)}
          scope="funds"
          size="tiny"
          initialSelection={formData.selection}
        />
        <DatePicker
          name="date"
          label="Date"
          extraClassnames="grid-50 grid-item"
          onChange={(value, validated) =>
            handleChange("date", value, validated)
          }
          errorMessage={errors.for("date")}
          error={!!errors.for("date")}
          value={formData.date}
          prefillToday={true}
          size="tiny"
        />
        <CurrencyInput
          decimalLimit={2}
          iconPosition="left"
          name="amount"
          label="Amount"
          placeholder="0.00"
          extraClassnames="grid-50 grid-item"
          onChange={(value) => handleChange("amount", value)}
          errorMessage={errors.for("amount")}
          value={formData.amount}
          size="tiny"
        />
      </FormGroup>
      <Documents
        isExpanded
        subtitle="Attach files to a company or investment"
        onChange={(documents) => handleChange("documents_data", documents)}
        onQueueChange={setUploadQueue}
        documents={documents_data}
        prefilledFilesStatus="editing"
        defaultDocumentType="Capital Call Notice"
      />
      <RequiredNote />
      {/* TODO: to be added back in later; removed for feature parity with GlobalCapitalCallModal */}
      {/* <Notes onChange={value => handleChange("note", value)} notes={note} /> */}
      <FormFooter
        componentName={ownName}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        disableSubmit={disableSubmit}
        touched={touched}
      />
    </div>
  );
}

export default CapitalCallForm;
