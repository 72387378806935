import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import NumberInput from "components_new/atoms/NumberInput";
import { Form } from "@ableco/semantic-ui-react";
import Input from "components_new/atoms/Input";
import HelperMessage from "components/shared/form/HelperMessage";
import "./CurrencyInput.scss";

function CurrencyInput(props) {
  const {
    label,
    decimalLimit,
    placeholder,
    iconPosition,
    defaultValue,
    textHelper,
    extraClassnames,
    size,
    ...inputProps
  } = props;

  const inputClassnames = classNames("Input CurrencyInput", {
    [size]: !!size,
    [extraClassnames]: !!extraClassnames,
  });

  if (inputProps.disabled) {
    return (
      <Fragment>
        <Input
          className={{ ...inputClassnames, left: iconPosition !== "left" }}
          disabled
          placeholder={"$" + placeholder}
          defaultValue={defaultValue && "$" + defaultValue.toString()}
          {...inputProps}
        />
        {textHelper && <HelperMessage text={textHelper} />}
      </Fragment>
    );
  }

  return (
    <Form.Field
      className={inputClassnames}
      control={NumberInput}
      label={<label data-testid="FieldMessage">{label}</label>}
      placeholder={placeholder}
      allowDecimal
      decimalLimit={decimalLimit}
      aria-label={label ? label : placeholder}
      defaultValue={defaultValue}
      textHelper={textHelper}
      size={size}
      icon={<i className="icon">$</i>}
      iconPosition={iconPosition}
      {...inputProps}
    />
  );
}

NumberInput.propTypes = {
  label: PropTypes.string,
  decimalLimit: PropTypes.number,
  placeholder: PropTypes.string,
  iconPosition: PropTypes.oneOf(["left", "right"]),
  defaultValue: PropTypes.string,
  size: PropTypes.oneOf(["tiny", "small", "regular"]),
};

NumberInput.defaultProps = {
  fieldLabel: null,
  decimalLimit: 2,
  placeholder: "",
  iconPosition: "left",
  defaultValue: null,
  size: "regular",
};

export default CurrencyInput;
