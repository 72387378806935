import React from "react";
import { InvestmentDetailsList } from "../components";

function DetailsList({
  investment,
  list,
  openReferrerModal,
  openContactDetailsView,
}) {
  return (
    <div className="InvestmentDetails-detailsList">
      <InvestmentDetailsList
        investment={investment}
        list={list}
        openReferrerModal={openReferrerModal}
        openContactDetailsView={openContactDetailsView}
      />
    </div>
  );
}

export default DetailsList;
