import PropTypes from "prop-types";
import React, { Component } from "react";
import DropdownMenuItem from "components/shared/DropdownMenuItem";

class DropdownMenu extends Component {
  renderDropdownItem(item, key) {
    return <DropdownMenuItem key={key} item={item} />;
  }

  render() {
    const { items, open } = this.props;
    return (
      open && (
        <ul className="DropdownMenu" data-testid="DropdownMenu">
          {items.map((item, index) => this.renderDropdownItem(item, index))}
        </ul>
      )
    );
  }
}

DropdownMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      onClickAction: PropTypes.func,
      isDisabled: PropTypes.bool,
    }),
  ).isRequired,
  open: PropTypes.bool.isRequired,
};

export default DropdownMenu;
