import React, { Component } from "react";
import { historyActionsProptype, companiesObjectPropType } from "PropTypes";
import PropTypes from "prop-types";
import Errors from "lib/errors";
import isServerValidationError from "lib/isServerValidationError";
import TableRow from "./TableRow";
import { shouldValidateQuantity } from "../config";

function initializePricePerShareValue(perShareOrTotal, quantity, amount) {
  return ["per_share", "per_quantity"].includes(perShareOrTotal)
    ? amount / quantity
    : amount;
}

function initializePricePerUnitValue(perShareOrTotal, quantity, amount) {
  return ["per_share", "per_quantity"].includes(perShareOrTotal)
    ? amount
    : amount * quantity;
}

function calculateActionsValue(perShareOrTotal, quantity, amount) {
  return ["per_share", "per_quantity"].includes(perShareOrTotal)
    ? amount
    : amount / quantity;
}

function calculatePurchasedValue(perShareOrTotal, quantity, amount) {
  return ["per_share", "per_quantity"].includes(perShareOrTotal)
    ? amount * quantity
    : amount;
}

const TYPE_OF_AMOUNTS = {
  purchased: {
    initializeFunction: initializePricePerShareValue,
    updateFunction: calculatePurchasedValue,
    receivedAmount: "total_acquisition_amount",
    createdAmountLabel: "per_share_or_total",
  },
  distributed: {
    initializeFunction: initializePricePerShareValue,
    updateFunction: calculatePurchasedValue,
    receivedAmount: "cost_basis",
    createdAmountLabel: "per_share_or_total",
  },
  acquired: {
    initializeFunction: initializePricePerShareValue,
    updateFunction: calculatePurchasedValue,
    receivedAmount: "cost_basis",
    createdAmountLabel: "price_cost_basis",
  },
  converted: {
    initializeFunction: initializePricePerShareValue,
    updateFunction: calculatePurchasedValue,
    receivedAmount: "first_valuation_amount",
    createdAmountLabel: "amount_first_valuation_temp",
  },
  sold: {
    initializeFunction: initializePricePerUnitValue,
    updateFunction: calculateActionsValue,
    receivedAmount: "price_per_unit",
    createdAmountLabel: "amount_price_per_unit",
  },
  written_off: {
    initializeFunction: () => {},
    updateFunction: () => {},
    receivedAmount: "",
    createdAmountLabel: "",
  },
  stock_splitted: {
    initializeFunction: () => {},
    updateFunction: () => {},
    receivedAmount: "",
    createdAmountLabel: "",
  },
};

function calculateAmount(formData, amountType, receivedValue, actionToDo) {
  const {
    amount_per_quantity_or_total,
    per_quantity_or_total_option,
    quantity,
  } = formData;
  return amountType[actionToDo](
    per_quantity_or_total_option || amount_per_quantity_or_total,
    quantity,
    formData[amountType[receivedValue]],
  );
}

const initialState = {
  openActiontId: null,
  actionType: null,
  formData: {},
  errors: new Errors({}),
  lastAction: null,
};

class TableBody extends Component {
  state = {
    ...initialState,
  };

  static propTypes = {
    collection: historyActionsProptype,
    companies: companiesObjectPropType,
    columns: PropTypes.arrayOf(PropTypes.shape({})),
    fetchAfterUpdateDocuments: PropTypes.func,
    openDocumentsViewerModal: PropTypes.func,
    openDeleteCertificateModal: PropTypes.func,
    updateCertificateHistory: PropTypes.func,
    isSubmitting: PropTypes.bool,
    organizationUsername: PropTypes.string.isRequired,
  };

  showEvent = ({ openActiontId, actionType }) => {
    this.setState({
      actionType,
      openActiontId,
      formData: {},
      errors: new Errors({}),
    });
  };

  initializeFormData(formData) {
    const newFormData = formData;
    if (shouldValidateQuantity(newFormData)) {
      const amountType = TYPE_OF_AMOUNTS[newFormData.actionType];
      const amount = calculateAmount(
        newFormData,
        amountType,
        "receivedAmount",
        "initializeFunction",
      );
      newFormData[amountType["createdAmountLabel"]] = amount;
    }

    this.setState({
      ...this.state,
      formData: {
        ...newFormData,
      },
    });
  }

  updateForm = (key, value) => {
    const newFormData = { ...this.state.formData, [key]: value };
    if (shouldValidateQuantity(newFormData)) {
      if (
        [
          "quantity",
          "amount_per_quantity_or_total",
          "per_quantity_or_total_option",
          "per_share_or_total",
          "amount_price_per_unit",
          "price_cost_basis",
          "amount_first_valuation_temp",
          "total_valuation_amount",
        ].includes(key)
      ) {
        // Exist one problem, when the user delete price per share in a equity purchased we calculate to total_acquisition_amount and if is null we need to send null instead of 0
        if (
          key === "per_share_or_total" &&
          !value &&
          newFormData.type === "equity"
        ) {
          newFormData.total_acquisition_amount = null;
        } else {
          const amountType = TYPE_OF_AMOUNTS[newFormData.actionType];
          const amount = calculateAmount(
            newFormData,
            amountType,
            "createdAmountLabel",
            "updateFunction",
          );
          newFormData[amountType["receivedAmount"]] = amount;
        }
      }
    }

    if (
      newFormData.type === "llc" &&
      key === "membership_units_or_percentage"
    ) {
      if (value === "units") {
        delete newFormData.percentage_owned;
      } else {
        delete newFormData.quantity;
      }
    }

    this.setState({
      formData: {
        ...newFormData,
      },
    });
  };

  sendForm = (certificateId) => {
    this.props
      .updateCertificateHistory(certificateId, this.state.formData)
      .then(
        () => {
          const findLastAction = this.props.collection.find(
            (action) => action.id === certificateId,
          );
          this.setState({
            ...initialState,
            lastAction: findLastAction,
          });
        },
        (error) => {
          if (isServerValidationError(error)) {
            const { data } = error;
            this.setState({
              errors: new Errors(data.errors),
            });
          }
        },
      );
  };

  render() {
    const {
      columns,
      collection,
      companies,
      fetchAfterUpdateDocuments,
      openDocumentsViewerModal,
      openDeleteCertificateModal,
      isSubmitting,
      organizationUsername,
    } = this.props;
    return (
      <div className="CertificateHistoryTable--Body">
        {collection.map((historyAction, index) => (
          <TableRow
            key={index}
            rowIndex={index}
            historyAction={historyAction}
            formData={this.state.formData}
            columns={columns}
            errors={this.state.errors}
            showEvent={this.showEvent}
            companies={companies}
            lastAction={this.state.lastAction}
            actionType={this.state.actionType}
            isOpen={this.state.openActiontId === historyAction.id}
            updateForm={this.updateForm}
            sendForm={this.sendForm}
            initializeFormData={(formData) => this.initializeFormData(formData)}
            fetchAfterUpdateDocuments={fetchAfterUpdateDocuments}
            openDocumentsViewerModal={openDocumentsViewerModal}
            openDeleteCertificateModal={openDeleteCertificateModal}
            isSubmitting={isSubmitting}
            organizationUsername={organizationUsername}
          />
        ))}
      </div>
    );
  }
}

export default TableBody;
