import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tab from "components_new/elements/Tab";
import { panes, panesTabIndex } from "./config";
import { useNavigate } from "react-router-dom";

const DEFAULT_TAB = panesTabIndex.all;

function PortfolioTab({ data }) {
  const { currentTab, portfolioId, orgUsername } = data;
  const [activeTabIndex, setActiveTabIndex] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    let activeTab = DEFAULT_TAB;
    if (currentTab) {
      activeTab = panesTabIndex[currentTab];
    }
    setActiveTabIndex(activeTab);
  }, [currentTab, portfolioId]);

  const handleTabChange = (_, { panes, activeIndex }) => {
    const tab = panes[activeIndex].path;
    navigate(`/${orgUsername}/portfolios/${portfolioId}/${tab || ""}`);
  };

  return (
    <Tab
      panes={panes({ ...data, currentTab, portfolioId })}
      onTabChange={handleTabChange}
      activeIndex={activeTabIndex}
      basicType
    />
  );
}

PortfolioTab.propTypes = {
  data: PropTypes.shape({
    portfolioId: PropTypes.string.isRequired,
    currentTab: PropTypes.string,
    orgUsername: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
  }),
  actions: PropTypes.shape({
    handleIsStatsLoading: PropTypes.func,
  }),
};

export default PortfolioTab;
