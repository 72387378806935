import Api from "lib/Api";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import addPost from "lib/posts/addPost";

export function useCreateNote() {
  const { organizationUsername } = useParams();
  const queryClient = useQueryClient();

  return useMutation(
    (formData) => {
      return Api.post(`/v2/notes`, {
        post: formData,
      });
    },
    {
      onSuccess: ({ data: newNote }) => {
        const params = {
          queryClient,
          organizationUsername,
          newNote,
        };

        addPostInDealView(params);
        addPostInCompanyView(params);
      },
      onError: (error) => {
        throw error;
      },
    },
  );
}

function addPostInDealView({ queryClient, newNote }) {
  const { resource } = newNote;
  if (resource.type.toLowerCase() === "deal") {
    const key = ["Deal", resource.deal_id];
    const dealView = queryClient.getQueryData(key);
    if (dealView) {
      queryClient.setQueryData(key, {
        ...dealView,
        documents: [...dealView.documents, ...newNote.documents_data],
        posts: addPost(dealView.posts, newNote),
      });
    }
  }
}

function addPostInCompanyView({ queryClient, organizationUsername, newNote }) {
  const { resource } = newNote;
  if (resource.type.toLowerCase() === "company") {
    const key = ["Company", organizationUsername, resource.company_id];
    const companyView = queryClient.getQueryData(key);

    if (companyView) {
      queryClient.setQueryData(key, {
        ...companyView,
        companyPosts: addPost(companyView.companyPosts, newNote),
      });
    }
  }
}
