import React, { useCallback, useState } from "react";
import TextInput from "components/shared/form/TextInput";
import PrimaryButton from "components/shared/PrimaryButton";
import { useMutation } from "react-query";
import Api from "lib/Api";

function PasswordCheck({ setTokenInfo }) {
  const [password, setPassword] = useState("");
  const [hasError, setHasError] = useState(false);

  const updatePasswordState = (value) => {
    setPassword(value);
    setHasError(false);
  };

  const createAsyncToken = useCallback(async () => {
    const {
      data: {
        async_auth_token: token,
        async_auth_token_expires_at: expiresAt,
        async_auth_token_id: id,
        phone_number: phoneNumber,
      },
    } = await Api.post(
      "/async_auth_token",
      {
        current_password: password,
      },
      { withoutOrganization: true },
    );

    setTokenInfo({ id, token, expiresAt, phoneNumber, password });
  }, [password, setTokenInfo]);

  const { mutate: createAsyncTokenMutate, isLoading } = useMutation(
    createAsyncToken,
    {
      onError: () => {
        setHasError(true);
      },
    },
  );

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      createAsyncTokenMutate();
    },
    [createAsyncTokenMutate],
  );

  return (
    <div className="LoginInformationForm">
      <form onSubmit={handleSubmit}>
        <h2 className="FormSection-title u-sectionHeading">
          Login Information
        </h2>
        <div className="FormSection-Body">
          <p className="FormElement FormElement--UserMessage">
            Please enter your password to edit your phone number and password.
          </p>
          <TextInput
            autoFocus
            label="Password"
            extraClassnames="FormElement--Password"
            value={password}
            typeOfInput="password"
            errorMessage={hasError ? "Password is not valid" : null}
            onChange={updatePasswordState}
          />
        </div>
        <div className="FormSection-Footer">
          <PrimaryButton
            customClass="FormElement FormElement--SubmitButton"
            disabled={password.trim() === ""}
            type="submit"
            filter={{
              applyingFilter: isLoading,
            }}
          >
            Continue
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
}

export default PasswordCheck;
