import PropTypes from "prop-types";
import React from "react";

function InvitationRevoked(props) {
  return (
    <div className="login revoked-invitation">
      <div className="container-fluid">
        <div className="login-title">
          <img alt="Mighty Logo" src="/assets/images/mighty-logo-black.svg" />
        </div>
        <div className="login-form">
          <h1> Your invitation was revoked </h1>
          <p className="text-center">
            Your invitation to manage <strong>{props.portfolioName}</strong>{" "}
            <br />
            has been revoked.
          </p>
          <p className="text-center">
            Contact<span className="inviter-name"> {props.inviterName} </span>
            if you think this was done by mistake.
          </p>
          <a className="back-to" href="/login">
            Back to Mighty Portfolio Home
          </a>
        </div>
      </div>
    </div>
  );
}

InvitationRevoked.propTypes = {
  portfolioName: PropTypes.string.isRequired,
  inviterName: PropTypes.string.isRequired,
};

export default InvitationRevoked;
