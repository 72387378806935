import React, { useCallback, useState } from "react";
import SnapshotFormSidebar from "./Sidebar";
import { Link, useParams, useNavigate } from "react-router-dom";
import useForm from "lib/hooks/useForm";
import Text from "components_new/atoms/Text";
import Form from "components_new/atoms/Form";
import ThreePaneLayout from "components_new/layouts/ThreePaneLayout";
import SnapshotBasic from "./SnapshotBasic";
import SnapshotContents from "./SnapshotContents";
import SnapshotFormSubmit from "./SnapshotFormSubmit";
import { merge } from "lodash";
import "./SnapshotForm.scss";

const INITIAL_VALUES = {
  formData: {
    name: "",
    description: "",
    data: {},
  },
};

function SnapshotForm({
  isEditing,
  snapshot,
  submitAction,
  showConfirmationMessage,
  snapshotType,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { organizationUsername } = useParams();
  const navigate = useNavigate();

  const initialValues = merge({}, INITIAL_VALUES, {
    formData: { ...snapshot, snapshot_type: snapshotType },
  });

  const navigateToSnapshots = useCallback(() => {
    navigate(`/${organizationUsername}/reports/snapshots`);
  }, [navigate, organizationUsername]);

  const submit = async (clientData) => {
    setIsLoading(true);
    const formData = {
      ...clientData,
    };
    try {
      await submitAction(formData);
      showConfirmationMessage();
      navigateToSnapshots();
    } catch (error) {
      setIsLoading(false);
      handleErrors(error);
    }
  };

  const {
    formData,
    handleChange,
    handleSubmit,
    handleErrors,
    resetForm,
    errors,
  } = useForm(initialValues, submit);

  const onExcludeStealh = useCallback(() => {
    const updatedFormData = {
      ...formData,
      data: formData.data.map((section) => ({
        ...section,
        data: section.data.filter((item) => !item.meta.stealth),
      })),
    };

    resetForm(updatedFormData);
  }, [formData, resetForm]);

  const submitTextButton = !isEditing ? "Save Snapshot" : "Save Changes";

  return (
    <div className="SnapshotForm">
      <ThreePaneLayout
        children={
          <Form onSubmit={handleSubmit} className="Pane-Wrapper">
            <div className="Pane-Header">
              <h4>
                <Link to={`/${organizationUsername}/reports/snapshots`}>
                  All Snapshots
                </Link>{" "}
                {">"} {isEditing ? `Edit ${snapshot.name}` : "New Snapshot"}
              </h4>
              <SnapshotFormSubmit
                onSubmit={handleSubmit}
                isLoading={isLoading}
                submitTextButton={submitTextButton}
                onClose={navigateToSnapshots}
              />
            </div>
            <div className="Pane-Body">
              <SnapshotBasic
                formData={formData}
                handleChange={handleChange}
                errors={errors}
              />
              <SnapshotContents
                formData={formData}
                handleChange={handleChange}
                errors={errors}
              />
              <Text type="body-2-regular" color="brand-light">
                *Required
              </Text>
            </div>
            <div className="Pane-Footer">
              <SnapshotFormSubmit
                onSubmit={handleSubmit}
                isLoading={isLoading}
                submitTextButton={submitTextButton}
                onClose={navigateToSnapshots}
              />
            </div>
          </Form>
        }
        sidebar={
          <div className="Pane-Wrapper">
            <div className="Pane-Body">
              <SnapshotFormSidebar
                formData={formData}
                onChange={handleChange}
                onExcludeStealh={onExcludeStealh}
                errors={errors}
              />
            </div>
          </div>
        }
      />
    </div>
  );
}

export default SnapshotForm;
