import React from "react";

function HelperContent({ content }) {
  return (
    <div
      className="HelperContent"
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
}

export default HelperContent;
