import DeleteCustomField from "components/customFields/DeleteCustomField";
import DeleteCertificateConfirmation from "components/investments/CertificateHistory/DeleteCertificateConfirmation";
import DeleteInvestment from "components/investments/DeleteInvestment";
import ConfirmOrganizationDeletion from "components/organizations/DeleteOrganization/ConfirmDeletion";
import ValidateOrganizationDeletion from "components/organizations/DeleteOrganization/ValidateDeletion";
import LeaveOrganization from "components/organizations/OrganizationMembers/LeaveOrganization";
import RemoveOrganizationMember from "components/organizations/OrganizationMembers/RemoveOrganizationMember";
import ReferrerModal from "components/referrals/ReferrerModal";
import RejectInvitation from "components/users/Organizations/RejectInvitation";
import DeleteTag from "components_new/elements/InputTag/DeleteTag";
import GlobalFormWarn from "components_new/SidebarComponents/SidebarForm/GlobalFormWarn";
import ConfirmDeleteContact from "components_new/views/Contacts/ConfirmDeleteContact";
import CommitToDeal from "components_new/views/Deals/shared/CommitToDeal";
import PassFromDeal from "components_new/views/Deals/shared/PassFromDeal";
import ReactivateDeal from "components_new/views/Deals/shared/ReactiveDeal";
import RemoveDeal from "components_new/views/Deals/shared/RemoveDeal";
import ConfirmPortfolioDeletion from "components_new/views/Portfolios/PortfolioActions/ConfirmDeletePortfolio";
import ValidatePortfolioDeletion from "components_new/views/Portfolios/PortfolioActions/ValidateDeletePortfolio";
import ConfirmTaskDeletion from "components_new/views/Queues/shared/ConfirmDeleteTask";
import ActionsStageForm from "components_new/views/Settings/DealStages/ActionsStage";
import Education from "components_new/views/Queues/shared/Education";
import RemoveCoInvestment from "components_new/views/Company/shared/RemoveCoInvestment";

export default {
  referrerModal: {
    component: ReferrerModal,
    size: "tiny",
  },
  deleteTag: {
    component: DeleteTag,
    size: "tiny",
  },
  deleteInvestment: {
    component: DeleteInvestment,
    size: "tiny",
  },
  deleteCustomField: {
    component: DeleteCustomField,
    size: "tiny",
  },
  deleteCertificate: {
    component: DeleteCertificateConfirmation,
    size: "tiny",
  },
  removeDeal: {
    component: RemoveDeal,
    size: "tiny",
  },
  reactivateDeal: {
    component: ReactivateDeal,
    size: "tiny",
  },
  removeOrganizationMember: {
    component: RemoveOrganizationMember,
    size: "tiny",
  },
  rejectInvitation: {
    component: RejectInvitation,
    size: "tiny",
  },
  leaveOrganization: {
    component: LeaveOrganization,
    size: "tiny",
  },
  validateOrganizationDeletion: {
    component: ValidateOrganizationDeletion,
    size: "tiny",
  },
  confirmOrganizationDeletion: {
    component: ConfirmOrganizationDeletion,
    size: "tiny",
  },
  validatePortfolioDeletion: {
    component: ValidatePortfolioDeletion,
    size: "tiny",
  },
  confirmPortfolioDeletion: {
    component: ConfirmPortfolioDeletion,
    size: "tiny",
  },
  confirmTaskDeletion: {
    component: ConfirmTaskDeletion,
    size: "tiny",
  },
  confirmDeleteContact: {
    component: ConfirmDeleteContact,
    size: "tiny",
  },
  globalFormWarn: {
    component: GlobalFormWarn,
    size: "tiny",
  },
  passFromDeal: {
    component: PassFromDeal,
    size: "tiny",
  },
  commitToDeal: {
    component: CommitToDeal,
    size: "tiny",
  },
  actionsStage: {
    component: ActionsStageForm,
    size: "tiny",
  },
  queueModal: {
    component: Education,
    size: "small",
  },
  removeCoInvestment: {
    component: RemoveCoInvestment,
    size: "tiny",
  },
};
