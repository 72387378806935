import React from "react";
import CompanyInfo from "components_new/elements/CompanyInfo";
import CompanyFlags from "components_new/views/CompanyDirectory/shared/CompanyFlags";
import CompanyLinkValue from "components_new/views/Company/shared/CompanyLinkValue";
import Link from "components_new/atoms/Link";
import EllipsisDropdown from "components_new/elements/EllipsisDropdown";
import { TableCell, TableRow } from "components_new/elements/Table";

const WEBSITE_PREFILL = "http://";

function CompanyInvestorRow({
  organizationUsername,
  openEditCoInvestmentForm,
  openDeleteCoInvestment,
  coInvestment,
}) {
  const { round_name: roundName, investor } = coInvestment;

  const investorActions = [
    {
      key: "edit",
      text: "Edit Investor",
      onClick: () => openEditCoInvestmentForm(coInvestment),
    },
    {
      key: "delete",
      text: "Delete Investor",
      onClick: () => openDeleteCoInvestment(coInvestment),
    },
  ];

  const companyDetailUrl = `/${organizationUsername}/companies/${investor.id}`;
  const statusInfo =
    status === "Active" ? "" : `${investor.status.toUpperCase()}`;

  return (
    <TableRow>
      <TableCell className="CompanyColumn">
        <CompanyInfo
          title={() => (
            <div className="CompanyName">
              <div className="CompanyNameTitle">
                <Link
                  to={companyDetailUrl}
                  extraClassnames="CompanyNameTitle-link"
                >
                  {investor.name}
                </Link>
                <span className="CompanyNameTitle-status">{statusInfo}</span>
              </div>
              {investor.website && (
                <CompanyLinkValue
                  url={investor.website}
                  label={investor.website}
                  preFilled={WEBSITE_PREFILL}
                />
              )}
            </div>
          )}
          content={(investor) => <CompanyFlags company={investor} />}
          company={investor}
          tier={investor.tier}
        />
      </TableCell>
      <TableCell className="RoundNameColumn">{roundName}</TableCell>
      <TableCell className="TagsColumn">{investor.tags.join(", ")}</TableCell>
      <TableCell className="ActionColumn u-textAlignRight">
        <EllipsisDropdown
          options={investorActions}
          iconOrientation="vertical"
          iconSize="small"
        />
      </TableCell>
    </TableRow>
  );
}

export default CompanyInvestorRow;
