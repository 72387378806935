import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Modal as SemanticModal } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/modal.css";

function ModalFooter({ children, withBorder, extraClassnames, ...props }) {
  const modalActionsClassnames = classnames("Modal-footer", {
    "with-border": withBorder,
    [extraClassnames]: !!extraClassnames,
  });
  return (
    <SemanticModal.Actions className={modalActionsClassnames} {...props}>
      {children}
    </SemanticModal.Actions>
  );
}

ModalFooter.propTypes = {
  children: PropTypes.node.isRequired,
  withBorder: PropTypes.bool,
  extraClassnames: PropTypes.string,
};

ModalFooter.defaultProps = {
  withBorder: false,
};

export default ModalFooter;
