import React, { Component } from "react";
import { getText } from "TermsMapper";
import classNames from "classnames";
import { formatPriceInputPreview } from "lib/abbreviatedFormat";
import TooltipHolder from "components/shared/TooltipHolder";
import { createHighlightContent } from "../config";

function getLabel(type) {
  switch (type) {
    case "debt":
      return "cost";
    case "fund":
      return "";
    default:
      return getText(type, "quantity_label");
  }
}

function isFundOrDebtOrLlcWithUnits(historyAction) {
  const { type, membership_units_or_percentage } = historyAction;
  return (
    type === "fund" ||
    type === "debt" ||
    (type === "llc" && membership_units_or_percentage !== "units")
  );
}

function costBasisValue(historyAction) {
  return isFundOrDebtOrLlcWithUnits(historyAction)
    ? historyAction.cost_basis
    : historyAction.unit_cost_basis;
}

function CostBasisView({ historyAction, isUpdated, lastAction }) {
  const { membership_units_or_percentage, type } = historyAction;
  const tooltipText = isUpdated ? createHighlightContent(lastAction) : null;
  const costBasisLabel =
    membership_units_or_percentage === "percentage" ? "" : getLabel(type);
  return (
    <div className="CostBasisView">
      <TooltipHolder
        extraClassnames="CostBasisView-value"
        tooltip={tooltipText}
        showTooltip={!!tooltipText}
        position="top"
      >
        {formatPriceInputPreview(costBasisValue(historyAction))}
      </TooltipHolder>
      <div>{costBasisLabel}</div>
    </div>
  );
}

class CostBasisCellPreview extends Component {
  state = {
    isUpdated: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { historyAction, isSubmitting } = this.props;
    const {
      historyAction: nextHistory,
      isSubmitting: nextIsSubmitting,
    } = nextProps;
    const prevCostBasis = costBasisValue(historyAction);
    const newCostBasis = costBasisValue(nextHistory);
    if (nextHistory && newCostBasis !== prevCostBasis) {
      this.setState({ isUpdated: true });
    } else if (this.state.isUpdated && nextIsSubmitting !== isSubmitting) {
      this.setState({ isUpdated: false });
    }
  }

  render() {
    const { historyAction, isTerminalAction, lastAction } = this.props;
    const costBasisClassNames = classNames("CostBasisCellPreview", {
      "is-updated": this.state.isUpdated,
    });
    return (
      <div className={costBasisClassNames}>
        {!isTerminalAction ? (
          <CostBasisView
            historyAction={historyAction}
            isUpdated={this.state.isUpdated}
            lastAction={lastAction}
          />
        ) : (
          "- - -"
        )}
      </div>
    );
  }
}

export default CostBasisCellPreview;
