import React from "react";
import Header from "components_new/atoms/Header";
import EmptyCard from "components_new/elements/EmptyCard";
import Table, {
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "components_new/elements/Table";
import CompanyCashFlowRow from "./CompanyCashFlowRow";

import "./CompanyCashFlowTable.scss";

function formatCashFlowTableData(cashFlows, portfolios) {
  const cashFlowObjects = cashFlows.map((cashFlow) => {
    if (!cashFlow) {
      return {};
    }
    const portfolio = portfolios.find(
      (portfolio) => portfolio.id === cashFlow.portfolio_id,
    );
    const dataObject = {
      date: cashFlow.date,
      description: cashFlow.transaction_type,
      subDescription: cashFlow.transaction_name,
      portfolio: portfolio.name,
      amount: cashFlow.amount,
      total: cashFlow.total,
    };
    return dataObject;
  });
  return cashFlowObjects;
}

export default function CompanyCashFlowTable({
  cashFlows,
  portfolios,
  ...restProps
}) {
  if (!(cashFlows && cashFlows.length > 0)) {
    return <EmptyCard title="Cash Flows" label="Cash Flows" {...restProps} />;
  }
  const cashFlowData = formatCashFlowTableData(cashFlows, portfolios);

  return (
    <div className="CompanyCashFlowTable" {...restProps}>
      <Header as="h5">Cash Flows</Header>
      <Table singleLine>
        <TableHeader className="CompanyCashFlowHeader">
          <TableRow>
            <TableHeaderCell>Date</TableHeaderCell>
            <TableHeaderCell>Description</TableHeaderCell>
            <TableHeaderCell>Portfolio</TableHeaderCell>
            <TableHeaderCell>Amount</TableHeaderCell>
            <TableHeaderCell>Total</TableHeaderCell>
          </TableRow>
        </TableHeader>

        <TableBody>
          {cashFlowData.map((cashFlow, index) => {
            return (
              <CompanyCashFlowRow
                cashFlow={cashFlow}
                key={`cashflowrow-${index}`}
              />
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}
