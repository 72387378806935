import { isEmpty, isPlainObject, isString, mapValues } from "lodash";

const handleEmptyOrNoLength = (value) => {
  /**
   * coerce empty strings
   * pass empty arrays and objects
   * pass numbers and booleans
   */
  return value === "" ? null : value;
};
const handleStrings = (value) => {
  /*  eslint-disable unicorn/no-nested-ternary */
  return value.trim() === ""
    ? // coerce values made of whitespace
      null
    : value.includes("</")
    ? // pass html values
      value
    : // coerce padded strings
      value.replace(/\s\s+/g, " ").trim();
  /*  eslint-enable unicorn/no-nested-ternary */
};
const handleAllArrays = (array) =>
  // including arrays of objects
  array.map((element) =>
    handleCollectionsNumbersBooleansStringsEmptiesNulls(element),
  );
const handleAllObjects = (object) =>
  // including objects of complex formData
  mapValues(object, handleCollectionsNumbersBooleansStringsEmptiesNulls);

const handleCollectionsNumbersBooleansStringsEmptiesNulls = (value) => {
  if (isEmpty(value)) {
    return handleEmptyOrNoLength(value);
  } else {
    if (isString(value)) {
      return handleStrings(value);
    }
    if (Array.isArray(value)) {
      return handleAllArrays(value);
    }
    if (isPlainObject(value)) {
      return handleAllObjects(value);
    }
  }
};

export default function ensureFormData(formData) {
  return mapValues(
    formData,
    handleCollectionsNumbersBooleansStringsEmptiesNulls,
  );
}
