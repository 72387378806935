import { useMutation, useQueryClient } from "react-query";
import Api from "lib/Api";
import useOrganizations from "store/organizations/hooks";
import addPost from "lib/posts/addPost";

export function useCreateCompanyPost() {
  const queryClient = useQueryClient();
  const {
    currentOrganization: { username: currentOrganizationUsername },
  } = useOrganizations();

  return useMutation(
    ({ resource, formData }) => {
      return Api.post(`/companies/${resource.company_id}/posts`, {
        post: formData,
      });
    },
    {
      onSuccess: ({ data: newPost }) => {
        const companyId = newPost.resource.company_id;
        const key = ["Company", currentOrganizationUsername, companyId];
        const companyView = queryClient.getQueryData(key);

        queryClient.setQueryData(key, {
          ...companyView,
          companyPosts: addPost(companyView.companyPosts, newPost),
        });
      },
      onError: (error) => {
        throw error;
      },
    },
  );
}
