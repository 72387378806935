import { useQuery } from "react-query";
import Api from "lib/Api";

const selectorToState = {
  ActiveDeals: "active",
  PassedDeals: "passed",
  CommittedDeals: "committed",
  SearchDeals: "",
};

export function useDeals(selector, pipelineId, filters = {}) {
  return useQuery(
    [selector, pipelineId, filters],
    async () => {
      const state = selectorToState[selector];

      const { data } = await Api.get(
        `/v2/pipelines/${pipelineId}/deals/${state}`,
        {
          params: { ...filters },
        },
      );

      return {
        deals: data.deals,
        owners: data.meta?.owners,
        headquarters: data.meta?.companies_headquarters,
      };
    },
    {
      staleTime: 5000,
    },
  );
}
