import { useMutation, useQueryClient } from "react-query";
import { buildPostResourceRoute } from "lib/globalActionsHelper";
import addPost from "lib/posts/addPost";
import Api from "lib/Api";

function addDealPost(oldData, newPost) {
  return {
    ...oldData,
    posts: addPost(oldData.posts, newPost),
  };
}

export function useCreateDealPost() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ resource, post }) => {
      const postResourceRoute = buildPostResourceRoute(resource);
      return Api.post(`${postResourceRoute}/posts`, { post });
    },
    {
      onSuccess: ({ data: newPost }, { resource }) => {
        return queryClient.setQueryData(["Deal", resource.deal_id], (oldData) =>
          addDealPost(oldData, newPost),
        );
      },
    },
  );
}
