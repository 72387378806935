import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  historyActionProptype,
  companiesObjectPropType,
  errorsPropType,
} from "PropTypes";
import NoteEvent from "../contentEvent/view/NoteEvent";
import ContentEvent from "../contentEvent";
import TextArea from "components/shared/form/TextArea";
import SecondaryButton from "components/shared/SecondaryButton";
import PrimaryButton from "components/shared/PrimaryButton";

function Note({ actionType, historyAction, updateForm }) {
  const { action_params: actionParams } = historyAction;
  const { notes } = actionParams;
  if (actionType !== "edit") {
    if (!notes) {
      return null;
    }
    return <NoteEvent content={notes} />;
  }
  return (
    <TextArea
      defaultValue={notes || ""}
      onChange={(value) => updateForm("notes", value)}
      label="Notes"
      rows="3"
      size="small"
      isOptional
    />
  );
}

function TableRowContent({
  historyAction,
  companies,
  actionType,
  updateForm,
  showEvent,
  sendForm,
  isSubmitting,
  rowIndex,
  formData,
  errors,
  organizationUsername,
}) {
  const TableRowContentClassNames = classNames("TableRowContent", {
    "is-viewing": actionType === "view",
    "is-editing": actionType === "edit",
    "submitting-form": isSubmitting,
    "is-deleting": actionType === "delete",
  });

  return (
    <div className={TableRowContentClassNames}>
      <div className="TableRowContent-content">
        <ContentEvent
          historyAction={historyAction}
          formData={formData}
          companies={companies}
          actionType={actionType}
          rowIndex={rowIndex}
          updateForm={updateForm}
          errors={errors}
          showEvent={showEvent}
          organizationUsername={organizationUsername}
        />
        <Note
          historyAction={historyAction}
          actionType={actionType}
          updateForm={updateForm}
        />

        {actionType === "edit" && (
          <div className="TableRowContent--submitForm">
            <PrimaryButton
              size="small"
              onClick={() => sendForm(historyAction.id)}
              disabled={isSubmitting}
              filter={{
                applyingFilter: isSubmitting,
              }}
            >
              Save Changes
            </PrimaryButton>
            <SecondaryButton
              size="small"
              onClick={() =>
                showEvent({ openActiontId: null, actionType: null })
              }
            >
              Cancel
            </SecondaryButton>
          </div>
        )}
      </div>
    </div>
  );
}

TableRowContent.propTypes = {
  historyAction: historyActionProptype,
  actionType: PropTypes.oneOf(["view", "edit", "delete"]),
  fetchAfterUpdateDocuments: PropTypes.func,
  openDocumentsViewerModal: PropTypes.func,
  updateForm: PropTypes.func,
  showEvent: PropTypes.func,
  sendForm: PropTypes.func,
  companies: companiesObjectPropType,
  isSubmitting: PropTypes.bool,
  rowIndex: PropTypes.number,
  formData: PropTypes.shape({}),
  errors: errorsPropType,
  organizationUsername: PropTypes.string.isRequired,
};

export default TableRowContent;
