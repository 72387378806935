import React from "react";
import FormGroup from "components_new/elements/FormGroup";
import CurrencyInput from "components_new/atoms/CurrencyInput";
import "./RealizedGain.scss";

function RealizedGain({
  title = "Realized Gain",
  subtitle,
  realizedGain,
  onChange,
  isExpanded = false,
  required = false,
  errors,
}) {
  const fieldLabel = required ? "Amount*" : "Amount";
  return (
    <FormGroup
      extraClassnames="RealizedGainGroup"
      title={title}
      required={required}
      subtitle={subtitle}
      isExpanded={isExpanded}
    >
      <CurrencyInput
        name="cash_amount"
        label={fieldLabel}
        defaultValue={realizedGain}
        placeholder="0.00"
        value={realizedGain}
        onChange={(value) => {
          onChange("cash_amount", value);
        }}
        decimalLimit={2}
        iconPosition="left"
        size="tiny"
        error={!!errors.for("cash_amount")}
        errorMessage={errors.for("cash_amount")}
      />
    </FormGroup>
  );
}

export default RealizedGain;
