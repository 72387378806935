import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import moment from "moment";
import { Grid } from "@ableco/semantic-ui-react";
import Segment from "components_new/atoms/Segment";
import Button from "components_new/atoms/Button";
import "./TableFilter.scss";
import { isEmpty } from "lodash";
import Icon from "components_new/atoms/Icon";
import Dropdown, { DropdownMenu } from "components_new/atoms/Dropdown";
import DropdownForm from "components_new/atoms/DropdownForm";
import pluralize from "pluralize";
import classnames from "classnames";
import InputTag from "components_new/elements/InputTag";
import ClearableRadioGroup from "components_new/atoms/ClearableRadioGroup";
import DatePicker, { valueDateFormat } from "components_new/atoms/DatePicker";
import { tagSuggestionsProptype } from "PropTypes";
import { dropdownOption } from "components_new/customPropTypes";
import PropTypes from "prop-types";
import { CUSTOM_RANGE_VALUE, RADIO_DATE_OPTIONS } from "./config.js";

const validateDateRange = (start, end) => {
  if (!start || !end) return true;
  return moment(start, valueDateFormat).isSameOrBefore(
    moment(end, valueDateFormat),
  );
};

function TableFilter({
  onApplyFilters,
  values,
  className,
  filterTags,
  tagSuggestions,
  headquarters,
  owners,
}) {
  const [reInit, setReInit] = useState(false);
  const [dateFilter, setDateFilter] = useState("");
  const [customRangeStart, setCustomRangeStart] = useState(null);
  const [customRangeEnd, setCustomRangeEnd] = useState(null);
  const [tagFilters, setTagFilters] = useState([]);
  const [headquartersFilter, setHeadquartersFilter] = useState(null);
  const [ownersFilter, setOwnersFilter] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(0);
  //When filters are applied, fall back to the following working filter state on cancel:
  const [workingFilters, setWorkingFilters] = useState(null);

  const buttonFilterName = pluralize(
    "Filter",
    filtersApplied,
    filtersApplied > 0,
  );

  const dropdownMenu = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  });

  const handleReset = () => {
    setReInit(true);
    setDateFilter("");
    setCustomRangeStart("");
    setCustomRangeEnd("");
    setTagFilters([]);
    setHeadquartersFilter(null);
    setOwnersFilter(null);
  };

  const handleCancel = () => {
    const {
      tag_filters,
      headquarters_filter,
      date_filters: { dates, range_start, range_end },
      owners_filter,
    } = workingFilters;
    setReInit(true);
    setTagFilters(tag_filters);
    setHeadquartersFilter(headquarters_filter);
    setOwnersFilter(owners_filter);
    setDateFilter(dates);
    setCustomRangeStart(range_start);
    setCustomRangeEnd(range_end);
  };

  const getDateFilterParams = () => {
    if (isEmpty(dateFilter)) return {};
    if (dateFilter === CUSTOM_RANGE_VALUE) {
      if (!customRangeStart && !customRangeEnd) return {};
      return {
        dates: dateFilter,
        range_start: customRangeStart,
        range_end: customRangeEnd,
      };
    }
    return { dates: dateFilter };
  };

  const handleDateToggleEvent = (_, { value }) => {
    if (value === dateFilter) {
      setDateFilter("");
    } else {
      setDateFilter(value);
    }
  };

  const handleCustomRangeStart = (value, validated) => {
    if (validated && validateDateRange(value, customRangeEnd)) {
      setCustomRangeStart(value);
    }
    dropdownMenu.current.focus();
  };

  const handleCustomRangeEnd = (value, validated) => {
    if (validated && validateDateRange(customRangeStart, value)) {
      setCustomRangeEnd(value);
    }
    dropdownMenu.current.focus();
  };

  const handleApplyFilters = () => {
    setIsOpen(false);
    const dateFilters = getDateFilterParams();
    onApplyFilters(tagFilters, headquartersFilter, ownersFilter, dateFilters);
    setWorkingFilters({
      tag_filters: tagFilters,
      headquarters_filter: headquartersFilter,
      date_filters: dateFilters,
      owners_filter: ownersFilter,
    });
    const filtersCount = countSelectedFilters();
    setFiltersApplied(filtersCount);
  };

  const countSelectedFilters = useCallback(
    () =>
      tagFilters.length +
      (isEmpty(headquartersFilter) ? 0 : 1) +
      (isEmpty(dateFilter) ? 0 : 1) +
      (isEmpty(ownersFilter) ? 0 : 1),
    [tagFilters.length, headquartersFilter, dateFilter, ownersFilter],
  );

  const shouldDisabledButton = useMemo(
    () => filtersApplied === 0 && countSelectedFilters() === 0,
    [countSelectedFilters, filtersApplied],
  );

  const handleClose = () => {
    if (filtersApplied > 0) {
      handleCancel();
    } else {
      handleReset();
    }
    setIsOpen(false);
  };

  const handleClearFilters = (event) => {
    handleReset();
    onApplyFilters([]);
    setFiltersApplied(0);
    if (event) {
      event.stopPropagation();
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownMenu.current && !dropdownMenu.current.contains(event.target)) {
      setIsOpen(false);
      document.removeEventListener("click", handleClickOutside, true);
    }
  };
  useEffect(() => {
    if (isEmpty(values)) {
      handleClearFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const tableFilterClassnames = classnames("PassedDealsFilter Filter", {
    [className]: !!className,
  });

  return (
    <div>
      <Dropdown
        className={tableFilterClassnames}
        trigger={
          <Button secondary size="small">
            <Icon icon="ri-filter-3-fill" />
            <span>{buttonFilterName}</span>

            {filtersApplied > 0 && (
              <Icon
                icon="ri-close-circle-fill"
                size="mini"
                onClick={handleClearFilters}
              />
            )}
          </Button>
        }
        icon={null}
        open={isOpen}
        openOnFocus={false}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <DropdownMenu className="Menu-Container">
          <div ref={dropdownMenu} onClick={(event) => event.stopPropagation()}>
            <Segment attached="top" className="TableFilter-Container">
              <Grid>
                <Grid.Column width={6}>
                  <Grid.Row className="CompanyHeadquarters-Selector">
                    <p className="u-captionBlack">Headquarters</p>
                    <DropdownForm
                      search
                      placeholder="Select Headquarters"
                      value={headquartersFilter}
                      items={headquarters}
                      onChange={(_, { value }) => setHeadquartersFilter(value)}
                      size="small"
                      extraClassnames="CompanyHeadquarters-Dropdown"
                    />
                  </Grid.Row>
                  <Grid.Row className="Owner-Selector">
                    <p className="u-captionBlack">Owner</p>
                    <DropdownForm
                      search
                      placeholder="Select Owner"
                      value={ownersFilter}
                      items={owners}
                      onChange={(_, { value }) => setOwnersFilter(value)}
                      size="small"
                      extraClassnames="Owner-Dropdown"
                    />
                  </Grid.Row>
                  <Grid.Row className="CompanyTag-Selector">
                    <p className="u-captionBlack">Tags</p>
                    <InputTag
                      placeholder="Type to filter or select a tag"
                      extraClassnames="CompanyTag-Input"
                      size="small"
                      onFilter={filterTags}
                      suggestedList={tagSuggestions}
                      onChange={(value) => setTagFilters(value)}
                      taggedList={tagFilters}
                      reInit={reInit}
                      setReInit={setReInit}
                    />
                  </Grid.Row>
                </Grid.Column>
                <Grid.Column width={9} className="InvestmentDate-Selector">
                  <p className="u-captionBlack">SELECT DEAL DATE</p>
                  <Grid.Row className="Radio-Button-Group-Row">
                    <ClearableRadioGroup
                      disabled={false}
                      handleChange={handleDateToggleEvent}
                      radioButtonInfo={RADIO_DATE_OPTIONS}
                      selected={dateFilter}
                    />
                  </Grid.Row>
                  <div className="DateInput-Container">
                    <DatePicker
                      className="DateInput"
                      size="small"
                      value={customRangeStart}
                      onChange={handleCustomRangeStart}
                      disabled={dateFilter !== CUSTOM_RANGE_VALUE}
                    />
                    <span className="DateInput-divider"> - </span>
                    <DatePicker
                      className="DateInput"
                      size="small"
                      value={customRangeEnd}
                      onChange={handleCustomRangeEnd}
                      disabled={dateFilter !== CUSTOM_RANGE_VALUE}
                    />
                  </div>
                </Grid.Column>
              </Grid>
            </Segment>
            <Segment attached="bottom" className="TableFilter-footer">
              <Grid columns="equal" className="TableFilter-actions">
                <Grid.Column>
                  <Grid.Row>
                    <div className="ClearButton" onClick={handleClearFilters}>
                      Clear All
                    </div>
                  </Grid.Row>
                </Grid.Column>
                <Grid.Column>
                  <Grid.Row className="Apply-Cancel-Buttons">
                    <Button secondary size="small" onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button
                      primary
                      size="small"
                      disabled={shouldDisabledButton}
                      onClick={handleApplyFilters}
                    >
                      Apply
                    </Button>
                  </Grid.Row>
                </Grid.Column>
              </Grid>
            </Segment>
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

TableFilter.propTypes = {
  onApplyFilters: PropTypes.func.isRequired,
  values: PropTypes.shape({
    dates: PropTypes.string,
    types: PropTypes.arrayOf(PropTypes.string),
    range_start: PropTypes.string,
    range_end: PropTypes.string,
  }),
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  filterTags: PropTypes.func,
  tagSuggestions: tagSuggestionsProptype,
  headquarters: PropTypes.arrayOf(dropdownOption),
  owners: PropTypes.arrayOf(dropdownOption),
};

export default TableFilter;
