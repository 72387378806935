import {
  SHOW_SIDEBAR_COMPONENT,
  HIDE_SIDEBAR_COMPONENT,
  RESET_COMPONENTS,
  MOUNT_SIDEBAR_COMPONENT,
  UNMOUNT_SIDEBAR_COMPONENT,
  UPDATE_SIDEBAR_COMPONENT,
} from "./actionTypes";

export const componentsInitialState = [];

export default function componentsReducer(
  components = componentsInitialState,
  action,
) {
  switch (action.type) {
    case SHOW_SIDEBAR_COMPONENT: {
      return components.map((component) => {
        if (component.name === action.name) {
          component.visible = true;
        }
        return component;
      });
    }

    case HIDE_SIDEBAR_COMPONENT: {
      return components.map((component) => {
        if (component.name === action.name) {
          component.visible = false;
        }
        return component;
      });
    }

    case RESET_COMPONENTS: {
      return componentsInitialState;
    }

    case MOUNT_SIDEBAR_COMPONENT: {
      const { data, ...restProps } = action.props;
      const updatedComponents = !components.find(
        (component) => component.name === action.name,
      )
        ? [
            ...components,
            {
              name: action.name,
              title: action.title,
              visible: true,
              submitTo: action.submitTo,
              componentData: {
                ...data,
              },
              ...restProps,
            },
          ]
        : [...components];

      return [...updatedComponents];
    }

    case UNMOUNT_SIDEBAR_COMPONENT: {
      return components.filter((component) => component.name !== action.name);
    }

    case UPDATE_SIDEBAR_COMPONENT: {
      return components.map((component) => {
        if (component.name === action.name) {
          const { data, ...restProps } = action.props;
          return {
            ...component,
            componentData: {
              ...component.componentData,
              ...data,
            },
            ...restProps,
          };
        }
        return component;
      });
    }

    default: {
      return components;
    }
  }
}
