import { useState } from "react";
import { uniqueId } from "lodash";

function useInputId() {
  // Using state instead of ref because initialization is lazy
  const [id] = useState(() => uniqueId());
  return id;
}

export default useInputId;
