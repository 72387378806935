import React from "react";
import FormGroup from "components_new/elements/FormGroup";
import PreFilledInput from "components_new/atoms/PreFilledInput";
import { TWITTER_PREFILL, LINKEDIN_PREFILL } from "./config";
import "./SocialMedia.scss";

function SocialMedia({
  title = "Social Media",
  formData,
  handleChange,
  subtitle,
  required = false,
  isExpanded = false,
  isVerifiedCompany = false,
}) {
  const { linkedin_handle, twitter_handle } = formData;
  return (
    <FormGroup
      extraClassnames="SocialMediaGroup"
      title={title}
      required={required}
      subtitle={subtitle}
      isExpanded={isExpanded}
    >
      <PreFilledInput
        name="linkedin"
        extraClassnames="CompanyPrivateProfileForm-FormElement"
        label="Linkedin"
        value={linkedin_handle}
        preFilled={LINKEDIN_PREFILL}
        onChange={(value) => handleChange("linkedin_handle", value)}
        readOnly={isVerifiedCompany}
        disabled={isVerifiedCompany}
        size="tiny"
      />
      <PreFilledInput
        name="twitter"
        label="Twitter"
        extraClassnames="CompanyPrivateProfileForm-FormElement"
        preFilled={TWITTER_PREFILL}
        value={twitter_handle}
        onChange={(value) => handleChange("twitter_handle", value)}
        readOnly={isVerifiedCompany}
        disabled={isVerifiedCompany}
        size="tiny"
      />
    </FormGroup>
  );
}

export default SocialMedia;
