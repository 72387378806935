import React from "react";
import preventerHandler from "lib/preventerHandler";
import TextInput from "components/shared/form/TextInput";
import PhoneNumberInput from "components/shared/form/PhoneNumberInput";
import PrimaryButton from "components/shared/PrimaryButton";
import Errors from "lib/errors";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

class CreateUserForm extends React.Component {
  state = {
    formData: {},
    errors: new Errors({}),
  };

  changeInput = (data, name, isValid = true) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [name]: data,
        [`${name}Valid`]: data !== "" && isValid,
      },
    });
  };

  handleSubmit = () => {
    const {
      onCreateAccountClick,
      inviteCode,
      organizationMembershipId,
      onUserCreated,
      email,
      isRevokedInvite,
    } = this.props;
    const {
      firstName: first_name,
      lastName: last_name,
      password,
      phoneNumber: phone_number,
    } = this.state.formData;
    const user = { email, first_name, last_name, password, phone_number };
    onCreateAccountClick(
      user,
      inviteCode,
      organizationMembershipId,
      isRevokedInvite,
    )
      .then(() => {
        this.setState({
          errors: new Errors({}),
        });
        onUserCreated();
      })
      .catch((error) => {
        const { data } = error;
        if (data.error) {
          data.errors = { password: [data.error] };
        }
        this.setState({ errors: new Errors(data.errors) });
      });
  };

  validateForm = () => {
    const {
      firstNameValid,
      lastNameValid,
      passwordValid,
      phoneNumberValid,
    } = this.state.formData;
    return firstNameValid && lastNameValid && passwordValid && phoneNumberValid;
  };

  render() {
    const { organizationName, email, onComeFromNewUserClick } = this.props;
    const { errors } = this.state;
    return (
      <form
        onSubmit={preventerHandler(() => this.handleSubmit())}
        className="InvitationOrgFormContainer"
      >
        <p className="FormElement invitationtMessage LoginAnimatedElement SignUpRequestMessage">
          You've been invited to collaborate with <b>{organizationName}</b> on
          Mighty Organization. Please enter your email address to continue.
        </p>
        <TextInput
          autoFocus
          extraClassnames="LoginAnimatedElement"
          label="Email address"
          name="email"
          value={email}
          errorMessage={errors.for("email")}
          readOnly={true}
        />
        <TextInput
          autoFocus
          label="First Name"
          extraClassnames="FormElement--DisplayName"
          name="firstName"
          onChange={this.changeInput}
          errorMessage={errors.for("firstName")}
        />
        <TextInput
          label="Last Name"
          extraClassnames="FormElement--DisplayName"
          name="lastName"
          onChange={this.changeInput}
          errorMessage={errors.for("lastName")}
        />
        <TextInput
          typeOfInput="password"
          extraClassnames="LoginAnimatedElement FormElementPassword"
          placeholder="Password"
          errorMessage={errors.for("password")}
          label="Password"
          name="password"
          onChange={this.changeInput}
        />
        <PhoneNumberInput
          onChange={this.changeInput}
          errorMessage={errors.for("phone_number")}
          name="phoneNumber"
        />
        <PrimaryButton
          customClass="LoginAnimatedElement FormElement"
          disabled={!this.validateForm()}
          type="submit"
        >
          <span className="Button-text">Create Account</span>
        </PrimaryButton>
        <div>
          <span className="HaveAccount">{"Already have an account? "}</span>
          <Link
            to="#"
            className="LoginAnimatedElement ForgotLink"
            onClick={preventerHandler(onComeFromNewUserClick)}
          >
            Sign in here
          </Link>
        </div>
      </form>
    );
  }
}
CreateUserForm.propTypes = {
  isRevokedInvite: PropTypes.bool.isRequired,
  email: PropTypes.string,
  organizationName: PropTypes.string,
  inviteCode: PropTypes.string.isRequired,
  organizationMembershipId: PropTypes.number,
  onCreateAccountClick: PropTypes.func,
  onUserCreated: PropTypes.func,
  onComeFromNewUserClick: PropTypes.func,
};

CreateUserForm.defaultProps = {
  email: "",
  organizationName: null,
  organizationMembershipId: null,
};

export default CreateUserForm;
