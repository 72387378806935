import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { logDistribution } from "./actions";
import { useQueryClient } from "react-query";

export default () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const invalidateQueries = useCallback(() => {
    queryClient.invalidateQueries(["Company"]);
  }, [queryClient]);

  const createDistribution = async (formData, investmentId) => {
    const distributions = await dispatch(
      logDistribution(formData, investmentId),
    );
    invalidateQueries();
    return distributions;
  };

  return { createDistribution };
};
