import {
  RECEIVE_PORTFOLIOS,
  REQUEST_PORTFOLIOS,
} from "actions/synchronous/shared";

const initialState = {
  portfolios: [],
  isFetching: false,
  newPortfolio: null,
};

function portfoliosView(state = initialState, action) {
  switch (action.type) {
    case REQUEST_PORTFOLIOS:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVE_PORTFOLIOS:
      return Object.assign({}, state, {
        isFetching: false,
      });
    default:
      return state;
  }
}

export default portfoliosView;
