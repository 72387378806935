import Api from "lib/Api";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { orderBy } from "lodash";

export function useCreateTask() {
  const queryClient = useQueryClient();
  const { organizationUsername } = useParams();

  return useMutation(
    ({ formData }) =>
      Api.post(`/tasks`, {
        task: formData,
      }),
    {
      onSuccess: ({ data: newTask }, { formData }) => {
        const { selection } = formData;
        updatePendingTasksList(queryClient, organizationUsername, newTask);
        queryClient.invalidateQueries(["QueuesView", organizationUsername]);
        if (selection?.type == "deal") {
          addTaskInDealView(queryClient, selection.id, newTask);
        }
      },
      onError: (error) => {
        throw error;
      },
    },
  );
}

function updatePendingTasksList(queryClient, organizationUsername, newTask) {
  const key = ["QueuesView", organizationUsername, "pending"];
  const queuesView = queryClient.getQueryData(key);

  if (queuesView?.tasks) {
    const newTasksList = [newTask, ...queuesView.tasks];
    const sortedTasksList = orderBy(
      newTasksList,
      [
        (task) => task.due_date && new Date(task.due_date),
        (task) => new Date(task.created_at),
      ],
      ["asc", "asc"],
    );

    queryClient.setQueryData(key, {
      ...queuesView,
      tasks: sortedTasksList,
    });
  }
}

function addTaskInDealView(queryClient, dealId, newTask) {
  const key = ["Deal", dealId];
  const dealView = queryClient.getQueryData(key);

  if (dealView?.deal) {
    const newTasksList = [...dealView.deal.tasks, newTask];
    queryClient.setQueryData(key, {
      ...dealView,
      deal: {
        ...dealView.deal,
        tasks: newTasksList,
      },
    });
  }
}
