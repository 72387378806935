import { useMutation, useQueryClient } from "react-query";
import Api from "lib/Api";

export function useUpdateDealDocument() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ document }) => Api.patch(`/documents/${document.id}`, { document }),
    {
      onSuccess: ({ data: newDocument }, { dealId }) => {
        return queryClient.setQueryData(["Deal", dealId], (oldData) => ({
          ...oldData,
          documents: oldData.documents.map((document) =>
            document.id === newDocument.id ? newDocument : document,
          ),
        }));
      },
    },
  );
}
