import React from "react";
import PropTypes from "prop-types";
import Badge from "-!svg-react-loader?!assets/icons/miscellaneous/verified.svg?name=Verified";
import classnames from "classnames";
import Icon from "components_new/atoms/Icon";
import "./BadgeIcon.scss";

const BadgeIcon = ({ size, color }) => {
  const badgeClassnames = classnames("BadgeIcon", {
    [`Badge--${size}`]: !!size,
    [`Badge--${color}`]: !!color,
  });
  return <Icon as={Badge} className={badgeClassnames} />;
};

BadgeIcon.defaultProps = {
  size: "small",
  color: "primary",
};

BadgeIcon.propTypes = {
  size: PropTypes.oneOf(["small", "large"]),
  color: PropTypes.oneOf(["primary", "secondary"]),
};

export default BadgeIcon;
