import React from "react";
import Segment from "components_new/atoms/Segment";
import { Grid } from "@ableco/semantic-ui-react";
import UserAvatar from "components_new/elements/UserAvatar";
import OrganizationMemberActionsContainer from "./shared/OrganizationMemberActions";
import BasicLabel from "components_new/atoms/BasicLabel";
import {
  organizationMembershipPropType,
  organizationProptype,
} from "PropTypes";
import PropTypes from "prop-types";

function showOrganizationMemberActions(
  currentOrganization,
  organizationMembership,
  currentUserID,
) {
  return (
    !organizationMembership.owner &&
    (currentOrganization.is_current_user_organization_owner ||
      organizationMembership.user_id === currentUserID)
  );
}

function MemberRow({
  currentOrganization,
  organizationMembership,
  currentUserID,
}) {
  const inviteeInitial = organizationMembership.email[0].toUpperCase();
  const { owner, status, initials, full_name, email } = organizationMembership;
  const isPendingOrExpired = ["pending", "expired"].includes(status);
  const showActions = showOrganizationMemberActions(
    currentOrganization,
    organizationMembership,
    currentUserID,
  );

  return (
    <Segment className="MemberRow RowCard">
      <Grid>
        <Grid.Row>
          <Grid.Column width={12}>
            <div className="OrganizationMembersTable-Initials">
              <UserAvatar
                initials={initials || inviteeInitial}
                fullName={full_name || ""}
                showTooltip={false}
              />
            </div>
            {full_name && (
              <div className="OrganizationMembersTable-FullName">
                {full_name}
              </div>
            )}
            <div className="OrganizationMembersTable-Email">{email}</div>
            {owner && (
              <BasicLabel type="generic" upperCase={true}>
                owner
              </BasicLabel>
            )}
            {isPendingOrExpired && (
              <BasicLabel
                type={status === "pending" ? "notice" : "warning"}
                upperCase={true}
              >
                {status}
              </BasicLabel>
            )}
          </Grid.Column>
          {showActions && (
            <Grid.Column
              floated="right"
              textAlign="right"
              verticalAlign="middle"
              width={4}
            >
              <OrganizationMemberActionsContainer
                organizationMembership={organizationMembership}
                isOrgOwnerPerformingAction={
                  currentOrganization.is_current_user_organization_owner
                }
              />
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    </Segment>
  );
}

MemberRow.propTypes = {
  currentOrganization: organizationProptype.isRequired,
  organizationMembership: organizationMembershipPropType.isRequired,
  currentUserID: PropTypes.number.isRequired,
};

export default MemberRow;
