import React, { useState, useMemo } from "react";

import getMessage from "components_new/lib/Messages";
import ChangePortfolio from "./ChangePortfolio";
import Loader from "components/shared/Loader";
import {
  moveInvestment,
  fetchInvestmentDetailsV2,
} from "store/investments/investment/actions";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import { useDispatch } from "react-redux";
import { useInvestment } from "components_new/SidebarComponents/views/InvestmentView/hooks/useInvestment";
import { closeModal } from "store/modal";
import { useQueryClient } from "react-query";
import useOrganizations from "store/organizations/hooks";

// This is just a temporary file to make the ChangePortfolioModal works both in InvestmentView and CompanyView.
// Probably, we will remove this component entirely when we migrate this modal into the sidebar.
function ChangePortfolioWrapper({ investmentId }) {
  const [submitting, setSubmitting] = useState(false);
  const { addToast } = useSetToast();
  const {
    currentOrganization: { portfolios },
  } = useOrganizations();

  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { data, isLoading } = useInvestment(investmentId);

  const portfoliosAvailableToSwitch = useMemo(() => {
    return portfolios.filter((item) => item.id !== data?.portfolio.id);
  }, [data, portfolios]);

  const handlePortfolioChange = async (formData) => {
    setSubmitting(true);

    try {
      await dispatch(moveInvestment(investmentId, formData));

      addToast(getMessage("updateInvestment"));
      dispatch(closeModal());

      queryClient.invalidateQueries(["Company"]);
      await dispatch(fetchInvestmentDetailsV2(investmentId));
    } finally {
      setSubmitting(false);
    }
  };

  if (isLoading || !investmentId) {
    return <Loader />;
  }

  return (
    <ChangePortfolio
      investmentDetail={data?.details}
      currentPortfolio={data?.portfolio}
      portfoliosAvailableToSwitch={portfoliosAvailableToSwitch}
      isSubmitting={submitting}
      moveInvestment={handlePortfolioChange}
    />
  );
}
export default ChangePortfolioWrapper;
