import React, { Component } from "react";
import PropTypes from "prop-types";
import FormLabel from "components/shared/form/FormLabel";
import ActionButton from "components/shared/ActionButton";
import TrashIcon from "-!svg-react-loader?!assets/icons/utility/trash.svg?name=TrashIcon";
import classNames from "classnames";

const MAX_NUMBER_OF_ITEMS = 8;

class FieldGroup extends Component {
  state = {
    values: this.props.values || [],
    maxNumberOfItems: this.props.maxNumberOfItems || MAX_NUMBER_OF_ITEMS,
    infiniteItems: this.props.infiniteItems,
  };

  static propTypes = {
    render: PropTypes.func.isRequired,
    extraClassnames: PropTypes.string,
    isOptional: PropTypes.bool,
    infiniteItems: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.shape({})),
    maxNumberOfItems: PropTypes.number,
    attributesTemplate: PropTypes.shape({}).isRequired,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func,
    onAdd: PropTypes.func,
    readOnly: PropTypes.bool,
    showLock: PropTypes.bool,
    lockedValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    startWithOne: PropTypes.bool,
  };

  static defaultProps = {
    extraClassnames: null,
    isOptional: false,
    infiniteItems: false,
    label: null,
    maxNumberOfItems: null,
    readOnly: false,
    showLock: false,
    lockedValues: [],
    startWithOne: false,
  };

  handleChange = (name, value, index) => {
    const newValues = [...this.state.values];
    newValues[index][name] = value;
    this.setState(
      {
        values: newValues,
      },
      () => {
        this.props.onChange(this.state.values);
      },
    );
  };

  addGroup = () => {
    if (
      this.state.infiniteItems ||
      this.state.values.length < this.state.maxNumberOfItems
    ) {
      this.setState(
        (prevState) => {
          return {
            values: [
              ...prevState.values,
              Object.assign({}, this.props.attributesTemplate),
            ],
          };
        },
        () => {
          this.props.onChange(this.state.values);
          if (this.props.onAdd) {
            this.props.onAdd();
          }
        },
      );
    }
  };

  removeGroup(index) {
    const newValues = [...this.state.values];
    newValues.splice(index, 1);
    this.setState(
      {
        values: newValues,
      },
      () => {
        this.props.onChange(this.state.values);
        if (this.props.onRemove) {
          this.props.onRemove(index);
        }
      },
    );
  }

  componentDidMount() {
    if (this.props.startWithOne) {
      this.addGroup();
    }
  }

  render() {
    const {
      label,
      isOptional,
      extraClassnames,
      maxNumberOfItems,
      name,
      readOnly,
      lockedValues,
    } = this.props;
    const fieldGroupClassnames = classNames("FieldGroup", {
      [extraClassnames]: !!extraClassnames,
      "has-readOnly": readOnly,
    });
    const hasReachedTheMaximum = this.state.values.length === maxNumberOfItems;

    return (
      <div className={fieldGroupClassnames}>
        {label && (
          <FormLabel id={label} label={label} isOptional={isOptional} />
        )}
        {lockedValues.map((value, index) => {
          return (
            <div className="FieldGroup-item" key={index}>
              {this.props.render({
                index: index,
                formData: value,
              })}
            </div>
          );
        })}
        {this.state.values.map((value, index) => {
          return (
            <div className="FieldGroup-item" key={index}>
              {this.props.render({
                index: index,
                updateForm: this.handleChange,
                formData: value,
              })}
              {!value.locked && (
                <div
                  className="FieldGroup-deleteAction"
                  onClick={() => this.removeGroup(index)}
                >
                  <TrashIcon />
                  Delete
                </div>
              )}
            </div>
          );
        })}
        {hasReachedTheMaximum && (
          <div className="FieldGroup-maximumPlaceholder">
            You can add up to {maxNumberOfItems} {name}
          </div>
        )}
        {!readOnly && (
          <ActionButton
            onClick={this.addGroup}
            size="small"
            disabled={hasReachedTheMaximum}
          >
            + Add
          </ActionButton>
        )}
      </div>
    );
  }
}

export default FieldGroup;
