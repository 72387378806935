import { OPEN_MODAL, CLOSE_MODAL, UPDATE_MODAL_OPTIONS } from "./actionTypes";
import { useDispatch } from "react-redux";

export default () => {
  const dispatch = useDispatch();

  const openModal = (modalName, props = {}) =>
    dispatch({
      type: OPEN_MODAL,
      modalName,
      props,
    });

  const closeModal = () =>
    dispatch({
      type: CLOSE_MODAL,
    });

  const updateModalOptions = (modalName, props = {}) =>
    dispatch({
      type: UPDATE_MODAL_OPTIONS,
      modalName,
      props,
    });

  return {
    openModal,
    closeModal,
    updateModalOptions,
  };
};
