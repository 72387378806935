import React, { Fragment } from "react";
import { connect } from "react-redux";
import { closeModal } from "store/modalV2/actions";
import ConfirmationModal from "components/shared/ConfirmationModal";
import { isSubmitting } from "store/domain/selectors";
import { deleteTag } from "store/tags/actions";
import pluralize from "pluralize";

const createMessage = ({
  contact_details_count,
  company_profiles_count,
  name,
}) => {
  const pluralizedWord = {
    company: pluralize("company", company_profiles_count),
    contact: pluralize("contact", contact_details_count),
  };

  return (
    <Fragment>
      Are you sure you want to delete the tag <strong>{name}</strong> and remove
      it from <strong>{company_profiles_count}</strong> associated{" "}
      {pluralizedWord.company} and <strong>{contact_details_count} </strong>{" "}
      associated {pluralizedWord.contact}?
    </Fragment>
  );
};

function mapStateToProps(state, { tag }) {
  return {
    title: "Delete Tag",
    message: createMessage(tag),
    confirmButtonLabel: "Delete Tag",
    cancelButtonLabel: "Cancel",
    primaryButtonClassnames: "Button--warning",
    submitting: isSubmitting(state, "removeTag"),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { tag, actionAfterDelete } = ownProps;

  return {
    closeModal: () => dispatch(closeModal()),
    action: () => {
      dispatch(deleteTag(tag.id)).then(() => {
        actionAfterDelete(tag);
        dispatch(closeModal());
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);
