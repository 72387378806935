import React from "react";
import SecondaryButton from "components/shared/SecondaryButton";
import CompanyIcon from "components/shared/CompanyIcon";
import Spinner from "components/shared/Spinner";

const OrganizationAvatar = ({
  logo_url,
  isUploading,
  openFileSelector,
  imgBase64,
  disabled,
}) => {
  const statusButtonText = logo_url == null ? "Upload" : "Change";
  return (
    <div className="Grid">
      <div className="OrganizationLogoPicker">
        {isUploading ? (
          <Spinner />
        ) : (
          <CompanyIcon
            src={imgBase64 || logo_url}
            isBase64={imgBase64}
            size="large"
          />
        )}
      </div>
      <div className="OrganizationLogoSelector">
        <SecondaryButton
          onClick={openFileSelector}
          size="small"
          disabled={disabled}
        >
          {statusButtonText} Logo
        </SecondaryButton>
        <span className="information">
          JPG or PNG format, and at least 250px
        </span>
      </div>
    </div>
  );
};

export default OrganizationAvatar;
