import accounting from "accounting";
import isValidNumberInput from "lib/formatters/isValidNumberInput";

export default function formatNumber(value, precision) {
  if (isValidNumberInput(value)) {
    return accounting.formatNumber(value, precision);
  }
  throw new Error(
    `Value, which now equals --> ${value}, cannot be undefined, null, Infinity, or empty string`,
  );
}
