import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Link from "components_new/atoms/Link";
import GainLossArrow from "components_new/elements/GainLossArrow";
import Card from "components_new/elements/Card";
import Value from "components_new/atoms/Value";

import "./CompanyInvestmentPerformanceSidepane.scss";
import { decimalPropType } from "PropTypes";

function CompanyInvestmentPerformanceSidepane({
  holdings,
  performance,
  capitalization,
  portfolios,
  organizationName,
  organizationUsername,
}) {
  const showIndicator = parseFloat(performance.unrealizedGain) !== 0;
  const gainLossValueClassnames = classnames("GainLossValue", {
    WithIndicator: !!showIndicator,
  });
  const {
    unrealizedGain,
    cashProceeds,
    cashCost,
    costBasis,
    currentMarketValue,
    multiple,
  } = performance;

  const {
    investorSince,
    lastUpdatedInvestmentDate,
    currentHoldings,
    sharesOwned,
    fullyDilutedSharesPercentage,
  } = holdings;

  const { valuation, pricePerShare, sharesOutstanding } = capitalization;
  const totalGain = parseFloat(unrealizedGain) + parseFloat(cashProceeds);

  return (
    <div className="CompanyInvestmentPerformanceSidepane">
      <Card
        inline={true}
        title="Investment Performance"
        subtitle={organizationName}
        attributes={[
          {
            label: "Investor Since",
            value: <Value type="date">{investorSince}</Value>,
          },
          {
            label: "Last Updated",
            value: <Value type="date">{lastUpdatedInvestmentDate}</Value>,
          },
          {
            label: "Total Investments",
            value: <Value type="quantity">{currentHoldings}</Value>,
          },
          {
            label: "Funds",
            value: portfolios.map((portfolio) => {
              return (
                <div key={portfolio.id} className="PortfoliosList-item">
                  <Link
                    to={`/${organizationUsername}/portfolios/${portfolio.id}`}
                  >
                    {portfolio.name}
                  </Link>
                </div>
              );
            }),
          },
          { type: "separator" },
          {
            label: "Price Per Share",
            value: (
              <Value type="currency" precision="3">
                {pricePerShare}
              </Value>
            ),
          },
          {
            label: "Shares Outstanding",
            value: <Value type="quantity">{sharesOutstanding}</Value>,
          },
          {
            label: "Valuation",
            value: <Value type="currency">{valuation}</Value>,
          },
          { type: "separator" },
          {
            label: "Shares Owned",
            value: <Value type="quantity">{sharesOwned}</Value>,
          },
          {
            label: "% Fully Diluted",
            value: (
              <Value type="percentage">{fullyDilutedSharesPercentage}</Value>
            ),
          },
          { type: "separator" },
          {
            label: "Cash Cost",
            value: <Value type="currency">{cashCost}</Value>,
          },
          {
            label: "Cost Basis",
            value: <Value type="currency">{costBasis}</Value>,
          },
          {
            label: "Fair Market Value",
            value: <Value type="currency">{currentMarketValue}</Value>,
          },
          {
            label: "Unrealized Gain",
            value: <Value type="currency">{unrealizedGain}</Value>,
          },
          {
            label: "Realized Gain",
            value: <Value type="currency">{cashProceeds}</Value>,
          },
          {
            label: "Total Gain",
            value: (
              <div className={gainLossValueClassnames}>
                {showIndicator && <GainLossArrow gain={unrealizedGain} />}
                <Value type="currency" precision="0">
                  {totalGain}
                </Value>
              </div>
            ),
          },
          {
            label: "Return Multiple",
            value: <Value type="multiple">{multiple}</Value>,
          },
        ]}
      />
    </div>
  );
}

CompanyInvestmentPerformanceSidepane.propTypes = {
  holdings: PropTypes.shape({
    investorSince: PropTypes.string,
    lastUpdatedInvestmentDate: PropTypes.string,
    currentHoldings: PropTypes.number,
    sharesOwned: PropTypes.number,
    fullyDilutedSharesPercentage: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  }).isRequired,
  performance: PropTypes.shape({
    costBasis: decimalPropType,
    currentMarketValue: decimalPropType,
    realizedGain: decimalPropType,
    unrealizedGain: decimalPropType,
    cashProceeds: decimalPropType,
    multiple: PropTypes.string,
    cashCost: PropTypes.string,
  }).isRequired,
  capitalization: PropTypes.shape({
    valuation: PropTypes.string,
    sharesOutstanding: PropTypes.number,
    pricePerShare: PropTypes.string,
    fullyDilutedShares: PropTypes.string,
  }).isRequired,
  portfolios: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  portfolioIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  organizationName: PropTypes.string.isRequired,
  organizationUsername: PropTypes.string.isRequired,
};

export default CompanyInvestmentPerformanceSidepane;
