import React from "react";
import classnames from "classnames";
import { Form, Dropdown as SemanticDropdown } from "@ableco/semantic-ui-react";
import { isEmpty } from "lodash";
import "semantic-ui-css/components/dropdown.css";
import "components_new/atoms/Dropdown/Dropdown.scss";
import "./DropdownForm.scss";

const SEMANTIC_ICON = "caret down";
const REMIX_ICON = "RemixIcon ri-arrow-down-s-line";

function DropdownForm({
  label,
  items,
  onChange,
  extraClassnames,
  value,
  disabled = false,
  required = false,
  size = "Default",
  readOnly,
  iconStyle = REMIX_ICON,
  errorMessage,
  name,
  selection = true,
  ...restProps
}) {
  const dropdownFormClassnames = classnames("DropdownForm", {
    [size]: !!size,
    [extraClassnames]: !!extraClassnames,
  });

  const dropdownClassnames = classnames("Dropdown selection", {
    [extraClassnames]: !!extraClassnames,
    [size]: !!size,
  });

  const displayLabel = required ? label + "*" : label;
  const icon = iconStyle === "semantic" ? SEMANTIC_ICON : REMIX_ICON;

  const dropdownFormProps = {
    selection: selection,
    className: dropdownClassnames,
    onChange: onChange,
    options: items,
    value: value,
    disabled,
    readOnly,
    icon,
    ...(!isEmpty(name) && { "data-testid": name }),
    ...restProps,
  };

  if (!label && restProps.placeholder) {
    dropdownFormProps["aria-label"] = restProps.placeholder;
  }

  return (
    <Form.Field className={dropdownFormClassnames}>
      {label && <label>{displayLabel}</label>}
      <SemanticDropdown {...dropdownFormProps} />
      {errorMessage && <label className="u-errorMessage">{errorMessage}</label>}
    </Form.Field>
  );
}

export default DropdownForm;
