import React from "react";
import SecondaryButton from "components/shared/SecondaryButton";
import UserAvatar from "components_new/elements/UserAvatar";
import PropTypes from "prop-types";
import { userPropType } from "PropTypes";

const DEFAULT_AVATAR = "/assets/images/user-avatar.png";

function AvatarUploader({ user, openAvatarFilePickerModal }) {
  const userHasAvatar = user.avatar_url !== null;
  const imageUrl = userHasAvatar ? user.avatar_url : DEFAULT_AVATAR;

  return (
    <div className="AvatarUploader">
      <UserAvatar
        avatarUrl={imageUrl}
        showTooltip={false}
        fullName={user.full_name}
        initials={user.initials}
        theme="inverse"
        size="large"
      />
      <div className="AvatarUploader-action">
        <SecondaryButton
          size="small"
          onClick={() => openAvatarFilePickerModal()}
        >
          {userHasAvatar ? "Change Avatar" : "Upload Avatar"}
        </SecondaryButton>
        <p className="AvatarUploader-imageRequirements">
          JPG or PNG format, and at least 250px
        </p>
      </div>
    </div>
  );
}

AvatarUploader.propTypes = {
  user: userPropType.isRequired,
  openAvatarFilePickerModal: PropTypes.func.isRequired,
};

export default AvatarUploader;
