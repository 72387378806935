import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function devToolPage(Component) {
  const DevToolPage = (props) => {
    const navigate = useNavigate();
    const [canContinue, setCanContinue] = useState(false);

    const appEnv = useSelector((state) => state.config.appEnv);
    const canAccessDevTools = useMemo(
      () => process.env.APP_ENV !== "production" && appEnv !== "production",
      [appEnv],
    );

    useEffect(() => {
      if (canAccessDevTools) {
        setCanContinue(true);
      } else {
        navigate("/dashboard", { replace: true });
      }
    }, [navigate, canAccessDevTools]);

    if (canContinue) {
      return <Component {...props} />;
    } else {
      return null;
    }
  };

  DevToolPage.displayName = `DevToolPage(${Component.name})`;
  return DevToolPage;
}

export default devToolPage;
