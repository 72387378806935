import React, { Component } from "react";
import ReactHighcharts from "react-highcharts";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import moment from "moment";
import PropTypes from "prop-types";
import { investmentPropType, valuationProptype } from "PropTypes";
import {
  parseData,
  getDataForGraph,
  formatMoneyLabel,
  globalChartConfig,
  greenAreaSeries,
  labelsYAxis,
  xAxisChart,
  labelsXAxis,
  tooltipChart,
  plotOptionsChart,
  lineChartConfig,
  yAxisPlotlineLineChart,
  yAxisLineChart,
} from "lib/charts/historyChartConfig";
import {
  formatPriceInputPreview,
  formatNumberInputPreview,
} from "lib/abbreviatedFormat";
import formatDate from "lib/formatDate";

function ChartLabels({ totalPaidIn, percentOfCommitment }) {
  return (
    <div className="ChartLabels">
      <div className="ChartLabels-content">
        Total Paid In: <span className="ChartLabels-amount">{totalPaidIn}</span>
      </div>
      <div className="ChartLabels-content">
        % of Committment:{" "}
        <span className="ChartLabels-amount">{percentOfCommitment}%</span>
      </div>
    </div>
  );
}

function capitalCallDataMapper({ amount, date: date, investment, id }) {
  return {
    y: +amount,
    date: date,
    investment: investment,
    id: id,
  };
}

class CapitalCallHistoryChart extends Component {
  componentDidMount() {
    NoDataToDisplay(ReactHighcharts.Highcharts);
  }

  renderHTMLCapitalCallTooltip() {
    return `
    <div class="HistoryChartTooltip">
      <div class="HistoryChartTooltip-date">
        ${formatDate(this.date, "ll")}
      </div>
      <span class="HistoryChartTooltip-amount">
      ${formatPriceInputPreview(this.y)}
      </span>
    </div>`;
  }

  render() {
    const { collection, investment } = this.props;
    const dates =
      collection.length > 0
        ? getDataForGraph(collection, "date")
        : [investment.date];
    const commitedCapital = parseInt(investment.total_acquisition_amount);
    const percentOfCommitment = formatNumberInputPreview(
      investment.percent_called,
    );
    const totalPaidIn = formatPriceInputPreview(investment.capital_called);
    const capitalCallPoints = parseData(collection, capitalCallDataMapper);

    const config = {
      ...globalChartConfig,
      tooltip: {
        ...tooltipChart,
      },
      plotOptions: {
        ...plotOptionsChart,
      },
      series: [
        {
          ...greenAreaSeries,
          name: "Paid-in Capital",
          type: "area",
          data: capitalCallPoints,
          tooltip: {
            pointFormatter: this.renderHTMLCapitalCallTooltip,
          },
        },
        {
          ...lineChartConfig,
          name: "Committed Capital",
          type: "line",
          data: [commitedCapital],
        },
      ],
      xAxis: {
        ...xAxisChart,
        type: "datetime",
        labels: {
          ...labelsXAxis,
          formatter: function () {
            return moment(this.value).format("MM/DD");
          },
        },
        categories: dates,
      },
      yAxis: {
        ...yAxisLineChart,
        plotLines: [
          {
            value: commitedCapital,
            ...yAxisPlotlineLineChart,
          },
        ],
        labels: {
          ...labelsYAxis,
          formatter: function () {
            return formatMoneyLabel(this.value);
          },
        },
      },
    };
    return (
      <div className="CapitalCallHistoryChart HistoryChart">
        <ChartLabels
          percentOfCommitment={percentOfCommitment}
          totalPaidIn={totalPaidIn}
        />
        <ReactHighcharts config={config} />
      </div>
    );
  }
}

CapitalCallHistoryChart.propTypes = {
  collection: PropTypes.arrayOf(valuationProptype),
  investment: investmentPropType,
};

export default CapitalCallHistoryChart;
