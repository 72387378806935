import React, { useEffect, useState } from "react";
import FormFooter from "components_new/SidebarComponents/SidebarForm/FormFooter";
import RequiredNote from "components_new/SidebarComponents/shared/RequiredNote";
import useForm from "lib/hooks/useForm";
import ContactDetails from "components_new/SidebarComponents/forms/groups/ContactDetails";
import ContactRoles from "components_new/SidebarComponents/forms/groups/ContactRoles";
import CustomFields from "components_new/SidebarComponents/forms/groups/CustomFields";
import Documents from "components_new/SidebarComponents/forms/groups/Documents";
import { sanitizeContactData } from "../ContactForm/config";
import { isEmpty } from "lodash";
import "./EditContactForm.scss";

function EditContactForm({
  ownName,
  componentData,
  updateContact,
  closeSidebar,
  contact,
  isAdmin,
  isOrgOwner,
  organizationCustomFields,
  currentOrganizationName,
  currentOrganizationUsername,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (contactData) => {
    setIsLoading(true);

    const formData = sanitizeContactData(contactData);
    updateContact(formData.id, formData)
      .then(() => {
        closeSidebar();
      })
      .catch((error) => {
        handleErrors(error);
        handleScrollView(error);
      })
      .finally(() => setIsLoading(false));
  };

  const initialValues = {
    formData: {
      ...contact,
      type: "contact",
    },
  };

  const {
    formData,
    handleChange,
    handleSubmit,
    handleErrors,
    handleScrollView,
    handleBatchChange,
    errors,
    touched,
  } = useForm(initialValues, onSubmit);

  useEffect(() => {
    if (!isEmpty(componentData)) {
      handleBatchChange(componentData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentData]);

  const { first_name, last_name, documents_data, roles } = formData;
  const isSubmitButtonDisabled = isEmpty(first_name) && isEmpty(last_name);

  return (
    <div className="EditContactForm">
      <ContactDetails
        formData={formData}
        onChange={handleChange}
        errors={errors}
      />
      <ContactRoles
        onChange={handleChange}
        errors={errors}
        submitTo={ownName}
        roles={roles}
      />
      <CustomFields
        resource_type="contact"
        formData={formData}
        isAdmin={isAdmin}
        isOrgOwner={isOrgOwner}
        handleChange={handleChange}
        organizationCustomFields={organizationCustomFields}
        organizationName={currentOrganizationName}
        organizationUsername={currentOrganizationUsername}
      />
      <Documents
        subtitle="Information relevant to closing activities, for tax purposes, etc"
        onChange={(documents) => handleChange("documents_data", documents)}
        documents={documents_data}
      />
      <RequiredNote />
      <FormFooter
        componentName={ownName}
        onSubmit={handleSubmit}
        disableSubmit={isSubmitButtonDisabled}
        isLoading={isLoading}
        touched={touched}
        submitTextButton="Save Referrer"
      />
    </div>
  );
}

export default EditContactForm;
