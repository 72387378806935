import React, { useState, useMemo, useCallback, useEffect } from "react";
import FormFooter from "components_new/SidebarComponents/SidebarForm/FormFooter";
import InvestmentDetailsModule from "./InvestmentDetailsModule";
import CompanyDetails from "components_new/SidebarComponents/forms/groups/CompanyDetails";
import RoundDetails from "components_new/SidebarComponents/forms/groups/RoundDetails";
import Documents from "components_new/SidebarComponents/forms/groups/Documents";
import CustomFields from "components_new/SidebarComponents/forms/groups/CustomFields";
import Contacts from "components_new/SidebarComponents/forms/groups/Contacts";
import Notes from "components_new/SidebarComponents/forms/groups/Notes";
import RequiredNote from "components_new/SidebarComponents/shared/RequiredNote";
import useForm from "lib/hooks/useForm";

import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import getMessage from "components_new/lib/Messages";
import { isEmpty } from "lodash";
import "./InvestmentForm.scss";

import {
  INITIAL_FORMDATA_VALUES,
  verifyDetailHasChanged,
  getInitialValuesForType,
  ensureRequest,
} from "./config";

function InvestmentForm({
  ownName,
  isAdmin,
  isOrgOwner,
  portfolios,
  prefilledContacts,
  investmentCustomFields,
  currentOrganizationName,
  currentOrganizationUsername,
  searchContact,
  hideSidebarComponent,
  mountSidebarComponent,
  updateSidebarComponent,
  submitInvestment,
  closeSidebar,
  componentData,
  deal,
  prefilledCompany,
  comesFromDeal,
  initialPortfolioId,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useSetToast();
  const initializeFormData = () => {
    if (comesFromDeal) {
      return {
        formData: {
          ...INITIAL_FORMDATA_VALUES.formData,
          contact_ids: deal.contact_ids,
          company: prefilledCompany,
          is_follow_on: deal.is_follow_on,
          deal,
          deal_id: deal.id,
        },
      };
    } else if (prefilledCompany) {
      return {
        formData: {
          ...INITIAL_FORMDATA_VALUES.formData,
          company: prefilledCompany,
        },
      };
    }
    if (initialPortfolioId || !isEmpty(prefilledCompany)) {
      return {
        formData: {
          ...INITIAL_FORMDATA_VALUES.formData,
          company: prefilledCompany,
          portfolio_id: initialPortfolioId || null,
        },
      };
    }

    return INITIAL_FORMDATA_VALUES;
  };

  const initialFormData = initializeFormData();
  const submit = useCallback(
    async (clientData) => {
      try {
        setIsLoading(true);
        const createdInvestment = await submitInvestment(
          ensureRequest(clientData),
        );
        if (createdInvestment) {
          setIsLoading(false);
          closeSidebar();
          addToast(
            getMessage("addedInvestment", {
              url: `/${currentOrganizationUsername}/investments/${createdInvestment.id}`,
            }),
          );
        }
      } catch (error) {
        handleErrors(error);
        handleScrollView(error);
        setIsLoading(false);
      }
    },
    [
      setIsLoading,
      submitInvestment,
      closeSidebar,
      addToast,
      handleErrors,
      handleScrollView,
      currentOrganizationUsername,
    ],
  );

  const {
    formData,
    handleChange,
    handleBatchChange,
    handleSubmit,
    handleErrors,
    handleScrollView,
    errors,
    touched,
  } = useForm(initialFormData, submit);

  useEffect(() => {
    if (!isEmpty(componentData)) {
      handleBatchChange(componentData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentData]);

  const [showFields, setShowFields] = useState(false);
  const [showRoundDetails, setShowRoundDetails] = useState(false);

  const { company, type, documents, notes } = formData;

  const isEssentialDataMissing = isEmpty(company) || !type;

  const detailWasTouched = useMemo(
    () => verifyDetailHasChanged(formData, type),
    [formData, type],
  );

  const changeInvestmentType = (selectedInvestmentType) => {
    const currentInvestmentType = type;
    const initialValues = getInitialValuesForType(currentInvestmentType);
    handleBatchChange({
      ...initialValues,
      ...formData,
      type: selectedInvestmentType,
    });
    updateSidebarComponent(ownName, {
      innerFormWarning: {},
    });
  };

  const handleTypeChange = (selectedInvestmentType) => {
    if (detailWasTouched) {
      updateSidebarComponent(ownName, {
        innerFormWarning: {
          warningMessage:
            "You have unsaved changes, are you sure you want to change investment type?",
          warningAction: () => changeInvestmentType(selectedInvestmentType),
        },
      });
    } else {
      changeInvestmentType(selectedInvestmentType);
    }
  };

  useEffect(() => {
    if (!isEmpty(type)) {
      setShowFields(true);
      setShowRoundDetails(type !== "fund");
    }
  }, [type]);

  return (
    <div className="InvestmentForm">
      <CompanyDetails
        company={company}
        submitTo={ownName}
        handleChange={handleChange}
        mountSidebarComponent={mountSidebarComponent}
        hideSidebarComponent={hideSidebarComponent}
        errors={errors}
        comesFromDeal={comesFromDeal}
      />
      <InvestmentDetailsModule
        portfolios={portfolios}
        formData={formData}
        handleChange={handleChange}
        handleTypeChange={handleTypeChange}
        showFields={showFields}
        preventTypeChange={detailWasTouched}
        errors={errors}
      />
      {showFields && (
        <div className="initially-hidden-fields">
          {showRoundDetails && (
            <RoundDetails formData={formData} handleChange={handleChange} />
          )}
          <Documents
            subtitle="Information relevant to closing activities, for tax purposes, etc"
            onChange={(documents) => handleChange("documents", documents)}
            documents={documents}
          />
          <CustomFields
            resource_type="investments"
            formData={formData}
            isAdmin={isAdmin}
            isOrgOwner={isOrgOwner}
            handleChange={handleChange}
            organizationCustomFields={investmentCustomFields}
            organizationName={currentOrganizationName}
            organizationUsername={currentOrganizationUsername}
          />
          <Contacts
            formData={formData}
            submitTo={ownName}
            handleChange={handleChange}
            searchContact={searchContact}
            prefilledContacts={prefilledContacts}
            mountSidebarComponent={mountSidebarComponent}
            hideSidebarComponent={hideSidebarComponent}
          />
          <Notes
            subtitle="Discretionary information that will be viewable by the entire organization."
            onChange={(value) => handleChange("notes", value)}
            notes={notes}
          />
        </div>
      )}
      <RequiredNote />
      <FormFooter
        componentName={ownName}
        data={formData}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        disableSubmit={isLoading || isEssentialDataMissing}
        touched={touched}
        submitTextButton="Save Investment"
      />
    </div>
  );
}

export default InvestmentForm;
