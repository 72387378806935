import React, { useState, useEffect } from "react";
import CompanySelector from "components_new/elements/CompanySelector/CompanySelector";
import DropdownForm from "components_new/atoms/DropdownForm";
import SplitSelect from "components_new/atoms/SplitSelect";
import NumberInput from "components_new/atoms/NumberInput";
import FormGroup from "components_new/elements/FormGroup";
import Checkbox from "components_new/atoms/Checkbox";
import CurrencyInput from "components_new/atoms/CurrencyInput";
import StockDetails from "components_new/SidebarComponents/forms/groups/StockDetails";
import { isNil } from "lodash";
import { seriesDropdownItem, splitSelectionOptions } from "./config";
import { buildPrefilledCompanyData } from "lib/globalActionsHelper";
import PropTypes from "prop-types";
import { equityInvestmentProptype } from "PropTypes";
import "./EquityProceeds.scss";

const ValuationGroup = ({
  formData,
  handleChange,
  virtualValuation,
  valueOrValuation,
  isSameAsCostBasis,
}) => {
  const { currentValuation } = formData;
  const [showValuationField, setShowValuationField] = useState(
    !isSameAsCostBasis,
  );

  useEffect(() => {
    handleChange("create_extra_valuation", showValuationField);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showValuationField]);

  return (
    <FormGroup
      title="Valuation"
      className="ValuationGroup"
      subGroup
      required
      isExpanded
      noBottomBorder
    >
      <Checkbox
        label="Same as cost basis"
        size="tiny"
        checked={!showValuationField}
        onChange={(e, value) => {
          if (value.checked) {
            handleChange(valueOrValuation, virtualValuation);
            setShowValuationField(false);
          } else {
            handleChange(valueOrValuation, "");
            setShowValuationField(true);
          }
        }}
      />
      {showValuationField && (
        <CurrencyInput
          name="realized_gain"
          label="Amount*"
          placeholder="0.00"
          value={currentValuation}
          onChange={(currentValuation) =>
            handleChange(valueOrValuation, currentValuation)
          }
          decimalLimit={2}
          iconPosition="left"
          size="tiny"
        />
      )}
    </FormGroup>
  );
};

const EquityProceeds = ({
  handleChange,
  formData,
  componentName,
  bottomBorder,
  valuationSameAsCost,
  prefilledCompany,
  errors,
  mountSidebarComponent,
  hideSidebarComponent,
}) => {
  const { series_name, quantity, cost, cost_type, company } = formData;

  const [virtualValuation, setVirtualValuation] = useState(formData.cost);
  const prefilledCompanyValue = buildPrefilledCompanyData(prefilledCompany);

  const isCompanyReadOnly = prefilledCompanyValue ? true : false;

  function getComponentName(type) {
    let valueOrValuation = {};
    switch (type) {
      case "logAcquisitionForm":
        valueOrValuation = "value";
        return valueOrValuation;
      case "distributionForm":
        valueOrValuation = "valuation";
        return valueOrValuation;
      case "logConversionForm":
        valueOrValuation = "valuation_amount";
        return valueOrValuation;

      default:
        throw new Error(`No registered object for ${type}.`);
    }
  }

  const valueOrValuation = getComponentName(componentName);

  const handleChangeValuation = (value) => {
    handleChange(valueOrValuation, value);
  };

  const handleSplitSelectChange = (_name, inputSelection) => {
    if (inputSelection.selection.value === "per_share") {
      if (!isNil(formData.quantity)) {
        setVirtualValuation(inputSelection.input * formData.quantity);
        handleChangeValuation(inputSelection.input * formData.quantity);
      }
    } else if (inputSelection.selection.value === "total") {
      setVirtualValuation(inputSelection.input);
      handleChangeValuation(inputSelection.input);
    }
    handleChange("cost_type", inputSelection.selection.value);
    handleChange("cost", inputSelection.input);
  };

  const handleQuantityChange = (value) => {
    handleChange("quantity", value);
    if (cost_type === "per_share") {
      setVirtualValuation(formData.cost * value);
      handleChangeValuation(formData.cost * value);
    }
  };

  const onCreateNewCompany = (value) => {
    handleChange("company", value);
    mountSidebarComponent(
      "addCompanyForm",
      "Add New Company",
      {
        data: { ...value },
      },
      componentName,
    );
    hideSidebarComponent(componentName);
  };

  return (
    <div className="EquityProceeds">
      <FormGroup
        title="Equity Proceeds"
        required
        isExpanded
        noBottomBorder={!bottomBorder}
      >
        <CompanySelector
          name="company"
          label="Company*"
          placeHolder="Search companies"
          onChange={(_, value) => handleChange("company", value)}
          prefilledValue={prefilledCompanyValue}
          onCreateNewCompany={onCreateNewCompany}
          disabled={isCompanyReadOnly}
          readOnly={isCompanyReadOnly}
          selectedCompany={company}
          isOnSidebar
          size="tiny"
        />
        <DropdownForm
          label="Series*"
          aria-label="Series*"
          placeholder="Select series"
          items={seriesDropdownItem}
          onChange={(_, { value }) => handleChange("series_name", value)}
          value={series_name}
          size="tiny"
          error={!!errors.for("series_name")}
          errorMessage={errors.for("series_name")}
        />
        <SplitSelect
          inputProps={{
            name: "cost",
            label: "Cost Basis*",
            decimalLimit: 4,
            placeholder: "0.0000",
          }}
          dropdownProps={{
            options: splitSelectionOptions,
            defaultValue: splitSelectionOptions[1],
            readOnly: true,
          }}
          onChange={handleSplitSelectChange}
          value={cost}
          extraClassnames="on-sidebar"
          size="tiny"
        />
        <NumberInput
          name="quantity"
          label="Quantity*"
          aria-label="Quantity*"
          onChange={(value) => handleQuantityChange(value)}
          value={quantity}
          size="tiny"
          error={!!errors.for("quantity")}
          errorMessage={errors.for("quantity")}
        />
        <div className="EquityProceeds-subgroups">
          <ValuationGroup
            formData={formData}
            handleChange={handleChange}
            virtualValuation={virtualValuation}
            valueOrValuation={valueOrValuation}
            isSameAsCostBasis={valuationSameAsCost}
          />
          <StockDetails
            title="Equity Details"
            formData={formData}
            handleChange={handleChange}
          />
        </div>
      </FormGroup>
    </div>
  );
};

EquityProceeds.defaultProps = {
  bottomBorder: false,
  valuationSameAsCost: false,
};

EquityProceeds.protoTypes = {
  formData: equityInvestmentProptype.isRequired,
  handleChange: PropTypes.func.isRequired,
  componentName: PropTypes.string.isRequired,
  bottomBorder: PropTypes.bool,
  valuationSameAsCost: PropTypes.bool,
  mountSidebarComponent: PropTypes.func,
  hideSidebarComponent: PropTypes.func,
  submitTo: PropTypes.string,
};
export default EquityProceeds;
