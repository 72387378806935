import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { dealProptype } from "PropTypes";
import DatePicker from "components_new/atoms/DatePicker";
import formatDate from "lib/formatDate";
import { valueDateFormat } from "config/dateInput";
import "./ReplyByDatePicker.scss";

function ReplyByDatePicker({ deal, pipelineId, updateDeal }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [decisionDueDate, setDecisionDueDate] = useState(
    deal.decision_due_date,
  );

  useEffect(() => {
    setDecisionDueDate(deal.decision_due_date);
    setIsError(false);
    setIsSuccess(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deal.id, deal.decision_due_date]);

  const onSubmit = (newDate) => {
    setIsLoading(true);
    updateDeal({
      formData: {
        ...deal,
        decision_due_date: newDate,
      },
      pipelineId,
      dealId: deal.id,
    })
      .then(() => {
        setIsSuccess(true);
        setTimeout(() => setIsSuccess(false), 1200);
      })
      .catch(() => {
        setIsError(true);
        setTimeout(() => setIsError(false), 1200);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <DatePicker
      name="decision_due_date"
      extraClassnames="FormElement FormElement--Date ReplyByDatePicker"
      placeholder="Decision due date"
      label=""
      value={formatDate(decisionDueDate)}
      onChange={(value) => {
        const newDecisionDueDate = formatDate(value, valueDateFormat);
        if (
          newDecisionDueDate !== formatDate(decisionDueDate, valueDateFormat)
        ) {
          setDecisionDueDate(newDecisionDueDate);
          onSubmit(newDecisionDueDate);
        }
      }}
      oldVersion
      size="tiny"
      inlineLabel={
        decisionDueDate
          ? `REPLY BY ${formatDate(decisionDueDate, "fullMonthAndDay")}`
          : "No Decision Date"
      }
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
    />
  );
}

ReplyByDatePicker.propTypes = {
  deal: dealProptype,
  pipelineId: PropTypes.string,
  updateDeal: PropTypes.func,
};

export default ReplyByDatePicker;
