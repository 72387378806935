import { connect } from "react-redux";
import OrganizationMemberActions from "./OrganizationMemberActions";
import { openModal } from "store/modalV2/actions";
import {
  revokeOrganizationMembership,
  inviteOrganizationMember,
} from "store/organizations/organizationMembers/actions";
import { flash } from "actions/synchronous/flash";
import { getOrganizationResourceAsCollection } from "store/resourcesV2/selectors";

function mapStateToProps(state, ownProps) {
  const organizationsList = getOrganizationResourceAsCollection(
    state,
    "organizations",
  );
  const privateOrganization = organizationsList.find((item) => item.private);
  return {
    organizationMembership: ownProps.organizationMembership,
    privateOrganization,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openRemoveOrganizationMemberModal: (organizationMembership) =>
      dispatch(
        openModal("removeOrganizationMember", {
          organizationMembership,
        }),
      ),
    openLeaveOrganizationModal: (organizationMembership, privateOrganization) =>
      dispatch(
        openModal("leaveOrganization", {
          organizationMembership,
          privateOrganization,
          type: "fromMember",
        }),
      ),
    revokeInvitation: (organizationMembership) =>
      dispatch(revokeOrganizationMembership(organizationMembership.id)).then(
        () => {
          dispatch(
            flash("removeOrRevokeMemberFromOrganization", {
              nameOrEmail: organizationMembership.email,
            }),
          );
        },
      ),
    resendInvitation: (organizationMembership) =>
      dispatch(inviteOrganizationMember(organizationMembership)).then(
        (payload) => {
          const { email } = payload;
          dispatch(flash("resendInvite", { email }));
        },
      ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrganizationMemberActions);
