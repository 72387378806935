import React from "react";
import UpdatePhoneNumber from "./UpdatePhoneNumber";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  updatePhoneNumber,
  sendValidationCode,
} from "store/users/asyncAuth/actions";

function UpdatePhoneNumberContainer(props) {
  return <UpdatePhoneNumber {...props} />;
}

function mapDispatchToProps(dispatch, { newPhoneNumber }) {
  return {
    sendCode: () => dispatch(sendValidationCode(newPhoneNumber)),
    verifyCode: (authCode) =>
      dispatch(updatePhoneNumber(authCode, newPhoneNumber)),
  };
}

UpdatePhoneNumberContainer.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  verifyCode: PropTypes.func.isRequired,
  sendCode: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(UpdatePhoneNumberContainer);
