import React, { Fragment } from "react";
import Avatar from "components_new/elements/Avatar";
import Text from "components_new/atoms/Text";
import CompanyFlags from "components_new/views/CompanyDirectory/shared/CompanyFlags";
import Tooltip from "components_new/atoms/Tooltip";
import Button from "components_new/atoms/Button";
import Icon from "components_new/atoms/Icon";
import BasicLabel from "components_new/atoms/BasicLabel";
import Link from "components_new/atoms/Link";
import CompanyLinkValue from "components_new/views/Company/shared/CompanyLinkValue";
import "./DealCompanyCard.scss";
export const WEBSITE_PREFILL = "http://";

function buildCommaList(list, displayKey) {
  return list.map((item, index) => {
    return `${displayKey ? item[displayKey] : item}${
      index + 1 < list.length ? ", " : ""
    }`;
  });
}

function DealCompanyCard({
  company,
  openEditCompanyForm,
  currentOrganizationUsername,
}) {
  return (
    <Fragment>
      <div className="SelectedDeal-Details-CompanyCard">
        <Avatar
          imageUrl={company.logo_url}
          avatarIcon="company-sm"
          size="mini"
        />
        <div className="SelectedDeal-Details-CompanyCard-Container">
          <div className="SelectedDeal-Details-CompanyCard-TitleRow">
            <Link
              to={`/${currentOrganizationUsername}/companies/${company.id}`}
            >
              <Text type="CompanyName subtitle-1-bold">{company.name}</Text>
            </Link>

            <CompanyFlags company={company} />
            {company.is_yc && <BasicLabel type="primary">YC</BasicLabel>}
            <Tooltip
              position="top center"
              pinned
              style={{ top: "-20px" }}
              trigger={
                <Button
                  aria-label="Edit Company"
                  size="mini"
                  className="transparent"
                  onClick={() => openEditCompanyForm(company.id, company.name)}
                >
                  <Icon icon="ri-pencil-line" size="tiny" />
                </Button>
              }
              content="Edit Company"
            />
          </div>
          {company.description && (
            <Text
              type="body-2-regular"
              as="p"
              extraClassnames="SelectedDeal-Details-CompanyCard-Description"
            >
              {company.description}
            </Text>
          )}
          <Text type="body-2-regular" color="brand-light">
            {company.website && (
              <CompanyLinkValue
                url={company.website}
                label={company.website}
                preFilled={WEBSITE_PREFILL}
              />
            )}
            {company.website && company.headquarters && (
              <Fragment> &#183; </Fragment>
            )}
            {company.headquarters && company.headquarters}
            {(company.website || company.headquarters) &&
              company.industries?.length > 0 && (
                <Fragment> &#183; </Fragment>
              )}{" "}
            {company.industries?.length > 0 && (
              <span className="u-textCapitalize">
                {buildCommaList(company.industries, "name")}
              </span>
            )}
          </Text>
        </div>
      </div>
    </Fragment>
  );
}

export default DealCompanyCard;
