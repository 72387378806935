import React from "react";
import useSidebar from "store/sidebar/hooks";
import CompanyInformation from "./CompanyInformation";
import useOrganization from "store/organizations/hooks";

function CompanyInformationWrapper(props) {
  const { openSidebar, closeSidebar } = useSidebar();
  const { currentOrganization } = useOrganization();

  return (
    <CompanyInformation
      openSidebar={openSidebar}
      closeSidebar={closeSidebar}
      prepopulatedCompanyTags={currentOrganization.prepopulated_company_tags}
      {...props}
    />
  );
}

export default CompanyInformationWrapper;
