import React from "react";
import { TableRow, TableCell } from "components_new/elements/Table";
import Value from "components_new/atoms/Value";
import SubValue from "components_new/atoms/SubValue";
import ContentWithTooltip from "components_new/elements/ContentWithTooltip";
import CompanyInfo from "components_new/elements/CompanyInfo";
import formatDate from "lib/formatDate";
import { isEmpty } from "lodash";

function CashFlowRow({ cashFlow }) {
  const amountToShow =
    cashFlow.cash_change_type === "cash_out"
      ? "-" + cashFlow.amount
      : cashFlow.amount;
  const investmentTypeLabel = cashFlow.investment_type_label;

  return (
    <TableRow key={cashFlow.id}>
      <TableCell className="CompanyNameColumn">
        {!isEmpty(cashFlow.company) && (
          <CompanyInfo
            company={cashFlow.company}
            tier={cashFlow.company.tier}
          />
        )}
      </TableCell>
      <TableCell className="InvestmentNameColumn u-textWithEllipsis">
        <ContentWithTooltip
          tooltip={{
            content: cashFlow.transaction_name,
            position: "top center",
            disabled: false,
          }}
        >
          <span>{cashFlow.transaction_name}</span>
        </ContentWithTooltip>
        {investmentTypeLabel && <SubValue>{investmentTypeLabel}</SubValue>}
      </TableCell>
      <TableCell>{cashFlow.transaction_type}</TableCell>
      <TableCell className="DateColumn">{formatDate(cashFlow.date)}</TableCell>
      <TableCell className="NumericColumn">
        <Value type="currency" precision="2">
          {amountToShow}
        </Value>
      </TableCell>
      <TableCell className="NumericColumn">
        <Value type="currency" precision="2">
          {cashFlow.total}
        </Value>
      </TableCell>
    </TableRow>
  );
}

export default CashFlowRow;
