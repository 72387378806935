import React from "react";
import ActivityItem from "components/shared/ActivityItem";
import moment from "moment";

const renderDate = (date) => (
  <div className="u-label ActivityFeedDivider">
    {moment(date).format("ll")}
    <hr className="ActivityFeedDivider-divider" />
  </div>
);

const renderActivityListItem = (
  event,
  index,
  events,
  company,
  investment,
  portfolios,
  organizationUsername,
) => {
  const activityItemWithoutKey = (
    <ActivityItem
      values={{ company, investment, event, portfolios }}
      type={events[index].event_type}
      organizationUsername={organizationUsername}
    />
  );

  if (
    index === 0 ||
    (events[index - 1] &&
      moment(event.created_at).date() !==
        moment(events[index - 1].created_at).date())
  ) {
    return (
      <div key={index}>
        {renderDate(event.created_at)}
        {activityItemWithoutKey}
      </div>
    );
  }
  return (
    <ActivityItem
      key={index}
      values={{ company, investment, event, portfolios }}
      type={events[index].event_type}
      organizationUsername={organizationUsername}
    />
  );
};

export default renderActivityListItem;
