import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  isCollectionFetching,
  isEntityFetching,
  getCollectionAsObject,
} from "store/domain/selectors";
import { reloadInvestment } from "store/investments/investment/actions";
import {
  bulkUpdateDistributions,
  reloadDistributions,
} from "store/investments/distributions/actions";
import {
  investmentPropType,
  distributionsPropType,
  companyPropType,
} from "PropTypes";
import DistributionTable from "./DistributionTable";
import DistributionHistoryChart from "./DistributionHistoryChart";
import { openInModal, ModalNames } from "store/modal";
import { openSidebar } from "store/sidebar";

function DistributionHistoryContainer({
  collection,
  companies,
  investment,
  fetchAfterUpdateDocuments,
  openDocumentsViewerModal,
  isFetching,
  bulkUpdate,
  openLogDistributionSidebarForm,
  organizationUsername,
}) {
  return (
    <div className="DistributionHistory">
      <DistributionHistoryChart
        collection={collection}
        investment={investment}
      />
      <DistributionTable
        collection={collection}
        companies={companies}
        investment={investment}
        fetchAfterUpdateDocuments={fetchAfterUpdateDocuments}
        openDocumentsViewerModal={openDocumentsViewerModal}
        isFetching={isFetching}
        bulkUpdate={bulkUpdate}
        openLogDistributionSidebarForm={openLogDistributionSidebarForm}
        organizationUsername={organizationUsername}
      />
    </div>
  );
}

DistributionHistoryContainer.propTypes = {
  collection: distributionsPropType.isRequired,
  investment: investmentPropType.isRequired,
  companies: PropTypes.objectOf(companyPropType),
  fetchAfterUpdateDocuments: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  bulkUpdate: PropTypes.func.isRequired,
  openDocumentsViewerModal: PropTypes.func.isRequired,
  openLogDistributionSidebarForm: PropTypes.func.isRequired,
  organizationUsername: PropTypes.string,
};

function makeMapStateToProps() {
  return function mapStateToProps(state, { investment }) {
    const {
      organizations: { currentOrganizationUsername },
    } = state;
    return {
      investment: investment,
      companies: getCollectionAsObject(state, "companies"),
      isFetching:
        isCollectionFetching(state, "Distributions") ||
        isEntityFetching(state, "investments", investment.id),
      organizationUsername: currentOrganizationUsername,
    };
  };
}

function mapDispatchToProps(dispatch, { investment }) {
  const { id: investmentId } = investment;
  return {
    bulkUpdate: (payload) => {
      return dispatch(bulkUpdateDistributions(investmentId, payload)).then(
        (data) => {
          return Promise.all([dispatch(reloadInvestment(investmentId))]).then(
            () => {
              return data;
            },
          );
        },
      );
    },
    fetchAfterUpdateDocuments: (itemId) =>
      dispatch(reloadDistributions(investmentId)).then((updateData) => {
        return updateData.find((data) => data.id === itemId).documents_data;
      }),
    openDocumentsViewerModal: (options) =>
      dispatch(openInModal(ModalNames.documentsViewer, options)),
    openLogDistributionSidebarForm: (investment) =>
      dispatch(
        openSidebar("distributionForm", "Log Distribution", { investment }),
      ),
  };
}

export default connect(
  makeMapStateToProps,
  mapDispatchToProps,
)(DistributionHistoryContainer);
