import { useMemo } from "react";
import { cloneDeep, orderBy, flatMap } from "lodash";

function expandCashAndEquityDistributions(collection) {
  return flatMap(collection, (item) => {
    if (item.is_cash_proceed && item.is_equity_proceed) {
      const cashItem = {
        ...item,
        is_equity_proceed: false,
        proceed_amount: item.cash_amount,
        equity: null,
      };
      return [item, cashItem];
    } else {
      return item;
    }
  });
}

export default function useInvestmentView(props) {
  const distributions = useMemo(
    () =>
      expandCashAndEquityDistributions(
        orderBy(props.distributions, ["date", "created_at"], ["desc", "desc"]),
      ),
    [props.distributions],
  );

  const valuations = useMemo(
    () => orderBy(props.valuations, ["date", "created_at"], ["desc", "desc"]),
    [props.valuations],
  );

  const capitalCallsForGraph = useMemo(() => {
    const c = cloneDeep(
      orderBy(props.capitalCalls, ["date", "created_at"], ["asc", "asc"]),
    );
    c.reduce((acc, currentValue) => {
      currentValue.amount = parseInt(currentValue.amount) + parseInt(acc);
      return currentValue.amount;
    }, 0);
    return c;
  }, [props.capitalCalls]);

  const capitalCalls = useMemo(
    () => orderBy(props.capitalCalls, ["date", "created_at"], ["desc", "desc"]),
    [props.capitalCalls],
  );

  return [distributions, valuations, capitalCalls, capitalCallsForGraph];
}
