import React from "react";
import Link from "components_new/atoms/Link";
import { isEmpty } from "lodash";
import InvestmentSummary from "./InvestmentSummary/InvestmentSummary";
import FundSummary from "./InvestmentSummary/FundSummary";
import DealSummary from "./DealSummary";

function CompanyLink({ company, organizationUsername }) {
  return (
    <div className="LinkCompanyName u-textWithEllipsis">
      <Link to={`/${organizationUsername}/companies/${company.id}`}>
        {company.name}
      </Link>
    </div>
  );
}

function PortfolioLink({ portfolio, organizationUsername }) {
  return (
    <div className="PortfoliosList">
      <div className="PortfoliosList-item u-textWithEllipsis">
        <Link to={`/${organizationUsername}/portfolios/${portfolio.id}`}>
          {portfolio.name}
        </Link>
      </div>
    </div>
  );
}

function InvestmentOverviewSection({
  investment,
  company,
  deal,
  organizationUsername,
}) {
  const isFund = investment.type === "fund";
  const performance = {
    cashCost: investment.amount || "0.00",
    costBasis: investment.cost_basis,
    totalAcquisitionAmount: investment.amount,
    fairMarketValue: investment.total_valuation_amount,
    unrealizedGain: investment.gain,
    realizedGain: investment.payout,
    totalReturn: investment.total_gain,
    multiple: investment.multiple,
    capital_called: investment.capital_called,
    percent_called: investment.percent_called,
    unfunded_amount: investment.unfunded_amount,
    tvpiMultiple: investment.tvpi_multiple,
  };

  const portfolio = {
    id: investment.portfolio_id,
    name: investment.portfolio_name,
  };

  return (
    <div className="OverviewSection">
      <h2 className="u-sectionHeading">Investment Summary</h2>
      <div className="OverviewSection-body">
        <div className="OverviewSection-sectionsWrap">
          <div className="OverviewSection-block">
            <h5 className="OverviewSection-blockTitle">Company</h5>
            <CompanyLink
              company={company}
              organizationUsername={organizationUsername}
            />
          </div>
          <div className="OverviewSection-block">
            <h5 className="OverviewSection-blockTitle">Fund</h5>
            <PortfolioLink
              portfolio={portfolio}
              organizationUsername={organizationUsername}
            />
          </div>
          {isFund ? (
            <FundSummary performance={performance} />
          ) : (
            <InvestmentSummary performance={performance} />
          )}
          {!isEmpty(deal) && (
            <DealSummary
              deal={deal}
              company={company}
              organizationUsername={organizationUsername}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default InvestmentOverviewSection;
