import React from "react";
import ViewStyleRouter from "./ViewStyleRouter";
import CompanyTabPane from "./CompanyTabPane";

export function panes(props) {
  return [
    {
      menuItem: "All Companies",
      render: () => (
        <CompanyTabPane type="all" {...props}>
          {(childProps) => <ViewStyleRouter {...childProps} />}
        </CompanyTabPane>
      ),
      path: "",
    },
    {
      menuItem: "Portfolio",
      render: () => (
        <CompanyTabPane type="portfolio" {...props}>
          {(childProps) => <ViewStyleRouter {...childProps} />}
        </CompanyTabPane>
      ),
      path: "portfolio",
    },
    {
      menuItem: "Investors",
      render: () => (
        <CompanyTabPane type="investors" {...props}>
          {(childProps) => <ViewStyleRouter {...childProps} />}
        </CompanyTabPane>
      ),
      path: "investors",
    },
    {
      menuItem: "Partners",
      render: () => (
        <CompanyTabPane type="partners" {...props}>
          {(childProps) => <ViewStyleRouter {...childProps} />}
        </CompanyTabPane>
      ),
      path: "partners",
    },
  ];
}

export const panesTabIndex = {
  all: 0,
  portfolio: 1,
  investors: 2,
  partners: 3,
};
