import { useMutation, useQueryClient } from "react-query";
import Api from "lib/Api";

export function useCreateInvestmentPost() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ resource, postContent }) => {
      return Api.post(`/investments/${resource.investment_id}/posts`, {
        post: postContent,
      });
    },
    {
      onSuccess: ({ data }) => {
        queryClient.invalidateQueries([
          "InvestmentPosts",
          data.resource.investment_id,
        ]);
      },
      onError: (error) => {
        throw error;
      },
    },
  );
}
