import PropTypes, {
  string,
  number,
  bool,
  func,
  arrayOf,
  shape,
  oneOfType,
} from "prop-types";

const numberOrString = PropTypes.oneOfType([number, string]);

const tagPropType = shape({
  id: string,
  name: string,
});

const jsonContactPropType = shape({
  name: string,
});

// TODO consolidate
export const companyAvatarPropType = shape({
  id: string,
  name: string,
  logo_url: string,
  status: string,
  verified: bool,
});

export const companyDetailsPropType = shape({
  tags: arrayOf(tagPropType),
});

export const companyPublicDataPropType = shape({
  headquarters: string,
  website: string,
  description: string,
  crunchbase_founders: arrayOf(jsonContactPropType),
  founders: string,
});

// TODO consolidate
const CurrentHoldingTotalsPropType = shape({
  fully_diluted_shares_percentage: numberOrString,
  cost_basis: numberOrString,
  value: numberOrString,
  payout: numberOrString,
  gain: numberOrString,
  realized_gain: numberOrString,
  multiple: numberOrString,

  investment_ids: arrayOf(string).isRequired,
  current_holding_count: number.isRequired,
});

// TODO consolidate
const PreviousHoldingTotalsPropType = shape({
  quantity: numberOrString,
  cost_basis: numberOrString,
  payout: numberOrString,
  realized_gain: numberOrString,
  multiple: numberOrString,
  adjustments: numberOrString,

  investment_ids: arrayOf(string).isRequired,
  previous_holding_count: number.isRequired,
});

// TODO consolidate
export const companyHoldingTotalsPropType = shape({
  id: string,
  investment_ids: arrayOf(string),

  first_investment_date: string,
  total_quantity: numberOrString,
  total_percent_fully_diluted: numberOrString,

  invested: numberOrString,
  cost_basis: numberOrString,
  value: numberOrString,
  gain: numberOrString,
  payout: numberOrString,
  total_gain: numberOrString,
  multiple: numberOrString,

  current: CurrentHoldingTotalsPropType,
  previous: PreviousHoldingTotalsPropType,
});

export const companySummaryPropType = shape({
  title: string,
  label: string,
  labelColor: string,
  imageUrl: string,
  editFunc: func,
  description: string,
  attributes: arrayOf(shape({})),
  moreInformation: arrayOf(shape({})),
});

// TODO consolidate
export const investmentDataPropType = shape({
  id: string,
  name: string,
  custom_name: string,
  series_name: string,
  type: string,

  previous_holding: bool,
  quantity: numberOrString,
  fully_diluted_shares_percentage: numberOrString,
  cost_basis: numberOrString,
  value: numberOrString,
  payout: numberOrString,
  gain: numberOrString,
  realized_gain: numberOrString,
  adjustments: numberOrString,
  multiple: numberOrString,

  quantity_text: string,
  cost_basis_subhead: string,
  valuation_subhead: string,
  origin_note: string,
});

export const fileItemProptype = shape({
  localId: oneOfType([string, number]),
  status: string,
  progress: numberOrString,
  isFileTooBig: bool,
  fileObject: shape({}),
});

export const uploadedDocPropType = shape({
  localId: string,
  status: string,
  handle: string,
  filename: string,
  closing_document: bool,
  document_type: string,
  url: string,
  size: number,
  mimetype: string,
  fileObject: shape({}),
});

export const stageProptype = shape({
  id: number,
  can_be_deleted: string,
  name: string,
  order: number,
  created_at: string,
});

export const dealProptype = PropTypes.shape({
  id: PropTypes.string.isRequired,
  pipeline_id: PropTypes.string.isRequired,
  company_id: PropTypes.string.isRequired,
  stage_id: PropTypes.number,
  staged_at: PropTypes.string.isRequired,
  owner_id: PropTypes.number,
  owner_name: PropTypes.string,
  date: PropTypes.string,
  round_name: PropTypes.string,
  amount_raised_in_round: PropTypes.string,
  company_valuation: PropTypes.string,
  company_valuation_type: PropTypes.string,
  allocation: PropTypes.string,
  notes: PropTypes.string,
});

export const itemPropType = PropTypes.shape({
  title: PropTypes.string,
  subtitle: PropTypes.string,
  imageUrl: PropTypes.string,
  avatarIcon: PropTypes.string,
  imagePosition: PropTypes.oneOf(["left", "right"]),
  onClick: PropTypes.func,
  active: PropTypes.bool,
});

export const dropdownOption = PropTypes.shape({
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.string,
  value: PropTypes.string,
});
