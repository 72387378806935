import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import removePost from "lib/posts/removePost";
import Api from "lib/Api";

function removeContactPost(oldData, postId) {
  return {
    ...oldData,
    posts: removePost(oldData.posts, postId),
  };
}

export function useRemoveContactPost() {
  const queryClient = useQueryClient();
  const { organizationUsername } = useParams();

  return useMutation(
    ({ postId, contactId }) =>
      Api.delete(`v2/contacts/${contactId}/posts/${postId}`),
    {
      onSuccess: (_, { contactId, postId }) => {
        return queryClient.setQueryData(
          ["Contacts", organizationUsername, contactId],
          (oldData) => removeContactPost(oldData, postId),
        );
      },
    },
  );
}
