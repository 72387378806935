import React, { useState } from "react";
import Input from "components_new/atoms/Input";
import Button from "components_new/atoms/Button";
import Icon from "components_new/atoms/Icon";
import { isEmpty, times } from "lodash";
import "./ContactEmails.scss";

const INITIAL_EMAIL_COUNT = 1;
const getInputTitle = (index) =>
  index > 0 ? `Email Address ${index + 1}` : `Primary Email`;

function ContactEmails({ value: emails, onChange, errors }) {
  const [emailsCount, setEmailsCount] = useState(
    isEmpty(emails) ? INITIAL_EMAIL_COUNT : emails.length,
  );

  const handleEmailChange = (value, key) => {
    const nextValues = [...emails];
    nextValues[key] = { key, value };

    onChange(nextValues);
  };

  return (
    <div className="ContactEmails">
      {times(emailsCount, (index) => (
        <Input
          index={index}
          label={getInputTitle(index)}
          placeholder="e.g. name@domain.com"
          value={emails[index]?.value}
          onChange={(e, { value }) => {
            handleEmailChange(value, index);
          }}
          errorMessage={errors.for(`emails[${index}]`)}
          size="tiny"
        />
      ))}
      <Button
        secondary
        size="tiny"
        className="ContactEmails-AddNewEmail"
        onClick={() => setEmailsCount((count) => count + 1)}
      >
        <Icon icon="ri-add-line" />
        Add Email
      </Button>
    </div>
  );
}

export default ContactEmails;
