import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SearchAndSelectField as SearchAndSelect } from "components_new/elements/SearchAndSelect";
import Documents from "components_new/SidebarComponents/forms/groups/Documents";
import FormFooter from "components_new/SidebarComponents/SidebarForm/FormFooter";
import RequiredNote from "components_new/SidebarComponents/shared/RequiredNote";
import FormGroup from "components_new/elements/FormGroup";
import redirectFromInitialValue from "components_new/lib/redirectFromInitialValue";
import { isEmpty, merge } from "lodash";
import useForm from "lib/hooks/useForm";
import { addTaskAttachmentsToFormDocuments } from "components_new/SidebarComponents/forms/config";
import { isUploading } from "components_new/elements/DropZone/config";

function FilesForm({
  ownName,
  prefilledSelection,
  taskId,
  taskAttachments,
  uploadDocuments,
  closeSidebar,
  showConfirmationMessage,
  currentOrganizationUsername,
}) {
  const INITIAL_VALUES = {
    formData: {
      selection: prefilledSelection || {},
      documents_data: [],
      formOrigin: ownName,
    },
  };

  const [isLoading, setIsLoading] = useState(false);
  const [uploadQueue, setUploadQueue] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const initialValues = merge(
    {},
    INITIAL_VALUES,
    addTaskAttachmentsToFormDocuments(taskId, taskAttachments),
  );

  const navigate = useNavigate();

  const submit = (clientData) => {
    setIsLoading(true);
    uploadDocuments(
      clientData.documents_data,
      clientData.selection,
      prefilledSelection,
    )
      .then(() => {
        closeSidebar();
        navigate(
          redirectFromInitialValue({
            formData,
            prefilledSelection,
            currentOrganizationUsername,
          }),
        );
        showConfirmationMessage();
      })
      .catch((error) => {
        setIsLoading(false);
        handleErrors(error);
      });
  };
  const {
    formData,
    handleChange,
    handleSubmit,
    handleErrors,
    touched,
  } = useForm(initialValues, submit);
  const { selection, documents_data } = formData;

  useEffect(() => {
    setDisableSubmit(isEmpty(selection.id) || isUploading(uploadQueue));
  }, [selection, uploadQueue]);

  return (
    <div className="FilesForm">
      {/* TODO: move to own component */}
      <FormGroup
        extraClassnames="FilesGroup"
        title="Files Details"
        required={true}
        isExpanded={true}
      >
        <SearchAndSelect
          name="selection"
          label="Company, Investment, or Deal"
          placeHolder="Select a company, investment, or deal"
          onChange={(value) =>
            handleChange("selection", {
              ...value,
              company_id: isEmpty(value) ? null : value.id,
            })
          }
          scope="all"
          size="tiny"
          initialSelection={formData.selection}
        />
      </FormGroup>
      <Documents
        isExpanded
        subtitle="Attach files to a company, deal or investment"
        onChange={(documents) => handleChange("documents_data", documents)}
        onQueueChange={setUploadQueue}
        documents={documents_data}
        prefilledFilesStatus="editing"
      />
      <RequiredNote />
      <FormFooter
        componentName={ownName}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        disableSubmit={disableSubmit}
        touched={touched}
      />
    </div>
  );
}

export default FilesForm;
