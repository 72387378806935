import React from "react";
import classNames from "classnames";
import { Checkbox as SemanticCheckbox } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/checkbox.css";
import "./Checkbox.scss";
import useInputId from "lib/hooks/useInputId";

function Checkbox(props) {
  const {
    checked,
    extraClassName,
    error,
    disabled,
    readOnly,
    label,
    onTable,
    focused,
    size,
    ...checkboxProps
  } = props;
  const checkboxClasses = classNames("Checkbox", {
    [extraClassName]: !!extraClassName,
    error: !!error,
    disabled: !!disabled,
    readOnly: !!readOnly,
    focused: !!focused,
    [size]: !!size,
  });

  const id = useInputId();

  return (
    <SemanticCheckbox
      id={id}
      className={checkboxClasses}
      checked={checked}
      label={
        <label htmlFor={id}>
          <span>{label}</span>
        </label>
      }
      disabled={disabled}
      readOnly={readOnly}
      {...checkboxProps}
    />
  );
}

export default Checkbox;
