import PropTypes from "prop-types";
import React from "react";

function Spinner(props) {
  const { children } = props;

  return (
    <div className="spinner">
      <div className="Spinner-container">
        <div className="SpinnerIcon" />
      </div>
      {children}
    </div>
  );
}

Spinner.propTypes = {
  children: PropTypes.string,
};

export default Spinner;
