export const integrationList = {
  slack: {
    name: "Slack",
    logoUrl: "/assets/icons/miscellaneous/slack.svg",
    integrationDescription:
      "Connect your slack account to send portfolios activity updates to a selected Slack channel.",
  },
};

export default { integrationList };
