import React from "react";
import DealSidebarHeader from "./DealSidebarHeader";
import ActiveDealFiles from "components_new/views/Deals/ActiveDeals/ActiveDealContent/DealFiles";
import CollapsableSection from "components_new/elements/CollapsableSection";
import DiscussionsDeal from "components_new/views/Deals/ActiveDeals/ActiveDealContent/Discussions";
import ActiveDealHeader from "components_new/views/Deals/ActiveDeals/ActiveDealContent/ActiveDealHeader";
import DealCompanyCard from "components_new/views/Deals/ActiveDeals/ActiveDealContent/DealCompanyCard";
import ActiveDealAttributes from "components_new/views/Deals/ActiveDeals/ActiveDealContent/ActiveDealAttributes";
import PropTypes from "prop-types";
import {
  companyPropType,
  dealProptype,
  documentPropType,
  stageProptype,
} from "PropTypes";
import { isEmpty } from "lodash";
import Loader from "components/shared/Loader";
import TaskList from "components_new/views/Deals/ActiveDeals/ActiveDealContent/TaskList";
import "./ActiveDealView.scss";

function ActiveDealView({
  deal,
  stage,
  isLoading,
  company,
  openEditCompanyForm,
  openDeleteDealModal,
  documents,
  saveDealDocuments,
  updateFile,
  removeFile,
  openEditDealForm,
  onConvertToInvesment,
  openReactivateDealModal,
  openEditCommitmentModal,
  openEditPassModal,
  currentOrganizationUsername,
  stagesOptions,
  updateDealStage,
  openCommitToDealModal,
  openPassFromDealModal,
  openContactDetailsView,
  posts,
  closeSidebar,
  onCompleteDealTask,
  onMovePendingDealTask,
  openTaskForm,
}) {
  if (isLoading || isEmpty(deal)) {
    return <Loader />;
  }

  return (
    <div className="ActiveDealView">
      <div className="ActiveDealContent">
        <DealSidebarHeader
          deal={deal}
          openEditDealForm={openEditDealForm}
          onConvertToInvesment={onConvertToInvesment}
          openDeleteDealModal={openDeleteDealModal}
          openReactivateDealModal={openReactivateDealModal}
          openEditCommitmentModal={openEditCommitmentModal}
          openEditPassModal={openEditPassModal}
          stagesOptions={stagesOptions}
          updateDealStage={updateDealStage}
          openCommitToDealModal={openCommitToDealModal}
          openPassFromDealModal={openPassFromDealModal}
          closeSidebar={closeSidebar}
        />
        <ActiveDealHeader
          deal={deal}
          dealStage={stage}
          openDeleteDealModal={openDeleteDealModal}
          stagesOptions={stagesOptions}
          updateDealStage={updateDealStage}
          openEditDealForm={openEditDealForm}
        />
        <DealCompanyCard
          company={company}
          openEditCompanyForm={openEditCompanyForm}
        />
        <ActiveDealAttributes
          deal={deal}
          openContactDetailsView={openContactDetailsView}
        />
        <TaskList
          tasks={deal.tasks}
          onComplete={onCompleteDealTask}
          undoComplete={onMovePendingDealTask}
          openTaskForm={openTaskForm}
        />
        <ActiveDealFiles
          documents={documents}
          dealId={deal.id}
          pipelineId={deal.pipeline_id}
          saveDealDocuments={saveDealDocuments}
          currentOrganizationUsername={currentOrganizationUsername}
          updateFile={updateFile}
          removeFile={removeFile}
          componentSize="small"
        />
        <CollapsableSection
          expanded
          title="Notes"
          extraClassNames="SelectedDeal-Details-Notes"
        >
          <DiscussionsDeal
            posts={posts}
            pipelineId={deal.pipeline_id}
            dealId={deal.id}
          />
        </CollapsableSection>
      </div>
    </div>
  );
}

ActiveDealView.propTypes = {
  isLoading: PropTypes.bool,
  deal: dealProptype,
  stage: stageProptype,
  company: PropTypes.arrayOf(companyPropType),
  openEditCompanyForm: PropTypes.func,
  openDeleteDealModal: PropTypes.func,
  documents: PropTypes.arrayOf(documentPropType).isRequired,
  stagesOptions: PropTypes.arrayOf(PropTypes.object),
  saveDealDocuments: PropTypes.func,
  updateFile: PropTypes.func,
  removeFile: PropTypes.func,
  updateDealStage: PropTypes.func,
  openEditDealForm: PropTypes.func,
  onConvertToInvesment: PropTypes.func,
  openReactivateDealModal: PropTypes.func,
  openEditCommitmentModal: PropTypes.func,
  openEditPassModal: PropTypes.func,
  posts: PropTypes.arrayOf(PropTypes.object),
  currentOrganizationUsername: PropTypes.string.isRequired,
  openCommitToDealModal: PropTypes.func,
  openPassFromDealModal: PropTypes.func,
  closeSidebar: PropTypes.func,
  openContactDetailsView: PropTypes.func,
  onCompleteDealTask: PropTypes.func,
  onMovePendingDealTask: PropTypes.func,
  openTaskForm: PropTypes.func,
};

export default ActiveDealView;
