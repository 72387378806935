import React, { useState, useEffect } from "react";
import classnames from "classnames";
import Button from "components_new/atoms/Button";
import Icon from "components_new/atoms/Icon";
import { Form } from "@ableco/semantic-ui-react";
import "./SuggestedButton.scss";

function SuggestedButton({
  children,
  onAdd,
  onRemove,
  suggestion,
  className,
  isSelected = false,
  label,
  disabled,
}) {
  const [isActive, setIsActive] = useState(false);

  const suggestedButtonClassname = classnames("SuggestedButton", {
    "is-active": isActive,
    [className]: !!className,
  });

  useEffect(() => {
    if (isSelected !== isActive) {
      setIsActive(isSelected);
    }
  }, [isSelected, isActive]);

  const onHandleClick = () => {
    if (!isActive) {
      setIsActive(true);
      onAdd(suggestion);
    }
  };

  const onHandleRemove = () => {
    setIsActive(false);
    if (onRemove) {
      onRemove(suggestion);
    }
  };

  return (
    <Form.Field>
      {label && (
        <label>
          <span>{label}</span>
        </label>
      )}
      <Button
        onClick={onHandleClick}
        className={suggestedButtonClassname}
        disabled={disabled}
      >
        {children}
        {isActive && (
          <Icon
            size="tiny"
            icon="ri-close-circle-fill"
            onClick={onHandleRemove}
          />
        )}
      </Button>
    </Form.Field>
  );
}

export default SuggestedButton;
