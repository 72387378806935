import React from "react";
import { Message as SemanticMessage } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/table.css";
import "./Message.scss";

function MessageHeader({ children, ...props }) {
  return <SemanticMessage.Header {...props}>{children}</SemanticMessage.Header>;
}

export default MessageHeader;
