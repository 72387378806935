import React from "react";

function InvestmentAmount({ obtainedText, quantity }) {
  return (
    <div className="InvestmentAmount">
      <div className="InvestmentAmount-inner">
        <div className="InvestmentAmount-quantity">{quantity}</div>
        <div className="InvestmentAmount-acquired">
          <div className="InvestmentAmount-purchased">{obtainedText}</div>
        </div>
      </div>
    </div>
  );
}

export default InvestmentAmount;
