import Api from "lib/Api";

import {
  addForgotPasswordValidationErrors,
  forgotPasswordSuccess,
} from "actions/synchronous/auth";

export function forgotPassword(email) {
  return (dispatch) => {
    return Api.post(
      "/session/forgot",
      { email },
      { withoutOrganization: true, parseResponse: false },
    )
      .then(() => {
        dispatch(forgotPasswordSuccess());
      })
      .catch((error) => {
        const { errors } = JSON.parse(error.response);
        if (error.status === 422) {
          dispatch(addForgotPasswordValidationErrors(errors));
        }
      });
  };
}
