import {
  COLLECTION_FETCH_START,
  COLLECTION_FETCH_SUCCESS,
  COLLECTION_FETCH_FAIL,
  ITEM_FETCH_START,
  ITEM_FETCH_SUCCESS,
  ITEM_FETCH_FAIL,
  SINGLETON_FETCH_START,
  SINGLETON_FETCH_SUCCESS,
  SINGLETON_FETCH_FAIL,
  MUTATION_START,
  MUTATION_SUCCESS,
  MUTATION_FAIL,
  OVERRIDE_COLLECTION,
  UPDATE_COLLECTION,
  RESET_COLLECTION,
} from "./domainActionTypes";
import { COLLECTION_NAMES, MUTATION_NAMES, SINGLETON_NAMES } from "./config";

export const REQUEST_STATUS = {
  NONE: "REQUEST_STATUS/NONE",
  FETCHING: "REQUEST_STATUS/FETCHING",
  RECEIVED: "REQUEST_STATUS/RECEIVED",
  FAILED: "REQUEST_STATUS/FAILED",
};

const ACTION_TO_REQUEST_STATUS = {
  [COLLECTION_FETCH_START]: REQUEST_STATUS.FETCHING,
  [ITEM_FETCH_START]: REQUEST_STATUS.FETCHING,
  [MUTATION_START]: REQUEST_STATUS.FETCHING,
  [COLLECTION_FETCH_SUCCESS]: REQUEST_STATUS.RECEIVED,
  [ITEM_FETCH_SUCCESS]: REQUEST_STATUS.RECEIVED,
  [MUTATION_SUCCESS]: REQUEST_STATUS.RECEIVED,
  [COLLECTION_FETCH_FAIL]: REQUEST_STATUS.FAILED,
  [ITEM_FETCH_FAIL]: REQUEST_STATUS.FAILED,
  [MUTATION_FAIL]: REQUEST_STATUS.FAILED,
  [SINGLETON_FETCH_START]: REQUEST_STATUS.FETCHING,
  [SINGLETON_FETCH_SUCCESS]: REQUEST_STATUS.RECEIVED,
  [SINGLETON_FETCH_FAIL]: REQUEST_STATUS.FAILED,
};

const initialCollectionState = {
  byId: {},
  asCollection: REQUEST_STATUS.NONE,
  isCollectionAvailable: false,
};

// NOTE: Shape example given an entity called companies, a mutation called createCompany, and
//       a singleton named session:
// {
//   companies: {
//     byId: {
//       hhf7smab: "REQUEST_STATUS/FETCHING",
//       asmdsd: "REQUEST_STATUS/RECEIVED"
//     },
//     asCollection: "REQUEST_STATUS/RECEIVED"
//   },
//   createCompany: "REQUEST_STATUS/RECEIVED",
//   session: "REQUEST_STATUS/RECEIVED"
// }
const initialState = generateInitialState({
  entities: COLLECTION_NAMES,
  mutations: MUTATION_NAMES,
  singletons: SINGLETON_NAMES,
});

function generateInitialState({ entities, mutations, singletons }) {
  const entitiesInitialState = entities.reduce(
    (requestStates, collectionName) => {
      return {
        ...requestStates,
        [collectionName]: initialCollectionState,
      };
    },
    {},
  );
  const mutationsInitialState = mutations.reduce(
    (requestStates, mutationName) => {
      return {
        ...requestStates,
        [mutationName]: REQUEST_STATUS.NONE,
      };
    },
    {},
  );
  const singletonsInitialState = singletons.reduce(
    (singletonsStates, singletonName) => {
      return {
        ...singletonsStates,
        [singletonName]: REQUEST_STATUS.NONE,
      };
    },
    {},
  );
  return {
    ...entitiesInitialState,
    ...mutationsInitialState,
    ...singletonsInitialState,
  };
}

export default function fetchStatusReducer(state = initialState, action) {
  const fetchStatus = ACTION_TO_REQUEST_STATUS[action.type];

  switch (action.type) {
    case COLLECTION_FETCH_START:
    case COLLECTION_FETCH_SUCCESS:
    case COLLECTION_FETCH_FAIL: {
      return {
        ...state,
        [action.collectionName]: {
          ...state[action.collectionName],
          asCollection: fetchStatus,
        },
      };
    }
    case OVERRIDE_COLLECTION:
    case UPDATE_COLLECTION:
      return {
        ...state,
        [action.collectionName]: {
          ...state[action.collectionName],
          isCollectionAvailable: true,
        },
      };
    case RESET_COLLECTION:
      return {
        ...state,
        [action.collectionName]: initialCollectionState,
      };
    case ITEM_FETCH_START:
    case ITEM_FETCH_SUCCESS:
    case ITEM_FETCH_FAIL: {
      return {
        ...state,
        [action.collectionName]: {
          ...state[action.collectionName],
          byId: {
            ...state[action.collectionName].byId,
            [action.id]: fetchStatus,
          },
        },
      };
    }
    case MUTATION_START:
    case MUTATION_SUCCESS:
    case MUTATION_FAIL: {
      return {
        ...state,
        [action.mutationName]: fetchStatus,
      };
    }
    case SINGLETON_FETCH_START:
    case SINGLETON_FETCH_SUCCESS:
    case SINGLETON_FETCH_FAIL: {
      return {
        ...state,
        [action.singletonName]: fetchStatus,
      };
    }
    default:
      return state;
  }
}
