import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Header from "components_new/atoms/Header";
import "./EmptyPage.scss";

function EmptyPage({
  headline,
  subHeadline,
  clickToAction,
  renderIcon,
  customView,
}) {
  const EmptyPageClassNames = classnames("EmptyPage", {
    "is-Light": customView == "light",
  });
  return (
    <div className={EmptyPageClassNames}>
      {renderIcon ? renderIcon() : null}
      <Header as="h1" extraClassName="EmptyPage-heading u-heading">
        {headline}
      </Header>
      {subHeadline && <p className="EmptyPage-subHeading">{subHeadline}</p>}
      {clickToAction && (
        <div className="EmptyPage-clickToAction">{clickToAction()}</div>
      )}
    </div>
  );
}

EmptyPage.propTypes = {
  headline: PropTypes.string.isRequired,
  subHeadline: PropTypes.string,
  clickToAction: PropTypes.func,
  renderIcon: PropTypes.func,
  customView: PropTypes.oneOf(["custom", "light"]),
};

EmptyPage.defaultProps = {
  subHeadline: null,
  clickToAction: null,
  renderIcon: null,
  customView: "custom",
};

export default EmptyPage;
