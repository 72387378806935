import React, { useState } from "react";
import SearchAndSelect from "components_new/elements/SearchAndSelect/SearchAndSelect";
import "./SnapshotItemSearch.scss";

const RESULT_MAP_SECTIONS = {
  company: "companies",
  deal: "deals",
  contact: "contacts",
};

export default function SnapshotItemSearch({
  size = "small",
  type = "company",
  onChange,
  searchForItem,
  value,
}) {
  const [results, setResults] = useState({});
  const [selectedValue, setSelectedValue] = useState(value);
  const [isLoading, setIsLoading] = useState(false);

  const searchItem = ({ query }) => {
    setIsLoading(true);
    searchForItem({ type, query })
      .then(({ data }) => {
        const newResults = {};
        newResults[RESULT_MAP_SECTIONS[type]] = data.map((item) => {
          return {
            ...item,
            logo_url: item.avatar,
          };
        });
        setResults(newResults);
        return newResults;
      })
      .finally(() => setIsLoading(false));
  };

  const handleChange = (value) => {
    const selection = results[RESULT_MAP_SECTIONS[type]].find(
      (item) => item.id === value.id,
    );
    setSelectedValue({ title: "" });
    onChange(selection);
  };

  return (
    <div className="SnapshotItemSearch">
      <SearchAndSelect
        scope="all"
        fetchResults={(value) => searchItem({ query: value })}
        results={results}
        isFetching={isLoading}
        onChange={handleChange}
        initialSelection={selectedValue}
        size={size}
        placeholder={`Search ${type}`}
      />
    </div>
  );
}
