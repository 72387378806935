import React, { useState } from "react";
import PrimaryButton from "components/shared/PrimaryButton";
import { Link } from "react-router-dom";
import TextInput from "components/shared/form/TextInput";
import preventerHandler from "lib/preventerHandler";
import PropTypes from "prop-types";
import useLogin from "store/auth/useLogin";

function InvitationAuth({
  comeFromNewUser,
  organizationName,
  goToForgotview,
  ...props
}) {
  const [email, setEmail] = useState(comeFromNewUser ? "" : props.email);
  const [password, setPassword] = useState("");
  const [loginValidationErrors, setLoginValidationErrors] = useState(null);

  const { doLogin } = useLogin();

  /* eslint-disable unicorn/consistent-function-scoping */
  const handleSubmit = async () => {
    try {
      await doLogin(email, password, "/personal-settings/organizations");
    } catch (error) {
      if (error.hasValidationErrors) {
        setLoginValidationErrors(error.errors);
      }
    }
  };
  /* eslint-enable unicorn/consistent-function-scoping */

  return (
    <form
      onSubmit={preventerHandler(() => handleSubmit())}
      className="InvitationOrgFormContainer"
    >
      <h1 className="u-heading LoginAnimatedElement">Welcome!</h1>
      <p className="FormElement invitationtMessage LoginAnimatedElement SignUpRequestMessage">
        You've been invited to collaborate with <b>{organizationName}</b> on
        Mighty Organization. Please enter your email address to continue.
      </p>
      <TextInput
        autoFocus
        extraClassnames="LoginAnimatedElement"
        placeholder="Email address"
        label="Email address"
        name="email"
        value={comeFromNewUser ? undefined : email}
        readOnly={!comeFromNewUser}
        onChange={setEmail}
        autoComplete="off"
      />
      <TextInput
        autoFocus={!comeFromNewUser}
        typeOfInput="password"
        extraClassnames="LoginAnimatedElement FormElementPassword"
        placeholder="Password"
        label="Password"
        name="password"
        errorMessage={loginValidationErrors}
        onChange={setPassword}
        autoComplete="off"
      />
      <Link
        to="#"
        className="LoginAnimatedElement ForgotLink FormElement"
        onClick={preventerHandler(goToForgotview)}
      >
        Forgot your password?
      </Link>
      <PrimaryButton
        customClass="LoginAnimatedElement FormElement"
        type="submit"
        disabled={password === ""}
      >
        <span className="Button-text">Sign In</span>
      </PrimaryButton>
    </form>
  );
}

InvitationAuth.propTypes = {
  isRevokedInvite: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  loginValidationErrors: PropTypes.string,
  organizationName: PropTypes.string.isRequired,
  goToForgotview: PropTypes.func.isRequired,
  doLogin: PropTypes.func.isRequired,
  organizationMembershipId: PropTypes.number,
  organizationUserName: PropTypes.string.isRequired,
  comeFromNewUser: PropTypes.bool,
};

InvitationAuth.defaultProps = {
  comeFromNewUser: false,
};

export default InvitationAuth;
