import Api from "lib/Api";
import {
  mutationStart,
  mutationSuccess,
  mutationFail,
} from "store/domain/domainActions";
import { fetchPortfolioV2 } from "store/portfolios/actions";
import { fetchCompanyV2 } from "store/companies/company/actions";
import { receiveCollections, receiveCollection } from "store/entities";
import { isInCurrentView } from "store/investments/investment/actions";
import { resourceTypeForValuation } from "lib/globalActionsHelper";
import { updateView } from "store/views";
import { isEmpty } from "lodash";

export function fetchValuations(investmentId) {
  return (dispatch) => {
    dispatch(mutationStart("createValuation"));
    return Api.get(`/investments/${investmentId}/investment_valuations`).then(
      ({ data: { entities } }) => {
        const { valuation_histories: valuationHistories } = entities;
        const valuations = valuationHistories[0].valuations;
        dispatch(receiveCollections(entities));
        dispatch(receiveCollection("valuationHistories", valuations));
        dispatch(mutationSuccess("createValuation"));
      },
    );
  };
}

export function addDocumentsToInvesmentView(dispatch, documents) {
  documents.forEach(({ id }) =>
    dispatch(updateView("investment", "document_ids", id)),
  );
}

export function bulkUpdateValuations(investmentId, payload) {
  return (dispatch) => {
    return Api.patch(`/investments/${investmentId}/investment_valuations`, {
      operations: payload,
    }).then(({ data }) => {
      const { entities } = data;
      if (entities) {
        data.success = true;
        dispatch(receiveCollections(entities));
        addDocumentsToInvesmentView(dispatch, entities.documents);
      }
      return data;
    });
  };
}

export function addValuation(formData) {
  const currentSelection = formData.selection;
  const resourcesType = resourceTypeForValuation(currentSelection.type);
  const resourceId = currentSelection.id;
  return (dispatch, getState) => {
    dispatch(mutationStart("createValuation"));
    return Api.patch(`/v2/${resourcesType}/${resourceId}/update_valuation`, {
      update_valuation: formData,
    })
      .then(({ data }) => {
        const {
          company: companyView,
          investment: investmentView,
          portfolio: portfolioView,
        } = getState().views;

        if (isEmpty(data.entities)) {
          if (isInCurrentView(companyView, data.id)) {
            dispatch(fetchCompanyV2(data.id));
          }
        } else {
          const { entities } = data;
          if (isInCurrentView(investmentView, resourceId)) {
            const {
              views: {
                investment: { document_ids },
              },
            } = data;
            document_ids.forEach((id) => {
              dispatch(updateView("investment", "document_ids", id));
            });

            dispatch(receiveCollections(entities));
          }

          data.success = true;
          const valuations = entities.valuation_histories[0];

          if (isInCurrentView(companyView, valuations.company_id)) {
            dispatch(fetchCompanyV2(valuations.company_id));
          }

          if (isInCurrentView(portfolioView, valuations.portfolio_id)) {
            dispatch(fetchPortfolioV2(valuations.portfolio_id));
          }
        }

        dispatch(mutationSuccess("createValuation"));

        return data;
      })
      .catch((error) => {
        dispatch(mutationFail("createValuation"));
        throw error;
      });
  };
}
