import {
  UPSERT_COLLECTION_2,
  CLEAR_COLLECTION_2,
  UPSERT_RESOURCE,
  REMOVE_RESOURCE,
} from "./actions";

import { keyBy, union, property, uniq, omit, without } from "lodash";

const initialState = {
  organizations: { ids: [], byId: {} },
  portfolios: { ids: [], byId: {} },
  industries: { ids: [], byId: {} },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPSERT_RESOURCE:
      return {
        ...state,
        [action.collectionName]: {
          byId: {
            ...state[action.collectionName].byId,
            [action.resource.id]: {
              ...(state[action.collectionName].byId[action.resource.id] || {}),
              ...action.resource,
            },
          },
          ids: uniq([
            ...state[action.collectionName].ids,
            ...[action.resource.id],
          ]),
        },
      };
    case REMOVE_RESOURCE:
      return {
        ...state,
        [action.collectionName]: {
          byId: omit(state[action.collectionName].byId, action.id),
          ids: without(state[action.collectionName].ids, action.id),
        },
      };
    case UPSERT_COLLECTION_2:
      return {
        ...state,
        [action.collectionName]: upsertResources(
          state,
          action.collectionName,
          action.collection,
        ),
      };
    case CLEAR_COLLECTION_2:
      return {
        ...state,
        [action.collectionName]: {
          byId: {},
          ids: [],
        },
      };
    default:
      return state;
  }
}

function upsertResources(state, resourceName, resourceCollection) {
  if (state[resourceName]) {
    return {
      byId: {
        ...state[resourceName].byId,
        ...keyBy(resourceCollection, property("id")),
      },
      ids: union(
        state[resourceName].ids,
        resourceCollection.map(property("id")),
      ),
    };
  } else {
    return {
      byId: keyBy(resourceCollection, property("id")),
      ids: resourceCollection.map(property("id")),
    };
  }
}
