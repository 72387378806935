import React from "react";
import AttachmentsList from "./AttachmentsList";
import classNames from "classnames";
import Dropdown from "components_new/atoms/Dropdown";

const NOTE_TYPES = [
  { key: "call", text: "Call", value: "call" },
  { key: "conference", text: "Conference", value: "conference" },
  { key: "diligence", text: "Diligence", value: "diligence" },
  { key: "hangout", text: "Hangout", value: "hangout" },
  { key: "meeting", text: "Meeting", value: "meeting" },
  { key: "update", text: "Update", value: "update" },
];

function HtmlEditorExtras({
  attachments,
  onAttachFilesClick,
  onRemoveDocument,
  showAddAttachmentLink,
  showOptions,
  onChangeOptions,
  optionValue,
}) {
  const HtmlEditotExtrasClassnames = classNames("HtmlEditorExtras", {
    "has-attachments": attachments.length > 0,
  });
  return (
    <div className={HtmlEditotExtrasClassnames}>
      <AttachmentsList
        attachments={attachments}
        onAttachFilesClick={onAttachFilesClick}
        onRemoveDocument={onRemoveDocument}
        showAddAttachmentLink={showAddAttachmentLink}
      />
      {showOptions && (
        <Dropdown
          selection
          fluid
          onChange={(_, option) => onChangeOptions(option.value)}
          options={NOTE_TYPES}
          value={optionValue}
          placeholder="Select a note type"
          size="tiny"
        />
      )}
    </div>
  );
}

export default HtmlEditorExtras;
