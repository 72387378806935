import React from "react";
import PropTypes from "prop-types";

import InlineValueList from "components_new/atoms/InlineValueList";
import Icon from "components_new/atoms/Icon";
import Segment from "components_new/atoms/Segment";
import Header from "components_new/atoms/Header";
import "./AttributeList.scss";

function AttributeList({ title, attributes, highlighted }) {
  return (
    <Segment vertical padded className="AttributeList">
      {title && (
        <Header size="small">
          {title}
          {highlighted && <Icon icon="ri-star-fill" />}
        </Header>
      )}
      <InlineValueList data={attributes} />
    </Segment>
  );
}

AttributeList.propTypes = {
  title: PropTypes.string,
  attributes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  highlighted: PropTypes.bool,
};

export default AttributeList;
