import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import updatePost from "lib/posts/updatePost";
import Api from "lib/Api";

function updateContactPost(oldData, post) {
  return {
    ...oldData,
    posts: updatePost(oldData.posts, post),
  };
}

export function useUpdateContactPost() {
  const queryClient = useQueryClient();
  const { organizationUsername } = useParams();

  return useMutation(
    ({ content, postId, contactId }) =>
      Api.patch(`v2/contacts/${contactId}/posts/${postId}`, { post: content }),
    {
      onSuccess: ({ data: post }, { contactId }) => {
        return queryClient.setQueryData(
          ["Contacts", organizationUsername, contactId],
          (oldData) => updateContactPost(oldData, post),
        );
      },
    },
  );
}
