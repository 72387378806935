import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useSnapshots } from "components_new/views/Snapshots/hooks/queries/useSnapshots";
import { useUpdateSnapshot } from "components_new/views/Snapshots/hooks/mutations/useUpdateSnapshot";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";

import SnapshotForm from "components_new/views/Snapshots/SnapshotForm";
import getMessage from "components_new/lib/Messages";

function EditSnapshotWrapper() {
  const { addToast } = useSetToast();
  const { id: currentSanpshotId } = useParams();

  const { data = {}, isLoading } = useSnapshots();
  const { mutateAsync: updateSnapshot } = useUpdateSnapshot();
  const { snapshots = [] } = data;

  const snapshot = snapshots.find((item) => item.id === currentSanpshotId);
  const snapshotType = snapshot?.snapshot_type || "deal";

  const handleEditSnapshot = useCallback(
    async (formData) => {
      return updateSnapshot({ snapshotId: currentSanpshotId, formData });
    },
    [currentSanpshotId, updateSnapshot],
  );

  const showConfirmationMessage = () => addToast(getMessage("editSnapshot"));

  return (
    <SnapshotForm
      isLoading={isLoading}
      isEditing={true}
      snapshot={snapshot}
      submitAction={handleEditSnapshot}
      showConfirmationMessage={showConfirmationMessage}
      snapshotType={snapshotType}
    />
  );
}

export default EditSnapshotWrapper;
