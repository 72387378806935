import PropTypes from "prop-types";

export const decimalPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);

export const companyPropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  logo_url: PropTypes.string,
  gain: PropTypes.string,
  invested: PropTypes.string,
  cost_basis: PropTypes.string,
  investment_ids: PropTypes.arrayOf(PropTypes.string),
  multiple: PropTypes.string,
  payout: PropTypes.string,
  total_quantity: PropTypes.number,
  value: decimalPropType,
});

export const companyAvatarPropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  logo_url: PropTypes.string,
  status: PropTypes.string,
  verified: PropTypes.bool,
});

export const stockSplitPropType = PropTypes.shape({
  date: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  documents_data: PropTypes.array,
  notes: PropTypes.string,
  split_ratio_from: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  split_ratio_to: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

export const investmentDataPropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  custom_name: PropTypes.string,
  series_name: PropTypes.string,
  type: PropTypes.string,

  previous_holding: PropTypes.bool,

  quantity: decimalPropType,
  fully_diluted_shares_percentage: decimalPropType,
  cost_basis: decimalPropType,
  value: decimalPropType,
  payout: decimalPropType,
  gain: decimalPropType,
  realized_gain: decimalPropType,
  adjustments: decimalPropType,
  multiple: decimalPropType,

  quantity_text: PropTypes.string,
  cost_basis_subhead: PropTypes.string,
  valuation_subhead: PropTypes.string,
  origin_note: PropTypes.string,
});

export const investmentPropType = PropTypes.shape({
  total_acquisition_amount: decimalPropType,
  cost_basis: PropTypes.string,
  unit_cost_basis: PropTypes.string,
  accrued_interest: decimalPropType,
  company_id: PropTypes.param,
  company_name: PropTypes.string,
  gain: PropTypes.string,
  id: PropTypes.string,
  membership_units_or_percentage: PropTypes.string,
  multiple: PropTypes.string,
  name: PropTypes.string,
  payout: PropTypes.string,
  percent_called: PropTypes.string,
  quantity: decimalPropType,
  type: PropTypes.string,
  total_valuation_amount: PropTypes.string,
  valuation_unit: PropTypes.string,
  valuation_unit_plural: PropTypes.string,
  original_quantity: PropTypes.number,
});

export const documentPropType = PropTypes.shape({
  closing_document: PropTypes.bool,
  company_id: PropTypes.string,
  created_at: PropTypes.string,
  file_name: PropTypes.string,
  id: PropTypes.number,
  investment_id: PropTypes.string,
  investment_name: PropTypes.string,
  mime_type: PropTypes.string,
  shorthand_full_name: PropTypes.string,
  updated_at: PropTypes.string,
  user_name: PropTypes.string,
});

export const errorsPropType = PropTypes.shape({
  for: PropTypes.func,
});

export const userPropType = PropTypes.shape({
  id: PropTypes.number,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  full_name: PropTypes.string,
  initials: PropTypes.string,
  email: PropTypes.string,
  phone_last_digits: PropTypes.string,
  phone_number: PropTypes.string,
});

export const portfolioCompanyDataPropType = PropTypes.shape({
  id: PropTypes.string,
  company_id: PropTypes.string,
  portfolio_id: PropTypes.string,
  investment_ids: PropTypes.arrayOf(PropTypes.string),
  current_holding_totals: CurrentHoldingTotalsPropType,
  previous_holding_totals: PreviousHoldingTotalsPropType,
});

export const companyHoldingTotalsPropType = PropTypes.shape({
  id: PropTypes.string,
  investment_ids: PropTypes.arrayOf(PropTypes.string),

  first_investment_date: PropTypes.string,
  total_quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total_percent_fully_diluted: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),

  invested: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  cost_basis: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  gain: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  payout: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total_gain: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  multiple: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  current: CurrentHoldingTotalsPropType,
  previous: PreviousHoldingTotalsPropType,
});

const CurrentHoldingTotalsPropType = PropTypes.shape({
  fully_diluted_shares_percentage: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  cost_basis: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  payout: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  gain: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  realized_gain: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  multiple: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  investment_ids: PropTypes.arrayOf(PropTypes.string).isRequired,
  current_holding_count: PropTypes.number.isRequired,
});

const PreviousHoldingTotalsPropType = PropTypes.shape({
  quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  cost_basis: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  payout: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  realized_gain: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  multiple: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  adjustments: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  investment_ids: PropTypes.arrayOf(PropTypes.string).isRequired,
  previous_holding_count: PropTypes.number.isRequired,
});

export const portfolioPropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
});

export const debtInvestmentProptype = PropTypes.shape({
  name: PropTypes.string,
  total_acquisition_amount: decimalPropType,
  date: PropTypes.string,
  note_maturity_date: PropTypes.string,
  interest_rate: decimalPropType,
  interest_schedule: PropTypes.string,
  interest_accrual_schedule: PropTypes.string,
  conversion_terms: PropTypes.string,
  conversion_cap: decimalPropType,
  advanced_fields: PropTypes.shape({
    repayment_rights: PropTypes.string,
    repayment_rights_enabled: PropTypes.bool,
  }),
});

export const equityInvestmentProptype = PropTypes.shape({
  name: PropTypes.string,
  total_acquisition_amount: decimalPropType,
  date: PropTypes.string,
  equity_class: PropTypes.string,
  interest_rate: decimalPropType,
  quantity: decimalPropType,
  shares_in_series: PropTypes.number,
  advanced_fields: PropTypes.shape({
    share_in_series: PropTypes.string,
    share_in_series_enabled: PropTypes.bool,
    participating_enabled: PropTypes.bool,
  }),
});

export const fundInvestmentProptype = PropTypes.shape({
  name: PropTypes.string,
  total_acquisition_amount: decimalPropType,
  date: PropTypes.string,
  equity_class: PropTypes.string,
  fund_size: decimalPropType,
  fund_vintage: decimalPropType,
});

export const llcInvestmentProptype = PropTypes.shape({
  name: PropTypes.string,
  total_acquisition_amount: decimalPropType,
  date: PropTypes.string,
  membership_units_or_percentage: PropTypes.string,
  quantity: decimalPropType,
  number_of_units_in_class: PropTypes.number,
  number_of_units_in_class_enabled: PropTypes.bool,
  percentage_owned: PropTypes.string,
  advanced_fields: PropTypes.shape({
    distribution_schedule_or_allocation_income: PropTypes.string,
    distribution_schedule_or_allocation_income_enabled: PropTypes.bool,
  }),
});

export const optionInvestmentProptype = PropTypes.shape({
  name: PropTypes.string,
  total_acquisition_amount: decimalPropType,
  date: PropTypes.string,
  quantity: decimalPropType,
  stock_class: PropTypes.string,
  option_exercise_price: PropTypes.string,
  option_exercise_date: PropTypes.string,
  option_expiration_date: PropTypes.string,
  advanced_fields: PropTypes.shape({
    participating_enabled: PropTypes.string,
    participating_enabled_enabled: PropTypes.bool,
  }),
});

export const warrantInvestmentProptype = PropTypes.shape({
  name: PropTypes.string,
  total_acquisition_amount: decimalPropType,
  date: PropTypes.string,
  quantity: decimalPropType,
  stock_class: PropTypes.string,
  warrant_exercise_price: PropTypes.string,
  warrant_expiration_date: PropTypes.string,
  option_exercise_date: PropTypes.string,
  advanced_fields: PropTypes.shape({
    participating_enabled: PropTypes.bool,
  }),
});

export const notePropType = PropTypes.shape({
  content: PropTypes.string,
  created_at: PropTypes.string,
  investment_id: PropTypes.string,
  creator_full_name: PropTypes.string,
});

export const investmentEventPropType = PropTypes.shape({
  id: PropTypes.number,
  event_type: PropTypes.string,
  params: PropTypes.shape({
    investment_id: PropTypes.string,
    valuation_date: PropTypes.string,
    investment_name: PropTypes.string,
    investment_type: PropTypes.string,
    valuation_deleted: PropTypes.string,
  }),
  created_at: PropTypes.string,
  owner: PropTypes.shape({
    id: PropTypes.number,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    full_name: PropTypes.string,
    initials: PropTypes.string,
    email: PropTypes.string,
    phone_last_digits: PropTypes.string,
    phone_number: PropTypes.string,
  }),
});

export const contactPropType = PropTypes.shape({
  id: PropTypes.number,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  email: PropTypes.string,
});

export const capitalCallPropType = PropTypes.shape({
  id: PropTypes.number,
  document: documentPropType,
  document_mime_type: PropTypes.string,
  document_name: PropTypes.string,
  document_url: PropTypes.string,
  investment: investmentPropType,
  date: PropTypes.string,
  amount: decimalPropType,
  note: PropTypes.string,
});

export const logAcquisitionPropType = PropTypes.shape({
  acquired_investment_id: PropTypes.string,
  cash_consideration: PropTypes.number,
  errors: errorsPropType,
  name: PropTypes.string,
  portfolio_id: PropTypes.string,
  shares_purchased: decimalPropType,
});

export const cashFlowCompanyPropType = PropTypes.shape({
  cash_flow_ids: PropTypes.arrayOf(PropTypes.number),
  cash_flow_investment_ids: PropTypes.arrayOf(PropTypes.string),
  cash_flow_net: PropTypes.string,
  cash_flow_total_in: decimalPropType,
  cash_flow_total_out: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.string,
  industry: PropTypes.string,
  location: PropTypes.string,
  name: PropTypes.string,
  status: PropTypes.string,
  updated_at: PropTypes.string,
  website: PropTypes.string,
});

export const cashFlowPropType = PropTypes.shape({
  cash_change_type: PropTypes.string,
  cash_out_type: PropTypes.string,
  company_id: PropTypes.string,
  date: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.number,
  investment_date: PropTypes.string,
  investment_id: PropTypes.string,
  total: PropTypes.string,
});

export const cashFlowInvestmentPropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

export const slackPortfolioIntegrationsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    portfolio_id: PropTypes.string,
    slack_channel_id: PropTypes.string,
    slack_channel_name: PropTypes.string,
  }),
);

export const portfolioHighlightsProptype = PropTypes.shape({
  biggest_winner_id: PropTypes.string,
  biggest_loser_id: PropTypes.string,
  largest_investment_company_id: PropTypes.string,
  biggest_winner_total: PropTypes.string,
  biggest_loser_total: PropTypes.string,
  largest_investment_company_total: PropTypes.string,
  average_investment_size: PropTypes.string,
  average_investments_per_month: PropTypes.string,
  follow_on_percentage: PropTypes.string,
});

export const portfolioCashFlowPerformancePropType = PropTypes.arrayOf(
  PropTypes.shape({
    period_start: PropTypes.string,
    period_type: PropTypes.string,
    cash_in: decimalPropType,
    cash_out: decimalPropType,
    cash_balance: decimalPropType,
  }),
);

export const portfolioInvestmentPacePropType = PropTypes.arrayOf(
  PropTypes.shape({
    date: PropTypes.string,
    first_investment_date: PropTypes.string,
    daily_investment_amount: decimalPropType,
    initial_investments_amount: decimalPropType,
    follow_on_investments_amount: decimalPropType,
  }),
);

export const reminderPropType = PropTypes.shape({
  expiration_date: PropTypes.string,
  read: PropTypes.bool,
  investment_id: PropTypes.string,
  id: PropTypes.number,
  message: PropTypes.string,
});

export const signUpRequestPropType = PropTypes.shape({
  name: PropTypes.string,
  email: PropTypes.string,
  investor_type: PropTypes.string,
  yearly_investments_count_range: PropTypes.string,
  portfolios_count_range: PropTypes.string,
});

export const dashboardPortfolioPropType = PropTypes.shape({
  id: PropTypes.number,
  portfolio_id: PropTypes.string,
  is_visible: PropTypes.boolean,
  portfolio_name: PropTypes.string,
});

export const portfoliosPropType = PropTypes.arrayOf(portfolioPropType);
export const investmentsPropType = PropTypes.arrayOf(investmentPropType);
export const documentsPropType = PropTypes.arrayOf(documentPropType);
export const cashFlowPerformancePropType = PropTypes.arrayOf(
  PropTypes.shape({
    period_start: PropTypes.string,
    period_type: PropTypes.string,
    cash_in: decimalPropType,
    cash_out: decimalPropType,
    cash_balance: decimalPropType,
  }),
);

export const investmentPacePropType = PropTypes.arrayOf(
  PropTypes.shape({
    date: PropTypes.string,
    first_investment_date: PropTypes.string,
    daily_investment_amount: decimalPropType,
    initial_investments_amount: decimalPropType,
    follow_on_investments_amount: decimalPropType,
  }),
);

export const dashboardPortfoliosPropType = PropTypes.arrayOf(
  dashboardPortfolioPropType,
);

export const investmentEventsPropType = PropTypes.arrayOf(
  investmentEventPropType,
);

export const companiesPropType = PropTypes.arrayOf(companyPropType);

export const companiesObjectPropType = PropTypes.objectOf(companyPropType);

export const investmentsObjectPropType = PropTypes.objectOf(investmentPropType);

export const portfoliosObjectOfArrayPropType = PropTypes.objectOf(
  portfoliosPropType,
);

// TODO: Rename inputPropPropType to one that doesn't feel as redundant.
export const inputPropPropType = PropTypes.shape({
  label: PropTypes.string,
  value: decimalPropType,
});

export const inputPropsPropType = PropTypes.arrayOf(inputPropPropType);

export const inputPropsObjectOfArrayPropType = PropTypes.objectOf(
  inputPropsPropType,
);

export const flashPropType = PropTypes.shape({
  id: PropTypes.string,
  message: PropTypes.string,
});

export const userContactPropType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
});

export const userContactsPropType = PropTypes.arrayOf(userContactPropType);

export const logInvestmentSalePropType = PropTypes.shape({
  buyer: userContactPropType,
  quantity: decimalPropType,
  price: decimalPropType,
  total: decimalPropType,
  percent_sold: PropTypes.string,
  per_quantity_or_total_option: PropTypes.oneOf(["total", "per_quantity"]),
  price_per_unit: decimalPropType,
  date: PropTypes.string,
  notes: PropTypes.string,
});

export const logInvestmentSalesPropType = PropTypes.arrayOf(
  logInvestmentSalePropType,
);

export const distributionPropType = PropTypes.shape({
  cash_amount: PropTypes.string,
  created_at: PropTypes.string,
  date: PropTypes.string,
  distribution_type: PropTypes.string,
  id: PropTypes.number,
  investement: investmentPropType,
  is_cash_proceed: PropTypes.bool,
  is_equity_proceed: PropTypes.bool,
  updated_at: PropTypes.string,
});

export const distributionsPropType = PropTypes.arrayOf(distributionPropType);

export const selectOptionPropType = PropTypes.shape({
  label: PropTypes.string,
  value: decimalPropType,
  isNewOption: PropTypes.bool,
});

export const loanConversionFormPropType = PropTypes.shape({
  date: PropTypes.string,
  conversion_amount: PropTypes.number,
  amount_for_equity: PropTypes.number,
  accrued_interest: decimalPropType,
  create_extra_valuation: PropTypes.bool,
  principal_amount: PropTypes.string,
  calculated_conversion_amount: decimalPropType,
  amount: decimalPropType,
  totalCostBasis: PropTypes.string,
});

export const uploadFilePropType = PropTypes.shape({
  fileObj: PropTypes.instanceOf(File),
  id: PropTypes.string,
  isCanceled: PropTypes.bool,
  isCompleted: PropTypes.bool,
  isDocumentTooBig: PropTypes.bool,
  isUploading: PropTypes.bool,
  progress: PropTypes.number,
});

export const uploadQueuePropType = PropTypes.arrayOf(uploadFilePropType);

export const filterOptionPropType = PropTypes.shape({
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
});

export const filterOptionsPropType = PropTypes.arrayOf(filterOptionPropType);

export const valuationProptype = PropTypes.shape({
  amount: decimalPropType,
  date: PropTypes.string,
  id: PropTypes.number,
  investment: investmentPropType,
  isVisible: PropTypes.bool,
  note: PropTypes.string,
});

export const acquisitionInfoPropType = PropTypes.shape({
  acquisition_date: PropTypes.string,
  company_name: PropTypes.string,
  company_param: PropTypes.string,
});

export const fundingRoundPropType = PropTypes.shape({
  cash_raised: decimalPropType,
  date: PropTypes.string,
  fully_diluted_shares: PropTypes.string,
  id: decimalPropType,
  investors: PropTypes.arrayOf(),
  round: PropTypes.string,
  valuation: PropTypes.string,
});

export const fundingRoundsPropType = PropTypes.arrayOf(fundingRoundPropType);

export const currentUserPropType = PropTypes.shape({
  created_at: PropTypes.string,
  email: PropTypes.string,
  full_name: PropTypes.string,
  has_portfolios: PropTypes.bool,
  id: decimalPropType,
  initials: PropTypes.string,
  integrations: PropTypes.arrayOf(),
  settings: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      settings: PropTypes.objectOf(PropTypes.bool),
    }),
  ),
  updated_at: PropTypes.string,
});

export const loadingStatePropType = PropTypes.shape({
  none: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
});

export const uploadedDocPropType = PropTypes.shape({
  file_name: PropTypes.string,
  id: PropTypes.string,
  mimetype: PropTypes.string,
  url: PropTypes.string,
});

export const investorPropType = PropTypes.shape({
  is_lead_investor: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.string,
});

export const investorsPropType = PropTypes.arrayOf(investorPropType);

export const companyInformationPropType = PropTypes.shape({
  acquisitions: PropTypes.arrayOf(),
  aliases: PropTypes.arrayOf(),
  contacts: PropTypes.arrayOf(),
  description: PropTypes.string,
  industries: PropTypes.arrayOf(),
  name: PropTypes.string,
  updated_at: PropTypes.string,
  verified: PropTypes.bool,
});

export const singleValuePropType = PropTypes.arrayOf(PropTypes.node);

export const keyValuePropType = PropTypes.shape({
  name: PropTypes.string,
  vallue: PropTypes.string,
});

export const keyValuePairsPropType = PropTypes.arrayOf(keyValuePropType);

export const companyActivityEventAuthorPropType = PropTypes.shape({
  name: PropTypes.string,
});

export const companyActivityEventPropType = PropTypes.shape({
  author: companyActivityEventAuthorPropType,
  body_params: PropTypes.objectOf(),
  date: PropTypes.string,
  edited: PropTypes.bool,
  id: PropTypes.number,
  title_params: PropTypes.objectOf(),
  type: PropTypes.string,
});

export const companyActivityEventsPropType = PropTypes.arrayOf(
  companyActivityEventPropType,
);

export const activityFeedDocumentPropType = PropTypes.shape({
  mime_type: PropTypes.string,
});

export const requestLoadingStatePropType = PropTypes.shape({
  none: PropTypes.boolean,
  loading: PropTypes.boolean,
  loaded: PropTypes.boolean,
});

export const requestLoadingStatesPropType = PropTypes.objectOf(
  requestLoadingStatePropType,
);

export const uploadedDocumentProptype = PropTypes.shape({
  filename: PropTypes.string,
  handle: PropTypes.string,
  localId: PropTypes.string,
  mimetype: PropTypes.string,
  size: PropTypes.number,
  status: PropTypes.string,
  url: PropTypes.string,
});

export const uploadedDocumentsProptype = PropTypes.arrayOf(
  uploadedDocumentProptype,
);

export const historyActionProptype = PropTypes.shape({
  action_params: PropTypes.shape({}),
  action_type: PropTypes.string,
  child_investment: PropTypes.shape({
    id: PropTypes.string,
    certificate_number: PropTypes.string,
    company_id: PropTypes.string,
    company_name: PropTypes.string,
    cost_basis: PropTypes.string,
    name: PropTypes.string,
    quantity: PropTypes.number,
    type: PropTypes.string,
  }),
  date: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.number,
  is_terminal_action: PropTypes.boolean,
  notes: PropTypes.string,
  parent_investment: PropTypes.shape({
    id: PropTypes.string,
    certificate_number: PropTypes.string,
    company_id: PropTypes.string,
    company_name: PropTypes.string,
    cost_basis: PropTypes.string,
    name: PropTypes.string,
    quantity: PropTypes.number,
    type: PropTypes.string,
  }),
});

export const historyActionsProptype = PropTypes.arrayOf(historyActionProptype);

export const organizationProptype = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  username: PropTypes.string,
  slack_channel_id: PropTypes.string,
  slack_channel_name: PropTypes.string,
  logo_handle: PropTypes.string,
  description: PropTypes.string,
  organization_type: PropTypes.string,
  website: PropTypes.string,
  location: PropTypes.string,
  owner_id: PropTypes.number,
  has_pipelines: PropTypes.bool,
  has_investments: PropTypes.bool,
  has_deals: PropTypes.bool,
  has_companies: PropTypes.bool,
});

export const organizationMembershipPropType = PropTypes.shape({
  id: PropTypes.number,
  user_id: PropTypes.number,
  full_name: PropTypes.string,
  initials: PropTypes.string,
  email: PropTypes.string,
  owner: PropTypes.bool,
  status: PropTypes.string,
});

export const pipelineProptype = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  stages_ids: PropTypes.arrayOf(PropTypes.string),
});

export const pipelinesProptype = PropTypes.arrayOf(pipelineProptype);

export const stageProptype = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  order: PropTypes.number,
});

export const defaultSelectDataPropType = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
});

export const dealProptype = PropTypes.shape({
  id: PropTypes.string.isRequired,
  pipeline_id: PropTypes.string.isRequired,
  company_id: PropTypes.string.isRequired,
  stage_id: PropTypes.number,
  staged_at: PropTypes.string,
  owner_id: PropTypes.number,
  owner_name: PropTypes.string,
  date: PropTypes.string,
  round_name: PropTypes.string,
  amount_raised_in_round: PropTypes.string,
  company_valuation: PropTypes.string,
  company_valuation_type: PropTypes.string,
  allocation: PropTypes.string,
  notes: PropTypes.string,
});

export const customFieldProptype = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  organization_id: PropTypes.string,
  helper_text: PropTypes.string,
  field_label: PropTypes.string,
  field_type: PropTypes.string,
  investment_types: PropTypes.arrayOf(PropTypes.string),
});

export const searchResultPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  logo_url: PropTypes.string,
  verified: PropTypes.bool,
});

export const tagProptype = PropTypes.shape({
  id: decimalPropType,
  name: PropTypes.string,
});

export const tagSuggestionsProptype = PropTypes.arrayOf(tagProptype);

export const navigationTabsItemsProptype = PropTypes.shape({
  url: PropTypes.string,
  children: PropTypes.node.isRequired,
  withoutOrganization: PropTypes.bool,
});

export const navigationTabsGroupHeaderProptype = PropTypes.shape({
  groupHeader: PropTypes.string,
});

export const postResourceProptype = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.string,
});

export const summaryStatsProptype = PropTypes.shape({
  number_of_companies: PropTypes.number,
  total_invested: PropTypes.string,
  total_valuation: PropTypes.string,
  total_cash_received: PropTypes.string,
  multiple: PropTypes.string,
});

export const dropdownOptionProptype = PropTypes.shape({
  key: PropTypes.string,
  name: PropTypes.string,
  vallue: PropTypes.string,
});

export const dropdownOptionsPropType = PropTypes.arrayOf(
  dropdownOptionProptype,
);

export const taskProptype = PropTypes.shape({
  attachments: PropTypes.arrayOf(PropTypes.shape({})),
  body: PropTypes.string,
  completed_at: PropTypes.string,
  created_at: PropTypes.string,
  creator: PropTypes.string,
  due_date: PropTypes.string,
  from: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  owner_id: PropTypes.number,
  owner_initials: PropTypes.string,
});

export const investmentsByCompanyPropType = PropTypes.shape({
  company_name: PropTypes.string.isRequired,
  last_date: PropTypes.string.isRequired,
  quantity: PropTypes.number,
  fully_diluted_shares_percentage: PropTypes.number,
  cost_basis: PropTypes.string,
  total_valuation_amount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  multiple: PropTypes.string,
});

export default {
  uploadedDocumentProptype,
  uploadedDocumentsProptype,
  requestLoadingStatePropType,
  activityFeedDocumentPropType,
  decimalPropType,
  companyPropType,
  documentPropType,
  errorsPropType,
  investmentPropType,
  investmentEventPropType,
  equityInvestmentProptype,
  debtInvestmentProptype,
  filterOptionPropType,
  filterOptionsPropType,
  fundInvestmentProptype,
  llcInvestmentProptype,
  loanConversionFormPropType,
  optionInvestmentProptype,
  warrantInvestmentProptype,
  notePropType,
  contactPropType,
  capitalCallPropType,
  cashFlowCompanyPropType,
  cashFlowPropType,
  cashFlowInvestmentPropType,
  portfolioCashFlowPerformancePropType,
  portfolioInvestmentPacePropType,
  cashFlowPerformancePropType,
  investmentPacePropType,
  reminderPropType,
  portfoliosPropType,
  investmentsPropType,
  documentsPropType,
  dashboardPortfolioPropType,
  dashboardPortfoliosPropType,
  investmentEventsPropType,
  companiesPropType,
  investmentsObjectPropType,
  portfoliosObjectOfArrayPropType,
  userContactPropType,
  userContactsPropType,
  logInvestmentSalePropType,
  logInvestmentSalesPropType,
  distributionsPropType,
  inputPropsPropType,
  inputPropsObjectOfArrayPropType,
  selectOptionPropType,
  uploadFilePropType,
  uploadQueuePropType,
  valuationProptype,
  acquisitionInfoPropType,
  fundingRoundPropType,
  fundingRoundsPropType,
  currentUserPropType,
  loadingStatePropType,
  uploadedDocPropType,
  investorPropType,
  investorsPropType,
  companyInformationPropType,
  singleValuePropType,
  keyValuePropType,
  keyValuePairsPropType,
  companyActivityEventAuthorPropType,
  companyActivityEventPropType,
  companyActivityEventsPropType,
  historyActionProptype,
  historyActionsProptype,
  organizationProptype,
  organizationMembershipPropType,
  pipelinesProptype,
  defaultSelectDataPropType,
  dealProptype,
  customFieldProptype,
  tagProptype,
  tagSuggestionsProptype,
  navigationTabsItemsProptype,
  navigationTabsGroupHeaderProptype,
  postResourceProptype,
  summaryStatsProptype,
  dropdownOptionProptype,
  dropdownOptionsPropType,
  investmentsByCompanyPropType,
  taskProptype,
};
