import { Image } from "@ableco/semantic-ui-react";
import PropTypes from "prop-types";
import React from "react";
import "./ResourceIcon.scss";

export default function ResourceIcon({ type, imageProps }) {
  if (type === "company") {
    return (
      <div className="ResourceIcon ResourceIcon-withImage">
        <Image {...imageProps} />
      </div>
    );
  } else {
    const intialName = type === "deal" ? "D" : "H";
    return (
      <div className="ResourceIcon">
        <span className="ResourceIcon-text">{intialName}</span>
        <div className="ResourceIcon-company">
          <Image {...imageProps} size="tiny" />
        </div>
      </div>
    );
  }
}

ResourceIcon.propTypes = {
  type: PropTypes.string,
};
