import React, { useState } from "react";
import Segment from "components_new/atoms/Segment";
import { DebouncedSearchInput } from "components_new/atoms/SearchInput";
import { SkeletonStatLine } from "components_new/elements/SummaryStats/SummaryStatsSkeleton";
import { isEmpty, isEqual, omitBy } from "lodash";
import TableFilter from "./TableFilter";
import PropTypes from "prop-types";
import ItemsCounter from "components_new/elements/ItemsCounter";
import { tagSuggestionsProptype } from "PropTypes";
import { dropdownOption } from "components_new/customPropTypes";
import { pluck } from "ramda";

const MAX_DEALS_PER_CALL = 250;

function PassedDealsTopBar({
  isLoading,
  fetchPassedDeals,
  dealsCount,
  filterTags,
  tagSuggestions,
  headquarters,
  owners,
  totalDeals,
}) {
  const [searchText, setSearchText] = useState(null);
  const [filters, setFilters] = useState({});

  const handleChangeText = (text) => {
    const filterParams = { ...filters };
    if (!isEmpty(text)) {
      filterParams.query = text;
    }
    setSearchText(text);
    fetchPassedDeals({ filters: filterParams });
  };

  const applyFilters = (tag_objects, headquarter, owner, dates) => {
    const tags = pluck("name", tag_objects);
    const newFilters = omitBy({ tags, headquarter, owner, ...dates }, isEmpty);

    if (!isEqual(filters, newFilters)) {
      const filterParams = { ...newFilters };
      if (!isEmpty(searchText)) {
        filterParams.query = searchText;
      }
      setFilters(newFilters);
      fetchPassedDeals({ filters: filterParams });
    }
  };

  return (
    <Segment attached="top" transparent clearing extraClassNames="DealsTopBar">
      <span className="DealsCount u-bodySmallBold">
        {isLoading ? (
          <SkeletonStatLine className="DealsCount-skeleton" />
        ) : (
          <ItemsCounter
            title="deal"
            totalItems={totalDeals}
            currentItems={dealsCount}
            maxItemsPerCall={MAX_DEALS_PER_CALL}
          />
        )}
      </span>
      <DebouncedSearchInput
        onChange={handleChangeText}
        className="TopBar-item"
        placeholder="Search deals or companies"
      />
      <TableFilter
        onApplyFilters={applyFilters}
        values={filters}
        className="TopBar-item"
        filterTags={filterTags}
        tagSuggestions={tagSuggestions}
        headquarters={headquarters}
        owners={owners}
      />
    </Segment>
  );
}

PassedDealsTopBar.propTypes = {
  isLoading: PropTypes.bool,
  fetchPassedDeals: PropTypes.func,
  dealsCount: PropTypes.number,
  totalDeals: PropTypes.number,
  filterTags: PropTypes.func,
  tagSuggestions: tagSuggestionsProptype,
  headquarters: PropTypes.arrayOf(dropdownOption),
  owners: PropTypes.arrayOf(dropdownOption),
};

PassedDealsTopBar.defaultProps = {
  isLoading: false,
};

export default PassedDealsTopBar;
