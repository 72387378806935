import React from "react";
import Icon from "components_new/atoms/Icon";
import classNames from "classnames";
import "./GainLossArrow.scss";

function GainLossArrow({ gain, multiple }) {
  const upWithMultiple = multiple && multiple > 1;
  const iconClassname = classNames("GainLossArrow", {
    "ri-arrow-up-s-fill gainIcon": gain >= 0.5 || upWithMultiple,
    "ri-arrow-down-s-fill lossIcon": gain <= -0.5,
  });
  return <Icon icon={iconClassname} />;
}

export default GainLossArrow;
