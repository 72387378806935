import React from "react";
import { times } from "lodash";

function GridItemSkeleton() {
  return (
    <div className="CompaniesGridItem">
      <div className="CompaniesGridItem-card">
        <div className="ui placeholder">
          <div className="image header">
            <div className="line"></div>
            <div className="line"></div>
          </div>
          <div className="paragraph">
            <div className="line"></div>
            <div className="line"></div>
            <div className="full line"></div>
            <div className="full line"></div>
            <div className="line"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

function CompaniesGridSkeleton() {
  return (
    <div className="CompaniesGrid">
      {times(8, String).map((item) => (
        <GridItemSkeleton key={item} />
      ))}
    </div>
  );
}

export default CompaniesGridSkeleton;
