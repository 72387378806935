import React, { Fragment } from "react";
import CustomFieldForm from "./CustomFieldForm";
import CustomFieldTable from "./CustomFieldTable";
import { defaultCustomFieldData } from "./CustomFieldForm/config";

function CustomFieldView({
  setAddCustomField,
  addCustomField,
  customFields,
  isSubmitting,
  onSubmit,
  onDelete,
  onEdit,
  openItem,
  setOpenItem,
  isOwner,
}) {
  return (
    <Fragment>
      {addCustomField && (
        <CustomFieldForm
          onCancel={() => setAddCustomField(false)}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          data={defaultCustomFieldData}
        />
      )}
      {customFields.length > 0 && (
        <CustomFieldTable
          data={customFields}
          isSubmitting={isSubmitting}
          onSubmit={onEdit}
          onDelete={onDelete}
          addCustomField={addCustomField}
          setAddCustomField={setAddCustomField}
          openItem={openItem}
          setOpenItem={setOpenItem}
          isOwner={isOwner}
        />
      )}
    </Fragment>
  );
}

export default CustomFieldView;
