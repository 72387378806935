import React from "react";
import SidebarPushable from "components_new/elements/Sidebar/SidebarPushable";
import SidebarPusher from "components_new/elements/Sidebar/SidebarPusher";
import SidebarComponents from "components_new/SidebarComponents";

function MainContentWithSidebarComponents({ children }) {
  return (
    <SidebarPushable>
      <div className="MainContentWithSidebarComponents">
        <SidebarComponents />
        <SidebarPusher>{children}</SidebarPusher>
      </div>
    </SidebarPushable>
  );
}

export default MainContentWithSidebarComponents;
