import Api from "lib/Api";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

export function useRemoveDeal() {
  const queryClient = useQueryClient();
  const { organizationUsername } = useParams();

  return useMutation(
    ({ id, pipeline_id }) => {
      return Api.delete(`/pipelines/${pipeline_id}/deals/${id}`);
    },
    {
      onSuccess: (_, deletedDeal) => {
        updateActiveDealsView(queryClient, deletedDeal);
        updateCompanyView(queryClient, organizationUsername, deletedDeal);

        const { pipeline_id: pipelineId } = deletedDeal;

        queryClient.invalidateQueries(["Pipeline"]);
        queryClient.invalidateQueries(["PassedDeals", pipelineId]);
        queryClient.invalidateQueries(["CommittedDeals", pipelineId]);
        queryClient.invalidateQueries(["SearchDeals", pipelineId]);
      },
    },
  );
}

function updateActiveDealsView(queryClient, deletedDeal) {
  const { id: dealId, pipeline_id: pipelineId } = deletedDeal;

  const key = ["ActiveDeals", pipelineId, {}];
  const activeDealsView = queryClient.getQueryData(key);

  if (activeDealsView?.deals) {
    queryClient.setQueryData(key, {
      ...activeDealsView,
      deals: activeDealsView.deals.filter((item) => item.id !== dealId),
    });
  }
}

function updateCompanyView(queryClient, organizationUsername, deletedDeal) {
  const key = ["Company", organizationUsername, deletedDeal.company_id];
  const companyView = queryClient.getQueryData(key);

  if (companyView?.companyDeals) {
    queryClient.setQueryData(key, {
      ...companyView,
      companyDeals: companyView.companyDeals.filter(
        (item) => item.id !== deletedDeal.id,
      ),
    });
  }
}
