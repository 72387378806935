import React from "react";
import PropTypes from "prop-types";
import Link from "components_new/atoms/Link";
import { getText } from "TermsMapper";
import TooltipHolder from "components/shared/TooltipHolder";
import CompanyIcon from "components/shared/CompanyIcon";
import formatNumber from "lib/formatters/formatNumber";
import formatPrice from "lib/formatters/formatPrice";

function HoldingCard({
  companyLogoUrl,
  companyId,
  companyName,
  investmentId,
  investmentName,
  investmentType,
  costBasis,
  quantity,
  totalAcquisitionAmount,
  organizationUsername,
}) {
  let holdingNumber = quantity && formatNumber(quantity);
  let holdingLabel = getText(investmentType, "valuation_unit_plural");

  if (investmentType === "debt" || investmentType === "llc") {
    holdingNumber = formatPrice(costBasis);
    holdingLabel = "Cost basis";
  }
  if (investmentType === "fund") {
    holdingNumber = formatPrice(totalAcquisitionAmount);
    holdingLabel = "Commitment amount";
  }

  const investmentValue = `${holdingNumber} ${holdingLabel}`;
  return (
    <div className="EventCard HoldingCard" data-testid="EventCard">
      <div className="EventCard-Icon">
        <TooltipHolder
          key={`company-${companyId}`}
          extraClassnames="CompanyThumb"
          tooltip={companyName}
          position="top"
        >
          <Link
            to={`/${organizationUsername}/companies/${companyId}/investments`}
          >
            <CompanyIcon src={companyLogoUrl} size="medium" />
          </Link>
        </TooltipHolder>
      </div>
      <div className="EventCard-Content">
        <Link
          extraClassnames="u-contentBold u-textWithEllipsis EventCard-ContentTitle"
          to={`/${organizationUsername}/investments/${investmentId}`}
          title={investmentName}
        >
          {investmentName}
        </Link>
        <div
          className="u-textWithEllipsis EventCard-ContentValue"
          title={investmentValue}
        >
          {investmentValue}
        </div>
      </div>
    </div>
  );
}

HoldingCard.propTypes = {
  companyId: PropTypes.string.isRequired,
  companyLogoUrl: PropTypes.string,
  companyName: PropTypes.string.isRequired,
  investmentId: PropTypes.string.isRequired,
  investmentName: PropTypes.string.isRequired,
  investmentType: PropTypes.string.isRequired,
  costBasis: PropTypes.number,
  quantity: PropTypes.number,
  totalAcquisitionAmount: PropTypes.number,
  organizationUsername: PropTypes.string.isRequired,
};

HoldingCard.defaultProps = {
  companyLogoUrl: null,
  costBasis: null,
  quantity: null,
};

export default HoldingCard;
