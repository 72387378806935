import React from "react";
import ReactVisibilitySensor from "react-visibility-sensor";
import PropTypes from "prop-types";

// VisibilitySensor
// implements https://github.com/joshwnj/react-visibility-sensor#readme
// containmentElement - the scrollable element\
// height/width - dimensions of the visible/invisible element
// onChange: returns boolean - visible or not visible in the scrollable view
function VisibilitySensor({ containmentElement, height, onChange, width }) {
  return (
    <ReactVisibilitySensor
      onChange={(v) => onChange(v)}
      containment={containmentElement}
    >
      <div style={{ height, width }} />
    </ReactVisibilitySensor>
  );
}

VisibilitySensor.propTypes = {
  containmentElement: PropTypes.instanceOf(Element),
  height: PropTypes.number,
  onChange: PropTypes.func,
  width: PropTypes.number,
};

VisibilitySensor.defaultProps = {
  height: 1,
  width: 1,
};

export default VisibilitySensor;
