import React, { useCallback, useMemo } from "react";
import PortfoliosView from "./PortfoliosView";
import useSidebar from "store/sidebar/hooks";

import { usePortfolios } from "components_new/views/Portfolios/hooks/queries/usePortfolios";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "store/modalV2";
import { resetInvestmentsViewRows } from "store/views";

function PortfoliosViewWrapper() {
  const { organizationUsername, id: portfolioId } = useParams();
  const { data, isLoading } = usePortfolios(organizationUsername);
  const { openSidebar } = useSidebar();
  const dispatch = useDispatch();
  const investmentView = useSelector((state) => state.views.investments);

  const allPortfoliosLogoInfo = {
    companies_count: data?.companiesCount || 0,
    investments_count: data?.investmentsCount || 0,
    logo_urls: data?.logoUrls || [],
  };

  const investmentCount = useMemo(() => {
    if (portfolioId && portfolioId !== "all") {
      return data?.portfoliosById[portfolioId]?.investments_count;
    }

    return data?.investmentsCount;
  }, [data, portfolioId]);

  const handleOpenEditPortfolioSidebar = useCallback(
    (portfolioId) =>
      dispatch(
        openSidebar("portfolioForm", "Edit portfolio", {
          portfolioId,
        }),
      ),
    [dispatch, openSidebar],
  );

  const handleValidatePortfolioDeletion = useCallback(
    () => dispatch(openModal("validatePortfolioDeletion")),
    [dispatch],
  );

  const handleConfirmPortfolioDeletion = useCallback(
    (portfolioId) =>
      dispatch(openModal("confirmPortfolioDeletion", { portfolioId })),
    [dispatch],
  );

  const handleResetInvestmentsViewRows = useCallback(
    () => dispatch(resetInvestmentsViewRows()),
    [dispatch],
  );

  return (
    <PortfoliosView
      isPortfolioStatsLoading={isLoading}
      portfolios={data?.portfolios}
      portfolioSummaryStats={data?.summaryStats}
      allPortfoliosLogoInfo={allPortfoliosLogoInfo}
      companiesCount={data?.companiesCount}
      investmentsCount={investmentCount}
      currentOrganizationUsername={organizationUsername}
      openEditPortfolioSidebar={handleOpenEditPortfolioSidebar}
      openValidatePortfolioDeletion={handleValidatePortfolioDeletion}
      openConfirmPortfolioDeletion={handleConfirmPortfolioDeletion}
      allRowsCollapsed={investmentView.allRowsCollapsed}
      allRowsExpanded={investmentView.allRowsExpanded}
      resetInvestmentsViewRows={handleResetInvestmentsViewRows}
    />
  );
}

export default PortfoliosViewWrapper;
