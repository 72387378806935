import { useMutation, useQueryClient } from "react-query";
import Api from "lib/Api";
import { useParams } from "react-router-dom";

export function useUpdateCompany() {
  const queryClient = useQueryClient();
  const { organizationUsername } = useParams();

  return useMutation(
    (formData) => {
      return Api.patch(`/v2/companies/${formData.id}`, {
        company: formData,
      });
    },
    {
      onSuccess: ({ data: updatedCompany }, { id }) => {
        const key = ["Company", organizationUsername, id];

        queryClient.setQueryData(key, (oldData) => ({
          ...oldData,
          companyDetails: updatedCompany,
        }));

        queryClient.invalidateQueries(key);
      },
    },
  );
}
