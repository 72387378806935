import React, { useState } from "react";
import HoldingMiniCard from "components/shared/HoldingMiniCard";
import EquityForm from "components_new/SidebarComponents/forms/InvestmentForm/InvestmentDetailsModule/EquityForm";
import DebtForm from "components_new/SidebarComponents/forms/InvestmentForm/InvestmentDetailsModule/DebtForm";
import WarrantForm from "components_new/SidebarComponents/forms/InvestmentForm/InvestmentDetailsModule/WarrantForm";
import LlcForm from "components_new/SidebarComponents/forms/InvestmentForm/InvestmentDetailsModule/LlcForm";
import OptionForm from "components_new/SidebarComponents/forms/InvestmentForm/InvestmentDetailsModule/OptionForm";
import FundForm from "components_new/SidebarComponents/forms/InvestmentForm/InvestmentDetailsModule/FundForm";
import RoundDetails from "components_new/SidebarComponents/forms/groups/RoundDetails";
import Documents from "components_new/SidebarComponents/forms/groups/Documents";
import CustomFields from "components_new/SidebarComponents/forms/groups/CustomFields";
import Contacts from "components_new/SidebarComponents/forms/groups/Contacts";
import Notes from "components_new/SidebarComponents/forms/groups/Notes";
import RequiredNote from "components_new/SidebarComponents/shared/RequiredNote";
import FormFooter from "components_new/SidebarComponents/SidebarForm/FormFooter";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import getMessage from "components_new/lib/Messages";
import useForm from "lib/hooks/useForm";
import "./EditInvestmentForm.scss";
import { ensureRequest } from "components_new/SidebarComponents/forms/InvestmentForm/config.js";
import Checkbox from "components_new/atoms/Checkbox";
import { FormField } from "components_new/atoms/Form";

function EditInvestmentForm({
  ownName,
  isAdmin,
  isOrgOwner,
  currentOrganizationUsername,
  investmentCustomFields,
  currentOrganizationName,
  searchContact,
  prefilledContacts,
  investment,
  company,
  submitInvestment,
  closeSidebar,
  isEditing,
  hideSidebarComponent,
  mountSidebarComponent,
}) {
  function selectSubFormComponent(typeInvesment) {
    switch (typeInvesment) {
      case "equity":
        return EquityForm;
      case "debt":
        return DebtForm;
      case "llc":
        return LlcForm;
      case "warrant":
        return WarrantForm;
      case "option":
        return OptionForm;
      case "fund":
        return FundForm;
      default:
        throw new Error(`No registered form for ${typeInvesment}.`);
    }
  }

  const SubForm = selectSubFormComponent(investment.type);
  const initialValues = { formData: { ...investment } };
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useSetToast();

  const onSubmit = (clientData) => {
    setIsLoading(true);
    submitInvestment(ensureRequest(clientData))
      .then(() => {
        closeSidebar();
        addToast(getMessage("updateInvestment"));
      })
      .catch((error) => {
        handleErrors(error);
        handleScrollView(error);
      })
      .finally(() => setIsLoading(false));
  };
  const {
    formData,
    handleChange,
    handleSubmit,
    handleErrors,
    handleScrollView,
    errors,
  } = useForm(initialValues, onSubmit, false);
  const { documents_data, notes, is_follow_on } = formData;

  return (
    <div className="EditInvestmentForm">
      <div className="HoldingGroup">
        <HoldingMiniCard
          investment={investment}
          company={company}
          className="FormElement"
        />
      </div>
      <FormField>
        <Checkbox
          extraClassName="EditInvestmentForm-IsFollowOnCheckbox"
          label="Follow-on"
          checked={is_follow_on}
          onChange={(_, { checked }) => handleChange("is_follow_on", checked)}
        />
      </FormField>
      <SubForm
        handleChange={handleChange}
        formData={formData}
        errors={errors}
        isEditing={isEditing}
      />
      {investment.type !== "fund" && (
        <RoundDetails
          formData={formData}
          handleChange={handleChange}
          withTopBorder
        />
      )}
      <Documents
        subtitle="Information relevant to closing activities, for tax purposes, etc"
        onChange={(documents) => {
          handleChange("documents_data", [...documents_data, ...documents]);
        }}
        documents={documents_data}
      />
      <CustomFields
        resource_type="investments"
        formData={formData}
        isAdmin={isAdmin}
        isOrgOwner={isOrgOwner}
        handleChange={handleChange}
        organizationCustomFields={investmentCustomFields}
        organizationName={currentOrganizationName}
        organizationUsername={currentOrganizationUsername}
      />
      <Contacts
        formData={formData}
        submitTo={ownName}
        handleChange={handleChange}
        searchContact={searchContact}
        prefilledContacts={prefilledContacts}
        mountSidebarComponent={mountSidebarComponent}
        hideSidebarComponent={hideSidebarComponent}
        isExpanded={!!prefilledContacts.length}
      />
      <Notes
        subtitle="Discretionary information that will be viewable by the entire organization."
        isEditing={true}
        notes={notes}
        isExpanded={!!notes}
        onChange={(notes) => {
          handleChange("notes", notes);
        }}
      />
      <RequiredNote />
      <FormFooter
        componentName={ownName}
        data={formData}
        onSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </div>
  );
}

export default EditInvestmentForm;
