import React, { useMemo } from "react";
import AdvancedField from "./AdvancedField";
import TooltipHolder from "components/shared/TooltipHolder";
import Pill from "components/shared/Pill";
import Link from "components_new/atoms/Link";
import { cloneDeep, isEmpty } from "lodash";
import OptionalLabel from "components/shared/OptionalLabel";
import "./AdvancedCustomFieldForm.scss";

export function createCustomFieldStructure(customField) {
  const customFieldStructure = cloneDeep(customField);
  const { id, field_value } = customFieldStructure;
  delete customFieldStructure.organization_id;
  delete customFieldStructure.resource_types;
  return {
    ...customFieldStructure,
    id: id.toString(),
    field_value: field_value || null,
    field_visible: true,
  };
}

export function initializeCustomFieldsFromResource(customFields, resource) {
  if (isEmpty(customFields)) return {};

  let customFieldForm = {};
  customFields.map((customField) => {
    const currentCustomField = createCustomFieldStructure(
      resource.custom_fields[+customField.id],
    );
    const value =
      currentCustomField.field_type === "multiple_choice"
        ? currentCustomField.field_data.filter((item) => item.value).length
        : currentCustomField.field_value || null;
    customFieldForm = {
      ...customFieldForm,
      [currentCustomField.id]: {
        ...currentCustomField,
        field_value: value,
      },
    };
  });
  return customFieldForm;
}

export function initializeCustomFields(
  customFields,
  resourceCustomFields = {},
) {
  if (customFields.length === 0) return {};
  let customFieldForm = {};
  customFields.map((customField) => {
    const customFieldStructure = createCustomFieldStructure(customField);
    const resourceCustomField = resourceCustomFields[customField.id];
    const field_value = resourceCustomField
      ? resourceCustomField.field_value
      : "";

    const fieldData = isEmpty(
      resourceCustomField && resourceCustomField.field_data,
    )
      ? customField.field_data
      : resourceCustomField.field_data;

    customFieldForm = {
      ...customFieldForm,
      [customFieldStructure.id]: {
        ...customFieldStructure,
        field_value: field_value,
        field_data: fieldData,
      },
    };
  });
  return customFieldForm;
}

export function updateCustomFields(key, value, customField, onChange) {
  const customFieldStructure = createCustomFieldStructure(customField);
  if (value !== undefined) {
    if (
      customFieldStructure.field_type === "multiple_choice" &&
      value !== null
    ) {
      customFieldStructure.field_data = value;
      customFieldStructure.field_value = null;
    } else {
      customFieldStructure.field_data = customField.field_data;
      customFieldStructure.field_value = value;
    }
    onChange(customFieldStructure);
  }
}

const placeholderAdvancedField = {
  text: "",
  price: "0.00",
  number: "0",
  textarea: null,
  multiple_choice: null,
};

const EDIT_CUSTOM_FIELDS_MSG =
  "Custom fields are editable by your organization's administrator" +
  " in the 'Custom Fields' tab of the organization settings page or by " +
  "clicking here on the tooltip.";
const emptyCustomFieldsMessage = (resource_type) =>
  `Your organization currently has no custom fields for ${resource_type}. Your organization` +
  "administrators can create custom fields under 'Organization Settings' or by clicking this tooltip";

function AdvancedCustomFieldForm({
  organizationName,
  fields,
  updateForm,
  formData,
  isOptional,
  resourceType,
  organizationUsername,
  fieldSize,
  showHeading,
}) {
  const toolTipMessage = useMemo(
    () =>
      isEmpty(fields)
        ? emptyCustomFieldsMessage(resourceType)
        : EDIT_CUSTOM_FIELDS_MSG,
    [resourceType], // eslint-disable-line react-hooks/exhaustive-deps
  );
  return (
    <div className="FormSection-group AdvancedCustomFieldForm">
      {showHeading && (
        <h3 className="u-contentBold FormSection-subTitle FormLabel OptionalLabel">
          {organizationName} Details {isOptional && <OptionalLabel />}
          <Link
            to={`/${organizationUsername}/settings/custom-fields`}
            isExternal
          >
            <TooltipHolder
              tooltip={toolTipMessage}
              position="top"
              showTooltip={true}
            >
              <Pill>?</Pill>
            </TooltipHolder>
          </Link>
        </h3>
      )}
      {fields.map((field, index) => {
        const { field_type, field_name, id, helper_text, field_label } = field;
        return (
          <AdvancedField
            key={index}
            editorType={field_type}
            name={field_name}
            value={formData[`${id}`].field_value}
            label={field_label}
            onChange={(key, value) => {
              updateForm(key, value, field);
            }}
            editorProps={{
              textHelper: helper_text,
              inputProps: formData[`${id}`].field_data,
              placeholder: placeholderAdvancedField[field_type],
              size: fieldSize,
            }}
          />
        );
      })}
    </div>
  );
}

AdvancedCustomFieldForm.defaultProps = {
  showHeading: true,
};

export default AdvancedCustomFieldForm;
