import PropTypes from "prop-types";
import React, { Component } from "react";
import classNames from "classnames";
import FilterLoader from "components/shared/FilterLoader";

class Button extends Component {
  componentDidMount() {
    const { autoFocus } = this.props;

    if (autoFocus) {
      setTimeout(() => this.button.focus(), 500);
    }
  }

  render() {
    const {
      children,
      customClass,
      size,
      filter,
      behavior,
      disabled,
      ...restProps
    } = this.props;
    const { applyingFilter } = filter;

    const btnClass = classNames("btn", "Button", {
      [`Button--${size}`]: !!size,
      [`Button--${behavior}`]: !!behavior,
      tertiary: !behavior,
      [customClass]: !!customClass,
    });

    return (
      <button
        {...restProps}
        type={this.props.type || "button"}
        disabled={disabled || applyingFilter}
        className={btnClass}
        ref={(button) => (this.button = button)}
      >
        <span className="Button-holder">
          {applyingFilter ? <FilterLoader /> : null}
          {children}
        </span>
      </button>
    );
  }
}

Button.propTypes = {
  autoFocus: PropTypes.bool,
  children: PropTypes.node,
  size: PropTypes.oneOf(["default", "small", "tiny"]),
  behavior: PropTypes.oneOf([
    "primary",
    "secondary",
    "warning",
    "action",
    "special",
  ]),
  customClass: PropTypes.string,
  type: PropTypes.string, // submit or reset
  filter: PropTypes.shape({
    applyingFilter: PropTypes.bool,
  }),
  disabled: PropTypes.bool,
};

// TODO: Use extraClassnames instead of customClass
Button.defaultProps = {
  autoFocus: false,
  behavior: "primary",
  size: "default",
  filter: {},
  type: "",
  customClass: "",
  children: null,
  disabled: null,
};

export default Button;
