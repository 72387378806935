import React from "react";
import EmptyPage from "components_new/elements/EmptyPage";
import PropTypes from "prop-types";
import {
  TableBody,
  TableHeader,
  TableRow,
  SortableTableHeaderCell,
  SortedTable,
  TableHeaderCell,
} from "components_new/elements/Table";
import ExitsRow from "./ExitsRow";
import GroupedExitsRow from "./GroupedExitsRow";
import Tooltip from "components_new/atoms/Tooltip/Tooltip";
import { companyAvatarPropType, investmentsByCompanyPropType } from "PropTypes";
import { isEmpty } from "lodash";
import { defaultOrderByCompanyName } from "components_new/views/Portfolios/config";
import getColumnHeaders from "../shared/getColumnHeaders";

export default function ExitsView({
  data = [],
  groupedByCompany,
  dataByCompany,
  onSelection,
  portfolio,
  orgUsername,
  isLoading,
  allRowsExpanded,
  allRowsCollapsed,
  resetInvestmentsViewRows,
  showClosingDocuments,
  isEmptyView,
}) {
  if (isEmptyView) {
    const exitHeadlineText =
      portfolio && portfolio.name
        ? "You don't have any exits in " + portfolio.name
        : "You don't have any exits";
    const exitSubheadlineText =
      "Log a liquidity event or write-off to track realized portfolio performance.";
    return (
      <EmptyPage
        headline={exitHeadlineText}
        subHeadline={exitSubheadlineText}
      />
    );
  }

  const currentData = groupedByCompany ? dataByCompany : data;
  const RowComponent = groupedByCompany ? GroupedExitsRow : ExitsRow;
  const columnHeaders = getColumnHeaders("exitsTable");

  const renderHeaders = (handleSort, isSorted) => {
    return (
      <TableHeader>
        <TableRow>
          {groupedByCompany && <TableHeaderCell className="EmptyCell" />}
          {columnHeaders.map((columnHeader, index) => {
            return (
              <SortableTableHeaderCell
                key={index}
                isSorted={isSorted}
                handleSort={handleSort}
                isGrouped={groupedByCompany}
                {...columnHeader}
              >
                <Tooltip
                  position={columnHeader.tooltipPosition}
                  content={columnHeader.tooltipContent}
                  disabled={!columnHeader.hasTooltip}
                  trigger={<div>{columnHeader.title}</div>}
                />
              </SortableTableHeaderCell>
            );
          })}
        </TableRow>
      </TableHeader>
    );
  };
  return (
    <SortedTable
      tableData={currentData}
      onSelection={onSelection}
      className="HoldingsTable attached"
      defaultOrder={defaultOrderByCompanyName}
      isLoading={isLoading}
      renderHeaders={renderHeaders}
      skeletonStructure={{ rows: 8, headers: columnHeaders }}
      expandable={groupedByCompany}
      shadow={false}
    >
      {({ data }) => (
        <TableBody isEmptyData={isEmpty(currentData)}>
          {!isEmpty(data) &&
            data.map((investment, index) => {
              return (
                <RowComponent
                  key={index}
                  investment={investment}
                  orgUsername={orgUsername}
                  allRowsExpanded={allRowsExpanded}
                  allRowsCollapsed={allRowsCollapsed}
                  resetInvestmentsViewRows={resetInvestmentsViewRows}
                  showClosingDocuments={showClosingDocuments}
                />
              );
            })}
        </TableBody>
      )}
    </SortedTable>
  );
}

ExitsView.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      company_name: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      quantity: PropTypes.number,
      fully_diluted_shares_percentage: PropTypes.number,
      cost_basis: PropTypes.string,
      total_valuation_amount: PropTypes.string,
      multiple: PropTypes.string,
    }),
  ),
  dataByCompany: PropTypes.arrayOf(investmentsByCompanyPropType),
  portfolio: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
  onSelection: PropTypes.func,
  companies: PropTypes.shape({ companyAvatarPropType }),
  orgUsername: PropTypes.string,
  isLoading: PropTypes.bool,
  showClosingDocuments: PropTypes.bool,
  groupedByCompany: PropTypes.bool,
  allRowsExpanded: PropTypes.bool,
  allRowsCollapsed: PropTypes.bool,
  resetInvestmentsViewRows: PropTypes.func,
  hasSearch: PropTypes.bool,
  isEmptyView: PropTypes.bool,
};
