import React from "react";
import classNames from "classnames";
import { Placeholder as SemanticSkeleton } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/placeholder.css";
import "./Skeleton.scss";

function SkeletonParagraph({ rightMarginedLine, children, ...props }) {
  const skeletonParagraphClassnames = classNames("SkeletonParagraph", {
    rightMarginedLine: !!rightMarginedLine,
  });
  return (
    <SemanticSkeleton.Paragraph
      className={skeletonParagraphClassnames}
      {...props}
    >
      {children}
    </SemanticSkeleton.Paragraph>
  );
}

export default SkeletonParagraph;
