import React from "react";
import PropTypes from "prop-types";
import { dealProptype, companyPropType } from "PropTypes";
import configureDealData from "./configureDealData";
import CompanyIcon from "components/shared/CompanyIcon";
import VerifiedBadge from "components/shared/VerifiedBadge";
import EmptyLabel from "components/shared/EmptyLabel";
import Dropdown from "components/shared/Dropdown";
import DotsIcon from "-!svg-react-loader?!assets/icons/utility/dots.svg?name=DotsIcon";
import classNames from "classnames";
import SecondaryButton from "components/shared/SecondaryButton";
import Link from "components_new/atoms/Link";

function DealCard({
  deal,
  company,
  openUpdateDealModal,
  openEditCommitmentModal,
  openCommitToDealModal,
  openPassFromDealModal,
  openChangeDealOwnerModal,
  isCurrentOrganizationPrivate,
  openDeleteDealModal,
  openReactivateDealModal,
  extraClassnames,
  organizationUsername,
}) {
  return (
    <div className={classNames("DealCard Container", extraClassnames)}>
      {company &&
        renderDealCardHeader(
          company,
          deal,
          openUpdateDealModal,
          openEditCommitmentModal,
          openCommitToDealModal,
          openPassFromDealModal,
          openReactivateDealModal,
          isCurrentOrganizationPrivate,
          openChangeDealOwnerModal,
          openDeleteDealModal,
          organizationUsername,
        )}
      {renderDealCardBody(deal, organizationUsername)}
    </div>
  );
}

function renderDealCardHeader(
  company,
  deal,
  openUpdateDealModal,
  openEditCommitmentModal,
  openCommitToDealModal,
  openPassFromDealModal,
  openReactivateDealModal,
  isCurrentOrganizationPrivate,
  openChangeDealOwnerModal,
  openDeleteDealModal,
  organizationUsername,
) {
  const activeActions = [
    {
      text: "Commit to Deal",
      onClickAction: () => openCommitToDealModal(deal),
    },
    { text: "Pass on Deal", onClickAction: () => openPassFromDealModal(deal) },
    { type: "separator" },
    { text: "Edit Deal", onClickAction: () => openUpdateDealModal(deal.id) },
    {
      text: "Change Owner",
      isDisabled: isCurrentOrganizationPrivate,
      onClickAction: () => openChangeDealOwnerModal(deal),
    },
    {
      text: "Delete Deal",
      onClickAction: () => openDeleteDealModal(deal, deal.pipeline_id),
    },
  ];
  const committedActions = [
    {
      text: "Edit Commitment",
      onClickAction: () => openEditCommitmentModal(deal),
    },
    {
      text: "Undo Commitment",
      onClickAction: () => openReactivateDealModal(deal, deal.pipeline_id),
    },
  ];
  const actionsByStatus = {
    active: activeActions,
    committed: committedActions,
    passed: [],
    invested: [],
  };
  const itemActions = actionsByStatus[deal.status];

  return (
    <div className="DealCard-header">
      <Link to={`/${organizationUsername}/deals/active/${deal.id}`}>
        <div className="DealCard-headerContents">
          <div className="DealCard-logo">
            <CompanyIcon size="medium" src={company.logo_url} />
          </div>
          <div className="DealCard-companyName u-headingSmall u-textWithEllipsis">
            {company.name}
          </div>
          {company.verified && <VerifiedBadge />}
        </div>
      </Link>
      <div className="DealCard-actions">
        {itemActions.length > 0 && (
          <Dropdown extraClassnames="Dropdown--dots" items={itemActions}>
            <DotsIcon className="Button-icon" />
          </Dropdown>
        )}
      </div>
    </div>
  );
}

function renderDealCardBody(deal, organizationUsername) {
  const dealRowsData = configureDealData(deal);

  return (
    <div className="DealCard-body">
      <Link to={`/${organizationUsername}/deals/active/${deal.id}`}>
        {dealRowsData.map((row, i) => {
          return (
            <DealCardBodyRow
              key={i}
              label={row.label}
              value={row.value}
              type={row.type}
              action={row.action}
            />
          );
        })}
      </Link>
    </div>
  );
}

function DealCardBodyRow({ type, label, value, action }) {
  const isButton = type === "button";
  return (
    <div className="DealCard-bodyRow">
      {isButton ? (
        <SecondaryButton
          customClass="DealCard-bodyButton"
          size="small"
          onClick={action}
        >
          {label}
        </SecondaryButton>
      ) : (
        <React.Fragment>
          <div className="DealCard-bodyLabel">{label}</div>
          <div className="DealCard-bodyValue u-textWithEllipsis">
            {value === "EMPTY" ? <EmptyLabel /> : value}
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

DealCard.propTypes = {
  deal: dealProptype.isRequired,
  company: companyPropType.isRequired,
  openUpdateDealModal: PropTypes.func,
  openEditCommitmentModal: PropTypes.func,
  openCommitToDealModal: PropTypes.func,
  openPassFromDealModal: PropTypes.func,
  isCurrentOrganizationPrivate: PropTypes.bool,
  openDeleteDealModal: PropTypes.func,
  openChangeDealOwnerModal: PropTypes.func,
  extraClassnames: PropTypes.string,
  openReactivateDealModal: PropTypes.func,
  organizationUsername: PropTypes.string,
};

export default DealCard;
