import React from "react";
import PurchasedEquity from "./PurchasedEquity";
import PurchasedDebt from "./PurchasedDebt";
import PurchasedLlc from "./PurchasedLlc";
import PurchasedOption from "./PurchasedOption";
import PurchasedWarrant from "./PurchasedWarrant";
import PurchasedFund from "./PurchasedFund";

const CONTENT_FORM_COMPONENTS = {
  equity: PurchasedEquity,
  debt: PurchasedDebt,
  llc: PurchasedLlc,
  option: PurchasedOption,
  fund: PurchasedFund,
  warrant: PurchasedWarrant,
};

function showFormByHoldingType(
  historyAction,
  companies,
  updateForm,
  errors,
  formData,
) {
  const {
    is_terminal_action: isTerminalAction,
    parent_investment: parentInvestment,
    child_investment: childInvestment,
  } = historyAction;
  const holding = isTerminalAction ? parentInvestment : childInvestment;
  return React.createElement(CONTENT_FORM_COMPONENTS[holding.type], {
    historyAction,
    companies,
    updateForm,
    formData,
    errors,
  });
}

function PurchasedForm({
  historyAction,
  companies,
  updateForm,
  errors,
  formData,
}) {
  return (
    <div className="PurchasedForm">
      {showFormByHoldingType(
        historyAction,
        companies,
        updateForm,
        errors,
        formData,
      )}
    </div>
  );
}

export default PurchasedForm;
