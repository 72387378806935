import { loadTerms } from "TermsMapper";
import Api from "lib/Api";

export default function loadTermsMapper() {
  return Api.get("/terms_mapping", { withoutOrganization: true }).then(
    ({ data: terms }) => {
      loadTerms(terms);
    },
  );
}
