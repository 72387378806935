import React, { useState } from "react";
import useForm from "lib/hooks/useForm";
import ContactDetails from "components_new/SidebarComponents/forms/groups/ContactDetails";
import Documents from "components_new/SidebarComponents/forms/groups/Documents";
import ContactRoles from "components_new/SidebarComponents/forms/groups/ContactRoles";
import CustomFields from "components_new/SidebarComponents/forms/groups/CustomFields";
import FormFooter from "components_new/SidebarComponents/SidebarForm/FormFooter";
import getMessage from "components_new/lib/Messages";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import "./ContactForm.scss";
import { isEmpty } from "lodash";
import { sanitizeContactData } from "./config";

const INITIAL_VALUES = {
  formData: {
    first_name: "",
    last_name: "",
    emails: [],
    location: "",
    tags: [],
    tier: null,
    documents_data: [],
    industries: [],
    roles_attributes: [],
    type: "contact",
  },
};

function ContactForm({
  ownName,
  isAdmin,
  isOrgOwner,
  createContact,
  closeSidebar,
  organizationCustomFields,
  currentOrganizationName,
  currentOrganizationUsername,
  prepopulatedContactTags,
  prepopulatedSectors,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useSetToast();

  const onSubmit = (contactData) => {
    setIsLoading(true);

    const formData = sanitizeContactData(contactData);
    createContact(formData)
      .then(({ data }) => {
        closeSidebar();
        const createdContactId = data.contacts.id;
        addToast(
          getMessage("addedContact", {
            url: `/${currentOrganizationUsername}/contacts?contactId=${createdContactId}`,
          }),
        );
      })
      .catch((error) => {
        handleErrors(error);
      })
      .finally(() => setIsLoading(false));
  };

  const {
    formData,
    handleChange,
    handleSubmit,
    handleErrors,
    errors,
  } = useForm(INITIAL_VALUES, onSubmit);

  const { first_name, last_name } = formData;
  const isSubmitButtonDisabled = isEmpty(first_name) || isEmpty(last_name);

  return (
    <div className="ContactForm">
      <ContactDetails
        formData={formData}
        onChange={handleChange}
        errors={errors}
        prepopulatedContactTags={prepopulatedContactTags}
        prepopulatedSectors={prepopulatedSectors}
      />
      <ContactRoles
        onChange={handleChange}
        errors={errors}
        submitTo={ownName}
      />
      <CustomFields
        resource_type="contact"
        formData={formData}
        isAdmin={isAdmin}
        isOrgOwner={isOrgOwner}
        handleChange={handleChange}
        organizationCustomFields={organizationCustomFields}
        organizationName={currentOrganizationName}
        organizationUsername={currentOrganizationUsername}
      />
      <Documents
        subtitle="Information relevant to closing activities, for tax purposes, etc"
        onChange={(documents) => handleChange("documents_data", documents)}
        documents={formData.documents_data}
      />
      <FormFooter
        componentName={ownName}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        disableSubmit={isSubmitButtonDisabled}
        submitTextButton="Save Contact"
      />
    </div>
  );
}

export default ContactForm;
