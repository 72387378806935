import React, { useCallback, useEffect, useState } from "react";
import Checkbox from "components_new/atoms/Checkbox";
import { capitalize } from "lodash";
import DropdownForm from "components_new/atoms/DropdownForm";
import InputTag from "components_new/elements/InputTag";
import TableFilter from "components_new/elements/TableFilter";
import "./TableFilter.scss";

const INITIAL_STATUS_FILTERS = {
  active: false,
  acquired: false,
  ipo: false,
  failed: false,
};

function CompanyFilter({
  onApplyFilters,
  className,
  filterTags,
  tagSuggestions,
  headquarters,
  values,
  coInvestors,
}) {
  const initialValues = {
    tags: [],
    headquarter: null,
    co_investor: null,
    status: INITIAL_STATUS_FILTERS,
  };

  const [headquartersOptions, setHeadquartersOptions] = useState([]);

  useEffect(() => {
    setHeadquartersOptions(headquarters);
  }, [headquarters]);

  const handleStatusEvent = useCallback((event, filters, updateFilters) => {
    const status = { ...filters.status, [event.value]: event.checked };
    const count = Object.values(status).filter((isSelected) => isSelected)
      .length;
    updateFilters("status", status, count);
  }, []);

  return (
    <TableFilter
      className={className}
      initialFilterValues={initialValues}
      onApplyFilters={onApplyFilters}
      values={values}
    >
      {(filters, updateFilters, reInit, setReInit) => (
        <div className="CompanyFilter">
          <DropdownForm
            search
            fluid
            label="Headquarters"
            name="headquarter"
            placeholder="Type to filter or select a location"
            value={filters.headquarter}
            items={headquartersOptions}
            onChange={(_, { value }) => updateFilters("headquarter", value, 1)}
            extraClassnames="FormElement"
            size="tiny"
          />
          <DropdownForm
            search
            fluid
            label="Co-Investor"
            name="co-investor"
            placeholder="Type to filter or select a Co-Investor"
            value={filters.co_investor}
            items={coInvestors}
            onChange={(_, { value }) => updateFilters("co_investor", value, 1)}
            extraClassnames="FormElement"
            size="tiny"
          />
          <InputTag
            label="Tags"
            placeholder="Type to filter or select a tag"
            extraClassnames="CompanyTag-Input"
            size="tiny"
            onFilter={filterTags}
            suggestedList={tagSuggestions}
            onChange={(values) => updateFilters("tags", values, values.length)}
            taggedList={filters.tags}
            reInit={reInit}
            setReInit={setReInit}
          />
          <div className="FormElement CompanyStatus-Selector">
            <p className="u-captionBlack">STATUS</p>
            {filters.status &&
              Object.keys(filters.status).map((type, index) => {
                return (
                  <Checkbox
                    key={index}
                    className="CompanyStatus-Item"
                    checked={filters.status[type]}
                    value={type}
                    onChange={(event, value) =>
                      handleStatusEvent(value, filters, updateFilters)
                    }
                    label={type === "ipo" ? "IPO" : capitalize(type)}
                  />
                );
              })}
          </div>
        </div>
      )}
    </TableFilter>
  );
}

export default CompanyFilter;
