import { connect } from "react-redux";
import DocumentsTable from "./DocumentsTable";
import { isSubmitting } from "store/domain/selectors";

function mapStateToProps(state) {
  const isUpdating = isSubmitting(state, "createDocuments");
  const {
    organizations: { currentOrganizationUsername },
  } = state;
  return { isUpdating, currentOrganizationUsername };
}

export default connect(mapStateToProps)(DocumentsTable);
