import { SET_CURRENT_ORGANIZATION } from "./actionTypes";

const initialState = {
  currentOrganizationId: null,
  organizationViewIsShown: false,
};

function organizations(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_ORGANIZATION:
      return {
        ...state,
        currentOrganizationId: action.value.id,
        currentOrganizationUsername: action.value.username,
        currentOrganizationName: action.value.name,
      };
    default:
      return state;
  }
}

export default organizations;
