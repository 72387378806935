import React from "react";
import CurrentRowCells from "./CurrentRowCells";
import PropTypes from "prop-types";
import { investmentDataPropType, companyAvatarPropType } from "PropTypes";
import { TableRow } from "components_new/elements/Table";

function CurrentRow({
  investment,
  company,
  orgUsername,
  isChild,
  className,
  isParent,
  showClosingDocuments,
}) {
  return (
    <TableRow key={investment.id} className={className}>
      <CurrentRowCells
        investment={investment}
        company={company}
        orgUsername={orgUsername}
        isChild={isChild}
        isParent={isParent}
        showClosingDocuments={showClosingDocuments}
      />
    </TableRow>
  );
}

CurrentRow.propTypes = {
  orgUsername: PropTypes.string,
  showClosingDocuments: PropTypes.bool,
  isChild: PropTypes.bool,
  className: PropTypes.string,
  investment: investmentDataPropType,
  company: companyAvatarPropType,
  isParent: PropTypes.bool,
};

export default CurrentRow;
