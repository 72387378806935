import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

function TooltipHolder({
  tooltip,
  extraClassnames,
  children,
  position,
  showTooltip,
}) {
  const wrapperClassnames = classnames("TooltipHolder-wrapper", {
    [extraClassnames]: !!extraClassnames,
    "TooltipHolder--bottom": position === "bottom",
    "TooltipHolder--top": position === "top",
    "is-hidden": !showTooltip,
  });

  return (
    <div className={wrapperClassnames}>
      <div className="TooltipHolder-holder">{children}</div>
      <span className="TooltipHolder-tooltip">
        <span className="TooltipHolder-content">{tooltip}</span>
      </span>
    </div>
  );
}

TooltipHolder.propTypes = {
  tooltip: PropTypes.node,
  children: PropTypes.node.isRequired,
  extraClassnames: PropTypes.string,
  position: PropTypes.oneOf(["bottom", "top"]),
  showTooltip: PropTypes.bool,
};

TooltipHolder.defaultProps = {
  extraClassnames: null,
  position: "bottom",
  showTooltip: true,
};

export default TooltipHolder;
