import React, { useState, useEffect } from "react";
import Header from "components_new/atoms/Header";
import useKeyPress from "lib/hooks/useKeyPress";
import "./ContactList.scss";

function ContactList({ suggestions, onChange }) {
  const [selected, setSelected] = useState(undefined);
  const downPress = useKeyPress("ArrowDown");
  const upPress = useKeyPress("ArrowUp");
  const enterPress = useKeyPress("Enter");
  const [cursor, setCursor] = useState(0);
  const [hovered, setHovered] = useState(undefined);

  useEffect(() => {
    if (selected) {
      onChange(selected.id, selected.title);
    }
  }, [selected, onChange]);

  useEffect(() => {
    if (suggestions.length > 0 && downPress) {
      setCursor((prevState) =>
        prevState < suggestions.length - 1 ? prevState + 1 : prevState,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downPress]);

  useEffect(() => {
    if (suggestions.length > 0 && upPress) {
      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upPress]);

  useEffect(() => {
    if (suggestions.length > 0 && enterPress) {
      setSelected(suggestions[cursor]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursor, enterPress]);

  useEffect(() => {
    if (suggestions.length > 0 && hovered) {
      setCursor(suggestions.indexOf(hovered));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hovered]);

  return (
    <ul className="SearchSelect-list ContactList">
      <Header as="h4" className="u-caption">
        Contacts
      </Header>
      {suggestions.map((suggestion, i) => (
        <li
          key={suggestion.id}
          className={`item ${i === cursor ? "active" : ""}`}
          onClick={() => setSelected(suggestion)}
          onMouseEnter={() => setHovered(suggestion)}
          onMouseLeave={() => setHovered(undefined)}
        >
          {suggestion.title}
        </li>
      ))}
    </ul>
  );
}

export default ContactList;
