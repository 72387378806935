export const initialPointDifferenceAmount = 1;
export const initialPointDifferenceTypePeriod = "week";

export const globalChartConfig = {
  chart: {
    type: "line",
    marginTop: 99,
    zoomType: "x",
  },
  credits: {
    enabled: false,
  },
  lang: {
    noData: "No data available",
  },
  legend: {
    align: "top",
    verticalAlign: "top",
    layout: "horizontal",
    y: 15,
    x: 0,
    itemStyle: {
      color: "#656976",
      fontSize: "12px",
      fontFamily: "Lato-Bold",
      letterSpacing: "0.03em",
      textTransform: "uppercase",
      cursor: "default",
    },
    itemHoverStyle: {
      color: "#656976",
    },
    lineHeight: 20,
    symbolPadding: 8,
    symbolRadius: 2, // Increase this number to get more circle shape.
    symbolHeight: 15,
    reversed: true,
  },
  noData: {
    style: {
      fontFamily: "Lato-Regular",
      fontSize: "14px",
      fontWeight: "normal",
      lineHeight: "16px",
      color: "#656976",
    },
  },
  title: {
    text: null,
  },
};

const labelYAxisStyle = {
  color: "#656976",
  fontSize: "12px",
  fontFamily: "Lato-Bold",
  letterSpacing: "0.03em",
  lineHeight: "20px",
};

export const labelsYAxis = {
  useHTML: true,
  autoRotationLimit: 150,
  style: {
    ...labelYAxisStyle,
    paddingRight: "6px",
  },
};

export const labelsXAxis = {
  useHTML: true,
  style: {
    ...labelYAxisStyle,
    paddingTop: "6px",
  },
};

export const lineAxisStyle = {
  lineColor: "rgba(128,132,148,0.25)",
  lineWidth: 1,
  tickWidth: 0,
};

export const plotOptionsChart = {
  column: {
    // To stack cash in/cash out chart
    stacking: "normal",
    pointPadding: 0,
    groupPadding: 0,
    pointPlacement: "on", // To avoid any extra padding for first and last tick in x-axis
    maxPointWidth: 90,
    events: {
      legendItemClick() {
        return false;
      },
    },
  },
  spline: {
    marker: {
      lineWidth: 1,
      radius: 3,
    },
    pointPlacement: "on", // To avoid any extra padding for first and last tick in x-axis
    events: {
      legendItemClick() {
        return false;
      },
    },
  },
};

// NOTE:
// Can't set specific padding side by side,
// so remaining padding should be added in scss
export const tooltipChart = {
  backgroundColor: "#fffff",
  borderWidth: 0,
  borderRadius: 0,
  padding: 0,
  shadow: false,
  useHTML: true,
};

export const xAxisChart = {
  ...lineAxisStyle,
  dateTimeLabelFormats: {
    month: "%m/%y",
    week: "%m/%d/%y",
    day: "%m/%d/%y",
    millisecond: "%m/%d/%y",
    second: "%m/%d/%y",
    hour: "%m/%d/%y",
    year: "%m/%y",
  },
  gridLineWidth: 1,
  labels: labelsXAxis,
  type: "datetime",
  tickPixelInterval: 300,
  maxPadding: 0,
  minPadding: 0,
};

// NOTE: Disabling since format label doesn't need an explicit variable.
export const yAxisChart = {
  ...lineAxisStyle,
  softMax: 1, // To force to show always a positive label in the axis
  title: {
    enabled: false,
  },
  plotLines: [
    {
      color: "rgba(183, 186, 195, 0.8)",
      width: 1,
      value: 0,
      zIndex: 3, // To put the line over the plot lines.
    },
  ],
  useHTML: true,
};

// Series cashout config
export const cashOutSeries = {
  borderWidth: 0,
  color: "#FCBA1D",
  type: "column",
  name: "Cash out",
};

// Series cash in config
export const cashInSeries = {
  borderWidth: 0,
  color: "#98C069",
  type: "column",
  name: "Cash in",
};

// Series config
export const cashBalanceSeries = {
  borderWidth: 0,
  color: "#808494",
  type: "spline",
  name: "Cumulative Cash flow",
  marker: {
    enabled: false,
  },
  stickyTracking: false,
};

export default {
  initialPointDifferenceAmount,
  initialPointDifferenceTypePeriod,
  cashOutSeries,
  cashInSeries,
  cashBalanceSeries,
  globalChartConfig,
  labelsYAxis,
  plotOptionsChart,
  tooltipChart,
  yAxisChart,
  xAxisChart,
};
