import Api from "lib/Api";
import { requestStart, requestSuccess, requestError } from "store/requests";
import consolidateInvestmentName from "lib/consolidateInvestmentName";
import { receiveView } from "store/views";
import { receiveCollections } from "store/entities";

export function validateLoanConversion(investment, formData) {
  const investmentId = investment;
  return (dispatch, getState) => {
    const payload = consolidateInvestmentName(formData, false, "series_name");
    const { currentOrganizationUsername } = getState().organizations;
    dispatch(requestStart("validateLoanConversion"));

    return Api.post(
      `/v2/investments/${investmentId}/actions/validate_loan_conversion`,
      {
        loan_conversion: payload,
      },
    ).then(
      () => {
        dispatch(requestSuccess("validateLoanConversion"));
        return {
          investment,
          formData,
          currentOrganizationUsername,
        };
      },
      (errors) => {
        dispatch(requestError("validateLoanConversion"));
        throw errors;
      },
    );
  };
}

export function createLoanConversion(investmentId, formData) {
  return (dispatch) => {
    dispatch(requestStart("createLoanConversion"));
    const payload = {
      ...consolidateInvestmentName(formData, false, "series_name"),
    };
    return Api.post(
      `/v2/investments/${investmentId}/actions/loan_conversions`,
      {
        loan_conversion: payload,
      },
    ).then(
      ({
        data: {
          entities,
          views: { investment: view },
        },
      }) => {
        dispatch(receiveCollections(entities));
        dispatch(receiveView("investment", view));
        const [investment] = entities.investment_details;
        dispatch(requestSuccess("createLoanConversion"));
        return investment;
      },
      (errors) => {
        dispatch(requestError("createLoanConversion"));
        throw errors;
      },
    );
  };
}
