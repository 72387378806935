import React, { useCallback } from "react";
import useOrganizations from "store/organizations/hooks";
import CompanyInvestmentTab from "./CompanyInvestmentTab";
import useSidebar from "store/sidebar/hooks";

import { useParams } from "react-router-dom";
import { useCompany } from "components_new/views/Company/hooks/queries/useCompany";
import { useDispatch } from "react-redux";
import { openModal } from "store/modalV2";

function CompanyInvestmentTabWrapper() {
  const dispatch = useDispatch();

  const { openSidebar } = useSidebar();
  const { id: companyId } = useParams();
  const {
    currentOrganization: { username: currentOrganizationUsername },
  } = useOrganizations();

  const { data } = useCompany(companyId);
  const {
    companyAvatars,
    companyCashFlows,
    investments,
    portfolios,
    exitedInvestments,
    companyCoInvestments,
  } = data;

  const handleOpenAddCoInvestmentForm = useCallback(
    () =>
      openSidebar("coInvestmentForm", "Add Investor", {
        companyId: data?.companyDetails?.id,
        prefilledCompany: data?.companyDetails,
        disableCompanySelector: true,
        resourceName: "Investor",
      }),
    [data, openSidebar],
  );

  const handleOpenEditCoInvestmentForm = useCallback(
    (coInvestment) =>
      openSidebar("coInvestmentForm", "Edit Investor", {
        companyId: data?.companyDetails?.id,
        disableCompanySelector: true,
        resourceName: "Investor",
        coInvestment,
      }),
    [data, openSidebar],
  );

  const handleOpenDeleteCoInvestment = useCallback(
    (coInvestment) =>
      dispatch(
        openModal("removeCoInvestment", {
          companyId: data?.companyDetails?.id,
          resourceName: "Investor",
          coInvestment,
        }),
      ),
    [data, dispatch],
  );

  return (
    <CompanyInvestmentTab
      companyAvatars={companyAvatars}
      companyCoInvestments={companyCoInvestments}
      cashFlows={companyCashFlows}
      investments={investments}
      portfolios={portfolios}
      exitedInvestments={exitedInvestments}
      currentOrganizationUsername={currentOrganizationUsername}
      openAddCoInvestmentForm={handleOpenAddCoInvestmentForm}
      openEditCoInvestmentForm={handleOpenEditCoInvestmentForm}
      openDeleteCoInvestment={handleOpenDeleteCoInvestment}
    />
  );
}

export default CompanyInvestmentTabWrapper;
