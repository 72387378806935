import Checkbox from "components_new/atoms/Checkbox";
import { Form } from "@ableco/semantic-ui-react";
import React from "react";
import classNames from "classnames";

export default function FlagsCheckboxGroup({
  interesting,
  double_down,
  stealth,
  yc,
  size,
  handleChange,
}) {
  const fieldClassnames = classNames("Input-field", {
    [size]: !!size,
  });
  return (
    <Form.Field className={fieldClassnames}>
      <label>Flags</label>
      <Checkbox
        extraClassName="CompanyFlag"
        label="Interesting"
        checked={interesting}
        onChange={() => handleChange("interesting", !interesting)}
        size={size}
      />
      <Checkbox
        extraClassName="CompanyFlag"
        label="Double Down"
        checked={double_down}
        onChange={() => handleChange("double_down", !double_down)}
        size={size}
      />
      <Checkbox
        extraClassName="CompanyFlag"
        label="Stealth"
        checked={stealth}
        onChange={() => handleChange("stealth", !stealth)}
        size={size}
      />
      <Checkbox
        extraClassName="CompanyFlag"
        label="Y Combinator"
        checked={yc}
        onChange={() => handleChange("is_yc", !yc)}
        size={size}
      />
    </Form.Field>
  );
}
