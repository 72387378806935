import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import FormElement from "components/shared/form/FormElement";
import { getPastedData } from "lib/shared/form/getPastedData";
import classNames from "classnames";
import { removePreFilledAndHttp } from "components_new/views/Company/shared/companyProfileConfig";
import Icon from "components_new/atoms/Icon";
import "./PreFilledInput.scss";

const iconType = {
  linkedin: "ri-linkedin-box-fill",
  twitter: "ri-twitter-fill",
  website: "ri-global-line",
  location: "ri-map-pin-2-fill",
};

function PreFilledInput({
  extraClassnames,
  name,
  preFilled,
  label,
  placeholder,
  isOptional,
  disabled,
  readOnly,
  showLock,
  size,
  alignIconToRight,
  errorMessage,
  value,
  type,
  onChange,
}) {
  const [displayValue, setDisplayValue] = useState(value || "");
  const [isFocused, setIsFocused] = useState(false);

  const prefilledInputRef = useRef(null);

  const handleClipboardPaste = (event) => {
    if (preFilled) {
      const cleanValue = removePreFilledAndHttp(
        getPastedData(event),
        preFilled,
      );
      setDisplayValue(cleanValue);
      onChange(cleanValue);
      prefilledInputRef.current.blur();
    }
  };

  const itemChange = (element) => {
    propagateChange(element.target.value);
  };

  const itemFocus = () => {
    setIsFocused(true);
  };

  const validateOnSubmit = (event) => {
    if (event.key === "Enter") {
      propagateChange(displayValue);
    }
  };

  const propagateChange = (value) => {
    setDisplayValue(value);
    onChange(value);
  };

  const preFilledInputClassnames = classNames("FormElement PreFilledInput", {
    "has-icon": !!type,
    [extraClassnames]: !!extraClassnames,
    [name]: !!name,
    "has-readOnly": readOnly,
    "is-active": isFocused || displayValue !== "",
    alignIconToRight: alignIconToRight,
    disabled: disabled,
    [size]: !!size,
  });

  useEffect(() => {
    setDisplayValue(value);
  }, [value]);

  return (
    <div className={preFilledInputClassnames}>
      <FormElement
        extraClassnames="FormElement--preFilledInput"
        label={label}
        name={name}
        isOptional={isOptional}
        readOnly={readOnly}
        showLock={showLock}
        errorMessage={errorMessage}
      >
        <div className="PreFilledInput-wrapper">
          {type && (
            <span className="PreFilledInput-icon">
              <Icon icon={iconType[type]} size={size} />
            </span>
          )}
          {preFilled && (
            <div className="PreFilledInput-content">{preFilled}</div>
          )}
          <input
            aria-label={label}
            className="InputText"
            onPaste={handleClipboardPaste}
            onKeyPress={validateOnSubmit}
            onChange={itemChange}
            onFocus={itemFocus}
            placeholder={placeholder}
            value={displayValue}
            autoComplete="off"
            ref={prefilledInputRef}
            disabled={disabled}
            readOnly={readOnly}
            type="text"
          />
        </div>
      </FormElement>
    </div>
  );
}

PreFilledInput.propTypes = {
  extraClassnames: PropTypes.string,
  name: PropTypes.string,
  preFilled: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isOptional: PropTypes.bool,
  readOnly: PropTypes.bool,
  showLock: PropTypes.bool,
  size: PropTypes.string,
  type: PropTypes.oneOf(["website", "location", "linkedin", "twitter"]),
  alignIconToRight: PropTypes.bool,
  errorMessage: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default PreFilledInput;
