function getElementOffset(element) {
  const { top } = element.getBoundingClientRect();
  return {
    top: top + document.body.offsetTop,
  };
}

function getElementProperties(elemDOMNode, parentDOMNode) {
  const parentHeight = parentDOMNode.clientHeight;
  const elementTop =
    getElementOffset(elemDOMNode).top - getElementOffset(parentDOMNode).top;
  const elementBotttom = elementTop + elemDOMNode.clientHeight;
  return {
    parentHeight,
    top: elementTop,
    bottom: elementBotttom,
  };
}

function isElementFullDisplayedInContainer(elemDOMNode, parentDOMNode) {
  const elementProps = getElementProperties(elemDOMNode, parentDOMNode);
  const { parentHeight, top, bottom } = elementProps;
  const isFullDisplayed = top >= 0 && bottom <= parentHeight;
  return isFullDisplayed;
}

function isElementPartialDisplayedInContainer(elemDOMNode, parentDOMNode) {
  const elementProps = getElementProperties(elemDOMNode, parentDOMNode);
  const { top, bottom, parentHeight } = elementProps;
  const isPartialDisplayed =
    (top < 0 && bottom > 0) || (top > 0 && top <= parentHeight);
  return isPartialDisplayed;
}

export {
  isElementPartialDisplayedInContainer,
  isElementFullDisplayedInContainer,
};
