import { isNil } from "lodash";

export function getView(state, viewName, viewId) {
  const view = state.viewsV2[viewName];
  return view[viewId] || {};
}

export function isLoadedView(state, viewName, viewId) {
  const view = state.viewsV2[viewName];
  return !isNil(view[viewId]);
}
