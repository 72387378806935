import React, { Fragment } from "react";
import { perUnitNumberPrecision } from "config/priceInput";
import { placeholderDateFormat } from "config/dateInput";
import amountPerShareOrTotal from "config/amountPerShareOrTotalConfig";
import PriceInput from "components/shared/form/PriceInput";
import DatePicker from "components_new/atoms/DatePicker";
import NumberInput from "components/shared/form/NumberInput";
import Select from "components/shared/form/Select";
import HelperContent from "components/shared/HelperContent";

function LogConversionForm({ formData, updateForm, historyAction, errors }) {
  const {
    date_helper_text: dateHelperContent,
    quantity_helper_text: numberHelperContent,
  } = historyAction;
  return (
    <Fragment>
      <DatePicker
        name="date"
        label="Date of conversion"
        placeholder={placeholderDateFormat}
        value={formData.date}
        onChange={(value) => updateForm("date", value)}
        helperContent={() => <HelperContent content={dateHelperContent} />}
        showHelper={!!dateHelperContent}
        extraClassnames="FormElement FormElement--Date"
        error={!!errors.for("date")}
        errorMessage={errors.for("date")}
        size="small"
        oldVersion
      />
      <PriceInput
        name="conversion_amount"
        label="Conversion amount"
        onChange={(value) => updateForm("total_conversion_amount", value)}
        value={formData.total_conversion_amount}
        errorMessage={errors.for("total_conversion_amount")}
        size="small"
      />
      <NumberInput
        name="quantity"
        label="Number of shares"
        placeholder=""
        onChange={(value) => updateForm("quantity", value)}
        value={formData.quantity}
        helperContent={() => <HelperContent content={numberHelperContent} />}
        showHelper={!!numberHelperContent}
        errorDisplayValidation={() => !numberHelperContent}
        errorMessage={errors.for("quantity")}
        size="small"
      />
      <div className="GroupInputs GroupInputs-quantityOrTotal small">
        <PriceInput
          name="cost"
          label="Value"
          extraClassnames="CompoundSelect-placeholder"
          errorDisplayValidation={() => false}
          onChange={(value) => updateForm("amount_first_valuation_temp", value)}
          value={formData.amount_first_valuation_temp}
          precision={perUnitNumberPrecision}
          size="small"
        />
        <Select
          extraClassnames="CompoundSelect-option"
          inputProps={amountPerShareOrTotal}
          onChange={(value) =>
            updateForm("amount_per_quantity_or_total", value)
          }
          value={formData.amount_per_quantity_or_total}
          size="small"
        />
        <p className="u-errorMessage QuantityErrorMessage">
          {errors.for("first_valuation_amount")}
        </p>
      </div>
    </Fragment>
  );
}

export default LogConversionForm;
