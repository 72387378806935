import {
  UPDATE_FORM,
  SAVE_DOCUMENTS_START,
  SAVE_DOCUMENTS_SUCCESS,
  CREATE_DOCUMENT_ERROR,
  USER_EDITED_TITLE,
  AUTOMATIC_EDITED_TITLE,
  CLEAN_DOCUMENT_FORM,
  DRAG_DOCUMENT_START,
  UPDATE_FILEPICKER_DATA,
  SET_DOCUMENTS_LENGTH,
  ADD_DOCUMENTS_TO_UPLOAD_QUEUE,
  UPDATE_DOCUMENT_IN_QUEUE,
  REMOVE_DOCUMENT_FROM_QUEUE,
  REMOVE_UPLOADED_DOCUMENT,
  STORE_UPLOADED_DOCUMENT,
} from "actions/synchronous/newDocumentForm";
import { STOP_DOCUMENT_CREATION } from "actions/synchronous/documentsView";

const initialState = {
  submitting: false,
  formData: {
    closing_document: false,
  },
  documentsData: [],
  errors: {},
  userEditedTitle: false,
  receiveFile: false,
  documentsLength: null,
  currentDocument: 0,
  uploadQueue: [],
  uploadedDocuments: [],
};

function newDocumentForm(state = initialState, action) {
  switch (action.type) {
    case UPDATE_FILEPICKER_DATA: {
      return Object.assign({}, state, {
        documentsData: state.documentsData.concat(action.filepickerData),
        currentDocument: state.currentDocument + 1,
      });
    }
    case UPDATE_FORM: {
      return Object.assign({}, state, {
        formData: Object.assign({}, state.formData, {
          [action.key]: action.value,
        }),
        errors: Object.assign({}, state.errros, { [action.key]: null }),
      });
    }
    case SET_DOCUMENTS_LENGTH:
      return Object.assign({}, state, {
        documentsLength: action.documentsLength,
      });
    case DRAG_DOCUMENT_START:
      return Object.assign({}, state, { receiveFile: true });
    case SAVE_DOCUMENTS_START:
      return Object.assign({}, state, { submitting: true });
    case CLEAN_DOCUMENT_FORM:
    case STOP_DOCUMENT_CREATION:
    case SAVE_DOCUMENTS_SUCCESS:
      return initialState;
    case CREATE_DOCUMENT_ERROR:
      return Object.assign({}, state, {
        errors: Object.assign({}, state.errors, action.errors),
        submitting: false,
      });
    case USER_EDITED_TITLE:
      return Object.assign({}, state, { userEditedTitle: true });
    case AUTOMATIC_EDITED_TITLE:
      return Object.assign({}, state, { userEditedTitle: false });
    case ADD_DOCUMENTS_TO_UPLOAD_QUEUE:
      return {
        ...state,
        uploadQueue: [...action.documents, ...state.uploadQueue],
      };
    case UPDATE_DOCUMENT_IN_QUEUE:
      const updatedUploadQueue = state.uploadQueue.map((item) => {
        if (item.id !== action.doc.id) {
          return item;
        }

        return {
          ...item,
          ...action.options,
        };
      });

      return {
        ...state,
        uploadQueue: [...updatedUploadQueue],
      };
    case REMOVE_DOCUMENT_FROM_QUEUE:
      const queueWithoutItem = state.uploadQueue.filter(
        (item) => item.id !== action.doc.id,
      );

      return {
        ...state,
        uploadQueue: [...queueWithoutItem],
      };
    case REMOVE_UPLOADED_DOCUMENT:
      const withoutDocument = state.uploadedDocuments.filter(
        (item) => item.localId !== action.doc.id,
      );

      return {
        ...state,
        uploadedDocuments: [...withoutDocument],
      };
    case STORE_UPLOADED_DOCUMENT:
      const uploadedDocument = action.uploadedDocument;
      uploadedDocument.localId = action.docId;

      return {
        ...state,
        uploadedDocuments: [
          ...state.uploadedDocuments,
          action.uploadedDocument,
        ],
      };
    default:
      return state;
  }
}

export default newDocumentForm;
