import React from "react";
import InputTag from "components_new/elements/InputTag";

function CompanyAliases(props) {
  const onCreate = function (tagName) {
    return new Promise(function (resolve, _reject) {
      const response = { data: { name: tagName } };
      resolve(response);
    });
  };

  return (
    <InputTag
      {...props}
      showSuggestions={false}
      onCreate={onCreate}
      createTagLabel="Add new alias"
    />
  );
}

export default CompanyAliases;
