import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import useKeyPress from "lib/hooks/useKeyPress";
import Input from "components_new/atoms/Input";
import { isEmpty } from "lodash";
import "./DataList.scss";

const filteredData = (value, options) => {
  return options.filter((option) =>
    option.toLowerCase().includes(value.toLowerCase()),
  );
};

function DataList({
  size,
  label,
  placeholder,
  options = [],
  onChange,
  defaultValue,
  ...otherProps
}) {
  const [value, setValue] = useState("");
  const downPress = useKeyPress("ArrowDown");
  const upPress = useKeyPress("ArrowUp");
  const enterPress = useKeyPress("Enter");
  const [cursor, setCursor] = useState(0);
  const [dataList, setDataList] = useState([]);
  const dataListRef = useRef(null);

  const onHandleFocus = () => {
    if (isEmpty(value)) {
      setDataList(options);
    }
  };

  const onHandleChange = (_, { value }) => {
    setValue(value);
    const suggestedData = filteredData(value, options);
    setDataList(suggestedData);
  };

  const onHandleClick = (value) => {
    setValue(value);
    setDataList([]);
    setCursor(0);
  };

  const handleClickOutside = (event) => {
    if (dataListRef.current.contains(event.target)) {
      return;
    }
    // when user clicks outside
    const dataListExist = document.querySelector(".DataList-options");
    if (dataListExist) {
      setDataList([]);
      setCursor(0);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (dataList.length > 0 && downPress) {
      setCursor((prevState) =>
        prevState < dataList.length - 1 ? prevState + 1 : prevState,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downPress]);

  useEffect(() => {
    if (dataList.length > 0 && upPress) {
      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upPress]);

  useEffect(() => {
    if (dataList.length > 0 && enterPress) {
      setValue(dataList[cursor]);
      setDataList([]);
      setCursor(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursor, enterPress]);

  useEffect(() => {
    onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <div ref={dataListRef} className="DataList">
      <Input
        size={size}
        label={label}
        placeholder={placeholder}
        autoComplete="off"
        onFocus={onHandleFocus}
        onChange={onHandleChange}
        value={value}
        {...otherProps}
      />
      {dataList.length > 0 && (
        <ul className="DataList-options">
          {dataList.map((option, index) => {
            return (
              <li
                key={index}
                className={`item ${index === cursor ? "active" : ""}`}
                onClick={() => onHandleClick(option)}
              >
                {option}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

DataList.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.string,
  defaultValue: PropTypes.string,
};

export default DataList;
