import moment from "moment";

export const displayDateFormat = "MM/DD/YYYY";
export const valueDateFormat = "YYYY-MM-DD";
export const dateInvalidMessage = "Date is invalid";
export const defaultDateMask = [
  /\d/,
  /\d/,
  "/",
  /\d/,
  /\d/,
  "/",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const validDateFormats = [
  "MM/DD/YYYY",
  "M/D/YY",
  "M/D/YYYY",
  "M-D-YY",
  "YYYY-MM-DD",
  "YYYY-MM-D",
  "YYYY-M-DD",
  "YYYY-M-D",
  "YY-M-D",
  "MM.D.YYYY",
  "M DD YY",
  "M D YY",
  "MM DD YY",
];

export function formatTextoToDate(text, format = displayDateFormat) {
  return moment(text, validDateFormats).utc().format(format);
}

export function removeEmptySpaces(text) {
  return text.replace(/ /g, "");
}

export function dateStringToMoment(date) {
  return moment(removeEmptySpaces(date), validDateFormats, true);
}

export default {
  displayDateFormat,
  valueDateFormat,
  dateInvalidMessage,
  defaultDateMask,
  validDateFormats,
  formatTextoToDate,
  removeEmptySpaces,
  dateStringToMoment,
};
