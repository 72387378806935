export default function filterBy(collection, query, keys) {
  const loweredQuery = query.toLowerCase().replace(/\W+/g, " ").trim();
  const regexp = new RegExp(`${loweredQuery}.*`);

  return collection.filter((item) => {
    const attributes = keys.map((key) => item[key]?.toLowerCase());
    const shouldAdd = attributes.some((attribute) => attribute?.match(regexp));

    return shouldAdd;
  });
}
