import React, { Fragment } from "react";
import { connect } from "react-redux";
import { closeModal, updateModalOptions } from "store/modalV2/actions";
import { removeOrganizationMembership } from "store/organizations/organizationMembers/actions";
import ConfirmationModal from "components/shared/ConfirmationModal";
import { flash } from "actions/synchronous/flash";

const createMessage = (organizationMembership) => {
  return (
    <Fragment>
      Remove <strong> {organizationMembership.full_name} </strong> from the
      organization <strong> {organizationMembership.organization_name} </strong>{" "}
      ? This member will lose all the privileges to view or manage portfolios
    </Fragment>
  );
};

function mapStateToProps(state, ownProps) {
  const organizationMembership = ownProps.organizationMembership;
  return {
    organizationMembership: organizationMembership,
    title: "Remove Member",
    message: createMessage(organizationMembership),
    confirmButtonLabel: "Remove Member",
    cancelButtonLabel: "Cancel",
    primaryButtonClassnames: "Button--warning",
    submitting: ownProps.isSubmitting || false,
  };
}

function updateModalProps(dispatch, props) {
  dispatch(updateModalOptions("removeOrganizationMember", props));
}

function mergeProps(stateProps, dispatchProps) {
  const { organizationMembership } = stateProps;
  const { dispatch } = dispatchProps;

  const newProps = {
    closeModal: () => dispatch(closeModal()),
    action: () => {
      updateModalProps(dispatch, {
        isSubmitting: true,
      });
      dispatch(removeOrganizationMembership(organizationMembership)).then(
        () => {
          updateModalProps(dispatch, {
            isSubmitting: false,
          });
          dispatch(closeModal());
          dispatch(
            flash("removeOrRevokeMemberFromOrganization", {
              nameOrEmail: organizationMembership.full_name,
            }),
          );
        },
      );
    },
  };

  return {
    ...stateProps,
    ...newProps,
  };
}

export default connect(mapStateToProps, null, mergeProps)(ConfirmationModal);
