import React from "react";
import FormGroup from "components_new/elements/FormGroup";
import MultiSelect from "components_new/elements/MultiSelect";
import { partition } from "lodash";
import formatContactName from "lib/formatters/formatContactName";
import getUniqueIdFromTime from "components_new/lib/getUniqueIdFromTime";
import "./Contacts.scss";

function Contacts({
  prefilledContacts,
  handleChange,
  submitTo,
  searchContact,
  mountSidebarComponent,
  hideSidebarComponent,
  isExpanded,
}) {
  const updateContactsData = (contactsData) => {
    const [newContacts, existingContacts] = partition(
      contactsData,
      "isNewRecord",
    );

    handleChange("contacts_attributes", newContacts);
    handleChange(
      "contact_ids",
      existingContacts.map((item) => item.id),
    );
  };

  const onCreateNewContact = (updateMultiselectComponent, query) => {
    const [first_name, last_name] = query.split(" ");
    last_name ? last_name : "";

    mountSidebarComponent(
      "addContactForm",
      "Add New Contact",
      {
        data: {
          first_name,
          last_name: last_name ? last_name : "",
        },
        updatePrimaryForm: (contactData) =>
          updateMultiselectComponent({
            key: getUniqueIdFromTime(),
            caption: formatContactName(contactData),
            value: { ...contactData, isNewRecord: true },
          }),
      },
      submitTo,
    );

    hideSidebarComponent(submitTo);
  };

  return (
    <FormGroup
      extraClassnames="ContactsGroup"
      title="Contacts"
      subtitle="Information about relationships to this investment"
      isExpanded={isExpanded}
    >
      <MultiSelect
        name="contacts"
        label="Referrer Source"
        placeholder="Search contacts"
        scope="contacts"
        prefilledItems={prefilledContacts}
        onFilter={searchContact}
        onClickAddNew={onCreateNewContact}
        onChange={(contacts) => {
          const contactsData = contacts.map((item) => item.value);
          updateContactsData(contactsData);
        }}
        size="tiny"
      />
    </FormGroup>
  );
}

export default Contacts;
