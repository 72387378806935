import React from "react";
import ConfirmationModal from "components/shared/ConfirmationModal";
import { makeWarningMessage } from "./config";
import useModal from "store/modalV2/hooks";

function GlobalFormWarn({ formTitle, organizationPath, navAction }) {
  const { closeModal } = useModal();
  const message = makeWarningMessage(formTitle);

  const action = () => {
    navAction(organizationPath);
    closeModal();
  };

  return (
    <ConfirmationModal
      title="Discard unsaved changes?"
      message={message}
      confirmButtonLabel="Discard Changes"
      primaryButtonClassnames="Button--warning"
      closeModal={closeModal}
      action={action}
      submitting={false}
    />
  );
}

export default GlobalFormWarn;
