import { useMutation, useQueryClient } from "react-query";
import Api from "lib/Api";

export function useRemoveStage() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ pipelineId, stageId }) =>
      Api.delete(`/pipelines/${pipelineId}/stages/${stageId}`),
    {
      onSuccess: (_, { pipelineId, stageId: deletedStageId }) => {
        return queryClient.setQueryData(
          ["Pipeline", pipelineId],
          (oldData) => ({
            ...oldData,
            stages: oldData.stages.filter(
              (stage) => stage.id !== deletedStageId,
            ),
          }),
        );
      },
    },
  );
}
