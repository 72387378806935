import React from "react";
import { useDispatch } from "react-redux";
import { performSearch } from "store/search";
import Investor from "./Investor";

const parseElements = (elements) => {
  return elements.map((element) => ({
    key: element.id,
    caption: element.name,
    value: element,
  }));
};
function DealFormWrapper({ onChange, label, placeholder, investors }) {
  const dispatch = useDispatch();

  const searchCompanies = (scope, query) =>
    dispatch(performSearch(scope, query)).then(
      ({ results: { ["investors"]: resultItems } }) => resultItems,
    );

  return (
    <Investor
      onChange={onChange}
      label={label}
      placeholder={placeholder}
      investorList={parseElements(investors || [])}
      searchInvestor={searchCompanies}
    />
  );
}

export default DealFormWrapper;
