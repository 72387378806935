import React, { useEffect } from "react";
import TaskView from "components_new/views/Queues/tabs/TaskView";
import { useTasks } from "components_new/views/Queues/hooks/queries/useTasks";

function SnoozedTasksWrapper({ filters, updateFilters, queueLabel }) {
  const { data, isLoading } = useTasks({ scope: "snoozed", filters });

  useEffect(() => {
    updateFilters((filters) => ({
      ...filters,
      query: "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TaskView
      isLoading={isLoading}
      tasks={data?.tasks || []}
      tasksType="snoozed"
      filters={filters}
      updateFilters={updateFilters}
      queueLabel={queueLabel}
    />
  );
}

export default SnoozedTasksWrapper;
