import React from "react";
import PropTypes from "prop-types";
import CloseIcon from "-!svg-react-loader?!assets/icons/utility/close.svg?name=CloseIcon";
import preventHandler from "lib/preventerHandler";
import { documentPropType } from "PropTypes";
import FileUploadStatus from "components/documents/FileUploadStatus";
import FileTypeIcon from "components/shared/FileTypeIcon";

function AttachmentRow({ documentFile, onRemoveDocument }) {
  return (
    <div className="AttachmentRow">
      <span className="AttachmentRow-documentIcon">
        <FileTypeIcon file={documentFile} size="small" />
      </span>
      <span className="AttachmentRow-name">
        <a
          className="Link Link--underline"
          href={documentFile.url}
          rel="nofollow noopener"
          target="_blank"
        >
          {
            /* TODO: Consolidate property name*/
            documentFile.file_name || documentFile.filename
          }
        </a>
        {documentFile.size && (
          <FileUploadStatus status="completed" file={documentFile} />
        )}
      </span>
      <span
        className="AttachmentRow-deleteDocument"
        onClick={preventHandler(() => onRemoveDocument(documentFile))}
      >
        <CloseIcon />
      </span>
    </div>
  );
}

AttachmentRow.propTypes = {
  documentFile: documentPropType.isRequired,
  onRemoveDocument: PropTypes.func.isRequired,
};

export default AttachmentRow;
