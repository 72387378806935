export const REQUEST_START = "requests/REQUEST_START";
export const REQUEST_SUCCESS = "requests/REQUEST_SUCCESS";
export const REQUEST_ERROR = "requests/REQUEST_ERROR";

export function requestStart(requestName) {
  return { type: REQUEST_START, requestName };
}

export function requestSuccess(requestName) {
  return { type: REQUEST_SUCCESS, requestName };
}

export function requestError(requestName) {
  return { type: REQUEST_ERROR, requestName };
}
