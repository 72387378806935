import React from "react";
import {
  TableHeader,
  TableRow,
  TableCell,
  SortableTableHeaderCell,
  SortedTable as SortedTableHelper,
  TableBody,
} from "components_new/elements/Table";
import Value from "components_new/atoms/Value";
import CompanyInfo from "components_new/elements/CompanyInfo";
import Link from "components_new/atoms/Link";
import Tooltip from "components_new/atoms/Tooltip";
import ContactInfo from "./ContactInfo";
import ContactReferrals from "./ContactReferrals";
import { isEmpty } from "lodash";

import { COLUMN_HEADERS } from "./config";
import "./ContactsTable.scss";

const renderHeaders = (handleSort, isSorted) => {
  return (
    <TableHeader>
      <TableRow>
        {COLUMN_HEADERS.map(({ skeletonType, ...columnHeader }, index) => {
          return (
            <SortableTableHeaderCell
              key={index}
              isSorted={isSorted}
              handleSort={handleSort}
              {...columnHeader}
            >
              <Tooltip
                position={columnHeader.tooltipPosition}
                content={columnHeader.tooltipContent}
                disabled={!columnHeader.hasTooltip}
                trigger={<div>{columnHeader.title}</div>}
              />
            </SortableTableHeaderCell>
          );
        })}
      </TableRow>
    </TableHeader>
  );
};

function SortedTable({
  tableData,
  isLoading,
  isSearching,
  organizationUsername,
  showSidebar,
}) {
  return (
    <SortedTableHelper
      tableData={tableData}
      className="ContactsTable attached"
      isLoading={isLoading || isSearching}
      renderHeaders={renderHeaders}
      skeletonStructure={{ rows: 8, headers: COLUMN_HEADERS }}
      shadow={false}
    >
      {({ data }) => (
        <TableBody isEmptyData={isEmpty(data)}>
          {!isEmpty(data) &&
            data.map((contact, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>
                    <ContactInfo contact={contact} showSidebar={showSidebar} />
                  </TableCell>
                  <TableCell>
                    {contact.roles.length > 0 &&
                      contact.roles.map((role, index) => {
                        return (
                          <CompanyInfo
                            key={index}
                            title={(company) => (
                              <Link
                                title={company.name}
                                to={`/${organizationUsername}/companies/${company.id}`}
                              />
                            )}
                            company={role.company}
                          />
                        );
                      })}
                  </TableCell>
                  <TableCell>{contact.tagline}</TableCell>
                  <TableCell>
                    <Value type="tag" emptyValue="" light>
                      {contact.industries}
                    </Value>
                  </TableCell>
                  <TableCell>
                    <Value type="tag" emptyValue="" light>
                      {contact.tags}
                    </Value>
                  </TableCell>
                  <TableCell>{contact.location}</TableCell>
                  <TableCell>
                    <ContactReferrals
                      organizationUsername={organizationUsername}
                      referrals={contact.referrals}
                      showSidebar={showSidebar}
                    />
                  </TableCell>
                  <TableCell>
                    <Value type="date" light>
                      {contact.last_referral_date}
                    </Value>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      )}
    </SortedTableHelper>
  );
}

export default SortedTable;
