import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useMappedInvestment } from "./hooks/useMappedInvestment";
import InlineValueList from "components_new/atoms/InlineValueList";
import ProfileAvatarGroup from "components_new/atoms/ProfileAvatar/ProfileAvatarGroup";
import Text from "components_new/atoms/Text";
import Avatar from "components_new/elements/Avatar";
import CollapsableSection from "components_new/elements/CollapsableSection";
import DiscussionsList from "components_new/elements/Discussions/DiscussionsList";
import FlatList from "components_new/elements/FlatList";
import ReportTable from "components_new/elements/ReportTable";
import Icon from "components_new/atoms/Icon";
import InvestmentFiles from "./InvestmentFiles";
import "./InvestmentView.scss";

function InvestmentView({
  investment,
  currentOrganizationUsername,
  users,
  currentUser,
  investmentPosts,
  filesActions,
  discussionActions,
  closeSidebar,
}) {
  // Map data to UI readable shape

  const {
    details = [],
    performance = [],
    valuationHistory,
    concernedDescription,
    concernedTypeLabel = "",
    capitalCallHistory = { data: [], footerData: [], footNote: null },
    distributionHistory = { data: [], footerData: [] },
    certificateHistory = [],
    referrers = [],
    files = {},
  } = useMappedInvestment({
    investment,
    currentOrganizationUsername,
  });

  function subtitle() {
    return (
      <Fragment>
        {concernedTypeLabel} in {investment.details.company_name}{" "}
        <Text as="span" type="label-regular">
          {investment.company_avatar.status}
        </Text>
      </Fragment>
    );
  }

  return (
    <div className="SidebarView-InvestmentView">
      <div className="InvestmentView-Header">
        <Avatar
          title={investment.details.name}
          subtitle={subtitle()}
          imageUrl={investment.company_avatar.logo_url}
          avatarIcon="company-med"
          size="mini"
        />
        <Icon
          icon="ri-close-line"
          size="large"
          extraClassnames="Sidebar-close"
          onClick={closeSidebar}
        />
        {/* <div className="content-row">
          <div className="content-row-item">
            <Button primary size="small">
              Update Share Price
            </Button>
          </div>
          <div className="content-row-item">
            <Dropdown
              trigger={
                <Button secondary size="small" iconPosition="right">
                  Actions <Icon icon="ri-arrow-down-s-line" />
                </Button>
              }
              options={[
                { key: 1, text: "Choice 1", value: 1 },
                { key: 2, text: "Choice 2", value: 2 },
                { key: 3, text: "Choice 3", value: 3 },
              ]}
              icon={null}
              item
            />
          </div>
        </div> */}
      </div>
      <div className="InvestmentView-Content InvestmentView-SubHeader">
        <Text type="title-2" extraClassnames="u-textAlignCenter">
          {concernedDescription} in {investment.details.company_name}
        </Text>
        <Text
          type="body-2-regular"
          color="brand-secondary"
          extraClassnames="u-textAlignCenter"
        >
          {investment.details.origin_note}
        </Text>
      </div>
      <div className="InvestmentView-Content InvestmentView-Content-Divided">
        <div className="content-row content-row-divided content-row-equal-items">
          <div className="content-row-item">
            <Text type="subtitle-2-bold">
              {investment.details.type_label} Details
            </Text>
            <InlineValueList data={details} showMoreCursorPosition={7} />
          </div>
          <div className="content-row-item">
            <Text type="subtitle-2-bold">
              {investment.details.type_label} Performance
            </Text>
            <InlineValueList data={performance} />
          </div>
        </div>
      </div>

      {/* TODO: We were having an error message for the multple choice custom fields, even if the investment don't have any custom fields created. I am commenting this block in order to work with more time and fix it and not patch it
      <div className="InvestmentView-Content">
        <CollapsableSection
          title="Custom Details"
          titleCount={customFields.length.toString()}
          expanded={customFields.length > 0}
        >
          <InlineValueList data={customFields} />
        </CollapsableSection>
      </div>
        */}

      <div className="InvestmentView-Content">
        <CollapsableSection
          title="Valuation History"
          titleCount={valuationHistory.length.toString()}
          expanded={valuationHistory.length > 0}
        >
          <ReportTable
            headers={[
              { title: "Date" },
              { title: "Notes" },
              { title: "Amount", align: "right" },
            ]}
            data={valuationHistory}
            emptyTitle="Valuations"
            nilLabel="0"
            // rowActions={[
            //   {
            //     text: "Edit",
            //     onClick: (rowIndex) => {
            //       alert(investment.valuationHistory[rowIndex]);
            //     },
            //   },
            //   {
            //     text: "Delete",
            //     onClick: () => null,
            //   },
            // ]}
          />
        </CollapsableSection>
      </div>
      {investment.details.type === "fund" && (
        <div className="InvestmentView-Content">
          <CollapsableSection
            title="Capital Call History"
            titleCount={capitalCallHistory.data.length.toString()}
            expanded={capitalCallHistory.data.length > 0}
          >
            <ReportTable
              headers={[
                { title: "#" },
                { title: "Due Date" },
                { title: "Amount", align: "right" },
                { title: "% Commitment", align: "right" },
                { title: "Total Paid-In", align: "right" },
              ]}
              data={capitalCallHistory.data}
              // rowActions={[
              //   {
              //     text: "View",
              //     onClick: () => alert("View"),
              //   },
              //   {
              //     text: "Edit",
              //     onClick: () => alert("Edit"),
              //   },
              //   {
              //     text: "Delete",
              //     onClick: () => alert("Delete"),
              //   },
              // ]}
              footerData={
                capitalCallHistory.footerData.length > 0
                  ? capitalCallHistory.footerData
                  : null
              }
              footNote={capitalCallHistory.footNote}
              emptyTitle="capital calls"
              nilLabel="0"
            />
          </CollapsableSection>
        </div>
      )}
      <div className="InvestmentView-Content">
        <CollapsableSection
          title="Distribution History"
          titleCount={distributionHistory.data.length.toString()}
          expanded={distributionHistory.data.length > 0}
        >
          <ReportTable
            headers={[
              { title: "Date" },
              { title: "Distribution" },
              { title: "Quantity", align: "right" },
              { title: "Amount", align: "right" },
            ]}
            data={distributionHistory.data}
            // rowActions={[
            //   {
            //     text: "View",
            //     onClick: () => alert("View"),
            //   },
            //   {
            //     text: "Edit",
            //     onClick: () => alert("Edit"),
            //   },
            //   {
            //     text: "Delete",
            //     onClick: () => alert("Delete"),
            //   },
            // ]}
            footerData={
              distributionHistory.footerData.length > 0
                ? distributionHistory.footerData
                : null
            }
            emptyTitle="distribution events"
            nilLabel="0"
          />
        </CollapsableSection>
      </div>
      <div className="InvestmentView-Content">
        <CollapsableSection
          title="Certificate History"
          titleCount={certificateHistory.length.toString()}
          expanded={certificateHistory.length > 0}
        >
          <ReportTable
            timeline
            headers={[
              { title: "Event" },
              { title: "Investment" },
              { title: "Certificate" },
              { title: "QTY.", align: "right" },
              { title: "Cost Basis", align: "right" },
            ]}
            data={certificateHistory}
            // rowActions={[
            //   {
            //     text: "View",
            //     onClick: () => alert("View"),
            //   },
            //   {
            //     text: "Edit",
            //     onClick: () => alert("Edit"),
            //   },
            //   {
            //     text: "Delete",
            //     onClick: () => alert("Delete"),
            //   },
            // ]}
            emptyTitle="certificate history"
            nilLabel="No"
          />
        </CollapsableSection>
      </div>
      <div className="InvestmentView-Content">
        <CollapsableSection
          title="Referrers"
          titleCount={referrers.length.toString()}
          expanded={referrers.length > 0}
        >
          <FlatList
            title="Referrers"
            data={referrers}
            renderItem={(item) => (
              <ProfileAvatarGroup
                name={item.name}
                subtitle={item.subtitle}
                extraClassNames="FlatListItem-ProfileAvatarGroup"
              />
            )}
            emptyTitle="referrers"
            nilLable="0"
          />
        </CollapsableSection>
      </div>
      <div className="InvestmentView-Content">
        <CollapsableSection
          title="Files"
          titleCount={investment.documents.length.toString()}
          expanded={true}
        >
          <InvestmentFiles {...files} {...filesActions} />
        </CollapsableSection>
      </div>
      <div className="InvestmentView-Content">
        {/* TODO: Collapsed if empty */}
        <CollapsableSection
          title="Notes"
          titleCount={investmentPosts.length.toString()}
          expanded={investmentPosts.length > 0}
        >
          <DiscussionsList
            posts={investmentPosts}
            organizationUsername={currentOrganizationUsername}
            currentUser={currentUser}
            users={users}
            resource={{
              type: "Investment",
              investment_id: investment.details.id,
            }}
            showResource={false}
            {...discussionActions}
          />
        </CollapsableSection>
      </div>
      <div className="InvestmentView-Content">
        <Text type="body-1-bold" extraClassnames="u-textAlignCenter" link>
          <Link
            to={`/${currentOrganizationUsername}/investments/${investment.details.id}`}
          >
            See Full Details
          </Link>
        </Text>
      </div>
    </div>
  );
}

export default InvestmentView;
