import React from "react";
import Button from "components_new/atoms/Button";
import Dropdown from "components_new/atoms/Dropdown";
import ColumnedValueList from "components_new/atoms/ColumnedValueList";
import formatPrice from "lib/formatters/formatPrice";
import formatDate from "lib/formatDate";
import Icon from "components_new/atoms/Icon";
import Text from "components_new/atoms/Text";

function CommittedDealHeader({
  deal,
  openEditDealForm,
  onConvertToInvesment,
  openDeleteDealModal,
  openReactivateDealModal,
  openEditCommitmentModal,
  closeSidebar,
}) {
  const columnData = [
    { label: "AMOUNT", value: formatPrice(deal.commitment_amount) },
    {
      label: "COMMITTED",
      value: formatDate(deal.decision_date, "semiDetailed"),
    },
  ];

  const actionsOptions = [
    {
      key: 1,
      text: "Edit commitment",
      value: 1,
      onClick: () => openEditCommitmentModal(deal.id),
    },
    {
      key: 2,
      text: "Undo commitment",
      value: 2,
      onClick: () => openReactivateDealModal(deal.id),
    },
    {
      key: 3,
      text: "Edit deal",
      value: 3,
      onClick: () => openEditDealForm(deal.id),
    },
    {
      key: 4,
      text: "Delete deal",
      value: 4,
      onClick: () => openDeleteDealModal(deal.id),
    },
  ];

  return (
    <div className="CommittedDealHeader">
      <div className="CommittedDealActions">
        <Button size="small" primary onClick={() => onConvertToInvesment(deal)}>
          Convert to Investment
        </Button>
        <Dropdown text="Actions" size="small" button options={actionsOptions} />
        <Icon
          icon="ri-close-line"
          size="large"
          extraClassnames="Sidebar-close"
          onClick={closeSidebar}
        />
      </div>
      <div className="CommittedDealInformation">
        <Text as="h4" type="subtitle-2-bold">
          Commitment Details
        </Text>
        <ColumnedValueList
          data={columnData}
          ellipsize={false}
          extraClassname="CommittedDealInformation-ColumnedValueList"
        />
      </div>
    </div>
  );
}

export default CommittedDealHeader;
