import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

function FilterLoader({ extraClassnames, size }) {
  const filterLoaderClassnames = classNames({
    Spinner: true,
    [`Spinner--${size}`]: !!size,
    [extraClassnames]: true,
  });
  return (
    <div className={filterLoaderClassnames}>
      <div className="Spinner-holder">
        <div className="SpinnerIcon-holder">
          <div className="SpinnerIcon" />
        </div>
      </div>
    </div>
  );
}

FilterLoader.propTypes = {
  extraClassnames: PropTypes.string,
  size: PropTypes.string,
};

FilterLoader.defaultProps = {
  extraClassnames: "",
  size: null,
};

export default FilterLoader;
