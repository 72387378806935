import Api from "lib/Api";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

export function useReactivateDeal() {
  const queryClient = useQueryClient();
  const { organizationUsername } = useParams();

  return useMutation(
    ({ pipelineId, dealId }) => {
      return Api.patch(`/pipelines/${pipelineId}/deals/${dealId}/reactivate`);
    },
    {
      onSuccess: ({ data: updatedDeal }, { pipelineId }) => {
        updateCompanyView(queryClient, organizationUsername, updatedDeal);
        updateActiveDealsView(queryClient, pipelineId, updatedDeal);
        updateCommittedDealsView(queryClient, pipelineId, updatedDeal);
        updatePassedDealsView(queryClient, pipelineId, updatedDeal);
        updateDealView(queryClient, updatedDeal);

        queryClient.invalidateQueries(["Pipeline", pipelineId]);
        queryClient.invalidateQueries(["SearchDeals", pipelineId]);
      },
    },
  );
}

function updateCompanyView(queryClient, organizationUsername, updatedDeal) {
  const key = ["Company", organizationUsername, updatedDeal.company_id];
  const companyView = queryClient.getQueryData(key);

  if (companyView?.companyDeals) {
    queryClient.setQueryData(key, {
      ...companyView,
      companyDeals: companyView.companyDeals.map((deal) =>
        deal.id === updatedDeal.id ? updatedDeal : deal,
      ),
    });
  }
}

function updateActiveDealsView(queryClient, pipelineId, updatedDeal) {
  const key = ["ActiveDeals", pipelineId, {}];
  const activeDealsView = queryClient.getQueryData(key);

  if (activeDealsView?.deals) {
    queryClient.setQueryData(key, {
      ...activeDealsView,
      deals: [updatedDeal, ...activeDealsView.deals],
    });
  } else {
    queryClient.setQueryData(key, {
      owners: [],
      headquarters: [],
      deals: [updatedDeal],
    });
  }
}

function updateDealView(queryClient, updatedDeal) {
  const key = ["Deal", updatedDeal.id];
  const dealView = queryClient.getQueryData(key);

  if (dealView?.deal) {
    queryClient.setQueryData(key, {
      ...dealView,
      deal: updatedDeal,
      stage: updatedDeal.stage,
    });
  }
}

function updateCommittedDealsView(queryClient, pipelineId, reactivatedDeal) {
  const key = ["CommittedDeals", pipelineId, {}];
  const committedDealsView = queryClient.getQueryData(key);

  if (committedDealsView?.deals) {
    queryClient.setQueryData(key, {
      ...committedDealsView,
      deals: committedDealsView.deals.filter(
        (deal) => deal.id !== reactivatedDeal.id,
      ),
    });
  }
}

function updatePassedDealsView(queryClient, pipelineId, reactivatedDeal) {
  const key = ["PassedDeals", pipelineId, {}];
  const passedDealsView = queryClient.getQueryData(key);

  if (passedDealsView?.deals) {
    queryClient.setQueryData(key, {
      ...passedDealsView,
      deals: passedDealsView.deals.filter(
        (deal) => deal.id !== reactivatedDeal.id,
      ),
    });
  }
}
