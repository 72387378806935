import React from "react";
import MultiSelect from "components_new/elements/MultiSelect";
import { partition, isEmpty } from "lodash";
import formatContactName from "lib/formatters/formatContactName";
import getUniqueIdFromTime from "components_new/lib/getUniqueIdFromTime";
import { useSetSidebar } from "components_new/elements/SidebarWrapper/Sidebar-context";

function Referrer({ prefilledContacts, handleChange, searchContact }) {
  const { showSidebar } = useSetSidebar();

  const updateContactsData = (contactsData) => {
    const [newContacts, existingContacts] = partition(
      contactsData,
      "isNewRecord",
    );

    handleChange("contacts_attributes", newContacts);
    handleChange(
      "contact_ids",
      existingContacts.map((item) => item.id),
    );
  };

  const onCreateNewContact = (updateMultiselectComponent, query) => {
    const [first_name, last_name] = query.split(" ");
    last_name ? last_name : "";

    showSidebar("referrerForm", {
      referrer: {
        first_name,
        last_name: last_name ? last_name : "",
      },
      onChange: (contactData) => {
        if (!isEmpty(contactData)) {
          return updateMultiselectComponent({
            key: getUniqueIdFromTime(),
            caption: formatContactName(contactData),
            value: { ...contactData, isNewRecord: true },
          });
        }
      },
    });
  };

  return (
    <MultiSelect
      name="contacts"
      label="Referrer"
      placeholder="Deal referrer"
      scope="contacts"
      prefilledItems={prefilledContacts}
      onFilter={searchContact}
      onClickAddNew={onCreateNewContact}
      onChange={(contacts) => {
        const contactsData = contacts.map((item) => item.value);
        updateContactsData(contactsData);
      }}
      size="tiny"
    />
  );
}

export default Referrer;
