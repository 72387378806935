import React, { useMemo } from "react";
import "./PortfolioMetaData.scss";
import SummaryCard from "components_new/elements/SummaryCard";
import Carousel from "components_new/elements/Carousel";
import valueOrEmpty from "components_new/lib/valueOrEmpty";
import formatPrice from "lib/formatters/formatPrice";
import formatDate from "lib/formatDate";
import accounting from "accounting";

const formattedValue = (type, value) => {
  switch (type) {
    case "percentage":
      return value
        ? `${accounting.formatNumber(value, 2)}%`
        : valueOrEmpty(value);
    case "price":
      return value ? formatPrice(value, { precision: 0 }) : valueOrEmpty(value);
    case "date":
      return value ? formatDate(value) : valueOrEmpty(value);
    default:
      return value ? accounting.formatNumber(value) : valueOrEmpty(value);
  }
};

function PortfolioMetaData({ metaData, isLoading }) {
  const highLevelStats = useMemo(() => {
    const {
      outstanding_capital_call_amount,
      percentage_capital_remaining,
      average_investment_size,
      invested_per_month,
      follow_on_investment_rate,
      average_follow_on_investment_size,
      total_follow_on_investment_amount,
      last_investment_date,
      limit,
      total_invested,
      capital_remaining,
      irr_percentage,
      equity_investment_count,
      fund_investment_count,
      llc_investment_count,
      warrant_investment_count,
      option_investment_count,
      debt_investment_count,
      safe_investment_count,
    } = metaData;
    const isPortfolioSelected = metaData.id !== "all";
    return [
      {
        title: "Outstanding Capital Call Amount",
        value:
          outstanding_capital_call_amount &&
          formattedValue("price", outstanding_capital_call_amount),
      },
      {
        title: "Avg. investment size",
        value: formattedValue("price", average_investment_size),
      },
      {
        title: "Invested per month",
        value: formattedValue("price", invested_per_month),
      },
      {
        title: "Follow-on rate",
        value: formattedValue("percentage", follow_on_investment_rate),
      },
      {
        title: "Avg. Follow-on Amount",
        value: formattedValue("price", average_follow_on_investment_size),
      },
      {
        title: "Total Follow-on Amount",
        value: formattedValue("price", total_follow_on_investment_amount),
      },
      {
        title: "Last updated",
        value: formattedValue("date", last_investment_date),
      },
      isPortfolioSelected && {
        title: "Fund limit",
        value: formattedValue("price", limit),
      },
      {
        title: "Total invested",
        value: formattedValue("price", total_invested),
      },
      {
        title: "Capital remaining",
        value: formattedValue("price", capital_remaining),
      },
      {
        title: "IRR",
        value: formattedValue("percentage", irr_percentage),
      },
      equity_investment_count > 0 && {
        title: "Equity Investments",
        value: equity_investment_count,
      },
      fund_investment_count > 0 && {
        title: "Fund Investments",
        value: metaData.fund_investment_count,
      },
      llc_investment_count > 0 && {
        title: "LLC Investments",
        value: llc_investment_count,
      },
      warrant_investment_count > 0 && {
        title: "Warrant Investments",
        value: warrant_investment_count,
      },
      option_investment_count > 0 && {
        title: "Option Investments",
        value: option_investment_count,
      },
      debt_investment_count > 0 && {
        title: "Debt Investments",
        value: debt_investment_count,
      },
      safe_investment_count > 0 && {
        title: "SAFE Investments",
        value: safe_investment_count,
      },
      {
        title: "% Capital remaining",
        value:
          percentage_capital_remaining &&
          formattedValue("percentage", percentage_capital_remaining),
      },
    ].filter(Boolean);
  }, [metaData]);

  return (
    <div className="PortfolioMetaData">
      <div className="PortfolioMetaData-card">
        <Carousel>
          {highLevelStats.map(
            (item, index) =>
              item.value && (
                <SummaryCard
                  key={index}
                  title={item.title}
                  value={item.value}
                  isLoading={isLoading}
                />
              ),
          )}
        </Carousel>
      </div>
    </div>
  );
}

PortfolioMetaData.defaultProps = {
  metaData: {},
};

export default PortfolioMetaData;
