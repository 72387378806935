import { connect } from "react-redux";
import ConfirmationModal from "components/shared/ConfirmationModal";
import { closeModal, ModalNames } from "store/modal";

function mapStateToProps(state) {
  const options = state.modal.options[ModalNames.deleteCompanyConfirmation];
  const companyId = options.companyId;
  const onConfirm = options.onConfirm;

  return {
    companyId,
    confirmButtonLabel: "Delete",
    message: `Are you sure you want to delete this company?`,
    onConfirm,
    submitting: false,
    title: "Delete Confirmation",
  };
}

function mergeProps(stateProps, dispatchProps) {
  const { companyId, onConfirm } = stateProps;
  const { dispatch } = dispatchProps;

  const newProps = {
    action: () => {
      onConfirm(companyId);
      dispatch(closeModal());
    },
  };

  return {
    ...stateProps,
    ...newProps,
  };
}

export default connect(mapStateToProps, null, mergeProps)(ConfirmationModal);
