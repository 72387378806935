import React, { useState, Fragment } from "react";
import { closeModal } from "store/modalV2/actions";
import { useDispatch } from "react-redux";

import Loader from "components/shared/Loader";
import ConfirmationModal from "components/shared/ConfirmationModal";

import { useDeal } from "components_new/views/Deals/hooks/queries/useDeal";
import { useRemoveDeal } from "components_new/views/Deals/hooks/mutations/useRemoveDeal";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import getMessage from "components_new/lib/Messages";

const createMessage = (companyName) => {
  return (
    <Fragment>
      Are you sure you want to delete this deal for{" "}
      <strong> {companyName} </strong> and any associated documents?
    </Fragment>
  );
};

function RemoveDealWrapper({ pipelineId, dealId, callback }) {
  const [submitting, setSubmitting] = useState(false);

  const { data, isLoading } = useDeal(pipelineId, dealId);
  const { mutateAsync: removeDeal } = useRemoveDeal();

  const dispatch = useDispatch();
  const { addToast } = useSetToast();

  const handleDeleteDeal = async () => {
    setSubmitting(true);
    callback?.();

    await removeDeal(data?.deal);
    dispatch(closeModal());
    setSubmitting(false);
    addToast(getMessage("deleteDeal"));
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ConfirmationModal
      title="Delete Deal"
      message={createMessage(data?.company.name)}
      confirmButtonLabel="Delete Deal"
      cancelButtonLabel="Cancel"
      primaryButtonClassnames="Button--warning"
      submitting={submitting}
      closeModal={() => dispatch(closeModal())}
      action={handleDeleteDeal}
    />
  );
}

export default RemoveDealWrapper;
