import React, { Fragment } from "react";
import { displayDateFormat } from "config/dateInput";
import TextInput from "components/shared/form/TextInput";
import PriceInput from "components/shared/form/PriceInput";
import DatePicker from "components_new/atoms/DatePicker";
import NumberInput from "components/shared/form/NumberInput";
import HelperContent from "components/shared/HelperContent";
import formatNumber from "lib/formatters/formatNumber";

function LogAcquisitionForm({ formData, updateForm, errors, historyAction }) {
  const {
    date_helper_text: dateHelperContent,
    quantity_helper_text: numberHelperContent,
    is_terminal_action: isTerminalAction,
  } = historyAction;
  return (
    <Fragment>
      <DatePicker
        name="date"
        label="Date of company acquisition"
        placeholder={displayDateFormat}
        onChange={(value, validated) => {
          updateForm("date", value, validated);
        }}
        value={formData.date}
        extraClassnames="FormElement FormElement--Date"
        error={!!errors.for("date")}
        errorMessage={errors.for("date")}
        helperContent={() => <HelperContent content={dateHelperContent} />}
        showHelper={!isTerminalAction && !!dateHelperContent}
        size="small"
        oldVersion
      />
      <TextInput
        label="Acquiring company"
        placeholder="Select a company"
        inputPlaceholder="Acquiring Company"
        name="company"
        value={formData.acquiring_company_name}
        errorMessage={errors.for("acquiring_company_id")}
        readOnly
        showLock
        size="small"
      />
      <PriceInput
        name="amount"
        label="Cash proceeds amount"
        placeholder={
          formData.cash_proceed
            ? formatNumber(formData.cash_proceed, 2)
            : "0.00"
        }
        onChange={(value) => updateForm("cash_proceed", value)}
        value={formData.cash_proceed}
        errorMessage={errors.for("cash_proceed")}
        size="small"
      />
      <NumberInput
        name="quantity"
        label="Number of shares"
        placeholder="0"
        onChange={(quantity) => updateForm("quantity", quantity)}
        errorMessage={errors.for("quantity")}
        helperContent={() => <HelperContent content={numberHelperContent} />}
        errorDisplayValidation={() => !numberHelperContent}
        showHelper={!isTerminalAction && !!numberHelperContent}
        readOnly={isTerminalAction}
        showLock={isTerminalAction}
        value={formData.quantity}
        size="small"
      />
    </Fragment>
  );
}

export default LogAcquisitionForm;
