import React, { useCallback } from "react";
import Icon from "components_new/atoms/Icon";
import Button from "components_new/atoms/Button";
import { useParams, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./CreateSnapshotButton.scss";

function CreateSnapshotButton({
  className,
  kind,
  snapshotType = "deal",
  buttonText,
  recipientCompanyId,
  ...props
}) {
  const { organizationUsername } = useParams();
  const navigate = useNavigate();

  const handleCreateSnapshot = useCallback(() => {
    const baseParam = `?type=${snapshotType}`;
    const search = recipientCompanyId
      ? `${baseParam}&recipient_company_id=${recipientCompanyId}`
      : baseParam;

    navigate({
      pathname: `/${organizationUsername}/reports/snapshots/new`,
      search,
    });
  }, [navigate, organizationUsername, recipientCompanyId, snapshotType]);

  const createSnapshotButtonClassnames = classnames(
    "CreateSnapshotButton icon",
    {
      [className]: !!className,
    },
  );

  const caption = buttonText ? buttonText : `New ${snapshotType} snapshot`;
  return (
    <Button
      className={createSnapshotButtonClassnames}
      onClick={handleCreateSnapshot}
      primary={kind === "primary"}
      secondary={kind === "secondary"}
      {...props}
    >
      <Icon icon="ri-add-line" size="small" />
      <span className="caption">{caption}</span>
    </Button>
  );
}

CreateSnapshotButton.prototypes = {
  className: PropTypes.string,
  kind: PropTypes.string,
};

export default CreateSnapshotButton;
