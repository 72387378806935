import React from "react";
import PropTypes from "prop-types";
import OptionalLabel from "components/shared/OptionalLabel";

function FormLabel({ label, id, isOptional }) {
  return (
    <label className="FormElement-label" htmlFor={id}>
      <span
        className="FormElement-labelHolder u-textWithLineBreaks"
        data-testid="FieldMessage"
      >
        {label} {isOptional && <OptionalLabel />}
      </span>
    </label>
  );
}

FormLabel.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isOptional: PropTypes.bool,
};

FormLabel.defaultProps = {
  isOptional: false,
};

export default FormLabel;
