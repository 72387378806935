import React from "react";
import { render } from "react-dom";
import Mighty from "components/Mighty";
import ModalDialog from "components_new/elements/ModalDialog";

export default function renderApplication(store) {
  const rootElement = document.getElementById("root");
  const renderMighty = (MightyComponent) =>
    render(<MightyComponent store={store} />, rootElement);

  ModalDialog.setup({ rootElement });
  renderMighty(Mighty);

  if (module.hot) {
    module.hot.accept("components/Mighty/index.js", () => {
      const NextMighty = require("components/Mighty/index.js").default;
      renderMighty(NextMighty);
    });
  }
}
