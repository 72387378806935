import React from "react";
import EmptyPage from "components_new/elements/EmptyPage";
import { isEmpty } from "lodash";
import {
  SortedTable,
  TableHeader,
  TableRow,
  SortableTableHeaderCell,
  TableBody,
} from "components_new/elements/Table";
import CashFlowRow from "./CashFlowRow";
import "./CashFlowTable.scss";
import { defaultOrderByDate } from "components_new/views/Portfolios/config";
import getColumnHeaders from "../shared/getColumnHeaders";

export default function CashFlowTable({
  data,
  onSelection,
  isLoading,
  isEmptyView,
}) {
  if (isEmptyView) {
    return <EmptyPage headline="You haven’t registered any cash events yet." />;
  }

  const columnHeaders = getColumnHeaders("cashFlowTable");
  const renderHeaders = (handleSort, isSorted) => {
    return (
      <TableHeader>
        <TableRow>
          {columnHeaders.map((columnHeader, index) => {
            return (
              <SortableTableHeaderCell
                key={index}
                isGrouped={false}
                isSorted={isSorted}
                handleSort={handleSort}
                {...columnHeader}
              >
                {columnHeader.title}
              </SortableTableHeaderCell>
            );
          })}
        </TableRow>
      </TableHeader>
    );
  };
  return (
    <SortedTable
      tableData={isEmpty(data) ? [] : data}
      className="CashFlowTable HoldingsTable attached"
      onSelection={onSelection}
      defaultOrder={defaultOrderByDate}
      isLoading={isLoading}
      renderHeaders={renderHeaders}
      skeletonStructure={{ rows: 8, headers: columnHeaders }}
      shadow={false}
    >
      {({ data }) => (
        <TableBody isEmptyData={isEmpty(data)}>
          {data &&
            data.map((data, index) => (
              <CashFlowRow key={index} cashFlow={data} />
            ))}
        </TableBody>
      )}
    </SortedTable>
  );
}
