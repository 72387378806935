import React, { useMemo, Fragment } from "react";
import { TableRow, TableCell } from "components_new/elements/Table";
import CompanyInfo from "components_new/elements/CompanyInfo";
import Link from "components_new/atoms/Link";
import CompanyLinkValue from "components_new/views/Company/shared/CompanyLinkValue";
import BadgeIcon from "components_new/atoms/BadgeIcon";
import CompanyFlags from "components_new/views/CompanyDirectory/shared/CompanyFlags";

export const WEBSITE_PREFILL = "http://";
const MAX_CONTACT_TO_SHOW = 3;

function CompanyContacts({ contacts }) {
  const extraContacts = contacts.length - MAX_CONTACT_TO_SHOW;

  const contactsToshow = useMemo(() => {
    const firstElements = contacts.slice(0, MAX_CONTACT_TO_SHOW);

    return firstElements.map((contact) => contact.full_name).join(", ");
  }, [contacts]);

  return (
    <Fragment>
      <span>{contactsToshow}</span>
      {extraContacts > 0 && (
        <span className="CompanyContacts">, {`and ${extraContacts} more`}</span>
      )}
    </Fragment>
  );
}

function CompaniesRow({
  company,
  organizationUsername,
  handleOpenEditCompanyForm,
}) {
  const { location, status } = company;
  const statusInfo = status === "Active" ? "" : `[${status.toUpperCase()}]`;
  const companyDetailUrl = `/${organizationUsername}/companies/${company.id}`;
  const companyName = (
    <span>
      {company.name}
      {company.verified && <BadgeIcon size="small" color="primary" />}
    </span>
  );

  return (
    <TableRow>
      <TableCell className="CompanyNameColumn">
        <CompanyInfo
          handleEdit={handleOpenEditCompanyForm}
          title={() => (
            <div className="CompanyNameTitle">
              <Link
                to={companyDetailUrl}
                extraClassnames="CompanyNameTitle-link"
              >
                {companyName}
                <span className="CompanyNameTitle-status">{statusInfo}</span>
              </Link>
              {company.website && (
                <CompanyLinkValue
                  url={company.website}
                  label={company.website}
                  preFilled={WEBSITE_PREFILL}
                />
              )}
            </div>
          )}
          content={(company) => <CompanyFlags company={company} />}
          company={company}
          tier={company.tier}
        />
      </TableCell>
      <TableCell>{location}</TableCell>
      <TableCell className="CompanyTagsColumn">
        {company.tags.join(", ")}
      </TableCell>
      <TableCell className="CompanyTagsColumn">
        {company.firm_type.join(", ")}
      </TableCell>
      <TableCell className="CompanyTagsColumn">
        {company.service_domain.join(", ")}
      </TableCell>
      <TableCell className="left aligned">
        <CompanyContacts contacts={company.companies_contacts} />
      </TableCell>
    </TableRow>
  );
}

export default CompaniesRow;
