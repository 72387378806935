import React, { useRef } from "react";
import PropTypes from "prop-types";
import DropHandler from "components/shared/DropHandler";
import Icon from "components_new/atoms/Icon";
import { Segment, Grid, Header } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/segment.css";
import "semantic-ui-css/components/icon.css";
import "semantic-ui-css/components/header.css";
import "semantic-ui-css/components/dimmer.css";
import "./FileUploader.scss";

const getFilesFromEvent = (event) => {
  let notSupportingDragEvent = false;
  let userDragging = false;
  try {
    userDragging = event.type === "drop" || event instanceof DragEvent;
  } catch (error) {
    if (error instanceof ReferenceError) {
      notSupportingDragEvent = true;
    }
  }
  if (notSupportingDragEvent) {
    return event.target.files;
  } else {
    return userDragging ? event.dataTransfer.files : event.target.files;
  }
};

function FileUploader({ onSelectFile, attached, disabled, size = "large" }) {
  const inputRef = useRef();

  const handleClick = () => {
    inputRef.current.value = null;
    inputRef.current.click();
  };

  const handleChange = (event) => {
    const files = getFilesFromEvent(event);
    if (onSelectFile) {
      onSelectFile(files);
    }
  };

  return (
    <DropHandler onDrop={handleChange} disabled={disabled}>
      <Segment
        className="FileUploader"
        onClick={handleClick}
        attached={attached}
      >
        <Grid columns={1}>
          <Grid.Column>
            <Header size="tiny">
              <input
                type="file"
                ref={inputRef}
                onChange={handleChange}
                className="FileUploader-inputFile"
                disabled={disabled}
              />
              <Icon
                icon={disabled ? "ri-lock-2-line" : "ri-attachment-2"}
                size={size}
              />
              <Header.Content>
                <div className="FileUploader-title">Attach Files</div>
                {disabled ? (
                  <Header.Subheader>
                    Please select a company, deal, or holding first
                  </Header.Subheader>
                ) : (
                  <Header.Subheader>
                    Drop one or more files, or <a>browse for files</a> to upload
                  </Header.Subheader>
                )}
              </Header.Content>
            </Header>
          </Grid.Column>
        </Grid>
      </Segment>
    </DropHandler>
  );
}

FileUploader.propTypes = {
  attached: PropTypes.oneOfType([
    PropTypes.oneOf(["top", "bottom"]),
    PropTypes.bool,
  ]),
  onSelectFile: PropTypes.func,
  disabled: PropTypes.bool,
  size: PropTypes.string,
};

FileUploader.defaultProps = {
  attached: false,
  disabled: false,
};

export default FileUploader;
