import React, { useContext, useEffect } from "react";
import AddContactForm from "./AddContactForm";
import { SidebarComponentsContext } from "components_new/SidebarComponents/SidebarComponentsWrapper";
import { isEmpty } from "lodash";
import useContacts from "store/contacts/hooks";
import useOrganization from "store/organizations/hooks";

const createContact = () => {
  alert("creating contact");
};

function AddContactFormWrapper({ ownName }) {
  const {
    getComponentState,
    registerForm,
    updateSidebarComponent,
    closeSidebar,
    onUnmount,
  } = useContext(SidebarComponentsContext);

  const { currentOrganization } = useOrganization();
  const { componentData, submitTo, updatePrimaryForm } = getComponentState(
    ownName,
  );

  const { validateContact } = useContacts();

  useEffect(() => {
    if (!isEmpty(ownName)) {
      registerForm(ownName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AddContactForm
      ownName={ownName}
      componentData={componentData}
      submitTo={submitTo}
      updateSidebarComponent={updateSidebarComponent}
      closeSidebar={closeSidebar}
      createContact={createContact}
      updatePrimaryForm={updatePrimaryForm}
      onUnmount={onUnmount}
      validateContact={validateContact}
      prepopulatedContactTags={currentOrganization.prepopulated_contact_tags}
      prepopulatedSectors={currentOrganization.prepopulated_sectors}
    />
  );
}

export default AddContactFormWrapper;
