import React from "react";
import PropTypes from "prop-types";
import { customFieldProptype } from "PropTypes";
import Select from "components/shared/form/Select";
import PreviewComponent from "components/shared/PreviewComponent";
import TextInput from "components/shared/form/TextInput";
import CheckBox from "components/shared/form/CheckBox";
import SecondaryButton from "components/shared/SecondaryButton";
import PrimaryButton from "components/shared/PrimaryButton";
import MutipleChoiceListMaker from "components/shared/MutipleChoiceListMaker";
import useForm from "lib/hooks/useForm";
import {
  componentSelectInputProps,
  manageCustomFieldProps,
  componentType,
  resourceTypes,
  fieldDataForChoiceFields,
} from "./config";
import { cloneDeep } from "lodash";
import pluralize from "pluralize";

function CustomFieldForm({
  data,
  onCancel,
  onSubmit,
  isSubmitting,
  defaultComponentType,
  isEditing,
}) {
  const initialValues = {
    formData: {
      field_label: data.field_label || "",
      helper_text: data.helper_text || "",
      field_type: data.field_type,
      resource_types: data.resource_types,
      field_data: data.field_data,
    },
  };
  const submit = (clientData) =>
    onSubmit(clientData).catch((error) => handleErrors(error));
  const {
    formData,
    handleChange,
    handleSubmit,
    handleErrors,
    errors,
  } = useForm(cloneDeep(initialValues), submit);

  const Component = componentType[formData.field_type || defaultComponentType];

  const isAvailableToSubmit = formData.field_type && formData.field_label;

  function updateInvestments(value) {
    let newInvestments = formData.resource_types.slice(0);
    if (newInvestments.includes(value)) {
      newInvestments = newInvestments.filter((type) => type !== value);
    } else {
      newInvestments.push(value);
    }
    handleChange("resource_types", newInvestments);
  }

  return (
    <form onSubmit={handleSubmit} className="CustomFieldForm" role="form">
      <div className="CustomFieldForm-form">
        <PreviewComponent
          extraClassnames="FormElement"
          componentProps={manageCustomFieldProps(formData.field_type, {
            label: formData.field_label,
            textHelper: formData.helper_text,
            inputProps: formData.field_data,
            onChange: null,
          })}
        >
          <Component />
        </PreviewComponent>
        <Select
          label="Field type"
          placeholder="Select a field type"
          inputProps={componentSelectInputProps}
          onChange={(value) => {
            handleChange("field_data", fieldDataForChoiceFields(value));
            handleChange("field_type", value);
          }}
          value={formData.field_type}
          errorMessage={errors.for("field_type")}
          readOnly={isEditing}
          size="small"
        />
        <TextInput
          label="Field Label"
          size="small"
          placeholder="Placeholder"
          onChange={(value) => handleChange("field_label", value)}
          errorMessage={errors.for("field_label")}
          value={formData.field_label}
        />
        <TextInput
          label="Helper Text"
          onChange={(value) => handleChange("helper_text", value)}
          errorMessage={errors.for("helper_text")}
          value={formData.helper_text}
          isOptional={true}
          size="small"
        />
        {formData.field_type === "multiple_choice" && (
          <MutipleChoiceListMaker
            values={formData.field_data}
            permanentItemsCount={2}
            name="field_data"
            type="checkBox"
            onChange={(name, value) => {
              handleChange(name, value);
            }}
          />
        )}
        {formData.field_type === "single_choice" && (
          <MutipleChoiceListMaker
            values={formData.field_data}
            permanentItemsCount={2}
            name="field_data"
            type="radioButton"
            onChange={(name, value) => {
              handleChange(name, value);
            }}
          />
        )}
      </div>
      <div className="CustomFieldForm-resourceTypes">
        <h3 className="u-formElementLabelSmall">Include for these types:</h3>
        {resourceTypes.map((type, id) => (
          <CheckBox
            key={id}
            label={type == "Deal" ? pluralize(type) : type}
            name={type.toLowerCase()}
            onChange={(value) => updateInvestments(value)}
            value={formData.resource_types.includes(type.toLowerCase())}
          />
        ))}
      </div>
      <div className="CustomFieldForm-actions">
        <PrimaryButton
          size="small"
          type="submit"
          disabled={!isAvailableToSubmit || isSubmitting}
          filter={{
            applyingFilter: isSubmitting,
          }}
        >
          Save Changes
        </PrimaryButton>
        <SecondaryButton size="small" onClick={() => onCancel()}>
          Cancel
        </SecondaryButton>
      </div>
    </form>
  );
}

CustomFieldForm.defaultProps = {
  defaultComponentType: "text",
  isEditing: false,
};

CustomFieldForm.propTypes = {
  data: customFieldProptype,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  defaultComponentType: PropTypes.string.isRequired,
};

export default CustomFieldForm;
