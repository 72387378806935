import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import ValuationHistoryChart from "./ValuationHistoryChart";
import ValuationHistoryTable from "./ValuationHistoryTable";
import { bulkUpdateValuations } from "store/investments/v2/valuations/actions";
import { investmentPropType, valuationProptype } from "PropTypes";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import getMessage from "components_new/lib/Messages";
import { openSidebar } from "store/sidebar";

function ValuationHistoryContainer({ collection, investment, isFetching }) {
  const { id: investmentId } = investment;
  const dispatch = useDispatch();
  const { addToast } = useSetToast();

  const bulkUpdate = (payload) => {
    return dispatch(bulkUpdateValuations(investmentId, payload)).then(
      (data) => {
        addToast(getMessage("updateValuation"));
        return data;
      },
    );
  };

  const openValuationSidebarForm = () => {
    return dispatch(
      openSidebar("valuationForm", "Add Valuation", {
        initialHoldingId: investmentId,
      }),
    );
  };
  return (
    <div className="ValuationTable">
      <ValuationHistoryChart collection={collection} investment={investment} />
      <ValuationHistoryTable
        collection={collection}
        investment={investment}
        isFetching={isFetching}
        bulkUpdate={bulkUpdate}
        openValuationSidebarForm={openValuationSidebarForm}
      />
    </div>
  );
}

ValuationHistoryContainer.propTypes = {
  collection: PropTypes.arrayOf(valuationProptype),
  investment: investmentPropType.isRequired,
  isFetching: PropTypes.bool,
};

export default ValuationHistoryContainer;
