import React, { Fragment, useCallback } from "react";
import ConfirmationModal from "components/shared/ConfirmationModal";
import useOrganizations from "store/organizations/hooks";
import pluralize from "pluralize";
import useModal from "store/modalV2/hooks";
import { useQueryClient } from "react-query";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import getMessage from "components_new/lib/Messages";
import { useDeleteContact } from "components_new/views/Contacts/hooks/mutations/useDeleteContact";
// import { SidebarComponentsContext } from "components_new/SidebarComponents/SidebarComponentsWrapper";

function ConfirmDeleteContactWrapper({ contact, callback }) {
  const { currentOrganizationName } = useOrganizations();
  const { closeModal } = useModal();
  // We cannot use context from the sidebar if we are not in the sidebar :blow-mind: That's not okay we can use it in any place.
  // We need to refactor this please
  // const { closeSidebar } = useContext(SidebarComponentsContext);

  const {
    deal_referral_count,
    investment_referral_count,
    assigned_introductions_count,
  } = contact;

  const investmentsCount = investment_referral_count
    ? investment_referral_count
    : 0;
  const dealsCount = deal_referral_count ? deal_referral_count : 0;
  const introductionsCount = assigned_introductions_count
    ? assigned_introductions_count
    : 0;
  const contactName = contact.full_name;

  const message = (
    <Fragment>
      <p>
        Are you sure you want to delete the contact{" "}
        <strong>{contactName}</strong>?
      </p>
      <p>
        This will permanently remove any files and notes associated with{" "}
        <strong>{contactName}</strong> from the{" "}
        <strong>{currentOrganizationName}</strong> organization.
      </p>
      <p>
        <strong>{contactName} </strong> has referred{" "}
        {pluralize("investment", investmentsCount, true)},{" "}
        {pluralize("deal", dealsCount, true)}, which will <strong>not</strong>{" "}
        be deleted, and{" "}
        {` ${pluralize("introduction", introductionsCount, true)}`}, which will
        be deleted.
      </p>
    </Fragment>
  );

  const queryClient = useQueryClient();
  const { mutate: deleteContact, isLoading } = useDeleteContact();

  const { addToast } = useSetToast();
  const handleDeleteContact = useCallback(() => {
    deleteContact(contact.id, {
      onSuccess: () => {
        callback?.();
        queryClient.invalidateQueries(["ContactsDirectory"]);
        addToast(getMessage("deleteReferrer"));
        closeModal();
      },
    });
  }, [addToast, closeModal, contact.id, deleteContact, queryClient, callback]);

  return (
    <ConfirmationModal
      title="Delete Contact"
      message={message}
      confirmButtonLabel="Delete Contact"
      cancelButtonLabel="Cancel"
      primaryButtonClassnames="Button--warning"
      closeModal={closeModal}
      action={handleDeleteContact}
      submitting={isLoading}
    />
  );
}

export default ConfirmDeleteContactWrapper;
