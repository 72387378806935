import React from "react";
import LogoReceptor from "lib/LogoReceptor";
import { uploadToFilestack } from "lib/fileStackUtils";
import PropTypes from "prop-types";
import classNames from "classnames";

class AvatarUploader extends React.Component {
  state = {
    isUploading: false,
    imgBase64: null,
  };

  openFileSelector = () => {
    if (!this.props.disabled) {
      this.logopicker.click();
    }
  };

  isFileTooBig(file) {
    return file.size > this.props.maxSize;
  }

  imageDimension(file) {
    const _URL = window.URL || window.webkitURL;
    const image = new Image();
    image.src = _URL.createObjectURL(file);
    return new Promise((resolve, reject) => {
      image.addEventListener("load", () =>
        resolve({ width: image.width, height: image.height }),
      );
      image.addEventListener("error", (error) => reject(error));
    });
  }

  validateImage = (file) => {
    const { minImageDimension } = this.props;
    return new Promise((resolve) => {
      this.imageDimension(file).then((dimension) => {
        resolve(
          dimension.width >= minImageDimension &&
            dimension.height >= minImageDimension &&
            !this.isFileTooBig(file) &&
            LogoReceptor.isValidTypeWithoutGif(file.type),
        );
      });
    });
  };

  handleImageToBase64 = (file) => {
    this.fileToBase64(file).then((data64) => {
      this.setState({
        isUploading: false,
        imgBase64: data64,
      });
    });
  };

  fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener("load", () => resolve(reader.result));
      reader.addEventListener("error", (error) => reject(error));
    });
  };

  uploadFile = (event) => {
    const {
      returnBase64,
      onInvalidImageUploaded,
      onImageUploaded,
    } = this.props;
    const file = event.target.files[0];
    const uploader = this.logopicker;
    this.setState({ isUploading: true });
    this.validateImage(file).then((isValid) => {
      if (isValid) {
        uploadToFilestack(file, {}, {}, this.token).then((uploadedFile) => {
          uploader.value = "";
          onImageUploaded(uploadedFile);
          if (returnBase64) {
            this.handleImageToBase64(file);
          } else {
            this.setState({ isUploading: false });
          }
        });
      } else {
        uploader.value = "";
        onInvalidImageUploaded();
        this.setState({ isUploading: false });
      }
    });
  };

  render() {
    const { disabled, logo_url, extraClassnames, renderComponent } = this.props;
    const avatarUploaderClassName = classNames("AvatarUploader", {
      [extraClassnames]: !!extraClassnames,
    });
    const { isUploading, imgBase64 } = this.state;
    return (
      <div className={avatarUploaderClassName}>
        {renderComponent({
          isUploading,
          logo_url,
          imgBase64,
          openFileSelector: this.openFileSelector,
          disabled,
        })}
        {!disabled && (
          <input
            type="file"
            ref={(logopicker) => (this.logopicker = logopicker)}
            className="hidden"
            accept="image/*"
            onChange={this.uploadFile}
          />
        )}
      </div>
    );
  }
}

AvatarUploader.propTypes = {
  disabled: PropTypes.bool,
  maxSize: PropTypes.number,
  extraClassnames: PropTypes.string,
  onInvalidImageUploaded: PropTypes.func,
  returnBase64: PropTypes.bool,
  onImageUploaded: PropTypes.func,
  logo_url: PropTypes.string,
  renderComponent: PropTypes.func,
  minImageDimension: PropTypes.number,
};

AvatarUploader.defaultProps = {
  returnBase64: false,
  minImageDimension: 125,
};

export default AvatarUploader;
