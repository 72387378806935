import React from "react";
import moment from "moment";
import { isEmpty } from "lodash";
import CashIcon from "./CashIcon";
import AttachmentIcon from "-!svg-react-loader?!assets/icons/utility/attachment.svg?name=AttachmentIcon";
import InfoIcon from "-!svg-react-loader?!assets/icons/utility/info.svg?name=InfoIcon";
import TooltipHolder from "components/shared/TooltipHolder";
import formatDate from "lib/formatDate";
import {
  placeholderDateFormatLowerCase,
  valueDateFormat,
} from "config/dateInput";
import {
  perUnitNumberPrecision,
  defaultNumberPrecision,
} from "config/priceInput";
import DatePicker from "components_new/atoms/DatePicker";
import PriceInput from "components/shared/form/PriceInput";
import TextInput from "components/shared/form/TextInput";
import HoldingCellPreview from "components/shared/HoldingCellPreview";
import { formatPriceInputPreview } from "lib/abbreviatedFormat";
import formatNumber from "lib/formatters/formatNumber";

export function initialStateData() {
  return {
    date: moment().format(valueDateFormat),
    description: "",
    documents_data: [],
    amount: "",
    id: null,
  };
}

export const emptyDistributionItem = {
  date: null,
  description: "- - -",
  documents_data: [],
  amount: null,
  id: null,
  investment: {
    previous_holding: false,
  },
};

export function canLockItem(item) {
  return item.investment.previous_holding || item.is_equity_proceed;
}

export function canLockButton(item) {
  return item.investment.previous_holding;
}

function cashNotesContent({ notes }) {
  return (
    <div className="distributionRealizedGainInfo">
      <TooltipHolder tooltip={notes} position="top">
        <InfoIcon className="InfoIcon" />
      </TooltipHolder>
    </div>
  );
}

function AttachmentContent(
  title,
  investmentId,
  documents,
  fetchAfterUpdateDocuments,
  itemId,
  openDocumentsViewerModal,
) {
  return (
    <div className="distributionDocuments">
      <TooltipHolder tooltip="View files" position="top">
        <AttachmentIcon
          className="DocumentsViewer-icon"
          onClick={() =>
            openDocumentsViewerModal({
              title,
              investmentId,
              documents,
              fetchAfterUpdateDocuments,
              itemId,
            })
          }
        />
      </TooltipHolder>
    </div>
  );
}

const emptyTableValue = "- - -";

function renderDistributionValue(
  item,
  companies,
  fetchAfterUpdateDocuments,
  openDocumentsViewerModal,
  organizationUsername,
) {
  if (item.is_equity_proceed) {
    return (
      <div className="DistributionRow--descriptionContent">
        <HoldingCellPreview
          holding={item.equity}
          companies={companies}
          organizationUsername={organizationUsername}
        />
        {!isEmpty(item.documents_data) &&
          AttachmentContent(
            item.equity.name,
            item.investment.id,
            item.documents_data,
            fetchAfterUpdateDocuments,
            item.id,
            openDocumentsViewerModal,
          )}
      </div>
    );
  } else {
    const columnName = "Realized Gain";
    return (
      <div className="DistributionRow--descriptionContent">
        <CashIcon />
        <div className="DistributionRow--descriptionText">{columnName}</div>
        {item.notes && cashNotesContent(item)}
        {!isEmpty(item.documents_data) &&
          AttachmentContent(
            columnName,
            item.investment.id,
            item.documents_data,
            fetchAfterUpdateDocuments,
            item.id,
            openDocumentsViewerModal,
          )}
      </div>
    );
  }
}

export function createDistributionColumns(
  companies,
  fetchAfterUpdateDocuments,
  openDocumentsViewerModal,
  organizationUsername,
) {
  const columns = [
    {
      text: "Date",
      classNames: "DistributionRow--date",
      alignment: "u-leftAlignedText ",
      renderValue: (item) =>
        item.date ? formatDate(item.date, "ll") : emptyTableValue,
      renderForm: (item, updateForm, index, errors) => (
        <DatePicker
          name="date"
          placeholder={placeholderDateFormatLowerCase}
          value={formatDate(item.date)}
          onChange={(value) => {
            updateForm("date", value, index, item.id);
          }}
          errorMessage={errors.for("date")}
          readOnly={!!item.is_equity_proceed}
          size="small"
          oldVersion
        />
      ),
    },
    {
      text: "Distribution",
      classNames: "DistributionRow--description",
      alignment: "u-leftAlignedText ",
      renderValue: (item) =>
        item.proceed_amount || item.cash_amount
          ? renderDistributionValue(
              item,
              companies,
              fetchAfterUpdateDocuments,
              openDocumentsViewerModal,
              organizationUsername,
            )
          : emptyTableValue,
      renderForm: (item, updateForm, index, errors) => (
        <TextInput
          value={item.is_equity_proceed ? item.equity.name : "Realized Gain"}
          size="small"
          readOnly
          errorMessage={errors.for("name")}
        />
      ),
    },
    {
      text: "Quantity",
      classNames: "DistributionRow--quantity",
      alignment: "u-rightAlignedText",
      renderValue: (item) =>
        item.is_equity_proceed ? (
          <span className="u-smallBoldDark">
            {formatNumber(item.equity.quantity)}
            <div className="perShareValue">shares</div>
          </span>
        ) : (
          <span className="u-smallRegularLight">N/A</span>
        ),
      renderForm: (item, updateForm, index, errors) => (
        <TextInput
          value={
            item.is_equity_proceed ? formatNumber(item.equity.quantity) : "N/A"
          }
          alignTo="right"
          errorMessage={errors.for("quantity")}
          readOnly
          size="small"
        />
      ),
    },
    {
      text: "Distribution Proceeds",
      classNames: "DistributionRow--proceeds",
      alignment: "u-rightAlignedText",
      renderValue: (item) => (
        <span>
          {item.proceed_amount || item.cash_amount
            ? formatPriceInputPreview(item.proceed_amount || item.cash_amount)
            : "$ - -"}
          {item.is_equity_proceed && (
            <div className="perShareValue">
              {formatPriceInputPreview(item.equity.unit_valuation_amount, {
                precision: perUnitNumberPrecision,
              })}
              {" per share"}
            </div>
          )}
        </span>
      ),
      renderForm: (item, updateForm, index, errors) => (
        <PriceInput
          name="cash_amount"
          onChange={(value) => {
            updateForm("cash_amount", value, index, item.id);
          }}
          value={
            item.is_equity_proceed
              ? item.equity.total_valuation_amount
              : item.cash_amount
          }
          errorMessage={errors.for("cash_amount")}
          precision={defaultNumberPrecision}
          alignTo="right"
          readOnly={!!item.is_equity_proceed}
          size="small"
        />
      ),
    },
  ];

  return columns;
}
