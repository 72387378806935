import React from "react";
import PropTypes from "prop-types";
import { investmentPropType, valuationProptype } from "PropTypes";
import Table from "components/shared/Table";
import ValuationRowTemplate from "./ValuationRowTemplate";
import {
  valuationEmptyData,
  createValuationsColumns,
  canLockItem,
} from "./config";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import getMessage from "components_new/lib/Messages";

function ValuationHistoryTable({
  investment,
  collection,
  isUpdating,
  isFetching,
  bulkUpdate,
  isOnModal,
  submitPortalSelector,
  viewMoreTooltipContent,
  openValuationSidebarForm,
}) {
  const isEditButtonDisabled =
    !isFetching &&
    !collection.some((valuation) => {
      return (
        (valuation.id != investment.initial_valuation_id &&
          !valuation.historical) ||
        valuation.historical
      );
    });
  const columns = isFetching ? [] : createValuationsColumns(investment);
  const areActionsDisabled = investment ? investment.previous_holding : true;
  const { addToast } = useSetToast();
  return (
    <Table
      columns={columns}
      submitPortalSelector={submitPortalSelector}
      title="Valuation History"
      deleteTitle="Delete this valuation update?"
      collection={collection}
      investment={investment}
      isFetching={isFetching}
      isUpdating={isUpdating}
      canLockItem={canLockItem}
      bulkUpdate={bulkUpdate}
      isOnModal={isOnModal}
      onCreate={() =>
        openValuationSidebarForm({ initialHoldingId: investment.id })
      }
      rowInitialState={valuationEmptyData()}
      isEditButtonDisabled={isEditButtonDisabled}
      editButtonDisabledTooltipText="No editable valuations"
      disabledActionsTooltipText="Valuations for previous investments cannot be modified"
      viewMoreTooltipContent={viewMoreTooltipContent}
      disabledActions={areActionsDisabled}
      saveChangesButtonExtraClassnames="js-ValuationTableSaveChangesSubmit"
      updateButtonText="Add Valuation"
      renderRow={(childrenProps) => (
        <ValuationRowTemplate investment={investment} {...childrenProps} />
      )}
      onDeleteRow={() => addToast(getMessage("deleteValuation"))}
    />
  );
}

ValuationHistoryTable.propTypes = {
  investment: investmentPropType,
  collection: PropTypes.arrayOf(valuationProptype),
  bulkUpdate: PropTypes.func,
  isFetching: PropTypes.bool,
  isOnModal: PropTypes.bool,
  isUpdating: PropTypes.bool,
  submitPortalSelector: PropTypes.string,
  viewMoreTooltipContent: PropTypes.string,
  openValuationSidebarForm: PropTypes.func,
};

ValuationHistoryTable.defaultProps = {
  isFetching: false,
  isUpdating: false,
  isOnModal: false,
};

export default ValuationHistoryTable;
