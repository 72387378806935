import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import Api from "lib/Api";

const buildParams = (filters) => {
  const params = {};

  if (!isEmpty(filters.query)) params.query = filters.query;
  if (filters.showAll) params.show_all = filters.showAll;

  return params;
};

export function useTasks({ scope, filters = {} }) {
  const { organizationUsername } = useParams();
  const params = buildParams(filters);

  return useQuery(
    ["QueuesView", organizationUsername, scope, filters],
    async () => {
      const { data: tasks } = await Api.get(`/tasks/${scope}`, { params });

      return {
        tasks,
      };
    },
    {
      staleTime: 2000,
    },
  );
}
