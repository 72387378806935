import Api from "lib/Api";
import { createCompany } from "store/companies/company/actions";
import { receiveCollections } from "store/entities";
import { receiveView } from "store/views";

export function updateCertificateHistory(
  investmentId,
  certificateId,
  formData,
) {
  return (dispatch) => {
    const companyPromise =
      formData.acquiring_company_id === null
        ? dispatch(createCompany(formData.newCompanyName))
        : Promise.resolve();

    return companyPromise.then((companyId) => {
      if (formData.acquiring_company_id === null) {
        formData.acquiring_company_id = companyId;
        formData.acquiring_company_name = formData.newCompanyName;
      }
      const payload = {
        id: certificateId,
        update_params: formData,
      };

      return Api.patch(
        `/investments/${investmentId}/history_actions/${certificateId}`,
        { history_action_params: payload },
      ).then(
        ({
          data: {
            entities,
            views: { investment: view },
          },
        }) => {
          dispatch(receiveCollections(entities));
          dispatch(receiveView("investment", view));
        },
      );
    });
  };
}

export function deleteCertificateHistory(investmentId, certificateId) {
  return () => {
    return Api.delete(
      `/investments/${investmentId}/history_actions/${certificateId}`,
    );
  };
}
