import { connect } from "react-redux";
import InvalidInvitationView from "./InvalidInvitationView";
import { cleanInvitation } from "store/invitations/organization/actions";

function mapDispatchToProps(dispatch) {
  return {
    cleanInvitation: () => dispatch(cleanInvitation()),
  };
}

export default connect(null, mapDispatchToProps)(InvalidInvitationView);
