import React, { useState, useRef, useEffect, forwardRef } from "react";
import PropTypes from "prop-types";
import accounting from "accounting";
import classNames from "classnames";
import FormElement from "components/shared/form/FormElement";
import { decimalPropType } from "PropTypes";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import MaskedInput from "react-text-mask";
import useInputId from "lib/hooks/useInputId";

function useForwardedRef(ref) {
  const innerRef = useRef();

  useEffect(() => {
    if (ref) {
      if (typeof ref === "function") {
        ref(innerRef.current);
      } else {
        innerRef.current = ref.current;
      }
    }
  });

  return innerRef;
}

const NumberInput = forwardRef((props, ref) => {
  const inputRef = useForwardedRef(ref);

  const {
    disabled,
    errorDisplayValidation,
    errorMessage,
    extraClassnames,
    isOptional,
    label,
    maxLength,
    name,
    numberSymbol,
    placeholder,
    required,
    typeOfInput,
    size,
    alignTo,
    readOnly,
    showLock,
    showHelper,
    textHelper,
    helperContent,
    allowDecimal,
    decimalLimit,
    autoFocus,
    includeThousandsSeparator,
    onBlur,
    value,
    inputProps,
  } = props;
  const wrappedLabel = required ? label + "*" : label;
  const id = useInputId();

  const [customValue, setValue] = useState(value);
  useEffect(
    () => {
      if (value !== customValue) {
        setValue(value);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value],
  );

  useEffect(() => {
    if (autoFocus) {
      inputRef.current.inputElement.focus();
    }
  }, [inputRef, autoFocus]);

  const numberMask = createNumberMask({
    prefix: "",
    suffix: "",
    allowDecimal,
    decimalLimit,
    includeThousandsSeparator,
  });

  const onChange = (event) => {
    const { onChange, allowDecimal } = props;
    const currentValue = event.target.value;
    setValue(currentValue);
    const isZeroDecimal = (value) => allowDecimal && "0.00000".includes(value);

    if (onChange) {
      /*  eslint-disable unicorn/no-nested-ternary */
      const newValue = !currentValue
        ? ""
        : isZeroDecimal(currentValue)
        ? currentValue
        : accounting.unformat(currentValue);
      /*  eslint-enable unicorn/no-nested-ternary */
      const isValid = typeof newValue == "number" || isZeroDecimal(newValue);
      onChange(newValue, name, isValid);
    }
  };

  const groupClassnames = classNames(
    "FormElement--textInput FormElement--numberInput",
    {
      alignToRight: alignTo === "right",
      [extraClassnames]: !!extraClassnames,
      "has-readonly": readOnly,
      [size]: !!size,
    },
  );

  return (
    <FormElement
      extraClassnames={groupClassnames}
      label={wrappedLabel}
      isOptional={isOptional}
      errorMessage={errorMessage}
      name={name}
      showLock={showLock}
      textHelper={textHelper}
      idOverride={id}
      {...(errorDisplayValidation && { errorDisplayValidation })}
    >
      <div className="InputHolder">
        <MaskedInput
          {...(maxLength && { maxLength })}
          id={id}
          ref={inputRef}
          mask={numberMask}
          type={typeOfInput}
          autoComplete="off"
          className="InputText"
          disabled={disabled}
          label={label}
          aria-label={label}
          name={name}
          onChange={(event) => onChange(event)}
          placeholder={placeholder}
          required={required}
          readOnly={readOnly}
          value={customValue}
          onBlur={onBlur}
          guide={false}
          {...inputProps}
        />
        {showHelper && helperContent()}
        {numberSymbol && <p className="NumberSymbol">{numberSymbol}</p>}
      </div>
    </FormElement>
  );
});

NumberInput.displayName = "NumberInput";

NumberInput.defaultProps = {
  autoFocus: false,
  disabled: false,
  errorDisplayValidation: null,
  errorMessage: null,
  extraClassnames: null,
  isOptional: false,
  label: null,
  maxLength: 24,
  name: null,
  numberSymbol: null,
  onChange: null,
  onBlur: null,
  placeholder: null,
  required: false,
  typeOfInput: "text",
  value: "",
  precision: 2,
  size: "medium",
  alignTo: "left",
  showLock: false,
  helperContent: () => {},
  showHelper: false,
  textHelper: null,
  allowDecimal: false,
  decimalLimit: 2,
  includeThousandsSeparator: true,
  inputProps: {},
};

NumberInput.propTypes = {
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  errorDisplayValidation: PropTypes.func,
  errorMessage: PropTypes.string,
  extraClassnames: PropTypes.string,
  isOptional: PropTypes.bool,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  name: PropTypes.string,
  numberSymbol: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  typeOfInput: PropTypes.string,
  value: decimalPropType,
  precision: PropTypes.number,
  readOnly: PropTypes.bool,
  size: PropTypes.oneOf(["medium", "small"]),
  alignTo: PropTypes.oneOf(["left", "right"]),
  showLock: PropTypes.bool,
  helperContent: PropTypes.func,
  textHelper: PropTypes.string,
  showHelper: PropTypes.bool,
  allowDecimal: PropTypes.bool,
  decimalLimit: PropTypes.number,
  includeThousandsSeparator: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
};

export default NumberInput;
