import { useDispatch } from "react-redux";
import { useMutation } from "react-query";
import { openInModal, ModalNames } from "store/modal";

export function useOpenDocumentsUploader() {
  const dispatch = useDispatch();

  return useMutation((options = {}) => {
    dispatch(openInModal(ModalNames.discussionDocuments, options));
  });
}
