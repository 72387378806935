import React, { useCallback } from "react";
import useSidebar from "store/sidebar/hooks";
import Dropdown from "components/shared/Dropdown";
import Icon from "components_new/atoms/Icon";
import {
  globalAddDealIsAvailable,
  globalUploadFileIsAvailable,
  globalPostIsAvailable,
  globalUpdatePPSIsAvailable,
  globalLogCapitalCallIsAvailable,
} from "lib/globalActionsAvailability";
import useOrganizations from "store/organizations/hooks";
import { useSetSidebar } from "components_new/elements/SidebarWrapper/Sidebar-context";
import useViewIds from "lib/hooks/useViewIds";

function MainActions() {
  const { currentOrganization } = useOrganizations();
  const {
    is_current_user_organization_owner: isCurrentUserOrganizationOwner,
  } = currentOrganization;
  const { openSidebar, sidebar } = useSidebar();
  const { showSidebar } = useSetSidebar();
  const { initialComponent } = sidebar;

  const { initialHoldingId, initialCompanyId, initialDealId } = useViewIds();

  const openFilesSidebarForm = useCallback(() => {
    openSidebar("filesForm", "Add new files", {
      initialHoldingId,
      initialCompanyId,
      initialDealId,
    });
  }, [openSidebar, initialHoldingId, initialCompanyId, initialDealId]);

  const openNotesSidebarForm = useCallback(() => {
    showSidebar("noteForm", {
      initialHoldingId,
      initialCompanyId,
      initialDealId,
    });
  }, [showSidebar, initialHoldingId, initialCompanyId, initialDealId]);

  const openDealSidebarForm = useCallback(() => {
    showSidebar("dealForm", {
      initialCompanyId,
      size: "wide",
    });
  }, [showSidebar, initialCompanyId]);

  const openValuationSidebarForm = useCallback(() => {
    openSidebar("valuationForm", "Add Valuation", {
      initialHoldingId,
      initialCompanyId,
    });
  }, [openSidebar, initialHoldingId, initialCompanyId]);

  const openGlobalCapitalCallSidebarForm = useCallback(() => {
    openSidebar("capitalCallForm", "Add Capital Call", { initialHoldingId });
  }, [openSidebar, initialHoldingId]);

  // TODO: remove this "items" logic and  this initialComponent logic as soon as we deprecate modal forms
  let items = [
    {
      text: "New Fund",
      onClickAction: () => {
        openSidebar("portfolioForm", "Add New Fund");
      },
    },
    {
      text: "New Deal",
      isDisabled: !globalAddDealIsAvailable(currentOrganization),
      tooltip: !globalAddDealIsAvailable(currentOrganization) && tooltipContent,
      tooltipPosition: "top",
      onClickAction: () => openDealSidebarForm(),
    },
    {
      text: "New Company",
      onClickAction: () => {
        openSidebar("globalCompanyForm", "Add New Company");
      },
    },
    {
      text: "New Contact",
      onClickAction: () => {
        openSidebar("contactForm", "Add New Contact");
      },
    },
    {
      text: "New Investment",
      onClickAction: () => {
        openSidebar("investmentForm", "Add New Investment");
      },
    },
    {
      text: "New Introduction",
      onClickAction: () => {
        openSidebar("introductionForm", "Add New Introduction");
      },
    },
    {
      type: "separator",
    },
    {
      text: "Add Files",
      isDisabled:
        !globalUploadFileIsAvailable(currentOrganization) || !!initialComponent,
      onClickAction: () => openFilesSidebarForm(),
    },
    {
      text: "Add Note",
      isDisabled:
        !globalPostIsAvailable(currentOrganization) || !!initialComponent,
      onClickAction: () => openNotesSidebarForm(),
    },
    {
      text: "Add Valuation",
      isDisabled: !globalUpdatePPSIsAvailable(currentOrganization),
      onClickAction: () => openValuationSidebarForm(),
    },
    {
      text: "Add Capital Call",
      isDisabled: !globalLogCapitalCallIsAvailable(currentOrganization),
      onClickAction: () => openGlobalCapitalCallSidebarForm(),
    },
  ];

  const tooltipContent = isCurrentUserOrganizationOwner
    ? "Please visit the pipeline tab\nto be able to create a deal."
    : "Please contact your org owner\nto set up a pipeline.";
  return (
    <Dropdown behavior="secondary" items={items}>
      <Icon icon="ri-add-line" /> Add New
    </Dropdown>
  );
}

export default MainActions;
