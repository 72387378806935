export default function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const kilobyte = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(kilobyte));
  return (
    parseFloat((bytes / Math.pow(kilobyte, i)).toFixed(decimals)) +
    " " +
    sizes[i]
  );
}
