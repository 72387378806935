import React from "react";
import components from "./components";

export const makeSidebarComponent = (componentName) => {
  const component = componentName && components[componentName];

  return React.createElement(
    component,
    {
      ownName: componentName,
    },
    null,
  );
};
