import React from "react";

function InvestmentHeadline({ name }) {
  return <h1 className="InvestmentHeadline u-headingXLarge">{name}</h1>;
}
function InvestmentSubtitle({ investment, company }) {
  const { type } = investment;
  let actualType;

  if (type === "llc") {
    actualType = "Ownership";
  } else if (type === "fund") {
    actualType = "Fund Commitment";
  } else {
    actualType = type;
  }

  return (
    <h2 className="InvestmentSubtitle">
      <span className="u-capitalize">{actualType}</span> in {company.name}
    </h2>
  );
}

function Masthead({ investment, company }) {
  return (
    <div className="InvestmentDetails-masthead">
      <InvestmentHeadline name={investment.name} />
      <InvestmentSubtitle investment={investment} company={company} />
    </div>
  );
}

export default Masthead;
