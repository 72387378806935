import React, { Fragment, useState, useEffect } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import DraggableItem from "components_new/elements/DraggableItem";
import DraggableList from "components_new/elements/DraggableList";
import EmptyCard from "components_new/elements/EmptyCard";
import Text from "components_new/atoms/Text";
import { isEmpty, isEqual } from "lodash";
import EmptyContent from "./EmptyContent";
import ContentHeader from "./ContentHeader";
import ContentBody from "./ContentBody";
import AddSectionForm from "../AddSectionForm";
import AddItemForm from "../AddItemForm";
import reorder, { reorderSnapshots } from "../shared/reorder";
import "./SnapshotContents.scss";

const SnapshotContents = ({
  data,
  sections,
  orderedColumns,
  snapshotType,
  addItem,
  removeItem,
  addSection,
  removeSection,
  searchForItem,
  handleChange,
}) => {
  const [columns, setColumns] = useState(sections);
  const [ordered, setOrdered] = useState(orderedColumns);

  useEffect(() => {
    if (!isEqual(columns, sections)) {
      setColumns(sections);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections]);

  useEffect(() => {
    if (!isEqual(ordered, orderedColumns)) {
      setOrdered(orderedColumns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderedColumns]);

  useEffect(() => {
    const cleanData = ordered.map((key) => columns[key]);
    handleChange("data", cleanData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, ordered]);

  if (isEmpty(data)) {
    return (
      <Fragment>
        <EmptyContent />
        <AddSectionForm addSection={addSection} />
      </Fragment>
    );
  }

  const onDragEnd = (result) => {
    // if user dropped nowhere
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    // did not move anywhere
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    if (result.combine) {
      if (result.type === "column") {
        const shallow = [...ordered];
        shallow.splice(source.index, 1);
        setOrdered(shallow);
        return;
      }

      const column = columns[source.droppableId];
      const withSnapshotRemoved = [...column];
      withSnapshotRemoved.splice(source.index, 1);
      const newColumns = {
        ...columns,
        [source.droppableId]: withSnapshotRemoved,
      };
      setColumns(newColumns);
      return;
    }

    // reordering column
    if (result.type === "column") {
      const x = reorder(ordered, source.index, destination.index);
      setOrdered(x);
      return;
    }

    const data = reorderSnapshots({
      snapshots: columns,
      source,
      destination,
    });
    setColumns(data.snapshots);
  };

  return (
    <Fragment>
      <Text type="label-bold" color="brand-light">
        SNAPSHOT CONTENTS
      </Text>
      <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
        <DraggableList listId="board" listType="column" direction="vertical">
          {ordered.map((key, index) => {
            const section = columns[key];
            return (
              <DraggableItem
                extraClassnames="SectionList-Section"
                key={key}
                index={index}
                draggableId={key}
              >
                <ContentHeader
                  section={section}
                  removeSection={removeSection}
                />
                <DraggableList
                  listId={key}
                  listType="list"
                  direction="vertical"
                >
                  <Fragment>
                    {section?.data.length > 0 ? (
                      section?.data.map((data, index) => {
                        return (
                          <DraggableItem
                            key={data.title}
                            index={index}
                            draggableId={data.title}
                          >
                            <ContentBody data={data} removeItem={removeItem} />
                          </DraggableItem>
                        );
                      })
                    ) : (
                      <EmptyCard title={section.title} />
                    )}
                  </Fragment>
                </DraggableList>
                <AddItemForm
                  addItem={addItem}
                  sectionId={section.title}
                  searchForItem={searchForItem}
                  snapshotType={snapshotType}
                />
              </DraggableItem>
            );
          })}
        </DraggableList>
      </DragDropContext>
      <AddSectionForm addSection={addSection} />
    </Fragment>
  );
};

export default SnapshotContents;
