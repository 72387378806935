import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, groupBy, lowerCase } from "lodash";
import classnames from "classnames";
import { inputPropsPropType, selectOptionPropType } from "PropTypes";

function isOptionSelected(selectedOption, { value, disabled }) {
  return selectedOption && value === selectedOption.value && !disabled;
}

function CreateNewOption({
  lastQuery,
  newOptionLabel,
  onClick,
  preSelectedOption,
}) {
  const selectCreateNewOptionClassnames = classnames({
    SelectOption: true,
    "SelectOption--create": true,
    "is-selected": preSelectedOption
      ? preSelectedOption.label === lastQuery
      : false,
  });

  return (
    <li
      className={selectCreateNewOptionClassnames}
      onClick={() =>
        onClick({ value: null, isNewOption: true, label: lastQuery })
      }
    >
      <a className="SelectOption-link">
        {newOptionLabel}: "{lastQuery}"
      </a>
    </li>
  );
}

class SelectOption extends Component {
  handleMouseOver = () => {
    const { option, onMouseOver } = this.props;
    onMouseOver(option);
  };

  handleClick = () => {
    const { option, onClick } = this.props;
    onClick(option);
  };

  // NOTE: This helps to avoid extra re-render optimizing the mouse over event,
  // Only re-render a options if was previously selected to clear the selection
  // or if it's currently selected to add the class `is-selected`
  shouldComponentUpdate({ preSelectedOption, option }) {
    const {
      preSelectedOption: currentPreSelectedOption,
      option: currentItem,
    } = this.props;
    const isOptionSelected =
      !!preSelectedOption && option.value === preSelectedOption.value;
    const wasOptionSelectedAndNewSelectionIsNull =
      !!currentPreSelectedOption &&
      currentItem.value === currentPreSelectedOption.value &&
      !preSelectedOption;
    const wasOptionSelectedAndNewSelectionHasChanged =
      !!currentPreSelectedOption &&
      !!preSelectedOption &&
      currentItem.value === currentPreSelectedOption.value &&
      currentPreSelectedOption.value !== preSelectedOption.value;
    const wasOptionSelected =
      wasOptionSelectedAndNewSelectionHasChanged ||
      wasOptionSelectedAndNewSelectionIsNull;
    return isOptionSelected || wasOptionSelected;
  }

  render() {
    const {
      formatItemContent,
      option,
      preSelectedOption,
      preSelectedOptionRef,
    } = this.props;
    const { disabledMessage, disabled = false, value } = option;
    const selectOptionClassnames = classnames({
      SelectOption: true,
      "is-disabled": disabled,
      "is-selected": isOptionSelected(preSelectedOption, { value, disabled }),
    });
    return (
      <li
        className={selectOptionClassnames}
        onMouseOver={() => {
          if (!disabled) {
            this.handleMouseOver();
          }
        }}
        onClick={() => {
          if (!disabled) {
            this.handleClick();
          }
        }}
        ref={(ref) => {
          if (isOptionSelected(preSelectedOption, option)) {
            preSelectedOptionRef(ref);
          }
        }}
      >
        <a className="SelectOption-link">
          {formatItemContent(option)}{" "}
          {!!disabledMessage && (
            <span className="SelectOption-disabledMessage">
              {disabledMessage}
            </span>
          )}
        </a>
      </li>
    );
  }
}

function DefaultSelectOption({
  onClick,
  onMouseOver,
  defaultOption,
  preSelectedOption,
}) {
  const selectOptionClassnames = classnames({
    SelectOption: true,
    "SelectOption--defaultOption": true,
    "is-selected": preSelectedOption && preSelectedOption.value === null,
  });
  const option = { value: null, isNewOption: false, label: null };

  return (
    <li
      className={selectOptionClassnames}
      onMouseOver={() => {
        onMouseOver(option);
      }}
      onClick={() => {
        onClick(option);
      }}
    >
      <a className="SelectOption-link">{defaultOption}</a>
    </li>
  );
}

function NoResults() {
  return (
    <li className="SelectOption SelectOption--noResults">
      <a className="SelectOption-link">No Results</a>
    </li>
  );
}

function SelectOptions({
  onClickOption,
  onMouseOverOption,
  options,
  ...restProps
}) {
  return (
    <ul className="SelectOptions">
      {options.map((option, index) => {
        return (
          <SelectOption
            index={index}
            option={option}
            key={option.value || lowerCase(option.label)}
            onClick={onClickOption}
            onMouseOver={onMouseOverOption}
            {...restProps}
          />
        );
      })}
    </ul>
  );
}

function SelectOptionsByGroup({ options, ...restProps }) {
  const optionsByGroup = groupBy(options, "group");
  return (
    <li>
      <ul className="SelectOptionsByGroup">
        {Object.keys(optionsByGroup).map((group) => (
          <li className="GroupSelectOption" key={group}>
            <span className="GroupSelectOption-groupName">{group}</span>
            <SelectOptions options={optionsByGroup[group]} {...restProps} />
          </li>
        ))}
      </ul>
    </li>
  );
}
// TODO: Explore ways to render only options that need to be displayed
// Some ideas: Scroll infinite, paginators?
// NOTE: Disabling react/prefer-stateless-function since we need to save the SelectPopup ref
/*eslint-disable react/prefer-stateless-function */
class SelectPopup extends Component {
  render() {
    const {
      defaultOption,
      lastQuery,
      formatItemContent,
      grouped,
      newOptionLabel,
      onClickOption,
      onMouseOverOption,
      options,
      preSelectedOption,
      preSelectedOptionRef,
      showNewOption,
      supportsNoResultsMessage,
    } = this.props;
    const optionsProps = {
      options,
      formatItemContent,
      onClickOption,
      preSelectedOption,
      onMouseOverOption,
      preSelectedOptionRef,
    };
    // TODO: We need to move the SelectOptions outside of this component so we can import it and test it
    return (
      <ul className="SelectPopup">
        {defaultOption && (
          <DefaultSelectOption
            onClick={onClickOption}
            onMouseOver={onMouseOverOption}
            defaultOption={defaultOption}
            preSelectedOption={preSelectedOption}
          />
        )}
        {grouped ? (
          <SelectOptionsByGroup {...optionsProps} />
        ) : (
          <li>
            <SelectOptions {...optionsProps} />
          </li>
        )}
        {isEmpty(options) && supportsNoResultsMessage && <NoResults />}
        {showNewOption && (
          <CreateNewOption
            lastQuery={lastQuery}
            newOptionLabel={newOptionLabel}
            onClick={onClickOption}
            preSelectedOption={preSelectedOption}
          />
        )}
      </ul>
    );
  }
}

SelectPopup.propTypes = {
  defaultOption: PropTypes.string,
  lastQuery: PropTypes.string.isRequired,
  formatItemContent: PropTypes.func,
  grouped: PropTypes.bool,
  newOptionLabel: PropTypes.string,
  onClickOption: PropTypes.func.isRequired,
  onMouseOverOption: PropTypes.func.isRequired,
  options: PropTypes.oneOfType([inputPropsPropType]).isRequired,
  preSelectedOption: selectOptionPropType,
  showNewOption: PropTypes.bool.isRequired,
  supportsNoResultsMessage: PropTypes.bool.isRequired,
  preSelectedOptionRef: PropTypes.func.isRequired,
};

SelectPopup.defaultProps = {
  defaultOption: null,
  preSelectedOption: null,
  newOptionLabel: null,
  grouped: false,
};

export default SelectPopup;
