import React, { useContext, useState, useEffect, useLayoutEffect } from "react";
import Sidebar from "components_new/elements/Sidebar";
import Segment from "components_new/atoms/Segment";
import SidebarComponent from "./SidebarComponent";
import { SidebarComponentsContext } from "./SidebarComponentsWrapper";
import { calculateWidth } from "./config";
import { isEmpty, isEqual } from "lodash";
import "./SidebarComponents.scss";

function SidebarComponents() {
  const {
    sidebar: { visible, initialComponent, initialTitle, initialProps },
    componentStatus,
    mountSidebarComponent,
    updateSidebarComponent,
    resetSidebarComponents,
    resetForm,
  } = useContext(SidebarComponentsContext);

  const [isLaunched, setIsLaunched] = useState(false);
  const [liveComponents, setLiveComponents] = useState(null);
  const [sessionProps, setSessionProps] = useState(initialProps);
  const [sidebarVisible, setSidebarVisible] = useState(false);

  useEffect(() => {
    /**
     * shutdown
     */
    if (isEmpty(initialComponent) && isLaunched) {
      resetForm();
      resetSidebarComponents();
      setIsLaunched(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialComponent]);

  useEffect(() => {
    /**
     * startup
     */
    if (!isEmpty(initialComponent)) {
      setIsLaunched(true);
      mountSidebarComponent(initialComponent, initialTitle, initialProps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialComponent]);

  useLayoutEffect(() => {
    if (!isEqual(liveComponents, componentStatus)) {
      setLiveComponents(componentStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentStatus]);

  useEffect(() => {
    if (!isEqual(initialProps, sessionProps)) {
      const newSessionProps = isEmpty(initialProps)
        ? initialProps
        : { ...sessionProps, ...initialProps };
      setSessionProps(newSessionProps);
      updateSidebarComponent(initialComponent, newSessionProps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialProps]);

  useEffect(() => {
    if (visible !== sidebarVisible) {
      setSidebarVisible(visible);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const sidebarWidth =
    !isEmpty(initialProps) &&
    liveComponents &&
    calculateWidth(
      liveComponents,
      initialComponent,
      initialProps.sidebarPolicy,
    );

  return (
    <Sidebar
      extraClassnames="SidebarComponents"
      style={{ width: sidebarWidth }}
      animation="overlay"
      vertical
      direction="right"
      visible={sidebarVisible}
      as={Segment}
    >
      {liveComponents &&
        liveComponents.map((component, index) => (
          <SidebarComponent key={index} componentName={component.name} />
        ))}
    </Sidebar>
  );
}

export default SidebarComponents;
