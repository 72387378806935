import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./ListValue.scss";

function ListValue({ list, attribute, showEllipsis = false }) {
  const listValueClassnames = classNames("ListValue list-item", {
    "u-textWithEllipsis": showEllipsis,
  });
  return (
    <div className={listValueClassnames}>
      {list.map((item) => item[attribute]).join(", ")}
    </div>
  );
}

ListValue.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})),
  attribute: PropTypes.string.isRequired,
  showEllipsis: PropTypes.bool,
};

export default ListValue;
