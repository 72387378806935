import React, { Fragment, useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import EllipsisDropdown from "components_new/elements/EllipsisDropdown";
import Text from "components_new/atoms/Text";
import Value from "components_new/atoms/Value";
import EmptyCard from "components_new/elements/EmptyCard";
import {
  childrenPT,
  classNamesPT,
  headerCellPT,
  headersPT,
  cellDataPT,
  rowIndexPT,
  rowDataPT,
  dataPT,
  rowActionPT,
  rowActionsPT,
  timelinePT,
} from "./ReportTablePropTypes.js";
import "./ReportTable.scss";

/* REPORT TABLE - Container
======================================== */
const ReportTable = ({
  children,
  extraClassNames,
  headers,
  data,
  rowActions,
  footerData,
  footNote,
  timeline,
  emptyTitle = "Items",
  nilLabel,
}) => {
  const ReportTableClassNames = classNames("ReportTable", {
    WithRowActions: !!rowActions,
    WithFooter: !!footerData,
    TimelineTable: !!timeline,
    [extraClassNames]: !!extraClassNames,
  });

  // if rowActions, add empty cell to each row
  const concernedHeaders = useMemo(
    () => (headers && rowActions ? [...headers, {}] : headers),
    [headers, rowActions],
  );

  const concernedFooterData = useMemo(
    () =>
      footerData && rowActions
        ? footerData.map((row) => [...row, {}])
        : footerData,
    [footerData, rowActions],
  );

  return (
    <div className="ReportTableWrapper">
      {!children && data.length === 0 ? (
        <EmptyCard title={emptyTitle} nilLabel={nilLabel} />
      ) : (
        <Fragment>
          <table className={ReportTableClassNames}>
            {headers && (
              <Header
                headers={headers ? concernedHeaders : null}
                timeline={timeline}
              />
            )}
            {data && (
              <Body data={data} rowActions={rowActions} timeline={timeline} />
            )}
            {children}
            {footerData && (
              <Footer
                data={footerData ? concernedFooterData : null}
                timeline={timeline}
              />
            )}
          </table>
          {footNote && (
            <Text
              as="p"
              type="body-2-regular"
              color="brand-light"
              extraClassnames="ReportTable-FootNote"
            >
              {footNote}
            </Text>
          )}
        </Fragment>
      )}
    </div>
  );
};

ReportTable.propTypes = {
  children: childrenPT,
  extraClassNames: classNamesPT,
  headers: headersPT,
  data: dataPT,
  rowActions: rowActionsPT,
  footerData: dataPT,
  footNote: PropTypes.string,
  timeline: timelinePT,
  emptyTitle: PropTypes.string,
  nilLabel: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
};

/* HEADER - Group
======================================== */
const Header = ({ children, headers, timeline }) => {
  return (
    <thead>
      <tr>
        {timeline && <HeaderCell />}
        {headers &&
          headers.map((item) => (
            <HeaderCell align={item.align} title={item.title} />
          ))}
        {children}
      </tr>
    </thead>
  );
};

Header.propTypes = {
  children: childrenPT,
  headers: headersPT,
  timeline: timelinePT,
};

/* HEADER CELL
======================================== */
const HeaderCell = ({ title, children, align }) => {
  const headerCellClassNames = classNames("HeaderCell", {
    [`AlignTextRight`]: align === "right",
  });
  return (
    <th className={headerCellClassNames}>
      {title && (
        <Text type="label-bold" color="brand-light">
          {title}
        </Text>
      )}
      {children}
    </th>
  );
};

HeaderCell.propTypes = {
  children: childrenPT,
  ...headerCellPT,
};

/* BODY - Group
======================================== */
const Body = ({ data, children, rowActions, timeline }) => {
  return (
    <tbody>
      {data &&
        data.map((items, index) => (
          <Row
            data={items}
            rowActions={rowActions}
            rowIndex={index}
            timeline={timeline}
          />
        ))}
      {children}
    </tbody>
  );
};

Body.propTypes = {
  children: childrenPT,
  data: dataPT,
  rowActions: rowActionsPT,
  timeline: timelinePT,
};

/* ROW - Group
======================================== */
const Row = ({
  children,
  data,
  rowActions,
  rowIndex,
  timeline,
  extraClassNames,
}) => {
  const rowClassNames = classNames("Row", {
    [extraClassNames]: !!extraClassNames,
  });
  return (
    <tr className={rowClassNames}>
      {timeline && <Cell extraClassNames="TimelineTable-TimelineCell" />}
      {data &&
        data.map((item) => (
          <Cell
            value={item.value}
            valueType={item.valueType}
            valueOptions={item.valueOptions}
            valueLight={item.valueLight}
            subValue={item.subValue}
            subValueType={item.subValueType}
            subValueOptions={item.subValueOptions}
            subValueLight={item.subValueLight}
            align={item.align}
            colspan={item.colspan}
          />
        ))}
      {children}
      {rowActions && (
        <td className="RowActions">
          <RowActionMenu options={rowActions} rowIndex={rowIndex} />
        </td>
      )}
    </tr>
  );
};

Row.propTypes = {
  children: childrenPT,
  data: rowDataPT,
  rowActions: rowActionsPT,
  rowIndex: rowIndexPT,
  timeline: timelinePT,
  extraClassNames: classNamesPT,
};

/* CELL
======================================== */
const Cell = ({
  children,
  value,
  valueType,
  valueOptions,
  valueLight = true,
  subValue,
  subValueType,
  subValueOptions,
  subValueLight = true,
  align,
  colspan = 1,
  extraClassNames,
}) => {
  const cellClassNames = classNames("Cell", {
    [`AlignTextRight`]: align === "right",
    [extraClassNames]: !!extraClassNames,
  });
  return (
    <td className={cellClassNames} colSpan={colspan}>
      {value && (
        <div>
          <Value type={valueType} {...valueOptions} light={valueLight}>
            {value}
          </Value>
        </div>
      )}
      {subValue && (
        <div>
          <Value type={subValueType} {...subValueOptions} light={subValueLight}>
            {subValue}
          </Value>
        </div>
      )}

      {children && <div>{children}</div>}
    </td>
  );
};

Cell.propTypes = {
  children: childrenPT,
  ...cellDataPT,
  extraClassNames: classNamesPT,
};

/* ROW ACTION MENU
======================================== */

const RowActionMenu = ({ options, rowIndex }) => {
  const concernedOptions = options.map((option) => {
    return {
      ...option,
      onClick: () => option.onClick(rowIndex),
    };
  });
  return (
    <EllipsisDropdown
      iconOrientation="vertical"
      iconSize="tiny"
      align="left"
      options={concernedOptions}
    />
  );
};

RowActionMenu.propTypes = {
  ...rowActionPT,
};

/* FOOTER
======================================== */
const Footer = ({ data }) => {
  return <tfoot>{data && data.map((items) => <Row data={items} />)}</tfoot>;
};

Footer.propTypes = {
  data: rowDataPT,
};

/* Assign Compound Components
======================================== */

ReportTable.Header = Header;
ReportTable.HeaderCell = HeaderCell;
ReportTable.Body = Body;
ReportTable.Row = Row;
ReportTable.Cell = Cell;
ReportTable.Footer = Footer;

export default ReportTable;
