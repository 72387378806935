import React, { useEffect, useState, useMemo } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { validDateFormat } from "config/customCalendarConfig";
import CalendarHeader from "./CalendarHeader";
import CalendarBody from "./CalendarBody";
import CalendarFooter from "./CalendarFooter";
import "./Calendar.scss";

function Calendar({
  selectedDate,
  preselectedDate,
  onMonthChange,
  onDateClick,
}) {
  const today = useMemo(() => moment(), []);
  const [currentDate, setCurrentDate] = useState(today);
  const [calendarMonthIndex, setCalendarMonthIndex] = useState(today.month());
  const [calendarYear, setCalendarYear] = useState(today.year());

  useEffect(() => {
    if (selectedDate && selectedDate !== currentDate) {
      const newDate = moment(selectedDate, validDateFormat);

      setCurrentDate(newDate);
      setCalendarMonthIndex(newDate.month());
      setCalendarYear(newDate.year());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const changeMonth = (e, amount) => {
    const newDate = moment({
      month: calendarMonthIndex,
      year: calendarYear,
    }).add(amount, "months");

    setCalendarMonthIndex(newDate.month());
    setCalendarYear(newDate.year());

    if (onMonthChange) {
      onMonthChange(newDate.month(), newDate.year());
    }
  };

  const isCurrentDate = (year, monthZeroIndexed, date) => {
    return (
      currentDate.date() === date &&
      currentDate.month() === monthZeroIndexed &&
      currentDate.year() === year
    );
  };

  const handleClickToday = () => {
    setCurrentDate(today);
    setCalendarMonthIndex(today.month());
    setCalendarYear(today.year());

    if (onDateClick) onDateClick(today);
  };

  return (
    <div className="Calendar">
      <CalendarHeader
        changeMonth={changeMonth}
        calendarMonthIndex={calendarMonthIndex}
        calendarYear={calendarYear}
      />
      <CalendarBody
        onDateClick={onDateClick}
        preselectedDate={preselectedDate}
        calendarMonthIndex={calendarMonthIndex}
        calendarYear={calendarYear}
        isCurrentDate={isCurrentDate}
      />
      <CalendarFooter onClickToday={handleClickToday} />
    </div>
  );
}

Calendar.propTypes = {
  onMonthChange: PropTypes.func,
  onDateClick: PropTypes.func,
  selectedDate: PropTypes.string,
  preselectedDate: PropTypes.string,
};

Calendar.defaultProps = {
  onMonthChange: null,
  onDateClick: null,
  selectedDate: null,
  preselectedDate: null,
};

export default Calendar;
