import React, { Fragment } from "react";
import CollapsableSection from "components_new/elements/CollapsableSection";
import Link from "components_new/atoms/Link";
import CompanyInfo from "components_new/elements/CompanyInfo";
import SubValue from "components_new/atoms/SubValue";
import Value from "components_new/atoms/Value";
import EmptyCard from "components_new/elements/EmptyCard";
import { useSetSidebar } from "components_new/elements/SidebarWrapper/Sidebar-context";
import "./ContactReferrals.scss";

function showtitle(count) {
  return (
    <Fragment>
      Referrals <small>({count})</small>
    </Fragment>
  );
}

function ContactReferrals({ referrals, organizationUsername }) {
  const haveReferrarls = referrals.length > 0;
  const { showSidebar } = useSetSidebar();

  const renderReferralLinks = (referral) => {
    if (referral.type === "Investment") {
      return (
        <Link
          extraClassnames="CompanyName"
          title={referral.title}
          to={`/${organizationUsername}/investments/${referral.id}`}
        />
      );
    } else if (referral.type === "Deal") {
      return (
        <div
          className="Link"
          onClick={() => {
            showSidebar("activeDealView", {
              dealId: referral.id,
            });
          }}
        >
          <Value type="text">{referral.title}</Value>
        </div>
      );
    }
  };

  return (
    <CollapsableSection
      expanded={haveReferrarls}
      title={showtitle(referrals.length)}
      extraClassNames="Contact-Details-Referrals"
    >
      {haveReferrarls ? (
        referrals.map((referral) => (
          <CompanyInfo
            key={referral.id}
            title={() => renderReferralLinks(referral)}
            content={() => (
              <SubValue textLinesLimit={2}>{referral.subtitle}</SubValue>
            )}
            company={referral.company}
          />
        ))
      ) : (
        <EmptyCard title="Referrals" />
      )}
    </CollapsableSection>
  );
}

export default ContactReferrals;
