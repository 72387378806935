import PropTypes from "prop-types";

export const childrenPT = PropTypes.node;
export const classNamesPT = PropTypes.string;
export const headerCellPT = {
  title: PropTypes.string,
  align: PropTypes.string,
  colspan: PropTypes.number,
};
export const headersPT = PropTypes.arrayOf(PropTypes.shape(headerCellPT));
export const valueTypePT = PropTypes.oneOf([
  "percentage",
  "text",
  "quantity",
  "currency",
  "multiple",
  "date",
  "tag",
  "abbreviated",
  "multiple_choice",
  "gainloss",
]);
export const cellDataPT = {
  value: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  valueType: valueTypePT,
  valueOptions: PropTypes.object,
  valueLight: PropTypes.bool,
  subValue: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  subValueType: valueTypePT,
  subValueOptions: PropTypes.object,
  subValueLight: PropTypes.bool,
  align: PropTypes.oneOf([null, "left", "right"]),
  colspan: PropTypes.number,
};
export const rowIndexPT = PropTypes.number;
export const rowDataPT = PropTypes.arrayOf(PropTypes.shape(cellDataPT));
export const dataPT = PropTypes.arrayOf(rowDataPT);
export const rowActionPT = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};
export const rowActionsPT = PropTypes.arrayOf(
  PropTypes.shape({
    rowActionPT,
  }),
);
export const timelinePT = PropTypes.bool;
