import React from "react";
import PropTypes from "prop-types";
import FileRow from "components_new/elements/FileRow";
import { map } from "lodash";
import { documentPropType } from "PropTypes";

function CompanyFiles({
  files,
  currentOrganizationUsername,
  setEditFile,
  setDeleteFile,
  resetFileActions,
  currentAction,
  editAction,
  deleteAction,
  modifiedFileId,
}) {
  return map(files, (tempFile) => {
    return (
      <FileRow
        key={tempFile.id}
        file={tempFile}
        setEditFile={setEditFile}
        setDeleteFile={setDeleteFile}
        resetFileActions={resetFileActions}
        currentAction={currentAction}
        editAction={editAction}
        deleteAction={deleteAction}
        modifiedFileId={modifiedFileId}
        currentOrganizationUsername={currentOrganizationUsername}
      />
    );
  });
}

CompanyFiles.propTypes = {
  files: PropTypes.arrayOf(documentPropType).isRequired,
  currentOrganizationUsername: PropTypes.string.isRequired,
  setEditFile: PropTypes.func.isRequired,
  setDeleteFile: PropTypes.func.isRequired,
  resetFileActions: PropTypes.func.isRequired,
  currentAction: PropTypes.string,
  editAction: PropTypes.func.isRequired,
  deleteAction: PropTypes.func.isRequired,
  modifiedFileId: PropTypes.number,
};

export default CompanyFiles;
