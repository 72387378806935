import accounting from "accounting";
import formatPrice from "lib/formatters/formatPrice";
import { perUnitNumberPrecision } from "config/priceInput";
import moment from "moment";
import isValidNumberInput from "lib/formatters/isValidNumberInput";

const formatAttributeType = (value, type, attribute) => {
  if (attribute === "interest rate" || attribute === "percentage") {
    return ` ${accounting.toFixed(value, 2, "")}%`;
  }
  if (
    attribute === "unit acquisition amount" ||
    attribute === "unit cost basis"
  ) {
    return ` ${
      isValidNumberInput(value)
        ? formatPrice(value, { precision: perUnitNumberPrecision })
        : ""
    }`;
  }
  switch (type) {
    case "text": {
      return ` ${value}`;
    }
    case "decimal": {
      return ` ${isValidNumberInput(value) ? formatPrice(value) : ""}`;
    }
    case "integer": {
      return ` ${accounting.formatNumber(value)}`;
    }
    case "datetime": {
      return ` ${moment(value, "YYYY-MM-DD HH:mm:SS").format("MM/DD/YYYY")}`;
    }
    case "string": {
      return ` ${value}`;
    }
    case "boolean": {
      return value ? " Yes" : " No";
    }
    default:
      return ` ${value}`;
  }
};

export default formatAttributeType;
