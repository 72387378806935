import React, { useEffect, useState } from "react";
import isServerValidationError from "lib/isServerValidationError";
import PrimaryButton from "components/shared/PrimaryButton";
import TextInput from "components/shared/form/TextInput";
import EmailInput from "components/shared/form/EmailInput";
import ColorPicker from "components_new/atoms/ColorPicker";
import classNames from "classnames";
import Errors from "lib/errors";
import AvatarUploader from "./AvatarUploader";

function AccountInformationForm({
  isSubmitting,
  openAvatarFilePickerModal,
  user,
  updateUserAccountInformation,
}) {
  const [userId, setUserId] = useState(null);
  const [hasFormChanged, setHasFormChanged] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    avatar_color: "",
  });
  const [errors, setErrors] = useState(new Errors({}));

  const accountInformationClassnames = classNames("AccountInformationForm", {
    "submitting-form": isSubmitting,
  });

  useEffect(() => {
    setUserId(user.id);
    setFormData({
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      avatar_color: user.avatar_color,
    });
  }, [user]);

  const updateForm = (key, value) => {
    setHasFormChanged(true);
    setErrors(errors.without(key));
    setFormData({ ...formData, [key]: value });
  };

  const handleSubmit = () => {
    updateUserAccountInformation(userId, formData)
      .then(() => {
        setHasFormChanged(false);
      })
      .catch((error) => {
        if (isServerValidationError(error)) {
          const { data } = error;
          setErrors(new Errors(data.errors));
        }
      });
  };

  return (
    <form className={accountInformationClassnames}>
      <h2 className="FormSection-title u-sectionHeading">
        Account Information
      </h2>
      <div className="FormSection-Body">
        <AvatarUploader
          user={user}
          openAvatarFilePickerModal={openAvatarFilePickerModal}
        />
        <TextInput
          required
          label="First Name"
          value={formData.first_name}
          errorMessage={errors.for("first_name")}
          extraClassnames="FormElement--DisplayName"
          onChange={(value) => updateForm("first_name", value)}
        />
        <TextInput
          required
          label="Last Name"
          value={formData.last_name}
          errorMessage={errors.for("last_name")}
          extraClassnames="FormElement--DisplayName"
          onChange={(value) => updateForm("last_name", value)}
        />
        <EmailInput
          required
          label="Email Address"
          value={formData.email}
          errorMessage={errors.for("email")}
          componentValidationEnabled={false}
          extraClassnames="FormElement--Email"
          onChange={(value) => updateForm("email", value)}
        />
        <ColorPicker
          name="color"
          label="Avatar color"
          value={formData.avatar_color}
          onChange={(value) => {
            updateForm("avatar_color", value);
          }}
        />
      </div>
      <div className="FormSection-Footer">
        <PrimaryButton
          customClass="AccountInformationForm-SubmitButton"
          disabled={!hasFormChanged}
          onClick={handleSubmit}
          type="submit"
          filter={{
            applyingFilter: isSubmitting,
          }}
        >
          Save Changes
        </PrimaryButton>
      </div>
    </form>
  );
}

export default AccountInformationForm;
