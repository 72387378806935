import { useDispatch } from "react-redux";
import { createCapitalCall as createCapitalCallHelper } from "store/investments/capitalCalls/actions";

export default () => {
  const dispatch = useDispatch();

  const createCapitalCall = (investmentId, formData) =>
    dispatch(createCapitalCallHelper(investmentId, formData));

  return {
    createCapitalCall,
  };
};
