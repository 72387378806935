import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import RemoveRow from "./RemoveRow";
import TooltipHolder from "components/shared/TooltipHolder";
import filterErrors from "lib/shared/form/filterErrors";
import LockIcon from "-!svg-react-loader?!assets/icons/utility/lock.svg?name=LockIcon";

function TableRow({
  index,
  data,
  renderRow,
  updateForm,
  removeRow,
  columns,
  removeIds,
  removeIndexes,
  canLockItem = () => false,
  errorsResults,
  deleteTitle = "",
  deleteButtonText,
  showAllRows,
  isShowingLessRows,
  viewMoreTooltipContent,
  lockTooltipContent,
}) {
  // removeIds was changed by removeIndexes just because Distributions with cash and equity required that
  // If we change this behavior we need to rollback to use removeIds instead
  const tableRowClassNames = classnames("Table--row", {
    isRemoved: removeIds
      ? removeIds.includes(data.id)
      : removeIndexes.includes(index),
    isLocked: canLockItem(data),
  });
  const errors = filterErrors(errorsResults, index);
  return (
    <div className={tableRowClassNames} data-testid="TableRow">
      {renderRow({
        index: index,
        data: data,
        updateForm: updateForm,
        columns: columns,
        errors: errors,
      })}
      {isShowingLessRows && index === 2 && (
        <TooltipHolder
          extraClassnames="ViewMoreWrapper"
          tooltip={viewMoreTooltipContent}
          position="top"
        >
          <div className="ViewMore" onClick={showAllRows}>
            View More
          </div>
        </TooltipHolder>
      )}
      <RemoveRow
        index={index}
        id={data.id}
        removeRow={removeRow}
        deleteTitle={deleteTitle}
        deleteButtonText={deleteButtonText}
      />
      <div className="Table--row-lockIcon">
        <TooltipHolder tooltip={lockTooltipContent} position="top">
          <LockIcon />
        </TooltipHolder>
      </div>
    </div>
  );
}

TableRow.propTypes = {
  index: PropTypes.number,
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  renderRow: PropTypes.func.isRequired,
  updateForm: PropTypes.func,
  canLockItem: PropTypes.func,
  removeRow: PropTypes.func,
  isEditing: PropTypes.bool,
  removeIds: PropTypes.arrayOf(PropTypes.number),
  removeIndexes: PropTypes.arrayOf(PropTypes.number),
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  errorsResults: PropTypes.arrayOf(PropTypes.shape({})),
  deleteTitle: PropTypes.string,
  deleteButtonText: PropTypes.string,
  showAllRows: PropTypes.func,
  isShowingLessRows: PropTypes.bool,
  viewMoreTooltipContent: PropTypes.string,
  lockTooltipContent: PropTypes.string,
};

export default TableRow;
