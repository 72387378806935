import { formatPriceInputPreview } from "./abbreviatedFormat";

export default function formatDescription(value) {
  const startAbbreviatingFrom = 100000000; // 100 million
  return formatPriceInputPreview(
    value,
    {
      precision: 0,
    },
    startAbbreviatingFrom,
    1,
  );
}
