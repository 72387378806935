import React from "react";
import InputTag from "components_new/elements/InputTag";
import useOrganizations from "store/organizations/hooks";
import useTags from "store/tags/hooks";

import { openModal } from "store/modalV2";
import { useDispatch } from "react-redux";

function TagsWrapper({ tagType = "company", ...restProps }) {
  const { currentOrganization } = useOrganizations();
  const { createTag, editTag, filterTags, tagSuggestions } = useTags();
  const dispatch = useDispatch();

  const suggested_tags =
    tagType === "company"
      ? currentOrganization.company_suggested_tags
      : currentOrganization.contact_suggested_tags;

  const onFilterTag = (tagName) => filterTags(tagName, tagType);
  const onCreateTag = (tagName) => createTag(tagName, tagType);

  const onDelete = (tag, actionAfterDelete) => {
    dispatch(
      openModal("deleteTag", {
        tag,
        actionAfterDelete,
      }),
    );
  };

  return (
    <InputTag
      onFilter={onFilterTag}
      onCreate={onCreateTag}
      onEdit={editTag}
      onDelete={onDelete}
      suggestedTags={suggested_tags}
      suggestedList={tagSuggestions}
      {...restProps}
    />
  );
}

export default TagsWrapper;
