import React from "react";
import { Link } from "react-router-dom";
import { TableRow, TableCell } from "components_new/elements/Table";
import formatDate from "lib/formatDate";
import Value from "components_new/atoms/Value";
import SubValue from "components_new/atoms/SubValue";
import Tooltip from "components_new/atoms/Tooltip/Tooltip";
import GainLossArrow from "components_new/elements/GainLossArrow";
import NoClosingDocIndicator from "components_new/elements/NoClosingDocIndicator";
import ColumnSection from "../ColumnSection";
import FMVSubvalue from "../FMVSubvalue";
import PropTypes from "prop-types";
import ContentWithTooltip from "components_new/elements/ContentWithTooltip";
import CompanyInfo from "components_new/elements/CompanyInfo";
import { companyAvatarPropType, investmentPropType } from "PropTypes";
import getInvestmentOrigin from "../getInvestmentOrigin";
import { ToggleInvestmentSidebar } from "components_new/SidebarComponents/views/InvestmentView/ToggleInvestmentSidebar";
import { isEmpty } from "lodash";

function InvestmentRow({ investment, orgUsername, showClosingDocuments }) {
  const investmentOrigin = getInvestmentOrigin(
    investment.type,
    investment.current_history_action_type,
  );

  return (
    <TableRow key={investment.id}>
      <TableCell className="CompanyNameColumn">
        {!isEmpty(investment.company) && (
          <CompanyInfo
            company={investment.company}
            title={(company) => {
              return (
                <ContentWithTooltip
                  tooltip={{
                    content: company.name,
                    position: "top center",
                    disabled: false,
                  }}
                >
                  <Link to={`/${orgUsername}/companies/${company.id}`}>
                    {company.name}
                  </Link>
                </ContentWithTooltip>
              );
            }}
            tier={investment.company.tier}
          />
        )}
      </TableCell>
      <TableCell className="InvestmentNameColumn">
        <div className="InvestmentNameColumn-content">
          <span className="InvestmentNameColumn-text">
            <ContentWithTooltip
              tooltip={{
                content: investment.name,
                position: "top center",
                disabled: false,
              }}
            >
              <ToggleInvestmentSidebar investmentId={investment.id}>
                {investment.name}
              </ToggleInvestmentSidebar>
            </ContentWithTooltip>
            <SubValue>{investment.type_label}</SubValue>
          </span>
          {showClosingDocuments && !investment.has_closing_documents && (
            <NoClosingDocIndicator />
          )}
        </div>
      </TableCell>
      <TableCell>
        {investment.previous_holding ? "Exited" : "Current"}
      </TableCell>
      <TableCell className="DateColumn">
        {formatDate(investment.date)}
        <SubValue extraClassName="u-capitalize">{investmentOrigin}</SubValue>
      </TableCell>
      <TableCell className="NumericColumn">
        <ColumnSection isNotApplicable={investment.previous_holding}>
          <Value type="quantity">{investment.quantity}</Value>
          {investment.fully_diluted_shares_percentage && (
            <span>
              <Tooltip
                position="top center"
                trigger={
                  <SubValue>
                    <Value type="percentage">
                      {investment.fully_diluted_shares_percentage}
                    </Value>
                    FD
                  </SubValue>
                }
                content="% Fully diluted"
              />
            </span>
          )}
        </ColumnSection>
      </TableCell>
      <TableCell className="NumericColumn CostBasis">
        <Value type="currency" precision="2">
          {investment.cost_basis}
        </Value>
        {investment.unit_cost_basis && (
          <SubValue>
            <Value type="currency" precision="2">
              {investment.unit_cost_basis}
            </Value>{" "}
            / {investment.unit}
          </SubValue>
        )}
      </TableCell>
      <TableCell className="NumericColumn">
        <ColumnSection isNotApplicable={investment.previous_holding}>
          <Value type="currency" precision="2">
            {investment.total_valuation_amount}
          </Value>
          <FMVSubvalue investment={investment} />
        </ColumnSection>
      </TableCell>
      <TableCell className="NumericColumn GainLossColumn">
        <Value type="currency" precision="2">
          {investment.gain}
        </Value>
        <GainLossArrow gain={investment.gain} multiple={investment.multiple} />
        <SubValue>
          <Value type="multiple">{investment.multiple}</Value>
        </SubValue>
      </TableCell>
    </TableRow>
  );
}

InvestmentRow.propTypes = {
  investment: investmentPropType,
  company: companyAvatarPropType,
  orgUsername: PropTypes.string,
  showClosingDocuments: PropTypes.bool,
};

export default InvestmentRow;
