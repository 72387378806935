import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { isEmpty, isEqual } from "lodash";
import FormElement from "components/shared/form/FormElement";
import SelectCurrentSelection from "components/shared/form/select/SelectCurrentSelection";
import SelectPopupManager from "components/shared/form/select/SelectPopupManager";
import reactDomContains from "lib/reactDomContains";
import { inputPropsPropType } from "PropTypes";
import setIsNewOptionAsFalse from "lib/shared/form/select/setIsNewOptionAsFalse";
import filterOptions from "lib/shared/form/select/filterOptions";
//TODO: remove this signal logic when we have a new CompanySelector:
import RESET_SIGNAL from "components_new/elements/CompanySelector/CompanySelectorResetSignal";

const EMPTY_ITEM = {
  value: null,
  isNewOption: false,
  label: null,
};

function formatNewOptionLabel(label) {
  return `+ New ${label}`;
}

class Select extends Component {
  static propTypes = {
    alwaysShowNewOption: PropTypes.bool,
    autocomplete: PropTypes.bool,
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    defaultOption: PropTypes.string,
    errorMessage: PropTypes.string,
    errorDisplayValidation: PropTypes.func,
    extraClassnames: PropTypes.string,
    formatItemContent: PropTypes.func,
    formatDisplayValue: PropTypes.func,
    grouped: PropTypes.bool,
    inputPlaceholder: PropTypes.string,
    inputProps: inputPropsPropType.isRequired,
    isOptional: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    newOptionLabel: PropTypes.string,
    onChange: PropTypes.func,
    resetForSearchHook: PropTypes.func,
    resetToPlaceholder: PropTypes.bool,
    openWithKeyDown: PropTypes.bool,
    placeholder: PropTypes.string,
    size: PropTypes.oneOf(["default", "small"]),
    supportsNoResultsMessage: PropTypes.bool,
    supportsNewOptionCreation: PropTypes.bool,
    tabIndex: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    readOnly: PropTypes.bool,
    showLock: PropTypes.bool,
  };

  static defaultProps = {
    alwaysShowNewOption: false,
    autocomplete: false,
    autoFocus: false,
    combobox: false,
    disabled: false,
    errorDisplayValidation: null,
    errorMessage: null,
    extraClassnames: null,
    formatItemContent: ({ label }) => label,
    formatDisplayValue: (displayValue) => displayValue,
    defaultOption: null,
    grouped: false,
    inputPlaceholder: "",
    inputProps: [],
    isOptional: false,
    label: null,
    name: "",
    newOptionLabel: "Option",
    onChange: null,
    openWithKeyDown: false,
    placeholder: "",
    resetToPlaceholder: false,
    tabIndex: "0",
    size: "default",
    supportsNewOptionCreation: false,
    supportsNoResultsMessage: false,
  };

  state = {
    displayValue: this.props.placeholder,
    filteredOptions: this.props.inputProps,
    isOpen: false,
    preSelectedOption: null,
    selectedOption: null,
    lastQuery: "",
  };

  outEventListener = ({ target }) => {
    const { isOpen } = this.state;

    if (isOpen && this.selectPopupManagerRef) {
      const outside = !reactDomContains(this.selectPopupManagerRef, target);

      if (outside) {
        this.toggleSelect();
      }
    }
  };

  handleClickOption = (selectedOption) => {
    this.updateSelectedOption(selectedOption, false, () => {
      this.toggleSelect();
    });
  };

  handleMouseOverOption = (option) => {
    this.updatePreSelectedOption(option);
  };

  handleInputChange = (value) => {
    const filteredOptions = filterOptions(value, this.props.inputProps);
    this.setState(
      {
        lastQuery: value,
        displayValue: value,
        filteredOptions,
        selectedOption: null,
        preSelectedOption: null,
      },
      () => {
        if (value !== "") {
          this.preSelectFirstOption();
        }
      },
    );
    this.fireOnChange(null);
  };

  updatePreSelectedOption = (option, callback) => {
    this.setState({ preSelectedOption: option }, callback);
  };

  updateSelectedOption = (option, preventFireOnChange = false, callback) => {
    const { placeholder, defaultOption } = this.props;
    this.setState(
      {
        preSelectedOption: option,
        selectedOption: option,
        displayValue: option ? option.label || defaultOption : placeholder,
      },
      () => {
        if (!preventFireOnChange) {
          this.fireOnChange(option);
        }

        if (callback) {
          callback();
        }
      },
    );
  };

  updateDisplayValue = (displayValue) => {
    this.setState({ displayValue });
  };

  toggleSelect = (isOpen = !this.state.isOpen) => {
    const { autocomplete } = this.props;
    const shouldResetPreSelectedOptionForAutocomplete = isOpen && autocomplete;
    const preSelectedOption = shouldResetPreSelectedOptionForAutocomplete
      ? null
      : this.state.selectedOption;

    this.setState({ isOpen, preSelectedOption }, () => {
      if (autocomplete) {
        if (isOpen) {
          this.inputRef.focus();
        }
        this.updateDisplayValueAndFilteredOptions(isOpen);
      } else if (isOpen) {
        this.selectPopupManagerRef &&
          this.selectPopupManagerRef.scrollToSelectedOption();
      }
    });
  };

  componentDidMount() {
    window.document.addEventListener("click", this.outEventListener);
    this.updateSelectedOptionFromValue(this.props.value);
  }

  componentWillUnmount() {
    window.document.removeEventListener("click", this.outEventListener);
  }

  // TODO: This should be using the nextProps.inputProps in the
  // componentDidReceiveProps case
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      value: nextValue,
      inputProps: nextInputsProps,
      resetToPlaceholder,
    } = nextProps;
    const {
      inputProps: prevInputProps,
      value: prevValue,
      placeholder,
    } = this.props;
    const { selectedOption } = this.state;

    if (resetToPlaceholder) {
      this.setState({
        selectedOption: null,
        preSelectedOption: null,
        displayValue: placeholder,
      });
    }

    if (
      (selectedOption && prevValue !== selectedOption.value) ||
      (!prevValue && nextValue)
    ) {
      this.updateSelectedOptionFromValue(nextValue);
    }

    // TODO: Avoid to create inputProps everytime on render,
    // so we can just do a simple comparation instead of deep
    if (!isEqual(prevInputProps, nextInputsProps)) {
      this.setState({ filteredOptions: nextInputsProps });
    }
  }

  fireOnChange(option) {
    const { onChange } = this.props;
    if (onChange) {
      const { onChange } = this.props;
      const selectedOrEmptyOption = option || EMPTY_ITEM;
      onChange(selectedOrEmptyOption.value, {
        isNewOption: selectedOrEmptyOption.isNewOption,
        label: selectedOrEmptyOption.label,
      });
    }
  }

  preSelectFirstOption() {
    const { filteredOptions } = this.state;
    const firstOption = filteredOptions[0];
    if (!isEmpty(filteredOptions)) {
      this.updatePreSelectedOption(setIsNewOptionAsFalse(firstOption));
    }
  }

  updateDisplayValueAndFilteredOptions(isOpen) {
    const { selectedOption } = this.state;
    const { placeholder, inputProps, resetForSearchHook } = this.props;
    const placeholderOrEmpty = isOpen ? "" : placeholder;
    const displayValue = selectedOption
      ? selectedOption.label
      : placeholderOrEmpty;
    this.setState({
      displayValue,
      filteredOptions: inputProps,
    });
    if (resetForSearchHook && !isOpen) {
      resetForSearchHook(RESET_SIGNAL);
      this.setState({ lastQuery: "" });
    }
  }

  updateSelectedOptionFromValue(value) {
    const { inputProps, defaultOption } = this.props;
    const formattedDefaultOption = {
      ...EMPTY_ITEM,
      label: defaultOption,
    };
    const selectedOption =
      value == null && defaultOption
        ? formattedDefaultOption
        : inputProps.find((option) => {
            return option.value === value && !!option.value;
          });
    if (selectedOption) {
      this.updateSelectedOption(selectedOption, true);
    }
  }

  render() {
    const {
      autocomplete,
      defaultOption,
      disabled,
      errorDisplayValidation,
      errorMessage,
      extraClassnames,
      formatDisplayValue,
      formatItemContent,
      grouped,
      inputPlaceholder,
      inputProps,
      isOptional,
      label,
      name,
      newOptionLabel,
      size,
      supportsNewOptionCreation,
      supportsNoResultsMessage,
      tabIndex,
      openWithKeyDown,
      autoFocus,
      readOnly,
      showLock,
    } = this.props;
    const {
      displayValue,
      isOpen,
      filteredOptions,
      selectedOption,
      preSelectedOption,
      lastQuery,
    } = this.state;

    const formElementClassnames = classnames("FormElement--select", {
      "FormElement--combobox": !!autocomplete,
      "FormElement--groupSelect": !!grouped,
      [extraClassnames]: !!extraClassnames,
      "has-readOnly": readOnly,
      [size]: !!size,
    });
    const selectClassnames = classnames("Select", {
      [`Select--${size}Size`]: true,
      Combobox: !!autocomplete,
      GroupSelect: !!grouped,
      "is-open": isOpen,
      "js-stopGlobalShortcutPropagation": true,
    });
    const options = autocomplete ? filteredOptions : inputProps;
    const hasCoincidence = options.some(
      (option) => option.label.toLowerCase() === lastQuery.toLowerCase(),
    );
    const showNewOption =
      supportsNewOptionCreation &&
      lastQuery.trim() !== "" &&
      (this.props.alwaysShowNewOption || !hasCoincidence);
    const groupClassnames = classnames({ "submitting-form": disabled });
    return (
      <FormElement
        {...(errorDisplayValidation && { errorDisplayValidation })}
        errorMessage={errorMessage}
        extraClassnames={formElementClassnames}
        isOptional={isOptional}
        readOnly={readOnly}
        showLock={showLock}
        label={label}
        name={name}
      >
        <div className={groupClassnames}>
          <SelectPopupManager
            autocomplete={autocomplete}
            extraClassnames={selectClassnames}
            isOpen={isOpen}
            lastQuery={lastQuery}
            options={options}
            openWithKeyDown={openWithKeyDown}
            popupProps={{
              defaultOption,
              formatItemContent,
              grouped,
              newOptionLabel: formatNewOptionLabel(newOptionLabel),
              onClickOption: this.handleClickOption,
              onMouseOverOption: this.updatePreSelectedOption,
              preSelectedOption,
              supportsNoResultsMessage,
            }}
            preSelectedOption={preSelectedOption}
            ref={(selectPopupManagerRef) =>
              (this.selectPopupManagerRef = selectPopupManagerRef)
            }
            selectedOption={selectedOption}
            showNewOption={showNewOption}
            tabIndex={tabIndex}
            toggleSelect={this.toggleSelect}
            updateDisplayValue={this.updateDisplayValue}
            updatePreSelectedOption={this.updatePreSelectedOption}
            updateSelectedOption={this.updateSelectedOption}
            autoFocus={autoFocus}
          >
            <SelectCurrentSelection
              autocomplete={autocomplete}
              disabled={disabled}
              displayValue={displayValue}
              formatDisplayValue={formatDisplayValue}
              inputPlaceholder={inputPlaceholder}
              isOpen={isOpen}
              onInputChange={this.handleInputChange}
              inputRef={(inputRef) => (this.inputRef = inputRef)}
              selectedOption={selectedOption}
              toggleSelect={this.toggleSelect}
            />
          </SelectPopupManager>
        </div>
      </FormElement>
    );
  }
}

export default Select;
