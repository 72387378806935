import React from "react";
import { TabPane } from "components_new/elements/Tab";
import Segment from "components_new/atoms/Segment";
import TopBar from "./TableActions/TopBar";
import { isEmpty } from "lodash";
import "./CompanyTab.scss";

export default function CompanyTabPane(props) {
  const {
    type,
    isLoading,
    totalCompanies,
    filterTags,
    tagSuggestions,
    headquarters,
    data,
    viewStyle,
    setViewStyle,
    updateFilters,
    filters,
    isSearchActive,
    children,
    filterIndustries,
    industriesSuggestions,
    coInvestors,
  } = props;

  const isEmptyView = isEmpty(data) && !isLoading && !isSearchActive;

  return (
    <TabPane>
      <Segment shadow extraClassNames="CompanyTab">
        {!isEmptyView && (
          <TopBar
            filters={filters}
            updateFilters={updateFilters}
            totalCompanies={totalCompanies}
            curentCompanies={data?.length}
            coInvestors={coInvestors}
            type={type}
            isLoading={isLoading}
            filterTags={filterTags}
            tagSuggestions={tagSuggestions}
            headquarters={headquarters}
            viewStyle={viewStyle}
            setViewStyle={setViewStyle}
            filterIndustries={filterIndustries}
            industriesSuggestions={industriesSuggestions}
          />
        )}
        {children({
          ...props,
          isEmptyView,
        })}
      </Segment>
    </TabPane>
  );
}
