import React from "react";
import { tagProptype } from "PropTypes";
import PropTypes from "prop-types";
import CloseIcon from "-!svg-react-loader?!assets/icons/utility/close.svg?name=CloseIcon";
import Button from "components/shared/Button";

function Tag({ tag, onRemove, size }) {
  const buttonSize = size === "tiny" ? size : "small";
  return (
    <li className="InputTag-list-item">
      <span className="InputTag-list-value">{tag.name}</span>
      <Button
        customClass="InputTag-list-delete Button--empty"
        onClick={() => onRemove(tag.id)}
        size={buttonSize}
      >
        <CloseIcon />
      </Button>
    </li>
  );
}

Tag.propTypes = {
  tag: tagProptype.isRequired,
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  size: PropTypes.string,
};

export default Tag;
