import React, { Fragment } from "react";
import TableColumns from "components/shared/Table/TableColumns";

function DocumentViewerRow({
  index,
  data,
  columns,
  updateForm,
  errors,
  currentOrganizationUsername,
}) {
  return (
    <TableColumns
      columns={columns}
      renderColumn={(columnProp) => {
        const { renderValue, renderForm } = columnProp;
        return (
          <Fragment>
            <div className="PreviewRow u-textWithEllipsis">
              {renderValue(data, currentOrganizationUsername)}
            </div>
            <div className="EditRow">
              {renderForm(data, updateForm, index, errors)}
            </div>
          </Fragment>
        );
      }}
    />
  );
}

export default DocumentViewerRow;
