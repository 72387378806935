import React from "react";
import MightyLogo from "components_new/atoms/MightyLogo";

function EmptyResultView({ query }) {
  return (
    <div className="EmptyResultView">
      <p className="EmptyResultView-emptyResultsGrettings">
        Nothing. Nada. Nil. Zilch.
      </p>
      <p className="EmptyResultView-emptyResultsNote">
        No results for &quot;
        <span className="EmptyResultView-emptyResultsNoteHolder">{query}</span>
        &quot;
      </p>
      <MightyLogo theme="secondary" size="medium" />
    </div>
  );
}

export default EmptyResultView;
