import React, { useContext, useEffect } from "react";
import { SidebarComponentsContext } from "components_new/SidebarComponents/SidebarComponentsWrapper";
import { tierDropdown } from "config/tierConfig";
import { isEmpty } from "lodash";

import AddCompanyForm from "./AddCompanyForm";
import useCompany from "store/companies/company/hooks";
import useOrganization from "store/organizations/hooks";
import mapCollectionToOptions from "components_new/lib/mapCollectionToOptions";

function AddCompanyFormWrapper({ ownName }) {
  const {
    getComponentState,
    registerForm,
    closeSidebar,
    updateSidebarComponent,
    onUnmount,
  } = useContext(SidebarComponentsContext);
  const { componentData, submitTo, onChange } = getComponentState(ownName);

  const { createCompany } = useCompany();
  const { currentOrganization } = useOrganization();

  useEffect(() => {
    if (!isEmpty(ownName)) {
      registerForm(ownName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const primarySectors = mapCollectionToOptions({
    collection: currentOrganization.primary_sectors,
    attrName: "name",
  });

  return (
    <AddCompanyForm
      ownName={ownName}
      submitTo={submitTo}
      componentData={componentData}
      createCompany={createCompany}
      closeSidebar={closeSidebar}
      updateSidebarComponent={updateSidebarComponent}
      onUnmount={onUnmount}
      onChange={onChange}
      tierOptions={tierDropdown}
      primarySectors={primarySectors}
      prepopulatedCompanyTags={currentOrganization.prepopulated_company_tags}
      prepopulatedSectors={currentOrganization.prepopulated_sectors}
    />
  );
}

export default AddCompanyFormWrapper;
