import React from "react";
import { Sidebar as SemanticSidebar } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/sidebar.css";
import "./Sidebar.scss";
import classNames from "classnames";
import PropTypes from "prop-types";

function Sidebar({ as, children, extraClassnames, className, ...otherProps }) {
  const SidebarClassnames = classNames("Sidebar", {
    [className]: !!className,
    [extraClassnames]: !![extraClassnames],
  });

  return (
    <SemanticSidebar as={as} className={SidebarClassnames} {...otherProps}>
      {children}
    </SemanticSidebar>
  );
}

Sidebar.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  children: PropTypes.node,
  className: PropTypes.string,
  extraClassnames: PropTypes.string,
};

export default Sidebar;
