import filestack from "filestack-js";
import Api from "lib/Api";

const config = {
  client: null,
};

export function setApiKey(apiKey) {
  config.client = filestack.init(apiKey);
}

export function getLastUploadSecurity() {
  return config.client.getSecurity();
}

function uploadMockForCypress(...args) {
  const config = args[1] || {};
  if (config.onProgress) {
    config.onProgress({ totalPercent: 100 });
  }
  return {
    filename: "like_image.png",
    mime_type: "image/png",
    handle: "1js943msxsk",
    size: 1024,
  };
}

// See https://filestack.github.io/filestack-js/classes/client.html#upload for arguments
export function uploadToFilestack(...args) {
  return Api.get("/upload-policy", { withoutOrganization: true }).then(
    ({ data: { policy, signature } }) => {
      config.client.setSecurity({ policy, signature });
      if (window.Cypress) {
        return uploadMockForCypress(...args);
      } else {
        return config.client.upload(...args);
      }
    },
  );
}
