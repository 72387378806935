import React, { Fragment, useState } from "react";
import ListItem from "components_new/atoms/InlineLabelValue/ListItem";
import NestedList from "components_new/atoms/InlineLabelValue/NestedList";
import Text from "components_new/atoms/Text";
import Value from "components_new/atoms/Value";
import Icon from "components_new/atoms/Icon";
import { List } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/list.css";
import "./InlineValueList.scss";

function renderListItemOrSeparator(
  type,
  value,
  index,
  icon,
  label,
  url,
  precision,
  ...props
) {
  if (type === "separator") {
    return <hr key={index} className="SeparatorItem" />;
  } else if (value || label) {
    return (
      <ListItem key={index} icon={icon} label={label} url={url}>
        <Value type={type} precision={precision} {...props}>
          {value}
        </Value>
      </ListItem>
    );
  } else {
    return null;
  }
}

function InlineValueList({ data, previewElementsNumber }) {
  // showAll functionality
  const shouldUseCursor =
    !!previewElementsNumber && previewElementsNumber <= data.length;
  const [expanded, setExpanded] = useState(shouldUseCursor ? false : true);
  const concernedData =
    !!data && shouldUseCursor && !expanded
      ? data.slice(0, previewElementsNumber)
      : data;

  return (
    <Fragment>
      <List link className="InlineValueList" as="dl">
        {concernedData.map((item, index) => {
          if (item.list) {
            return <NestedList key={index} index={index} data={item} />;
          }
          return renderListItemOrSeparator(
            item.type,
            item.value,
            index,
            item.icon,
            item.label,
            item.url,
            item.precision,
            ...item,
          );
        })}
      </List>
      {shouldUseCursor && (
        <Text
          type="body-2-bold"
          color="brand-secondary"
          onClick={() => setExpanded((s) => !s)}
        >
          {!expanded ? (
            <Fragment>
              Show More{" "}
              <Icon
                icon="ri-arrow-down-s-line"
                origin="remixIcon"
                size="tiny"
              />
            </Fragment>
          ) : (
            <Fragment>
              Show Less{" "}
              <Icon icon="ri-arrow-up-s-line" origin="remixIcon" size="tiny" />
            </Fragment>
          )}
        </Text>
      )}
    </Fragment>
  );
}

export default InlineValueList;
