import { RECEIVE_VIEW } from "./actions";

const initialState = {
  companyView: {},
  investmentView: {},
  organizationView: {},
  portfolioView: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_VIEW: {
      return {
        ...state,
        [action.viewName]: {
          ...state[action.viewName],
          [action.viewId]: action.view,
        },
      };
    }
    default:
      return state;
  }
}
