import React from "react";
import PropTypes from "prop-types";
import {
  TableHeader,
  TableRow,
  SortableTableHeaderCell,
  SortedTable,
  TableBody,
} from "components_new/elements/Table";
import InvestmentRow from "components_new/views/Portfolios/PortfolioTables/shared/InvestmentRow";
import Tooltip from "components_new/atoms/Tooltip/Tooltip";
import { isEmpty } from "lodash";
import { companyAvatarPropType } from "PropTypes";
import { defaultOrderByCompanyName } from "components_new/views/Portfolios/config";
import getColumnHeaders from "../../shared/getColumnHeaders";

export default function InvestmentsDefaultTable({
  data,
  isLoading,
  onSelection,
  orgUsername,
  showClosingDocuments,
}) {
  const columnHeaders = getColumnHeaders("defaultTable");
  const renderHeaders = (handleSort, isSorted) => {
    return (
      <TableHeader>
        <TableRow>
          {columnHeaders.map((columnHeader, index) => {
            return (
              <SortableTableHeaderCell
                key={index}
                isGrouped={false}
                isSorted={isSorted}
                handleSort={handleSort}
                {...columnHeader}
              >
                <Tooltip
                  position={columnHeader.tooltipPosition}
                  content={columnHeader.tooltipContent}
                  disabled={!columnHeader.hasTooltip}
                  trigger={<div>{columnHeader.title}</div>}
                />
              </SortableTableHeaderCell>
            );
          })}
        </TableRow>
      </TableHeader>
    );
  };

  return (
    <SortedTable
      tableData={data}
      onSelection={onSelection}
      className="HoldingsTable attached"
      isLoading={isLoading}
      renderHeaders={renderHeaders}
      skeletonStructure={{ rows: 8, headers: columnHeaders }}
      defaultOrder={defaultOrderByCompanyName}
      shadow={false}
    >
      {({ data }) => (
        <TableBody isEmptyData={isEmpty(data)}>
          {!isEmpty(data) &&
            data.map((investment) => (
              <InvestmentRow
                key={investment.id}
                investment={investment}
                orgUsername={orgUsername}
                showClosingDocuments={showClosingDocuments}
              />
            ))}
        </TableBody>
      )}
    </SortedTable>
  );
}

InvestmentsDefaultTable.defaultProps = {
  investments: [],
  companies: [],
};

InvestmentsDefaultTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      company_name: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      quantity: PropTypes.number,
      fully_diluted_shares_percentage: PropTypes.number,
      cost_basis: PropTypes.string,
      total_valuation_amount: PropTypes.string,
      multiple: PropTypes.string,
    }),
  ),
  onSelection: PropTypes.func,
  companies: PropTypes.shape({ companyAvatarPropType }),
  isLoading: PropTypes.bool,
  orgUsername: PropTypes.string,
  showClosingDocuments: PropTypes.bool,
};
