import React, { useState, useEffect } from "react";
import Table from "./Table";
import "./SortedTable.scss";
import classNames from "classnames";
import TableBody from "./TableBody";
import PropTypes from "prop-types";
import { ascend, descend, prop, sortWith } from "ramda";
import { propFunction } from "./config";
import { isEmpty } from "lodash";
import SkeletonRow from "./SkeletonRow";

const renderSkeleton = (skeletonStructure) => {
  const { rows, headers, expandable } = skeletonStructure;
  const skeletonRows = [];
  for (let i = 0; i < rows; i++)
    skeletonRows.push(
      <SkeletonRow
        key={i}
        headersStructure={headers}
        expandable={expandable}
      />,
    );
  return <TableBody>{skeletonRows}</TableBody>;
};

const orderColumn = (column, direction, type) => {
  const propFunc = (propFunction[type] || prop)(column);
  const func = direction === "ascending" ? ascend(propFunc) : descend(propFunc);
  return sortWith([func]);
};

const DEFAULT_DIRECTION = "ascending";

function SortedTable({
  tableData,
  children,
  onSelection,
  className,
  renderHeaders,
  isLoading,
  expandable,
  defaultOrder,
  withCheckbox,
  skeletonStructure,
  ...otherProps
}) {
  const [column, setColumn] = useState("");
  const [data, setData] = useState(null);
  const [direction, setDirection] = useState(DEFAULT_DIRECTION);

  useEffect(() => {
    if (!isEmpty(tableData)) {
      setColumn("");
      setDirection(DEFAULT_DIRECTION);
    }
    setData(defaultOrder(tableData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  const handleSort = (
    clickedColumn,
    defaultDirection = "ascending",
    type = "string",
  ) => {
    let customDirection = defaultDirection;
    if (column === clickedColumn) {
      /*  eslint-disable unicorn/no-nested-ternary */
      customDirection =
        direction === ""
          ? defaultDirection
          : direction === "ascending"
          ? "descending"
          : "ascending";
      /*  eslint-enable unicorn/no-nested-ternary */
      setDirection(customDirection);
    } else {
      setColumn(clickedColumn);
      setDirection(customDirection);
    }
    const order = orderColumn(clickedColumn, customDirection, type);
    setData(order(data));
  };

  const isSorted = (columnName) => (columnName === column ? direction : null);
  const classes = classNames("SortedTable", {
    [className]: !!className,
    expandable: expandable,
    WithCheckbox: withCheckbox,
    "has-data": !isEmpty(data),
    "is-loading": isLoading,
  });

  const [selectAllStatus, setSelectAllStatus] = useState({
    checked: false,
    indeterminate: false,
  });

  const onClickSelectRow = (rowIndex) => {
    const updatedData = [...data];
    updatedData[rowIndex].isSelected = !updatedData[rowIndex].isSelected;

    const selectedRowsCount = data.filter((row) => row.isSelected).length;
    const isAllRowsSelected = selectedRowsCount == data.length;

    setData(updatedData);
    setSelectAllStatus({
      checked: isAllRowsSelected,
      indeterminate: selectedRowsCount == 0 ? false : !isAllRowsSelected,
    });
  };

  const onClickSelectAll = (checkboxValues) => {
    const { checked, indeterminate } = checkboxValues;

    let updatedData = [];
    const updatedStatus = { isAllchecked: false, isIndeterminate: false };

    if (checked && !indeterminate) {
      updatedData = data.map((row) => {
        return { ...row, isSelected: true };
      });
      updatedStatus.isAllchecked = checked;
      updatedStatus.isIndeterminate = indeterminate;
    } else {
      updatedData = data.map((row) => {
        return { ...row, isSelected: false };
      });
    }

    setData(updatedData);
    setSelectAllStatus({
      checked: updatedStatus.isAllchecked,
      indeterminate: updatedStatus.isIndeterminate,
    });
  };

  return (
    <Table className={classes} {...otherProps} sortable celled>
      {renderHeaders && renderHeaders(handleSort, isSorted)}
      {isLoading
        ? renderSkeleton({ ...skeletonStructure, expandable: expandable })
        : children({
            data,
            handleSort,
            isSorted,
            onClickSelectRow,
            onClickSelectAll,
            selectAllStatus,
          })}
    </Table>
  );
}

SortedTable.defaultProps = {
  expandable: false,
  defaultOrder: (data) => data,
  withCheckbox: false,
};

SortedTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.shape({})),
  children: PropTypes.func,
  onSelection: PropTypes.func,
  className: PropTypes.string,
  renderHeaders: PropTypes.func,
  isLoading: PropTypes.bool,
  expandable: PropTypes.bool,
  defaultOrder: PropTypes.func,
  withCheckbox: PropTypes.bool,
  skeletonStructure: PropTypes.shape({
    rows: PropTypes.number,
    headers: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default SortedTable;
