import React, { useState } from "react";
import QueuesView from "components_new/views/Queues/QueuesView";
import Loader from "components/shared/Loader";
import useOrganization from "store/organizations/hooks";
import { useQueues } from "components_new/views/Queues/hooks/queries/useQueues";
import { useParams } from "react-router-dom";

function QueuesViewWrapper() {
  const [filters, updateFilters] = useState({ query: "", showAll: true });
  const { data, isLoading } = useQueues({ filters });
  const { organizationUsername } = useParams();
  const { currentOrganizationName } = useOrganization();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <QueuesView
      queueLabel={data?.label}
      filters={filters}
      updateFilters={updateFilters}
      pendingTasksCount={data?.pendingTasksCount}
      snoozedTasksCount={data?.snoozedTasksCount}
      completedTasksCount={data?.completedTasksCount}
      organizationName={currentOrganizationName}
      organizationUsername={organizationUsername}
    />
  );
}

export default QueuesViewWrapper;
