class Errors {
  constructor(errors) {
    this.errors = errors;
  }

  for(field) {
    const fieldErrors = this.errors[field];
    // TODO: Convert errors to sentence instead of getting just the first error for a field
    return fieldErrors ? fieldErrors[0] : null;
  }

  without(field) {
    return new Errors({ ...this.errors, [field]: null });
  }

  isEmpty() {
    return Object.keys(this.errors).length === 0;
  }

  toObject() {
    return Object.keys(this.errors).reduce(
      (errorsObject, fieldName) => ({
        ...errorsObject,
        [fieldName]: this.for(fieldName),
      }),
      {},
    );
  }
}

export default Errors;
