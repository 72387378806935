import Header from "components_new/atoms/Header";
import Icon from "components_new/atoms/Icon";
import classNames from "classnames";
import Tooltip from "components_new/atoms/Tooltip";
import copy from "copy-to-clipboard";
import React, { useState, Fragment } from "react";
import "./Education.scss";

const Education = ({
  currentOrganizationName,
  queueLabel,
  isOnModal = false,
}) => {
  const [tooltipIsOpen, setTooltipOpen] = useState(false);
  const [tooltipTimeout, setTooltipTimeout] = useState({});

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
    setTooltipTimeout(
      setTimeout(() => {
        setTooltipOpen(false);
      }, 1000),
    );
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
    clearTimeout(tooltipTimeout);
  };

  const edutacionClassnames = classNames("QueuesView-Education", {
    "is-onModal": isOnModal,
  });

  const queueAddress = `q+${queueLabel}@${process.env.QUEUE_DOMAIN}`;

  return (
    <div className={edutacionClassnames}>
      <div className="Education-Wrapper">
        {isOnModal ? (
          <Fragment>
            <Header as="h3" extraClassName="Education-label">
              {currentOrganizationName}'s Queue
            </Header>
            <h2 className="Education-Title">How it works</h2>
          </Fragment>
        ) : (
          <h2 className="Education-Title">{currentOrganizationName}'s Queue</h2>
        )}

        <div className="Education-QueueAddressCard">
          <div>
            <p className="QueueAddressCard-Label">
              {currentOrganizationName}'S QUEUE EMAIL ADDRESS
            </p>
            <p className="QueueAddressCard-Address">{queueAddress}</p>
          </div>
          <div className="QueueAddressCard-CopyBtn">
            <Tooltip
              position="top center"
              content="Copied!"
              on="click"
              open={tooltipIsOpen}
              onClose={handleTooltipClose}
              onOpen={handleTooltipOpen}
              trigger={
                <Icon
                  icon="ri-links-line"
                  extraClassnames="icon-pointer"
                  size="tiny"
                  onClick={() => copy(queueAddress)}
                />
              }
            />
          </div>
        </div>

        {!isOnModal && (
          <Header as="h3" extraClassName="Education-Subtitle">
            How it works
          </Header>
        )}
        <p>
          Queue gives {currentOrganizationName} the ability to pull relevant
          information into Mighty via a unique email link to more quickly manage
          tasks related to investment management.
        </p>
        <ol className="Education-StepList">
          <li>
            Use the link above to forward emails and attachments to Queue as a
            new task.
          </li>
          <li>Organize and act on the new task.</li>
          <li>Mark the Pending task as Complete.</li>
          <li>Get on with your day.</li>
        </ol>
        <Header as="h3" extraClassName="Education-Subtitle">
          Coming soon
        </Header>
        <ul>
          <li>Ability to assign a task to someone in your organization.</li>
          <li>Ability to create unique tasks within your Queue.</li>
        </ul>
      </div>
    </div>
  );
};

export default Education;
