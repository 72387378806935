import "core-js";
import "regenerator-runtime/runtime";
import "initialization/configureTimezone";
import loadServerSettings from "initialization/loadServerSettings";
import configureSentry from "initialization/configureSentry";
import createStore from "initialization/createStore";
import configureFileStack from "initialization/configureFileStack";
import configureHeap from "initialization/configureHeap";
import renderApplication from "initialization/renderApplication";
import Api from "lib/Api";

import "./index.scss?global";
import "stylesheets_new/index.scss";

async function initialize() {
  const config = await loadServerSettings();
  configureSentry(config.sentryDSN);
  configureHeap(config.heapApiKey);
  configureFileStack(config.filepickerApiKey);

  const store = createStore(config);

  Api.configure({ baseUrl: config.apiUrl, store });
  renderApplication(store);
}

initialize();
