import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import TextInput from "components_new/atoms/Input";
import Button from "components_new/atoms/Button";
import { toString } from "lodash";
import usePrevious from "lib/hooks/usePrevious";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { animationFromLeft } from "lib/animations";
import AuthenticationContext from "components/Mighty/AuthenticationContext";
import Api from "lib/Api";
import loadTermsMapper from "initialization/loadTermsMapper";

const CODE_INPUT_LENGTH = 6;

// TODO: Change classNames according to VerifyCodeForm
function VerifyMessage({ error, resendCodeSucceeded, isCodeCompleted }) {
  const shouldShowError = error && !resendCodeSucceeded && isCodeCompleted;
  const message = shouldShowError
    ? "We weren’t able to verify your phone with the number you entered."
    : "We just sent a verification code to your phone.\nIt may take a few minutes to arrive.";

  const groupClassnames = "LoginForm-message";

  const textGroupClassnames = classNames({
    "has-errors": shouldShowError,
    VerifyMessage: true,
  });

  const keyMessageType = shouldShowError
    ? "just-sent-verification-code"
    : "unable-to-verify";

  return (
    <p className={groupClassnames}>
      <motion.span
        key={`VerifyMessage-${keyMessageType}`}
        initial="hidden"
        animate="visible"
        variants={animationFromLeft()}
        transition={{ duration: 0.15 }}
        className={textGroupClassnames}
      >
        {message}
      </motion.span>
    </p>
  );
}

function VerifyCodeForm({
  children,
  afterSendingCode,
  nextPath,
  doNotRedirect,
}) {
  const navigate = useNavigate();

  const [shouldRestartAnimation, setShouldRestartAnimation] = useState(false);
  const [codeInput, setCodeInput] = useState("");

  const isCodeCompleted = useMemo(
    () => codeInput.length === CODE_INPUT_LENGTH,
    [codeInput],
  );

  const {
    currentUser,
    mfaToken,
    mfaNextPath,
    persistAuthentication,
    completeMfaAuthentication,
  } = useContext(AuthenticationContext);

  const phoneNumber = currentUser.phone_number;

  const [isResendingCode, setIsResendingCode] = useState(false);
  const [resendCodeSucceeded, setResendCodeSucceeded] = useState(false);
  const previousIsResendingCode = usePrevious(isResendingCode);
  const resendCode = useCallback(async () => {
    setResendCodeSucceeded(false);
    setIsResendingCode(true);
    try {
      return await Api.post(
        "/session/resend_code",
        {
          authentication: {
            intermediate_auth_token: mfaToken,
          },
          phone_number: phoneNumber,
        },
        { withoutOrganization: true },
      );
    } catch (error) {
      setIsResendingCode(false);
      setResendCodeSucceeded(true);
    }
  }, [mfaToken, phoneNumber]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [verificationFailed, setVerificationFailed] = useState(false);
  const verifyCode = useCallback(
    async (codeInput) => {
      try {
        const response = await Api.post(
          "/session/verify",
          {
            auth_code: codeInput,
            authentication: {
              intermediate_auth_token: mfaToken,
            },
          },
          { withoutOrganization: true },
        );
        const { id, token, expires } = response.data.authentication;

        if (id) {
          const expirationDate = new Date(expires);
          persistAuthentication({ id, token, expirationDate });
          completeMfaAuthentication();
          loadTermsMapper();

          if (afterSendingCode) {
            afterSendingCode();
          }
          if (!doNotRedirect) {
            // TODO: If invitations: /username/personal-settings/organizations
            navigate(nextPath || mfaNextPath || "/");
          }
        } else {
          setIsSubmitting(false);
        }
      } catch (error) {
        setIsSubmitting(false);
        setVerificationFailed(true);
      }
    },
    [
      navigate,
      mfaToken,
      mfaNextPath,
      persistAuthentication,
      completeMfaAuthentication,
      afterSendingCode,
      nextPath,
      doNotRedirect,
    ],
  );

  const handleChange = async (value) => {
    setVerificationFailed(false);
    const code = toString(value);
    if (code.length === CODE_INPUT_LENGTH) {
      setCodeInput(code);
      await verifyCode(code);
    }
  };

  useEffect(() => {
    let timer = null;
    if (previousIsResendingCode !== isResendingCode && !isResendingCode) {
      setShouldRestartAnimation(true);
      setTimeout(() => {
        setShouldRestartAnimation(false);
      }, 0);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [previousIsResendingCode, isResendingCode, setShouldRestartAnimation]);

  const disabled =
    codeInput.length !== CODE_INPUT_LENGTH ||
    isSubmitting ||
    verificationFailed;

  const groupClassnames = classNames({
    VerifyCodeForm: true,
    "submitting-form": isSubmitting || isResendingCode,
  });

  return (
    <form className={groupClassnames}>
      {shouldRestartAnimation ? null : (
        <motion.div
          initial="hidden"
          animate="visible"
          variants={animationFromLeft()}
          transition={{ duration: 0.15 }}
        >
          {children}
          <VerifyMessage
            error={verificationFailed}
            resendCodeSucceeded={resendCodeSucceeded}
            isCodeCompleted={isCodeCompleted}
          />
          <motion.div
            initial="hidden"
            animate="visible"
            variants={animationFromLeft({ delay: 0.1 })}
            transition={{ duration: 0.15 }}
          >
            <TextInput
              maxLength={CODE_INPUT_LENGTH}
              onChange={(_, response) => handleChange(response.value)}
              error={isCodeCompleted && verificationFailed}
              autoFocus
              aria-label="code"
            />
          </motion.div>
          <Button
            primary
            type="submit"
            disabled={disabled}
            loading={isSubmitting}
            onClick={() => verifyCode(codeInput)}
          >
            Submit
          </Button>
          <motion.p
            initial="hidden"
            animate="visible"
            variants={animationFromLeft({ delay: 0.2 })}
            transition={{ duration: 0.15 }}
            className=" DidntReceiveCode"
          >
            Didn’t receive the code?
            <a onClick={resendCode} className="SendCodeAgain">
              Try sending again
            </a>
          </motion.p>
        </motion.div>
      )}
    </form>
  );
}

VerifyCodeForm.propTypes = {
  children: PropTypes.node,
  nextPath: PropTypes.string,
  afterSendingCode: PropTypes.func,
  doNotRedirect: PropTypes.bool,
};

export default VerifyCodeForm;
