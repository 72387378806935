import React, { useEffect, useState } from "react";
import FormFooter from "components_new/SidebarComponents/SidebarForm/FormFooter";
import Tags from "components/shared/Tags";
import Input from "components_new/atoms/Input";
import useForm from "lib/hooks/useForm";
import Form from "components_new/atoms/Form";
import RequiredNote from "components_new/SidebarComponents/shared/RequiredNote";
import DropdownForm from "components_new/atoms/DropdownForm";
import Industry from "components/shared/Industry";
import ContactRoles from "components_new/SidebarComponents/forms/groups/ContactRoles";
import FormGroup from "components_new/elements/FormGroup";
import ContactEmails from "components_new/SidebarComponents/forms/groups/ContactDetails/ContactEmails";

import { tierDropdown } from "config/tierConfig";
import { isEmpty } from "lodash";
import { ENTER_KEY } from "config/keyCodes";
import "./AddContactForm.scss";

const initialValues = {
  formData: {
    first_name: "",
    last_name: "",
    emails: [],
    tags: [],
    tier: null,
    roles_attributes: [],
  },
};

function AddContactForm({
  ownName,
  submitTo,
  componentData,
  createContact,
  closeSidebar,
  updatePrimaryForm,
  onUnmount,
  validateContact,
  prepopulatedContactTags,
  prepopulatedSectors,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (clientData) => {
    setIsLoading(true);
    validateContact(clientData)
      .then(() => {
        if (!isEmpty(submitTo)) {
          const [primaryRol] = clientData.roles_attributes;
          const formData = {
            ...clientData,
            company_id: !isEmpty(primaryRol) && primaryRol.company_id,
          };

          updatePrimaryForm(formData);
          onUnmount(ownName, submitTo);
        } else {
          createContact(clientData)
            .then((_) => {
              setIsLoading(false);
              closeSidebar();
            })
            .catch((error) => {
              handleErrors(error);
              handleScrollView(error);
              setIsLoading(false);
            });
        }
      })
      .catch((error) => handleErrors(error))
      .finally(() => setIsLoading(false));
  };

  const {
    formData,
    handleChange,
    handleSubmit,
    handleErrors,
    handleBatchChange,
    handleScrollView,
    errors,
    touched,
  } = useForm(initialValues, onSubmit, { strictValidation: true });

  useEffect(() => {
    if (!isEmpty(componentData)) {
      handleBatchChange(componentData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentData]);

  function checkKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      e.preventDefault();
    }
  }

  const {
    first_name,
    last_name,
    emails,
    tags,
    location,
    tier,
    industries,
  } = formData;

  const isSubmitButtonDisabled = isEmpty(first_name) && isEmpty(last_name);

  return (
    <Form className="AddContactForm" onKeyDown={(e) => checkKeyDown(e)}>
      {/* TODO: use onSubmit when addressing:
      https://www.pivotaltracker.com/story/show/176196148 */}
      <FormGroup
        title="Add New Referrer"
        subtitle="Information about the new referrer."
        isExpanded={true}
      >
        <Input
          name="first_name"
          label="First name"
          placeholder="e.g. John"
          value={first_name}
          isRequired
          onChange={(e, { value }) => {
            handleChange("first_name", value);
          }}
          error={!!errors.for("first_name")}
          errorMessage={errors.for("first_name")}
          size="tiny"
        />
        <Input
          name="last_name"
          label="Last name"
          placeholder="e.g. Harrington"
          isRequired
          value={last_name}
          onChange={(e, { value }) => {
            handleChange("last_name", value);
          }}
          error={!!errors.for("last_name")}
          errorMessage={errors.for("last_name")}
          size="tiny"
        />
        <ContactEmails
          value={emails}
          onChange={(value) => handleChange("emails", value)}
          errors={errors}
        />
        <Input
          label="Location"
          value={location}
          onChange={(e, { value }) => {
            handleChange("location", value);
          }}
          size="tiny"
        />
        <Industry
          label="Industries"
          createTagLabel="Create new industry"
          placeholder="Search industries"
          name="industries"
          taggedList={industries || []}
          onChange={(value) => handleChange("industries", value)}
          size="tiny"
          extraClassnames="IndustryTags"
          prepopulatedItems={prepopulatedSectors}
        />
        <Tags
          tagType="contact"
          label="Tags"
          name="tags"
          taggedList={tags}
          onChange={(value) => handleChange("tags", value)}
          error={!!errors.for("tags")}
          errorMessage={errors.for("tags")}
          size="tiny"
          prepopulatedItems={prepopulatedContactTags}
        />
        <DropdownForm
          label="Tier"
          placeholder="Select a tier"
          items={tierDropdown}
          onChange={(e, { value }) => handleChange("tier", value)}
          value={tier}
          size="tiny"
        />
      </FormGroup>
      <ContactRoles
        onChange={handleChange}
        errors={errors}
        submitTo={ownName}
        disabledCompanyCreation
      />
      <RequiredNote />
      <FormFooter
        componentName={ownName}
        onSubmit={handleSubmit}
        disableSubmit={isSubmitButtonDisabled}
        isLoading={isLoading}
        touched={touched}
        submitTextButton="Add Referrer"
      />
    </Form>
  );
}

export default AddContactForm;
