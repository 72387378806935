import React from "react";
import PropTypes from "prop-types";
import EllipsisDropdown from "components_new/elements/EllipsisDropdown";
import { dealProptype } from "PropTypes";
import { useNavigate } from "react-router-dom";

function DealActionsDropdown({
  deal,
  openCommitToDealModal,
  openPassFromDealModal,
  openUpdateDealModal,
  openDeleteDealModal,
  openReactivateDealModal,
  currentOrganizationUsername,
  // openChangeDealOwnerModal,
  // isCurrentOrganizationPrivate,
}) {
  const navigate = useNavigate();

  const dealActionsByStatus = {
    active: [
      {
        key: "0",
        text: "Commit to Deal",
        onClick: () => {
          openCommitToDealModal(deal);
        },
      },
      {
        key: "1",
        text: "Pass on Deal",
        onClick: () => openPassFromDealModal(deal),
      },
      {
        key: "2",
        text: "Edit Deal",
        onClick: () => openUpdateDealModal(deal.id),
      },
      /*
      {
        key: "3",
        text: "Change Owner",
        isDisabled: isCurrentOrganizationPrivate,
        onClick: () => openChangeDealOwnerModal(deal),
      },
      */
      {
        key: "3",
        text: "Delete Deal",
        onClick: () =>
          openDeleteDealModal(
            deal,
            deal.pipeline_id,
            currentOrganizationUsername,
          ),
      },
    ],
    committed: [
      {
        key: "0",
        text: "Edit Commitment",
        onClick: () => openCommitToDealModal(deal),
      },
      {
        key: "1",
        text: "Undo Commitment",
        onClick: () => openReactivateDealModal(deal),
      },
    ],
    passed: [
      {
        key: "0",
        text: "Edit Pass",
        onClick: () => openPassFromDealModal(deal),
      },
      {
        key: "1",
        text: "Undo Pass",
        onClick: () => openReactivateDealModal(deal),
      },
      {
        key: "2",
        text: "View Deal",
        onClick: () =>
          navigate(`/${currentOrganizationUsername}/deals/active/${deal.id}`),
      },
    ],
    invested: [
      {
        key: "2",
        text: "Edit Deal",
        onClick: () => openUpdateDealModal(deal.id),
      },
    ],
  };

  return (
    <EllipsisDropdown
      iconOrientation="vertical"
      iconSize="small"
      options={dealActionsByStatus[deal.status]}
    />
  );
}

DealActionsDropdown.propTypes = {
  deal: PropTypes.objectOf(dealProptype).isRequired,
  openCommitToDealModal: PropTypes.func.isRequired,
  openPassFromDealModal: PropTypes.func.isRequired,
  openUpdateDealModal: PropTypes.func.isRequired,
  openDeleteDealModal: PropTypes.func.isRequired,
  openReactivateDealModal: PropTypes.func.isRequired,
  openChangeDealOwnerModal: PropTypes.func.isRequired,
  currentOrganizationUsername: PropTypes.string,
  isCurrentOrganizationPrivate: PropTypes.bool,
};

export default DealActionsDropdown;
