import loadTermsMapper from "initialization/loadTermsMapper";
import loadOrganizations from "initialization/loadOrganizations";
import { useMutation } from "react-query";
import Api from "lib/Api";
import { useDispatch } from "react-redux";
import { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthenticationContext from "components/Mighty/AuthenticationContext";

const loginRequest = async (credentials) => {
  const { data } = await Api.post(
    "/session",
    {
      authentication: credentials,
    },
    {
      withoutOrganization: true,
      headers: {},
      params: {},
    },
  );
  return data;
};

export default function useLogin() {
  const { mutateAsync } = useMutation(loginRequest);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { setCurrentUser, persistAuthentication } = useContext(
    AuthenticationContext,
  );

  const handleError = useCallback((response, data) => {
    const { error: responseError, errors } = data;
    setIsSubmitting(false);
    if (response.status === 403) {
      throw { isAuthError: true, errorMessage: responseError };
    } else if (response.status === 422) {
      throw { hasValidationErrors: true, errors };
    }
  }, []);

  const handleSuccess = useCallback(
    async ({ authentication, user }, nextPath) => {
      let finalNextPath;
      if (authentication.intermediate_auth_token !== undefined) {
        setCurrentUser(user, {
          mfa: {
            token: authentication.intermediate_auth_token,
            nextPath,
          },
        });
        finalNextPath = "/verify-login";
      } else {
        const { id, token, expires } = authentication;
        const expirationDate = new Date(expires);

        persistAuthentication({ id, token, expirationDate });
        setCurrentUser(user);
        loadTermsMapper();

        const { currentOrganization, organizations } = await loadOrganizations(
          dispatch,
        );
        const hasLastViewedOrganization = !!user.last_viewed_organization_param;
        const lastViewedOrganization = organizations.find(
          (organization) =>
            organization.id === user.last_viewed_organization_param,
        );
        const goToOrganization = hasLastViewedOrganization
          ? `/${lastViewedOrganization.username}/dashboard`
          : `/${currentOrganization.username}/dashboard`;

        finalNextPath = nextPath || goToOrganization;
      }

      navigate(finalNextPath);
    },
    [dispatch, navigate, persistAuthentication, setCurrentUser],
  );

  const doLogin = useCallback(
    async (email, password, nextPath) => {
      setIsSubmitting(true);
      await mutateAsync(
        { email, password },
        {
          onSuccess: (resp) => handleSuccess(resp, nextPath),
          onError: (error) => handleError(error.response, error.data),
        },
      );
    },
    [handleSuccess, handleError, mutateAsync],
  );

  return { doLogin, isSubmitting };
}
