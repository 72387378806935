import React from "react";
import PropTypes from "prop-types";
import {
  investmentDataPropType,
  companyHoldingTotalsPropType,
  companyPropType,
  dealProptype,
  stageProptype,
  contactPropType,
  documentPropType,
  taskProptype,
} from "PropTypes";
import ActiveDealDetails from "./ActiveDealDetails";
import ActiveDealContentSkeleton from "./ActiveDealContentSkeleton";
import TaskList from "./TaskList";
import ActiveDealStageSelector from "./ActiveDealStageSelector";
import AdvanceStagesButtons from "./AdvanceStagesButtons";
import ActiveDealFiles from "components_new/views/Deals/ActiveDeals/ActiveDealContent/DealFiles";
import ScrollView from "components_new/elements/ScrollView";

function ActiveDealContent({
  isLoading,
  deal,
  pipelineId,
  stage,
  company,
  companyAvatars,
  investments,
  documents,
  contacts,
  stagesOptions,
  currentOrganizationUsername,
  openPassFromDealModal,
  openCommitToDealModal,
  openDeleteDealModal,
  openTaskForm,
  companyHoldingTotals,
  openEditCompanyForm,
  saveDealDocuments,
  updateDealDocument,
  removeDealDocument,
  updateDeal,
  updateDealStage,
  openEditDealForm,
  openContactDetailsView,
  onCompleteDealTask,
  onMovePendingDealTask,
  noteToHighlight,
  posts,
  tasks,
}) {
  if (isLoading) {
    return <ActiveDealContentSkeleton />;
  }
  return (
    <div className="ActiveDeals-SelectedDeal">
      <ActiveDealDetails
        deal={deal}
        pipelineId={pipelineId}
        dealContacts={contacts}
        dealStage={stage}
        openEditCompanyForm={openEditCompanyForm}
        company={company}
        companyAvatars={companyAvatars}
        investments={investments}
        companyHoldingTotals={companyHoldingTotals}
        openDeleteDealModal={openDeleteDealModal}
        documents={documents}
        saveDealDocuments={saveDealDocuments}
        updateFile={updateDealDocument}
        removeFile={removeDealDocument}
        stagesOptions={stagesOptions}
        updateDeal={updateDeal}
        updateDealStage={updateDealStage}
        openEditDealForm={openEditDealForm}
        openContactDetailsView={openContactDetailsView}
        currentOrganizationUsername={currentOrganizationUsername}
        isLoading={isLoading}
        noteToHighlight={noteToHighlight}
        posts={posts}
      />

      <ScrollView
        scrollY={true}
        extraClassnames="ActiveDeals-SelectedDeal-ActionMenu"
      >
        <AdvanceStagesButtons
          deal={deal}
          stagesOptions={stagesOptions}
          stage={stage}
          pipelineId={pipelineId}
          updateDealStage={updateDealStage}
          openPassFromDealModal={openPassFromDealModal}
          openCommitToDealModal={openCommitToDealModal}
        />
        <ActiveDealStageSelector
          deal={deal}
          stagesOptions={stagesOptions}
          updateDealStage={updateDealStage}
          openCommitToDealModal={openCommitToDealModal}
        />
        <TaskList
          tasks={tasks}
          onComplete={onCompleteDealTask}
          undoComplete={onMovePendingDealTask}
          openTaskForm={openTaskForm}
        />
        <ActiveDealFiles
          documents={documents}
          dealId={deal.id}
          pipelineId={deal.pipeline_id}
          saveDealDocuments={saveDealDocuments}
          currentOrganizationUsername={currentOrganizationUsername}
          updateFile={updateDealDocument}
          removeFile={removeDealDocument}
          isInView={true}
          componentSize="mini"
        />
      </ScrollView>
    </div>
  );
}

ActiveDealContent.propTypes = {
  isLoading: PropTypes.bool,
  deal: dealProptype,
  pipelineId: PropTypes.string,
  stage: stageProptype,
  investments: investmentDataPropType,
  documents: PropTypes.arrayOf(documentPropType),
  contacts: PropTypes.arrayOf(contactPropType),
  company: companyPropType,
  companyAvatars: PropTypes.arrayOf(companyPropType),
  companyHoldingTotals: companyHoldingTotalsPropType,
  stagesOptions: PropTypes.arrayOf(PropTypes.object),
  currentOrganizationUsername: PropTypes.string.isRequired,
  updateDeal: PropTypes.func,
  updateDealStage: PropTypes.func,
  saveDealDocuments: PropTypes.func,
  updateDealDocument: PropTypes.func,
  removeDealDocument: PropTypes.func,
  openPassFromDealModal: PropTypes.func,
  openCommitToDealModal: PropTypes.func,
  openDeleteDealModal: PropTypes.func,
  openEditCompanyForm: PropTypes.func,
  posts: PropTypes.arrayOf(PropTypes.object),
  openEditDealForm: PropTypes.func,
  openContactDetailsView: PropTypes.func,
  onCompleteDealTask: PropTypes.func,
  onMovePendingDealTask: PropTypes.func,
  openTaskForm: PropTypes.func,
  tasks: PropTypes.arrayOf(taskProptype),
  noteToHighlight: PropTypes.string,
};

export default ActiveDealContent;
