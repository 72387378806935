import React from "react";
import { Message as SemanticMessage } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/table.css";
import "./Message.scss";

function MessageContent({ children, ...props }) {
  return (
    <SemanticMessage.Content {...props}>{children}</SemanticMessage.Content>
  );
}

export default MessageContent;
