import PropTypes from "prop-types";
import React from "react";
import CompanyIcon from "components/shared/CompanyIcon";
import VerifiedBadge from "components/shared/VerifiedBadge";
import classNames from "classnames";

function MiniCard({ logoUrl, title, verified, description, value, className }) {
  const miniCardClassNames = classNames("MiniCard", {
    [className]: !!className,
  });
  return (
    <div className={miniCardClassNames}>
      <div className="MiniCard-Icon">
        <CompanyIcon src={logoUrl} size="large" alt={`${title} minicard`} />
      </div>
      <div className="MiniCard-Content">
        <div className="MiniCard-Title">
          <div className="MiniCard-Title--text u-textWithEllipsis">{title}</div>
          {verified && <VerifiedBadge />}
        </div>
        <div className="MiniCard-Description u-textWithEllipsis">
          {" "}
          {description}{" "}
        </div>
        <div className="MiniCard-Value u-textWithEllipsis"> {value} </div>
      </div>
    </div>
  );
}

MiniCard.propTypes = {
  logoUrl: PropTypes.string,
  title: PropTypes.string,
  verified: PropTypes.bool,
  description: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
};

export default MiniCard;
