import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Tab from "components_new/elements/Tab";
import { panes, panesTabIndex } from "./CompanyTab/config";
import { VIEW_STYLES } from "./CompanyTab/TableActions/ViewStyleSelector";
import "./CompanyDirectoryView.scss";

const DEFAULT_TAB = panesTabIndex.all;

function CompanyDirectoryView({
  isLoading,
  companies,
  totalCompanies,
  coInvestors,
  params,
  tagSuggestions,
  headquarters,
  filterTags,
  updateFilters,
  filters,
  isSearchActive,
  handleOpenEditCompanyForm,
  filterIndustries,
  industriesSuggestions,
}) {
  const { tab: currentTab, organizationUsername } = params;
  const [activeTabIndex, setActiveTabIndex] = useState(null);
  const [viewStyle, setViewStyle] = useState(VIEW_STYLES.LIST);

  const navigate = useNavigate();

  useEffect(() => {
    let activeTab = DEFAULT_TAB;
    if (currentTab) {
      activeTab = panesTabIndex[currentTab];
    }
    setActiveTabIndex(activeTab);
  }, [currentTab]);

  const handleTabChange = (_, { panes, activeIndex }) => {
    const tab = panes[activeIndex].path;
    navigate(`/${organizationUsername}/companydirectory/${tab}`);
  };

  return (
    <div className="CompanyDirectoryView">
      <Tab
        basicType
        panes={panes({
          data: companies,
          updateFilters,
          isLoading,
          isSearchActive,
          totalCompanies,
          coInvestors,
          organizationUsername,
          tagSuggestions,
          headquarters,
          filterTags,
          viewStyle,
          setViewStyle,
          filters,
          handleOpenEditCompanyForm,
          filterIndustries,
          industriesSuggestions,
        })}
        onTabChange={handleTabChange}
        activeIndex={activeTabIndex}
      />
    </div>
  );
}

export default CompanyDirectoryView;
