import Api from "lib/Api";
import { collectionFetchSuccess } from "store/domain/domainActions";

export function filterIndustry(industryName) {
  return (dispatch) => {
    return Api.get(`/industries/?name=${industryName}`).then(
      ({ data: industries }) => {
        dispatch(collectionFetchSuccess("industries", industries));
      },
    );
  };
}

export function createIndustry(industryName) {
  return () => {
    return Api.post("/industries", {
      industry: { name: industryName },
    });
  };
}
