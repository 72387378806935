import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import Pill from "components_new/atoms/Pill";
import MenuItem from "components_new/atoms/MenuItem";

function TabMenuItem({
  index,
  active,
  onClick,
  disabled,
  position,
  to,
  name,
  count,
  textOnly,
}) {
  const menuItemClassNames = classNames("TabMenu-item", {
    disabled: disabled,
  });

  return (
    <MenuItem
      className={menuItemClassNames}
      as={Link}
      key={index}
      active={active}
      onClick={onClick}
      disabled={disabled}
      position={position}
      to={to}
    >
      <span>{name}</span>
      {count >= 0 && (
        <PillContent
          count={count}
          active={active}
          disabled={disabled}
          textOnly={textOnly}
        />
      )}
    </MenuItem>
  );
}

function PillContent({ count, active, disabled, textOnly }) {
  if (textOnly) {
    return <span className="text-only-count">({count})</span>;
  }

  const pillClassnames = classNames("display-count", {
    inactive: !active,
    disabled: disabled,
  });

  return <Pill className={pillClassnames}>{count}</Pill>;
}

TabMenuItem.defaultProps = {
  disabled: false,
};

export default TabMenuItem;
