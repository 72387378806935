import React from "react";
import CreateDiscussion from "./CreateDiscussion";
import ViewDiscussion from "./ViewDiscussion";

function RepliesSection({
  replies,
  replyAction,
  editAction,
  deleteAction,
  currentUser,
  isSubmitting,
  openDocumentsUploader,
  onCancel,
  parentId,
  users,
  organizationUsername,
  resource,
  focusEditor,
  isInlineAttachment,
  size,
}) {
  return (
    <section className="RepliesSection">
      {replies.map((reply, index) => {
        const user = users[reply.author_id][0];
        return (
          <ViewDiscussion
            key={index}
            user={user}
            post={reply}
            users={users}
            currentUser={currentUser}
            editAction={editAction}
            deleteAction={deleteAction}
            resource={resource}
            openDocumentsUploader={openDocumentsUploader}
            organizationUsername={organizationUsername}
            isSubmitting={isSubmitting}
            parentId={parentId}
            isAvailableToReply={false}
            isInlineAttachment={isInlineAttachment}
          />
        );
      })}
      <CreateDiscussion
        createAction={replyAction}
        user={currentUser}
        isSubmitting={isSubmitting}
        openDocumentsUploader={openDocumentsUploader}
        placeholder="Add a reply"
        showPreviewForm={!focusEditor}
        resource={resource}
        parentId={parentId}
        onCancel={onCancel}
        isInlineAttachment={isInlineAttachment}
        size={size}
        isAReply={true}
      />
    </section>
  );
}

export default RepliesSection;
