import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Mousetrap from "mousetrap";
import Dropdown from "components/shared/Dropdown";
import UserAvatar from "components_new/elements/UserAvatar";
import { getOrganizationResourceAsCollection } from "store/resourcesV2";
import AuthenticationContext from "components/Mighty/AuthenticationContext";
import useCurrentUser from "hooks/useCurrentUser";

const sendSupportEmail = () => {
  window.location.href = "mailto:support@mighty.co";
};

function UserMenu() {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();

  const { logout } = useContext(AuthenticationContext);

  const privateOrganization = useSelector((state) => {
    const organizations = getOrganizationResourceAsCollection(
      state,
      "organizations",
    );
    return organizations.find((item) => item.private);
  });

  const userMenuItems = [
    {
      text: "Account Settings",
      onClickAction: () => {
        navigate(`/${privateOrganization.username}/personal-settings`);
      },
    },
    {
      text: "Keyboard Shortcuts",
      onClickAction: () => Mousetrap.trigger("?"),
    },
    {
      text: "Support",
      onClickAction: sendSupportEmail,
    },
    {
      text: "Logout",
      onClickAction: logout,
    },
  ];

  return (
    <Dropdown
      items={userMenuItems}
      extraClassnames="UserMenu"
      extraButtonClassnames="Button--action-over-body"
      renderButton={() => (
        <UserAvatar
          avatarUrl={currentUser.avatar_url}
          showTooltip={false}
          initials={currentUser.initials}
          fullName={currentUser.full_name}
          theme="inverse"
          size="small"
        />
      )}
    />
  );
}

export default UserMenu;
