export default function getTotalPaidInValues(collection) {
  const collectionCopy = [...collection].reverse();

  const totalPaidInColumn = [];
  collectionCopy.reduce((accum, element) => {
    const calculatedValue = accum + parseFloat(element.amount);

    totalPaidInColumn.unshift(calculatedValue);
    return calculatedValue;
  }, 0);

  return totalPaidInColumn;
}
