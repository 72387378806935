import React, { useMemo, useEffect } from "react";
import TaskView from "components_new/views/Queues/tabs/TaskView";

import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useTasks } from "components_new/views/Queues/hooks/queries/useTasks";
import { useQueueRefresh } from "components_new/views/Queues/hooks/queries/useQueueRefresh";
import { maxBy } from "lodash";

function PendingTasksWrapper({ filters, updateFilters, queueLabel }) {
  const queryClient = useQueryClient();

  const { organizationUsername } = useParams();
  const { data, isLoading } = useTasks({ scope: "pending", filters });

  const lastTaskId = useMemo(() => {
    const lastTask = maxBy(data?.tasks, "id");
    return lastTask?.id;
  }, [data]);
  const { data: newTasksCount = 0 } = useQueueRefresh(lastTaskId);

  const handleFetchNewTasks = () => {
    queryClient.invalidateQueries(["QueuesView", organizationUsername]);
  };

  useEffect(() => {
    updateFilters((filters) => ({
      ...filters,
      query: "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TaskView
      tasks={data?.tasks || []}
      tasksType="pending"
      filters={filters}
      updateFilters={updateFilters}
      isLoading={isLoading}
      fetchNewTasks={handleFetchNewTasks}
      newTasksCount={newTasksCount}
      queueLabel={queueLabel}
    />
  );
}

export default PendingTasksWrapper;
