import PropTypes from "prop-types";
import React from "react";
import NavigationTabs from "components/shared/NavigationTabs";

const PageLayout = ({ children }) => {
  const menu = [
    {
      url: "/privacy",
      children: "Privacy Policy",
    },
    {
      url: "/terms",
      children: "Terms of Service",
    },
  ];

  return (
    <div className="u-row PageLayout">
      <h1 className="u-heading">Mighty Privacy & Terms</h1>
      <div className="PageLayout-nav">
        <NavigationTabs orientation="vertical" items={menu} />
      </div>
      <div className="PageLayout-content">{children}</div>
    </div>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node,
};

export default PageLayout;
