import React from "react";
import { uniqBy } from "lodash";
import pluralize from "pluralize";
import UserAvatar from "components_new/elements/UserAvatar";

function RepliesSummary({ replies, users, onClick }) {
  const repliesUniquesUsers = uniqBy(replies, "author_id");
  return (
    <div className="RepliesSummary" onClick={onClick}>
      {repliesUniquesUsers.map((user, index) => {
        const { initials, avatar_url: avatarUrl } = users[user.author_id][0];
        return (
          <UserAvatar
            key={index}
            avatarUrl={avatarUrl}
            initials={initials}
            showTooltip={false}
            size="mini"
          />
        );
      })}
      <p className="link">
        {replies.length} {pluralize("reply", replies.length)}
      </p>
      <span className="u-metaContentLight">View thread</span>
    </div>
  );
}

export default RepliesSummary;
