import React from "react";
import FormGroup from "components_new/elements/FormGroup";
import Input from "components_new/atoms/Input";
import Tags from "components/shared/Tags";
import DropdownForm from "components_new/atoms/DropdownForm";
import Industry from "components/shared/Industry";
import ContactEmails from "./ContactEmails";
import { tierDropdown } from "config/tierConfig";
import "./ContactDetails.scss";

function ContactDetails({
  formData,
  onChange,
  errors,
  prepopulatedContactTags,
  prepopulatedSectors,
}) {
  const {
    first_name,
    last_name,
    emails,
    tagline,
    location,
    tags,
    tier,
    industries,
  } = formData;

  return (
    <FormGroup
      extraClassnames="ContactDetailsGroup"
      title="Basic Details"
      subtitle="Information about the new contact."
      isExpanded
      required
    >
      <Input
        label="First Name"
        value={first_name}
        isRequired
        onChange={(e, { value }) => {
          onChange("first_name", value);
        }}
        errorMessage={errors.for("first_name")}
        size="tiny"
      />
      <Input
        label="Last Name"
        isRequired
        value={last_name}
        onChange={(e, { value }) => {
          onChange("last_name", value);
        }}
        errorMessage={errors.for("last_name")}
        size="tiny"
      />
      <Input
        label="Tagline"
        value={tagline}
        onChange={(e, { value }) => {
          onChange("tagline", value);
        }}
        errorMessage={errors.for("tagline")}
        size="tiny"
        maxLength={140}
      />
      <ContactEmails
        value={emails}
        onChange={(value) => onChange("emails", value)}
        errors={errors}
      />
      <Input
        label="Location"
        value={location}
        onChange={(e, { value }) => {
          onChange("location", value);
        }}
        errorMessage={errors.for("location")}
        size="tiny"
      />
      <Industry
        label="Industries"
        createTagLabel="Create new industry"
        placeholder="Search industries"
        name="industries"
        taggedList={industries || []}
        onChange={(value) => onChange("industries", value)}
        size="tiny"
        errorMessage={errors.for("industries")}
        extraClassnames="IndustryTags"
        prepopulatedItems={prepopulatedSectors}
      />
      <Tags
        tagType="contact"
        label="Tags"
        name="tags"
        taggedList={tags}
        onChange={(value) => onChange("tags", value)}
        errorMessage={errors.for("tags")}
        size="tiny"
        prepopulatedItems={prepopulatedContactTags}
      />
      <DropdownForm
        label="Tier"
        placeholder="Select a tier"
        items={tierDropdown}
        onChange={(e, { value }) => onChange("tier", value)}
        value={tier}
        size="tiny"
      />
    </FormGroup>
  );
}

export default ContactDetails;
