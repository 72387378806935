export function removeTaskFromScope({
  taskId,
  queryClient,
  organizationUsername,
  scope,
}) {
  const key = ["QueuesView", organizationUsername, scope];
  const queuesView = queryClient.getQueryData(key);

  if (queuesView?.tasks) {
    const newTasksList = queuesView?.tasks.filter((task) => task.id !== taskId);

    queryClient.setQueryData(key, {
      ...queuesView,
      tasks: newTasksList,
    });
  }
}
