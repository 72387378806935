import React from "react";
import Value from "components_new/atoms/Value";
import SubValue from "components_new/atoms/SubValue";
import PropTypes from "prop-types";

function FMVSubvalue({ investment }) {
  const shouldShowFMVSubvalue = !["debt", "fund", "llc"].includes(
    investment.type,
  );
  if (shouldShowFMVSubvalue) {
    return (
      <SubValue>
        <Value type="currency" precision="2">
          {investment.unit_valuation_amount}
        </Value>{" "}
        / share
      </SubValue>
    );
  }
  return null;
}

FMVSubvalue.propTypes = {
  investment: PropTypes.shape({
    company_name: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    quantity: PropTypes.number,
    fully_diluted_shares_percentage: PropTypes.number,
    cost_basis: PropTypes.string,
    total_valuation_amount: PropTypes.string,
    multiple: PropTypes.string,
    unit_valuation_amount: PropTypes.string,
  }),
};

export default FMVSubvalue;
