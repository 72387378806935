import React from "react";
import Header from "components_new/atoms/Header";
import DiscussionsList from "components_new/elements/Discussions/DiscussionsList";
import "./CompanyNoteTab.scss";

function CompanyNoteTab({
  organizationUsername,
  users,
  posts,
  resource,
  isSubmitting = false,
  createPost,
  updatePost,
  removePost,
  openDocumentsUploader,
  openContactDetailsView,
  noteToHighlight,
}) {
  return (
    <div className="TabContentDiv">
      <Header as="h5">Notes</Header>
      <DiscussionsList
        extraClassnames="CompanyNoteTab"
        organizationUsername={organizationUsername}
        users={users}
        posts={posts}
        resource={resource}
        isSubmitting={isSubmitting}
        createPost={createPost}
        updatePost={updatePost}
        removePost={removePost}
        openDocumentsUploader={openDocumentsUploader}
        openContactDetailsView={openContactDetailsView}
        showDiligenceContact={true}
        noteToHighlight={noteToHighlight}
        showResource={false}
      />
    </div>
  );
}

export default CompanyNoteTab;
