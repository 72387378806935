import React from "react";
import ColumnedLabelValue from "components_new/atoms/ColumnedLabelValue";
import EmptyLabel from "components/shared/EmptyLabel";
import classnames from "classnames";
import "./ColumnedValueList.scss";

function ColumnedValueList({ data, ellipsize, extraClassname }) {
  return (
    <div
      className={classnames("ColumnedValueList", {
        [extraClassname]: !!extraClassname,
      })}
    >
      {data.map(({ onClick, label, value, type, ...options }, index) => {
        return (
          <div className="ColumnedValueList-Column" key={index}>
            <ColumnedLabelValue
              onClick={onClick}
              label={label}
              type={type}
              ellipsize={ellipsize}
              {...options}
            >
              {value ? value : <EmptyLabel />}
            </ColumnedLabelValue>
          </div>
        );
      })}
    </div>
  );
}

export default ColumnedValueList;
