import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import accounting from "accounting";
import { Form } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/input.css";
import "semantic-ui-css/components/icon.css";
import classnames from "classnames";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import HelperMessage from "components/shared/form/HelperMessage";
import Input from "components_new/atoms/Input";
import "./NumberInput.scss";

function NumberInput(props) {
  const {
    allowDecimal,
    decimalLimit,
    includeThousandsSeparator,
    placeholder,
    iconPosition,
    defaultValue,
    extraClassnames,
    errorMessage,
    fieldLabel,
    onChange,
    value,
    maxLength,
    autoFocus,
    textHelper,
    required = false,
    size,
    icon,
    name,
    ...inputProps
  } = props;

  const [displayValue, setDisplayValue] = useState(value);
  const numberInputRef = useRef();

  useEffect(() => {
    if (value !== displayValue) {
      setDisplayValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (autoFocus) {
      numberInputRef.current.inputElement.focus();
    }
  }, [autoFocus]);

  const numberMask = createNumberMask({
    prefix: "",
    suffix: "",
    allowDecimal,
    decimalLimit,
    includeThousandsSeparator,
    integerLimit: maxLength,
  });

  const handleOnChange = (event) => {
    const currentValue = event.target.value;
    setDisplayValue(currentValue);

    if (onChange) {
      const newValue = !!currentValue ? accounting.unformat(currentValue) : "";

      onChange(newValue);
    }
  };

  const numberInputClassnames = classnames("NumberInput", {
    [extraClassnames]: !!extraClassnames,
    [size]: !!size,
    error: !!errorMessage,
  });

  return (
    <Form.Field className={numberInputClassnames}>
      {fieldLabel && <label>{fieldLabel}</label>}
      <Input
        iconPosition={icon && iconPosition}
        size={size}
        isRequired={required}
        errorMessage={errorMessage}
        {...inputProps}
      >
        <MaskedInput
          name={name}
          mask={numberMask}
          guide={true}
          defaultValue={defaultValue}
          value={value}
          placeholder={placeholder}
          onChange={handleOnChange}
          ref={numberInputRef}
          value={displayValue}
          {...(!fieldLabel && placeholder
            ? {
                "aria-label": placeholder,
              }
            : {})}
          {...(inputProps["aria-label"]
            ? { "aria-label": inputProps["aria-label"] }
            : {})}
        />
        {icon}
      </Input>
      {textHelper && <HelperMessage text={textHelper} />}
    </Form.Field>
  );
}

NumberInput.propTypes = {
  name: PropTypes.string,
  allowDecimal: PropTypes.bool,
  currencyInput: PropTypes.bool,
  percentageInput: PropTypes.bool,
  iconPosition: PropTypes.oneOf(["left", "right"]),
  decimalLimit: PropTypes.number,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  includeThousandsSeparator: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  fieldLabel: PropTypes.string,
  errorMessage: PropTypes.string,
  extraClassnames: PropTypes.string,
  size: PropTypes.string,
  value: PropTypes.string,
  maxLength: PropTypes.number,
  autoFocus: PropTypes.bool,
  textHelper: PropTypes.string,
  required: PropTypes.bool,
  icon: PropTypes.node,
};

NumberInput.defaultProps = {
  allowDecimal: false,
  currencyInput: false,
  percentageInput: false,
  iconPosition: "right",
  decimalLimit: 2,
  includeThousandsSeparator: true,
  defaultValue: null,
  type: "text",
  size: "normal",
  icon: null,
};

export default NumberInput;
