import React from "react";
import preventerHandler from "lib/preventerHandler";
import PrimaryButton from "components/shared/PrimaryButton";
import PropTypes from "prop-types";
import AlertIcon from "-!svg-react-loader?!assets/icons/utility/alert.svg?name=AlertIcon";

function DebugMessage({ error }) {
  return (
    <>
      <p>Here's the stack trace:</p>
      <pre
        style={{
          textAlign: "left",
          overflow: "auto",
          fontSize: "14px",
        }}
      >
        <code>{error.stack}</code>
      </pre>
    </>
  );
}

function ErrorRequest({ error }) {
  const isDevelopment = process.env.NODE_ENV !== "production";
  const runningEndToEndTests = !!window.Cypress;

  const showDebugMessage = (isDevelopment || runningEndToEndTests) && error;

  return (
    <div className="ErrorModal">
      <form onSubmit={preventerHandler(() => window.location.replace(""))}>
        <div>
          <div className="ErrorModal-icon">
            <AlertIcon width="36" height="36" />
          </div>
          <h1 className="u-heading">Oh no! An error has occurred</h1>
          <div className="ErrorModal-note">
            {showDebugMessage ? (
              <DebugMessage error={error} />
            ) : (
              <>
                The Mighty Team has been notified, and will work to fix the
                issue.
              </>
            )}
          </div>
          <div className="ErrorModal-actionButtons">
            <PrimaryButton type="submit">Refresh Page</PrimaryButton>
          </div>
        </div>
      </form>
    </div>
  );
}

ErrorRequest.propTypes = {
  message: PropTypes.node,
  error: PropTypes.shape({ stack: PropTypes.string }),
};

export default ErrorRequest;
