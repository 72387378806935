import React from "react";
import PassedDealsTable from "./PassedDealsTable";
import PropTypes from "prop-types";
import EmptyDeals from "components_new/views/Deals/EmptyDeals";
import { dealProptype, tagSuggestionsProptype } from "PropTypes";
import { dropdownOption } from "components_new/customPropTypes";
import PassedDealsTopBar from "./TableActions/PassedDealsTopBar";
import Segment from "components_new/atoms/Segment";
import { matchPath, useLocation } from "react-router-dom";
import "./PassedDeals.scss";

function getSelectedDealId(matchPath, pathname) {
  let selectedDealId = null;

  const match = matchPath(
    { path: ":organizationId/deals/passed/:id" },
    pathname,
  );

  if (match) {
    selectedDealId = match.params.id;
  }

  return selectedDealId;
}

function PassedDeals({
  isLoading,
  hasSearchOrFilter,
  passedDeals,
  organizationUsername,
  openReactivateDealModal,
  openDeleteDealModal,
  passedDealsCount,
  fetchPassedDeals,
  headquarters,
  owners,
  tagSuggestions,
  filterTags,
  openViewDealSidebar,
  openEditDealSidebar,
  openEditPassDealSidebar,
  totalDeals,
}) {
  const location = useLocation();
  const selectedDealId = getSelectedDealId(matchPath, location.pathname);

  if (!isLoading && !hasSearchOrFilter && passedDeals.length === 0) {
    return <EmptyDeals dealType="passed" />;
  }

  return (
    <Segment extraClassNames="PassedDeals" shadow>
      <PassedDealsTopBar
        isLoading={isLoading}
        dealsCount={passedDealsCount}
        fetchPassedDeals={fetchPassedDeals}
        headquarters={headquarters}
        owners={owners}
        tagSuggestions={tagSuggestions}
        filterTags={filterTags}
        totalDeals={totalDeals}
      />
      <PassedDealsTable
        isLoading={isLoading}
        passedDeals={passedDeals}
        organizationUsername={organizationUsername}
        openReactivateDealModal={openReactivateDealModal}
        openDeleteDealModal={openDeleteDealModal}
        openViewDealSidebar={openViewDealSidebar}
        openEditDealSidebar={openEditDealSidebar}
        openEditPassDealSidebar={openEditPassDealSidebar}
        selectedDealId={selectedDealId}
      />
    </Segment>
  );
}

PassedDeals.propTypes = {
  isLoading: PropTypes.bool,
  hasSearchOrFilter: PropTypes.bool,
  passedDeals: PropTypes.arrayOf(dealProptype),
  organizationUsername: PropTypes.string.isRequired,
  openReactivateDealModal: PropTypes.func.isRequired,
  openDeleteDealModal: PropTypes.func,
  passedDealsCount: PropTypes.number,
  fetchPassedDeals: PropTypes.func.isRequired,
  filterTags: PropTypes.func,
  tagSuggestions: tagSuggestionsProptype,
  headquarters: PropTypes.arrayOf(dropdownOption),
  owners: PropTypes.arrayOf(dropdownOption),
  openViewDealSidebar: PropTypes.func.isRequired,
  openEditDealSidebar: PropTypes.func.isRequired,
  openEditPassDealSidebar: PropTypes.func.isRequired,
  totalDeals: PropTypes.number,
};

export default PassedDeals;
