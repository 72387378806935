import NOT_ORGANIZATION_URLS from "./config";

export default function getOrganizationPathname(
  url = window.location.pathname,
) {
  //TODO: Should change the way to get the organizationUsernme because is not syncup
  //with the store with the url
  const organizationPathname = url && url.split("/").filter(Boolean)[0];

  return NOT_ORGANIZATION_URLS.includes(organizationPathname)
    ? null
    : organizationPathname;
}
