import React from "react";
import DropdownForm from "components_new/atoms/DropdownForm";
import InputTag from "components_new/elements/InputTag";
import TableFilter from "components_new/elements/TableFilter";
import { tierDropdown } from "config/tierConfig";
import { INVESTMENT_DOMAIN } from "components_new/SidebarComponents/forms/groups/CompanyBasicDetails/FirmType/config";
import "./TableFilter.scss";

const initialValues = {
  headquarter: null,
  investment_domain: [],
  tier: [],
  industries: [],
  tags: [],
};

function CompanyInvestorFilter({
  onApplyFilters,
  className,
  filterTags,
  tagSuggestions,
  headquarters,
  values,
  filterIndustries,
  industriesSuggestions,
}) {
  return (
    <TableFilter
      className={className}
      initialFilterValues={initialValues}
      onApplyFilters={onApplyFilters}
      values={values}
    >
      {(filters, updateFilters) => (
        <div className="CompanyInvestorFilter">
          <DropdownForm
            search
            multiple
            fluid
            label="Investment domain"
            placeholder="Select an investment domain"
            value={filters.investment_domain}
            items={INVESTMENT_DOMAIN}
            onChange={(_, { value }) =>
              updateFilters("investment_domain", value, value.length)
            }
            extraClassnames="FormElement"
            size="tiny"
          />
          <DropdownForm
            search
            multiple
            fluid
            label="Tier"
            placeholder="Type to filter or select a tier"
            value={filters.tier}
            items={tierDropdown}
            onChange={(_, { value }) =>
              updateFilters("tier", value, value.length)
            }
            extraClassnames="FormElement"
            size="tiny"
          />
          <DropdownForm
            search
            fluid
            label="Location"
            placeholder="Type to filter or select a location"
            value={filters.headquarter}
            items={headquarters}
            onChange={(_, { value }) => updateFilters("headquarter", value, 1)}
            extraClassnames="FormElement"
            size="tiny"
          />
          <InputTag
            label="Industries/Sector"
            placeholder="Type to filter or select a sector"
            extraClassnames="CompanyTag-Input"
            size="small"
            onFilter={filterIndustries}
            suggestedList={industriesSuggestions}
            onChange={(values) =>
              updateFilters("industries", values, values.length)
            }
            taggedList={filters.industries}
            size="tiny"
          />
          <InputTag
            label="Tags"
            placeholder="Type to filter or select a tag"
            extraClassnames="CompanyTag-Input"
            size="small"
            onFilter={filterTags}
            suggestedList={tagSuggestions}
            onChange={(values) => updateFilters("tags", values, values.length)}
            taggedList={filters.tags}
            size="tiny"
          />
        </div>
      )}
    </TableFilter>
  );
}

export default CompanyInvestorFilter;
