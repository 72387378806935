import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Form } from "@ableco/semantic-ui-react";
import RadioButton from "components_new/atoms/RadioButton/RadioButton";
import HelperMessage from "components/shared/form/HelperMessage";
import classnames from "classnames";
import "./RadioButtonGroup.scss";

export default function RadioButtonGroup({
  inputProps,
  onChange,
  textHelper,
  label,
  value,
  name,
  size,
}) {
  const handleChange = (_, { value }) => {
    onChange(value);
  };

  const radioButtonGroupClassnames = classnames("RadioButtonGroup", {
    [size]: !!size,
  });

  return (
    <Fragment>
      <div className={radioButtonGroupClassnames}>
        {label && <label className="RadioButtonGroup-label">{label}</label>}
        <div className="RadioButtonGroup-options">
          {inputProps.map((option, index) => (
            <Form.Field key={index} className="RadioButtonGroup-item">
              <RadioButton
                key={index}
                label={option.label}
                name={name}
                value={option.value}
                checked={option.value === value}
                onChange={handleChange}
              />
            </Form.Field>
          ))}
        </div>
      </div>
      {textHelper && <HelperMessage text={textHelper} />}
    </Fragment>
  );
}

RadioButtonGroup.propTypes = {
  inputProps: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string })),
  onChange: PropTypes.func,
  textHelper: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
};
