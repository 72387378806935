import PropTypes from "prop-types";
import React from "react";
import Button from "components/shared/Button";

function SecondaryButton(props) {
  const { children, ...restProps } = props;

  return (
    <Button {...restProps} behavior="secondary">
      {children}
    </Button>
  );
}

SecondaryButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SecondaryButton;
