import { useQuery } from "react-query";
import Api from "lib/Api";

export function useSearch({ query, scope }) {
  return useQuery(
    ["Search", scope, query],
    async () => {
      const { data } = await Api.get(`/search/${scope}/${query}`);

      const {
        results: { [scope]: resultItems },
      } = data;

      return resultItems;
    },
    { enabled: !!query },
  );
}
