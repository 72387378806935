import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bulkDocumentsUpdate } from "store/documents/actions";
import { reloadInvestmentAfterDocumentsV2 } from "store/investments/investment/actions";
import { openInModal, ModalNames } from "store/modal";
import DocumentsInvestmentView from "./DocumentsInvestmentView";
import { useSetToast } from "components_new/elements/Toaster/Toaster-context";
import getMessage from "components_new/lib/Messages";
import { getOrderedCollection } from "store/entities";

function DocumentsInvestmentWrapper({ investment }) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { addToast } = useSetToast();

  const {
    views: { investment: view },
  } = state;

  const documents = getOrderedCollection(state, "documents", view.document_ids);

  const bulkUpdateDocuments = (payload) => {
    return dispatch(bulkDocumentsUpdate(investment.id, payload)).then(
      (response) => {
        dispatch(reloadInvestmentAfterDocumentsV2(investment.id));
        addToast(getMessage("updateFiles"));
        return response;
      },
    );
  };

  const openInvestmentsDocumentsModal = () => {
    dispatch(
      openInModal(ModalNames.newInvestmentDocuments, {
        investmentId: investment.id,
        companyId: investment.company_id,
        canMarkAsClosing: true,
        canChooseFileType: true,
      }),
    );
  };

  return (
    <DocumentsInvestmentView
      documents={documents}
      bulkUpdateDocuments={bulkUpdateDocuments}
      openInvestmentsDocumentsModal={openInvestmentsDocumentsModal}
    />
  );
}

export default DocumentsInvestmentWrapper;
