import React from "react";

import Value from "components_new/atoms/Value";

import { Header } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/header.css";
import "./ColumnedLabelValue.scss";

function ColumnedLabelValue({
  onClick,
  secondary,
  highlighted,
  label,
  ellipsize,
  children,
  type,
  ...options
}) {
  return (
    <div onClick={onClick} className="ColumnedLabelValue">
      <Header
        sub
        disabled={secondary}
        size={highlighted ? "huge" : undefined}
        content={label}
        data-testid={`${label}`}
      />
      <div className={ellipsize ? "ellipsize" : undefined}>
        <Value
          type={type}
          secondary={secondary}
          highlighted={highlighted}
          {...options}
        >
          {children}
        </Value>
      </div>
    </div>
  );
}

export default ColumnedLabelValue;
