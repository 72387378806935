import React from "react";
import { TableRow, TableCell } from "components_new/elements/Table";
import { skeletonRowContent } from "./config";
import "./SkeletonRow.scss";

export default function SkeletonRow({ headersStructure, expandable }) {
  return (
    <TableRow>
      {expandable && <TableCell></TableCell>}
      {headersStructure.map((header, index) => (
        <TableCell key={index}>
          {header.skeletonClassName === "date-singleLine"
            ? skeletonRowContent["date_single_line"]
            : skeletonRowContent[header.skeletonType]}
        </TableCell>
      ))}
    </TableRow>
  );
}
