export function checkIfHtmlHasContent(htmlString) {
  if (!htmlString) {
    return false;
  }

  const div = document.createElement("div");
  div.innerHTML = htmlString.trim();

  return div.textContent.trim().length > 0;
}

// Based on https://stackoverflow.com/a/3866442
export function setEndOfContenteditable(contentEditableElement) {
  // ONLY FOR TEST PURPOUSE
  if (document.createRange !== undefined) {
    const range = document.createRange(); //Create a range (a range is a like the selection but invisible)
    range.selectNodeContents(contentEditableElement); //Select the entire contents of the element with the range
    range.collapse(false); //collapse the range to the end point. false means collapse to end rather than the start
    const selection = window.getSelection(); //get the selection object (allows you to change selection)
    selection.removeAllRanges(); //remove any selections already made
    selection.addRange(range); //make the range you have just created the visible selection
  }
}

export function detectAttachmentRowDeleteEvent(clickEvent) {
  return !!clickEvent
    .composedPath()
    .slice(0, 5) //shorten path for the sake of performance -- the delete event will always be found at or near the top of the path.
    .find((el) => el.className === "AttachmentRow-deleteDocument");
}
