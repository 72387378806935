import React, { useContext, useEffect, useCallback, useMemo } from "react";
import { useQueryClient } from "react-query";
import InvestmentForm from "./InvestmentForm";
import EditInvestmentForm from "components_new/SidebarComponents/forms/EditInvestmentForm/EditInvestmentForm";
import useOrganizations from "store/organizations/hooks";
import useInvestment from "store/investments/investment/hooks";
import useResourcesV2 from "store/resourcesV2/hooks";
import { SidebarComponentsContext } from "components_new/SidebarComponents/SidebarComponentsWrapper";
import { buildPrefilledCompanyData } from "lib/globalActionsHelper";
import { isEmpty } from "lodash";
import { parseElements } from "components_new/elements/MultiSelect/config";
import useEntities from "store/entities/hooks";

function InvestmentFormWrapper({ ownName }) {
  const {
    getComponentState,
    registerForm,
    mountSidebarComponent,
    hideSidebarComponent,
    updateSidebarComponent,
    closeSidebar,
    onUnmount,
  } = useContext(SidebarComponentsContext);

  const {
    componentData,
    companyId,
    deal,
    investment,
    isEditing,
    submitTo,
    initialPortfolioId,
    callback,
  } = getComponentState(ownName);

  const { getCollection } = useEntities();
  const queryClient = useQueryClient();

  const {
    currentOrganization,
    currentOrganizationName,
    isCurrentUserOrganizationOwner,
    isAdmin,
    currentOrganizationUsername,
  } = useOrganizations();

  const { getOrganizationResourceAsCollection } = useResourcesV2();
  const organizationtCustomFields = getOrganizationResourceAsCollection(
    "customFields",
  );

  const prefilledCompany = useMemo(() => {
    if (deal) {
      return buildPrefilledCompanyData(deal.company);
    } else if (companyId) {
      const { companyDetails } = queryClient.getQueryData([
        "Company",
        currentOrganizationUsername,
        companyId,
      ]);
      return buildPrefilledCompanyData(companyDetails);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, deal]);

  const prefilledContacts = useMemo(() => {
    if (deal) {
      return parseElements(deal.referrers);
    } else if (investment) {
      return parseElements(getCollection("contacts", investment.contact_ids));
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deal, investment]);

  const { searchContact, createInvestment, updateInvestment } = useInvestment();
  const FormComponent = isEditing ? EditInvestmentForm : InvestmentForm;

  function handleSubmitInvestment(formData) {
    if (isEditing) {
      return updateInvestment(formData);
    }
    return createInvestment(formData);
  }

  useEffect(() => {
    if (!isEmpty(ownName)) {
      registerForm(ownName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseSidebar = useCallback(() => {
    if (!isEmpty(submitTo)) {
      onUnmount(ownName, submitTo);
    } else {
      closeSidebar();
    }

    callback?.();
  }, [callback, closeSidebar, onUnmount, ownName, submitTo]);

  return (
    <FormComponent
      ownName={ownName}
      isAdmin={isAdmin}
      isOrgOwner={isCurrentUserOrganizationOwner}
      isEditing={isEditing}
      componentData={componentData}
      investment={investment}
      submitTo={submitTo}
      portfolios={currentOrganization.portfolios}
      prefilledContacts={prefilledContacts}
      submitInvestment={handleSubmitInvestment}
      investmentCustomFields={organizationtCustomFields}
      currentOrganizationName={currentOrganizationName}
      currentOrganizationUsername={currentOrganizationUsername}
      searchContact={searchContact}
      mountSidebarComponent={mountSidebarComponent}
      hideSidebarComponent={hideSidebarComponent}
      updateSidebarComponent={updateSidebarComponent}
      closeSidebar={handleCloseSidebar}
      deal={deal}
      prefilledCompany={prefilledCompany}
      comesFromDeal={!isEmpty(deal)}
      initialPortfolioId={initialPortfolioId}
    />
  );
}

export default InvestmentFormWrapper;
