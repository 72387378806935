import PropTypes from "prop-types";
import React from "react";
import Autocomplete from "components_new/elements/Autocomplete";
import Textarea from "components_new/atoms/TextArea";
import PriceInput from "components/shared/form/PriceInput";
import NumberInput from "components_new/atoms/NumberInput";
import CurrencyInput from "components_new/atoms/CurrencyInput";
import Input from "components_new/atoms/Input";
import classNames from "classnames";
import FormElement from "components/shared/form/FormElement";
import RadioButtonGroup from "components_new/elements/AdvancedFields/RadioButtonGroup";
import Select from "components/shared/form/Select";
import SelectAndOther from "components/shared/form/SelectAndOther";
import DatePicker from "components_new/atoms/DatePicker";
import CheckBoxGroup from "components/shared/form/CheckBoxGroup";
import { manageCustomFieldProps } from "components/customFields/CustomFieldForm/config.js";

const ALLOWED_EDITOR_TYPES = {
  textarea: Textarea,
  text: Input,
  price: PriceInput,
  number: NumberInput,
  autocomplete: Autocomplete,
  radioButton: RadioButtonGroup,
  select: Select,
  selectAndOther: SelectAndOther,
  date: DatePicker,
  multiple_choice: CheckBoxGroup,
  single_choice: RadioButtonGroup,
  currency: CurrencyInput,
};

function buildSelectAndOtherProps(editorProps) {
  const { otherValue, onOtherSelection, inputProps, placeholder } = editorProps;
  return {
    otherValue,
    onOtherSelection,
    selectProps: { inputProps, placeholder },
  };
}

function renderEditorChildComponent(editorProps) {
  const { childType, childProps } = editorProps;
  const { onChange, name, ...restProps } = childProps;
  const EditorChildComponent = ALLOWED_EDITOR_TYPES[childType];
  return (
    <EditorChildComponent
      onChange={(value) => {
        if (onChange) {
          return onChange(name, value);
        }
      }}
      {...restProps}
    />
  );
}

function AdvancedFieldContentEditor({
  editorProps,
  errorMessage,
  onChange,
  name,
  value,
  editorType,
  options,
  label,
  ...restProps
}) {
  const EditorComponent = ALLOWED_EDITOR_TYPES[editorType];
  const wrappedEditorProps =
    editorType === "selectAndOther"
      ? buildSelectAndOtherProps(editorProps)
      : editorProps;

  const customFieldProps = manageCustomFieldProps(editorType, {
    ...wrappedEditorProps,
  });

  const handleChange = (value, target) => {
    if (onChange) {
      onChange(
        name,
        ["text", "textarea"].includes(editorType) ? target.value : value,
      );
    }
  };
  return (
    <EditorComponent
      extraClassName="AdvancedFieldContentTextArea"
      placeholder={restProps.placeholder}
      label={label}
      onChange={handleChange}
      options={options}
      errorMessage={errorMessage}
      value={value}
      defaultValue={value}
      name={name}
      {...customFieldProps}
    >
      {editorProps.childType && renderEditorChildComponent(editorProps)}
    </EditorComponent>
  );
}

function AdvancedField(props) {
  const { errorDisplayValidation, extraClassnames } = props;

  const advancedFieldExtraClassnames = classNames(
    "FormElement--advancedField",
    {
      [extraClassnames]: !!extraClassnames,
    },
  );

  return (
    <FormElement
      {...(errorDisplayValidation && { errorDisplayValidation })}
      extraClassnames={advancedFieldExtraClassnames}
    >
      <AdvancedFieldContentEditor {...props} />
    </FormElement>
  );
}

AdvancedField.propTypes = {
  editorProps: PropTypes.shape({}),
  extraClassnames: PropTypes.string,
  editorType: PropTypes.string,
  errorMessage: PropTypes.string,
  errorDisplayValidation: PropTypes.func,
  inverseDisplay: PropTypes.bool,
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updateForm: PropTypes.func,
};

AdvancedField.defaultProps = {
  checkboxExtraAction: null,
  children: null,
  editorType: "textarea",
  editorProps: {},
  errorMessage: null,
  errorDisplayValidation: null,
  extraClassnames: null,
  inverseDisplay: false,
  label: null,
  onChange: null,
  updateForm: null,
  value: "",
  placeholder: "Add your description",
};

export default AdvancedField;
