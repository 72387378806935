export const validDateFormats = [
  "MM/DD/YYYY",
  "M/D/YY",
  "M/D/YYYY",
  "M-D-YY",
  "YYYY-MM-DD",
  "YYYY-MM-D",
  "YYYY-M-DD",
  "YYYY-M-D",
  "YY-M-D",
  "MM.D.YYYY",
  "M DD YY",
  "M D YY",
  "MM DD YY",
];

export const displayDateFormat = "MM/DD/YYYY";
export const monthYearDateFormat = "MM/YY";
export const placeholderDateFormat = "MM/DD/YYYY";
export const placeholderDateFormatLowerCase = "mm/dd/yyyy";
export const shortDateFormat = "MMMM DD, YYYY";
export const valueDateFormat = "YYYY-MM-DD";

export default {
  displayDateFormat,
  monthYearDateFormat,
  placeholderDateFormat,
  placeholderDateFormatLowerCase,
  validDateFormats,
  valueDateFormat,
};
