const NOT_ORGANIZATION_URLS = [
  "login",
  "organizations",
  "privacy",
  "terms",
  "account",
  "verify-login",
  "forgot-password",
  "reset-password",
  "signup-request",
  "revoked-invitation",
  "dashboard",
];

export default NOT_ORGANIZATION_URLS;
