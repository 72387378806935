import React from "react";
import moment from "moment";
import PageLayout from "./shared/PageLayout";

const TermsOfService = () => {
  const lastModified = "02/21/2017";
  return (
    <PageLayout>
      <div className="u-pagesContent">
        <h2 className="u-headingAccountSettings heading">Terms of Service</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget
          suscipit justo. Integer vitae nisl semper, auctor sapien a, rutrum
          est. Sed finibus tristique nisl, non porttitor orci ornare ut.
          Maecenas est diam, dignissim in fermentum ut
          <span className="u-link"> consectetur </span>
          at est. Nullam semper tempus pharetra. Quisque ac elit massa.
          Vestibulum finibus suscipit eros, eu elementum dui gravida nec. Fusce
          ultrices lorem mauris, vitae fringilla ex malesuada quis. Sed bibendum
          iaculis nisl, sed mattis elit sodales eu. Integer mauris lectus,
          condimentum quis ipsum et, posuere pulvinar nibh. Vivamus interdum
          enim in venenatis luctus
        </p>
        <div className="list">
          Nulla massa dui, auctor a odio maximus, ultricies vehicula nibh.
          <ul>
            <li>Aliquam vitae ex nec tellus vehicula suscipit.</li>
            <li>
              Ut vulputate ipsum ut dolor convallis, et porttitor nibh egestas.
            </li>
            <li>Aliquam a diam quis leo euismod rhoncus.</li>
            <li>Duis gravida mi ac laoreet ultricies.</li>
          </ul>
        </div>
        <p>
          Cras vehicula ex neque, vel rhoncus dolor in. <br />
          Ut ac sagittis est. Vivamus eleifend maximus aliquet. Donec viverra
          tellus eget risus auctor, vitae{" "}
          <span className="u-link"> malesuada </span>
          ipsum blandit. Curabitur tortor quam, efficitur vel mi vel, sagittis
          pretium nibh. Fusce aliquam malesuada dapibus. Fusce lectus lectus,
          hendrerit ut cursus non, vulputate ac libero.
        </p>
        <h2 className="u-sectionHeading subheading">
          Class aptent taciti sociosqu ad litora
        </h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed magna
          velit, aliquet non auctor sit amet, tincidunt nec nunc. Vestibulum
          blandit lobortis nulla et posuere. In hac habitasse platea dictumst.
          Suspendisse vitae lectus vel diam luctus luctus id egestas arcu. Nulla
          porta lacus nisi. Nunc ipsum ipsum, fermentum id tortor ultricies,
          tempor consectetur neque
        </p>
        <div className="list-spacing">
          Nulla massa dui, auctor a odio maximus, ultricies vehicula nibh.
          <ul>
            <li>
              <strong>Duis placerat justo a lorem vehicula rhoncus.</strong>
              In cursus volutpat nibh, ut accumsan dolor facilisis vitae.
              Aliquam vitae ex nec tellus vehicula suscipit.
            </li>
            <li>
              <strong>Nam blandit tortor a maximus porttitor.</strong>
              Vestibulum id nisl consectetur, scelerisque arcu vitae, sagittis
              libero. Class aptent taciti sociosqu.
            </li>
            <li>
              <strong>Suspendisse potenti Donec non mi suscipit</strong>,
              posuere nisi vel, pellentesque libero. Nulla tempor leo et libero
              sollicitudin, nec suscipit ex tempus. Nullam accumsan nec nunc
              eget euismod.
            </li>
            <li>
              <strong>
                Cras vehicula ex neque, vel rhoncus dolor malesuada in.
              </strong>
              Ut ac sagittis est. Vivamus eleifend maximus aliquet. Donec
              viverra tellus eget risus auctor, vitae luctus ipsum blandit.{" "}
            </li>
          </ul>
        </div>
        <p>
          In hac habitasse platea dictumst. Suspendisse vitae lectus vel diam
          luctus luctus id egestas arcu. Nulla porta lacus nisi. Nunc ipsum
          ipsum, fermentum id tortor ultricies, tempor consectetur neque.
        </p>
        <h2 className="u-sectionHeading subheading">
          Class aptent taciti sociosqu ad litora
        </h2>
        <p>
          Quisque laoreet ex eget cursus pretium. Aliquam erat volutpat. Duis
          pretium feugiat pharetra. Vestibulum ante ipsum primis in faucibus
          orci luctus et ultrices posuere cubilia Curae; Aenean semper viverra
          dui, ut vehicula est varius sit amet. Quisque sagittis tempor leo, et
          accumsan ante mollis non. In vestibulum non quam gravida volutpat.
          Cras suscipit eget libero vel molestie. Morbi viverra urna purus,
          pharetra consequat magna commodo in.
        </p>
        <p>
          Suspendisse potenti. Donec non mi suscipit, posuere nisi vel,
          pellentesque libero. Nulla tempor leo et libero sollicitudin, nec
          suscipit ex tempus. Nullam accumsan nec nunc eget euismod. Nam blandit
          tortor a maximus porttitor. Vestibulum id nisl consectetur,
          scelerisque arcu vitae, sagittis libero. Class aptent taciti sociosqu
          ad litora torquent per conubia nostra, per inceptos himenaeos.
          Suspendisse nibh nulla, gravida quis eros et, euismod imperdiet metus.
          Donec fringilla ullamcorper feugiat. Nunc sit amet velit enim. Quisque
          et ex a justo aliquet volutpat ut ac augue. Aenean dapibus sed sapien
          in malesuada. Nullam consectetur elit vel metus sodales ornare. Nulla
          facilisi. Vestibulum sagittis lacus a felis aliquam molestie.
        </p>
        <p>
          Duis placerat justo a lorem vehicula rhoncus. In cursus volutpat nibh,
          ut accumsan dolor facilisis vitae. Integer vel quam eu eros maximus
          facilisis eget ut est. Quisque vitae metus lacus. Suspendisse eu nulla
          blandit, gravida sem sed, eleifend est. Proin sollicitudin, dolor
          vitae luctus molestie, odio tortor blandit mauris, at porta sapien
          ante scelerisque tortor. Pellentesque ornare vel lorem et tempus. Duis
          dignissim, nibh sed interdum laoreet, lacus augue vestibulum elit,
          quis posuere orci nulla eget est. Integer quis felis et libero commodo
          pulvinar vel non sapien.
        </p>
        <div className="LastModified">
          Last Modified: {moment(lastModified).format("MMMM D, YYYY")}
        </div>
      </div>
    </PageLayout>
  );
};

export default TermsOfService;
