import React from "react";
import moment from "moment";
import { times } from "lodash";
import DropdownForm from "components_new/atoms/DropdownForm";
import "./YcClass.scss";

// for each year between 2011 and now
// create two options - `${year} ${semester}`
// 2011 Spring, 2011 Winter, etc.
const generatedItems = () => {
  const years = moment().format("YYYY") - 2011 + 1; // + 1 includes this year
  const yearsArr = times(years, (n) => {
    const year = n + 2011;
    return [`${year} Spring`, `${year} Winter`];
  }).flat();
  return yearsArr;
};

const ITEMS = ["YC Fellowship", "YC Pre-StartFund", ...generatedItems()].map(
  (item) => ({
    key: item,
    text: item,
    value: item,
  }),
);

function YcClass({ handleChange, size, ycClass }) {
  return (
    <div className="YcClass">
      <DropdownForm
        label="YC Class"
        fluid
        placeholder="Select YC class"
        value={ycClass}
        items={ITEMS}
        onChange={(_, { value }) => handleChange("yc_class", value)}
        size={size}
      />
    </div>
  );
}

export default YcClass;
