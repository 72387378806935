import React from "react";
import { ModalBody, ModalHeader } from "components/shared/Modal";
import AlertIcon from "-!svg-react-loader?!assets/icons/utility/alert.svg?name=AlertIcon";

function InvalidInvitationView({ cleanInvitation }) {
  cleanInvitation();
  return (
    <div className="InvalidInvitation">
      <ModalHeader />
      <ModalBody type="announcement">
        <div className="ErrorModal-icon">
          <AlertIcon width="36" height="36" />
        </div>
        <h1 className="InvalidInvitation-title u-heading">
          This invitation is no longer valid!
        </h1>
        <p className="InvalidInvitation-message">
          This invitation has expired or is no longer valid.
          <br /> Contact your organization owner for details.
        </p>
      </ModalBody>
    </div>
  );
}

export default InvalidInvitationView;
