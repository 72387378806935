const initialState = {
  ownedPortfolioIds: [],
  ownedCompanyIds: [],
  dashboardPortfolioIds: [],
};

// NOTE: use this with selectors to restrict access and to scope data
export default function idCollectionsReducer(state = initialState, _action) {
  return state;
}
