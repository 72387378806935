import React from "react";
import moment from "moment";
import {
  DTFileClosingEdit,
  DTFileNameEdit,
  DTFileNamePreview,
  DTFileTypeEdit,
  DTFileUploadDateEdit,
} from "./cellTypes";

const FILE_TYPES = [
  "Presentation",
  "Financials",
  "Stock Purchase Agreement",
  "Company Update",
  "Cap Table",
  "Capital Call Notice",
  "Investor Rights Agreement",
  "Voting Agreement",
  "Note Purchase Agreement",
  "Partnership Agreement",
  "Subscription",
  "Other",
];

function emptyDocumentItem() {
  return {
    id: null,
    file_name: "",
    document_type: null,
    upload_date: null,
  };
}

function createColumns() {
  const columns = [
    {
      text: "Name",
      classNames: "DocumentsTable-fileNameCol",
      renderValue: (item, currentOrganizationUsername) =>
        item.file_name ? (
          <DTFileNamePreview
            file={item}
            currentOrganizationUsername={currentOrganizationUsername}
          />
        ) : (
          "- - -"
        ),
      renderForm: (item, updateForm, index, errors) => (
        <DTFileNameEdit
          file={item}
          updateForm={updateForm}
          formIndex={index}
          errorMessage={errors.for("file_name")}
        />
      ),
    },
    {
      text: "",
      classNames: "DocumentsTable-closingCol",
      renderValue: () => {},
      renderForm: (item, updateForm, index, errors) => (
        <DTFileClosingEdit
          file={item}
          updateForm={updateForm}
          formIndex={index}
          errorMessage={errors.for("closing_document")}
        />
      ),
    },
    {
      text: "Type",
      classNames: "DocumentsTable-typeCol",
      renderValue: (item) => item.document_type || "- - -",
      renderForm: (item, updateForm, index, errors) => (
        <DTFileTypeEdit
          file={item}
          updateForm={updateForm}
          formIndex={index}
          errorMessage={errors.for("document_type")}
          fileTypes={FILE_TYPES}
        />
      ),
    },
    {
      text: "Upload Date",
      classNames: "DocumentsTable-uploadDateCol",
      renderValue: (item) =>
        item.created_at ? moment(item.created_at).format("ll") : "- - -",
      renderForm: (item) => <DTFileUploadDateEdit file={item} />,
    },
  ];

  return columns;
}

export { createColumns, emptyDocumentItem, FILE_TYPES as fileTypes };
