import React from "react";
import numeral from "numeral";
import identity from "lodash/identity";

const amountDefaultValue = "N/A";

const unlessDefault = (fn) => {
  return (value) => {
    return value === amountDefaultValue ? value : fn(value);
  };
};

const presentMoney = unlessDefault((value) => {
  return numeral(value).format("($1.00 a)");
});

const presentMultiple = unlessDefault((value) => {
  return `${numeral(value).format("0.00")}x`;
});

function DashboardHighlightsItem({ label, amount, presenter = identity }) {
  if (amount === undefined || amount == null) {
    amount = amountDefaultValue;
  }
  return (
    <li className="InvestmentHighlightsItem">
      <span className="InvestmentHighlightsItem-label u-metaContentLight">
        {label}
      </span>
      <span className="InvestmentHighlightsItem-amount u-contentNumerical">
        {presenter(amount)}
      </span>
    </li>
  );
}

export default function DashboardHighlights({
  totalCompanies,
  totalInvestments,
  totalInvestmentsAmount,
  totalInvestmentsValue,
  totalCashReceived,
}) {
  return (
    <ul className="InvestmentHighlights u-metaContentLight">
      <DashboardHighlightsItem
        label="Total Companies"
        amount={totalCompanies}
      />
      <DashboardHighlightsItem
        label="Current Investments"
        amount={totalInvestments}
      />
      <DashboardHighlightsItem
        label="Total Investment Amount"
        amount={totalInvestmentsAmount}
        presenter={presentMoney}
      />
      <DashboardHighlightsItem
        label="Total Value of Investment"
        amount={totalInvestmentsValue}
        presenter={presentMoney}
      />
      <DashboardHighlightsItem
        label="Total Cash Received"
        amount={totalCashReceived}
        presenter={presentMoney}
      />
      <DashboardHighlightsItem
        label="Multiple"
        amount={null}
        presenter={presentMultiple}
      />
    </ul>
  );
}
