import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchOrganizations } from "store/organizations/actions";

function Root() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goToPrivateOrganization = useCallback(async () => {
    const organizations = await dispatch(fetchOrganizations());
    const privateOrganization = organizations.find(
      (organization) => organization.private,
    );
    navigate(`/${privateOrganization.username}`);
  }, [dispatch, navigate]);

  useEffect(() => {
    goToPrivateOrganization();
  }, [goToPrivateOrganization]);

  return null;
}

export default Root;
