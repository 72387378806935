import React from "react";
import { Progress as SemanticProgress } from "@ableco/semantic-ui-react";
import "semantic-ui-css/components/progress.css";
import "./Progress.scss";

function Progress(props) {
  return <SemanticProgress className="Progress" {...props} />;
}

export default Progress;
