import React, { useState, useEffect } from "react";
import { useSidebarList, useSetSidebar } from "./Sidebar-context";
import Sidebar from "components_new/elements/SidebarPOC";
import "./SidebarWrapper.scss";

function SidebarWrapper() {
  const [indexToRemove, setIndexToRemove] = useState(null);
  const sidebars = useSidebarList();
  const { removeSidebar } = useSetSidebar();

  useEffect(() => {
    setIndexToRemove(null);
  }, [sidebars]);

  const handleRemove = (key) => {
    setIndexToRemove(key);
    setTimeout(() => {
      removeSidebar(key);
      //This is to gave some time to the animation when the sidebar is closing it.
    }, 400);
  };

  return (
    <div className="SidebarWrapper">
      {sidebars.map((sidebar) => {
        const Component = sidebar.component;
        return (
          <Sidebar
            key={sidebar.key}
            expanded={sidebar.key !== indexToRemove}
            position={sidebar.position}
            size={sidebar.props?.size || sidebar.size}
          >
            {({ toogleSize, currentSize }) => {
              return (
                <Component
                  {...sidebar.props}
                  closeSidebar={() => handleRemove(sidebar.key)}
                  toogleSize={toogleSize}
                  currentSize={currentSize}
                />
              );
            }}
          </Sidebar>
        );
      })}
    </div>
  );
}

export default SidebarWrapper;
