import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { closeModal } from "store/modal/actions";

function ModalFooter({ children, render, closeModal }) {
  const footerChildren = render ? render(closeModal) : children;
  return <div className="ModalFooter">{footerChildren}</div>;
}

ModalFooter.propTypes = {
  children: PropTypes.node,
  render: PropTypes.func,
  closeModal: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => dispatch(closeModal()),
  };
}

export default connect(null, mapDispatchToProps)(ModalFooter);
