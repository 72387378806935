import React from "react";
import InvestmentDetails from "components/investments/InvestmentDetails";
import InvestmentOverviewSection from "./InvestmentOverviewSection";
import CapitalCallHistoryContainer from "../CapitalCallHistory/CapitalCallHistoryContainer";
import DistributionHistoryContainer from "../InvestmentDistribution/DistributionHistoryContainer";
import ValuationHistoryContainer from "../investmentValuation/ValuationHistoryContainer";
import CertificateHistory from "../CertificateHistory/";
import canInvestmentDoAction from "config/canInvestmentDoAction";
import Tabs from "components/shared/Tabs";
import Pane from "components/shared/Tabs/Pane";
import PropTypes from "prop-types";
import {
  companyPropType,
  distributionsPropType,
  investmentPropType,
  valuationProptype,
  historyActionProptype,
  companyAvatarPropType,
} from "PropTypes";
import DocumentsInvestmentContainer from "./Documents/DocumentsInvestmentContainer";
import DiscussionsInvestment from "./Discussions";
import useInvestmentView from "./useInvestmentView";
import useSidebar from "store/sidebar/hooks";
import { useSetSidebar } from "components_new/elements/SidebarWrapper/Sidebar-context";

function InvestmentView({
  investment,
  company,
  bulkUpdateDocuments,
  actions,
  companies,
  convertedDeal,
  organizationUsername,
  ...props
}) {
  const [
    distributions,
    valuations,
    capitalCalls,
    capitalCallsForGraph,
  ] = useInvestmentView(props);
  const showCapitalCallSection = canInvestmentDoAction(
    investment,
    "logCapitalCalls",
  );

  const { openSidebar } = useSidebar();
  const { showSidebar } = useSetSidebar();
  const openContactDetailsView = (contact) => {
    showSidebar("contactsView", { contactId: contact.id });
  };
  const openReferrerModal = (referrer) =>
    openSidebar("editContactForm", "Edit Referrer", { referrer });

  const capitalCallTab = {
    name: "Capital Calls",
    extraClassnames: "CapitalCalls-table",
    content: (
      <CapitalCallHistoryContainer
        investment={investment}
        collection={capitalCalls}
        collectionForGraph={capitalCallsForGraph}
      />
    ),
  };

  const contentTabs = [
    {
      name: "Notes",
      content: <DiscussionsInvestment investmentId={investment.id} />,
    },
    {
      name: "Files",
      content: <DocumentsInvestmentContainer investment={investment} />,
    },
  ];

  const metricTabs = [
    {
      name: "Valuations",
      extraClassnames: "Valuations-table",
      content: (
        <ValuationHistoryContainer
          investment={investment}
          collection={valuations}
        />
      ),
    },
    {
      name: "Distributions",
      extraClassnames: "Distributions-table",
      content: (
        <DistributionHistoryContainer
          collection={distributions}
          investment={investment}
        />
      ),
    },
  ];

  if (showCapitalCallSection) {
    metricTabs.splice(1, 0, capitalCallTab);
  }

  return (
    <div className="u-row u-fixed-row InvestmentView">
      <div className="InvestmentView-content">
        <div className="u-displayInlineBlock InvestmentView-main">
          <InvestmentDetails
            company={company}
            investment={investment}
            openReferrerModal={openReferrerModal}
            openContactDetailsView={openContactDetailsView}
            organizationUsername={organizationUsername}
          />
          <Tabs extraClassnames="InvestmentView--History">
            {metricTabs.map((tab, index) => (
              <Pane
                key={index}
                label={tab.name}
                extraClassnames={tab.extraClassnames || ""}
              >
                {tab.content}
              </Pane>
            ))}
          </Tabs>

          <CertificateHistory
            investment={investment}
            companies={companies}
            collection={actions}
          />

          <div className="InvestmentView-tabs">
            <Tabs>
              {contentTabs.map((tab, index) => (
                <Pane key={index} label={tab.name}>
                  {tab.content}
                </Pane>
              ))}
            </Tabs>
          </div>
        </div>
        <div className="u-displayInlineBlock InvestmentView-sidebar">
          <InvestmentOverviewSection
            investment={investment}
            company={company}
            deal={convertedDeal}
            organizationUsername={organizationUsername}
          />
        </div>
      </div>
    </div>
  );
}

InvestmentView.propTypes = {
  displayCapitalCallsModal: PropTypes.func,
  distributions: distributionsPropType.isRequired,
  valuations: PropTypes.arrayOf(valuationProptype).isRequired,
  investment: investmentPropType.isRequired,
  company: companyPropType.isRequired,
  actions: PropTypes.arrayOf(historyActionProptype),
  companies: PropTypes.objectOf(companyAvatarPropType).isRequired,
  bulkUpdateDocuments: PropTypes.func,
  openReferrerModal: PropTypes.func,
  convertedDeal: PropTypes.shape({}),
  organizationUsername: PropTypes.string.isRequired,
};

export default InvestmentView;
