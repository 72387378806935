import React from "react";
import MiniCard from "components/shared/MiniCard";
import formatPrice from "lib/formatters/formatPrice";
import formatNumber from "lib/formatters/formatNumber";
import getLlcQuantityValue from "lib/getLlcQuantityValue";
import { companyPropType, investmentPropType } from "PropTypes";
import { getText } from "TermsMapper";
import "stylesheets/components/shared/HoldingMiniCard.scss";

function holdingValueFormatter(investment) {
  const { type, quantity, total_valuation_amount } = investment;
  switch (type) {
    case "llc":
      return investment && getLlcQuantityValue(investment);
    case "debt":
    case "fund":
      return total_valuation_amount && formatPrice(total_valuation_amount);
    case "equity":
    case "option":
    case "warrant":
      return `${formatNumber(quantity)} ${getText(type, "per_unit_label")}`;
  }
}

function HoldingMiniCard({ investment, company, ...restProps }) {
  const { logo_url: logoUrl, name, verified } = company;
  const { name: investmentName, portfolio_name: portfolioName } = investment;
  const value = holdingValueFormatter(investment);
  return (
    <MiniCard
      title={name}
      verified={verified}
      logoUrl={logoUrl}
      description={`${investmentName} held in ${portfolioName}`}
      value={value}
      {...restProps}
    />
  );
}

HoldingMiniCard.defaultProps = {
  investment: { name: "", portfolio_name: "" },
  company: {
    logo_url: "",
  },
};

HoldingMiniCard.propTypes = {
  company: companyPropType,
  investment: investmentPropType,
};

export default HoldingMiniCard;
