import React from "react";
import Icon from "components_new/atoms/Icon";
import "./ExpandIcon.scss";

function ExpandIcon({ size, onClick }) {
  return (
    <div onClick={onClick} className="ExpandIcon">
      <Icon icon="ri-arrow-right-line" size={size} />
      <Icon icon="ri-arrow-right-line" size={size} />
    </div>
  );
}

export default ExpandIcon;
