import React, { useEffect } from "react";
import { closeModal } from "store/modal";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { ESC_KEY } from "config/keyCodes";

function ModalOverlay({ children, closeModal }) {
  const escapeEventListener = (event) => {
    if (event.which === ESC_KEY) {
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", escapeEventListener);
    return () => {
      document.removeEventListener("keydown", escapeEventListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className="modalOverlay">{children}</div>;
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => dispatch(closeModal()),
  };
}

ModalOverlay.propTypes = {
  closeModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default connect(null, mapDispatchToProps)(ModalOverlay);
