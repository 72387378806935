import { memo } from "react";
import { connect } from "react-redux";
import ActivityFeedEvents from "./ActivityFeedEvents";
import { createSelector } from "reselect";
import { mapValues, sortBy, isEqual } from "lodash";
import { getCollection } from "store/domain/selectors";

const portfoliosByCompanyId = createSelector(
  (state) => getCollection(state, "portfolios"),
  (portfolios) => {
    const hash = {};
    portfolios.forEach((portfolio) => {
      portfolio.company_ids.forEach((companyId) => {
        hash[companyId] = hash[companyId] || new Set();
        hash[companyId].add(portfolio);
      });
    });
    return mapValues(hash, (companyPortfolios) =>
      sortBy(Array.from(companyPortfolios), ["id"]),
    );
  },
);

function mapStateToProps(state) {
  const {
    organizations: { currentOrganizationUsername },
  } = state;
  return {
    companies: getCollection(state, "companies"),
    portfoliosByCompanyId: portfoliosByCompanyId(state),
    organizationUsername: currentOrganizationUsername,
  };
}

const PureActivityFeedEvents = memo(ActivityFeedEvents, isEqual);

export default connect(mapStateToProps)(PureActivityFeedEvents);
