import React, { Fragment } from "react";
import FormGroup from "components_new/elements/FormGroup";
import DatePicker from "components_new/atoms/DatePicker";
import CompanySelector from "components_new/elements/CompanySelector/CompanySelector";
import RealizedGain from "components_new/SidebarComponents/forms/groups/RealizedGain";
import EquityProceeds from "components_new/SidebarComponents/forms/groups/EquityProceeds";
import DropdownForm from "components_new/atoms/DropdownForm";
import { proceedsTypeDropdownItems } from "./config";
import PropTypes from "prop-types";
import {
  tagSuggestionsProptype,
  acquisitionInfoPropType,
  errorsPropType,
} from "PropTypes";

function Acquisition({
  handleChange,
  errors,
  formData,
  componentName,
  showAdditionalFields,
  proceedsType,
  setProceedsType,
  filterCompanyTag,
  tagCompanySuggestions,
  mountSidebarComponent,
  hideSidebarComponent,
}) {
  const { company, date, cash_amount, type, acquiring_company } = formData;

  const handleProceedsTypeChange = (value) => {
    setProceedsType(value);
    if (value === "cash") {
      handleChange("is_cash_proceed", true);
      handleChange("is_equity_proceed", false);
    } else if (value === "equity") {
      handleChange("is_equity_proceed", true);
      handleChange("type", "equity");
      handleChange("is_cash_proceed", false);
    } else {
      handleChange("is_equity_proceed", true);
      handleChange("type", "both");
      handleChange("is_cash_proceed", true);
    }
    formData.type = value;
  };

  const onCreateNewCompany = (value) => {
    handleChange("acquiring_company", value);
    mountSidebarComponent(
      "addCompanyForm",
      "Add New Company",
      {
        data: { ...value },
      },
      componentName,
    );
    hideSidebarComponent(componentName);
  };

  return (
    <Fragment>
      <FormGroup title="Acquisition Details" isExpanded required>
        <DatePicker
          name="date"
          label="Date of acquisition"
          prefillToday
          onChange={(value, validated) => {
            handleChange("date", value, validated);
          }}
          value={date}
          size="tiny"
          required
          error={!!errors.for("date")}
          errorMessage={errors.for("date")}
        />
        <CompanySelector
          extraClassnames="LogAcquisitionForm-company"
          name="acquiring_company_id"
          label="Acquiring Company"
          placeHolder="Search"
          onChange={(_, value) => handleChange("acquiring_company", value)}
          prefilledValue={acquiring_company}
          filterCompanyTag={filterCompanyTag}
          suggestedCompanyList={tagCompanySuggestions}
          onCreateNewCompany={onCreateNewCompany}
          selectedCompany={acquiring_company}
          errorMessage={errors.for("acquiring_company_id")}
          excludedCompanies={[company && company.id]}
          isOnSidebar
          size="tiny"
        />
        <DropdownForm
          name="base"
          label="Type of Proceeds"
          required
          placeholder="Select proceeds type"
          value={type}
          items={proceedsTypeDropdownItems}
          onChange={(_, { value }) => handleProceedsTypeChange(value)}
          size="tiny"
          error={!!errors.for("base")}
          errorMessage={errors.for("base")}
        />
      </FormGroup>
      {showAdditionalFields && (
        <AdditionalAcquisitionDetails
          proceedsType={proceedsType}
          handleChange={handleChange}
          formData={formData}
          componentName={componentName}
          cashAmount={cash_amount}
          errors={errors}
        />
      )}
    </Fragment>
  );
}

function AdditionalAcquisitionDetails({
  proceedsType,
  handleChange,
  formData,
  cashAmount,
  componentName,
  errors,
  mountSidebarComponent,
  hideSidebarComponent,
}) {
  const acquisitionTypesMapping = {
    cash: (
      <RealizedGain
        realizedGain={cashAmount}
        onChange={handleChange}
        required
        isExpanded
        errors={errors}
      />
    ),
    equity: (
      <EquityProceeds
        formData={formData}
        handleChange={handleChange}
        componentName={componentName}
        mountSidebarComponent={mountSidebarComponent}
        hideSidebarComponent={hideSidebarComponent}
        prefilledCompany={formData.acquiring_company}
        errors={errors}
        bottomBorder
      />
    ),
    both: (
      <div>
        <RealizedGain
          realizedGain={cashAmount}
          onChange={handleChange}
          required
          isExpanded
          errors={errors}
        />
        <EquityProceeds
          formData={formData}
          handleChange={handleChange}
          componentName={componentName}
          prefilledCompany={formData.acquiring_company}
          mountSidebarComponent={mountSidebarComponent}
          hideSidebarComponent={hideSidebarComponent}
          bottomBorder
          errors={errors}
        />
      </div>
    ),
  };
  return acquisitionTypesMapping[proceedsType];
}
Acquisition.protoTypes = {
  errors: errorsPropType.isRequired,
  formData: acquisitionInfoPropType.isRequired,
  handleChange: PropTypes.func.isRequired,
  componentName: PropTypes.string.isRequired,
  showAdditionalFields: PropTypes.func.isRequired,
  proceedsType: PropTypes.string.isRequired,
  setProceedsType: PropTypes.string.isRequired,
  filterCompanyTag: PropTypes.func,
  tagCompanySuggestions: tagSuggestionsProptype,
  mountSidebarComponent: PropTypes.func,
  hideSidebarComponent: PropTypes.func,
};
export default Acquisition;
