import React from "react";
import Segment from "components_new/atoms/Segment";
import Icon from "components_new/atoms/Icon";
import Skeleton, {
  SkeletonHeader,
  SkeletonLine,
  SkeletonImage,
} from "components_new/atoms/Skeleton";

export default function SkeletonDropdown() {
  const skeletonLogoGroup = [];
  for (let i = 0; i < 5; i++) {
    skeletonLogoGroup.push(
      <Skeleton key={i}>
        <SkeletonImage />
      </Skeleton>,
    );
  }

  const skeletonDropdown = (
    <Segment basic className="SkeletonDropdown">
      {skeletonLogoGroup}
      <Skeleton>
        <SkeletonHeader>
          <SkeletonLine length="full" />
        </SkeletonHeader>
      </Skeleton>
      <Skeleton>
        <SkeletonHeader>
          <SkeletonLine length="full" />
        </SkeletonHeader>
      </Skeleton>
    </Segment>
  );

  // NOTE: This markup belongs to the Semantic UI Dropdown.
  // This was previously doing the same by passing the skeleton via the placeholder prop
  // of the dropdown, which theoretically shouldn't work because placeholders are normally not part
  // of the DOM, but it does because that dropdown shows the placeholder inline, which it's
  // an implementation detail of it, a choice that's not part of their explicit API, which says
  // that the placeholder should be a string.
  // As explicit dependencies are better that implicit ones, this creates a dependency with the
  // dropdown markup but not with the dropdown implementation itself.
  return (
    <div
      role="listbox"
      aria-expanded="false"
      className="ui fluid selection dropdown Dropdown default"
      tabIndex="0"
    >
      <div
        className="default text"
        role="alert"
        aria-live="polite"
        aria-atomic="true"
      >
        {skeletonDropdown}
      </div>
      <Icon icon="ri-arrow-down-s-line" />
    </div>
  );
}
