import React, { useState } from "react";
import RemoteSearch from "./RemoteSearch";
import ResultCard from "components_new/elements/ResultCard";
import formatContactName from "lib/formatters/formatContactName";

import { useSearch } from "queries/useSearch";
import { isEmpty, take } from "lodash";

export const getTextFromElement = (item) => {
  const { title } = item;
  return title ? title : formatContactName(item);
};

const buildOptions = ({ items, limit, query, allowAddItems, onAddNewItem }) => {
  const options = take(items, limit).map((item) => ({
    key: item.id,
    text: item.title,
    value: item,
    content: (
      <ResultCard
        query={query}
        title={getTextFromElement(item)}
        description={item.description}
        type={item.type}
        initials={item.initials}
        size="tiny"
      />
    ),
  }));

  if (!isEmpty(query) && allowAddItems) {
    options.push({
      key: "add-new-element",
      text: query,
      onClick: onAddNewItem,
      content: (
        <span className="RemoteSearch-addNewElement">
          + Add: <b>{query}</b>
        </span>
      ),
    });
  }

  return options;
};

function RemoteSearchWrapper({
  scope,
  value,
  onChange,
  onAddNewItem,
  placeholderResults,
  limit = 100,
  allowAddItems = false,
  ...restProps
}) {
  const [query, setQuery] = useState(null);

  const { data = [], isLoading } = useSearch({
    query,
    scope,
  });

  const options = buildOptions({
    items: data,
    limit,
    query,
    allowAddItems,
    onAddNewItem,
  });

  return (
    <RemoteSearch
      value={value}
      query={query}
      options={options}
      isLoading={isLoading}
      search={setQuery}
      onChange={onChange}
      placeholderResults={placeholderResults}
      {...restProps}
    />
  );
}

export default RemoteSearchWrapper;
