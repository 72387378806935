import React from "react";
import Label from "components/shared/Label";

function UserOrganizationLabel({ status, extraClassnames }) {
  const type = {
    owner: { message: "OWNER", type: "generic" },
    pending: { message: "INVITED", type: "bright" },
    expired: { message: "EXPIRED", type: "warning" },
  };

  return (
    <Label
      className={extraClassnames}
      message={type[status].message}
      type={type[status].type}
    />
  );
}

export default UserOrganizationLabel;
