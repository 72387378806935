import React from "react";
import RealizedGainCard from "../../cards/RealizedGainCard";
import HoldingCard from "../../cards/HoldingCard";
import Connector from "-!svg-react-loader?!assets/icons/miscellaneous/connection.svg?name=Connector";

function PurchasedEvent({ historyAction, companies, organizationUsername }) {
  const { child_investment: childInvestment } = historyAction;

  return (
    <div className="ContentEvent-view">
      <RealizedGainCard realizedGain={childInvestment.cost_basis} />
      <Connector className="ConnectorIcon" />
      <HoldingCard
        companyId={childInvestment.company_id}
        companyLogoUrl={companies[childInvestment.company_id].logo_url}
        companyName={companies[childInvestment.company_id].name}
        investmentId={childInvestment.id}
        investmentName={childInvestment.name}
        investmentType={childInvestment.type}
        costBasis={childInvestment.cost_basis}
        quantity={childInvestment.quantity}
        totalAcquisitionAmount={childInvestment.total_acquisition_amount}
        organizationUsername={organizationUsername}
      />
    </div>
  );
}

export default PurchasedEvent;
