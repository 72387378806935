import React from "react";

function InvestmentNotes({ notes }) {
  if (!notes) {
    return null;
  }

  return (
    <div className="InvestmentNotes">
      <h3 className="InvestmentNotes-title">Notes</h3>
      <div className="InvestmentNotes-content">{notes}</div>
    </div>
  );
}

export default InvestmentNotes;
