import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Header, { SubHeader } from "components_new/atoms/Header";
import Icon from "components_new/atoms/Icon";
import "./CollapsableSection.scss";
import classNames from "classnames";

function CollapsableSection({
  expanded,
  title,
  titleCount,
  subtitle,
  required,
  subGroup,
  children,
  noBottomBorder,
  withTopBorder,
  extraClassNames,
}) {
  const [isExpanded, setExpanded] = useState(expanded);

  useEffect(() => {
    setExpanded(expanded);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  const handleToggle = () => {
    setExpanded(!isExpanded);
  };

  const collapsableSectionClassNames = classNames("CollapsableSection", {
    [extraClassNames]: !!extraClassNames,
    subGroup: !!subGroup,
    "no-bottom-border": !!noBottomBorder,
    "with-top-border": !!withTopBorder,
  });

  return (
    <div className={collapsableSectionClassNames}>
      <CollapsableSectionHeader
        isExpanded={isExpanded}
        title={title}
        titleCount={titleCount}
        subtitle={subtitle}
        required={required}
        subGroup={subGroup}
        handleToggle={handleToggle}
      />
      <CollapsableSectionContent isExpanded={isExpanded}>
        {children}
      </CollapsableSectionContent>
    </div>
  );
}

function CollapsableSectionHeader({
  isExpanded,
  title,
  titleCount,
  subtitle,
  required,
  handleToggle,
}) {
  const utilityIcon = isExpanded
    ? "ri-arrow-down-s-line"
    : "ri-arrow-right-s-line";

  return (
    <div className="CollapsableSectionHeader" onClick={handleToggle}>
      <div className="CollapsableSectionIconHeader">
        <Icon icon={utilityIcon} />
        <Header as="h4">
          {title}
          {!!titleCount && (
            <span className="CollapsableSectionIconHeader-TitleCount">
              ({titleCount})
            </span>
          )}
          {required && "*"}
        </Header>
      </div>
      {subtitle && (
        <SubHeader className="CollapsableSectionSubHeader">
          {subtitle}
        </SubHeader>
      )}
    </div>
  );
}

function CollapsableSectionContent({ children, isExpanded }) {
  const childrenClassNames = classNames("CollapsableSectionContent", {
    hidden: !isExpanded,
  });
  return <div className={childrenClassNames}> {children} </div>;
}

CollapsableSection.propTypes = {
  expanded: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  titleCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subtitle: PropTypes.string,
  required: PropTypes.bool,
  subGroup: PropTypes.bool,
  children: PropTypes.element,
  noBottomBorder: PropTypes.bool,
  withTopBorder: PropTypes.bool,
  extraClassNames: PropTypes.string,
};

export default CollapsableSection;
