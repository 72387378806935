import React from "react";
import classNames from "classnames";
import { Image as SemanticImage } from "@ableco/semantic-ui-react";
import PropTypes from "prop-types";
import "semantic-ui-css/components/image.css";
import "./Image.scss";

function Image(props) {
  const { extraClassNames, ...imageProps } = props;
  const imageClassnames = classNames("Image", {
    [extraClassNames]: !!extraClassNames,
  });
  return <SemanticImage className={imageClassnames} {...imageProps} />;
}

Image.propTypes = {
  extraClassNames: PropTypes.string,
};

export default Image;
