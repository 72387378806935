import Api from "lib/Api";

// eslint-disable-next-line unicorn/regex-shorthand
const FILENAME_REGEXP = /filename\*?="?([^;\r\n"]*)["|[^;\n]]?/;

const extractFileName = (contentDisposition) => {
  const filenameMatches = FILENAME_REGEXP.exec(contentDisposition);
  if (filenameMatches !== null) return filenameMatches[1];
};

const REQUEST_OPTIONS = {
  parseResponse: false,
  headers: {
    Accept: "text/csv",
    "Content-Type": "text/csv",
  },
};

export const getExport = (url, requestOptions = {}) => {
  let mergedRequestOptions = {
    ...REQUEST_OPTIONS,
    ...requestOptions,
  };
  return Api.get(url, mergedRequestOptions).then(({ data, response }) => {
    const contentDisposition = response.headers.get("Content-Disposition");
    const fileName = extractFileName(contentDisposition);
    return { data, fileName };
  });
};
