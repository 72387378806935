import Api from "lib/Api";
import { requestStart, requestSuccess, requestError } from "store/requests";
import { upsertResource } from "store/resourcesV2/actions";
import {
  SET_CURRENT_ORGANIZATION,
  FETCH_ORGANIZATIONS_SUCCESS,
} from "./actionTypes";
import { receiveCollection } from "store/resourcesV2";

export function fetchOrganizations() {
  return (dispatch) => {
    return Api.get("/organizations", {
      withoutOrganization: true,
    }).then(({ data: organizations }) => {
      dispatch(receiveCollection("organizations", organizations));
      return organizations;
    });
  };
}

export function createOrganization(formData) {
  return (dispatch) => {
    dispatch(requestStart("createOrganization"));
    return Api.post(
      "/organizations",
      {
        organization: formData,
      },
      { withoutOrganization: true },
    )
      .then(({ data: organization }) => {
        dispatch(requestSuccess("createOrganization"));
        dispatch(upsertResource("organizations", organization));
        return organization;
      })
      .catch((error) => {
        dispatch(requestError("createOrganization"));
        throw error;
      });
  };
}

export function updateOrganization(organizationId, formData) {
  return (dispatch) => {
    return Api.patch(
      `/organizations/${organizationId}`,
      {
        organization: formData,
      },
      { withoutOrganization: true },
    ).then(({ data: organization }) => {
      dispatch(upsertResource("organizations", organization));
    });
  };
}

export function updateOrganizationPreferences(organizationId, formData) {
  return (dispatch) => {
    return Api.patch(
      `/organizations/${organizationId}/update_preferences`,
      {
        preferences: formData,
      },
      { withoutOrganization: true },
    ).then(({ data: organization }) =>
      dispatch(receiveCollection("organizations", [organization])),
    );
  };
}

export function deleteOrganization(organizationId) {
  return () => {
    return Api.delete(`/organizations/${organizationId}`, {
      withoutOrganization: true,
    });
  };
}

export function setCurrentOrganization(organization) {
  return { type: SET_CURRENT_ORGANIZATION, value: organization };
}

export function fetchOrganizationsSuccess() {
  return { type: FETCH_ORGANIZATIONS_SUCCESS };
}
